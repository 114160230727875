import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DownloadButton from '../custom/buttons/downLoadButton';

// import './css/modalPopUp.css';
import { closeModal, saveUpdatedApplicant, selectDownloadPhotoModalPopUpDetail } from './reducerSlices/downloadPhotoModalPopUpSlice';
import { isObjectArray } from '../../../utilities/utilityFunctions';

import deleteDarkImg from './../../../assets/images/clipart/delete-dark.svg';
import downloadDarkImg from './../../../assets/images/clipart/download-dark.svg';
import pencilDarkImg from './../../../assets/images/clipart/pencil-dark.svg';
import { onPhotoChanged } from '../../../features/applicant/services/photoSaver';
import { updateApplicant, updateSingleData } from '../../../features/applicant/reducerSlices/applicantSlice';
import { getBase64Image, setUploadedPhoto } from '../../../common/common';
import FileUpload from '../custom/textbox/fileUpload';
import nophoto from './../../../assets/images/clipart/nophoto.png';
import { tenantId } from '../../../api/client';

function DownloadPhotoModalPopUp(props) {

    const dispatch = useDispatch();

    const [base64Image, setBase64Image] = useState("");
    const [file, setFile] = useState();

    const downloadPhotoModalPopUpDetails = useSelector(selectDownloadPhotoModalPopUpDetail);
    const applicant = downloadPhotoModalPopUpDetails.applicant;
    const updatedApplicant = downloadPhotoModalPopUpDetails.updatedApplicant;

    const onCloseClicked = () => {
        dispatch(closeModal());
    };

    const onDownloadClicked = (e) => {
        e.preventDefault();
        try {
            let urlParts = downloadPhotoModalPopUpDetails.content.split("?");
            if (isObjectArray(urlParts) && urlParts.length == 2) {
                urlParts[0] = urlParts[0].replace(".jpg", "");
                urlParts[0] = urlParts[0] + ".jpg";
                window.open(urlParts[0] + "?" + urlParts[1], "_blank");
            }
        }
        catch (e) {
        }
    };

    const onFileSelected = e => {
        console.log('onFileSelected..');
        let file = e.target.files[0];
        console.log('onFileSelected.. file', file);
        getBase64Image(file)
            .then(result => {
                result = result.split(',').pop();
                file["base64"] = result;
                setBase64Image(result);
                onPhotoUpdated(`data:image/png;base64,${result}`);
            })
            .catch(err => {
                console.log(err);
            });

        setFile(e.target.files[0]);
        // setUploadedPhoto(e, "imgApplicantPhoto");
        setUploadedPhoto(e, "imgGeneratedRQRCode");
    };

    const onPhotoUpdated = (photoURL) => {
        let _applicantObj = Object.assign({}, applicant);
        let _newApplicantObj = onPhotoChanged(_applicantObj, photoURL);
        // dispatch(updateSingleData(_newApplicantObj));
        dispatch(saveUpdatedApplicant(_newApplicantObj));
    };

    const onPhotoDelete = async (photoData) => {
        // if (editMode) {
        onPhotoUpdated(``);
        setFile('');
        // }
    }

    const onSaveClicked = (e, id) => {
        e.preventDefault();
        // console.log('updatedApplicant', updatedApplicant);
        // return
        // if (isFormValid(APPLICANT_FORM)) {
        if (applicant && updatedApplicant) {
            dispatch(updateApplicant({
                client: tenantId,
                serviceType: "TENANTUSER",
                operation: "SUBMITAPPLICANT",
                payload: {
                    id: id,
                    data: JSON.stringify({
                        applicant: JSON.stringify(updatedApplicant),
                        photo: {
                            contentType: "image/jpg",
                            length: 0,
                            photoData: base64Image
                        }
                    })
                }
            }));
        }
        // }
        // else {
        // setAPFInvalid(true);
        // }
        // setApplicantFormSubmitted(true);
    };

    return (
        <React.Fragment>
            {(downloadPhotoModalPopUpDetails && downloadPhotoModalPopUpDetails.show) ? (<React.Fragment>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-block">
                                <div className="d-flex">
                                    <h5 className="modal-title">{downloadPhotoModalPopUpDetails.title}</h5>
                                    {updatedApplicant &&
                                        <button
                                            type="button"
                                            className="modal-close-button ms-3"
                                            onClick={(e) => onSaveClicked(e, updatedApplicant.id)}
                                        >
                                            <span aria-hidden="true"><i className="fa fa-solid fa-floppy-disk" aria-hidden="true"></i></span>
                                        </button>
                                    }
                                    <button
                                        type="button"
                                        className="modal-close-button ms-auto"
                                        onClick={onCloseClicked}>
                                        <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </button>
                                </div>
                                <div className="d-flex">
                                    <span className="modal-sub-title">Profile set, change, delete profile photo.</span>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="barcode-container">
                                    <img id="imgGeneratedRQRCode" src={downloadPhotoModalPopUpDetails.content ? downloadPhotoModalPopUpDetails.content : nophoto} alt="profile data" className="modal-photo" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className='w-100 '>
                                    <div className="d-flex justify-content-between">
                                        {/* <button type="button" className="c-transparent-btn" onClick={onPhotoDelete(props.photoPath)}> */}
                                        <button type="button" className="c-transparent-btn" onClick={(e) => onPhotoDelete(downloadPhotoModalPopUpDetails.content)} title="Delete profile photo">
                                            {/* <img src={deleteDarkImg} /> */}
                                            <i className="fa-solid fa-trash fa-lg my-3"></i>
                                            Delete
                                        </button>
                                        <button type="button" className="c-transparent-btn" onClick={(e) => onDownloadClicked(e)} title="Download profile photo">
                                            {/* <img src={downloadDarkImg} /> */}
                                            <i className="fa-solid fa-download fa-lg my-3"></i>
                                            Download
                                        </button>

                                        <FileUpload
                                            icon={pencilDarkImg}
                                            FileUploadClasses="form-control-file applicant-photo-uploader"
                                            id="fuApplicantPhoto"
                                            name="fuApplicantPhoto"
                                            onChange={(e) => onFileSelected(e)}
                                            accept="image/png, image/jpeg"
                                        >
                                            <button type="button" className="c-transparent-btn" title='upload profile photo'>
                                                {/* <img src={pencilDarkImg} /> */}
                                                <i className="fa-solid fa-pencil fa-lg my-3"></i>
                                                Change
                                            </button>
                                        </FileUpload>

                                    </div>
                                </div>
                                {/* <DownloadButton
                                    title="Download photo..."
                                    onDownloadClicked={(e) => onDownloadClicked(e)} /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </React.Fragment>) : <React.Fragment></React.Fragment>}
        </React.Fragment>
    );
}

export default DownloadPhotoModalPopUp;