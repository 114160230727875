import React, { useEffect, useState } from 'react';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import { toLocalDate, _getContent, formatraw, _getStatusText } from '../../../common/common';
import DataNotFoundCard from '../../Card/DataNotFoundCard';


function LogCard(props) {
    const getStatus = (logValue, logType) => {

        let statusText;

        if (logValue === 0) {
            statusText = statusText = <><span className="p-changed"> {logType} </span></>;
        }
        else if (logValue === 1) {
            // statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-gray"> {logType} </span></span></>;
            statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-gray"> {_getStatusText(props.statuses, logType)} </span></span></>;
        }
        else if (logValue === 2) {
            // statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-green"> {logType} </span></span></>;
            statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-green"> {_getStatusText(props.statuses, logType)} </span></span></>;
        }
        else if (logValue === 3) {
            // statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-success"> {logType} </span></span></>;
            statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-success"> {_getStatusText(props.statuses, logType)} </span></span></>;
        }
        else if (logValue === 4) {
            // statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-danger"> {logType} </span></span></>;
            statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-danger"> {_getStatusText(props.statuses, logType)} </span></span></>;
        }
        else if (logValue === 5 || logValue === 6) {
            // statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill status-dnu"> {logType} </span></span></>;
            statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill status-dnu"> {_getStatusText(props.statuses, logType)} </span></span></>;
        }
        else if (logValue === 7) {
            // statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-gold"> {logType} </span></span></>;
            statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-gold"> {_getStatusText(props.statuses, logType)} </span></span></>;
        }
        else if (logValue === 8 || logValue === "8") {
            statusText = <><span className="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill status-undispatch"> {logType} </span></span></>;
        }
        else if (logValue === -1) {
            statusText = <><span className="p-changed"> Added Note </span></>;
        }
        else if (logValue === -2) {
            statusText = <><span className="p-status"> <span className="badge rounded-pill bg-light-orange"> {logType} </span></span><span className="p-changed"> Applicant </span></>;
        }
        else if (logValue === -3) {
            statusText = <><span className="p-changed"> Applicant </span><span className="p-status"><span className="badge rounded-pill bg-blue"> {logType} </span></span></>;
        }
        else if (logValue === -4) {
            statusText = <><span className="p-changed"> Set </span><span className="p-status"><span className="text-orange"> {logType} </span></span></>;
        }
        else if (logValue === -9) {
            statusText = <><span className="p-changed"> has {logType}</span></>;
        }
        else {
            statusText = <span><span className="text-muted statusfont"></span><span className="text-dark cardtitle"> {logType} </span></span>;
        }
        return statusText;
    };

    const getStatusIcon = (logValue, logType) => {
        let statusIcon;
        if (logValue === -9) {
            if (logType === "Location Change") {
                statusIcon = <div className="tracking-icon status-intransit bg-light-orange"><span><i className="fa fa-location-dot fa-md" aria-hidden="true"></i></span></div>;
            } else {
                statusIcon = <div className="tracking-icon status-intransit bg-light-orange"><span><i className="fa fa-fire-flame-curved fa-md" aria-hidden="true"></i></span></div>;
            }
        }
        else if (logValue === -4) {
            statusIcon = <div className="tracking-icon status-intransit bg-orange"><span><i className="fa fa-person-walking-arrow-loop-left fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === -2 || logValue === -3) {
            statusIcon = <div className="tracking-icon status-intransit bg-green"><span><i className="fa fa-phone fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === -1) {
            statusIcon = <div className="tracking-icon status-intransit bg-blue"><span><i className="fa fa-pen-to-square fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 0) {
            statusIcon = <div className="tracking-icon status-intransit bg-create"><span><i className="fa fa-bolt fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 1 || logValue === 2 || logValue === 3 || logValue === 4 || logValue === 5 || logValue === 7) {
            statusIcon = <div className="tracking-icon status-intransit bg-purple"><span><i className="fa fa-stamp fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 8 || logValue === '8') {
            statusIcon = <div className="tracking-icon status-intransit status-undispatch"><span><i className="fa fa-person-walking-arrow-right fa-md" aria-hidden="true"></i></span></div>;
        }
        else {
            statusIcon = <div className="tracking-icon status-intransit bg-secondary"><span><i className="fa fa-info fa-md" aria-hidden="true"></i></span></div>;
        }
        return statusIcon;
    }

    const [logs, setLogs] = useState();

    useEffect(() => {
        let _statusLogs = props.statusLogs;
        let _logs;
        if (isObjectArray(_statusLogs) && _statusLogs.length > 0) {
            _logs = _statusLogs.map((log, index) => (
                <React.Fragment>
                    {log &&
                        <div className="tracking-item">
                            {/* <div className="tracking-icon status-intransit bg-purple">
                                <span><i className="fa fa-user-tie fa-lg" aria-hidden="true"></i></span>
                            </div> */}
                            {getStatusIcon(log.LogValue, log.LogType)}
                            <div className="tracking-content">
                                <p>
                                    <span className="p-name">{log.UserName != null ? log.UserName : ""}</span>
                                    {log && getStatus(log.LogValue, log.LogType)}
                                    {/* <span className="p-changed"> Changed status to</span>
                                    <span className="p-status"> Dispatched</span> */}
                                </p>
                                <p className="p-subtext">{log && _getContent(log.Content, log.LogValue)}</p>
                                {log && log.Client && <p className="p-dark-gray">Client : <span className="p-light-gray">{log.Client}</span></p>}
                                {log && log.PayRates && <p className="p-dark-gray">Pay Rates : <span className="p-light-gray">{log && log.PayRates}</span></p>}
                                <p className="p-light-gray">{log && toLocalDate(log.TimeStamp)}</p>
                            </div>
                        </div>
                    }
                </React.Fragment>

            ));
            setLogs(_logs);
        }
        else {
            setLogs(<DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title="" description="" />);
        }
    }, [props.statusLogs]);

    return (
        <React.Fragment>
            {logs}
        </React.Fragment>
    );
}

export default LogCard;