import {
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    activityData: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    mileageData: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false,
    },
    salesPerformanceData: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false,
    },
}

export const getActivityReport = createAsyncThunk('report/GetActivityReport', async (reportModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', reportModel, getAxiosRequestConfig());
    const data = response.data ? response.data : "[]";
    let options;
    if (isJSON(data.data)) {
        options = JSON.parse(data.data);
    }
    return {
        options: options,
        success: response.data.success
    };
});

export const getMileageReport = createAsyncThunk('locations/getMileageReport', async (reportModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', reportModel, getAxiosRequestConfig());
    const data = response.data ? response.data : "[]";
    let options;
    if (isJSON(data.data)) {
        options = JSON.parse(data.data);
    }
    return {
        options: options,
        success: response.data.success
    };
});

export const getSalesPerformanceReport = createAsyncThunk('locations/getSalesPerformanceReport', async (reportModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', reportModel, getAxiosRequestConfig());
    const data = response.data ? response.data : "[]";
    let options;
    if (isJSON(data.data)) {
        options = JSON.parse(data.data);
    }
    return {
        options: options,
        success: response.data.success
    };
});

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        // updateStatus: (state) => {
        //     state.data.status = fetchStatus.DONE;
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(getActivityReport.pending, (state, action) => {
            state.activityData.status = fetchStatus.LOADING;
        }).addCase(getActivityReport.fulfilled, (state, action) => {
            let _options = action.payload.options;
            console.log(action.payload)
            state.activityData.options = _options;
            state.activityData.status = fetchStatus.SUCCEEDED;
            state.activityData.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getActivityReport.rejected, (state, action) => {
            state.activityData.status = fetchStatus.FAILED;
            state.activityData.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getMileageReport.pending, (state, action) => {
            state.mileageData.status = fetchStatus.LOADING;
        }).addCase(getMileageReport.fulfilled, (state, action) => {
            let _options = action.payload.options;
            state.mileageData.options = _options;
            state.mileageData.status = fetchStatus.SUCCEEDED;
            state.mileageData.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getMileageReport.rejected, (state, action) => {
            state.mileageData.options = undefined;
            state.mileageData.status = fetchStatus.FAILED;
            state.mileageData.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getSalesPerformanceReport.pending, (state, action) => {
            state.salesPerformanceData.status = fetchStatus.LOADING;
        }).addCase(getSalesPerformanceReport.fulfilled, (state, action) => {
            let _options = action.payload.options;
            state.salesPerformanceData.options = _options;
            state.salesPerformanceData.status = fetchStatus.SUCCEEDED;
            state.salesPerformanceData.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getSalesPerformanceReport.rejected, (state, action) => {
            state.salesPerformanceData.options = undefined;
            state.salesPerformanceData.status = fetchStatus.FAILED;
            state.salesPerformanceData.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export default reportSlice.reducer

export const selectActivityRecords = state => state.report.activityData.options;

export const selectMileageRecords = state => state.report.mileageData.options;

export const selectSalesPerformanceRecords = state => state.report.salesPerformanceData.options;