
export const locationsData = [
    {
        text: "Venice (Mucker)",
        value: "138EB11A-4A9E-4C97-8C38-F369B869CB22"
    },
    {
        text: "COMPTON",
        value: "63A33046-0AAE-4778-9781-81D2056027C5"
    },
    {
        text: "ONTARIO",
        value: "75699A06-519B-44F5-9321-1EF277908515"
    },
    {
        text: "BUENA PARK",
        value: "838EB11A-4A9E-4C97-8C38-F369B869CB21"
    },
    {
        text: "VAN NUYS",
        value: "93FEBF82-ECF0-4F08-9453-579B05186536"
    },
    {
        text: "CITY OF INDUSTRY",
        value: "9AD45711-0810-43CA-B3D0-3EF9DF446CC2"
    },
    {
        text: "CORONA",
        value: "AE145F43-CAF9-4ED6-AF4B-13B25EEF361B"
    },
    {
        text: "BELL",
        value: "B1F891D5-DF5A-4A6A-9D7B-5C755B36F02A"
    },
    {
        text: "TORRANCE",
        value: "D164BF6C-421C-4B16-91D4-44EB3F94EEC0"
    },
    {
        text: "BELLFLOWER",
        value: "EF4F92BD-875E-471E-B513-FF86C84B49A8"
    },
    {
        text: "NORWALK",
        value: "FB9EFC9E-1613-4DAB-B548-49535230E1F1"
    }
];


export const applicantStatuses = [
    {
        text: "In Progress",
        value: "25A33046-0AAE-4778-9781-81D2056027Z5"
    },
    {
        text: "Available",
        value: "cdcf1026-3ae8-478b-8383-92928b271442"
    },
    {
        text: "Active",
        value: "635e9608-097e-41f5-8135-4e3114639d5f"
    },
    {
        text: "InActive",
        value: "21438919-889c-42a9-855f-b4d408a02c9f"
    },
    {
        text: "DNU",
        value: "9340db45-7719-4ef6-85d9-dea8430ed96e"
    },
    {
        text: "Dispatched",
        value: "572c079e-de04-481a-a021-5af0af240656"
    }
];

export const applicantStatusesData = {
    InProgress: '25A33046-0AAE-4778-9781-81D2056027Z5',
    Available: 'cdcf1026-3ae8-478b-8383-92928b271442',
    Active: '635e9608-097e-41f5-8135-4e3114639d5f',
    InActive: '21438919-889c-42a9-855f-b4d408a02c9f',
    DNU: '9340db45-7719-4ef6-85d9-dea8430ed96e',
    Dispatched: '572c079e-de04-481a-a021-5af0af240656'
};

export const jobStatuses = [
    {
        text: "In Progress",
        value: "In Progress"
    },
    {
        text: "Available",
        value: "Available"
    },
    {
        text: "Active",
        value: "Active"
    },
    {
        text: "InActive",
        value: "InActive"
    },
    {
        text: "DNU",
        value: "DNU"
    },
    // {
    //     text: "DNR",
    //     value: "DNR"
    // },
    {
        text: "Dispatch",
        value: "Dispatch"
    }
];




export const userStatuses = [
    {
        text: "Active",
        value: "Active"
    },
    {
        text: "Deactive",
        value: "Deactive"
    }
];

export const userRoles = [
    {
        text: "Admin",
        value: "Admin"
    },
    {
        text: "Reviewer",
        value: "Reviewer"
    },
    {
        text: "Payroll",
        value: "Payroll"
    },
    {
        text: "SalesRep",
        value: "SalesRep"
    },
    {
        text: "SalesManager",
        value: "SalesManager"
    }
];

export const clientStatuses = [
    {
        text: "Active",
        value: "Active"
    },
    {
        text: "Deactive",
        value: "Deactive"
    }
];

export const leadStatuses = [
    {
        text: "New",
        value: "New"
    },
    {
        text: "In Progress",
        value: "In Progress"
    },
    {
        text: "Credit Check",
        value: "Credit Check"
    },
    {
        text: "Safety Check",
        value: "Safety Check"
    },
    {
        text: "Proposal",
        value: "Proposal"
    },
    {
        text: "SA",
        value: "SA"
    },
    {
        text: "COI",
        value: "COI"
    },
    {
        text: "Completed",
        value: "Completed"
    },
    {
        text: "Rejected",
        value: "Rejected"
    },
];

export const visibilityStatuses = [
    {
        text: "Enabled",
        value: "Active"
    },
    {
        text: "Disabled",
        value: "Deactive"
    }
];