import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus } from '../../../../api/client';
import { _getDateText } from '../../../../common/common';
import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from '../../../../features/locations/reducerSlices/locationSlice';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
// import CheckBox from '../checkers/checkbox';

// import './css/dispatchJobSelector.css';

function DispatchJobSelector(props) {

    const _getPositionText = (id) => {
        if (isObjectArray(props.positionOptions)) {
            let position = props.positionOptions.find(p => p.value === id);
            return position && position.text ? position.text : "";
        }
        return "";
    }

    const _getClientText = (id) => {
        let name = "";
        if (isObjectArray(props.clients)) {
            let _client = props.clients.find(c => c.id === id);
            if (_client && _client.ClientName) {
                name = _client.ClientName;
            }
        }
        return name;
    }

    const _getLocationText = (id) => {
        if (isObjectArray(props.locations)) {
            let location = props.locations.find(l => l.value === id);
            return location && location.text ? location.text : "";
        }
        return "";
    }

    const _getRepeatStatus = (clientId) => {
        let applicant = props.applicant;
        if (applicant && isObjectArray(applicant.dispatchDetails)) {
            for (let i = 0; i < applicant.dispatchDetails.length; i++) {
                if (clientId === applicant.dispatchDetails[i].client) {
                    return true;
                }
            }
        }
        return false;
    }


    const dispatch = useDispatch();
    const [onSelect, setOnSelect] = useState(false);
    const [value, setValue] = useState("");
    const [text, setText] = useState("");
    const [optionsData, setOptionsData] = useState(props.options);
    const [options, setOptions] = useState();
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);



    const optionsContainerRef = useRef(null);

    useEffect(() => {
        if (isObjectArray(props.options)) {
            let _optionsData = [].concat(props.options);
            _optionsData.sort((a, b) => {
                if (a.matched > b.matched) return -1;
                if (a.matched < b.matched) return 1;
                return 0;
            });

            setOptionsData(_optionsData);
        }

    }, [props.options]);

    useEffect(() => {
        let _options = [];
        if (isObjectArray(optionsData)) {
            _options = optionsData.map((o, index) => (
                <tr key={o.jobKey} onClick={(e) => onSelected(e, o.jobKey)}>
                    <td className="py-2">
                        {o.jobTitle}
                    </td>
                    <td className="py-2">
                        {_getPositionText(o.positionId)} {o.jobType ? `[${o.jobType}]` : ""} {o.positionDescription ? `[${o.positionDescription}]` : ""}
                    </td>
                    <td className="py-2">
                        {o.reportTo}
                    </td>
                    <td className="py-2">
                        {_getDateText(o.startDate)} {o.startTime ? o.startTime : ""}
                    </td>
                    <td className="py-2">
                        <label className=" FieldsWidth100"  >{_getRepeatStatus(o.clientId) ? <span className='c-badge p-1 px-3 bg-light-orange'>Repeat</span> : <span className='c-badge p-1 px-3 bg-blue'>New</span>}</label>
                    </td>
                    {/* <td>
                        <label className=" FieldsWidth100"  >{o.matched === 1 ? <span className="badge badge-success ">100 %</span> : <React.Fragment></React.Fragment>}</label>
                    </td>
                    <td>
                        <label className=" FieldsWidthempoyeeaddress"  >{_getClientText(o.clientId)}</label>
                    </td> */}
                    <td className="py-2">
                        {_getLocationText(o.locationId)}
                    </td>

                </tr>
                // <li key={o.jobKey} className="dispatch-job-selector-option" onClick={(e) => onSelected(e, o.jobKey)}>
                //     <br></br>
                //     <div className="option-detail-title">{o.jobTitle} 

                //     </div>
                //     <div className="option-section">
                //             <div className={`${(value === o.jobKey) ? "option-selected" : ""}`}>
                //                 Report To : {o.reportTo}
                //             </div>


                //         </div>

                //     <div className="dispatch-job-selector-option-details">
                //         <div className="option-section">
                //             <div className={`${(value === o.jobKey) ? "option-selected" : ""}`}>
                //                 <span className="badge badge-info ">{_getDateText(o.startDate)} {o.startTime ? o.startTime : ""}</span>
                //             </div>
                //         </div>
                //         <div className="option-section">
                //             <div className={`${(value === o.jobKey) ? "option-selected" : ""}`}>
                //                 {o.matched === 1 ? <span className="badge badge-success ">100 %</span> : <React.Fragment></React.Fragment>}
                //             </div>
                //         </div>
                //     </div>
                //     <div className="dispatch-job-selector-option-details">
                //         <div className="option-section">

                //             <div className={`${(value === o.jobKey) ? "option-selected" : ""}`}>
                //                 {`${_getClientText(o.clientId)}`}
                //             </div>
                //             <div className={`${(value === o.jobKey) ? "option-selected" : ""}`}>
                //                 {`${_getLocationText(o.locationId)}`}
                //             </div>
                //         </div>
                //         <div className="option-section">
                //             <div className={`${(value === o.jobKey) ? "option-selected" : ""}`}>
                //                 {_getPositionText(o.positionId)} {o.positionDescription ? `[${o.positionDescription}]` : ""}
                //             </div>
                //             <div className={`${(value === o.jobKey) ? "option-selected" : ""}`}>
                //                 {_getRepeatStatus(o.clientId) ? <span className='badge badge-warning'>Repeat</span> : <React.Fragment></React.Fragment>}
                //             </div>

                //         </div>
                //     </div>
                //     <hr></hr>
                // </li>
            ));
        }
        setOptions(_options);
    }, [optionsData]);



    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        let _text = "";
        if (value && isObjectArray(optionsData)) {
            let _job = optionsData.find(j => j.jobKey === value);
            if (_job) {
                _text = _job.jobTitle + " " + _getPositionText(_job.positionId);
            }
        }
        setText(_text);
    }, [value]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value || (value && Array.isArray(value) && value.length === 0)) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
        }
        else {
            setInvalid(false);
        }

    }, [value, props.required, edited, props.submited, props.disabled]);

    useEffect(() => {
        if (onSelect) {
            optionsContainerRef.current.focus();
        }
    }, [onSelect, optionsContainerRef]);

    const onSelected = async (e, v) => {
        e.stopPropagation();

        setValue(v);
        setOnSelect(false);
        setEdited(true);
        let _e = { ...e };
        _e.target["name"] = props.name;
        props.onChange && props.onChange(_e, v);
    };

    const onBlur = (e) => {
        setOnSelect(false);
    };



    return (
        <React.Fragment>
            <div id={props.id} data-name={props.name} className="dispatch-job-selector" data-form={props.form ? props.form : ""}
                data-invalid={invalid}>
                <div className={`selected-options-container ${props.disabled ? "selector-disabled" : ""}`}
                    onClick={(e) => !props.disabled && setOnSelect(!onSelect)}>
                    {text ? <span className="selected-options-summary">{`${text}`}</span> : <span className="selected-options-summary">-- Select --</span>}
                </div>
                {onSelect ? <div ref={optionsContainerRef} className="dispatch-job-selector-options-container" tabIndex={-1}
                    onBlur={(e) => onBlur(e)}>
                    <div className="dispatch-job-selector-options">
                        <table className="table table-hover c-table">
                            <thead>
                                <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col">Position</th>
                                    <th scope="col">Report To</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">Status</th>
                                    {/* <th scope="col">Match</th>
                                        <th scope="col">Client Name</th> */}
                                    <th scope="col">Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                {options}
                            </tbody>
                        </table>
                    </div>
                    {/* <ul className="dispatch-job-selector-options">
                        {options}
                    </ul> */}
                </div> : <React.Fragment></React.Fragment>}
            </div>
            {
                invalid && <span className="error-label">
                    <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            }
        </React.Fragment>
    );
}

export default DispatchJobSelector;