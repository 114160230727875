import React from "react";
import phoneCallFillImg from './../../assets/images/clipart/phone-call-fill.svg';
import mapLocationImg from './../../assets/images/clipart/map-location.svg';
import RemoveIconButton from "../Controls/custom/buttons/removeIconButton";
import DialCallButton from "../Controls/buttons/dialCallButton";

const ProspectsHorizontalCard = (props) => {


    return (
        <div className="card people-horizontal-card mb-3">
            <div className="people-horizontal-card-body p-2 cursor-pointer" onClick={props.onClick}>
                <div className="d-flex">
                    <div className="ps-3 col-4">
                        <div className="title w-100">
                            <span>{props.leadName}</span>
                        </div>
                        <div className="row">
                            <div className="detail col-12">
                                {/* <img src={phoneCallFillImg} /> {props.employerPhone} */}
                                <div className='d-flex align-items-center'>
                                    <DialCallButton dialCallButtonClasses="pe-1" />
                                    {props.employerPhone}
                                </div>
                            </div>
                            <div className="detail col-12">
                                <img src={mapLocationImg} /> {props.employerAddress}
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="people-horizontal-card-info-box">
                            <div className="detail">Supervisor</div>
                            <div className="detail detail-bold text-truncate" title={props.supervisor}>{props.supervisor}</div>
                            <div className="detail">Supervisor Phone</div>
                            <div className="detail detail-bold text-truncate" title={props.employerTelePhone}>
                                <div className='d-flex align-items-center'>
                                    <DialCallButton dialCallButtonClasses="pe-1" />
                                    {props.employerTelePhone}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="people-horizontal-card-info-box">
                            <div className="detail">Position</div>
                            <div className="detail detail-bold text-truncate" title={props.position}>{props.position}</div>
                            <div className="detail">Agency Name</div>
                            <div className="detail detail-bold text-truncate" title={props.employerAgencyName}>{props.employerAgencyName}</div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="people-horizontal-card-info-box">
                            <div className="detail">Applicant Name</div>
                            <div className="detail detail-bold text-truncate">{props.applicantName}</div>
                            <div className="detail">Reason For Leaving</div>
                            <div className="detail detail-bold text-truncate">{props.reasonForLeaving}</div>
                        </div>
                    </div>
                    <div className="col-1">
                        <button
                            type="button"
                            className="c-btn dark-btn mt-2"
                            title="Add To Lead"
                            onClick={props.onAddClicked}>
                            Add as Lead
                        </button>
                        <br />
                        <RemoveIconButton
                            title="Remove position"
                            RemoveIconButtonClasses="delete-document-button d-flex justify-content-center"
                            RemoveIconClasses="mt-3"
                            onClick={props.onRejectClicked}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ProspectsHorizontalCard;
