export const sections = {
    USER_DETAILS: 0
};

export const onValueChanged = (user, e, section, value) => {
    let _user = JSON.parse(JSON.stringify(user));
    if (section == sections.USER_DETAILS) {
        return _saveUserDetails(_user, e, value);
    }
};

const _saveUserDetails = (user, e, value) => {
    if (e.target.name === "txtFirstName") {
        user.FirstName.Data = e.target.value;
    }
    else if (e.target.name === "txtLastName") {
        user.LastName.Data = e.target.value;
    }
    else if (e.target.name === "txtPhone") {
        user.PhoneNumber.Data = e.target.value;
    }
    else if (e.target.name === "txtEmail") {
        user.EmailAddress.Data = e.target.value;
    }
    else if (e.target.name === "txtPassword") {
        user.Password.Data = e.target.value;
    }
    else if (e.target.name === "drpRoles") {
        user.Role.Data = e.target.value;
    }
    else if (e.target.name === "drpStatuses") {
        user.Status.Data = e.target.value;
    }
    else if (e.target.name === "drpLocations") {
        user.Location.Data = e.target.value;
    }

    return user;
};