import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../../api/client';
import { getAxiosRequestConfig } from '../../../../common/common';
import { isJSON } from '../../../../utilities/utilityFunctions';
import Collection from '../../paginators/collection';
import { toaster } from '../../toasts/toaster';

const initialState = {
    title: "",
    show: false,
    statusChange: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
}

export const saveClientImport = createAsyncThunk('clientImportModalPopUp/saveClientImport', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
        }
        return {
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const clientImportModalPopUpSlice = createSlice({
    name: 'clientImportModalPopUp',
    initialState,
    reducers: {
        showClientImportModal: (state, action) => {
            const { title } = action.payload
            state.title = title;
            state.show = true;
        },
        closeClientImportModal: state => {
            state.show = false;
            state.title = "";
        }
    },
    extraReducers: (builder) => {
        builder.addCase(saveClientImport.pending, (state, action) => {
            state.statusChange.status = fetchStatus.LOADING;
        }).addCase(saveClientImport.fulfilled, (state, action) => {
            state.statusChange.data = action.payload.applicant;
            state.statusChange.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(saveClientImport.rejected, (state, action) => {
            state.statusChange.status = fetchStatus.FAILED;
            state.statusChange.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { showClientImportModal, closeClientImportModal,
    updateStatus, updateCurrentPage,
    updateReadyForDispatch, removeReadyForDispatch } = clientImportModalPopUpSlice.actions;

export default clientImportModalPopUpSlice.reducer

export const selectClientImportModalPopUpDetail = state => {
    return {
        title: state.clientImportModalPopUp.title,
        show: state.clientImportModalPopUp.show
    }
}

export const selectSaveClientImportStatus = state => state.clientImportModalPopUp.statusChange.status;
