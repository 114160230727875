import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OkButton from '../custom/buttons/okButton';

import { closeModal, selectModalPopUpDetail } from './reducerSlices/modalPopUpSlice';

function ModalPopUp(props) {

    const dispatch = useDispatch();

    const modalPopUpDetails = useSelector(selectModalPopUpDetail);

    const onCloseClicked = () => {
        dispatch(closeModal());
    };

    const onOkClicked = () => {
        dispatch(closeModal());
    };

    return (
        <React.Fragment>
            {(modalPopUpDetails && modalPopUpDetails.show) ? (<React.Fragment>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-block">
                                <div className="d-flex">
                                    <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                                    <button
                                        type="button"
                                        className="modal-close-button ms-auto"
                                        onClick={onCloseClicked}>
                                        <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </button>
                                </div>
                                <div className="d-flex">
                                    <span className="modal-sub-title">{modalPopUpDetails.subtitle}</span>
                                </div>
                            </div>
                            <div className="modal-body">
                                {modalPopUpDetails.content}
                            </div>
                            <div className="modal-footer">
                                <OkButton onOkClicked={() => onOkClicked()} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </React.Fragment>) : <React.Fragment></React.Fragment>}
        </React.Fragment>
    );
}

export default ModalPopUp;