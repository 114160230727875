import React from 'react'
import AvatarGroup from '../Avatar/AvatarGroup';

import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import mapLocationImg from './../../assets/images/clipart/map-location.svg';
import dateStartImg from './../../assets/images/clipart/date-start.svg';
import dateEndImg from './../../assets/images/clipart/date-end.svg';
import userActiveImg from './../../assets/images/clipart/user-active.svg';
import filesTextImg from './../../assets/images/clipart/files-text.svg';
import bagLockImg from './../../assets/images/clipart/bag-lock.svg';
import calendarImg from './../../assets/images/clipart/calendar.svg';


function JobOrderCard(props) {

  const _getOpenCount = () => {
    let _filled = props.filled ? props.filled : 0;
    let _total = props.total ? props.total : 0;
    if (isNaN(_filled) || isNaN(_total)) {
      return 0;
    }
    _filled = parseInt(_filled);
    _total = parseInt(_total);
    if (isNaN(_filled) || isNaN(_total)) {
      return 0;
    }
    else {
      return _total - _filled;
    }
  }

  const _getStatusClassBadge = () => {
    if (props.status === 'New') {
      return 'status-available';
    }
    else if (props.status === 'Inactive') {
      return 'status-incomplete ';
    }
    else if (props.status === 'INPROGRESS') {
      return 'status-inprogress';
    }
    else if (props.status === "Inprogress") {
      return 'status-inprogress';
    }
    else if (props.status === 'Filled') {
      return 'status-active';
    }
  }

  const _getPeopleDispatched = (applicantDetails) => {
    const applicantDetailsArray = applicantDetails.map(item => {
      return {
        title: item.Name,
        photo: item.Photo ? item.Photo !== "" && item.Photo.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "") :""
      };
    });

    return applicantDetailsArray;
  }

  return (
    <div className="job-order-card">
      <div className="job-order-card-body cursor-pointer" onClick={props.onClick ? props.onClick : ''}>
        <div className={`status ${_getStatusClassBadge()}`}>{props.status}</div>
        {/* <div className="job-order-card-user"> */}
        <div className="row w-100 m-0">
          <div className="job-order-card-user-row">
            <div className="job-order-card-photo">
              <div className="job-order-card-photo-box">
                {/* <img src={boyFaceImg} alt="job-order-card-user-img" /> */}
                <div className='job-order-card-photo-box-text'>{props.name.slice(0, 2)}</div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="job-order-card-user-name">{props.name}</div>
              <div className="job-order-card-user-location">
                <img src={mapLocationImg} />
                <div className="ps-2">
                  {props.address}
                </div>
                <img src={calendarImg} className="ps-4" />
                <div className="ps-2">
                  {/* Request Date: 08/30/2022 */}
                  Request Date: {props.requestDate}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row w-100 m-0 mt-2">
          <div className="job-order-card-user-info-col-250 col-4">
            <div className="job-order-card-user-info pt-2">
              <div className="d-inline-flex">
                <div className="d-flex flex-column">
                  <h5>People Dispatched</h5>
                  <AvatarGroup photosData={_getPeopleDispatched(props.applicantDetails)} />
                </div>
              </div>
            </div>
            <div className="job-order-card-user-info">
              <div className="d-inline-flex">
                <img src={userActiveImg} />
                <div className="d-flex flex-column ps-3">
                  <h5>Active People</h5>
                  <small> {props.numberOfActivePeople}</small>
                </div>
              </div>
            </div>
          </div>
          <div className="job-order-card-user-info-col-250 col-4">
            <div className="job-order-card-user-info">
              <div className="d-inline-flex">
                <img src={dateStartImg} />
                <div className="d-flex flex-column ps-3">
                  <h5>Start Date</h5>
                  <small> {props.startDate}, {props.startTime}</small>
                </div>
              </div>
            </div>
            <div className="job-order-card-user-info">
              <div className="d-inline-flex">
                <img src={filesTextImg} />
                <div className="d-flex flex-column ps-3">
                  <h5>People Filled</h5>
                  <small>{props.filled}</small>
                </div>
              </div>
            </div>
          </div>
          <div className="job-order-card-user-info-col-250 col-4">
            <div className="job-order-card-user-info">
              <div className="d-inline-flex">
                <img src={dateEndImg} />
                <div className="d-flex flex-column ps-3">
                  <h5>Job End Date</h5>
                  <small> {props.endDate}, {props.endTime}</small>
                </div>
              </div>
            </div>
            <div className="job-order-card-user-info">
              <div className="d-inline-flex">
                <img src={bagLockImg} />
                <div className="d-flex flex-column ps-3">
                  <h5>Total Job Position Open</h5>
                  <small>{_getOpenCount()}</small>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* </div> */}
      </div>
    </div>
  )
}

export default JobOrderCard