import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    selectedFilter: 0,
    id: "",
    name: "",
    defaultFilter: false,
    where: {
        location: {
            value: [],
            text: "All"
        },
        role: {
            value: [],
            text: "All"
        },
        userStatus: {
            value: [],
            text: "All"
        },
        name: ""
    },
    refreshData: false
};

export const userFilterSlice = createSlice({
    name: 'userFilter',
    initialState,
    reducers: {
        updateUserRefreshData: (state, action) => {
            state.refreshData = action.payload;
        },
        updateUserFilter: (state, action) => {
            let { id, name, whereClause, defaultFilter } = action.payload;
            state.id = id;
            state.name = name;
            state.defaultFilter = defaultFilter;
            whereClause = whereClause ? whereClause : {
                location: {
                    value: [],
                    text: "All"
                },
                role: {
                    value: [],
                    text: "All"
                },
                userStatus: {
                    value: [],
                    text: "All"
                },
                name: ""
            };
            state.where = Object.assign({}, whereClause);
        },
        updateLocationFilter: (state, action) => {
            const { locations, value } = action.payload;
            let location = { ...state.where.location };
            let text = "All";
            if (!location.value.includes(value)) {
                location.value = [...location.value, value];
            }
            else {
                location.value = location.value.filter((val) => (val !== value));
            }
            if ((locations && Array.isArray(locations) && locations.length > 0) && location.value.length > 0) {
                text = "";
                for (let i = 0; i < locations.length; i++) {
                    let loc = locations[i];
                    if (location.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            location.text = text;
            location.text = location.value.length > 0 ? `(${location.value.length}) selected` : "All";
            state.where.location = location;
        },
        updateRoleFilter: (state, action) => {
            const { roles, value } = action.payload;
            let role = { ...state.where.role };
            let text = "All";
            if (!role.value.includes(value)) {
                role.value = [...role.value, value];
            }
            else {
                role.value = role.value.filter((val) => (val !== value));
            }
            if ((roles && Array.isArray(roles) && roles.length > 0) && role.value.length > 0) {
                text = "";
                for (let i = 0; i < roles.length; i++) {
                    let loc = roles[i];
                    if (role.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            role.text = text;
            role.text = role.value.length > 0 ? `(${role.value.length}) selected` : "All";
            state.where.role = role;
        },
        updateUserStatusFilter: (state, action) => {
            const { userStatuses, value } = action.payload;
            let userStatus = { ...state.where.userStatus };
            let text = "All";
            if (!userStatus.value.includes(value)) {
                userStatus.value = [...userStatus.value, value];
            }
            else {
                userStatus.value = userStatus.value.filter((val) => (val !== value));
            }
            if ((userStatuses && Array.isArray(userStatuses) && userStatuses.length > 0) && userStatus.value.length > 0) {
                text = "";
                for (let i = 0; i < userStatuses.length; i++) {
                    let loc = userStatuses[i];
                    if (userStatus.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            userStatus.text = text;
            userStatus.text = userStatus.value.length > 0 ? `(${userStatus.value.length}) selected` : "All";
            state.where.userStatus = userStatus;
        },
        updateNameFilter: (state, action) => {
            state.where.name = action.payload;
        }
    }
});

export const { updateUserRefreshData, updateUserFilter, updateLocationFilter, updateRoleFilter,
    updateUserStatusFilter, updateNameFilter } = userFilterSlice.actions;

export default userFilterSlice.reducer

export const selectLocationFilter = state => state.userFilter.where.location;
export const selectRoleFilter = state => state.userFilter.where.role;
export const selectUserStatusFilter = state => state.userFilter.where.userStatus;

export const selectFilterId = state => state.userFilter.id;

export const selectFilterName = state => state.userFilter.name;

export const selectWhereClause = state => state.userFilter.where;

export const selectDefaultFilter = state => state.userFilter.defaultFilter;

export const selectRefreshData = state => state.userFilter.refreshData;

export const selectWhereClauseChanged = state => {
    const whereClause = state.userFilter.where;
    if (whereClause.location.value.length > 0) {
        return true;
    }
    if (whereClause.role.value.length > 0) {
        return true;
    }
    if (whereClause.userStatus.value.length > 0) {
        return true;
    }
    return false;
}