import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './features/accounts/screens/Login';
import Register from './features/accounts/screens/Register';
import Personal_Info_1 from './features/accounts/screens/Personal_Info_1';
import Personal_Info_2 from './features/accounts/screens/Personal_Info_2';
import References from './features/accounts/screens/References';
import './assets/css/custom.css';
import './App.css';
import Employment_Details_1 from './features/accounts/screens/Employment_Details_1';
import Education_Details from './features/accounts/screens/Education_Details';
import Congratulations from './features/accounts/screens/Congratulations';
import Demo from './component/Demo/Demo';
import Test from './component/Demo/Test';
import People from './features/people/screens/People';
import PeopleDetails from './features/people/screens/PeopleDetails';
import JobOrder from './features/jobOrder/screens/jobOrder';
import JobOrderEdit from './features/jobOrder/screens/components/JobOrderEdit';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StatusUpdateModalPopUp from './component/Controls/modals/statusUpdateModalPopUp';
import AlertModalPopUp from './component/Controls/modals/alertModalPopUp';
import ChangeLocationModalPopUp from './component/Controls/modals/changeLocationModalPopUp';
import DownloadPhotoModalPopUp from './component/Controls/modals/downloadPhotoModalPopUp';
import ModalPopUp from './component/Controls/modals/modalPopUp';
import FillOrderModalPopUp from './component/Controls/modals/fillOrderModalPopUp';
import Setting from './features/setting/screens/setting';
import ClientFlyout from './component/Flyout/ClientFlyout';
import ConfirmAlert from './component/Controls/alerts/confirmAlert';
import LocationFlyout from './component/Flyout/LocationFlyout';
import UserFlyout from './component/Flyout/UserFlyout';
import ChangePasswordModalPopUp from './component/Controls/modals/changePasswordModalPopUp';
import Report from './features/report/screens/report';
import Inbox from './features/inbox/screens/inbox';
import Feed from './features/feed/screens/feed';
import SaveFilterModalPopUp from './component/Controls/modals/saveFilterModalPopUp';
import SaveJobFilterModalPopUp from './component/Controls/modals/saveJobFilterModalPopUp';
import DNRModalPopUp from './component/Controls/modals/DNRModalPopUp';
import ChatFlyout from './component/Flyout/ChatFlyout';
import Dashboard from './features/dashboard/screens/dashboard';
import Prospects from './features/crm/screens/prospects';
import ProspectsStatusUpdateModalPopUp from './component/Controls/modals/prospectsStatusUpdateModalPopUp';
import Lead from './features/crm/screens/lead';
import LeadDetails from './features/crm/screens/leadDetails';
import LeadCalledFlyout from './component/Flyout/LeadCalledFlyout';
import Schedule from './features/crm/screens/schedule';
import LeadStatusUpdateModalPopUp from './component/Controls/modals/leadStatusUpdateModalPopUp';
import LeadNoteFlyout from './component/Flyout/LeadNoteFlyout';
import UsersTracking from './features/crm/screens/usersTracking';
import LayoutRoute from './routes/layoutRoute';
import LeadRouteNoteModalPopUp from './component/Controls/modals/leadRouteNoteModalPopUp';
import PreferredClientModalPopUp from './component/Controls/modals/PreferredClientModalPopUp';
import RouteTracking from './features/crm/screens/routeTracking';
import RouteTrackingDetails from './features/crm/screens/routeTrackingDetails';
import RouteTrackingLeadModalPopUp from './component/Controls/modals/routeTrackingLeadModalPopUp';
import ProspectsPeople from './features/prospectsPeople/prospectsPeople';
import ProspectsPeopleDetails from './features/prospectsPeople/prospectsPeopleDetails';
import StatusFlyout from './component/Flyout/StatusFlyout';
import PeopleImportModalPopUp from './component/Controls/modals/peopleImportModalPopUp';
import CompanyFlyout from './component/Flyout/CompanyFlyout';
import ClientImportModalPopUp from './component/Controls/modals/clientImportModalPopUp';
import ResetPassword from './features/accounts/screens/ResetPassword';
import ExperienceFlyout from './component/Flyout/ExperienceFlyout';
import SkillGroupFlyout from './component/Flyout/SkillGroupFlyout';
import PositionFlyout from './component/Flyout/PositionFlyout';
import CompanyDetails from './features/companySetup/screens/companyDetails';
import CompanyLocation from './features/companySetup/screens/companyLocation';
import CompanyPositions from './features/companySetup/screens/companyPositions';
import CompanySkillGroup from './features/companySetup/screens/companySkillGroup';
import CompanyExperience from './features/companySetup/screens/companyExperience';
import CompanyStatuses from './features/companySetup/screens/companyStatuses';
import CompanySetupRoute from './routes/companySetupRoute';

const App = () => {
  return (
    <>
      {<ToastContainer />}
      <Router>
        <ModalPopUp />
        <StatusUpdateModalPopUp />
        <AlertModalPopUp />
        <ChangeLocationModalPopUp />
        <ChatFlyout />
        <DNRModalPopUp />
        <DownloadPhotoModalPopUp />
        <FillOrderModalPopUp />
        <ClientFlyout />
        <ConfirmAlert />
        <LocationFlyout />
        <UserFlyout />
        <StatusFlyout />
        <CompanyFlyout />
        <ExperienceFlyout />
        <SkillGroupFlyout />
        <PositionFlyout />
        <ChangePasswordModalPopUp />
        <SaveFilterModalPopUp />
        <SaveJobFilterModalPopUp />
        <ProspectsStatusUpdateModalPopUp />
        <LeadCalledFlyout />
        <LeadStatusUpdateModalPopUp />
        <LeadNoteFlyout />
        <LeadRouteNoteModalPopUp />
        <PreferredClientModalPopUp />
        <RouteTrackingLeadModalPopUp />
        <PeopleImportModalPopUp />
        <ClientImportModalPopUp />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/personal_info_1" component={Personal_Info_1} />
          <Route exact path="/personal_info_2" component={Personal_Info_2} />
          <Route exact path="/references" component={References} />
          <Route exact path="/employment_details_1" component={Employment_Details_1} />
          <Route exact path="/education_details" component={Education_Details} />
          <Route exact path="/congratulations" component={Congratulations} />
          <Route exact path="/demo" component={Demo} />
          <Route exact path="/test" component={Test} />
          <Route exact path="/resetPassword" component={ResetPassword} />
          <LayoutRoute exact path="/people" component={People} />
          <LayoutRoute exact path="/people/details" component={PeopleDetails} />
          <LayoutRoute exact path="/jobOrder" component={JobOrder} />
          <LayoutRoute exact path="/jobOrder/job" component={JobOrderEdit} />
          <LayoutRoute exact path="/report" component={Report} />
          <LayoutRoute exact path="/setting" component={Setting} />
          <LayoutRoute exact path="/inbox" component={Inbox} />
          <LayoutRoute exact path="/feed" component={Feed} />
          <LayoutRoute exact path="/dashboard" component={Dashboard} />
          <LayoutRoute exact path="/prospects" component={Prospects} />
          <LayoutRoute exact path="/lead" component={Lead} />
          <LayoutRoute exact path="/lead/details" component={LeadDetails} />
          <LayoutRoute exact path="/schedules" component={Schedule} />
          <LayoutRoute exact path="/usersTracking" component={UsersTracking} />
          <LayoutRoute exact path='/routeList' component={RouteTracking} />
          <LayoutRoute exact path='/routeList/details' component={RouteTrackingDetails} />
          <LayoutRoute exact path='/prospectsPeople' component={ProspectsPeople} />
          <LayoutRoute exact path='/prospectsPeople/details' component={ProspectsPeopleDetails} />
          <CompanySetupRoute exact path='/setup/companyDetails' component={CompanyDetails} />
          <CompanySetupRoute exact path='/setup/companyLocation' component={CompanyLocation} />
          <CompanySetupRoute exact path='/setup/companyPositions' component={CompanyPositions} />
          <CompanySetupRoute exact path='/setup/companySkillGroup' component={CompanySkillGroup} />
          <CompanySetupRoute exact path='/setup/companyExperience' component={CompanyExperience} />
          <CompanySetupRoute exact path='/setup/companyStatuses' component={CompanyStatuses} />
        </Switch>
      </Router>
    </>
  )
}

export default App;