import {
    createAsyncThunk,
    createSlice
}
    from '@reduxjs/toolkit';
import { BajoAPI, fetchStatus } from '../../../../api/client';
import { getAxiosRequestConfig } from '../../../../common/common';
import { isJSON } from '../../../../utilities/utilityFunctions';

const initialState = {
    title: "",
    id: "",
    routeTracking: undefined,
    leadsOptions: undefined,
    show: false,
    canceled: false,
};

export const routeTrackingLeadModalPopUpSlice = createSlice({
    name: 'routeTrackingLeadModalPopUp',
    initialState,
    reducers: {
        showRTLModal: (state, action) => {
            const { title, id, routeTracking, leadsOptions } = action.payload;
            state.title = title;
            state.id = id;
            state.routeTracking = routeTracking;
            state.leadsOptions = leadsOptions;
            state.show = true;
        },
        closeModal: state => {
            state.title = "";
            state.id = "";
            state.routeTracking = undefined;
            state.leadsOptions = undefined;
            state.show = false;
            state.canceled = true;
        }
    }
});

export const { showRTLModal, closeModal } = routeTrackingLeadModalPopUpSlice.actions;

export default routeTrackingLeadModalPopUpSlice.reducer

export const selectModalPopUpDetail = state => {
    return {
        title: state.routeTrackingLeadModalPopUp.title,
        id: state.routeTrackingLeadModalPopUp.id,
        routeTracking: state.routeTrackingLeadModalPopUp.routeTracking,
        leadsOptions: state.routeTrackingLeadModalPopUp.leadsOptions,
        show: state.routeTrackingLeadModalPopUp.show,
    }
}

export const selectCanceledStatus = state => state.routeTrackingLeadModalPopUp.canceled;