import React, { useState } from 'react';
import { JobLogType } from '../../common/constants';

import bagCheckWhiteImg from './../../assets/images/clipart/bag-check-white.svg';
import pagelinewhiteImg from './../../assets/images/clipart/page-line-white.svg';
import phoneCallWhiteImg from './../../assets/images/clipart/phone-call-white.svg';
import cloudImg from './../../assets/images/clipart/cloud.svg';


function JobStatusCard(props) {

    // console.log('props.logType -> ', props.logType, ' logValue->', props.logValue);
    let statusIcon;
    if (props.logValue === JobLogType.CREATION.value) {
        statusIcon = <div className="tracking-icon status-intransit bg-blue"><span><i className="fa fa-bolt mr-2" aria-hidden="true"></i></span></div>;
    }
    else if (props.logValue === JobLogType.MODIFICATION.value) {
        statusIcon = <div className="tracking-icon status-intransit bg-purple"><span><i className="fa fa-pencil mr-2" aria-hidden="true"></i></span></div>;
    }
    else if (props.logValue === JobLogType.INACTIVATION.value) {
        statusIcon = <div className="tracking-icon status-intransit bg-gray"><span><i className="fa fa-ban mr-2" aria-hidden="true"></i></span></div>;
    }
    else if (props.logValue === JobLogType.DISPATCH.value) {
        statusIcon = <div className="tracking-icon status-intransit bg-gold"><span><i className="fa fa-close mr-2" aria-hidden="true"></i></span></div>;
    }
    else if (props.logValue === JobLogType.FILLED.value) {
        statusIcon = <div className="tracking-icon status-intransit bg-green"><span><i className="fa fa-check mr-2" aria-hidden="true"></i></span></div>;
    }


    const getStatus = (logValue, logType) => {
        let statusText;

        if (logValue === 0) {
            statusText = <React.Fragment>Job Creation</React.Fragment>;
        }
        else if (logValue === 1) {
            statusText = <React.Fragment>Job Modification</React.Fragment>;
        }
        else if (logValue === 2) {
            statusText = <React.Fragment>Job Inactivated</React.Fragment>;
        }
        else if (logValue === 3 && logType === 'Applicant Dispatched') {
            statusText = <React.Fragment>Applicant <span className='text-orange'>Dispatched</span></React.Fragment>;
        }
        else if (logValue === 3 && logType === 'Applicant undispatched') {
            statusText = <React.Fragment>Applicant <span className='text-rust'>Undispatched</span></React.Fragment>;
        }
        else if (logValue === 4) {
            statusText = <React.Fragment>Job Filled</React.Fragment>;
        }
        return statusText;
    }

    return (
        // <div className={`card status-card ${props.JobStatusCardClasses ? props.JobStatusCardClasses : ""}`}>
        //     <div className="card-header">
        //         {statusIcon}{props.logType}
        //     </div>
        //     <div className="card-body">
        //         <p className="card-text text-justify">{props.content}</p>
        //         <p className="card-text mt-3">
        //             {props.client && <span>Client : <span>{props.client}</span></span>}
        //         </p>
        //     </div>
        // </div >

        <div className="tracking-item">
            {/* <div className="tracking-icon status-intransit bg-blue">
                <img src={bagCheckWhiteImg} />
            </div> */}
            {statusIcon}
            <div className="tracking-content">
                <p>
                    <span className="p-name">{props.userName} </span>
                    {/* <span className="p-changed"> {`${props.LogValue === -1 ? "added on" : "updated status on"} - ${props.timeStamp} `}  </span> */}
                    <span className="p-changed">{getStatus(props.logValue, props.logType)} {props.timeStamp}   </span>
                </p>
                {/* <p className="p-subtext">Job modified successfully by <b> Rob Smith </b></p> */}
                <p className="p-subtext">{props.content}</p>
                <span className="p-light-gray">{props.client && <span>Client : <span>{props.client}</span></span>}</span>
                {/* <p className="p-dark-gray"> {props.logType}</p> */}
            </div>
        </div>

    );
}

export default JobStatusCard;