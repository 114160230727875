import { nanoid } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { onFormUploaded } from '../../../features/applicant/services/formSaver';
import { updateSingleData } from '../../../features/applicant/reducerSlices/applicantSlice';
import { documentTypes, getBase64Image, getFileSizeText } from '../../../common/common';
import { isFormValid } from '../services/formService';

import licenseImg from './../../../assets/images/clipart/license.svg';
import pdfImg from './../../../assets/images/clipart/pdf.svg';
import { saveDocument, selectDocumentUploadStatus } from '../../../features/reviewer/reducerSlices/reviewerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { accountService } from '../../../features/accounts/services/accountService';
import { useEffect } from 'react';
import Loader from '../loaders/loader';
import EllipsisLoader from '../loaders/ellipsisLoader';
import { fetchStatus, tenantId } from '../../../api/client';
import ReactTooltip from 'react-tooltip';
import UrlText from '../custom/textbox/urlText';
import YesNoRadio from '../custom/checkers/yesNoRadio';


function UploadDocumentsCard(props) {

    const dispatch = useDispatch();

    const UPLOAD_FORM = "UploadForm";
    const user = accountService.getAuthenticatedUser();

    const [uploadFormSubmitted, setUploadFormSubmitted] = useState(false);

    const [docType, setDocType] = useState(props.docType);

    const [invalid, setInvalid] = useState(false);
    const [urlLink, setUrlLink] = useState("");
    const [sourceType, setSourceType] = useState("File");
    const [urlLinkValue, setUrlLinkValue] = useState("");


    const documentUploadStatus = useSelector(selectDocumentUploadStatus);

    const [note, setNote] = useState("");
    const [base64Image, setBase64Image] = useState("");
    const [file, setFile] = useState();
    const [value, setValue] = useState("");
    const [size, setSize] = useState(0);
    const [edited, setEdited] = useState(false);
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: '*',
        onDrop: acceptedFiles => {
            let file = acceptedFiles[0];
            getBase64Image(file)
                .then(result => {
                    result = result.split(',').pop();
                    file["base64"] = result;
                    setBase64Image(result);
                })
                .catch(err => {
                    console.log(err);
                });

            setFile(acceptedFiles[0]);
            setSize(acceptedFiles[0].size);
            setValue(acceptedFiles[0].name);
            setEdited(true);


            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const onSaveClicked = async (id) => {
        if (isFormValid(UPLOAD_FORM)) {
            setUploadFormSubmitted(false);
            if (id) {
                if (sourceType === 'File') {
                    if (file && file.size >= 1) {
                        let doctypeObj = documentTypes.find(dt => dt.value == docType);
                        let docTypeText = !doctypeObj ? "" : doctypeObj.text;
                        let documentId = nanoid();
                        const fileDetails = {
                            length: file.size,
                            fileContent: base64Image,
                            documentData: {
                                id: documentId,
                                UploadedOn: (new Date()).toUTCString(),
                                DocumentName: documentId + "_" + file.name,
                                DocumentType: docType,
                                DocumentTypeText: docTypeText,
                                Note: note,
                                UploadedBy: (user ? user.firstName + " " + user.lastName : ""),
                                UploadedFileInfo: "",
                                Length: file.size,
                                IsURL: false
                            }
                        };
                        let _applicantObj = Object.assign({}, props.applicant);
                        let _newApplicantObj = onFormUploaded(_applicantObj, fileDetails);
                        dispatch(updateSingleData(_newApplicantObj));
                        await dispatch(saveDocument({
                            client: tenantId,
                            serviceType: "TENANTADMIN",
                            operation: "SAVEDOCUMENT",
                            payload: {
                                id: id,
                                data: JSON.stringify(fileDetails)
                            }
                        }));

                        // Clear the form after successful save
                        clearForm();
                    } else {
                        setInvalid(true);
                    }
                } else {
                    let doctypeObj = documentTypes.find(dt => dt.value == docType);
                    let docTypeText = !doctypeObj ? "" : doctypeObj.text;
                    let documentId = nanoid();
                    const fileDetails = {
                        length: 0,
                        fileContent: "",
                        documentData: {
                            id: documentId,
                            UploadedOn: (new Date()).toUTCString(),
                            DocumentName: urlLinkValue,
                            DocumentType: docType,
                            DocumentTypeText: docTypeText,
                            Note: note,
                            UploadedBy: (user ? user.firstName + " " + user.lastName : ""),
                            UploadedFileInfo: "",
                            Length: 0,
                            IsURL: true
                        }
                    };
                    let _applicantObj = Object.assign({}, props.applicant);
                    let _newApplicantObj = onFormUploaded(_applicantObj, fileDetails);
                    dispatch(updateSingleData(_newApplicantObj));
                    await dispatch(saveDocument({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVEDOCUMENT",
                        payload: {
                            id: id,
                            data: JSON.stringify(fileDetails)
                        }
                    }));

                    // Clear the form after successful save
                    clearForm();
                }
            }
        } else {
            setUploadFormSubmitted(true);
        }
    };

    useEffect(() => {
        if (props.applicant) {
            setInvalid(false);
            setNote('');
        }
    }, [props.applicant]);


    useEffect(() => {
        if (uploadFormSubmitted) {
            if (!value) {
                setInvalid(true);
            }
            else {
                setInvalid(false);
            }
        }
        else {
            setInvalid(false);
        }
    }, [value, uploadFormSubmitted]);

    const clearForm = () => {
        setSourceType("File");
        setUrlLink("");

        setValue("");
        setNote("");
        setFile(undefined);
        setSize(0);
        setEdited(false);
        setFiles([]);
        setInvalid(false);
    };

    const onCancelClicked = () => {
        clearForm();
    }

    const onSourceTypeFileClicked = () => {
        setSourceType("File");
        setUrlLink("");

        setValue("");
        setFile(undefined);
        setSize(0);
        setFiles([]);
    }
    const onSourceTypeURLLinkClicked = () => {
        setSourceType("UrlLink");

        setUrlLink("");
        setValue("");
        setFile(undefined);
        setSize(0);
        setFiles([]);
    }

    const onValueUpdated = (e, v) => {
        if (v === "Y") {
            setSourceType("UrlLink");
        } else {
            setSourceType("File");
        }

        setUrlLink("");
        setValue("");
        setFile(undefined);
        setSize(0);
        setFiles([]);
    }

    return (
        <React.Fragment>
            <>
                <div className='px-3 m-0 pt-3'>
                    <div className='row'>
                        <div className='col-md-12 m-0'>
                            <div className="flyout-label-bold ">
                                Select a source type
                            </div>
                        </div>
                        <div className="col-md-12 m-0">
                            {/* <div className="form-group"> */}
                            <label className="flyout-label">Url Link</label>
                            <YesNoRadio
                                name="rdbtnCEM"
                                value={sourceType === "UrlLink" ? "Y" : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, v)} />

                            {/* </div> */}
                        </div>

                        {/* <div className='col'>
                            <button className="c-btn dark-btn rounded-pill" type="button" onClick={onSourceTypeFileClicked}>Upload File</button>

                            <span className='ps-3'>
                                <button className="c-btn dark-btn rounded-pill" type="button" onClick={onSourceTypeURLLinkClicked} >URL Link</button>
                            </span>
                        </div> */}
                    </div>
                </div>
            </>
            {sourceType === "UrlLink" &&
                <>
                    <div className='px-3 m-0'>
                        <label className="flyout-label pt-3">Document Url Link</label>
                        <UrlText
                            id={"txtUrlLink"}
                            name={"txtUrlLink"}
                            UrlTextClasses="form-control"
                            placeholder="Url Link"
                            required={true}
                            form={UPLOAD_FORM}
                            value={urlLinkValue}
                            onChange={(e) => setUrlLinkValue(e.target.value)}
                            submited={uploadFormSubmitted}
                        />
                    </div>
                </>
            }
            {sourceType === "File" &&
                <>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            <div className='pt-3'>
                                <div className={`file-upload-box mx-3 ${invalid && "file-upload-box-invalid"}`}>
                                    <div className="flyout-list-group-item-card">
                                        <i className="fa-solid fa-cloud-arrow-up"></i>
                                        <span className="ps-2">
                                            <div className='file-upload-text'>Drag & Drop files here <span className='text-secondary'>or</span></div>
                                        </span>
                                        <span className="ms-auto">
                                            <button className="c-btn dark-btn rounded-pill" type="button" >Choose Files</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        invalid && <span className="error-label ps-3">
                            <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                            This field is required"
                        </span>
                    }
                    <div className="flyout-card-sub-title bold ps-3">
                        Attached File
                    </div>
                    {
                        value &&
                        <div className="flyout-list-group-item-card">
                            <div className='sub-icon'>
                                <img src={pdfImg} className="icon-img" />
                                <p className='file-size-text'>{getFileSizeText(size)}</p>
                            </div>
                            <span className="ps-2">
                                <div className='file-name'> {value}</div>
                            </span>
                        </div>
                    }
                </>
            }
            <div className='px-3 pb-5'>
                <label className="flyout-label pt-3">Notes</label>
                <textarea
                    id="txtDocumentNote"
                    name="txtDocumentNote"
                    className="flyout-input"
                    placeholder='Add note here'
                    onChange={(e) => setNote(e.target.value)}
                    value={note}
                ></textarea>
                {documentUploadStatus === fetchStatus.LOADING ? <div className="float-end mt-2"><EllipsisLoader /></div> :
                    <React.Fragment>
                        <button className="c-btn transparent-btn f-w-500 text-blue float-end" type="button" onClick={() => onSaveClicked(props.applicant.id)} data-tip='Save'>Save</button>
                        <button className="c-btn transparent-btn f-w-500 text-gray float-end" type="button" onClick={onCancelClicked} data-tip='Cancel'>Cancel</button>
                        <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

export default UploadDocumentsCard;