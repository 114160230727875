import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, masterTenantId, tenantId } from '../../../../api/client';
import GridLoader from '../../../../component/Controls/loaders/gridLoader';
import { getAllSubscribers, selectAllSubscribers, selectStatus } from '../../reducerSlices/subscribersSlice';
import ReactPlaceholder from 'react-placeholder';
import { Filter, GridComponent, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';
import ReactTooltip from 'react-tooltip';
import { messages } from '../../../../common/actionMessages';
import { show } from '../../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import { AccountPlan } from '../../../../common/constants';

function Subscribers() {
    const whereClause = {
        name: ""
    };
    const dispatch = useDispatch();

    const [dummyCards, setDummyCards] = useState([]);

    const subscribers = useSelector(selectAllSubscribers);
    const subscribersRequestStatus = useSelector(selectStatus);

    useEffect(() => {
        if (subscribersRequestStatus === fetchStatus.IDLE) {
            _loadSubscribers();
        }
    }, [subscribersRequestStatus, dispatch]);

    let _dummyCards = [];

    useEffect(() => {
        if (subscribersRequestStatus === fetchStatus.LOADING) {
            for (let i = 0; i < 10; i++) {
                _dummyCards.push(
                    <div className="list-loading-content">
                        <ReactPlaceholder type='text' ready={false} rows={2} color='#E0E0E0'>
                        </ReactPlaceholder>
                    </div>
                );
            }
            setDummyCards(_dummyCards);
        }
    }, [subscribersRequestStatus]);

    const _loadSubscribers = async () => {
        await dispatch(getAllSubscribers({
            page: 0,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETSUBSCRIBERS",
                payload: {
                    id: "",
                    data: ""
                }
            }
        }));

    }

    const getSubscriptionText = (field, data, column) => {
        let _subscription = data["Subscription"]
        return _subscription === true ? 'Yes' : 'No';
    }

    // const getVerifyAccountText = (field, data, column) => {
    //     let _verifyAccount = data["VerifyAccount"]
    //     return _verifyAccount === true ? <i class="fa-solid fa-certificate"></i> : '';
    // }

    const getVerifyAccountIcon = (props) => {
        return <>
            {props.VerifyAccount === true &&
                <i class="fa-solid fa-certificate text-blue"  title='This account is verified.'></i>
            }
        </>
    }

    const gridRef = useRef(null);
    useEffect(() => {
        if (gridRef.current) {
            gridRef.current.refresh();
        }
    }, [subscribers, subscribersRequestStatus]);

    const columns = [
        { field: 'CompanyName', headerText: 'Company Name', width: '100' },
        { field: 'Name', headerText: 'Name', width: '100' },
        { field: 'Email', headerText: 'Email', width: '100' },
        { field: 'PhoneNumber', headerText: 'PhoneNumber', width: '70' },
        { field: 'Position', headerText: 'Position', width: '100' },
        { field: 'NumberOfEmployees', headerText: 'Number Of Employees', width: '110' },
        { field: 'NumberOfApplicantPerMonth', headerText: 'Number Of Applicant Per Month', width: '150' },
        { field: 'Subscription', headerText: 'Subscription', width: '70', valueAccessor: getSubscriptionText },
        // { field: 'VerifyAccount', headerText: 'Verify Account', width: '70', valueAccessor: getVerifyAccountText },
        {
            headerText: 'Verify Account',
            template: getVerifyAccountIcon,
            width: '50px',
        }
    ];


    return (
        <>
            {(subscribersRequestStatus === fetchStatus.LOADING) &&
                <div className="app-container-loader">
                    <GridLoader />
                </div>}
            <div className="row">
                <div className="col-md-6">
                    <span className="detail-container-title">Subscribers</span>
                </div>
            </div>
            <div className="row p-3">
                <GridComponent ref={gridRef} dataSource={subscribers} allowPaging={true} allowSorting={true} allowResizing={true} width="100%" toolbar={['Search']} pageSettings={{ pageSize: 10, pageCount: 5 }} rowHeight={50} columns={columns} >
                    <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                </GridComponent>
            </div>
        </>
    )
}

export default Subscribers;