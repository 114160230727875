import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Content from '../../../components/Controls/custom/content/content';
// import ToolBar from '../../../components/Controls/custom/toolBar';
import { getBase64Image, openFileUploader, setUploadedPhoto, _getDateText, _getLocationText, _getLocationTextAll, toLocalDateOnly } from '../../../../common/common';
// import { updateLayout } from '../../../components/layouts/reducerSlices/layoutSlice';
// import { onRoleChanged, onClientLocationChanged, onClientStatusChanged } from '../services/payrollReportFilterHandler';
// import InputGroup from '../../../components/Controls/custom/inputGroup';
import DateText from '../../../../component/Controls/custom/textbox/dateText';
import Selector from '../../../../component/Controls/custom/selectors/selectors';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import "react-placeholder/lib/reactPlaceholder.css";
import {
    selectDefaultFilter,
    selectLocationFilter,
    selectRefreshData,
    selectRoleFilter,
    selectClientStatusFilter,
    selectWhereClause,
    selectWhereClauseChanged,
    updateFilter,
    updateClientFilter,
    updateClientRefreshData,
    updateNameFilter,
} from '../../../../component/Controls/filters/reducerSlices/payrollReportFilterSlice';
import { selectClientOptions, getClientOptionsAll, selectClientOptionsRefreshed, selectClientOptionsStatus } from '../../../setting/reducerSlices/clientSlice';

import { getLocationOptions, selectLocationOptions, getLocationOptionsAll, selectLocationOptionsRefreshed, selectLocationOptionsStatus, getPayrollReport, selectPayrollRecords } from '../../../locations/reducerSlices/locationSlice';
import { fetchStatus, tenantId } from '../../../../api/client';
// import ActiveEmpDetail from '../screens/component/activeEmpReportDetials';
// import exportFromJSON from 'export-from-json'
import jsPDF from "jspdf";
import "jspdf-autotable";
// import { CSVLink, CSVDownload } from "react-csv";
import Loader from "react-js-loader";
import { accountService } from '../../../accounts/services/accountService';
import XLSX from 'xlsx';
import Moment from 'moment';

import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';



function ActiveEmpReport(props) {

    const [pageLoaded, setPageLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const defaultFilter = useSelector(selectDefaultFilter);
    const whereClauseChanged = useSelector(selectWhereClauseChanged);
    const [clientOptionsValue, setClientOptionsValue] = useState();
    const clientOptions = useSelector(selectClientOptions);
    const clientOptionsStatus = useSelector(selectClientOptionsStatus);
    const clientOptionsRefreshed = useSelector(selectClientOptionsRefreshed);
    const [start, setStart] = useState(Moment(Moment(new Date()).add(-7, 'days')).format('YYYY-MM-DD'));
    const [end, setEnd] = useState(new Date());
    const [clientid, setClient] = useState("");
    const PayrollData = useSelector(selectPayrollRecords);
    const [loading, setLoading] = useState(false);


    let _clientOptions = undefined;

    const fileHeaders = ["FULL NAME", "DOB", "SSN", "START DATE", "PAY RATE", "CLIENT NAME", "LOCATION"];

    const ExportToExcel = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 30;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = " Payroll Dispatch Report";
        const headers = ["FULL NAME", "DOB", "START DATE", "PAY RATE", "CLIENT NAME", "LOCATION"];





        const data = PayrollData.map(elt => [elt.Name, elt.SSN, elt.MaritalStatus, elt.Dependents, elt.DOB, elt.Address, "$ " + elt.PayRates, elt.flexibility, elt.Location, elt.Client]);

        let content = {
            startY: 50,
            head: headers,
            body: data,
            theme: 'grid'
        };

        var str = 'Page ' + doc.internal.getNumberOfPages()
        doc.setFontSize(10)
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        doc.text(str, marginLeft, pageHeight - 10)
        doc.setFontSize(20)
        doc.text(title, marginLeft, 40);
        doc.setFontSize(15)
        doc.autoTable(content);
        doc.save("Payroll Dispatch Report.pdf")
    }
    useEffect(() => {
        if (clientOptionsRefreshed || clientOptionsStatus === fetchStatus.IDLE) {
            setLoading(true);
            dispatch(getClientOptionsAll({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETCLIENTOPTIONS",
                payload: {
                    id: "",
                    data: JSON.stringify({ All: "true" })
                }
            }));
        }
    }, [clientOptionsStatus, clientOptionsRefreshed]);


    useEffect(() => {
        if (isObjectArray(clientOptions)) {

            _clientOptions = clientOptions.map((o) => (
                <option value={o.value}>{o.text}</option>
            ));


            setClientOptionsValue(_clientOptions);
            setLoading(false);
        }
    }, [clientOptions]);


    // useEffect(() => {
    //     if (!pageLoaded) {
    //         dispatch(updateLayout({
    //             page: 9,
    //             title: "Active Employee Report"
    //         }));
    //         setPageLoaded(true);

    //     }
    // });



    const dispatch = useDispatch();
    const OnClickReport = async () => {
        if (start != undefined && clientid != '') {
            //&& end != undefined
            setLoading(true);
            await dispatch(getPayrollReport({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETACTIVECLIENTREPORT",
                payload: {
                    id: '',
                    data: JSON.stringify({
                        clientid: clientid,
                        StartDate: start,
                        EndDate: Moment(Moment(start).add(1, 'd').format('YYYY/MM/DD HH:mm:ss'))
                    })
                }
            }));
            setLoading(false);

        }
        else {
            alert('Please select filter')
        }

    };



    const OnExportClick = async () => {
        var data = PayrollData.map(elt => [elt.Name, _getDateText(elt.DOB), elt.SSN, toLocalDateOnly(elt.ActiveDate), "$ " + elt.PayRates, elt.ClientName, elt.ApplicantLocation]);
        const ClientHeader = ["Client Name : ", _getLocationTextAll(clientOptions, clientid), "", "", ""];
        const wb = XLSX.utils.book_new();
        data.sort((a, b) => a.Name - b.Name);
        var ws_data = [ClientHeader].concat([fileHeaders].concat(data));
        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(wb, ws, "Active Employee Report");

        XLSX.writeFile(wb, "Active Employee Report.xlsx");



    }

    const truncate = (str, n) => {
        return (str.length > n) ? str.substr(0, n - 1) + '.' : str;
    };


    const OnClickLastWeek = async () => {


        var curr = new Date; // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        first = first - 7
        var last = first + 6; // last day is the first day + 6

        var firstday = new Date(curr.setDate(first));
        var lastday = new Date(curr.setDate(last));
        setStart(firstday);
        setEnd(lastday);
        if (start != '' && end != '' && clientid != '') {
            // var dt = `Between: ${start} and ${end} at location ${clientid}`;
            // alert (dt);
            setLoading(true);
            await dispatch(getPayrollReport({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETACTIVECLIENTREPORT",
                payload: {
                    id: '',
                    data: JSON.stringify({
                        clientid: clientid,
                        StartDate: start,
                        EndDate: end
                    })
                }
            }));
            setLoading(false);

        }
        else {
            alert('Please select client')
        }
    };

    const payRatesFormatter = (field, data, column) => {
        return '$' + data["PayRates"];
    }

    return (
        <React.Fragment>
            {/* <ToolBar> */}
            <div className="d-flex">
                <div className="p-3">
                    <Selector

                        id="drpclients"
                        name="drpclients"
                        SelectorClasses="form-select"

                        options={
                            <React.Fragment>
                                {clientOptionsValue}
                            </React.Fragment>
                        }
                        onChange={(e) => setClient(e.target.value)} />
                </div>
                <div className="p-3">
                    <DateText
                        id="txtStartDate"
                        name="txtStartDate"
                        DateTextClasses="form-control"
                        placeholder="Start Date"
                        value={Moment(start).format('YYYY-MM-DD')}
                        selected={start}
                        onChange={(e) => setStart(e.target.value)} />
                </div>

                {isObjectArray(PayrollData) ?
                    <div className="p-3">
                        <button className="form-control" onClick={() => OnExportClick()}> <i className="fa fa-download mr-3" aria-hidden="true"></i></button>
                    </div>
                    : <React.Fragment></React.Fragment>}

                <div className="p-3 ms-auto">
                    <button type="button" className="c-btn dark-btn position-relative" onClick={() => OnClickReport()}>
                        <i className="fa fa-filter" aria-hidden="true"></i> Filter
                        <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    </button>
                </div>
            </div>


            {/* </ToolBar> */}
            {loading ?
                <Loader type="spinner-circle" bgColor={"#92DCE2"} title={""} color={'#92DCE2'} size={100} />
                : <React.Fragment></React.Fragment>}
            {isObjectArray(PayrollData) ? <span className="px-3 report-heading">Active Employee Report for  {_getLocationTextAll(clientOptions, clientid)}  </span> : <React.Fragment></React.Fragment>}

            {/* <Content>
                <div className="reporttable">
                    <table className="table table-hover">
                        <thead className="">
                            <tr>
                                <th scope="col">Full NAME</th>
                                <th scope="col">DOB</th>
                                <th scope="col">SSN</th>
                                <th scope="col">START DATE</th>
                                <th scope="col">PAY RATE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading ? <ActiveEmpDetail
                                details={PayrollData} /> : <React.Fragment></React.Fragment>}
                        </tbody>

                    </table>
                </div>
            </Content> */}
            <div className="row p-3">
                <GridComponent dataSource={PayrollData} allowPaging={false} allowSorting={true} allowResizing={true} toolbar={['Search']} >
                    <ColumnsDirective>
                        <ColumnDirective field='Name' headerText='Full Name' width='200' />
                        <ColumnDirective field='DOB' headerText='DOB' width='100' valueAccessor={(field, data, column) => _getDateText(data.DOB)} />
                        <ColumnDirective field='SSN' headerText='SSN' width='100' />
                        <ColumnDirective field='ActiveDate' headerText='Start Date' width='100' valueAccessor={(field, data, column) => toLocalDateOnly(data.ActiveDate)} />
                        <ColumnDirective field='PayRates' headerText='Pay Rate' width='100' valueAccessor={payRatesFormatter} />
                        <ColumnDirective field='ClientName' headerText='Client Name' width='100' />
                        <ColumnDirective field='ApplicantLocation' headerText='Location' width='100' />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                </GridComponent>
            </div>
        </React.Fragment>
    );
}

export default ActiveEmpReport;