import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../../api/client';
import { accountService } from '../../../features/accounts/services/accountService';
import { saveProspectStatus, selectProspectStatusChangeStatus } from '../../../features/crm/reducerSlices/prospectsSlice';
import CancelButton from '../custom/buttons/cancelButton';
import SaveButton from '../custom/buttons/saveButton';
import InputGroup from '../custom/inputGroup/inputGroup';
import EllipsisLoader from '../loaders/ellipsisLoader';
import { isFormValid } from '../services/formService';
import Statement from '../custom/textbox/statement';
import { closeModal, selectModalPopUpDetail } from './reducerSlices/prospectsStatusUpdateModelPopUpSlice';


function ProspectsStatusUpdateModalPopUp(props) {

  const dispatch = useDispatch();

  const PROSPECT_STATUS_FORM = "ProspectStatusForm";

  const noteStatus = useSelector(selectProspectStatusChangeStatus);
  const modalPopUpDetails = useSelector(selectModalPopUpDetail);

  const [lnfSubmitted, setLNFSubmitted] = useState(false);

  const [note, setNote] = useState("");

  useEffect(() => {
    if (noteStatus !== fetchStatus.LOADING) {
      setNote("");
      dispatch(closeModal());
    }
  }, [noteStatus]);

  const onCloseClicked = () => {
    setNote("");
    setLNFSubmitted(false);
    dispatch(closeModal());
  };

  const onSaveClicked = async (id) => {
    const user = accountService.getAuthenticatedUser();
    if (isFormValid(PROSPECT_STATUS_FORM)) {
      if (id) {
        await dispatch(saveProspectStatus({
          client: tenantId,
          serviceType: "TENANTADMIN",
          operation: "SAVEPROSPECTREJECT",
          payload: {
            id: id,
            data: JSON.stringify({ statusNote: note })
          }
        }));
      }
    }
    else {
      setLNFSubmitted(true);
    }
  };

  return (
    <>
      {(modalPopUpDetails && modalPopUpDetails.show) ? (<>
        <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header d-block">
                <div className="d-flex">
                  <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                  {noteStatus === fetchStatus.LOADING ? <></> : <button
                    type="button"
                    className="modal-close-button ms-auto"
                    onClick={onCloseClicked}>
                    <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                  </button>}
                </div>
                <div className="d-flex">
                  <span className="modal-sub-title pt-2 pe-4">Reject Lead</span>
                </div>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <InputGroup
                      icon={<i className="fa fa-pencil-square-o"></i>}
                      control={
                        <Statement
                          id="txtStatusNote"
                          name="txtStatusNote"
                          value={note}
                          StatementClasses="form-control"
                          onChange={(e) => setNote(e.target.value)}
                          required={true}
                          errorMessage="Please enter note"
                          form={PROSPECT_STATUS_FORM}
                          submited={lnfSubmitted}
                          placeholder="Enter note..." />
                      } />
                  </div>
                </div>

              </div>
              <div className="modal-footer">
                {noteStatus === fetchStatus.LOADING ? <EllipsisLoader /> : <><CancelButton
                  CancelButtonClasses="mr-2"
                  onCancelClicked={() => onCloseClicked()} />
                  <SaveButton
                    onSaveClicked={() => onSaveClicked(modalPopUpDetails.id)} /></>}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show">
        </div>
      </>) : <></>}
    </>
  );
}

export default ProspectsStatusUpdateModalPopUp;