export const jobStatusData = [
    {
        text: "Filled",
        value: "Filled"
    },
    {
        text: "Inactive",
        value: "Inactive"
    },
    {
        text: "In Progress",
        value: "Inprogress"
    },
    {
        text: "New",
        value: "New"
    }
];