import React, { useEffect, useState } from 'react';


function CheckBox(props) {
    const [checked, setChecked] = useState(props.checked ? props.checked : false);
    const [checkBoxSize, setCheckBoxSize] = useState("checkbox-sm");
    const [checkerSize, setCheckerSize] = useState("checker-sm");

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    useEffect(() => {
        if (props.size) {
            let size = props.size;
            if (size === "Small") {
                setCheckBoxSize("checkbox-sm");
                setCheckerSize("checker-sm");
            }
            else if (size === "Medium") {
                setCheckBoxSize("checkbox-md");
                setCheckerSize("checker-md");
            }
            else if (size === "Large") {
                setCheckBoxSize("checkbox-lg");
                setCheckerSize("checker-lg");
            }
        }
    }, [props.size])

    const onClick = (e) => {
        setChecked(!checked);
        let _e = { ...e };
        _e.target["name"] = props.name;
        props.onClick && props.onClick(!checked, _e);
    };

    return (
        <>
            <div className={`checkbox ${checkBoxSize} ${props.round ? "round" : ""} ${props.CheckBoxClasses ? props.CheckBoxClasses : ""}`} onClick={(e) => onClick(e)}>
                {checked && <span className={`checker ${checkerSize}`}><i className="fa fa-check" aria-hidden="true"></i></span>}
            </div>
            <input
                type="checkbox"
                id={props.id}
                name={props.name}
                value={props.value ? props.value : ""}
                className={`input-hide`}
                checked={checked}
                onClick={(e) => onClick(e)}/>
        </>
    );
}

export default CheckBox;