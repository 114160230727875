import React from 'react'
import ReactTooltip from 'react-tooltip';
import bagCheckImg from './../../assets/images/clipart/bag-check.svg';
import DialCallButton from '../Controls/buttons/dialCallButton';

function PeopleDetailSubCard(props) {
  return (
    <div className="people-detail-card-sub">
      <div className='d-flex justify-content-between'>
        <div className="people-detail-card-code">{props.code}</div>
        {/* <div className="fa fa-circle-check  " style={{ fontSize: '1.4rem', color: '#36BC57' }} /> */}
      </div>
      <div className='clearfix'></div>
      {/* <div className="people-detail-card-date">Request Date: {props.requestDate}</div> */}
      <div className="row py-3">
        <div className="col-2">
          <div className="people-detail-card-icon">
            <img src={bagCheckImg} className="w-100 p-1" />
          </div>
        </div>
        <div className="col-4">
          <div className="people-detail-card-label">Start Date</div>
          <div className="people-detail-card-text">{props.startDate}</div>
        </div>
        {/* <div className="col-5">
          <div className="people-detail-card-label">Start Time</div>
          <div className="people-detail-card-text">{props.startTime}</div>
        </div> */}
        <div className="col-5">
          <div className="people-detail-card-label">To Date</div>
          <div className="people-detail-card-text">{props.endDate}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <span className="people-detail-card-label-2">Job Position</span>
          <p className="text-primary">{props.jobPosition}</p>
        </div>

        <div className="col-6">
          <span className="people-detail-card-label-2">Job Type</span>
          <p className="text-primary">{props.jobType}</p>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-6">
          <span className="people-detail-card-label-2">Supervisor Name</span>
          <p className="text-primary">{props.reportTo}</p>
        </div>
        <div className="col-6">
          {props.clientPhone &&
            <>
              <span className="people-detail-card-label-2">Company Phone Number</span>
              <div className='d-flex align-items-center'>
                <DialCallButton
                  dialCallButtonClasses="pe-2" />
                <p className="text-primary">{props.clientPhone}</p>
              </div>
            </>
          }
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <span className="people-detail-card-badge people-detail-card-badge-pink">$ {props.payRates}</span>
        </div>
        <div className="col-6">
          {props.whyItEnded &&
            <>
              <i className="fa-solid fa-person-walking-dashed-line-arrow-right icon-btn" aria-hidden="true" data-tip={props.whyItEnded}></i>
              <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
            </>
          }
          {props.DNRStatus && props.DNRStatus ?
            <span className="people-detail-card-badge people-detail-card-badge-pink ms-3">DNR</span>
            : <></>
          }
        </div>
      </div>
    </div >
  )
}

export default PeopleDetailSubCard