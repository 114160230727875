import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import cardViewImg from './../../assets/images/clipart/card-view.svg';
import cardViewLightImg from './../../assets/images/clipart/card-view-light.svg';
import listViewImg from './../../assets/images/clipart/list-view.svg';
import listViewLightImg from './../../assets/images/clipart/list-view-light.svg';


import ProspectsPeopleCard from "../../component/Card/ProspectsPeopleCard";
import ProspectsPeopleHorizontalCard from "../../component/Card/ProspectsPeopleHorizontalCard";
import ProspectsPeopleMoreFilters from "../../component/Filters/ProspectsPeopleMoreFilters";

import { updateLayout } from "../../component/layouts/reducerSlices/layoutSlice";
import Collection from "../../component/Controls/paginators/collection";
import { getAllApplicants, selectAllApplicantRecords, selectConvertToPeopleStatus, selectCurrentPage, selectRemovalStatus, selectStatus, selectTotalItems, updateConvertToPeopleStatus, updateCurrentPage, updateIsFiltered, updateRecentClickedApplicant, updateRemovalStatus, updateSingleData, updateStatus } from "./reducerSlices/prospectsPeopleSlice";
import { tenantId, fetchStatus } from "../../api/client";
import { accountService } from "../accounts/services/accountService";
import { selectCalledOnFilter, selectDefaultFilter, selectEmploymentTypeFilter, selectFilterId, selectPositionFilter, selectProfileProcessStatusFilter, selectRefreshData, selectShiftFilter, selectTotalMoreFilterCount, selectWhereClause, selectWhereClauseChanged, selectWorkStatusFilter, updateFilter, updateLocationFilter, updateNameFilter, updateRefreshData } from "../../component/Controls/filters/reducerSlices/prospectsPeopleFilterSlice";
import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from "../locations/reducerSlices/locationSlice";

import { selectApplicantSorterDetail, selectSort } from '../../component/Controls/dataSorters/reducerSlices/prospectsPeopleSorterSlice';
import { toLocalDate, _getDateText, _getLocationText, _getNoDataText, _getTextFromValueList } from "../../common/common";
import { isObjectArray } from "../../utilities/utilityFunctions";
import { LanguageData } from "../applicant/services/masterData";
import LocationFilter from "../../component/Controls/filters/locationFilter";
import { selectLocationFilter } from "../../component/Controls/filters/reducerSlices/prospectsPeopleFilterSlice";
import { onCalledOnChanged, onEmploymentTypeChanged, onLocationChanged, onPositionChanged, onProfileProcessStatusChanged, onShiftChanged, onWorkStatusChanged } from "./services/prospectsPeopleFilterHandlers";
import ShiftFilter from "../../component/Controls/filters/shiftFilter";
import PositionFilter from "../../component/Controls/filters/positionFilter";
import WorkStatusFilter from "../../component/Controls/filters/workStatusFilter";
import EmploymentTypeFilter from "../../component/Controls/filters/employmentTypeFilter";
import CalledOnFilter from "../../component/Controls/filters/callOnFilter";
import { getUserFilters, removeFilter, removeUserFilter, selectDefaultFilters, selectRemoveFilterId, selectRemoveFilterStatus, selectUserFilters, selectUserFiltersStatus } from "../reviewer/reducerSlices/reviewerSlice";
import EllipsisLoader from "../../component/Controls/loaders/ellipsisLoader";
import { FilterType } from "../../common/constants";
import { showSFModal } from "../../component/Controls/modals/reducerSlices/saveFilterModalPopUpSlice";
import PageContainer from "../../component/Controls/paginators/paginator/pageContainer";
import ProspectsPeopleSorter from "../../component/Controls/dataSorters/prospectsPeopleSorter";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import ProfileProcessStatusFilter from "../../component/Controls/filters/profileProcessStatusFilter";
import SearchBar from "../../component/Controls/searchBars/searchbar";

import boy1 from '../../assets/images/blur-photos/boys/1.png';
import boy2 from '../../assets/images/blur-photos/boys/2.png';
import boy3 from '../../assets/images/blur-photos/boys/3.png';
import boy4 from '../../assets/images/blur-photos/boys/4.png';
import boy5 from '../../assets/images/blur-photos/boys/5.png';
import boy6 from '../../assets/images/blur-photos/boys/6.png';
import boy7 from '../../assets/images/blur-photos/boys/7.png';
import boy8 from '../../assets/images/blur-photos/boys/8.png';

// import boy2 from '../../assets/images/blur-photos/boys/2.png';
// import boy3 from '../../assets/images/blur-photos/boys/3.png';
// import boy4 from '../../assets/images/blur-photos/boys/4.png';
// import boy5 from '../../assets/images/blur-photos/boys/5.png';
// import boy6 from '../../assets/images/blur-photos/boys/6.png';
// import boy7 from '../../assets/images/blur-photos/boys/7.png';
// import boy8 from '../../assets/images/blur-photos/boys/8.png';
// import boy9 from '../../assets/images/blur-photos/boys/9.png';
// import boy10 from '../../assets/images/blur-photos/boys/10.png';
// import boy11 from '../../assets/images/blur-photos/boys/11.png';
// import boy12 from '../../assets/images/blur-photos/boys/12.png';

import girl1 from '../../assets/images/blur-photos/girls/1.png';
import girl2 from '../../assets/images/blur-photos/girls/2.png';
import girl3 from '../../assets/images/blur-photos/girls/3.png';
import girl4 from '../../assets/images/blur-photos/girls/4.png';
import girl5 from '../../assets/images/blur-photos/girls/5.png';
import { getPositionOptions, selectPositionOptions, selectPositionOptionsStatus } from "../setting/reducerSlices/positionsSlice";
import { getStatusOptionsAll, selectStatusOptions, selectStatusOptionsStatus } from "../setting/reducerSlices/statusesSlice";




function ProspectsPeople() {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = accountService.getAuthenticatedUser();

  const [applyMoreFilters, setApplyMoreFilters] = useState(false);
  const [uploadDownloadDocumentsFlyout, setUploadDownloadDocumentsFlyout] = useState(false);
  const [addNoteFlyout, setAddNoteFlyout] = useState(false);

  const [peopleListView, setPeopleListView] = useState(false);

  const [applicantsLoaded, setApplicantsLoaded] = useState(false);

  const [pageLoaded, setPageLoaded] = useState(false);

  const currentPage = useSelector(selectCurrentPage);

  const totalPageItems = useSelector(selectTotalItems);
  const applicants = useSelector(selectAllApplicantRecords);
  const applicantsRequestStatus = useSelector(selectStatus);


  const locationOptions = useSelector(selectLocationOptions);
  const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
  const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);

  const sort = useSelector(selectSort);


  const whereClause = useSelector(selectWhereClause);
  const whereClauseChanged = useSelector(selectWhereClauseChanged);
  const defaultFilter = useSelector(selectDefaultFilter);
  const refreshData = useSelector(selectRefreshData);

  const [appRecords, setAppRecords] = useState(new Collection());
  const [appRecordsHorizontalCard, setAppRecordsHorizontalCard] = useState(new Collection());
  const [dummyCards, setDummyCards] = useState([]);
  const [dummyHorizontalCards, setDummyHorizontalCards] = useState([]);

  const [people_Data, setPeople_Data] = useState('');
  const [people_DataHorizontalCard, setPeople_DataHorizontalCard] = useState('');

  const totalMoreFilterCount = useSelector(selectTotalMoreFilterCount);

  const userFiltersStatus = useSelector(selectUserFiltersStatus);
  const [customFilters, setCustomFilters] = useState([]);
  const _filterId = useSelector(selectFilterId);
  const [filterId, setFilterId] = useState("");
  const userFilters = useSelector(selectUserFilters);
  const defaultFilters = useSelector(selectDefaultFilters);

  const removeFilterStatus = useSelector(selectRemoveFilterStatus);
  const removeFilterId = useSelector(selectRemoveFilterId);

  const [isFiltered, setIsFiltered] = useState(false);

  const applicantRemovalStatus = useSelector(selectRemovalStatus);
  const [searchTerm, setSearchTerm] = useState("");

  const convertToPeopleStatus = useSelector(selectConvertToPeopleStatus);

  const statusOptions = useSelector(selectStatusOptions);
  const statusOptionsStatus = useSelector(selectStatusOptionsStatus);

  const positionOptions = useSelector(selectPositionOptions);
  const positionOptionsStatus = useSelector(selectPositionOptionsStatus);

  useEffect(() => {
    if (statusOptionsStatus === fetchStatus.IDLE) {
      dispatch(getStatusOptionsAll({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSTATUSOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [statusOptionsStatus]);

  useEffect(() => {
    if (positionOptionsStatus === fetchStatus.IDLE) {
      dispatch(getPositionOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETPOSITIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [positionOptionsStatus]);

  const getRandomPhotoByGender = (gender) => {
    let photos;

    if (gender === 'Male') {
      photos = [boy1, boy2, boy3, boy4, boy5, boy6, boy7, boy8];
    } else if (gender === 'Female') {
      photos = [girl1, girl2, girl3, girl4, girl5];
    } else {
      return boy1;
    }

    const randomIndex = Math.floor(Math.random() * photos.length);
    return photos[randomIndex];
  };

  const getRandomColor = () => {
    let color = ['#F8E3FB', '#DAD1FB', '#D1D5FB', '#FFE6CE', '#FBE3E3', '#E1E1E1', '#D9F3D0', '#FFD4D4', '#C2E2FF', '#C8ECFC', '#E1CAFF', '#FFE8A3', '#FECAD6',
      '#B2EBF2', '#FFCCBC', '#D7CCC8', '#96dbb1', '#C8E6C9', '#B2DFDB', '#E1BEE7', '#FFAB91', '#90CAF9', '#FFCCBC', '#FFD180', '#ffc1ad', '#EA80FC',
      '#F8E3FB', '#DAD1FB', '#D1D5FB', '#FFE6CE', '#FBE3E3', '#E1E1E1', '#D9F3D0', '#C2E2FF', '#C8ECFC', '#FFE8A3', '#FECAD6', '#B2EBF2', '#D7CCC8', '#C8E6C9',
      '#B2DFDB', '#E1BEE7', '#90CAF9', '#FFD180', '#E0F7FA', '#B3E5FC', '#81D4FA', '#c7c78f', '#96d4ef'];
    const randomIndex = Math.floor(Math.random() * color.length);
    return color[randomIndex];
  }


  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 18,
        title: "Prospects"
      }));
      setPageLoaded(true);
    }
  });

  useEffect(() => {
    if (applicantsRequestStatus === fetchStatus.IDLE) {
      // let loc = user ? user.locationId : "";
      let where = JSON.parse(JSON.stringify(whereClause));
      // where.location.value.push(loc);
      // dispatch(updateLocationFilter({
      //   locations: locationOptions,
      //   value: loc
      // }));
      dispatch(updateIsFiltered());
      _loadApplicants(0, 0, 10, where, sort);
      setApplicantsLoaded(true);
    }
  }, [applicantsRequestStatus, dispatch, applicantsLoaded]);

  const _loadApplicants = async (page, offset, limit, whereClause, sort) => {
    setIsFiltered(false);
    await dispatch(getAllApplicants({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTUSER",
        operation: "GETPROSPECTSPEOPLE",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: offset,
            limit: limit,
            where: whereClause,
            sort: sort
          })
        }
      }
    }));

  }

  useEffect(() => {
    if (refreshData) {
      dispatch(updateIsFiltered());
      _loadApplicants(0, 0, 10, whereClause, sort);
      dispatch(updateRefreshData(false));
    }
  }, [whereClauseChanged, whereClause, refreshData]);

  useEffect(() => {
    if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
      dispatch(getLocationOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLOCATIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({})
        }
      }));
    }
  }, [locationOptionsStatus, locationOptionsRefreshed]);

  const _getLanguageText = (idList) => {
    if (isObjectArray(idList)) {
      let languages = "";
      for (let i = 0; i < idList.length; i++) {
        let language = LanguageData.find(p => p.value === idList[i]);
        if (language && language.text) {
          languages += language.text + ", ";
        }
      }
      languages = languages.trim();
      languages = languages.slice(0, -1);
      return languages;
    }
    return "";
  }

  let applicantCards;
  let _dummyCards = [];
  let _dummyHorizontalCards = [];

  let _appRecords = new Collection();

  let applicantCardsHorizontalCard;
  let _appRecordsHorizontalCard = new Collection();

  useEffect(() => {
    for (let i = 0; i < 10; i++) {
      _dummyCards.push(
        <div className="people-card">
          <div className="people-card-body p-2">
            <ReactPlaceholder showLoadingAnimation={true} type='media' ready={false} rows={3}>
            </ReactPlaceholder>
          </div>
        </div>
      );

      _dummyHorizontalCards.push(
        <div className="people-horizontal-card">
          <div className="people-horizontal-card-body p-2">
            <ReactPlaceholder showLoadingAnimation={true} type='media' ready={false} rows={3}>
            </ReactPlaceholder>
          </div>
        </div>
      );
    }
    setDummyCards(_dummyCards);
    setDummyHorizontalCards(_dummyHorizontalCards);
  }, [])

  useEffect(() => {
    if (applicantsRequestStatus === fetchStatus.SUCCEEDED || applicantsRequestStatus === fetchStatus.DONE) {
      if (applicants.Items && applicants.Items.length > 0) {
        for (let i = 0; i < applicants.Items.length; i++) {
          let item = applicants.Items[i];
          applicantCards = item.records.map((applicant, index) => (
            <ProspectsPeopleCard
              dob={''}
              address={'1'}
              lastApplied={'1'}
              key={applicant.id}
              id={applicant.id}
              // profilePhoto={applicant.ImageData}
              profilePhoto={getRandomPhotoByGender(applicant.Gender)}
              profileColor={getRandomColor()}
              fullName={applicant.FirstName + " " + applicant.LastName}
              applicationDate={toLocalDate(applicant.ApplicationDate)}
              email={applicant.Email}
              phone={applicant.PhoneNumber}
              location={_getLocationText(locationOptions, applicant.Location)}
              status={applicant.Status}
              statusText={applicant.Status}
              isOpened={applicant.IsOpened}
              // dateOfBirth={_getDateText(applicant.DateOfBirth)}
              dateOfBirth={applicant.DateOfBirth}
              skill={applicant.Position ? _getTextFromValueList(positionOptions, applicant.Position) : _getNoDataText()}
              gender={applicant.Gender}
              onClick={(e) => onClickApplicant(e, applicant.id)}
            />
          ));
          _appRecords.Add(item.key, applicantCards);


          applicantCardsHorizontalCard = item.records.map((applicant, index) => (
            <ProspectsPeopleHorizontalCard
              skill={applicant.Position ? _getTextFromValueList(positionOptions, applicant.Position) : _getNoDataText()}
              lastCalledIn={applicant.LastCalledIn ? toLocalDate(applicant.LastCalledIn) : _getNoDataText()}
              lastCalledOut={applicant.LastCalled ? toLocalDate(applicant.LastCalled) : _getNoDataText()}
              language={applicant.Language ? _getLanguageText(applicant.Language) : _getNoDataText()}
              cumulativeActiveDays={'12'}
              lastStatus={'All documents and data verify, 10/05/2022, 22:46:31.'}
              profilePhoto={getRandomPhotoByGender(applicant.Gender)}
              profileColor={getRandomColor()}
              key={applicant.id}
              id={applicant.id}
              // profilePhoto={applicant.ImageData}
              fullName={applicant.FirstName + " " + applicant.LastName}
              applicationDate={toLocalDate(applicant.ApplicationDate)}
              email={applicant.Email}
              phone={applicant.PhoneNumber}
              location={_getLocationText(locationOptions, applicant.Location)}
              status={applicant.Status}
              statusText={applicant.Status}
              isOpened={applicant.IsOpened}
              // dateOfBirth={_getDateText(applicant.DateOfBirth)}
              dateOfBirth={applicant.DateOfBirth}
              gender={applicant.Gender}
              onClick={(e) => onClickApplicant(e, applicant.id)}
            />
          ));
          _appRecordsHorizontalCard.Add(item.key, applicantCardsHorizontalCard);
        }
        setAppRecords(_appRecords);
        setAppRecordsHorizontalCard(_appRecordsHorizontalCard);
        dispatch(updateStatus());
      }
      dispatch(updateStatus());
    }
  }, [applicantsRequestStatus, currentPage]);


  const onClickApplicant = (e, applicant) => {
    dispatch(updateSingleData(undefined));
    dispatch(updateRecentClickedApplicant(applicant));
    history.push('prospectsPeople/details')
  }


  const onFilterClicked = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    dispatch(updateFilter({
      id: "",
      name: "",
      whereClause: whereClause,
      defaultFilter: false
    }));
    _loadApplicants(0, 0, 10, whereClause, sort);
  };

  const onSaveFilterClicked = async (e) => {
    e.preventDefault();
    if (whereClauseChanged && !defaultFilter) {
      const user = accountService.getAuthenticatedUser();
      if (user) {
        await dispatch(showSFModal({
          title: <><span>Save Filter For</span> <span className="modal-header-name">{user ? user.firstName + " " + user.lastName : "N/A"}</span></>,
          userId: user.id
        }));
      }
    }
  };

  const onCustomFiterClicked = (e, id, defaultFilter) => {
    e.preventDefault();
    let filter;
    if (defaultFilter) {
      const locationId = accountService.getLoggedInUserLocationId();
      filter = defaultFilters.find(f => f.id === id);
      filter = JSON.parse(JSON.stringify(filter));
      filter.whereClause.location.value = [locationId];
      filter.whereClause.location.text = "(1) selected";
    }
    else {
      filter = userFilters.find(f => f.id === id);
    }
    dispatch(updateFilter({
      id: filter ? filter.id : "",
      name: filter ? filter.name : "",
      whereClause: filter ? filter.whereClause : undefined,
      defaultFilter: defaultFilter
    }));
    dispatch(updateRefreshData(true));
  };

  const onFiterDeleteClicked = (e, id, filterType) => {
    e.stopPropagation();
    if (id) {
      dispatch(removeFilter({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "REMOVEFILTER",
        payload: {
          id: id,
          data: ""
        }
      }));
    }
  };

  useEffect(() => {
    setFilterId(_filterId);
  });

  useEffect(() => {
    if (removeFilterStatus === fetchStatus.SUCCEEDED) {
      if (removeFilterId) {
        dispatch(removeUserFilter(removeFilterId));
      }
    }
  }, [removeFilterStatus, removeFilterId]);

  useEffect(() => {
    let _customFilters = [], _customUserFilters = [], _customClientFilters = [], _customJobFilters = [];
    if (userFiltersStatus === fetchStatus.SUCCEEDED) {
      if (userFilters && Array.isArray(userFilters) && userFilters.length > 0) {
        for (let i = 0; i < userFilters.length; i++) {
          let filter = userFilters[i];
          if (filter.filterType === FilterType.APPLICANT) {
            _customFilters.push(
              <div className="btn-group mx-1" role="group" aria-label="First group">
                <button type="button" className={`c-btn ${filterId === filter.id ? "" : "c-btn-gray"}`} onClick={(e) => onCustomFiterClicked(e, filter.id, false)}>{filter.name}</button>
                <button type="button" className={`c-btn c-btn-transparent`} onClick={(e) => onFiterDeleteClicked(e, filter.id, FilterType.APPLICANT)} title="Remove filter"><i className="fa fa-trash text-danger" aria-hidden="true"></i></button>
              </div>
            );
          }

        }
        setCustomFilters(_customFilters);
      }
      else {
        setCustomFilters(undefined);
      }
    }

  }, [userFiltersStatus, userFilters, filterId]);

  useEffect(() => {
    const id = accountService.getLoggedInUserId();
    if (id) {
      if (userFiltersStatus === fetchStatus.IDLE) {
        dispatch(getUserFilters({
          client: tenantId,
          serviceType: "TENANTADMIN",
          operation: "GETUSERFILTERS",
          payload: {
            id: id,
            data: ""
          }
        }));
      }
    }
  }, [userFiltersStatus]);

  const onPageChanged = async (p, l) => {
    dispatch(updateCurrentPage(p));
    if (l) {
      await onLoadItems(p);
    }
  };

  const onLoadItems = async (p) => {
    await _loadApplicants(p, p * 10, 10, whereClause, sort);
  };

  const onSorted = async (sortBy, ascending) => {
    await dispatch(updateIsFiltered());
    _loadApplicants(0, 0, 10, whereClause, {
      by: sortBy,
      ascending: ascending
    });
  };

  useEffect(() => {
    if (applicantRemovalStatus === fetchStatus.SUCCEEDED) {
      if (applicants && applicants.Items.length > 0) {
        dispatch(updateSingleData(undefined));
      }
      else {
        setAppRecords(undefined);
        dispatch(updateSingleData(undefined));
      }
      dispatch(updateRemovalStatus());
    }
  }, [applicantRemovalStatus]);


  const onSearchClicked = async (e, value) => {
    e.preventDefault();
    if (value) {
      let where = JSON.parse(JSON.stringify(whereClause));
      where.name = value;
      await dispatch(updateNameFilter(value));
      await dispatch(updateIsFiltered());
      _loadApplicants(0, 0, 10, where, sort);
    }
  };

  const onSearchCleared = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    let where = JSON.parse(JSON.stringify(whereClause));
    where.name = "";
    await dispatch(updateNameFilter(""));
    _loadApplicants(0, 0, 10, where, sort);
  };

  useEffect(() => {
    if (convertToPeopleStatus === fetchStatus.SUCCEEDED) {
      if (applicants && applicants.Items.length > 0) {
        dispatch(updateSingleData(undefined));
      }
      else {
        setAppRecords(undefined);
        dispatch(updateSingleData(undefined));
      }

      // let loc = user ? user.locationId : "";
      let where = JSON.parse(JSON.stringify(whereClause));
      // where.location.value.push(loc);
      // dispatch(updateLocationFilter({
      //   locations: locationOptions,
      //   value: loc
      // }));
      dispatch(updateIsFiltered());
      _loadApplicants(0, 0, 10, where, sort);

      dispatch(updateConvertToPeopleStatus());
    }
  }, [convertToPeopleStatus]);


  return (
    <>
      {/* Filter Bar */}
      <div className="filter-bar p-3">
        {/* <button className={`c-btn mx-1 ${filterId === "3343FE89-9898-4CB0-9369-13AC9AD5D40B" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "3343FE89-9898-4CB0-9369-13AC9AD5D40B", true)}>In Progress</button>
        <button className={`c-btn mx-1 ${filterId === "1C634E95-61BB-4831-9983-873743395DE6" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "1C634E95-61BB-4831-9983-873743395DE6", true)}>Available</button>
        <button className={`c-btn mx-1 ${filterId === "ACFDF03E-D97D-4F47-9258-156C420E7F6D" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "ACFDF03E-D97D-4F47-9258-156C420E7F6D", true)}>Dispatched</button>
        <button className={`c-btn mx-1 ${filterId === "5305FC65-B4C4-4C59-9E0D-8405646B1BE6" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "5305FC65-B4C4-4C59-9E0D-8405646B1BE6", true)}>Active</button>
        <button className={`c-btn mx-1 ${filterId === "55CEF3E8-6F0E-4696-ABDF-3929F71923D9" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "55CEF3E8-6F0E-4696-ABDF-3929F71923D9", true)}>In Active</button>
        <button className={`c-btn mx-1 ${filterId === "2D3C1DD4-00CF-4A06-A3A5-6600C53644F5" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "2D3C1DD4-00CF-4A06-A3A5-6600C53644F5", true)}>DNU</button>
        {removeFilterStatus === fetchStatus.LOADING ? <span className="px-1 m-0"><EllipsisLoader /></span> : customFilters}
        {<button className="c-btn c-btn-transparent" type="button" onClick={(e) => onSaveFilterClicked(e)} disabled={!(whereClauseChanged && !defaultFilter)}>+ Create New List</button>} */}

        <div className="float-end d-flex p-3">
          <SearchBar
            id="txtSearch"
            name="txtSearch"
            searchTerm={searchTerm}
            onSearch={(e) => setSearchTerm(e.target.value)}
            onClear={(e, value) => onSearchCleared(e)}
            onSearchClicked={(e, value) => onSearchClicked(e, value)} />
          {isFiltered &&
            <button type="button" className="c-btn dark-btn position-relative ms-3" onClick={(e) => onFilterClicked(e)}>
              <i className="fa fa-search me-2" aria-hidden="true"></i>Search
            </button>
          }
          <button type="button" className="c-btn white-btn position-relative ms-3" onClick={() => setApplyMoreFilters(true)}>
            <i className="fa fa-filter me-2" aria-hidden="true"></i>Filters
            <span className={`position-absolute top-0 start-100 translate-middle ${totalMoreFilterCount > 0 ? 'p-1' : 'p-0'} bg-danger border border-light badge bg-danger`}>
              <span className="">{totalMoreFilterCount > 0 ? totalMoreFilterCount : ''}</span>
            </span>
          </button>
        </div>
      </div>

      <div className="filter-bar p-3 pt-0">
        {/* <LocationFilter
          locations={locationOptions}
          locationFilterSelector={selectLocationFilter}
          onLocationChanged={(e, v, locations) => { onLocationChanged(e, v, locations); setIsFiltered(true); }}
        /> */}
        <ShiftFilter shiftFilterSelector={selectShiftFilter} onShiftChanged={(e, v, shifts) => { onShiftChanged(e, v, shifts); setIsFiltered(true); }} />
        <PositionFilter positions={positionOptions} positionFilterSelector={selectPositionFilter} onPositionChanged={(e, v, positions) => { onPositionChanged(e, v, positions); setIsFiltered(true); }} />
        <WorkStatusFilter
          statuses={statusOptions && statusOptions.filter(d => d.StatusOfStatus === 'Active')}
          workStatusFilterSelector={selectWorkStatusFilter} onWorkStatusChanged={(e, v, workStatuses) => { onWorkStatusChanged(e, v, workStatuses); setIsFiltered(true); }} />
        <EmploymentTypeFilter employmentTypeFilterSelector={selectEmploymentTypeFilter} onEmploymentTypeChanged={(e, v, employmentTypes) => { onEmploymentTypeChanged(e, v, employmentTypes); setIsFiltered(true); }} />
        {/* <CalledOnFilter calledOnFilterSelector={selectCalledOnFilter} onCalledOnChanged={(e, c) => { onCalledOnChanged(e, c); setIsFiltered(true); }} /> */}
        {/* <ProfileProcessStatusFilter profileProcessStatusFilterSelector={selectProfileProcessStatusFilter} onProfileProcessStatusChanged={(e, c) => { onProfileProcessStatusChanged(e, c); setIsFiltered(true); }} /> */}

        <div className="float-end">
          <div className="sort-btn-group">
            <button className={`c-btn sort-btn-transparent ${peopleListView && 'sort-btn-active'}`} type="button" onClick={() => setPeopleListView(true)} ><img src={peopleListView ? listViewImg : listViewLightImg} /></button>
            <button className={`c-btn sort-btn-transparent ${!peopleListView && 'sort-btn-active'}`} type="button" onClick={() => setPeopleListView(false)}><img src={peopleListView ? cardViewLightImg : cardViewImg} /></button>
          </div>
        </div>

      </div>



      <div className="right-content">
        <div className="d-flex w-100 p-3 pb-0">
          <div className="align-self-center">
            <div className="job-order-result-text">Showing {totalPageItems} Results <span className="job-order-result-sub-text">(Based your search)</span> </div>
          </div>
          <div className="ms-auto align-self-center">
            <div className="d-flex">
              <ProspectsPeopleSorter
                onSorted={(s, a) => onSorted(s, a)}
              />
            </div>
          </div>
        </div>
      </div>

      <PageContainer
        currentPage={currentPage}
        totalPageItems={totalPageItems}
        pageRecords={!peopleListView ? appRecords : appRecordsHorizontalCard}
        isItemsLoading={applicantsRequestStatus}
        itemsLoadingContent={!peopleListView ? dummyCards : dummyHorizontalCards}
        onPageChanged={(p, l) => onPageChanged(p, l)}
        infinite={true}
        pageRecordsCardClassName={!peopleListView ? 'people-card' : 'people-horizontal-card'}
      />

      {
        applyMoreFilters ?
          <ProspectsPeopleMoreFilters
            onClose={() => setApplyMoreFilters(false)}
          />
          :
          ''
      }

    </>
  )
}

export default ProspectsPeople