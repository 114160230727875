import {
    createSlice,
    createAsyncThunk,
    nanoid
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import Collection from '../../../component/Controls/paginators/collection';
import { refreshCreated } from '../../../component/Controls/paginators/services/createdResource';
import { refreshRemoved } from '../../../component/Controls/paginators/services/removedResource';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    form: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    dispatchDetail: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    dispatchList: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    dispatchCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    removal: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    },
    userOptionsAll:{
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    show: false,
    createMode: false,
    editMode: false,
};

export const getAllUsers = createAsyncThunk('users/getAllUsers', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', userModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let users;
        if (isJSON(data)) {
            users = JSON.parse(data);
        }
        return {
            page: userModel.page,
            users: users,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getDashbaordUserOptionsAll = createAsyncThunk('users/getDashbaordUserOptionsAll', async (userModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', userModel.model, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let options;
    if (isJSON(data)) {
        options = JSON.parse(data);
    }
    return {
        options: options,
        success: response.data.success
    };
});

export const getUserById = createAsyncThunk('users/getUserById', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, userModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let user = undefined;
        if (data && isJSON(data)) {
            user = JSON.parse(data);
        }
        return {
            page: userModel.page,
            user: user,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getDispatchById = createAsyncThunk('users/getDispatchById', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, userModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let dispatchDetail = undefined;
        if (data && isJSON(data)) {
            dispatchDetail = JSON.parse(data);
        }
        return {
            dispatchDetail: dispatchDetail,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getDispatchListById = createAsyncThunk('users/getDispatchListById', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, userModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let dispatchList = undefined;
        if (data && isJSON(data)) {
            dispatchList = JSON.parse(data);
        }
        return {
            dispatchList: dispatchList,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getDispatchCountById = createAsyncThunk('users/getDispatchCountById', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, userModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let dispatchCount = undefined;
        if (data && isJSON(data)) {
            dispatchCount = JSON.parse(data);
        }
        return {
            dispatchCount: dispatchCount,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getUserForm = createAsyncThunk('users/getUserForm', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, userModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let form = undefined;
        if (data && isJSON(data)) {
            form = JSON.parse(data);
        }
        return {
            form: form,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const createUser = createAsyncThunk('users/createUser', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', userModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let user = undefined;
        if (data && isJSON(data)) {
            user = JSON.parse(data);
        }
        return {
            user: user,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const updateUser = createAsyncThunk('users/updateUser', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, userModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let user = undefined;
        if (data && isJSON(data)) {
            user = JSON.parse(data);
        }
        return {
            user: user,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const removeUser = createAsyncThunk('users/removeUser', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, userModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        return {
            id: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },

        updateDispatchDetailStatus: (state) => {
            state.dispatchDetail.status = fetchStatus.IDLE;
        },

        updateDispatchListStatus: (state) => {
            state.dispatchList.status = fetchStatus.IDLE;
        },
        
        updateDispatchCountStatus: (state) => {
            state.dispatchCount.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateRemovalStatus: (state) => {
            state.removal.status = fetchStatus.IDLE;
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        loadDispatchdetailData: (state, _data) => {
            state.dispatchDetail.data = Object.assign({}, _data.payload);
        },
        loadDispatchListData: (state, _data) => {
            state.dispatchList.data = Object.assign({}, _data.payload);
        },
        loadDispatchCountData: (state, _data) => {
            state.dispatchCount.data = Object.assign({}, _data.payload);
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateSingleData: (state, _data) => {
            state.single.refreshed = nanoid();
            state.single.data = _data.payload ? Object.assign({}, _data.payload) : _data.payload;
            // if (_data.payload) {
            //     let _item = state.data.records.Get(state.data.currentPage);
            //     if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
            //         let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.id);
            //         let newRecords = _item.records;
            //         let _user = {
            //             id: _data.payload.id,
            //             FirstName: _data.payload.FirstName.Data,
            //             LastName: _data.payload.LastName.Data,
            //             Email: _data.payload.EmailAddress.Data,
            //             PhoneNumber: _data.payload.PhoneNumber.Data,
            //             Location: _data.payload.Location.Data,
            //             Status: _data.payload.Status.Data,
            //             ImageData: _data.payload.PhotoPath.Data
            //         };
            //         newRecords[_updatedObjectIndex] = _user;
            //         _item.records = newRecords;
            //         let records = new Collection();
            //         records.Add(_item.key, newRecords);
            //         state.data.records.Remove(_item.key);
            //         records.Concat(state.data.records);
            //         state.data.records = records;
            //     }
            // }
        },
        updateDispatchDetailData: (state, _data) => {
            state.dispatchDetail.refreshed = nanoid();
            state.dispatchDetail.data = _data.payload ? Object.assign({}, _data.payload) : _data.payload;
            
        },
        updateDispatchListData: (state, _data) => {
            state.dispatchList.refreshed = nanoid();
            state.dispatchList.data = _data.payload ? Object.assign({}, _data.payload) : _data.payload;
            
        },
        updateDispatchCountData: (state, _data) => {
            state.dispatchCount.refreshed = nanoid();
            state.dispatchCount.data = _data.payload ? Object.assign({}, _data.payload) : _data.payload;
            
        },
        createNewUser: state => {
            state.single.data = {
                "FirstName": {
                    "Id": "txtFirstName",
                    "Data": ""
                },
                "LastName": {
                    "Id": "txtLastName",
                    "Data": ""
                },
                "PhoneNumber": {
                    "Id": "txtPhoneNumber",
                    "Data": ""
                },
                "EmailAddress": {
                    "Id": "txtEmailAddress",
                    "Data": ""
                },
                "Role": {
                    "Id": "drpRoles",
                    "Data": ""
                },
                "Status": {
                    "Id": "drpStatuses",
                    "Data": ""
                },
                "Location": {
                    "Id": "drpLocations",
                    "Data": ""
                },
                "Password": {
                    "Id": "drpLocations",
                    "Data": ""
                },
                "PhotoPath": {
                    "Id": "fuUserPhoto",
                    "Data": ""
                }
            };
        },
        refreshUserOptionsAll: (state) => {
            state.userOptionsAll.refreshed = true;
        },
        showUserFlyout: (state, action) => {
            const { createMode, editMode } = action.payload;
            state.createMode = createMode;
            state.editMode = editMode;
            state.show = true;
        },
        closeUserFlyout: (state, action) => {
            state.createMode = false;
            state.editMode = false;
            state.show = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllUsers.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        })
        // .addCase(getAllUsers.fulfilled, (state, action) => {
        //     state.data.totalItems = action.payload.users.totalItems;
        //     let _records = new Collection();
        //     _records.Add(action.payload.page, action.payload.users.items);
        //     if (!state.data.isFiltered) {
        //         _records.Concat(state.data.records);
        //     }
        //     else {
        //         state.data.firstRecords = new Collection();
        //         state.data.currentPage = 0;
        //     }
        //     state.data.isFiltered = false;
        //     state.data.records = _records;
        //     state.data.status = fetchStatus.SUCCEEDED;
        //     toaster.success(action.payload.success);
        // })
        .addCase(getAllUsers.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.users.totalItems;
            state.data.records = action.payload.users.items;
            let _records = new Collection();
            _records.Add(action.payload.page, action.payload.users.items);
            // if (!state.data.isFiltered) {
            //     _records.Concat(state.data.records);
            // }
            // else {
            //     state.data.firstRecords = new Collection();
            //     state.data.currentPage = 0;
            // }
            state.data.isFiltered = false;
            // state.data.records = _records;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        })
        .addCase(getAllUsers.rejected, (state, action) => {
            state.status = fetchStatus.FAILED;
            state.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getUserById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getUserById.fulfilled, (state, action) => {
            state.single.data = action.payload.user;
            toaster.success(action.payload.success);
        }).addCase(getUserById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getDispatchById.pending, (state, action) => {
            state.dispatchDetail.status = fetchStatus.LOADING;
        }).addCase(getDispatchById.fulfilled, (state, action) => {
            state.dispatchDetail.data = action.payload.dispatchDetail;
            state.dispatchDetail.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getDispatchById.rejected, (state, action) => {
            state.dispatchDetail.status = fetchStatus.FAILED;
            state.dispatchDetail.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getDispatchListById.pending, (state, action) => {
            state.dispatchList.status = fetchStatus.LOADING;
        }).addCase(getDispatchListById.fulfilled, (state, action) => {
            state.dispatchList.data = action.payload.dispatchList;
            state.dispatchList.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getDispatchListById.rejected, (state, action) => {
            state.dispatchList.status = fetchStatus.FAILED;
            state.dispatchList.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getDispatchCountById.pending, (state, action) => {
            state.dispatchCount.status = fetchStatus.LOADING;
        }).addCase(getDispatchCountById.fulfilled, (state, action) => {
            state.dispatchCount.data = action.payload.dispatchCount;
            state.dispatchCount.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getDispatchCountById.rejected, (state, action) => {
            state.dispatchCount.status = fetchStatus.FAILED;
            state.dispatchCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(createUser.pending, (state, action) => {
            state.creation.status = fetchStatus.LOADING;
        }).addCase(createUser.fulfilled, (state, action) => {
            if (action.payload.user) {
                state.single.data = action.payload.user;
                let _user = {
                    id: action.payload.user.id,
                    FirstName: action.payload.user.FirstName.Data,
                    LastName: action.payload.user.LastName.Data,
                    Email: action.payload.user.EmailAddress.Data,
                    PhoneNumber: action.payload.user.PhoneNumber.Data,
                    Location: action.payload.user.Location.Data,
                    Status: action.payload.user.Status.Data,
                    ImageData: action.payload.user.PhotoPath.Data
                };
                const created = refreshCreated(state.data.records, _user);
                state.data.records = created.records;
                state.data.currentPage = created.lastPage;
                state.data.totalItems += 1;
                state.single.refreshed = nanoid();
            }
            state.creation.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createUser.rejected, (state, action) => {
            state.creation.status = fetchStatus.FAILED;
            state.creation.error = action.payload.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateUser.pending, (state, action) => {
            state.modification.status = fetchStatus.LOADING;
        }).addCase(updateUser.fulfilled, (state, action) => {
            state.modification.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateUser.rejected, (state, action) => {
            state.modification.status = fetchStatus.FAILED;
            state.modification.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(removeUser.pending, (state, action) => {
            state.removal.status = fetchStatus.LOADING;
        }).addCase(removeUser.fulfilled, (state, action) => {
            const removed = refreshRemoved(state.data.records, action.payload.id, state.data.currentPage);
            state.data.records = removed.records;
            state.data.currentPage = removed.lastPage;
            state.data.totalItems -= 1;
            state.single.refreshed = nanoid();
            state.removal.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(removeUser.rejected, (state, action) => {
            state.removal.status = fetchStatus.FAILED;
            state.removal.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getUserForm.pending, (state, action) => {
            state.form.status = fetchStatus.LOADING;
        }).addCase(getUserForm.fulfilled, (state, action) => {
            state.form.data = action.payload.form;
            state.form.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getUserForm.rejected, (state, action) => {
            state.form.status = fetchStatus.FAILED;
            state.form.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getDashbaordUserOptionsAll.pending, (state, action) => {
            state.userOptions.status = fetchStatus.LOADING;
        }).addCase(getDashbaordUserOptionsAll.fulfilled, (state, action) => {
            state.userOptionsAll.options =  action.payload.options;
            state.userOptionsAll.status = fetchStatus.SUCCEEDED;
            state.userOptionsAll.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getDashbaordUserOptionsAll.rejected, (state, action) => {
            state.userOptionsAll.status = fetchStatus.FAILED;
            state.userOptionsAll.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });;

    }
});

export const { updateCreationStatus, updateModificationStatus, updateRemovalStatus,
    updateSingleStatus, updateIsFiltered, updateStatus, loadSingleData,
    updateCurrentPage, updateSingleData, createNewUser, 
    refreshUserOptionsAll,
    showUserFlyout, closeUserFlyout
} = userSlice.actions;

export default userSlice.reducer

export const selectAllUsers = state => state.users.data.records;

export const selectTotalItems = state => state.users.data.totalItems;

export const selectStatus = state => state.users.data.status;

export const selectError = state => state.data.users.error;

export const selectCreationStatus = state => state.users.creation.status;

export const selectModificationStatus = state => state.users.modification.status;

export const selectRemovalStatus = state => state.users.removal.status;

export const selectCreationError = state => state.users.creation.error;

export const selectModificationError = state => state.users.modification.error;

export const selectRemovalError = state => state.users.removal.error;

export const selectUserById = (state) => {
    return state.users.single ? state.users.single.data : undefined;
}

export const selectSingleStatus = state => state.users.single.status;

export const selectSingleError = state => state.users.single.error;

export const selectDispatchDetail = (state) => {
    return state.users.dispatchDetail ? state.users.dispatchDetail.data : undefined;
}



export const selectDispatchDetailStatus = state => state.users.dispatchDetail.status;

export const selectDispatchDetailError = state => state.users.dispatchDetail.error;

export const selectDispatchList = (state) => {
    return state.users.dispatchList ? state.users.dispatchList.data : undefined;
}
export const selectDispatchListStatus = state => state.users.dispatchList.status;

export const selectDispatchListError = state => state.users.dispatchList.error;

export const selectDispatchCount = (state) => {
    return state.users.dispatchCount ? state.users.dispatchCount.data : undefined;
}
export const selectDispatchCountStatus = state => state.users.dispatchCount.status;

export const selectDispatchCountError = state => state.users.dispatchCount.error;

export const selectCurrentPage = state => state.users.data.currentPage;

export const selectFirstRecord = (state, currentPage) => {
    return state.users.data.firstRecords.Get(currentPage);
}

export const selectSingleRefreshed = state => state.users.single.refreshed;

export const selectUserFormStatus = state => state.users.form.status;

export const selectUserFormError = state => state.users.form.error;

export const selectUserForm = state => state.users.form.data;

export const selectUserOptionsAll = state => state.users.userOptionsAll.options;
export const selectUserOptionsAllStatus = state => state.users.userOptionsAll.status;

export const selectUserOptionsAllRefreshed = state => state.users.userOptionsAll.refreshed;

export const selectUserFlyoutShow = state => state.users.show;

export const selectUserFlyoutCreateMode = state => state.users.createMode;

export const selectUserFlyoutEditmode= state => state.users.editMode;