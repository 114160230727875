import {
    createSlice, nanoid
}
    from '@reduxjs/toolkit';
import { FilterType } from '../../../../common/constants';

const initialState = {
    title: "",
    userId: "",
    show: false,
    filterType: FilterType.APPLICANT,
    saving: nanoid()
};

export const saveFilterModalPopUpSlice = createSlice({
    name: 'saveFilterModalPopUp',
    initialState,
    reducers: {
        showSFModal: (state, action) => {
            const { title, userId } = action.payload;
            state.title = title;
            state.userId = userId;
            state.saving = nanoid();
            state.show = true;
        },
        closeModal: state => {
            state.title = "";
            state.userId = "";
            state.show = false;
            state.filterType = FilterType.APPLICANT;
        }
    }
});

export const { showSFModal, closeModal } = saveFilterModalPopUpSlice.actions;

export default saveFilterModalPopUpSlice.reducer

export const selectModalPopUpDetail = state => {
    return {
        title: state.saveFilterModalPopUp.title,
        id: state.saveFilterModalPopUp.userId,
        show: state.saveFilterModalPopUp.show,
        filterType: state.saveFilterModalPopUp.filterType,
        saving: state.saveFilterModalPopUp.saving
    }
}

export const selectSavingStatus = state => state.saveFilterModalPopUp.saving;