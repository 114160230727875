import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    sortByText: "Last Updated Date",
    sortBy: 1,
    ascending: false,
    open: false,
    sortItems: [
        {
            text: "Last Updated Date",
            value: 1
        },
        {
            text: "Created Date",
            value: 2
        }
    ]
};

export const routeTrackingSorterSlice = createSlice({
    name: 'routeTrackingSorter',
    initialState,
    reducers: {
        updateAscending: (state, action) => {
            state.ascending = action.payload;
        },
        toggleMenu: (state, action) => {
            state.open = action.payload;
        },
        toggleAscending: (state, action) => {
            state.ascending = action.payload;
        },
        setSortItem: (state, action) => {
            const { sortByText, sortBy, open } = action.payload;
            state.sortByText = sortByText;
            state.sortBy = sortBy;
            state.open = open;
        },
        hideMenu: state => {
            state.open = false;
        }
    }
});

export const { updateAscending, setSortItem, toggleMenu, toggleAscending, hideMenu } = routeTrackingSorterSlice.actions;

export default routeTrackingSorterSlice.reducer

export const selectRouteTrackingSorterDetail = state => {
    return {
        sortByText: state.routeTrackingSorter.sortByText,
        sortBy: state.routeTrackingSorter.sortBy,
        ascending: state.routeTrackingSorter.ascending,
        open: state.routeTrackingSorter.open,
        sortByItems: state.routeTrackingSorter.sortItems
    }
}

export const selectSort = state => {
    return {
        by: state.routeTrackingSorter.sortBy,
        ascending: state.routeTrackingSorter.ascending
    }
};
