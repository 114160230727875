import { ErrorCode } from "./constants";

export const messages = {
    toast: {
        title: {
            created: "Created",
            modified: "Updated",
            removed: "Removed"
        },
        content: {
            created: (resource) => `${resource} created successfully.`,
            modified: (resource) => `${resource} updated successfully.`,
            removed: (resource) => `${resource} removed successfully.`
        }
    },
    confirmAlert: {
        title: "Please Confirm",
        content: {
            removal: (resource) => `Are you sure, you want to remove "${resource}" ?`
        }
    }
};


