import React, { useState, useImperativeHandle, forwardRef } from 'react'

import InputGroup from "../../../../component/Controls/custom/inputGroup/inputGroup";
import TextBox from "../../../../component/Controls/custom/textbox/textBox";
import Selector from "../../../../component/Controls/custom/selectors/selectors";
import InputDate from "../../../../component/Controls/custom/inputDate/inputDate";

import { useDispatch, useSelector } from 'react-redux';
import ShortText from '../../../../component/Controls/custom/textbox/shortText';
import DateText from '../../../../component/Controls/custom/textbox/dateText';

import { createJob, createPositionDetail, deletePositionDetail, getClientById, getClients, getJobById, getJobForm, refreshJobOptions, selectClient, selectClients, selectClientsStatus, selectClientStatus, selectCreationStatus, selectJobById, selectJobForm, selectJobFormStatus, selectModificationStatus, selectRecentClickedJob, selectSingleStatus, selectTotalItems, updateJob, updateSingleData } from './../../reducerSlices/jobSlice';
import { useEffect } from 'react';
import { fetchStatus, tenantId } from '../../../../api/client';
import { onValueChanged, sections } from '../../services/jobSaver';
import ZipCodeText from '../../../../component/Controls/custom/textbox/zipCodeText';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import AutoComplete from '../../../../component/Controls/autoComplete/autoComplete';
import Statement from '../../../../component/Controls/custom/textbox/statement';
import PositionDetail from './positionDetail';
import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from '../../../locations/reducerSlices/locationSlice';
import TimeInput from '../../../../component/Controls/custom/timeInput';

import { isFormValid } from '../../../../component/Controls/services/formService';
import { accountService } from '../../../accounts/services/accountService';
import { JobLogType } from '../../../../common/constants';
import { _getDateText } from '../../../../common/common';
import { selectStatusChangeStatus } from '../../../reviewer/reducerSlices/reviewerSlice';
import EllipsisLoader from '../../../../component/Controls/loaders/ellipsisLoader';
import SaveButton from '../../../../component/Controls/custom/buttons/saveButton';
import { toaster } from '../../../../component/Controls/toasts/toaster';
import { selectPositionOptions } from '../../../setting/reducerSlices/positionsSlice';


const JobOrderDetailsTab = forwardRef((props, ref) => {

  const dispatch = useDispatch();

  const JOB_FORM = "Jobform";

  const job = useSelector(selectJobById);

  const jobFormStatus = useSelector(selectJobFormStatus);
  const jobForm = useSelector(selectJobForm);

  const recentClickedJob = useSelector(selectRecentClickedJob);

  const [jfSubmitted, setJFSubmitted] = useState(false);
  const [createMode, setCreatemode] = useState(false);
  const [editMode, setEditmode] = useState();

  useEffect(() => {
    setEditmode(props.editMode);
    setReportToOptions();
  }, [props.editMode]);

  const [statusVisibility, setStatusVisibility] = useState(false);

  const jobRequestStatus = useSelector(selectSingleStatus);

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");

  const clientsStatus = useSelector(selectClientsStatus);
  const clients = useSelector(selectClients);

  const [clientOptions, setClientOptions] = useState();
  const [clientOptionsValue, setClientOptionsValue] = useState();
  const [locationOptionsValue, setLocationOptionsValue] = useState();


  const locationOptions = useSelector(selectLocationOptions);
  const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
  const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);


  const [reportToOptions, setReportToOptions] = useState();
  const clientStatus = useSelector(selectClientStatus);
  const client = useSelector(selectClient);

  const totalPageItems = useSelector(selectTotalItems);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [jobOrderLoaded, setJobOrderLoaed] = useState(false);
  const statusChangeStatus = useSelector(selectStatusChangeStatus);
  const modificationStatus = useSelector(selectModificationStatus);
  const creationStatus = useSelector(selectCreationStatus);

  const positionOptions = useSelector(selectPositionOptions);

  useEffect(() => {
    if (props.createMode === true) {
      setAddress("");
      setCity("");
      setState("");
      setZipCode("");
      setReportToOptions();
    }
  }, [props.createMode]);

  useEffect(() => {
    if (!pageLoaded) {
      // dispatch(updateLayout({
      //   page: 5,
      //   title: "Jobs"
      // }));
      setPageLoaded(true);
      if (jobFormStatus === fetchStatus.IDLE) {
        _loadForm();
      }
    }
  });

  useEffect(() => {
    if (jobRequestStatus === fetchStatus.IDLE || jobOrderLoaded === false) {
      if (recentClickedJob && recentClickedJob !== 0) {
        _loadJob(recentClickedJob, '')
      }
    }
    setJobOrderLoaed(true);
  }, [jobRequestStatus]);


  useEffect(() => {
    if (statusChangeStatus === fetchStatus.SUCCEEDED) {
      if (recentClickedJob && recentClickedJob !== 0) {
        _loadJob(recentClickedJob, '')
      }
    }
  }, [statusChangeStatus]);

  useEffect(() => {
    if (recentClickedJob === 0) {
      setCreatemode(true);
    } else {
      setCreatemode(false);
    }

  }, [recentClickedJob]);

  const _getClientText = (clients, id) => {
    if (isObjectArray(clients)) {
      let client = clients.find(l => l.value === id);
      if (client && client.text) {
        return client.text;
      }
    }
    return "N/A";
  }

  // const _loadJob = async (id, page) => {
  const _loadJob = async (id, page) => {
    await dispatch(getJobById({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETJOBBYID",
        payload: {
          id: id,
          // id: '1dceb99e-247c-46c4-870d-ffa8a88297c7',
          // id: recentClickedJob,
          data: ""
        }
      }
    }));
  }

  const _loadClient = async (id) => {
    await dispatch(getClientById({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCLIENTBYID",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  const _loadClients = async (locationId) => {
    await dispatch(getClients({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCLIENTSFORSTATUSUPDATE",
      payload: {
        id: "",
        data: JSON.stringify({
          where: {
            clientStatus: "",
            location: locationId
          },
          excludeNumber: true
        })
      }
    }));
  }

  const onSaveClicked = async (e, id, created) => {
    e.preventDefault();

    if (job && job.Positions.length > 0) {
    } else {
      toaster.error("At least add one job position");
      setJFSubmitted(true);
      props.onChangeCFInvalid(true);
      return;
    }

    if (isFormValid(JOB_FORM)) {

      if (job) {
        let user = accountService.getAuthenticatedUser();
        let _job = JSON.parse(JSON.stringify(job));
        if (created) {

          if (_job.Address.Data === "") {
            _job.Address.Data = address;
          }
          if (_job.City.Data === "") {
            _job.City.Data = city;
          }
          if (_job.State.Data === "") {
            _job.State.Data = state;
          }
          if (_job.ZipCode.Data === "") {
            _job.ZipCode.Data = zipCode;
          }

          await dispatch(createJob({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVEJOB",
            payload: {
              id: id,
              data: JSON.stringify({
                job: JSON.stringify(_job),
                log: _getStatusLogObj(user)
              })
            }
          }));
        }
        else {
          await dispatch(updateJob({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVEJOB",
            payload: {
              id: id,
              data: JSON.stringify({
                job: JSON.stringify(job),
                log: _getStatusLogObj(user)
              })
            }
          }));
        }
        await _loadJob(id, 1);
        dispatch(refreshJobOptions());
      }
      setCreatemode(false);
      setEditmode(false);
      props.onChangeCreateMode(false);
      props.onChangeEditMode(false);
      //setCFInvalid(false);
      setJFSubmitted(false);
    }
    else {
      props.onChangeCFInvalid(true);
      // console.log('In-----valid');
      //setCFInvalid(true);
    }
    setJFSubmitted(true);
  };

  useEffect(() => {
    if (modificationStatus === 'success' || creationStatus === 'success') {
    }
  }, [modificationStatus, creationStatus]);

  const onAddPositionClicked = (e) => {
    e.preventDefault();
    dispatch(createPositionDetail());
  }

  const onDeletePositionClicked = (e, id) => {
    e.preventDefault();
    dispatch(deletePositionDetail(id));
  }

  useEffect(() => {
    if (clientsStatus === fetchStatus.IDLE && job && job.Location.Data) {
      _loadClients(job.Location.Data);
    }
  }, [clientsStatus, job]);

  useEffect(() => {
    if ((clientStatus === fetchStatus.IDLE && editMode) && job && job.Client.Data) {
      // if ((clientStatus === fetchStatus.IDLE) && job && job.Client.Data) {

      _loadClient(job.Client.Data);
    }
  }, [clientStatus, job, editMode]);

  let _clientOptionsValue;

  useEffect(() => {
    let _clientOptions;
    if (isObjectArray(clients)) {
      _clientOptions = clients.map((client, index) => (
        {
          text: client.ClientName,
          value: client.id,
        }
      ));
      _clientOptionsValue = clients.map((client, index) => (
        <option value={client.id} key={'JODT' + client.id}>{client.ClientName}</option>
      ));
      setClientOptions(_clientOptions);
      setClientOptionsValue(_clientOptionsValue);
    }
  }, [clients]);


  let _locationOptions = undefined;

  useEffect(() => {
    if (isObjectArray(locationOptions)) {
      _locationOptions = locationOptions.map((o) => (
        <option value={o.value} key={'JODT' + o.value}>{o.text}</option>
      ));
      setLocationOptionsValue(_locationOptions);
    }
  }, [locationOptions]);

  useEffect(() => {
    if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
      dispatch(getLocationOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLOCATIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({})
        }
      }));
    }
  }, [locationOptionsStatus, locationOptionsRefreshed]);


  useEffect(() => {
    if (clientStatus === fetchStatus.SUCCEEDED) {
      if (client) {
        if (isObjectArray(client.ReportingPersons)) {
          let _reportToOptions;
          _reportToOptions = client.ReportingPersons.map((r, index) => (
            {
              text: r.name,
              value: r.id,
            }
          ));
          setReportToOptions(_reportToOptions);
        }

        if (client.Address) {
          setAddress(client.Address.Data);
        }
        else {
          setAddress("");
        }

        if (client.City) {
          setCity(client.City.Data);
        }
        else {
          setCity("");
        }

        if (client.State) {
          setState(client.State.Data);
        }
        else {
          setState("");
        }

        if (client.ZipCode) {
          setZipCode(client.ZipCode.Data);
        }
        else {
          setZipCode("");
        }
      }
    }
  }, [clientStatus, client]);

  const onValueUpdated = (e, section, value) => {
    let _jobObj = Object.assign({}, job);
    let _newJobObj = onValueChanged(_jobObj, e, section, value);
    dispatch(updateSingleData(_newJobObj));
  };

  const _getStatusLogObj = (user) => {
    if (createMode) {
      return {
        TimeStamp: (new Date()).toUTCString(),
        LogType: JobLogType.CREATION.type,
        LogValue: JobLogType.CREATION.value,
        Content: `Job created successfully by ${user ? user.firstName + " " + user.lastName : ""}.`,
        Client: _getClientText(clientOptions, job ? job.Client.Data : ""),
        ClientId: (job ? job.Client.Data : ""),
        UserName: (user ? user.firstName + " " + user.lastName : ""),
        UserId: (user ? user.id : "")
      }
    }
    else if (editMode) {
      if (job && job.Status.Data === "Inactive") {
        return {
          TimeStamp: (new Date()).toUTCString(),
          LogType: JobLogType.INACTIVATION.type,
          LogValue: JobLogType.INACTIVATION.value,
          Content: `Job inactivated successfully by ${user ? user.firstName + " " + user.lastName : ""}.`,
          Client: _getClientText(clientOptions, job ? job.Client.Data : ""),
          ClientId: (job ? job.Client.Data : ""),
          UserName: (user ? user.firstName + " " + user.lastName : ""),
          UserId: (user ? user.id : "")
        }
      }
      else {
        return {
          TimeStamp: (new Date()).toUTCString(),
          LogType: JobLogType.MODIFICATION.type,
          LogValue: JobLogType.MODIFICATION.value,
          Content: `Job modified successfully by ${user ? user.firstName + " " + user.lastName : ""}.`,
          Client: _getClientText(clientOptions, job ? job.Client.Data : ""),
          ClientId: (job ? job.Client.Data : ""),
          UserName: (user ? user.firstName + " " + user.lastName : ""),
          UserId: (user ? user.id : "")
        }
      }

    }
  };

  const _setAddress = () => {
    if (job && job.Address.Data) {
      return job.Address.Data;
    }
    else {
      return address;
    }
  }

  const _setCity = () => {
    if (job && job.City.Data) {
      return job.City.Data;
    }
    else {
      return city;
    }
  }

  const _setState = () => {
    if (job && job.State.Data) {
      return job.State.Data;
    }
    else {
      return state;
    }
  }

  const _setZipCode = () => {
    if (job && job.ZipCode.Data) {
      return job.ZipCode.Data;
    }
    else {
      return zipCode;
    }
  }

  const _loadForm = async () => {
    await dispatch(getJobForm({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETFORMBYTYPE",
      payload: {
        id: "",
        data: "JobForm"
      }
    }));
  }


  const onLocationChanged = (e, s, v) => {
    onValueUpdated(e, s, v);
    _loadClients(e.target.value);
  }

  const onClientChanged = (e, s, v) => {
    let clientName = _getClientText(clientOptions, e.target.value);
    const _titleData = {
      total: totalPageItems,
      clientName: clientName,
      editMode: editMode
    }
    onValueUpdated(e, s, _titleData);
    _loadClient(e.target.value);
  }

  const onReportToChanged = (e, s, v) => {
    onValueUpdated(e, s, v);
  }

  const _getLocationText = (locations, id) => {
    if (isObjectArray(locations)) {
      let loc = locations.find(l => l.value === id);
      if (loc && loc.text) {
        return loc.text;
      }
    }
    return "N/A";
  }


  const handleChildClick = (e, _jobId, _createMode) => {
    onSaveClicked(e, _jobId, _createMode)
  };

  useImperativeHandle(ref, () => ({
    handleChildClick,
  }));


  return (
    <>
      {/* <div className="a-card-details">
      </div> */}


      <div className='p-3'>
        {/* <div className="d-flex">
          <div className="ms-auto">
            <div className="fa fa-pencil fa-lg m-3" onClick={() => setEditmode(!editMode)} ></div>
          </div>
        </div> */}
        <div className="row">
          <div className='col-md-3'>
            <div className="form-group">
              <label id="lblTitle" className="field-label" htmlFor="txtTitle">Title</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{job ? job.Title.Data : ""}</label> :
                <InputGroup
                  icon={<i className="fa fa-address-card"></i>}
                  control={
                    <ShortText
                      id="txtTitle"
                      name="txtTitle"
                      ShortTextClasses="form-control"
                      placeholder=""
                      value={job ? job.Title.Data : ""}
                      required={jobForm && jobForm.Title.Required}
                      errorMessage={jobForm && jobForm.Title.Validation}
                      form={JOB_FORM}
                      disabled={true}
                      submited={jfSubmitted} />
                  } />}
            </div>
          </div>
          <div className='col-md-3'>
            <div className="form-group">
              <label id="lblLocations" className="field-label" htmlFor="drpLocations">Location</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{job ? _getLocationText(locationOptions, job.Location.Data) : ""}</label> :
                <InputGroup
                  icon={<i className="fa fa-map-marker"></i>}
                  control={
                    <Selector
                      required={jobForm && jobForm.Location.Required}
                      errorMessage={jobForm && jobForm.Location.Validation}
                      form={JOB_FORM}
                      submited={jfSubmitted}
                      id="drpLocations"
                      name="drpLocations"
                      SelectorClasses="form-select"
                      value={job ? job.Location.Data : ""}
                      disabled={editMode}
                      options={
                        <>
                          {locationOptionsValue}
                        </>
                      } onChange={(e) => onLocationChanged(e, sections.JOB_DETAILS, null)} />
                  } />}
            </div>
          </div>
          <div className='col-md-3'>
            <div className="form-group">
              <label id="ReqDate" className="field-label" htmlFor="txtReqDate">Request Date</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{job ? _getDateText(job.RequestDate.Data) : ""}</label> :
                <InputGroup

                  icon={<i className="fa fa-calendar"></i>}
                  control={
                    <DateText
                      id="txtReqDate"
                      name="txtReqDate"
                      DateTextClasses="form-control"
                      placeholder=""
                      value={job ? job.RequestDate.Data : ""}
                      onChange={(e) => onValueUpdated(e, sections.JOB_DETAILS, null)}
                      required={jobForm && jobForm.RequestDate.Required}
                      errorMessage={jobForm && jobForm.RequestDate.Validation}
                      form={JOB_FORM}
                      submited={jfSubmitted}
                      disabled={editMode} />
                  } />}
            </div>
          </div>
          <div className='col-md-3'>
            <div className="form-group">
              <label id="lblClients" className="field-label" htmlFor="drpClients">Client Name</label>
              {!editMode && !createMode ?
                <label className="field-label-value"> {job && job.Client && job.Client.Data ? _getClientText(clientOptions, job.Client.Data) : ""}</label> :


                <InputGroup
                  icon={<i className="fa fa-address-card"></i>}
                  control={
                    <Selector
                      required={jobForm && jobForm.Client.Required}
                      errorMessage={jobForm && jobForm.Client.Validation}
                      form={JOB_FORM}
                      submited={jfSubmitted}
                      id="drpClients"
                      name="drpClients"
                      SelectorClasses="form-select"
                      value={job ? job.Client.Data : ""}
                      disabled={editMode}
                      options={
                        <>
                          {clientOptionsValue}
                        </>
                      } onChange={(e) => onClientChanged(e, sections.JOB_DETAILS, null)} />
                  } />}
            </div>

          </div>
          <div className='col-md-3'>
            <div className="form-group">
              <label id="lblStartDate" className="field-label" htmlFor="txtStartDate">Start Date</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{job ? _getDateText(job.StartDate.Data) : ""}</label> :
                <InputGroup

                  icon={<i className="fa fa-calendar"></i>}
                  control={
                    <DateText
                      id="txtStartDate"
                      name="txtStartDate"
                      DateTextClasses="form-control"
                      placeholder=""
                      value={job ? job.StartDate.Data : ""}
                      onChange={(e) => onValueUpdated(e, sections.JOB_DETAILS, null)}
                      required={jobForm && jobForm.StartDate.Required}
                      errorMessage={jobForm && jobForm.StartDate.Validation}
                      form={JOB_FORM}
                      submited={jfSubmitted}
                      disabled={editMode} />
                  } />}
            </div>
          </div>
          <div className='col-md-3'>
            <div className="form-group">
              <label id="lblStartTime" className="field-label" htmlFor="txtStartTime">Start Time</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{job && job.StartTime ? job.StartTime.Data : ""}</label> :
                <InputGroup

                  icon={<i className="fa fa-clock-o"></i>}
                  control={
                    <TimeInput
                      id="txtStartTime"
                      name="txtStartTime"
                      TimeInputClasses="form-control"
                      placeholder=""
                      value={job && job.StartTime ? job.StartTime.Data : ""}
                      required={jobForm && jobForm.StartTime.Required}
                      errorMessage={jobForm && jobForm.StartTime.Validation}
                      form={JOB_FORM}
                      submited={jfSubmitted}

                      onChange={(e, v) => onValueUpdated(e, sections.JOB_DETAILS, v)} />
                  } />}
            </div>
          </div>
          <div className='col-md-3'>
            <div className="form-group">
              <label id="lblReportTo" className="field-label" htmlFor="txtReportTo">Report To</label>

              {!editMode && !createMode ?
                <label className="field-label-value">{job ? job.ReportTo.Data : ""}</label> :

                <InputGroup
                  icon={<i className="fa fa-address-card"></i>}
                  control={
                    <AutoComplete
                      required={jobForm && jobForm.ReportTo.Required}
                      errorMessage={jobForm && jobForm.ReportTo.Validation}
                      form={JOB_FORM}
                      submited={jfSubmitted}
                      id="txtReportTo"
                      name="txtReportTo"
                      AutoCompleteClasses="form-control"
                      value={job ? job.ReportTo.Data : ""}
                      options={
                        reportToOptions
                      }
                      // onChange={(e, v) => onReportToChanged(e, sections.JOB_DETAILS, v)} 
                      onChange={(e, v) => onValueUpdated(e, sections.JOB_DETAILS, v)}
                    />
                  } />}
            </div>
          </div>
          <div className='col-md-3'>
            <div className="form-group">
              <label id="lblDressCode" className="field-label" htmlFor="txtDressCode">Dress Code</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{job && job.DressCode ? job.DressCode.Data : ""}</label> :
                <InputGroup

                  icon={<i className="fa fa-tags"></i>}
                  control={
                    <ShortText
                      id="txtDressCode"
                      name="txtDressCode"
                      ShortTextClasses="form-control"
                      placeholder=""
                      value={job && job.DressCode ? job.DressCode.Data : ""}
                      required={jobForm && jobForm.DressCode.Required}
                      errorMessage={jobForm && jobForm.DressCode.Validation}
                      form={JOB_FORM}
                      submited={jfSubmitted}
                      onChange={(e) => onValueUpdated(e, sections.JOB_DETAILS, null)} />
                  } />}
            </div>
          </div>
          <div className='col-md-3'>
            <div className="form-group">
              <label id="lblEndDate" className="field-label" htmlFor="txtEndDate">End Date</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{job && job.EndDate ? _getDateText(job.EndDate.Data) : ""}</label> :
                <InputGroup

                  icon={<i className="fa fa-calendar"></i>}
                  control={
                    <DateText
                      id="txtEndDate"
                      name="txtEndDate"
                      DateTextClasses="form-control"
                      placeholder=""
                      value={job && job.EndDate ? job.EndDate.Data : ""}
                      onChange={(e) => onValueUpdated(e, sections.JOB_DETAILS, null)}
                      required={jobForm && jobForm.EndDate && jobForm.EndDate.Required}
                      errorMessage={jobForm && jobForm.EndDate && jobForm.EndDate.Validation}
                      form={JOB_FORM}
                      submited={jfSubmitted}
                    />
                  } />}
            </div>
          </div>
          <div className='col-md-3'>
            <div className="form-group">
              <label id="lblEndTime" className="field-label" htmlFor="txtEndTime">End Time</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{job && job.EndTime ? job.EndTime.Data : ""}</label> :
                <InputGroup

                  icon={<i className="fa fa-clock-o"></i>}
                  control={
                    <TimeInput
                      id="txtEndTime"
                      name="txtEndTime"
                      TimeInputClasses="form-control"
                      placeholder=""
                      value={job && job.EndTime ? job.EndTime.Data : ""}
                      required={jobForm && jobForm.EndTime && jobForm.EndTime.Required}
                      errorMessage={jobForm && jobForm.EndTime && jobForm.EndTime.Validation}
                      form={JOB_FORM}
                      submited={jfSubmitted}

                      onChange={(e, v) => onValueUpdated(e, sections.JOB_DETAILS, v)} />
                  } />}
            </div>
          </div>
        </div>
      </div>

      <div className="c-line"></div>
      <div className='p-3'>
        <div className="row">
          <div className="col-md-12">
            <p className="p-name mb-0 px-3 pt-3">Reporting Address</p>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label id="lblAddress" className="field-label" htmlFor="txtAddress">Address</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{_setAddress()}</label> :


                <InputGroup
                  icon={<i className="fa fa-home"></i>}
                  control={
                    <ShortText
                      id="txtAddress"
                      name="txtAddress"
                      ShortTextClasses="form-control"
                      placeholder=""
                      value={_setAddress()}
                      onChange={(e) => onValueUpdated(e, sections.JOB_DETAILS, null)}
                      required={jobForm && jobForm.Address.Required}
                      errorMessage={jobForm && jobForm.Address.Validation}
                      form={JOB_FORM}
                      // disabled={editMode}
                      submited={jfSubmitted} />
                  } />}
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label className="field-label" htmlFor="txtCity">City</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{_setCity()}</label> :

                <InputGroup
                  icon={<i className="fa fa-map-marker"></i>}
                  control={
                    <ShortText
                      id="txtCity"
                      name="txtCity"
                      ShortTextClasses="form-control"
                      placeholder="City"
                      value={_setCity()}
                      onChange={(e) => onValueUpdated(e, sections.JOB_DETAILS, null)}
                      required={jobForm && jobForm.City.Required}
                      errorMessage={jobForm && jobForm.City.Validation}
                      form={JOB_FORM}
                      // disabled={editMode}
                      submited={jfSubmitted} />
                  } />}

            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="field-label" htmlFor="txtState">State</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{_setState()}</label> :

                <InputGroup
                  icon={<i className="fa fa-map-marker"></i>}
                  control={
                    <ShortText
                      id="txtState"
                      name="txtState"
                      ShortTextClasses="form-control"
                      placeholder="State"
                      value={_setState()}
                      onChange={(e) => onValueUpdated(e, sections.JOB_DETAILS, null)}
                      required={jobForm && jobForm.State.Required}
                      errorMessage={jobForm && jobForm.State.Validation}
                      form={JOB_FORM}
                      // disabled={editMode}
                      submited={jfSubmitted} />
                  } />}

            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="field-label" htmlFor="txtZipCode">Zipcode</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{_setZipCode()}</label> :

                <InputGroup
                  icon={<i className="fa fa-map-pin"></i>}
                  control={
                    <ZipCodeText
                      id="txtZipCode"
                      name="txtZipCode"
                      ZipCodeTextClasses="form-control"
                      placeholder="Zipcode"
                      value={_setZipCode()}
                      onChange={(e) => onValueUpdated(e, sections.JOB_DETAILS, null)}
                      required={jobForm && jobForm.ZipCode.Required}
                      errorMessage={jobForm && jobForm.ZipCode.Validation}
                      form={JOB_FORM}
                      // disabled={editMode}
                      submited={jfSubmitted} />
                  } />}

            </div>
          </div>

        </div>
      </div>

      <div className="c-line" />
      <div className="p-3">
        <div className="row">
          <div className="col-md-12">
            <p className="p-name px-3 pt-3">Job Request</p>
            <div className='float-end'>
              {editMode || createMode ? <span
                className="add-position-button"
                title={props.addButtonTitle ? props.addButtonTitle : "Create new record"}
                onClick={(e) => onAddPositionClicked(e)}>
                <i className="fa fa-plus-circle fa-3x"></i>
              </span> : ''}
            </div>
          </div>
          <div className="col-md-12">
            <div className='px-3'>
              <table className="table c-table ">
                <thead>
                  <tr>
                    <th scope="col">Sr. No</th>
                    <th scope="col">Job Postion</th>
                    <th scope="col">Description</th>
                    <th scope="col">Job Type</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Pay Rates ($)</th>
                    {!editMode && !createMode ? <th scope="col">Status</th> : <></>}
                    <th scope="col"></th>
                    {!editMode && !createMode ? <th scope="col"></th> : <></>}
                  </tr>
                </thead>
                <tbody>
                  <PositionDetail
                    positions={positionOptions}
                    form={JOB_FORM}
                    submited={jfSubmitted}
                    jobForm={jobForm}
                    readonly={!editMode && !createMode}
                    details={job && job.Positions}
                    job={job}
                    onValueUpdated={(e, s, v) => onValueUpdated(e, s, v)}
                    onDeleteClicked={(e, id) => onDeletePositionClicked(e, id)} />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="c-line" />

      <div className="p-3">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label id="Notes" className="field-label" htmlFor="txtNotes">Notes</label>
              {!editMode && !createMode ?
                <label className="field-label-value">{job ? job.Notes.Data : ""}</label> :

                <InputGroup
                  icon={<i className="fa fa-pencil"></i>}
                  control={
                    <Statement
                      id="txtNotes"
                      name="txtNotes"
                      value={job ? job.Notes.Data : ""}
                      rows={3}
                      StatementClasses="form-control"
                      onChange={(e) => onValueUpdated(e, sections.JOB_DETAILS, null)}
                      required={jobForm && jobForm.Notes.Required}
                      errorMessage={jobForm && jobForm.Notes.Validation}
                      form={JOB_FORM}
                      submited={jfSubmitted}
                      placeholder="Enter note..." />
                  } />}
            </div>
          </div>
          <div className="col-md-12">
            <div className="float-end p-3">
              {creationStatus === fetchStatus.LOADING || modificationStatus === fetchStatus.LOADING ?
                <EllipsisLoader />
                :
                <SaveButton
                  onSaveClicked={(e) => onSaveClicked(e, job ? job.id : "", createMode)} />
              }
            </div>
          </div>
        </div>
      </div>
    </ >
  )
});

export default JobOrderDetailsTab;