import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../../api/client';
import { accountService } from '../../../features/accounts/services/accountService';
import { saveLeadRoute, selectLeadRouteStatus } from '../../../features/crm/reducerSlices/leadSlice';
import CancelButton from '../custom/buttons/cancelButton';
import SaveButton from '../custom/buttons/saveButton';
import InputGroup from '../custom/inputGroup/inputGroup';
import EllipsisLoader from '../loaders/ellipsisLoader';
import { isFormValid } from '../services/formService';
import Statement from '../custom/textbox/statement';
import { closeModal, selectModalPopUpDetail } from './reducerSlices/leadRouteNoteModalPopUpSlice';


function LeadRouteNoteModalPopUp(props) {

    const dispatch = useDispatch();

    const LEAD_ROUTE_NOTE_FORM = "LeadRouteNoteForm";

    const noteLeadRouteStatus = useSelector(selectLeadRouteStatus);
    const modalPopUpDetails = useSelector(selectModalPopUpDetail);

    const [lrnfSubmitted, setLRNFSubmitted] = useState(false);
    const lead = modalPopUpDetails.lead;
    const [note, setNote] = useState("");


    useEffect(() => {
        if (noteLeadRouteStatus !== fetchStatus.LOADING) {
            setNote("");
            dispatch(closeModal());
        }
    }, [noteLeadRouteStatus]);

    const onCloseClicked = () => {
        setNote("");
        setLRNFSubmitted(false);
        dispatch(closeModal());
    };

    const onSaveClicked = async (id) => {
        const user = accountService.getAuthenticatedUser();

        if (isFormValid(LEAD_ROUTE_NOTE_FORM)) {
            setLRNFSubmitted(false);
            let details = {
                note: note,
                RouteDetails: {
                    Data: false,
                    TimeStamp: (new Date()).toUTCString(),
                    UnixTimestamp: Math.floor(Date.now() / 1000)
                },
                log: {
                    TimeStamp: (new Date()).toUTCString(),
                    LogType: "Route Uncheck",
                    LogValue: 32,
                    Content: note,
                    UserName: (user ? user.firstName + " " + user.lastName : ""),
                    UserId: (user ? user.id : "")
                }
            }
            dispatch(saveLeadRoute({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "SAVELEADROUTE",
                payload: {
                    id: id,
                    data: JSON.stringify(details)
                }
            }));
        } else {
            setLRNFSubmitted(true);
        }
    };


    return (
        <>
            {(modalPopUpDetails && modalPopUpDetails.show) ? (<>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-block">
                                <div className="d-flex">
                                    <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                                    {noteLeadRouteStatus === fetchStatus.LOADING ? <></> : <button
                                        type="button"
                                        className="modal-close-button ms-auto"
                                        onClick={onCloseClicked}>
                                        <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </button>}
                                </div>
                                <div className="d-flex">
                                    <span className="modal-sub-title">Lead route list remove</span>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <InputGroup
                                            icon={<i className="fa fa-pencil-square-o"></i>}
                                            control={
                                                <Statement
                                                    id="txtLeadNote"
                                                    name="txtLeadNote"
                                                    value={note}
                                                    StatementClasses="form-control"
                                                    onChange={(e) => setNote(e.target.value)}
                                                    required={true}
                                                    errorMessage="Please enter note"
                                                    form={LEAD_ROUTE_NOTE_FORM}
                                                    submited={lrnfSubmitted}
                                                    placeholder="Enter note..." />
                                            } />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                {noteLeadRouteStatus === fetchStatus.LOADING ? <EllipsisLoader /> : <><CancelButton
                                    CancelButtonClasses="mr-2"
                                    onCancelClicked={() => onCloseClicked()} />
                                    <SaveButton
                                        onSaveClicked={() => onSaveClicked(modalPopUpDetails.id)} /></>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </>) : <></>}
        </>
    );
}

export default LeadRouteNoteModalPopUp;