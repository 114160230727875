
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDocument, downloadDocument, selectDocumentDeleteStatus, selectDocumentDownloadData, selectDocumentDownloadStatus, updateDocumentDownloadStatus } from '../../../features/reviewer/reducerSlices/reviewerSlice';
import { fetchStatus, tenantId } from '../../../api/client';
import { accountService } from '../../../features/accounts/services/accountService';
import RemoveIconButton from '../buttons/removeIconButton';

import folderImg from './../../../assets/images/clipart/folder.svg';
import pdfImg from './../../../assets/images/clipart/pdf.svg';
import bisinessCardImg from './../../../assets/images/clipart/bisiness-card.svg';
import officePhotoImg from './../../../assets/images/clipart/office-photo.svg';
import creditCheckImg from './../../../assets/images/clipart/credit-check.png';
import safetyTestAndCertificateImg from './../../../assets/images/clipart/safety-test-and-certificate.svg';
import proposalImg from './../../../assets/images/clipart/proposal.png';
import completedImg from './../../../assets/images/clipart/completed.svg';
import coiImg from './../../../assets/images/clipart/coi.svg';
import saImg from './../../../assets/images/clipart/sa.png';
import { downloadFile, getFileSizeText, toLocalDate } from '../../../common/common';
import ReactTooltip from 'react-tooltip';

function LeadDocumentsCard(props) {

    const lead = props.lead;

    const dispatch = useDispatch();
    const isAdmin = accountService.isAdmin();
    const documentDownloadStatus = useSelector(selectDocumentDownloadStatus);
    const documentDownloadData = useSelector(selectDocumentDownloadData);
    const [documentsTrs, setDocumentsTrs] = useState([]);
    const [businessCardTrs, setBusinessCardTrs] = useState([]);
    const [officePhotoTrs, setOfficePhotoTrs] = useState([]);
    const [creditTrs, setCreditTrs] = useState([]);
    const [safetyTrs, setSafetyTrs] = useState([]);
    const [proposalTrs, setProposalTrs] = useState([]);
    const [completedTrs, setCompletedTrs] = useState([]);
    const [sATrs, setSATrs] = useState([]);
    const [cOITrs, setCOITrs] = useState([]);
    const [othersTrs, setOthersTrs] = useState([]);


    const onDeleteClicked = async (e, id, name) => {
        if (id) {
            await dispatch(deleteDocument({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "DELETEDOCUMENT",
                payload: {
                    id: lead.id,
                    data: JSON.stringify({
                        documentName: name,
                        documentId: id
                    })
                }
            }));
        }
    };

    const onDownloadClicked = async (e, id, documentName) => {

        await dispatch(downloadDocument({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "DOWNLOADDOCUMENT",
            payload: {
                id: id,
                data: documentName
            }
        }));
    };

    if (documentDownloadStatus === fetchStatus.SUCCEEDED) {
        if (documentDownloadData) {
            downloadFile(documentDownloadData.documentData, documentDownloadData.documentName);
        }
        dispatch(updateDocumentDownloadStatus());
    }

    let _documentsTrs;
    let _itemDiv;

    useEffect(() => {
        let _lead = props.lead;
        let _businessCardArr = [];
        let _officePhotoArr = [];
        let _creditArr = [];
        let _safetyArr = [];
        let _proposalArr = [];
        let _completedArr = [];
        let _sAArr = [];
        let _cOIArr = [];
        let _otherArr = [];

        if (_lead && _lead.Documents && Array.isArray(_lead.Documents) && _lead.Documents.length > 0) {
            _documentsTrs = _lead.Documents.map((doc, index) => (
                _itemDiv = <div className="flyout-list-group-item-card">
                    <div className='sub-icon'>
                        <img src={pdfImg} className="icon-img" />
                        <p className='file-size-text'>{getFileSizeText(doc.Length)}</p>
                    </div>
                    <span className="ps-2">
                        <div className='file-name'>{truncate(doc.DocumentType, 20)}</div>
                        <div className='file-by'>{doc.UploadedBy}, {doc.UploadedOn && toLocalDate(doc.UploadedOn)} </div>
                    </span>
                    <span className="ms-auto d-flex">
                        <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onDownloadClicked(e, _lead.id, doc.DocumentName)} data-tip='Download'></i>
                        <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                        {isAdmin ?
                            <>
                                <i className="fa fa-trash icon-btn ms-2" aria-hidden="true" onClick={(e) => onDeleteClicked(e, doc.id, doc.DocumentName)} data-tip='Delete'></i>
                                <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                            </>
                            : <></>
                        }
                    </span>
                </div>,

                doc.DocumentType == 26 ?
                    _officePhotoArr.push(_itemDiv) : '',

                doc.DocumentType == 27 ?
                    _businessCardArr.push(_itemDiv) : '',

                doc.DocumentType == 52 ?
                    _creditArr.push(_itemDiv) : '',

                doc.DocumentType == 53 ?
                    _safetyArr.push(_itemDiv) : '',

                doc.DocumentType == 54 ?
                    _proposalArr.push(_itemDiv) : '',

                doc.DocumentType == 55 ?
                    _completedArr.push(_itemDiv) : '',

                doc.DocumentType == 56 ?
                    _sAArr.push(_itemDiv) : '',

                doc.DocumentType == 57 ?
                    _cOIArr.push(_itemDiv) : '',

                (doc.DocumentType == 26 || doc.DocumentType == 27) ?
                    '' : _otherArr.push(_itemDiv),


                <></>
            ));
            setDocumentsTrs(_documentsTrs);
            setBusinessCardTrs(_businessCardArr);
            setOfficePhotoTrs(_officePhotoArr);
            setCreditTrs(_creditArr);
            setSafetyTrs(_safetyArr);
            setProposalTrs(_proposalArr);
            setCompletedTrs(_completedArr);
            setSATrs(_sAArr);
            setCOITrs(_cOIArr);
            setOthersTrs(_otherArr);
        }
        else {
            setDocumentsTrs([]);
            setBusinessCardTrs(_businessCardArr);
            setOfficePhotoTrs(_officePhotoArr);
            setCreditTrs(_creditArr);
            setSafetyTrs(_safetyArr);
            setProposalTrs(_proposalArr);
            setCompletedTrs(_completedArr);
            setSATrs(_sAArr);
            setCOITrs(_cOIArr);
            setOthersTrs(_otherArr);
        }
    }, [props.documents]);

    // useEffect(() => {
    //     let _lead = props.lead;
    //     if (_lead && _lead.Documents && Array.isArray(_lead.Documents) && _lead.Documents.length > 0) {
    //         _documentsTrs = _lead.Documents.map((doc, index) => (
    //             <div className="flyout-list-group-item-card">
    //                 <div className='sub-icon'>
    //                     <img src={pdfImg} className="icon-img" />
    //                     <p className='file-size-text'>{getFileSizeText(doc.Length)}</p>
    //                 </div>
    //                 <span className="ps-2">
    //                     <div className='file-name'>{truncate(doc.DocumentType, 20)}</div>
    //                     <div className='file-by'>{doc.UploadedBy}, {doc.UploadedOn && toLocalDate(doc.UploadedOn)} </div>
    //                 </span>
    //                 <span className="ms-auto d-flex">
    //                     <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onDownloadClicked(e, _lead.id, doc.DocumentName)} data-tip='Download'></i>
    //                     <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
    //                     {isAdmin ?
    //                         <>
    //                             <i className="fa fa-trash icon-btn ms-2" aria-hidden="true" onClick={(e) => onDeleteClicked(e, doc.id, doc.DocumentName)} data-tip='Delete'></i>
    //                             <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
    //                         </>
    //                         : <></>
    //                     }
    //                 </span>
    //             </div>
    //         ));
    //         setDocumentsTrs(_documentsTrs);
    //     }
    //     else {
    //         setDocumentsTrs([]);
    //     }
    // }, [props.documents]);


    const truncate = (str, n) => {
        var docText = 'Others';
        if (str == '51') {
            docText = "In Progress Details";
        }
        if (str == '52') {
            docText = "Credit Check Details";
        }
        if (str == '53') {
            docText = "Safety Check Details";
        }
        if (str == '54') {
            docText = "Proposal Details";
        }
        if (str == '55') {
            docText = "Completed Details";
        }
        if (str == '56') {
            docText = "SA";
        }
        if (str == '57') {
            docText = "COI";
        }
        if (str == '26') {
            docText = "Office Photo";
        }
        if (str == '27') {
            docText = "Business Card";
        }

        return (docText.length > n) ? docText.substr(0, n - 1) + '...' : docText;
    };


    return (
        <div>
            <div className="accordion" id="accordionPeopleAttachment">

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        <img src={bisinessCardImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Business Card
                        </span>
                        <span className="ms-auto light-text">
                            {businessCardTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {businessCardTrs}
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <img src={officePhotoImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Office Photo
                        </span>
                        <span className="ms-auto light-text">
                            {officePhotoTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {officePhotoTrs}
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <img src={creditCheckImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Credit Check
                        </span>
                        <span className="ms-auto light-text">
                            {creditTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {creditTrs}
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <img src={safetyTestAndCertificateImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Safety Check
                        </span>
                        <span className="ms-auto light-text">
                            {safetyTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {safetyTrs}
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        <img src={proposalImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Proposal
                        </span>
                        <span className="ms-auto light-text">
                            {proposalTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {proposalTrs}
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        <img src={completedImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Completed
                        </span>
                        <span className="ms-auto light-text">
                            {completedTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {completedTrs}
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        <img src={saImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            SA
                        </span>
                        <span className="ms-auto light-text">
                            {sATrs.length} Items
                        </span>
                    </div>
                    <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {sATrs}
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        <img src={coiImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            COI
                        </span>
                        <span className="ms-auto light-text">
                            {cOITrs.length} Items
                        </span>
                    </div>
                    <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {cOITrs}
                        </div>
                    </div>
                </div>

                {/* <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                        <img src={folderImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Others
                        </span>
                        <span className="ms-auto light-text">
                            {othersTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {othersTrs}
                        </div>
                    </div>
                </div> */}

                {/* <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                        <img src={folderImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            All
                        </span>
                        <span className="ms-auto light-text">
                            {businessCardTrs.length + officePhotoTrs.length + othersTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseTen" className="accordion-collapse collapse show" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {businessCardTrs}
                            {officePhotoTrs}
                            {othersTrs}
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default LeadDocumentsCard;