import {
    createSlice,
    createAsyncThunk,
    nanoid
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import Collection from '../../../component/Controls/paginators/collection';
import { refreshRemoved } from '../../../component/Controls/paginators/services/removedResource';
import { toaster } from '../../../component/Controls/toasts/toaster';

import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    data: {
        totalItems: 0,
        items: [],
        currentPage: 0,
        status: fetchStatus.IDLE,
        error: null,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: undefined,
        isOpened: false
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    removal: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    },
    qrCode: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    registeredApplicant: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    registeredModification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    recentClickedApplicant: {
        id: undefined
    },
    jobsApplicant: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    emailForTempLogin: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    appicantCompleteProfileByUser: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
};

export const getAllApplicants = createAsyncThunk('applicants/getAllApplicants', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', applicantModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let applicants;
        if (isJSON(data)) {
            applicants = JSON.parse(data);
        }
        return {
            page: applicantModel.page,
            applicants: applicants,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getApplicantById = createAsyncThunk('applicants/getApplicantById', async (applicantModel, { rejectWithValue }) => {
    try {
        // const response = await BajoAPI.post(`Gateway`, applicantModel.model, getAxiosRequestConfig());
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());

        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
            console.log('if data isJSON -->', applicant);
            // return applicant;
        }
        return {
            page: applicantModel.page,
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const createApplicant = createAsyncThunk('applicants/createApplicant', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', applicantModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
        }
        return {
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const updateApplicant = createAsyncThunk('applicants/updateApplicant', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
        }
        return {
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const removeApplicant = createAsyncThunk('applicants/removeApplicant', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        return {
            id: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getApplicantQRCode = createAsyncThunk('applicants/getApplicantQRCode', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "";
        return {
            data: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getRegisteredApplicantById = createAsyncThunk('applicants/getRegisteredApplicantById', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
        }
        return {
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const updateRegisteredApplicant = createAsyncThunk('applicants/updateRegisteredApplicant', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
        }
        return {
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getJobsByApplicantId = createAsyncThunk('applicants/getJobsByApplicantId', async (applicantModel, { rejectWithValue }) => {
    try {
        // const response = await BajoAPI.post(`Gateway`, applicantModel.model, getAxiosRequestConfig());
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());

        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
            console.log('if data isJSON -->', applicant);
            // return applicant;
        }
        return {
            page: applicantModel.page,
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const updateAppicantEmailForTempLogin = createAsyncThunk('applicants/updateAppicantEmailForTempLogin', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
        return {
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const updateAppicantCompleteProfileByUser = createAsyncThunk('applicants/updateAppicantCompleteProfileByUser', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
        return {
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const applicantSlice = createSlice({
    name: 'applicants',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateStatusIdle: (state) => {
            state.data.status = fetchStatus.IDLE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateRegisteredModificationStatus: (state) => {
            state.registeredModification.status = fetchStatus.IDLE;
        },
        updateRemovalStatus: (state) => {
            state.removal.status = fetchStatus.IDLE;
        },
        updateSingleData: (state, _data) => {
            state.single.isOpened = false;
            state.single.refreshed = nanoid();
            if (_data.payload) {
                state.single.data = Object.assign({}, _data.payload);
                let _item = state.data.records.Get(state.data.currentPage);
                if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                    let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.id);
                    let newRecords = _item.records;//.filter(a => a.id !== _data.payload.id);
                    let _applicant = {
                        id: _data.payload.id,
                        FirstName: _data.payload.FirstName.Data,
                        LastName: _data.payload.LastName.Data,
                        ApplicationDate: _data.payload.ApplicationDate,
                        Email: _data.payload.EmailAddress.Data,
                        PhoneNumber: _data.payload.PhoneNumber.Data,
                        Location: _data.payload.Location.Data,
                        Status: _data.payload.Status,
                        SecondaryStatus: _data.payload.SecondaryStatus,
                        ImageData: _data.payload.PhotoPath.Data,
                        IsOpened: _data.payload.IsOpened
                    };
                    newRecords[_updatedObjectIndex] = _applicant;
                    _item.records = newRecords;
                    let records = new Collection();
                    records.Add(_item.key, newRecords);
                    state.data.records.Remove(_item.key);
                    records.Concat(state.data.records);
                    state.data.records = records;
                }
            }
            else {
                state.single.data = undefined;
            }
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        updateRegisteredApplicantData: (state, _data) => {
            if (_data.payload) {
                state.registeredApplicant.data = Object.assign({}, _data.payload);
            }
            // else {
            //     state.registeredApplicant.data = undefined;
            // }
        },
        updateSingleApplicant: (state, _data) => {
            if (_data.payload) {
                state.single.data = Object.assign({}, _data.payload);
            }
        },
        updateRecentClickedApplicant: (state, _data) => {
            state.recentClickedApplicant.id = _data.payload;
        },
        updateApplicantQRCodeStatusIDLE: (state) => {
            state.qrCode.status = fetchStatus.IDLE;
        },
        updateApplicantEmailForTempLoginStatus: (state) => {
            state.emailForTempLogin.status = fetchStatus.IDLE;
        },
        updateAppicantCompleteProfileByUserStatus: (state) => {
            state.appicantCompleteProfileByUser.status = fetchStatus.IDLE;
        },
        createExperienceDetail: (state, action) => {
            const detail = action.payload;
            state.single.data = Object.assign({}, state.single.data);
            if (!state.single.data.ExperienceDetails) {
                state.single.data["ExperienceDetails"] = [];
            }
            state.single.data.ExperienceDetails.push(detail);
        },
        removeExperienceDetailByItemId: (state, action) => {
            const experienceDetailId = action.payload;
            state.single.data = Object.assign({}, state.single.data);
            if (state.single.data.ExperienceDetails && experienceDetailId !== '') {
                const updatedExperienceDetails  = state.single.data.ExperienceDetails.filter(item => item.id !== experienceDetailId);
                state.single.data.ExperienceDetails = updatedExperienceDetails;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllApplicants.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        }).addCase(getAllApplicants.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.applicants.totalItems;
            let _records = new Collection();
            _records.Add(action.payload.page, action.payload.applicants.items);
            if (!state.data.isFiltered) {
                _records.Concat(state.data.records);
            }
            else {
                state.data.firstRecords = new Collection();
                state.data.currentPage = 0;
            }
            state.data.isFiltered = false;
            state.data.records = _records;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getAllApplicants.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getApplicantById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getApplicantById.fulfilled, (state, action) => {
            state.single.data = action.payload.applicant;
            if (action.payload.page >= 0) {
                let _records = new Collection();
                _records.Add(action.payload.page, action.payload.applicant);
                _records.Concat(state.data.firstRecords);
                state.data.firstRecords = _records;
            }
            state.single.status = fetchStatus.SUCCEEDED;
            state.single.isOpened = true;
            toaster.success(action.payload.success);
        }).addCase(getApplicantById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(createApplicant.pending, (state, action) => {
            state.creation.status = fetchStatus.LOADING;
        }).addCase(createApplicant.fulfilled, (state, action) => {
            state.data.push(action.payload);
            state.creation.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createApplicant.rejected, (state, action) => {
            state.creation.status = fetchStatus.FAILED;
            state.creation.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateApplicant.pending, (state, action) => {
            state.modification.status = fetchStatus.LOADING;
        }).addCase(updateApplicant.fulfilled, (state, action) => {
            state.single.data = action.payload.applicant;
            state.modification.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateApplicant.rejected, (state, action) => {
            state.modification.status = fetchStatus.FAILED;
            state.modification.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(removeApplicant.pending, (state, action) => {
            state.removal.status = fetchStatus.LOADING;
        }).addCase(removeApplicant.fulfilled, (state, action) => {
            const removed = refreshRemoved(state.data.records, action.payload.id, state.data.currentPage);
            state.data.records = removed.records;
            state.data.currentPage = removed.lastPage;
            state.data.totalItems -= 1;
            state.single.refreshed = nanoid();
            state.removal.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(removeApplicant.rejected, (state, action) => {
            state.removal.status = fetchStatus.FAILED;
            state.removal.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getApplicantQRCode.pending, (state, action) => {
            state.qrCode.status = fetchStatus.LOADING;
        }).addCase(getApplicantQRCode.fulfilled, (state, action) => {
            state.qrCode.data = action.payload.data;
            state.qrCode.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getApplicantQRCode.rejected, (state, action) => {
            state.qrCode.status = fetchStatus.FAILED;
            state.qrCode.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getRegisteredApplicantById.pending, (state, action) => {
            console.log('is pending...', state, action);
            state.registeredApplicant.status = fetchStatus.LOADING;
        }).addCase(getRegisteredApplicantById.fulfilled, (state, action) => {
            console.log('is fulfilled...', state, action);
            state.registeredApplicant.data = action.payload.applicant;
            state.registeredApplicant.status = fetchStatus.SUCCEEDED;
            state.registeredApplicant.isOpened = true;
            toaster.success(action.payload.success);
        }).addCase(getRegisteredApplicantById.rejected, (state, action) => {
            console.log('is rejected...', state, action);
            state.registeredApplicant.status = fetchStatus.FAILED;
            state.registeredApplicant.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateRegisteredApplicant.pending, (state, action) => {
            state.registeredModification.status = fetchStatus.LOADING;
        }).addCase(updateRegisteredApplicant.fulfilled, (state, action) => {
            state.registeredApplicant.data = action.payload.applicant;
            state.registeredModification.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateRegisteredApplicant.rejected, (state, action) => {
            state.registeredModification.status = fetchStatus.FAILED;
            state.registeredModification.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getJobsByApplicantId.pending, (state, action) => {
            state.jobsApplicant.status = fetchStatus.LOADING;
        }).addCase(getJobsByApplicantId.fulfilled, (state, action) => {
            state.jobsApplicant.data = action.payload.applicant;
            state.jobsApplicant.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getJobsByApplicantId.rejected, (state, action) => {
            state.jobsApplicant.status = fetchStatus.FAILED;
            state.jobsApplicant.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateAppicantEmailForTempLogin.pending, (state, action) => {
            state.emailForTempLogin.status = fetchStatus.LOADING;
        }).addCase(updateAppicantEmailForTempLogin.fulfilled, (state, action) => {
            state.emailForTempLogin.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateAppicantEmailForTempLogin.rejected, (state, action) => {
            state.emailForTempLogin.status = fetchStatus.FAILED;
            state.emailForTempLogin.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateAppicantCompleteProfileByUser.pending, (state, action) => {
            state.appicantCompleteProfileByUser.status = fetchStatus.LOADING;
        }).addCase(updateAppicantCompleteProfileByUser.fulfilled, (state, action) => {
            state.appicantCompleteProfileByUser.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateAppicantCompleteProfileByUser.rejected, (state, action) => {
            state.appicantCompleteProfileByUser.status = fetchStatus.FAILED;
            state.appicantCompleteProfileByUser.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { updateCreationStatus, updateModificationStatus,
    updateRemovalStatus, updateSingleStatus, updateSingleData, updateCurrentPage,
    updateStatus, updateStatusIdle, updateIsFiltered, loadSingleData, updateRegisteredApplicantData,
    updateSingleApplicant, updateRegisteredModificationStatus, updateRecentClickedApplicant,
    updateApplicantEmailForTempLoginStatus, updateAppicantCompleteProfileByUserStatus,
    createExperienceDetail, removeExperienceDetailByItemId } = applicantSlice.actions;

export default applicantSlice.reducer

export const selectAllApplicants = state => {
    return state.applicants.data.items;
}

export const selectAllApplicantRecords = state => {
    return state.applicants.data.records;
}

export const selectTotalItems = state => state.applicants.data.totalItems;

export const selectStatus = state => state.applicants.data.status;

export const selectError = state => state.applicants.data.error;

export const selectCreationStatus = state => state.applicants.creation.status;

export const selectModificationStatus = state => state.applicants.modification.status;

export const selectRemovalStatus = state => state.applicants.removal.status;

export const selectCreationError = state => state.applicants.creation.error;

export const selectModificationError = state => state.applicants.modification.error;

export const selectRemovalError = state => state.applicants.removal.error;

export const selectApplicantById = (state) => {
    return state.applicants.single ? state.applicants.single.data : undefined;
}

export const selectSingleStatus = state => state.applicants.single.status;

export const selectSingleError = state => state.applicants.single.error;

export const selectApplicantQRCode = state => state.applicants.qrCode.data;

export const selectApplicantQRCodeStatus = state => state.applicants.qrCode.status;

export const selectApplicantQRCodeError = state => state.applicants.qrCode.error;

export const selectCurrentPage = state => {
    return state.applicants.data.currentPage;
}

export const selectFirstRecord = (state, currentPage) => {
    return state.applicants.data.firstRecords.Get(currentPage);
}

export const selectSingleRefreshed = state => state.applicants.single.refreshed;

export const selectSingleOpened = state => state.applicants.single.isOpened;

export const selectRegisteredApplicantById = state => state.applicants.registeredApplicant.data;

export const selectRegisteredApplicantStatus = state => state.applicants.registeredApplicant.status;

export const selectRegisteredApplicantModificationStatus = state => state.applicants.registeredModification.status;

export const selectRecentClickedApplicant = state => state.applicants.recentClickedApplicant.id;

export const selectJobsApplicantStatus = state => state.applicants.jobsApplicant.status;

export const selectJobsByApplicantId = state => state.applicants.jobsApplicant.data;

export const selectAppicantEmailForTempLoginStatus = state => state.applicants.emailForTempLogin.status;

export const selectAppicantCompleteProfileByUserStatus = state => state.applicants.appicantCompleteProfileByUser.status;