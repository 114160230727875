import React from 'react';
import Loader from "react-js-loader";
import { fetchStatus } from '../../../../api/client';
import ReactPlaceholder from 'react-placeholder';

function DashboardCard(props) {
  return (
    <>
      <div className={`dashboard-card`}>
        <div className={`dashboard-card-circle ${props.iconBackgroundClass ? props.iconBackgroundClass : ''}`}>
          <i className={`fa fa-xl text-white ${props.faIcon ? props.faIcon : 'fa-arrow-trend-up'}  `}></i>
        </div>
        <div className="w-100 ps-3 ">
          <div className="dashboard-card-title">
            {props.title}
          </div>
          {props.isItemsLoading === fetchStatus.LOADING ?
            <div className="dashboard-card-number"><ReactPlaceholder showLoadingAnimation={true} ready={false} rows={1}></ReactPlaceholder></div>
            :
            <div className="dashboard-card-number">
              {props.number}
              <span className={`dashboard-card-sub-number ${props.percentage >= 0 ? 'dashboard-card-sub-number-success' : 'dashboard-card-sub-number-danger'} `}>
                <i className={`fa ${props.percentage >= 0 ? "fa-arrow-trend-up" : "fa-arrow-trend-down"}`}></i> {Math.abs(props.percentage)}%
              </span>
            </div>
          }
          <div className="dashboard-card-info">
            ({props.info})
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardCard;