import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Selector from '../custom/selectors/selectors'
import InputGroup from '../custom/inputGroup/inputGroup';
import NumberText from '../custom/textbox/numberText';
import { isObjectArray } from '../../../utilities/utilityFunctions';

function SkillFilter(props) {
  let id = props.id;
  const [operation, setOperation] = useState(">");
  const [value, setValue] = useState("");

  let _experienceTypes = props.experienceTypes;

  const otherExperienceFilter = useSelector(props.otherExperienceFilterSelector);

  useEffect(() => {
    if (otherExperienceFilter) {
      let _otherExperienceFilter = otherExperienceFilter.filter(d => d.id === id)[0];
      if (_otherExperienceFilter) {

        if (_otherExperienceFilter.Data.experience.operation) {
          setOperation(_otherExperienceFilter.Data.experience.operation);
        }
        else {
          setOperation(">");
        }
        setValue(_otherExperienceFilter.Data.experience.value);
      }else{
        setOperation(">");
        setValue("");
      }
    }
  }, [otherExperienceFilter]);

  const onOperationChanged = (e) => {
    setOperation(e.target.value);
    let _value = undefined;

    _value = {
      type: props.type ? props.type : "",
      experience: {
        operation: e.target.value,
        value: value
      }
    }

    props.onExperienceChanged && props.onExperienceChanged(e, _value);
  };

  const onExperienceChanged = (e) => {
    setValue(e.target.value);
    let _value = undefined;

    _value = {
      type: props.type ? props.type : "",
      experience: {
        operation: operation,
        value: e.target.value
      }
    }

    props.onExperienceChanged && props.onExperienceChanged(e, _value);
  };

  const onExperienceTypeChanged = (e) => {
    setValue(e.target.value);
    let _value = undefined;

    _value = {
      type: props.type ? props.type : "",
      experience: {
        operation: "=",
        value: e.target.value
      }
    }

    props.onExperienceChanged && props.onExperienceChanged(e, _value);
  };

  let experienceTypesList;
  if (isObjectArray(_experienceTypes)) {
    experienceTypesList = _experienceTypes.map((option, index) => (
      <option key={option} value={option}>
        {option}
      </option>
    ));
  }

  return (
    <>
      <div className="row">
        <div className={props.includeType ? "col-md-12" : "col-md-12"}>
          <label>
            {props.experienceFor ? props.experienceFor : ""}
          </label>
        </div>
      </div>
      {props.type === "Months" &&
        <div className="row">
          <div className="col-md-12">
            <div className="form-group p-0 pt-2">
              <label id={`lbl${id}`}
                className="field-label d-inline"
                htmlFor={`txtExperience${id}`}>
                Months Experience
              </label>
              <div className="experience-controls float-end">
                <Selector
                  required={true}
                  errorMessage={"Please select operation"}
                  form={undefined}
                  submited={false}
                  id={`drpOperation${id}`}
                  name={`drpOperation${id}`}
                  SelectorClasses="form-control operation-control"
                  value={operation}
                  withoutDefault={true}
                  options={
                    <>
                      <option value=">">{'>'}</option>
                      <option value="<">{'<'}</option>
                      <option value=">=">{'>='}</option>
                      <option value="<=">{'<='}</option>
                      <option value="=">{'='}</option>
                    </>
                  }
                  onChange={(e) => onOperationChanged(e)} />
                <InputGroup
                  icon={<i className="fa fa-suitcase"></i>}
                  control={
                    <NumberText
                      max="999"
                      maxLength={3}
                      required={true}
                      errorMessage={"Please enter experience"}
                      form={undefined}
                      submited={false}
                      id={`txtExperience${id}`}
                      name={`txtExperience${id}`}
                      NumberTextClasses={`form-control`}
                      value={value}
                      onChange={(e) => onExperienceChanged(e)} />
                  } />
              </div>
            </div>
          </div>
        </div>
      }

      {props.type !== "Months" &&
        <div className="row">
          <div className="col-md-12">
            <div className="form-group p-0 pt-2">
              <label id={`lblExperienceType${id}`}
                className="field-label d-inline"
                htmlFor={`drpExperienceType${id}`}>
                Type
              </label>
              <div className="d-flex float-end">
                <Selector
                  required={true}
                  errorMessage={"Please select experience type"}
                  form={undefined}
                  submited={false}
                  id={`drpExperienceType${id}`}
                  name={`drpExperienceType${id}`}
                  SelectorClasses="form-select"
                  value={value}
                  options={experienceTypesList}
                  onChange={(e) => onExperienceTypeChanged(e)}
                />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default SkillFilter