import {
    createAsyncThunk,
    createSlice
}
    from '@reduxjs/toolkit';
import { BajoAPI, fetchStatus } from '../../../../api/client';
import { getAxiosRequestConfig } from '../../../../common/common';
import { isJSON } from '../../../../utilities/utilityFunctions';

const initialState = {
    title: "",
    id: "",
    lead: undefined,
    updatedLead: undefined,
    status: {
        value: 0,
        text: ""
    },
    show: false,
    canceled: false,
};

export const leadStatusUpdateModalPopUpSlice = createSlice({
    name: 'leadStatusUpdateModalPopUp',
    initialState,
    reducers: {
        showLSUModal: (state, action) => {
            const { title, id, lead, status } = action.payload;
            state.title = title;
            state.id = id;
            state.lead = lead;
            state.status.value = status.value;
            state.status.text = status.text;
            state.show = true;
        },
        closeModal: state => {
            state.title = "";
            state.id = "";
            state.lead = undefined;
            state.updatedLead = undefined;
            state.status.value = 0;
            state.status.text = "";
            state.show = false;
            state.canceled = true;
        },
        saveUpdatedLead: (state, action) => {
            state.updatedLead = action.payload;
        },
        resetCanceledStatus: (state) => {
            state.canceled = false;
        }
    }
});

export const { showLSUModal, closeModal, saveUpdatedLead, resetCanceledStatus } = leadStatusUpdateModalPopUpSlice.actions;

export default leadStatusUpdateModalPopUpSlice.reducer

export const selectModalPopUpDetail = state => {
    return {
        title: state.leadStatusUpdateModalPopUp.title,
        id: state.leadStatusUpdateModalPopUp.id,
        lead: state.leadStatusUpdateModalPopUp.lead,
        updatedLead: state.leadStatusUpdateModalPopUp.updatedLead,
        status: state.leadStatusUpdateModalPopUp.status,
        show: state.leadStatusUpdateModalPopUp.show,
    }
}

export const selectCanceledStatus = state => state.leadStatusUpdateModalPopUp.canceled;

