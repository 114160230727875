import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    title: "",
    id: "",
    updatedLead: undefined,
    show: false
};

export const leadRouteNoteModalPopUpSlice = createSlice({
    name: 'leadRouteNoteModalPopUp',
    initialState,
    reducers: {
        showLRNModal: (state, action) => {
            const { title, id, lead } = action.payload;
            state.title = title;
            state.id = id;
            state.show = true;
        },
        closeModal: state => {
            state.title = "";
            state.id = "";
            state.show = false;
        }
    }
});

export const { showLRNModal, closeModal } = leadRouteNoteModalPopUpSlice.actions;

export default leadRouteNoteModalPopUpSlice.reducer

export const selectModalPopUpDetail = state => {
    return {
        title: state.leadRouteNoteModalPopUp.title,
        id: state.leadRouteNoteModalPopUp.id,
        updatedLead: state.leadRouteNoteModalPopUp.updatedLead,
        show: state.leadRouteNoteModalPopUp.show
    }
}