import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { isObjectArray } from '../../utilities/utilityFunctions';
import { toLocalDate, _getContent } from '../../common/common';
import DataNotFoundCard from './DataNotFoundCard';

function LogCardLead(props) {

    const getStatus = (logValue, logType) => {
        let statusText;

        if (logValue === 1) {
            statusText = <><span className="p-changed">Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-gray"> {logType} </span></span></>;
        }
        else if (logValue === 18 || logValue === 19 || logValue === 20 || logValue === 21 || logValue === 22 || logValue === 23 || logValue === 24 || logValue === 25) {
            statusText = <><span className="p-changed">Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-blue"> {logType} </span></span></>;
        }
        else if (logValue === 26) {
            statusText = <><span className="p-changed">Office Photo </span><span className="p-status"><span className="badge rounded-pill bg-gold"> Update</span></span></>;
        }
        else if (logValue === 27) {
            statusText = <><span className="p-changed">Business Card </span><span className="p-status"><span className="badge rounded-pill bg-gold"> Update</span></span></>;
        }
        else if (logValue === 31) {
            statusText = <><span className="p-changed">Route List </span><span className="p-status"><span className="badge rounded-pill bg-green"> Added</span></span></>;
        }
        else if (logValue === 32) {
            statusText = <><span className="p-changed">Route List </span><span className="p-status"><span className="badge rounded-pill bg-danger"> Removed</span></span></>;
        }
        else if (logValue === 71) {
            statusText = <><span className="p-changed">Appointment </span><span className="p-status"><span className="badge rounded-pill bg-green"> Created </span></span></>;
        }
        else if (logValue === 72) {
            statusText = <><span className="p-changed">Appointment </span><span className="p-status"><span className="badge rounded-pill bg-gold"> Update </span></span></>;
        }
        else if (logValue === 73) {
            statusText = <><span className="p-changed">Appointment </span><span className="p-status"><span className="badge rounded-pill bg-danger"> Removed </span></span></>;
        }
        else if (logValue === -1) {
            statusText = <><span className="p-changed"> Added Note </span></>;
        }
        else if (logValue === -2) {
            statusText = <><span className="p-status"> <span className="badge rounded-pill bg-light-orange"> {logType} </span></span><span className="p-changed"> Lead </span></>;
        }
        else if (logValue === -3) {
            statusText = <><span className="p-status"> <span className="badge rounded-pill bg-gold"> {logType} </span></span><span className="p-changed"> Lead </span></>;
        }
        else if (logValue === -10) {
            statusText = <><span className="p-changed">Contact </span><span className="p-status"><span className="badge rounded-pill bg-danger"> Deleted </span></span></>;
        }
        else {
            statusText = <><span className="text-muted statusfont"></span><span className="p-status"><span className="badge rounded-pill bg-gray"> {logType} </span></span></>;
        }

        return statusText;
    };


    const getStatusIcon = (logValue) => {
        let statusIcon;
        if (logValue === 1) {
            statusIcon = <div className="tracking-icon status-intransit bg-light-orange"><span><i className="fa fa-stamp  fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 18 || logValue === 19 || logValue === 20 || logValue === 21 || logValue === 22 || logValue === 23 || logValue === 24 || logValue === 25) {
            statusIcon = <div className="tracking-icon status-intransit bg-orange"><span><i className="fa fa-file-contract fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 26) {
            statusIcon = <div className="tracking-icon status-intransit bg-green"><span><i className="fa fa-camera-retro fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 27) {
            statusIcon = <div className="tracking-icon status-intransit bg-blue"><span><i className="fa fa-address-card fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 31 || logValue === 32) {
            statusIcon = <div className="tracking-icon status-intransit bg-blue"><span><i className="fa fa-route fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 71) {
            statusIcon = <div className="tracking-icon status-intransit bg-purple"><span><i className="fa fa-calendar fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 72) {
            statusIcon = <div className="tracking-icon status-intransit bg-purple"><span><i className="fa fa-calendar fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 73) {
            statusIcon = <div className="tracking-icon status-intransit bg-purple"><span><i className="fa fa-calendar fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === -1) {
            statusIcon = <div className="tracking-icon status-intransit bg-blue"><span><i className="fa fa-pen-to-square fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === -2 || logValue === -3) {
            statusIcon = <div className="tracking-icon status-intransit bg-green"><span><i className="fa fa-phone fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === -10) {
            statusIcon = <div className="tracking-icon status-intransit bg-danger"><span><i className="fa fa-x fa-md" aria-hidden="true"></i></span></div>;
        }
        else {
            statusIcon = <div className="tracking-icon status-intransit bg-secondary"><span><i className="fa fa-info fa-md" aria-hidden="true"></i></span></div>;
        }
        return statusIcon;
    }

    const [logs, setLogs] = useState();

    useEffect(() => {
        let _statusLogs = props.statusLogs;
        let _logs;
        if (isObjectArray(_statusLogs) && _statusLogs.length > 0) {
            _logs = _statusLogs.map((log, index) => (
                <>
                    {log &&
                        <div className="tracking-item">
                            {getStatusIcon(log.LogValue)}
                            <div className="tracking-content">
                                <p>
                                    <span className="p-name pe-1">{log.UserName != null ? log.UserName : ""}</span>
                                    {log && getStatus(log.LogValue, log.LogType)}
                                </p>
                                {
                                    log.LogValue === 71 || log.LogValue === 72 || log.LogValue === 73 ?
                                        <>
                                            <p className="card-text text-justify">
                                                {log && log.AppointmentLog.Subject && <p className="p-light-gray">Subject : <span className="p-dark-gray">{log && log.AppointmentLog.Subject}</span></p>}
                                                {log && log.AppointmentLog.Status && <p className="p-light-gray">Status : <span className="p-dark-gray">{log && log.AppointmentLog.Status}</span></p>}
                                                {log && log.AppointmentLog.Description && <p className="p-light-gray">Description : <span className="p-dark-gray">{log && log.AppointmentLog.Description}</span></p>}
                                            </p>
                                        </>
                                        :
                                        <p className="p-subtext">{log && log.Content}</p>
                                }
                                <p className="p-light-gray">{log && toLocalDate(log.TimeStamp)}</p>
                            </div>
                        </div>
                    }
                </>
            ));
            setLogs(_logs);
        }
        else {
            setLogs(<DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title="" description="" />);
        }
    }, [props.statusLogs]);

    return (
        <React.Fragment>
            {logs}
        </React.Fragment>
    );
}

export default LogCardLead;