import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addCurrentStep, selectLanguage, updateLoginStatus } from '../reducerSlices/accountsSlice';
import { fetchStatus, tenantId } from '../../../api/client';
import { accountService } from '../services/accountService';
import Progress from '../../../component/Progress/Progress';
import '../css/accounts.css';
import BackButton from './../../../component/Controls/buttons/backButton';
import ContinueButton from './../../../component/Controls/buttons/continueButton';
import { isFormValid } from '../../../component/Controls/services/formService';
import { getRegisteredApplicantById, selectRegisteredApplicantById, selectRegisteredApplicantModificationStatus, selectRegisteredApplicantStatus, updateRegisteredApplicant, updateRegisteredModificationStatus, updateRegisteredApplicantData } from '../../applicant/reducerSlices/applicantSlice';
import { USER_KEY, Language } from '../../../common/constants';
import SSNText from '../../../component/Controls/custom/textbox/ssnText';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup';
import PhoneText from '../../../component/Controls/custom/textbox/phoneText';
import EmailText from '../../../component/Controls/custom/textbox/emailText';
import MultiSelector from '../../../component/Controls/custom/selectors/multiSelector';
import { employmentTypesData, shiftsData } from '../../applicant/services/masterData';
import { onValueChanged, sections } from '../../applicant/services/applicantSaver';
import { applicantDetailsEN } from '../../applicant/services/applicantDetails';
import Radio from '../../../component/Controls/custom/checkers/radio';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';
import { getPositionOptions, selectPositionOptions, selectPositionOptionsStatus } from '../../setting/reducerSlices/positionsSlice';

const Personal_Info_2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(addCurrentStep('Personal'));

  const APPLICANT_DETAIL_FORM = "AppilcantDetailForm";
  const [adfSubmitted, setADFSubmitted] = useState(false);
  const [aPFInvalid, setAPFInvalid] = useState(false);
  
  const applicantRequestStatus = useSelector(selectRegisteredApplicantStatus);
  const applicantModificationStatus = useSelector(selectRegisteredApplicantModificationStatus);
  const applicant = useSelector(selectRegisteredApplicantById);

  const [applicantDetails, setApplicantDetails] = useState(applicantDetailsEN);
  const language = useSelector(selectLanguage);

  const positionOptions = useSelector(selectPositionOptions);
  const positionOptionsStatus = useSelector(selectPositionOptionsStatus);

  useEffect(() => {
    if (positionOptionsStatus === fetchStatus.IDLE) {
      dispatch(getPositionOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETPOSITIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [positionOptionsStatus]);

  const _loadApplicant = async (id) => {
    await dispatch(getRegisteredApplicantById({
      client: tenantId,
      serviceType: "TENANTUSER",
      operation: "GETREGISTEREDAPPLICANT",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  useEffect(() => {
    if (applicantRequestStatus === fetchStatus.IDLE) {
      const userId = accountService.getLoggedInUserId(USER_KEY);
      _loadApplicant(userId);
    }
  }, [applicantRequestStatus]);

  const onNextClicked = (e) => {
    setAPFInvalid(false);
    if (isFormValid(APPLICANT_DETAIL_FORM)) {
      if (applicant) {
        const userId = accountService.getLoggedInUserId(USER_KEY);
        dispatch(updateRegisteredApplicant({
          client: tenantId,
          serviceType: "TENANTUSER",
          operation: "SUBMITAPPLICANT",
          payload: {
            id: userId,
            data: JSON.stringify({
              applicant: JSON.stringify(applicant),
              photo: {
                contentType: "image/jpg",
                length: 0,
                photoData: ""
              }
            })
          }
        }));
      }
      setADFSubmitted(false);
    }
    else {
      setAPFInvalid(true);
      setADFSubmitted(true);
    }
  };

  const onBack = (e) => {
    history.push('/personal_info_1');
  }

  useEffect(() => {
    if (applicantModificationStatus === fetchStatus.SUCCEEDED) {
      if (accountService.isAuthenticated()) {
        dispatch(updateLoginStatus());
        dispatch(updateRegisteredModificationStatus());
        history.push("/references");
      }
    }
  }, [applicantModificationStatus, dispatch, history]);

  const onValueUpdated = (e, section, value) => {
    let _applicantObj = Object.assign({}, applicant);
    let _newApplicantObj = onValueChanged(_applicantObj, e, section, value);
    dispatch(updateRegisteredApplicantData(_newApplicantObj));
  };


  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 card card-left d-flex align-items-stretch p-0">
            <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn">
              <div className="row p-2">
                <div className="col-md-12">
                  <div className="card-left-header">Personal Info</div>
                  <div className="card-left-sub-header">Please enter your personal details. Dont have any? Its ok leave it blank. </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblEmailAddress" className="field-label" htmlFor="txtEmailAddress">{applicantDetails ? applicantDetails.Email : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-envelope"></i>}
                      control={
                        <EmailText
                          required={applicant && applicant.EmailAddress.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.EmailAddressError : applicant && applicant.EmailAddress.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          disabled={true}
                          id="txtEmail"
                          name="txtEmail"
                          EmailTextClasses="form-control"
                          placeholder="xyz@example.com"
                          value={applicant ? applicant.EmailAddress.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />
                      } />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblPhone" className="field-label" htmlFor="txtPhone">{applicantDetails ? applicantDetails.PhoneNumber : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-phone"></i>}
                      control={
                        <PhoneText
                          required={applicant && applicant.PhoneNumber.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.PhoneNumberError : applicant && applicant.PhoneNumber.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          id="txtPhone"
                          name="txtPhone"
                          PhoneTextClasses="form-control"
                          placeholder="(000)-00-000"
                          value={applicant ? applicant.PhoneNumber.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />
                      } />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblSSN" className="field-label" htmlFor="txtSSN">{applicantDetails ? applicantDetails.SSN : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <SSNText
                          required={false}
                          errorMessage={language === Language.SPANISH ? applicantDetails.SSNError : applicant && applicant.SSN.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          id="txtSSN"
                          name="txtSSN"
                          SSNTextClasses="form-control"
                          placeholder="000000000"
                          value={applicant ? applicant.SSN.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />
                      } />
                  </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblAlternateEmail" className="field-label" htmlFor="txtAlternateEmail">{applicantDetails ? applicantDetails.AlternateEmailAddress : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-envelope"></i>}
                      control={
                        <EmailText
                          required={applicant && applicant.AlternateEmailAddress && applicant.AlternateEmailAddress.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.EmailAddressError : applicant && applicant.AlternateEmailAddress && applicant.AlternateEmailAddress.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          id="txtAlternateEmail"
                          name="txtAlternateEmail"
                          EmailTextClasses="form-control"
                          placeholder="xyz@example.com"
                          value={applicant && applicant.AlternateEmailAddress ? applicant.AlternateEmailAddress.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />
                      } />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblAlternatePhone" className="field-label" htmlFor="txtAlternatePhone">{applicantDetails ? applicantDetails.AlternatePhoneNumber : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-phone"></i>}
                      control={<PhoneText
                        required={false}
                        errorMessage={'Enter phone number'}
                        form={APPLICANT_DETAIL_FORM}
                        submited={adfSubmitted}
                        id="txtAlternatePhone"
                        name="txtAlternatePhone"
                        PhoneTextClasses="form-control"
                        placeholder=""
                        value={applicant && applicant.AlternatePhoneNumber ? applicant.AlternatePhoneNumber.Data : ""}
                        onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)}
                      />
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-12 pt-3">
                  <div className="card-left-sub-header">Positions Interested In</div>
                </div>
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblPositions" className="field-label" htmlFor="drpPositions">{applicantDetails ? applicantDetails.Position : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-id-badge"></i>}
                      control={
                        <MultiSelector
                          id="drpPositions"
                          name="drpPositions"
                          value={applicant ? applicant.Position.Data : []}
                          options={positionOptions}
                          onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                          required={applicant && applicant.Position.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.PositionError : applicant && applicant.Position.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted} />
                      } />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblShifts" className="field-label" htmlFor="drpShifts">{applicantDetails ? applicantDetails.Shifts : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-id-badge"></i>}
                      control={
                        <MultiSelector
                          id="drpShifts"
                          name="drpShifts"
                          value={applicant ? applicant.Shift.Data : []}
                          options={shiftsData}
                          onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                          required={applicant && applicant.Shift.Required}
                          errorMessage={applicant && applicant.Shift.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted} />
                      } />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblEmploymentTypes" className="field-label" htmlFor="drpEmploymentTypes">Employment Type</label>
                    <InputGroup
                      icon={<i className="fa fa-id-badge"></i>}
                      control={
                        <MultiSelector
                          id="drpEmploymentTypes"
                          name="drpEmploymentTypes"
                          value={applicant ? applicant.EmploymentType.Data : []}
                          options={employmentTypesData}
                          onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                          required={applicant && applicant.EmploymentType.Required}
                          errorMessage={applicant && applicant.EmploymentType.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted} />
                      } />
                  </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-12 pt-3">
                  <div className="card-left-sub-header">Marital Status</div>
                  <>
                    <div data-form={APPLICANT_DETAIL_FORM} data-invalid={applicant && applicant.MaritalStatus.Data === ""}>
                      <Radio id="rdbtnFSMar"
                        name="rdbtnFilingStatus"
                        label="Married"
                        value="Married"
                        onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                        checked={applicant && applicant.MaritalStatus.Data === "Married"} />
                      <Radio id="rdbtnFSSing"
                        name="rdbtnFilingStatus"
                        label="Single"
                        value="Unmarried"
                        onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                        checked={applicant && applicant.MaritalStatus.Data === "Unmarried"} />
                      <Radio id="rdbtnFSHOH"
                        name="rdbtnFilingStatus"
                        label="Head Of House"
                        value="HOH"
                        onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                        checked={applicant && applicant.MaritalStatus.Data === "HOH"} />
                    </div>
                    {
                      (aPFInvalid && applicant && applicant.MaritalStatus.Data === "") ?
                        <span className="error-label">
                          <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                          Please select marital status
                        </span>
                        : <></>
                    }
                  </>
                </div>
                <div className="col-md-12 pt-3">
                  <div className="card-left-sub-header">Gender</div>
                  <><div data-form={APPLICANT_DETAIL_FORM} data-invalid={applicant && applicant.Gender.Data === ""}>
                    <Radio id="rdbtnMale"
                      name="rdbtnGender"
                      label="Male"
                      value="Male"
                      onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                      checked={applicant && applicant.Gender.Data === "Male"} />
                    <Radio id="rdbtnFemale"
                      name="rdbtnGender"
                      label="Female"
                      value="Female"
                      onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                      checked={applicant && applicant.Gender.Data === "Female"} />
                    <Radio id="rdbtnOther"
                      name="rdbtnGender"
                      label="Others"
                      value="Other"
                      onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                      checked={applicant && applicant.Gender.Data === "Other"} />
                  </div>
                    {
                      (aPFInvalid && applicant && applicant.Gender.Data === "") ?
                        <span className="error-label">
                          <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                          Please select gender
                        </span>
                        : <></>
                    }
                  </>
                </div>
              </div>
            </div>
            <div className="card-footer bottom-0 px-4 py-2 w-100">
              <div className="row">
                <div className="col-md-12">
                  {applicantModificationStatus === fetchStatus.LOADING ? <div className="float-end my-2"><EllipsisLoader /></div>
                    :
                    <>
                      <BackButton onBackClicked={onBack} />
                      <div className="float-end">
                        <ContinueButton onClick={(e) => onNextClicked(e)} />
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 card card-right d-flex align-items-stretch p-0">
            <Progress percentage="15" getPercentage="30" subTitle="Personal Info" currentPage="personalInfo2" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Personal_Info_2;