import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import XLSX from 'xlsx';
import moment from 'moment';
// import Papa from 'papaparse';

import { fetchStatus, tenantId } from '../../../api/client';
import { closeClientImportModal, saveClientImport, selectClientImportModalPopUpDetail, selectSaveClientImportStatus } from './reducerSlices/clientImportModalPopUpSlice';
import { isFormValid } from '../services/formService';
import CancelButton from '../custom/buttons/cancelButton';
import SaveButton from '../custom/buttons/saveButton';
import EmailText from '../custom/textbox/emailText';
import PhoneText from '../custom/textbox/phoneText';
// import ShortText from '../textboxes/shortText';
import Selector from '../custom/selectors/selectors';
import DateText from '../custom/textbox/dateText';
import ShortText from '../custom/textbox/shortText';
import ZipCodeText from '../custom/textbox/zipCodeText';
import { applicantDetailsEN } from '../../../features/applicant/services/applicantDetails';
import { selectLocationOptions } from '../../../features/locations/reducerSlices/locationSlice';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import StateOptionsValue from '../../../common/stateOptionsValue';
import MultiSelector from '../custom/selectors/multiSelector';
import { employmentTypesData } from '../../../features/applicant/services/masterData';
import SSNText from '../custom/textbox/ssnText';
import EllipsisLoader from '../loaders/ellipsisLoader';
import InputGroup from '../custom/inputGroup/inputGroup';
import { updateStatusIdle } from '../../../features/applicant/reducerSlices/applicantSlice';
import { toaster } from '../toasts/toaster';
import { _splitCamelCase } from '../../../common/common';


function ClientImportModalPopUp() {

  const dispatch = useDispatch();

  const PEOPLE_FORM = "PEOPLE_FORM";

  const [applicantDetails, setApplicantDetails] = useState(applicantDetailsEN);
  const [editedData, setEditedData] = useState(null);
  const [pfSubmitted, setPFSubmitted] = useState(false);


  const clientImportModalPopUpDetails = useSelector(selectClientImportModalPopUpDetail);
  const saveClientImportStatus = useSelector(selectSaveClientImportStatus);

  const [locationOptionsValue, setLocationOptionsValue] = useState();
  const locationOptions = useSelector(selectLocationOptions);


  useEffect(() => {
    if (isObjectArray(locationOptions)) {
      let _locationOptions = undefined;
      _locationOptions = locationOptions.map((o) => (
        <option value={o.value}>{o.text}</option>
      ));
      setLocationOptionsValue(_locationOptions);
    }
  }, [locationOptions]);

  const onCloseClicked = () => {
    setEditedData(null);
    setPFSubmitted(false);
    dispatch(closeClientImportModal());
  };


  const _formatPhoneNumber = (phoneNumber) => {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
    if (numericPhoneNumber.length !== 10) {
      return "";
    }
    const formattedPhoneNumber = `(${numericPhoneNumber.substring(0, 3)})${numericPhoneNumber.substring(3, 6)}-${numericPhoneNumber.substring(6, 10)}`;
    return formattedPhoneNumber;
  };

  const _formatSSN = (SSN) => {
    const numericSSN = SSN.replace(/\D/g, '');
    if (numericSSN.length !== 9) {
      return "";
    }
    const formattedSSN = `${numericSSN.substring(0, 3)}-${numericSSN.substring(3, 5)}-${numericSSN.substring(5, 9)}`;
    return formattedSSN;
  };

  const _excelDateToJSDate = (serial) => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);
    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);
    const seconds = total_seconds % 60;
    total_seconds -= seconds;
    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  };


  const _is18Age = (formattedDate) => {
    const jsDate = moment(formattedDate, 'YYYY-MM-DD').toDate();
    const now = moment();
    const diff = now.diff(jsDate, 'years');
    return diff >= 18;
  };

  const _formatDate = (dateValue) => {
    if (typeof dateValue === 'number') {
      const jsDate = _excelDateToJSDate(dateValue);
      const fDate = moment(jsDate).format('YYYY-MM-DD');
      if (_is18Age(fDate)) {
        return fDate;
      } else {
        return '';
      }

    } else {
      let fDate = moment(dateValue).format('YYYY-MM-DD');
      if (fDate === 'Invalid date') {
        return '';
      } else {
        if (_is18Age(fDate)) {
          return fDate;
        } else {
          return '';
        }
      }
    }
  };

  function getEmploymentTypeByText(inputString) {
    const textArray = inputString.split(',');
    const resultArray = [];
    for (const text of textArray) {
      for (const type of employmentTypesData) {
        if (type.text === text) {
          resultArray.push(type.value);
          break; // Move to the next text in the input array
        }
      }
    }
    console.log('resultArray', resultArray)
    return resultArray;
  }

  function getValueByText(DataList, inputString) {
    const textArray = inputString.split(',');
    const resultArray = [];
    for (const text of textArray) {
      for (const type of DataList) {
        if (type.text === text) {
          resultArray.push(type.value);
          break; // Move to the next text in the input array
        }
      }
    }
    console.log('resultArray', resultArray)
    return resultArray;
  }

  function getLocationValueByText(text) {
    for (const location of locationOptions) {
      if (location.text === text) {
        return location.value;
      }
    }
    return "";
  }

  // Define the expected headers in the correct sequence
  const expectedHeaders = [
    "ClientName",
    "ClientNumber",
    "Status",
    "Location",
    "Address",
    "City",
    "State",
    "ZipCode",
    "CompanyWebsite",
    "ClientPhone"
  ];

  const expectedHeadersString = expectedHeaders.join(', ');

  // Function to validate the header row sequence
  function validateHeaderRowSequence(headerRow, expectedHeaders) {
    return headerRow.length === expectedHeaders.length &&
      headerRow.every((header, index) => header === expectedHeaders[index]);
  }

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const fileData = reader.result;
      const workbook = XLSX.read(fileData, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming the first sheet
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Convert array of arrays to array of objects (key-value pairs)
      const headerRow = data[0];

      // Validate the header row sequence
      if (validateHeaderRowSequence(headerRow, expectedHeaders)) {
        const formattedData = data.slice(1).map((row) => {
          const rowData = {};
          headerRow.forEach((header, index) => {
            rowData[header] = row[index];
          });
          return rowData;
        });
        console.log('formattedData', formattedData);

        const formattedData2 = data.slice(1).map((row) => ({
          ClientName: row[0] || "",
          ClientNumber: row[1] || "",
          Status: row[2] || "",
          Location: row[3] && getLocationValueByText(row[3]) || "",
          Address: row[4] || "",
          City: row[5] || "",
          State: row[6] || "",
          ZipCode: row[7] || "",
          CompanyWebsite: row[8] || "",
          ClientPhone: (row[9] && _formatPhoneNumber(row[9].toString())) || "",

        }));
        console.log('formattedData2', formattedData2);


        // setCsvData(formattedData);
        setEditedData(formattedData2);
      } else {
        // alert("Header column sequence invalid. Check the order of your column headers.")
        toaster.error("Header column sequence invalid. Check the order of your column headers.")
      }
    };

    reader.readAsBinaryString(file);
  };

  const handleEdit = (e, rowIndex, columnName) => {
    const newData = [...editedData];
    newData[rowIndex][columnName] = e.target.value;
    setEditedData(newData);
  };

  const handleEdit2 = (e, rowIndex, columnName, v) => {
    const newData = [...editedData];
    newData[rowIndex][columnName] = v;
    setEditedData(newData);
  };

  const onDeleteClicked = (rowIndex) => {
    const newData = [...editedData];
    newData.splice(rowIndex, 1);
    setEditedData(newData);
  };



  const onSaveClicked = async () => {
    console.log('Saving data:', editedData);
    setPFSubmitted(true);
    if (isFormValid(PEOPLE_FORM)) {
      let disData = {
        clients: editedData
      }
      await dispatch(saveClientImport({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "SAVECLIENTIMPORTMULTIPLE",
        payload: {
          id: "",
          data: JSON.stringify(disData)
        }
      }));

    } else {
      setPFSubmitted(true);
    }
  };

  useEffect(() => {
    if (saveClientImportStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateStatusIdle());
      onCloseClicked();
    }
  }, [saveClientImportStatus]);

  useEffect(() => {
    const handleBackButton = (event) => {
      if (clientImportModalPopUpDetails.show) {
        onCloseClicked();
      }
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [clientImportModalPopUpDetails.show]);


  return (
    <>
      {(clientImportModalPopUpDetails && clientImportModalPopUpDetails.show) ?
        <>

          <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
            {/* <div className="modal-dialog modal-dialog-centered" role="document"> */}
            {/* <div className="modal-dialog modal-dialog-centered FillOrderDialogWidth modal-fullscreen" role="document"> */}
            <div className={`modal-dialog modal-dialog-centered ${(editedData && editedData.length > 0) ? 'FillOrderDialogWidth' : ''} `} role="document">


              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{clientImportModalPopUpDetails.title}</h5>
                  <button
                    type="button"
                    className="modal-close-button"
                    onClick={onCloseClicked}>
                    <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row p-2">
                    <div className="col-md-12">
                      {(editedData && editedData.length > 0)
                        ?
                        <>
                          <b className="text-pink">Please fill the missing fields that are required:</b> <br />
                        </>
                        :
                        <>
                          <b className="text-pink">The CSV column sequence must precisely match the following order:</b> <br />
                          {expectedHeaders.map((d, index) =>
                            (<>{`${index + 1} . ${_splitCamelCase(d)}`} <br /></>)
                          )}
                        </>
                      }
                    </div>
                  </div>
                  <div className="row p-2">
                    <div className="col-md-4">
                      <div className="form-group p-0">
                        <label id="lblImportFile" className="field-label" htmlFor="txtImportFile">Upload CSV File Client</label>
                        <InputGroup
                          icon={<i className="fa fa-address-card"></i>}
                          control={
                            <input type="file" onChange={handleCSVUpload} accept=".csv, .xlsx, .xls" />
                          } />

                      </div>
                    </div>
                  </div>
                  <div>
                    <br></br>
                    <br></br>
                    {editedData && editedData.length > 0 && (
                      <>
                        <div class="people-table">
                          <table class="table table-responsive table-striped">
                            <thead>
                              <tr>
                                {Object.keys(editedData[0]).map((header) => {
                                  if (header !== "CustomFields") {
                                    return (
                                      <th key={header} className="single-line">
                                        {_splitCamelCase(header)}
                                      </th>
                                    );
                                  } else {
                                    return Object.keys(editedData[0].CustomFields).map((customHeader) => (
                                      <th key={customHeader} className="single-line">
                                        {_splitCamelCase(customHeader)}
                                      </th>
                                    ));
                                  }
                                })}
                                <th className="single-line">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                /* {editedData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  {Object.entries(row).map(([key, value]) => (
                                    <td key={key}>
                                      <input
                                        type="text"
                                        value={value}
                                        onChange={(e) => handleEdit(e, rowIndex, key)}
                                      />
                                    </td>
                                  ))}
                                  <td>
                                    <button onClick={() => onDeleteClicked(rowIndex)}>Delete</button>
                                  </td>
                                </tr>
                                ))} */
                              }

                              {editedData.map((row, rowIndex) => (
                                <tr key={rowIndex}>



                                  {/* ClientName */}
                                  <td>
                                    <ShortText
                                      id="txtClientName"
                                      name="txtClientName"
                                      ShortTextClasses="form-control"
                                      required={true}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      value={row.ClientName}
                                      onChange={(e) => handleEdit(e, rowIndex, "ClientName")} />
                                  </td>

                                  {/* ClientNumber */}
                                  <td>
                                    <ShortText
                                      id="txtClientNumber"
                                      name="txtClientNumber"
                                      ShortTextClasses="form-control"
                                      required={false}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      value={row.ClientNumber}
                                      onChange={(e) => handleEdit(e, rowIndex, "ClientNumber")} />
                                  </td>

                                  {/* Status */}
                                  <td>
                                    <Selector
                                      // required={applicant.Status.required}
                                      // errorMessage={applicantDetails.validation} 
                                      required={false}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="rdbtnStatus"
                                      name="rdbtnStatus"
                                      SelectorClasses="form-select"
                                      value={row.Status}
                                      options={
                                        <>
                                          <option value="Active">Active</option>
                                          <option value="Deactive">Deactive</option>
                                        </>
                                      }
                                      onChange={(e) => handleEdit(e, rowIndex, 'Status')} />
                                  </td>

                                  {/* Location */}
                                  <td>
                                    <Selector
                                      id="drpLocation"
                                      name="drpLocation"
                                      SelectorClasses="form-select"
                                      default={" -- Select -- "}
                                      options={
                                        <>
                                          {locationOptionsValue}
                                          {/* <option value="138EB11A-4A9E-4C97-8C38-F369B869CB22">Venice (Mucker)</option>
                                          <option value="63A33046-0AAE-4778-9781-81D2056027C5">COMPTON</option>
                                          <option value="838EB11A-4A9E-4C97-8C38-F369B869CB21">BUENA PARK</option> */}
                                        </>
                                      }
                                      required={false}
                                      errorMessage="Please select location"
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      value={row.Location}
                                      onChange={(e) => handleEdit(e, rowIndex, "Location")} />
                                  </td>

                                  {/* Address */}
                                  <td>
                                    <ShortText
                                      // required={applicant && applicant.Address.Required}
                                      required={false}
                                      errorMessage={applicantDetails.AddressError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtAddress"
                                      name="txtAddress"
                                      ShortTextClasses="form-control"
                                      placeholder={applicantDetails ? applicantDetails.AddressPlaceHolder : ""}
                                      value={row.Address}
                                      onChange={(e) => handleEdit(e, rowIndex, 'Address')} />
                                  </td>

                                  {/* City */}
                                  <td>
                                    <ShortText
                                      // required={applicant && applicant.City.Required}
                                      required={false}
                                      errorMessage={applicantDetails.CityError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtCity"
                                      name="txtCity"
                                      ShortTextClasses="form-control"
                                      placeholder={applicantDetails ? applicantDetails.City : ""}
                                      value={row.City}
                                      onChange={(e) => handleEdit(e, rowIndex, 'City')} />
                                  </td>

                                  {/* State */}
                                  <td>
                                    <Selector
                                      // required={applicant.State.required}
                                      required={false}
                                      // errorMessage={applicantDetails.validation} 
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtState"
                                      name="txtState"
                                      SelectorClasses="form-select"
                                      value={row.State.trim()}
                                      options={
                                        <>
                                          <StateOptionsValue />
                                        </>}
                                      onChange={(e) => handleEdit(e, rowIndex, 'State')} />
                                  </td>

                                  {/* Zipcode */}
                                  <td>
                                    <ZipCodeText
                                      // required={applicant && applicant.ZipCode.Required}
                                      required={false}
                                      errorMessage={applicantDetails.ZipCodeError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtZipcode"
                                      name="txtZipcode"
                                      ZipCodeTextClasses="form-control"
                                      value={row.ZipCode}
                                      onChange={(e) => handleEdit(e, rowIndex, 'ZipCode')} />
                                  </td>

                                  {/* CompanyWebsite */}
                                  <td>
                                    <ShortText
                                      id="txtCompanyWebsite"
                                      name="txtCompanyWebsite"
                                      ShortTextClasses="form-control"
                                      required={false}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      value={row.CompanyWebsite}
                                      onChange={(e) => handleEdit(e, rowIndex, "CompanyWebsite")} />
                                  </td>

                                  {/* CompanyPhoneNumber */}
                                  <td>
                                    <PhoneText
                                      id={"txtPhoneNumber" + rowIndex}
                                      name={"txtPhoneNumber" + rowIndex}
                                      PhoneTextClasses="form-control"
                                      placeholder="Please enter phone number"
                                      required={false}
                                      errorMessage={"Please enter phone number"}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      value={row.ClientPhone}
                                      onChange={(e) => handleEdit(e, rowIndex, "PhoneNumber")} />
                                  </td>







                                  <td>
                                    <i className='fa fa-trash ps-3' onClick={(e) => onDeleteClicked(rowIndex)}></i>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>



                </div>
                <div className="modal-footer">
                  {(saveClientImportStatus === fetchStatus.LOADING) ? <EllipsisLoader /> : <>
                    <CancelButton
                      CancelButtonClasses="me-2"
                      onCancelClicked={() => onCloseClicked()} />
                    <SaveButton
                      onSaveClicked={() => onSaveClicked()} />
                  </>}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show">
          </div>
        </>
        :
        <></>
      }
    </>
  )
}

export default ClientImportModalPopUp