import React from 'react';
import {  useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import meteorRainImg from './../../../assets/images/clipart/meteor-rain.gif';

const Congratulations = () => {
  const myState = useSelector((state) => state.accounts.registerDetails)
  const history = useHistory();

  const onFormSubmitted = (e) => {
    e.preventDefault();
    // console.log(myState);
    history.push('/personal_info_1');
  }

  // let allFormData = useSelector((state) => state);
  // console.log(allFormData);

  return (
    <div>
      <div className="container justify-content-center col-12 col-md-6 congratulations">
        <form onSubmit={onFormSubmitted}>
          <div className="animate__animated animate__fadeIn">
            <div className="card card-wizard">
              <div className="card-body">
                <div className="row p-2">
                  <div className="col-md-12 justify-content-center">
                    <center>
                      <img src={meteorRainImg} alt="no photo" style={{width:"20%"}} />
                      <div className="animate__animated animate__zoomIn">
                        <h3 className="pt-5">
                          Congratulations</h3>
                      </div>
                    </center>
                  </div>
                </div>
                <div className="row p-2">
                  <div className="col-md-12">
                    <center>
                      <div className="animate__animated animate__headShake">
                        <p>Now you are part of Hirebase. Login to our web portal
                          to hire our experts.</p>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
};

export default Congratulations; 