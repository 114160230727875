import React from 'react';
import ReactTooltip from 'react-tooltip';

const DialCallButton = ({ phoneNumber, dialCallButtonClasses }) => {

    const handleCallButtonClick = (e) => {
        e.stopPropagation();
        console.log('Calling', phoneNumber);
        // Example: window.location.href = `tel:${phoneNumber}`;
    };

    return (

        // <button onClick={handleCallButtonClick}>
        //     <i class="fa-solid fa-phone"></i>
        // </button>
        <>
            <div className={`dial-call-button ${dialCallButtonClasses && dialCallButtonClasses}`} onClick={(e) => handleCallButtonClick(e)}>
                <i className="fa-solid fa-phone" data-tip='Dial call'  title='Dial call' ></i>
            </div>
            <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
        </>
    );
};

export default DialCallButton;