import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CheckBox from '../../Controls/custom/checkers/checkbox';


function WorkStatusFilter(props) {
    const [open, setOpen] = useState(false);
    const workStatusFilter = useSelector(props.workStatusFilterSelector);
    const wsfContainerRef = useRef(null);

    let _workStatuses = props.statuses;

    let workStatusesList;
    let isworkStatusesSelected = (workStatusFilter.value && Array.isArray(workStatusFilter.value) && workStatusFilter.value.length > 0);
    if (_workStatuses && Array.isArray(_workStatuses) && _workStatuses.length > 0) {
        workStatusesList = _workStatuses.map((workStatus, index) => (
            <li key={workStatus.value} className="filter-list-item"
                onClick={(e) => props.onWorkStatusChanged && props.onWorkStatusChanged(e, workStatus.value, _workStatuses)}>
                <CheckBox
                    id={`ckbWorkStatus-${workStatus.value}`}
                    size="Small"
                    checked={isworkStatusesSelected ? workStatusFilter.value.includes(workStatus.value) : false} />
                <span className={`ms-3 ${isworkStatusesSelected && workStatusFilter.value.includes(workStatus.value) ? "filter-selected" : ""}`}>{workStatus.text}</span>
            </li>
        ));
    }

    useEffect(() => {
        if (open && wsfContainerRef && wsfContainerRef.current) {
            wsfContainerRef.current.focus();
        }
    });

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Work Status</span> :
                <span className="filter-value">{workStatusFilter.value ? workStatusFilter.text : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={wsfContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    {workStatusesList}
                </ul>
            </div> : <></>}
        </div>
    );
}

export default WorkStatusFilter;