import {
    createSlice,
    createAsyncThunk,
    nanoid
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { Operation } from '../../../common/constants';
import Collection from '../../../component/Controls/paginators/collection';
import { refreshCreated } from '../../../component/Controls/paginators/services/createdResource';
import { refreshRemoved } from '../../../component/Controls/paginators/services/removedResource';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON, isObjectArray } from '../../../utilities/utilityFunctions';

const initialState = {
    form: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    clientOptions: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    removal: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    },
    show: false,
    createMode: false,
    editMode: false,
};

export const getClientOptionsAll = createAsyncThunk('locations/getClientOptionsAll', async (clientModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', clientModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let options;
    if (isJSON(data)) {
        options = JSON.parse(data);
    }
    return {
        options: options,
        success: response.data.success
    };
});

export const getAllClients = createAsyncThunk('clients/getAllClients', async (clientModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', clientModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let clients;
        if (isJSON(data)) {
            clients = JSON.parse(data);
        }
        return {
            page: clientModel.page,
            clients: clients,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getClientById = createAsyncThunk('clients/getClientById', async (clientModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, clientModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let client = undefined;
        if (data && isJSON(data)) {
            client = JSON.parse(data);
        }
        return {
            page: clientModel.page,
            client: client,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getClientForm = createAsyncThunk('clients/getClientForm', async (clientModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, clientModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let form = undefined;
        if (data && isJSON(data)) {
            form = JSON.parse(data);
        }
        return {
            form: form,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const createClient = createAsyncThunk('clients/createClient', async (clientModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, clientModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let client = undefined;
        if (data && isJSON(data)) {
            client = JSON.parse(data);
        }
        return {
            client: client,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const updateClient = createAsyncThunk('clients/updateClient', async (clientModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, clientModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let client = undefined;
        if (data && isJSON(data)) {
            client = JSON.parse(data);
        }
        return {
            client: client,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const removeClient = createAsyncThunk('clients/removeClient', async (clientModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, clientModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        return {
            id: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const clientSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateRemovalStatus: (state) => {
            state.removal.status = fetchStatus.IDLE;
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateSingleData: (state, _data) => {
            state.single.refreshed = nanoid();
            state.single.data = _data.payload ? Object.assign({}, _data.payload.client) : _data.payload;
            if (_data.payload && _data.payload.client) {
                if (_data.payload.operation === Operation.UPDATED) {
                    let _item = state.data.records.Get(state.data.currentPage);
                    if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                        let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.client.id);
                        let newRecords = _item.records;
                        let _client = {
                            id: _data.payload.client.id,
                            ClientName: _data.payload.client.ClientName.Data,
                            ClientNumber: _data.payload.client.ClientNumber.Data,
                            Status: _data.payload.client.Status.Data,
                            Location: _data.payload.client.Location.Data
                        };
                        newRecords[_updatedObjectIndex] = _client;
                        _item.records = newRecords;
                        let records = new Collection();
                        records.Add(_item.key, newRecords);
                        state.data.records.Remove(_item.key);
                        records.Concat(state.data.records);
                        state.data.records = records;
                    }
                }
            }
        },
        createNewClient: state => {
            state.single.data = {
                "ClientName": {
                    "Id": "txtClientName",
                    "Data": ""
                },
                "ClientNumber": {
                    "Id": "txtClientNumber",
                    "Data": ""
                },
                "Status": {
                    "Id": "drpStatuses",
                    "Data": ""
                },
                "Location": {
                    "Id": "drpLocations",
                    "Data": ""
                },
                "Address": {
                    "Id": "txtAddress",
                    "Data": ""
                },
                "City": {
                    "Id": "txtCity",
                    "Data": ""
                },
                "State": {
                    "Id": "txtState",
                    "Data": ""
                },
                "ZipCode": {
                    "Id": "txtZipCode",
                    "Data": ""
                },
                "CompanyWebsite": {
                    "Id": "txtCompanyWebsite",
                    "Data": ""
                }
            };
        },
        showClientFlyout: (state, action) => {
            const { createMode, editMode } = action.payload;
            state.createMode = createMode;
            state.editMode = editMode;
            state.show = true;
        },
        closeClientFlyout: (state, action) => {
            state.createMode = false;
            state.editMode = false;
            state.show = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllClients.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        })
        // .addCase(getAllClients.fulfilled, (state, action) => {
        //     state.data.totalItems = action.payload.clients.totalItems;
        //     let _records = new Collection();
        //     _records.Add(action.payload.page, action.payload.clients.items);
        //     if (!state.data.isFiltered) {
        //         _records.Concat(state.data.records);
        //     }
        //     else {
        //         state.data.firstRecords = new Collection();
        //         state.data.currentPage = 0;
        //     }
        //     state.data.isFiltered = false;
        //     state.data.records = _records;
        //     state.data.status = fetchStatus.SUCCEEDED;
        //     toaster.success(action.payload.success);
        // })
        .addCase(getAllClients.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.clients.totalItems;
            state.data.items = action.payload.clients.items;
            // let _records = new Collection();
            // _records.Add(action.payload.page, action.payload.clients.items);
            // if (!state.data.isFiltered) {
            //     _records.Concat(state.data.records);
            // }
            // else {
            //     state.data.firstRecords = new Collection();
            //     state.data.currentPage = 0;
            // }
            state.data.isFiltered = false;
            // state.data.records = _records;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        })
        .addCase(getAllClients.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.data.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getClientById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getClientById.fulfilled, (state, action) => {
            state.single.data = action.payload.client;
            if (action.payload.page >= 0) {
                let _records = new Collection();
                _records.Add(action.payload.page, action.payload.client);
                _records.Concat(state.data.firstRecords);
                state.data.firstRecords = _records;
            }
            state.single.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getClientById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(createClient.pending, (state, action) => {
            state.creation.status = fetchStatus.LOADING;
        }).addCase(createClient.fulfilled, (state, action) => {
            if (action.payload.client) {
                state.single.data = action.payload.client;
                let _client = {
                    id: action.payload.client.id,
                    ClientName: action.payload.client.ClientName.Data,
                    ClientNumber: action.payload.client.ClientNumber.Data,
                    Status: action.payload.client.Status.Data,
                    Location: action.payload.client.Location.Data
                };
                const created = refreshCreated(state.data.records, _client);
                state.data.records = created.records;
                state.data.currentPage = created.lastPage;
                state.data.totalItems += 1;
                state.single.refreshed = nanoid();
            }
            state.creation.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createClient.rejected, (state, action) => {
            state.creation.status = fetchStatus.FAILED;
            state.creation.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateClient.pending, (state, action) => {
            state.modification.status = fetchStatus.LOADING;
        }).addCase(updateClient.fulfilled, (state, action) => {
            state.single.data = action.payload.client;
            state.modification.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateClient.rejected, (state, action) => {
            state.modification.status = fetchStatus.FAILED;
            state.modification.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(removeClient.pending, (state, action) => {
            state.removal.status = fetchStatus.LOADING;
        }).addCase(removeClient.fulfilled, (state, action) => {
            const removed = refreshRemoved(state.data.records, action.payload.id, state.data.currentPage);
            state.data.records = removed.records;
            state.data.currentPage = removed.lastPage;
            state.data.totalItems -= 1;
            state.single.refreshed = nanoid();
            state.removal.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(removeClient.rejected, (state, action) => {
            state.removal.status = fetchStatus.FAILED;
            state.removal.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getClientForm.pending, (state, action) => {
            state.form.status = fetchStatus.LOADING;
        }).addCase(getClientForm.fulfilled, (state, action) => {
            state.form.data = action.payload.form;
            state.form.status = fetchStatus.SUCCEEDED;
        }).addCase(getClientForm.rejected, (state, action) => {
            state.form.status = fetchStatus.FAILED;
            state.form.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getClientOptionsAll.pending, (state, action) => {
            state.clientOptions.status = fetchStatus.LOADING;
        }).addCase(getClientOptionsAll.fulfilled, (state, action) => {
            let _options = action.payload.options;
            _options = _options.map(function (option) {
                option['value'] = option['id'];
                delete option['id'];
                option['text'] = option['ClientName'];
                delete option['ClientName'];
                return option;
            });
            state.clientOptions.options = _options;
            state.clientOptions.status = fetchStatus.SUCCEEDED;
            state.clientOptions.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getClientOptionsAll.rejected, (state, action) => {
            state.clientOptions.status = fetchStatus.FAILED;
            state.clientOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });

    }
});

export const { updateCreationStatus, updateModificationStatus, updateRemovalStatus,
    updateSingleStatus, updateIsFiltered, updateStatus, loadSingleData,
    updateCurrentPage, updateSingleData, createNewClient, showClientFlyout, closeClientFlyout } = clientSlice.actions;

export default clientSlice.reducer

// export const selectAllClients = state => state.clients.data.records;
export const selectAllClients = state => state.clients.data.items;

export const selectTotalItems = state => state.clients.data.totalItems;

export const selectStatus = state => state.clients.data.status;

export const selectError = state => state.data.clients.error;

export const selectCreationStatus = state => state.clients.creation.status;

export const selectModificationStatus = state => state.clients.modification.status;

export const selectRemovalStatus = state => state.clients.removal.status;

export const selectCreationError = state => state.clients.creation.error;

export const selectModificationError = state => state.clients.modification.error;

export const selectRemovalError = state => state.clients.removal.error;

export const selectClientById = (state) => {
    return state.clients.single ? state.clients.single.data : undefined;
}

export const selectSingleStatus = state => state.clients.single.status;

export const selectSingleError = state => state.clients.single.error;

export const selectCurrentPage = state => state.clients.data.currentPage;

export const selectFirstRecord = (state, currentPage) => {
    return state.clients.data.firstRecords.Get(currentPage);
}

export const selectSingleRefreshed = state => state.clients.single.refreshed;

export const selectClientFormStatus = state => state.clients.form.status;

export const selectClientFormError = state => state.clients.form.error;

export const selectClientForm = state => state.clients.form.data;

export const selectClientOptions = state => state.clients.clientOptions.options;

export const selectClientOptionsStatus = state => state.clients.clientOptions.status;

export const selectClientOptionsRefreshed = state => state.clients.clientOptions.refreshed;

export const selectClientFlyoutShow = state => state.clients.show;

export const selectClientFlyoutCreateMode = state => state.clients.createMode;

export const selectClientFlyoutEditmode= state => state.clients.editMode;