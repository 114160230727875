import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import boyFaceImg from './../../../assets/images/clipart/boy-face.svg';
import cardViewImg from './../../../assets/images/clipart/card-view.svg';
import cardViewLightImg from './../../../assets/images/clipart/card-view-light.svg';
import listViewImg from './../../../assets/images/clipart/list-view.svg';
import listViewLightImg from './../../../assets/images/clipart/list-view-light.svg';

import PeopleCard from "../../../component/Card/PeopleCard";
import PeopleHorizontalCard from "../../../component/Card/PeopleHorizontalCard";
import PeopleMoreFilters from "../../../component/Filters/PeopleMoreFilters";

import mapLocationImg from './../../../assets/images/clipart/map-location.svg';
import userActiveImg from './../../../assets/images/clipart/user-active.svg';
import bagCheckImg from './../../../assets/images/clipart/bag-check.svg';
import bagLockImg from './../../../assets/images/clipart/bag-lock.svg';
import filesTextImg from './../../../assets/images/clipart/files-text.svg';
import calendarImg from './../../../assets/images/clipart/calendar.svg';
import dateStartImg from './../../../assets/images/clipart/date-start.svg';
import dateEndImg from './../../../assets/images/clipart/date-end.svg';
import AvatarGroup from "../../../component/Avatar/AvatarGroup";
import JobOrderHorizontalCard from "../../../component/Card/JobOrderHorizontalCard";
import JobOrderCard from "../../../component/Card/JobOrderCard";
import Selector from "../../../component/Controls/custom/selectors/selectors";

import { updateLayout } from "../../../component/layouts/reducerSlices/layoutSlice";
import Collection from "../../../component/Controls/paginators/collection";
import { createNewJob, getAllJobs, getClients, loadSingleData, selectAllJobs, selectClients, selectClientsStatus, selectCurrentPage, selectStatus, selectTotalItems, updateCurrentPage, updateIsFiltered, updateRecentClickedJob, updateSingleData, updateStatus } from "../reducerSlices/jobSlice";
import { fetchStatus, tenantId } from "../../../api/client";
import { accountService } from "../../accounts/services/accountService";
import { selectClientFilter, selectJobStatusFilter, selectLocationFilter, selectPositionFilter, selectWhereClauseChanged, selectRefreshData, selectWhereClause, updateJobRefreshData, updateLocationFilter, selectJobDateFilter, updateJobFilter, selectFilterId, selectDefaultFilter } from "../../../component/Controls/filters/reducerSlices/jobFilterSlice";
import { selectLocationOptions } from "../../locations/reducerSlices/locationSlice";

import { selectSort } from '../../../component/Controls/dataSorters/reducerSlices/jobSorterSlice';
import JobSorter from "../../../component/Controls/dataSorters/jobSorter";
import ToolBar from "../../../component/Controls/custom/toolBar";
import LocationFilter from "../../../component/Controls/filters/locationFilter";
import { onJobClientChanged, onJobLocationChanged, onJobPositionChanged, onJobStatusChanged } from "../services/jobFilterHandler";
import ClientFilter from "../../../component/Controls/filters/clientFilter";
import { isObjectArray } from "../../../utilities/utilityFunctions";
import { _getDateText } from "../../../common/common";
import PositionFilter from "../../../component/Controls/filters/positionFilter";
import JobStatusFilter from "../../../component/Controls/filters/jobStatusFilter";
import JobDateFilter from "../../../component/Controls/filters/jobDateFilter";
import { getUserFilters, removeFilter, removeUserFilter, selectJobDefaultFilters, selectRemoveFilterId, selectRemoveFilterStatus, selectUserFilters, selectUserFiltersStatus } from "../../reviewer/reducerSlices/reviewerSlice";
import EllipsisLoader from "../../../component/Controls/loaders/ellipsisLoader";
import { FilterType } from "../../../common/constants";
import { showJSFModal } from "../../../component/Controls/modals/reducerSlices/saveJobFilterModalPopUpSlice";
import PageContainer from "../../../component/Controls/paginators/paginator/pageContainer";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { getPositionOptions, selectPositionOptions, selectPositionOptionsStatus } from "../../setting/reducerSlices/positionsSlice";
import { getStatusOptionsAll, selectStatusOptions, selectStatusOptionsStatus } from "../../setting/reducerSlices/statusesSlice";


function JobOrder() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [jobData, setJobData] = useState('');
  const [jobDataHorizontalCard, setJobDataHorizontalCard] = useState('');

  const [applyMoreFilters, setApplyMoreFilters] = useState(false);

  const [jobListView, setJobListView] = useState(true);

  const [pageLoaded, setPageLoaded] = useState(false);
  const [jobOrderLoaded, setJobOrderLoaded] = useState(false);


  // const itemsPerPage = 10;
  const JOB_FORM = "Jobform";

  const [searchTerm, setSearchTerm] = useState("");
  const [jobRecords, setJobRecords] = useState(new Collection());
  const [jobRecordsHorizontalCard, setJobRecordsHorizontalCard] = useState(new Collection());

  const totalPageItems = useSelector(selectTotalItems);
  const jobs = useSelector(selectAllJobs);
  const jobsRequestStatus = useSelector(selectStatus);

  const currentPage = useSelector(selectCurrentPage);

  const whereClauseChanged = useSelector(selectWhereClauseChanged);
  const whereClause = useSelector(selectWhereClause);
  const sort = useSelector(selectSort);

  const refreshData = useSelector(selectRefreshData);

  const locationOptions = useSelector(selectLocationOptions);
  const [isFiltered, setIsFiltered] = useState(false);

  const [dummyCards, setDummyCards] = useState([]);
  const [dummyHorizontalCards, setDummyHorizontalCards] = useState([]);

  const positionOptions = useSelector(selectPositionOptions);
  const positionOptionsStatus = useSelector(selectPositionOptionsStatus);

  const statusOptions = useSelector(selectStatusOptions);
  const statusOptionsStatus = useSelector(selectStatusOptionsStatus);

  useEffect(() => {
    if (positionOptionsStatus === fetchStatus.IDLE) {
      dispatch(getPositionOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETPOSITIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [positionOptionsStatus]);

  useEffect(() => {
    if (statusOptionsStatus === fetchStatus.IDLE) {
      dispatch(getStatusOptionsAll({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSTATUSOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [statusOptionsStatus]);

  useEffect(() => {
    if (refreshData) {
      dispatch(updateIsFiltered());
      _loadJobs(0, 0, 10, whereClause, sort);
      dispatch(updateJobRefreshData(false));
    }
  }, [whereClauseChanged, whereClause, refreshData]);

  useEffect(() => {
    if (jobsRequestStatus === fetchStatus.IDLE || jobOrderLoaded === false) {
      const user = accountService.getAuthenticatedUser();
      let loc = user ? user.locationId : "";
      let where = JSON.parse(JSON.stringify(whereClause));
      where.location.value.push(loc);
      dispatch(updateLocationFilter({
        locations: locationOptions,
        value: loc
      }));
      dispatch(updateIsFiltered());
      _loadJobs(0, 0, 10, where, sort);
    }
    setJobOrderLoaded(true);
  }, [jobsRequestStatus, dispatch]);

  let jobCards;
  let jobCardHorizontalCard;
  let _dummyCards = [];
  let _dummyHorizontalCards = [];

  let _jobRecords = new Collection();
  let _jobRecordsHorizontalCard = new Collection();

  const onClickJobEdit = () => {
    history.push('jobOrder/job')
  }

  // const onJobSelected = (id) => {
  //   history.push('jobOrder/job')
  // }

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 5,
        title: "Job Orders"
      }));
      setPageLoaded(true);
    }
  });

  const _loadJobs = async (page, offset, limit, whereClause = undefined, sort) => {
    setIsFiltered(false);
    await dispatch(getAllJobs({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETJOBS",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: offset,
            limit: limit,
            where: whereClause,
            sort: sort
          })
        }
      }
    }));

  }

  useEffect(() => {
    for (let i = 0; i < 10; i++) {
      _dummyCards.push(
        <div className="job-order-card">
          <div className="job-order-card-body d-block p-4">
            <ReactPlaceholder showLoadingAnimation={true} type='media' ready={false} rows={5}>
            </ReactPlaceholder>
          </div>
        </div>
      );
      _dummyHorizontalCards.push(
        <div className="job-order-horizontal-card">
          <div className="job-order-horizontal-card-container p-3">
            <ReactPlaceholder showLoadingAnimation={true} type='media' ready={false} rows={3}>
            </ReactPlaceholder>
          </div>
        </div>
      );
    }
    setDummyCards(_dummyCards);
    setDummyHorizontalCards(_dummyHorizontalCards);
  }, []);

  useEffect(() => {
    if (jobsRequestStatus === fetchStatus.SUCCEEDED) {
      let _records = jobs.Items;


      if (jobs.Items && jobs.Items.length > 0) {
        for (let i = 0; i < jobs.Items.length; i++) {
          let item = jobs.Items[i];
          jobCards = item.records.map((job, index) => (
            console.log('job ----->', job),
            <JobOrderCard
              key={job.id}
              jobCardClasses="mb-2"
              name={job.Title}
              address={job.Address}
              requestDate={_getDateText(job.RequestDate)}
              startDate={_getDateText(job.StartDate)}
              startTime={job.StartTime}
              endDate={_getDateText(job.EndDate)}
              endTime={job.EndTime}
              status={job.Status}
              filled={job.Filled}
              total={job.Total}
              reportTo={job.ReportTo}
              numberOfActivePeople={job.NumberOfActivePeople}
              applicantDetails={job.ApplicantDetails}
              onClick={() => onClickJob(job.id)}
            />
          ));
          _jobRecords.Add(item.key, jobCards);
          setJobData(jobCards);

          jobCardHorizontalCard = item.records.map((job, index) => (
            <JobOrderHorizontalCard
              key={job.id}
              jobCardClasses="mb-2"
              name={job.Title}
              address={job.Address}
              requestDate={_getDateText(job.RequestDate)}
              startDate={_getDateText(job.StartDate)}
              startTime={job.StartTime}
              endDate={_getDateText(job.EndDate)}
              endTime={job.EndTime}
              status={job.Status}
              filled={job.Filled}
              total={job.Total}
              reportTo={job.ReportTo}
              numberOfActivePeople={job.NumberOfActivePeople}
              onClick={() => onClickJob(job.id)}
            />
          ));
          _jobRecordsHorizontalCard.Add(item.key, jobCardHorizontalCard);
          setJobDataHorizontalCard(jobCardHorizontalCard);
        }

        setJobRecords(_jobRecords);
        setJobRecordsHorizontalCard(_jobRecordsHorizontalCard);
        dispatch(updateStatus());
      }
      dispatch(updateStatus());
    }
    // if (jobs && jobs.Items.length > 0) {
    //     let item = jobs.Get(currentPage);
    //     let _jobs = item ? item.records : [];
    //     if (_jobs && Array.isArray(_jobs) && _jobs.length > 0) {
    //         let _job = _jobs[0];
    //         if (_job && _job.id) {
    //             if (firstJob && firstJob.records) {
    //                 dispatch(loadSingleData(firstJob.records));
    //             }
    //             else {
    //                 _loadJob(_job.id, currentPage);
    //             }
    //         }
    //     }
    //     else {
    //         dispatch(updateSingleData(undefined));
    //     }
    // }
  }, [jobsRequestStatus, currentPage]);


  const onClickJob = (job) => {
    dispatch(updateRecentClickedJob(job));
    // alert(job);
    history.push('jobOrder/job');
  }

  const onAddRecordClicked = (e) => {
    // setCreatemode(true);
    // setTempJobId(job ? job.id : "");//job.id);
    // setSelectedTab(0);
    dispatch(createNewJob());
    dispatch(updateRecentClickedJob(0));
    history.push('jobOrder/job');
  };

  const onSorted = async (sortBy, ascending) => {
    await dispatch(updateIsFiltered());
    _loadJobs(0, 0, 10, whereClause, {
      by: sortBy,
      ascending: ascending
    });
  };

  const _loadClients = async (locationId) => {
    await dispatch(getClients({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCLIENTSFORSTATUSUPDATE",
      payload: {
        id: "",
        data: JSON.stringify({
          where: {
            clientStatus: "",
            location: locationId
          },
          excludeNumber: true
        })
      }
    }));
  }

  const clientsStatus = useSelector(selectClientsStatus);
  const clients = useSelector(selectClients);

  const [clientOptions, setClientOptions] = useState();
  const [clientOptionsValue, setClientOptionsValue] = useState();

  // useEffect(() => {
  //   if (clientsStatus === fetchStatus.IDLE && job && job.Location.Data) {
  //     _loadClients(job.Location.Data);
  //   }
  // }, [clientsStatus, job]);
  useEffect(() => {
    if (clientsStatus === fetchStatus.IDLE) {
      _loadClients(selectLocationFilter);
    }
  }, [clientsStatus]);

  let _clientOptionsValue;

  useEffect(() => {
    let _clientOptions;
    if (isObjectArray(clients)) {
      _clientOptions = clients.map((client, index) => (
        {
          text: client.ClientName,
          value: client.id,
        }
      ));
      _clientOptionsValue = clients.map((client, index) => (
        <option value={client.id}>{client.ClientName}</option>
      ));
      setClientOptions(_clientOptions);
      setClientOptionsValue(_clientOptionsValue);
    }
  }, [clients]);

  const onFilterClicked = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    dispatch(updateJobFilter({
      id: "",
      name: "",
      whereClause: whereClause,
      defaultFilter: false
    }));
    _loadJobs(0, 0, 10, whereClause, sort);
  };

  const defaultFilter = useSelector(selectDefaultFilter);

  const jobDefaultFilters = useSelector(selectJobDefaultFilters);
  const userFilters = useSelector(selectUserFilters);

  const _filterId = useSelector(selectFilterId);
  const [filterId, setFilterId] = useState("");
  const removeFilterStatus = useSelector(selectRemoveFilterStatus);
  const removeFilterId = useSelector(selectRemoveFilterId);
  const [customJobFilters, setCustomJobFilters] = useState([]);
  const userFiltersStatus = useSelector(selectUserFiltersStatus);

  const onCustomJobFiterClicked = (e, id, defaultFilter) => {
    e.preventDefault();

    let filter;
    if (defaultFilter) {
      filter = jobDefaultFilters.find(f => f.id === id);
    }
    else {
      filter = userFilters.find(f => f.id === id);
    }
    dispatch(updateJobFilter({
      id: filter ? filter.id : "",
      name: filter ? filter.name : "",
      whereClause: filter ? filter.whereClause : undefined,
      defaultFilter: defaultFilter
    }));
    dispatch(updateJobRefreshData(true));
  };

  useEffect(() => {
    setFilterId(_filterId);
  });

  useEffect(() => {
    if (removeFilterStatus === fetchStatus.SUCCEEDED) {
      if (removeFilterId) {
        dispatch(removeUserFilter(removeFilterId));
      }
    }
  }, [removeFilterStatus, removeFilterId]);

  useEffect(() => {
    const id = accountService.getLoggedInUserId();
    if (id) {
      if (userFiltersStatus === fetchStatus.IDLE) {
        dispatch(getUserFilters({
          client: tenantId,
          serviceType: "TENANTADMIN",
          operation: "GETUSERFILTERS",
          payload: {
            id: id,
            data: ""
          }
        }));
      }
    }
  }, [userFiltersStatus]);

  useEffect(() => {
    let _customFilters = [], _customUserFilters = [], _customClientFilters = [], _customJobFilters = [];
    if (userFiltersStatus === fetchStatus.SUCCEEDED) {
      if (userFilters && Array.isArray(userFilters) && userFilters.length > 0) {
        for (let i = 0; i < userFilters.length; i++) {
          let filter = userFilters[i];

          if (filter.filterType === FilterType.JOB) {
            // _customJobFilters.push(<li key={filter.id}
            //   className={`menu-sub-item-link-new ${filterId === filter.id ? "menu-sub-item-link-new-selected" : ""}`}
            //   onClick={(e) => onCustomJobFiterClicked(e, filter.id, false)}>
            //   <span>{filter.name}</span>
            //   <span className="remove-filter-button"
            //     title="Remove filter"
            //     onClick={(e) => onFiterDeleteClicked(e, filter.id, FilterType.CLIENT)}>
            //     <i className="fa fa-times" aria-hidden="true"></i>
            //   </span>
            // </li>);
            _customJobFilters.push(
              <div className="btn-group mx-1 " role="group" aria-label="First group">
                <button type="button" className={`c-btn ${filterId === filter.id ? "" : "c-btn-gray"}`} onClick={(e) => onCustomJobFiterClicked(e, filter.id, false)}>{filter.name}</button>
                <button type="button" className={`c-btn ${filterId === filter.id ? "" : "c-btn-transparent"}`} onClick={(e) => onFiterDeleteClicked(e, filter.id, FilterType.CLIENT)} title="Remove filter"><i className="fa fa-trash text-danger" aria-hidden="true"></i></button>
              </div>
            );
          }
        }
        setCustomJobFilters(_customJobFilters);
      }
      else {
        setCustomJobFilters(undefined);
      }
    }

  }, [userFiltersStatus, userFilters]);


  const onFiterDeleteClicked = (e, id, filterType) => {
    e.stopPropagation();
    if (id) {
      dispatch(removeFilter({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "REMOVEFILTER",
        payload: {
          id: id,
          data: ""
        }
      }));
    }
  };

  const _getJobId = () => {
    let item = jobs.Get(0);
    let _jobs = item ? item.records : [];
    if (_jobs && Array.isArray(_jobs) && _jobs.length > 0) {
      let _job = _jobs[0];
      if (_job && _job.id) {
        return _job.id
      }
    }
  }

  const onSaveFilterClicked = async (e) => {
    e.preventDefault();
    if (whereClauseChanged && !defaultFilter) {
      const user = accountService.getAuthenticatedUser();
      if (user) {
        await dispatch(showJSFModal({
          title: <><span>Save Filter For</span> <span className="modal-header-name">{user ? user.firstName + " " + user.lastName : "N/A"}</span></>,
          // jobId: job.id
          jobId: _getJobId()
        }));
      }
    }
  };



  const onPageChanged = async (p, l) => {
    dispatch(updateCurrentPage(p));
    if (l) {
      await onLoadItems(p);
    }
  };

  const onLoadItems = async (p) => {
    await _loadJobs(p, p * 10, 10, whereClause, sort);
  };

  return (
    <>
      {/* Filter Bar */}
      <div className="filter-bar p-3">
        <button className={`c-btn mx-1 ${filterId === "A91B6EE5-7A89-418A-AEAE-909742AD305D" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomJobFiterClicked(e, "A91B6EE5-7A89-418A-AEAE-909742AD305D", true)}>New</button>
        <button className={`c-btn mx-1 ${filterId === "CA6C7705-3483-4927-92AC-8E035E4344C0" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomJobFiterClicked(e, "CA6C7705-3483-4927-92AC-8E035E4344C0", true)}>Inactive</button>
        <button className={`c-btn mx-1 ${filterId === "32F3B376-51FB-49BF-81C0-6B5DE5A93826" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomJobFiterClicked(e, "32F3B376-51FB-49BF-81C0-6B5DE5A93826", true)}>In Progress</button>
        <button className={`c-btn mx-1 ${filterId === "2E099E65-39A6-45BF-A4B0-FB278812AFE4" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomJobFiterClicked(e, "2E099E65-39A6-45BF-A4B0-FB278812AFE4", true)}>Filled</button>
        {removeFilterStatus === fetchStatus.LOADING ? <span className="px-1 m-0"><EllipsisLoader /></span> : customJobFilters}
        {<button className="c-btn c-btn-transparent" type="button" onClick={(e) => onSaveFilterClicked(e)} disabled={!(whereClauseChanged && !defaultFilter)} >+ Create New List</button>}

        <div className="float-end">
          {isFiltered &&
            <button type="button" className="c-btn dark-btn position-relative" onClick={(e) => onFilterClicked(e)}>
              <i className="fa fa-filter me-2" aria-hidden="true"></i> Filter
              {/* <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                  <span className="visually-hidden">New alerts</span>
                </span> */}
            </button>
          }

          <button type="button" className="c-btn dark-btn ms-3" onClick={onAddRecordClicked} >
            Add Job
          </button>
        </div>
      </div>

      <div className="filter-bar p-3 pt-0">
        <LocationFilter
          locations={locationOptions}
          locationFilterSelector={selectLocationFilter}
          onLocationChanged={(e, v, locations) => { onJobLocationChanged(e, v, locations); setIsFiltered(true); }}
        />

        <ClientFilter
          clients={clientOptions}
          clientFilterSelector={selectClientFilter}
          onClientChanged={(e, v, locations) => { onJobClientChanged(e, v, locations); setIsFiltered(true); }} />

        <PositionFilter
          positions={positionOptions}
          positionFilterSelector={selectPositionFilter}
          onPositionChanged={(e, v, locations) => { onJobPositionChanged(e, v, locations); setIsFiltered(true); }} />

        <JobStatusFilter
          jobStatusFilterSelector={selectJobStatusFilter}
          onJobStatusChanged={(e, v, locations) => { onJobStatusChanged(e, v, locations); setIsFiltered(true); }} />

        <JobDateFilter
          jobDateFilterSelector={selectJobDateFilter} onJobDateFilter={(e) => { setIsFiltered(true); }} />
        {/* <Selector
              id="drpLocations"
              name="drpLocations"
              SelectorClasses="filter-input d-inline-flex"
              options={
                <React.Fragment>
                  <option value="1">option 1</option>
                  <option value="2">option 2</option>
                  <option value="3">option 3</option>
                </React.Fragment>
              }
              default="Testing UI"
            /> */}

        <div className="float-end">
          <div className="sort-btn-group">
            <button className={`c-btn sort-btn-transparent ${jobListView && 'sort-btn-active'}`} type="button" onClick={() => setJobListView(true)} ><img src={jobListView ? listViewImg : listViewLightImg} /></button>
            <button className={`c-btn sort-btn-transparent ${!jobListView && 'sort-btn-active'}`} type="button" onClick={() => setJobListView(false)}><img src={jobListView ? cardViewLightImg : cardViewImg} /></button>
          </div>
        </div>
      </div>
      {/* Filter Bar End */}

      {/* main section */}

      <div className="right-content">
        <div className="d-flex w-100 p-3 pb-0">
          <div className="align-self-center">
            <div className="job-order-result-text">Showing {totalPageItems} Results <span className="job-order-result-sub-text">(Based your search)</span> </div>
          </div>
          <div className="ms-auto align-self-center">
            <div className="d-flex">
              {/* <select name="modifiedDate" className="me-3">
                    {<option className="" value="">Modified Date</option>}
                    {<option className="" value="">Modified Date</option>}
                  </select>
                  <select name="filled">
                    {<option className="" value="">Filled</option>}
                    {<option className="" value="">Open</option>}
                  </select> */}


              <JobSorter
                onSorted={(s, a) => onSorted(s, a)}
              />
            </div>

          </div>
        </div>

        {/* {
              jobListView ?
                // jobRecords
                peopleData.map((d) => {
                  return <JobOrderHorizontalCard
                    onClickJobEdit={onClickJobEdit}
                  />
                })
                :
                peopleData.map((d) => {
                  return <JobOrderCard
                    onClickJobEdit={onClickJobEdit}
                  />
                })
            } */}

        {/* {
              jobData
            } */}

        {/* {jobListView ?
              jobDataHorizontalCard
              :
              jobData
            } */}

      </div>

      <PageContainer
        currentPage={currentPage}
        totalPageItems={totalPageItems}
        pageRecords={!jobListView ? jobRecords : jobRecordsHorizontalCard}
        isItemsLoading={jobsRequestStatus}
        itemsLoadingContent={!jobListView ? dummyCards : dummyHorizontalCards}
        onPageChanged={(p, l) => onPageChanged(p, l)}
        infinite={true}
        pageRecordsCardClassName={!jobListView ? 'job-order-card' : 'job-order-horizontal-card'}
      />

      {/* End main section */}

      {/* Apply More Filters */}

      {/* {
            applyMoreFilters ?
              <PeopleMoreFilters
                onClose={() => setApplyMoreFilters(false)}
                applyMoreFilters={applyMoreFilters}
              />
              :
              ''
          } */}
    </>
  )
}

export default JobOrder