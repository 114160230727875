import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckBox from '../../Controls/custom/checkers/checkbox';


function LanguageFilter(props) {
    const dispatch = useDispatch();


    const [open, setOpen] = useState(false);

    const languageFilter = useSelector(props.languageFilterSelector);

    const gfContainerRef = useRef(null);

    let _languages = undefined;
    _languages = [
        {
            text: "English",
            value: "English"
        },
        {
            text: "Spanish",
            value: "Spanish"
        },
        {
            text: "Manderin",
            value: "Manderin"
        },
        {
            text: "Cantenise",
            value: "Cantenise"
        },
        {
            text: "Other",
            value: "Other"
        },
    ];

    let languagesList;
    let isLanguagesSelected = (languageFilter.value && Array.isArray(languageFilter.value) && languageFilter.value.length > 0);
    if (_languages && Array.isArray(_languages) && _languages.length > 0) {
        languagesList = _languages.map((language) => (
            <li key={language.value} className="filter-list-item"
                onClick={(e) => props.onLanguageChanged && props.onLanguageChanged(e, language.value, _languages)}>
                <CheckBox
                    id={`ckbLanguage-${language.value}`}
                    size="Small"
                    checked={isLanguagesSelected ? languageFilter.value.includes(language.value) : false} />
                <span className={`ms-3 ${isLanguagesSelected && languageFilter.value.includes(language.value) ? "filter-selected" : ""}`}>{language.text}</span>

            </li>
        ));
    }

    useEffect(() => {
        if (open && gfContainerRef && gfContainerRef.current) {
            gfContainerRef.current.focus();
        }
    });



    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Language</span> :
                <span className="filter-value">{languageFilter.value ? languageFilter.text : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={gfContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    {languagesList}
                </ul>
            </div> : <></>}
        </div>
    );
}

export default LanguageFilter;