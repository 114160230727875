import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    title: "",
    content: "",
    show: false,
    applicant: undefined,
    updatedApplicant: undefined,
};

export const downloadPhotoModalPopUpSlice = createSlice({
    name: 'downloadPhotoModalPopUp',
    initialState,
    reducers: {
        showDPModal: (state, action) => {
            const { title, content, applicant } = action.payload
            state.title = title;
            state.content = content;
            state.applicant = applicant;
            state.show = true;
        },
        closeModal: state => {
            state.title = "";
            state.content = "";
            state.applicant = undefined;
            state.updatedApplicant = undefined;
            state.show = false;
        },
        saveUpdatedApplicant: (state, action) => {
            state.updatedApplicant = action.payload;
        }
    }
});

export const { showDPModal, closeModal, saveUpdatedApplicant } = downloadPhotoModalPopUpSlice.actions;

export default downloadPhotoModalPopUpSlice.reducer

export const selectDownloadPhotoModalPopUpDetail = state => {
    return {
        title: state.downloadPhotoModalPopUp.title,
        content: state.downloadPhotoModalPopUp.content,
        applicant: state.downloadPhotoModalPopUp.applicant,
        updatedApplicant: state.downloadPhotoModalPopUp.updatedApplicant,
        show: state.downloadPhotoModalPopUp.show
    }
}