import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../../api/client';
import { closeModal, getClients, selectClients, selectClientsStatus, selectModalPopUpDetail } from './reducerSlices/PreferredClientModalPopUpSlice';
import Radio from '../custom/checkers/radio';
import InputGroup from '../custom/inputGroup/inputGroup';
import EllipsisLoader from '../loaders/ellipsisLoader';
import Selector from '../custom/selectors/selectors';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import Statement from '../custom/textbox/statement';
import SaveButton from '../custom/buttons/saveButton';
import CancelButton from '../custom/buttons/cancelButton';
import { accountService } from '../../../features/accounts/services/accountService';
import { isFormValid } from '../services/formService';
import { saveStatus, selectStatusChangeStatus } from '../../../features/reviewer/reducerSlices/reviewerSlice';


function PreferredClientModalPopUp(props) {

    const dispatch = useDispatch();
    const statusChangeStatus = useSelector(selectStatusChangeStatus);
    const modalPopUpDetails = useSelector(selectModalPopUpDetail);
    const STATUS_UPDATE_FORM = "PreferredClientUpdateForm";
    const [cl, setCL] = useState("0");
    const [clientOptionsValue, setClientOptionsValue] = useState();
    const [clientId, setClientID] = useState("");
    const clientsStatus = useSelector(selectClientsStatus);
    const clients = useSelector(selectClients);
    const [clientOptions, setClientOptions] = useState(undefined);
    const applicant = modalPopUpDetails.applicant;
    const [statusNote, setStatusNote] = useState("");
    const [sufSubmitted, setSUFSubmitted] = useState(false);
    const [client, setClient] = useState("");


    const onClientChanged = (e, s, v) => {
        setClientID(e.target.value);

    }

    const onCLChanged = (e, v) => {
        setCL(v);
        _loadClients((v === "1" ? "" : applicant.Location.Data));

    }

    const onCloseClicked = () => {
        setStatusNote("");
        setSUFSubmitted(false);
        dispatch(closeModal());
    };

    const _getClientName = (id) => {
        let name = "";
        if (isObjectArray(clients)) {
            let _client = clients.find(c => c.id === id);
            if (_client && _client.ClientName) {
                name = _client.ClientName;
            }
        }
        return name;
    }

    const onSaveClicked = async (id) => {
        const user = accountService.getAuthenticatedUser();
        if (isFormValid(STATUS_UPDATE_FORM)) {
            const statusDetails = {
                status: modalPopUpDetails.status.text,
                log: {
                    TimeStamp: (new Date()).toUTCString(),
                    LogType: "Preferred Client",
                    LogValue: -9,
                    Content: statusNote + " for client " + _getClientName(clientId),
                    PayRates: null,
                    Client: _getClientName(client),
                    ClientId: clientId,
                    UserName: (user ? user.firstName + " " + user.lastName : ""),
                    UserId: (user ? user.id : ""),
                    DispatchDate: "",
                    ClientListType: cl,
                    PostDispatchStatus: "",
                    StatusNote: statusNote
                }
            };

            setSUFSubmitted(false);
            if (id) {
                await dispatch(saveStatus({
                    client: tenantId,
                    serviceType: "TENANTADMIN",
                    operation: "SAVECLIENTPREFERRENCE",
                    payload: {
                        id: id,
                        data: JSON.stringify(statusDetails)
                    }
                }));

            }
        }
        else {
            setSUFSubmitted(true);
        }
    };

    const _loadClients = (locationId) => {
        if (modalPopUpDetails && accountService.isAuthenticated()) {
            if (modalPopUpDetails.status.value === "Active") {
                locationId = ""
            }
            dispatch(getClients({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETCLIENTSFORSTATUSUPDATE",
                payload: {
                    id: modalPopUpDetails.id,
                    data: JSON.stringify({
                        where: {
                            clientStatus: "Active",
                            location: locationId
                        }
                    })
                }
            }));
        }
    };

    let _clientOptionsValue;

    useEffect(() => {
        let _clientOptions;
        if (isObjectArray(clients)) {
            _clientOptions = clients.map((client, index) => (
                {
                    text: client.ClientName,
                    value: client.id,
                }
            ));
            _clientOptionsValue = clients.map((client, index) => (
                client.ClientName.includes('DNR') ?
                    <option value={client.id} className="optionRed">{client.ClientName}</option>
                    : <option value={client.id}>{client.ClientName}</option>

            ));
            setClientOptions(_clientOptions);
            setClientOptionsValue(_clientOptionsValue);
        }
    }, [clients]);

    useEffect(() => {
        if (clientsStatus === fetchStatus.IDLE) {
            _loadClients("");
            if (applicant) {
                if (applicant.dispatchDetails && applicant.dispatchDetails.clientListType === "1") {
                    _loadClients("");
                }
                else {
                    _loadClients(applicant.Location.Data);
                }

            }
        }
    }, [clientsStatus, modalPopUpDetails]);


    useEffect(() => {
        if (statusChangeStatus === fetchStatus.SUCCEEDED) {
            onCloseClicked();
        }
    }, [statusChangeStatus]);

    return (
        <>
            {(modalPopUpDetails && modalPopUpDetails.show) ? (<>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-block">
                                <div className="d-flex">
                                    <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                                    {statusChangeStatus === fetchStatus.LOADING ? <></> : <button
                                        type="button"
                                        className="modal-close-button ms-auto"
                                        onClick={onCloseClicked}>
                                        <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </button>}
                                </div>
                                <div className="d-flex">
                                    <span className="modal-sub-title pt-2 pe-4">Setting a Preferred Client" refers to designating a specific customer as a priority or giving them special treatment in business interactions or services.</span>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div >
                                            <Radio id="rdbtnMC"
                                                name="rdbtnCL"
                                                label="My Clients"
                                                value="0"
                                                onChange={(e, v) => onCLChanged(e, v)}
                                                checked={cl === "0"} />
                                            <Radio id="rdbtnACL"
                                                name="rdbtnCL"
                                                label="All Clients"
                                                value="1"
                                                onChange={(e, v) => onCLChanged(e, v)}
                                                checked={cl === "1"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label id="lblClients" className="field-label pt-2" htmlFor="drpClientsselect">Client</label>
                                        <InputGroup
                                            icon={<i className="fa fa-address-card"></i>}
                                            control={
                                                <Selector
                                                    id="drpClientsselect"
                                                    name="drpClientsselect"
                                                    SelectorClasses="form-control"
                                                    options={
                                                        <>
                                                            {clientOptionsValue}
                                                        </>
                                                    } 
                                                    onChange={(e) => onClientChanged(e, "", null)}
                                                    required={true}
                                                    errorMessage="Please select client"
                                                    form={STATUS_UPDATE_FORM}
                                                    submited={sufSubmitted}
                                                    />
                                            } />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label id="lblStatusNote" className="field-label pt-2" htmlFor="txtStatusNote">Note</label>
                                        <InputGroup
                                            InputGroupClasses="mb-3"
                                            icon={<i className="fa fa-pencil-square-o"></i>}
                                            control={
                                                <Statement
                                                    id="txtStatusNote"
                                                    name="txtStatusNote"
                                                    value={statusNote}
                                                    StatementClasses="form-control"
                                                    onChange={(e) => setStatusNote(e.target.value)}
                                                    required={true}
                                                    errorMessage="Please enter status note"
                                                    form={STATUS_UPDATE_FORM}
                                                    submited={sufSubmitted}
                                                    placeholder="Enter status note..." />
                                            } />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {(statusChangeStatus === fetchStatus.LOADING || clientsStatus === fetchStatus.LOADING) ? <EllipsisLoader /> : <><CancelButton
                                    CancelButtonClasses="mr-2"
                                    onCancelClicked={() => onCloseClicked()} />
                                    <SaveButton
                                        onSaveClicked={() => onSaveClicked(modalPopUpDetails.id)} /></>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </>) : <></>}
        </>
    );
}

export default PreferredClientModalPopUp;