import React, { useEffect, useState } from 'react';
import useUrlValidator from '../../../hooks/useUrlValidator';

function UrlText(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);
    const [isUrlValid, setIsUrlValid] = useState(true);
    let urlValid = useUrlValidator(value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setIsUrlValid(urlValid);
    }, [props.value, urlValid]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
        }
        else {
            setInvalid(false);
        }
    }, [value, props.required, edited, props.submited, props.disabled]);

    const onChange = (e) => {
        props.onChange && props.onChange(e);
        setValue(e.target.value);
        setEdited(true);
    };

    return (
        <>
            <input
                type="text"
                id={props.id}
                name={props.name}
                value={value}//props.value ? props.value : ""}
                // className={`c-form-control ${invalid && "e-error"}`}
                className={`${props.UrlTextClasses ? props.UrlTextClasses : ""} ${invalid && "is-invalid"}`}
                placeholder={`${props.placeholder ? props.placeholder : ""}`}
                onChange={(e) => onChange(e)}
                disabled={props.disabled === true}
                formNoValidate={true}
                data-form={props.form ? props.form : ""}
                data-invalid={invalid}
                data-required={props.required ? props.required : false}
            />
            {
                invalid && <span className="error-label">
                    <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            }
            {
                (value && !isUrlValid) && <span className="error-label">
                    <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                    Please enter valid url address
                </span>
            }
        </>
    );
}

export default UrlText;