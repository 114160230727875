import * as React from 'react';
import './css/button.css';


function ContinueButton(props) {
    return (
        <button
            type="button"
            className={`btn  continue-button ${props.BackButtonClasses ? props.BackButtonClasses : ""}`}
            title={props.title ? props.title : "Click to continue"}
            onClick={props.onClick}
            disabled={props.disabled === true}
        >
            {props.text ? props.text : "Continue"}
        </button>
    );
}

export default ContinueButton;