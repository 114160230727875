export const sections = {
    EXPERIENCE_DETAILS: 0,
    EXPERIENCE_OPTIONS_DETAILS: 1
};

export const onValueChanged = (experience, e, section, value) => {
    let _experience = JSON.parse(JSON.stringify(experience));
    console.log('experience', experience)
    console.log('section', section)
    console.log('e', e)
    console.log('value', value)
    if (section == sections.EXPERIENCE_DETAILS) {
        return _saveExperienceDetails(_experience, e, value);
    } else if (section === sections.EXPERIENCE_OPTIONS_DETAILS) {
        return _saveExperienceOptionsDetails(_experience, e, value);
    }
};

const _saveExperienceDetails = (experience, e, value) => {
    if (e.target.name === "txtExperienceName") {
        experience.ExperienceName.Data = e.target.value.trim();
    }
    else if (e.target.name === "drpStatuses") {
        if (!experience.Status) {
            experience.Status = {
                "Id": "drpStatuses",
                "Data": ""
            }
        }
        experience.Status.Data = e.target.value;
    }
    else if (e.target.name === "drpType") {
        if (e.target.value === "Single Choice" || e.target.value === "Multi Choice") {
            // experience.ExperienceOptions = ['']
        } else {
            experience.ExperienceOptions = ['']
        }
        experience.Type.Data = e.target.value;
    }
    else if (e.target.name === "drpSkillGroupId") {
        if (!experience.SkillGroupId) {
            experience.SkillGroupId = {
                "Id": "drpSkillGroupId",
                "Data": ""
            }
        }
        experience.SkillGroupId.Data = e.target.value;
    }

    return experience;
};

const _saveExperienceOptionsDetails = (experience, e, value) => {
    let nameParts = e.target.name.split('-');
    const name = nameParts[0];
    const index = nameParts[1];
    const newOptions = [...experience.ExperienceOptions];
    newOptions[index] = e.target.value.trim();
    experience.ExperienceOptions = newOptions

    return experience;
};