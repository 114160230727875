import {
    createAsyncThunk,
    createSlice
}
    from '@reduxjs/toolkit';
import { BajoAPI, fetchStatus } from '../../../../api/client';
import { getAxiosRequestConfig } from '../../../../common/common';
import { isJSON } from '../../../../utilities/utilityFunctions';
import { toaster } from '../../toasts/toaster';

const initialState = {
    title: "",
    id: "",
    show: false
};

export const prospectsStatusUpdateModelPopUpSlice = createSlice({
    name: 'prospectsStatusUpdateModelPopUp',
    initialState,
    reducers: {
        showPSUModal: (state, action) => {
            const { title, id } = action.payload;
            state.title = title;
            state.id = id;
            state.show = true;
        },
        closeModal: state => {
            state.title = "";
            state.id = "";
            // state.statusNote = undefined;
            state.show = false;
        },
        saveUpdatedLead: (state, action) => {
            state.updatedLead = action.payload;
        }
    }
});

export const { showPSUModal, closeModal, saveUpdatedLead } = prospectsStatusUpdateModelPopUpSlice.actions;

export default prospectsStatusUpdateModelPopUpSlice.reducer

export const selectModalPopUpDetail = state => {
    return {
        title: state.prospectsStatusUpdateModelPopUp.title,
        id: state.prospectsStatusUpdateModelPopUp.id,
        // statusNote: state.prospectsStatusUpdateModelPopUp.statusNote,
        show: state.prospectsStatusUpdateModelPopUp.show
    }
}