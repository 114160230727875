import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import nophoto from './../../assets/images/clipart/nophoto.png';
import ProspectsPeopleDetailCard from "../../component/Card/ProspectsPeopleDetailCard";
import InputGroup from "../../component/Controls/custom/inputGroup/inputGroup";
import {
  getApplicantById, selectApplicantById, updateSingleData,
  selectSingleStatus as applicantStatus,
  selectRecentClickedApplicant,
  selectJobsByApplicantId,
  selectJobsApplicantStatus,
  getJobsByProspectsApplicantId,
  selectModificationStatus,
  selectRemovalStatus,
  addProspectsToPeople,
  selectConvertToPeopleStatus
} from "./reducerSlices/prospectsPeopleSlice";
import { useDispatch, useSelector } from "react-redux";
import { onValueChanged, sections as _sections } from '../applicant/services/applicantSaver';
import { tenantId, fetchStatus, masterTenantId } from "../../api/client";

import {
  isAgeOver18,
  toLocalDate,
  _getDateText,
  _getLocationText,
  _getTextFromValueList
} from '../../common/common';

import {
  shiftsData,
  employmentTypesData,
  forkOpeTypeData,
  secutrityTypeData,
  compKnowTypeData,
  LanguageData
} from '../applicant/services/masterData';
import ShortText from "../../component/Controls/custom/textbox/shortText";
import DateText from "../../component/Controls/custom/textbox/dateText";
import YesNoRadio from "../../component/Controls/custom/checkers/yesNoRadio";
import PhoneText from "../../component/Controls/custom/textbox/phoneText";

import Radio from "../../component/Controls/custom/checkers/radio";
import NumberText from "../../component/Controls/custom/textbox/numberText";
import MindMapSwitch from "../../component/Controls/custom/mindMapSwitch";

import MultiSelector from "../../component/Controls/custom/selectors/multiSelector";
import Selector from "../../component/Controls/custom/selectors/selectors";
import { updateLayout } from "../../component/layouts/reducerSlices/layoutSlice";

import { isObjectArray } from "../../utilities/utilityFunctions";
import { applicantStatuses, locationsData } from '../shared/services/masterData';

import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from "../locations/reducerSlices/locationSlice";
import Statement from "../../component/Controls/custom/textbox/statement";

import { selectStatusChangeStatus } from "../reviewer/reducerSlices/reviewerSlice";
import GridLoader from "../../component/Controls/loaders/gridLoader";
import ReactTooltip from "react-tooltip";
import StatusLogFilterBar from "../../component/Controls/filterBars/statusLogFilterBar/statusLogFilterBar";
import SortButton from "../../component/Controls/custom/buttons/sortButton";
import DummyDocumentsCard from "../../component/Controls/cards/dummyDocumentsCard";
import LogCard from "../../component/Controls/cards/logCard";
import { showModal } from "../../component/Controls/modals/reducerSlices/modalPopUpSlice";

import comingSoonImg from '../../assets/images/clipart/coming-soon.svg';
import { selectPositionOptions } from "../setting/reducerSlices/positionsSlice";


const ProspectsPeopleDetails = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const [applicantObj, setApplicantObj] = useState('');

  const [statusVisibility, setStatusVisibility] = useState(false);

  const APPLICANT_FORM = "Applicantform";

  const applicant = useSelector(selectApplicantById);

  const [applicantFormSubmitted, setApplicantFormSubmitted] = useState(false);

  const [sections, setSections] = useState(_sections);

  const applicantRequestStatus = useSelector(applicantStatus);

  const recentClickedApplicant = useSelector(selectRecentClickedApplicant);

  const [selectedStatus, setSelectedStatus] = useState();

  const [latestLog, setLatestLog] = useState(undefined);
  const [logCards, setLogCards] = useState(undefined);
  const [sort, setSort] = useState(false);

  const [isHavingLWDetails, setIsHavingLWDetails] = useState(false);
  const [ageOver18, setAgeOver18] = useState(false);
  const [isPTO, setIsPTO] = useState(false);
  const [havingMI, setHavingMI] = useState(false);

  const locationOptions = useSelector(selectLocationOptions);
  const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
  const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);

  const [aPFInvalid, setAPFInvalid] = useState(false);

  const [isAnotherEmpName, setIsAnotherEmpName] = useState(false);
  const [isEverTerminated, setIsEverTerminated] = useState(false);
  const [havingNoWeekend, setHavingNoWeekend] = useState(false);
  const [havingASAPO, setHavingASAPO] = useState(false);
  const [havingExpDate, setHavingExpDate] = useState(false);

  const [havingGENL, setHavingGENL] = useState(false);
  const [havingSHIP, setHavingSHIP] = useState(false);
  const [havingPCLB, setHavingPCLB] = useState(false);
  const [havingINSW, setHavingINSW] = useState(false);
  const [havingRFSC, setHavingRFSC] = useState(false);
  const [havingASLI, setHavingASLI] = useState(false);
  const [havingFOPO, setHavingFOPO] = useState(false);
  const [havingMNOP, setHavingMNOP] = useState(false);
  const [havingSULE, setHavingSULE] = useState(false);
  const [havingMAME, setHavingMAME] = useState(false);
  const [havingELEC, setHavingELEC] = useState(false);
  const [havingSANI, setHavingSANI] = useState(false);
  const [havingQAQC, setHavingQAQC] = useState(false);
  const [havingFLOP, setHavingFLOP] = useState(false);
  const [havingSECR, setHavingSECR] = useState(false);
  const [havingCMKL, setHavingCMKL] = useState(false);
  const [havingAPMNOP, setHavingAPMNOP] = useState(false);
  const [havingAPLO, setHavingAPLO] = useState(false);
  const [havingSCPR, setHavingSCPR] = useState(false);
  const [havingTILA, setHavingTILA] = useState(false);

  const [pageLoaded, setPageLoaded] = useState(false);

  const jobsApplicantStatus = useSelector(selectJobsApplicantStatus);
  const jobsApplicant = useSelector(selectJobsByApplicantId);

  const statusChangeStatus = useSelector(selectStatusChangeStatus);
  const applicantModificationStatus = useSelector(selectModificationStatus);
  const applicantRemovalStatus = useSelector(selectRemovalStatus);

  const convertToPeopleStatus = useSelector(selectConvertToPeopleStatus);

  const [isDNR, setIsDNR] = useState(false);

  const [searchTermStatusLogs, setSearchTermStatusLogs] = useState("");
  const [statusLogsType, setStatusLogsType] = useState("");

  const positionOptions = useSelector(selectPositionOptions);

  const _isHavingEducationDetails = () => {
    return applicant && applicant.Sections && applicant.Sections[2] && applicant.Sections[2].HavingDetails;
  }

  const _isHavingEmploymentDetails = () => {
    return applicant && applicant.Sections && applicant.Sections[3] && applicant.Sections[3].HavingDetails;
  }

  const _isHavingEmployerDetails = () => {
    return applicant && applicant.Sections && applicant.Sections[3] && applicant.Sections[3].HavingEmployerDetails;
  }

  const _isCurrentlyEmployed = () => {
    return applicant && applicant.CurrentlyEmployed.Data === 'Y';
  }

  const _getShiftText = (idList) => {
    if (isObjectArray(idList)) {
      let shifts = "";
      for (let i = 0; i < idList.length; i++) {
        let shift = shiftsData.find(s => s.value === idList[i]);
        if (shift && shift.text) {
          shifts += shift.text + ", ";
        }
      }
      shifts = shifts.trim();
      shifts = shifts.slice(0, -1);
      return shifts;
    }
    return "";
  }

  const _getLanguageText = (idList) => {
    if (isObjectArray(idList)) {
      let languages = "";
      for (let i = 0; i < idList.length; i++) {
        let language = LanguageData.find(p => p.value === idList[i]);
        if (language && language.text) {
          languages += language.text + ", ";
        }
      }
      languages = languages.trim();
      languages = languages.slice(0, -1);
      return languages;
    }
    return "";
  }

  const _getEmploymentTypeText = (idList) => {
    if (isObjectArray(idList)) {
      let empTypes = "";
      for (let i = 0; i < idList.length; i++) {
        let empType = employmentTypesData.find(e => e.value === idList[i]);
        if (empType && empType.text) {
          empTypes += empType.text + ", ";
        }
      }
      empTypes = empTypes.trim();
      empTypes = empTypes.slice(0, -1);
      return empTypes;
    }
    return "";
  }

  const _getForkLiftOperatorText = (idList) => {
    if (isObjectArray(idList)) {
      let forkLiftOpeTypes = "";
      for (let i = 0; i < idList.length; i++) {
        let forkLiftOpeType = forkOpeTypeData.find(fl => fl.value === idList[i]);
        if (forkLiftOpeType && forkLiftOpeType.text) {
          forkLiftOpeTypes += forkLiftOpeType.text + ", ";
        }
      }
      forkLiftOpeTypes = forkLiftOpeTypes.trim();
      forkLiftOpeTypes = forkLiftOpeTypes.slice(0, -1);
      return forkLiftOpeTypes;
    }
    return "";
  }

  const _getSecurityTypeText = (idList) => {
    if (isObjectArray(idList)) {
      let secTypes = "";
      for (let i = 0; i < idList.length; i++) {
        let secType = secutrityTypeData.find(st => st.value === idList[i]);
        if (secType && secType.text) {
          secTypes += secType.text + ", ";
        }
      }
      secTypes = secTypes.trim();
      secTypes = secTypes.slice(0, -1);
      return secTypes;
    }
    return "";
  }

  const _getComputerKnowText = (idList) => {
    if (isObjectArray(idList)) {
      let comKnows = "";
      for (let i = 0; i < idList.length; i++) {
        let comKnow = compKnowTypeData.find(p => p.value === idList[i]);
        if (comKnow && comKnow.text) {
          comKnows += comKnow.text + ", ";
        }
      }
      comKnows = comKnows.trim();
      comKnows = comKnows.slice(0, -1);
      return comKnows;
    }
    return "";
  }

  const _loadApplicant = async (id) => {
    await dispatch(getApplicantById({
      client: masterTenantId,
      serviceType: "TENANTUSER",
      operation: "GETPROSPECTSPEOPLE",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  useEffect(() => {
    if (recentClickedApplicant === undefined) {
      history.push('../prospectsPeople');
    }

    if (recentClickedApplicant) {
      console.log('useEffect recentClickedApplicant ->', recentClickedApplicant);
      _loadApplicant(recentClickedApplicant);
      _loadJobsApplicant(recentClickedApplicant);
      setSelectedTab(1);
      setSearchTermStatusLogs("");
      setStatusLogsType("");
    }
  }, [recentClickedApplicant]);

  useEffect(() => {
    if (statusChangeStatus === fetchStatus.SUCCEEDED && recentClickedApplicant !== undefined) {
      _loadJobsApplicant(recentClickedApplicant);
      setSelectedTab(1);
    }
  }, [statusChangeStatus]);

  useEffect(() => {
    if (applicant) {
      setAgeOver18(isAgeOver18(applicant.DateOfBirth.Data));
      setApplicantObj(applicant);
    }
  }, [applicant]);

  const onValueUpdated = (e, section, value) => {
    let _applicantObj = Object.assign({}, applicant);
    let _newApplicantObj = onValueChanged(_applicantObj, e, section, value);
    dispatch(updateSingleData(_newApplicantObj));
  };





  const _checkIsDNR = (statusLogs) => {
    if (statusLogs) {
      if (statusLogs.some(d => d.LogValue === -4)) {
        setIsDNR(true);
      } else {
        setIsDNR(false);
      }
    } else {
      setIsDNR(false);
    }
  }

  let _logCards;
  useEffect(() => {
    setLatestLog(undefined);
    if (applicant) {

      setSelectedStatus(applicant.Status);
      if (applicant.StatusLogs && Array.isArray(applicant.StatusLogs) && applicant.StatusLogs.length > 0) {
        let _latestLog = applicant.StatusLogs.slice(-1);
        if (_latestLog && Array.isArray(_latestLog) && _latestLog.length === 1) {
          setLatestLog(_latestLog[0]);
        }
      }
      if (applicant && applicant.StatusLogs && Array.isArray(applicant.StatusLogs) && applicant.StatusLogs.length > 0) {

        let logs = [...applicant.StatusLogs];
        setLogCards(logs.reverse());
      }
      else {
        setLogCards(undefined);
      }
      setAgeOver18(isAgeOver18(applicant.DateOfBirth.Data));
      _checkIsDNR(applicant.StatusLogs);
    }
    else {
      setLogCards(undefined);
    }
  }, [applicantRequestStatus, applicant]);

  useEffect(() => {
    if (applicant) {
      setIsPTO(applicant.IsPTO.Data === "Y");
      setIsAnotherEmpName(applicant.IsOtherEmploymentName.Data === "Y");
      setIsEverTerminated(applicant.EverTerminated.Data === "Y");
      setHavingMI(applicant.IsMedicalInsurance.Data === "Y");
      setHavingASAPO(applicant.ASAPOrders.Data === "Y");

      setHavingNoWeekend(applicant.WorkWeekend.Data === "Y");

      setHavingGENL(applicant.GeneralLaber.Data === "Y");
      setHavingSHIP(applicant.ShipRec.Data === "Y");
      setHavingPCLB(applicant.PacLab.Data === "Y");
      setHavingINSW(applicant.IndSew.Data === "Y");
      setHavingRFSC(applicant.RFScan.Data === "Y");
      setHavingASLI(applicant.AssemblyLine.Data === "Y");
      setHavingFOPO(applicant.FoodProd.Data === "Y");
      setHavingMNOP(applicant.MacOpe.Data === "Y");
      setHavingSULE(applicant.SupLead.Data === "Y");
      setHavingMAME(applicant.MainMech.Data === "Y");
      setHavingELEC(applicant.Electrician.Data === "Y");
      setHavingSANI(applicant.Sanitation.Data === "Y");
      setHavingQAQC(applicant.QAQC.Data === "Y");

      setHavingFLOP(applicant.ForkOpe.Data === "Y");
      setHavingExpDate(applicant.ForkliftCertificate && applicant.ForkliftCertificate.Data === "Y");
      setHavingSECR(applicant.Security.Data === "Y");
      setHavingCMKL(applicant.CompKnow.Data === "Y");

      setHavingAPMNOP(applicant.AppMacOpe && applicant.AppMacOpe.Data === "Y");
      setHavingAPLO(applicant.AppLoa && applicant.AppLoa.Data === "Y");
      setHavingSCPR(applicant.ScrPri && applicant.ScrPri.Data === "Y");
      setHavingTILA(applicant.TicLab && applicant.TicLab.Data === "Y");
    }
  }, [applicantRequestStatus]);

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 18,
        title: "Prospects"
      }));
      setPageLoaded(true);
    }
  });


  useEffect(() => {
    if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
      dispatch(getLocationOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLOCATIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({})
        }
      }));
    }
  }, [locationOptionsStatus, locationOptionsRefreshed]);

  useEffect(() => {
    if (!applicant || !applicant.StatusLogs || !Array.isArray(applicant.StatusLogs) || applicant.StatusLogs.length === 0) {
      setLogCards(undefined);
      return;
    }

    let filteredLogs = [...applicant.StatusLogs];

    if (statusLogsType !== "") {
      filteredLogs = filteredLogs.filter((d) => d.LogValue === parseInt(statusLogsType));
    }

    if (searchTermStatusLogs !== "") {
      const _searchTerm = searchTermStatusLogs.toLowerCase();
      filteredLogs = filteredLogs.filter(
        (d) =>
          d.Content.toLowerCase().includes(_searchTerm) ||
          d.LogType.toLowerCase().includes(_searchTerm) ||
          d.UserName.toLowerCase().includes(_searchTerm) ||
          toLocalDate(d.TimeStamp).toLowerCase().includes(_searchTerm) ||
          (d.Client && d.Client.toLowerCase().includes(_searchTerm))
      );
    }

    if (!sort) {
      filteredLogs = filteredLogs.reverse();
    }
    setLogCards(filteredLogs);
  }, [searchTermStatusLogs, statusLogsType]);

  const onSort = (e) => {
    setSort(!sort);
    const sortData = [...logCards].reverse();
    setLogCards(sortData);
  }




  const _loadJobsApplicant = async (id) => {
    await dispatch(getJobsByProspectsApplicantId({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETJOBSBYPROSPECTSAPPLICANTID",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const [selectedTab, setSelectedTab] = useState(1);

  const onTabSelected = (e, v) => {
    setSelectedTab(v);
  };


  const comingSoon = () => {
    dispatch(showModal({
      title: <><span className="modal-header-name">This feature is coming soon...</span></>,
      content: <>
        <div className="barcode-container">
          <img id="coming-soon" src={comingSoonImg} alt="Coming Soon" className="coming-soon" />
        </div>
      </>,
      subtitle: <></>
    }));
  }

  const _convertToPeopleClicked = (e, id) => {
    if (tenantId === "Worca") {
      comingSoon();
      return;
    }

    if (id) {
      dispatch(addProspectsToPeople({
        client: tenantId,
        serviceType: "TENANTUSER",
        operation: "SAVEPROSPECTPEOPLETOAPPLICANT",
        payload: {
          id: id
        }
      }));
    }
  }

  useEffect(() => {
    if (convertToPeopleStatus === fetchStatus.SUCCEEDED) {
      history.push('../prospectsPeople');
    }
  }, [convertToPeopleStatus]);

  const randomAsterisks = () => {
    const length = Math.floor(Math.random() * 6) + 5; // Random length between 5 and 10
    return '*'.repeat(length);
  }

  const dummyStatusLogsData = [
    {
      "TimeStamp": "11/6/2023 10:57:09 AM GMT",
      "LogType": "Registered",
      "LogValue": 0,
      "Content": "Account created successfully by Hazel Stone",
      "PayRates": "",
      "Client": "",
      "ClientId": "",
      "UserName": "Hazel Stone",
      "UserId": "e81f8a00-7dde-4a5f-8630-fdb36fa6bab2"
    },
    {
      "TimeStamp": "Mon, 06 Nov 2023 17:45:06 GMT",
      "LogType": "Admin Note",
      "LogValue": -1,
      "Content": "test",
      "PayRates": "",
      "Client": "",
      "UserName": "Tushar Kothari"
    },
    {
      "TimeStamp": "Tue, 07 Nov 2023 09:24:48 GMT",
      "LogType": "Available",
      "LogValue": 2,
      "Content": "looking for job ",
      "PayRates": "",
      "Client": "",
      "ClientId": "",
      "UserName": "Tushar Kothari",
      "UserId": "b9309330-7cec-4f61-abbc-0f2d2a94a8aa",
      "DispatchDate": "",
      "ClientListType": "0",
      "PostDispatchStatus": "",
      "StatusNote": "looking for job"
    },
    {
      "TimeStamp": "12/5/2023 6:03:56 AM GMT",
      "LogType": "Dispatched",
      "LogValue": 7,
      "Content": "",
      "PayRates": "14.96",
      "Client": "CFG Workshop ",
      "ClientId": "329b11fd-7665-46f5-aaf8-1fcf405a72b2",
      "UserName": "Shubham Singh",
      "UserId": "8a35b63e-406e-41d5-9e0b-346b2533b804",
      "DispatchDate": "2023-11-15",
      "ClientListType": "1",
      "PostDispatchStatus": "",
      "StatusNote": ""
    },
    {
      "TimeStamp": "Tue, 05 Dec 2023 11:40:00 GMT",
      "LogType": "Available",
      "LogValue": 2,
      "Content": "now looking new job ",
      "PayRates": "",
      "Client": "",
      "ClientId": "",
      "UserName": "Tushar Kothari",
      "UserId": "b9309330-7cec-4f61-abbc-0f2d2a94a8aa",
      "DispatchDate": "",
      "ClientListType": "1",
      "PostDispatchStatus": "",
      "StatusNote": "now looking new job"
    },
    {
      "TimeStamp": "12/11/2023 2:01:20 PM GMT",
      "LogType": "Called Out",
      "LogValue": -2,
      "Content": "Call Out 1 ",
      "PayRates": "",
      "Client": "",
      "UserName": "Tushar Kothari",
      "UserId": "b9309330-7cec-4f61-abbc-0f2d2a94a8aa"
    },
    {
      "TimeStamp": "Wed, 20 Dec 2023 10:39:03 GMT",
      "LogType": "Admin Note",
      "LogValue": -1,
      "Content": "Submit your all documents",
      "PayRates": "",
      "Client": "",
      "UserName": "Tushar Kothari"
    }
  ];


  return (
    <>
      {(applicantRequestStatus === fetchStatus.LOADING ||
        jobsApplicantStatus === fetchStatus.LOADING ||
        applicantModificationStatus === fetchStatus.LOADING ||
        applicantRemovalStatus === fetchStatus.LOADING ||
        convertToPeopleStatus === fetchStatus.LOADING
      ) && <div className="app-container-loader">
          <GridLoader />
        </div>}

      {/* main section */}
      <div className="px-3 pt-3">
        <div className="row">
          <div className="d-flex bd-highlight">
            <div className="bd-highlight">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Prospects</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Details</li>
                </ol>
              </nav>
            </div>

            <div className="ms-auto">



              {/* <div className="circle-ripple mt-3 me-3">
                <div className='c-icon-box-add-to-people' onClick={(e) => _convertToPeopleClicked(e, applicant ? applicant.id : "")} data-tip='Add To People'>
                  <i className='fa-solid fa-lg fa-user-plus fa-fade'></i>
                </div>
              </div> */}



              {/* <div className='c-icon-box' onClick={(e) => _convertToPeopleClicked(e, applicant ? applicant.id : "")} data-tip='Add To People'>
                <i className='fa-solid fa-user-plus main-button'></i>
              </div> */}
              <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
            </div>
          </div>
        </div>
      </div>

      <div className="px-3">
        <div className="row">
          <div className="col-md-3">
            <ProspectsPeopleDetailCard
              isRecordValid={applicant}
              statuses={applicantStatuses}
              selectedStatus={selectedStatus}
              statusVisibility={statusVisibility}

              photoPath={applicant && applicant.PhotoPath ? applicant.PhotoPath.Data.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "") : nophoto}
              convertToPeopleClicked={(e) => _convertToPeopleClicked(e, applicant ? applicant.id : "")}
              dispatchDetails={jobsApplicant}
              applicantId={recentClickedApplicant}
              positionOptions={positionOptions}
            />
          </div>

          <div className="col-md-9">
            {/* tab menu */}
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {/* <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 0 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 0)} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Feed</button>
              </li> */}
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 1 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 1)} id="personal-tab" data-bs-toggle="tab" data-bs-target="#personal" type="button" role="tab" aria-controls="personal" aria-selected="false">Personal</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 3 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 3)} id="education-tab" data-bs-toggle="tab" data-bs-target="#education" type="button" role="tab" aria-controls="education" aria-selected="false">Education</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 4 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 4)} id="employment-tab" data-bs-toggle="tab" data-bs-target="#employment" type="button" role="tab" aria-controls="employment" aria-selected="false">Employment</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 6 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 6)} id="otherDetails-tab" data-bs-toggle="tab" data-bs-target="#otherDetails" type="button" role="tab" aria-controls="otherDetails" aria-selected="false">Other Details</button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">

              <div className={`tab-pane fade ${selectedTab === 0 && "show active"}`} id="home" role="tabpanel" aria-labelledby="home-tab">
                <div className="card a-card-wrap pt-3">

                  {/* <StatusLogFilterBar>
                    <SortButton
                      SortButtonClasses="mx-2"
                      sorted={sort}
                    />
                    <div className="line line-start"></div>
                  </StatusLogFilterBar> */}

                  <div className="d-flex">
                    <div className="col-md-8 col-lg-8">
                      <div className="tracking-list pt-3 ps-3 blur-section">
                        <LogCard statusLogs={dummyStatusLogsData.reverse()} />
                      </div>
                    </div>


                    <div className="col-md-4 col-lg-4  end-r-3 p-3 ">
                      <div className=" a-card">
                        <div className="d-flex">
                          <span className="text-primary">Documents</span>
                        </div>
                        <DummyDocumentsCard />
                        <div className="float-button">
                          <button className="c-btn dark-btn rounded-pill me-3" type="button" ><i className="fa fa-file pe-2" ></i>Add Note</button>
                          <button className="c-btn dark-btn rounded-pill" type="button" ><i className="fa-solid fa-comment pe-2"></i>Message</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className={`tab-pane fade ${selectedTab === 1 && "show active"}`} id="personal" role="tabpanel" aria-labelledby="personal-tab">
                <div className="card a-card-wrap">
                  <div className="d-flex">
                    <div className="col-md-8 col-lg-8">

                      {/* Start Personal */}
                      {applicant &&
                        <>
                          <div className="row m-0 p-3">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label id="lblFirstName" className="field-label" htmlFor="txtFirstName">First Name</label>
                                <label className="field-label-value">{applicant ? applicant.FirstName.Data : ""}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label id="lblMiddleName" className="field-label" htmlFor="txtMiddleName">Middle Name</label>
                                <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label id="lblLastName" className="field-label" htmlFor="txtLastName">Last Name</label>
                                <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                              </div>
                            </div>
                          </div>
                          <div className="row m-0 px-3">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label id="lblPositions" className="field-label" htmlFor="drpPositions">Position</label>
                                <label className="field-label-value">{applicant ? _getTextFromValueList(positionOptions, applicant.Position.Data) : ""}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label id="lblShifts" className="field-label" htmlFor="drpShifts">Shift</label>
                                <label className="field-label-value">{applicant ? _getShiftText(applicant.Shift.Data) : ""}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label id="lblEmploymentTypes" className="field-label" htmlFor="drpEmploymentTypes">Employment Type</label>
                                <label className="field-label-value">{applicant ? _getEmploymentTypeText(applicant.EmploymentType.Data) : ""}</label>
                              </div>
                            </div>
                          </div>
                          <div className="row m-0 px-3">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="field-label">Gender</label>
                                <label className="field-label-value">{applicant ? applicant.Gender.Data : ""}</label>
                              </div>
                            </div>
                          </div>

                          <div className="row m-0 px-3">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label id="lblLanguage" className="field-label" htmlFor="drpLanguage">Language</label>
                                <label className="field-label-value">{applicant ? _getLanguageText(applicant.Language.Data) : ""}</label>
                              </div>
                            </div>
                          </div>
                          <div className="row m-0 px-3">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label id="lblLocations" className="field-label" htmlFor="drpLocations">Location</label>
                                <label className="field-label-value">{applicant ? _getLocationText(locationOptions, applicant.Location.Data) : ""}</label>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      {/* End Personal */}


                    </div>

                    <div className="col-md-4 col-lg-4  end-r-3 p-3 ">
                      <div className=" a-card">
                        <div className="d-flex">
                          <span className="text-primary">Documents</span>
                        </div>
                        <DummyDocumentsCard />
                        {/* <div className="float-button">
                          <button className="c-btn dark-btn rounded-pill me-3" type="button" ><i className="fa fa-file pe-2" ></i>Add Note</button>
                          <button className="c-btn dark-btn rounded-pill" type="button" ><i className="fa-solid fa-comment pe-2"></i>Message</button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`tab-pane fade ${selectedTab === 3 && "show active"}`} id="education" role="tabpanel" aria-labelledby="education-tab">
                <div className="card a-card-wrap">
                  {/* Start education */}
                  {applicant &&
                    <>
                      <div className="row m-0 p-3">
                        <>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="field-label">High School: Completed</label>
                              <label className="field-label-value">{applicant ? applicant.HighSchoolCompleted.Data : ""}</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="field-label">Diploma</label>
                              <label className="field-label-value">{applicant ? (applicant.Diploma.Data === "Y" ? "Yes" : "No") : ""}</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="field-label">G.E.D</label>
                              <label className="field-label-value">{applicant ? (applicant.GED.Data === "Y" ? "Yes" : "No") : ""}</label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label id="lblSchools" className="field-label" htmlFor="txtSchools">School(s)</label>
                              <label className="field-label-value">{applicant ? applicant.School.Data : ""}</label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label id="lblEDCityState" className="field-label" htmlFor="txtEDCityState">City / State</label>
                              <label className="field-label-value">{applicant ? applicant.CityState.Data : ""}</label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label id="lblEDOthers" className="field-label" htmlFor="txtEDOthers">Others</label>
                              <label className="field-label-value">{applicant ? applicant.Other.Data : ""}</label>
                            </div>
                          </div>
                        </>
                      </div>
                    </>
                  }
                  {/* Start education */}
                </div>
              </div>
              <div className={`tab-pane fade ${selectedTab === 4 && "show active"}`} id="employment" role="tabpanel" aria-labelledby="employment-tab">
                <div className="card a-card-wrap">
                  {/* Start employment */}
                  {applicant &&
                    <div className="row m-0 p-3">
                      <>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmployer1" className="field-label" htmlFor="txtEmployer1">Employer</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmployerAddress1" className="field-label" htmlFor="txtEmployerAddress1">Address</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmployerPhone1" className="field-label" htmlFor="txtEmployerPhone1">Company Phone Number</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmployerTelePhone1" className="field-label" htmlFor="txtEmployerTelePhone1">Supervisor Number</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblPosition1" className="field-label" htmlFor="drpPosition1">Position</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblSupervisor1" className="field-label" htmlFor="txtSupervisor1">Supervisor</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Currently Employed?</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblDOEFrom1" className="field-label" htmlFor="txtDOEFrom1">Date Of Employment From</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblDOETo1" className="field-label" htmlFor="txtDOETo1">Date Of Employment To</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label id="lblReasonForLeaving1" className="field-label" htmlFor="txtReasonForLeaving1">Reason For Leaving</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblEmployerEmployedBy1" className="field-label" htmlFor="drpEmployerEmployedBy1">Employed By</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblEmployerAgencyName1" className="field-label" htmlFor="txtEmployerAgencyName1">Employer Agency Name</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label id="lblEmployerNote1" className="field-label" htmlFor="txtEmployerNote1">Note</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Any other name for employment at any time?</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblOtherEmploymentName" className="field-label" htmlFor="txtOtherEmploymentName">Other Employment Name</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Ever been asked to resign?</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblExplain" className="field-label" htmlFor="txtExplain">Explain</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <hr />
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmployer2" className="field-label" htmlFor="txtEmployer2">Employer</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmployerAddress2" className="field-label" htmlFor="txtEmployerAddress2">Address</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmployerPhone2" className="field-label" htmlFor="txtEmployerPhone2">Company Phone Number</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmployerTelePhone2" className="field-label" htmlFor="txtEmployerTelePhone2">Supervisor Number</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblPosition2" className="field-label" htmlFor="drpPosition2">Position</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblSupervisor2" className="field-label" htmlFor="txtSupervisor2">Supervisor</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblDOEFrom2" className="field-label" htmlFor="txtDOEFrom2">Date Of Employment From</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblDOETo2" className="field-label" htmlFor="txtDOETo2">Date Of Employment To</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label id="lblReasonForLeaving2" className="field-label" htmlFor="txtReasonForLeaving2">Reason For Leaving</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblEmployerEmployedBy2" className="field-label" htmlFor="drpEmployerEmployedBy2">Employed By</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblEmployerAgencyName2" className="field-label" htmlFor="txtEmployerAgencyName2">Employer Agency Name</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label id="lblEmployerNote2" className="field-label" htmlFor="txtEmployerNote2">Note</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <hr />
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmployer3" className="field-label" htmlFor="txtEmployer3">Employer</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmployerAddress3" className="field-label" htmlFor="txtEmployerAddress3">Address</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmployerPhone3" className="field-label" htmlFor="txtEmployerPhone3">Company Phone Number</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmployerTelePhone3" className="field-label" htmlFor="txtEmployerTelePhone3">Supervisor Number</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblPosition3" className="field-label" htmlFor="drpPosition3">Position</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblSupervisor3" className="field-label" htmlFor="txtSupervisor3">Supervisor</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblDOEFrom3" className="field-label" htmlFor="txtDOEFrom3">Date Of Employment From</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblDOETo3" className="field-label" htmlFor="txtDOETo3">Date Of Employment To</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label id="lblReasonForLeaving3" className="field-label" htmlFor="txtReasonForLeaving3">Reason For Leaving</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblEmployerEmployedBy3" className="field-label" htmlFor="drpEmployerEmployedBy3">Employed By</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblEmployerAgencyName3" className="field-label" htmlFor="txtEmployerAgencyName3">Employer Agency Name</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label id="lblEmployerNote3" className="field-label" htmlFor="txtEmployerNote3">Note</label>
                            <label className="field-label-value"><span className="blur">{randomAsterisks()}</span></label>
                          </div>
                        </div>
                      </>
                    </div>
                  }
                  {/* Start employment */}
                </div>
              </div>
              <div className={`tab-pane fade ${selectedTab === 6 && "show active"}`} id="otherDetails" role="tabpanel" aria-labelledby="otherDetails-tab">
                <div className="card a-card-wrap">
                  {/* Start references */}
                  {applicant &&
                    <>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblLastWorkPlace" className="field-label" htmlFor="txtLastWorkPlace">Last Work Place Job / Duties</label>
                            <label className="field-label-value">{applicant ? applicant.LastWorkPlace.Data : ""}</label>
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 px-3">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label id="lblKnowAboutUs" className="field-label" htmlFor="txtKnowAboutUs">How Did You Learn About Us?</label>
                            <label className="field-label-value">{applicant ? applicant.KnowAboutUs.Data : ""}</label>
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Has Own Medical Insurance?</label>
                            <label className="field-label-value">{applicant ? (applicant.IsMedicalInsurance.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblMedicalInsuranceType" className="field-label" htmlFor="txtMedicalInsuranceType">Medical Insurance Type</label>
                            <label className="field-label-value">{applicant ? applicant.MedicalInsuranceType.Data : ""}</label>
                          </div>
                        </div>

                      </div>

                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Available For ASAP Orders</label>
                            <label className="field-label-value">{applicant ? (applicant.ASAPOrders.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblASAPOAvailableDate" className="field-label" htmlFor="txtASAPOAvailableDate">Available Start Date</label>
                            <label className="field-label-value">{applicant ? _getDateText(applicant.ASAPOAvailableDate.Data) : ""}</label>
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 p-3">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>

                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Available For OT</label>
                            <label className="field-label-value">{applicant ? (applicant.OTAvailable.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Willing to commute for other cities</label>
                            <label className="field-label-value">{applicant ? (applicant.WillingToCommute.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Fast Paced Environments</label>
                            <label className="field-label-value">{applicant ? (applicant.FastPacedEnvironments.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Standing Long Period Of Time (8 Hours)</label>
                            <label className="field-label-value">{applicant ? (applicant.StandingLongPeriod.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can Work In Hot Tempearture</label>
                            <label className="field-label-value">{applicant ? (applicant.HotTempeartureWork.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Repetitive Movements</label>
                            <label className="field-label-value">{applicant ? (applicant.RepetitiveMovements.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can Work In Cold Tempearture</label>
                            <label className="field-label-value">{applicant ? (applicant.ColdTempWork.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Heavy Lifting (50LBS)</label>
                            <label className="field-label-value">{applicant ? (applicant.HeavyLifting.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Understand / Went Over Safety / Injury Protocol</label>
                            <label className="field-label-value">{applicant ? (applicant.SafetyProtocol.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over CA Minimum Wage</label>
                            <label className="field-label-value">{applicant && applicant.CAMinimmum ? (applicant.CAMinimmum.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over all Job Assignments being Temporary (Temp to Hire)</label>
                            <label className="field-label-value">{applicant && applicant.TemptoHire ? (applicant.TemptoHire.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over Alcohol and Drug Prohibition</label>
                            <label className="field-label-value">{applicant && applicant.AlcoholProhibition ? (applicant.AlcoholProhibition.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over Harassment and Bullying Reports</label>
                            <label className="field-label-value">{applicant && applicant.HarassmentBullying ? (applicant.HarassmentBullying.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over Check Pick Up and Direct Deposit Qualification</label>
                            <label className="field-label-value">{applicant && applicant.DirectDeposit ? (applicant.DirectDeposit.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Clarified to Associate that SBS is the Employer not the Client</label>
                            <label className="field-label-value">{applicant && applicant.ClarifiedEmployer ? (applicant.ClarifiedEmployer.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Understand / Went Over Uniform Policy</label>
                            <label className="field-label-value">{applicant ? (applicant.UniformPolicy.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label-value">{applicant ? (applicant.NoShowPolicy.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Understand / Went Over Time Clock Use / Policy</label>
                            <label className="field-label-value">{applicant ? (applicant.TimeClockUse.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over Weekly Pay And 3 Sick Days</label>
                            <label className="field-label-value">{applicant ? (applicant.WeeklyPaySL.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Own Still Toe Boots</label>
                            <label className="field-label-value">{applicant ? (applicant.StillBoots.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Own Slip Resistant Boots</label>
                            <label className="field-label-value">{applicant ? (applicant.SlipResistBoots.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over 30 Mandatory Lunch / Punctuality</label>
                            <label className="field-label-value">{applicant ? (applicant.Punctuality.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Available for 1 Day assignment</label>
                            <label className="field-label-value">{applicant && applicant.OneDayAssignment ? (applicant.OneDayAssignment.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can You Read In English?</label>
                            <label className="field-label-value">{applicant && applicant.ReadEnglish ? (applicant.ReadEnglish.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can You Write In English?</label>
                            <label className="field-label-value">{applicant && applicant.WriteEnglish ? (applicant.WriteEnglish.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can You Write In Spanish?</label>
                            <label className="field-label-value">{applicant && applicant.WriteSpanish ? (applicant.WriteSpanish.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can You Read In Spanish?</label>
                            <label className="field-label-value">{applicant && applicant.ReadSpanish ? (applicant.ReadSpanish.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Do You Have Basic Math Skills(Adding and Substracting)?</label>
                            <label className="field-label-value">{applicant && applicant.BasicMathSkills ? (applicant.BasicMathSkills.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Are You COVID Vaccinated?</label>
                            <label className="field-label-value">{applicant && applicant.CovidVaccinated ? (applicant.CovidVaccinated.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can Work Weekends</label>
                            <label className="field-label-value">{applicant ? (applicant.WorkWeekend.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblReasontoNotWork" className="field-label" htmlFor="txtReasontoNotWork">If Not Why</label>
                            <label className="field-label-value">{applicant && applicant.IfNotWorkWeekend ? applicant.IfNotWorkWeekend.Data : ""}</label>
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 p-3">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>


                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">General Laber: Loading / Unloading</label>
                            <label className="field-label-value">{applicant ? (applicant.GeneralLaber.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblGLLUYearMonthExpr" className="field-label" htmlFor="txtGLLUYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.GLExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Shipping & Receiving</label>
                            <label className="field-label-value">{applicant ? (applicant.ShipRec.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblSHRCYearMonthExpr" className="field-label" htmlFor="txtSHRCYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.SRExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Packaging & Labeling</label>
                            <label className="field-label-value">{applicant ? (applicant.PacLab.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblPALAYearMonthExpr" className="field-label" htmlFor="txtPALAYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.PLExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Industrial Sewing</label>
                            <label className="field-label-value">{applicant ? (applicant.IndSew.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblINDSEYearMonthExpr" className="field-label" htmlFor="txtINDSEYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.ISWExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">RF Scanning</label>
                            <label className="field-label-value">{applicant ? (applicant.RFScan.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblRFSCYearMonthExpr" className="field-label" htmlFor="txtRFSCYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.RFSExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Assembly Line</label>
                            <label className="field-label-value">{applicant ? (applicant.AssemblyLine.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblASLIYearMonthExpr" className="field-label" htmlFor="txtASLIYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.ALExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Food Production</label>
                            <label className="field-label-value">{applicant ? (applicant.FoodProd.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblFOPROYearMonthExpr" className="field-label" htmlFor="txtFOPROYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.FPExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Machine Operator</label>
                            <label className="field-label-value">{applicant ? (applicant.MacOpe.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblMACOYearMonthExpr" className="field-label" htmlFor="txtMACOYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.MOExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Supervisor / Lead</label>
                            <label className="field-label-value">{applicant ? (applicant.SupLead.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblSULEYearMonthExpr" className="field-label" htmlFor="txtSULEYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.SULExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Maintainance Mechanic</label>
                            <label className="field-label-value">{applicant ? (applicant.MainMech.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblMAIMEYearMonthExpr" className="field-label" htmlFor="txtMAIMEYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.MaMeExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Electrician</label>
                            <label className="field-label-value">{applicant ? (applicant.Electrician.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblElecYearMonthExpr" className="field-label" htmlFor="txtElecYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.ElecExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Sanitation</label>
                            <label className="field-label-value">{applicant ? (applicant.Sanitation.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblSANTYearMonthExpr" className="field-label" htmlFor="txtSANTYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.SaniExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Quality Assurance / Quality Control</label>
                            <label className="field-label-value">{applicant ? (applicant.QAQC.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblQAQCExperience" className="field-label" htmlFor="txtQAQCExperience">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.QAQCExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 p-3">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>

                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Forklift Operator</label>
                            <label className="field-label-value">{applicant ? (applicant.ForkOpe.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblForkOpeType" className="field-label" htmlFor="drpForkOpeType">Forklift Operator Type</label>
                            <label className="field-label-value">{applicant ? _getForkLiftOperatorText(applicant.ForkOpeType.Data) : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblFOROYearMonthExpr" className="field-label" htmlFor="txtFOROYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.FOExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <>
                          </>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Forklift Certificate</label>
                            <label className="field-label-value">{applicant && applicant.ForkliftCertificate ? (applicant.ForkliftCertificate.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblCertificateExpiryDate" className="field-label" htmlFor="txtCertificateExpiryDate">Certificate Expiry Date</label>
                            <label className="field-label-value">{applicant && applicant.CertificateExpiryDate ? _getDateText(applicant && applicant.CertificateExpiryDate ? applicant.CertificateExpiryDate.Data : "") : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Security</label>
                            <label className="field-label-value">{applicant ? (applicant.Security.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblSecType" className="field-label" htmlFor="drpSecType">Security Type</label>
                            <label className="field-label-value">{applicant ? _getSecurityTypeText(applicant.SecType.Data) : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblSECRYearMonthExpr" className="field-label" htmlFor="txtSECRYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.SecExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Clerical: Computer Knowledge</label>
                            <label className="field-label-value">{applicant ? (applicant.CompKnow.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblCompKnowType" className="field-label" htmlFor="drpCompKnowType">Computer Knowledge Type</label>
                            <label className="field-label-value">{applicant ? _getComputerKnowText(applicant.CompKnowType.Data) : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblCLCOMPYearMonthExpr" className="field-label" htmlFor="txtCLCOMPYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant ? applicant.CompKnowExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 p-3">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>

                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Apparel Machine Operator</label>
                            <label className="field-label-value">{applicant ? applicant.AppMacOpe && (applicant.AppMacOpe.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblAMOYearMonthExpr" className="field-label" htmlFor="txtAMOYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant && applicant.AMOExperience ? applicant.AMOExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Apparel Loader</label>
                            <label className="field-label-value">{applicant ? applicant.AppLoa && (applicant.AppLoa.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblAppLoaYearMonthExpr" className="field-label" htmlFor="txtAppLoaYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant && applicant.AppLoaExperience ? applicant.AppLoaExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Screen Printing</label>
                            <label className="field-label-value">{applicant ? applicant.ScrPri && (applicant.ScrPri.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblScrPriYearMonthExpr" className="field-label" htmlFor="txtScrPriYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant && applicant.ScrPriExperience ? applicant.ScrPriExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Ticketing / Labeling</label>
                            <label className="field-label-value">{applicant ? applicant.TicLab && (applicant.TicLab.Data === "Y" ? "Yes" : "No") : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblTicLabYearMonthExpr" className="field-label" htmlFor="txtTicLabYearMonthExpr">Months Experience</label>
                            <label className="field-label-value">{applicant && applicant.TicLabExperience ? applicant.TicLabExperience.Data : ""}</label>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProspectsPeopleDetails;