export const registerDetailsEN = {
    Default: " -- Select -- ",
    Title: "GET STARTED TODAY!",
    FirstName: "First Name",
    LastName: "Last Name",
    PhoneNumber: "Phone Number",
    Email: "Email",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    Location: "Location",
    BirthDate: "Birth Date",
    FirstNameError: "Please enter first name",
    LastNameError: "Please enter last name",
    PhoneNumberError: "Please enter phone number",
    EmailError: "Please enter email",
    PasswordError: "Please enter password",
    ConfirmPasswordError: "Please enter confirm password",
    LocationError: "Please select location",
    BirthDateError: "Please enter birth date",
    Register: "Register",
    RegisterTooltip: "Click to create an account",
    LoginPrompt: "Already have an account?",
    Login: "Login",
    PasswordMismatchError: "Password and Confirm Password did not match",
    PasswordLengthError: "Password should be at least 8 characters",
}

export const registerDetailsSP = {
    Default: " -- Seleccione -- ",
    Title: "¡EMPIECE HOY!",
    FirstName: "Primer nombre",
    LastName: "Apellido",
    PhoneNumber: "Número de teléfono",
    Email: "Correo electrónico",
    Password: "Contraseña",
    ConfirmPassword: "Confirmar Contraseña",
    Location: "Localización",
    BirthDate: "Fecha de nacimiento",
    FirstNameError: "Por favor ingrese su nombre",
    LastNameError: "Por favor ingrese su apellido",
    PhoneNumberError: "Por favor ingrese el numero de telefono",
    EmailError: "Por favor ingrese la dirección de correo electrónico",
    PasswordError: "Por favor, ingrese contraseña",
    ConfirmPasswordError: "Por favor ingrese confirmar contraseña",
    LocationError: "Por favor seleccione la ubicación",
    BirthDateError: "Por favor ingrese la fecha de nacimiento",
    Register: "Registrarse",
    RegisterTooltip: "Haga clic para crear una cuenta",
    LoginPrompt: "¿Ya tienes una cuenta?",
    Login: "Acceso",
    PasswordMismatchError: "Contraseña y Confirmar contraseña no coinciden",
    PasswordLengthError: "La contraseña debe tener al menos 8 caracteres",
}