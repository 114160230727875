export const sections = {
    POSITION_DETAILS: 0
};

export const onValueChanged = (position, e, section, value) => {
    let _position = JSON.parse(JSON.stringify(position));
    if (section == sections.POSITION_DETAILS) {
        return _savePositionDetails(_position, e, value);
    }
};

const _savePositionDetails = (position, e, value) => {
    if (e.target.name === "txtPositionName") {
        position.PositionName.Data = e.target.value.trim();
    }

    return position;
};