import React, { useState } from 'react';

import user1Img from './../../assets/images/clipart/user-1.png';
import user2Img from './../../assets/images/clipart/user-2.png';
import sendImg from './../../assets/images/clipart/send.svg';


function MessageFlyout(props) {

  const [isActive, setActive] = useState(false);
  const ToggleClass = () => {
    setActive(!isActive);

    const timer = setTimeout(() => {
      props.onClose(false);
    }, 1500);
  };

  return (
    <>
      <div className="flyout-wrapper position-fixed top-0 end-0">
        <div className={`flyout-card ${isActive ? 'close' : 'open'}`}>
          <div className="flyout-card-header">
            <div className="float-end">
              <div className="close-btn">
                <i className="fa fa-times" aria-hidden="true" onClick={ToggleClass}></i>
              </div>
            </div>
            <div className="flyout-card-title">
              <img src={user1Img} className="flyout-list-group-item-card-profile-img me-2" />Message with {props.title}
            </div>
          </div>

          {/* No Message */}
          {/* <div className="flyout-data-not-found-container">
          <div className="flyout-data-not-found">
            <img src={noMessagesImg} />
            <p className="title">No results found</p>
            <p className="description">Try adjusting your search to find what you are looking for</p>
          </div>
        </div> */}
          {/* End No Message */}

          {/* <div className="flyout-card-body">
          <ul className="flyout-list-group p-0">
            <li className="pt-3">
              <div className="flyout-message-container">
                <div className="flyout-message-card">
                  <img src={user2Img} className="flyout-list-group-item-card-profile-img " />
                  <div className="received">
                    <div className="message-text">Yes! I will sent you tomorrow morning.</div>
                    <div className="message-date">07/05/2022 10:45 AM</div>
                  </div>
                </div>
              </div>
            </li>
            <li className="pt-3">
              <div className="flyout-message-container">
                <div className="flyout-message-card d-flex flex-row-reverse">
                  <img src={user1Img} className="flyout-list-group-item-card-profile-img " />
                  <div className="replied">
                    <div className="message-text">I will message  you, if anything i want Thanks!</div>
                    <div className="message-date">07/05/2022 10:45 AM</div>
                  </div>
                </div>
              </div>
            </li>
            <li className="pt-3">
              <div className="flyout-message-container">
                <div className="flyout-message-card d-flex flex-row-reverse">
                  <img src={user1Img} className="flyout-list-group-item-card-profile-img " />
                  <div className="replied">
                    <div className="message-text">Send as soon as possiable</div>
                    <div className="message-date">07/05/2022 10:45 AM</div>
                  </div>
                </div>
              </div>
            </li>
            <li className="pt-3">
              <div className="flyout-message-container">
                <div className="flyout-message-card">
                  <img src={user2Img} className="flyout-list-group-item-card-profile-img " />
                  <div className="received">
                    <div className="message-text">Sure</div>
                    <div className="message-date">07/05/2022 10:45 AM</div>
                  </div>
                </div>
              </div>
            </li>
            <li className="pt-3">
              <div className="flyout-message-container">
                <div className="flyout-message-card d-flex flex-row-reverse">
                  <img src={user1Img} className="flyout-list-group-item-card-profile-img " />
                  <div className="replied">
                    <div className="message-text">Can you sent me the update documents</div>
                    <div className="message-date">07/05/2022 10:45 AM</div>
                  </div>
                </div>
              </div>
            </li>
            <li className="pt-3">
              <div className="flyout-message-container">
                <div className="flyout-message-card d-flex flex-row-reverse">
                  <img src={user1Img} className="flyout-list-group-item-card-profile-img " />
                  <div className="replied">
                    <div className="message-text">Sure, I’m good.</div>
                    <div className="message-date">07/05/2022 10:45 AM</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div> */}

          {/* <div className="flyout-card-body pb-5 pm-3"> */}
          {props.children}
          {/* </div> */}

          {/* <div className="flyout-separator">
          <div className='line' />
          <div className='unread-messages'>2 Unread Messages</div>
          <div className='line' />
        </div> */}

          {/* <div className="flyout-card-body">
          <ul className="flyout-list-group p-0">
            <li className="pt-3">
              <div className="flyout-message-container">
                <div className="flyout-message-card">
                  <img src={user1Img} className="flyout-list-group-item-card-profile-img " />
                  <div className="received">
                    <div className="message-text">Are you available chat?</div>
                    <div className="message-date">07/06/2022 10:30 AM</div>
                  </div>
                </div>
              </div>
            </li>
            <li className="pt-3">
              <div className="flyout-message-container">
                <div className="flyout-message-card">
                  <img src={user1Img} className="flyout-list-group-item-card-profile-img " />
                  <div className="received">
                    <div className="message-text">Hi, Allen Yarbrough! How are you?</div>
                    <div className="message-date">07/06/2022 10:30 AM</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div> */}

          {/* <div className="position-fixed bottom-0 end-0 pb-3 pe-4">
          <input type="text" placeholder="Enter you message" className='flyout-message-text-box' />
          <button className="flyout-message-send-btn" type="button" >
            <img src={sendImg} />
          </button>
        </div> */}


        </div>
      </div>
      <div className='flyout-backdrop flyout-backdrop-show' />
    </>
  )
}

export default MessageFlyout