import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _getLocationText } from '../../common/common';
import ShortText from '../Controls/custom/textbox/shortText';
import { isFormValid } from '../Controls/services/formService';
import { closeExperienceFlyout, createExperience, selectExperienceById, selectExperienceFlyoutCreateMode, selectExperienceFlyoutEditmode, selectExperienceFlyoutShow, selectExperienceForm, selectExperienceFormStatus, selectCreationStatus, selectModificationStatus, updateExperience, updateSingleData, createExperienceOptionDetail, deleteExperienceOptionDetail, updateExperienceOptionsStatus } from '../../features/setting/reducerSlices/experienceSlice';
import { onValueChanged, sections } from '../../features/setting/services/experienceSaver';
import { Operation } from '../../common/constants';
import Selector from '../Controls/custom/selectors/selectors';
import { fetchStatus, tenantId } from '../../api/client';
import InputGroup from '../Controls/custom/inputGroup/inputGroup';
import ReactTooltip from 'react-tooltip';
import EllipsisLoader from '../Controls/loaders/ellipsisLoader';
import RemoveIconButton from '../Controls/buttons/removeIconButton';
import { userStatuses } from '../../features/shared/services/masterData';
import { isObjectArray } from '../../utilities/utilityFunctions';
import { selectSkillGroupOptions } from '../../features/setting/reducerSlices/skillGroupSlice';


function ExperienceFlyout(props) {

    const dispatch = useDispatch();
    const experienceFlyoutShow = useSelector(selectExperienceFlyoutShow);

    const EXPERIENCE_FORM = "Experienceform";

    const [createMode, setCreatemode] = useState(false);
    const [editMode, setEditmode] = useState(false);
    const [isActive, setActive] = useState(false);
    const [cFInvalid, setCFInvalid] = useState(false);
    const [cfSubmitted, setCFSubmitted] = useState(false);
    const [statusOptions, setStatusOptions] = useState([]);

    const experienceFlyoutCreateMode = useSelector(selectExperienceFlyoutCreateMode);
    const experienceFlyoutEditMode = useSelector(selectExperienceFlyoutEditmode);
    const experienceFormStatus = useSelector(selectExperienceFormStatus);
    const experienceForm = useSelector(selectExperienceForm);
    const experience = useSelector(selectExperienceById);
    const experienceCreationStatus = useSelector(selectCreationStatus);
    const experienceModificationStatus = useSelector(selectModificationStatus);
    const skillGroupOptions = useSelector(selectSkillGroupOptions);

    const ToggleClass = () => {
        setActive(!isActive);
        onCancelClicked();

        const timer = setTimeout(() => {
            dispatch(closeExperienceFlyout())
            setActive(false)
        }, 1500);
    };

    const onCancelClicked = (e) => {
        setCreatemode(false);
        setEditmode(false);
        setCFInvalid(false);
        setCFSubmitted(false);
    };

    useEffect(() => {
        setCreatemode(experienceFlyoutCreateMode);
        setEditmode(experienceFlyoutEditMode)
    }, [experienceFlyoutCreateMode, experienceFlyoutEditMode]);

    useEffect(() => {
        if (experienceCreationStatus === fetchStatus.SUCCEEDED || experienceModificationStatus === fetchStatus.SUCCEEDED) {
            dispatch(updateExperienceOptionsStatus());
            ToggleClass()
        }
    }, [experienceCreationStatus, experienceModificationStatus]);

    const onValueUpdated = (e, section, value) => {
        let _experienceObj = Object.assign({}, experience);
        let _newExperienceObj = onValueChanged(_experienceObj, e, section, value);
        dispatch(updateSingleData({
            experience: _newExperienceObj,
            operation: Operation.UPDATED
        }));
    };

    const onSaveClicked = (e, id, created) => {
        e.preventDefault();
        if (isFormValid(EXPERIENCE_FORM)) {
            if (experience) {
                if (created) {
                    dispatch(createExperience({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVESETTINGEXPERIENCE",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                settingExperience: JSON.stringify(experience),
                            })
                        }
                    }));
                }
                else {
                    dispatch(updateExperience({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVESETTINGEXPERIENCE",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                settingExperience: JSON.stringify(experience),
                            })
                        }
                    }));
                }
            }
            setCreatemode(false);
            setEditmode(false);
            setCFInvalid(false);
            setCFSubmitted(false);
        }
        else {
            setCFInvalid(true);
        }
        setCFSubmitted(true);
    };

    const onAddExperienceOptionClicked = (e) => {
        e.preventDefault();
        dispatch(createExperienceOptionDetail());
    };

    const onDeleteExperienceOptionClicked = (e, index) => {
        e.preventDefault();
        dispatch(deleteExperienceOptionDetail(index));
    }

    useEffect(() => {
        const handleBackButton = (event) => {
            if (experienceFlyoutShow) {
                dispatch(closeExperienceFlyout());
            }
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [experienceFlyoutShow, dispatch]);

    let _statusOptions;
    useEffect(() => {
        if (isObjectArray(userStatuses) && userStatuses.length > 0) {
            _statusOptions = userStatuses.map((status, index) => (
                <option key={index} value={status.value}>
                    {status.text}
                </option>
            ));
            setStatusOptions(_statusOptions);
        }

    }, [userStatuses]);

    return (
        <>
            {(experienceFlyoutShow) ? (<>
                <div className="flyout-wrapper position-fixed top-0 end-0">
                    <div className={`flyout-card ${isActive ? 'close' : 'open'}`}>
                        <div className="flyout-card-header">
                            <div className="float-end">
                                <div className="close-btn">
                                    <i className="fa fa-times" aria-hidden="true" data-tip='Close' onClick={ToggleClass}></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </div>
                            </div>
                            <div className="flyout-card-title">{experience && experience.id ? "Edit" : "Add"} Skill Details  </div>
                            <div className="flyout-card-sub-title">

                            </div>
                        </div>
                        <div className="flyout-card-body vh-100">


                            <div className="form-group p-0 pt-2">
                                <label id="lblFirstName" className="flyout-label" htmlFor="txtExperienceNumber">Skill Name</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{experience && experience.ExperienceName ? experience.ExperienceName.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtExperienceName"
                                                name="txtExperienceName"
                                                ShortTextClasses="form-control"
                                                placeholder="Skill Name"
                                                value={experience && experience.ExperienceName ? experience.ExperienceName.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.EXPERIENCE_DETAILS, null)}
                                                // required={experienceForm.ExperienceName.Required}
                                                required={true}
                                                // errorMessage={experienceForm.ExperienceName.Validation}
                                                form={EXPERIENCE_FORM}
                                                submited={cfSubmitted}
                                                disabled={editMode} />
                                        }
                                    />
                                }
                            </div>


                            <div className="form-group p-0 pt-2">
                                <label id="lblType" className="flyout-label" htmlFor="drpType">Field Type</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{experience && experience.Type ? experience.Type.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                id="drpType"
                                                name="drpType"
                                                SelectorClasses="form-select"
                                                value={experience && experience.Type ? experience.Type.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.EXPERIENCE_DETAILS, null)}
                                                options={
                                                    <>
                                                        <option value="Single Choice">Single Choice</option>
                                                        <option value="Multi Choice">Multi Choice</option>
                                                        <option value="Months">Months</option>
                                                    </>
                                                }
                                                // required={experienceForm.Type.Required}
                                                required={true}
                                                // errorMessage={experienceForm.Type.Validation}
                                                form={EXPERIENCE_FORM}
                                                submited={cfSubmitted}
                                                disabled={editMode} />
                                        }
                                    />
                                }
                            </div>

                            {(experience && (experience.Type.Data === 'Single Choice' || experience.Type.Data === 'Multi Choice')) && (
                                <div className="form-group p-0 pt-2">
                                    <label id="lblType" className="flyout-label" htmlFor="drpType">Options</label>

                                    {experience.ExperienceOptions.map((option, index) => (
                                        !editMode && !createMode ?
                                            <label className="field-label-value mb-3">{option}</label>
                                            :
                                            <div key={index} className='d-flex align-items-center mb-3'>
                                                <div className='w-100'>
                                                    <ShortText
                                                        id={`txtExperienceOption-${index}`}
                                                        name={`txtExperienceOption-${index}`}
                                                        ShortTextClasses="form-control"
                                                        // placeholder="Experience Option"
                                                        placeholder={`Experience Option ${index + 1}`}
                                                        value={option}
                                                        onChange={(e) => onValueUpdated(e, sections.EXPERIENCE_OPTIONS_DETAILS, null)}
                                                        required={true}
                                                        // errorMessage={experienceForm.ExperienceOption.Validation}
                                                        form={EXPERIENCE_FORM}
                                                        submited={cfSubmitted}
                                                        disabled={editMode} />
                                                </div>
                                                {
                                                    createMode && experience.ExperienceOptions.length > 1 &&
                                                    <RemoveIconButton
                                                        title="Remove Option"
                                                        RemoveIconButtonClasses="delete-document-button ms-4"
                                                        RemoveIconClasses="fa-lg"
                                                        onClick={(e) => onDeleteExperienceOptionClicked(e, index)}
                                                    />
                                                }
                                            </div>
                                    ))}
                                    {createMode &&
                                        <button type="button" className="c-btn dark-btn" onClick={(e) => onAddExperienceOptionClicked(e)} >
                                            Add Option
                                        </button>
                                    }
                                </div>
                            )}

                            <div className="form-group p-0 pt-2">
                                <label id="lblStatuses" className="flyout-label" htmlFor="drpStatuses">Status</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{experience ? experience.Status.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                id="drpStatuses"
                                                name="drpStatuses"
                                                SelectorClasses="form-select"
                                                value={(experience && experience.Status) ? experience.Status.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.EXPERIENCE_DETAILS, null)}
                                                options={
                                                    <>
                                                        {statusOptions}
                                                    </>
                                                }
                                                required={true}
                                                // errorMessage={}
                                                form={EXPERIENCE_FORM}
                                                submited={cfSubmitted} />}
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblSkillGroupId" className="flyout-label" htmlFor="drpSkillGroupId">Skill Group</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{experience ? experience.Status.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                id="drpSkillGroupId"
                                                name="drpSkillGroupId"
                                                SelectorClasses="form-select"
                                                value={(experience && experience.SkillGroupId) ? experience.SkillGroupId.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.EXPERIENCE_DETAILS, null)}
                                                options={
                                                    <>
                                                        {skillGroupOptions.map((o) => <option value={o.value}>{o.text}</option>)}
                                                    </>
                                                }
                                                required={true}
                                                // errorMessage={}
                                                form={EXPERIENCE_FORM}
                                                submited={cfSubmitted} />}
                                    />
                                }
                            </div>


                        </div>
                        <div className="flyout-card-footer">
                            <div className="d-flex">
                                <div className="ms-auto">
                                    {experienceCreationStatus === fetchStatus.LOADING || experienceModificationStatus === fetchStatus.LOADING ? <EllipsisLoader /> :
                                        <>
                                            <button className="c-btn white-btn me-3" type="button" onClick={ToggleClass} >Cancel</button>
                                            <button className="c-btn dark-btn" type="button" onClick={(e) => onSaveClicked(e, experience ? experience.id : "", createMode)}>Save</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flyout-backdrop flyout-backdrop-show' />
            </>
            ) :
                ''
            }
        </>

    )
}

export default ExperienceFlyout;