import React from 'react'
import AvatarGroup from '../Avatar/AvatarGroup';

import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import mapLocationImg from './../../assets/images/clipart/map-location.svg';
import calendarImg from './../../assets/images/clipart/calendar.svg';
import dateStartImg from './../../assets/images/clipart/date-start.svg';
import dateEndImg from './../../assets/images/clipart/date-end.svg';
import userActiveImg from './../../assets/images/clipart/user-active.svg';
import filesTextImg from './../../assets/images/clipart/files-text.svg';
import bagLockImg from './../../assets/images/clipart/bag-lock.svg';


function JobOrderHorizontalCard(props) {

    const _getOpenCount = () => {
        let _filled = props.filled ? props.filled : 0;
        let _total = props.total ? props.total : 0;
        if (isNaN(_filled) || isNaN(_total)) {
            return 0;
        }
        _filled = parseInt(_filled);
        _total = parseInt(_total);
        if (isNaN(_filled) || isNaN(_total)) {
            return 0;
        }
        else {
            return _total - _filled;
        }
    }

    const _getStatusClassBadge = () => {
        if (props.status === 'New') {
            return 'status-available';
        }
        else if (props.status === 'Inactive') {
            return 'status-incomplete ';
        }
        else if (props.status === 'INPROGRESS') {
            return 'status-inprogress';
        }
        else if (props.status === "Inprogress") {
            return 'status-inprogress';
        }
        else if (props.status === 'Filled') {
            return 'status-active';
        }
    }

    return (
        <div className="job-order-horizontal-card">
            <div className="job-order-horizontal-card-container cursor-pointer" onClick={props.onClick ? props.onClick : ''}>
                <div className="d-flex">
                    <div className="ms-auto">
                        <div className={`status ${_getStatusClassBadge()}`}>{props.status && props.status}</div>
                    </div>
                </div>
                <div className="job-order-horizontal-card-body">
                    <div className="job">
                        <div className="job-order-horizontal-card-profile" >
                            <div className="job-order-horizontal-card-profile-box">
                                {/* <img className="job-order-horizontal-card-profile-box-img" src={boyFaceImg} /> */}
                                <div className='job-order-horizontal-card-profile-box-text'>{props.name.slice(0, 2)}</div>
                            </div>
                        </div>
                        <div className="title">
                            <h1>{props.name}</h1>
                            <p>
                                <img src={mapLocationImg} />
                                {props.address}
                            </p>
                            <p className='pt-1'>
                                <img src={calendarImg} />
                                Request Date: {props.requestDate}</p>
                        </div>
                    </div>
                    <div className="job ms-auto">
                        {/* <div className="group-detai-container">
                            <div className="group-info group-info-fill">
                                <div className="group-detail">
                                    <div className="label">People Dispatched</div>
                                    <div className="detail">
                                        <AvatarGroup />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="d-flex flex-column">
                            {/* <div className="ms-auto d-flex">
                                <div className={`circle ${_getStatusClassBadge()}`} />
                                <div className={`status`}>{props.status}</div>
                            </div> */}
                            <div className='d-flex'>
                                <div className="group-detai-container">
                                    <div className="group-info">
                                        <img src={dateStartImg} className="group-info-icon" />
                                        <div className="group-detail">
                                            <div className="label">Job Start Date</div>
                                            <div className="detail">{props.startDate}, {props.startTime}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="group-detai-container">
                                    <div className="group-detai-container-line" />
                                    <div className="group-info">
                                        <img src={dateEndImg} className="group-info-icon" />
                                        <div className="group-detail">
                                            <div className="label">Job End Date</div>
                                            <div className="detail">{props.endDate}, {props.endTime}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="group-detai-container">
                                    <div className="group-detai-container-line" />
                                    <div className="group-info">
                                        <img src={userActiveImg} className="group-info-icon" />
                                        <div className="group-detail">
                                            <div className="label">Active People</div>
                                            <div className="detail">{props.numberOfActivePeople}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="group-detai-container">
                                    <div className="group-detai-container-line" />
                                    <div className="group-info">
                                        <img src={filesTextImg} className="group-info-icon" />
                                        <div className="group-detail">
                                            <div className="label">People Filled</div>
                                            <div className="detail">{props.filled}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="group-detai-container">
                                    <div className="group-detai-container-line" />
                                    <div className="group-info">
                                        <img src={bagLockImg} className="group-info-icon" />
                                        <div className="group-detail">
                                            <div className="label">Job Position Open</div>
                                            <div className="detail">{_getOpenCount()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobOrderHorizontalCard