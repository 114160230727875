import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _getLocationText } from '../../common/common';
import ShortText from '../Controls/custom/textbox/shortText';
import { isFormValid } from '../Controls/services/formService';
import { closeClientFlyout, createClient, getClientForm, selectClientById, selectClientFlyoutCreateMode, selectClientFlyoutEditmode, selectClientFlyoutShow, selectClientForm, selectClientFormStatus, selectCreationStatus, selectModificationStatus, updateClient, updateSingleData } from '../../features/setting/reducerSlices/clientSlice';
import { onValueChanged, sections } from '../../features/setting/services/clientSaver';
import { Operation } from '../../common/constants';
import Selector from '../Controls/custom/selectors/selectors';
import { selectLocationOptions } from '../../features/locations/reducerSlices/locationSlice';
import { isObjectArray } from '../../utilities/utilityFunctions';
import ZipCodeText from '../Controls/custom/textbox/zipCodeText';
import { fetchStatus, tenantId } from '../../api/client';
import { userStatuses } from '../../features/shared/services/masterData';
import { nanoid } from '@reduxjs/toolkit';
import clientLogo from '../../assets/images/clipart/client-logo.svg';
import InputGroup from '../Controls/custom/inputGroup/inputGroup';
import ReactTooltip from 'react-tooltip';
import EllipsisLoader from '../Controls/loaders/ellipsisLoader';
import { accountService } from '../../features/accounts/services/accountService';
import PhoneText from '../Controls/textboxes/phoneText';


function ClientFlyout(props) {

    const dispatch = useDispatch();
    const clientFlyoutShow = useSelector(selectClientFlyoutShow);

    const CLIENT_FORM = "Clientform";

    const [createMode, setCreatemode] = useState(false);
    const [editMode, setEditmode] = useState(false);
    const [isActive, setActive] = useState(false);
    const [cFInvalid, setCFInvalid] = useState(false);
    const [cfSubmitted, setCFSubmitted] = useState(false);
    const [base64Image, setBase64Image] = useState("");
    const [file, setFile] = useState();
    const [pageLoaded, setPageLoaded] = useState(false);
    const [statusOptions, setStatusOptions] = useState([]);

    const clientFlyoutCreateMode = useSelector(selectClientFlyoutCreateMode);
    const clientFlyoutEditMode = useSelector(selectClientFlyoutEditmode);
    const clientFormStatus = useSelector(selectClientFormStatus);
    const clientForm = useSelector(selectClientForm);
    const client = useSelector(selectClientById);
    const locationOptions = useSelector(selectLocationOptions);
    const clientCreationStatus = useSelector(selectCreationStatus);
    const clientModificationStatus = useSelector(selectModificationStatus);

    const ToggleClass = () => {
        setActive(!isActive);
        onCancelClicked();

        const timer = setTimeout(() => {
            dispatch(closeClientFlyout())
            setActive(false)
        }, 1500);
    };

    const onCancelClicked = (e) => {
        setCreatemode(false);
        setEditmode(false);
        setCFInvalid(false);
        setCFSubmitted(false);
    };


    useEffect(() => {
        setCreatemode(clientFlyoutCreateMode);
        setEditmode(clientFlyoutEditMode)
    }, [clientFlyoutCreateMode, clientFlyoutEditMode]);

    useEffect(() => {
        if (clientCreationStatus === fetchStatus.SUCCEEDED || clientModificationStatus === fetchStatus.SUCCEEDED) {
            ToggleClass()
        }
    }, [clientCreationStatus, clientModificationStatus]);

    useEffect(() => {
        if (!pageLoaded) {
            if (clientFormStatus === fetchStatus.IDLE && accountService.isAuthenticated()) {
                setPageLoaded(true);
                _loadForm();
            }
        }
    });

    let _locationOptions = undefined;

    if (isObjectArray(locationOptions)) {
        _locationOptions = locationOptions.map((o) => (
            <option key={o.value} value={o.value}>{o.text}</option>
        ));
    }

    const _loadForm = async () => {
        await dispatch(getClientForm({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "GETFORMBYTYPE",
            payload: {
                id: "",
                data: "ClientForm"
            }
        }));
    }

    const onValueUpdated = (e, section, value) => {
        let _clientObj = Object.assign({}, client);
        let _newClientObj = onValueChanged(_clientObj, e, section, value);
        dispatch(updateSingleData({
            client: _newClientObj,
            operation: Operation.UPDATED
        }));
    };

    let _statusOptions;
    useEffect(() => {
        if (isObjectArray(userStatuses) && userStatuses.length > 0) {
            _statusOptions = userStatuses.map((status, index) => (
                <option key={nanoid()} value={status.value}>
                    {status.text}
                </option>
            ));
            setStatusOptions(_statusOptions);
        }
    }, [userStatuses]);

    const onSaveClicked = (e, id, created) => {
        e.preventDefault();
        if (isFormValid(CLIENT_FORM)) {
            if (client) {
                if (created) {
                    dispatch(createClient({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVECLIENT",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                client: JSON.stringify(client),
                                photo: {
                                    contentType: "image/jpg",
                                    length: 0,
                                    photoData: base64Image
                                }
                            })
                        }
                    }));
                }
                else {
                    dispatch(updateClient({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVECLIENT",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                client: JSON.stringify(client),
                                photo: {
                                    contentType: "image/jpg",
                                    length: 0,
                                    photoData: base64Image
                                }
                            })
                        }
                    }));
                }
            }
            setCreatemode(false);
            setEditmode(false);
            setCFInvalid(false);
            setCFSubmitted(false);
        }
        else {
            setCFInvalid(true);
        }
        setCFSubmitted(true);
    };

    useEffect(() => {
        const handleBackButton = (event) => {
            if (clientFlyoutShow) {
                dispatch(closeClientFlyout());
            }
        };
    
        window.addEventListener('popstate', handleBackButton);
    
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [clientFlyoutShow, dispatch]);
    

    return (
        <>
            {(clientFlyoutShow) ? (<>
                <div className="flyout-wrapper position-fixed top-0 end-0">
                    <div className={`flyout-card ${isActive ? 'close' : 'open'}`}>
                        <div className="flyout-card-header">
                            <div className="float-end">
                                <div className="close-btn">
                                    <i className="fa fa-times" aria-hidden="true" data-tip='Close' onClick={ToggleClass}></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </div>
                            </div>
                            <div className="flyout-card-title">{client && client.id ? "Edit" : "Add"} Client Details  </div>
                            <div className="flyout-card-sub-title">

                            </div>
                        </div>
                        <div className="flyout-card-body vh-100">
                            <div className="d-flex align-items-center">
                                <div className="client-log-box">
                                    <img src={clientLogo} />
                                </div>
                                <button className="c-btn dark-btn ms-3" type="button">Upload Client Logo</button>
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblFirstName" className="flyout-label" htmlFor="txtClientNumber">Client Name</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{client && client.ClientName ? client.ClientName.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtClientName"
                                                name="txtClientName"
                                                ShortTextClasses="form-control"
                                                placeholder="Client Name"
                                                value={client && client.ClientName ? client.ClientName.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.CLIENT_DETAILS, null)}
                                                required={clientForm.ClientName.Required}
                                                errorMessage={clientForm.ClientName.Validation}
                                                form={CLIENT_FORM}
                                                submited={cfSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblClientNumber" className="flyout-label" htmlFor="txtClientNumber">Client Number</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{client && client.ClientNumber ? client.ClientNumber.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                required={clientForm.ClientNumber.Required}
                                                errorMessage={clientForm.ClientNumber.Validation}
                                                form={CLIENT_FORM}
                                                submited={cfSubmitted}
                                                id="txtClientNumber"
                                                name="txtClientNumber"
                                                ShortTextClasses="form-control"
                                                placeholder=""
                                                value={client && client.ClientNumber ? client.ClientNumber.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.CLIENT_DETAILS, null)} />}
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblStatuses" className="flyout-label" htmlFor="drpStatuses">Status</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{client ? client.Status.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                id="drpStatuses"
                                                name="drpStatuses"
                                                SelectorClasses="form-select"
                                                value={client ? client.Status.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.CLIENT_DETAILS, null)}
                                                options={
                                                    <>
                                                        {statusOptions}
                                                    </>
                                                }
                                                required={clientForm.Status.Required}
                                                errorMessage={clientForm.Status.Validation}
                                                form={CLIENT_FORM}
                                                submited={cfSubmitted} />}
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblLocation" className="flyout-label" htmlFor="drpLocations">Location</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{client && client.Location ? _getLocationText(locationOptions, client.Location.Data) : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                id="drpLocations"
                                                name="drpLocations"
                                                SelectorClasses="form-select"
                                                value={client && client.Location ? client.Location.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.CLIENT_DETAILS, null)}
                                                options={
                                                    <>
                                                        {_locationOptions}
                                                    </>
                                                }
                                                required={clientForm.Location.Required}
                                                errorMessage={clientForm.Location.Validation}
                                                form={CLIENT_FORM}
                                                submited={cfSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblAddress" className="flyout-label" htmlFor="txtAddress">Address</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{client && client.Address ? client.Address.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtAddress"
                                                name="txtAddress"
                                                ShortTextClasses="form-control"
                                                placeholder=""
                                                value={client && client.Address ? client.Address.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.CLIENT_DETAILS, null)}
                                                required={clientForm && clientForm.Address.Required}
                                                errorMessage={clientForm && clientForm.Address.Validation}
                                                form={CLIENT_FORM}
                                                submited={cfSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblCity" className="flyout-label" htmlFor="txtCity">City</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{client && client.City ? client.City.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtCity"
                                                name="txtCity"
                                                ShortTextClasses="form-control"
                                                placeholder="City"
                                                value={client && client.City ? client.City.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.CLIENT_DETAILS, null)}
                                                required={clientForm && clientForm.City.Required}
                                                errorMessage={clientForm && clientForm.City.Validation}
                                                form={CLIENT_FORM}
                                                submited={cfSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblState" className="flyout-label" htmlFor="txtState">State</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{client && client.State ? client.State.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector required={clientForm && clientForm.State.Required} errorMessage={clientForm && clientForm.State.validation} form={CLIENT_FORM} submited={cfSubmitted} id="txtState" name="txtState" SelectorClasses="form-select"
                                                value={client && client.State ? client.State.Data.trim() : ""}
                                                options={
                                                    <>
                                                        <option value="AL">AL</option>
                                                        <option value="AK">AK</option>
                                                        <option value="AR">AR</option>
                                                        <option value="AZ">AZ</option>
                                                        <option value="CA">CA</option>
                                                        <option value="CO">CO</option>
                                                        <option value="CT">CT</option>
                                                        <option value="DC">DC</option>
                                                        <option value="DE">DE</option>
                                                        <option value="FL">FL</option>
                                                        <option value="GA">GA</option>
                                                        <option value="HI">HI</option>
                                                        <option value="IA">IA</option>
                                                        <option value="ID">ID</option>
                                                        <option value="IL">IL</option>
                                                        <option value="IN">IN</option>
                                                        <option value="KS">KS</option>
                                                        <option value="KY">KY</option>
                                                        <option value="LA">LA</option>
                                                        <option value="MA">MA</option>
                                                        <option value="MD">MD</option>
                                                        <option value="ME">ME</option>
                                                        <option value="MI">MI</option>
                                                        <option value="MN">MN</option>
                                                        <option value="MO">MO</option>
                                                        <option value="MS">MS</option>
                                                        <option value="MT">MT</option>
                                                        <option value="NC">NC</option>
                                                        <option value="NE">NE</option>
                                                        <option value="NH">NH</option>
                                                        <option value="NJ">NJ</option>
                                                        <option value="NM">NM</option>
                                                        <option value="NV">NV</option>
                                                        <option value="NY">NY</option>
                                                        <option value="ND">ND</option>
                                                        <option value="OH">OH</option>
                                                        <option value="OK">OK</option>
                                                        <option value="OR">OR</option>
                                                        <option value="PA">PA</option>
                                                        <option value="RI">RI</option>
                                                        <option value="SC">SC</option>
                                                        <option value="SD">SD</option>
                                                        <option value="TN">TN</option>
                                                        <option value="TX">TX</option>
                                                        <option value="UT">UT</option>
                                                        <option value="VT">VT</option>
                                                        <option value="VA">VA</option>
                                                        <option value="WA">WA</option>
                                                        <option value="WI">WI</option>
                                                        <option value="WV">WV</option>
                                                        <option value="WY">WY</option>

                                                        <option value="AS">AS</option>
                                                        <option value="GU">GU</option>
                                                        <option value="MP">MP</option>
                                                        <option value="PR">PR</option>
                                                        <option value="UM">UM</option>
                                                        <option value="VI">VI</option>

                                                        <option value="AA">AA</option>
                                                        <option value="AP">AP</option>
                                                        <option value="AE">AE</option>
                                                    </>
                                                } onChange={(e) => onValueUpdated(e, sections.CLIENT_DETAILS, null)} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblZipcode" className="flyout-label" htmlFor="txtZipcode">Zipcode</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{client && client.ZipCode ? client.ZipCode.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ZipCodeText
                                                id="txtZipCode"
                                                name="txtZipCode"
                                                ZipCodeTextClasses="form-control"
                                                placeholder="Zipcode"
                                                value={client && client.ZipCode ? client.ZipCode.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.CLIENT_DETAILS, null)}
                                                required={clientForm && clientForm.ZipCode.Required}
                                                errorMessage={clientForm && clientForm.ZipCode.Validation}
                                                form={CLIENT_FORM}
                                                submited={cfSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblCompanyWebsite" className="flyout-label" htmlFor="txtCompanyWebsite">Company Website</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{client && client.CompanyWebsite ? client.CompanyWebsite.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtCompanyWebsite"
                                                name="txtCompanyWebsite"
                                                ShortTextClasses="form-control"
                                                placeholder="Company website"
                                                value={client && client.CompanyWebsite ? client.CompanyWebsite.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.CLIENT_DETAILS, null)}
                                                required={clientForm && clientForm.CompanyWebsite.Required}
                                                errorMessage={clientForm && clientForm.CompanyWebsite.Validation}
                                                form={CLIENT_FORM}
                                                submited={cfSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblClientPhone" className="flyout-label" htmlFor="txtClientPhone">Company Phone Number</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{client && client.ClientPhone ? client.ClientPhone.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <PhoneText
                                                id="txtClientPhone"
                                                name="txtClientPhone"
                                                PhoneTextClasses="form-control"
                                                value={client && client.ClientPhone ? client.ClientPhone.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.CLIENT_DETAILS, null)}
                                                required={clientForm.ClientPhone.Required}
                                                errorMessage={clientForm.ClientPhone.Validation}
                                                form={CLIENT_FORM}
                                                submited={cfSubmitted}
                                            />
                                        }
                                    />
                                }
                            </div>

                        </div>
                        <div className="flyout-card-footer">
                            <div className="d-flex">
                                <div className="ms-auto">
                                    {clientCreationStatus === fetchStatus.LOADING || clientModificationStatus === fetchStatus.LOADING ? <EllipsisLoader /> :
                                        <>
                                            <button className="c-btn white-btn me-3" type="button" onClick={ToggleClass} >Cancel</button>
                                            <button className="c-btn dark-btn" type="button" onClick={(e) => onSaveClicked(e, client ? client.id : "", createMode)}>Save</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flyout-backdrop flyout-backdrop-show' />
            </>
            ) :
                ''
            }
        </>

    )
}

export default ClientFlyout;