import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Loader from "react-js-loader";
import { fetchStatus, tenantId } from '../../../api/client';
import { closeModal, getClients, getJobsToBeDispatched, saveUpdatedApplicant, selectClients, selectClientsStatus, selectJobsToBeDispatched, selectJobsToBeDispatchedFiltered, selectJobsToBeDispatchedStatus, selectModalPopUpDetail } from './reducerSlices/ChangeLocationModalPopUpSlice';
import Radio from '../custom/checkers/radio';
import InputGroup from '../custom/inputGroup/inputGroup';
import EllipsisLoader from '../loaders/ellipsisLoader';
import Selector from '../custom/selectors/selectors';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import Statement from '../custom/textbox/statement';
import SaveButton from '../custom/buttons/saveButton';
import CancelButton from '../custom/buttons/cancelButton';
import { accountService } from '../../../features/accounts/services/accountService';
import { isFormValid } from '../services/formService';

import { saveNote, saveStatus, selectStatusChangeData, selectStatusChangeStatus } from '../../../features/reviewer/reducerSlices/reviewerSlice';
import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from '../../../features/locations/reducerSlices/locationSlice';

import {
    getBase64Image,
    isAgeOver18,
    openFileUploader,
    setUploadedPhoto,
    toLocalDate,
    _getDateText,
    _getLocationText
} from '../../../common/common';



function ChangeLocationModalPopUp(props) {

    const dispatch = useDispatch();
    const statusChangeStatus = useSelector(selectStatusChangeStatus);
    const modalPopUpDetails = useSelector(selectModalPopUpDetail);
    const STATUS_UPDATE_FORM = "DNRUpdateForm";
    const [cl, setCL] = useState("0");
    const [clientOptionsValue, setClientOptionsValue] = useState();
    const [clientId, setClientID] = useState("");
    const clientsStatus = useSelector(selectClientsStatus);
    const clients = useSelector(selectClients);
    const [clientOptions, setClientOptions] = useState(undefined);
    const applicant = modalPopUpDetails.applicant;
    const [statusNote, setStatusNote] = useState("");
    const [sufSubmitted, setSUFSubmitted] = useState(false);
    const [client, setClient] = useState("");
    const [locationOptionsValue, setLocationOptionsValue] = useState();
    const locationOptions = useSelector(selectLocationOptions);
    const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
    const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);
    const [locationid, setLocation] = useState("");

    const [loading, setLoading] = useState(false);
    let _locationOptions = undefined;

    useEffect(() => {
        // if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
        if ((locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) && accountService.isAuthenticated()) {
            setLoading(true);
            dispatch(getLocationOptions({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETLOCATIONOPTIONS",
                payload: {
                    id: "",
                    data: JSON.stringify({ All: "false" })
                }
            }));
        }
    }, [locationOptionsStatus, locationOptionsRefreshed]);


    useEffect(() => {
        if (isObjectArray(locationOptions)) {

            _locationOptions = locationOptions.map((o) => (
                <option value={o.value}>{o.text}</option>
            ));


            setLocationOptionsValue(_locationOptions);
            setLoading(false);
        }
    }, [locationOptions]);


    const onClientChanged = (e, s, v) => {
        setClientID(e.target.value);

    }



    const onCloseClicked = () => {
        // setJobKey("");
        // setStatusNote("");
        // setUDReason("");
        // setPayRates("");
        // setClient("");
        // setDispatchDate("");
        // setStartTime("");
        // setPostDispatchStatus("");
        dispatch(closeModal());
        // setSUFSubmitted(false);
        // setCL("0");
    };



    const onSaveClicked = async (id) => {
        const user = accountService.getAuthenticatedUser();
        if (locationid != "" && statusNote != "") {
            const statusDetails = {
                status: modalPopUpDetails.status.text,
                log: {
                    TimeStamp: (new Date()).toUTCString(),
                    LogType: "Location Change",
                    LogValue: -9,
                    Content: statusNote,
                    PayRates: null,
                    UserName: (user ? user.firstName + " " + user.lastName : ""),
                    UserId: (user ? user.id : ""),
                    DispatchDate: "",
                    locationid: locationid,
                    ClientListType: cl,
                    PostDispatchStatus: "",
                    StatusNote: statusNote
                },
                LocationData: {
                    locationid: locationid,
                    TimeStamp: (new Date()).toUTCString()
                }
            };

            //dispatch(saveUpdatedApplicant(_newApplicantObj));
            setSUFSubmitted(false);
            if (id) {
                await dispatch(saveStatus({
                    client: tenantId,
                    serviceType: "TENANTADMIN",
                    operation: "SAVEAPPLICANTLOCATION",
                    payload: {
                        id: id,
                        data: JSON.stringify(statusDetails)
                    }
                }));

            }
            dispatch(closeModal());
        }
        else {
            alert('Please Select Location and Enter notes');
            //  setSUFSubmitted(true);
        }

    };



    return (
        <React.Fragment>
            {(modalPopUpDetails && modalPopUpDetails.show) ? (<React.Fragment>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">

                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-block">
                                <div className="d-flex">
                                    <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                                    {statusChangeStatus === fetchStatus.LOADING ? <React.Fragment></React.Fragment> : <button
                                        type="button"
                                        className="modal-close-button ms-auto"
                                        onClick={onCloseClicked}>
                                        <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </button>}
                                </div>
                                <div className="d-flex">
                                    <span className="modal-sub-title">Applicant relocate to another location.</span>
                                </div>
                            </div>
                            <div className="modal-body">

                                <div className="row">
                                    <div className="col-md-12">
                                        <label id="lblClients" className="field-label" htmlFor="drpLocations">Location</label>

                                        <InputGroup
                                            icon={<i className="fa fa-map-marker"></i>}
                                            control={
                                                <Selector

                                                    id="drpLocations"
                                                    name="drpLocations"
                                                    SelectorClasses="form-select"

                                                    options={
                                                        <React.Fragment>
                                                            {locationOptionsValue}
                                                        </React.Fragment>
                                                    }
                                                    onChange={(e) => setLocation(e.target.value)} />
                                            } />
                                    </div>

                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <label id="lblStatusNote" className="field-label pt-2" htmlFor="txtStatusNote">Note</label>
                                        <InputGroup
                                            InputGroupClasses="mb-3"
                                            icon={<i className="fa fa-pencil-square-o"></i>}
                                            control={
                                                <Statement
                                                    id="txtStatusNote"
                                                    name="txtStatusNote"
                                                    value={statusNote}
                                                    StatementClasses="form-control"
                                                    onChange={(e) => setStatusNote(e.target.value)}
                                                    required={true}
                                                    errorMessage="Please enter status note"
                                                    form={STATUS_UPDATE_FORM}
                                                    submited={sufSubmitted}
                                                    placeholder="Enter status note..." />
                                            } />

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {(statusChangeStatus === fetchStatus.LOADING || clientsStatus === fetchStatus.LOADING) ? <EllipsisLoader /> : <React.Fragment><CancelButton
                                    CancelButtonClasses="mr-2"
                                    onCancelClicked={() => onCloseClicked()} />
                                    <SaveButton
                                        onSaveClicked={() => onSaveClicked(modalPopUpDetails.id)} /></React.Fragment>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </React.Fragment>) : <React.Fragment></React.Fragment>}
        </React.Fragment>
    );
}

export default ChangeLocationModalPopUp;