import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import store from './store';
import { Provider } from 'react-redux';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';

import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
// registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJjX39YcX1RRWNZUEU=');
registerLicense('Mgo+DSMBaFt/QHRqVVhkX1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSH9Td0FmUH9Yc3BWRw==;Mgo+DSMBPh8sVXJ0S0J+XE9AdVRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Td0RjWHtbeXRTRWVbUA==;ORg4AjUWIQA/Gnt2VVhkQlFaclxJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRiWX5adX1VQGNbUUU=;OTE0MzAzQDMyMzAyZTM0MmUzMGxBUmFnTGUwYmNTNGlCNUM5cFRDRUdkOEZ5TFM0clJicDdaaWplTy9RTDg9;OTE0MzA0QDMyMzAyZTM0MmUzME5QUWhObUNuQUxiV2toVWhqSnJPN0lnczdqUGM1anNUM0xJZWdlaDJncUU9;NRAiBiAaIQQuGjN/V0Z+WE9EaFtBVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUViWH9fdHFcRmZYUk13;OTE0MzA2QDMyMzAyZTM0MmUzME1tSmdHOEp5dmY4Qi81bldKcU14YThhSmNoT2tCeDA3UHZjVE1STnpudkU9;OTE0MzA3QDMyMzAyZTM0MmUzMFg5WXlKK3grU2ZNRVpiRTNRemNVeGlyczNwb2JoM1h0NktKZitlQ2JQWlE9;Mgo+DSMBMAY9C3t2VVhkQlFaclxJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRiWX5adX1VQGVcUkU=;OTE0MzA5QDMyMzAyZTM0MmUzMEVZMlpnN3plMXFFT0c4L2t4NVlVM2I0RU5hK0l3S2IzRkNEaEhIVjcvTDQ9;OTE0MzEwQDMyMzAyZTM0MmUzMEl3RUZianVhd3B6SUUrdTRtSG5pOTlYQzhCNGxSS2x1azNsZEVSZUlrOUk9;OTE0MzExQDMyMzAyZTM0MmUzME1tSmdHOEp5dmY4Qi81bldKcU14YThhSmNoT2tCeDA3UHZjVE1STnpudkU9'); // Version 20.2.0.36


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.getElementById('root')
);
// registerServiceWorker();
