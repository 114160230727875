import React, { useEffect, useRef } from 'react';

const GMap = (props) => {
    const googleMapRef = useRef(null);
    let googleMap = null;
    let markers = [];

    useEffect(() => {
        googleMap = initGoogleMap();
        var bounds = new window.google.maps.LatLngBounds();
        props.markerList && props.markerList.map(x => {
            const marker = createMarker(x);
            markers.push(marker);
            bounds.extend(marker.position);
        });
        googleMap.fitBounds(bounds); // the map to contain all markers

        // Add event listeners for showing and hiding tooltips on marker hover
        markers.forEach(marker => {
            marker.addListener('mouseover', () => {
                showCustomTooltip(marker, marker.getTitle());
            });

            marker.addListener('mouseout', () => {
                hideCustomTooltip();
            });
        });
    }, [props.markerList]);

    // initialize the google map
    const initGoogleMap = () => {
        return new window.google.maps.Map(googleMapRef.current, {
            center: { lat: 34.127, lng: -118.030 },
            zoom: 5
        });
    }

    // create marker on google map
    const createMarker = (markerObj) => new window.google.maps.Marker({
        position: { lat: markerObj.lat, lng: markerObj.lng },
        title: markerObj.title,
        label: markerObj.label,
        map: googleMap,
        icon: {
            url: markerObj.icon,
            // set marker width and height
            scaledSize: new window.google.maps.Size(50, 50)
        }
    });

    // Function to show the custom tooltip
    function showCustomTooltip(marker, title) {
        const tooltip = document.createElement('div');
        tooltip.classList.add('map-custom-tooltip');
        tooltip.innerHTML = title;

        googleMap.getDiv().appendChild(tooltip);

        const overlay = new window.google.maps.OverlayView();
        overlay.draw = function () { };
        overlay.setMap(googleMap);

        overlay.onAdd = function () {
            const point = this.getProjection().fromLatLngToContainerPixel(marker.getPosition());

            // Set the tooltip position relative to the marker
            const offsetX = -tooltip.offsetWidth / 2; // Adjust this value to position the tooltip horizontally
            const offsetY = -tooltip.offsetHeight - 40; // Adjust this value to position the tooltip vertically

            const tooltipLeft = point.x + offsetX;
            const tooltipTop = point.y + offsetY;

            tooltip.style.left = tooltipLeft + 'px';
            tooltip.style.top = tooltipTop + 'px';
        };
    }

    // Function to hide the custom tooltip
    function hideCustomTooltip() {
        const tooltips = document.getElementsByClassName('map-custom-tooltip');
        for (let i = 0; i < tooltips.length; i++) {
            tooltips[i].parentNode.removeChild(tooltips[i]);
        }
    }

    return <div
        ref={googleMapRef}
        style={
            { height: '80vh', width: '100%' }
        }
    />
}

export default GMap;