import React, { useEffect, useState } from 'react';
import { toLocalDate, _getDateText, _getStatusText } from '../../../../common/common';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// import {
//     selectCanceledStatus,
//     showSUModal,
//     resetCanceledStatus
// } from '../../../../../components/Controls/modals/reducerSlices/statusUpdateModalPopUpSlice';
import { getJobApplicantById, selecteJobApplicant, selectJobApplicant, selectJobApplicantStatus } from '../../reducerSlices/jobSlice';
import { fetchStatus, tenantId } from '../../../../api/client';
// import nophoto from '../../../../../assets/images/nophoto.png';
import nophoto from '../../../../assets/images/clipart/nophoto.png';
import { showSUModal } from '../../../../component/Controls/modals/reducerSlices/statusUpdateModalPopUpSlice';
import { updateRecentClickedApplicant } from '../../../applicant/reducerSlices/applicantSlice';
import DialCallButton from '../../../../component/Controls/buttons/dialCallButton';
import { selectStatusOptions } from '../../../setting/reducerSlices/statusesSlice';

// import {
//     showDispatchModal
// } from  '../../../../components/Controls/modals/reducerSlices/DispatchModalPopUpSlice'


function PreferredApplicant(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const [text, setText] = useState("");
    const [value, setValue] = useState("");
    const [prompted, setPrompted] = useState(false);

    const jobApplicant = useSelector(selectJobApplicant);
    const jobApplicantStatus = useSelector(selectJobApplicantStatus);

    const statusOptions = useSelector(selectStatusOptions);

    useEffect(() => {
        if (prompted && jobApplicantStatus === fetchStatus.SUCCEEDED && jobApplicant && jobApplicant.id) {
            let name = "";
            if (jobApplicant.FirstName && jobApplicant.FirstName.Data) {
                name += jobApplicant.FirstName.Data + " ";
            }
            if (jobApplicant.LastName && jobApplicant.LastName.Data) {
                name += jobApplicant.LastName.Data;
            }
            dispatch(showSUModal({
                title: <><span>Add Reason For</span> <span className="modal-header-name">{name}</span></>,
                id: jobApplicant.id,
                clientId: props.clientId,
                applicant: jobApplicant,
                status: {
                    value: value,
                    text: text
                }
            }));
            setPrompted(false);
        }
    });

    const _loadApplicant = async (id) => {
        await dispatch(getJobApplicantById({
            client: tenantId,
            serviceType: "TENANTUSER",
            operation: "GET",
            payload: {
                id: id,
                data: ""
            }
        }));
    }

    const onStatusChanged = (e, value, text, id, name) => {
        e.preventDefault();
        setText(text);
        setValue(value);
        setPrompted(true);
        _loadApplicant(id);

        // dispatch(showDispatchModal({
        //     title: <><span>Change Location for</span> <span className="modal-header-name">{name}</span></>,
        //     id: id,
        //     applicant: null
        // }));
    };
    const onApplicantClick = (e, applicantId) => {
        dispatch(updateRecentClickedApplicant(applicantId));
        history.push('/people/details');
    }



    const _getPostDispatchStatus = (value) => {
        if (value === "1") {
            return "Showed Up";
        }
        else if (value === "2") {
            return "Not Showed Up";
        }
        else if (value === "3") {
            return "2 Hours Pay";
        }
        return "N/A";
    }
    const _getPositionText = (id) => {
        if (props.positions) {
            let position = props.positions.find(p => p.value === id);
            if (position && position.text) {
                return position.text;
            }
        }
        return "N/A";
    }
    const [applicantDetailsTrs, setApplicantDetailsTrs] = useState([]);
    let _applicantDetailsTrs;

    useEffect(() => {
        if (isObjectArray(props.details)) {
            _applicantDetailsTrs = props.details.map((detail, index) => (

                <tr key={detail.id}>
                    <td>
                        <div className='d-flex align-items-center'>
                            <DialCallButton dialCallButtonClasses="pe-1" />
                        </div>
                        {detail.Status === "Available" ? <>
                            <a href="#" onClick={(e) => onStatusChanged(e, "Dispatched", "Dispatched", detail.id, detail.Name)} title="Dispatched" className="e-focus"><span className=""><i className="fa fa-check me-3"></i></span></a>
                        </> : <></>}
                    </td>
                    {/* <td>{index + 1}</td> */}
                    <td className="py-1">
                        {/* <img id={detail.id} className={`person-small-photo `}
                            src={detail && detail.Photo && detail.Photo.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "") || nophoto}
                            alt="No photo available"
                        /> */}
                        <div className="avatar">
                            <div className="avatar-box ">
                                <img
                                    // src={boyFaceImg}
                                    src={detail && detail.Photo && detail.Photo.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "") || nophoto}
                                    className="avatar-box-img"
                                />
                            </div>
                        </div>
                    </td>
                    <td className="py-2">
                        {/* <label className=" FieldsWidth100"  >{detail ? detail.Name : ""}</label> */}
                        <label className=" FieldsWidth100"  ><a href="#" onClick={(e) => onApplicantClick(e, detail.id)} title="Applicant" className="e-focus"><span className="zoomicon">{detail ? detail.Name : ""}</span></a>
                        </label>
                    </td>
                    <td className="py-2">
                        {detail ? detail.SSN : ""}
                    </td>
                    <td>
                        {detail ? _getDateText(detail.DOB) : ""}
                    </td>
                    <td>
                        {detail ? detail.Phone : ""}
                    </td>
                    <td>
                        {/* {detail ? detail.Status : ""} */}
                        {detail ? _getStatusText(statusOptions, detail.Status) : ""}
                    </td>
                    <td>
                        {detail ? detail.Flexibility : ""}
                    </td>

                    <td>
                        {detail ? detail.Repeat : ""}
                    </td>
                </tr>
            ));
            setApplicantDetailsTrs(_applicantDetailsTrs);
        }
        else {
            setApplicantDetailsTrs(<tr>
                <td colSpan="15">No records available</td>
            </tr>);
        }
    }, [props.details, props.readonly]);
    return (
        <>
            {applicantDetailsTrs}
        </>
    );
}

export default PreferredApplicant;