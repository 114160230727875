import React from 'react';

import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import girlFaceImg from './../../assets/images/clipart/girl-face.svg';
import nophoto from './../../assets/images/clipart/nophoto.png';

function Avatar(props) {
    return (
        <div className="avatar">
            <div className="avatar-box ">
                {/* <img src={boyFaceImg} className="avatar-box -img" /> */}
                <img className="avatar-box-img"
                    src={props && props.PhotoPath ?
                        props.PhotoPath.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "")
                        : props && props.Gender  === 'Female' ? girlFaceImg
                            : props && props.Gender === 'Male' ? boyFaceImg
                                : nophoto
                    }
                    draggable={false}
                />
            </div>
        </div>
    )
}

export default Avatar;