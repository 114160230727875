import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import { hideMenu, selectProspectsPeopleSorterDetail, setSortItem, toggleAscending, toggleMenu } from './reducerSlices/prospectsPeopleSorterSlice';

function ProspectsPeopleSorter(props) {
    const dispatch = useDispatch();
    const prospectsPeopleSorterDetail = useSelector(selectProspectsPeopleSorterDetail);

    const sortByContainerRef = useRef(null);

    useEffect(() => {
        if (prospectsPeopleSorterDetail.open && sortByContainerRef && sortByContainerRef.current) {
            sortByContainerRef.current.focus();
        }
    });

    let _sortByList;
    if (isObjectArray(prospectsPeopleSorterDetail.sortByItems)) {
        if (prospectsPeopleSorterDetail.sortByItems.length > 0) {
            _sortByList = prospectsPeopleSorterDetail.sortByItems.map((sortByItem, index) => (
                <li
                    className="sort-by-menu-item"
                    onClick={(e) => onSortItemClicked(e, sortByItem.value, sortByItem.text)}>
                    {sortByItem.text}
                </li>
            ));
        }
    }

    const onBlur = (e) => {
        dispatch(hideMenu());
    };

    const onSorted = (e) => {
        dispatch(toggleAscending(!prospectsPeopleSorterDetail.ascending));
        props.onSorted && props.onSorted(prospectsPeopleSorterDetail.sortBy, !prospectsPeopleSorterDetail.ascending);
    };

    const onSortItemClicked = (e, value, text) => {
        dispatch(setSortItem({
            sortByText: text,
            sortBy: value,
            open: false
        }));
        props.onSorted && props.onSorted(value, prospectsPeopleSorterDetail.ascending);
    };

    const toggle = () => {
        dispatch(toggleMenu(!prospectsPeopleSorterDetail.open));
    };

    return (
        <div className="sort-by-container">
            <div>
                <span className="sort-by" onClick={(e) => toggle()}>{prospectsPeopleSorterDetail.sortByText}</span>
                {prospectsPeopleSorterDetail.open ? <div className="sort-by-menu"
                    ref={sortByContainerRef}
                    tabIndex={-1}
                    onBlur={(e) => onBlur(e)}>
                    <ul className="sort-by-menu-items">
                        {_sortByList}
                    </ul>
                </div> : <></>}
            </div>
            <span className="sort-button" onClick={(e) => onSorted(e)}><i className={prospectsPeopleSorterDetail.ascending ? "fa fa-chevron-down" : "fa fa-chevron-up"}></i></span>
        </div>
    );
}

export default ProspectsPeopleSorter;