import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckBox from '../../Controls/custom/checkers/checkbox';
import { selectGenderFilter, updateGenderFilter, selectSelectedFilter, updateSelectedFilter } from './reducerSlices/filterSilce';

function GenderFilter(props) {
    const dispatch = useDispatch();
    //const selectedFilter = useSelector(selectSelectedFilter);

    const [open, setOpen] = useState(false);

    const genderFilter = useSelector(props.genderFilterSelector);

    const gfContainerRef = useRef(null);

    let _genders = undefined;
    _genders = [
        {
            text: "Male",
            value: "Male"
        },
        {
            text: "Female",
            value: "Female"
        },
        {
            text: "Others",
            value: "Other"
        }
    ];

    let gendersList;
    let isGendersSelected = (genderFilter.value && Array.isArray(genderFilter.value) && genderFilter.value.length > 0);
    if (_genders && Array.isArray(_genders) && _genders.length > 0) {
        gendersList = _genders.map((gender, index) => (
            <li key={gender.value} className="filter-list-item"
                onClick={(e) => props.onGenderChanged && props.onGenderChanged(e, gender.value, _genders)}>
                <CheckBox
                    id={`ckbGender-${gender.value}`}
                    size="Small"
                    checked={isGendersSelected ? genderFilter.value.includes(gender.value) : false} />
                <span className={`ms-3 ${isGendersSelected && genderFilter.value.includes(gender.value) ? "filter-selected" : ""}`}>{gender.text}</span>

            </li>
        ));
    }

    useEffect(() => {
        if (open && gfContainerRef && gfContainerRef.current) {
            gfContainerRef.current.focus();
        }
    });

    const onGenderChanged = async (e, value) => {
        await dispatch(updateGenderFilter({
            genders: _genders,
            value: value
        }));
    };

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Gender</span> :
                <span className="filter-value">{genderFilter.value ? genderFilter.text : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={gfContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    {gendersList}
                </ul>
            </div> : <></>}
        </div>
    );
}

export default GenderFilter;