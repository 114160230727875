import React, { createRef, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import { accountService } from '../../accounts/services/accountService';
import DashboardCard from './component/dashboardCard';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import ChartFilter from './component/chartFilter';
import deskPeopleImg from '../../../assets/images/clipart/desk-avatar.png';
import Moment from 'moment';
import Loader from "react-js-loader";

import {
  getGraphCounts, selectGraphCountStatus, selectGraphCounts,
  getRegistrationCounts, selectRegistrationCountStatus, selectRegistrationCount,
  getDispatchCounts, selectDispatchCountStatus, selectDispatchCount,
  getInterviewCounts, selectInterviewCountStatus, selectInterviewCount,
  getJobCounts, selectJobCountStatus, selectJobCount,
  getClientCounts, selectClientCountStatus, selectClientCount,
  getNoShowCounts, selectNoShowCount,
  getCallCounts, selectCallCount,
  getStatusCounts, selectStatusCount, selectStatusStatus,
  getLeadStatusCounts, selectLeadStatusCount, selectLeadStatusStatus,
  getDispatchListCounts, selectDispatchListStatus, selectDispatchListCount,
  getInterviewListCounts, selectInterviewListCount,
  getEmployeePerformanceListCounts, selectEmployeePerformanceListCount,
  getLeadPerformanceListCounts, selectLeadPerformanceListCount,
  getUserOptions, selectUserOptions, selectUserOptionsState,
  getEmailBodyDashboard, selectEmailBodyDashboard, selectEmailBodyDashboardStatus,
  getActivePeopleCounts, selectActivePeopleCount
} from './../reducerSlices/dashboardSlice';
import { getDashbaordLocationOptionsAll, selectLocationOptionsAll, selectLocationOptionsAllRefreshed, selectLocationOptionsAllStatus } from '../../locations/reducerSlices/locationSlice';
import { fetchStatus, tenantId } from '../../../api/client';
import HirebaseHorizontalBarChart from './component/hirebaseHorizontalBarChart';
import HirebaseVerticalBarChart from './component/hirebaseVerticalBarChart';
import HirebaseLineChart from './component/hirebaseLineChart';
import HirebaseMultipleVerticalBarChart from './component/hirebaseMultipleVerticalBarChart';
import HirebaseVerticalStackedBarChart from './component/hirebaseVerticalStackedBarChart';
import HirebaseMultipleLineChart from './component/hirebaseMultipleLineChart';
import HirebaseDoughnutChart from './component/hirebaseDoughnutChart';
import TopList from './component/topList';
import { isJSON } from '../../../utilities/utilityFunctions';
import exportAsImage2 from './component/exportAsImage2';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';

ChartJS.register(ArcElement, Tooltip, Legend);

function Dashboard() {

  const history = useHistory();
  const dispatch = useDispatch();

  const user = accountService.getAuthenticatedUser();
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 12,
        title: "Dashboard"
      }));
      setPageLoaded(true);
    }
  });


  const exportRef = useRef();
  const exportRefDashboardCard = useRef();
  const exportRefTopInterviewer = useRef();
  const exportRefTopDispatchersr = useRef();
  const exportRefApplicantInterview = useRef();
  const exportRefApplicantDispatche = useRef();
  const exportRefAssociateMovements = useRef();
  const exportRefLeadStatus = useRef();
  const exportRefCalledInAndCalledOut = useRef();
  const exportRefApplicantRegistration = useRef();
  const exportRefNewJobsByOffice = useRef();
  const exportRefClients = useRef();
  const exportRefNoShow = useRef();
  const exportRefEmployeePerformance = useRef();
  const exportRefLeadPerformance = useRef();
  const exportRefActivePeople = useRef();


  const ref = createRef(null);
  // const [image, takeScreenShot] = useScreenshot({
  //   type: "image/jpeg",
  //   quality: 1.0
  // });

  // const download = (image, { name = "img", extension = "jpg" } = {}) => {
  //   const a = document.createElement("a");
  //   a.href = image;
  //   a.download = createFileName(extension, name);
  //   a.click();
  // };

  // const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  const [actBtnApplicantRegistration, setActBtnApplicantRegistration] = useState("");
  const [locationApplicantRegistration, setLocationApplicantRegistration] = useState("");
  const [startApplicantRegistration, setStartApplicantRegistration] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endApplicantRegistration, setEndApplicantRegistration] = useState(new Date());

  const [actBtnAssociateMovements, setActBtnAssociateMovements] = useState("");
  const [locationAssociateMovements, setLocationAssociateMovements] = useState("All");
  const [startAssociateMovements, setStartAssociateMovements] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endAssociateMovements, setEndAssociateMovements] = useState(new Date());

  const [actBtnLeadStatus, setActBtnLeadStatus] = useState("");
  const [locationLeadStatus, setLocationLeadStatus] = useState("All");
  const [startLeadStatus, setStartLeadStatus] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endLeadStatus, setEndLeadStatus] = useState(new Date());

  const [actBtnStatusChange, setActBtnStatusChange] = useState("");
  const [locationStatusChange, setLocationStatusChange] = useState("");
  const [startStatusChange, setStartStatusChange] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endStatusChange, setEndStatusChange] = useState(new Date());

  const [actBtnEmployeePerformance, setActBtnEmployeePerformance] = useState("");
  const [locationEmployeePerformance, setLocationEmployeePerformance] = useState("All");
  const [empEmployeePerformance, setEmpEmployeePerformance] = useState();
  const [startEmployeePerformance, setStartEmployeePerformance] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endEmployeePerformance, setEndEmployeePerformance] = useState(new Date());

  const [actBtnLeadPerformance, setActBtnLeadPerformance] = useState("");
  const [locationLeadPerformance, setLocationLeadPerformance] = useState("All");
  const [startLeadPerformance, setStartLeadPerformance] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endLeadPerformance, setEndLeadPerformance] = useState(new Date());

  const [actBtnTopInterviewers, setActBtnTopInterviewers] = useState("");
  const [locationTopInterview, setLocationTopInterview] = useState("All");
  const [startTopInterviewers, setStartTopInterviewers] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endTopInterviewers, setEndTopInterviewers] = useState(new Date());

  const [actBtnApplicantDispatches, setActBtnApplicantDispatches] = useState("");
  const [locationApplicantDispatches, setLocationApplicantDispatches] = useState("All");
  const [startApplicantDispatches, setStartApplicantDispatches] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endApplicantDispatches, setEndApplicantDispatches] = useState(new Date());

  const [actBtnCalledInAndCalledOut, setActBtnCalledInAndCalledOut] = useState("");
  const [locationCalledInAndCalledOut, setLocationCalledInAndCalledOut] = useState("All");
  const [startCalledInAndCalledOut, setStartCalledInAndCalledOut] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endCalledInAndCalledOut, setEndCalledInAndCalledOut] = useState(new Date());

  const [actBtnApplicantInterview, setActBtnApplicantInterview] = useState("");
  const [locationApplicantInterview, setLocationApplicantInterview] = useState("All");
  const [startApplicantInterview, setStartApplicantInterview] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endApplicantInterview, setEndApplicantInterview] = useState(new Date());

  const [actBtnNewJobsByOffice, setActBtnNewJobsByOffice] = useState("");
  const [locationNewJobsByOffice, setLocationNewJobsByOffice] = useState("");
  const [startNewJobsByOffice, setStartNewJobsByOffice] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endNewJobsByOffice, setEndNewJobsByOffice] = useState(new Date());

  const [actBtnJob, setActBtnJob] = useState("");
  const [locationJob, setLocationJob] = useState("");
  const [startJob, setStartJob] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endJob, setEndJob] = useState(new Date());

  const [actBtnClients, setActBtnClients] = useState("");
  const [locationClients, setLocationClients] = useState("");
  const [startClients, setStartClients] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endClients, setEndClients] = useState(new Date());

  const [actBtnTopDispatchers, setActBtnTopDispatchers] = useState("");
  const [locationTopDispatchers, setLocationTopDispatchers] = useState("All");
  const [startTopDispatchers, setStartTopDispatchers] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endTopDispatchers, setEndTopDispatchers] = useState(new Date());


  const [actBtnNoShow, setActBtnNoShow] = useState("");
  const [locationNoShow, setLocationNoShow] = useState("");
  const [startNoShow, setStartNoShow] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endNoShow, setEndNoShow] = useState(new Date());

  const [actBtnActivePeople, setActBtnActivePeople] = useState("");
  const [locationActivePeople, setLocationActivePeople] = useState("All");
  const [startActivePeople, setStartActivePeople] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [endActivePeople, setEndActivePeople] = useState(new Date());

  const [start, setStart] = useState(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [end, setEnd] = useState(new Date());


  const [locationOptionsValue, setLocationOptionsValue] = useState();
  const locationOptions = useSelector(selectLocationOptionsAll);
  const locationOptionsStatus = useSelector(selectLocationOptionsAllStatus);
  const locationOptionsRefreshed = useSelector(selectLocationOptionsAllRefreshed);

  const [userOptionsValue, setUserOptionsValue] = useState();
  const userOptions = useSelector(selectUserOptions);
  const userOptionsStatus = useSelector(selectUserOptionsState);


  const graphCounts = useSelector(selectGraphCounts);
  const graphCountRefreshed = useSelector(selectGraphCountStatus);
  const registrationCount = useSelector(selectRegistrationCount);
  const registrationCountStatus = useSelector(selectRegistrationCountStatus);
  const dispatchCount = useSelector(selectDispatchCount);
  const interviewCount = useSelector(selectInterviewCount);
  const jobCount = useSelector(selectJobCount);
  const clientCount = useSelector(selectClientCount);
  const noShowCount = useSelector(selectNoShowCount);
  const callCount = useSelector(selectCallCount);
  const statusCount = useSelector(selectStatusCount);
  const statusCountRefreshed = useSelector(selectStatusStatus);
  const leadStatusCount = useSelector(selectLeadStatusCount);
  const leadStatusCountRefreshed = useSelector(selectLeadStatusStatus);
  const dispatchListCount = useSelector(selectDispatchListCount);
  const dispatchListStatus = useSelector(selectDispatchListStatus);
  const interviewListCount = useSelector(selectInterviewListCount);
  const employeePerformanceListCount = useSelector(selectEmployeePerformanceListCount);
  const leadPerformanceListCount = useSelector(selectLeadPerformanceListCount);
  const activePeopleCount = useSelector(selectActivePeopleCount);
  const emailBodyDashboardStatus = useSelector(selectEmailBodyDashboardStatus);
  const emailBodyDashboard = useSelector(selectEmailBodyDashboard);

  const [loading, setLoading] = useState(false);
  const [locationid, setLocation] = useState("");

  const isAdmin = accountService.isAdmin();
  const isPayroll = accountService.isPayroll();
  const IsReviewer = accountService.isReviewer();
  const isSalesRep = accountService.isSalesRep();
  const isSalesManager = accountService.isSalesManager();


  let _locationOptions = undefined;

  let _userOptions = undefined;

  useEffect(() => {
    if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
      setLoading(true);
      dispatch(getDashbaordLocationOptionsAll({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLOCATIONOPTIONS",

        payload: {
          id: "",
          data: JSON.stringify({ All: "true" })
        }
      }));

    }
  }, [locationOptionsStatus, locationOptionsRefreshed]);


  useEffect(() => {
    // if (isObjectArray(locationOptions)) {
    if (locationOptions) {
      _locationOptions = locationOptions.map((o) => (

        <option value={o.value} selected={o.text == "All"}>{o.text}</option>
      ));
      setLocationOptionsValue(_locationOptions);
      setLoading(false);
    }
  }, [locationOptions]);

  useEffect(() => {
    // if (userOptionsRefreshed || userOptionsStatus === fetchStatus.IDLE) {
    if (userOptionsStatus === fetchStatus.IDLE) {
      setLoading(true);
      _getUserOption("All");
    }
    // }, [userOptionsStatus, userOptionsRefreshed]);
  }, [userOptionsStatus]);

  useEffect(() => {
    // if (isObjectArray(userOptions)) {
    if (userOptions) {
      _userOptions = userOptions.map((o) => (
        // <option value={o.value} selected ={o.text == "All"}>{o.text}</option>
        // <option value={o.value} selected ={o.text == "All"}>{o.FirstName} {o.FirstName}</option>
        <option value={o.id} selected={o.text == "All"}>{o.FirstName} {o.LastName}</option>
      ));
      setUserOptionsValue(_userOptions);
      setLoading(false);
    }
  }, [userOptions]);

  const _getUserOption = (locationId) => {
    dispatch(getUserOptions({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETUSERSBYLOCATION",
      payload: {
        id: "",
        data: JSON.stringify({ "locationId": locationId })
      }
    }));
  }


  const setState = () => {
    setActBtnApplicantRegistration("");
    setLocationApplicantRegistration("All");
    setStartApplicantRegistration(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndApplicantRegistration(new Date());

    setActBtnAssociateMovements("");
    setLocationAssociateMovements("All");
    setStartAssociateMovements(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndAssociateMovements(new Date());

    setActBtnLeadStatus("");
    setLocationLeadStatus("All");
    setStartLeadStatus(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndLeadStatus(new Date());

    setActBtnStatusChange("");
    setLocationStatusChange("All");
    setStartStatusChange(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndStatusChange(new Date());

    setActBtnEmployeePerformance("");
    setLocationEmployeePerformance("All");
    setEmpEmployeePerformance("");
    setStartEmployeePerformance(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndEmployeePerformance(new Date());

    setActBtnLeadPerformance("");
    setLocationLeadPerformance("All");
    setStartLeadPerformance(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndLeadPerformance(new Date());

    setActBtnTopInterviewers("");
    setLocationTopInterview("All");
    setStartTopInterviewers(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndTopInterviewers(new Date());

    setActBtnApplicantDispatches("");
    setLocationApplicantDispatches("All");
    setStartApplicantDispatches(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndApplicantDispatches(new Date());

    setActBtnCalledInAndCalledOut("");
    setLocationCalledInAndCalledOut("All");
    setStartCalledInAndCalledOut(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndCalledInAndCalledOut(new Date());

    setActBtnApplicantInterview("");
    setLocationApplicantInterview("All");
    setStartApplicantInterview(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndApplicantInterview(new Date());

    setActBtnNewJobsByOffice("");
    setLocationNewJobsByOffice("All");
    setStartNewJobsByOffice(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndNewJobsByOffice(new Date());

    setActBtnJob("");
    setLocationJob("All");
    setStartJob(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndJob(new Date());

    setActBtnClients("");
    setLocationClients("");
    setStartClients(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndClients(new Date());

    setActBtnTopDispatchers("");
    setLocationTopDispatchers("All");
    setStartTopDispatchers(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndTopDispatchers(new Date());


    setActBtnNoShow("");
    setLocationNoShow("All");
    setStartNoShow(Moment(Moment(new Date()).add(-6, 'd').format('YYYY/MM/DD HH:mm:ss')));
    setEndNoShow(new Date());

  }


  useEffect(() => {
    if (graphCountRefreshed || graphCountRefreshed === fetchStatus.IDLE) {


      setLoading(true);

      dispatch(getGraphCounts({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETGRAPHCOUNTS",
        payload: {
          id: "",
          data: JSON.stringify({
            locationId: "all",
            startDate: Moment(start).format('YYYY-MM-DD'),
            endDate: Moment(end).format('YYYY-MM-DD')
          })
        }
      }));
      //  GetEmployeePerformanceListData();
      // GetInterviewListData();
      // GetDispatchListData();
      // GetInterviewData();
      // GetDispatchData();
      // GetStatusData();
      // GetCallData();
      // GetRegistrationData();
      // GetJobData();
      // GetClientData();
      // GetNoShowData();




    }
  }, [start]);


  var curr = new Date;
  var lastWeekDate = Moment(curr).subtract(6, 'day');
  var lastMonthDate = Moment(curr).subtract(1, 'month');
  var lastTodayDate = Moment(curr).subtract(1, 'day');




  const GetInterviewListData = () => {
    dispatch(getInterviewListCounts({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCHARTTOPINTERVIEWERS",
      payload: {
        id: "",
        data: JSON.stringify({
          locationId: locationTopInterview,
          startDate: Moment(startTopInterviewers).format('YYYY-MM-DD'),
          endDate: Moment(endTopInterviewers).format('YYYY-MM-DD')

        })
      }
    }));
  }

  const GetDispatchListData = () => {
    dispatch(getDispatchListCounts({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCHARTTOPDISPATCHERS",
      payload: {
        id: "",
        data: JSON.stringify({
          locationId: locationTopDispatchers,
          startDate: Moment(startTopDispatchers).format('YYYY-MM-DD'),
          endDate: Moment(endTopDispatchers).format('YYYY-MM-DD')

        })
      }
    }));
  }

  const GetLeadPerformanceListData = () => {
    dispatch(getLeadPerformanceListCounts({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETLEADPERFORMANCE",
      payload: {
        id: "",
        data: JSON.stringify({
          locationId: locationLeadPerformance,
          startDate: Moment(startLeadPerformance).format('YYYY-MM-DD'),
          endDate: Moment(endLeadPerformance).format('YYYY-MM-DD')
        })
      }
    }));
  }

  const GetRegistrationData = () => {
    dispatch(getRegistrationCounts({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCHARTAPPLICANTREGISTRATION",
      payload: {
        id: "",
        data: JSON.stringify({
          locationId: "all",
          startDate: Moment(startApplicantRegistration).format('YYYY-MM-DD'),
          endDate: Moment(endApplicantRegistration).format('YYYY-MM-DD')

        })
      }
    }));
  }

  const GetClientData = () => {
    dispatch(getClientCounts({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCHARTCLIENTS",
      payload: {
        id: "",
        data: JSON.stringify({
          locationId: "all",
          startDate: Moment(startClients).format('YYYY-MM-DD'),
          endDate: Moment(endClients).format('YYYY-MM-DD')

        })
      }
    }));
  }

  const GetDispatchData = () => {
    dispatch(getDispatchCounts({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCHARTAPPLICANTDISPATCHES",
      payload: {
        id: "",
        data: JSON.stringify({
          locationId: locationApplicantDispatches,
          startDate: Moment(startApplicantDispatches).format('YYYY-MM-DD'),
          endDate: Moment(endApplicantDispatches).format('YYYY-MM-DD')

        })
      }
    }));
  }

  const GetStatusData = () => {
    dispatch(getStatusCounts({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCHARTASSOCIATEMOVEMENTS",
      payload: {
        id: "",
        data: JSON.stringify({
          locationId: locationAssociateMovements,
          startDate: Moment(startAssociateMovements).format('YYYY-MM-DD'),
          endDate: Moment(endAssociateMovements).format('YYYY-MM-DD')

        })
      }
    }));
  }

  const GetLeadStatusData = () => {
    dispatch(getLeadStatusCounts({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCHARTLEADSTATUS",
      payload: {
        id: "",
        data: JSON.stringify({
          locationId: locationLeadStatus,
          startDate: Moment(startLeadStatus).format('YYYY-MM-DD'),
          endDate: Moment(endLeadStatus).format('YYYY-MM-DD')

        })
      }
    }));
  }

  const GetJobData = () => {
    dispatch(getJobCounts({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCHARTNEWJOBSBYOFFICE",
      payload: {
        id: "",
        data: JSON.stringify({
          locationId: "all",
          startDate: Moment(startNewJobsByOffice).format('YYYY-MM-DD'),
          endDate: Moment(endNewJobsByOffice).format('YYYY-MM-DD')

        })
      }
    }));
  }

  const GetCallData = () => {
    if (statusCountRefreshed || statusCountRefreshed === fetchStatus.IDLE) {
      dispatch(getCallCounts({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETCHARTCALLEDINANDCALLEDOUT",
        payload: {
          id: "",
          data: JSON.stringify({
            locationId: locationCalledInAndCalledOut,
            startDate: Moment(startCalledInAndCalledOut).format('YYYY-MM-DD'),
            endDate: Moment(endCalledInAndCalledOut).format('YYYY-MM-DD')

          })
        }
      }));
    }
  }

  const GetNoShowData = () => {
    dispatch(getNoShowCounts({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCHARTNOSHOW",
      payload: {
        id: "",
        data: JSON.stringify({
          locationId: "all",
          startDate: Moment(startNoShow).format('YYYY-MM-DD'),
          endDate: Moment(endNoShow).format('YYYY-MM-DD')

        })
      }
    }));
  }

  const GetInterviewData = () => {
    dispatch(getInterviewCounts({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCHARTAPPLICANTINTERVIEW",
      payload: {
        id: "",
        data: JSON.stringify({
          locationId: locationApplicantInterview,
          startDate: Moment(startApplicantInterview).format('YYYY-MM-DD'),
          endDate: Moment(endApplicantInterview).format('YYYY-MM-DD')

        })
      }
    }));
  }

  const GetActivePeopleData = () => {
    dispatch(getActivePeopleCounts({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETCHARTACTIVEPEOPLE",
      payload: {
        id: "",
        data: JSON.stringify({
          locationId: locationActivePeople,
          startDate: Moment(startActivePeople).format('YYYY-MM-DD'),
          endDate: Moment(endActivePeople).format('YYYY-MM-DD')
        })
      }
    }));
  }


  const onWeekClickedLeadPerformance = (e) => {
    setStartLeadPerformance(lastWeekDate);
    setEndLeadPerformance(curr);
    setActBtnLeadPerformance("week");
  }
  const onMonthClickedLeadPerformance = (e) => {
    setStartLeadPerformance(lastMonthDate);
    setEndLeadPerformance(curr);
    setActBtnLeadPerformance("month");
  }
  const onTodayClickedLeadPerformance = (e) => {
    setStartLeadPerformance(curr);
    setEndLeadPerformance(curr);
    setActBtnLeadPerformance("today");
  }
  const onLeadPerformanceLocationChange = (e) => {
    setLocationLeadPerformance(e.target.value)
  }
  const onLeadPerformanceDateChange = (e) => {
    setStartLeadPerformance(e.startDate);
    setEndLeadPerformance(e.endDate);
    setActBtnLeadPerformance("custom");
  }

  useEffect(() => {
    GetLeadPerformanceListData();
  }, [actBtnLeadPerformance, startLeadPerformance, endLeadPerformance]);

  useEffect(() => {
    GetLeadPerformanceListData();
  }, [locationLeadPerformance]);



  const onWeekClickedTopInterviewers = (e) => {
    setStartTopInterviewers(lastWeekDate);
    setEndTopInterviewers(curr);
    setActBtnTopInterviewers("week");
  }
  const onMonthClickedTopInterviewers = (e) => {
    setStartTopInterviewers(lastMonthDate);
    setEndTopInterviewers(curr);
    setActBtnTopInterviewers("month");
  }
  const onTodayClickedTopInterviewers = (e) => {
    setStartTopInterviewers(lastTodayDate);
    setEndTopInterviewers(curr);
    setActBtnTopInterviewers("today");
  }
  const onTopInterviewLocationChange = (e) => {
    setLocationTopInterview(e.target.value)
  }
  const onTopInterviewersDateChange = (e) => {
    setStartTopInterviewers(e.startDate);
    setEndTopInterviewers(e.endDate);
    setActBtnTopInterviewers("custom");
  }

  useEffect(() => {
    GetInterviewListData();
  }, [actBtnTopInterviewers, startTopInterviewers, endTopInterviewers, locationTopInterview]);



  const onWeekClickedTopDispatchers = (e) => {
    setStartTopDispatchers(lastWeekDate);
    setEndTopDispatchers(curr);
    setActBtnTopDispatchers("week");
  }
  const onMonthClickedTopDispatchers = (e) => {
    setStartTopDispatchers(lastMonthDate);
    setEndTopDispatchers(curr);
    setActBtnTopDispatchers("month");
  }
  const onTodayClickedTopDispatchers = (e) => {
    setStartTopDispatchers(curr);
    setEndTopDispatchers(curr);
    setActBtnTopDispatchers("today");
  }
  const onTopDispatchersLocationChange = (e) => {
    setLocationTopDispatchers(e.target.value)
  }
  const onTopDispatchersDateChange = (e) => {
    setStartTopDispatchers(e.startDate);
    setEndTopDispatchers(e.endDate);
    setActBtnTopDispatchers("custome");
  }

  useEffect(() => {
    GetDispatchListData()
  }, [actBtnTopDispatchers, startTopDispatchers, endTopDispatchers, locationTopDispatchers]);



  const onWeekClickedActivePeople = (e) => {
    setStartActivePeople(lastWeekDate);
    setEndActivePeople(curr);
    setActBtnActivePeople("week");
  }
  const onMonthClickedActivePeople = (e) => {
    setStartActivePeople(lastMonthDate);
    setEndActivePeople(curr);
    setActBtnActivePeople("month");
  }

  const onTodayClickedActivePeople = (e) => {
    setStartActivePeople(curr);
    setEndActivePeople(curr);
    setActBtnActivePeople("today");
  }

  const onActivePeopleLocationChange = (e) => {
    setLocationActivePeople(e.target.value)
  }

  const onActivePeopleDateChange = (e) => {
    setStartActivePeople(e.startDate);
    setEndActivePeople(e.endDate);
    setActBtnActivePeople("custom");
  }

  useEffect(() => {
    GetActivePeopleData();
  }, [actBtnActivePeople, startActivePeople, endActivePeople, locationActivePeople]);



  const onWeekClickedApplicantRegistration = (e) => {
    setStartApplicantRegistration(lastWeekDate);
    setEndApplicantRegistration(curr);
    setActBtnApplicantRegistration("week");
  }
  const onMonthClickedApplicantRegistration = (e) => {
    setStartApplicantRegistration(lastMonthDate);
    setEndApplicantRegistration(curr);
    setActBtnApplicantRegistration("month");
  }
  const onTodayClickedApplicantRegistration = (e) => {
    setStartApplicantRegistration(curr);
    setEndApplicantRegistration(curr);
    setActBtnApplicantRegistration("today");
  }
  const onApplicantRegistrationDateChange = (e) => {
    setStartApplicantRegistration(e.startDate);
    setEndApplicantRegistration(e.endDate);
    setActBtnAssociateMovements("custom");
  }

  useEffect(() => {
    GetRegistrationData();
  }, [actBtnApplicantRegistration, startApplicantRegistration, endApplicantRegistration]);



  const onWeekClickedClients = (e) => {
    setStartClients(lastWeekDate);
    setEndClients(curr);
    setActBtnClients("week");
  }
  const onMonthClickedClients = (e) => {
    setStartClients(lastMonthDate);
    setEndClients(curr);
    setActBtnClients("month");
  }
  const onTodayClickedClients = (e) => {
    setStartClients(curr);
    setEndClients(curr);
    setActBtnClients("today");
  }
  const onClientsDateChange = (e) => {
    setStartClients(e.startDate);
    setEndClients(e.endDate);
    setActBtnClients("custome");
  }

  useEffect(() => {
    GetClientData();
  }, [actBtnClients, startClients, endClients]);



  const onWeekClickedApplicantDispatches = (e) => {
    setStartApplicantDispatches(lastWeekDate);
    setEndApplicantDispatches(curr);
    setActBtnApplicantDispatches("week");
  }
  const onMonthClickedApplicantDispatches = (e) => {
    setStartApplicantDispatches(lastMonthDate);
    setEndApplicantDispatches(curr);
    setActBtnApplicantDispatches("month");
  }
  const onTodayClickedApplicantDispatches = (e) => {
    setStartApplicantDispatches(curr);
    setEndApplicantDispatches(curr);
    setActBtnApplicantDispatches("today");
  }
  const onApplicantDispatchLocationChange = (e) => {
    setLocationApplicantDispatches(e.target.value)
  }
  const onApplicantDispatchesDateChange = (e) => {
    setStartApplicantDispatches(e.startDate);
    setEndApplicantDispatches(e.endDate);
    setActBtnApplicantDispatches("custom");
  }

  useEffect(() => {
    GetDispatchData()
  }, [actBtnApplicantDispatches, startApplicantDispatches, endApplicantDispatches, locationApplicantDispatches]);



  const onWeekClickedAssociateMovements = (e) => {
    setStartAssociateMovements(lastWeekDate);
    setEndAssociateMovements(curr);
    setActBtnAssociateMovements("week");
  }
  const onMonthClickedAssociateMovements = (e) => {
    setStartAssociateMovements(lastMonthDate);
    setEndAssociateMovements(curr);
    setActBtnAssociateMovements("month");
  }
  const onTodayClickedAssociateMovements = (e) => {
    setStartAssociateMovements(curr);
    setEndAssociateMovements(curr);
    setActBtnAssociateMovements("today");
  }
  const onAssociateMovementsLocationChange = (e) => {
    setLocationAssociateMovements(e.target.value)
  }
  const onAssociateMovementsDateChange = (e) => {
    setStartAssociateMovements(e.startDate);
    setEndAssociateMovements(e.endDate);
    setActBtnAssociateMovements("custom");
  }

  useEffect(() => {
    GetStatusData();
  }, [actBtnAssociateMovements, startAssociateMovements, endAssociateMovements, locationAssociateMovements]);


  const onWeekClickedLeadStatus = (e) => {
    setStartLeadStatus(lastWeekDate);
    setEndLeadStatus(curr);
    setActBtnLeadStatus("week");
  }
  const onMonthClickedLeadStatus = (e) => {
    setStartLeadStatus(lastMonthDate);
    setEndLeadStatus(curr);
    setActBtnLeadStatus("month");
  }
  const onTodayClickedLeadStatus = (e) => {
    setStartLeadStatus(curr);
    setEndLeadStatus(curr);
    setActBtnLeadStatus("today");
  }
  const onLeadStatusLocationChange = (e) => {
    setLocationLeadStatus(e.target.value)
  }
  const onLeadStatusDateChange = (e) => {
    setStartLeadStatus(e.startDate);
    setEndLeadStatus(e.endDate);
    setActBtnLeadStatus("custom");
  }

  useEffect(() => {
    GetLeadStatusData();
  }, [actBtnLeadStatus, startLeadStatus, endLeadStatus]);

  useEffect(() => {
    GetLeadStatusData();
  }, [locationLeadStatus]);


  const onWeekClickedNewJobsByOffice = (e) => {
    setStartNewJobsByOffice(lastWeekDate);
    setEndNewJobsByOffice(curr);
    setActBtnNewJobsByOffice("week");
  }
  const onMonthClickedNewJobsByOffice = (e) => {
    setStartNewJobsByOffice(lastMonthDate);
    setEndNewJobsByOffice(curr);
    setActBtnNewJobsByOffice("month");
  }
  const onTodayClickedNewJobsByOffice = (e) => {
    setStartNewJobsByOffice(curr);
    setEndNewJobsByOffice(curr);
    setActBtnNewJobsByOffice("today");
  }
  const onNewJobsByOfficeDateChange = (e) => {
    setStartNewJobsByOffice(e.startDate);
    setEndNewJobsByOffice(e.endDate);
    setActBtnNewJobsByOffice("custome");
  }
  useEffect(() => {
    GetJobData();
  }, [actBtnNewJobsByOffice, startNewJobsByOffice, endNewJobsByOffice]);



  const onWeekClickedCalledInAndCalledOut = (e) => {
    setStartCalledInAndCalledOut(lastWeekDate);
    setEndCalledInAndCalledOut(curr);
    setActBtnCalledInAndCalledOut("week");
  }
  const onMonthClickedCalledInAndCalledOut = (e) => {
    setStartCalledInAndCalledOut(lastMonthDate);
    setEndCalledInAndCalledOut(curr);
    setActBtnCalledInAndCalledOut("month");
  }
  const onTodayClickedCalledInAndCalledOut = (e) => {
    setStartCalledInAndCalledOut(curr);
    setEndCalledInAndCalledOut(curr);
    setActBtnCalledInAndCalledOut("today");
  }
  const onCalledInCalledOutLocationChange = (e) => {
    setLocationCalledInAndCalledOut(e.target.value);
  }
  const onCalledInAndCalledOuDateChange = (e) => {
    setStartCalledInAndCalledOut(e.startDate);
    setEndCalledInAndCalledOut(e.endDate);
    setActBtnCalledInAndCalledOut("custom");
  }

  useEffect(() => {
    GetCallData();
  }, [actBtnCalledInAndCalledOut, startCalledInAndCalledOut, endCalledInAndCalledOut, locationCalledInAndCalledOut]);



  const onWeekClickedNoShow = (e) => {
    setStartNoShow(lastWeekDate);
    setEndNoShow(curr);
    setActBtnNoShow("week");
  }
  const onMonthClickedNoShow = (e) => {
    setStartNoShow(lastMonthDate);
    setEndNoShow(curr);
    setActBtnNoShow("month");
  }

  const onTodayClickedNoShow = (e) => {
    setStartNoShow(curr);
    setEndNoShow(curr);
    setActBtnNoShow("today");
  }

  const onNoShowDateChange = (e) => {
    setStartNoShow(e.startDate);
    setEndNoShow(e.endDate);
    setActBtnNoShow("custom");
  }

  useEffect(() => {
    GetNoShowData();
  }, [actBtnNoShow, startNoShow, endNoShow]);



  const onWeekClickedApplicantInterview = (e) => {
    setStartApplicantInterview(lastWeekDate);
    setEndApplicantInterview(curr);
    setActBtnApplicantInterview("week");
  }
  const onMonthClickedApplicantInterview = (e) => {
    setStartApplicantInterview(lastMonthDate);
    setEndApplicantInterview(curr);
    setActBtnApplicantInterview("month");
  }
  const onTodayClickedApplicantInterview = (e) => {
    setStartApplicantInterview(curr);
    setEndApplicantInterview(curr);
    setActBtnApplicantInterview("today");
  }
  const onApplicantInterviewLocationChange = (e) => {
    setLocationApplicantInterview(e.target.value)
  }
  const onApplicantInterviewDateChange = (e) => {
    setStartApplicantInterview(e.startDate);
    setEndApplicantInterview(e.endDate);
    setActBtnApplicantInterview("custome");
  }
  useEffect(() => {
    GetInterviewData();
  }, [actBtnApplicantInterview, startApplicantInterview, endApplicantInterview, locationApplicantInterview]);


  const [multipleBarChartData, setMultipleBarChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    if (statusCount && statusCount[0]) {
      const _multipleBarChartData = {
        labels: statusCount && isJSON(statusCount[0].labelsChart) && JSON.parse(statusCount[0].labelsChart),
        datasets: []
      };

      for (const key in statusCount[0]) {
        if (key !== "labelsChart") {
          const label = key;
          const data = statusCount[0][key].replace('[', '').replace(']', '').split(',');
          _multipleBarChartData.datasets.push({
            label: label,
            data: data
          });
        }
      }

      setMultipleBarChartData(_multipleBarChartData)
    }
  }, [statusCount]);

  const leadStatusData = {
    labels: leadStatusCount && JSON.parse(leadStatusCount[0].labelsChart),
    datasets: [
      {
        label: 'New',
        data: leadStatusCount != undefined && leadStatusCount[0].NewCount != undefined ? leadStatusCount[0].NewCount.replace('[', '').replace(']', '').split(',') : []
      },
      {
        label: 'In Progress',
        data: leadStatusCount != undefined && leadStatusCount[0].InProgressCount != undefined ? leadStatusCount[0].InProgressCount.replace('[', '').replace(']', '').split(',') : []
      },
      {
        label: 'Credit Check',
        data: leadStatusCount != undefined && leadStatusCount[0].CreditCheckValue != undefined ? leadStatusCount[0].CreditCheckValue.replace('[', '').replace(']', '').split(',') : []
      },
      {
        label: 'Safety Check',
        data: leadStatusCount != undefined && leadStatusCount[0].SafetyCheckValue != undefined ? leadStatusCount[0].SafetyCheckValue.replace('[', '').replace(']', '').split(',') : []
      },
      {
        label: 'Proposal',
        data: leadStatusCount != undefined && leadStatusCount[0].ProposalValue != undefined ? leadStatusCount[0].ProposalValue.replace('[', '').replace(']', '').split(',') : []
      },
      {
        label: 'SA',
        data: leadStatusCount != undefined && leadStatusCount[0].SAValue != undefined ? leadStatusCount[0].SAValue.replace('[', '').replace(']', '').split(',') : []
      },
      {
        label: 'COI',
        data: leadStatusCount != undefined && leadStatusCount[0].COIValue != undefined ? leadStatusCount[0].COIValue.replace('[', '').replace(']', '').split(',') : []
      },
      {
        label: 'Completed',
        data: leadStatusCount != undefined && leadStatusCount[0].CompletedValue != undefined ? leadStatusCount[0].CompletedValue.replace('[', '').replace(']', '').split(',') : []
      },
      {
        label: 'Rejected',
        data: leadStatusCount != undefined && leadStatusCount[0].RejectedValue != undefined ? leadStatusCount[0].RejectedValue.replace('[', '').replace(']', '').split(',') : []
      },
    ],
  };

  const CalledInAndCalledOutData = {
    labels: callCount && isJSON(callCount[0].labelsChart) && JSON.parse(callCount[0].labelsChart),
    datasets: [
      {
        label: 'Called In',
        data: callCount != undefined && callCount[0].calledInvalues != undefined ? callCount[0].calledInvalues.replace('[', '').replace(']', '').split(',') : []
      },
      {
        label: 'Called Out',
        data: callCount != undefined && callCount[0].calledOutvalues != undefined ? callCount[0].calledOutvalues.replace('[', '').replace(']', '').split(',') : []
      }
    ],
  };

  const CalledInAndCalledOutBlankData = {
    labels: "",
    datasets: [
      {
        label: 'Called In',
        data: [],
      },
      {
        label: 'Called Out',
        data: [],
      }
    ],
  };


  const horizontalBarChartOptions = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      }
    },
  };

  const stackedBarChartOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
  };

  const data = {
    labels: ['Compton', 'Ontario', 'Buena', 'Torrance', 'Bell', 'Bellflower'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 8, 11, 18, 15],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const multipleData = {
    labels: ['Compton', 'Ontario', 'Buena', 'Torrance', 'Bell', 'Bellflower'],
    datasets: [
      {
        label: 'Available',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: '#5870DF',
      },
      {
        label: 'Active',
        data: [2, 9, 13, 15, 5, 8],
        backgroundColor: '#F2B25C',
      },
      {
        label: 'InActive',
        data: [5, 8, 2, 10, 15, 19],
        backgroundColor: '#22C493',
      },
    ]
  };


  const calledData = {
    labels: ['Compton', 'Ontario', 'Buena', 'Torrance', 'Bell', 'Bellflower'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [25, 150, 300, 180, 350, 75],
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        fill: true
      },
      {
        label: 'Dataset 2',
        data: [25, 100, 180, 120, 200, 225],
        backgroundColor: 'rgba(242, 178, 92, 0.4)',
        borderColor: 'rgba(242, 178, 92, 1)',
        fill: true
      }
    ]
  }

  const calledoptions = {

    elements: {
      line: {
        tension: 0.4
      }
    }
  }

  const employeePerformanceData = [
    { user: 'Jurriaan van der Broek', location: "Compton", pay: "10", shows: "10" },
    { user: 'Xian Zhou', location: "Ontario", pay: "20", shows: "20" },
    { user: 'Noell Blue', location: "Buena", pay: "23", shows: "23" },
    { user: 'Njimoluh Ebua', location: "Van Nuys", pay: "24", shows: "24" },
    { user: 'Jeremias del Pozo', location: "Corona", pay: "43", shows: "46" }
    ,]


  useEffect(() => {
    if (interviewListCount) {
      const users = interviewListCount.map(employee => employee.value);
    }
  }, [interviewListCount])

  const getGrowthPercentage = (previous, current) => {
    if (previous === 0 && current === 0) {
      return 0; // Both values are zero, so growth is considered 0
    } else if (previous === 0) {
      return current * 100; // Previous week's data is zero, growth is infinite
    }
    const growthPercentage = ((current - previous) / previous) * 100;
    return growthPercentage.toFixed(2);
  }

  const [pdfGenerateLoading, setPdfGenerateLoading] = useState();
  useEffect(() => {
    // if (emailBodyDashboardStatus === fetchStatus.SUCCEEDED) {
    //   let generatPDF = exportAsImage2("performance report",
    //     exportRefTopInterviewer.current,
    //     exportRefTopDispatchersr.current,
    //     exportRefActivePeople.current,
    //     exportRefApplicantRegistration.current,
    //     exportRefClients.current,
    //     exportRefApplicantDispatche.current,
    //     exportRefAssociateMovements.current,
    //     exportRefNewJobsByOffice.current,
    //     exportRefCalledInAndCalledOut.current,
    //     exportRefNoShow.current,
    //     exportRefApplicantInterview.current,
    //     // exportRefEmployeePerformance.current,
    //     exportRefDashboardCard.current,
    //     emailBodyDashboard
    //   );

    //   console.log('r',r)
    // }

    const _generatePDF = async () => {
      if (emailBodyDashboardStatus === fetchStatus.SUCCEEDED) {
        setPdfGenerateLoading(true);
        try {
          const result = await exportAsImage2("performance report",
            exportRefTopInterviewer.current,
            exportRefTopDispatchersr.current,
            exportRefActivePeople.current,
            exportRefApplicantRegistration.current,
            exportRefClients.current,
            exportRefApplicantDispatche.current,
            exportRefAssociateMovements.current,
            exportRefNewJobsByOffice.current,
            exportRefCalledInAndCalledOut.current,
            exportRefNoShow.current,
            exportRefApplicantInterview.current,

            exportRefLeadStatus.current,
            exportRefLeadPerformance.current,

            exportRefDashboardCard.current,
            emailBodyDashboard
          );

        } catch (error) {

        }
        setPdfGenerateLoading(false);
      }
    };

    _generatePDF();

  }, [emailBodyDashboardStatus]);

  const onClickPdf = async () => {
    setPdfGenerateLoading(true);
    dispatch(getEmailBodyDashboard({
      client: tenantId,
      fromDate: start.format("YYYY-MM-DD"),
      toDate: Moment(end).format('YYYY-MM-DD')
    }));
  }


  return (
    <>
      <div className="p-3">
        <div className="row mt-2" ref={exportRefDashboardCard}>
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-2 mb-3">
            <DashboardCard
              title="Registration"
              number={graphCounts ? graphCounts[0].RegistrationTotal : "-1"}
              // percentage="10"
              percentage={graphCounts ? getGrowthPercentage(graphCounts[0].RegistrationTotalPreviousWeek, graphCounts[0].RegistrationTotal) : 0}
              info="7 Days"
              faIcon="fa-calendar-check"
              iconBackgroundClass="bg-blue"
              isItemsLoading={graphCountRefreshed} />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-2 mb-3">
            <DashboardCard
              title="Dispatched"
              number={graphCounts ? graphCounts[0].DispatchesTotal : "-1"}
              percentage={graphCounts ? getGrowthPercentage(graphCounts[0].DispatchesTotalPreviousWeek, graphCounts[0].DispatchesTotal) : 0}
              info="7 Days"
              faIcon="fa-briefcase"
              iconBackgroundClass="bg-orange"
              isItemsLoading={graphCountRefreshed} />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-2 mb-3">
            <DashboardCard
              title="Active Working"
              number={graphCounts ? graphCounts[0].ActiveTotal : "-1"}
              percentage={graphCounts ? getGrowthPercentage(graphCounts[0].ActiveTotalPreviousWeek, graphCounts[0].ActiveTotal) : 0}
              info="7 Days"
              faIcon="fa-users"
              iconBackgroundClass="bg-warning"
              isItemsLoading={graphCountRefreshed} />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-2 mb-3">
            <DashboardCard
              title="Inbound Calls"
              number={graphCounts ? graphCounts[0].CalledInTotal : "-1"}
              percentage={graphCounts ? getGrowthPercentage(graphCounts[0].CalledInTotalPreviousWeek, graphCounts[0].CalledInTotal) : 0}
              info="7 Days"
              faIcon="fa-phone"
              iconBackgroundClass="bg-success"
              isItemsLoading={graphCountRefreshed} />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-2 mb-3">
            <DashboardCard
              title="Outbound Calls"
              number={graphCounts ? graphCounts[0].CalledOutTotal : "-1"}
              percentage={graphCounts ? getGrowthPercentage(graphCounts[0].CalledOutTotalPreviousWeek, graphCounts[0].CalledOutTotal) : 0}
              info="7 Days"
              faIcon="fa-phone"
              iconBackgroundClass="bg-light-orange"
              isItemsLoading={graphCountRefreshed} />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-2 mb-3">
            <DashboardCard
              title="Client"
              number={graphCounts ? graphCounts[0].ClientsTotal : "-1"}
              percentage={graphCounts ? getGrowthPercentage(graphCounts[0].ClientsTotalPreviousWeek, graphCounts[0].ClientsTotal) : 0}
              info="7 Days"
              faIcon="fa-handshake"
              iconBackgroundClass="bg-purple"
              isItemsLoading={graphCountRefreshed} />
          </div>
        </div>



        <div className="row mt-3" >
          <div className=" col-md-12 col-lg-6 col-xl-6 col-xxl-5 mb-4">
            <div className="dashboard-container">
              <div className="dashboard-header" style={{ backgroundImage: `url(${deskPeopleImg})` }}>
                <h3 className="dashboard-header-title">Total Interviews</h3>
                {/* <span className="dashboard-header-sub-title">{interviewListCount && interviewListCount.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0)}</span>
                  <span className={`dashboard-card-sub-number ${10 > 0 ? 'dashboard-card-sub-number-success' : 'dashboard-card-sub-number-danger'} me-3`}>
                    <i className={`fa ${10 > 0 ? "fa-arrow-trend-up" : "fa-arrow-trend-down"}`}></i> 24.57%
                  </span> */}
                {graphCounts ?
                  <>
                    <span className="dashboard-header-sub-title">{graphCounts ? graphCounts[0].InterviewsTotalCurrentMonth : 0}</span>
                    <span className={`dashboard-card-sub-number ${getGrowthPercentage(graphCounts[0].InterviewsTotalPreviousMonth, graphCounts[0].InterviewsTotalCurrentMonth) >= 0 ? 'dashboard-card-sub-number-success' : 'dashboard-card-sub-number-danger'} me-3`}>
                      <i className={`fa ${getGrowthPercentage(graphCounts[0].InterviewsTotalPreviousMonth, graphCounts[0].InterviewsTotalCurrentMonth) >= 0 ? "fa-arrow-trend-up" : "fa-arrow-trend-down"}`}></i> {graphCounts ? Math.abs(getGrowthPercentage(graphCounts[0].InterviewsTotalPreviousMonth, graphCounts[0].InterviewsTotalCurrentMonth)) : 0}%
                    </span>
                  </>
                  : <>
                    <span className="dashboard-header-sub-title">0</span>
                    <span className={`dashboard-card-sub-number dashboard-card-sub-number-success me-3`}>
                      <i className={`fa fa-arrow-trend-up`}></i> 0%
                    </span>
                  </>}
                <span>vs. pervious month</span>
              </div>
              <div className="dashboard-body">
                <div ref={exportRefTopInterviewer}>
                  <HirebaseHorizontalBarChart
                    actBtn={actBtnTopInterviewers}
                    showLocation='true'
                    startDate={Moment(startTopInterviewers).format('YYYY-MM-DD')}
                    endDate={Moment(endTopInterviewers).format('YYYY-MM-DD')}
                    locationOptionsValue={locationOptionsValue}

                    onLocationChange={(e) => onTopInterviewLocationChange(e)}
                    onWeekClicked={(e) => onWeekClickedTopInterviewers(e)}
                    onMonthClicked={(e) => onMonthClickedTopInterviewers(e)}
                    onTodayClicked={(e) => onTodayClickedTopInterviewers(e)}
                    onFilter={(e) => onTopInterviewersDateChange(e)}

                    title="Top Interviewer"
                    legendChart="Interview"
                    labelsChart={interviewListCount && interviewListCount.map(d => d.name)}
                    valuesChart={interviewListCount && interviewListCount.map(d => d.value)}

                    backgroundColor={['rgba(255, 99, 132, 0.8)', 'rgba(54, 162, 235, 0.8)', 'rgba(255, 206, 86, 0.8)', 'rgba(75, 192, 192, 0.8)', 'rgba(153, 102, 255, 0.8)']}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" col-md-12 col-lg-6 col-xl-6 col-xxl-4 mb-4">
            <div className="dashboard-container">
              <div className="dashboard-header" style={{ backgroundImage: `url(${deskPeopleImg})` }}>
                <h3 className="dashboard-header-title">Total Dispatched</h3>
                {/* <span className="dashboard-header-sub-title">{dispatchListCount && dispatchListCount.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0)}</span> */}
                {/* <span className={`dashboard-card-sub-number ${10 > 0 ? 'dashboard-card-sub-number-success' : 'dashboard-card-sub-number-danger'} me-3`}>
                    <i className={`fa ${10 > 0 ? "fa-arrow-trend-up" : "fa-arrow-trend-down"}`}></i> 24.57%
                  </span> */}
                {graphCounts ?
                  <>
                    <span className="dashboard-header-sub-title">{graphCounts ? graphCounts[0].DispatchesTotalCurrentMonth : 0}</span>
                    <span className={`dashboard-card-sub-number ${getGrowthPercentage(graphCounts[0].DispatchesTotalPreviousMonth, graphCounts[0].DispatchesTotalCurrentMonth) >= 0 ? 'dashboard-card-sub-number-success' : 'dashboard-card-sub-number-danger'} me-3`}>
                      <i className={`fa ${getGrowthPercentage(graphCounts[0].DispatchesTotalPreviousMonth, graphCounts[0].DispatchesTotalCurrentMonth) >= 0 ? "fa-arrow-trend-up" : "fa-arrow-trend-down"}`}></i> {graphCounts ? Math.abs(getGrowthPercentage(graphCounts[0].DispatchesTotalPreviousMonth, graphCounts[0].DispatchesTotalCurrentMonth)) : 0}%
                    </span>
                  </>
                  : <>
                    <span className="dashboard-header-sub-title">0</span>
                    <span className={`dashboard-card-sub-number dashboard-card-sub-number-success me-3`}>
                      <i className={`fa fa-arrow-trend-up`}></i> 0%
                    </span>
                  </>}
                <span>vs. pervious month</span>
              </div>
              <div className="dashboard-body">
                {!dispatchListCount ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>}
                <div ref={exportRefTopDispatchersr}>
                  <HirebaseHorizontalBarChart
                    actBtn={actBtnTopDispatchers}
                    showLocation='true'
                    startDate={Moment(startTopDispatchers).format('YYYY-MM-DD')}
                    endDate={Moment(endTopDispatchers).format('YYYY-MM-DD')}
                    locationOptionsValue={locationOptionsValue}
                    onLocationChange={(e) => onTopDispatchersLocationChange(e)}

                    onWeekClicked={(e) => onWeekClickedTopDispatchers(e)}
                    onMonthClicked={(e) => onMonthClickedTopDispatchers(e)}
                    onTodayClicked={(e) => onTodayClickedTopDispatchers(e)}

                    onFilter={(e) => onTopDispatchersDateChange(e)}

                    title="Top Dispatchers"
                    legendChart="Dispatcher"
                    labelsChart={dispatchListCount && dispatchListCount.map(d => d.name)}
                    valuesChart={dispatchListCount && dispatchListCount.map(d => d.value)}

                    backgroundColor={['rgba(255, 99, 132, 0.8)', 'rgba(54, 162, 235, 0.8)', 'rgba(255, 206, 86, 0.8)', 'rgba(75, 192, 192, 0.8)', 'rgba(153, 102, 255, 0.8)']}
                    isItemsLoading={dispatchListStatus}
                  />
                </div>

              </div>
            </div>
          </div>

          <div className=" col-md-12 col-lg-4 col-xl-4 col-xxl-3 mb-4">
            <div className="dashboard-container">
              <div className="dashboard-body">
                {!activePeopleCount ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>}
                <div ref={exportRefActivePeople}>
                  <HirebaseDoughnutChart
                    actBtn={actBtnActivePeople}
                    showLocation='true'
                    startDate={Moment(startActivePeople).format('YYYY-MM-DD')}
                    endDate={Moment(endActivePeople).format('YYYY-MM-DD')}
                    locationOptionsValue={locationOptionsValue}
                    onLocationChange={(e) => onActivePeopleLocationChange(e)}

                    onWeekClicked={(e) => onWeekClickedActivePeople(e)}
                    onMonthClicked={(e) => onMonthClickedActivePeople(e)}
                    onTodayClicked={(e) => onTodayClickedActivePeople(e)}

                    onFilter={(e) => onActivePeopleDateChange(e)}

                    title="Active People"
                    legendChart="Active People"
                    labelsChart={activePeopleCount && JSON.parse(activePeopleCount[0].labelsChart)}
                    valuesChart={activePeopleCount && activePeopleCount[0].valuesChart.replace('[', '').replace(']', '').split(',')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" col-md-12 col-lg-8 col-xl-8 col-xxl-6 mb-4">
            <div className="dashboard-container">
              <div className="dashboard-body">
                {!registrationCount ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>}
                <div ref={exportRefApplicantRegistration}>
                  <HirebaseVerticalBarChart
                    actBtn={actBtnApplicantRegistration}
                    showLocation='false'
                    startDate={Moment(startApplicantRegistration).format('YYYY-MM-DD')}
                    endDate={Moment(endApplicantRegistration).format('YYYY-MM-DD')}
                    locationOptionsValue={locationOptionsValue}
                    onLocationChange={(e) => setLocationApplicantRegistration(e.target.value)}

                    onWeekClicked={(e) => onWeekClickedApplicantRegistration(e)}
                    onMonthClicked={(e) => onMonthClickedApplicantRegistration(e)}
                    onTodayClicked={(e) => onTodayClickedApplicantRegistration(e)}

                    onFilter={(e) => onApplicantRegistrationDateChange(e)}

                    title="Applicant Registration"
                    legendChart="Registration"
                    labelsChart={registrationCount && JSON.parse(registrationCount[0].labelsChart)}
                    valuesChart={registrationCount && registrationCount[0].valuesChart.replace('[', '').replace(']', '').split(',')}
                    isItemsLoading={registrationCountStatus}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-4">
            <div className="dashboard-container">
              <div className="dashboard-body">
                {!clientCount ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>}
                <div ref={exportRefClients}>
                  <HirebaseLineChart
                    actBtn={actBtnClients}
                    showLocation='false'
                    startDate={Moment(startClients).format('YYYY-MM-DD')}
                    endDate={Moment(endClients).format('YYYY-MM-DD')}
                    locationOptionsValue={locationOptionsValue}
                    onLocationChange={(e) => setLocationClients(e.target.value)}

                    onWeekClicked={(e) => onWeekClickedClients(e)}
                    onMonthClicked={(e) => onMonthClickedClients(e)}
                    onTodayClicked={(e) => onTodayClickedClients(e)}

                    onFilter={(e) => onClientsDateChange(e)}

                    title="Clients"
                    legendChart="Clients"
                    labelsChart={clientCount && JSON.parse(clientCount[0].labelsChart)}
                    valuesChart={clientCount && clientCount[0].valuesChart.replace('[', '').replace(']', '').split(',')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-4">
            <div className="dashboard-container">
              <div className="dashboard-body">
                {!dispatchCount ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>}
                <div ref={exportRefApplicantDispatche}>
                  <HirebaseLineChart
                    showLocation='true'
                    actBtn={actBtnApplicantDispatches}
                    startDate={Moment(startApplicantDispatches).format('YYYY-MM-DD')}
                    endDate={Moment(endApplicantDispatches).format('YYYY-MM-DD')}
                    locationOptionsValue={locationOptionsValue}
                    onLocationChange={(e) => onApplicantDispatchLocationChange(e)}

                    onWeekClicked={(e) => onWeekClickedApplicantDispatches(e)}
                    onMonthClicked={(e) => onMonthClickedApplicantDispatches(e)}
                    onTodayClicked={(e) => onTodayClickedApplicantDispatches(e)}

                    onFilter={(e) => onApplicantDispatchesDateChange(e)}

                    title="Branch Dispatches"
                    legendChart="Dispatches"
                    labelsChart={dispatchCount && isJSON(dispatchCount[0].labelsChart) && JSON.parse(dispatchCount[0].labelsChart)}
                    valuesChart={dispatchCount && dispatchCount[0].valuesChart.replace('[', '').replace(']', '').split(',')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-4">
            <div className="dashboard-container">
              <div className="dashboard-body">
                {!statusCount ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>}
                <div ref={exportRefAssociateMovements}>
                  <HirebaseVerticalStackedBarChart
                    showLocation='true'
                    actBtn={actBtnAssociateMovements}
                    startDate={Moment(startAssociateMovements).format('YYYY-MM-DD')}
                    endDate={Moment(endAssociateMovements).format('YYYY-MM-DD')}
                    locationOptionsValue={locationOptionsValue}
                    onLocationChange={(e) => onAssociateMovementsLocationChange(e)}

                    onWeekClicked={(e) => onWeekClickedAssociateMovements(e)}
                    onMonthClicked={(e) => onMonthClickedAssociateMovements(e)}
                    onTodayClicked={(e) => onTodayClickedAssociateMovements(e)}

                    onFilter={(e) => onAssociateMovementsDateChange(e)}

                    title="Associate Movements"
                    labelsChart={statusCount && isJSON(statusCount[0].labelsChart) && JSON.parse(statusCount[0].labelsChart)}
                    multipleBarChartData={multipleBarChartData}
                    backgroundColor={['rgba(255, 99, 132, 0.5)', 'rgba(255, 159, 64, 0.5)', 'rgba(255, 205, 86, 0.5)', 'rgba(75, 192, 192, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(153, 102, 255, 0.5)', 'rgba(201, 203, 207, 0.5)',
                      'rgba(0, 255, 255, 0.5)', 'rgba(255, 0, 255, 0.5)', 'rgba(253, 173, 253, 0.5)', 'rgba(213, 215, 255, 0.5)', 'rgba(249, 249, 114, 0.5)', 'rgba(243 , 141, 141, 0.5)', 'rgba(255, 0, 0, 0.5)', 'rgba(0, 255, 0, 0.5)', 'rgba(0, 0, 255, 0.5)', 'rgba(128, 0, 0, 0.5)', 'rgba(0, 128, 128, 0.5)', 'rgba(0, 0, 0, 0.5)', 'rgba(255, 255, 255, 0.5)', 'rgba(165, 42, 42, 0.5)', 'rgba(255, 165, 0, 0.5)', 'rgba(218, 165, 32, 0.5)', 'rgba(0, 255, 127, 0.5)', 'rgba(255, 0, 255, 0.5)', 'rgba(0, 128, 0, 0.5)'
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" col-md-12 col-lg-6 col-xl-6 col-xxl-7 mb-4">
            <div className="dashboard-container">
              <div className="dashboard-body">
                {!jobCount ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>}
                <div ref={exportRefNewJobsByOffice}>
                  <HirebaseHorizontalBarChart
                    actBtn={actBtnNewJobsByOffice}
                    showLocation='false'
                    startDate={Moment(startNewJobsByOffice).format('YYYY-MM-DD')}
                    endDate={Moment(endNewJobsByOffice).format('YYYY-MM-DD')}
                    locationOptionsValue={locationOptionsValue}
                    onLocationChange={(e) => setLocationNewJobsByOffice(e.target.value)}

                    onWeekClicked={(e) => onWeekClickedNewJobsByOffice(e)}
                    onMonthClicked={(e) => onMonthClickedNewJobsByOffice(e)}
                    onTodayClicked={(e) => onTodayClickedNewJobsByOffice(e)}

                    onFilter={(e) => onNewJobsByOfficeDateChange(e)}

                    title="Jobs Created By Office"
                    legendChart="Job"
                    labelsChart={jobCount && JSON.parse(jobCount[0].labelsChart)}
                    valuesChart={jobCount && jobCount[0].valuesChart.replace('[', '').replace(']', '').split(',')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" col-md-12 col-lg-7 col-xl-7 col-xxl-5 mb-4">
            <div className="dashboard-container">
              <div className="dashboard-body">
                {!callCount ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>}
                <div ref={exportRefCalledInAndCalledOut}>
                  <HirebaseMultipleLineChart
                    showLocation='true'
                    actBtn={actBtnCalledInAndCalledOut}
                    startDate={Moment(startCalledInAndCalledOut).format('YYYY-MM-DD')}
                    endDate={Moment(endCalledInAndCalledOut).format('YYYY-MM-DD')}
                    locationOptionsValue={locationOptionsValue}
                    onLocationChange={(e) => onCalledInCalledOutLocationChange(e)}

                    onWeekClicked={(e) => onWeekClickedCalledInAndCalledOut(e)}
                    onMonthClicked={(e) => onMonthClickedCalledInAndCalledOut(e)}
                    onTodayClicked={(e) => onTodayClickedCalledInAndCalledOut(e)}

                    onFilter={(e) => onCalledInAndCalledOuDateChange(e)}

                    title="Inbound Calls & Outbound Calls"
                    labelsChart={callCount && isJSON(callCount[0].labelsChart) && JSON.parse(callCount[0].labelsChart)}
                    multipleBarChartData={callCount != undefined ? CalledInAndCalledOutData : CalledInAndCalledOutBlankData}
                    backgroundColor={['rgba(210, 214, 222, 0.4)', 'rgba(75, 148, 191, 0.4)']}
                    borderColor={['rgba(210, 214, 222, 1)', 'rgba(75, 148, 191, 1)']}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" col-md-12 col-lg-5 col-xl-5 col-xxl-4 mb-4">
            <div className="dashboard-container">
              <div className="dashboard-body">
                {!noShowCount ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>}
                <div ref={exportRefNoShow}>
                  <HirebaseDoughnutChart
                    actBtn={actBtnNoShow}
                    showLocation='false'
                    startDate={Moment(startNoShow).format('YYYY-MM-DD')}
                    endDate={Moment(endNoShow).format('YYYY-MM-DD')}
                    locationOptionsValue={locationOptionsValue}
                    onLocationChange={(e) => setLocationClients(e.target.value)}

                    onWeekClicked={(e) => onWeekClickedNoShow(e)}
                    onMonthClicked={(e) => onMonthClickedNoShow(e)}
                    onTodayClicked={(e) => onTodayClickedNoShow(e)}

                    onFilter={(e) => onNoShowDateChange(e)}

                    title="No Shows"
                    legendChart="No Shows"
                    labelsChart={noShowCount && JSON.parse(noShowCount[0].labelsChart)}
                    valuesChart={noShowCount && noShowCount[0].valuesChart.replace('[', '').replace(']', '').split(',')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" col-md-12 col-lg-6 col-xl-6 col-xxl-8 mb-4">
            <div className="dashboard-container">
              <div className="dashboard-body">
                {!interviewCount ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>}
                <div ref={exportRefApplicantInterview}>
                  <HirebaseVerticalBarChart
                    actBtn={actBtnApplicantInterview}
                    showLocation='true'
                    startDate={Moment(startApplicantInterview).format('YYYY-MM-DD')}
                    endDate={Moment(endApplicantInterview).format('YYYY-MM-DD')}
                    locationOptionsValue={locationOptionsValue}
                    onLocationChange={(e) => onApplicantInterviewLocationChange(e)}

                    onWeekClicked={(e) => onWeekClickedApplicantInterview(e)}
                    onMonthClicked={(e) => onMonthClickedApplicantInterview(e)}
                    onTodayClicked={(e) => onTodayClickedApplicantInterview(e)}

                    onFilter={(e) => onApplicantInterviewDateChange(e)}

                    title="Branch Interviews"
                    legendChart="Interview"
                    labelsChart={interviewCount && isJSON(interviewCount[0].labelsChart) && JSON.parse(interviewCount[0].labelsChart)}
                    valuesChart={interviewCount && interviewCount[0].valuesChart.replace('[', '').replace(']', '').split(',')}
                  />
                </div>
              </div>
            </div>
          </div>

          {isAdmin || isSalesRep || isSalesManager ?
            <div className=" col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  {!leadStatusCount ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>}
                  <div ref={exportRefLeadStatus}>
                    <HirebaseMultipleVerticalBarChart
                      showLocation='true'
                      actBtn={actBtnLeadStatus}
                      startDate={Moment(startLeadStatus).format('YYYY-MM-DD')}
                      endDate={Moment(endLeadStatus).format('YYYY-MM-DD')}
                      locationOptionsValue={locationOptionsValue}
                      onLocationChange={(e) => onLeadStatusLocationChange(e)}

                      onWeekClicked={(e) => onWeekClickedLeadStatus(e)}
                      onMonthClicked={(e) => onMonthClickedLeadStatus(e)}
                      onTodayClicked={(e) => onTodayClickedLeadStatus(e)}

                      onFilter={(e) => onLeadStatusDateChange(e)}

                      title="Lead Status"
                      labelsChart={leadStatusCount && isJSON(leadStatusCount[0].labelsChart) && JSON.parse(leadStatusCount[0].labelsChart)}
                      multipleBarChartData={leadStatusData}
                      backgroundColor={['rgba(255, 99, 132, 0.5)', 'rgba(255, 159, 64, 0.5)', 'rgba(255, 205, 86, 0.5)', 'rgba(75, 192, 192, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(153, 102, 255, 0.5)', 'rgba(201, 203, 207, 0.5)', 'rgba(226, 126, 231, 0.5)', 'rgba(132, 188, 225, 0.5)']}
                    />
                  </div>
                </div>
              </div>
            </div>
            : <></>
          }

          {isAdmin || isSalesManager ?
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-4">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  {!leadPerformanceListCount ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={20} /> : <></>}
                  <div ref={exportRefLeadPerformance}>
                    <TopList
                      actBtn={actBtnLeadPerformance}
                      showLocation='true'
                      startDate={Moment(startLeadPerformance).format('YYYY-MM-DD')}
                      endDate={Moment(endLeadPerformance).format('YYYY-MM-DD')}
                      locationOptionsValue={locationOptionsValue}
                      onLocationChange={(e) => onLeadPerformanceLocationChange(e)}

                      onWeekClicked={(e) => onWeekClickedLeadPerformance(e)}
                      onMonthClicked={(e) => onMonthClickedLeadPerformance(e)}
                      onTodayClicked={(e) => onTodayClickedLeadPerformance(e)}

                      onFilter={(e) => onLeadPerformanceDateChange(e)}

                      title="Top Lead Performance"
                      pastCount={-20}
                      // topListData={topListData}
                      topListData={leadPerformanceListCount != undefined ? leadPerformanceListCount : []}
                    // onClickShowAll={(e) => onClickShowAllLeadPerformance(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            : <></>
          }

          {isAdmin &&
            <div className="col-md-12">
              <div className="float-button">
                {pdfGenerateLoading ?
                  <EllipsisLoader />
                  :
                  <button className="c-btn dark-btn rounded-pill pdf-export-btn" type="button" onClick={(e) => onClickPdf(e)}><i class="fa fa-file-contract me-3"></i>Export</button>
                }
              </div>
            </div>
          }

        </div>

        {/* 
          <div className="row mt-3" >
            <div className="col-md-5 mt-3 mt-md-0">
              <div className="dashboard-container">
                <div className="dashboard-header" style={{ backgroundImage: `url(${deskPeopleImg})` }}>
                  <h3 className="dashboard-header-title">Total Interviews</h3>
                  <span className="dashboard-header-sub-title">689</span>
                  <span className={`dashboard-card-sub-number ${10 > 0 ? 'dashboard-card-sub-number-success' : 'dashboard-card-sub-number-danger'} me-3`}>
                    <i className={`fa ${10 > 0 ? "fa-arrow-trend-up" : "fa-arrow-trend-down"}`}></i> 24.57%
                  </span>
                  <span>vs. pervious month</span>
                </div>
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">Top Interviewer</span>
                    <ChartFilter
                      showLocation="true"
                    />
                  </div>
                  <Bar options={horizontalBarChartOptions} data={data} />
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3 mt-md-0">
              <div className="dashboard-container">
                <div className="dashboard-header" style={{ backgroundImage: `url(${deskPeopleImg})` }}>
                  <h3 className="dashboard-header-title">Total Dispatched</h3>
                  <span className="dashboard-header-sub-title">543</span>
                  <span className={`dashboard-card-sub-number ${10 > 0 ? 'dashboard-card-sub-number-success' : 'dashboard-card-sub-number-danger'} me-3`}>
                    <i className={`fa ${10 > 0 ? "fa-arrow-trend-up" : "fa-arrow-trend-down"}`}></i> 24.57%
                  </span>
                  <span>vs. pervious month</span>
                </div>
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">Top Dispatchers</span>
                    <ChartFilter
                      showLocation="true" />
                  </div>
                  <Bar options={horizontalBarChartOptions} data={data} />
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3 mt-md-0">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">Your Staff</span>
                    <ChartFilter
                      showLocation="true" />
                  </div>
                  <Doughnut data={data} />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-5 mt-3 mt-md-0">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">Applicant Registration</span>
                    <ChartFilter
                      showLocation="true" />
                  </div>
                  <Bar options={options} data={data} />
                </div>
              </div>
            </div>
            <div className="col-md-7 mt-3 mt-md-0">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">Clients</span>
                    <ChartFilter
                      showLocation="true" />
                  </div>
                  <Line options={options} data={data} style={{ position: 'relative', height: '20vh', width: '80vw' }} />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-8">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">Applicant Dispatches</span>
                    <ChartFilter
                      showLocation="true" />
                  </div>
                  <Line options={options} data={data} />
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3 mt-md-0">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">Associate Movements</span>
                    <ChartFilter
                      showLocation="true" />
                  </div>
                  <Bar options={stackedBarChartOptions} data={multipleData} />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-3">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">No. of No Show (Jobs)</span>
                    <ChartFilter
                      showLocation="true" />
                  </div>
                  <Doughnut options={options} data={multipleData} />
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3 mt-md-0">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">Jobs Created by Office</span>
                    <ChartFilter
                      showLocation="true" />
                  </div>
                  <Bar options={horizontalBarChartOptions} data={data} />
                </div>
              </div>
            </div>
            <div className="col-md-5 mt-3 mt-md-0">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">Called In & Called Out</span>
                    <ChartFilter
                      showLocation="true" />
                  </div>
                  <Line options={calledoptions} data={calledData} />
                </div>
              </div>
            </div>
          </div>
          
          <div className="row mt-4">
            <div className="col-md-3 mt-3 mt-md-0">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">No Shows</span>
                    <ChartFilter
                      showLocation="true" />
                  </div>
                  <Doughnut data={data} />
                </div>
              </div>
            </div>
            <div className="col-md-9 mt-3 mt-md-0">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">Employee Performance</span>
                    <ChartFilter
                      showLocation="true" />
                  </div>
                  <table className="table dashboard-table">
                    <thead>
                      <tr>
                        <th scope="col">User</th>
                        <th scope="col">Location</th>
                        <th scope="col">2Hours Pay</th>
                        <th scope="col">No Shows</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeePerformanceData.map(d =>
                        <tr>
                          <td>{d.user}</td>
                          <td>{d.location}</td>
                          <td>{d.pay}</td>
                          <td>{d.shows}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-5">
              <div className="dashboard-container">
                <div className="dashboard-body">
                  <div className="dashboard-section">
                    <span className="dashboard-title">Applicant Interview</span>
                    <ChartFilter
                      showLocation="true"
                    />
                  </div>
                  <Bar options={horizontalBarChartOptions} data={data} />
                </div>
              </div>
            </div>
          </div> */}
      </div>
    </>
  )
}

export default Dashboard;