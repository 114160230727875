import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchStatus, tenantId } from "../../../api/client";
import { updateLayout } from "../../../component/layouts/reducerSlices/layoutSlice";
import { accountService } from "../../accounts/services/accountService";
import { getDispatchList, selectDispatchList, selectDispatchListState } from "../../inbox/reducerSlices/messageSlice";
import noPhoto from '../../../assets/images/clipart/nophoto.png';
import applicationImg from './../../../assets/images/clipart/application.svg';
import msgImg from './../../../assets/images/clipart/msg.svg';
import { toLocalDate, toLocalDateOnly } from "../../../common/common";

import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject, Year, ExcelExport, Resize, Print, ViewsDirective, ViewDirective } from '@syncfusion/ej2-react-schedule';
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { extend } from '@syncfusion/ej2-base';
import {
    getAllShedules,
    updateIsFiltered,
    selectStatus,
    selectAllSchedules,
    createAppointment,
    selectRemovalAppointmentStatus,
    removeScheduleAppointment,
} from "../../crm/reducerSlices/scheduleSlice";
import {
    selectDefaultFilter,
    selectLocationFilter,
    updateLocationFilter,
    selectRefreshData,
    selectWhereClause,
    selectWhereClauseChanged,
    updateScheduleFilter,
    updateScheduleRefreshData
} from "../../../component/Controls/filters/reducerSlices/scheduleFilterSlice";
import { selectLocationOptions } from "../../locations/reducerSlices/locationSlice";
import { updateRecentClickedApplicant, updateSingleData } from "../../applicant/reducerSlices/applicantSlice";
import { showChatFlyout } from "../../../component/Flyout/reducerSlices/chatFlyoutSlice";


function Feed() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [pageLoaded, setPageLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = accountService.getAuthenticatedUser();

    const dispatchList = useSelector(selectDispatchList);
    const dispatchListStatus = useSelector(selectDispatchListState);

    const [dispatchListDetailTrs, setDispatchListDetailTrs] = useState([]);

    useEffect(() => {
        if (!pageLoaded) {
            dispatch(updateLayout({
                page: 11,
                title: "Feed"
            }));
            setPageLoaded(true);

            _loaddispatchList(user.id);
        }
    });

    const removalAppointmentStatus = useSelector(selectRemovalAppointmentStatus);

    useEffect(() => {
        if (removalAppointmentStatus === fetchStatus.SUCCEEDED) {
            _loadSchedules(whereClause);
        }
        if (removalAppointmentStatus === fetchStatus.FAILED) {
            _loadSchedules(whereClause);
        }
    }, [removalAppointmentStatus]);
    const _loaddispatchList = async (userid) => {
        setLoading(true);
        dispatch(getDispatchList({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "GETDISPATCH",
            payload: {
                id: userid,
                data: JSON.stringify({
                    userId: userid,
                })
            }
        }));
    }

    useEffect(() => {
        if (dispatchListStatus === fetchStatus.SUCCEEDED) {
            setLoading(false);
            let _dispatchListDetailTrs;
            setDispatchListDetailTrs([]);
            _dispatchListDetailTrs = dispatchList.map((detail, index) => (
                <div className="card people-card people-card-shadowshadow mb-4">
                    <div key={detail.id} onClick={(e) => onClickApplicant(e, detail.aid)}>
                        <div className="status status-dispatched">Dispatched</div>
                        <div className="people-card-body p-2 cursor-pointer">
                            <div className="d-flex">
                                <div className="pe-1">
                                    <div className="people-card-photo">
                                        <div className="people-card-photo-box">
                                            <img className="people-card-profile-box-img" src={detail && detail.Photo && detail.Photo.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "") || noPhoto} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="ps-2 w-100">
                                    <div className="title w-100">{detail ? detail.Name : ""}</div>
                                    <div className="row">
                                        {/* <div className="detail col-12">{detail ? detail.Title : ""}</div>
                                    <div className="detail col-6">Job Type: {detail ? detail.JobType : ""}</div> */}
                                        <div className="col-8">
                                            <div className="detail">{detail ? detail.Title : ""}</div>
                                            <div className="detail col-6">Job Type: {detail ? detail.JobType : ""}</div>
                                        </div>
                                        <div className="detail col-4">
                                            <div className="float-end me-2">
                                                <span className="people-detail-card-badge people-detail-card-badge-pink">$ {detail ? detail.PayRates : ""}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="people-card-footer">
                        <div className="item">
                            <span className="detail">
                                <img src={applicationImg} /> Start Date: {toLocalDateOnly(detail.StartDate)}
                            </span>
                            <span className="detail ms-2">
                                <img src={applicationImg} /> Dispatch Date: {toLocalDateOnly(detail.DispatchDate)}
                            </span>
                        </div>
                        <div className="item">
                            <span className="detail cursor-pointer " title="Read Message" onClick={(e) => onChatClicked(e, detail.aid)}>
                                <img src={msgImg} />Message
                            </span>
                        </div>
                    </div>
                </div>
            ));
            setDispatchListDetailTrs(_dispatchListDetailTrs);


        }
    }, [dispatchListStatus]);



    let scheduleObj;

    const refreshData = useSelector(selectRefreshData);


    const schedules = useSelector(selectAllSchedules);
    const schedulesRequestStatus = useSelector(selectStatus);

    const whereClause = useSelector(selectWhereClause);
    const whereClauseChanged = useSelector(selectWhereClauseChanged);

    const locationOptions = useSelector(selectLocationOptions);

    const [isFiltered, setIsFiltered] = useState(false);

    useEffect(() => {
        if (schedulesRequestStatus === fetchStatus.IDLE) {
            let loc = user ? user.locationId : "";
            let where = JSON.parse(JSON.stringify(whereClause));
            where.location.value.push(loc);
            dispatch(updateLocationFilter({
                locations: locationOptions,
                value: loc
            }));
            _loadSchedules(whereClause);
        }
    }, [schedulesRequestStatus, dispatch]);

    useEffect(() => {
        if (refreshData) {
            dispatch(updateIsFiltered());
            _loadSchedules(whereClause);
            dispatch(updateScheduleRefreshData(false));
        }
    }, [whereClauseChanged, whereClause, refreshData]);

    const _loadSchedules = async (whereClause) => {
        setIsFiltered(false);
        await dispatch(getAllShedules({
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETAPPOINTMENTS",
                payload: {
                    id: "",
                    data: JSON.stringify({
                        where: whereClause
                    })
                }
            }
        }));
    }

    const data = extend([], schedules, null, true);

    const onActionComplete = (args) => {
        console.log('args', args)
        if (args.requestType === 'eventChanged') {
            // args.changedRecords
            console.log('args.changedRecords', args.changedRecords)
            const appointmentId = args.changedRecords[0]["appointmentId"];
            const appointmentDetails = {
                // scheduleId: schedule ? schedule.id : "",
                // scheduleName: schedule && schedule.ScheduleName ? schedule.ScheduleName.Data : "",
                calendarData: args.changedRecords[0]
            };

            delete appointmentDetails.calendarData.appointmentId;
            delete appointmentDetails.calendarData._leadName;
            delete appointmentDetails.calendarData._assignData;
            dispatch(createAppointment({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "SAVEAPPOINTMENT",
                payload: {
                    id: appointmentId,
                    data: JSON.stringify({
                        appointment: JSON.stringify(appointmentDetails)
                    })
                }
            }));
        }
        if (args.requestType === 'eventRemoved') {
            const appointmentId = args.deletedRecords[0]["appointmentId"];
            dispatch(removeScheduleAppointment({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "REMOVEAPPOINTMENT",
                payload: {
                    id: appointmentId,
                    data: ''
                }
            }));
        }
    }

    const editorTemplate = (props) => {

        const handleStartTimeChange = (args) => {
            const startTime = args.value;
            const endTimePicker = document.getElementById("EndTime").ej2_instances[0];
            const endTime = endTimePicker.value;

            if (startTime && endTime && startTime.getTime() >= endTime.getTime()) {
                endTimePicker.value = new Date(startTime.getTime() + 30 * 60 * 1000); // Add 30 minutes
            }
        }

        const handleEndTimeChange = (args) => {
            const endTime = args.value;
            const startTimePicker = document.getElementById("StartTime").ej2_instances[0];
            const startTime = startTimePicker.value;

            if (startTime && endTime && endTime.getTime() <= startTime.getTime()) {
                startTimePicker.value = new Date(endTime.getTime());
            }
        }

        const [title, setTitle] = useState(props.Subject || '');
        const [description, setDescription] = useState(props.Description || '');
        const [meetingWith, setMeetingWith] = useState(props.MeetingWith || '');

        return props !== undefined ? (
            <table className="custom-event-editor" style={{ width: '100%', cellpadding: '5' }}>
                <tbody>
                    <tr>
                        <td className="e-textlabel mb-2">Title</td>
                        <td colSpan={4}>
                            <input
                                id="Summary"
                                className="e-field e-input"
                                type="text"
                                name="Subject"
                                style={{ width: '100%' }}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="e-textlabel mb-2">Status</td>
                        <td colSpan={4}>
                            <DropDownListComponent
                                id="Status"
                                placeholder="-- Choose Status --"
                                data-name="Status"
                                className="e-field"
                                style={{ width: "100%" }}
                                dataSource={["Open", "Done", "Rejected"]}
                                value={props.Status || null}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="e-textlabel">From</td>
                        <td colSpan={4}>
                            <DateTimePickerComponent
                                format="dd/MM/yy hh:mm a"
                                id="StartTime"
                                data-name="StartTime"
                                value={new Date(props.startTime || props.StartTime)}
                                className="e-field"
                                change={handleStartTimeChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="e-textlabel">To</td>
                        <td colSpan={4}>
                            <DateTimePickerComponent
                                format="dd/MM/yy hh:mm a"
                                id="EndTime"
                                data-name="EndTime"
                                value={new Date(props.endTime || props.EndTime)}
                                className="e-field"
                                change={handleEndTimeChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="e-textlabel">Description</td>
                        <td colSpan={4}>
                            <textarea
                                id="Description"
                                className="e-field e-input"
                                name="Description"
                                rows={3}
                                cols={50}
                                style={{
                                    width: '100%',
                                    height: '60px !important',
                                    resize: 'vertical',
                                }}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="e-textlabel mb-2">Meeting with</td>
                        <td colSpan={4}>
                            <input
                                id="MeetingWith"
                                className="e-field e-input"
                                type="text"
                                name="MeetingWith"
                                style={{ width: '100%' }}
                                value={meetingWith}
                                onChange={(e) => setMeetingWith(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="e-textlabel">Feedback</td>
                        <td colSpan={4}>
                            <DropDownListComponent
                                id="Feedback"
                                placeholder="-- Choose Feedback --"
                                data-name="Feedback"
                                className="e-field"
                                style={{ width: "100%" }}
                                dataSource={["Positive", "Negative", "Rescheduled"]}
                                value={props.Feedback || null}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        ) : (
            <div />
        );
    }


    function onEventRendered(args) {
        console.log(args)
        if (args.data.Status == "") {
            args.element.style.backgroundColor = "#357cd2";
        } else if (args.data.Status == "Open") {
            // args.element.firstChild.style.backgroundColor  = "#008000";
            // args.element.firstChild.style.borderColor  = "#0A253E";
        } else if (args.data.Status == "Rejected") {
            args.element.firstChild.style.backgroundColor = "#A0A0A033";
            args.element.firstChild.style.borderColor = "#A0A0A0";
        } else if (args.data.Status == "Done") {
            args.element.firstChild.style.backgroundColor = "#1abc9c33";
            args.element.firstChild.style.borderColor = "#1abc9c";
        } else {
            return
        }
    }

    const tooltipTemplate = (props) => {
        return (<div className="tooltip-wrap">
            <div className="content-area">
                <table>
                    <tr>
                        <td>Lead Name</td>
                        <td>: {props._leadName}</td>
                    </tr>
                    {props._assignData &&
                        <>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td>Assigned To</td>
                                <td>: {props._assignData.assignedToName}</td>
                            </tr>
                            <tr>
                                <td>Assigned To</td>
                                <td>: {props._assignData.assignedTo2Name}</td>
                            </tr>
                            <tr>
                                <td>Sales Manger</td>
                                <td>: {props._assignData.salesMangerName}</td>
                            </tr>
                        </>
                    }
                    <tr><td>&nbsp;</td></tr>
                    <tr>
                        <td>Subject</td>
                        <td>: {props.Subject}</td>
                    </tr>
                    <br />
                    <tr>
                        <td>From</td>
                        <td>: {props.StartTime.toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td>To</td>
                        <td>: {props.EndTime.toLocaleString()}</td>
                    </tr>
                    <br />
                    <tr>
                        <td>Status</td>
                        <td>: {props.Status}</td>
                    </tr>
                    <tr>
                        <td>Feedback</td>
                        <td>: {props.Feedback}</td>
                    </tr>
                </table>
            </div></div>);
    }

    const onClickApplicant = (e, applicant) => {
        dispatch(updateSingleData(undefined));
        dispatch(updateRecentClickedApplicant(applicant));
        history.push('people/details');
    }

    const onChatClicked = (e, id) => {
        e.stopPropagation();
        dispatch(showChatFlyout({
            title: <><span></span></>,
            applicantId: id,
        }));
    };

    return (
        <>
            <div className="px-3 pt-3">
                <div className="row">
                    <div className="col-md-8">
                        <div className="default-root-container detail-container">
                            {dispatchListDetailTrs}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <ScheduleComponent
                            id='schedule'
                            height='700px'
                            currentView='Agenda'
                            cssClass='feed-schedule-container'
                            selectedDate={new Date()}
                            eventSettings={{ dataSource: data, enableTooltip: true, tooltipTemplate: tooltipTemplate.bind(this), allowAdding: false, allowDeleting: true }}
                            actionComplete={onActionComplete.bind(this)}
                            editorTemplate={editorTemplate.bind(this)}
                            ref={(t) => (scheduleObj = t)}
                            eventRendered={onEventRendered.bind(this)}
                        >
                            <ViewsDirective>
                                {/* <ViewDirective option='Day' /> */}
                                <ViewDirective option='Agenda' />
                            </ViewsDirective>
                            <Inject services={[Agenda, Resize]} />
                        </ScheduleComponent>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Feed;