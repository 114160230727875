import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../api/client';
import { toLocalDate } from '../../common/common';
import { accountService } from '../../features/accounts/services/accountService';
import { getApplicantById, selectApplicantById, updateSingleData } from '../../features/applicant/reducerSlices/applicantSlice';
import { onNoteChanged } from '../../features/applicant/services/noteSaver';
import { saveNote, selectNoteStatus } from '../../features/reviewer/reducerSlices/reviewerSlice';
import DataNotFoundCard from '../Card/DataNotFoundCard';
import Statement from '../Controls/custom/textbox/statement';
import { isFormValid } from '../Controls/services/formService';

import sortImg from './../../assets/images/clipart/sort.svg';
import { saveUpdatedApplicant } from './reducerSlices/adminNoteSlice';
import ReactTooltip from 'react-tooltip';
import EllipsisLoader from '../Controls/loaders/ellipsisLoader';

function AddNoteFlyout(props) {

  const dispatch = useDispatch();

  const [isActive, setActive] = useState(false);
  const ToggleClass = () => {
    setActive(!isActive);

    const timer = setTimeout(() => {
      props.onClose(false);
    }, 1500);
  };

  const [note, setNote] = useState("");
  const [notesList, setNotesList] = useState([]);

  const ADMIN_NOTE_FORM = "AdminNoteForm";
  const [anfSubmitted, setANFSubmitted] = useState(false);
  const noteStatus = useSelector(selectNoteStatus);
  const [sort, setSort] = useState(false);

  const onSaveClicked = async (id) => {
    const user = accountService.getAuthenticatedUser();
    if (isFormValid(ADMIN_NOTE_FORM)) {
      const noteDetails = {
        note: note,
        log: {
          TimeStamp: (new Date()).toUTCString(),
          LogType: "Admin Note",
          LogValue: -1,
          Content: note,
          PayRates: "",
          Client: "",
          UserName: (user ? user.firstName + " " + user.lastName : ""),
        }
      };
      let _applicantObj = Object.assign({}, applicant);
      let _newApplicantObj = onNoteChanged(_applicantObj, noteDetails);
      dispatch(saveUpdatedApplicant(_newApplicantObj));  //ASk
      dispatch(updateSingleData(_newApplicantObj));
      setANFSubmitted(false);
      if (id) {
        await dispatch(saveNote({
          client: tenantId,
          serviceType: "TENANTADMIN",
          operation: "SAVENOTE",
          payload: {
            id: id,
            data: JSON.stringify(noteDetails)
          }
        }));

      }
    }
    else {
      setANFSubmitted(true);
    }
  };


  const applicant = useSelector(selectApplicantById);

  const _loadApplicant = async (id) => {
    await dispatch(getApplicantById({
      client: tenantId,
      serviceType: "TENANTUSER",
      operation: "GET",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  useEffect(() => {
    if (props.applicantId) {
      console.log('useEffect props.applicantId ->', props.applicantId);
      _loadApplicant(props.applicantId);
    }
    setNote("");
  }, [props.applicantId]);

  useEffect(() => {
    let _logs;
    let _noteDiv;
    let _noteArr = [];

    if (applicant && applicant.StatusLogs) {
      _logs = applicant.StatusLogs.map((log, index) => {
        if (log.LogValue === -1) {
          _noteDiv = <div className="tracking-item">
            <div className="tracking-icon status-intransit bg-blue">
              <span><i className="fa fa-pen-to-square fa-md" aria-hidden="true"></i></span>
            </div>
            <div className="tracking-content">
              <p>
                <span className="p-name">{log.UserName}</span>
              </p>
              <p className="p-subtext">{log.Content}</p>
              <span className="p-light-gray">{log.TimeStamp && toLocalDate(log.TimeStamp)}</span>
            </div>
          </div>
          _noteArr.push(_noteDiv);
        }
      });

      setNotesList(_noteArr.reverse());
    }
  }, [applicant]);

  const onSort = () => {
    setSort(!sort);
    const sortData = [...notesList].reverse();
    setNotesList(sortData);
  }

  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      onSaveClicked(props.applicantId)
    }
  }

  useEffect(() => {
    if (noteStatus === fetchStatus.SUCCEEDED && note !== '') {
      setNote("");
      ToggleClass();
    }
  }, [noteStatus]);

  return (
    <>
      <div className="flyout-wrapper position-fixed top-0 end-0">
        <div className={`flyout-card ${isActive ? 'close' : 'open'}`}>
          <div className="flyout-card-body overflow-visible">
            <div className="float-end">
              <div className="close-btn">
                <i className="fa fa-times" aria-hidden="true" onClick={ToggleClass} data-tip='Close'></i>
                <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
              </div>
            </div>
            <div className="flyout-card-title">Add Note</div>
            <div className="flyout-card-sub-title">
              {applicant ? applicant.FirstName.Data : ""}  {applicant ? applicant.LastName.Data : ""}
            </div>
            <label className="flyout-label pt-3">Notes</label>
            <Statement
              id="txtAdminNote"
              name="txtAdminNote"
              value={note}

              // StatementClasses="form-control"
              StatementClasses="flyout-input"
              rows={2}
              onChange={(e) => setNote(e.target.value)}
              required={true}
              errorMessage="Please enter note"
              form={ADMIN_NOTE_FORM}
              submited={anfSubmitted}
              placeholder="Enter note..."
              onKeyDown={onEnterPress}
            />
            <div className="d-flex align-items-baseline pt-3">
              {/* <div className="form-group ">
              <input type="checkbox" id="html" name='html' />
              <label className="flyout-label" for="html">Pin note to top</label>
            </div> */}
              <div className="ms-auto">
                {(noteStatus === fetchStatus.LOADING) ? <EllipsisLoader />
                  : <>
                    <button className="c-btn dark-btn" type="button" onClick={() => onSaveClicked(props.applicantId)} >Add Note</button>
                  </>
                }
              </div>
            </div>
          </div>


          <div className="flyout-card-filter-bar d-flex align-items-baseline">
            <div className="flyout-card-semi-title">
              Recent Notes
            </div>
            <div className="ms-auto">
              {/* <button className="c-btn white-btn flyout-sort-bt" type="button" onClick={sort}>
              <img src={sortImg} className="pe-2" />Sort
            </button> */}
              <div className="sort-btn-group  white-btn flyout-sort-bt mx-2" onClick={onSort}>
                <button className={`c-btn sort-btn-transparent sort-btn-active`} type="button"><span className={sort ? 'fa fa-arrow-up-wide-short fa-lg' : 'fa fa-arrow-down-wide-short fa-lg'}></span></button>
                <button className={`c-btn sort-btn-transparent`} type="button">Sort</button>
              </div>
            </div>
          </div>

          <div className="flyout-card-body">
            {Object.keys(notesList).length === 0 ?
              <DataNotFoundCard containerClassOverriding={`data-not-found-container-100 pt-5 mt-5`} title={'No notes found '} />
              :
              <div className="tracking-list pt-3">
                {notesList}
              </div>
            }
          </div>

          {/* <div className="d-flex justify-content-center">
          <button className="c-btn c-btn-transparent flyout-card-load-more-btn mx-2" type="button" >Load more...</button>
        </div> */}
        </div>
      </div>
      <div className='flyout-backdrop flyout-backdrop-show' />
    </>
  )
}

export default AddNoteFlyout