import React, { useEffect, useRef, useState } from 'react';
import CheckBox from '../checkers/checkbox';

function MultiSelector(props) {
    const [onSelect, setOnSelect] = useState(false);
    const [value, setValue] = useState([]);
    const [optionsData, setOptionsData] = useState(props.options);
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);

    const optionsContainerRef = useRef(null);

    useEffect(() => {
        setOptionsData(props.options);
    }, [props.options]);

    let options = [], selectedOptions = [];
    let isSelectedValues = (value && Array.isArray(value) && value.length > 0);
    if (optionsData && Array.isArray(optionsData) && optionsData.length > 0) {
        options = optionsData.map((o, index) => (
            <li key={o.value} className="multi-selector-option" onClick={(e) => onSelected(e, o.value)}>
                <CheckBox
                    id={`ckbOption-${o.value}`}
                    size="Medium"
                    checked={isSelectedValues ? value.includes(o.value) : false} />
                <span className={`ms-3 ${isSelectedValues && value.includes(o.value) ? "option-selected" : ""}`}>{o.text}</span>
            </li>
        ));

        if (isSelectedValues) {
            for (let i = 0; i < optionsData.length; i++) {
                let loc = optionsData[i];
                if (value.includes(loc.value)) {
                    selectedOptions.push(
                        <span key={i} className="selected-option"><i className="fa fa-check mr-2" aria-hidden="true"></i>{loc.text}</span>
                    );
                }
            }

        }
    }


    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value || (value && Array.isArray(value) && value.length === 0)) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
        }
        else {
            setInvalid(false);
        }

    }, [value, props.required, edited, props.submited, props.disabled]);

    useEffect(() => {
        if (onSelect) {
            optionsContainerRef.current.focus();
        }
    }, [onSelect, optionsContainerRef]);

    const onSelected = async (e, v) => {
        e.stopPropagation();
        if (!value.includes(v)) {
            setValue([...value, v])
        }
        else {
            let _value = value.filter(vl => vl !== v);
            setValue(_value);
        }
        setOnSelect(true);
        setEdited(true);
        // let _e = { ...e };
        // _e.target["name"] = props.name;
        // props.onChange && props.onChange(_e, value);
    };

    const onBlur = (e) => {
        setOnSelect(false);
        let _e = { ...e };
        _e.target["name"] = props.name;
        _e.target["id"] = props.id;
        props.onChange && props.onChange(_e, value);
    };

    return (
        <React.Fragment>
            <div id={props.id} data-name={props.name} className="form-control multi-selector" data-form={props.form ? props.form : ""}
                data-invalid={invalid}>
                <div className={`selected-options-container ${props.disabled ? "selector-disabled" : ""}`}
                    onClick={(e) => !props.disabled && setOnSelect(!onSelect)}>
                    {value.length > 0 ? <span className="selected-options-summary"><strong>{`${value.length}`}</strong> selected</span> : <span className="selected-options-summary">Please select</span>}
                </div>
                {onSelect ? <div ref={optionsContainerRef} className="multi-selector-options-container" tabIndex={-1}
                    onBlur={(e) => onBlur(e)}>
                    <ul className="multi-selector-options">
                        {options}
                    </ul>
                </div> : <React.Fragment></React.Fragment>}
            </div>
            {
                invalid && <span className="error-label">
                    <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            }
        </React.Fragment>
    );
}

export default MultiSelector;