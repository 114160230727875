import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import { getLocationOptionsAll, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from '../../locations/reducerSlices/locationSlice';
import { addProspectsToLead, getAllProspects, selectAllProspects, selectConvertToLeadStatus, selectProspectStatusChangeStatus, selectStatus, selectTotalItems, updateIsFiltered } from '../reducerSlices/prospectsSlice';
import { accountService } from '../../accounts/services/accountService';
import {
  selectDefaultFilter,
  selectLocationFilter,
  selectRefreshData,
  selectWhereClause,
  selectWhereClauseChanged,
  updateLocationFilter,
  updateProspectsRefreshData
} from '../../../component/Controls/filters/reducerSlices/prospectsFilterSlice';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import { fetchStatus, tenantId } from '../../../api/client';
import { show } from '../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';
import LocationFilter from '../../../component/Controls/filters/locationFilter';
import { onProspectsLocationChanged } from '../services/prospectsFilterHandler';
import { showPSUModal } from '../../../component/Controls/modals/reducerSlices/prospectsStatusUpdateModelPopUpSlice';
import ProspectsHorizontalCard from '../../../component/Card/ProspectsHorizontalCard';
import DataNotFoundCard from '../../../component/Card/DataNotFoundCard';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { _getTextFromValueList } from '../../../common/common';
import { getPositionOptions, selectPositionOptions, selectPositionOptionsStatus } from '../../setting/reducerSlices/positionsSlice';

function Prospects() {

  const dispatch = useDispatch();
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 13,
        title: "Prospects",
        product: "CRM"
      }));
      setPageLoaded(true);
    }
  });


  const user = accountService.getAuthenticatedUser();

  const [loading, setLoading] = useState(false);

  const [locationOptionsValue, setLocationOptionsValue] = useState();
  const locationOptions = useSelector(selectLocationOptions);
  const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
  const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);
  const [base64Image, setBase64Image] = useState("");

  const convertToLeadStatus = useSelector(selectConvertToLeadStatus);
  const rejectProspectStatusChange = useSelector(selectProspectStatusChangeStatus);

  const prospectsRequestStatus = useSelector(selectStatus);

  const whereClause = useSelector(selectWhereClause);
  const whereClauseChanged = useSelector(selectWhereClauseChanged);
  const defaultFilter = useSelector(selectDefaultFilter);
  const refreshData = useSelector(selectRefreshData);
  const totalPageItems = useSelector(selectTotalItems);
  const [isFiltered, setIsFiltered] = useState(false);

  const positionOptions = useSelector(selectPositionOptions);
  const positionOptionsStatus = useSelector(selectPositionOptionsStatus);

  let _locationOptions = undefined;

  useEffect(() => {
    if (isObjectArray(locationOptions)) {
      _locationOptions = locationOptions.map((o) => (
        <option value={o.value}>{o.text}</option>
      ));
      setLocationOptionsValue(_locationOptions);
      setLoading(false);
    }
  }, [locationOptions]);

  useEffect(() => {
    if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
      setLoading(true);
      dispatch(getLocationOptionsAll({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLOCATIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "true" })
        }
      }));
    }
  }, [locationOptionsStatus, locationOptionsRefreshed]);

  useEffect(() => {
    if (positionOptionsStatus === fetchStatus.IDLE) {
      dispatch(getPositionOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETPOSITIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [positionOptionsStatus]);

  useEffect(() => {
    if (prospectsRequestStatus === fetchStatus.IDLE) {
      let loc = user ? user.locationId : "";
      let where = JSON.parse(JSON.stringify(whereClause));
      where.location.value.push(loc);
      dispatch(updateLocationFilter({
        locations: locationOptions,
        value: loc
      }));
      dispatch(updateIsFiltered());
      _loadProspects(0, 0, 10, where);
    }
  }, [prospectsRequestStatus, dispatch]);

  useEffect(() => {
    if (refreshData) {
      dispatch(updateIsFiltered());
      _loadProspects(0, 0, 10, whereClause);
      dispatch(updateProspectsRefreshData(false));
    }
  }, [whereClauseChanged, whereClause, refreshData]);

  const prospects = useSelector(selectAllProspects);

  useEffect(() => {
    if (convertToLeadStatus === fetchStatus.SUCCEEDED || rejectProspectStatusChange === fetchStatus.SUCCEEDED) {
      _loadProspects(0, 0, 10, whereClause);
    }
  }, [convertToLeadStatus, rejectProspectStatusChange]);

  const _loadProspects = async (page, offset, limit, whereClause) => {
    setIsFiltered(false);
    await dispatch(getAllProspects({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETPROSPECTS",
        payload: {
          id: "",
          data: JSON.stringify({
            // offset: offset,
            // limit: limit,
            where: whereClause
          })
        }
      }
    }));
  }

  const _convertToLead = (id) => {
    if (id) {
      dispatch(addProspectsToLead({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "CONVERTTOLEAD",
        payload: {
          id: id
        }
      }));
    }
  }

  const onAddClicked = (e, props) => {
    e.preventDefault();
    console.log('Add lead Id ', props);

    if (props.id) {
      dispatch(show({
        id: props.id,
        title: <><span>Add Lead </span> <span className="modal-header-name">{props.Employer ? props.Employer : "N/A"}</span></>,
        content: "Are you sure, you want to add lead " + props.Employer + " ?",
        confirmedCallback: _convertToLead
      }));
    }
  }

  const onRejectClicked = (e, props) => {
    e.preventDefault();
    if (props.id) {
      dispatch(showPSUModal({
        id: props.id,
        title: <><span>Reject Lead </span> <span className="modal-header-name">{props.Employer ? props.Employer : "N/A"}</span></>
      }));
    }
  }

  const addLeadBtnTemplate = (props) => {
    return <>
      <button
        type="button"
        className="c-btn dark-btn"
        title="Add To Lead"
        onClick={(e) => onAddClicked(e, props)}>
        <i className='fa fa-plus'></i>
      </button>
      <button
        type="button"
        className="c-btn white-btn text-danger ms-2"
        title="Reject To Lead"
        onClick={(e) => onRejectClicked(e, props)}>
        <i className='fa fa-times'></i>
      </button>
    </>
  }

  const onFilterClicked = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    _loadProspects(0, 0, 10, whereClause);
  };


  const [prospectsRecords, setProspectsRecords] = useState();

  useEffect(() => {
    if (prospects && prospects.items) {
      let _prospectsCards = prospects.items.map((d, index) =>
        <ProspectsHorizontalCard
          leadName={d.Employer}
          employerAddress={d.EmployerAddress}
          employerTelePhone={d.EmployerTelePhone}

          supervisor={d.Supervisor}
          employerPhone={d.EmployerPhone}

          position={d.Position && _getTextFromValueList(positionOptions, d.Position)}
          employerAgencyName={d.EmployerAgencyName}

          applicantName={`${d.FirstName} ${d.LastName}`}
          reasonForLeaving={d.ReasonForLeaving}

          onAddClicked={(e) => onAddClicked(e, d)}
          onRejectClicked={(e) => onRejectClicked(e, d)}
        />
      );

      let invisibleCard = <>
        <div className={`card people-horizontal-card invisible-card`} />
        <div className={`card people-horizontal-card invisible-card`} />
      </>;
   
      // setProspectsRecords(_prospectsCards);
      setProspectsRecords([..._prospectsCards, invisibleCard]);
    }
  }, [prospects]);

  const [dummyCards, setDummyCards] = useState([]);
  let _dummyCards = [];

  useEffect(() => {
    if (prospectsRequestStatus === fetchStatus.LOADING) {
      for (let i = 0; i < 10; i++) {
        _dummyCards.push(
          <div className="people-horizontal-card">
            <div className="people-horizontal-card-body p-2">
              <ReactPlaceholder showLoadingAnimation={true} type='media' ready={false} rows={3}>
              </ReactPlaceholder>
            </div>
          </div>
        );
      }
      setDummyCards(_dummyCards);
    }
  }, [prospectsRequestStatus])

  return (
    <>
      <div className="filter-bar p-3">
        <LocationFilter
          locations={locationOptions}
          locationFilterSelector={selectLocationFilter}
          onLocationChanged={(e, v, locations) => { onProspectsLocationChanged(e, v, locations); setIsFiltered(true); }}
        />
        <div className="float-end">
          {isFiltered &&
            <button type="button" className="c-btn dark-btn position-relative" onClick={(e) => onFilterClicked(e)}>
              <i className="fa fa-filter me-2" aria-hidden="true"></i> Filter
            </button>
          }
        </div>
      </div>

      <div className="right-content">
        <div className="d-flex w-100 p-3 pb-0">
          <div className="align-self-center">
            <div className="job-order-result-text">Showing {totalPageItems} Results <span className="job-order-result-sub-text">(Based your search)</span> </div>
          </div>
        </div>
      </div>

      <div className="right-content p-2">
        {prospectsRequestStatus === fetchStatus.LOADING ?
          <>
            {dummyCards}

          </>
          :
          prospectsRecords &&
            prospectsRecords.length > 0 ?
            prospectsRecords :
            <DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title={'No prospects found '} />
        }
      </div>
    </>
  )
}

export default Prospects;