import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Content from '../../../components/Controls/custom/content/content';
// import DetailContainer from '../../../components/Controls/custom/content/detailContainer';
// import ListContainer from '../../../components/Controls/custom/content/listContainer';
// import ToolBar from '../../../components/Controls/custom/toolBar';
// import SearchBar from '../../../components/Controls/searchBars/searchbar';
import {
  getAllLocations,
  updateIsFiltered,
  selectStatus,
  selectCurrentPage,
  selectTotalItems,
  selectAllLocations,
  updateStatus,
  selectFirstRecord,
  loadSingleData,
  getLocationById,
  updateCurrentPage,
  selectLocationById,
  updateLocation,
  selectModificationStatus,
  selectRemovalStatus,
  selectSingleStatus,
  selectSingleRefreshed,
  getLocationForm,
  selectLocationFormStatus,
  selectLocationForm,
  updateSingleData,
  createNewLocation,
  updateLocationFilters,
  removeLocation,
  createLocation,
  selectCreationStatus,
  refreshLocationOptions,
  selectDispatchDetail,
  selectDispatchDetailStatus,
  selectDispatchList,
  selectDispatchListStatus,
  selectDispatchCount,
  selectDispatchCountStatus,
  getDispatchById,
  getDispatchListById,
  getDispatchCountById,
  showLocationFlyout
} from '../../../locations/reducerSlices/locationSlice';
import { fetchStatus, tenantId } from '../../../../api/client';
import PageContainer from '../../../../component/Controls/paginators/paginator/pageContainer';
import Collection from '../../../../component/Controls/paginators/collection';
// import LocationCard from '../../../components/Controls/cards/locationCard';
// import EllipsisLoader from '../../../components/Controls/loaders/ellipsisLoader';
// import nophoto from '../../../assets/images/nophoto.png';
// import SaveControls from '../../shared/components/saveControls';
import { isFormValid } from '../../../../component/Controls/services/formService';
import { show } from '../../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';
import { messages } from '../../../../common/actionMessages';
import { locationsData } from '../../../shared/services/masterData';
// import BasicInfoBar from '../../shared/components/basicInfoBar';
// import { getBase64Image, openFileUploader, setUploadedPhoto } from '../../../common/common';
// import DetailContent from '../../shared/components/detailContent';
// import { updateLayout } from '../../../components/layouts/reducerSlices/layoutSlice';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import { nanoid } from '@reduxjs/toolkit';
import { onValueChanged, sections } from '../../services/locationSaver';
// import EditControls from './components/location/editControl';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import Moment from 'moment';
// import DateText from '../../../components/Controls/textboxes/DateText';
// import Loader from "react-js-loader";
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';
import ReactTooltip from 'react-tooltip';


function Location(props) {

  const _getLocationText = (id) => {
    let loc = locationsData.find(l => l.value === id);
    if (loc && loc.text) {
      return loc.text;
    }
    return "N/A";
  }

  const itemsPerPage = 10;
  const LOCATION_FORM = "Locationform";

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [locationRecords, setLocationRecords] = useState(new Collection());

  const [cFInvalid, setCFInvalid] = useState(false);
  const [cfSubmitted, setCFSubmitted] = useState(false);
  const [createMode, setCreatemode] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [statusVisibility, setStatusVisibility] = useState(false);

  const location = useSelector(selectLocationById);
  const firstLocation = useSelector((state) => selectFirstRecord(state, currentPage));
  const [dummyCards, setDummyCards] = useState([]);
  const currentPage = useSelector(selectCurrentPage);

  const locationFormStatus = useSelector(selectLocationFormStatus);
  const locationForm = useSelector(selectLocationForm);

  const locationRequestStatus = useSelector(selectSingleStatus);
  const singleRefreshedStatus = useSelector(selectSingleRefreshed);

  const locationCreationStatus = useSelector(selectCreationStatus);
  const locationModificationStatus = useSelector(selectModificationStatus);
  const locationRemovalStatus = useSelector(selectRemovalStatus);

  const totalPageItems = useSelector(selectTotalItems);
  const locations = useSelector(selectAllLocations);
  const locationsRequestStatus = useSelector(selectStatus);

  const [base64Image, setBase64Image] = useState("");
  const [file, setFile] = useState();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [tempLocationId, setTempLocationId] = useState("");
  const [disDet, setDisDet] = useState();
  const [disList, setDisList] = useState();
  const [disCount, setDisCount] = useState();
  const [start, setStart] = useState(Moment(Moment(new Date()).add(-1, 'd').format('YYYY/MM/DD')));
  const [loading, setLoading] = useState(false);
  const disatchListStatus = useSelector(selectDispatchListStatus);
  const dispatchCount = useSelector(selectDispatchCount);
  const disatchCountStatus = useSelector(selectDispatchCountStatus);
  const dispatchDetail = useSelector(selectDispatchDetail);
  const disatchDetailStatus = useSelector(selectDispatchDetailStatus);
  const dispatchList = useSelector(selectDispatchList);

  const OnClickReport = async (id) => {

    // var dt = `Between: ${start} and ${end} at location ${locationid}`;

    setLoading(true);

    // await dispatch(getDispatchCountById({
    //     page: 1,
    //     model: {
    //         client: tenantId,,
    //         serviceType: "TENANTADMIN",
    //         operation: "GECOUNTS",
    //         payload: {
    //             id: id,
    //             data: JSON.stringify({id: id,EndDate: Moment(start).add(1, 'd').format('YYYY/MM/DD'),
    //                 userName: '',
    //                 reportDate: Moment(start).format('YYYY-MM-DD'), 
    //                 type : 'Location'})
    //         }
    //     }
    // }));
    await dispatch(getDispatchById({
      page: 1,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GEDISPATCHDETAILS",
        payload: {
          id: id,
          data: JSON.stringify({
            id: id, userName: '',
            reportDate: Moment(start).format('YYYY-MM-DD'),
            type: 'Location'
          })
        }
      }
    }));

    await dispatch(getDispatchListById({
      page: 1,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GEDISPATCHLIST",
        payload: {
          id: id,
          data: JSON.stringify({
            id: id,
            reportDate: Moment(start).format('YYYY-MM-DD'),
            type: 'Location'
          })
        }
      }
    }));
    setLoading(false);


  };


  useEffect(() => {
    if (disatchDetailStatus === fetchStatus.SUCCEEDED) {
      let _disDet;

      if (isObjectArray(dispatchDetail) && dispatchDetail.length > 0) {
        _disDet = dispatchDetail.map((disDetail, index) => (
          <div>
            <div className="card-border card p-3 mb-2 ">

              <div className="mt-1">
                <p className="card-text text-justify">Employee Name: <b>{disDetail ? disDetail.name : ""}</b></p>
                <p className="card-text text-justify">Show/No Show: <b>{disDetail ? disDetail.dispatchDetail : ""}</b></p>
                <p className="card-text text-justify">Client Location: <b>{disDetail ? disDetail.clientLocation : ""}</b></p>
                <p className="card-text text-justify">Start Time: <b>{disDetail ? disDetail.startTime : ""}</b></p>
                <p className="card-text text-justify">Start Date: <b>{disDetail ? disDetail.startDate : ""}</b></p>
              </div>
            </div>
          </div>


        ));

        setDisDet(_disDet);
      }
      else {
        _disDet = <div> No data found</div>
        setDisDet(_disDet);
      }
    }
  }, [disatchDetailStatus, dispatchDetail]);


  useEffect(() => {
    if (disatchListStatus === fetchStatus.SUCCEEDED) {
      let _disList;

      if (isObjectArray(dispatchList) && dispatchList.length > 0) {

        _disList = dispatchList.map((disList, index) => (


          <tr key={disList.id}>
            <td>
              <label className=" FieldsWidth100"  >{disList ? disList.id : ""}</label>
            </td>
            <td>
              <label className=" FieldsWidth100"  >{disList ? disList.name : ""}</label>
            </td>
            <td>
              <label className="FieldsWidth100">{disList ? disList.clientName : ""}</label>
            </td>
            <td>
              <label className="FieldsWidth100">{disList ? disList.startTime : ""}</label>
            </td>
            <td>
              <label className="FieldsWidth100">{disList ? disList.startDate : ""}</label>
            </td>
          </tr>




        ));

        setDisList(_disList);
      }
      else {
        _disList = <div> No data found</div>
        setDisList(_disList);
      }
    }
  }, [disatchListStatus, dispatchList]);

  useEffect(() => {
    if (disatchCountStatus === fetchStatus.SUCCEEDED) {
      let _disCount;

      if (isObjectArray(dispatchCount) && dispatchCount.length > 0) {
        _disCount = dispatchCount.map((disList, index) => (
          <div className="row">
            <div className="md-col-2 p-2">
              <h4><span className="badge badge-primary">{disList.CalledOnTotal} Incoming Calls </span></h4>
            </div>
            <div className="md-col-2 p-2">
              <h4><span className="badge badge-info">{disList.CalledInTotal} Outgoing Calls</span></h4>
            </div>
            <div className="md-col-2 p-2">
              <h4><span className="badge badge-secondary">{disList.ApplicantTotal} Applications Taken</span></h4>
            </div>
            <div className="md-col-2 p-2">
              <h4><span className="badge badge-success">{disList.DispatchTotal} Dispatch</span></h4>
            </div>
            <div className="md-col-2 p-2">
              <h4><span className="badge badge-warning">{disList.StatusChangeTotal} Status Changes</span></h4>
            </div>


          </div>
        ));

        setDisCount(_disCount);
      }
      else {
        _disCount = <div className="row">
          <div className="md-col-2 p-2">
            <h2><span className="badge badge-primary">{disList.CalledOnTotal} Incoming Calls </span></h2>
          </div>
          <div className="md-col-2 p-2">
            <h2><span className="badge badge-secondary">{disList.ApplicantTotal} Applications Taken</span></h2>
          </div>
          <div className="md-col-2 p-2">
            <h2><span className="badge badge-success">{disList.DispatchTotal} Dispatch</span></h2>
          </div>
          <div className="md-col-2 p-2">
            <h2><span className="badge badge-warning">{disList.StatusChangeTotal} Status Changes</span></h2>
          </div>

        </div>
        setDisCount(_disCount);

      }

    }
  }, [disatchCountStatus, dispatchCount]);

  useEffect(() => {
    if (!pageLoaded) {
      if (locationFormStatus === fetchStatus.IDLE) {
        setPageLoaded(true);
        _loadForm();
      }
    }
  });

  useEffect(() => {
    if (locationsRequestStatus === fetchStatus.IDLE) {
      _loadLocations(0, 0, 10);
    }
  }, [locationsRequestStatus, dispatch]);

  let locationCards;
  let _dummyCards = [];

  let _locationRecords = new Collection();

  useEffect(() => {
    if (locationsRequestStatus === fetchStatus.SUCCEEDED) {
      let _records = locations.Items;

      if (locations.Items && locations.Items.length > 0) {
        for (let i = 0; i < locations.Items.length; i++) {
          let item = locations.Items[i];
          locationCards = item.records.map((location, index) => (
            // <LocationCard
            //   key={location.Id}
            //   locationCardClasses="mb-2"
            //   name={location.LocationName}
            //   city={location.City}
            //   state={location.State}
            //   pincode={location.Pincode}
            //   onClick={() => onLocationSelected(location.id)}
            // />
            <tr key={location.Id}>
              <td>{location.LocationName}</td>
              <td>{location.Address}</td>
              <td>{location.City}</td>
              <td>{location.State}</td>
              <td>{location.Pincode}</td>
              <td>
                <div className='c-icon-box' data-tip='Edit' onClick={() => onLocationSelected(location.id)}>
                  <i className='fa fa-pencil'></i>
                </div>
              </td>
            </tr>
          ));
          _locationRecords.Add(item.key, locationCards);
        }

        setLocationRecords(_locationRecords);
        dispatch(updateStatus());
      }
      dispatch(updateStatus());
    }
    // if (locations && locations.Items.length > 0) {
    //   let item = locations.Get(currentPage);
    //   let _locations = item ? item.records : [];
    //   if (_locations && Array.isArray(_locations) && _locations.length > 0) {
    //     let _location = _locations[0];
    //     if (_location && _location.id) {
    //       if (firstLocation && firstLocation.records) {
    //         dispatch(loadSingleData(firstLocation.records));
    //       }
    //       else {
    //         _loadLocation(_location.id, currentPage);
    //       }
    //     }
    //   }
    //   else {
    //     dispatch(updateSingleData(undefined));
    //   }
    // }
  }, [locationsRequestStatus, currentPage]);

  useEffect(() => {
    if (locationsRequestStatus === fetchStatus.LOADING) {
      for (let i = 0; i < 10; i++) {
        _dummyCards.push(
          <div className="list-loading-content">
            <ReactPlaceholder showLoadingAnimation ready={false} type="text" rows={5}>
            </ReactPlaceholder>
          </div>
        );
      }
      setDummyCards(_dummyCards);
    }
  }, [locationsRequestStatus]);

  useEffect(() => {
    if (locationsRequestStatus === fetchStatus.DONE) {
      let _records = locations.Items;

      if (locations.Items && locations.Items.length > 0) {
        for (let i = 0; i < locations.Items.length; i++) {
          let item = locations.Items[i];
          locationCards = item.records.map((location, index) => (
            // <LocationCard
            //   key={location.Id}
            //   locationCardClasses="mb-2"
            //   name={location.LocationName}
            //   city={location.City}
            //   state={location.State}
            //   pincode={location.Pincode}
            //   onClick={() => onLocationSelected(location.id)}
            // />
            <tr key={location.Id}>
              <td>{location.LocationName}</td>
              <td>{location.Address}</td>
              <td>{location.City}</td>
              <td>{location.State}</td>
              <td>{location.Pincode}</td>
              <td>
                <div className='c-icon-box' data-tip='Edit' onClick={() => onLocationSelected(location.id)}>
                  <i className='fa fa-pencil'></i>
                </div>
              </td>
            </tr>
          ));
          _locationRecords.Add(item.key, locationCards);
        }

        setLocationRecords(_locationRecords);
      }
      else {
        setLocationRecords(undefined);
      }
    }

  }, [singleRefreshedStatus]);

  useEffect(() => {
    if (locationRemovalStatus === fetchStatus.SUCCEEDED) {
      if (locations && locations.Items.length > 0) {
        let item = locations.Get(currentPage);
        let _locations = item ? item.records : [];
        if (_locations && Array.isArray(_locations) && _locations.length > 0) {
          let _location = _locations[0];
          if (_location && _location.id) {
            _loadLocation(_location.id, currentPage);
          }
        }
        else {
          dispatch(updateSingleData(undefined));
        }
      }
      else {
        setLocationRecords(undefined);
        dispatch(updateSingleData(undefined));
      }
    }

  }, [locationRemovalStatus]);

  const _loadLocation = async (id, page) => {
    await dispatch(getLocationById({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLOCATIONBYID",
        payload: {
          id: id,
          data: ""
        }
      }
    }));
  }

  const _loadForm = async () => {
    await dispatch(getLocationForm({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETFORMBYTYPE",
      payload: {
        id: "",
        data: "LocationForm"
      }
    }));
  }

  const _loadLocations = async (page, offset, limit, whereClause = undefined) => {
    await dispatch(getAllLocations({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLOCATIONS",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: offset,
            limit: 1000,
            where: whereClause
          })
        }
      }
    }));

  }

  const onValueUpdated = (e, section, value) => {
    let _locationObj = Object.assign({}, location);
    let _newLocationObj = onValueChanged(_locationObj, e, section, value);
    dispatch(updateSingleData(_newLocationObj));
  };

  const onFilterClicked = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    _loadLocations(0, 0, 10);
  };

  const onLocationSelected = (id) => {
    _loadLocation(id, undefined);
    setEditmode(false);
    setCreatemode(false);

    dispatch(showLocationFlyout({
      createMode: false,
      editMode: true
    }));
  };

  const onPageChanged = async (p, l) => {
    dispatch(updateCurrentPage(p));
    if (l) {
      await onLoadItems(p);
    }
  };

  const onLoadItems = async (p) => {
    await _loadLocations(p, p * 10, 10);
  };

  const onEditClicked = (e) => {
    e.preventDefault();
    setEditmode(true);
  };

  const onSaveClicked = (e, id, created) => {
    e.preventDefault();
    if (isFormValid(LOCATION_FORM)) {
      if (location) {
        if (created) {
          dispatch(createLocation({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVELOCATION",
            payload: {
              id: id,
              data: JSON.stringify({
                location: JSON.stringify(location)
              })
            }
          }));
        }
        else {
          dispatch(updateLocation({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVELOCATION",
            payload: {
              id: id,
              data: JSON.stringify({
                location: JSON.stringify(location)
              })
            }
          }));
        }
        dispatch(refreshLocationOptions());
      }
      setCreatemode(false);
      setEditmode(false);
      setCFInvalid(false);
      setCFSubmitted(false);
    }
    else {
      setCFInvalid(true);
    }
    setCFSubmitted(true);
  };

  const onCancelClicked = (e) => {
    setCreatemode(false);
    setEditmode(false);
    setCFInvalid(false);
    setCFSubmitted(false);
    if (tempLocationId) {
      setTempLocationId("");
      _loadLocation(tempLocationId, undefined);
    }
    else {
      dispatch(updateSingleData(undefined));
    }
  };

  const onDeleteClicked = (e, id) => {
    e.preventDefault();
    if (id) {
      dispatch(show({
        id: id,
        title: messages.confirmAlert.title,
        content: messages.confirmAlert.content.removal('Location'),
        confirmedCallback: _removeLocation
      }));
    }
  };

  const onAddRecordClicked = (e) => {
    setCreatemode(true);
    if (location && location.id) {
      setTempLocationId(location.id);
    }
    dispatch(createNewLocation());

    dispatch(showLocationFlyout({
      createMode: true,
      editMode: false
    }));
  };

  const onSearchClicked = async (e, value) => {
    e.preventDefault();
    if (value) {
      await dispatch(updateIsFiltered());
      _loadLocations(0, 0, 10, {
        name: value
      });
    }
  };

  const onSearchCleared = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    _loadLocations(0, 0, 10, {
      name: ""
    });
  };

  const _removeLocation = async (id) => {
    await dispatch(removeLocation({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "DELETELOCATION",
      payload: {
        id: id,
        data: ""
      }
    }));
    dispatch(refreshLocationOptions());
  };

  useEffect(() => {
    if (locationCreationStatus === fetchStatus.SUCCEEDED) {
      _loadLocations(0, 0, 10);
    }
  }, [locationCreationStatus]);

  useEffect(() => {
    if (locationModificationStatus === fetchStatus.SUCCEEDED) {
      _loadLocations(0, 0, 10);
    }
  }, [locationModificationStatus]);

  const addActionTemplate = (props) => {
    return <React.Fragment>
      <i className='fa fa-pencil' data-tip='Edit' title='Edit' onClick={() => onLocationSelected(props.id)}></i>
      <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
    </React.Fragment>
  }
  const gridRef = useRef(null);
  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  }, [locations, locationsRequestStatus]);

  const columns = [
    { field: 'LocationName', headerText: 'Location Name', width: '100' },
    { field: 'Address', headerText: 'Address', width: '100' },
    { field: 'City', headerText: 'City', width: '100' },
    { field: 'State', headerText: 'State', width: '100' },
    { field: 'Pincode', headerText: 'Pincode', width: '100' },
    {
      headerText: 'Action',
      template: addActionTemplate,
      width: '50px',
    },
  ];

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-6">
          <span className="detail-container-title">Locations</span>
        </div>
        <div className="col-md-6 text-end">
          <button className="c-btn dark-btn" type="button" data-tip='Create new location' onClick={(e) => onAddRecordClicked(e)}>
            <i className="fa fa-plus"></i>
          </button>
          {/* <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' /> */}
        </div>
      </div>
      {/* <div className="row pt-3">
        <table className="table c-table ">
          <thead>
            <tr>
              <th scope="col">Location Name</th>
              <th scope="col">Address</th>
              <th scope="col">City</th>
              <th scope="col">State</th>
              <th scope="col">Pincode</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <PageContainer
              currentPage={currentPage}
              totalPageItems={totalPageItems}
              pageRecords={locationRecords}
              isItemsLoading={locationsRequestStatus}
              itemsLoadingContent={dummyCards}
              onPageChanged={(p, l) => onPageChanged(p, l)}
              numberOfColumns={6}
            />
          </tbody>
        </table>
      </div> */}
      <div className="row p-3">
        <GridComponent ref={gridRef} dataSource={locations} allowPaging={true} allowSorting={true} allowResizing={true} width="100%" toolbar={['Search']} pageSettings={{ pageSize: 10, pageCount: 5 }} rowHeight={50} columns={columns} >
          <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
        </GridComponent>
      </div>
    </React.Fragment>
  )
}

export default Location;