import React from 'react';

function RemoveIconButton(props) {
    const onClick = (e) => {
        e.preventDefault();
        if (!props.isLoading) {
            props.onClick(e);
        }
    };

    return (
        <span
            title={props.title ? props.title : "Remove record"}
            className={`text-danger ${props.isLoading ? "button-loading-icon" : ""} ${props.RemoveIconButtonClasses ? props.RemoveIconButtonClasses : ""}`}
            onClick={(e) => onClick(e)}>
            <i className={`${props.isLoading ? "fa fa-circle-o-notch fa-spin fa-fw" : "fa fa-trash"} ${props.RemoveIconClasses ? props.RemoveIconClasses : ""}`}></i>
        </span>
    );
}

export default RemoveIconButton;