import React, { useEffect, useState } from 'react';
import PageNavigation from './pageNavigation';
import Collection from '../collection';
import { fetchStatus } from '../../../../api/client';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import noDataImg from './../../../../assets/images/clipart/no-data.svg';

function TableContainer(props) {

    const _getTotalPageCount = (totalPageItems, itemsPerPage) => {
        if (totalPageItems % itemsPerPage === 0) {
            return totalPageItems / itemsPerPage;
        }
        return Math.floor(totalPageItems / itemsPerPage) + 1;
    };

    const currentPage = props.currentPage;
    const [pageRecords, setPageRecords] = useState(new Collection());
    const [pageCount, setPageCount] = useState(0);

    let totalPageItems = props.totalPageItems ? props.totalPageItems : 0;
    let itemsPerPage = (props.itemsPerPage || props.itemsPerPage >= 5) ? props.itemsPerPage : 10;
    let totalVisiblePageNumbers = (props.totalVisiblePageNumbers || props.totalVisiblePageNumbers >= 5) ? props.itemsPerPage : 5;

    let pageItems = props.pageItems;

    let totalPages = _getTotalPageCount(totalPageItems, itemsPerPage);

    useEffect(() => {
        if (props.pageRecords && props.pageRecords.Count > 0) {
            setPageRecords(props.pageRecords);
        }
        else {
            setPageRecords(new Collection());
        }
    }, [props.pageRecords, currentPage])

    const onPageClicked = (page) => {
        if (!pageRecords.Contains(page)) {
            props.onPageChanged(page, true);
        }
        else {
            props.onPageChanged(page, false);
        }
    }
    return (
        <>
            <tbody>
                {props.isItemsLoading === fetchStatus.LOADING ?
                    props.itemsLoadingContent
                    :
                    <>
                        {
                            pageRecords.Get(currentPage)
                                && isObjectArray(pageRecords.Get(currentPage).records)
                                && pageRecords.Get(currentPage).records.length > 0 ?
                                pageRecords.Get(currentPage).records :
                                <tr>
                                    <td colspan={props.numberOfColumns}>
                                        <div className="my-5">
                                            <div className="data-not-found">
                                                <img src={noDataImg} />
                                                <p className="title">No records found</p>
                                                <p className="description">Please refresh search filters</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                        }
                    </>}
            </tbody>

            <PageNavigation
                selectedPage={currentPage}
                totalPages={totalPages}
                totalVisiblePageNumbers={totalVisiblePageNumbers}
                onPageClicked={onPageClicked}
                totalPageItems={totalPageItems}
                numberOfColumns={props.numberOfColumns}
                itemsPerPage={itemsPerPage}
            />
        </>
    );
}

export default TableContainer