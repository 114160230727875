import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    items: [],
    currentPage: 0
};

export const pageContainerSlice = createSlice({
    name: 'pageContiner',
    initialState,
    reducers: {
        updateItems: (state, newItems) => {
            state.items = [...state.items, newItems.payload];
        },
        updateCurrentPage: (state, page) => {
            state.currentPage = page.payload;
        }
    }
});

export const { updateItems, updateCurrentPage } = pageContainerSlice.actions;

export default pageContainerSlice.reducer

export const selectAllItems = state => {
    return state.pageContainer.items;
}

export const selectCurrentPage = state => state.pageContainer.currentPage;