import React from 'react';
import DialCallButton from '../Controls/buttons/dialCallButton';

function ProspectsPeopleDetailSubCard(props) {
    return (
        <div className="people-detail-card-sub">
            <div className='d-flex justify-content-between'>
                <div className="people-detail-card-code">{props.clientName}</div>
            </div>
            <div className='clearfix'></div>
            <div className="row py-3">
                <div className="col-2">
                    <div className="people-detail-card-icon">
                        <div className="fa fa-duotone fa-business-time  fa-xl" />
                    </div>
                </div>
                <div className="col-4">
                    <div className="people-detail-card-label">From Date</div>
                    <div className="people-detail-card-text">{props.startDate}</div>
                </div>
                <div className="col-6">
                    <div className="people-detail-card-label">To Date</div>
                    <div className="people-detail-card-text">{props.endDate}</div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <span className="people-detail-card-label-2">Job Position</span>
                    <p className="text-primary">{props.jobPosition}</p>
                </div>
                {/* <div className="col-6">
                    <div className="float-end">
                        <span className="people-detail-card-badge people-detail-card-badge-pink">$ {props.price}</span>
                    </div>
                </div> */}
                <div className="col-6">
                    <span className="people-detail-card-label-2">Job Type</span>
                    <p className="text-primary">{props.jobType}</p>
                </div>
            </div>
            <div className="row mt-2">
                {/* <div className="col-6">
                    <span className="people-detail-card-label-2">Job Type</span>
                    <p className="text-primary">{props.jobType}</p>
                </div> */}
                <div className="col-6">
                    {/* <div className="float-end"> */}
                    <span className="people-detail-card-label-2">Supervisor Name</span>
                    <p className="text-primary">{props.reportTo}</p>
                    {/* </div> */}
                </div>
                <div className="col-6">
                    {props.clientPhone &&
                        <>
                            <span className="people-detail-card-label-2">Company Phone Number</span>
                            <div className='d-flex align-items-center'>
                                <DialCallButton
                                    dialCallButtonClasses="pe-2" />
                                <p className="text-primary">{props.clientPhone}</p>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProspectsPeopleDetailSubCard