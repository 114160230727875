import {
    createSlice,
    createAsyncThunk,
    nanoid
} from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';

import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON, isObjectArray } from '../../../utilities/utilityFunctions';
import Collection from '../../../component/Controls/paginators/collection';
import { refreshCreated } from '../../../component/Controls/paginators/services/createdResource';


const initialState = {
    leadsOptions: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    routeTrackingByUserAndDate: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    routeDateOptions: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    routeMapData: {
        totalItems: 0,
        data: undefined,
        status: fetchStatus.IDLE
    },
    recentClickedRouteTracking: {
        id: undefined
    }
}

export const getLeadsOptions = createAsyncThunk('routeTracking/getLeadsOptions', async (routeTrackingModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', routeTrackingModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let leadsOptionsData;
        if (isJSON(data)) {
            leadsOptionsData = JSON.parse(data);
        }
        return {
            page: routeTrackingModel.page,
            leadsOptions: leadsOptionsData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getAllRouteTracking = createAsyncThunk('routeTracking/getAllRouteTracking', async (routeTrackingModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', routeTrackingModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let routeTracking;
        if (isJSON(data)) {
            routeTracking = JSON.parse(data);
        }
        return {
            page: routeTrackingModel.page,
            routeTracking: routeTracking,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getRouteTrackingById = createAsyncThunk('routeTracking/getRouteTrackingById', async (routeTrackingModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, routeTrackingModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let routeTracking = undefined;
        if (data && isJSON(data)) {
            routeTracking = JSON.parse(data);
        }
        return {
            page: routeTrackingModel.page,
            routeTracking: routeTracking,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const createRouteTracking = createAsyncThunk('routeTracking/createRouteTracking', async (routeTrackingModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, routeTrackingModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let routeTracking = undefined;
    if (data && isJSON(data)) {
        routeTracking = JSON.parse(data);
    }
    return {
        routeTracking: routeTracking,
        success: response.data.success
    };
});

export const updateRouteTracking = createAsyncThunk('routeTracking/updateRouteTracking', async (routeTrackingModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, routeTrackingModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let routeTracking = undefined;
    if (data && isJSON(data)) {
        routeTracking = JSON.parse(data);
    }
    return {
        routeTracking: routeTracking,
        success: response.data.success
    };
});

export const getRouteTrackingByUserAndDate = createAsyncThunk('routeTracking/getRouteTrackingByUserAndDate', async (routeTrackingModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, routeTrackingModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let routeTracking = undefined;
        if (data && isJSON(data)) {
            data = JSON.parse(data);
        }
        return {
            data: data,
            success: response.data.success,
            error: response.data.error
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getRouteDateOptions = createAsyncThunk('routeTracking/GetRouteDateOptions', async (routeTrackingModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', routeTrackingModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let routeDateOptionsData;
        if (isJSON(data)) {
            routeDateOptionsData = JSON.parse(data);
        }
        return {
            page: routeTrackingModel.page,
            routeDateOptions: routeDateOptionsData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getRouteMap = createAsyncThunk('routeTracking/getRouteMap', async (routeTrackingModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', routeTrackingModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let routeMapData;
        if (isJSON(data)) {
            routeMapData = JSON.parse(data);
        }
        return {
            page: routeMapData.page,
            routeMapData: routeMapData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});


export const routeTrackingSlice = createSlice({
    name: 'routeTrackingSlice',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateSingleData: (state, _data) => {
            state.single.refreshed = nanoid();
            state.single.data = _data.payload ? Object.assign({}, _data.payload) : _data.payload;
            if (_data.payload) {
                let _item = state.data.records.Get(state.data.currentPage);
                if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                    let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.id);
                    let newRecords = _item.records;
                    let _routeTracking = {
                        Id: _data.payload.id,
                        userId: _data.payload.userId,
                        routeDate: _data.payload.routeDate
                    };
                    newRecords[_updatedObjectIndex] = _routeTracking;
                    _item.records = newRecords;
                    let records = new Collection();
                    records.Add(_item.key, newRecords);
                    state.data.records.Remove(_item.key);
                    records.Concat(state.data.records);
                    state.data.records = records;
                }
            }
        },
        createNewRouteTracking: state => {
            state.single.data = {
                "userId": "",
                "routeDate": "",
                "leadsDetails": [],
                "CreatedDate": "",
                "LastUpdated": "",
                "routeStatus": "New"
            }
        },
        // createLeadsDetails: (state, action) => {
        //     const leadsIdArr = action.payload.selectedLeads;
        //     const leadsOptionsArr = action.payload.leadsOptions;
        //     const leadsDetails = getleadsDetailsObject(leadsIdArr, leadsOptionsArr);
        //     console.log('leadsDetails 204 -->', leadsDetails)
        //     state.single.data = Object.assign({}, state.single.data);
        //     state.single.data.leadsDetails.push(...leadsDetails);
        // },
        createLeadsDetails: (state, action) => {
            const leadsIdArr = action.payload.selectedLeads;
            const leadsOptionsArr = action.payload.leadsOptions;
            const user = action.payload.user;
            const leadsDetails = getleadsDetailsObject(leadsIdArr, leadsOptionsArr);
            if (state.single.data.id) {
                const leadNames = leadsOptionsArr
                    .filter((lead) => leadsIdArr.includes(lead.id))
                    .map((lead) => lead.LeadName)
                    .join(", ");

                const logEntry = {
                    TimeStamp: new Date().toISOString(),
                    LogType: "Update Pipeline",
                    LogValue: 104,
                    Content: leadNames,
                    UserName: user.firstName + ' ' + user.lastName,
                    UserId: user.id,
                    StatusNote: leadNames
                };

                state.single.data.StatusLogs.push(logEntry);

            }
            state.single.data = Object.assign({}, state.single.data);
            state.single.data.leadsDetails.push(...leadsDetails);
            if (state.single.data.id) {
                const totalLeads = state.single.data.leadsDetails.length;
                const totalVisited = state.single.data.leadsDetails.filter(item => item.visited === 'Yes').length;
                state.single.data.TotalLeads = totalLeads;
                if (totalLeads === totalVisited) {
                    state.single.data.routeStatus = "Completed";
                } else if (totalVisited === 0) {
                    state.single.data.routeStatus = "New";
                } else {
                    state.single.data.routeStatus = "In Progress";
                }
            }
        },
        // deleteLeadsDetails: (state, action) => {
        //     const leadId = action.payload;
        //     state.single.data.leadsDetails = state.single.data.leadsDetails.filter(d => d.leadId !== leadId);
        //     state.single.data = Object.assign({}, state.single.data);
        // },
        deleteLeadsDetails: (state, action) => {
            const { leadId, user } = action.payload;
            if (!state.single.data.id) {
                state.single.data.leadsDetails = state.single.data.leadsDetails.filter(d => d.leadId !== leadId);
            } else {
                // Find the lead index with the given leadId
                const leadIndex = state.single.data.leadsDetails.findIndex((lead) => lead.leadId === leadId);
                if (leadIndex !== -1) {
                    // Get the lead object to record the deletion action in the log
                    const deletedLead = state.single.data.leadsDetails[leadIndex];
                    // Create a log entry for the deletion action
                    const logEntry = {
                        TimeStamp: new Date().toISOString(),
                        LogType: "Deleted",
                        LogValue: 103,
                        Content: deletedLead.leadName,
                        UserName: user.firstName + ' ' + user.lastName,
                        UserId: user.id,
                        StatusNote: "Lead deleted by Jerry Smith",
                    };
                    // Add the log entry to the StatusLogs array
                    state.single.data.StatusLogs.push(logEntry);
                    // Remove the lead from leadsDetails
                    state.single.data.leadsDetails.splice(leadIndex, 1);
                }

                const totalLeads = state.single.data.leadsDetails.length;
                const totalVisited = state.single.data.leadsDetails.filter(item => item.visited === 'Yes').length;
                state.single.data.TotalLeads = totalLeads;
                if (totalLeads === totalVisited) {
                    state.single.data.routeStatus = "Completed";
                } else if (totalVisited === 0) {
                    state.single.data.routeStatus = "New";
                } else {
                    state.single.data.routeStatus = "In Progress";
                }
            }
            state.single.data = Object.assign({}, state.single.data);
        },
        updateRrouteTrackingByUserAndDate: (state) => {
            state.routeTrackingByUserAndDate.data = undefined;
        },
        updateRouteMapData: (state) => {
            state.routeMapData.data = undefined;
        },
        updateRecentClickedRouteTracking: (state, _data) => {
            state.recentClickedRouteTracking.id = _data.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLeadsOptions.pending, (state, action) => {
            state.leadsOptions.status = fetchStatus.LOADING;
        }).addCase(getLeadsOptions.fulfilled, (state, action) => {
            state.leadsOptions.data = action.payload.leadsOptions;
            state.leadsOptions.status = fetchStatus.SUCCEEDED;
        }).addCase(getLeadsOptions.rejected, (state, action) => {
            state.leadsOptions.status = fetchStatus.FAILED;
            state.leadsOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getAllRouteTracking.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        }).addCase(getAllRouteTracking.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.routeTracking.totalItems;
            let _records = new Collection();
            _records.Add(action.payload.page, action.payload.routeTracking.items);
            if (!state.data.isFiltered) {
                _records.Concat(state.data.records);
            }
            else {
                state.data.firstRecords = new Collection();
                state.data.currentPage = 0;
            }
            state.data.isFiltered = false;
            state.data.records = _records;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getAllRouteTracking.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.data.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getRouteTrackingById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getRouteTrackingById.fulfilled, (state, action) => {
            state.single.data = action.payload.routeTracking;
            if (action.payload.page >= 0) {
                let _records = new Collection();
                _records.Add(action.payload.page, action.payload.routeTracking);
                _records.Concat(state.data.firstRecords);
                state.data.firstRecords = _records;
            }
            state.single.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getRouteTrackingById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(createRouteTracking.pending, (state, action) => {
            state.creation.status = fetchStatus.LOADING;
        }).addCase(createRouteTracking.fulfilled, (state, action) => {
            if (action.payload.routeTracking) {
                state.single.data = action.payload.routeTracking;
                const _totalLeads = action.payload.routeTracking.leadsDetails.length;
                let _routeTracking = {
                    id: action.payload.routeTracking.id,
                    userId: action.payload.routeTracking.userId,
                    routeDate: action.payload.routeTracking.routeDate,
                    routeStatus: action.payload.routeTracking.routeStatus,
                    TotalLeads: _totalLeads,
                    visited: 0
                };
                const created = refreshCreated(state.data.records, _routeTracking);
                state.data.records = created.records;
                state.data.currentPage = created.lastPage;
                state.data.totalItems += 1;
                state.single.refreshed = nanoid();
            }
            state.creation.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createRouteTracking.rejected, (state, action) => {
            state.creation.status = fetchStatus.FAILED;
            state.creation.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateRouteTracking.pending, (state, action) => {
            state.modification.status = fetchStatus.LOADING;
        }).addCase(updateRouteTracking.fulfilled, (state, action) => {
            //state.single.data = action.payload;
            state.modification.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateRouteTracking.rejected, (state, action) => {
            state.modification.status = fetchStatus.FAILED;
            state.modification.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getRouteTrackingByUserAndDate.pending, (state, action) => {
            state.routeTrackingByUserAndDate.status = fetchStatus.LOADING;
        }).addCase(getRouteTrackingByUserAndDate.fulfilled, (state, action) => {
            state.routeTrackingByUserAndDate.data = action.payload.data;
            state.routeTrackingByUserAndDate.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getRouteTrackingByUserAndDate.rejected, (state, action) => {
            state.routeTrackingByUserAndDate.status = fetchStatus.FAILED;
            state.routeTrackingByUserAndDate.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getRouteDateOptions.pending, (state, action) => {
            state.routeDateOptions.status = fetchStatus.LOADING;
        }).addCase(getRouteDateOptions.fulfilled, (state, action) => {
            state.routeDateOptions.data = action.payload.routeDateOptions;
            state.routeDateOptions.status = fetchStatus.SUCCEEDED;
        }).addCase(getRouteDateOptions.rejected, (state, action) => {
            state.routeDateOptions.status = fetchStatus.FAILED;
            state.routeDateOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getRouteMap.pending, (state, acttion) => {
            state.routeMapData.status = fetchStatus.LOADING;
        }).addCase(getRouteMap.fulfilled, (state, action) => {
            state.routeMapData.totalItems = action.payload.routeMapData.totalItems;
            state.routeMapData.data = action.payload.routeMapData.items;
            state.routeMapData.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getRouteMap.rejected, (state, action) => {
            state.routeMapData.status = fetchStatus.FAILED;
            state.routeMapData.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { updateStatus,
    updateCreationStatus, updateModificationStatus,
    updateSingleStatus,
    updateCurrentPage, updateIsFiltered,
    loadSingleData, updateSingleData, createNewRouteTracking,
    createLeadsDetails, deleteLeadsDetails,
    updateRrouteTrackingByUserAndDate,
    updateRouteMapData,
    updateRecentClickedRouteTracking } = routeTrackingSlice.actions;

export default routeTrackingSlice.reducer

export const selectLeadsOptions = state => state.routeTracking.leadsOptions.data;
export const selectLeadsOptionsState = state => state.routeTracking.leadsOptions.status;

export const selectAllRouteTracking = state => state.routeTracking.data.records;
export const selectTotalItems = state => state.routeTracking.data.totalItems;
export const selectStatus = state => state.routeTracking.data.status;
export const selectError = state => state.data.routeTracking.error;
export const selectCurrentPage = state => state.routeTracking.data.currentPage;
export const selectFirstRecord = (state, currentPage) => {
    return state.routeTracking.data.firstRecords.Get(currentPage);
}

export const selectRouteTrackingById = (state) => {
    return state.routeTracking.single ? state.routeTracking.single.data : undefined;
}
export const selectSingleStatus = state => state.routeTracking.single.status;
export const selectSingleError = state => state.routeTracking.single.error;
export const selectSingleRefreshed = state => state.routeTracking.single.refreshed;

export const selectCreationStatus = state => state.routeTracking.creation.status;

export const selectModificationStatus = state => state.routeTracking.modification.status;

export const selectRouteTrackingByIdUserAndDate = state => state.routeTracking.routeTrackingByUserAndDate.data;

export const selectRouteDateOptions = state => state.routeTracking.routeDateOptions.data;
export const selectRouteDateOptionsState = state => state.routeTracking.routeDateOptions.status;

export const selectRouteMapStatus = state => state.routeTracking.routeMapData.status;
export const selectRouteMap = (state) => {
    return state.routeTracking.routeMapData ? state.routeTracking.routeMapData.data : undefined;
}

const getleadsDetailsObject = (leadsIdArr, leadsOptionsArr) => {
    if (leadsIdArr && Array.isArray(leadsIdArr) && Array.isArray(leadsOptionsArr)) {
        let output = leadsIdArr.map((leadId) => {
            const leadOption = leadsOptionsArr.find((option) => option.id === leadId);
            if (leadOption) {
                return {
                    "id": nanoid().split('-').join(''),
                    "leadId": leadOption.id,
                    "leadName": leadOption.LeadName,
                    "leadPhone": leadOption.LeadPhone,
                    "leadStatus": leadOption.Status,
                    "visited": "No",
                    "visitedTimeStamp": "",
                    "photo": "",
                    "uploadedFileInfo": "",
                    "note": "",
                    "visitedLatitude": "",
                    "visitedLongitude": ""
                };
            }
            return null;
        });
        return output.filter(Boolean); // Filter out null values if any
    }
    return [];
}

export const selectRecentClickedRouteTracking = state => state.routeTracking.recentClickedRouteTracking.id;

