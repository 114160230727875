import {
    createSlice,
    createAsyncThunk,
    nanoid
} from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { Operation } from '../../../common/constants';
import Collection from '../../../component/Controls/paginators/collection';
import { refreshCreated } from '../../../component/Controls/paginators/services/createdResource';
import { refreshRemoved } from '../../../component/Controls/paginators/services/removedResource';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON, isObjectArray } from '../../../utilities/utilityFunctions';

const initialState = {
    form: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    leadOptions: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    removal: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    },
    LeadCalledOn: {
        status: fetchStatus.IDLE,
        error: null,
        data: ""
    },
    note: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    LeadCalledOn: {
        status: fetchStatus.IDLE,
        error: null,
        data: ""
    },
    convertToClient: {
        status: fetchStatus.IDLE,
        error: null,
        convertToClientResource: undefined,
        data: undefined,
    },
    users: {
        totalItems: 0,
        status: fetchStatus.IDLE,
        error: null,
        records: undefined
    },
    salesRepOptions: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    salesMangerOptions: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    salesRepAndSalesMangerOptions: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    appointments: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creationAppointment: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    removalAppointment: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    },
    leadByAddress: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    leadRoute: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    recentClickedLead: {
        id: undefined
    }
};

export const getLeadOptionsAll = createAsyncThunk('locations/getLeadOptionsAll', async (leadModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', leadModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let options;
    if (isJSON(data)) {
        options = JSON.parse(data);
    }
    return {
        options: options,
        success: response.data.success
    };
});

export const getAllLeads = createAsyncThunk('leads/getAllLeads', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', leadModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let leads;
        if (isJSON(data)) {
            leads = JSON.parse(data);
        }
        return {
            page: leadModel.page,
            leads: leads,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getLeadById = createAsyncThunk('leads/getLeadById', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let lead = undefined;
        if (data && isJSON(data)) {
            lead = JSON.parse(data);
        }
        return {
            page: leadModel.page,
            lead: lead,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getLeadForm = createAsyncThunk('leads/getLeadForm', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let form = undefined;
        if (data && isJSON(data)) {
            form = JSON.parse(data);
        }
        return {
            form: form,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const createLead = createAsyncThunk('leads/createLead', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let lead = undefined;
        if (data && isJSON(data)) {
            lead = JSON.parse(data);
        }
        return {
            lead: lead,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const updateLead = createAsyncThunk('leads/updateLead', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let lead = undefined;
        if (data && isJSON(data)) {
            lead = JSON.parse(data);
        }
        return {
            lead: lead,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const removeLead = createAsyncThunk('leads/removeLead', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        return {
            id: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const saveNote = createAsyncThunk('leads/saveNote', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let lead = undefined;
        if (data && isJSON(data)) {
            lead = JSON.parse(data);
        }
        return {
            lead: lead,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const saveLeadCalledOn = createAsyncThunk('reviewers/saveLeadCalledOn', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let lead = undefined;
        if (data && isJSON(data)) {
            lead = JSON.parse(data);
        }
        return {
            lead: lead,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const addLeadToClient = createAsyncThunk('leads/addLeadToClient', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let lead = undefined;
        if (data && isJSON(data)) {
            lead = JSON.parse(data);
        }
        return {
            lead: lead,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});


export const getAllUsers = createAsyncThunk('users/getAllUsers', async (userModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', userModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let users;
        if (isJSON(data)) {
            users = JSON.parse(data);
        }
        return {
            page: userModel.page,
            users: users,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getSalesRepOptions = createAsyncThunk('dashbaord/GetSalesRepOptions', async (dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let salesRepOptionsData;
        if (isJSON(data)) {
            salesRepOptionsData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            salesRepOptions: salesRepOptionsData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getSalesMangerOptions = createAsyncThunk('dashbaord/GetSalesMangerOptions', async (dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let salesMangerOptionsData;
        if (isJSON(data)) {
            salesMangerOptionsData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            salesMangerOptions: salesMangerOptionsData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getSalesRepAndSalesMangerOptions = createAsyncThunk('dashbaord/GetSalesRepAndSalesMangerOptions', async (dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let salesRepAndSalesMangerOptionsData;
        if (isJSON(data)) {
            salesRepAndSalesMangerOptionsData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            salesRepAndSalesMangerOptions: salesRepAndSalesMangerOptionsData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getAppointmentsById = createAsyncThunk('leads/getAppointmentsById', async (appointmentsModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, appointmentsModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let appointments = undefined;
        if (data && isJSON(data)) {
            appointments = JSON.parse(data);
        }
        return {
            page: appointmentsModel.page,
            appointments: appointments,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const createAppointment = createAsyncThunk('leads/createAppointment', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let appointment = undefined;
        if (data && isJSON(data)) {
            appointment = JSON.parse(data);
        }
        return {
            appointment: appointment,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getLeadByAddress = createAsyncThunk('leads/getLeadByAddress', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let lead = undefined;
        if (data) {
            lead = data;
        }
        return {
            page: leadModel.page,
            lead: lead,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const saveLeadRoute = createAsyncThunk('leads/saveLeadRoute', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let lead = undefined;
        if (data && isJSON(data)) {
            lead = JSON.parse(data);
        }
        return {
            lead: lead,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const removeAppointment = createAsyncThunk('leads/removeAppointment', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        return {
            id: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const leadSlice = createSlice({
    name: 'leads',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateRemovalStatus: (state) => {
            state.removal.status = fetchStatus.IDLE;
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateSingleData: (state, _data) => {
            state.single.refreshed = nanoid();
            state.single.data = _data.payload ? Object.assign({}, _data.payload.lead) : _data.payload;
            if (_data.payload && _data.payload.lead) {

                if (_data.payload.operation === Operation.UPDATED) {
                    let _item = state.data.records.Get(state.data.currentPage);
                    if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                        let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.lead.id);
                        let newRecords = _item.records;
                        let _lead = {
                            id: _data.payload.lead.id,
                            LeadName: _data.payload.lead.LeadName.Data,
                            LeadPhone: _data.payload.lead.LeadPhone.Data,
                            Status: _data.payload.lead.Status.Data,
                            Location: _data.payload.lead.Location.Data,
                            LeadDate: _data.payload.lead.LeadDate
                        };
                        newRecords[_updatedObjectIndex] = _lead;
                        _item.records = newRecords;
                        let records = new Collection();
                        records.Add(_item.key, newRecords);
                        state.data.records.Remove(_item.key);
                        records.Concat(state.data.records);
                        state.data.records = records;
                    }
                }
            } else {
                state.single.data = Object.assign({}, _data.payload);
                let _item = state.data.records.Get(state.data.currentPage);
                if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                    let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.id);
                    let newRecords = _item.records;//.filter(a => a.id !== _data.payload.id);
                    let _lead = {
                        id: _data.payload.id,
                        LeadName: _data.payload.LeadName.Data,
                        LeadPhone: _data.payload.LeadPhone.Data,
                        Status: _data.payload.Status.Data,
                        // LeadDate: _data.payload.lead.LeadDate.Data
                    };
                    newRecords[_updatedObjectIndex] = _lead;
                    _item.records = newRecords;
                    let records = new Collection();
                    records.Add(_item.key, newRecords);
                    state.data.records.Remove(_item.key);
                    records.Concat(state.data.records);
                    state.data.records = records;
                }
            }
        },
        updateSingleLead: (state, _data) => {
            if (_data.payload) {
                state.single.data = Object.assign({}, _data.payload);
            }
        },
        createNewLead: state => {
            state.single.data = {
                "LeadName": {
                    "Id": "txtLeadName",
                    "Data": ""
                },
                "LeadPhone": {
                    "Id": "txtLeadPhone",
                    "Data": ""
                },
                "LeadTelePhone": {
                    "Id": "txtLeadTelePhone",
                    "Data": ""
                },
                "Position": {
                    "Id": "drpPosition",
                    "Data": ""
                },
                "Supervisor": {
                    "Id": "txtSupervisor",
                    "Data": ""
                },
                "Address": {
                    "Id": "txtAddress",
                    "Data": ""
                },

                "City": {
                    "Id": "txtCity",
                    "Data": ""
                },
                "State": {
                    "Id": "txtState",
                    "Data": ""
                },
                "ZipCode": {
                    "Id": "txtZipCode",
                    "Data": ""
                },
                "Location": {
                    "Id": "drpLocations",
                    "Data": ""
                },
                "LeadType": {
                    "Id": "drpLeadType",
                    "Data": "Create"
                },
                "Status": {
                    "Id": "drpStatuses",
                    "Data": "New"
                },
                "AssignedTo": {
                    "Id": "txtAssignedTo",
                    "Data": ""
                },
                "ApplicantId": "",
                "LeadDate": "",
                "Contacts": [

                ],
                "GoogleAddressDetail": []
            };
        },
        updateConvertToClientStatus: (state) => {
            state.convertToClient.status = fetchStatus.IDLE;
        },
        createContactDetail: (state) => {
            const detail = getContactDetailObject();
            state.single.data = Object.assign({}, state.single.data);
            if (!state.single.data.Contacts) {
                state.single.data.Contacts = [];
            }
            state.single.data.Contacts.push(detail);
        },
        deleteContactDetail: (state, action) => {
            const id = action.payload;
            state.single.data.Contacts = state.single.data.Contacts.filter(d => d.id !== id);
            state.single.data = Object.assign({}, state.single.data);
        },
        updateSalesManagerOptionsStatus: (state) => {
            state.salesMangerOptions.status = fetchStatus.DONE;
        },
        updateAppointmentsStatus: (state) => {
            state.appointments.status = fetchStatus.IDLE;
        },
        loadAppointmentsData: (state, _data) => {
            state.appointments.data = Object.assign({}, _data.payload);
        },
        updateAppointmentsData: (state, _data) => {
            state.appointments.refreshed = nanoid();
            state.appointments.data = _data.payload ? Object.assign({}, _data.payload.appointments) : _data.payload;
        },
        updateSingleAppointments: (state, _data) => {
            if (_data.payload) {
                state.appointments.data = Object.assign({}, _data.payload);
            }
        },
        updateRecentClickedLead: (state, _data) => {
            state.recentClickedLead.id = _data.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllLeads.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        }).addCase(getAllLeads.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.leads.totalItems;
            let _records = new Collection();
            _records.Add(action.payload.page, action.payload.leads.items);
            if (!state.data.isFiltered) {
                _records.Concat(state.data.records);
            }
            else {
                state.data.firstRecords = new Collection();
                state.data.currentPage = 0;
            }
            state.data.isFiltered = false;
            state.data.records = _records;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getAllLeads.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.data.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getLeadById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getLeadById.fulfilled, (state, action) => {
            state.single.data = action.payload.lead;
            // console.log(action.payload.lead)
            if (action.payload.page >= 0) {
                let _records = new Collection();
                _records.Add(action.payload.page, action.payload.lead);
                _records.Concat(state.data.firstRecords);
                state.data.firstRecords = _records;
            }
            state.single.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getLeadById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(createLead.pending, (state, action) => {
            state.creation.status = fetchStatus.LOADING;
        }).addCase(createLead.fulfilled, (state, action) => {
            if (action.payload.lead) {
                state.single.data = action.payload.lead;
                let _lead = {
                    id: action.payload.lead.id,
                    LeadName: action.payload.lead.LeadName.Data,
                    LeadPhone: action.payload.lead.LeadPhone.Data,
                    Status: action.payload.lead.Status.Data,
                    Location: action.payload.lead.Location.Data
                };
                const created = refreshCreated(state.data.records, _lead);
                state.data.records = created.records;
                state.data.currentPage = created.lastPage;
                state.data.totalItems += 1;
                state.single.refreshed = nanoid();
            }
            state.creation.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createLead.rejected, (state, action) => {
            state.creation.status = fetchStatus.FAILED;
            state.creation.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateLead.pending, (state, action) => {
            state.modification.status = fetchStatus.LOADING;
        }).addCase(updateLead.fulfilled, (state, action) => {
            state.single.data = action.payload.lead;
            state.modification.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateLead.rejected, (state, action) => {
            state.modification.status = fetchStatus.FAILED;
            state.modification.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(removeLead.pending, (state, action) => {
            state.removal.status = fetchStatus.LOADING;
        }).addCase(removeLead.fulfilled, (state, action) => {
            const removed = refreshRemoved(state.data.records, action.payload.id, state.data.currentPage);
            state.data.records = removed.records;
            state.data.currentPage = removed.lastPage;
            state.data.totalItems -= 1;
            state.single.refreshed = nanoid();
            state.removal.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(removeLead.rejected, (state, action) => {
            state.removal.status = fetchStatus.FAILED;
            state.removal.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getLeadForm.pending, (state, action) => {
            state.form.status = fetchStatus.LOADING;
        }).addCase(getLeadForm.fulfilled, (state, action) => {
            state.form.data = action.payload.form;
            state.form.status = fetchStatus.SUCCEEDED;
        }).addCase(getLeadForm.rejected, (state, action) => {
            state.form.status = fetchStatus.FAILED;
            state.form.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getLeadOptionsAll.pending, (state, action) => {
            state.leadOptions.status = fetchStatus.LOADING;
        }).addCase(getLeadOptionsAll.fulfilled, (state, action) => {
            let _options = action.payload.options;
            _options = _options.map(function (option) {
                option['value'] = option['id'];
                delete option['id'];
                option['text'] = option['LeadName'];
                delete option['LeadName'];
                return option;
            });
            state.leadOptions.options = _options;
            state.leadOptions.status = fetchStatus.SUCCEEDED;
            state.leadOptions.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getLeadOptionsAll.rejected, (state, action) => {
            state.leadOptions.status = fetchStatus.FAILED;
            state.leadOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(saveNote.pending, (state, action) => {
            state.note.status = fetchStatus.LOADING;
        }).addCase(saveNote.fulfilled, (state, action) => {
            state.note.data = action.payload.reviewer;
            state.note.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(saveNote.rejected, (state, action) => {
            state.note.status = fetchStatus.FAILED;
            state.note.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(saveLeadCalledOn.pending, (state, action) => {
            state.LeadCalledOn.status = fetchStatus.LOADING;
        }).addCase(saveLeadCalledOn.fulfilled, (state, action) => {
            state.LeadCalledOn.data = action.payload.lead;
            state.LeadCalledOn.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(saveLeadCalledOn.rejected, (state, action) => {
            state.LeadCalledOn.status = fetchStatus.FAILED;
            state.LeadCalledOn.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(addLeadToClient.pending, (state, action) => {
            state.convertToClient.status = fetchStatus.LOADING;
        }).addCase(addLeadToClient.fulfilled, (state, action) => {
            state.convertToClient.data = action.payload.lead;
            state.convertToClient.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(addLeadToClient.rejected, (state, action) => {
            state.convertToClient.status = fetchStatus.FAILED;
            state.convertToClient.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getAllUsers.pending, (state, action) => {
            state.users.status = fetchStatus.LOADING;
        }).addCase(getAllUsers.fulfilled, (state, action) => {
            state.users.totalItems = action.payload.users.totalItems;
            state.users.records = action.payload.users.items;
            state.users.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getAllUsers.rejected, (state, action) => {
            state.status = fetchStatus.FAILED;
            state.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getSalesRepOptions.pending, (state, action) => {
            state.salesRepOptions.status = fetchStatus.LOADING;
        }).addCase(getSalesRepOptions.fulfilled, (state, action) => {
            state.salesRepOptions.data = action.payload.salesRepOptions;
            state.salesRepOptions.status = fetchStatus.SUCCEEDED;
        }).addCase(getSalesRepOptions.rejected, (state, action) => {
            state.salesRepOptions.status = fetchStatus.FAILED;
            state.salesRepOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getSalesMangerOptions.pending, (state, action) => {
            state.salesMangerOptions.status = fetchStatus.LOADING;
        }).addCase(getSalesMangerOptions.fulfilled, (state, action) => {
            state.salesMangerOptions.data = action.payload.salesMangerOptions;
            state.salesMangerOptions.status = fetchStatus.SUCCEEDED;
        }).addCase(getSalesMangerOptions.rejected, (state, action) => {
            state.salesMangerOptions.status = fetchStatus.FAILED;
            state.salesMangerOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getSalesRepAndSalesMangerOptions.pending, (state, action) => {
            state.salesRepAndSalesMangerOptions.status = fetchStatus.LOADING;
        }).addCase(getSalesRepAndSalesMangerOptions.fulfilled, (state, action) => {
            state.salesRepAndSalesMangerOptions.data = action.payload.salesRepAndSalesMangerOptions;
            state.salesRepAndSalesMangerOptions.status = fetchStatus.SUCCEEDED;
        }).addCase(getSalesRepAndSalesMangerOptions.rejected, (state, action) => {
            state.salesRepAndSalesMangerOptions.status = fetchStatus.FAILED;
            state.salesRepAndSalesMangerOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getAppointmentsById.pending, (state, action) => {
            state.appointments.status = fetchStatus.LOADING;
        }).addCase(getAppointmentsById.fulfilled, (state, action) => {
            state.appointments.data = action.payload.lead;
            // console.log("action.payload.appointments", action.payload.appointments)
            // if (action.payload.page >= 0) {
            //     let _records = new Collection();
            //     _records.Add(action.payload.page, action.payload.lead);
            //     _records.Concat(state.data.firstRecords);
            //     state.data.firstRecords = _records;
            // }
            let calanderData = action.payload.appointments.map(function (d) {
                // return d.calendarData
                let cd = { ...d.calendarData, "appointmentId": d.id }
                return cd
            });
            // console.log('calanderD', calanderData)
            state.appointments.data = calanderData;
            state.appointments.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getAppointmentsById.rejected, (state, action) => {
            state.appointments.status = fetchStatus.FAILED;
            state.appointments.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(createAppointment.pending, (state, action) => {
            state.creationAppointment.status = fetchStatus.LOADING;
        }).addCase(createAppointment.fulfilled, (state, action) => {
            // if (action.payload.appointments) {
            //     state.single.data = action.payload.lead;
            //     let _appointments = {
            //         id: action.payload.lead.id,
            //         LeadName: action.payload.lead.LeadName.Data,
            //         LeadPhone: action.payload.lead.LeadPhone.Data,
            //         Status: action.payload.lead.Status.Data,
            //         Location: action.payload.lead.Location.Data
            //     };
            //     const created = refreshCreated(state.data.records, _appointments);
            //     state.data.records = created.records;
            //     state.data.currentPage = created.lastPage;
            //     state.data.totalItems += 1;
            //     state.single.refreshed = nanoid();
            // }
            state.creationAppointment.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createAppointment.rejected, (state, action) => {
            state.creationAppointment.status = fetchStatus.FAILED;
            state.creationAppointment.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getLeadByAddress.pending, (state, action) => {
            state.leadByAddress.status = fetchStatus.LOADING;
        }).addCase(getLeadByAddress.fulfilled, (state, action) => {
            state.leadByAddress.data = action.payload.lead;
            state.leadByAddress.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getLeadByAddress.rejected, (state, action) => {
            state.leadByAddress.data = "";
            state.leadByAddress.status = fetchStatus.FAILED;
            state.leadByAddress.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(saveLeadRoute.pending, (state, action) => {
            state.leadRoute.status = fetchStatus.LOADING;
        }).addCase(saveLeadRoute.fulfilled, (state, action) => {
            state.leadRoute.data = action.payload.lead;
            state.leadRoute.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(saveLeadRoute.rejected, (state, action) => {
            state.leadRoute.status = fetchStatus.FAILED;
            state.leadRoute.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(removeAppointment.pending, (state, action) => {
            state.removalAppointment.status = fetchStatus.LOADING;
        }).addCase(removeAppointment.fulfilled, (state, action) => {
            // state.data.records = removed.records;
            state.removalAppointment.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(removeAppointment.rejected, (state, action) => {
            state.removalAppointment.status = fetchStatus.FAILED;
            state.removalAppointment.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });

    }
});

export const { updateCreationStatus, updateModificationStatus, updateRemovalStatus,
    updateSingleStatus, updateIsFiltered, updateStatus, loadSingleData,
    updateCurrentPage, updateSingleData, createNewLead, updateSingleLead, updateConvertToClientStatus,
    createContactDetail, deleteContactDetail,
    updateSalesManagerOptionsStatus,
    updateAppointmentsData,
    updateRecentClickedLead } = leadSlice.actions;

export default leadSlice.reducer

export const selectAllLeads = state => state.leads.data.records;

export const selectTotalItems = state => state.leads.data.totalItems;

export const selectStatus = state => state.leads.data.status;

export const selectError = state => state.data.leads.error;

export const selectCreationStatus = state => state.leads.creation.status;

export const selectModificationStatus = state => state.leads.modification.status;

export const selectRemovalStatus = state => state.leads.removal.status;

export const selectCreationError = state => state.leads.creation.error;

export const selectModificationError = state => state.leads.modification.error;

export const selectRemovalError = state => state.leads.removal.error;

export const selectLeadById = (state) => {
    return state.leads.single ? state.leads.single.data : undefined;
}

export const selectSingleStatus = state => state.leads.single.status;

export const selectSingleError = state => state.leads.single.error;

export const selectCurrentPage = state => state.leads.data.currentPage;

export const selectFirstRecord = (state, currentPage) => {
    return state.leads.data.firstRecords.Get(currentPage);
}

export const selectSingleRefreshed = state => state.leads.single.refreshed;

export const selectLeadFormStatus = state => state.leads.form.status;

export const selectLeadFormError = state => state.leads.form.error;

export const selectLeadForm = state => state.leads.form.data;

export const selectLeadOptions = state => state.leads.leadOptions.options;

export const selectLeadOptionsStatus = state => state.leads.leadOptions.status;

export const selectLeadOptionsRefreshed = state => state.leads.leadOptions.refreshed;

export const selectNoteStatus = state => state.leads.note.status;

export const selectLeadCalledOnStatus = state => state.leads.LeadCalledOn.status;

export const selectCalledOnLead = state => state.leads.LeadCalledOn.data;

export const selectConvertToClientStatus = state => state.leads.convertToClient.status;

export const selectConvertToClientError = state => state.leads.convertToClient.error;

export const selectConvertToClientData = state => state.leads.convertToClient.data;

const getContactDetailObject = () => {
    const id = nanoid().split('-').join('');
    return {
        "id": id,
        "FullName": {
            "Id": `txtFullName-${id}`,
            "Data": ""
        },
        "Position": {
            "Id": `drpPositions-${id}`,
            "Data": ""
        },
        "ContactNumber": {
            "Id": `txtContactNumber-${id}`,
            "Data": ""
        },
        "Email": {
            "Id": `txtEmail-${id}`,
            "Data": ""
        }
    }
}

export const selectUserStatus = state => state.leads.users.status;

export const selectAllUsers = state => state.leads.users.records;

export const selectSalesRepOptions = state => state.leads.salesRepOptions.data;
export const selectSalesRepOptionsState = state => state.leads.salesRepOptions.status;

export const selectSalesMangerOptions = state => state.leads.salesMangerOptions.data;
export const selectSalesMangerOptionsState = state => state.leads.salesMangerOptions.status;

export const selectSalesRepAndSalesMangerOptions = state => state.leads.salesRepAndSalesMangerOptions.data;
export const selectSalesRepAndSalesMangerOptionsState = state => state.leads.salesRepAndSalesMangerOptions.status;

export const selectAppointmentsById = (state) => {
    return state.leads.appointments ? state.leads.appointments.data : undefined;
}

export const selectCreationAppointmentStatus = state => state.leads.creationAppointment.status;

export const selectLeadByAddress = (state) => {
    return state.leads.leadByAddress ? state.leads.leadByAddress.data : undefined;
}

export const selectRecentClickedLead = state => state.leads.recentClickedLead.id;

export const selectAppointmentsStatus = state => state.leads.appointments.status;

export const selectLeadRouteStatus = state => state.leads.leadRoute.status;

export const selectRemovalAppointmentStatus = state => state.leads.removalAppointment.status;