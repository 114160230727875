import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import { accountService } from '../../accounts/services/accountService';
import { fetchStatus, tenantId } from '../../../api/client';
import {
  selectDefaultFilter,
  selectLocationFilter,
  updateLocationFilter,
  selectRefreshData,
  selectWhereClause,
  selectWhereClauseChanged,
  updateScheduleFilter,
  updateScheduleRefreshData
} from '../../../component/Controls/filters/reducerSlices/scheduleFilterSlice';
import {
  getAllShedules,
  updateIsFiltered,
  selectStatus,
  selectAllSchedules,
  createAppointment,
  selectSaveAppointmentStatus,
  removeScheduleAppointment,
  selectRemovalAppointmentStatus,
} from '../reducerSlices/scheduleSlice';
import { selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from '../../locations/reducerSlices/locationSlice';
import LocationFilter from '../../../component/Controls/filters/locationFilter';
import { onScheduleLocationChanged } from '../services/scheduleFilterHandler';


import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject, Year, ExcelExport, Resize, Print } from '@syncfusion/ej2-react-schedule';
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { extend } from '@syncfusion/ej2-base';
import GridLoader from '../../../component/Controls/loaders/gridLoader';

function Schedule(props) {

  let scheduleObj;

  const user = accountService.getAuthenticatedUser();
  const isAdmin = accountService.isAdmin();

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const [pageLoaded, setPageLoaded] = useState(false);
  const refreshData = useSelector(selectRefreshData);

  const [editMode, setEditmode] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [statusVisibility, setStatusVisibility] = useState(false);

  const schedules = useSelector(selectAllSchedules);
  const schedulesRequestStatus = useSelector(selectStatus);

  const saveAppointmentStatus = useSelector(selectSaveAppointmentStatus);

  const whereClause = useSelector(selectWhereClause);
  const whereClauseChanged = useSelector(selectWhereClauseChanged);
  const defaultFilter = useSelector(selectDefaultFilter);

  const locationOptions = useSelector(selectLocationOptions);
  const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
  const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);

  const [activeAddBtn, setActiveAddBtn] = useState(true);

  const [isFiltered, setIsFiltered] = useState(false);
  const removalAppointmentStatus = useSelector(selectRemovalAppointmentStatus);


  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 15,
        title: "Calendar"
      }));
      setPageLoaded(true);
    }
  });

  useEffect(() => {
    if (schedulesRequestStatus === fetchStatus.IDLE) {
      let loc = user ? user.locationId : "";
      let where = JSON.parse(JSON.stringify(whereClause));
      where.location.value.push(loc);
      dispatch(updateLocationFilter({
        locations: locationOptions,
        value: loc
      }));
      _loadSchedules(whereClause);
    }
  }, [schedulesRequestStatus, dispatch]);

  useEffect(() => {
    if (refreshData) {
      dispatch(updateIsFiltered());
      _loadSchedules(whereClause);
      dispatch(updateScheduleRefreshData(false));
    }
  }, [whereClauseChanged, whereClause, refreshData]);



  const _loadSchedules = async (whereClause) => {
    setIsFiltered(false);
    await dispatch(getAllShedules({
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETAPPOINTMENTS",
        payload: {
          id: "",
          data: JSON.stringify({
            where: whereClause
          })
        }
      }
    }));
  }

  useEffect(() => {
    if (saveAppointmentStatus === fetchStatus.SUCCEEDED) {
      _loadSchedules(whereClause);
    }
  }, [saveAppointmentStatus]);
    
  useEffect(() => {
      if (removalAppointmentStatus === fetchStatus.SUCCEEDED) {
        _loadSchedules(whereClause);
      }
      if (removalAppointmentStatus === fetchStatus.FAILED) {
        _loadSchedules(whereClause);
      }
    }, [removalAppointmentStatus]);


  const onFilterClicked = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    _loadSchedules(whereClause);
  };

  const onSaveFilterClicked = async (e) => {
    // e.preventDefault();
    // if (whereClauseChanged && !defaultFilter) {
    //   const schedule = accountService.getAuthenticatedUser();
    //   if (schedule) {
    //     await dispatch(showCSFModal({
    //       title: `Save Filter For ${schedule.firstName + " " + schedule.lastName}`,
    //       scheduleId: schedule.id
    //     }));
    //   }
    // }
  };

  const onClearFilterClicked = async (e) => {
    e.preventDefault();
    dispatch(updateScheduleFilter({
      id: "",
      name: "",
      whereClause: undefined,
      defaultFilter: false
    }));
    dispatch(updateScheduleRefreshData(true));
  };

  const data = extend([], schedules, null, true);

  const fields = {
    id: 'Id',
    subject: { name: 'Subject', validation: { required: true } },
    startTime: { name: 'StartTime' },
    endTime: { name: 'EndTime' },
    description: { name: 'Description' },
    meetingWith: { name: 'MeetingWith' },
    feedback: { name: 'Feedback' }
  }

  const onActionComplete = (args) => {
    console.log('args', args)
    if (args.requestType === 'eventChanged') {
      // args.changedRecords
      console.log('args.changedRecords', args.changedRecords)
      const appointmentId = args.changedRecords[0]["appointmentId"];
      const appointmentDetails = {
        // scheduleId: schedule ? schedule.id : "",
        // scheduleName: schedule && schedule.ScheduleName ? schedule.ScheduleName.Data : "",
        calendarData: args.changedRecords[0]
      };

      delete appointmentDetails.calendarData.appointmentId;
      delete appointmentDetails.calendarData._leadName;
      delete appointmentDetails.calendarData._assignData;
      dispatch(createAppointment({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "SAVEAPPOINTMENT",
        payload: {
          id: appointmentId,
          data: JSON.stringify({
            appointment: JSON.stringify(appointmentDetails)
          })
        }
      }));
    }
    if (args.requestType === 'eventRemoved') {
      const appointmentId = args.deletedRecords[0]["appointmentId"];
      dispatch(removeScheduleAppointment({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "REMOVEAPPOINTMENT",
        payload: {
          id: appointmentId,
          data: ''
        }
      }));
    }
  }

  const editorTemplate = (props) => {

    const handleStartTimeChange = (args) => {
      const startTime = args.value;
      const endTimePicker = document.getElementById("EndTime").ej2_instances[0];
      const endTime = endTimePicker.value;

      if (startTime && endTime && startTime.getTime() >= endTime.getTime()) {
        endTimePicker.value = new Date(startTime.getTime() + 30 * 60 * 1000); // Add 30 minutes
      }
    }

    const handleEndTimeChange = (args) => {
      const endTime = args.value;
      const startTimePicker = document.getElementById("StartTime").ej2_instances[0];
      const startTime = startTimePicker.value;

      if (startTime && endTime && endTime.getTime() <= startTime.getTime()) {
        startTimePicker.value = new Date(endTime.getTime());
      }
    }

    const [title, setTitle] = useState(props.Subject || '');
    const [description, setDescription] = useState(props.Description || '');
    const [meetingWith, setMeetingWith] = useState(props.MeetingWith || '');

    return props !== undefined ? (
      <table
        className="custom-event-editor"
        style={{ width: "100%", cellpadding: "5" }}
      >
        <tbody>
          <tr>
            <td className="e-textlabel mb-2">Title</td>
            <td colSpan={4}>
              <input
                id="Summary"
                className="e-field e-input"
                type="text"
                name="Subject"
                style={{ width: '100%' }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel mb-2">Status</td>
            <td colSpan={4}>
              <DropDownListComponent
                id="Status"
                placeholder="-- Choose Status --"
                data-name="Status"
                className="e-field"
                style={{ width: "100%" }}
                dataSource={["Open", "Done", "Rejected"]}
                value={props.Status || null}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">From</td>
            <td colSpan={4}>
              <DateTimePickerComponent
                format="dd/MM/yy hh:mm a"
                id="StartTime"
                data-name="StartTime"
                value={new Date(props.startTime || props.StartTime)}
                className="e-field"
                change={handleStartTimeChange}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">To</td>
            <td colSpan={4}>
              <DateTimePickerComponent
                format="dd/MM/yy hh:mm a"
                id="EndTime"
                data-name="EndTime"
                value={new Date(props.endTime || props.EndTime)}
                className="e-field"
                change={handleEndTimeChange}
              />
            </td>
          </tr>

          <tr>
            <td className="e-textlabel">Description</td>
            <td colSpan={4}>
              <textarea
                id="Description"
                className="e-field e-input"
                name="Description"
                rows={3}
                cols={50}
                style={{
                  width: '100%',
                  height: '60px !important',
                  resize: 'vertical',
                }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel mb-2">Meeting with</td>
            <td colSpan={4}>
              <input
                id="MeetingWith"
                className="e-field e-input"
                type="text"
                name="MeetingWith"
                style={{ width: '100%' }}
                value={meetingWith}
                onChange={(e) => setMeetingWith(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Feedback</td>
            <td colSpan={4}>
              <DropDownListComponent
                id="Feedback"
                placeholder="-- Choose Feedback --"
                data-name="Feedback"
                className="e-field"
                style={{ width: "100%" }}
                dataSource={["Positive", "Negative", "Rescheduled"]}
                value={props.Feedback || null}
              />
            </td>
          </tr>
        </tbody>
      </table>
    ) : (
      <div />
    );
  }


  function onEventRendered(args) {
    // if (args.data.Status == "") {
    //   args.element.style.backgroundColor = "#357cd2";
    // } else if (args.data.Status == "Open") {
    //   args.element.style.backgroundColor = "#008000";
    // } else if (args.data.Status == "Rejected" && args.data.Feedback == "Positive") {
    //   args.element.style.backgroundColor = "#A9A9A9";
    // } else if (args.data.Status == "Rejected" && args.data.Feedback == "Negative") {
    //   args.element.style.backgroundColor = "#909090";
    // } else if (args.data.Status == "Rejected" && args.data.Feedback == "Rescheduled") {
    //   args.element.style.backgroundColor = "#a0ab2d";
    // } else if (args.data.Status == "Rejected") {
    //   args.element.style.backgroundColor = "#A0A0A0";
    // } else if (args.data.Status == "Done" && args.data.Feedback == "Positive") {
    //   args.element.style.backgroundColor = " rgb(26, 170, 85)";
    // } else if (args.data.Status == "Done" && args.data.Feedback == "Negative") {
    //   args.element.style.backgroundColor = "#FF6666";
    // } else if (args.data.Status == "Done" && args.data.Feedback == "Rescheduled") {
    //   args.element.style.backgroundColor = "#c6d33e";
    // } else if (args.data.Status == "Done") {
    //   args.element.style.backgroundColor = "#1ABC9C";
    // } else {
    //   return
    // }

    // if (args.data.Status == "") {
    //   args.element.style.backgroundColor = "#357cd280";
    //   args.element.style.borderColor = "#357cd2";
    // } else if (args.data.Status == "Open") {
    //   args.element.style.backgroundColor = "#324cff80";
    //   args.element.style.borderColor = "#64a3ff";
    // } else if (args.data.Status == "Rejected") {
    //   args.element.style.backgroundColor = "#A0A0A080";
    //   args.element.style.borderColor = "#A0A0A0";
    // } else if (args.data.Status == "Done") {
    //   args.element.style.backgroundColor = "#1ABC9C80";
    //   args.element.style.borderColor = "#1ABC9C";
    // } 

    if (args.data.Status == "") {
      args.element.style.backgroundColor = "#357cd280";
      args.element.style.borderColor = "#357cd2";
    } else if (args.data.Status == "Open") {
      args.element.style.backgroundColor = "#001eefab";
      args.element.style.borderColor = "#001eef";
    } else if (args.data.Status == "Rejected") {
      args.element.style.backgroundColor = "#A0A0A080";
      args.element.style.borderColor = "#A0A0A0";
    } else if (args.data.Status == "Done") {
      args.element.style.backgroundColor = "#1ABC9C80";
      args.element.style.borderColor = "#1ABC9C";
    }
  }

  const tooltipTemplate = (props) => {
    return (<div className="tooltip-wrap">
      <div className="content-area">
        <table>
          <tr>
            <td>Lead Name</td>
            <td>: {props._leadName}</td>
          </tr>
          {props._assignData &&
            <>
              <tr><td>&nbsp;</td></tr>
              <tr>
                <td>Assigned To</td>
                <td>: {props._assignData.assignedToName}</td>
              </tr>
              <tr>
                <td>Assigned To</td>
                <td>: {props._assignData.assignedTo2Name}</td>
              </tr>
              <tr>
                <td>Sales Manger</td>
                <td>: {props._assignData.salesMangerName}</td>
              </tr>
            </>
          }
          <tr><td>&nbsp;</td></tr>
          <tr>
            <td>Subject</td>
            <td>: {props.Subject}</td>
          </tr>
          <tr><td>&nbsp;</td></tr>
          <tr>
            <td>From</td>
            <td>: {props.StartTime.toLocaleString()}</td>
          </tr>
          <tr>
            <td>To</td>
            <td>: {props.EndTime.toLocaleString()}</td>
          </tr>
          <tr><td>&nbsp;</td></tr>
          <tr>
            <td>Status</td>
            <td>: {props.Status}</td>
          </tr>
          <tr>
            <td>Feedback</td>
            <td>: {props.Feedback}</td>
          </tr>
        </table>
      </div></div>);
  }

  return (
    <>
      <div className="filter-bar p-3">
        {isAdmin &&
          <LocationFilter
            locations={locationOptions}
            locationFilterSelector={selectLocationFilter}
            onLocationChanged={(e, v, locations) => { onScheduleLocationChanged(e, v, locations); setIsFiltered(true); }} />
        }
        <div className="float-end">
          {isFiltered &&
            <button type="button" className="c-btn dark-btn position-relative" onClick={(e) => onFilterClicked(e)}>
              <i className="fa fa-filter me-2" aria-hidden="true"></i> Filter
            </button>
          }
        </div>
      </div>

      {(schedulesRequestStatus === fetchStatus.LOADING
      ) && <div className="app-container-loader">
          <GridLoader />
        </div>}

      <div className="px-3 pt-3">
        <div className="default-root-container detail-container">
          <ScheduleComponent
            id='schedule'
            height='700px'
            currentView='Month'
            cssClass='excel-export'
            selectedDate={new Date()}
            eventSettings={{ dataSource: data, enableTooltip: true, tooltipTemplate: tooltipTemplate.bind(this), allowAdding: false, allowDeleting: true }}
            actionComplete={onActionComplete.bind(this)}
            editorTemplate={editorTemplate.bind(this)}
            ref={(t) => (scheduleObj = t)}
            eventRendered={onEventRendered.bind(this)}
          >
            <Inject services={[Day, Week, WorkWeek, Month, Year, Agenda, ExcelExport, Resize, Print]} />
          </ScheduleComponent>
        </div>
      </div>
    </>
  )
}

export default Schedule;