import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../../../api/client';
import GridLoader from '../../../../component/Controls/loaders/gridLoader';
import { createNewStatus, getAllStatuses, getStatusById, removeStatus, selectAllStatuses, selectCreationStatus, selectModificationStatus, selectRemovalStatus, selectStatus, showStatusFlyout, updateSingleData, updateStatus } from '../../reducerSlices/statusesSlice';
import ReactPlaceholder from 'react-placeholder';
import { Filter, GridComponent, Inject, Page, Sort, Resize, Toolbar, CommandColumn, valueAccessor } from '@syncfusion/ej2-react-grids';
import ReactTooltip from 'react-tooltip';
import { messages } from '../../../../common/actionMessages';
import { show } from '../../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import { applicantStatuses, visibilityStatuses } from '../../../shared/services/masterData';

function Statuses() {
    const whereClause = {
        name: ""
    };
    const dispatch = useDispatch();

    const [dummyCards, setDummyCards] = useState([]);

    const statusCreationStatus = useSelector(selectCreationStatus);
    const statusModificationStatus = useSelector(selectModificationStatus);
    const statusesRemovalStatus = useSelector(selectRemovalStatus);

    const statuses = useSelector(selectAllStatuses);

    const statusesRequestStatus = useSelector(selectStatus);


    useEffect(() => {
        if (statusesRequestStatus === fetchStatus.IDLE) {
            _loadStatuses(0, 0, 10, whereClause);
        }
    }, [statusesRequestStatus, dispatch]);

    let _dummyCards = [];

    useEffect(() => {
        if (statusesRequestStatus === fetchStatus.SUCCEEDED) {
            dispatch(updateStatus());
        }
    }, [statusesRequestStatus]);

    useEffect(() => {
        if (statusesRequestStatus === fetchStatus.LOADING) {
            for (let i = 0; i < 10; i++) {
                _dummyCards.push(
                    <div className="list-loading-content">
                        <ReactPlaceholder type='text' ready={false} rows={2} color='#E0E0E0'>
                        </ReactPlaceholder>
                    </div>
                );
            }
            setDummyCards(_dummyCards);
        }
    }, [statusesRequestStatus]);

    useEffect(() => {
        if (statusesRemovalStatus === fetchStatus.SUCCEEDED) {
            _loadStatuses(0, 0, 10, whereClause);
            if (statuses && statuses.Items && statuses.Items.length > 0) {
                let item = statuses.Get(0);
                let _statuses = item ? item.records : [];
                if (_statuses && Array.isArray(_statuses) && _statuses.length > 0) {
                    let _status = _statuses[0];
                    if (_status && _status.id) {
                        _loadStatus(_status.id, 0);
                    }
                }
                else {
                    dispatch(updateSingleData(undefined));
                }
            }
            else {
                dispatch(updateSingleData(undefined));
            }
        }
    }, [statusesRemovalStatus]);

    const _loadStatus = async (id, page) => {
        await dispatch(getStatusById({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETSTATUSBYID",
                payload: {
                    id: id,
                    data: ""
                }
            }
        }));
    }

    // const _loadForm = async () => {
    //     await dispatch(getClientForm({
    //         client: tenantId,
    //         serviceType: "TENANTADMIN",
    //         operation: "GETFORMBYTYPE",
    //         payload: {
    //             id: "",
    //             data: "ClientForm"
    //         }
    //     }));
    // }

    const _loadStatuses = async (page, offset, limit, whereClause) => {
        await dispatch(getAllStatuses({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETSTATUSES",
                payload: {
                    id: "",
                    data: JSON.stringify({
                        offset: offset,
                        limit: 1000,
                        where: whereClause
                    })
                }
            }
        }));
    }

    const onStatusSelected = (e, id) => {
        e.preventDefault();
        _loadStatus(id, undefined);
        dispatch(showStatusFlyout({
            createMode: false,
            editMode: true
        }));
    };

    const onDeleteClicked = (e, id) => {
        e.preventDefault();
        if (id) {
            dispatch(show({
                id: id,
                title: messages.confirmAlert.title,
                content: messages.confirmAlert.content.removal('Status'),
                confirmedCallback: _removeStatus
            }));
        }
    };

    const onAddRecordClicked = (e) => {
        dispatch(createNewStatus());

        dispatch(showStatusFlyout({
            createMode: true,
            editMode: false
        }));
    };

    const _removeStatus = async (id) => {
        await dispatch(removeStatus({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "DELETESTATUS",
            payload: {
                id: id,
                data: ""
            }
        }))
    };

    useEffect(() => {
        if (statusCreationStatus === fetchStatus.SUCCEEDED) {
            _loadStatuses(0, 0, 10, whereClause);
        }
    }, [statusCreationStatus]);

    useEffect(() => {
        if (statusModificationStatus === fetchStatus.SUCCEEDED) {
            _loadStatuses(0, 0, 10, whereClause);
        }
    }, [statusModificationStatus]);

    const _geStatusText = (statuses, id) => {
        if (isObjectArray(statuses)) {
            let _statuse = statuses.find(l => l.id === id);
            if (_statuse && _statuse.StatusName) {
                return _statuse.StatusName;
            }
        }
        return "";
    }

    const _geStatusText2 = (statuses, id) => {
        if (isObjectArray(statuses)) {
            let _statuse = statuses.find(l => l.value === id);
            if (_statuse && _statuse.text) {
                return _statuse.text;
            }
        }
        return "";
    }


    const getStatus = (field, data, column) => {
        return _geStatusText2(applicantStatuses, data["PrimaryStatus"]);
    }

    const _getVisibilityStatusText = (statuses, id) => {
        if (isObjectArray(statuses)) {
            let _statuse = statuses.find(l => l.value === id);
            if (_statuse && _statuse.text) {
                return _statuse.text;
            }
        }
        return "";
    }

    const getVisibilityStatus = (field, data, column) => {
        return _getVisibilityStatusText(visibilityStatuses, data["Status"]);
    }

    const addActionTemplate = (props) => {
        return <>
            <i className='fa fa-pencil' data-tip='Edit' title='Edit' onClick={(e) => onStatusSelected(e, props.id)}></i>
            {props.isDefaultStatusName
                ?
                <></>
                :
                <i className='fa fa-trash ms-3' data-tip='Delete' title='Delete' onClick={(e) => onDeleteClicked(e, props ? props.id : "")}></i>
            }
            <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
        </>
    }
    const gridRef = useRef(null);
    useEffect(() => {
        if (gridRef.current) {
            gridRef.current.refresh();
        }
    }, [statuses, statusesRequestStatus]);

    const columns = [
        { field: 'StatusName', headerText: 'Status Name', width: '100' },
        { field: 'PrimaryStatus', headerText: 'Primary Status', width: '100', valueAccessor: getStatus },
        { field: 'Status', headerText: 'Visibility', width: '100', valueAccessor: getVisibilityStatus },
        {
            headerText: 'Action',
            template: addActionTemplate,
            width: '50px',
        },
    ];

    return (
        <>
            {(statusesRequestStatus === fetchStatus.LOADING ||
                statusesRemovalStatus === fetchStatus.LOADING
            ) &&
                <div className="app-container-loader">
                    <GridLoader />
                </div>}
            <div className="row">
                <div className="col-md-6">
                    <span className="detail-container-title">Statuses</span>
                </div>
                <div className="col-md-6 text-end">
                    <button className="c-btn dark-btn" type="button" data-tip='Create new statuses' onClick={(e) => onAddRecordClicked(e)}>
                        <i className="fa fa-plus"></i>
                    </button>
                </div>
            </div>
            <div className="row p-3">
                <GridComponent ref={gridRef} dataSource={statuses} allowPaging={true} allowSorting={true} allowResizing={true} width="100%" toolbar={['Search']} pageSettings={{ pageSize: 10, pageCount: 5 }} rowHeight={50} columns={columns} >
                    <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                </GridComponent>
            </div>
        </>
    )
}

export default Statuses