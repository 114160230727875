import * as React from 'react';

function Radio(props) {
    return (
        // <div className="custom-radio">
        //     <input type="radio" name={props.name} id={props.id} value={props.value} onChange={(e) => props.onChange && props.onChange(e, props.value)} checked={props.checked} />
        //     <label className='ps-2' htmlFor={props.id}>{props.label}</label>
        // </div>
        <div className="form-check me-3 d-inline-flex">
            <input className="form-check-input" type="radio" name={props.name} id={props.id} value={props.value} onChange={(e) => props.onChange && props.onChange(e, props.value)} checked={props.checked} />
            <label className="form-check-label ps-2" htmlFor={props.id}>
                {props.label}
            </label>
        </div>
    );
}

export default Radio;