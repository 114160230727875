import React from 'react';
import { useSelector } from 'react-redux';
import './progress.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaCheck, FaRegIdBadge, FaUserFriends, FaBriefcase, FaUserGraduate } from 'react-icons/fa';
import { accountService } from '../../features/accounts/services/accountService';


const Progress = (props) => {
  let myState = useSelector((state) => state.accounts);

  const user = accountService.getAuthenticatedUser();
  let name = user && user.firstName ? user.firstName : "";

  let personalCompleted = (myState.currentStep === 'References' || myState.currentStep === 'Employment' || myState.currentStep === 'Education') ? true : false;
  let referencesCompleted = (myState.currentStep === 'Employment' || myState.currentStep === 'Education') ? true : false;
  let employmentCompleted = (myState.currentStep === 'Education') ? true : false;
  let educationCompleted = false;

  const percentage = props.percentage ? props.percentage : '0';


  return (
    <>
      <div className="card-body">
        <div className="row p-md-2">
          <div className="col-md-12 text-center">
            <div className="animate__animated animate__swing">
              <div className="stepper-item-sub-heading">Welcome!</div>
            </div>
            <div className="animate__animated animate__bounceIn text-white">
              <div className="stepper-item-heading">{name ? name : ''}</div>
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-center mt-5 d-none d-md-flex ">
            <div style={{ width: 200, height: 200 }}>
              <CircularProgressbar
                background={true}
                value={percentage}
                text={percentage + `%`}
                className="<div className='animate__animated animate__fadeIn'>25</div>"
                styles={buildStyles({
                  pathColor: '#F02469',
                  textColor: '#0A253E',
                  trailColor: '#E6EAF3',
                  backgroundColor: '#FFF'
                })}
              />
            </div>
          </div>
          <div className="col-md-12 text-center mt-5 d-none d-md-block">
            <div className="animate__animated animate__zoomI text-white">
              <div className="stepper-item-details">
                {props.subTitle}
              </div>
            </div>
            {/* <p className="animate__animated animate__zoomIn text-white">complete and Get <span className='text-success'>+10%</span></p> */}
          </div>
        </div>
      </div>
      <div className="card-footer mb-md-5">
        <div className="stepper-wrapper">
          <div className={`stepper-item ${myState.currentStep === 'Personal' ? 'active' : personalCompleted && 'completed'}`}>
            <div className="step-counter">{personalCompleted ? <FaCheck /> : <FaRegIdBadge />}</div>
            <div className="step-name">Personal</div>
          </div>
          <div className={`stepper-item ${myState.currentStep === 'References' ? 'active' : referencesCompleted && 'completed'}`}>
            <div className="step-counter">{referencesCompleted ? <FaCheck /> : <FaUserFriends />}</div>
            <div className="step-name">References</div>
          </div>
          <div className={`stepper-item ${myState.currentStep === 'Employment' ? 'active' : employmentCompleted && 'completed'}`}>
            <div className="step-counter">{employmentCompleted ? <FaCheck /> : <FaBriefcase />}</div>
            <div className="step-name">Employment</div>
          </div>
          <div className={`stepper-item ${myState.currentStep === 'Education' ? 'active' : educationCompleted && 'completed'}`}>
            <div className="step-counter">{educationCompleted ? <FaCheck /> : <FaUserGraduate />}</div>
            <div className="step-name">Education</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Progress;