import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchStatus, tenantId } from '../../../../api/client';
import { _getLocationText } from '../../../../common/common';
import Avatar from '../../../../component/Avatar/Avatar';
import {
  selectDefaultFilter,
  selectLocationFilter,
  selectRefreshData,
  selectRoleFilter,
  selectClientStatusFilter,
  selectWhereClause,
  selectWhereClauseChanged,
  updateFilter,
  updateClientFilter,
  updateClientRefreshData,
  updateNameFilter
} from '../../../../component/Controls/filters/reducerSlices/clientFilterSlice';
import Collection from '../../../../component/Controls/paginators/collection';
import PageContainer from '../../../../component/Controls/paginators/paginator/pageContainer';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from '../../../locations/reducerSlices/locationSlice';
import { userStatuses } from '../../../shared/services/masterData';
import {
  getAllClients,
  updateIsFiltered,
  selectStatus,
  selectCurrentPage,
  selectTotalItems,
  selectAllClients,
  updateStatus,
  selectFirstRecord,
  loadSingleData,
  getClientById,
  updateCurrentPage,
  selectClientById,
  updateClient,
  selectModificationStatus,
  selectRemovalStatus,
  selectSingleStatus,
  selectSingleRefreshed,
  getClientForm,
  selectClientFormStatus,
  selectClientForm,
  updateSingleData,
  createNewClient,
  updateClientFilters,
  removeClient,
  createClient,
  selectCreationStatus,
  showClientFlyout
} from '../../reducerSlices/clientSlice';
import clientLogo from '../../../../assets/images/clipart/client-logo.svg';
import { show } from '../../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';
import { messages } from '../../../../common/actionMessages';

import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';
import ReactTooltip from 'react-tooltip';
import GridLoader from '../../../../component/Controls/loaders/gridLoader';
import { selectSaveClientImportStatus, showClientImportModal } from '../../../../component/Controls/modals/reducerSlices/clientImportModalPopUpSlice';

const Client = () => {
  const dispatch = useDispatch();

  const [statusVisibility, setStatusVisibility] = useState(false);

  const client = useSelector(selectClientById);
  const [dummyCards, setDummyCards] = useState([]);
  const currentPage = useSelector(selectCurrentPage);
  const refreshData = useSelector(selectRefreshData);

  const clientRequestStatus = useSelector(selectSingleStatus);
  const singleRefreshedStatus = useSelector(selectSingleRefreshed);

  const clientCreationStatus = useSelector(selectCreationStatus);
  const clientModificationStatus = useSelector(selectModificationStatus);
  const clientRemovalStatus = useSelector(selectRemovalStatus);

  const saveClientImportStatus = useSelector(selectSaveClientImportStatus); 

  const clients = useSelector(selectAllClients);
  const clientsRequestStatus = useSelector(selectStatus);

  const whereClause = useSelector(selectWhereClause);
  const whereClauseChanged = useSelector(selectWhereClauseChanged);
  const defaultFilter = useSelector(selectDefaultFilter);

  const locationOptions = useSelector(selectLocationOptions);
  const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
  const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);

  const [tempClientId, setTempClientId] = useState("");

  useEffect(() => {
    if (clientsRequestStatus === fetchStatus.IDLE) {
      _loadClients(0, 0, 10, whereClause);
    }
  }, [clientsRequestStatus, dispatch]);

  let clientCards;
  let _dummyCards = [];

  let _clientRecords = new Collection();

  useEffect(() => {
    if (clientsRequestStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateStatus());
    }
  }, [clientsRequestStatus, currentPage]);

  useEffect(() => {
    if (clientsRequestStatus === fetchStatus.LOADING) {
      for (let i = 0; i < 10; i++) {
        _dummyCards.push(
          <div className="list-loading-content">
            <ReactPlaceholder type='text' ready={false} rows={2} color='#E0E0E0'>
            </ReactPlaceholder>
          </div>
        );
      }
      setDummyCards(_dummyCards);
    }
  }, [clientsRequestStatus]);

  useEffect(() => {
    if (refreshData) {
      dispatch(updateIsFiltered());
      _loadClients(0, 0, 10, whereClause);
      dispatch(updateClientRefreshData(false));
    }
  }, [whereClauseChanged, whereClause, refreshData]);


  useEffect(() => {
    if (clientRemovalStatus === fetchStatus.SUCCEEDED) {
      _loadClients(0, 0, 10, whereClause);
      if (clients && clients.Items && clients.Items.length > 0) {
        let item = clients.Get(currentPage);
        let _clients = item ? item.records : [];
        if (_clients && Array.isArray(_clients) && _clients.length > 0) {
          let _client = _clients[0];
          if (_client && _client.id) {
            _loadClient(_client.id, currentPage);
          }
        }
        else {
          dispatch(updateSingleData(undefined));
        }
      }
      else {
        dispatch(updateSingleData(undefined));
      }
    }

  }, [clientRemovalStatus]);



  useEffect(() => {
    if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
      dispatch(getLocationOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLOCATIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({})
        }
      }));
    }
  }, [locationOptionsStatus, locationOptionsRefreshed]);

  let _locationOptions = undefined;

  if (isObjectArray(locationOptions)) {
    _locationOptions = locationOptions.map((o) => (
      <option value={o.value}>{o.text}</option>
    ));
  }

  const _loadClient = async (id, page) => {
    await dispatch(getClientById({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETCLIENTBYID",
        payload: {
          id: id,
          data: ""
        }
      }
    }));
  }

  const _loadForm = async () => {
    await dispatch(getClientForm({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETFORMBYTYPE",
      payload: {
        id: "",
        data: "ClientForm"
      }
    }));
  }

  const _loadClients = async (page, offset, limit, whereClause) => {
    await dispatch(getAllClients({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETCLIENTS",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: offset,
            limit: 1000,
            where: whereClause
          })
        }
      }
    }));

  }

  const onClientSelected = (e, id) => {
    e.preventDefault();
    _loadClient(id, undefined);

    dispatch(showClientFlyout({
      createMode: false,
      editMode: true
    }));
  };

  const onLoadItems = async (p) => {
    await _loadClients(p, p * 10, 10, whereClause);
  };

  const onDeleteClicked = (e, id) => {
    e.preventDefault();
    if (id) {
      dispatch(show({
        id: id,
        title: messages.confirmAlert.title,
        content: messages.confirmAlert.content.removal('Client'),
        confirmedCallback: _removeClient
      }));
    }
  };

  const onAddRecordClicked = (e) => {
    if (client && client.id) {
      setTempClientId(client.id);
    }
    dispatch(createNewClient());

    // dispatch(showClientFlyout());
    dispatch(showClientFlyout({
      createMode: true,
      editMode: false
    }));
  };

  const _removeClient = async (id) => {
    await dispatch(removeClient({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "DELETECLIENT",
      payload: {
        id: id,
        data: ""
      }
    }))
  };

  useEffect(() => {
    if (clientCreationStatus === fetchStatus.SUCCEEDED) {
      _loadClients(0, 0, 10, whereClause);
    }
  }, [clientCreationStatus]);

  useEffect(() => {
    if (clientModificationStatus === fetchStatus.SUCCEEDED) {
      _loadClients(0, 0, 10, whereClause);
    }
  }, [clientModificationStatus]);

  useEffect(() => {
    if (saveClientImportStatus === fetchStatus.SUCCEEDED || saveClientImportStatus === fetchStatus.FAILED) {
      _loadClients(0, 0, 10, whereClause);
    }
  }, [saveClientImportStatus]);

  const getLocation = (field, data, column) => {
    return _getLocationText(locationOptions, data["Location"]);
  }

  const addActionTemplate = (props) => {
    return <>
      <i className='fa fa-pencil' data-tip='Edit' title='Edit' onClick={(e) => onClientSelected(e, props.id)}></i>
      <i className='fa fa-trash ms-3' data-tip='Delete' title='Delete' onClick={(e) => onDeleteClicked(e, props ? props.id : "")}></i>
      <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
    </>
  }
  const gridRef = useRef(null);
  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  }, [clients, clientsRequestStatus]);

  const columns = [
    { field: 'ClientName', headerText: 'Client Name', width: '100' },
    { field: 'ClientNumber', headerText: 'Client Number', width: '100' },
    { field: 'Location', headerText: 'Location', width: '100', valueAccessor: getLocation },
    { field: 'Status', headerText: 'Status', width: '100' },
    {
      headerText: 'Action',
      template: addActionTemplate,
      width: '50px',
    },
  ];

  const onPeopleImportClicked = async (e) => {
    e.preventDefault();
    await dispatch(showClientImportModal({
      title: <><span>Import Client</span></>
    }));
  };


  return (
    <>
      {(clientsRequestStatus === fetchStatus.LOADING ||
        clientRemovalStatus === fetchStatus.LOADING ||
        locationOptionsStatus === fetchStatus.LOADING
      ) && <div className="app-container-loader">
          <GridLoader />
        </div>}

      <div className="row">
        <div className="col-md-6">
          <span className="detail-container-title">Clients</span>
        </div>
        <div className="col-md-6 text-end">
          <button type="button" className="c-btn dark-btn position-relative mx-3" onClick={(e) => onPeopleImportClicked(e)}>
            <i className="fa fa-upload me-2" aria-hidden="true"></i>Import
          </button>
          <button className="c-btn dark-btn" type="button" data-tip='Create new client' onClick={(e) => onAddRecordClicked(e)}>
            <i className="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div className="row p-3">
        <GridComponent ref={gridRef} dataSource={clients} allowPaging={true} allowSorting={true} allowResizing={true} width="100%" toolbar={['Search']} pageSettings={{ pageSize: 10, pageCount: 5 }} rowHeight={50} columns={columns} >
          <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
        </GridComponent>
      </div>
    </>
  )
}

export default Client;