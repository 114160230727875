import React from 'react';

import EllipsisLoader from './ellipsisLoader';

function Loader(props) {
    return (
        <EllipsisLoader />
    );
}

export default Loader;