import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _getLocationText } from '../../common/common';
import ShortText from '../Controls/custom/textbox/shortText';
import { isFormValid } from '../Controls/services/formService';
import { closePositionFlyout, createPosition, selectPositionById, selectPositionFlyoutCreateMode, selectPositionFlyoutEditmode, selectPositionFlyoutShow, selectCreationStatus, selectModificationStatus, updatePosition, updateSingleData } from '../../features/setting/reducerSlices/positionsSlice';
import { onValueChanged, sections } from '../../features/setting/services/positionSaver';
import { Operation } from '../../common/constants';
import { fetchStatus, tenantId } from '../../api/client';
import InputGroup from '../Controls/custom/inputGroup/inputGroup';
import ReactTooltip from 'react-tooltip';
import EllipsisLoader from '../Controls/loaders/ellipsisLoader';


function PositionFlyout() {

    const dispatch = useDispatch();
    const positionFlyoutShow = useSelector(selectPositionFlyoutShow);

    const POSITION_FORM = "Positionform";

    const [createMode, setCreatemode] = useState(false);
    const [editMode, setEditmode] = useState(false);
    const [isActive, setActive] = useState(false);
    const [cFInvalid, setCFInvalid] = useState(false);
    const [cfSubmitted, setCFSubmitted] = useState(false);

    const positionFlyoutCreateMode = useSelector(selectPositionFlyoutCreateMode);
    const positionFlyoutEditMode = useSelector(selectPositionFlyoutEditmode);
    // const positionFormStatus = useSelector(selectPositionFormStatus);
    // const positionForm = useSelector(selectPositionForm);
    const position = useSelector(selectPositionById);
    const positionCreationStatus = useSelector(selectCreationStatus);
    const positionModificationStatus = useSelector(selectModificationStatus);

    const ToggleClass = () => {
        setActive(!isActive);
        onCancelClicked();

        const timer = setTimeout(() => {
            dispatch(closePositionFlyout())
            setActive(false)
        }, 1500);
    };

    const onCancelClicked = (e) => {
        setCreatemode(false);
        setEditmode(false);
        setCFInvalid(false);
        setCFSubmitted(false);
    };

    useEffect(() => {
        setCreatemode(positionFlyoutCreateMode);
        setEditmode(positionFlyoutEditMode)
    }, [positionFlyoutCreateMode, positionFlyoutEditMode]);

    useEffect(() => {
        if (positionCreationStatus === fetchStatus.SUCCEEDED || positionModificationStatus === fetchStatus.SUCCEEDED) {
            ToggleClass()
        }
    }, [positionCreationStatus, positionModificationStatus]);

    const onValueUpdated = (e, section, value) => {
        let _positionObj = Object.assign({}, position);
        console.log('_positionObj ->', _positionObj)
        let _newPositionObj = onValueChanged(_positionObj, e, section, value);
        dispatch(updateSingleData({
            position: _newPositionObj,
            operation: Operation.UPDATED
        }));
    };

    useEffect(() => {
        const handleBackButton = (event) => {
            if (positionFlyoutShow) {
                dispatch(closePositionFlyout());
            }
        };
    
        window.addEventListener('popstate', handleBackButton);
    
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [positionFlyoutShow, dispatch]);


    const onSaveClicked = (e, id, created) => {
        e.preventDefault();
        if (isFormValid(POSITION_FORM)) {
            if (position) {
                if (created) {
                    dispatch(createPosition({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVEPOSITION",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                position: JSON.stringify(position),
                            })
                        }
                    }));
                }
                else {
                    dispatch(updatePosition({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVEPOSITION",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                position: JSON.stringify(position),
                            })
                        }
                    }));
                }
            }
            setCreatemode(false);
            setEditmode(false);
            setCFInvalid(false);
            setCFSubmitted(false);
        }
        else {
            setCFInvalid(true);
        }
        setCFSubmitted(true);
    };



    return (
        <>
            {(positionFlyoutShow) ? (<>
                <div className="flyout-wrapper position-fixed top-0 end-0">
                    <div className={`flyout-card ${isActive ? 'close' : 'open'}`}>
                        <div className="flyout-card-header">
                            <div className="float-end">
                                <div className="close-btn">
                                    <i className="fa fa-times" aria-hidden="true" data-tip='Close' onClick={ToggleClass}></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </div>
                            </div>
                            <div className="flyout-card-title">{position && position.id ? "Edit" : "Add"} Position Details</div>
                            <div className="flyout-card-sub-title">

                            </div>
                        </div>
                        <div className="flyout-card-body vh-100">


                            <div className="form-group p-0 pt-2">
                                <label id="lblPositionName" className="flyout-label" htmlFor="txtPositionName">Position Name</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{position && position.PositionName ? position.PositionName.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtPositionName"
                                                name="txtPositionName"
                                                ShortTextClasses="form-control"
                                                placeholder="Position Name"
                                                value={position && position.PositionName ? position.PositionName.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.POSITION_DETAILS, null)}
                                                // required={positionForm.PositionName.Required}
                                                required={true}
                                                // errorMessage={positionForm.PositionName.Validation}
                                                form={POSITION_FORM}
                                                submited={cfSubmitted}
                                                // disabled={editMode}
                                                 />
                                        }
                                    />
                                }
                            </div>
                        </div>
                        <div className="flyout-card-footer">
                            <div className="d-flex">
                                <div className="ms-auto">
                                    {positionCreationStatus === fetchStatus.LOADING || positionModificationStatus === fetchStatus.LOADING ? <EllipsisLoader /> :
                                        <>
                                            <button className="c-btn white-btn me-3" type="button" onClick={ToggleClass} >Cancel</button>
                                            <button className="c-btn dark-btn" type="button" onClick={(e) => onSaveClicked(e, position ? position.id : "", createMode)}>Save</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flyout-backdrop flyout-backdrop-show' />
            </>
            ) :
                ''
            }
        </>

    )
}

export default PositionFlyout;