import React from 'react';
import SideNavBar from '../SideNavBar/SideNavBar';
import TopNavBar from '../TopNavBar/TopNavBar';

function Layout(props) {
    return (
        <>
            <SideNavBar />
            <TopNavBar />
            <div className="my-container active-cont">
                <div className="app-container">
                    {props.children}
                </div>
            </div>
        </>
    );
}

export default Layout;