import { updateClientFilter, updateJobStatusFilter, updateLocationFilter, updatePositionFilter } from '../../../component/Controls/filters/reducerSlices/jobFilterSlice';
import store from '../../../store';

export const onJobLocationChanged = async (e, value, locations) => {
    await store.dispatch(updateLocationFilter({
        locations: locations,
        value: value
    }));
};

export const onJobPositionChanged = async (e, value, positions) => {
    await store.dispatch(updatePositionFilter({
        positions: positions,
        value: value
    }));
};

export const onJobClientChanged = async (e, value, clients) => {
    await store.dispatch(updateClientFilter({
        clients: clients,
        value: value
    }));
};

export const onJobStatusChanged = async (e, value, statuses) => {
    await store.dispatch(updateJobStatusFilter({
        statuses: statuses,
        value: value
    }));
};