import * as React from 'react';

function YesNoRadio(props) {
    return (
        // <div className="form-group d-flex">
        //     <div className="custom-radio">
        //         <input disabled={props.disabled} type="radio" name={props.name} id={props.name + "Yes"} value="Y" onChange={(e) => props.onSelectionChanged ? props.onSelectionChanged(e, 'Y') : null} checked={props.value === 'Y'} />
        //         <label className='ps-2' htmlFor={props.name + "Yes"}>{props.yesText ? props.yesText : "Yes"}</label>
        //     </div>
        //     <div className="custom-radio ps-4">
        //         <input disabled={props.disabled} type="radio" name={props.name} id={props.name + "No"} value="N" onChange={(e) => props.onSelectionChanged ? props.onSelectionChanged(e, 'N') : null} checked={props.value === 'N' || props.value === ''} />
        //         <label className='ps-2' htmlFor={props.name + "No"}>{props.noText ? props.noText : "No"}</label>
        //     </div>
        // </div>

        // <div className="form-group d-flex">
        //     <div className="form-check">
        //         <input disabled={props.disabled} className="form-check-input" type="radio" name={props.name} id={props.name + "Yes"} value="Y" onChange={(e) => props.onSelectionChanged ? props.onSelectionChanged(e, 'Y') : null} checked={props.value === 'Y'} />
        //         <label className="form-check-label" htmlFor={props.name + "Yes"}>
        //             {props.yesText ? props.yesText : "Yes"}
        //         </label>
        //     </div>
        //     <div className="form-check ps-5">
        //         <input className="form-check-input" type="radio" name={props.name} id={props.name + "No"} value="N" onChange={(e) => props.onSelectionChanged ? props.onSelectionChanged(e, 'N') : null} checked={props.value === 'N' || props.value === ''} />
        //         <label className="form-check-label" htmlFor={props.name + "No"}>
        //             {props.noText ? props.noText : "No"}
        //         </label>
        //     </div>
        // </div>

        // <div className="form-check form-switch" >
        <div className={`form-check form-switch ${props.YesNoRadioClasses ? props.YesNoRadioClasses : ''}`} >
            {/* <input className="form-check-input" type="checkbox" name={props.name} id={props.name} onChange={(e) => props.onSelectionChanged ? props.onSelectionChanged(e, 'Y') : null} disabled={props.disabled}/> */}
            {/* <input className="form-check-input" type="checkbox" name={props.name} id={props.name} onClick={(e) => props.onSelectionChanged} disabled={props.disabled} checked={props.value === 'Y'}/> */}
            <input className="form-check-input" type="checkbox" name={props.name} id={props.name} onClick={(e) => props.onSelectionChanged(e, props.value === 'Y' ? 'N':'Y') } disabled={props.disabled} checked={props.value === 'Y'}/>
            <label className="form-check-label ps-1" htmlFor={props.name}>{ props.value === 'Y' ? 'Yes':'No'}</label>
        </div>
    );
}

export default YesNoRadio;