import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckBox from '../../Controls/custom/checkers/checkbox';


function ProfileProcessStatusFilter(props) {

    const profileProcessStatusFilter = useSelector(props.profileProcessStatusFilterSelector);

    const [open, setOpen] = useState(false);

    const profileProcessStatusContainerRef = useRef(null);

    useEffect(() => {
        if (open && profileProcessStatusContainerRef && profileProcessStatusContainerRef.current) {
            profileProcessStatusContainerRef.current.focus();
        }
    });

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Profile Process</span> :
                <span className="filter-value">{profileProcessStatusFilter ? "Yes" : "No"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={profileProcessStatusContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    <li className="filter-list-item"
                        onClick={(e) => { props.onProfileProcessStatusChanged && props.onProfileProcessStatusChanged(e, !profileProcessStatusFilter) }}>
                        <CheckBox
                            id={`ckbProfileProcessStatusFilter`}
                            size="Small"
                            checked={profileProcessStatusFilter} />
                        <span className={`ms-3 ${profileProcessStatusFilter ? "filter-selected" : ""}`}>Yes</span>

                    </li>
                </ul>
            </div> : <></>}
        </div>
    );
}

export default ProfileProcessStatusFilter;