import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CheckBox from '../../Controls/custom/checkers/checkbox';

function LeadStatusFilter(props) {
    const [open, setOpen] = useState(false);

    const leadStatusFilter = useSelector(props.leadStatusFilterSelector);

    const gfContainerRef = useRef(null);

    let _leadStatuses = undefined;
    _leadStatuses = [
        {
            text: "COI",
            value: "COI"
        },
        {
            text: "Completed",
            value: "Completed"
        },
        {
            text: "Credit Check",
            value: "Credit Check"
        },
        {
            text: "In Progress",
            value: "In Progress"
        },
        {
            text: "New",
            value: "New"
        },
        {
            text: "Proposal",
            value: "Proposal"
        },
        {
            text: "Rejected",
            value: "Rejected"
        },
        {
            text: "SA",
            value: "SA"
        },
        {
            text: "Safety Check",
            value: "Safety Check"
        }
    ];

    let leadStatusesList;
    let isLeadStatusesSelected = (leadStatusFilter.value && Array.isArray(leadStatusFilter.value) && leadStatusFilter.value.length > 0);
    if (_leadStatuses && Array.isArray(_leadStatuses) && _leadStatuses.length > 0) {
        leadStatusesList = _leadStatuses.map((leadStatus, index) => (
            <li key={leadStatus.value} className="filter-list-item"
                onClick={(e) => props.onLeadStatusChanged && props.onLeadStatusChanged(e, leadStatus.value, _leadStatuses)}>
                <CheckBox
                    id={`ckbLeadStatus-${leadStatus.value}`}
                    size="Small"
                    checked={isLeadStatusesSelected ? leadStatusFilter.value.includes(leadStatus.value) : false} />
                <span className={`ms-3 ${isLeadStatusesSelected && leadStatusFilter.value.includes(leadStatus.value) ? "filter-selected" : ""}`}>{leadStatus.text}</span>

            </li>
        ));
    }

    useEffect(() => {
        if (open && gfContainerRef && gfContainerRef.current) {
            gfContainerRef.current.focus();
        }
    });

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Lead Status</span> :
                <span className="filter-value">{leadStatusFilter.value ? leadStatusFilter.text : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={gfContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    {leadStatusesList}
                </ul>
            </div> : <></>}
        </div>
    );
}

export default LeadStatusFilter;