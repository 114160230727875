import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addCurrentStep, selectLanguage, selectReferencesError, selectReferencesStatus, updateLoginStatus } from '../reducerSlices/accountsSlice';
import Progress from '../../../component/Progress/Progress';
import '../css/accounts.css';
import BackButton from './../../../component/Controls/buttons/backButton';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import ContinueButton from './../../../component/Controls/buttons/continueButton';
import { getApplicantById, getRegisteredApplicantById, selectApplicantById, selectRegisteredApplicantById, selectRegisteredApplicantModificationStatus, selectRegisteredApplicantStatus, updateRegisteredApplicant, updateRegisteredApplicantData, updateRegisteredModificationStatus } from '../../applicant/reducerSlices/applicantSlice';
import { useEffect } from 'react';
import { Language, USER_KEY } from '../../../common/constants';
import { fetchStatus, tenantId } from '../../../api/client';
import { accountService } from '../services/accountService';
import { isFormValid } from '../../../component/Controls/services/formService';
import { handlerError } from '../../../common/common';
import YesNoRadio from '../../../component/Controls/custom/checkers/yesNoRadio';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup';
import ShortText from '../../../component/Controls/custom/textbox/shortText';
import PhoneText from '../../../component/Controls/custom/textbox/phoneText';
import { applicantDetailsEN } from '../../applicant/services/applicantDetails';
import { onValueChanged, sections } from '../../applicant/services/applicantSaver';
import MultiSelector from '../../../component/Controls/custom/selectors/multiSelector';
import DateText from '../../../component/Controls/custom/textbox/dateText';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';
import { getPositionOptions, selectPositionOptions, selectPositionOptionsStatus } from '../../setting/reducerSlices/positionsSlice';


const Employment_Details_1 = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(addCurrentStep('Employment'));

  const APPLICANT_DETAIL_FORM = "AppilcantDetailForm";
  const [adfSubmitted, setADFSubmitted] = useState(false);
  const [aPFInvalid, setAPFInvalid] = useState(false);

  const applicantRequestStatus = useSelector(selectRegisteredApplicantStatus);
  const applicantModificationStatus = useSelector(selectRegisteredApplicantModificationStatus);
  const applicant = useSelector(selectRegisteredApplicantById);

  const [applicantDetails, setApplicantDetails] = useState(applicantDetailsEN);
  const language = useSelector(selectLanguage);

  const [isAnotherEmpName, setIsAnotherEmpName] = useState(false);
  const [isEverTerminated, setIsEverTerminated] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);
  const [showExperience2, setShowExperience2] = useState(false);
  const [showExperience3, setShowExperience3] = useState(false);

  const positionOptions = useSelector(selectPositionOptions);
  const positionOptionsStatus = useSelector(selectPositionOptionsStatus);

  useEffect(() => {
    if (positionOptionsStatus === fetchStatus.IDLE) {
      dispatch(getPositionOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETPOSITIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [positionOptionsStatus]);

  const _loadApplicant = async (id) => {
    await dispatch(getRegisteredApplicantById({
      client: tenantId,
      serviceType: "TENANTUSER",
      operation: "GETREGISTEREDAPPLICANT",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  useEffect(() => {
    if (applicantRequestStatus === fetchStatus.IDLE) {
      const userId = accountService.getLoggedInUserId(USER_KEY);
      _loadApplicant(userId);
    }

    if (applicantRequestStatus === fetchStatus.SUCCEEDED) {
      // isExperience2DetailsAdded() ? setShowExperience2(true) : setShowExperience2(false);
      // isExperience3DetailsAdded() ? setShowExperience3(true) : setShowExperience3(false);

      setShowExperience2(isExperience2DetailsAdded());
      setShowExperience3(isExperience3DetailsAdded());
    }
  }, [applicantRequestStatus]);

  useEffect(() => {
    if (applicant) {
      setIsAnotherEmpName(applicant.IsOtherEmploymentName.Data === "Y");
      setIsEverTerminated(applicant.EverTerminated.Data === "Y");
    }
  }, [applicant]);

  const onNextClicked = (e) => {
    setAPFInvalid(false);
    if (isFormValid(APPLICANT_DETAIL_FORM)) {
      if (applicant) {
        const userId = accountService.getLoggedInUserId(USER_KEY);
        dispatch(updateRegisteredApplicant({
          client: tenantId,
          serviceType: "TENANTUSER",
          operation: "SUBMITAPPLICANT",
          payload: {
            id: userId,
            data: JSON.stringify({
              applicant: JSON.stringify(applicant),
              photo: {
                contentType: "image/jpg",
                length: 0,
                photoData: ""
              }
            })
          }
        }));
      }
      setADFSubmitted(false);
    }
    else {
      setAPFInvalid(true);
      setADFSubmitted(true);
    }
  };

  const onBack = (e) => {
    history.push('/references');
  }

  useEffect(() => {
    if (applicantModificationStatus === fetchStatus.SUCCEEDED) {
      if (accountService.isAuthenticated()) {
        dispatch(updateLoginStatus());
        dispatch(updateRegisteredModificationStatus());
        history.push("/education_details");
      }
    }
  }, [applicantModificationStatus, dispatch, history]);

  const onValueUpdated = (e, section, value) => {
    let _applicantObj = Object.assign({}, applicant);
    let _newApplicantObj = onValueChanged(_applicantObj, e, section, value);
    dispatch(updateRegisteredApplicantData(_newApplicantObj));
  };

  const _isHavingEmployerDetails = () => {
    return applicant && applicant.Sections && applicant.Sections[3] && applicant.Sections[3].HavingEmployerDetails;
  }

  const _isCurrentlyEmployed = () => {
    return applicant && applicant.CurrentlyEmployed.Data === 'Y';
  }

  const isExperience2DetailsAdded = () => {
    return applicant && applicant.Employer2 && applicant.Employer2.Data !== '';
  }

  const isExperience3DetailsAdded = () => {
    return applicant && applicant.Employer3 && applicant.Employer3.Data !== '';
  }

  const onTabSelected = (e, v) => {
    setSelectedTab(v);
  };

  const onAddMore2 = (e) => {
    setShowExperience2(true);
    setSelectedTab(1);
  };

  const onAddMore3 = (e) => {
    setShowExperience3(true);
    setSelectedTab(2);
  };



  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-8 card card-left d-flex align-items-stretch p-0">
            <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn">
              <div className="row p-2">
                <div className="col-md-12">
                  <div className="card-left-header">Work History</div>
                  <div className="card-left-sub-header">Please enter your previous employment details.</div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-12">
                  <label className="field-label">Do you have employment history?</label>
                  <YesNoRadio
                    name="rdbtnEMYD"
                    yesText={applicantDetails ? applicantDetails.Yes : ""}
                    noText={applicantDetails ? applicantDetails.No : ""}
                    onSelectionChanged={(e, value) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, value)}
                    value={applicant && applicant.Sections[3].HavingEmployerDetails ? (applicant.Sections[3].HavingEmployerDetails ? "Y" : "N") : ""} />
                </div>
              </div>
              {_isHavingEmployerDetails() ?
                <>
                  <div className="row p-2">
                    <div className="col-md-12">
                      {/* tab menu */}
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className={`nav-link nav-link-people ${selectedTab === 0 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 0)} id="exp-tab-1" data-bs-toggle="tab" data-bs-target="#expTab1" type="button" role="tab" aria-controls="expTab1" aria-selected="true">Experience</button>
                        </li>
                        {showExperience2 &&
                          <li className="nav-item" role="presentation">
                            <button className={`nav-link nav-link-people ${selectedTab === 1 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 1)} id="exp-tab-2" data-bs-toggle="tab" data-bs-target="#expTab2" type="button" role="tab" aria-controls="expTab2" aria-selected="false">Experience 2</button>
                          </li>
                        }
                        {showExperience3 &&
                          <li className="nav-item" role="presentation">
                            <button className={`nav-link nav-link-people ${selectedTab === 2 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 2)} id="exp-tab-3" data-bs-toggle="tab" data-bs-target="#expTab3" type="button" role="tab" aria-controls="expTab3" aria-selected="false">Experience 3</button>
                          </li>
                        }
                        {!showExperience2 &&
                          <li className="nav-item" role="presentation">
                            <button className={`nav-link nav-link-people`} onClick={(e) => onAddMore2(e)} id="add-exp-tab-2" type="button" aria-selected="false">+ Add More</button>
                          </li>
                        }
                        {(showExperience2 && !showExperience3) &&
                          <li className="nav-item" role="presentation">
                            <button className={`nav-link nav-link-people`} onClick={(e) => onAddMore3(e)} id="add-exp-tab-3" type="button" aria-selected="false">+ Add More</button>
                          </li>
                        }
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div className={`tab-pane fade ${selectedTab === 0 && "show active"}`} id="expTab1" role="tabpanel" aria-labelledby="exp-tab-1">
                          <div className="card a-card-wrap a-card-wrap-register pt-3">
                            <div className="row p-2">
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblEmployer1" className="field-label" htmlFor="txtEmployer1">{applicantDetails ? applicantDetails.Employer : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-industry"></i>}
                                    control={
                                      <ShortText
                                        required={applicant && applicant.Employer1.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.EmployerError : applicant && applicant.Employer1.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtEmployer1"
                                        name="txtEmployer1"
                                        ShortTextClasses="form-control"
                                        value={applicant ? applicant.Employer1.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblEmployerAddress1" className="field-label" htmlFor="txtEmployerAddress1">{applicantDetails ? applicantDetails.EmployerAddress : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-map-marker"></i>}
                                    control={
                                      <ShortText
                                        required={applicant && applicant.EmployerAddress1.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.EmployerAddressError : applicant && applicant.EmployerAddress1.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtEmployerAddress1"
                                        name="txtEmployerAddress1"
                                        ShortTextClasses="form-control"
                                        value={applicant ? applicant.EmployerAddress1.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblEmployerPhone1" className="field-label" htmlFor="txtEmployerPhone1">{applicantDetails ? applicantDetails.EmployerPhone : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-phone"></i>}
                                    control={
                                      <PhoneText
                                        required={applicant && applicant.EmployerPhone1.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.EmployerPhoneError : applicant && applicant.EmployerPhone1.Validation}
                                        form={APPLICANT_DETAIL_FORM} submited={adfSubmitted}
                                        id="txtEmployerPhone1"
                                        name="txtEmployerPhone1"
                                        PhoneTextClasses="form-control"
                                        value={applicant ? applicant.EmployerPhone1.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblSupervisor1" className="field-label" htmlFor="txtSupervisor1">{applicantDetails ? applicantDetails.Supervisor : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-user-circle"></i>}
                                    control={
                                      <ShortText
                                        required={applicant && applicant.Supervisor1.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.SupervisorError : applicant && applicant.Supervisor1.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtSupervisor1"
                                        name="txtSupervisor1"
                                        ShortTextClasses="form-control"
                                        value={applicant ? applicant.Supervisor1.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblPosition1" className="field-label" htmlFor="drpPosition1">{applicantDetails ? applicantDetails.Position : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-id-badge"></i>}
                                    control={
                                      <MultiSelector
                                        id="drpPosition1"
                                        name="drpPosition1"
                                        value={applicant ? applicant.Position1.Data : []}
                                        options={positionOptions}
                                        onChange={(e, v) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v)}
                                        required={applicant && applicant.Position1.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.PositionError : applicant && applicant.Position1.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblEmployerTelePhone1" className="field-label" htmlFor="txtEmployerTelePhone1">{applicantDetails ? applicantDetails.EmployerTelePhone : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-fax"></i>}
                                    control={
                                      <PhoneText
                                        required={applicant && applicant.EmployerTelePhone1.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.EmployerTelePhoneError : applicant && applicant.EmployerTelePhone1.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtEmployerTelePhone1"
                                        name="txtEmployerTelePhone1"
                                        PhoneTextClasses="form-control"
                                        value={applicant ? applicant.EmployerTelePhone1.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>

                            </div>
                            <div className="row p-2">
                              <div className="col-md-4">
                                <label className="field-label">
                                  {applicantDetails ? applicantDetails.CurrentlyEmployed : ""}
                                </label>
                                <YesNoRadio
                                  name="rdbtnCEM"
                                  yesText={applicantDetails ? applicantDetails.Yes : ""}
                                  noText={applicantDetails ? applicantDetails.No : ""}
                                  value={applicant ? applicant.CurrentlyEmployed.Data : "N"}
                                  onSelectionChanged={(e, v) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v)} />
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblDOEFrom1" className="field-label" htmlFor="txtDOEFrom1">{applicantDetails ? applicantDetails.DOEFrom : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-calendar"></i>}
                                    control={
                                      <DateText
                                        required={applicant && applicant.DOEFrom1.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.DOEFromError : applicant && applicant.DOEFrom1.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtDOEFrom1"
                                        name="txtDOEFrom1"
                                        DateTextClasses="form-control"
                                        value={applicant ? applicant.DOEFrom1.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)}
                                        max={applicant && applicant.DOETo1 && applicant.DOETo1.Data} />
                                    } />
                                </div>
                              </div>

                              {_isCurrentlyEmployed() ?
                                <></>
                                :
                                <div className="col-md-4">
                                  <div className="form-group p-0 mobile-margin-bottom-3">
                                    <label id="lblDOETo1" className="field-label" htmlFor="txtDOETo1">{applicantDetails ? applicantDetails.DOETo : ""}</label>
                                    <InputGroup
                                      icon={<i className="fa fa-calendar"></i>}
                                      control={
                                        <DateText
                                          required={applicant && applicant.DOETo1.Required}
                                          errorMessage={language === Language.SPANISH ? applicantDetails.DOEToError : applicant && applicant.DOETo1.Validation}
                                          form={APPLICANT_DETAIL_FORM}
                                          submited={adfSubmitted}
                                          id="txtDOETo1"
                                          name="txtDOETo1"
                                          DateTextClasses="form-control"
                                          value={applicant ? applicant.DOETo1.Data : ""}
                                          onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)}
                                          min={applicant && applicant.DOEFrom1 && applicant.DOEFrom1.Data}
                                        />
                                      } />
                                  </div>
                                </div>
                              }
                            </div>

                            {!_isCurrentlyEmployed() && (applicant && applicant.DOEFrom1.Data !== '' ? true : false) && (applicant && applicant.DOETo1.Data !== '' ? true : false) ?
                              <div className="row p-2">
                                <div className="col-md-12">
                                  <div className="form-group p-0 mobile-margin-bottom-3">
                                    <label id="lblReasonForLeaving1" className="field-label" htmlFor="txtReasonForLeaving1">{applicantDetails ? applicantDetails.ReasonForLeaving : ""}</label>
                                    <InputGroup
                                      icon={<i className="fa fa-question-circle"></i>}
                                      control={
                                        <ShortText
                                          required={applicant && applicant.ReasonForLeaving1.Required}
                                          errorMessage={language === Language.SPANISH ? applicantDetails.ReasonForLeavingError : applicant && applicant.ReasonForLeaving1.Validation}
                                          form={APPLICANT_DETAIL_FORM}
                                          submited={adfSubmitted}
                                          id="txtReasonForLeaving1"
                                          name="txtReasonForLeaving1"
                                          ShortTextClasses="form-control"
                                          value={applicant ? applicant.ReasonForLeaving1.Data : ""}
                                          onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                      } />
                                  </div>
                                </div>
                              </div>
                              : <></>
                            }
                          </div>
                        </div>

                        {/* Tab 2 */}
                        <div className={`tab-pane fade ${selectedTab === 1 && "show active"}`} id="expTab2" role="tabpanel" aria-labelledby="exp-tab-2">
                          <div className="card a-card-wrap a-card-wrap-register pt-3">
                            <div className="row p-2">
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblEmployer2" className="field-label" htmlFor="txtEmployer2">{applicantDetails ? applicantDetails.Employer : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-industry"></i>}
                                    control={
                                      <ShortText
                                        required={applicant && applicant.Employer2.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.EmployerError : applicant && applicant.Employer2.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtEmployer2"
                                        name="txtEmployer2"
                                        ShortTextClasses="form-control"
                                        value={applicant ? applicant.Employer2.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblEmployerAddress2" className="field-label" htmlFor="txtEmployerAddress2">{applicantDetails ? applicantDetails.EmployerAddress : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-map-marker"></i>}
                                    control={
                                      <ShortText
                                        required={applicant && applicant.EmployerAddress2.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.EmployerAddressError : applicant && applicant.EmployerAddress2.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtEmployerAddress2"
                                        name="txtEmployerAddress2"
                                        ShortTextClasses="form-control"
                                        value={applicant ? applicant.EmployerAddress2.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblEmployerPhone2" className="field-label" htmlFor="txtEmployerPhone2">{applicantDetails ? applicantDetails.EmployerPhone : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-phone"></i>}
                                    control={
                                      <PhoneText
                                        required={applicant && applicant.EmployerPhone2.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.EmployerPhoneError : applicant && applicant.EmployerPhone2.Validation}
                                        form={APPLICANT_DETAIL_FORM} submited={adfSubmitted}
                                        id="txtEmployerPhone2"
                                        name="txtEmployerPhone2"
                                        PhoneTextClasses="form-control"
                                        value={applicant ? applicant.EmployerPhone2.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblSupervisor2" className="field-label" htmlFor="txtSupervisor2">{applicantDetails ? applicantDetails.Supervisor : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-user-circle"></i>}
                                    control={
                                      <ShortText
                                        required={applicant && applicant.Supervisor2.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.SupervisorError : applicant && applicant.Supervisor2.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtSupervisor2"
                                        name="txtSupervisor2"
                                        ShortTextClasses="form-control"
                                        value={applicant ? applicant.Supervisor2.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblPosition2" className="field-label" htmlFor="drpPosition2">{applicantDetails ? applicantDetails.Position : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-id-badge"></i>}
                                    control={
                                      <MultiSelector
                                        id="drpPosition2"
                                        name="drpPosition2"
                                        value={applicant ? applicant.Position2.Data : []}
                                        options={positionOptions}
                                        onChange={(e, v) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v)}
                                        required={applicant && applicant.Position2.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.PositionError : applicant && applicant.Position2.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblEmployerTelePhone2" className="field-label" htmlFor="txtEmployerTelePhone2">{applicantDetails ? applicantDetails.EmployerTelePhone : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-fax"></i>}
                                    control={
                                      <PhoneText
                                        required={applicant && applicant.EmployerTelePhone2.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.EmployerTelePhoneError : applicant && applicant.EmployerTelePhone2.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtEmployerTelePhone2"
                                        name="txtEmployerTelePhone2"
                                        PhoneTextClasses="form-control"
                                        value={applicant ? applicant.EmployerTelePhone2.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>

                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblDOEFrom2" className="field-label" htmlFor="txtDOEFrom2">{applicantDetails ? applicantDetails.DOEFrom : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-calendar"></i>}
                                    control={
                                      <DateText
                                        required={applicant && applicant.DOEFrom2.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.DOEFromError : applicant && applicant.DOEFrom2.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtDOEFrom2"
                                        name="txtDOEFrom2"
                                        DateTextClasses="form-control"
                                        value={applicant ? applicant.DOEFrom2.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)}
                                        max={applicant && applicant.DOETo2 && applicant.DOETo2.Data} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblDOETo2" className="field-label" htmlFor="txtDOETo2">{applicantDetails ? applicantDetails.DOETo : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-calendar"></i>}
                                    control={
                                      <DateText
                                        required={applicant && applicant.DOETo2.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.DOEToError : applicant && applicant.DOETo2.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtDOETo2"
                                        name="txtDOETo2"
                                        DateTextClasses="form-control"
                                        value={applicant ? applicant.DOETo2.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)}
                                        min={applicant && applicant.DOEFrom2 && applicant.DOEFrom2.Data}
                                      />
                                    } />
                                </div>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-12">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblReasonForLeaving2" className="field-label" htmlFor="txtReasonForLeaving2">{applicantDetails ? applicantDetails.ReasonForLeaving : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-question-circle"></i>}
                                    control={
                                      <ShortText
                                        required={applicant && applicant.ReasonForLeaving2.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.ReasonForLeavingError : applicant && applicant.ReasonForLeaving2.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtReasonForLeaving2"
                                        name="txtReasonForLeaving2"
                                        ShortTextClasses="form-control"
                                        value={applicant ? applicant.ReasonForLeaving2.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Tab 3 */}
                        <div className={`tab-pane fade ${selectedTab === 2 && "show active"}`} id="expTab3" role="tabpanel" aria-labelledby="exp-tab-3">
                          <div className="card a-card-wrap a-card-wrap-register pt-3">
                            <div className="row p-2">
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblEmployer3" className="field-label" htmlFor="txtEmployer3">{applicantDetails ? applicantDetails.Employer : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-industry"></i>}
                                    control={
                                      <ShortText
                                        required={applicant && applicant.Employer3.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.EmployerError : applicant && applicant.Employer3.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtEmployer3"
                                        name="txtEmployer3"
                                        ShortTextClasses="form-control"
                                        value={applicant ? applicant.Employer3.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblEmployerAddress3" className="field-label" htmlFor="txtEmployerAddress3">{applicantDetails ? applicantDetails.EmployerAddress : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-map-marker"></i>}
                                    control={
                                      <ShortText
                                        required={applicant && applicant.EmployerAddress3.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.EmployerAddressError : applicant && applicant.EmployerAddress3.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtEmployerAddress3"
                                        name="txtEmployerAddress3"
                                        ShortTextClasses="form-control"
                                        value={applicant ? applicant.EmployerAddress3.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblEmployerPhone3" className="field-label" htmlFor="txtEmployerPhone3">{applicantDetails ? applicantDetails.EmployerPhone : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-phone"></i>}
                                    control={
                                      <PhoneText
                                        required={applicant && applicant.EmployerPhone3.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.EmployerPhoneError : applicant && applicant.EmployerPhone3.Validation}
                                        form={APPLICANT_DETAIL_FORM} submited={adfSubmitted}
                                        id="txtEmployerPhone3"
                                        name="txtEmployerPhone3"
                                        PhoneTextClasses="form-control"
                                        value={applicant ? applicant.EmployerPhone3.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblSupervisor3" className="field-label" htmlFor="txtSupervisor3">{applicantDetails ? applicantDetails.Supervisor : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-user-circle"></i>}
                                    control={
                                      <ShortText
                                        required={applicant && applicant.Supervisor3.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.SupervisorError : applicant && applicant.Supervisor3.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtSupervisor3"
                                        name="txtSupervisor3"
                                        ShortTextClasses="form-control"
                                        value={applicant ? applicant.Supervisor3.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblPosition3" className="field-label" htmlFor="drpPosition3">{applicantDetails ? applicantDetails.Position : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-id-badge"></i>}
                                    control={
                                      <MultiSelector
                                        id="drpPosition3"
                                        name="drpPosition3"
                                        value={applicant ? applicant.Position3.Data : []}
                                        options={positionOptions}
                                        onChange={(e, v) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v)}
                                        required={applicant && applicant.Position3.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.PositionError : applicant && applicant.Position3.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblEmployerTelePhone3" className="field-label" htmlFor="txtEmployerTelePhone3">{applicantDetails ? applicantDetails.EmployerTelePhone : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-fax"></i>}
                                    control={
                                      <PhoneText
                                        required={applicant && applicant.EmployerTelePhone3.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.EmployerTelePhoneError : applicant && applicant.EmployerTelePhone3.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtEmployerTelePhone3"
                                        name="txtEmployerTelePhone3"
                                        PhoneTextClasses="form-control"
                                        value={applicant ? applicant.EmployerTelePhone3.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>

                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblDOEFrom3" className="field-label" htmlFor="txtDOEFrom3">{applicantDetails ? applicantDetails.DOEFrom : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-calendar"></i>}
                                    control={
                                      <DateText
                                        required={applicant && applicant.DOEFrom3.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.DOEFromError : applicant && applicant.DOEFrom3.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtDOEFrom3"
                                        name="txtDOEFrom3"
                                        DateTextClasses="form-control"
                                        value={applicant ? applicant.DOEFrom3.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)}
                                        max={applicant && applicant.DOETo3 && applicant.DOETo3.Data} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblDOETo3" className="field-label" htmlFor="txtDOETo3">{applicantDetails ? applicantDetails.DOETo : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-calendar"></i>}
                                    control={
                                      <DateText
                                        required={applicant && applicant.DOETo3.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.DOEToError : applicant && applicant.DOETo3.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtDOETo3"
                                        name="txtDOETo3"
                                        DateTextClasses="form-control"
                                        value={applicant ? applicant.DOETo3.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)}
                                        min={applicant && applicant.DOEFrom3 && applicant.DOEFrom3.Data} />
                                    } />
                                </div>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-12">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblReasonForLeaving3" className="field-label" htmlFor="txtReasonForLeaving3">{applicantDetails ? applicantDetails.ReasonForLeaving : ""}</label>
                                  <InputGroup
                                    icon={<i className="fa fa-question-circle"></i>}
                                    control={
                                      <ShortText
                                        required={applicant && applicant.ReasonForLeaving3.Required}
                                        errorMessage={language === Language.SPANISH ? applicantDetails.ReasonForLeavingError : applicant && applicant.ReasonForLeaving3.Validation}
                                        form={APPLICANT_DETAIL_FORM}
                                        submited={adfSubmitted}
                                        id="txtReasonForLeaving3"
                                        name="txtReasonForLeaving3"
                                        ShortTextClasses="form-control"
                                        value={applicant ? applicant.ReasonForLeaving3.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                                    } />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div className="row p-2">
                    <div className="col-md-6">
                      <label className="field-label">
                        {applicantDetails ? applicantDetails.IsOtherEmploymentName : ""}
                      </label>
                      <YesNoRadio
                        name="rdbtnAONEAge"
                        yesText={applicantDetails ? applicantDetails.Yes : ""}
                        noText={applicantDetails ? applicantDetails.No : ""}
                        value={applicant ? applicant.IsOtherEmploymentName.Data : "N"}
                        onSelectionChanged={(e, v) => { setIsAnotherEmpName(v == "Y"); onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v) }} />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group p-0 mobile-margin-bottom-3">
                        <label id="lblOtherEmploymentName" className="field-label" htmlFor="txtOtherEmploymentName">{applicantDetails ? applicantDetails.OtherName : ""}</label>
                        <InputGroup
                          icon={<i className="fa fa-address-card"></i>}
                          control={
                            <ShortText
                              required={applicant && applicant.OtherEmploymentName.Required}
                              errorMessage={language === Language.SPANISH ? applicantDetails.OtherEmploymentNameError : applicant && applicant.OtherEmploymentName.Validation}
                              form={APPLICANT_DETAIL_FORM}
                              submited={adfSubmitted}
                              id="txtOtherEmploymentName"
                              name="txtOtherEmploymentName"
                              placeholder={applicantDetails ? applicantDetails.OtherName : ""}
                              ShortTextClasses={`form-control ${!isAnotherEmpName ? "disabled" : ""}`}
                              disabled={!isAnotherEmpName}
                              value={isAnotherEmpName ? (applicant ? applicant.OtherEmploymentName.Data : "") : ""}
                              onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                          } />
                      </div>
                    </div>
                  </div>

                  <div className="row p-2">
                    <div className="col-md-6">
                      <label className="field-label">
                        {applicantDetails ? applicantDetails.EverTerminated : ""}
                      </label>

                      <YesNoRadio
                        name="rdbtnEBAR"
                        yesText={applicantDetails ? applicantDetails.Yes : ""}
                        noText={applicantDetails ? applicantDetails.No : ""}
                        value={applicant ? applicant.EverTerminated.Data : "N"}
                        onSelectionChanged={(e, v) => { setIsEverTerminated(v == "Y"); onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v) }} />

                    </div>
                    <div className="col-md-6">
                      <div className="form-group p-0 mobile-margin-bottom-3">
                        <label id="lblExplain" className="field-label" htmlFor="txtExplain">{applicantDetails ? applicantDetails.Explain : ""}</label>

                        <InputGroup
                          icon={<i className="fa fa-question-circle"></i>}
                          control={
                            <ShortText
                              required={applicant && applicant.TerminationReason.Required}
                              errorMessage={language === Language.SPANISH ? applicantDetails.TerminationReasonError : applicant && applicant.TerminationReason.Validation}
                              form={APPLICANT_DETAIL_FORM}
                              submited={adfSubmitted}
                              id="txtExplain"
                              name="txtExplain"
                              placeholder={applicantDetails ? applicantDetails.ExplainPlaceHolder : ""}
                              ShortTextClasses={`form-control ${!isEverTerminated ? "disabled" : ""}`}
                              disabled={!isEverTerminated}
                              value={isEverTerminated ? (applicant ? applicant.TerminationReason.Data : "") : ""}
                              onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />
                          } />
                      </div>
                    </div>
                  </div>
                </>
                : <></>
              }

            </div>
            <div className="card-footer bottom-0 px-4 py-2 w-100">
              <div className="row">
                <div className="col-md-12">
                  {applicantModificationStatus === fetchStatus.LOADING ? <div className="float-end my-2"><EllipsisLoader /></div>
                    :
                    <>
                      <BackButton onBackClicked={onBack} />
                      <div className="float-end">
                        <ContinueButton onClick={(e) => onNextClicked(e)} />
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 card card-right d-flex align-items-stretch p-0">
            <Progress percentage="50" getPercentage="15" subTitle="Employment Details" currentPage="employmentDetails1" />
          </div>
        </div>

      </div>
    </>
  )
}

export default Employment_Details_1;