import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { documentTypes, downloadFile, getBase64Image, getFileSizeText, toLocalDate } from '../../../common/common';
// import './css/documentsCard.css';
import {
    deleteDocument, downloadDocument, downloadForm, saveDocument, selectDocumentDeleteStatus, selectDocumentDownloadData,
    selectDocumentDownloadStatus, selectDocumentUploadStatus,
    selectFormDownloadData,
    selectFormDownloadStatus,
    updateDocumentDeleteStatus,
    updateDocumentDownloadStatus,
    updateformDownloadStatus
} from '../../../features/reviewer/reducerSlices/reviewerSlice';
import { fetchStatus, tenantId } from '../../../api/client';
import { accountService } from '../../../features/accounts/services/accountService';
import RemoveIconButton from '../buttons/removeIconButton';
// import { closeModal, selectDocumentId, selectModalPopUpDetail, updateApplicant, updateDocumentId } from '../modals/reducerSlices/uploadFormsModalPopUpSlice';

import i9Img from './../../../assets/images/clipart/i9.svg';
import fileImg from './../../../assets/images/clipart/file.svg';
import genderImg from './../../../assets/images/clipart/gender.svg';
import termsImg from './../../../assets/images/clipart/terms.svg';
import folderImg from './../../../assets/images/clipart/folder.svg';
import filesImg from './../../../assets/images/clipart/files.svg';
import safetyTestAndCertificateImg from './../../../assets/images/clipart/safety-test-and-certificate.svg';
import eddFileImg from './../../../assets/images/clipart/edd-file.png';
import covidFormFileImg from './../../../assets/images/clipart/covid-form-file.png';
import vaccinationDetailsFileImg from './../../../assets/images/clipart/vaccination-details-file.png';
import resumeImg from './../../../assets/images/clipart/resume.svg';

import pdfImg from './../../../assets/images/clipart/pdf.svg';
import licenseImg from './../../../assets/images/clipart/license.svg';
import deleteImg from './../../../assets/images/clipart/delete.svg';
import downloadImg from './../../../assets/images/clipart/download.svg';
import cloudImg from './../../../assets/images/clipart/cloud.svg';
import { isFormValid } from '../services/formService';

import UploadDocumentsCard from './uploadDocumentsCard';
import ReactTooltip from 'react-tooltip';
import { getApplicantById } from '../../../features/applicant/reducerSlices/applicantSlice';

function DocumentsCard(props) {
    const [documentsTrs, setDocumentsTrs] = useState([]);

    const FORM_DOWNLOAD_FORM = "FormDownloadForm";

    const [fdfSubmitted, setFDFSubmitted] = useState(false);

    const formDownloadStatus = useSelector(selectFormDownloadStatus);
    const formDownloadData = useSelector(selectFormDownloadData);

    const documentDownloadStatus = useSelector(selectDocumentDownloadStatus);
    const documentDownloadData = useSelector(selectDocumentDownloadData);



    const [formType, setFormType] = useState("");



    const [i9Trs, setI9Trs] = useState([]);
    const [w4Trs, setW4Trs] = useState([]);
    const [ewaTrs, setEwaTrs] = useState([]);
    const [sexualTrs, setSexualTrs] = useState([]);
    const [termsTrs, setTermsTrs] = useState([]);
    const [othersTrs, setOthersTrs] = useState([]);
    const [safetyTestandCertificateTrs, setSafetyTestandCertificateTrs] = useState([]);
    const [covidFormTrs, setCovidFormTrs] = useState([]);
    const [vaccinationDetailsTrs, setVaccinationDetailsTrs] = useState([]);
    const [resumeTrs, setResumeTrs] = useState([]);
    const [videoTrs, setVideoTrs] = useState([]);
    const [backgroundCheckTrs, setBackgroundCheckTrs] = useState([]);
    const [linkedInTrs, setLinkedInTrs] = useState([]);
    const [portfolioGithubTrs, setPortfolioGithubTrs] = useState([]);
    // const [Trs, setTrs] = useState([]);

    const dispatch = useDispatch();
    const isAdmin = accountService.isAdmin();
    //const [applicant, setapplicant] = useState([]);
    const documentDeleteStatus = useSelector(selectDocumentDeleteStatus);
    const applicant = props.applicant;

    const onDeleteClicked = async (e, id, name) => {
        // let _applicantObj = Object.assign({}, applicant);
        // let _newApplicantObj = onDocumentRemoved(_applicantObj, id);
        // dispatch(updateSingleData(_newApplicantObj));

        // dispatch(updateDocumentId(id)); // ASK
        if (id) {
            await dispatch(deleteDocument({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "DELETEDOCUMENT",
                payload: {
                    id: applicant.id,
                    data: JSON.stringify({
                        documentName: name,
                        documentId: id
                    })
                }
            }));
        }

    };

    const onFormDownloadClicked = async (id, formType) => {
        // setFDFSubmitted(false);
        if (isFormValid(FORM_DOWNLOAD_FORM)) {
            if (id && formType) {
                await dispatch(downloadForm({
                    client: tenantId,
                    serviceType: "TENANTADMIN",
                    operation: "DOWNLOADFORM",
                    payload: {
                        id: id,
                        data: formType
                    }
                }));
            }
        }
        // else {
        //     setFDFSubmitted(true);
        // }
    };

    if (formDownloadStatus === fetchStatus.SUCCEEDED) {
        if (formDownloadData) {
            downloadFile(formDownloadData.formData, formDownloadData.formName);
        }
        dispatch(updateformDownloadStatus());
    }

    const onDownloadClicked = async (e, id, documentName) => {

        await dispatch(downloadDocument({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "DOWNLOADDOCUMENT",
            payload: {
                id: id,
                data: documentName
            }
        }));
    };

    if (documentDownloadStatus === fetchStatus.SUCCEEDED) {
        if (documentDownloadData) {
            downloadFile(documentDownloadData.documentData, documentDownloadData.documentName);
        }
        dispatch(updateDocumentDownloadStatus());
    }

    let _documentsTrs;
    let _itemDiv;

    useEffect(() => {
        //setapplicant(props.applicant);
        let _applicant = props.applicant;
        let _i9Arr = [];
        let _w4Arr = [];
        let _ewaArr = [];
        let _sexualArr = [];
        let _termsArr = [];
        let _otherArr = [];
        let _safetyTestandCertificateTrsArr = [];
        let _covidFormTrsArr = [];
        let _vaccinationDetailsTrsArr = [];
        let _resumeArr = [];
        let _videoArr = [];
        let _backgroundCheckArr = [];
        let _linkedInArr = [];
        let _portfolioGithubArr = [];

        if (_applicant && _applicant.Documents && Array.isArray(_applicant.Documents) && _applicant.Documents.length > 0) {

            _documentsTrs = _applicant.Documents.map((doc, index) => (
                _itemDiv = <div className="flyout-list-group-item-card">
                    <div className='sub-icon'>
                        {doc.DocumentType === 13 ?
                            <i class="fa-solid fa-video fa-lg text-orange mx-3"></i>
                            :
                            <img src={pdfImg} className="icon-img" />
                        }
                        <p className='file-size-text'>{getFileSizeText(doc.Length)}</p>
                    </div>
                    <span className="ps-2">
                        {/* <div className='file-name'>{doc.DocumentName}</div> */}
                        {/* <div className='file-name'>{truncate(doc.DocumentType, 20)}</div> */}
                        {
                            doc.IsURL ?
                                <a href={doc.DocumentName} target="_blank" className='file-name'>{truncateURLLink(doc.DocumentName, 30)}</a>
                                : <div className='file-name'>{truncate(doc.DocumentType, 20)}</div>
                        }
                        <div className='file-by'>{doc.UploadedBy}, {doc.UploadedOn && toLocalDate(doc.UploadedOn)} </div>
                    </span >
                    <span className="ms-auto d-flex">
                        {documentDownloadStatus === fetchStatus.LOADING ?
                            doc.IsURL ?
                                <>
                                    {/* <i className="fa fa-link icon-btn fa-bounce" aria-hidden="true" data-tip='Link'></i> */}
                                </>
                                :
                                <i className="fa fa-download icon-btn fa-bounce" aria-hidden="true" data-tip='Download'></i>
                            :
                            doc.IsURL ?
                                <>
                                    {/* <i className="fa fa-link icon-btn" aria-hidden="true" onClick={(e) => window.open(doc.DocumentName, '_blank')} data-tip='Link'></i> */}
                                </>
                                :
                                <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onDownloadClicked(e, _applicant.id, doc.DocumentName)} data-tip='Download'></i>
                        }
                        <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                        {isAdmin ?
                            <React.Fragment>
                                {documentDeleteStatus === fetchStatus.LOADING ?
                                    <i className="fa fa-trash icon-btn ms-2 fa-bounce" aria-hidden="true" data-tip='Delete'></i>
                                    :
                                    <i className="fa fa-trash icon-btn ms-2" aria-hidden="true" onClick={(e) => onDeleteClicked(e, doc.id, doc.DocumentName)} data-tip='Delete'></i>
                                }
                                <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                            </React.Fragment>
                            : ''
                        }
                    </span>
                </div >,

                doc.DocumentType == 1 ?
                    _i9Arr.push(_itemDiv) : '',

                doc.DocumentType == 2 ?
                    _w4Arr.push(_itemDiv) : '',

                doc.DocumentType == 3 ?
                    _sexualArr.push(_itemDiv) : '',

                doc.DocumentType == 4 ?
                    _termsArr.push(_itemDiv) : '',

                // ['1', '2', '3', '4', '6', 1, 2, 3, 4, 6].includes(doc.DocumentType) ?
                //     '' : _otherArr.push(_itemDiv),

                doc.DocumentType == 5 ?
                    _otherArr.push(_itemDiv) : '',

                doc.DocumentType == 6 ?
                    _ewaArr.push(_itemDiv) : '',

                doc.DocumentType == 7 ?
                    _safetyTestandCertificateTrsArr.push(_itemDiv) : '',

                doc.DocumentType == 8 ?
                    _covidFormTrsArr.push(_itemDiv) : '',

                doc.DocumentType == 10 ?
                    _vaccinationDetailsTrsArr.push(_itemDiv) : '',

                doc.DocumentType == 12 ?
                    _resumeArr.push(_itemDiv) : '',

                doc.DocumentType == 13 ?
                    _videoArr.push(_itemDiv) : '',

                doc.DocumentType == 14 ?
                    _backgroundCheckArr.push(_itemDiv) : '',

                doc.DocumentType == 15 ?
                    _linkedInArr.push(_itemDiv) : '',

                doc.DocumentType == 16 ?
                    _portfolioGithubArr.push(_itemDiv) : '',

                <></>
            ));
            setDocumentsTrs(_documentsTrs);

            setI9Trs(_i9Arr);
            setW4Trs(_w4Arr);
            setEwaTrs(_ewaArr);
            setSexualTrs(_sexualArr);
            setTermsTrs(_termsArr);
            setOthersTrs(_otherArr);
            setSafetyTestandCertificateTrs(_safetyTestandCertificateTrsArr);
            setCovidFormTrs(_covidFormTrsArr);
            setVaccinationDetailsTrs(_vaccinationDetailsTrsArr);
            setResumeTrs(_resumeArr);
            setVideoTrs(_videoArr);
            setBackgroundCheckTrs(_backgroundCheckArr);
            setLinkedInTrs(_linkedInArr);
            setPortfolioGithubTrs(_portfolioGithubArr);
        }
        else {
            setDocumentsTrs(<tr>
                <td colSpan="6">No records available</td>
            </tr>);

            setI9Trs(_i9Arr);
            setW4Trs(_w4Arr);
            setEwaTrs(_ewaArr);
            setSexualTrs(_sexualArr);
            setTermsTrs(_termsArr);
            setOthersTrs(_otherArr);
            setSafetyTestandCertificateTrs(_safetyTestandCertificateTrsArr);
            setCovidFormTrs(_covidFormTrsArr);
            setVaccinationDetailsTrs(_vaccinationDetailsTrsArr);
            setResumeTrs(_resumeArr);
            setVideoTrs(_videoArr);
            setBackgroundCheckTrs(_backgroundCheckArr);
            setLinkedInTrs(_linkedInArr);
            setPortfolioGithubTrs(_portfolioGithubArr);
        }
    }, [props.documents, documentDeleteStatus, documentDownloadStatus]);


    const truncate = (str, n) => {
        var docText = 'Others';
        if (str == '1') {
            docText = "I9";
        }
        if (str == '2') {
            docText = "W4";
        }
        if (str == '3') {
            docText = "Sexual Harassments Certificate";
        }
        if (str == '4') {
            docText = "Terms";
        }
        if (str == '5') {
            docText = "Others";
        }
        if (str == '6') {
            docText = "EDD";
        }
        if (str == '7') {
            docText = "Safety Test Certificate";
        }
        if (str == '8') {
            docText = "Covid Form";
        }
        if (str == '9') {
            docText = "W4 + EDD";
        }
        if (str == '10') {
            docText = "Vaccination Details";
        }
        if (str == '12') {
            docText = "Resume";
        }
        if (str == '13') {
            docText = "Video";
        }
        if (str == '14') {
            docText = "Background Check";
        }
        if (str == '15') {
            docText = "LinkedIn";
        }
        if (str == '16') {
            docText = "Portfolio/Github";
        }


        return (docText.length > n) ? docText.substr(0, n - 1) + '...' : docText;
    };

    const truncateURLLink = (str, n) => {
        return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
    }

    const getIcon = (str) => {
        var docIcon = folderImg;
        if (str == '1') {
            docIcon = i9Img;
        }
        if (str == '2') {
            docIcon = fileImg;
        }
        if (str == '3') {
            docIcon = genderImg;
        }
        if (str == '4') {
            docIcon = termsImg;
        }
        if (str == '5') {
            docIcon = folderImg;
        }
        if (str == '6') {
            docIcon = filesImg;
        }
        if (str == '7') {
            docIcon = filesImg;
        }
        if (str == '8') {
            docIcon = filesImg;
        }
        if (str == '9') {
            docIcon = filesImg;
        }
        if (str == '10') {
            docIcon = filesImg;
        }
        return docIcon;
    };

    const _loadApplicant = async (id) => {
        await dispatch(getApplicantById({
            client: tenantId,
            serviceType: "TENANTUSER",
            operation: "GET",
            payload: {
                id: id,
                data: ""
            }
        }));
    }

    useEffect(() => {
        if (documentDeleteStatus === fetchStatus.SUCCEEDED) {
            if (props.applicant.id !== "") {
                _loadApplicant(props.applicant.id)
                dispatch(updateDocumentDeleteStatus());
            }
        }
    }, [documentDeleteStatus])

    return (
        <React.Fragment>
            {/* <div className="row">
                <div className="col-md-9"><span><i className="fa fa-paperclip" ></i></span><span> Attachments</span></div>
                <div className="col-md-3">
                    <button onClick={(e) => { props.onUploadFormsClicked && props.onUploadFormsClicked(e); }} title="Add Document" className="DeletePhoto e-focus"><span className="zoomicon text-success"><i className="fa fa-plus mr-3 zoomicon"></i></span></button>
                </div>
            </div> */}

            {/* {fileTrs}
            {documentsTrs} */}


            <div className="accordion" id="accordionPeopleAttachment">

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                        <img src={resumeImg} className="icon-img" />
                        {/* <i class="fa-regular fa-file"></i> */}
                        <span className="bold-text ps-2">
                            Resume
                        </span>
                        <span className="ms-auto light-text">
                            {resumeTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {resumeTrs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={12}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                        {/* <img src={vaccinationDetailsFileImg} className="icon-img" /> */}
                        <i class="fa-solid fa-video fa-lg text-orange"></i>
                        <span className="bold-text ps-2">
                            Video
                        </span>
                        <span className="ms-auto light-text">
                            {videoTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseThirteen" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {videoTrs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={13}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapsefourteen" aria-expanded="false" aria-controls="collapsefourteen">
                        <img src={licenseImg} className="icon-img" />
                        {/* <i class="fa-regular fa-file"></i> */}
                        <span className="bold-text ps-2">
                            Background Check
                        </span>
                        <span className="ms-auto light-text">
                            {backgroundCheckTrs.length} Items
                        </span>
                    </div>
                    <div id="collapsefourteen" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {backgroundCheckTrs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={14}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                        {/* <img src={licenseImg} className="icon-img" /> */}
                        <i class="fa-brands fa-linkedin fa-lg text-blue"></i>
                        <span className="bold-text ps-2">
                            LinkedIn
                        </span>
                        <span className="ms-auto light-text">
                            {linkedInTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseFifteen" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {linkedInTrs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={15}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                        {/* <img src={licenseImg} className="icon-img" /> */}
                        <i class="fa-brands fa-github fa-lg text-dark"></i>
                        <span className="bold-text ps-2">
                            Portfolio/Github
                        </span>
                        <span className="ms-auto light-text">
                            {portfolioGithubTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseSixteen" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {portfolioGithubTrs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={16}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        <img src={i9Img} className="icon-img" />
                        <span className="bold-text ps-2">
                            I9
                        </span>
                        <span className="ms-auto light-text">
                            {i9Trs.length} Items
                        </span>
                    </div>
                    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            <div className="flyout-list-group-item-card system">
                                <div className='sub-icon'>
                                    <img src={pdfImg} className="icon-img" />
                                    <p className='file-size-text'>0kb</p>
                                </div>
                                <span className="ps-2">
                                    <div className='file-name'>Pre-Filled Template</div>
                                    <div className='file-by'>System Generated</div>
                                </span>
                                <span className="ms-auto d-flex">
                                    {/* <img src={downloadImg} alt='not found' className='icon-btn' onClick={(e) => onFormDownloadClicked(props.applicant.id, "0")} data-tip='Download' /> */}
                                    <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onFormDownloadClicked(props.applicant.id, "0")} data-tip='Download'></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </span>
                            </div>
                            {i9Trs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={1}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <img src={fileImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            W4
                        </span>
                        <span className="ms-auto light-text">
                            {w4Trs.length} Items
                        </span>
                    </div>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            <div className="flyout-list-group-item-card system">
                                <div className='sub-icon'>
                                    <img src={pdfImg} className="icon-img" />
                                    <p className='file-size-text'>0kb</p>
                                </div>
                                <span className="ps-2">
                                    <div className='file-name'>Pre-Filled Template</div>
                                    <div className='file-by'>System Generated</div>
                                </span>
                                <span className="ms-auto d-flex">
                                    {/* <img src={downloadImg} alt='not found' className='icon-btn' onClick={(e) => onFormDownloadClicked(props.applicant.id, "1")} data-tip='Download' /> */}
                                    <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onFormDownloadClicked(props.applicant.id, "1")} data-tip='Download'></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </span>
                            </div>
                            {w4Trs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={2}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        <img src={eddFileImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            EDD
                        </span>
                        <span className="ms-auto light-text">
                            {ewaTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            <div className="flyout-list-group-item-card system">
                                <div className='sub-icon'>
                                    <img src={pdfImg} className="icon-img" />
                                    <p className='file-size-text'>0kb</p>
                                </div>
                                <span className="ps-2">
                                    <div className='file-name'>Pre-Filled Template (English)</div>
                                    <div className='file-by'>System Generated</div>
                                </span>
                                <span className="ms-auto d-flex">
                                    {/* <img src={downloadImg} alt='not found' className='icon-btn' onClick={(e) => onFormDownloadClicked(props.applicant.id, "2")} data-tip='Download' /> */}
                                    <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onFormDownloadClicked(props.applicant.id, "2")} data-tip='Download'></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </span>
                            </div>
                            <div className="flyout-list-group-item-card system">
                                <div className='sub-icon'>
                                    <img src={pdfImg} className="icon-img" />
                                    <p className='file-size-text'>0kb</p>
                                </div>
                                <span className="ps-2">
                                    <div className='file-name'>Pre-Filled Template (Spanish)</div>
                                    <div className='file-by'>System Generated</div>
                                </span>
                                <span className="ms-auto d-flex">
                                    {/* <img src={downloadImg} alt='not found' className='icon-btn' onClick={(e) => onFormDownloadClicked(props.applicant.id, '3')} data-tip='Download' /> */}
                                    <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onFormDownloadClicked(props.applicant.id, "3")} data-tip='Download'></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </span>
                            </div>
                            {ewaTrs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={6}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <img src={genderImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Sexual Harassment
                        </span>
                        <span className="ms-auto light-text">
                            {sexualTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {sexualTrs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={3}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <img src={termsImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Terms
                        </span>
                        <span className="ms-auto light-text">
                            {termsTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {termsTrs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={4}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        <img src={safetyTestAndCertificateImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Safety Test and Certificate
                        </span>
                        <span className="ms-auto light-text">
                            {safetyTestandCertificateTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {safetyTestandCertificateTrs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={7}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        <img src={covidFormFileImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Covid Form
                        </span>
                        <span className="ms-auto light-text">
                            {covidFormTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {covidFormTrs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={8}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                        <img src={vaccinationDetailsFileImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Vaccination Details
                        </span>
                        <span className="ms-auto light-text">
                            {vaccinationDetailsTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {vaccinationDetailsTrs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={10}
                            />
                        </div>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        <img src={folderImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Others
                        </span>
                        <span className="ms-auto light-text">
                            {othersTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {othersTrs}
                            <UploadDocumentsCard
                                applicant={props.applicant}
                                docType={5}
                            />
                        </div>
                    </div>
                </div>


                {/* <div className="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseAll" aria-expanded="false" aria-controls="collapseAll">
                        <img src={filesImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            All
                        </span>
                        <span className="ms-auto light-text">
                            {i9Trs.length + w4Trs.length + ewaTrs.length + sexualTrs.length + termsTrs.length + othersTrs.length + safetyTestandCertificateTrs.length + covidFormTrs.length + vaccinationDetailsTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseAll" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {i9Trs}
                            {w4Trs}
                            {ewaTrs}
                            {sexualTrs}
                            {termsTrs}
                            {othersTrs}
                            {safetyTestandCertificateTrs}
                            {covidFormTrs}
                            {vaccinationDetailsTrs}
                        </div>
                    </div>
                </div> */}
            </div>
        </React.Fragment>
    );
}

export default DocumentsCard;