import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../../api/client';
import { ssnShortFormate, toLocalDateMinute, _getDateText, toLocalDateOnly } from '../../../common/common';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import FillOrderPeopleCard from '../../Card/FillOrderPeopleCard';
import CancelButton from '../custom/buttons/cancelButton';
import SaveButton from '../custom/buttons/saveButton';
import { closeFillOrderModal, getApplicantsForFillOrder, removeReadyForDispatch, saveJobDispatch, selectAllApplicantRecordsFillOrder, selectCurrentPage, selectFillOrderModalPopUpDetail, selectReadyForDispatch, selectSaveJobDispatchStatus, selectStatus, selectTotalItems, updateCurrentPage, updateReadyForDispatch, updateStatus } from './reducerSlices/fillOrderModalPopUpSlice';

import leftArrowImg from './../../../assets/images/clipart/left-arrow.svg';
import rightArrowImg from './../../../assets/images/clipart/right-arrow.svg';
import clockCalendarImg from './../../../assets/images/clipart/clock-calendar.svg';
import useHeartImg from './../../../assets/images/clipart/use-heart.svg';
import handshakeImg from './../../../assets/images/clipart/handshake.svg';
import dispatchedImg from './../../../assets/images/clipart/dispatched.svg';
import Avatar from '../../Avatar/Avatar';
import { selectLocationOptions } from '../../../features/locations/reducerSlices/locationSlice';
import { selectWhereClause } from '../filters/reducerSlices/jobFilterSlice'; // Testing need remove
import Collection from '../paginators/collection';
import { getJobById, selectClients } from '../../../features/jobOrder/reducerSlices/jobSlice';

import { accountService } from '../../../features/accounts/services/accountService';
import EllipsisLoader from '../loaders/ellipsisLoader';
import ReactPlaceholder from 'react-placeholder';
import PageContainer from '../paginators/paginator/pageContainer';
import TableContainer from '../paginators/paginator/tableContainer';
import { selectPositionOptions } from '../../../features/setting/reducerSlices/positionsSlice';

function FillOrderModalPopUp(props) {

    const dispatch = useDispatch();

    const itemsPerPage = 6;

    const modalPopUpDetails = useSelector(selectFillOrderModalPopUpDetail);
    const locationOptions = useSelector(selectLocationOptions);

    const clients = useSelector(selectClients);

    const fillOrderApplicantsRequestStatus = useSelector(selectStatus);

    const whereClause = useSelector(selectWhereClause); // Testing need remove

    const FILL_ORDER_FORM = "FillOrderForm";

    const applicants = useSelector(selectAllApplicantRecordsFillOrder);

    const [people_Data, setPeople_Data] = useState('');
    const [appRecords, setAppRecords] = useState(new Collection());

    const [clientOptions, setClientOptions] = useState();

    const saveJobDispatchStatus = useSelector(selectSaveJobDispatchStatus);
    const [dummyCards, setDummyCards] = useState([]);

    const readyForDispatch = useSelector(selectReadyForDispatch);

    const currentPage = useSelector(selectCurrentPage);
    const totalPageItems = useSelector(selectTotalItems);

    const positionOptions = useSelector(selectPositionOptions);


    useEffect(() => {
        let _clientOptions;
        if (isObjectArray(clients)) {
            // console.log('clients ----> ',clients)
            _clientOptions = clients.map((client, index) => (
                {
                    text: client.ClientName,
                    value: client.id,
                }
            ));
            setClientOptions(_clientOptions);
        }
    }, [clients]);


    const _getPositionText = (id) => {
        if (positionOptions) {
            let position = positionOptions.find(p => p.value === id);
            if (position && position.text) {
                return position.text;
            }
        }
        return "N/A";
    }

    const _getLocationText = (locations, id) => {
        if (isObjectArray(locations)) {
            let loc = locations.find(l => l.value === id);
            if (loc && loc.text) {
                return loc.text;
            }
        }
        return "N/A";
    }

    const onCloseClicked = () => {
        dispatch(closeFillOrderModal());
    };

    const _loadApplicants = async (page, offset, limit, whereClause, sort) => {
        //setIsItemsLoading(true);
        // await dispatch(getAllApplicants({
        await dispatch(getApplicantsForFillOrder({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETAPPLICANTSFORFILLORDER",
                payload: {
                    id: "",
                    data: JSON.stringify({
                        offset: offset,
                        limit: limit,
                        where: whereClause,
                        sort: sort
                    })
                }
            }
        }));

    }

    useEffect(() => {
        if (modalPopUpDetails.position) {
            let where = {
                client: modalPopUpDetails.job.Client.Data,
                position: modalPopUpDetails.position.Position.Data,
                jobType: modalPopUpDetails.position.JobType.Data,
                location: modalPopUpDetails.job.Location.Data,
                jobId: modalPopUpDetails.job.id
            };
            let sort = { by: 1, ascending: false };
            _loadApplicants(0, 0, itemsPerPage, where, sort);

        }
    }, [modalPopUpDetails.position]);

    const _getStatus = (status) => {
        if (status === "In Progress") {
            return <div className="c-badge status-inprogress text-dark">In Progress</div>;
        }
        else if (status === "Available") {
            return <div className="c-badge status-available text-dark">Available</div>;
        }
        else if (status === "Dispatched") {
            return <div className="c-badge status-dispatched text-dark">Dispatched</div>;
        }
    }

    let applicantCards;
    let _dummyCards = [];

    let _appRecords = new Collection();

    useEffect(() => {
        if (fillOrderApplicantsRequestStatus === fetchStatus.SUCCEEDED || fillOrderApplicantsRequestStatus === fetchStatus.DONE) {
            let _records = applicants.Items;
            if (applicants.Items && applicants.Items.length > 0) {
                for (let i = 0; i < applicants.Items.length; i++) {
                    let item = applicants.Items[i];
                    applicantCards = item.records.map((applicant, index) => (
                        <tr
                            key={applicant.id}
                            id={applicant.id}
                            draggable
                            onDragStart={(e) => onDragStart(e)}
                            onDragEnd={(e) => onDragEnd(e)}
                            applicantDetails={applicant}
                            className=""
                        >
                            <td className="py-2">
                                <div className="d-flex">
                                    <Avatar PhotoPath={applicant.Photo} Gender={applicant.Gender} />
                                    <div className="align-self-center p-2">
                                        {applicant.FirstName} {` `} {applicant.LastName}
                                    </div>
                                </div>
                            </td>
                            <td className="py-2">{ssnShortFormate(applicant.SSN)}</td>
                            {/* <td className="py-2">{toLocalDateMinute(applicant.ApplicationDate)} </td> */}
                            <td className="py-2">{applicant.LastWorkingDate && toLocalDateOnly(applicant.LastWorkingDate)} </td>
                            <td className="py-2"><div className="c-badge c-badge-green ">{applicant.Repeat}</div></td>
                            <td className="py-2"><div className="c-badge bg-blue">{applicant.PreferedClient}</div></td>
                        </tr>
                    ));
                    _appRecords.Add(item.key, applicantCards);
                }
                setAppRecords(_appRecords);
                dispatch(updateStatus());
            }
            dispatch(updateStatus());
        }
    }, [fillOrderApplicantsRequestStatus, currentPage, applicants]);

    const _getClientText = (clients, id) => {
        if (isObjectArray(clients)) {
            let client = clients.find(l => l.value === id);
            if (client && client.text) {
                return client.text;
            }
        }
        return "N/A";
    }

    const [state, setState] = useState('');

    const onDragStart = (evt) => {
        let element = evt.currentTarget;
        element.classList.add("dragged");
        evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
        evt.dataTransfer.effectAllowed = "move";
    };

    const onDragEnd = (evt) => {
        evt.currentTarget.classList.remove("dragged");
    };

    const onDragEnter = (evt) => {
        evt.preventDefault();
        let element = evt.currentTarget;
        element.classList.add("dragged-over");
        evt.dataTransfer.dropEffect = "move";
    };

    const onDragLeave = (evt) => {
        let currentTarget = evt.currentTarget;
        let newTarget = evt.relatedTarget;
        if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
            return;
        evt.preventDefault();
        let element = evt.currentTarget;
        element.classList.remove("dragged-over");
    };

    const onDragOver = (evt) => {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = "move";
    };

    const onDrop = (evt, value, status) => {
        evt.preventDefault();
        evt.currentTarget.classList.remove("dragged-over");
        let data = evt.dataTransfer.getData("text/plain");
        let tasks = state.tasks;
        dispatch(updateReadyForDispatch({ applicantId: data }));
    };

    const onClickRemovePeople = (id) => {
        dispatch(removeReadyForDispatch({ applicantId: id, itemsPerPage: itemsPerPage }));
    }

    const onSave = async () => {
        readyForDispatch.forEach(async d => {
            console.log('readyForDispatch', d.id, d);
        });

        // return;
        const user = accountService.getAuthenticatedUser();
        let jobKey = modalPopUpDetails.job.id + '$$$#$$$' + modalPopUpDetails.position.id;
        let jobId = modalPopUpDetails.job.id
        let applicantIds = readyForDispatch.map((applicant) =>
            applicant.id
        );

        let disData = {
            applicantIds: applicantIds,
            jobId: jobId,
            jobKey: jobKey,
            payRates: modalPopUpDetails.position && modalPopUpDetails.position.PayRates && modalPopUpDetails.position.PayRates.Data,
            client: modalPopUpDetails.job && modalPopUpDetails.job.Client && modalPopUpDetails.job.Client.Data,
        }

        console.log('disData', disData);

        await dispatch(saveJobDispatch({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVEMULTIPLEJOBDISPATCH",
            payload: {
                id: jobId,
                data: JSON.stringify(disData)
            }
        }));
    }

    const _loadJob = async (id, page) => {
        await dispatch(getJobById({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETJOBBYID",
                payload: {
                    id: id,
                    data: ""
                }
            }
        }));
    }

    useEffect(() => {
        if (saveJobDispatchStatus === fetchStatus.SUCCEEDED) {
            _loadJob(modalPopUpDetails.job.id, '');
            onCloseClicked();
        }
    }, [saveJobDispatchStatus]);


    useEffect(() => {
        for (let i = 0; i < 5; i++) {
            _dummyCards.push(
                <tr>
                    <td><ReactPlaceholder showLoadingAnimation={true} type='media' ready={false} rows={0} /></td>
                    <td><ReactPlaceholder showLoadingAnimation={true} type='text' ready={false} rows={1} /></td>
                    <td><ReactPlaceholder showLoadingAnimation={true} type='text' ready={false} rows={1} /></td>
                    <td><ReactPlaceholder showLoadingAnimation={true} type='text' ready={false} rows={1} /></td>
                    <td><ReactPlaceholder showLoadingAnimation={true} type='text' ready={false} rows={1} /></td>
                </tr>
            );
        }
        setDummyCards(_dummyCards);
    }, [])

    const getIsWillPositionFilled = () => {
        return (modalPopUpDetails.job && modalPopUpDetails.job.Status && modalPopUpDetails.job.Status.Data &&
            modalPopUpDetails.job.Status.Data != "Filled" && modalPopUpDetails.job.Status.Data != "Inactive") &&
            (modalPopUpDetails.position
                && modalPopUpDetails.position.Status
                && modalPopUpDetails.position.Status.Data
                && modalPopUpDetails.position.Status.Data !== "Filled"
            ) && (modalPopUpDetails.position.Filled + readyForDispatch.length < modalPopUpDetails.position.Quantity.Data)
    }

    const onLoadItems = async (p) => {
        let where = {
            client: modalPopUpDetails.job.Client.Data,
            position: modalPopUpDetails.position.Position.Data,
            jobType: modalPopUpDetails.position.JobType.Data,
            location: modalPopUpDetails.job.Location.Data,
            jobId: modalPopUpDetails.job.id
        };
        let sort = { by: 1, ascending: false };
        await _loadApplicants(p, p * itemsPerPage, itemsPerPage, where, sort);
    };

    const onPageChanged = async (p, l) => {
        dispatch(updateCurrentPage(p));
        if (l) {
            await onLoadItems(p);
        }
    };

    useEffect(() => {
        const handleBackButton = (event) => {
          if (modalPopUpDetails.show) {
            onCloseClicked();
          }
        };
    
        window.addEventListener('popstate', handleBackButton);

        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
      }, [modalPopUpDetails.show]);

    return (
        <>
            {(modalPopUpDetails && modalPopUpDetails.show) ? (<>
                <div className="modal non-copyable" tabIndex="-1" role="dialog" aria-hidden="true">

                    <div className="modal-dialog modal-dialog-centered FillOrderDialogWidth modal-fullscreen" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                                {/*{statusChangeStatus === fetchStatus.LOADING ? <></> : <button
                                    type="button"
                                    className="modal-close-button"
                                    onClick={onCloseClicked}>
                                    <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                </button>} */}
                                <button
                                    type="button"
                                    className="modal-close-button"
                                    onClick={onCloseClicked}>
                                    <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='p-3'>

                                    <div className="row">
                                        <div className="col-3">
                                            <div className="fill-order-card h-100">
                                                <div className="form-group">
                                                    <label className="field-label">Location</label>
                                                    <label className="field-label-value">{_getLocationText(locationOptions, modalPopUpDetails.job.Location.Data)}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label className="field-label">Client</label>
                                                    <label className="field-label-value">{_getClientText(clientOptions, modalPopUpDetails.job.Client.Data)}</label>
                                                </div>
                                                {
                                                    modalPopUpDetails.job && modalPopUpDetails.job.ApplicantDetails && modalPopUpDetails.job.ApplicantDetails.map((d) => {
                                                        {
                                                            const _jobKey = `${modalPopUpDetails.job.id}$$$#$$$${modalPopUpDetails.position.id}`;
                                                            return d.jobKey === _jobKey ?
                                                                <FillOrderPeopleCard photoPath={d.Photo} gender={d.Gender} fullName={d.Name} mobileNumber={d.Phone} ssn={ssnShortFormate(d.SSN)} date={toLocalDateMinute(d.DispatchDate)} />
                                                                : ''
                                                        }
                                                    })
                                                }

                                                {
                                                    getIsWillPositionFilled() &&
                                                    <div className='p-3'>
                                                        <div className={`file-upload-box`}
                                                            onDragLeave={(e) => onDragLeave(e)}
                                                            onDragEnter={(e) => onDragEnter(e)}
                                                            onDragEnd={(e) => onDragEnd(e)}
                                                            onDragOver={(e) => onDragOver(e)}
                                                            onDrop={(e) => onDrop(e, false, "ready for Dispatch")}>
                                                            <div className="flyout-list-group-item-card">
                                                                <i className="fa-solid fa-user"></i>
                                                                <span className="ps-2">
                                                                    <div className='file-upload-text'>Drag & Drop applicant here</div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    readyForDispatch && readyForDispatch.map((d) => {
                                                        {
                                                            return <FillOrderPeopleCard photoPath={d.Photo} gender={d.Gender} fullName={`${d.FirstName} ${d.LastName}`} mobileNumber={d.Phone} ssn={ssnShortFormate(d.SSN)} date={toLocalDateMinute(d.ApplicationDate)}
                                                                onClickRemovePeople={(e) => onClickRemovePeople(d.id)}
                                                            />
                                                        }
                                                    })
                                                }

                                            </div>
                                        </div>
                                        <div className="col-9">
                                            <div className="d-flex rectangle-wrapper p-3">
                                                <div className="pe-5">
                                                    <div className="c-label">Title</div>
                                                    <div className="c-label bold">{modalPopUpDetails.job && modalPopUpDetails.job.Title && modalPopUpDetails.job.Title.Data}</div>
                                                </div>
                                                <div className="pe-5">
                                                    <div className="c-label">Postion</div>
                                                    <div className="c-label bold">{modalPopUpDetails.position && modalPopUpDetails.position.Position && _getPositionText(modalPopUpDetails.position.Position.Data)} ( {modalPopUpDetails.position && modalPopUpDetails.position.JobType && modalPopUpDetails.position.JobType.Data})</div>
                                                </div>
                                                <div className="pe-5">
                                                    <div className="c-label">Report To</div>
                                                    <div className="c-label bold">{modalPopUpDetails.job && modalPopUpDetails.job.ReportTo && modalPopUpDetails.job.ReportTo.Data}</div>
                                                </div>
                                                <div className="pe-5">
                                                    <div className="c-label">Pay Rate</div>
                                                    <div className="c-label bold">$ {modalPopUpDetails.position && modalPopUpDetails.position.PayRates && modalPopUpDetails.position.PayRates.Data}</div>
                                                </div>
                                                <div className="pe-5">
                                                    <div className="c-label">Total</div>
                                                    <div className="c-label bold">{modalPopUpDetails.position && modalPopUpDetails.position.Quantity && modalPopUpDetails.position.Quantity.Data}</div>
                                                </div>
                                                <div className="pe-5">
                                                    <div className="c-label">Filled</div>
                                                    <div className="c-label bold">{modalPopUpDetails.position && modalPopUpDetails.position.Filled && modalPopUpDetails.position.Filled}</div>
                                                </div>
                                                <div className="pe-5">
                                                    <div className="c-label">Open</div>
                                                    <div className="c-label bold">{modalPopUpDetails.position && modalPopUpDetails.position.Quantity && (modalPopUpDetails.position.Quantity.Data - modalPopUpDetails.position.Filled)}</div>
                                                </div>

                                            </div>
                                            <div className="p-2">
                                                <table className="table c-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col"><img src={handshakeImg} className="pe-1" alt="no photo" />SSN</th>
                                                            <th scope="col"><img src={clockCalendarImg} className="pe-1" alt="no photo" />Last Working Date</th>
                                                            <th scope="col"><div className="d-flex"><img src={clockCalendarImg} className="pe-1" alt="no photo" />Worked Here Before</div></th>
                                                            <th scope="col"><div className="d-flex"><img src={useHeartImg} className="pe-1" alt="no photo" />Prefered Client</div></th>
                                                        </tr>
                                                    </thead>
                                                    <TableContainer
                                                        currentPage={currentPage}
                                                        totalPageItems={totalPageItems}
                                                        pageRecords={appRecords}
                                                        isItemsLoading={fillOrderApplicantsRequestStatus}
                                                        itemsLoadingContent={dummyCards}
                                                        onPageChanged={(p, l) => onPageChanged(p, l)}
                                                        pageRecordsCardClassName={'people-card'}
                                                        numberOfColumns={5}
                                                        itemsPerPage={itemsPerPage}
                                                    />
                                                </table>

                                                {/* <div className="d-flex justify-content-between">
                                                    <div className="c-table-result">Showing 1 to 8 of 24 entries</div>
                                                    <div className="d-flex" >
                                                        <ul className="c-pagination">
                                                            <li><a className="" href="#"><img src={leftArrowImg} /></a></li>
                                                            <li><a href="#" className="active">1</a></li>
                                                            <li><a href="#"><img src={rightArrowImg} /></a></li>
                                                        </ul>
                                                    </div>
                                                </div> */}

                                                {/* <PageContainer
                                                    currentPage={currentPage}
                                                    totalPageItems={totalPageItems}
                                                    pageRecords={appRecords}
                                                    isItemsLoading={fillOrderApplicantsRequestStatus}
                                                    itemsLoadingContent={dummyCards}
                                                    onPageChanged={(p, l) => onPageChanged(p, l)}
                                                    infinite={false}
                                                    pageRecordsCardClassName={'people-card'}
                                                /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                {(saveJobDispatchStatus === fetchStatus.LOADING) ? <EllipsisLoader /> : <>
                                    <CancelButton
                                        CancelButtonClasses="me-2"
                                        onCancelClicked={() => onCloseClicked()} />
                                    <SaveButton
                                        onSaveClicked={() => onSave()} />
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </>) : <></>}
        </>
    );
}

export default FillOrderModalPopUp;