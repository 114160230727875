import React, { useEffect, useState } from 'react';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import { sections } from '../../services/leadSaver';
import { toInteger } from '../../../../common/common';
import RemoveIconButton from '../../../../component/Controls/custom/buttons/removeIconButton';
import InputGroup from '../../../../component/Controls/custom/inputGroup/inputGroup';
import ShortText from '../../../../component/Controls/custom/textbox/shortText';
import NumberText from '../../../../component/Controls/custom/textbox/numberText';
import PhoneText from '../../../../component/Controls/custom/textbox/phoneText';
import EmailText from '../../../../component/Controls/custom/textbox/emailText';
import CheckBox from '../../../../component/Controls/custom/checkers/checkbox';
import DialCallButton from '../../../../component/Controls/buttons/dialCallButton';


function ContactDetail(props) {
    const _getPositionText = (id) => {
        if (props.positions) {
            let position = props.positions.find(p => p.value === id);
            if (position && position.text) {
                return position.text;
            }
        }
        return "N/A";
    }
    const [contactDetailsTrs, setContactDetailsTrs] = useState([]);
    let _contactDetailsTrs;
    const editMode = props.editMode;
    const createMode = props.createMode;
    const contactForm = props.leadForm && props.leadForm.Contacts && props.leadForm.Contacts[0];

    let _positionOptions;

    if (isObjectArray(props.positions)) {
        _positionOptions = props.positions.map((position, index) => (
            <option value={position.value}>{position.text}</option>
        ));
    }


    const _getStatusClassBadge = (data) => {
        if (data === "In Progress") {
            return 'badge badge-secondary';
        }
        else if (data === "In Active") {
            return 'badge badge-danger';
        }
        else if (data === "Open") {
            return 'badge badge-warning';
        }
        else if (data === "Filled") {
            return 'badge badge-success';
        }
    }

    const _isQuantityInvalid = (detail) => {
        if (detail) {
            if (detail.Quantity.Data && detail.Filled) {
                return toInteger(detail.Quantity.Data) < toInteger(detail.Filled);
            }
        }
        return false;
    };

    useEffect(() => {
        if (isObjectArray(props.details)) {
            _contactDetailsTrs = props.details.map((detail, index) => (

                <tr key={detail.id}>
                    {/* <td>{index + 1}</td> */}
                    <td>
                        <div className="">
                            {props.activeAddBtn ?
                                <label className="field-label-value lead-contact-cell-width">{detail ? detail.FullName.Data : ""}</label> :
                                <InputGroup
                                    icon={<i className="fa fa-user-circle"></i>}
                                    control={
                                        <ShortText
                                            id={`txtFullName-${detail.id}`}
                                            name={`txtFullName-${detail.id}`}
                                            ShortTextClasses="form-control lead-contact-cell-width"
                                            placeholder=""
                                            value={detail && detail.FullName ? detail.FullName.Data : ""}
                                            onChange={(e) => props.onValueUpdated && props.onValueUpdated(e, sections.CONTACT_DEATILS, null)}
                                            required="False"
                                            errorMessage={contactForm && contactForm.FullName.Validation}
                                            form={props.form}
                                            submited={props.submitted}
                                        />}
                                />
                            }
                        </div>
                    </td>
                    <td>
                        <div className="">
                            {props.activeAddBtn ?
                                <label className="field-label-value lead-contact-cell-width">{detail ? detail.Position.Data : ""}</label> :
                                <>
                                    <InputGroup
                                        icon={<i className="fa fa-id-badge"></i>}
                                        control={<ShortText
                                            id={`drpPositions-${detail.id}`}
                                            name={`drpPositions-${detail.id}`}
                                            ShortTextClasses="form-control lead-contact-cell-width"
                                            placeholder=""
                                            value={detail ? detail.Position.Data : ""}
                                            onChange={(e) => props.onValueUpdated && props.onValueUpdated(e, sections.CONTACT_DEATILS, null)}
                                            required={contactForm && contactForm.Position.Required}
                                            errorMessage={contactForm && contactForm.Position.Validation}
                                            form={props.form}
                                            submited={props.submitted} />
                                        } />

                                </>
                            }
                        </div>
                    </td>
                    <td>
                        <div className="">
                            {props.activeAddBtn ?
                                <label className="field-label-value lead-contact-cell-width">{detail ? detail.ContactNumber.Data : ""}</label> :
                                <InputGroup
                                    icon={<i className="fa fa-phone"></i>}
                                    control={
                                        <PhoneText
                                            id={`txtContactNumber-${detail.id}`}
                                            name={`txtContactNumber-${detail.id}`}
                                            PhoneTextClasses="form-control lead-contact-cell-width"
                                            value={detail && detail.ContactNumber ? detail.ContactNumber.Data : ""}
                                            onChange={(e) => props.onValueUpdated && props.onValueUpdated(e, sections.CONTACT_DEATILS, null)}
                                            required={contactForm && contactForm.ContactNumber.Required}
                                            errorMessage={contactForm && contactForm.ContactNumber.Validation}
                                            form={props.form}
                                            submited={props.submitted}
                                        />
                                    }
                                />
                            }
                        </div>
                    </td>
                    <td>
                        <div className="">
                            {props.activeAddBtn ?
                                <label className="field-label-value lead-contact-cell-width-extension">{detail && detail.Extension ? detail.Extension.Data : ""}</label> :
                                <InputGroup
                                    icon={<i className="fa fa-play-circle"></i>}
                                    control={
                                        <NumberText
                                            id={`txtExtension-${detail.id}`}
                                            name={`txtExtension-${detail.id}`}
                                            NumberTextClasses="form-control lead-contact-cell-width-extension"
                                            value={detail && detail.Extension ? detail.Extension.Data : ""}
                                            onChange={(e) => props.onValueUpdated && props.onValueUpdated(e, sections.CONTACT_DEATILS, null)}
                                            required={contactForm && contactForm.Extension.Required}
                                            errorMessage={contactForm && contactForm.Extension.Validation}
                                            form={props.form}
                                            submited={props.submitted}
                                        />
                                    }
                                />
                            }
                        </div>
                    </td>
                    <td>
                        <div className="">
                            {props.activeAddBtn ?
                                <label className="field-label-value lead-contact-cell-width">{detail ? detail.Email.Data : ""}</label> :
                                <InputGroup
                                    icon={<i className="fa fa-envelope"></i>}
                                    control={
                                        <EmailText
                                            id={`txtEmail-${detail.id}`}
                                            name={`txtEmail-${detail.id}`}
                                            EmailTextClasses="form-control lead-contact-cell-width"
                                            placeholder="xyz@example.com"
                                            value={detail && detail.Email ? detail.Email.Data : ""}
                                            onChange={(e) => props.onValueUpdated && props.onValueUpdated(e, sections.CONTACT_DEATILS, null)}
                                            required={contactForm && contactForm.Email.Required}
                                            errorMessage={contactForm && contactForm.Email.Validation}
                                            form={props.form}
                                            submited={props.submitted}
                                        />
                                    }
                                />
                            }
                        </div>
                    </td>
                    <td>
                        <div className="">
                            {props.activeAddBtn ?
                                <label className="field-label-value lead-contact-cell-width-2">{detail && detail.ClientInterest ? detail.ClientInterest.Data : ""}</label> :
                                <>
                                    <InputGroup
                                        icon={<i className="fa fa-handshake-o"></i>}
                                        control={<ShortText
                                            id={`txtClientInterest-${detail.id}`}
                                            name={`txtClientInterest-${detail.id}`}
                                            ShortTextClasses="form-control lead-contact-cell-width-2"
                                            placeholder=""
                                            value={detail && detail.ClientInterest ? detail.ClientInterest.Data : ""}
                                            onChange={(e) => props.onValueUpdated && props.onValueUpdated(e, sections.CONTACT_DEATILS, null)}
                                            required={contactForm && contactForm.ClientInterest.Required}
                                            errorMessage={contactForm && contactForm.ClientInterest.Validation}
                                            form={props.form}
                                            submited={props.submitted} />
                                        } />

                                </>
                            }
                        </div>
                    </td>
                    <td className='text-center'>
                        {props.activeAddBtn && props.readonly ?
                            detail && detail.ContactMaker && detail.ContactMaker.Data === "Y" ? <i className='fa fa-star fa-2x text-warning' /> : <></>
                            :
                            <CheckBox
                                id={`ckbContactMaker-${detail.id}`}
                                name={`ckbContactMaker-${detail.id}`}
                                CheckBoxClasses=""
                                checked={detail && detail.ContactMaker ? (detail.ContactMaker.Data === "Y") : false}
                                onClick={(c, e) => props.onValueUpdated && props.onValueUpdated(e, sections.CONTACT_DEATILS, c)}
                            />
                        }
                    </td>

                    <td>
                        <div className='d-flex align-items-center'>
                            <DialCallButton dialCallButtonClasses="pe-3 fa-2x" />
                            <RemoveIconButton
                                title="Remove position"
                                RemoveIconButtonClasses="delete-document-button"
                                RemoveIconClasses="fa-2x"
                                onClick={(e) => props.onDeleteClicked && props.onDeleteClicked(e, detail.id)}
                            />
                        </div>
                    </td>
                </tr>
            ));
            setContactDetailsTrs(_contactDetailsTrs);
        }
        else {
            setContactDetailsTrs(<tr>
                <td colSpan="5">No records available</td>
            </tr>);
        }
    }, [props.details, props.readonly]);
    return (
        <>
            {contactDetailsTrs}
        </>
    );
}

export default ContactDetail;