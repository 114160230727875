import React from 'react'

function SkillPeopleDetailSubCard(props) {
    return (
        <div className="skill-card">
            <div className='d-flex justify-content-between'>
                <div className="skill-card-title">{props.ExperienceName}</div>
            </div>
            <div className="row">
                <div className="col-12">
                    {props.Type === "Multi Choice" ?
                        <>
                            {props.Data.map(opt => <span className="skill-card-badge skill-card-badge-pink me-3 mt-2">{opt}</span>)
                            }
                        </>
                        :
                        <span className="skill-card-badge skill-card-badge-pink mt-2">
                            {props.Data}
                        </span>
                    }
                </div>
            </div>
        </div >
    )
}

export default SkillPeopleDetailSubCard