export const sections = {
    ROUTETRACKING_DETAILS: 0,
};

export const onValueChanged = (routeTracking, e, section, value) => {
    let _routeTracking = JSON.parse(JSON.stringify(routeTracking));
    if (section === sections.ROUTETRACKING_DETAILS) {
        return _saverouteTrackingDetails(_routeTracking, e, value);
    }
};

const _saverouteTrackingDetails = (routeTracking, e, value) => {
    if (e.target.name === "txtUserId") {
        routeTracking.userId = value;
        routeTracking.leadsDetails= [];
    }
    else if (e.target.name === "txtRouteDate") {
        routeTracking.routeDate = e.target.value;
    }
    
    return routeTracking;
};