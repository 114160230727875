

export const isObjectArray = (obj) => {
    return obj !== undefined && Array.isArray(obj) && obj.length > 0;
};

export const isJSON = (str) => {
    if (typeof str !== "string") {
        return false;
    }
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};