import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import officeMapImg from '../../assets/images/clipart/office-photo.svg';


const GMapWaypointsDirectionsAndMarker = (props) => {
    const mapRef = useRef(null);

    const [waypoints, setWaypoints] = useState([{}]);
    const [markerpoints, setMarkerpoints] = useState([{}]);

    useEffect(() => {
        if (props.markerList) {
            // let _markerList = props.markerList.map((d) => {
            //     return { location: { lat: d.lat, lng: d.lng }, stopover: true }
            // });
            const _waypoints = props.markerList.filter(d => d.locationCategory === 'user').map((d) => { return { location: { lat: d.lat, lng: d.lng }, stopover: false } });
            if (_waypoints.length > 0) {
                setWaypoints(_waypoints);
            } else {
                setWaypoints([{}]);
            }
            // const _markerList = props.markerList.filter(d => d.locationCategory === 'lead').map((d) => { return { lat: d.lat, lng: d.lng, title: d.title, icon: d.icon } });
            const _markerList = props.markerList.map((d) => { return { lat: d.lat, lng: d.lng, title: d.title, icon: d.icon, label: d.label } });
            if (_markerList.length > 0) {
                setMarkerpoints(_markerList);
            } else {
                setMarkerpoints([{}]);
            }
        } else {
            setWaypoints([{}]);
            setMarkerpoints([{}]);
        }
    }, [props.markerList])

    useEffect(() => {
        // Initialize the map
        const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 39.368, lng: -99.0352 },
            zoom: 5,
        });

        const directionsService = new window.google.maps.DirectionsService();
        const directionsDisplay = new window.google.maps.DirectionsRenderer({
            map,
            suppressMarkers: true, // This removes default markers
        });

        const chunkSize = 23; // Maximum number of waypoints per request
        const chunkedWaypoints = []; // Array to hold the chunks of waypoints

        // Divide the waypoints into chunks of 23 or less
        for (let i = 0; i < waypoints.length; i += chunkSize) {
            const chunk = waypoints.slice(i, i + chunkSize);
            chunkedWaypoints.push(chunk);
        }

        // Make a directions request for each chunk of waypoints
        let origin = chunkedWaypoints[0][0].location; // First origin is the first waypoint of the first chunk
        // let destination = chunkedWaypoints[chunkedWaypoints.length - 1][chunkSize - 1].location; // Last destination is the last waypoint of the last chunk
        chunkedWaypoints.forEach((chunk) => {
            const request = {
                origin,
                destination: chunk[chunk.length - 1].location,
                waypoints: chunk.slice(1, -1),
                travelMode: window.google.maps.TravelMode.DRIVING,
            };
            directionsService.route(request, (response, status) => {
                console.log(response)
                if (status === 'OK') {
                    directionsDisplay.setDirections(response);
                    map.setZoom(5);
                } else {
                    console.error(`Directions request failed: ${status}`);
                }
            });
            origin = chunk[chunk.length - 1].location; // Update the origin for the next request
        });



        // create marker on google map
        if (markerpoints) {
            var bounds = new window.google.maps.LatLngBounds();
            markerpoints && markerpoints.map(markerObj => {
                new window.google.maps.Marker({
                    position: { lat: markerObj.lat, lng: markerObj.lng },
                    map,
                    title: markerObj.title,
                    icon: {
                        url: markerObj.icon,
                        scaledSize: new window.google.maps.Size(50, 50)
                    },
                    label: markerObj.label
                    // label: markerObj.label && {text: markerObj.label, fontWeight: "bold", fontSize: "14px"}
                })
            });
            map.fitBounds(bounds); // the map to contain all markers
        }

    }, [waypoints, markerpoints]);



    return <div ref={mapRef}
        style={
            { height: '80vh', width: '100%' }}
    />;
}

export default GMapWaypointsDirectionsAndMarker;
