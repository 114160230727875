import {
    createSlice,
    createAsyncThunk,
    nanoid
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import Collection from '../../../component/Controls/paginators/collection';
import { refreshCreated } from '../../../component/Controls/paginators/services/createdResource';
import { refreshRemoved } from '../../../component/Controls/paginators/services/removedResource';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';
import { accountService } from '../../accounts/services/accountService';

const initialState = {
    form: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    removal: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    },
    locationOptions: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    locationOptionsAll: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    PayrollData: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    dispatchDetail: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    dispatchList: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    dispatchCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    show: false,
    createMode: false,
    editMode: false,
};

export const getDispatchById = createAsyncThunk('location/getDispatchById', async (locationModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, locationModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let dispatchDetail = undefined;
        if (data && isJSON(data)) {
            dispatchDetail = JSON.parse(data);
        }
        return {
            dispatchDetail: dispatchDetail,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getDispatchListById = createAsyncThunk('locations/getDispatchListById', async (locationModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, locationModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let dispatchList = undefined;
        if (data && isJSON(data)) {
            dispatchList = JSON.parse(data);
        }
        return {
            dispatchList: dispatchList,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getDispatchCountById = createAsyncThunk('locations/getDispatchCountById', async (locationModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, locationModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let dispatchCount = undefined;
        if (data && isJSON(data)) {
            dispatchCount = JSON.parse(data);
        }
        return {
            dispatchCount: dispatchCount,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});


export const getAllLocations = createAsyncThunk('locations/getAllLocations', async (locationModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', locationModel.model, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let locations;
    if (isJSON(data)) {
        locations = JSON.parse(data);
    }
    return {
        page: locationModel.page,
        locations: locations,
        success: response.data.success
    };
});

export const getLocationById = createAsyncThunk('locations/getLocationById', async (locationModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, locationModel.model, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let location = undefined;
    if (data && isJSON(data)) {
        location = JSON.parse(data);
    }
    return {
        page: locationModel.page,
        location: location,
        success: response.data.success
    };;
});

export const getLocationForm = createAsyncThunk('locations/getLocationForm', async (locationModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, locationModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let form = undefined;
    if (data && isJSON(data)) {
        form = JSON.parse(data);
    }
    return {
        form: form,
        success: response.data.success
    };
});

export const createLocation = createAsyncThunk('locations/createLocation', async (locationModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, locationModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let location = undefined;
    if (data && isJSON(data)) {
        location = JSON.parse(data);
    }
    return {
        location: location,
        success: response.data.success
    };
});

export const updateLocation = createAsyncThunk('locations/updateLocation', async (locationModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, locationModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let location = undefined;
    if (data && isJSON(data)) {
        location = JSON.parse(data);
    }
    return {
        location: location,
        success: response.data.success
    };
});

export const removeLocation = createAsyncThunk('locations/removeLocation', async (locationModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, locationModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    return {
        id: data,
        success: response.data.success
    };
});

export const getLocationOptions = createAsyncThunk('locations/getLocationOptions', async (locationModel, { rejectWithValue }) => {
    try{
        const response = await BajoAPI.post('Gateway', locationModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let options;
        if (isJSON(data)) {
            options = JSON.parse(data);
        }
        return {
            options: options,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getLocationOptionsAll = createAsyncThunk('locations/getLocationOptionsAll', async (locationModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', locationModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let options;
    if (isJSON(data)) {
        options = JSON.parse(data);
    }
    return {
        options: options,
        success: response.data.success
    };
});

export const getDashbaordLocationOptionsAll = createAsyncThunk('locations/getDashbaordLocationOptionsAll', async (locationModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', locationModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let options;
    if (isJSON(data)) {
        options = JSON.parse(data);
    }
    return {
        options: options,
        success: response.data.success
    };
});

export const getPayrollReport = createAsyncThunk('locations/GetPayrollReport', async (locationModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', locationModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let options;
    if (isJSON(data)) {
        options = JSON.parse(data);
    }
    return {
        options: options,
        success: response.data.success
    };
});





export const locationSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateDispatchDetailStatus: (state) => {
            state.dispatchDetail.status = fetchStatus.IDLE;
        },

        updateDispatchListStatus: (state) => {
            state.dispatchList.status = fetchStatus.IDLE;
        },
        
        updateDispatchCountStatus: (state) => {
            state.dispatchCount.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateRemovalStatus: (state) => {
            state.removal.status = fetchStatus.IDLE;
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        loadDispatchdetailData: (state, _data) => {
            state.dispatchDetail.data = Object.assign({}, _data.payload);
        },
        loadDispatchListData: (state, _data) => {
            state.dispatchList.data = Object.assign({}, _data.payload);
        },
        loadDispatchCountData: (state, _data) => {
            state.dispatchCount.data = Object.assign({}, _data.payload);
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateSingleData: (state, _data) => {
            state.single.refreshed = nanoid();
            state.single.data = _data.payload ? Object.assign({}, _data.payload) : _data.payload;
            if (_data.payload) {
                let _item = state.data.records.Get(state.data.currentPage);
                if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                    let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.id);
                    let newRecords = _item.records;
                    let _location = {
                        id: _data.payload.id,
                        LocationName: _data.payload.LocationName.Data,
                        Address: _data.payload.Address.Data,
                        City: _data.payload.City.Data,
                        State: _data.payload.State.Data,
                        Pincode: _data.payload.Pincode.Data
                    };
                    newRecords[_updatedObjectIndex] = _location;
                    _item.records = newRecords;
                    let records = new Collection();
                    records.Add(_item.key, newRecords);
                    state.data.records.Remove(_item.key);
                    records.Concat(state.data.records);
                    state.data.records = records;
                }
            }
        },
        updateDispatchDetailData: (state, _data) => {
            state.dispatchDetail.refreshed = nanoid();
            state.dispatchDetail.data = _data.payload ? Object.assign({}, _data.payload) : _data.payload;
            
        },
        updateDispatchListData: (state, _data) => {
            state.dispatchList.refreshed = nanoid();
            state.dispatchList.data = _data.payload ? Object.assign({}, _data.payload) : _data.payload;
            
        },
        updateDispatchCountData: (state, _data) => {
            state.dispatchCount.refreshed = nanoid();
            state.dispatchCount.data = _data.payload ? Object.assign({}, _data.payload) : _data.payload;
            
        },
        createNewLocation: state => {
            state.single.data = {
                "LocationName": {
                    "Id": "txtLocationName",
                    "Data": ""
                },
                "Address": {
                    "Id": "txtAddress",
                    "Data": ""
                },
                "City": {
                    "Id": "txtCity",
                    "Data": ""
                },
                "State": {
                    "Id": "txtState",
                    "Data": ""
                },
                "Pincode": {
                    "Id": "txtPincode",
                    "Data": ""
                },
                "Phone": {
                    "Id": "txtPhone",
                    "Data": ""
                }
            };
        },
        refreshLocationOptions: (state) => {
            state.locationOptions.refreshed = true;
        },
        refreshLocationOptionsAll: (state) => {
            state.locationOptionsAll.refreshed = true;
        },
        showLocationFlyout: (state, action) => {
            const { createMode, editMode } = action.payload;
            state.createMode = createMode;
            state.editMode = editMode;
            state.show = true;
        },
        closeLocationFlyout: (state, action) => {
            state.createMode = false;
            state.editMode = false;
            state.show = false;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getAllLocations.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        })
        // .addCase(getAllLocations.fulfilled, (state, action) => {
        //     state.data.totalItems = action.payload.locations.totalItems;
        //     let _records = new Collection();
        //     _records.Add(action.payload.page, action.payload.locations.items);
        //     if (!state.data.isFiltered) {
        //         _records.Concat(state.data.records);
        //     }
        //     else {
        //         state.data.firstRecords = new Collection();
        //         state.data.currentPage = 0;
        //     }
        //     state.data.isFiltered = false;
        //     state.data.records = _records;
        //     state.data.status = fetchStatus.SUCCEEDED;
        //     toaster.success(action.payload.success);
        // })
        .addCase(getAllLocations.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.locations.totalItems;
            state.data.items = action.payload.locations.items;
            // let _records = new Collection();
            // _records.Add(action.payload.page, action.payload.locations.items);
            // if (!state.data.isFiltered) {
            //     _records.Concat(state.data.records);
            // }
            // else {
            //     state.data.firstRecords = new Collection();
            //     state.data.currentPage = 0;
            // }
            state.data.isFiltered = false;
            // state.data.records = _records;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        })
        .addCase(getAllLocations.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.data.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getLocationById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getLocationById.fulfilled, (state, action) => {
            state.single.data = action.payload.location;
            if (action.payload.page >= 0) {
                let _records = new Collection();
                _records.Add(action.payload.page, action.payload.location);
                _records.Concat(state.data.firstRecords);
                state.data.firstRecords = _records;
            }
            state.single.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getLocationById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getDispatchById.pending, (state, action) => {
            state.dispatchDetail.status = fetchStatus.LOADING;
        }).addCase(getDispatchById.fulfilled, (state, action) => {
            state.dispatchDetail.data = action.payload.dispatchDetail;
            state.dispatchDetail.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getDispatchById.rejected, (state, action) => {
            state.dispatchDetail.status = fetchStatus.FAILED;
            state.dispatchDetail.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getDispatchListById.pending, (state, action) => {
            state.dispatchList.status = fetchStatus.LOADING;
        }).addCase(getDispatchListById.fulfilled, (state, action) => {
            state.dispatchList.data = action.payload.dispatchDetail;
            state.dispatchList.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getDispatchListById.rejected, (state, action) => {
            state.dispatchList.status = fetchStatus.FAILED;
            state.dispatchList.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getDispatchCountById.pending, (state, action) => {
            state.dispatchCount.status = fetchStatus.LOADING;
        }).addCase(getDispatchCountById.fulfilled, (state, action) => {
            state.dispatchCount.data = action.payload.dispatchCount;
            state.dispatchCount.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getDispatchCountById.rejected, (state, action) => {
            state.dispatchCount.status = fetchStatus.FAILED;
            state.dispatchCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(createLocation.pending, (state, action) => {
            state.creation.status = fetchStatus.LOADING;
        }).addCase(createLocation.fulfilled, (state, action) => {
            if (action.payload.location) {
                state.single.data = action.payload.location;
                let _location = {
                    id: action.payload.location.id,
                    LocationName: action.payload.location.LocationName.Data,
                    Address: action.payload.location.Address.Data,
                    City: action.payload.location.City.Data,
                    State: action.payload.location.State.Data,
                    Pincode: action.payload.location.Pincode.Data
                };
                const created = refreshCreated(state.data.records, _location);
                state.data.records = created.records;
                state.data.currentPage = created.lastPage;
                state.data.totalItems += 1;
                state.single.refreshed = nanoid();
            }
            state.creation.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createLocation.rejected, (state, action) => {
            state.creation.status = fetchStatus.FAILED;
            state.creation.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateLocation.pending, (state, action) => {
            state.modification.status = fetchStatus.LOADING;
        }).addCase(updateLocation.fulfilled, (state, action) => {
            //state.single.data = action.payload;
            state.modification.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateLocation.rejected, (state, action) => {
            state.modification.status = fetchStatus.FAILED;
            state.modification.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(removeLocation.pending, (state, action) => {
            state.removal.status = fetchStatus.LOADING;
        }).addCase(removeLocation.fulfilled, (state, action) => {
            const removed = refreshRemoved(state.data.records, action.payload.id, state.data.currentPage);
            state.data.records = removed.records;
            state.data.currentPage = removed.lastPage;
            state.data.totalItems -= 1;
            state.single.refreshed = nanoid();
            state.removal.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(removeLocation.rejected, (state, action) => {
            state.removal.status = fetchStatus.FAILED;
            state.removal.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getLocationForm.pending, (state, action) => {
            state.form.status = fetchStatus.LOADING;
        }).addCase(getLocationForm.fulfilled, (state, action) => {
            state.form.data = action.payload.form;
            state.form.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getLocationForm.rejected, (state, action) => {
            state.form.status = fetchStatus.FAILED;
            state.form.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getPayrollReport.pending, (state, action) => {
            state.PayrollData.status = fetchStatus.LOADING;
        }).addCase(getPayrollReport.fulfilled, (state, action) => {
            let _options = action.payload.options;
            state.PayrollData.options = _options;
            state.PayrollData.status = fetchStatus.SUCCEEDED;
            state.PayrollData.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getPayrollReport.rejected, (state, action) => {
            state.PayrollData.status = fetchStatus.FAILED;
            state.PayrollData.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getLocationOptions.pending, (state, action) => {
            state.locationOptions.status = fetchStatus.LOADING;
        }).addCase(getLocationOptions.fulfilled, (state, action) => {
            let _options = action.payload.options;
            _options = _options.map(function (option) {
                option['value'] = option['id'];
                delete option['id'];
                option['text'] = option['LocationName'];
                delete option['LocationName'];
                return option;
            });
            state.locationOptions.options = _options;
            state.locationOptions.status = fetchStatus.SUCCEEDED;
            state.locationOptions.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getLocationOptions.rejected, (state, action) => {
            state.locationOptions.status = fetchStatus.FAILED;
            state.locationOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
            if (action.payload && action.payload.message === 'Unauthorized') {
                accountService.logout();
                window.location.reload();
            }
        }).addCase(getLocationOptionsAll.pending, (state, action) => {
            state.locationOptionsAll.status = fetchStatus.LOADING;
        }).addCase(getLocationOptionsAll.fulfilled, (state, action) => {
            let _options = action.payload.options;
            _options = _options.map(function (option) {
                option['value'] = option['id'];
                delete option['id'];
                option['text'] = option['LocationName'];
                delete option['LocationName'];
                return option;
            });
            state.locationOptionsAll.options = _options;
            state.locationOptionsAll.status = fetchStatus.SUCCEEDED;
            state.locationOptionsAll.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getLocationOptionsAll.rejected, (state, action) => {
            state.locationOptionsAll.status = fetchStatus.FAILED;
            state.locationOptionsAll.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getDashbaordLocationOptionsAll.pending, (state, action) => {
            state.locationOptionsAll.status = fetchStatus.LOADING;
        }).addCase(getDashbaordLocationOptionsAll.fulfilled, (state, action) => {
            let _options = action.payload.options;
            _options = _options.map(function (option) {
                option['value'] = option['id'];
                delete option['id'];
                option['text'] = option['LocationName'];
                delete option['LocationName'];
                return option;
            });
            state.locationOptionsAll.options = _options;
            state.locationOptionsAll.status = fetchStatus.SUCCEEDED;
            state.locationOptionsAll.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getDashbaordLocationOptionsAll.rejected, (state, action) => {
            state.locationOptionsAll.status = fetchStatus.FAILED;
            state.locationOptionsAll.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });

    }
});

export const { updateCreationStatus, updateModificationStatus, updateRemovalStatus,
    updateSingleStatus, updateIsFiltered, updateStatus, loadSingleData,
    updateCurrentPage, updateSingleData, createNewLocation, refreshLocationOptions, refreshLocationOptionsAll, showLocationFlyout, closeLocationFlyout } = locationSlice.actions;

export default locationSlice.reducer

// export const selectAllLocations = state => state.locations.data.records;
export const selectAllLocations = state => state.locations.data.items;

export const selectTotalItems = state => state.locations.data.totalItems;

export const selectStatus = state => state.locations.data.status;

export const selectError = state => state.data.locations.error;
export const selectDispatchDetail = (state) => {
    return state.locations.dispatchDetail ? state.locations.dispatchDetail.data : undefined;
}



export const selectDispatchDetailStatus = state => state.locations.dispatchDetail.status;

export const selectDispatchDetailError = state => state.locations.dispatchDetail.error;

export const selectDispatchList = (state) => {
    return state.locations.dispatchList ? state.locations.dispatchList.data : undefined;
}
export const selectDispatchListStatus = state => state.locations.dispatchList.status;

export const selectDispatchListError = state => state.locations.dispatchList.error;

export const selectDispatchCount = (state) => {
    return state.locations.dispatchCount ? state.locations.dispatchCount.data : undefined;
}
export const selectDispatchCountStatus = state => state.locations.dispatchCount.status;

export const selectDispatchCountError = state => state.locations.dispatchCount.error;


export const selectCreationStatus = state => state.locations.creation.status;

export const selectModificationStatus = state => state.locations.modification.status;

export const selectRemovalStatus = state => state.locations.removal.status;

export const selectCreationError = state => state.locations.creation.error;

export const selectModificationError = state => state.locations.modification.error;

export const selectRemovalError = state => state.locations.removal.error;

export const selectLocationById = (state) => {
    return state.locations.single ? state.locations.single.data : undefined;
}

export const selectSingleStatus = state => state.locations.single.status;

export const selectSingleError = state => state.locations.single.error;

export const selectCurrentPage = state => state.locations.data.currentPage;

export const selectFirstRecord = (state, currentPage) => {
    return state.locations.data.firstRecords.Get(currentPage);
}

export const selectSingleRefreshed = state => state.locations.single.refreshed;

export const selectLocationFormStatus = state => state.locations.form.status;

export const selectLocationFormError = state => state.locations.form.error;

export const selectLocationForm = state => state.locations.form.data;

export const selectLocationOptions = state => state.locations.locationOptions.options;

export const selectPayrollRecords = state => state.locations.PayrollData.options;


export const selectLocationOptionsStatus = state => state.locations.locationOptions.status;

export const selectLocationOptionsRefreshed = state => state.locations.locationOptions.refreshed;


export const selectLocationOptionsAll = state => state.locations.locationOptionsAll.options;
export const selectLocationOptionsAllStatus = state => state.locations.locationOptionsAll.status;

export const selectLocationOptionsAllRefreshed = state => state.locations.locationOptionsAll.refreshed;

export const selectLocationFlyoutShow = state => state.locations.show;

export const selectLocationFlyoutCreateMode = state => state.locations.createMode;

export const selectLocationFlyoutEditmode= state => state.locations.editMode;