import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    selectedFilter: 0,
    id: "",
    name: "",
    defaultFilter: false,
    where: {
        location: {
            value: [],
            text: "All"
        },
        prospectsStatus: {
            value: [],
            text: "All"
        },
        name: ""
    },
    refreshData: false
};

export const prospectsFilterSlice = createSlice({
    name: 'prospectsFilter',
    initialState,
    reducers: {
        updateProspectsRefreshData: (state, action) => {
            state.refreshData = action.payload;
        },
        updateProspectsFilter: (state, action) => {
            let { id, name, whereClause, defaultFilter } = action.payload;
            state.id = id;
            state.name = name;
            state.defaultFilter = defaultFilter;
            whereClause = whereClause ? whereClause : {
                location: {
                    value: [],
                    text: "All"
                },
                prospectsStatus: {
                    value: [],
                    text: "All"
                },
                name: ""
            };
            state.where = Object.assign({}, whereClause);
        },
        updateLocationFilter: (state, action) => {
            const { locations, value } = action.payload;
            let location = { ...state.where.location };
            let text = "All";
            if (!location.value.includes(value)) {
                location.value = [...location.value, value];
            }
            else {
                location.value = location.value.filter((val) => (val !== value));
            }

            if (value == 'All' && !location.value.includes('All')) {
                location.value = [];
            } else if (value == 'All') {
                location.value = locations.map((d) => d.value);
            } else {
                location.value = location.value.filter((val) => (val !== 'All'));
            }

            if (locations) {
                let _locations = locations.map((d) => d.value);
                _locations = _locations.filter((d) => (d !== 'All'))
                if (_locations.length === location.value.length) {
                    location.value.push('All')
                }
            }

            if (value == 'All') {
                text = "All";
            } else {
                if ((locations && Array.isArray(locations) && locations.length > 0) && location.value.length > 0) {
                    text = "";
                    for (let i = 0; i < locations.length; i++) {
                        let loc = locations[i];
                        if (location.value.includes(loc.value) && !location.value.includes('All')) {
                            text += `${loc.text},`
                        }
                    }
                    text = text.slice(0, -1);
                }
            }
            location.text = text;
            location.text = location.value.length > 0 && !location.value.includes('All') ? `(${location.value.length}) selected` : "All";
            state.where.location = location;
        },
        updateProspectsStatusFilter: (state, action) => {
            const { prospectsStatuses, value } = action.payload;
            let prospectsStatus = { ...state.where.prospectsStatus };
            let text = "All";
            if (!prospectsStatus.value.includes(value)) {
                prospectsStatus.value = [...prospectsStatus.value, value];
            }
            else {
                prospectsStatus.value = prospectsStatus.value.filter((val) => (val !== value));
            }
            if ((prospectsStatuses && Array.isArray(prospectsStatuses) && prospectsStatuses.length > 0) && prospectsStatus.value.length > 0) {
                text = "";
                for (let i = 0; i < prospectsStatuses.length; i++) {
                    let loc = prospectsStatuses[i];
                    if (prospectsStatus.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            prospectsStatus.text = text;
            prospectsStatus.text = prospectsStatus.value.length > 0 ? `(${prospectsStatus.value.length}) selected` : "All";
            state.where.prospectsStatus = prospectsStatus;
        },
        updateNameFilter: (state, action) => {
            state.where.name = action.payload;
        }
    }
});

export const { updateProspectsRefreshData, updateProspectsFilter, updateLocationFilter,
    updateProspectsStatusFilter, updateNameFilter } = prospectsFilterSlice.actions;

export default prospectsFilterSlice.reducer

export const selectLocationFilter = state => state.prospectsFilter.where.location;
export const selectProspectsStatusFilter = state => state.prospectsFilter.where.prospectsStatus;

export const selectFilterId = state => state.prospectsFilter.id;

export const selectFilterName = state => state.prospectsFilter.name;

export const selectWhereClause = state => state.prospectsFilter.where;

export const selectDefaultFilter = state => state.prospectsFilter.defaultFilter;

export const selectRefreshData = state => state.prospectsFilter.refreshData;

export const selectWhereClauseChanged = state => {
    const whereClause = state.prospectsFilter.where;
    if (whereClause.location.value.length > 0) {
        return true;
    }
    if (whereClause.prospectsStatus.value.length > 0) {
        return true;
    }
    return false;
}