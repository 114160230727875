import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    selectedFilter: 0,
    id: "",
    name: "",
    defaultFilter: false,
    where: {
        location: {
            value: [],
            text: "All"
        },
        clientStatus: {
            value: [],
            text: "All"
        },
        name: ""
    },
    refreshData: false
};

export const clientFilterSlice = createSlice({
    name: 'clientFilter',
    initialState,
    reducers: {
        updateClientRefreshData: (state, action) => {
            state.refreshData = action.payload;
        },
        updateClientFilter: (state, action) => {
            let { id, name, whereClause, defaultFilter } = action.payload;
            state.id = id;
            state.name = name;
            state.defaultFilter = defaultFilter;
            whereClause = whereClause ? whereClause : {
                location: {
                    value: [],
                    text: "All"
                },
                clientStatus: {
                    value: [],
                    text: "All"
                },
                name: ""
            };
            state.where = Object.assign({}, whereClause);
        },
        updateLocationFilter: (state, action) => {
            const { locations, value } = action.payload;
            let location = { ...state.where.location };
            let text = "All";
            if (!location.value.includes(value)) {
                location.value = [...location.value, value];
            }
            else {
                location.value = location.value.filter((val) => (val !== value));
            }
            if ((locations && Array.isArray(locations) && locations.length > 0) && location.value.length > 0) {
                text = "";
                for (let i = 0; i < locations.length; i++) {
                    let loc = locations[i];
                    if (location.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            location.text = text;
            location.text = location.value.length > 0 ? `(${location.value.length}) selected` : "All";
            state.where.location = location;
        },
        updateClientStatusFilter: (state, action) => {
            const { clientStatuses, value } = action.payload;
            let clientStatus = { ...state.where.clientStatus };
            let text = "All";
            if (!clientStatus.value.includes(value)) {
                clientStatus.value = [...clientStatus.value, value];
            }
            else {
                clientStatus.value = clientStatus.value.filter((val) => (val !== value));
            }
            if ((clientStatuses && Array.isArray(clientStatuses) && clientStatuses.length > 0) && clientStatus.value.length > 0) {
                text = "";
                for (let i = 0; i < clientStatuses.length; i++) {
                    let loc = clientStatuses[i];
                    if (clientStatus.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            clientStatus.text = text;
            clientStatus.text = clientStatus.value.length > 0 ? `(${clientStatus.value.length}) selected` : "All";
            state.where.clientStatus = clientStatus;
        },
        updateNameFilter: (state, action) => {
            state.where.name = action.payload;
        }
    }
});

export const { updateClientRefreshData, updateClientFilter, updateLocationFilter,
    updateClientStatusFilter, updateNameFilter } = clientFilterSlice.actions;

export default clientFilterSlice.reducer

export const selectLocationFilter = state => state.clientFilter.where.location;
export const selectClientStatusFilter = state => state.clientFilter.where.clientStatus;

export const selectFilterId = state => state.clientFilter.id;

export const selectFilterName = state => state.clientFilter.name;

export const selectWhereClause = state => state.clientFilter.where;

export const selectDefaultFilter = state => state.clientFilter.defaultFilter;

export const selectRefreshData = state => state.clientFilter.refreshData;

export const selectWhereClauseChanged = state => {
    const whereClause = state.clientFilter.where;
    if (whereClause.location.value.length > 0) {
        return true;
    }
    if (whereClause.clientStatus.value.length > 0) {
        return true;
    }
    return false;
}