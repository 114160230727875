import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    selectedFilter: 0,
    id: "",
    name: "",
    defaultFilter: false,
    where: {
        location: {
            value: [],
            text: "All"
        },
        client: {
            value: [],
            text: "All"

        },
        position: {
            value: [],
            text: "All"

        },
        jobDate: {
            equal: ""
        },
        jobStatus: {
            value: [],
            text: "All"
        },
        title: ""
    },
    refreshData: false
};

export const jobFilterSlice = createSlice({
    name: 'jobFilter',
    initialState,
    reducers: {
        updateJobRefreshData: (state, action) => {
            state.refreshData = action.payload;
        },
        updateJobFilter: (state, action) => {
            let { id, name, whereClause, defaultFilter } = action.payload;
            state.id = id;
            state.name = name;
            state.defaultFilter = defaultFilter;
            whereClause = whereClause ? whereClause : {
                location: {
                    value: [],
                    text: "All"
                },
                jobStatus: {
                    value: [],
                    text: "All"
                },
                client: {
                    value: [],
                    text: "All"
                },
                jobDate: {
                    equal: ""
                },
                position: {
                    value: [],
                    text: "All"
                },
                title: ""
            };
            state.where = Object.assign({}, whereClause);
        },
        updateLocationFilter: (state, action) => {
            const { locations, value } = action.payload;
            let location = { ...state.where.location };
            let text = "All";
            if (!location.value.includes(value)) {
                location.value = [...location.value, value];
            }
            else {
                location.value = location.value.filter((val) => (val !== value));
            }
            if ((locations && Array.isArray(locations) && locations.length > 0) && location.value.length > 0) {
                text = "";
                for (let i = 0; i < locations.length; i++) {
                    let loc = locations[i];
                    if (location.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            location.text = text;
            location.text = location.value.length > 0 ? `(${location.value.length}) selected` : "All";
            state.where.location = location;
        },
        updateClientFilter: (state, action) => {
            const { clients, value } = action.payload;
            let client = { ...state.where.client };
            let text = "All";
            if (!client.value.includes(value)) {
                client.value = [...client.value, value];
            }
            else {
                client.value = client.value.filter((val) => (val !== value));
            }
            if ((clients && Array.isArray(clients) && client.length > 0) && client.value.length > 0) {
                text = "";
                for (let i = 0; i < clients.length; i++) {
                    let loc = clients[i];
                    if (client.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            client.text = text;
            client.text = client.value.length > 0 ? `(${client.value.length}) selected` : "All";
            state.where.client = client;
        },

        updatePositionFilter: (state, action) => {
            const { positions, value } = action.payload;
            let position = { ...state.where.position };
            let text = "All";
            if (!position.value.includes(value)) {
                position.value = [...position.value, value];
            }
            else {
                position.value = position.value.filter((val) => (val !== value));
            }
            if ((positions && Array.isArray(positions) && position.length > 0) && position.value.length > 0) {
                text = "";
                for (let i = 0; i < positions.length; i++) {
                    let loc = positions[i];
                    if (position.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            position.text = text;
            position.text = position.value.length > 0 ? `(${position.value.length}) selected` : "All";
            state.where.position = position;
        },
        updateJobDateFilter: (state, action) => {
            state.where.jobDate.equal = action.payload;
        },

        updateJobStatusFilter: (state, action) => {
            const { jobStatuss, value } = action.payload;
            let jobStatus = { ...state.where.jobStatus };
            let text = "All";
            if (!jobStatus.value.includes(value)) {
                jobStatus.value = [...jobStatus.value, value];
            }
            else {
                jobStatus.value = jobStatus.value.filter((val) => (val !== value));
            }
            if ((jobStatuss && Array.isArray(jobStatuss) && jobStatus.length > 0) && jobStatus.value.length > 0) {
                text = "";
                for (let i = 0; i < jobStatuss.length; i++) {
                    let loc = jobStatuss[i];
                    if (jobStatus.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            jobStatus.text = text;
            jobStatus.text = jobStatus.value.length > 0 ? `(${jobStatus.value.length}) selected` : "All";
            state.where.jobStatus = jobStatus;
        },

        updateTitleFilter: (state, action) => {
            state.where.title = action.payload;
        }
    }
});

export const { updateJobRefreshData, updateJobFilter, updateClientFilter, updateLocationFilter, updateSelectedFilter, updatePositionFilter, updateJobDateFilter, updateJobStatusFilter,
    updateTitleFilter } = jobFilterSlice.actions;

export default jobFilterSlice.reducer





export const selectTitleFilter = state => state.jobFilter.title;

export const selectDefaultFilter = state => state.jobFilter.defaultFilter;

export const selectLocationFilter = state => state.jobFilter.where.location;

export const selectClientFilter = state => state.jobFilter.where.client;
export const selectJobDateFilter = state => state.jobFilter.where.jobDate;
export const selectJobStatusFilter = state => state.jobFilter.where.jobStatus;

export const selectPositionFilter = state => state.jobFilter.where.position;

export const selectRefreshData = state => state.jobFilter.refreshData;

export const selectFilterId = state => state.jobFilter.id;

export const selectFilterName = state => state.jobFilter.name;

export const selectWhereClause = state => state.jobFilter.where;

export const selectWhereClauseChanged = state => {
    const whereClause = state.jobFilter.where;
    if (whereClause.location.value.length > 0) {
        return true;
    }
    if (whereClause.client.value.length > 0) {
        return true;
    }
    if (whereClause.position.value.length > 0) {
        return true;
    }
    if (whereClause.jobDate.equal !== "") {
        return true;
    }
    if (whereClause.jobStatus.value.length > 0) {
        return true;
    }
    return false;
}