import React from 'react';
import ReactTooltip from 'react-tooltip';

function SaveControls(props) {

    return (
        <>
            <div className='c-icon-box' onClick={(e) => props.onSaveClicked && props.onSaveClicked(e)} data-tip='Save'>
                <i className={`fa fa-solid fa-floppy-disk ${props.formInvalid ? "text-danger" : ""}`}></i>
            </div>
            <div className='c-icon-box' onClick={() => props.onCancelClicked && props.onCancelClicked()} data-tip='Cancel saving'>
                <i className='fa fa-times'></i>
            </div>
            <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
        </>
    );
}

export default SaveControls;