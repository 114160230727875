// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import axios from 'axios';

export const BajoAPI = axios.create({

    // baseURL: `https://landerr-testapi.azurewebsites.net/api/` //Test Enviroment 
    baseURL: `https://hirebaseapi.azurewebsites.net/api/` //Hirebase Demo Enviroment 
    // baseURL: `https://localhost:44377/api/` //local Enviroment 
    // baseURL: `/fakeApi/`
    //baseURL: `https://localhost:5001/api/`
});
// export const SignalrUrl = 'https://landerr-testapi.azurewebsites.net/hubs/chat'; //Test Enviroment 
export const SignalrUrl = 'http://hirebaseapi.azurewebsites.net/hubs/chat'; //Hirebase Demo Enviroment 
//export const SignalrUrl = 'http://localhost:44377/hubs/chat'; //local Enviroment 

export const fetchStatus = {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    DONE: 'done'
}

export const masterTenantId = "HireBase";

// const subDomainName = window.location.host.split('.')[0];
// export const tenantId = subDomainName.charAt(0).toUpperCase() + subDomainName.slice(1);  //  Live Enviroment
// 
// export const tenantId = "HireBase";       // Test Enviroment                                             
// export const tenantId = "ztech";       // Test Enviroment                                             
// export const tenantId = "HireBaseDemo";  // Test Enviroment 

// export const tenantId = "Worca";            // Test Enviroment 


const subDomainName = window.location.host.split('.')[0].toLowerCase();

export const tenantId =
    subDomainName === 'hirebase' || subDomainName === 'hirebaseproto' ? 'HireBase' :
        subDomainName === 'worca' ? 'Worca' :
            subDomainName === 'localhost:3000' ? 'HireBase' :                // Local Environment
                subDomainName;




// export async function client(endpoint, { body, ...customConfig } = {}) {
//   const headers = { 'Content-Type': 'application/json' }

//   const config = {
//     method: body ? 'POST' : 'GET',
//     ...customConfig,
//     headers: {
//       ...headers,
//       ...customConfig.headers,
//     },
//   }

//   if (body) {
//     config.body = JSON.stringify(body)
//   }

//   let data
//   try {
//     const response = await window.fetch(endpoint, config)
//     data = await response.json()
//     if (response.ok) {
//       return data
//     }
//     throw new Error(response.statusText)
//   } catch (err) {
//     return Promise.reject(err.message ? err.message : data)
//   }
// }

// client.get = function (endpoint, customConfig = {}) {
//   return client(endpoint, { ...customConfig, method: 'GET' })
// }

// client.post = function (endpoint, body, customConfig = {}) {
//   return client(endpoint, { ...customConfig, body })
// }