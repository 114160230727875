import {
    createSlice,
    createAsyncThunk,
    nanoid
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { JobPositionStatus } from '../../../common/constants';
import Collection from '../../../component/Controls/paginators/collection';
import { refreshCreated } from '../../../component/Controls/paginators/services/createdResource';
import { refreshRemoved } from '../../../component/Controls/paginators/services/removedResource';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    form: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    removal: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    },
    jobOptions: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    clients: {
        status: fetchStatus.IDLE,
        error: undefined,
        data: undefined
    },
    reportToPersons: {
        status: fetchStatus.IDLE,
        error: undefined,
        data: undefined
    },
    client: {
        status: fetchStatus.IDLE,
        error: undefined,
        data: undefined
    },
    selectedJobApplicant: {
        status: fetchStatus.IDLE,
        error: undefined,
        data: undefined
    },
    recentClickedJob: {
        id: undefined
    }
};

export const getAllJobs = createAsyncThunk('jobs/getAllJobs', async (jobModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', jobModel.model, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let jobs;
    if (isJSON(data)) {
        jobs = JSON.parse(data);
    }
    return {
        page: jobModel.page,
        jobs: jobs,
        success: response.data.success
    };
});
export const getClients = createAsyncThunk('jobs/getClients', async (model) => {
    const response = await BajoAPI.post('Gateway', model, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let clients;
    if (isJSON(data)) {
        clients = JSON.parse(data);
    }
    return clients;
});

export const getReportToPersons = createAsyncThunk('jobs/getReportToPersons', async (model) => {
    const response = await BajoAPI.post('Gateway', model, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let _reportToPersons;
    if (isJSON(data)) {
        _reportToPersons = JSON.parse(data);
    }
    return _reportToPersons;
});

export const getClientById = createAsyncThunk('jobs/getClientById', async (model) => {
    const response = await BajoAPI.post('Gateway', model, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let _client;
    if (isJSON(data)) {
        _client = JSON.parse(data);
    }
    return _client;
});

export const getJobById = createAsyncThunk('jobs/getJobById', async (jobModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, jobModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let job = undefined;
        if (data && isJSON(data)) {
            job = JSON.parse(data);
        }
        return {
            page: jobModel.page,
            job: job,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

// export const getJobById = createAsyncThunk('jobs/getJobById', async (jobModel, { rejectWithValue }) => {
//     const response = await BajoAPI.post(`Gateway`, jobModel.model, getAxiosRequestConfig());
//     const data = response.data ? response.data.data : undefined;
//     let job = undefined;
//     if (data && isJSON(data)) {
//         job = JSON.parse(data);
//     }
//     return {
//         page: jobModel.page,
//         job: job,
//         success: response.data.success
//     };;
// });

export const getJobForm = createAsyncThunk('jobs/getJobForm', async (jobModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, jobModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let form = undefined;
    if (data && isJSON(data)) {
        form = JSON.parse(data);
    }
    return {
        form: form,
        success: response.data.success
    };
});

export const createJob = createAsyncThunk('jobs/createJob', async (jobModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, jobModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let job = undefined;
    if (data && isJSON(data)) {
        job = JSON.parse(data);
    }
    return {
        job: job,
        success: response.data.success
    };
});

export const updateJob = createAsyncThunk('jobs/updateJob', async (jobModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, jobModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let job = undefined;
    if (data && isJSON(data)) {
        job = JSON.parse(data);
    }
    return {
        job: job,
        success: response.data.success
    };
});

export const removeJob = createAsyncThunk('jobs/removeJob', async (jobModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, jobModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    return {
        id: data,
        success: response.data.success
    };
});

export const getJobOptions = createAsyncThunk('jobs/getJobOptions', async (jobModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', jobModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let options;
    if (isJSON(data)) {
        options = JSON.parse(data);
    }
    return {
        options: options,
        success: response.data.success
    };
});

export const getJobApplicantById = createAsyncThunk('applicants/getJobApplicantById', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
        }
        return {
            applicant: applicant
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const jobSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateRemovalStatus: (state) => {
            state.removal.status = fetchStatus.IDLE;
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        updateClientStatus: (state) => {
            state.client.status = fetchStatus.IDLE;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateSingleData: (state, _data) => {
            state.single.refreshed = nanoid();
            state.single.data = _data.payload ? Object.assign({}, _data.payload) : _data.payload;
            if (_data.payload) {
                //console.log(_data.payload);
                let _item = state.data.records.Get(state.data.currentPage);
                if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                    let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.id);
                    let newRecords = _item.records;
                    let _job = {
                        Id: _data.payload.id,
                        Title: _data.payload.Title.Data,
                        Status: _data.payload.Status.Data,
                        Filled: _data.payload.TotalFilled,
                        Total: _data.payload.TotalQuantity,
                        ReportTo: _data.payload.ReportTo.Data,
                    };
                    newRecords[_updatedObjectIndex] = _job;
                    _item.records = newRecords;
                    let records = new Collection();
                    records.Add(_item.key, newRecords);
                    state.data.records.Remove(_item.key);
                    records.Concat(state.data.records);
                    state.data.records = records;
                }
            }
        },
        createNewJob: state => {
            state.single.data = {
                "Title": {
                    "Id": "txtTitle",
                    "Data": ""
                },
                "Status": {
                    "Id": "txtStatus",
                    "Data": "New"
                },
                "Location": {
                    "Id": "txtLocation",
                    "Data": ""
                },
                "RequestDate": {
                    "Id": "txtRequestDate",
                    "Data": ""
                },
                "Client": {
                    "Id": "txtClient",
                    "Data": ""
                },
                "StartDate": {
                    "Id": "txtStartDate",
                    "Data": ""
                },
                "ReportTo": {
                    "Id": "txtReportTo",
                    "Data": ""
                },
                "Address": {
                    "Id": "txtAddress",
                    "Data": ""
                },
                "City": {
                    "Id": "txtCity",
                    "Data": ""
                },
                "State": {
                    "Id": "txtState",
                    "Data": ""
                },
                "ZipCode": {
                    "Id": "txtZipCode",
                    "Data": ""
                },
                "Positions": [

                ],
                "Notes": {
                    "Id": "txtNotes",
                    "Data": ""
                },
                "StartTime": {
                    "Id": "txtStartTime",
                    "Data": ""
                },
                "DressCode": {
                    "Id": "txtDressCode",
                    "Data": ""
                },
                "CreatedDate": "",
                "ModifiedDate": "",
                "TotalQuantity": 0,
                "TotalFilled": 0
            };
        },
        refreshJobOptions: (state) => {
            state.jobOptions.refreshed = true;
        },
        createPositionDetail: (state) => {
            const detail = getPositionDetailObject();
            state.single.data = Object.assign({}, state.single.data);
            state.single.data.Positions.push(detail);
        },
        deletePositionDetail: (state, action) => {
            const id = action.payload;
            state.single.data.Positions = state.single.data.Positions.filter(d => d.id !== id);
            state.single.data = Object.assign({}, state.single.data);
        },
        updateReportToPersons: (state, action) => {
            const reportTo = action.payload;
            const exists = state.reportToPersons.data.findIndex(r => r.name === reportTo);
            if (exists === -1) {
                state.reportToPersons.data = [].concat(state.reportToPersons.data);
                state.reportToPersons.data.push({
                    id: "",
                    name: reportTo
                })
            }
        },
        updateRecentClickedJob: (state, _data) =>{
            state.recentClickedJob.id=  _data.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllJobs.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        }).addCase(getAllJobs.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.jobs.totalItems;
            let _records = new Collection();
            _records.Add(action.payload.page, action.payload.jobs.items);
            if (!state.data.isFiltered) {
                _records.Concat(state.data.records);
            }
            else {
                state.data.firstRecords = new Collection();
                state.data.currentPage = 0;
            }
            state.data.isFiltered = false;
            state.data.records = _records;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getAllJobs.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.data.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getJobById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getJobById.fulfilled, (state, action) => {
            console.log('job ==> ', action.payload.job)
            state.single.data = action.payload.job;
            if (action.payload.page >= 0) {
                let _records = new Collection();
                _records.Add(action.payload.page, action.payload.job);
                _records.Concat(state.data.firstRecords);
                state.data.firstRecords = _records;
            }
            state.single.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getJobById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(createJob.pending, (state, action) => {
            state.creation.status = fetchStatus.LOADING;
        }).addCase(createJob.fulfilled, (state, action) => {
            if (action.payload.job) {
                state.single.data = action.payload.job;
                let _job = {
                    Id: action.payload.job.id,
                    Title: action.payload.job.Title.Data,
                    Status: action.payload.job.Status.Data,
                    Filled: action.payload.job.TotalFilled,
                    Total: action.payload.job.TotalQuantity
                };
                const created = refreshCreated(state.data.records, _job);
                state.data.records = created.records;
                state.data.currentPage = created.lastPage;
                state.data.totalItems += 1;
                state.single.refreshed = nanoid();
            }
            state.creation.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createJob.rejected, (state, action) => {
            state.creation.status = fetchStatus.FAILED;
            state.creation.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateJob.pending, (state, action) => {
            state.modification.status = fetchStatus.LOADING;
        }).addCase(updateJob.fulfilled, (state, action) => {
            state.single.data = action.payload.job;
            state.modification.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateJob.rejected, (state, action) => {
            state.modification.status = fetchStatus.FAILED;
            state.modification.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(removeJob.pending, (state, action) => {
            state.removal.status = fetchStatus.LOADING;
        }).addCase(removeJob.fulfilled, (state, action) => {
            const removed = refreshRemoved(state.data.records, action.payload.id, state.data.currentPage);
            state.data.records = removed.records;
            state.data.currentPage = removed.lastPage;
            state.data.totalItems -= 1;
            state.single.refreshed = nanoid();
            state.removal.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(removeJob.rejected, (state, action) => {
            state.removal.status = fetchStatus.FAILED;
            state.removal.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getJobForm.pending, (state, action) => {
            state.form.status = fetchStatus.LOADING;
        }).addCase(getJobForm.fulfilled, (state, action) => {
            state.form.data = action.payload.form;
            state.form.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getJobForm.rejected, (state, action) => {
            state.form.status = fetchStatus.FAILED;
            state.form.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getJobOptions.pending, (state, action) => {
            state.jobOptions.status = fetchStatus.LOADING;
        }).addCase(getJobOptions.fulfilled, (state, action) => {
            let _options = action.payload.options;
            _options = _options.map(function (option) {
                option['value'] = option['id'];
                delete option['id'];
                option['text'] = option['JobName'];
                delete option['JobName'];
                return option;
            });
            state.jobOptions.options = _options;
            state.jobOptions.status = fetchStatus.SUCCEEDED;
            state.jobOptions.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getJobOptions.rejected, (state, action) => {
            state.jobOptions.status = fetchStatus.FAILED;
            state.jobOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getClients.pending, (state, action) => {
            state.clients.status = fetchStatus.LOADING;
        }).addCase(getClients.fulfilled, (state, action) => {
            state.clients.data = action.payload;
            state.clients.status = fetchStatus.SUCCEEDED;
        }).addCase(getClients.rejected, (state, action) => {
            state.clients.status = fetchStatus.FAILED;
            state.clients.error = action.error.message;
        }).addCase(getReportToPersons.pending, (state, action) => {
            state.reportToPersons.status = fetchStatus.LOADING;
        }).addCase(getReportToPersons.fulfilled, (state, action) => {
            state.reportToPersons.data = action.payload;
            state.reportToPersons.status = fetchStatus.SUCCEEDED;
        }).addCase(getReportToPersons.rejected, (state, action) => {
            state.reportToPersons.status = fetchStatus.FAILED;
            state.reportToPersons.error = action.error.message;
        }).addCase(getClientById.pending, (state, action) => {
            state.client.status = fetchStatus.LOADING;
        }).addCase(getClientById.fulfilled, (state, action) => {
            state.client.data = action.payload;
            state.client.status = fetchStatus.SUCCEEDED;
        }).addCase(getClientById.rejected, (state, action) => {
            state.client.status = fetchStatus.FAILED;
            state.client.error = action.error.message;
        }).addCase(getJobApplicantById.pending, (state, action) => {
            state.selectedJobApplicant.status = fetchStatus.LOADING;
        }).addCase(getJobApplicantById.fulfilled, (state, action) => {
            state.selectedJobApplicant.data = action.payload.applicant;
            state.selectedJobApplicant.status = fetchStatus.SUCCEEDED;
        }).addCase(getJobApplicantById.rejected, (state, action) => {
            state.selectedJobApplicant.status = fetchStatus.FAILED;
            state.selectedJobApplicant.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});


export const { updateCreationStatus, updateModificationStatus, updateRemovalStatus,
    updateSingleStatus, updateIsFiltered, updateStatus, loadSingleData,
    updateCurrentPage, updateSingleData, createNewJob, refreshJobOptions,
    createPositionDetail, deletePositionDetail, updateReportToPersons , updateRecentClickedJob, updateClientStatus} = jobSlice.actions;

export default jobSlice.reducer

export const selectAllJobs = state => state.jobs.data.records;

export const selectTotalItems = state => state.jobs.data.totalItems;

export const selectStatus = state => state.jobs.data.status;

export const selectError = state => state.data.jobs.error;

export const selectCreationStatus = state => state.jobs.creation.status;

export const selectModificationStatus = state => state.jobs.modification.status;

export const selectRemovalStatus = state => state.jobs.removal.status;

export const selectCreationError = state => state.jobs.creation.error;

export const selectModificationError = state => state.jobs.modification.error;

export const selectRemovalError = state => state.jobs.removal.error;

export const selectJobById = (state) => {
    return state.jobs.single ? state.jobs.single.data : undefined;
}

export const selectSingleStatus = state => state.jobs.single.status;

export const selectSingleError = state => state.jobs.single.error;


// export const selectCurrentPage = state => state.jobs.data.currentPage;

// export const selectCurrentPage = state => {
//     return state.jobs.data.currentPage;
// }
export const selectCurrentPage = state => state.jobs.data.currentPage;


export const selectFirstRecord = (state, currentPage) => {
    return state.jobs.data.firstRecords.Get(currentPage);
}

export const selectSingleRefreshed = state => state.jobs.single.refreshed;

export const selectJobFormStatus = state => state.jobs.form.status;

export const selectJobFormError = state => state.jobs.form.error;

export const selectJobForm = state => state.jobs.form.data;

export const selectJobOptions = state => state.jobs.jobOptions.options;

export const selectJobOptionsStatus = state => state.jobs.jobOptions.status;

export const selectJobOptionsRefreshed = state => state.jobs.jobOptions.refreshed;

// export const selectLocationFilter = state => state.filter.where.location;


// export const selectClientFilter = state => state.filter.where.client;
// export const selectPositionFilter = state => state.filter.where.position;
// export const selectClientOptions = state => state.clients.clientOptions.options;

// export const selectJobDetail = state => {
//     return {
//         title: state.job.title,
//         id: state.jobs.id,
//         job: state.jobs.job,
//         updatedJob: state.job.updatedJob,
//         status: state.jobs.status,
//         show: state.jobs.show
//     }
// }

// export const selectCanceledStatus = state => state.jobs.canceled;

export const selectClients = state => state.jobs.clients.data;

export const selectClientsStatus = state => state.jobs.clients.status;

export const selectReportToPersons = state => state.jobs.reportToPersons.data;

export const selectReportToPersonsStatus = state => state.jobs.reportToPersons.status;

export const selectClient = state => state.jobs.client.data;

export const selectClientStatus = state => state.jobs.client.status;

export const selectJobApplicant = state => state.jobs.selectedJobApplicant.data;

export const selectJobApplicantStatus = state => state.jobs.selectedJobApplicant.status;

export const selectJobApplicantError = state => state.jobs.selectedJobApplicant.error;

const getPositionDetailObject = () => {
    const id = nanoid().split('-').join('');
    //id = id.split('-').join('');
    console.log(id);
    return {
        "id": id,
        "Position": {
            "Id": `drpPositions-${id}`,
            "Data": ""
        },
        "PositionDescription": {
            "Id": `txtPositionDesc-${id}`,
            "Data": ""
        },
        "JobType": {
            "Id": `rdbtnJT-${id}`,
            "Data": ""
        },
        "Quantity": {
            "Id": `txtQuantity-${id}`,
            "Data": ""
        },
        "PayRates": {
            "Id": `txtPayRates-${id}`,
            "Data": ""
        },
        "Status": {
            "Id": `txtStatus-${id}`,
            "Data": JobPositionStatus.NOTFILLED
        },
        "Filled": 0
    }
}

export const selectRecentClickedJob = state => state.jobs.recentClickedJob.id;
