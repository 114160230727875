import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    title: "",
    content: "",
    confirmedCallback: undefined,
    show: false,
    id: ""
};

export const confirmAlertSlice = createSlice({
    name: 'confirmAlert',
    initialState,
    reducers: {
        show: (state, action) => {
            const { id, title, content, confirmedCallback } = action.payload
            state.id = id;
            state.title = title;
            state.content = content;
            state.show = true;
            state.confirmedCallback = confirmedCallback;
        },
        close: state => {
            state.id = "";
            state.confirmedCallback = undefined;
            state.show = false;
        }
    }
});

export const { show, close } = confirmAlertSlice.actions;

export default confirmAlertSlice.reducer

export const selectConfirmAlertDetail = state => {
    return {
        id: state.confirmAlert.id,
        title: state.confirmAlert.title,
        content: state.confirmAlert.content,
        show: state.confirmAlert.show,
        confirmedCallback: state.confirmAlert.confirmedCallback
    }
}