export const sections = {
    CLIENT_DETAILS: 0
};

export const onValueChanged = (client, e, section, value) => {
    let _client = JSON.parse(JSON.stringify(client));
    if (section == sections.CLIENT_DETAILS) {
        return _saveClientDetails(_client, e, value);
    }
};

const _saveClientDetails = (client, e, value) => {
    if (e.target.name === "txtClientName") {
        client.ClientName.Data = e.target.value;
    }
    else if (e.target.name === "txtClientNumber") {
        client.ClientNumber.Data = e.target.value;
    }
    else if (e.target.name === "drpStatuses") {
        client.Status.Data = e.target.value;
    }
    else if (e.target.name === "drpLocations") {
        client.Location.Data = e.target.value;
    }
    else if (e.target.name === "txtAddress") {
        if (!client.Address) {
            client.Address = {
                "Id": "txtAddress",
                "Data": ""
            }
        }
        client.Address.Data = e.target.value;
    }
    else if (e.target.name === "txtCity") {
        if (!client.City) {
            client.City = {
                "Id": "txtCity",
                "Data": ""
            }
        }
        client.City.Data = e.target.value;
    }
    else if (e.target.name === "txtState") {
        if (!client.State) {
            client.State = {
                "Id": "txtState",
                "Data": ""
            }
        }
        client.State.Data = e.target.value;
    }
    else if (e.target.name === "txtZipCode") {
        if (!client.ZipCode) {
            client.ZipCode = {
                "Id": "txtZipCode",
                "Data": ""
            }
        }
        client.ZipCode.Data = e.target.value;
    }
    else if (e.target.name === "txtCompanyWebsite") {
        if (!client.CompanyWebsite) {
            client.CompanyWebsite = {
                "Id": "txtCompanyWebsite",
                "Data": ""
            }
        }
        client.CompanyWebsite.Data = e.target.value;
    }
    else if (e.target.name === "txtClientPhone") {
        if (!client.ClientPhone) {
            client.ClientPhone = {
                "Id": "txtClientPhone",
                "Data": ""
            }
        }
        client.ClientPhone.Data = e.target.value;
    }
    return client;
};