import {
    createSlice,
    createAsyncThunk
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { FilterType } from '../../../common/constants';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';
import { applicantStatusesData } from '../../shared/services/masterData';

const initialState = {
    data: [],
    status: fetchStatus.IDLE,
    error: null,
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    removal: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    },
    qrCode: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    applicantPassword: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    note: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    statusChange: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    documentUpload: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    documentDelete: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    documentDownload: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    formDownload: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    saveFilter: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        filterType: FilterType.NONE,
        savedFilterId: ""
    },
    removeFilter: {
        status: fetchStatus.IDLE,
        error: null,
        data: ""
    },
    setApplicantOpened: {
        status: fetchStatus.IDLE,
        error: null,
        data: ""
    },
    ApplicantCalledOn: {
        status: fetchStatus.IDLE,
        error: null,
        data: ""
    },
    userFilters: {
        status: fetchStatus.IDLE,
        error: null,
        data: []
    },
    defaultFilters: {
        status: fetchStatus.IDLE,
        error: null,
        data: [{
            id: "2D98B0C0-5E22-45FE-BA2C-D9E408D982BA",
            name: "In Progress",
            whereClause: {
                "location": {
                    "value": [],
                    "text": "All"
                },
                "shift": {
                    "value": [],
                    "text": "All"
                },
                "workStatus": {
                    "value": [
                        "Incomplete"
                    ],
                    "text": "(1) selected"
                },
                "position": {
                    "value": [],
                    "text": "All"
                },
                "employmentType": {
                    "value": [],
                    "text": "All"
                },
                "ethinicity": {
                    "value": [],
                    "text": "All"
                },
                "maritalStatus": {
                    "value": [],
                    "text": "All"
                },
                "gender": {
                    "value": [],
                    "text": "All"
                },
                "appliedDate": {
                    "equal": "",
                    "between": {
                        "start": "",
                        "end": ""
                    }
                },
                language: {
                    value: [],
                    text: "All"
                },
                flexibility: "-1",
                calledOn: false,
                availableForOT: "-1",
                willingToComute: "-1",
                fastPacedEnv: "-1",
                standingLongPeriod: "-1",
                workInHotTemp: "-1",
                repetitiveMov: "-1",
                workinColdTemp: "-1",
                heavyLifting: "-1",
                wentOverSafetyProtocol: "-1",
                wentOverUniformProtocol: "-1",
                noShowPolicy: "-1",
                timeClockPolicy: "-1",
                weekPayPolicy: "-1",
                stillToeBoots: "-1",
                slipResistantBoots: "-1",
                workWeekends: "-1",
                mandateLunch: "-1",
                oneDayAssignment: "-1",
                ReadEnglish: "-1",
                WriteEnglish: "-1",
                WriteSpanish: "-1",
                ReadSpanish: "-1",
                BasicMathSkills: "-1",
                CovidVaccinated: "-1",
                generalLaber: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                shippingReceiving: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                packagingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                industrialSewing: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                rfScanning: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                assemblyLine: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                foodProduction: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                machineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                supervisorLead: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                maintainanceMechanic: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                electrician: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                sanitation: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                qualityAssurance: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                forkLiftOperator: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    },
                    havingCertificate: "-1"
                },
                security: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                computer: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelMachineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelLoader: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                screenPrinting: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                ticketingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                }
            },
            filterType: FilterType.APPLICANT
        },
        {
            id: "3343FE89-9898-4CB0-9369-13AC9AD5D40B",
            name: "In Progress",
            whereClause: {
                "location": {
                    "value": [],
                    "text": "All"
                },
                "shift": {
                    "value": [],
                    "text": "All"
                },
                "workStatus": {
                    "value": [
                        // "In Progress",
                        applicantStatusesData.InProgress,
                        "Incomplete"
                    ],
                    "text": "(1) selected"
                },
                "position": {
                    "value": [],
                    "text": "All"
                },
                "employmentType": {
                    "value": [],
                    "text": "All"
                },
                "ethinicity": {
                    "value": [],
                    "text": "All"
                },
                "maritalStatus": {
                    "value": [],
                    "text": "All"
                },
                "gender": {
                    "value": [],
                    "text": "All"
                },
                "appliedDate": {
                    "equal": "",
                    "between": {
                        "start": "",
                        "end": ""
                    }
                },
                language: {
                    value: [],
                    text: "All"
                },
                flexibility: "-1",
                calledOn: false,
                availableForOT: "-1",
                willingToComute: "-1",
                fastPacedEnv: "-1",
                standingLongPeriod: "-1",
                workInHotTemp: "-1",
                repetitiveMov: "-1",
                workinColdTemp: "-1",
                heavyLifting: "-1",
                wentOverSafetyProtocol: "-1",
                wentOverUniformProtocol: "-1",
                noShowPolicy: "-1",
                timeClockPolicy: "-1",
                weekPayPolicy: "-1",
                stillToeBoots: "-1",
                slipResistantBoots: "-1",
                workWeekends: "-1",
                mandateLunch: "-1",
                oneDayAssignment: "-1",
                ReadEnglish: "-1",
                WriteEnglish: "-1",
                WriteSpanish: "-1",
                ReadSpanish: "-1",
                BasicMathSkills: "-1",
                CovidVaccinated: "-1",
                generalLaber: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                shippingReceiving: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                packagingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                industrialSewing: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                rfScanning: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                assemblyLine: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                foodProduction: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                machineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                supervisorLead: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                maintainanceMechanic: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                electrician: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                sanitation: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                qualityAssurance: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                forkLiftOperator: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    },
                    havingCertificate: "-1"
                },
                security: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                computer: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelMachineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelLoader: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                screenPrinting: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                ticketingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                }
            },
            filterType: FilterType.APPLICANT
        },
        {
            id: "1C634E95-61BB-4831-9983-873743395DE6",
            name: "Available",
            whereClause: {
                "location": {
                    "value": [],
                    "text": "All"
                },
                "shift": {
                    "value": [],
                    "text": "All"
                },
                "workStatus": {
                    "value": [
                        // "Available"
                        applicantStatusesData.Available
                    ],
                    "text": "(1) selected"
                },
                "position": {
                    "value": [],
                    "text": "All"
                },
                "employmentType": {
                    "value": [],
                    "text": "All"
                },
                "ethinicity": {
                    "value": [],
                    "text": "All"
                },
                "maritalStatus": {
                    "value": [],
                    "text": "All"
                },
                "gender": {
                    "value": [],
                    "text": "All"
                },
                "appliedDate": {
                    "equal": "",
                    "between": {
                        "start": "",
                        "end": ""
                    }
                },
                language: {
                    value: [],
                    text: "All"
                },
                flexibility: "-1",
                calledOn: false,
                availableForOT: "-1",
                willingToComute: "-1",
                fastPacedEnv: "-1",
                standingLongPeriod: "-1",
                workInHotTemp: "-1",
                repetitiveMov: "-1",
                workinColdTemp: "-1",
                heavyLifting: "-1",
                wentOverSafetyProtocol: "-1",
                wentOverUniformProtocol: "-1",
                noShowPolicy: "-1",
                timeClockPolicy: "-1",
                weekPayPolicy: "-1",
                stillToeBoots: "-1",
                slipResistantBoots: "-1",
                workWeekends: "-1",
                mandateLunch: "-1",
                oneDayAssignment: "-1",
                ReadEnglish: "-1",
                WriteEnglish: "-1",
                WriteSpanish: "-1",
                ReadSpanish: "-1",
                BasicMathSkills: "-1",
                CovidVaccinated: "-1",
                generalLaber: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                shippingReceiving: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                packagingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                industrialSewing: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                rfScanning: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                assemblyLine: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                foodProduction: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                machineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                supervisorLead: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                maintainanceMechanic: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                electrician: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                sanitation: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                qualityAssurance: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                forkLiftOperator: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    },
                    havingCertificate: "-1"
                },
                security: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                computer: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelMachineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelLoader: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                screenPrinting: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                ticketingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                }
            },
            filterType: FilterType.APPLICANT
        },
        {
            id: "5305FC65-B4C4-4C59-9E0D-8405646B1BE6",
            name: "Active",
            whereClause: {
                "location": {
                    "value": [],
                    "text": "All"
                },
                "shift": {
                    "value": [],
                    "text": "All"
                },
                "workStatus": {
                    "value": [
                        // "Active"
                        applicantStatusesData.Active
                    ],
                    "text": "(1) selected"
                },
                "position": {
                    "value": [],
                    "text": "All"
                },
                "employmentType": {
                    "value": [],
                    "text": "All"
                },
                "ethinicity": {
                    "value": [],
                    "text": "All"
                },
                "maritalStatus": {
                    "value": [],
                    "text": "All"
                },
                "gender": {
                    "value": [],
                    "text": "All"
                },
                "appliedDate": {
                    "equal": "",
                    "between": {
                        "start": "",
                        "end": ""
                    }
                },
                language: {
                    value: [],
                    text: "All"
                },
                flexibility: "-1",
                calledOn: false,
                availableForOT: "-1",
                willingToComute: "-1",
                fastPacedEnv: "-1",
                standingLongPeriod: "-1",
                workInHotTemp: "-1",
                repetitiveMov: "-1",
                workinColdTemp: "-1",
                heavyLifting: "-1",
                wentOverSafetyProtocol: "-1",
                wentOverUniformProtocol: "-1",
                noShowPolicy: "-1",
                timeClockPolicy: "-1",
                weekPayPolicy: "-1",
                stillToeBoots: "-1",
                slipResistantBoots: "-1",
                workWeekends: "-1",
                mandateLunch: "-1",
                oneDayAssignment: "-1",
                ReadEnglish: "-1",
                WriteEnglish: "-1",
                WriteSpanish: "-1",
                ReadSpanish: "-1",
                BasicMathSkills: "-1",
                CovidVaccinated: "-1",
                generalLaber: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                shippingReceiving: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                packagingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                industrialSewing: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                rfScanning: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                assemblyLine: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                foodProduction: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                machineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                supervisorLead: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                maintainanceMechanic: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                electrician: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                sanitation: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                qualityAssurance: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                forkLiftOperator: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    },
                    havingCertificate: "-1"
                },
                security: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                computer: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelMachineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelLoader: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                screenPrinting: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                ticketingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                }
            },
            filterType: FilterType.APPLICANT
        },
        {
            id: "55CEF3E8-6F0E-4696-ABDF-3929F71923D9",
            name: "InActive",
            whereClause: {
                "location": {
                    "value": [],
                    "text": "All"
                },
                "shift": {
                    "value": [],
                    "text": "All"
                },
                "workStatus": {
                    "value": [
                        // "InActive"
                        applicantStatusesData.InActive
                    ],
                    "text": "(1) selected"
                },
                "position": {
                    "value": [],
                    "text": "All"
                },
                "employmentType": {
                    "value": [],
                    "text": "All"
                },
                "ethinicity": {
                    "value": [],
                    "text": "All"
                },
                "maritalStatus": {
                    "value": [],
                    "text": "All"
                },
                "gender": {
                    "value": [],
                    "text": "All"
                },
                "appliedDate": {
                    "equal": "",
                    "between": {
                        "start": "",
                        "end": ""
                    }
                },
                language: {
                    value: [],
                    text: "All"
                },
                flexibility: "-1",
                calledOn: false,
                availableForOT: "-1",
                willingToComute: "-1",
                fastPacedEnv: "-1",
                standingLongPeriod: "-1",
                workInHotTemp: "-1",
                repetitiveMov: "-1",
                workinColdTemp: "-1",
                heavyLifting: "-1",
                wentOverSafetyProtocol: "-1",
                wentOverUniformProtocol: "-1",
                noShowPolicy: "-1",
                timeClockPolicy: "-1",
                weekPayPolicy: "-1",
                stillToeBoots: "-1",
                slipResistantBoots: "-1",
                workWeekends: "-1",
                mandateLunch: "-1",
                oneDayAssignment: "-1",
                ReadEnglish: "-1",
                WriteEnglish: "-1",
                WriteSpanish: "-1",
                ReadSpanish: "-1",
                BasicMathSkills: "-1",
                CovidVaccinated: "-1",
                generalLaber: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                shippingReceiving: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                packagingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                industrialSewing: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                rfScanning: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                assemblyLine: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                foodProduction: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                machineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                supervisorLead: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                maintainanceMechanic: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                electrician: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                sanitation: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                qualityAssurance: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                forkLiftOperator: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    },
                    havingCertificate: "-1"
                },
                security: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                computer: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelMachineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelLoader: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                screenPrinting: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                ticketingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                }
            },
            filterType: FilterType.APPLICANT
        },
        {
            id: "2D3C1DD4-00CF-4A06-A3A5-6600C53644F5",
            name: "DNU",
            whereClause: {
                "location": {
                    "value": [],
                    "text": "All"
                },
                "shift": {
                    "value": [],
                    "text": "All"
                },
                "workStatus": {
                    "value": [
                        // "DNU"
                        applicantStatusesData.DNU
                    ],
                    "text": "(1) selected"
                },
                "position": {
                    "value": [],
                    "text": "All"
                },
                "employmentType": {
                    "value": [],
                    "text": "All"
                },
                "ethinicity": {
                    "value": [],
                    "text": "All"
                },
                "maritalStatus": {
                    "value": [],
                    "text": "All"
                },
                "gender": {
                    "value": [],
                    "text": "All"
                },
                "appliedDate": {
                    "equal": "",
                    "between": {
                        "start": "",
                        "end": ""
                    }
                },
                language: {
                    value: [],
                    text: "All"
                },
                flexibility: "-1",
                calledOn: false,
                availableForOT: "-1",
                willingToComute: "-1",
                fastPacedEnv: "-1",
                standingLongPeriod: "-1",
                workInHotTemp: "-1",
                repetitiveMov: "-1",
                workinColdTemp: "-1",
                heavyLifting: "-1",
                wentOverSafetyProtocol: "-1",
                wentOverUniformProtocol: "-1",
                noShowPolicy: "-1",
                timeClockPolicy: "-1",
                weekPayPolicy: "-1",
                stillToeBoots: "-1",
                slipResistantBoots: "-1",
                workWeekends: "-1",
                mandateLunch: "-1",
                oneDayAssignment: "-1",
                ReadEnglish: "-1",
                WriteEnglish: "-1",
                WriteSpanish: "-1",
                ReadSpanish: "-1",
                BasicMathSkills: "-1",
                CovidVaccinated: "-1",
                generalLaber: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                shippingReceiving: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                packagingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                industrialSewing: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                rfScanning: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                assemblyLine: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                foodProduction: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                machineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                supervisorLead: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                maintainanceMechanic: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                electrician: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                sanitation: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                qualityAssurance: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                forkLiftOperator: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    },
                    havingCertificate: "-1"
                },
                security: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                computer: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelMachineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelLoader: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                screenPrinting: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                ticketingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                }
            },
            filterType: FilterType.APPLICANT
        },

        {
            id: "ACFDF03E-D97D-4F47-9258-156C420E7F6D",
            name: "Dispatch",
            whereClause: {
                "location": {
                    "value": [],
                    "text": "All"
                },
                "shift": {
                    "value": [],
                    "text": "All"
                },
                "workStatus": {
                    "value": [
                        // "Dispatched"
                        applicantStatusesData.Dispatched
                    ],
                    "text": "(1) selected"
                },
                "position": {
                    "value": [],
                    "text": "All"
                },
                "employmentType": {
                    "value": [],
                    "text": "All"
                },
                "ethinicity": {
                    "value": [],
                    "text": "All"
                },
                "maritalStatus": {
                    "value": [],
                    "text": "All"
                },
                "gender": {
                    "value": [],
                    "text": "All"
                },
                "appliedDate": {
                    "equal": "",
                    "between": {
                        "start": "",
                        "end": ""
                    }
                },
                language: {
                    value: [],
                    text: "All"
                },
                flexibility: "-1",
                calledOn: false,
                availableForOT: "-1",
                willingToComute: "-1",
                fastPacedEnv: "-1",
                standingLongPeriod: "-1",
                workInHotTemp: "-1",
                repetitiveMov: "-1",
                workinColdTemp: "-1",
                heavyLifting: "-1",
                wentOverSafetyProtocol: "-1",
                wentOverUniformProtocol: "-1",
                noShowPolicy: "-1",
                timeClockPolicy: "-1",
                weekPayPolicy: "-1",
                stillToeBoots: "-1",
                slipResistantBoots: "-1",
                workWeekends: "-1",
                mandateLunch: "-1",
                oneDayAssignment: "-1",
                ReadEnglish: "-1",
                WriteEnglish: "-1",
                WriteSpanish: "-1",
                ReadSpanish: "-1",
                BasicMathSkills: "-1",
                CovidVaccinated: "-1",
                generalLaber: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                shippingReceiving: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                packagingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                industrialSewing: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                rfScanning: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                assemblyLine: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                foodProduction: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                machineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                supervisorLead: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                maintainanceMechanic: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                electrician: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                sanitation: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                qualityAssurance: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                forkLiftOperator: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    },
                    havingCertificate: "-1"
                },
                security: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                computer: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelMachineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelLoader: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                screenPrinting: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                ticketingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                }
            },

            filterType: FilterType.APPLICANT
        },
        {
            id: "2ECA9945-E6F0-426A-8447-8EB35D79834C",
            name: "DNR",
            whereClause: {
                "location": {
                    "value": [],
                    "text": "All"
                },
                "shift": {
                    "value": [],
                    "text": "All"
                },
                "workStatus": {
                    "value": [],
                    "text": "All"
                },
                "position": {
                    "value": [],
                    "text": "All"
                },
                "employmentType": {
                    "value": [],
                    "text": "All"
                },
                "ethinicity": {
                    "value": [],
                    "text": "All"
                },
                "maritalStatus": {
                    "value": [],
                    "text": "All"
                },
                "gender": {
                    "value": [],
                    "text": "All"
                },
                "appliedDate": {
                    "equal": "",
                    "between": {
                        "start": "",
                        "end": ""
                    }
                },
                language: {
                    value: [],
                    text: "All"
                },
                calledOn: true,
                availableForOT: "-1",
                willingToComute: "-1",
                fastPacedEnv: "-1",
                standingLongPeriod: "-1",
                workInHotTemp: "-1",
                repetitiveMov: "-1",
                workinColdTemp: "-1",
                heavyLifting: "-1",
                wentOverSafetyProtocol: "-1",
                wentOverUniformProtocol: "-1",
                noShowPolicy: "-1",
                timeClockPolicy: "-1",
                weekPayPolicy: "-1",
                stillToeBoots: "-1",
                slipResistantBoots: "-1",
                workWeekends: "-1",
                mandateLunch: "-1",
                oneDayAssignment: "-1",
                ReadEnglish: "-1",
                WriteEnglish: "-1",
                WriteSpanish: "-1",
                ReadSpanish: "-1",
                BasicMathSkills: "-1",
                CovidVaccinated: "-1",
                generalLaber: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                shippingReceiving: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                packagingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                industrialSewing: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                rfScanning: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                assemblyLine: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                foodProduction: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                machineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                supervisorLead: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                maintainanceMechanic: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                electrician: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                sanitation: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                qualityAssurance: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                forkLiftOperator: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    },
                    havingCertificate: "-1"
                },
                security: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                computer: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelMachineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelLoader: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                screenPrinting: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                ticketingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                }
            },
            filterType: FilterType.APPLICANT
        },
        {
            id: "7A065EE5-D88B-4801-8C87-56C1B721BEBD",
            name: "Active",
            whereClause: {
                "location": {
                    "value": [],
                    "text": "All"
                },
                "role": {
                    "value": [],
                    "text": "All"
                },
                "userStatus": {
                    "value": [
                        "Active"
                    ],
                    "text": "(1) selected"
                }
            },
            filterType: FilterType.USER
        },
        {
            id: "2415AC5D-E50E-4A98-9FC4-60AAA0B5BB52",
            name: "Deactive",
            whereClause: {
                "location": {
                    "value": [],
                    "text": "All"
                },
                "role": {
                    "value": [],
                    "text": "All"
                },
                "userStatus": {
                    "value": [
                        "Deactive"
                    ],
                    "text": "(1) selected"
                }
            },
            filterType: FilterType.USER
        },
        {
            id: "F2FCB7D5-58B7-42DD-B618-88352C2A4D5B",
            name: "Active",
            whereClause: {
                "location": {
                    "value": [],
                    "text": "All"
                },
                "clientStatus": {
                    "value": [
                        "Active"
                    ],
                    "text": "(1) selected"
                }
            },
            filterType: FilterType.CLIENT
        },
        {
            id: "C748E1D7-D255-45FF-A85E-DD15CC8D59BD",
            name: "Deactive",
            whereClause: {
                "location": {
                    "value": [],
                    "text": "All"
                },
                "clientStatus": {
                    "value": [
                        "Deactive"
                    ],
                    "text": "(1) selected"
                }
            },
            filterType: FilterType.CLIENT
        },
        {
            id: "A91B6EE5-7A89-418A-AEAE-909742AD305D",
            name: "New",
            whereClause: {
                location: {
                    value: [],
                    text: "All"
                },
                client: {
                    value: [],
                    text: "All"

                },
                position: {
                    value: [],
                    text: "All"

                },
                jobDate: {
                    equal: ""
                },
                jobStatus: {
                    value: ["New"],
                    text: "(1) selected"
                },
                title: ""
            },
            filterType: FilterType.JOB
        },
        {
            id: "CA6C7705-3483-4927-92AC-8E035E4344C0",
            name: "Inactive",
            whereClause: {
                location: {
                    value: [],
                    text: "All"
                },
                client: {
                    value: [],
                    text: "All"

                },
                position: {
                    value: [],
                    text: "All"

                },
                jobDate: {
                    equal: ""
                },
                jobStatus: {
                    value: ["Inactive"],
                    text: "(1) selected"
                },
                title: ""
            },
            filterType: FilterType.JOB
        },
        {
            id: "32F3B376-51FB-49BF-81C0-6B5DE5A93826",
            name: "In Progress",
            whereClause: {
                location: {
                    value: [],
                    text: "All"
                },
                client: {
                    value: [],
                    text: "All"

                },
                position: {
                    value: [],
                    text: "All"

                },
                jobDate: {
                    equal: ""
                },
                jobStatus: {
                    value: ["Inprogress"],
                    text: "(1) selected"
                },
                title: ""
            },
            filterType: FilterType.JOB
        },
        {
            id: "2E099E65-39A6-45BF-A4B0-FB278812AFE4",
            name: "Filled",
            whereClause: {
                location: {
                    value: [],
                    text: "All"
                },
                client: {
                    value: [],
                    text: "All"

                },
                position: {
                    value: [],
                    text: "All"

                },
                jobDate: {
                    equal: ""
                },
                jobStatus: {
                    value: ["Filled"],
                    text: "(1) selected"
                },
                title: ""
            },
            filterType: FilterType.JOB
        }]
    }
};

export const changeApplicantPassword = createAsyncThunk('reviewers/changeApplicantPassword', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let reviewer = undefined;
        if (data && isJSON(data)) {
            reviewer = JSON.parse(data);
        }
        return {
            reviewer: reviewer,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const saveNote = createAsyncThunk('reviewers/saveNote', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let reviewer = undefined;
        if (data && isJSON(data)) {
            reviewer = JSON.parse(data);
        }
        return {
            reviewer: reviewer,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const saveDocument = createAsyncThunk('reviewers/saveDocument', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let reviewer = undefined;
        if (data && isJSON(data)) {
            reviewer = JSON.parse(data);
        }
        return {
            reviewer: reviewer,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const deleteDocument = createAsyncThunk('reviewers/deleteDocument', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let reviewer = undefined;
        if (data && isJSON(data)) {
            reviewer = JSON.parse(data);
        }
        return {
            reviewer: reviewer,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const downloadDocument = createAsyncThunk('reviewers/downloadDocument', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let documentObj = undefined;
        if (data && isJSON(data)) {
            documentObj = JSON.parse(data);
        }
        return {
            documentObj: documentObj,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const downloadForm = createAsyncThunk('reviewers/downloadForm', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let formObj = undefined;
        if (data && isJSON(data)) {
            formObj = JSON.parse(data);
        }
        return {
            formObj: formObj,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const saveStatus = createAsyncThunk('reviewers/saveStatus', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
        }
        return {
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getReviewerQRCode = createAsyncThunk('reviewers/getReviewerQRCode', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "";
        return {
            data: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const saveFilter = createAsyncThunk('reviewers/saveFilter', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const payloadData = reviewerModel.payload.data;
        const data = response.data ? response.data.data : "";
        let filter = undefined;
        if (payloadData && isJSON(payloadData)) {
            filter = JSON.parse(payloadData);
        }
        return {
            filterType: filter.filterType,
            savedFilterId: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getUserFilters = createAsyncThunk('reviewers/getUserFilters', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let reviewer = undefined;
        if (data && isJSON(data)) {
            reviewer = JSON.parse(data);
        }
        return {
            reviewer: reviewer,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const removeFilter = createAsyncThunk('reviewers/removeFilter', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        return {
            id: reviewerModel.payload.id,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const setApplicantOpened = createAsyncThunk('reviewers/setApplicantOpened', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        return {
            id: reviewerModel.payload.id,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const saveApplicantCalledOn = createAsyncThunk('reviewers/saveApplicantCalledOn', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
        }
        return {
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});



export const reviewerSlice = createSlice({
    name: 'reviewers',
    initialState,
    reducers: {
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateRemovalStatus: (state) => {
            state.removal.status = fetchStatus.IDLE;
        },
        updateDocumentDownloadStatus: (state) => {
            state.documentDownload.status = fetchStatus.IDLE;
        },
        updateformDownloadStatus: (state) => {
            state.formDownload.status = fetchStatus.IDLE;
        },
        updateDocumentDeleteStatus: (state) => {
            state.documentDelete.status = fetchStatus.IDLE;
        },
        updateUserFilters: (state, action) => {
            const filter = action.payload;
            if (filter) {
                let existingFilter = state.userFilters.data.find(f => f.id === filter.id);
                if (existingFilter) {
                    let filters = state.userFilters.data.filter(f => f.id !== filter.id);
                    filters.push(filter);
                    state.userFilters.data = filters;
                }
                else {
                    state.userFilters.data = [...state.userFilters.data, filter];
                }
            }
        },
        removeUserFilter: (state, action) => {
            const filterId = action.payload;
            let filters = state.userFilters.data.filter(f => f.id !== filterId);
            state.userFilters.data = filters;
        },
        updateSaveApplicantStatusStatus: (state) => {
            state.statusChange.status = fetchStatus.DONE;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getReviewerQRCode.pending, (state, action) => {
            state.qrCode.status = fetchStatus.LOADING;
        }).addCase(getReviewerQRCode.fulfilled, (state, action) => {
            state.qrCode.data = action.payload.data;
            state.qrCode.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getReviewerQRCode.rejected, (state, action) => {
            state.qrCode.status = fetchStatus.FAILED;
            state.qrCode.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(changeApplicantPassword.pending, (state, action) => {
            state.applicantPassword.status = fetchStatus.LOADING;
        }).addCase(changeApplicantPassword.fulfilled, (state, action) => {
            state.applicantPassword.data = action.payload.reviewer;
            state.applicantPassword.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(changeApplicantPassword.rejected, (state, action) => {
            state.applicantPassword.status = fetchStatus.FAILED;
            state.applicantPassword.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(saveNote.pending, (state, action) => {
            state.note.status = fetchStatus.LOADING;
        }).addCase(saveNote.fulfilled, (state, action) => {
            state.note.data = action.payload.reviewer;
            state.note.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(saveNote.rejected, (state, action) => {
            state.note.status = fetchStatus.FAILED;
            state.note.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(saveStatus.pending, (state, action) => {
            state.statusChange.status = fetchStatus.LOADING;
        }).addCase(saveStatus.fulfilled, (state, action) => {
            state.statusChange.data = action.payload.applicant;
            state.statusChange.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(saveStatus.rejected, (state, action) => {
            state.statusChange.status = fetchStatus.FAILED;
            state.statusChange.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(saveDocument.pending, (state, action) => {
            state.documentUpload.status = fetchStatus.LOADING;
        }).addCase(saveDocument.fulfilled, (state, action) => {
            state.documentUpload.data = action.payload.reviewer;
            state.documentUpload.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(saveDocument.rejected, (state, action) => {
            state.documentUpload.status = fetchStatus.FAILED;
            state.documentUpload.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(deleteDocument.pending, (state, action) => {
            state.documentDelete.status = fetchStatus.LOADING;
        }).addCase(deleteDocument.fulfilled, (state, action) => {
            state.documentDelete.data = action.payload.reviewer;
            state.documentDelete.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(deleteDocument.rejected, (state, action) => {
            state.documentDelete.status = fetchStatus.FAILED;
            state.documentDelete.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(downloadDocument.pending, (state, action) => {
            state.documentDownload.status = fetchStatus.LOADING;
        }).addCase(downloadDocument.fulfilled, (state, action) => {
            state.documentDownload.data = action.payload.documentObj;
            state.documentDownload.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(downloadDocument.rejected, (state, action) => {
            state.documentDownload.status = fetchStatus.FAILED;
            state.documentDownload.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(downloadForm.pending, (state, action) => {
            state.formDownload.status = fetchStatus.LOADING;
        }).addCase(downloadForm.fulfilled, (state, action) => {
            state.formDownload.data = action.payload.formObj;
            state.formDownload.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(downloadForm.rejected, (state, action) => {
            state.formDownload.status = fetchStatus.FAILED;
            state.formDownload.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(saveFilter.pending, (state, action) => {
            state.saveFilter.status = fetchStatus.LOADING;
        }).addCase(saveFilter.fulfilled, (state, action) => {
            state.saveFilter.filterType = action.payload.filterType;
            state.saveFilter.savedFilterId = action.payload.savedFilterId;
            state.saveFilter.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(saveFilter.rejected, (state, action) => {
            state.saveFilter.status = fetchStatus.FAILED;
            state.saveFilter.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getUserFilters.pending, (state, action) => {
            state.userFilters.status = fetchStatus.LOADING;
        }).addCase(getUserFilters.fulfilled, (state, action) => {
            state.userFilters.data = action.payload.reviewer;
            state.userFilters.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getUserFilters.rejected, (state, action) => {
            state.userFilters.status = fetchStatus.FAILED;
            state.userFilters.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(removeFilter.pending, (state, action) => {
            state.removeFilter.status = fetchStatus.LOADING;
        }).addCase(removeFilter.fulfilled, (state, action) => {
            state.removeFilter.data = action.payload.id;
            state.removeFilter.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(removeFilter.rejected, (state, action) => {
            state.removeFilter.status = fetchStatus.FAILED;
            state.removeFilter.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(setApplicantOpened.pending, (state, action) => {
            state.setApplicantOpened.status = fetchStatus.LOADING;
        }).addCase(setApplicantOpened.fulfilled, (state, action) => {
            state.setApplicantOpened.data = action.payload.id;
            state.setApplicantOpened.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(setApplicantOpened.rejected, (state, action) => {
            state.setApplicantOpened.status = fetchStatus.FAILED;
            state.setApplicantOpened.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(saveApplicantCalledOn.pending, (state, action) => {
            state.ApplicantCalledOn.status = fetchStatus.LOADING;
        }).addCase(saveApplicantCalledOn.fulfilled, (state, action) => {
            state.ApplicantCalledOn.data = action.payload.applicant;
            state.ApplicantCalledOn.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(saveApplicantCalledOn.rejected, (state, action) => {
            state.ApplicantCalledOn.status = fetchStatus.FAILED;
            state.ApplicantCalledOn.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { updateCreationStatus, updateModificationStatus, updateRemovalStatus,
    updateSingleStatus, updateDocumentDownloadStatus, updateformDownloadStatus,
    updateUserFilters, removeUserFilter, updateSaveApplicantStatusStatus,
    updateDocumentDeleteStatus } = reviewerSlice.actions;

export default reviewerSlice.reducer

export const selectReviewerQRCode = state => state.reviewers.qrCode.data;

export const selectReviewerQRCodeStatus = state => state.reviewers.qrCode.status;

export const selectReviewerQRCodeError = state => state.reviewers.qrCode.error;

export const selectApplicantPasswordStatus = state => state.reviewers.applicantPassword.status;

export const selectNoteStatus = state => state.reviewers.note.status;

export const selectStatusChangeData = state => state.reviewers.statusChange.data;

export const selectStatusChangeStatus = state => state.reviewers.statusChange.status;

export const selectDocumentUploadStatus = state => state.reviewers.documentUpload.status;

export const selectDocumentDeleteStatus = state => state.reviewers.documentDelete.status;

export const selectDocumentDownloadStatus = state => state.reviewers.documentDownload.status;

export const selectDocumentDownloadData = state => state.reviewers.documentDownload.data;

export const selectFormDownloadStatus = state => state.reviewers.formDownload.status;

export const selectFormDownloadData = state => state.reviewers.formDownload.data;

export const selectSaveFilterType = state => state.reviewers.saveFilter.filterType;

export const selectSaveFilterId = state => state.reviewers.saveFilter.savedFilterId;

export const selectSaveFilterStatus = state => state.reviewers.saveFilter.status;

export const selectUserFilters = state => state.reviewers.userFilters.data;

export const selectUserFiltersStatus = state => state.reviewers.userFilters.status;

export const selectUserFiltersError = state => state.reviewers.userFilters.error;

export const selectDefaultFilters = state => {
    return state.reviewers.defaultFilters.data.filter(f => f.filterType === FilterType.APPLICANT);
}

export const selectUserDefaultFilters = state => {
    return state.reviewers.defaultFilters.data.filter(f => f.filterType === FilterType.USER);
}

export const selectClientDefaultFilters = state => {
    return state.reviewers.defaultFilters.data.filter(f => f.filterType === FilterType.CLIENT);
}

export const selectJobDefaultFilters = state => {
    return state.reviewers.defaultFilters.data.filter(f => f.filterType === FilterType.JOB);
}

export const selectRemoveFilterId = state => state.reviewers.removeFilter.data;

export const selectRemoveFilterStatus = state => state.reviewers.removeFilter.status;

export const selectApplicantCalledOnStatus = state => state.reviewers.ApplicantCalledOn.status;

export const selectCalledOnApplicant = state => state.reviewers.ApplicantCalledOn.data;

export const selectSaveApplicantStatusStatus = state => state.reviewers.statusChange.status;

