import React, { useEffect, useState } from 'react';
import useSubdomainValidator from '../../../hooks/useSubdomainValidator';

function SubDomainUrlText(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);
    const [isUrlValid, setIsUrlValid] = useState(true);
    let urlValid = useSubdomainValidator(value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setIsUrlValid(urlValid);
    }, [props.value, urlValid]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
        }
        else {
            setInvalid(false);
        }
    }, [value, props.required, edited, props.submited, props.disabled]);

    const onChange = (e) => {
        const lowerCaseValue = e.target.value.toLowerCase();
        setValue(lowerCaseValue);
        setEdited(true);

        const newEvent = {
            ...e,
            target: {
                ...e.target,
                name: e.target.name === null ? props.name : e.target.name,
                value: lowerCaseValue
            }
        };
        props.onChange && props.onChange(newEvent);
    };

    return (
        <>
            <div className="input-group mb-3">
                <input
                    aria-label="Subdomain"
                    aria-describedby="basic-addon2"

                    type="text"
                    id={props.id}
                    name={props.name}
                    value={value}
                    className={`${props.subDomainUrlTextClasses ? props.subDomainUrlTextClasses : ""} ${invalid && "is-invalid"}`}
                    placeholder={`${props.placeholder ? props.placeholder : ""}`}
                    onChange={(e) => onChange(e)}
                    disabled={props.disabled === true}
                    formNoValidate={true}
                    data-form={props.form ? props.form : ""}
                    data-invalid={invalid}
                    data-required={props.required ? props.required : false}
                />
                <span className="input-group-text" id="basic-addon2">.hirebase.co</span>

                {
                    invalid && <span className="error-label">
                        <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                        {props.errorMessage ? props.errorMessage : "This field is required"}
                    </span>
                }

                {
                    (value && !isUrlValid) && <span className="error-label">
                        <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                        Please enter valid url address
                    </span>
                }

                {props.children}
            </div>


        </>
    );
}

export default SubDomainUrlText;