import React, { useState } from 'react';
// import './css/leadCard.css';
import CheckBox from '../Controls/custom/checkers/checkbox';
import phoneCallFillImg from './../../assets/images/clipart/phone-call-fill.svg';


function RouteTrackingLeadCard(props) {

    const [isSelected, setIsSelected] = useState(false);

    const _getStatusClass = () => {
        if (props.status === "In Progress") {
            return 'status-inprogress';
        }
        else if (props.status === "Completed") {
            return 'status-completed';
        }
        else if (props.status === "Rejected") {
            return 'status-rejected';
        }
        else if (props.status === "New") {
            return 'status-new';
        }
        else if (props.status === "Credit Check") {
            return 'status-credit-check';
        }
        else if (props.status === "status-safety-check") {
            return 'status-safety-check';
        }
        else if (props.status === "Proposal") {
            return 'status-proposal';
        }
        else if (props.status === "SA") {
            return 'status-sa';
        }
        else if (props.status === "COI") {
            return 'status-coi';
        }
    }

    const _getStatusClassBadge = () => {
        if (props.status === "Completed") {
            return 'badge badge-success';
        }
        else if (props.status === "In Progress") {
            return 'badge badge-warning';
        }
        else if (props.status === "Rejected") {
            return 'badge badge-danger';
        }
        else if (props.status === "New") {
            return 'badge badge-info';
        }
        else if (props.status === "Credit Check") {
            return 'badge badge-light';
        }
        else if (props.status === "Safety Check") {
            return 'badge badge-secondary';
        }
        else if (props.status === "Proposal") {
            return 'badge badge-dark';
        }
        else if (props.status === "SA") {
            return 'badge badge-sa';
        }
        else if (props.status === "COI") {
            return 'badge badge-coi';
        }
    }

    const _getPercentage = () => {
        if (props.status === "New") {
            return '0';
        }
        else if (props.status === "In Progress") {
            return '5';
        }
        else if (props.status === "Credit Check") {
            return '10';
        }
        else if (props.status === "Safety Check") {
            return '15';
        }
        else if (props.status === "Proposal") {
            return '30';
        }
        else if (props.status === "SA") {
            return '50';
        }
        else if (props.status === "COI") {
            return '70';
        }
        else if (props.status === "Completed") {
            return '100';
        }
        else if (props.status === "Rejected") {
            return '0';
        }
    }

    const onClick = (c, e) => {
        setIsSelected(true);
        props.onClick && props.onClick(c, e);

    };

    return (
        // <div className={`card card-shadow lead-card ${isSelected ? "horizpntal-card-selected" : ""} ${props.leadCardClasses ? props.leadCardClasses : ""}`} onClick={props.onClick ? props.onClick : null}>
        //     <div className="lead-card-container">
        //         <div className="lead-card-side-container">
        //             <div className={`semi-donut`}
        //                 style={{ "--percentage": _getPercentage() }}>
        //                 {_getPercentage()}%
        //             </div>
        //             <div className="card-status pt-3">{props.status ? <span className={_getStatusClassBadge()}>{props.status}</span> : <></>}</div>
        //         </div>
        //         <div className="card-body">
        //             <div className="card-title lead-card-title">
        //                 <span className="person-name">{props.name ? props.name : "John Doe"}</span>
        //             </div>
        //             {/* <p className="card-text"><small className=""><span className="text-primary mr-2"> <i className="fa fa-calendar"></i></span>{props.leadDate ? props.leadDate : "N/A"} </small></p> */}
        //             <p className="card-text"> <small className=""><span className="text-primary mr-2"><i className="fa fa-phone"></i></span>{props.LeadPhone ? props.LeadPhone : ""}  </small></p>
        //             {/* <p className="card-text"><small className=""><span className="text-dark mr-2"><i className="fa fa-map-marker"></i> </span>{props.location ? props.location : ""}</small></p> */}
        //             {props.isLoading ? <></> : <div className="controls-container"><CheckBox size="Small" checked={props.routeDetailsData ? props.routeDetailsData : false} onClick={(c, e) => onClick(c, e)} /></div>}
        //             {props.leadCardBody ? props.leadCardBody : ""}
        //         </div>
        //     </div>
        //     {
        //         props.footerContent ? <div className="card-footer">
        //             {props.footerContent}
        //         </div> : <></>
        //     }
        // </div >

        <div className={`card people-card ${props.leadCardClasses ? props.leadCardClasses : ""}`} id={props.key}>
            <div className="c-checkbox mt-3">
                <CheckBox size="Small" checked={props.routeDetailsData ? props.routeDetailsData : false} onClick={(c, e) => onClick(c, e)} />
                {/* <input type="checkbox" checked={props.routeDetailsData ? props.routeDetailsData : false} onChange={(c, e) => onClick(c, e)} /> */}
            </div>
            <div className={`status ${_getStatusClass()}`}>{props.status && props.status}</div>
            <div className="people-card-body p-2 cursor-pointer" onClick={props.onClick}>
                <div className="d-block">
                    <div className="ps-2">
                        <div className="title w-100">
                            {props.name ? props.name : "John Doe"}
                        </div>
                        <div className="row">
                            <div className="detail col-4">
                                <img src={phoneCallFillImg} />{props.LeadPhone ? props.LeadPhone : ""}
                            </div>
                            <div className="detail col-8">
                            </div>
                            <div className="detail col-4">
                            </div>
                            <div className="detail col-8">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="people-card-footer">
                <div className="item">
                    <span className="detail">
                        <img src={applicationImg} /> Lead Date: {props.leadDate}
                    </span>
                </div>
                <div className="item">
                </div>
            </div> */}
        </div>
    );
}

export default RouteTrackingLeadCard;