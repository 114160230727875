import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup';
import PasswordText from '../../../component/Controls/custom/textbox/passwordText';
import { fetchStatus, masterTenantId, tenantId } from '../../../api/client';
import SaveButton from '../../../component/Controls/custom/buttons/saveButton';
import { registerDetailsEN } from '../services/registerDetails';
import { selectSetPasswordStatus, setPassword } from '../reducerSlices/accountsSlice';
import { isFormValid } from '../../../component/Controls/services/formService';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


import hirebaseLogoImg from '../../../assets/images/logo/hirebase-logo.svg';
import backgroundImg from '../../../assets/images/login/dashboards-metrics.svg';
import facebookImg from "../../../assets/images/clipart/facebook.svg";
import facebookImg2 from "../../../assets/images/clipart/facebook-2.svg";
import linkedinImg from "../../../assets/images/clipart/linkedin.svg";
import linkedinImg2 from "../../../assets/images/clipart/linkedin-2.svg";
import twitterImg from "../../../assets/images/clipart/twitter.svg";
import twitterImg2 from "../../../assets/images/clipart/twitter-2.svg";



function ResetPassword() {

    const query = new URLSearchParams(useLocation().search);;
    const userId = query.get('userId');
    const code = query.get('code');
    const isValidLink = userId && code;

    const dispatch = useDispatch();
    const history = useHistory();

    const [twitterImage, setTwitterImage] = useState(twitterImg);
    const [linkedinImage, setLinkedinImage] = useState(linkedinImg);
    const [facebookImage, setFacebookImage] = useState(facebookImg);

    const PASSWORD_RESET_FORM = "PasswordResetForm";

    const [passwordText, setPasswordText] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [prfSubmitted, setPRFSubmitted] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(true);
    const [passwordLengthInvalid, setPasswordLengthInvalid] = useState(false);

    const [registerDetail, setRegisterDetail] = useState(registerDetailsEN);

    const setPasswordStatus = useSelector(selectSetPasswordStatus);


    const onSaveClicked = async (e) => {
        e.preventDefault();
        setPasswordMismatch(false);
        setPasswordLengthInvalid(false);
        if (isFormValid(PASSWORD_RESET_FORM)) {
            if (passwordText.length >= 8) {
                if (passwordText === confirmPassword) {
                    dispatch(setPassword({
                        client: masterTenantId,
                        userId: userId,
                        code: code,
                        newPassword: passwordText,
                        confirmPassword: confirmPassword
                    }));
                } else {
                    setPasswordMismatch(true);
                }
            } else {
                setPasswordLengthInvalid(true);
            }
        } else {

        }
        setPRFSubmitted(true);
    };

    // useEffect(() => {
    //     if (setPasswordStatus === fetchStatus.SUCCEEDED) {
    //         history.push('/login');
    //     }
    // }, [setPasswordStatus])

    return (
        <>


            {/* <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="card card-wizard overflow-hidden">
                    <div className="card-body">


                        <div className="row p-3">
                            <div className="col-md-12">
                                <div className="card-left-header">Reset Password</div>
                                <div className="card-left-sub-header">Please enter your new password</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Password</label>
                                    <InputGroup
                                        icon={<i className="fa fa-unlock-alt"></i>}
                                        control={
                                            <PasswordText id="txtPassword"
                                                name="txtPassword"
                                                PasswordTextClasses="form-control"
                                                placeholder="Enter Password"
                                                value={passwordText}
                                                onChange={(e) => setPasswordText(e.target.value)}
                                                required={true}
                                                errorMessage="Please enter password"
                                                form={PASSWORD_RESET_FORM}
                                                submited={prfSubmitted} />
                                        } />
                                    {passwordLengthInvalid && <span className="error-label">
                                        <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                        {registerDetail.PasswordLengthError}
                                    </span>}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <InputGroup
                                        icon={<i className="fa fa-unlock-alt"></i>}
                                        control={
                                            <PasswordText
                                                id="txtConfirmPassword"
                                                name="txtConfirmPassword"
                                                PasswordTextClasses="form-control"
                                                placeholder="Enter Confirm Password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required={true}
                                                errorMessage="Please enter confirm password"
                                                form={PASSWORD_RESET_FORM}
                                                submited={prfSubmitted} />
                                        } />
                                    {prfSubmitted && passwordMismatch && <span className="error-label">
                                        <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                        {registerDetail.PasswordMismatchError}
                                    </span>}
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    {setPasswordStatus === fetchStatus.LOADING ? <EllipsisLoader /> : <>
                                        {setPasswordStatus !== fetchStatus.SUCCEEDED &&
                                            <SaveButton
                                                toolTipText="Save Password"
                                                onSaveClicked={(e) => onSaveClicked(e)} />}
                                    </>}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div> */}

            {/* {isValidLink ? (
                <>
                    <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header d-block">
                                    <div className="d-flex">
                                        <h5 className="modal-title">Reset Password</h5>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    {setPasswordStatus === fetchStatus.SUCCEEDED ?
                                        <>
                                            <p>Password Changed Successfully.</p><br />
                                            <p>Your password has been changed successfully. You can now go back to the <Link to="/login">home page</Link>.</p>
                                        </>
                                        :
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label id="lblPassword" className="field-label" htmlFor="txtPassword">Password</label>
                                                    <InputGroup
                                                        icon={<i className="fa fa-unlock-alt"></i>}
                                                        control={
                                                            <PasswordText id="txtPassword"
                                                                name="txtPassword"
                                                                PasswordTextClasses="form-control"
                                                                placeholder="Enter Password"
                                                                value={passwordText}
                                                                onChange={(e) => setPasswordText(e.target.value)}
                                                                required={true}
                                                                errorMessage="Please enter password"
                                                                form={PASSWORD_RESET_FORM}
                                                                submited={prfSubmitted} />
                                                        } />
                                                    {passwordLengthInvalid && <span className="error-label">
                                                        <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                                        {registerDetail.PasswordLengthError}
                                                    </span>}
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">

                                                    <label id="lblConfirmPassword" className="field-label pt-2" htmlFor="txtConfirmPassword">Confirm Password</label>
                                                    <InputGroup
                                                        icon={<i className="fa fa-unlock-alt"></i>}
                                                        control={
                                                            <PasswordText
                                                                id="txtConfirmPassword"
                                                                name="txtConfirmPassword"
                                                                PasswordTextClasses="form-control"
                                                                placeholder="Enter Confirm Password"
                                                                value={confirmPassword}
                                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                                required={true}
                                                                errorMessage="Please enter confirm password"
                                                                form={PASSWORD_RESET_FORM}
                                                                submited={prfSubmitted} />
                                                        } />
                                                    {prfSubmitted && passwordMismatch && <span className="error-label">
                                                        <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                                        {registerDetail.PasswordMismatchError}
                                                    </span>}
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="modal-footer">
                                    {setPasswordStatus === fetchStatus.LOADING ? <EllipsisLoader /> : <>
                                        {setPasswordStatus !== fetchStatus.SUCCEEDED &&
                                            <SaveButton
                                                onSaveClicked={(e) => onSaveClicked(e)} />}
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show">
                    </div>
                </>
            ) : (
                <>
                    <h1>Invalid Link</h1>
                    <p>The link you used to reset your password is invalid or incomplete. Please check the link and try again.</p>
                </>
            )} */}


            <div className="container-fluid">
                <div className="row no-gutter">
                    <div className="col-md-6 d-none d-md-inline bg-image">
                        <div className="login d-flex">
                            <div className="container login-container">
                                <div className="row">
                                    <div className="col-lg-11 col-xl-11 mx-auto">
                                        <div className="text-center">
                                            <h1>HireBase Dashboards</h1>
                                            <p>Welcome to the hirebase portal</p>
                                        </div>
                                        <img src={backgroundImg} className="w-100" />
                                    </div>
                                </div>
                                <footer className="footer pt-5 text-end">
                                    {/* <div className="pt-md-3"> */}
                                    <a href="https://twitter.com/base_hire"
                                        target="_balcnk"
                                        onMouseEnter={() => setTwitterImage(twitterImg2)}
                                        onMouseLeave={() => setTwitterImage(twitterImg)}
                                    >
                                        <img src={twitterImage} alt="no" />
                                    </a>
                                    <a href="https://www.linkedin.com/company/hirebase-co/about/"
                                        target="_balcnk"
                                        onMouseEnter={() => setLinkedinImage(linkedinImg2)}
                                        onMouseLeave={() => setLinkedinImage(linkedinImg)}
                                        className="ms-3"
                                    >
                                        <img src={linkedinImage} alt="no" />
                                    </a>
                                    <a href="https://www.facebook.com/gethirebase"
                                        target="_balcnk"
                                        onMouseEnter={() => setFacebookImage(facebookImg2)}
                                        onMouseLeave={() => setFacebookImage(facebookImg)}
                                        className="ms-3"
                                    >
                                        <img src={facebookImage} alt="no" />
                                    </a>
                                    {/* </div> */}
                                </footer>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 bg-light">
                        {/* <div className="login d-flex align-items-center py-5"> */}
                        <div className="login d-flex">
                            <div className="container  login-container">
                                <div className="row">
                                    <div className="col-lg-10 col-xl-7 mx-auto">
                                        <img src={hirebaseLogoImg} alt="hirebase logo" className="mx-auto d-block w-75" />
                                        <p className="login-text mb-4">Reset Password</p>

                                        {setPasswordStatus !== fetchStatus.SUCCEEDED ?
                                            <>
                                                <p className="text-muted mb-4">Please enter your new credentials to access your account and reset your password.</p>
                                                <label className="field-label">Password</label>
                                                <PasswordText id="txtPassword"
                                                    name="txtPassword"
                                                    PasswordTextClasses="form-control"
                                                    placeholder="Enter Password"
                                                    value={passwordText}
                                                    onChange={(e) => setPasswordText(e.target.value)}
                                                    required={true}
                                                    errorMessage="Please enter password"
                                                    form={PASSWORD_RESET_FORM}
                                                    submited={prfSubmitted} />
                                                {passwordLengthInvalid && <span className="error-label">
                                                    <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                                    {registerDetail.PasswordLengthError}
                                                </span>}
                                                <label className="field-label mt-4">Confirm Password</label>
                                                <PasswordText
                                                    id="txtConfirmPassword"
                                                    name="txtConfirmPassword"
                                                    PasswordTextClasses="form-control"
                                                    placeholder="Enter Confirm Password"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    required={true}
                                                    errorMessage="Please enter confirm password"
                                                    form={PASSWORD_RESET_FORM}
                                                    submited={prfSubmitted} />
                                                {prfSubmitted && passwordMismatch && <span className="error-label">
                                                    <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                                    {registerDetail.PasswordMismatchError}
                                                </span>}
                                            </>
                                            :
                                            <>
                                                <p className="text-muted mb-4">Your password has been changed.</p>
                                            </>

                                        }

                                        {/* <button
                                            type="submit"
                                            className="col-md-12 btn btn-login pt-2 mt-4"
                                            disabled={setPasswordStatus === fetchStatus.LOADING}
                                            onClick={(e) => onSaveClicked(e)}>
                                            {setPasswordStatus === fetchStatus.LOADING ? <i className="fa fa-circle-notch fa-spin fa-fw me-2"></i> : <></>}Save Password</button> */}

                                        {setPasswordStatus === fetchStatus.LOADING ? <EllipsisLoader /> : <>
                                            {setPasswordStatus !== fetchStatus.SUCCEEDED ?
                                                <button
                                                    type="submit"
                                                    className="col-md-12 btn btn-login pt-2 mt-4"
                                                    disabled={setPasswordStatus === fetchStatus.LOADING}
                                                    onClick={(e) => onSaveClicked(e)}>
                                                    {setPasswordStatus === fetchStatus.LOADING ? <i className="fa fa-circle-notch fa-spin fa-fw me-2"></i> : <></>}Save Password</button>
                                                : <>
                                                    <br></br>
                                                    <span className="text-pink"><Link to="/login" className="text-pink">Login</Link></span>
                                                </>}
                                        </>}
                                    </div>
                                </div>
                                {/* <div className="row mt-5 mt-md-0   mt-lg-5">
                                    <div className="col-lg-10 col-xl-7 mx-auto text-center">
                                        Need access ?  <span className="text-pink"><Link to="/register" className="text-pink">Register</Link></span>
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-10 col-xl-7 mx-auto"> */}
                                <footer className="footer mt-5 pt-5 mt-md-0  mt-lg-5 text-center">
                                    <span className="text-muted">Copyright © 2022 - Hirebase | All Rights Reserved.</span>
                                </footer>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ResetPassword