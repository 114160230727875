import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _getDateText } from '../../../common/common';
import InputGroup from '../custom/inputGroup/inputGroup';
import DateText from '../custom/textbox/dateText';
import { updateJobDateFilter } from './reducerSlices/jobFilterSlice';


function JobDateFilter(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [dateRequired, setDateRequired] = useState(false);
    const [equal, setEqual] = useState("");
    const jobDateFilter = useSelector(props.jobDateFilterSelector);

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onChange = (e) => {
        setEqual(e.target.value);
    };

    const onSaveFilterClicked = (e) => {
        e.preventDefault();
        if (equal) {
            setDateRequired(false);
            dispatch(updateJobDateFilter(equal));
            setOpen(false);
        }
        else {
            setDateRequired(true);
        }
    }

    const onClearFilterClicked = (e) => {
        e.preventDefault();
        setDateRequired(false);
        setEqual("");
        dispatch(updateJobDateFilter(""));
        setOpen(false);
    }

    const onCloseClicked = (e) => {
        e.preventDefault();
        setDateRequired(false);
        setOpen(false);
    }

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Job Date</span> :
                <span className="filter-value">{jobDateFilter && jobDateFilter.equal ? _getDateText(jobDateFilter.equal) : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div className="filter-control-container"><div className="row">
                <div className="col-md-12">
                    <div className="form-group p-1">
                        <label id="lblJobDateEqual" className="filter-label" htmlFor="txtJobDateEqual">Start Date <span
                            className="close-filter-button"
                            title="Close"
                            onClick={(e) => onCloseClicked(e)}>
                            <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                        </span></label>
                        <InputGroup
                            InputGroupClasses="input-group-sm"
                            icon={<i className="fa fa-calendar"></i>}
                            control={<DateText
                                id="txtJobDateEqual"
                                name="txtJobDateEqual"
                                DateTextClasses="form-control"
                                required={dateRequired}
                                errorMessage={"Please select start date"}
                                submited={true}
                                value={equal} onChange={(e) => onChange(e)} />}
                        />
                    </div>
                    <div className="text-end p-1">
                        <a
                            href="#"
                            title="Save"
                            onClick={(e) => { onSaveFilterClicked(e); props.onJobDateFilter(e) && props.onJobDateFilter(e); }}>
                            <span className="filter-controls">
                                <i className="fa fa-save me-2"></i>
                            </span>
                        </a>
                        <a
                            href="#"
                            title="Clear"
                            onClick={(e) => { onClearFilterClicked(e); props.onJobDateFilter(e) && props.onJobDateFilter(e); }}>
                            <span className="filter-controls">
                                <i className="fa fa-eraser"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            </div> : <></>}
        </div>
    );
}

export default JobDateFilter;