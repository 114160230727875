export const ErrorCode = {
    EMAIL_IN_USE: 100,
    UNKNOWN_SERVER_ERROR: 101,
    INVALID_TOKEN: 102,
    INVALID_LOGIN_ATTEMPT: 103,
    UNAUTHORIZED: 104,
    INVALID_RESET_TOKEN: 105
};

export const USER_KEY = "THSBGDYDPKDH1552NC";

export const Role = {
    ADMIN: "Admin",
    APPLICANT: "Applicant",
    REVIEWER: "Reviewer",
    PAYROLL: "Payroll",
    SALESREP: "SalesRep",
    SALESMANAGER: "SalesManager"
};

export const ForPage = {
    ADMIN: "Admin",
    USER: "User"
}

export const FilterType = {
    NONE: -1,
    APPLICANT: 0,
    USER: 1,
    CLIENT: 2,
    JOB: 3,
}

export const Operation = {
    CREATED: 0,
    UPDATED: 1,
    DELETED: 2
}

export const Language = {
    ENGLISH: "EN",
    SPANISH: "SP"
}

export const JobLogType = {
    CREATION: {
        type: "Job Creation",
        value: 0
    },
    MODIFICATION: {
        type: "Job Modification",
        value: 1
    },
    INACTIVATION: {
        type: "Job Inactivated",
        value: 2
    },
    DISPATCH: {
        type: "Applicant Dispatched",
        value: 3
    },
    FILLED: {
        type: "Job Filled",
        value: 4
    },
    UNDISPATCH: {
        type: "Applicant undispatched",
        value: 3
    }
}

export const JobStatus = {
    NEW: "New",
    INACTIVE: "Inactive",
    INPROGRESS: "In Progress",
    FILLED: "Filled",
}

export const JobPositionStatus = {
    NOTFILLED: "Open",
    INPROGRESS: "In Progress",
    FILLED: "Filled",
}

export const AccountPlan = {
    0: "Free",
    1: "Basic",
    2: "Standard",
    3: "Enterprise",
}