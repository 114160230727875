import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { getBase64Image } from '../../../../common/common';

const FileUpload = (props ) => {
    const [base64Image, setBase64Image] = useState("");
    const [files, setFiles] = useState([]);
    const [invalid, setInvalid] = useState(false);

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            setInvalid(true);
        } else {
            setInvalid(false);
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            let file = acceptedFiles[0];
            getBase64Image(file)
                .then(result => {
                    result = result.split(',').pop();
                    file["base64"] = result;
                    setBase64Image(result);
                    props.onImageUpload(result);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': [],
            'image/png': []
        }, // Specify accepted file types
        maxFiles: 1, // Maximum number of files
        maxSize: 1048576, // Maximum file size in bytes (1MB)
    });

    // Cleanup function to revoke data uris to avoid memory leaks
    React.useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <div className="file-upload-container">
            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <div className={`file-upload-box ${invalid ? 'file-upload-box-invalid' : ''}`}>
                    <div className="flyout-list-group-item-card">
                        <i className="fa-solid fa-cloud-arrow-up"></i>
                        <span className="ps-2">
                            <div className="file-upload-text">
                                Drag & Drop files here <span className="text-secondary">or</span>
                                <br /><em className='text-pink'>(*.jpeg, *.png images will be accepted)</em>
                            </div>
                        </span>
                        <span className="ms-auto">
                            <button className="c-btn dark-btn rounded-pill" type="button">
                                Choose Files
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            {files.length > 0 && (
                <>
                    {files.map((file, index) => (
                        <div key={index} className="preview-image-box mt-2">
                            <img src={file.preview} alt="Preview" className="preview-image" />
                        </div>
                    ))}
                </>
            )}

        </div>
    );
};

export default FileUpload;
