import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, GridComponent, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';

import { fetchStatus, tenantId } from '../../../api/client';
import { Operation } from '../../../common/constants';
import { isFormValid } from '../../../component/Controls/services/formService';
import { isObjectArray } from '../../../utilities/utilityFunctions';

import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup'
import ShortText from '../../../component/Controls/custom/textbox/shortText'
import Selector from '../../../component/Controls/custom/selectors/selectors';
import RemoveIconButton from '../../../component/Controls/buttons/removeIconButton';
import ContinueButton from '../../../component/Controls/buttons/continueButton';
import BackButton from '../../../component/Controls/buttons/backButton';
import SetupProgress from '../../../component/Progress/SetupProgress';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';

import { onValueChanged, sections } from '../../setting/services/experienceSaver';
import { getSkillGroupOptions, selectSkillGroupOptions, selectSkillGroupOptionsStatus } from '../../setting/reducerSlices/skillGroupSlice';
import { createExperience, createExperienceOptionDetail, createNewExperience, deleteExperienceOptionDetail, getAllExperience, getExperienceById, removeExperience, selectAllExperience, selectCreationStatus, selectExperienceById, selectModificationStatus, selectRemovalStatus, selectSingleStatus, selectStatus, showExperienceFlyout, updateExperience, updateExperienceOptionsStatus, updateSingleData, updateStatus } from '../../setting/reducerSlices/experienceSlice';
import { userStatuses } from '../../shared/services/masterData';
import GridLoader from '../../../component/Controls/loaders/gridLoader';
import ReactTooltip from 'react-tooltip';
import { show } from '../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';
import { messages } from '../../../common/actionMessages';



function CompanyExperience() {

  const dispatch = useDispatch();
  const history = useHistory();

  const EXPERIENCE_FORM = "Experienceform";

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [cFInvalid, setCFInvalid] = useState(false);
  const [cfSubmitted, setCFSubmitted] = useState(false);

  const [statusOptions, setStatusOptions] = useState([]);

  const whereClause = {
    name: ""
  };

  const experience = useSelector(selectExperienceById);
  const experienceRequestStatus = useSelector(selectSingleStatus);
  const experienceCreationStatus = useSelector(selectCreationStatus);
  const experienceModificationStatus = useSelector(selectModificationStatus);
  const experienceRemovalStatus = useSelector(selectRemovalStatus);
  const experiences = useSelector(selectAllExperience);
  const experiencesRequestStatus = useSelector(selectStatus);

  const skillGroupOptionsStatus = useSelector(selectSkillGroupOptionsStatus);
  const skillGroupOptions = useSelector(selectSkillGroupOptions);

  useEffect(() => {
    if (skillGroupOptionsStatus === fetchStatus.IDLE) {
      dispatch(getSkillGroupOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSKILLGROUPOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [skillGroupOptionsStatus]);

  useEffect(() => {
    if (experiencesRequestStatus === fetchStatus.IDLE) {
      _loadExperiences(0, 0, 10, whereClause);
    }
  }, [experiencesRequestStatus, dispatch]);

  let _dummyCards = [];

  useEffect(() => {
    if (experiencesRequestStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateStatus());
    }
  }, [experiencesRequestStatus]);

  // useEffect(() => {
  //   if (experiencesRequestStatus === fetchStatus.LOADING) {
  //     for (let i = 0; i < 10; i++) {
  //       _dummyCards.push(
  //         <div className="list-loading-content">
  //           <ReactPlaceholder type='text' ready={false} rows={2} color='#E0E0E0'>
  //           </ReactPlaceholder>
  //         </div>
  //       );
  //     }
  //     setDummyCards(_dummyCards);
  //   }
  // }, [experiencesRequestStatus]);

  useEffect(() => {
    if (experienceRemovalStatus === fetchStatus.SUCCEEDED) {
      _loadExperiences(0, 0, 10, whereClause);
      dispatch(updateExperienceOptionsStatus());
    }
  }, [experienceRemovalStatus]);

  const _loadExperience = async (id, page) => {
    await dispatch(getExperienceById({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSETTINGEXPERIENCEBYID",
        payload: {
          id: id,
          data: ""
        }
      }
    }));
  }

  // const _loadForm = async () => {
  //     await dispatch(getClientForm({
  //         client: tenantId,
  //         serviceType: "TENANTADMIN",
  //         operation: "GETFORMBYTYPE",
  //         payload: {
  //             id: "",
  //             data: "ClientForm"
  //         }
  //     }));
  // }

  const _loadExperiences = async (page, offset, limit, whereClause) => {
    await dispatch(getAllExperience({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSETTINGEXPERIENCE",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: offset,
            limit: 1000,
            where: whereClause
          })
        }
      }
    }));
  }

  const onExperienceSelected = (e, id) => {
    e.preventDefault();
    _loadExperience(id, undefined);
    setCreateMode(false);
    setEditmode(true);
  };

  const onDeleteClicked = (e, id) => {
    e.preventDefault();
    if (id) {
      dispatch(show({
        id: id,
        title: messages.confirmAlert.title,
        content: messages.confirmAlert.content.removal('Experience'),
        confirmedCallback: _removeExperience
      }));
    }
  };

  const onAddRecordClicked = (e) => {
    dispatch(createNewExperience());

    setCreateMode(true);
    setEditmode(false);
    setCFSubmitted(false);
  };

  const _removeExperience = async (id) => {
    await dispatch(removeExperience({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "DELETESETTINGEXPERIENCE",
      payload: {
        id: id,
        data: ""
      }
    }))
  };

  useEffect(() => {
    if (experienceCreationStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateExperienceOptionsStatus());
      _loadExperiences(0, 0, 10, whereClause);
    }
  }, [experienceCreationStatus]);

  useEffect(() => {
    if (experienceModificationStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateExperienceOptionsStatus());
      _loadExperiences(0, 0, 10, whereClause);
    }
  }, [experienceModificationStatus]);

  const addActionTemplate = (props) => {
    return <>
      <i className='fa fa-pencil' data-tip='Edit' title='Edit' onClick={(e) => onExperienceSelected(e, props.id)}></i>
      <i className='fa fa-trash ps-3' data-tip='Delete' title='Delete' onClick={(e) => onDeleteClicked(e, props ? props.id : "")}></i>
      <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
    </>
  }
  const gridRef = useRef(null);
  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  }, [experiences, experiencesRequestStatus]);

  const _geSkillGroupNameText = (skillGroup, id) => {
    if (isObjectArray(skillGroup)) {
      let _skillGroup = skillGroup.find(l => l.value === id);
      if (_skillGroup && _skillGroup.text) {
        return _skillGroup.text;
      }
    }
    return "";
  }

  const getSkillGroupName = (field, data, column) => {
    return _geSkillGroupNameText(skillGroupOptions, data["SkillGroupId"]);
  }

  const columns = [
    { field: 'ExperienceName', headerText: 'Skill Name', width: '100' },
    { field: 'Type', headerText: 'Field Type', width: '100' },
    { field: 'Status', headerText: 'Status', width: '100' },
    { field: 'SkillGroupId', headerText: 'Skill Group Name', width: '100', valueAccessor: getSkillGroupName },
    {
      headerText: 'Action',
      template: addActionTemplate,
      width: '50px',
    },
  ];


  const onValueUpdated = (e, section, value) => {
    let _experienceObj = Object.assign({}, experience);
    let _newExperienceObj = onValueChanged(_experienceObj, e, section, value);
    dispatch(updateSingleData({
      experience: _newExperienceObj,
      operation: Operation.UPDATED
    }));
  };

  const onSaveClicked = (e, id, created) => {
    e.preventDefault();
    if (isFormValid(EXPERIENCE_FORM)) {
      if (experience) {
        if (created) {
          dispatch(createExperience({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVESETTINGEXPERIENCE",
            payload: {
              id: id,
              data: JSON.stringify({
                settingExperience: JSON.stringify(experience),
              })
            }
          }));
        }
        else {
          dispatch(updateExperience({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVESETTINGEXPERIENCE",
            payload: {
              id: id,
              data: JSON.stringify({
                settingExperience: JSON.stringify(experience),
              })
            }
          }));
        }
      }
      setCreateMode(false);
      setEditmode(false);
      setCFInvalid(false);
      setCFSubmitted(false);
    }
    else {
      setCFInvalid(true);
    }
    setCFSubmitted(true);
  };

  const onAddExperienceOptionClicked = (e) => {
    e.preventDefault();
    dispatch(createExperienceOptionDetail());
  };

  const onDeleteExperienceOptionClicked = (e, index) => {
    e.preventDefault();
    dispatch(deleteExperienceOptionDetail(index));
  }

  let _statusOptions;
  useEffect(() => {
    if (isObjectArray(userStatuses) && userStatuses.length > 0) {
      _statusOptions = userStatuses.map((status, index) => (
        <option key={index} value={status.value}>
          {status.text}
        </option>
      ));
      setStatusOptions(_statusOptions);
    }

  }, [userStatuses]);


  const onCancelClicked = (e) => {
    setCreateMode(false);
    setEditmode(false);
    setCFInvalid(false);
    setCFSubmitted(false);
  };

  const onNextClicked = (e) => {
    history.push('companyStatuses');
    // console.log('forms --->', forms)
  }

  const onBack = (e) => {
    history.push('companySkillGroup');
  }





  return (
    <>
      <div className="container ">
        <div className="row">
          <div className="col-md-4 card setup-wizard-left-side d-flex align-items-stretch p-0">
            <SetupProgress percentage="30" getPercentage="45" subTitle="Skill Fields" currentPage="companyExperience" />
          </div>
          <div className="col-md-8 card setup-wizard-right-side d-flex align-items-stretch p-0">
            <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn">

              {(experiencesRequestStatus === fetchStatus.LOADING ||
                experienceRequestStatus === fetchStatus.LOADING ||
                experienceRemovalStatus === fetchStatus.LOADING ||
                skillGroupOptionsStatus === fetchStatus.LOADING
              ) &&
                <div className="setup-wizard-loader">
                  <GridLoader />
                </div>
              }

              <div className="row p-2">
                <div className="col-md-12">
                  <div className="card-left-header">Skill Fields Configuration</div>
                  <div className="card-left-sub-header">Setup custom skill sets tailored to organizational proficiency needs.</div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-end">
                  {(createMode || editMode)?
                    <>
                      <button className="c-btn dark-btn" type="button" title='Cancle' onClick={(e) => onCancelClicked(e)}>
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                    :
                    <button className="c-btn dark-btn" type="button" title='Create new skill group' onClick={(e) => onAddRecordClicked(e)}>
                      <i className="fa fa-plus"></i>
                    </button>
                  }
                </div>
              </div>

              {!editMode && !createMode ?
                <div className="row p-2">
                  <GridComponent ref={gridRef} dataSource={experiences} allowPaging={true} allowSorting={true} allowResizing={true} width="100%" toolbar={['Search']} pageSettings={{ pageSize: 7, pageCount: 5 }} rowHeight={50} columns={columns} >
                    <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                  </GridComponent>
                </div>
                :
                <>
                  <div className="row p-2">
                    <div className="col-md-6">
                      <div className="form-group p-0 pt-2">
                        <label id="lblFirstName" className="flyout-label" htmlFor="txtExperienceNumber">Skill Name</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{experience && experience.ExperienceName ? experience.ExperienceName.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-address-card"></i>}
                            control={
                              <ShortText
                                id="txtExperienceName"
                                name="txtExperienceName"
                                ShortTextClasses="form-control"
                                placeholder="Skill Name"
                                value={experience && experience.ExperienceName ? experience.ExperienceName.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.EXPERIENCE_DETAILS, null)}
                                // required={experienceForm.ExperienceName.Required}
                                required={true}
                                // errorMessage={experienceForm.ExperienceName.Validation}
                                form={EXPERIENCE_FORM}
                                submited={cfSubmitted}
                                disabled={editMode} />
                            }
                          />
                        }
                      </div>

                    </div>

                    <div className="col-md-6">
                      <div className="form-group p-0 pt-2">
                        <label id="lblSkillGroupId" className="flyout-label" htmlFor="drpSkillGroupId">Skill Group</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{experience ? experience.Status.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-address-card"></i>}
                            control={
                              <Selector
                                id="drpSkillGroupId"
                                name="drpSkillGroupId"
                                SelectorClasses="form-select"
                                value={(experience && experience.SkillGroupId) ? experience.SkillGroupId.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.EXPERIENCE_DETAILS, null)}
                                options={
                                  <>
                                    {skillGroupOptions.map((o) => <option value={o.value}>{o.text}</option>)}
                                  </>
                                }
                                required={true}
                                // errorMessage={}
                                form={EXPERIENCE_FORM}
                                submited={cfSubmitted} />}
                          />
                        }
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group p-0 pt-2">
                        <label id="lblType" className="flyout-label" htmlFor="drpType">Field Type</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{experience && experience.Type ? experience.Type.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-address-card"></i>}
                            control={
                              <Selector
                                id="drpType"
                                name="drpType"
                                SelectorClasses="form-select"
                                value={experience && experience.Type ? experience.Type.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.EXPERIENCE_DETAILS, null)}
                                options={
                                  <>
                                    <option value="Single Choice">Single Choice</option>
                                    <option value="Multi Choice">Multi Choice</option>
                                    <option value="Months">Months</option>
                                  </>
                                }
                                // required={experienceForm.Type.Required}
                                required={true}
                                // errorMessage={experienceForm.Type.Validation}
                                form={EXPERIENCE_FORM}
                                submited={cfSubmitted}
                                disabled={editMode} />
                            }
                          />
                        }
                      </div>
                    </div>
                  </div>

                  <div className="row p-2">

                    {(experience && (experience.Type.Data === 'Single Choice' || experience.Type.Data === 'Multi Choice')) && (
                      <div className="col-md-6">
                        <div className="form-group p-0 pt-2">
                          <label id="lblType" className="flyout-label" htmlFor="drpType">Options</label>

                          {experience.ExperienceOptions.map((option, index) => (
                            !editMode && !createMode ?
                              <label className="field-label-value mb-3">{option}</label>
                              :
                              <div key={index} className='d-flex align-items-center mb-3'>
                                <div className='w-100'>
                                  <ShortText
                                    id={`txtExperienceOption-${index}`}
                                    name={`txtExperienceOption-${index}`}
                                    ShortTextClasses="form-control"
                                    // placeholder="Experience Option"
                                    placeholder={`Experience Option ${index + 1}`}
                                    value={option}
                                    onChange={(e) => onValueUpdated(e, sections.EXPERIENCE_OPTIONS_DETAILS, null)}
                                    required={true}
                                    // errorMessage={experienceForm.ExperienceOption.Validation}
                                    form={EXPERIENCE_FORM}
                                    submited={cfSubmitted}
                                    disabled={editMode} />
                                </div>
                                {
                                  createMode && experience.ExperienceOptions.length > 1 &&
                                  <RemoveIconButton
                                    title="Remove Option"
                                    RemoveIconButtonClasses="delete-document-button ms-4"
                                    RemoveIconClasses="fa-lg"
                                    onClick={(e) => onDeleteExperienceOptionClicked(e, index)}
                                  />
                                }
                              </div>
                          ))}
                          {createMode &&
                            <button type="button" className="c-btn dark-btn" onClick={(e) => onAddExperienceOptionClicked(e)} >
                              Add Option
                            </button>
                          }
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div className="row p-2">
                    <div className="col-md-6">
                      <div className="form-group p-0 pt-2">
                        <label id="lblStatuses" className="flyout-label" htmlFor="drpStatuses">Status</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{experience ? experience.Status.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-address-card"></i>}
                            control={
                              <Selector
                                id="drpStatuses"
                                name="drpStatuses"
                                SelectorClasses="form-select"
                                value={(experience && experience.Status) ? experience.Status.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.EXPERIENCE_DETAILS, null)}
                                options={
                                  <>
                                    {statusOptions}
                                  </>
                                }
                                required={true}
                                // errorMessage={}
                                form={EXPERIENCE_FORM}
                                submited={cfSubmitted} />}
                          />
                        }
                      </div>
                    </div>
                  </div> */}
                </>
              }




            </div>
            <div className="card-footer bottom-0 px-4 py-2 w-100">
              <div className="row">
                <div className="col-md-12">
                  {experienceCreationStatus === fetchStatus.LOADING || experienceModificationStatus === fetchStatus.LOADING
                    ? <div className="float-end my-2"><EllipsisLoader /></div>
                    :
                    <>
                      {(createMode || editMode) ?
                        <>
                          <BackButton onBackClicked={(e) => onCancelClicked(e)} text="Cancle" />
                          <div className="float-end">
                            <ContinueButton onClick={(e) => onSaveClicked(e, experience ? experience.id : "", createMode)} text="Save" />
                          </div>
                        </>
                        :
                        <>
                          <BackButton onBackClicked={(e) => onBack(e)} />
                          <div className="float-end">
                            <ContinueButton onClick={(e) => onNextClicked(e)} />
                          </div>
                        </>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyExperience