import React from 'react';

function AddButton(props) {
    return (
        <button
            type="button"
            className={`c-btn dark-btn  ${props.SaveButtonClasses ? props.SaveButtonClasses : ""}`}
            title={props.toolTipText ? props.toolTipText : "Add"}
            onClick={(e) => props.onAddClicked && props.onAddClicked(e)}>
            {props.buttonText ? props.buttonText : 'Add'}
        </button>
    );
}

export default AddButton;