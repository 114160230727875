import React from 'react';

function OkButton(props) {
    return (
        <button
            type="button"
            className="btn btn-dark"
            title={props.toolTipText ? props.toolTipText : "OK"}
            onClick={props.onOkClicked ? props.onOkClicked : null}>
            <i className='fa fa-check me-2'></i>Ok
        </button>
    );
}

export default OkButton;