import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    title: "",
    content: "",
    show: false,
    subtitle: "",
};

export const modalPopUpSlice = createSlice({
    name: 'modalPopUp',
    initialState,
    reducers: {
        showModal: (state, action) => {
            const { title, content, subtitle } = action.payload
            state.title = title;
            state.content = content;
            state.subtitle = subtitle;
            state.show = true;
        },
        closeModal: state => {
            state.show = false;
            state.title = "";
            state.content = "";
            state.subtitle = "";
        }
    }
});

export const { showModal, closeModal } = modalPopUpSlice.actions;

export default modalPopUpSlice.reducer

export const selectModalPopUpDetail = state => {
    return {
        title: state.modalPopUp.title,
        content: state.modalPopUp.content,
        show: state.modalPopUp.show,
        subtitle: state.modalPopUp.subtitle,
    }
}