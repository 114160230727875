import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { beforeCloseLeadCalledFlyout, closeLeadCalledFlyout, selectFlyoutDetail } from './reducerSlices/leadCalledFlyoutSlice';
import ReactTooltip from 'react-tooltip';
import Radio from '../Controls/custom/checkers/radio';
import Statement from '../Controls/custom/textbox/statement';
import sortImg from './../../assets/images/clipart/sort.svg';
import DataNotFoundCard from '../Card/DataNotFoundCard';
import { getLeadById, saveLeadCalledOn, selectCalledOnLead, selectLeadById, selectLeadCalledOnStatus, updateSingleLead } from '../../features/crm/reducerSlices/leadSlice';
import { toLocalDate } from '../../common/common';
import SortButton from '../Controls/custom/buttons/sortButton';
import { accountService } from '../../features/accounts/services/accountService';
import { isFormValid } from '../Controls/services/formService';
import { fetchStatus, tenantId } from '../../api/client';
import SaveButton from '../Controls/custom/buttons/saveButton';
import EllipsisLoader from '../Controls/loaders/ellipsisLoader';


function LeadCalledFlyout() {

    const dispatch = useDispatch();
    const flyoutDetails = useSelector(selectFlyoutDetail);

    const ToggleClass = () => {
        dispatch(beforeCloseLeadCalledFlyout());

        const timer = setTimeout(() => {
            dispatch(closeLeadCalledFlyout());
        }, 1500);
    };

    const [type, setType] = useState("CalledOn");

    const [note, setNote] = useState("");
    const [notesList, setNotesList] = useState([]);

    const CALLEDIN_NOTE_FORM = "CalledinNoteForm";

    const [lcnfSubmitted, setLCNFSubmitted] = useState(false);

    const lead = useSelector(selectLeadById);
    const [sort, setSort] = useState(false);

    const leadCalledOnStatus = useSelector(selectLeadCalledOnStatus);
    const calledOnLead = useSelector(selectCalledOnLead);

    useEffect(() => {
        let _logs;
        let _noteDiv;
        let _noteArr = [];

        if (lead && lead.StatusLogs) {
            _logs = lead.StatusLogs.map((log, index) => {
                if (log && log.LogValue) {

                    if (log.LogValue === -2 || log.LogValue === -3) {
                        _noteDiv = <div className="tracking-item">
                            {/* <div className="tracking-icon status-intransit bg-warning">
                            <img src={messageSquareImg} />
                        </div> */}
                            {getIcon(log.LogValue)}
                            <div className="tracking-content">
                                <p>
                                    <span className="p-name">{log.UserName}</span>
                                    {log && getStatus(log.LogValue, log.LogType)}
                                </p>
                                <p className="p-subtext">{log.Content}</p>
                                <span className="p-light-gray">{log.TimeStamp && toLocalDate(log.TimeStamp)}</span>
                            </div>
                        </div>
                        _noteArr.push(_noteDiv);
                    }
                }

            });

            setNotesList(_noteArr.reverse());
        }
    }, [lead]);

    const onSort = () => {
        setSort(!sort);
        const sortData = [...notesList].reverse();
        setNotesList(sortData);
    }


    const getIcon = (logValue) => {
        let icon;
        if (logValue === -2) {
            // icon = <div className="tracking-icon status-intransit bg-light-orange fa-flip-horizontal">
            //     <i className="fa fa-duotone fa-phone-volume"></i>
            // </div>
            icon = <div className="tracking-icon status-intransit bg-light-orange">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-inbound-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0z" />
                </svg>
            </div >
        } else if (logValue === -3) {
            icon = <div className="tracking-icon status-intransit bg-blue">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-outbound-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                </svg>
            </div>
        }

        return icon;
    }

    const getStatus = (logValue, logType) => {
        let statusText;

        if (logValue === -3) {
            statusText = <React.Fragment><span className="p-changed"> Lead </span><span className="p-status"><span className="badge rounded-pill bg-blue"> {logType} </span></span></React.Fragment>;
        }
        else if (logValue === -2) {
            statusText = <React.Fragment><span className="p-status"> <span className="badge rounded-pill bg-light-orange"> {logType} </span></span><span className="p-changed"> Lead </span></React.Fragment>;
        }

        return statusText;
    };

    const onSaveClicked = async (id) => {
        if (id) {
            const user = accountService.getAuthenticatedUser();
            if (isFormValid(CALLEDIN_NOTE_FORM)) {
                var noteDetails = {};
                noteDetails = {
                    Content: note,
                    statusValue: 0,
                    status: "No Change",
                };

                setLCNFSubmitted(false);
                if (id) {
                    if (type === 'CalledOn') {

                        await dispatch(saveLeadCalledOn({
                            client: tenantId,
                            serviceType: "TENANTADMIN",
                            operation: "SAVELEADCALLEDON",
                            payload: {
                                id: lead.id,
                                data: JSON.stringify(noteDetails)
                            }
                        }));

                        // _loadLead(lead.id, undefined);
                        setNote("");
                        ToggleClass();
                    }
                    if (type === 'CalledIn') {
                        await dispatch(saveLeadCalledOn({
                            client: tenantId,
                            serviceType: "TENANTADMIN",
                            operation: "SAVELEADCALLEDIN",
                            payload: {
                                id: lead.id,
                                data: JSON.stringify(noteDetails)
                            }
                        }));

                        // _loadLead(lead.id, undefined);
                        setNote("");
                        ToggleClass();
                    }
                }
            }
            else {
                setLCNFSubmitted(true);
            }
        }
    };

    const _loadLead = async (id, page) => {
        await dispatch(getLeadById({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETLEADBYID",
                payload: {
                    id: id,
                    data: ""
                }
            }
        }));
    }

    useEffect(() => {
        if (leadCalledOnStatus === fetchStatus.SUCCEEDED) {
            if (calledOnLead && calledOnLead.id) {
                dispatch(updateSingleLead(calledOnLead));
            }
        }
    }, [leadCalledOnStatus, calledOnLead]);

    return (
        <>
            {(flyoutDetails && flyoutDetails.show) ?
                <>
                    <div className="flyout-wrapper position-fixed top-0 end-0">
                        <div className={`flyout-card ${flyoutDetails.isActive ? 'close' : 'open'}`}>
                            <div className="flyout-card-body overflow-visible">
                                <div className="float-end">
                                    <div className="close-btn">
                                        <i className="fa fa-times" aria-hidden="true" onClick={ToggleClass} data-tip='Close'></i>
                                        <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                    </div>
                                </div>
                                <div className="flyout-card-title">Called In / Called Out</div>
                                <div className="flyout-card-sub-title">
                                    {lead && lead.LeadName ? lead.LeadName.Data : ""}
                                </div>

                                <div className='pt-3'>
                                    {/* <label className="flyout-label pt-3">Called</label> */}
                                    <Radio id="rdbtnCalledOn"
                                        name="call_type"
                                        label="Call Out"
                                        value="CalledOn"
                                        onChange={() => setType("CalledOn")}
                                        checked={type === "CalledOn"}
                                    />
                                    <Radio id="rdbtnCalledOut"
                                        name="call_type"
                                        label="Call In"
                                        value="CalledIn"
                                        onChange={() => setType("CalledIn")}
                                        checked={type === "CalledIn"}
                                    />
                                </div>


                                <label className="flyout-label pt-2">Notes</label>
                                <Statement
                                    id="txtAdminNote"
                                    name="txtAdminNote"
                                    value={note}
                                    // StatementClasses="form-control"
                                    StatementClasses="flyout-input"
                                    rows={2}
                                    onChange={(e) => setNote(e.target.value)}
                                    required={true}
                                    errorMessage="Please enter note"
                                    form={CALLEDIN_NOTE_FORM}
                                    submited={lcnfSubmitted}
                                    placeholder="Enter note..."
                                />
                                <div className="d-flex align-items-baseline pt-3">
                                    <div className="ms-auto">
                                        {(leadCalledOnStatus === fetchStatus.LOADING) ? <EllipsisLoader />
                                            : <>
                                                <SaveButton
                                                    buttonText={'Add Note'}
                                                    onSaveClicked={() => onSaveClicked(lead ? lead.id : "")} />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className="flyout-card-filter-bar d-flex align-items-baseline">
                                <div className="flyout-card-semi-title">
                                    Recent
                                </div>
                                <div className="ms-auto">
                                    {/* <button className="c-btn white-btn flyout-sort-bt" type="button" onClick={sort}>
                                        <img src={sortImg} className="pe-2" />Sort
                                    </button> */}
                                    <SortButton
                                        SortButtonClasses="bg-white mx-2"
                                        sorted={sort}
                                        onSortClicked={(e) => onSort(e)}
                                    />
                                </div>
                            </div>

                            <div className="flyout-card-body">

                                <div className="tracking-list pt-3">
                                    {/* {notesList} */}
                                    {Object.keys(notesList).length === 0 ?
                                        <DataNotFoundCard containerClassOverriding={`data-not-found-container-100 pt-5 mt-5`} title={'No notes found '} />
                                        :
                                        <div className="tracking-list pt-3">
                                            {notesList}
                                        </div>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='flyout-backdrop flyout-backdrop-show' />
                </>
                :
                <></>
            }
        </>
    )
}

export default LeadCalledFlyout;