import React from "react";
import ReactTooltip from 'react-tooltip';
import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import girlFaceImg from './../../assets/images/clipart/girl-face.svg';
import nophoto from './../../assets/images/clipart/nophoto.png';
import phoneCallFillImg from './../../assets/images/clipart/phone-call-fill.svg';
import calendarImg from './../../assets/images/clipart/calendar.svg';
import mailImg from './../../assets/images/clipart/mail.svg';
import mapLocationImg from './../../assets/images/clipart/map-location.svg';
import noteImg from './../../assets/images/clipart/note.svg';
import documentImg from './../../assets/images/clipart/document.svg';
import msgImg from './../../assets/images/clipart/msg.svg';

const ProspectsPeopleHorizontalCard = (props) => {

  const _getStatusClass = () => {
    if (props.status === "Active") {
      return 'status-active';
    }
    else if (props.status === "Incomplete") {
      return 'status-incomplete';
    }
    else if (props.status === "In Progress") {
      return 'status-inprogress';
    }
    else if (props.status === "Available") {
      return 'status-available';
    }
    else if (props.status === "Dispatched") {
      return 'status-dispatched';
    }
    else if (props.status === "InActive") {
      return 'status-inactive';
    }
    else if (props.status === "DNU") {
      return 'status-dnu';
    }
  }


  return (
    <div className="card people-horizontal-card">
      {/* <div className={`status ${_getStatusClass()}`}>{props.status && props.status}</div>
      <div className="c-checkbox">
        <input type="checkbox" name="c1" />
      </div> */}
      <div className="people-horizontal-card-body p-2 cursor-pointer" onClick={props.onClick}>
        <div className="d-flex">
          <div className="pe-1">
            <div className="people-horizontal-card-photo" style={{ borderColor: props.profileColor }}>
              <div className="people-horizontal-card-photo-box" style={{ backgroundColor: props.profileColor }}>
                <img className="people-horizontal-card-profile-box-img"
                  src={props && props.profilePhoto ?
                    props.profilePhoto.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "")
                    : props && props.gender === 'Female' ? girlFaceImg
                      : props && props.gender === 'Male' ? boyFaceImg
                        : nophoto
                  }
                  alt=" " />
              </div>
            </div>
          </div>
          <div className="ps-3 col-4">
            <div className="title w-100">
              <span>{props.fullName}</span>
            </div>
            <div className="row">
              <div className="detail col-4">
                <img src={phoneCallFillImg} /> <span dangerouslySetInnerHTML={{ __html: props.phone.replace(/\*/g, '<span class="blur">*</span>')}} />
              </div>
              <div className="detail col-8">
                <img src={mailImg} /> <span dangerouslySetInnerHTML={{ __html: props.email.replace(/\*/g, '<span class="blur">*</span>')}} />
              </div>
              <div className="detail col-4">
                <img src={calendarImg} /> DOB: {props.dateOfBirth}
              </div>
              <div className="detail col-8">
                <img src={mapLocationImg} /> {props.location}
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="people-horizontal-card-info-box">
              <div className="detail">Skill</div>
              <div className="detail detail-bold text-truncate" title={props.skill}>{props.skill}</div>
              <div className="detail">Language</div>
              <div className="detail detail-bold text-truncate" title={props.language}>{props.language}</div>

            </div>
          </div>
          {/* <div className="col-2">
            <div className="people-horizontal-card-info-box">
              <div className="detail">Last Called In</div>
              <div className="detail detail-bold text-truncate" title={props.lastCalledIn}>{props.lastCalledIn}</div>
              <div className="detail">Last Called Out</div>
              <div className="detail detail-bold text-truncate" title={props.lastCalledOut}>{props.lastCalledOut}</div>
            </div>
          </div> */}
          <div className="col-3">
            <div className="people-horizontal-card-info-box">
              <div className="detail">Cumulative Active Days</div>
              <div className="detail detail-bold text-truncate">{props.cumulativeActiveDays}</div>
              <div className="detail">Last works on</div>
              <div className="detail detail-bold text-truncate">{props.lastStatus}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProspectsPeopleHorizontalCard;
