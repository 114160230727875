import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { routeTrackingStatusData } from '../../../features/crm/services/masterData';
import CheckBox from '../custom/checkers/checkbox';


function RouteTrackingStatusFilter(props) {

    const [open, setOpen] = useState(false);

    const routeTrackingStatusFilter = useSelector(props.routeTrackingStatusFilterSelector);

    const gfContainerRef = useRef(null);

    let _routeTrackingStatuses = routeTrackingStatusData;

    let routeTrackingStatusesList;
    let isRouteTrackingStatusesSelected = (routeTrackingStatusFilter.value && Array.isArray(routeTrackingStatusFilter.value) && routeTrackingStatusFilter.value.length > 0);
    if (_routeTrackingStatuses && Array.isArray(_routeTrackingStatuses) && _routeTrackingStatuses.length > 0) {
        routeTrackingStatusesList = _routeTrackingStatuses.map((routeTrackingStatus) => (
            routeTrackingStatus.value !== "Inprogress" &&
            <li key={routeTrackingStatus.value} className="filter-list-item"
                onClick={(e) => props.onRouteTrackingStatusChanged && props.onRouteTrackingStatusChanged(e, routeTrackingStatus.value, _routeTrackingStatuses)}>
                <CheckBox
                    id={`ckbRouteTrackingStatus-${routeTrackingStatus.value}`}
                    size="Small"
                    checked={isRouteTrackingStatusesSelected ? routeTrackingStatusFilter.value.includes(routeTrackingStatus.value) : false} />
                <span className={`ms-3 ${isRouteTrackingStatusesSelected && routeTrackingStatusFilter.value.includes(routeTrackingStatus.value) ? "filter-selected" : ""}`}>{routeTrackingStatus.text}</span>

            </li>
        ));
    }

    useEffect(() => {
        if (open && gfContainerRef && gfContainerRef.current) {
            gfContainerRef.current.focus();
        }
    });

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Route Status</span> :
                <span className="filter-value">{routeTrackingStatusFilter.value ? routeTrackingStatusFilter.text : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={gfContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    {routeTrackingStatusesList}
                </ul>
            </div> : <></>}
        </div>
    );
}

export default RouteTrackingStatusFilter;