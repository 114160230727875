import React, { useEffect, useState } from 'react';
// import './../../css/leadsDetailsTracking.css';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import RemoveIconButton from '../../../../component/Controls/custom/buttons/removeIconButton';
import { downloadDocument } from '../../../reviewer/reducerSlices/reviewerSlice';
import { useDispatch } from 'react-redux';
import { toLocalDate } from '../../../../common/common';
import { tenantId } from '../../../../api/client';
import DialCallButton from '../../../../component/Controls/buttons/dialCallButton';


function LeadsDetailsTracking(props) {
    const dispatch = useDispatch();

    const [logs, setLogs] = useState();

    const onDownloadClicked = async (e, id, documentName) => {
        await dispatch(downloadDocument({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "DOWNLOADDOCUMENT",
            payload: {
                id: id,
                data: documentName
            }
        }));
    };

    useEffect(() => {
        let _leadsDetails = props.leadsDetails;
        let _logs;
        if (isObjectArray(_leadsDetails) && _leadsDetails.length > 0) {
            _logs = _leadsDetails.map((leadsDetails, index) => (
                <div className="col-md-12">
                    <div className={`card leads-details-tracking-card mb-4`}>
                        <div className="leads-details-tracking-card-container">
                            <div className="card-body">
                                <div className="card-title leads-details-tracking-card-title">
                                    <span className="route-date">{leadsDetails.leadName ? leadsDetails.leadName : "Lead Name"}</span>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <p className="card-text"><medium className=""><span className="text-primary me-2"><i className="fa fa-flag me-1" aria-hidden="true"></i> Pipeline Status:</span>{leadsDetails.leadStatus}</medium></p>
                                        {/* <p className="card-text"> <medium className=""><span className="text-primary me-2"><i className="fa fa-phone me-2" aria-hidden="true"></i> Pipeline Phone:</span>{leadsDetails.leadPhone}</medium></p> */}
                                        <div className="card-text">
                                            <div className="d-flex align-items-center">
                                                <DialCallButton dialCallButtonClasses="me-1"/>
                                                <span className="text-primary me-2 ">Pipeline Phone:</span>{leadsDetails.leadPhone}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="card-text"> <medium className=""><span className="text-primary me-2"><i className="fa fa-briefcase me-1"></i>Visited:</span>{leadsDetails.visited === "Yes" ? "Yes" : "No"}  </medium></p>
                                        {leadsDetails.visitedTimeStamp ?
                                            <>
                                                <p className="card-text"><medium className=""><span className="text-primary me-2"> <i className="fa fa-clock me-1"></i>Time:</span>{toLocalDate(leadsDetails.visitedTimeStamp)}</medium></p>
                                            </>
                                            :
                                            <></>
                                        }

                                    </div>
                                    <div className="col-md-3">
                                        {leadsDetails.photo &&
                                            <>
                                                <a
                                                    href={leadsDetails.UploadedFileInfo ? leadsDetails.UploadedFileInfo : "#"}
                                                    onClick={(e) => { e.preventDefault(); onDownloadClicked(e, props.routeTracking.id, leadsDetails.photo); }}
                                                    title={"Visited Photo Download"}>
                                                    <span>
                                                        <i className="fa fa-camera  me-2" aria-hidden="true" />Visited Photo
                                                    </span>
                                                </a>
                                            </>
                                        }
                                    </div>
                                    <div className="col-md-1">
                                        {props.readonly ?
                                            <></>
                                            :
                                            leadsDetails.visited !== "Yes" ?
                                                <RemoveIconButton
                                                    title="Remove pipeline"
                                                    RemoveIconButtonClasses="delete-document-button"
                                                    RemoveIconClasses="fa-2x"
                                                    onClick={(e) => props.onDeleteClicked && props.onDeleteClicked(e, leadsDetails.leadId)}
                                                />
                                                : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            props.footerContent ? <div className="card-footer">
                                {props.footerContent}
                            </div> : <></>
                        }
                    </div >
                </div>
            ));
            setLogs(_logs);
        }
        else {
            setLogs(undefined);
        }
    }, [props.leadsDetails, props.readonly]);

    return (
        <>
            {logs}
        </>
    );
}

export default LeadsDetailsTracking;