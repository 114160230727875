import React, { useEffect, useState } from 'react';
// import './css/logCard.css';
import { isObjectArray } from '../../utilities/utilityFunctions';
import { toLocalDate } from '../../common/common';
import DataNotFoundCard from './DataNotFoundCard';


function LogCardRouteTracking(props) {

    const getStatus = (logValue, logType) => {
        let statusText;

        if (logValue === 101) {
            statusText = <><span className="p-changed">Route List </span><span className="p-status"><span className="badge rounded-pill bg-green"> Created </span></span></>;
        }
        else if (logValue === 102) {
            statusText = <><span className="p-changed">Lead </span><span className="p-status"><span className="badge rounded-pill bg-success"> Visited</span></span></>;
        }
        else if (logValue === 103) {
            statusText = <><span className="p-changed">Lead </span><span className="p-status"><span className="badge rounded-pill bg-danger"> Removed</span></span></>;
        }
        else if (logValue === 104) {
            statusText = <><span className="p-changed">Lead </span><span className="p-status"><span className="badge rounded-pill bg-gold"> Updated</span></span></>;
        }
        else {
            statusText = <><span className="text-muted statusfont"></span><span className="p-status"><span className="badge rounded-pill bg-gray"> {logType} </span></span></>;
        }

        return statusText;
    };


    const getStatusIcon = (logValue) => {
        let statusIcon;
        if (logValue === 101) {
            statusIcon = <div className="tracking-icon status-intransit bg-create"><span><i className="fa fa-bolt fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 102) {
            statusIcon = <div className="tracking-icon status-intransit bg-blue"><span><i className="fa fa-route fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 103) {
            statusIcon = <div className="tracking-icon status-intransit bg-danger"><span><i className="fa fa-route fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 104) {
            statusIcon = <div className="tracking-icon status-intransit bg-purple"><span><i className="fa fa-route fa-md" aria-hidden="true"></i></span></div>;
        }
        else {
            statusIcon = <div className="tracking-icon status-intransit bg-secondary"><span><i className="fa fa-info fa-md" aria-hidden="true"></i></span></div>;
        }
        return statusIcon;
    }

    const [logs, setLogs] = useState();

    useEffect(() => {
        let _statusLogs = props.statusLogs;
        let _logs;
        if (isObjectArray(_statusLogs) && _statusLogs.length > 0) {
            _logs = _statusLogs.map((log, index) => (
                <>
                    {log &&
                        <div className="tracking-item">
                            {getStatusIcon(log.LogValue)}
                            <div className="tracking-content">
                                <p>
                                    <span className="p-name pe-1">{log.UserName != null ? log.UserName : ""}</span>
                                    {log && getStatus(log.LogValue, log.LogType)}
                                </p>
                                {
                                    log.LogValue === 102 ?
                                        <>
                                            <p className="card-text text-justify">
                                                {log && log.leadName && <p className="p-light-gray">Lead : <span className="p-dark-gray">{log && log.leadName}</span></p>}
                                                {log && log.Content && <p className="p-light-gray">Note : <span className="p-dark-gray">{log && log.Content}</span></p>}
                                            </p>
                                        </>
                                        :
                                        <>
                                            <p className="p-subtext">{(log.LogValue === 101 || log.LogValue === 103 || log.LogValue === 104)  && <span className='p-light-gray'>Lead : </span>}
                                                {log && log.Content}
                                            </p>
                                        </>
                                }
                                <p className="p-light-gray">{log && toLocalDate(log.TimeStamp)}</p>
                            </div>
                        </div>
                    }
                </>
            ));
            setLogs(_logs);
        }
        else {
            setLogs(<DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title="" description="" />);
        }
    }, [props.statusLogs]);


    return (
        <div>
            {logs}
        </div>
    );
}

export default LogCardRouteTracking;