import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../../api/client';
import { changeApplicantPassword, selectApplicantPasswordStatus } from '../../../features/reviewer/reducerSlices/reviewerSlice';
// import CancelButton from '../buttons/cancelButton';
import SaveButton from '../custom/buttons/saveButton';
import InputGroup from '../custom/inputGroup/inputGroup';
import EllipsisLoader from '../loaders/ellipsisLoader';
import { isFormValid } from '../services/formService';
import PasswordText from '../custom/textbox/passwordText';

// import './css/modalPopUp.css';
// import './css/changePasswordModalPopUp.css';
import { closeModal, selectModalPopUpDetail } from './reducerSlices/changePasswordModalPopUpSlice';

function ChangePasswordModalPopUp(props) {

    const dispatch = useDispatch();

    const PASSWORD_RESET_FORM = "PasswordResetForm";

    const applicantPasswordStatus = useSelector(selectApplicantPasswordStatus);
    const modalPopUpDetails = useSelector(selectModalPopUpDetail);

    const [password, setPassword] = useState("");
    const [changePassword, setChangePassword] = useState("");
    const [prfSubmitted, setPRFSubmitted] = useState(false);
    const [passwordMatched, setPasswordMatched] = useState(true);
    const [passwordLengthInvalid, setPasswordLengthInvalid] = useState(false);

    useEffect(() => {
        if (applicantPasswordStatus === fetchStatus.SUCCEEDED) {
            setPassword("");
            setChangePassword("");
            dispatch(closeModal());
        }
    }, [applicantPasswordStatus]);

    const onCloseClicked = () => {
        dispatch(closeModal());
        setPRFSubmitted(false);
        setPasswordMatched(true);
        setPasswordLengthInvalid(false);
        setPassword("");
        setChangePassword("");
    };

    const onSaveClicked = async (id) => {
        setPasswordMatched(true);
        setPasswordLengthInvalid(false);
        setPRFSubmitted(false);
        if (isFormValid(PASSWORD_RESET_FORM)) {
            if (password.length >= 8) {
                if (password === changePassword) {
                    setPasswordMatched(true);
                    if (id) {
                        await dispatch(changeApplicantPassword({
                            client: tenantId,
                            serviceType: "TENANTADMIN",
                            operation: "CHANGEUSERPASSWORD",
                            payload: {
                                id: id,
                                data: JSON.stringify({
                                    password: password
                                })
                            }
                        }));
                    }
                }
                else {
                    setPasswordMatched(false);
                }
            }
            else {
                setPasswordLengthInvalid(true);
            }
        }
        else {
            setPasswordMatched(true);
            setPRFSubmitted(true);
        }
    };

    return (
        <React.Fragment>
            {(modalPopUpDetails && modalPopUpDetails.show) ? (<React.Fragment>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-block">
                                <div className="d-flex">
                                    <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                                    <button
                                        type="button"
                                        className="modal-close-button ms-auto"
                                        onClick={onCloseClicked}>
                                        <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </button>
                                </div>
                                <div className="d-flex">
                                    <span className="modal-sub-title">Applicant password reset.</span>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label id="lblPassword" className="field-label" htmlFor="txtPassword">Password</label>
                                        <InputGroup
                                            icon={<i className="fa fa-unlock-alt"></i>}
                                            control={
                                                <PasswordText id="txtPassword"
                                                    name="txtPassword"
                                                    PasswordTextClasses="form-control"
                                                    placeholder="Enter Password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required={true}
                                                    errorMessage="Please enter password"
                                                    form={PASSWORD_RESET_FORM}
                                                    submited={prfSubmitted} />
                                            } />
                                        {passwordLengthInvalid && <span className="error-label">
                                            <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                            {"Password should be at least 8 characters"}
                                        </span>}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">

                                        <label id="lblConfirmPassword" className="field-label pt-2" htmlFor="txtConfirmPassword">Confirm Password</label>
                                        <InputGroup
                                            icon={<i className="fa fa-unlock-alt"></i>}
                                            control={
                                                <PasswordText
                                                    id="txtConfirmPassword"
                                                    name="txtConfirmPassword"
                                                    PasswordTextClasses="form-control"
                                                    placeholder="Enter Confirm Password"
                                                    value={changePassword}
                                                    onChange={(e) => setChangePassword(e.target.value)}
                                                    required={true}
                                                    errorMessage="Please enter confirm password"
                                                    form={PASSWORD_RESET_FORM}
                                                    submited={prfSubmitted} />
                                            } />
                                        {passwordMatched ? <React.Fragment></React.Fragment> : <span className="password-mismatch-error">
                                            <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                            Password and Confirm password did not match
                                        </span>}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {applicantPasswordStatus === fetchStatus.LOADING ? <EllipsisLoader /> : <React.Fragment>
                                    {/* <CancelButton
                                    CancelButtonClasses="mr-2"
                                    onCancelClicked={() => onCloseClicked()} /> */}
                                    <SaveButton
                                        onSaveClicked={() => onSaveClicked(modalPopUpDetails.id)} /></React.Fragment>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </React.Fragment>) : <React.Fragment></React.Fragment>}
        </React.Fragment>
    );
}

export default ChangePasswordModalPopUp;