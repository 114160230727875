import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { beforeCloseChatFlyout, closeChatFlyout, selectChatFlyoutShow, selectFlyoutDetail } from './reducerSlices/chatFlyoutSlice';

import user1Img from './../../assets/images/clipart/user-1.png';
import user2Img from './../../assets/images/clipart/user-2.png';
import sendImg from './../../assets/images/clipart/send.svg';
import ChatRoom from '../Controls/chat/Chat';
import {
  selectApplicantById,
  selectSingleStatus as applicantStatus,
  getApplicantById
} from '../../features/applicant/reducerSlices/applicantSlice';
import { createMessage, getAllMessages, selectAllMessages, selectMessageStatus, updateMessage, updaterefreshInbox } from '../../features/inbox/reducerSlices/messageSlice';
import { accountService } from '../../features/accounts/services/accountService';
import { useEffect } from 'react';
import { fetchStatus, tenantId } from '../../api/client';
import ReactTooltip from 'react-tooltip';


function ChatFlyout() {

  const dispatch = useDispatch();
  const chatFlyoutShow = useSelector(selectChatFlyoutShow);

  const flyoutDetails = useSelector(selectFlyoutDetail);

  const ToggleClass = () => {
    dispatch(beforeCloseChatFlyout());

    const timer = setTimeout(() => {
      setChatVisibility(false)
      dispatch(closeChatFlyout());
    }, 1500);
  };

  const [chatVisibility, setChatVisibility] = useState(false);

  const user = accountService.getAuthenticatedUser();
  const applicant = useSelector(selectApplicantById);
  const applicantRequestStatus = useSelector(applicantStatus);
  const Messages = useSelector(selectAllMessages);
  const MessagesStatus = useSelector(selectMessageStatus);

  const _loadApplicant = async (id, page) => {
    await dispatch(getApplicantById({
      client: tenantId,
      serviceType: "TENANTUSER",
      operation: "GET",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  useEffect(() => {
    if (flyoutDetails.applicantId) {
      _loadApplicant(flyoutDetails.applicantId);
    }
  }, [flyoutDetails.applicantId]);

  const _loadapplicantMessages = async (id) => {
    dispatch(getAllMessages({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETMESSAGES",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  useEffect(() => {
    if (applicantRequestStatus === fetchStatus.SUCCEEDED && flyoutDetails.applicantId) {
      _loadapplicantMessages(flyoutDetails.applicantId);
    }
  }, [applicantRequestStatus]);

  const parentCallback = (sender, senderAvatar, message, applicant) => {
    const messagetext = {
      text: message,
      userType: "User",
      sendBy: (user ? user.firstName + " " + user.lastName : ""),
      sendById: user.id,
      sendOn: (new Date()).toUTCString(),
      receivedBy: applicant.FirstName.Data + " " + applicant.LastName.Data,
      receivedById: applicant.id,
      receivedOn: (new Date()).toUTCString(),
      isReceived: true,
      isRead: false,
      readOn: (new Date()).toUTCString()
    };
    const applicantdata =
      [{
        id: applicant.id,
        FirstName: applicant.FirstName.Data,
        LastName: applicant.LastName.Data,
        PhoneNumber: applicant.PhoneNumber.Data,
      }
      ]

    dispatch(createMessage({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "SAVEMESSAGE",
      payload: {
        id: "",
        data: JSON.stringify({
          message: JSON.stringify(messagetext),
          applicants: applicantdata
        })
      }
    }));
  }

  const updateReadmessages = (applicant) => {
    dispatch(updateMessage({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "UPDATEMESSAGE",
      payload: {
        id: applicant.id,
        data: null
      }
    }));
    dispatch(updaterefreshInbox("Updated"));
  }

  useEffect(() => {
    if (MessagesStatus === fetchStatus.SUCCEEDED && flyoutDetails.show) {
      setChatVisibility(true);
    }
  }, [MessagesStatus]);

  useEffect(() => {
    const handleBackButton = (event) => {
        if (chatFlyoutShow) {
            dispatch(closeChatFlyout());
        }
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
        window.removeEventListener('popstate', handleBackButton);
    };
}, [chatFlyoutShow, dispatch]);

  return (
    <>
      {(flyoutDetails && flyoutDetails.show) ?
        <>
          <div className="flyout-wrapper position-fixed top-0 end-0">
            <div className={`flyout-card ${flyoutDetails.isActive ? 'close' : 'open'}`}>
              <div className="flyout-card-header">
                <div className="float-end">
                  <div className="close-btn">
                    <i className="fa fa-times" aria-hidden="true" onClick={ToggleClass} data-tip='Close'></i>
                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                  </div>
                </div>
                <div className="flyout-card-title">
                  {/* <img src={user1Img} className="flyout-list-group-item-card-profile-img me-2" /> */}
                  {/* <img src={applicant && applicant.PhotoPath.Data.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "") || user1Img} className="flyout-list-group-item-card-profile-img me-2" /> */}
                  Message with {applicant ? applicant.FirstName.Data : ""}  {applicant ? applicant.LastName.Data : ""}
                </div>
              </div>

              {
                chatVisibility &&
                <ChatRoom
                  applicant={applicant ? applicant : ""}
                  appsMessages={Messages ? Messages : []}
                  parentCallback={(sender, senderAvatar, message) => parentCallback(sender, senderAvatar, message, applicant)}
                  user={user ? user : ""}
                  updateReadmessages={() => updateReadmessages(applicant ? applicant : "")}
                />
              }

            </div>
          </div>
          <div className='flyout-backdrop flyout-backdrop-show' />
        </>
        :
        <></>
      }
    </>
  )
}

export default ChatFlyout;