import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../../api/client';
import { FilterType } from '../../../common/constants';
import { accountService } from '../../../features/accounts/services/accountService';
import { saveFilter, selectSaveFilterId, selectSaveFilterStatus, selectSaveFilterType, updateUserFilters } from '../../../features/reviewer/reducerSlices/reviewerSlice';
import CancelButton from '../custom/buttons/cancelButton';
import SaveButton from '../custom/buttons/saveButton';
import InputGroup from '../custom/inputGroup/inputGroup';
import ShortText from '../custom/textbox/shortText';
import { selectFilterId, selectFilterName, selectWhereClause } from '../filters/reducerSlices/jobFilterSlice';
import EllipsisLoader from '../loaders/ellipsisLoader';
import { isFormValid } from '../services/formService';
import { closeModal, selectModalPopUpDetail, selectSavingStatus } from './reducerSlices/saveJobFilterModalPopUpSlice';


function SaveJobFilterModalPopUp(props) {

    const dispatch = useDispatch();

    const SAVE_JOB_FILTER_FORM = "SaveJobFilterForm";

    const saveFilterStatus = useSelector(selectSaveFilterStatus);
    const saveFilterType = useSelector(selectSaveFilterType);
    const whereClause = useSelector(selectWhereClause);
    const filter_Id = useSelector(selectFilterId);
    const filter_Name = useSelector(selectFilterName);
    const modalPopUpDetails = useSelector(selectModalPopUpDetail);
    const saveFilterId = useSelector(selectSaveFilterId);
    const savingStatus = useSelector(selectSavingStatus);

    const [usfSubmitted, setUSFSubmitted] = useState(false);

    const [filterId, setFilterId] = useState(filter_Id);
    const [filterName, setFilterName] = useState(filter_Name);


    useEffect(() => {
        if (saveFilterStatus === fetchStatus.SUCCEEDED) {
            if (saveFilterType === FilterType.JOB) {
                dispatch(updateUserFilters({
                    id: saveFilterId,
                    name: filterName,
                    whereClause: whereClause,
                    filterType: saveFilterType
                }));
                dispatch(closeModal());
            }
        }

    }, [saveFilterStatus]);

    useEffect(() => {
        setFilterId(filter_Id);
    }, [savingStatus]);

    useEffect(() => {
        setFilterName(filter_Name);
    }, [savingStatus]);

    const onCloseClicked = () => {
        setUSFSubmitted(false);
        dispatch(closeModal());
    };

    const onSaveClicked = async (id) => {
        if (isFormValid(SAVE_JOB_FILTER_FORM)) {
            const userId = accountService.getLoggedInUserId();
            setUSFSubmitted(false);
            if (id) {
                await dispatch(saveFilter({
                    client: tenantId,
                    serviceType: "TENANTADMIN",
                    operation: "SAVEFILTER",
                    payload: {
                        id: userId,
                        data: JSON.stringify({
                            id: filterId,
                            filterName: filterName,
                            whereClause: whereClause,
                            filterType: modalPopUpDetails.filterType
                        })
                    }
                }));
            }
        }
        else {
            setUSFSubmitted(true);
        }
    };

    return (
        <>
            {(modalPopUpDetails && modalPopUpDetails.show) ? (<>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-block">
                                <div className="d-flex">
                                    <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                                    {saveFilterStatus === fetchStatus.LOADING ? <></> : <button
                                        type="button"
                                        className="modal-close-button ms-auto"
                                        onClick={onCloseClicked}>
                                        <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </button>}
                                </div>
                                <div className="d-flex">
                                    <span className="modal-sub-title">Save your customized filter.</span>
                                </div>
                            </div>
                            <div className="modal-body">

                                <div className="row">
                                    <div className="col-md-12">
                                        <label id="lblFilterName" className="field-label pt-2" htmlFor="txtFilterName">Filter Name</label>
                                        <InputGroup
                                            icon={<i className="fa fa-pencil-square-o"></i>}
                                            control={
                                                <ShortText
                                                    id="txtFilterName"
                                                    name="txtFilterName"
                                                    value={filterName}
                                                    ShortTextClasses="form-control"
                                                    onChange={(e) => setFilterName(e.target.value)}
                                                    required={true}
                                                    errorMessage="Please enter filter name"
                                                    form={SAVE_JOB_FILTER_FORM}
                                                    submited={usfSubmitted}
                                                    placeholder="Enter Filter Name" />
                                            } />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                {saveFilterStatus === fetchStatus.LOADING ? <EllipsisLoader /> : <><CancelButton
                                    CancelButtonClasses="mr-2"
                                    onCancelClicked={() => onCloseClicked()} />
                                    <SaveButton
                                        onSaveClicked={() => onSaveClicked(modalPopUpDetails.id)} /></>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </>) : <></>}
        </>
    );
}

export default SaveJobFilterModalPopUp;