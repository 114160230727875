import { createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { Operation } from '../../../common/constants';
import Collection from '../../../component/Controls/paginators/collection';
import { refreshCreated } from '../../../component/Controls/paginators/services/createdResource';
import { refreshRemoved } from '../../../component/Controls/paginators/services/removedResource';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    form: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    removal: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    },
    positionsOptions: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    show: false,
    createMode: false,
    editMode: false,
};


export const getAllPositions = createAsyncThunk('positions/getAllPositions', async (positionsModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', positionsModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let positions;
        if (isJSON(data)) {
            positions = JSON.parse(data);
        }
        return {
            page: positionsModel.page,
            positions: positions,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getPositionById = createAsyncThunk('positions/getPositionById', async (positionsModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, positionsModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let positions = undefined;
        if (data && isJSON(data)) {
            positions = JSON.parse(data);
        }
        return {
            page: positionsModel.page,
            positions: positions,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const createPosition = createAsyncThunk('positions/createPosition', async (positionsModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, positionsModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let position = undefined;
        if (data && isJSON(data)) {
            position = JSON.parse(data);
        }
        return {
            position: position,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const updatePosition = createAsyncThunk('positions/updatePosition', async (positionsModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, positionsModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let position = undefined;
        if (data && isJSON(data)) {
            position = JSON.parse(data);
        }
        return {
            position: position,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const removePosition = createAsyncThunk('positions/removePosition', async (positionsModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, positionsModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        return {
            id: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getPositionOptions = createAsyncThunk('positions/getPositionOptions', async (positionsModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', positionsModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let options;
    if (isJSON(data)) {
        options = JSON.parse(data);
    }
    return {
        options: options,
        success: response.data.success
    };
});


export const positionslice = createSlice({
    name: 'positions',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateRemovalStatus: (state) => {
            state.removal.status = fetchStatus.IDLE;
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateSingleData: (state, _data) => {
            state.single.refreshed = nanoid();
            state.single.data = _data.payload ? Object.assign({}, _data.payload.position) : _data.payload;
            if (_data.payload && _data.payload.position) {
                if (_data.payload.operation === Operation.UPDATED) {
                    let _item = state.data.records.Get(state.data.currentPage);
                    if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                        let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.position.id);
                        let newRecords = _item.records;
                        let _position = {
                            id: _data.payload.position.id,
                            PositionName: _data.payload.position.PositionName.Data,
                        };
                        newRecords[_updatedObjectIndex] = _position;
                        _item.records = newRecords;
                        let records = new Collection();
                        records.Add(_item.key, newRecords);
                        state.data.records.Remove(_item.key);
                        records.Concat(state.data.records);
                        state.data.records = records;
                    }
                }
            }
        },
        createNewPosition: state => {
            state.single.data = {
                "PositionName": {
                    "Id": "txtPositionName",
                    "Data": ""
                }
            };
        },
        updatePositionsOptionsStatus: (state) => {
            state.positionsOptions.status = fetchStatus.IDLE;
        },
        showPositionFlyout: (state, action) => {
            const { createMode, editMode } = action.payload;
            state.createMode = createMode;
            state.editMode = editMode;
            state.show = true;
        },
        closePositionFlyout: (state, action) => {
            state.createMode = false;
            state.editMode = false;
            state.show = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllPositions.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        })
            .addCase(getAllPositions.fulfilled, (state, action) => {
                state.data.totalItems = action.payload.positions.totalItems;
                state.data.items = action.payload.positions.items;
                state.data.isFiltered = false;
                state.data.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            })
            .addCase(getAllPositions.rejected, (state, action) => {
                state.data.status = fetchStatus.FAILED;
                state.data.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(getPositionById.pending, (state, action) => {
                state.single.status = fetchStatus.LOADING;
            }).addCase(getPositionById.fulfilled, (state, action) => {
                state.single.data = action.payload.positions;
                if (action.payload.page >= 0) {
                    let _records = new Collection();
                    _records.Add(action.payload.page, action.payload.positions);
                    _records.Concat(state.data.firstRecords);
                    state.data.firstRecords = _records;
                }
                state.single.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(getPositionById.rejected, (state, action) => {
                state.single.status = fetchStatus.FAILED;
                state.single.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(createPosition.pending, (state, action) => {
                state.creation.status = fetchStatus.LOADING;
            }).addCase(createPosition.fulfilled, (state, action) => {
                if (action.payload.position) {
                    state.single.data = action.payload.position;
                    console.log('action.payload.position ', action.payload.position)
                    let _position = {
                        id: action.payload.position.id,
                        PositionName: action.payload.position.PositionName.Data,
                    };
                    const created = refreshCreated(state.data.records, _position);
                    state.data.records = created.records;
                    state.data.currentPage = created.lastPage;
                    state.data.totalItems += 1;
                    state.single.refreshed = nanoid();
                }
                state.creation.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(createPosition.rejected, (state, action) => {
                state.creation.status = fetchStatus.FAILED;
                state.creation.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(updatePosition.pending, (state, action) => {
                state.modification.status = fetchStatus.LOADING;
            }).addCase(updatePosition.fulfilled, (state, action) => {
                state.single.data = action.payload.position;
                state.modification.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(updatePosition.rejected, (state, action) => {
                state.modification.status = fetchStatus.FAILED;
                state.modification.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(removePosition.pending, (state, action) => {
                state.removal.status = fetchStatus.LOADING;
            }).addCase(removePosition.fulfilled, (state, action) => {
                const removed = refreshRemoved(state.data.records, action.payload.id, state.data.currentPage);
                state.data.records = removed.records;
                state.data.currentPage = removed.lastPage;
                state.data.totalItems -= 1;
                state.single.refreshed = nanoid();
                state.removal.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(removePosition.rejected, (state, action) => {
                state.removal.status = fetchStatus.FAILED;
                state.removal.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(getPositionOptions.pending, (state, action) => {
                state.positionsOptions.status = fetchStatus.LOADING;
            }).addCase(getPositionOptions.fulfilled, (state, action) => {
                let _options = action.payload.options;
                _options = _options.map(function (option) {
                    option['value'] = option['id'];
                    delete option['id'];
                    option['text'] = option['PositionName'];
                    delete option['PositionName'];
                    return option;
                });
                state.positionsOptions.options = _options;
                state.positionsOptions.status = fetchStatus.SUCCEEDED;
                state.positionsOptions.refreshed = false;
                toaster.success(action.payload.success);
            }).addCase(getPositionOptions.rejected, (state, action) => {
                state.positionsOptions.status = fetchStatus.FAILED;
                state.positionsOptions.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            });

    }
});

export const { updateCreationStatus, updateModificationStatus, updateRemovalStatus,
    updateSingleStatus, updateIsFiltered, updateStatus, loadSingleData,
    updateCurrentPage, updateSingleData, createNewPosition,
    updatePositionsOptionsStatus,
    showPositionFlyout, closePositionFlyout } = positionslice.actions;

export default positionslice.reducer


export const selectAllPositions = state => state.positions.data.items;
export const selectTotalItems = state => state.positions.data.totalItems;
export const selectStatus = state => state.positions.data.status;

export const selectCreationStatus = state => state.positions.creation.status;

export const selectModificationStatus = state => state.positions.modification.status;

export const selectRemovalStatus = state => state.positions.removal.status;

export const selectPositionById = (state) => {
    return state.positions.single ? state.positions.single.data : undefined;
}

export const selectSingleStatus = state =>  state.positions.single.status;
// export const selectPositionsFormStatus = state => state.positions.form.status;
// export const selectPositionsFormError = state => state.positions.form.error;
// export const selectPositionsForm = state => state.positions.form.data;

export const selectPositionOptionsStatus = state => state.positions.positionsOptions.status;
export const selectPositionOptions = state => state.positions.positionsOptions.options;

export const selectPositionFlyoutShow = state => state.positions.show;
export const selectPositionFlyoutCreateMode = state => state.positions.createMode;
export const selectPositionFlyoutEditmode = state => state.positions.editMode;