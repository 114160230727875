import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addRegister, getLocationOptionsForRegistration, selectLocationOptionsForRegistration, selectLocationOptionsForRegistrationStatus, selectRegisterError, selectRegisterStatus, updateLoginStatus } from '../reducerSlices/accountsSlice';
import { fetchStatus, tenantId } from '../../../api/client';
import { accountService } from '../services/accountService';
import { isFormValid } from '../../../component/Controls/services/formService';
import { handlerError } from '../../../common/common';
// import ShortText from '../../../component/Controls/textboxes/shortText';
// import PhoneText from '../../../component/Controls/textboxes/phoneText';
// import EmailText from '../../../component/Controls/textboxes/emailText';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';
// import PasswordText from '../../../component/Controls/textboxes/passwordText';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup';
import ShortText from '../../../component/Controls/custom/textbox/shortText';
import PhoneText from '../../../component/Controls/custom/textbox/phoneText';
import EmailText from '../../../component/Controls/custom/textbox/emailText';
import PasswordText from '../../../component/Controls/custom/textbox/passwordText';
import { registerDetailsEN } from '../services/registerDetails';
import RegisterButton from '../../../component/Controls/custom/buttons/registerButton';
import DateText from '../../../component/Controls/custom/textbox/dateText';
import Selector from '../../../component/Controls/custom/selectors/selectors';
import registerImg from '../../../assets/images/login/laptop.png';
import { isObjectArray } from '../../../utilities/utilityFunctions';

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const REGISTER_FORM = "RegisterForm";

  const registerStatus = useSelector(selectRegisterStatus);
  const registerError = useSelector(selectRegisterError);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [location, setLocation] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [rfSubmitted, setRFSubmitted] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordLengthInvalid, setPasswordLengthInvalid] = useState(false);

  const [registerDetail, setRegisterDetail] = useState(registerDetailsEN);

  const locationOptionsForRegistration = useSelector(selectLocationOptionsForRegistration);
  const locationOptionsForRegistrationStatus = useSelector(selectLocationOptionsForRegistrationStatus);

  const onRegisterClicked = (e) => {
    e.preventDefault();

    setPasswordMismatch(false);
    setPasswordLengthInvalid(false);
    if (isFormValid(REGISTER_FORM)) {
      if (password.length >= 8) {
        if (password === confirmPassword) {
          setPasswordMismatch(false);
          accountService.register({
            client: tenantId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            password: password,
            locationId: location,
            dateOfBirth: dateOfBirth,
            language: 'EN'
          });
        }
        else {
          setPasswordMismatch(true);
        }
      }
      else {
        setPasswordLengthInvalid(true);
      }
    }
    else {
      setRFSubmitted(true);
    }
  }

  useEffect(() => {
    if (registerStatus === fetchStatus.SUCCEEDED) {
      if (accountService.isAuthenticated()) {
        dispatch(updateLoginStatus());
        history.push("/personal_info_1");
      }
    }
  }, [registerStatus, dispatch, history]);

  useEffect(() => {
    if (registerStatus === fetchStatus.FAILED) {
      handlerError(registerError);
    }
  }, [registerStatus]);

  let _locationOptionsForRegistration = undefined;

  if (isObjectArray(locationOptionsForRegistration)) {
    _locationOptionsForRegistration = locationOptionsForRegistration.map((o) => (
      <option value={o.value}>{o.text}</option>
    ));
  }
  useEffect(() => {
    if (locationOptionsForRegistrationStatus === fetchStatus.IDLE) {
      dispatch(getLocationOptionsForRegistration(
        { Client: tenantId }
      ));
    }
  }, [locationOptionsForRegistrationStatus]);



  return (
    <div>
      <div className="container justify-content-center col-md-9">
        <div className="animate__animated animate__fadeIn">
          <div className="card card-wizard overflow-hidden">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-md-4 pe-0 d-none d-md-flex">
                  <img src={registerImg} className="w-100 h-100" />
                </div>
                <div className="col-md-8 py-3">
                  <div className="row p-3">
                    <div className="col-md-6">
                      <h3>Register </h3>
                    </div>
                    <div className="col-md-6">
                      <p>
                        Have a already account?
                        <Link to='/login' className="text-danger"> Login</Link>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{registerDetail.FirstName}</label>
                        <InputGroup
                          icon={<i className="fa fa-address-card"></i>}
                          control={
                            <ShortText
                              id="txtFirstName"
                              name="txtFirstName"
                              ShortTextClasses="form-control"
                              placeholder={registerDetail.FirstName}
                              required={true}
                              errorMessage={registerDetail.FirstNameError}
                              form={REGISTER_FORM}
                              submited={rfSubmitted}
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)} />
                          } />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{registerDetail.LastName}</label>
                        <InputGroup
                          icon={<i className="fa fa-address-card"></i>}
                          control={
                            <ShortText
                              id="txtLastName"
                              name="txtLastName"
                              ShortTextClasses="form-control"
                              placeholder={registerDetail.LastName}
                              required={true}
                              errorMessage={registerDetail.LastNameError}
                              form={REGISTER_FORM}
                              submited={rfSubmitted}
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)} />
                          } />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{registerDetail.PhoneNumber}</label>
                        <InputGroup
                          icon={<i className="fa fa-phone"></i>}
                          control={
                            <PhoneText
                              id="txtPhoneNumber"
                              name="txtPhoneNumber"
                              PhoneTextClasses="form-control"
                              placeholder={registerDetail.PhoneNumber}
                              required={true}
                              errorMessage={registerDetail.PhoneNumberError}
                              form={REGISTER_FORM}
                              submited={rfSubmitted}
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)} />
                          } />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{registerDetail.Email}</label>
                        <InputGroup
                          icon={<i className="fa fa-envelope"></i>}
                          control={
                            <EmailText
                              id="txtEmail"
                              name="txtEmail"
                              EmailTextClasses="form-control"
                              placeholder={registerDetail.Email}
                              required={true}
                              errorMessage={registerDetail.EmailError}
                              form={REGISTER_FORM}
                              submited={rfSubmitted}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)} />
                          } />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{registerDetail.Password}</label>
                        <InputGroup
                          icon={<i className="fa fa-unlock-alt"></i>}
                          control={
                            <PasswordText
                              id="txtPassword"
                              name="txtPassword"
                              PasswordTextClasses="form-control"
                              placeholder={registerDetail.Password}
                              required={true}
                              errorMessage={registerDetail.PasswordError}
                              form={REGISTER_FORM}
                              submited={rfSubmitted}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)} />
                          } />
                        {passwordLengthInvalid && <span className="error-label">
                          <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                          {registerDetail.PasswordLengthError}
                        </span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{registerDetail.ConfirmPassword}</label>
                        <InputGroup
                          icon={<i className="fa fa-unlock-alt"></i>}
                          control={
                            <PasswordText
                              id="txtConfirmPassword"
                              name="txtConfirmPassword"
                              PasswordTextClasses="form-control"
                              placeholder={registerDetail.ConfirmPassword}
                              required={true}
                              errorMessage={registerDetail.ConfirmPasswordError}
                              form={REGISTER_FORM}
                              submited={rfSubmitted}
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)} />
                          } />
                        {passwordMismatch && <span className="error-label">
                          <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                          {registerDetail.PasswordMismatchError}
                        </span>}

                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{registerDetail.BirthDate}</label>
                        <InputGroup
                          icon={<i className="fa fa-calendar"></i>}
                          control={
                            <DateText
                              id="txtBirthDate"
                              name="txtBirthDate"
                              DateTextClasses="form-control"
                              required={true}
                              errorMessage={registerDetail.BirthDateError}
                              form={REGISTER_FORM}
                              submited={rfSubmitted}
                              value={dateOfBirth}
                              onChange={(e) => setDateOfBirth(e.target.value)}
                              max={(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate())).toISOString().split('T')[0]}
                              min={(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate())).toISOString().split('T')[0]} />
                          } />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{registerDetail.Location}</label>
                        <InputGroup
                          icon={<i className="fa fa-map-marker"></i>}
                          control={
                            <Selector
                              id="drpLocations"
                              name="drpLocations"
                              SelectorClasses="form-select"
                              default={registerDetail.Default}
                              options={
                                <>
                                  <>
                                    {_locationOptionsForRegistration}
                                  </>
                                  {/* <option value="138EB11A-4A9E-4C97-8C38-F369B869CB22">Venice (Mucker)</option>
                                  <option value="63A33046-0AAE-4778-9781-81D2056027C5">COMPTON</option>
                                  <option value="75699A06-519B-44F5-9321-1EF277908515">ONTARIO</option>
                                  <option value="838EB11A-4A9E-4C97-8C38-F369B869CB21">BUENA PARK</option>
                                  <option value="93FEBF82-ECF0-4F08-9453-579B05186536">VAN NUYS</option>
                                  <option value="9AD45711-0810-43CA-B3D0-3EF9DF446CC2">CITY OF INDUSTRY</option>
                                  <option value="B1F891D5-DF5A-4A6A-9D7B-5C755B36F02A">BELL</option>
                                  <option value="EF4F92BD-875E-471E-B513-FF86C84B49A8">BELLFLOWER</option> */}
                                  {/* <option value="FB9EFC9E-1613-4DAB-B548-49535230E1F1">RIVERSIDE</option>
                              <option value="FB9EFC9E-1613-4DAB-B548-49535230E1F2">ALHAMBRA</option>
                              <option value="FB9EFC9E-1613-4DAB-B548-49535230E1F3">SAN DIEGO</option>
                              <option value="073cc2d2-f7c5-4ebd-8f44-e48fe39e4753">SAN MARCOS</option>
                              <option value="FB9EFC9E-1613-4DAB-B548-49535230E1F4">LAS VEGAS</option>
                              <option value="FB9EFC9E-1613-4DAB-B548-49535230E1F6">SAN BERNARDINO</option> */}
                                </>
                              }
                              required={true}
                              errorMessage={registerDetail.LocationError}
                              form={REGISTER_FORM}
                              submited={rfSubmitted}
                              value={location}
                              onChange={(e) => setLocation(e.target.value)} />
                          } />

                      </div>
                    </div>
                  </div>
                  <div className="row p-3 pt-4">
                    <div className="col-md-12">
                      <RegisterButton
                        text={registerDetail.Register}
                        title={registerDetail.RegisterTooltip}
                        disabled={registerStatus === fetchStatus.LOADING}
                        loading={registerStatus === fetchStatus.LOADING}
                        onRegisterClicked={(e) => onRegisterClicked(e)} />
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Register; 