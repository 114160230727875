import React from "react";
import VerticalStackedBarChart from "../../../../component/Controls/chart/verticalStackedBarChart";
import ChartFilter from "./chartFilter";
import Moment from 'moment';

function HirebaseVerticalStackedBarChart(props) {
  return (
    <>
      <div className="dashboard-section">
        <span className="dashboard-title">{props.title}</span>
        <ChartFilter
          showLocation={props.showLocation}
          locationOptionsValue={props.locationOptionsValue}
          onLocationChange={props.onLocationChange}

          onWeekClicked={(e) => props.onWeekClicked() && props.onWeekClicked(e)}
          onMonthClicked={(e) => props.onMonthClicked() && props.onMonthClicked(e)}
          onTodayClicked={(e) => props.onTodayClicked() && props.onTodayClicked(e)}

          startDate={props.startDate}
          endDate={props.endDate}
          onFilter={props.onFilter}

          actBtn={props.actBtn}
          title={props.actBtn === 'today' ? 'Date : ' + Moment(props.startDate).format('MM-DD-YYYY') : 'From : ' + Moment(props.startDate).format('MM-DD-YYYY') + ' To ' + Moment(props.endDate).format('MM-DD-YYYY')}
        />
      </div>
      <VerticalStackedBarChart
        // title={props.actBtn === 'today' ? props.title + ' ' + Moment(props.startDate).format('MM-DD-YYYY') : props.title + ' ' + Moment(props.startDate).format('MM-DD-YYYY') + ' To ' + Moment(props.endDate).format('MM-DD-YYYY')}
        legendChart={props.legendChart}
        labelsChart={props.labelsChart}
        multipleBarChartData={props.multipleBarChartData}
        backgroundColor={props.backgroundColor}
      />
    </>
  );
}

export default HirebaseVerticalStackedBarChart; 