import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckBox from '../../Controls/custom/checkers/checkbox';


function CalledOnFilter(props) {

    const calledOnFilter = useSelector(props.calledOnFilterSelector);

    const [open, setOpen] = useState(false);

    const calledOnContainerRef = useRef(null);

    useEffect(() => {
        if (open && calledOnContainerRef && calledOnContainerRef.current) {
            calledOnContainerRef.current.focus();
        }
    });

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Called In</span> :
                <span className="filter-value">{calledOnFilter ? "Yes" : "No"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={calledOnContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                {/* <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Called In</label>
                            <CheckBox
                                checked={calledOnFilter}
                                onClick={(checked, e) => { console.log(checked ? "Y" : "N"); props.onCalledOnChanged && props.onCalledOnChanged(e, checked) }} />
                        </div>
                    </div>
                </div> */}
                <ul className="filter-list">
                    <li className="filter-list-item"
                        onClick={(e) => { props.onCalledOnChanged && props.onCalledOnChanged(e, !calledOnFilter) }}>
                        <CheckBox
                            id={`ckbCalledOnFilter`}
                            size="Small"
                            checked={calledOnFilter} />
                        <span className={`ms-3 ${calledOnFilter ? "filter-selected" : ""}`}>Yes</span>

                    </li>
                </ul>
            </div> : <></>}
        </div>
    );
}

export default CalledOnFilter;