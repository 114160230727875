import React from 'react'


function StatusLogFilterBar(props) {
    return (
        <div className="separator">
             <div className="line line-start"></div>
            <input type="text" className="separator-input" placeholder="Search"
                onChange={(e) => props.onSearch && props.onSearch(e)}
                value={props.searchTerm}
            />
            {props.children ? props.children : <></>}
            <div className="line"></div>
            {/* <div className="separator-input style-2 mx-2">Sep 22, 2023</div>
            <div className="line"></div> */}
        </div>
    )
}

export default StatusLogFilterBar