import { updateRouteTrackingStatusFilter, updateUserIdFilter } from "../../../component/Controls/filters/reducerSlices/routeTrackingFilterSlice";
import store from '../../../store';

export const onRouteTrackingStatusChanged = async (e, value, statuses) => {
    await store.dispatch(updateRouteTrackingStatusFilter({
        statuses: statuses,
        value: value
    }));
};

export const onRouteTrackingUserIdChanged = async (e, value, userIds) => {
    await store.dispatch(updateUserIdFilter({
        userIds: userIds,
        value: value
    }));
};