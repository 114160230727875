import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _getLocationText } from '../../common/common';
import ShortText from '../Controls/custom/textbox/shortText';
import { isFormValid } from '../Controls/services/formService';
import { closeSkillGroupFlyout, createSkillGroup, selectSkillGroupById, selectSkillGroupFlyoutCreateMode, selectSkillGroupFlyoutEditmode, selectSkillGroupFlyoutShow, selectSkillGroupForm, selectSkillGroupFormStatus, selectCreationStatus, selectModificationStatus, updateSkillGroup, updateSingleData, createSkillGroupOptionDetail, deleteSkillGroupOptionDetail, updateSkillGroupOptionsStatus } from '../../features/setting/reducerSlices/skillGroupSlice';
import { onValueChanged, sections } from '../../features/setting/services/skillGroupSaver';
import { Operation } from '../../common/constants';
import Selector from '../Controls/custom/selectors/selectors';
import { fetchStatus, tenantId } from '../../api/client';
import InputGroup from '../Controls/custom/inputGroup/inputGroup';
import ReactTooltip from 'react-tooltip';
import EllipsisLoader from '../Controls/loaders/ellipsisLoader';
import RemoveIconButton from '../Controls/buttons/removeIconButton';
import { userStatuses } from '../../features/shared/services/masterData';
import { isObjectArray } from '../../utilities/utilityFunctions';


function SkillGroupFlyout() {

    const dispatch = useDispatch();
    const skillGroupFlyoutShow = useSelector(selectSkillGroupFlyoutShow);

    const SKILLGROUP_FORM = "SkillGroupform";

    const [createMode, setCreatemode] = useState(false);
    const [editMode, setEditmode] = useState(false);
    const [isActive, setActive] = useState(false);
    const [cFInvalid, setCFInvalid] = useState(false);
    const [cfSubmitted, setCFSubmitted] = useState(false);
    const [statusOptions, setStatusOptions] = useState([]);

    const skillGroupFlyoutCreateMode = useSelector(selectSkillGroupFlyoutCreateMode);
    const skillGroupFlyoutEditMode = useSelector(selectSkillGroupFlyoutEditmode);
    const skillGroupFormStatus = useSelector(selectSkillGroupFormStatus);
    const skillGroupForm = useSelector(selectSkillGroupForm);
    const skillGroup = useSelector(selectSkillGroupById);
    const skillGroupCreationStatus = useSelector(selectCreationStatus);
    const skillGroupModificationStatus = useSelector(selectModificationStatus);

    const ToggleClass = () => {
        setActive(!isActive);
        onCancelClicked();

        const timer = setTimeout(() => {
            dispatch(closeSkillGroupFlyout())
            setActive(false)
        }, 1500);
    };

    const onCancelClicked = (e) => {
        setCreatemode(false);
        setEditmode(false);
        setCFInvalid(false);
        setCFSubmitted(false);
    };

    useEffect(() => {
        setCreatemode(skillGroupFlyoutCreateMode);
        setEditmode(skillGroupFlyoutEditMode)
    }, [skillGroupFlyoutCreateMode, skillGroupFlyoutEditMode]);

    useEffect(() => {
        if (skillGroupCreationStatus === fetchStatus.SUCCEEDED || skillGroupModificationStatus === fetchStatus.SUCCEEDED) {
            dispatch(updateSkillGroupOptionsStatus());
            ToggleClass()
        }
    }, [skillGroupCreationStatus, skillGroupModificationStatus]);

    const onValueUpdated = (e, section, value) => {
        let _skillGroupObj = Object.assign({}, skillGroup);
        let _newSkillGroupObj = onValueChanged(_skillGroupObj, e, section, value);
        dispatch(updateSingleData({
            skillGroup: _newSkillGroupObj,
            operation: Operation.UPDATED
        }));
    };

    const onSaveClicked = (e, id, created) => {
        e.preventDefault();
        if (isFormValid(SKILLGROUP_FORM)) {
            if (skillGroup) {
                if (created) {
                    dispatch(createSkillGroup({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVESETTINGSKILLGROUP",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                settingSkillGroup: JSON.stringify(skillGroup),
                            })
                        }
                    }));
                }
                else {
                    dispatch(updateSkillGroup({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVESETTINGSKILLGROUP",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                settingSkillGroup: JSON.stringify(skillGroup),
                            })
                        }
                    }));
                }
            }
            setCreatemode(false);
            setEditmode(false);
            setCFInvalid(false);
            setCFSubmitted(false);
        }
        else {
            setCFInvalid(true);
        }
        setCFSubmitted(true);
    };

    const onAddSkillGroupOptionClicked = (e) => {
        e.preventDefault();
        dispatch(createSkillGroupOptionDetail());
    };

    const onDeleteSkillGroupOptionClicked = (e, index) => {
        e.preventDefault();
        dispatch(deleteSkillGroupOptionDetail(index));
    }

    useEffect(() => {
        const handleBackButton = (event) => {
            if (skillGroupFlyoutShow) {
                dispatch(closeSkillGroupFlyout());
            }
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [skillGroupFlyoutShow, dispatch]);

    let _statusOptions;
    useEffect(() => {
        if (isObjectArray(userStatuses) && userStatuses.length > 0) {
            _statusOptions = userStatuses.map((status, index) => (
                <option key={index} value={status.value}>
                    {status.text}
                </option>
            ));
            setStatusOptions(_statusOptions);
        }

    }, [userStatuses]);

    return (
        <>
            {(skillGroupFlyoutShow) ? (<>
                <div className="flyout-wrapper position-fixed top-0 end-0">
                    <div className={`flyout-card ${isActive ? 'close' : 'open'}`}>
                        <div className="flyout-card-header">
                            <div className="float-end">
                                <div className="close-btn">
                                    <i className="fa fa-times" aria-hidden="true" data-tip='Close' onClick={ToggleClass}></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </div>
                            </div>
                            <div className="flyout-card-title">{skillGroup && skillGroup.id ? "Edit" : "Add"} Skill Details  </div>
                            <div className="flyout-card-sub-title">

                            </div>
                        </div>
                        <div className="flyout-card-body vh-100">


                            <div className="form-group p-0 pt-2">
                                <label id="lblFirstName" className="flyout-label" htmlFor="txtSkillGroupNumber">Skill Group Name</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{skillGroup && skillGroup.SkillGroupName ? skillGroup.SkillGroupName.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtSkillGroupName"
                                                name="txtSkillGroupName"
                                                ShortTextClasses="form-control"
                                                placeholder="Skill Group Name"
                                                value={skillGroup && skillGroup.SkillGroupName ? skillGroup.SkillGroupName.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.SKILLGROUP_DETAILS, null)}
                                                // required={skillGroupForm.SkillGroupName.Required}
                                                required={true}
                                                // errorMessage={skillGroupForm.SkillGroupName.Validation}
                                                form={SKILLGROUP_FORM}
                                                submited={cfSubmitted}
                                                // disabled={editMode}
                                                 />
                                        }
                                    />
                                }
                            </div>
                            
                            <div className="form-group p-0 pt-2">
                                <label id="lblStatuses" className="flyout-label" htmlFor="drpStatuses">Status</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{skillGroup ? skillGroup.Status.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                id="drpStatuses"
                                                name="drpStatuses"
                                                SelectorClasses="form-select"
                                                value={(skillGroup && skillGroup.Status) ? skillGroup.Status.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.SKILLGROUP_DETAILS, null)}
                                                options={
                                                    <>
                                                        {statusOptions}
                                                    </>
                                                }
                                                required={true}
                                                // errorMessage={}
                                                form={SKILLGROUP_FORM}
                                                submited={cfSubmitted} />}
                                    />
                                }
                            </div>


                        </div>
                        <div className="flyout-card-footer">
                            <div className="d-flex">
                                <div className="ms-auto">
                                    {skillGroupCreationStatus === fetchStatus.LOADING || skillGroupModificationStatus === fetchStatus.LOADING ? <EllipsisLoader /> :
                                        <>
                                            <button className="c-btn white-btn me-3" type="button" onClick={ToggleClass} >Cancel</button>
                                            <button className="c-btn dark-btn" type="button" onClick={(e) => onSaveClicked(e, skillGroup ? skillGroup.id : "", createMode)}>Save</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flyout-backdrop flyout-backdrop-show' />
            </>
            ) :
                ''
            }
        </>

    )
}

export default SkillGroupFlyout;