import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    selectedFilter: 0,
    id: "",
    name: "",
    defaultFilter: false,
    where: {
        location: {
            value: [],
            text: "All"
        },
        leadStatus: {
            value: [],
            text: "All"
        },
        assignedTo: {
            value: [],
            text: "All"
        },
        name: ""
    },
    refreshData: false
};

export const leadFilterSlice = createSlice({
    name: 'leadFilter',
    initialState,
    reducers: {
        updateLeadRefreshData: (state, action) => {
            state.refreshData = action.payload;
        },
        updateLeadFilter: (state, action) => {
            let { id, name, whereClause, defaultFilter } = action.payload;
            state.id = id;
            state.name = name;
            state.defaultFilter = defaultFilter;
            whereClause = whereClause ? whereClause : {
                location: {
                    value: [],
                    text: "All"
                },
                leadStatus: {
                    value: [],
                    text: "All"
                },
                name: ""
            };
            state.where = Object.assign({}, whereClause);
        },
        updateLocationFilter: (state, action) => {
            const { locations, value } = action.payload;
            let location = { ...state.where.location };
            let text = "All";
            if (!location.value.includes(value)) {
                location.value = [...location.value, value];
            }
            else {
                location.value = location.value.filter((val) => (val !== value));
            }

            if (value == 'All' && !location.value.includes('All')) {
                location.value = [];
            } else if (value == 'All') {
                location.value = locations.map((d) => d.value);
            } else {
                location.value = location.value.filter((val) => (val !== 'All'));
            }

            if (locations) {
                let _locations = locations.map((d) => d.value);
                _locations = _locations.filter((d) => (d !== 'All'))
                if (_locations.length === location.value.length) {
                    location.value.push('All')
                }
            }

            if (value == 'All') {
                text = "All";
            } else {
                if ((locations && Array.isArray(locations) && locations.length > 0) && location.value.length > 0) {
                    text = "";
                    for (let i = 0; i < locations.length; i++) {
                        let loc = locations[i];
                        if (location.value.includes(loc.value) && !location.value.includes('All')) {
                            text += `${loc.text},`
                        }
                    }
                    text = text.slice(0, -1);
                }
            }
            location.text = text;
            location.text = location.value.length > 0 && !location.value.includes('All') ? `(${location.value.length}) selected` : "All";
            state.where.location = location;
        },
        updateLeadStatusFilter: (state, action) => {
            const { leadStatuses, value } = action.payload;
            let leadStatus = { ...state.where.leadStatus };
            let text = "All";
            if (!leadStatus.value.includes(value)) {
                leadStatus.value = [...leadStatus.value, value];
            }
            else {
                leadStatus.value = leadStatus.value.filter((val) => (val !== value));
            }
            if ((leadStatuses && Array.isArray(leadStatuses) && leadStatuses.length > 0) && leadStatus.value.length > 0) {
                text = "";
                for (let i = 0; i < leadStatuses.length; i++) {
                    let loc = leadStatuses[i];
                    if (leadStatus.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            leadStatus.text = text;
            leadStatus.text = leadStatus.value.length > 0 ? `(${leadStatus.value.length}) selected` : "All";
            state.where.leadStatus = leadStatus;
        },
        updateNameFilter: (state, action) => {
            state.where.name = action.payload;
        },
        updateAssignedToFilter: (state, action) => {
            const { assignedTos, value } = action.payload;
            let assignedTo = { ...state.where.assignedTo };
            let text = "All";
            if (!assignedTo.value.includes(value)) {
                assignedTo.value = [...assignedTo.value, value];
            }
            else {
                assignedTo.value = assignedTo.value.filter((val) => (val !== value));
            }

            if (value == 'All' && !assignedTo.value.includes('All')) {
                assignedTo.value = [];
            } else if (value == 'All') {
                assignedTo.value = assignedTos.map((d) => d.id);
            } else {
                assignedTo.value = assignedTo.value.filter((val) => (val !== 'All'));
            }

            if (assignedTos) {
                let _assignedTos = assignedTos.map((d) => d.id);
                _assignedTos = _assignedTos.filter((d) => (d !== 'All'))
                if (_assignedTos.length === assignedTo.value.length) {
                    assignedTo.value.push('All')
                }
            }

            if (value == 'All') {
                text = "All";
            } else {
                if ((assignedTos && Array.isArray(assignedTos) && assignedTos.length > 0) && assignedTo.value.length > 0) {
                    text = "";
                    for (let i = 0; i < assignedTos.length; i++) {
                        let loc = assignedTos[i];
                        if (assignedTo.value.includes(loc.value) && !assignedTo.value.includes('All')) {
                            text += `${loc.text},`
                        }
                    }
                    text = text.slice(0, -1);
                }
            }
            assignedTo.text = text;
            assignedTo.text = assignedTo.value.length > 0 && !assignedTo.value.includes('All') ? `(${assignedTo.value.length}) selected` : "All";
            state.where.assignedTo = assignedTo;
        }
    }
});

export const { updateLeadRefreshData, updateLeadFilter, updateLocationFilter,
    updateLeadStatusFilter, updateNameFilter,
    updateAssignedToFilter } = leadFilterSlice.actions;

export default leadFilterSlice.reducer

export const selectLocationFilter = state => state.leadFilter.where.location;
export const selectLeadStatusFilter = state => state.leadFilter.where.leadStatus;
export const selectAssignedToFilter = state => state.leadFilter.where.assignedTo;

export const selectFilterId = state => state.leadFilter.id;

export const selectFilterName = state => state.leadFilter.name;

export const selectWhereClause = state => state.leadFilter.where;

export const selectDefaultFilter = state => state.leadFilter.defaultFilter;

export const selectRefreshData = state => state.leadFilter.refreshData;

export const selectWhereClauseChanged = state => {
    const whereClause = state.leadFilter.where;
    if (whereClause.location.value.length > 0) {
        return true;
    }
    if (whereClause.leadStatus.value.length > 0) {
        return true;
    }
    return false;
}