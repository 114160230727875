import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _getDateText, _getLocationText, _getLocationTextAll } from '../../../../common/common';
import DateText from '../../../../component/Controls/custom/textbox/dateText';
import Selector from '../../../../component/Controls/custom/selectors/selectors';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import "react-placeholder/lib/reactPlaceholder.css";
import { getLocationOptionsAll, getPayrollReport, selectPayrollRecords, selectLocationOptionsAll, selectLocationOptionsAllStatus, selectLocationOptionsAllRefreshed } from '../../../locations/reducerSlices/locationSlice';
import { fetchStatus, tenantId } from '../../../../api/client';
import Loader from "react-js-loader";
import XLSX from 'xlsx';
import Moment from 'moment';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';


const BranchReport = (props) => {

    const dispatch = useDispatch();

    const [locationOptionsValue, setLocationOptionsValue] = useState();
    const locationOptions = useSelector(selectLocationOptionsAll);
    const locationOptionsStatus = useSelector(selectLocationOptionsAllStatus);
    const locationOptionsRefreshed = useSelector(selectLocationOptionsAllRefreshed);
    const [start, setStart] = useState(Moment(Moment(new Date()).add(-7, 'd').format('YYYY/MM/DD HH:mm:ss')));
    const [end, setEnd] = useState(new Date());
    const [locationid, setLocation] = useState("");
    const PayrollData = useSelector(selectPayrollRecords);
    const [loading, setLoading] = useState(false);

    let _locationOptions = undefined;
    const fileHeaders = ["FULL NAME", "SOCIAL", "MARITAL STATUS", "DEPENDENTS", "DOB", "EMPLOYEE ADDRESS", "PAY RATE", "FLEX", "BRANCH", "PAYROLL CONTACT", "EMAIL", "CLIENT", "REGULER HOURS", "OT", "OT2", "SICK"];

    useEffect(() => {
        if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
            setLoading(true);
            dispatch(getLocationOptionsAll({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETLOCATIONOPTIONS",
                payload: {
                    id: "",
                    data: JSON.stringify({ All: "true" })
                }
            }));
        }
    }, [locationOptionsStatus, locationOptionsRefreshed]);

    useEffect(() => {
        if (isObjectArray(locationOptions)) {
            _locationOptions = locationOptions.map((o) => (
                <option value={o.value}>{o.text}</option>
            ));
            setLocationOptionsValue(_locationOptions);
            setLoading(false);
        }
    }, [locationOptions]);


    const OnClickReport = async () => {
        if (start != undefined && end != undefined && locationid != '') {
            setLoading(true);
            await dispatch(getPayrollReport({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETPAYROLLREPORT",
                payload: {
                    id: '',
                    data: JSON.stringify({
                        locationid: locationid,
                        StartDate: start,
                        EndDate: end
                    })
                }
            }));
            setLoading(false);
        }
        else {
            alert('Please select filter')
        }
    };

    const OnExportClick = async () => {
        var data = PayrollData.map(elt => [elt.Name, elt.SSN, elt.MaritalStatus, elt.deduction, elt.DOB, elt.Address, "$ " + elt.PayRates, "", elt.Location, "Nancy Cardenas", "nancy.c@sbaysafety.com", elt.Client, "", "", "", ""]);
        data.sort((a, b) => a.Client - b.Client);
        var uniques = PayrollData.map(item => item.Client)
            .filter((value, index, self) => self.indexOf(value) === index)
        const wb = XLSX.utils.book_new();
        uniques.sort();
        var cnt = 1;
        uniques.map(function (unique, i) {
            const cfil = PayrollData.filter((item) => item.Client.includes(unique))

            var data = cfil.map(elt => [elt.Name, elt.SSN, elt.MaritalStatus, elt.deduction, elt.DOB, elt.Address, "$ " + elt.PayRates, "", elt.Location, "Nancy Cardenas", "nancy.c@sbaysafety.com", elt.Client, "", "", "", ""]);
            data.sort((a, b) => a.Name - b.Name);
            var ws_data = [fileHeaders].concat(data);
            var clientheader = ["Client Name", unique];
            ws_data = [clientheader].concat(ws_data);

            var ws = XLSX.utils.aoa_to_sheet(ws_data);
            unique = unique.replace(/[^\w\s]/gi, '')
            XLSX.utils.book_append_sheet(wb, ws, truncate(cnt + ' ' + unique, 29));
            cnt = cnt + 1;
        })
        XLSX.writeFile(wb, "Branch Report.xlsx");
    }

    const truncate = (str, n) => {
        return (str.length > n) ? str.substr(0, n - 1) + '.' : str;
    };

    const payRatesFormatter = (field, data, column) => {
        return '$' + data["PayRates"];
    }


    return (
        <>
            {/* <ToolBar> */}
            <div className="d-flex">
                <div className="p-3">
                    <Selector
                        id="drpLocations"
                        name="drpLocations"
                        SelectorClasses="form-select"
                        options={
                            <>
                                {locationOptionsValue}
                            </>
                        }
                        onChange={(e) => setLocation(e.target.value)} />
                </div>
                <div className="p-3">
                    <DateText
                        id="txtStartDate"
                        name="txtStartDate"
                        DateTextClasses="form-control"
                        placeholder="Start Date"
                        value={Moment(start).format('YYYY-MM-DD')}
                        selected={start}
                        onChange={(e) => setStart(e.target.value)} />
                </div>
                <div className="p-3">
                    <DateText
                        id="txtEndDate"
                        name="txtEndDate"
                        DateTextClasses="form-control"
                        placeholder="End Date"
                        value={Moment(end).format('YYYY-MM-DD')}

                        selected={end}
                        onChange={(e) => setEnd(e.target.value)} />
                </div>

                {isObjectArray(PayrollData) ?
                    <div className="p-3">
                        <button className="form-control" onClick={() => OnExportClick()}> <i className="fa fa-download mr-3" aria-hidden="true"></i></button>
                    </div>
                    : <></>}

                <div className="p-3 ms-auto">
                    <button type="button" className="c-btn dark-btn position-relative" onClick={() => OnClickReport()}>
                        <i className="fa fa-filter" aria-hidden="true"></i> Filter
                        <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    </button>
                </div>
            </div>
            {/* </ToolBar> */}

            {loading ?
                <Loader type="spinner-circle" bgColor={"#92DCE2"} title={""} color={'#92DCE2'} size={100} />
                : <></>}
            {isObjectArray(PayrollData) ? <span className="px-3 report-heading">Payroll Report for  {_getLocationTextAll(locationOptions, locationid)}  </span> : <></>}

            <div className="row p-3">
                <GridComponent dataSource={PayrollData} allowPaging={false} allowSorting={true} allowResizing={true} toolbar={['Search']} loadingIndicator={{ indicatorType: 'Spinner' }} allowTextWrap={true} textWrapSettings={{ wrapMode: 'Header' } } >
                    <ColumnsDirective>
                        <ColumnDirective field='Name' headerText='Full Name' width='200' />
                        <ColumnDirective field='SSN' headerText='Social' width='130' />
                        <ColumnDirective field='MaritalStatus' headerText='Marital Status' width='125' />
                        <ColumnDirective field='deduction' headerText='Dependents' width='120' />
                        <ColumnDirective field='DOB' headerText='DOB' width='120' valueAccessor={(field, data, column) => _getDateText(data.DOB)} />
                        <ColumnDirective field='Address' headerText='Employee Address' width='400' />
                        <ColumnDirective field='PayRates' headerText='Pay Rate' width='100' valueAccessor={payRatesFormatter} />
                        <ColumnDirective field='' headerText='Flex' width='100' />
                        <ColumnDirective field='Location' headerText='Branch' />
                        <ColumnDirective field='' headerText='Payroll Contact' width='200' valueAccessor={(field, data, column) => "Nancy Cardenas"} />
                        <ColumnDirective field='' headerText='Email' valueAccessor={(field, data, column) => "nancy.c@sbaysafety.com"} />
                        <ColumnDirective field='Client' headerText='Client' />
                        <ColumnDirective field='' headerText='Regular Hours' width='125' />
                        <ColumnDirective field='' headerText='OT' width='100' />
                        <ColumnDirective field='' headerText='OT2' width='100' />
                        <ColumnDirective field='' headerText='Sick' width='100' />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                </GridComponent>
            </div>
        </>
    );
}

export default BranchReport;