import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../../api/client';
import { _getDateText } from '../../../common/common';
import { JobLogType } from '../../../common/constants';
import { accountService } from '../../../features/accounts/services/accountService';
import { updateSingleData } from '../../../features/applicant/reducerSlices/applicantSlice';
import { onStatusChanged } from '../../../features/applicant/services/statusSaver';
import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from '../../../features/locations/reducerSlices/locationSlice';
import { saveStatus, selectStatusChangeData, selectStatusChangeStatus } from '../../../features/reviewer/reducerSlices/reviewerSlice';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import CancelButton from '../custom/buttons/cancelButton';
import SaveButton from '../custom/buttons/saveButton';
import Radio from '../custom/checkers/radio';
import InputGroup from '../custom/inputGroup/inputGroup';
import EllipsisLoader from '../loaders/ellipsisLoader';
import DispatchJobSelector from '../custom/selectors/dispatchJobSelector';
import Selector from '../custom/selectors/selectors';
import { isFormValid } from '../services/formService';
import Statement from '../custom/textbox/statement';
// import Loader from "react-js-loader"; //Ask


// import DispatchImg from '../../../assets/images/dispatch.png'
// import './css/modalPopUp.css';
// import './css/statusUpdateModalPopUp.css';
import { closeModal, getClients, getJobsToBeDispatched, saveUpdatedApplicant, selectClients, selectClientsStatus, selectJobsToBeDispatched, selectJobsToBeDispatchedFiltered, selectJobsToBeDispatchedStatus, selectModalPopUpDetail } from './reducerSlices/statusUpdateModalPopUpSlice';
import { applicantStatusesData } from '../../../features/shared/services/masterData';
import { selectStatusOptions } from '../../../features/setting/reducerSlices/statusesSlice';
import { selectPositionOptions } from '../../../features/setting/reducerSlices/positionsSlice';

function StatusUpdateModalPopUp(props) {

    const dispatch = useDispatch();

    const STATUS_UPDATE_FORM = "StatusUpdateForm";

    const statusChangeData = useSelector(selectStatusChangeData);
    const statusChangeStatus = useSelector(selectStatusChangeStatus);
    const modalPopUpDetails = useSelector(selectModalPopUpDetail);
    const clientsStatus = useSelector(selectClientsStatus);
    const clients = useSelector(selectClients);

    const jobsToBeDispatchedStatus = useSelector(selectJobsToBeDispatchedStatus);
    const jobsToBeDispatched = useSelector(selectJobsToBeDispatched);
    const jobsToBeDispatchedFiltered = useSelector(selectJobsToBeDispatchedFiltered);
    const applicant = modalPopUpDetails.applicant;

    const refreshJobs = modalPopUpDetails.refreshJobs;

    const [sufSubmitted, setSUFSubmitted] = useState(false);
    const [statusNote, setStatusNote] = useState("");
    const [udReason, setUDReason] = useState("");
    const [payRates, setPayRates] = useState("");
    const [client, setClient] = useState("");
    const [clientId, setClientID] = useState("");
    const [jobKey, setJobKey] = useState("");
    const [dispatchDate, setDispatchDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [clientOptions, setClientOptions] = useState(undefined);
    const [cl, setCL] = useState("0");
    const [postDispatchStatus, setPostDispatchStatus] = useState("");

    const locationOptions = useSelector(selectLocationOptions);
    const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
    const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);

    // const [clientOptions, setClientOptions] = useState();
    const [clientOptionsValue, setClientOptionsValue] = useState();
    const [DNRChecked, setDNRChecked] = useState(false);

    const [loading, setLoading] = useState(false);
    const statusOptions = useSelector(selectStatusOptions);
    const positionOptions = useSelector(selectPositionOptions);


    const _getClientName = (id) => {
        let name = "";
        if (isObjectArray(clients)) {
            let _client = clients.find(c => c.id === id);
            if (_client && _client.ClientName) {
                name = _client.ClientName.replace('- DNR', '');
            }
        }
        return name;
    }

    // const _getLogValue = (status) => {
    //     if (status === "In Progress") {
    //         return 1;
    //     }
    //     else if (status === "Available") {
    //         return 2;
    //     }
    //     else if (status === "Active") {
    //         return 3;
    //     }
    //     else if (status === "InActive") {
    //         return 4;
    //     }
    //     else if (status === "DNU") {
    //         return 5;
    //     }
    //     else if (status === "Dispatched") {
    //         return 7;
    //     }
    //     else if (status === "UnDispatch") {
    //         return 8;
    //     }
    //     return 0;
    // }

    const findStatusByValue = (value) => {
        const statusWithMatchingValue = statusOptions.find(status => status.value === value);
        if (statusWithMatchingValue) {
            return statusOptions
                .filter(status => status.primaryStatus === statusWithMatchingValue.primaryStatus)
                .map(status => status.value);
        } else {
            return [];
        }
    }

    const _getLogValue = (status) => {
        // if (status === applicantStatusesData.InProgress) {
        if (status === applicantStatusesData.InProgress || findStatusByValue(applicantStatusesData.InProgress).includes(status)) {
            return 1;
        }
        else if (status === applicantStatusesData.Available || findStatusByValue(applicantStatusesData.Available).includes(status)) {
            return 2;
        }
        else if (status === applicantStatusesData.Active || findStatusByValue(applicantStatusesData.Active).includes(status)) {
            return 3;
        }
        else if (status === applicantStatusesData.InActive || findStatusByValue(applicantStatusesData.InActive).includes(status)) {
            return 4;
        }
        else if (status === applicantStatusesData.DNU || findStatusByValue(applicantStatusesData.DNU).includes(status)) {
            return 5;
        }
        else if (status === applicantStatusesData.Dispatched || findStatusByValue(applicantStatusesData.Dispatched).includes(status)) {
            return 7;
        }
        else if (status === "UnDispatch") {
            return 8;
        }
        return 0;
    }

    const _getActiveReasonText = (postdispatch) => {
        if (postdispatch === "1") {
            return "[Showed Up]";
        }
        else if (postdispatch === "2") {
            return "[Not Showed Up]";
        }
        else if (postdispatch === "3") {
            return "[2 Hour Show Up Time]";
        }

        return "";
    }

    useEffect(() => {
        // if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
        if ((locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) && accountService.isAuthenticated()) {
            dispatch(getLocationOptions({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETLOCATIONOPTIONS",
                payload: {
                    id: "",
                    data: JSON.stringify({})
                }
            }));
        }
    }, [locationOptionsStatus, locationOptionsRefreshed]);

    useEffect(() => {
        if (statusChangeStatus !== fetchStatus.LOADING) {
            setJobKey("");
            setStatusNote("");
            setUDReason("");
            setPayRates("");
            setClient("");
            setDispatchDate("");
            setStartTime("");
            setPostDispatchStatus("");
            setCL("0");
            setDNRChecked(false);
            dispatch(closeModal());
        }
        if (statusChangeStatus === fetchStatus.SUCCEEDED) {
            if (statusChangeData && statusChangeData.id) {
                dispatch(updateSingleData(statusChangeData));
            }
        }
    }, [statusChangeStatus]);

    useEffect(() => {
        if (clientsStatus === fetchStatus.IDLE) {
            _loadClients("");
            if (applicant) {
                if (applicant.dispatchDetails && applicant.dispatchDetails.clientListType === "1") {
                    _loadClients("");
                }
                else {
                    _loadClients(applicant.Location.Data);
                }

            }
        }
    }, [clientsStatus, modalPopUpDetails]);

    useEffect(() => {
        if (refreshJobs) {
            _loadJobsToBeDispatched(cl === "1");
        }
    }, [refreshJobs]);

    let _clientOptions;
    useEffect(() => {
        if (clientsStatus === fetchStatus.SUCCEEDED) {
            if (isObjectArray(clients)) {
                _clientOptions = clients.map((client, index) => (
                    client.ClientName.includes('- DNR') ?
                        <option key={nanoid()} value={client.id} className="optionRed">
                            {client.ClientName}
                        </option> : <option key={nanoid()} value={client.id}>
                            {client.ClientName}
                        </option>
                ));
                setClientOptions(_clientOptions);
            }
        }
    }, [clientsStatus]);

    useEffect(() => {
        if (modalPopUpDetails) {
            if (clientOptions) {
                if (applicant && applicant.PayRates) {
                    setPayRates(applicant.PayRates);
                }
                if (applicant && applicant.Client) {
                    setClient(applicant.Client);
                }
            }
        }

    }, [clientOptions]);

    useEffect(() => {
        if (applicant && applicant.dispatchDetails && applicant.dispatchDetails.length > 0 && applicant.dispatchDetails[applicant.dispatchDetails.length - 1].clientListType) {
            setCL(applicant.dispatchDetails[applicant.dispatchDetails.length - 1].clientListType);
            // if (modalPopUpDetails.status.value === "Dispatched" || modalPopUpDetails.status.value === "Active") {
            if (getPrimaryStatusById(modalPopUpDetails.status.value) === applicantStatusesData.Dispatched || getPrimaryStatusById(modalPopUpDetails.status.value) === applicantStatusesData.Active) {
                setPayRates(applicant.dispatchDetails[applicant.dispatchDetails.length - 1].payRates);
                setClient(applicant.dispatchDetails[applicant.dispatchDetails.length - 1].client);
            }
        }
    }, [applicant]);

    useEffect(() => {
        if (jobKey && isObjectArray(jobsToBeDispatched)) {
            let _job = jobsToBeDispatched.find(j => j.jobKey === jobKey);
            if (_job) {
                setClient(_job.clientId);
                setPayRates(_job.payRates);
                setDispatchDate(_job.startDate);
                setStartTime(_job.startTime);
            }
        }
    }, [jobKey]);

    let _clientOptionsValue;

    useEffect(() => {
        let _clientOptions;
        if (isObjectArray(clients)) {
            _clientOptions = clients.map((client, index) => (
                {
                    text: client.ClientName,
                    value: client.id,
                }
            ));
            _clientOptionsValue = clients.map((client, index) => (
                client.ClientName.includes('DNR') ?
                    <option value={client.id} className="optionRed">{client.ClientName}</option>
                    : <option value={client.id}>{client.ClientName}</option>

            ));
            setClientOptions(_clientOptions);
            setClientOptionsValue(_clientOptionsValue);
        }
    }, [clients]);

    const onCloseClicked = () => {
        setJobKey("");
        setStatusNote("");
        setUDReason("");
        setPayRates("");
        setClient("");
        setDispatchDate("");
        setStartTime("");
        setPostDispatchStatus("");
        dispatch(closeModal());
        setSUFSubmitted(false);
        setCL("0");
    };

    const onSaveClicked = async (id) => {
        const user = accountService.getAuthenticatedUser();
        if (isFormValid(STATUS_UPDATE_FORM)) {
            const statusDetails = {
                status: modalPopUpDetails.status.text,
                statusNote: statusNote,
                unDispatchReason: udReason,
                payRates: payRates,
                client: client,
                jobKey: jobKey,
                log: {
                    TimeStamp: (new Date()).toUTCString(),
                    LogType: modalPopUpDetails.status.text,
                    LogValue: _getLogValue(modalPopUpDetails.status.value),
                    // Content: modalPopUpDetails.status.text === "Dispatched" ? `${statusNote}.` : statusNote + " " + _getActiveReasonText(postDispatchStatus),
                    Content: modalPopUpDetails.status.text === applicantStatusesData.Dispatched ? `${statusNote}.` : statusNote + " " + _getActiveReasonText(postDispatchStatus),
                    PayRates: payRates,
                    Client: _getClientName(client),
                    ClientId: client,
                    UserName: (user ? user.firstName + " " + user.lastName : ""),
                    UserId: (user ? user.id : ""),
                    DispatchDate: dispatchDate,
                    ClientListType: cl,
                    PostDispatchStatus: postDispatchStatus,
                    StatusNote: statusNote
                },
                dispatched: {
                    jobKey: jobKey,
                    dispatchDate: dispatchDate,
                    clientListType: cl,
                    statusNote: statusNote,
                    payRates: payRates,
                    client: client,
                    isActive: false,
                    UserName: (user ? user.firstName + " " + user.lastName : ""),
                    UserId: (user ? user.id : ""),
                },
                jobLog: {
                    TimeStamp: (new Date()).toUTCString(),
                    // LogType: modalPopUpDetails.status.text === "Dispatched" ? JobLogType.DISPATCH.type : JobLogType.UNDISPATCH.type,
                    // LogValue: modalPopUpDetails.status.text === "Dispatched" ? JobLogType.DISPATCH.value : JobLogType.UNDISPATCH.value,
                    // Content: `${applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : ""} ${modalPopUpDetails.status.text === "Dispatched" ? "dispatched" : "undispatched"} successfully by ${user ? user.firstName + " " + user.lastName : ""}. ${modalPopUpDetails.status.text === "Dispatched" ? "" : " With Notes: " + udReason + " "}`,
                    LogType: modalPopUpDetails.status.text === applicantStatusesData.Dispatched ? JobLogType.DISPATCH.type : JobLogType.UNDISPATCH.type,
                    LogValue: modalPopUpDetails.status.text === applicantStatusesData.Dispatched ? JobLogType.DISPATCH.value : JobLogType.UNDISPATCH.value,
                    Content: `${applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : ""} ${modalPopUpDetails.status.text === applicantStatusesData.Dispatched ? "dispatched" : "undispatched"} successfully by ${user ? user.firstName + " " + user.lastName : ""}. ${modalPopUpDetails.status.text === applicantStatusesData.Dispatched ? "" : " With Notes: " + udReason + " "}`,
                    Client: _getClientName(client),
                    ClientId: client,
                    UserName: (user ? user.firstName + " " + user.lastName : ""),
                    UserId: (user ? user.id : ""),
                    UnDispatchReason: udReason,
                    StatusNote: statusNote
                },
                DnrStatus: {
                    DNRStatus: DNRChecked,
                    ClientId: applicant.Client,
                    TimeStamp: (new Date()).toUTCString()
                }
            };
            let _applicantObj = Object.assign({}, applicant);
            let _newApplicantObj = onStatusChanged(_applicantObj, statusDetails);
            dispatch(saveUpdatedApplicant(_newApplicantObj));
            setSUFSubmitted(false);
            if (id) {
                await dispatch(saveStatus({
                    client: tenantId,
                    serviceType: "TENANTADMIN",
                    operation: "SAVEAPPLICANTSTATUS",
                    payload: {
                        id: id,
                        data: JSON.stringify(statusDetails)
                    }
                }));

            }
        }
        else {
            setSUFSubmitted(true);
        }
    };

    const _loadClients = (locationId) => {
        if (modalPopUpDetails && accountService.isAuthenticated()) {

            // if (modalPopUpDetails.status.value === "Active") {
            if (getPrimaryStatusById(modalPopUpDetails.status.value) === applicantStatusesData.Active) {
                locationId = ""
            }
            dispatch(getClients({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETDNRCLIENTS",
                payload: {
                    id: modalPopUpDetails.id,
                    data: JSON.stringify({
                        where: {
                            clientStatus: "Active",
                            location: locationId
                        }
                    })
                }
            }));
        }
    };

    const _loadJobsToBeDispatched = (all) => {
        // if (modalPopUpDetails && (modalPopUpDetails.status.value === "Dispatched")) {
        if (modalPopUpDetails && (getPrimaryStatusById(modalPopUpDetails.status.value) === applicantStatusesData.Dispatched)) {
            const userId = accountService.getLoggedInUserId();
            dispatch(getJobsToBeDispatched({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETJOBSTOBEDISPATCHED",
                payload: {
                    id: modalPopUpDetails.id,
                    data: JSON.stringify({

                        all: all

                    })
                }
            }));
        }
    };

    const _loadNewJobsToBeDispatched = (client) => async () => {
        // if (modalPopUpDetails && (modalPopUpDetails.status.value === "Dispatched")) {
        if (modalPopUpDetails && (getPrimaryStatusById(modalPopUpDetails.status.value) === applicantStatusesData.Dispatched)) {
            const userId = accountService.getLoggedInUserId();
            await dispatch(getJobsToBeDispatched({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETCLIENTJOBSTOBEDISPATCHED",
                payload: {
                    id: modalPopUpDetails.id,
                    data: JSON.stringify({

                        client: client

                    })
                }
            }));
            //setLoading(false);
        }
    };

    const onCLChanged = (e, v) => {
        setCL(v);
        _loadClients((v === "1" ? "" : applicant.Location.Data));
        _loadJobsToBeDispatched(v === "1");
    }

    const _getClientText = (clients, id) => {
        if (isObjectArray(clients)) {
            let client = clients.find(l => l.value === id);
            if (client && client.text) {
                return client.text;
            }
        }
        return "N/A";
    }

    const onClientChanged = (e, s, v) => {
        setClientID(e.target.value);
        modalPopUpDetails.refreshJobs = true;
        //const abc  = jobsToBeDispatchedFiltered.filter((jd) => jd.clientId.includes(ClientId));
        // setLoading(true);
        //setClientID(abc);
        //await _loadNewJobsToBeDispatched(e.target.value);
        //setLoading(false);
        //onValueUpdated(e, s, _titleData);
        //  _loadClient(e.target.value);
    }

    const getPrimaryStatusById = (id) => {
        console.log('getPrimaryStatusById............', statusOptions)
        if (statusOptions) {
            const status = statusOptions.find(option => option.value === id);
            return status ? status.primaryStatus : "";
        }
        return ""
    }

    useEffect(() => {
        const handleBackButton = (event) => {
          if (modalPopUpDetails.show) {
            onCloseClicked();
          }
        };

        window.addEventListener('popstate', handleBackButton);
    
        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
      }, [modalPopUpDetails.show]);

    return (
        <>
            {(modalPopUpDetails && modalPopUpDetails.show) ? (<>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">

                    {/* <div className={(modalPopUpDetails.status.value === "Dispatched") ? "modal-dialog modal-dialog-centered DipatchDialogWidth" : "modal-dialog modal-dialog-centered"} role="document"> */}
                    <div className={(getPrimaryStatusById(modalPopUpDetails.status.value) === applicantStatusesData.Dispatched) ? "modal-dialog modal-dialog-centered DipatchDialogWidth" : "modal-dialog modal-dialog-centered"} role="document">
                        <div className="modal-content">
                            <div className="modal-header d-block">
                                <div className="d-flex">
                                    <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                                    {statusChangeStatus === fetchStatus.LOADING ? <></> : <button
                                        type="button"
                                        className="modal-close-button ms-auto"
                                        onClick={onCloseClicked}>
                                        <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </button>}
                                </div>
                                <div className="d-flex">
                                    <span className="modal-sub-title">Applicant status change</span>
                                </div>
                            </div>
                            <div className="modal-body">
                                {/* {(modalPopUpDetails.status.value === "Dispatched") && <> */}
                                {(getPrimaryStatusById(modalPopUpDetails.status.value) === applicantStatusesData.Dispatched) && <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className='mb-3"'>
                                                <Radio id="rdbtnMC"
                                                    name="rdbtnCL"
                                                    label="My Job Orders"
                                                    value="0"
                                                    onChange={(e, v) => onCLChanged(e, v)}
                                                    checked={cl === "0"} />
                                                <Radio id="rdbtnACL"
                                                    name="rdbtnCL"
                                                    label="All Job Orders"
                                                    value="1"
                                                    onChange={(e, v) => onCLChanged(e, v)}
                                                    checked={cl === "1"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label id="lblClients" className="field-label pt-2" htmlFor="drpClientsselect">Client</label>

                                            <InputGroup
                                                icon={<i className="fa fa-address-card"></i>}
                                                control={
                                                    <Selector


                                                        id="drpClientsselect"
                                                        name="drpClientsselect"
                                                        SelectorClasses="form-select"


                                                        options={
                                                            <>
                                                                {clientOptionsValue}
                                                            </>
                                                        } onChange={(e) => onClientChanged(e, "", null)} />
                                                } />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* {loading ?  <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={30}  />: <></>} //ask  */}
                                            {!loading ? <label id="lblJobs" className="field-label pt-2" htmlFor="drpJobs">Jobs</label> : <></>}

                                            {!loading ? <InputGroup
                                                icon={<i className="fa fa-user"></i>}
                                                control={
                                                    <DispatchJobSelector
                                                        id="drpJobs"
                                                        name="drpJobs"
                                                        SelectorClasses="form-control"
                                                        value={jobKey}
                                                        options={

                                                            jobsToBeDispatchedFiltered.filter((jd) => jd.clientId.includes(clientId))
                                                        }
                                                        onChange={(e, v) => setJobKey(v)}
                                                        required={true}
                                                        errorMessage="Please select job"
                                                        form={STATUS_UPDATE_FORM}
                                                        submited={sufSubmitted}
                                                        clients={clients}
                                                        applicant={modalPopUpDetails.applicant}
                                                        locations={locationOptions}
                                                        positionOptions={positionOptions} />
                                                } /> : <></>}

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label id="lblClients" className="field-label pt-2" htmlFor="drpClients">Client</label>
                                            <div className="client-name-container">
                                                <div className="client-label"><i className="fa fa-user"></i></div>
                                                <div className="client-name"><span>{_getClientName(client)}</span></div>
                                            </div>
                                        </div>
                                    </div><div className="row">
                                        <div className="col-md-12">
                                            <label id="lblDispatchDate" className="field-label pt-2" htmlFor="txtDispatchDate">Start Date</label>

                                            <div className="client-name-container">
                                                <div className="client-label"><i className="fa fa-calendar"></i></div>
                                                <div className="client-name"><span>{_getDateText(dispatchDate)}  {startTime}</span></div>
                                            </div>
                                        </div>
                                    </div></>}
                                {/* {(modalPopUpDetails.status.value === "Active") && <div className="row"> */}
                                {(getPrimaryStatusById(modalPopUpDetails.status.value) === applicantStatusesData.Active) && <div className="row">
                                    <div className="col-md-12">
                                        <label id="lblClient" className="field-label pt-2" htmlFor="drpClient">Client</label>
                                        <div className="client-name-container">
                                            <div className="client-label"><i className="fa fa-calendar"></i></div>
                                            <div className="client-name"><span>{_getClientName(client)}</span></div>
                                        </div>

                                    </div>
                                </div>}
                                {/* {applicant && applicant.Status === "Dispatched" && modalPopUpDetails.status.value === "Active" ? <div className="row"> */}
                                {applicant && applicant.Status === applicantStatusesData.Dispatched && getPrimaryStatusById(modalPopUpDetails.status.value) === applicantStatusesData.Active ? <div className="row">
                                    <div className="col-md-12">
                                        <label id="lblPostDispatchStatus" className="field-label pt-2" htmlFor="drpPostDispatchStatus">Post Dispatch Status</label>
                                        <InputGroup
                                            icon={<i className="fa fa-info"></i>}
                                            control={
                                                <Selector
                                                    id="drpPostDispatchStatus"
                                                    name="drpPostDispatchStatus"
                                                    SelectorClasses="form-select"
                                                    value={postDispatchStatus}
                                                    options={
                                                        <>
                                                            <option value="1">Showed Up</option>
                                                            <option value="2">Not Showed Up</option>
                                                            <option value="3">2 Hour Show Up Time</option>
                                                        </>
                                                    }
                                                    onChange={(e) => setPostDispatchStatus(e.target.value)}
                                                    required={true}
                                                    errorMessage="Please select post dispatch status"
                                                    form={STATUS_UPDATE_FORM}
                                                    submited={sufSubmitted} />
                                            } />

                                    </div>
                                </div> : <></>}
                                {/* {(modalPopUpDetails.status.value === "Active" || modalPopUpDetails.status.value === "Dispatched") && <><div className="row"> */}
                                {(getPrimaryStatusById(modalPopUpDetails.status.value) === applicantStatusesData.Active || getPrimaryStatusById(modalPopUpDetails.status.value) === applicantStatusesData.Dispatched) && <><div className="row">
                                    <div className="col-md-12">
                                        <label id="lblPayrates" className="field-label pt-2" htmlFor="txtPayrates">Pay Rates</label>
                                        {/* <InputGroup
                                            icon={<i className="fa fa-dollar"></i>}
                                            control={
                                                <NumberText
                                                    id="txtPayrates"
                                                    min="0.01"
                                                    step="0.01"
                                                    max="2500"
                                                    name="txtPayrates"
                                                    NumberTextClasses="form-control"
                                                    value={payRates}
                                                    placeholder="Pay Rate"
                                                    onChange={(e) => setPayRates(e.target.value)}
                                                    required={true}
                                                    errorMessage="Please enter pay rates"
                                                    form={STATUS_UPDATE_FORM}
                                                    submited={sufSubmitted}
                                                    placeholder="Pay Rates"
                                                    disabled={true} />
                                            } /> */}
                                        <div className="client-name-container">
                                            <div className="client-label"><i className="fa fa-dollar"></i></div>
                                            <div className="client-name"><span>{payRates}</span></div>
                                        </div>
                                    </div>
                                </div>
                                </>}
                                {/* {applicant && applicant.Status === "Dispatched" && modalPopUpDetails.status.value !== "Active" ? */}
                                {applicant && applicant.Status === applicantStatusesData.Dispatched && getPrimaryStatusById(modalPopUpDetails.status.value) !== applicantStatusesData.Active ?
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label id="lblUnDispatchReason" className="field-label pt-2" htmlFor="txtUnDispatchReason">Undispatch Reason</label>
                                            <InputGroup
                                                icon={<i className="fa fa-pencil-square-o"></i>}
                                                control={
                                                    <Statement
                                                        id="txtUnDispatchReason"
                                                        name="txtUnDispatchReason"
                                                        value={udReason}
                                                        StatementClasses="form-control"
                                                        onChange={(e) => setUDReason(e.target.value)}
                                                        required={true}
                                                        errorMessage="Please enter undispatch reason"
                                                        form={STATUS_UPDATE_FORM}
                                                        submited={sufSubmitted}
                                                        placeholder="Enter undispatch reason..." />
                                                } />

                                        </div>
                                    </div> : <></>}
                                <div className="row">
                                    <div className="col-md-12">
                                        <label id="lblStatusNote" className="field-label pt-2" htmlFor="txtStatusNote">Note</label>
                                        <InputGroup
                                            icon={<i className="fa fa-pencil-square-o"></i>}
                                            control={
                                                <Statement
                                                    id="txtStatusNote"
                                                    name="txtStatusNote"
                                                    value={statusNote}
                                                    StatementClasses="form-control"
                                                    onChange={(e) => setStatusNote(e.target.value)}
                                                    required={true}
                                                    errorMessage="Please enter status note"
                                                    form={STATUS_UPDATE_FORM}
                                                    submited={sufSubmitted}
                                                    placeholder="Enter status note..." />
                                            } />

                                    </div>
                                </div>

                                {/* {applicant && applicant.Status === "Dispatched" || applicant.Status === "Active" ? */}
                                {applicant && applicant.Status === applicantStatusesData.Dispatched || applicant.Status === applicantStatusesData.Active ?
                                    <div className="row p-3">
                                        <div className='col-md-12'>
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" defaultChecked={DNRChecked} onChange={() => setDNRChecked(!DNRChecked)} />
                                                {/* <label className="form-check-label" for="exampleCheck1">Check me out</label> */}
                                                <label id="lbldnr" className="field-label" htmlFor="txtUnDispatchReason">Do not return to client {_getClientName(applicant.Client)} (DNR)</label>
                                            </div>
                                        </div>
                                    </div>
                                    // <div className="row p-3">
                                    //     <div className="col-md-1">
                                    //         <input type="checkbox" defaultChecked={DNRChecked} onChange={() => setDNRChecked(!DNRChecked)} className="form-control" />
                                    //     </div>
                                    //     <div className="col-md-11">
                                    //         <label id="lbldnr" className="c-label" htmlFor="txtUnDispatchReason">Do not return to client {_getClientName(applicant.Client)} (DNR)</label>
                                    //     </div>
                                    // </div> 
                                    : <></>}

                            </div>

                            <div className="modal-footer">
                                {(statusChangeStatus === fetchStatus.LOADING || clientsStatus === fetchStatus.LOADING) ? <EllipsisLoader /> : <><CancelButton
                                    CancelButtonClasses="mr-2"
                                    onCancelClicked={() => onCloseClicked()} />
                                    <SaveButton
                                        onSaveClicked={() => onSaveClicked(modalPopUpDetails.id)} />
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </>) : <></>}
        </>
    );
}

export default StatusUpdateModalPopUp;