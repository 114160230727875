import { createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { Operation } from '../../../common/constants';
import Collection from '../../../component/Controls/paginators/collection';
import { refreshCreated } from '../../../component/Controls/paginators/services/createdResource';
import { refreshRemoved } from '../../../component/Controls/paginators/services/removedResource';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    form: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    show: false,
    createMode: false,
    editMode: false,
};


export const getAllSubscribers = createAsyncThunk('subscribers/getAllSubscribers', async (subscribersModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', subscribersModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let subscribers;
        if (isJSON(data)) {
            subscribers = JSON.parse(data);
        }
        return {
            page: subscribersModel.page,
            subscribers: subscribers,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const subscriberslice = createSlice({
    name: 'subscribers',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        showSubscribersFlyout: (state, action) => {
            const { createMode, editMode } = action.payload;
            state.createMode = createMode;
            state.editMode = editMode;
            state.show = true;
        },
        closeSubscribersFlyout: (state, action) => {
            state.createMode = false;
            state.editMode = false;
            state.show = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllSubscribers.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        })
        .addCase(getAllSubscribers.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.subscribers.totalItems;
            state.data.items = action.payload.subscribers.items;
            state.data.isFiltered = false;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        })
        .addCase(getAllSubscribers.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.data.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });

    }
});

export const {  updateStatus, showSubscribersFlyout, closeSubscribersFlyout } = subscriberslice.actions;

export default subscriberslice.reducer


export const selectAllSubscribers = state => state.subscribers.data.items;
export const selectTotalItems = state => state.subscribers.data.totalItems;
export const selectStatus = state => state.subscribers.data.status;



