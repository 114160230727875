import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import { createNewRouteTracking, createRouteTracking, deleteLeadsDetails, getAllRouteTracking, getLeadsOptions, getRouteTrackingById, getRouteTrackingByUserAndDate, loadSingleData, selectAllRouteTracking, selectCreationStatus, selectCurrentPage, selectFirstRecord, selectLeadsOptions, selectLeadsOptionsState, selectModificationStatus, selectRouteTrackingById, selectRouteTrackingByIdUserAndDate, selectSingleRefreshed, selectSingleStatus, selectStatus, selectTotalItems, updateCurrentPage, updateIsFiltered, updateRecentClickedRouteTracking, updateRouteTracking, updateRrouteTrackingByUserAndDate, updateSingleData, updateStatus } from '../reducerSlices/routeTrackingSlice';
import Collection from '../../../component/Controls/paginators/collection';
import { selectDefaultFilter, selectRefreshData, selectRouteTrackingStatusFilter, selectUserIdFilter, selectWhereClause, selectWhereClauseChanged, updateNameFilter, updateRouteTrackingFilter, updateRouteTrackingRefreshData } from '../../../component/Controls/filters/reducerSlices/routeTrackingFilterSlice';
import { selectSort } from '../../../component/Controls/dataSorters/reducerSlices/routeTrackingSorterSlice';
import { fetchStatus, tenantId } from '../../../api/client';
import { accountService } from '../../accounts/services/accountService';
import RouteTrackingCard from '../../../component/Card/RouteTrackingCard';
import { getSalesRepAndSalesMangerOptions, selectSalesRepAndSalesMangerOptions, selectSalesRepAndSalesMangerOptionsState } from '../reducerSlices/leadSlice';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import { toLocalDateOnly } from '../../../common/common';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import PageContainer from '../../../component/Controls/paginators/paginator/pageContainer';
import RouteTrackingSorter from '../../../component/Controls/dataSorters/routeTrackingSorter';
import RouteTrackingStatusFilter from '../../../component/Controls/filters/routeTrackingStatusFilter';
import { onRouteTrackingStatusChanged, onRouteTrackingUserIdChanged } from '../services/routeTrackingFilterHandler';
import RouteTrackingUserFilter from '../../../component/Controls/filters/routeTrackingUserFilter';


function RouteTracking() {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = accountService.getAuthenticatedUser();


  const [pageLoaded, setPageLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [routeTrackingRecords, setRouteTrackingRecords] = useState(new Collection());

  const totalPageItems = useSelector(selectTotalItems);
  const routeTrackings = useSelector(selectAllRouteTracking);
  const routeTrackingsRequestStatus = useSelector(selectStatus);
  const currentPage = useSelector(selectCurrentPage);

  const [salesRepAndSalesMangerOptionsData, setSalesRepAndSalesMangerOptionsData] = useState();
  const salesRepAndSalesMangerOptionsState = useSelector(selectSalesRepAndSalesMangerOptionsState);
  const salesRepAndSalesMangerOptions = useSelector(selectSalesRepAndSalesMangerOptions);

  const routeTrackingRequestStatus = useSelector(selectSingleStatus);
  const singleRefreshedStatus = useSelector(selectSingleRefreshed);

  const [dummyCards, setDummyCards] = useState([]);

  const whereClause = useSelector(selectWhereClause);
  const whereClauseChanged = useSelector(selectWhereClauseChanged);
  const defaultFilter = useSelector(selectDefaultFilter);
  const sort = useSelector(selectSort);

  const isAdmin = accountService.isAdmin();
  const isSalesManager = accountService.isSalesManager();

  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 17,
        title: "Route List",
        product: "CRM"
      }));
      setPageLoaded(true);
    }
  });

  useEffect(() => {
    if (salesRepAndSalesMangerOptionsState === fetchStatus.SUCCEEDED) {
      if (salesRepAndSalesMangerOptions) {
        if (isObjectArray(salesRepAndSalesMangerOptions)) {
          if (isAdmin || isSalesManager) {
            let _salesRepAndSalesMangerOptions;
            _salesRepAndSalesMangerOptions = salesRepAndSalesMangerOptions.map((d, index) => (
              {
                text: d.FirstName + ' ' + d.LastName,
                value: d.id,
              }
            ));
            setSalesRepAndSalesMangerOptionsData(_salesRepAndSalesMangerOptions);
          } else {
            let _fullName = user.firstName + ' ' + user.lastName;
            let _userId = user.id;
            setSalesRepAndSalesMangerOptionsData([{ text: _fullName, value: _userId }]);
          }
        }
      }
    }
  }, [salesRepAndSalesMangerOptions]);

  useEffect(() => {
    if (salesRepAndSalesMangerOptionsState === fetchStatus.IDLE) {
      setLoading(true);
      dispatch(getSalesRepAndSalesMangerOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSALESREPANDSALESMANAGER",
        payload: {
          id: "",
          // data: JSON.stringify({ "locationId": locationId })
          // data: JSON.stringify({ "locationId": locationId })
        }
      }));
    }
  }, [salesRepAndSalesMangerOptionsState]);

  const _getUserText = (options, id) => {
    if (isObjectArray(options)) {
      let userResult = options.find(u => u.id === id);
      if (userResult && userResult.FirstName) {
        return userResult.FirstName + ' ' + userResult.LastName;
      }
    }
    return "";
  }

  const _loadRouteTrackings = async (page, offset, limit, whereClause = undefined, sort) => {
    setIsFiltered(false);
    await dispatch(getAllRouteTracking({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETROUTETRACKING",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: offset,
            limit: limit,
            where: whereClause,
            sort: sort
          })
        }
      }
    }));

  }

  useEffect(() => {
    if (routeTrackingsRequestStatus === fetchStatus.IDLE) {
      const user = accountService.getAuthenticatedUser();
      let loc = user ? user.locationId : "";
      let where = JSON.parse(JSON.stringify(whereClause));
      // where.location.value.push(loc);
      dispatch(updateIsFiltered());
      _loadRouteTrackings(0, 0, 10, where, sort);
    }
  }, [routeTrackingsRequestStatus, dispatch]);

  let routeTrackingCards;
  let _dummyCards = [];
  let _routeTrackingRecords = new Collection();

  useEffect(() => {
    if (routeTrackingsRequestStatus === fetchStatus.SUCCEEDED) {
      let _records = routeTrackings.Items;

      if (routeTrackings.Items && routeTrackings.Items.length > 0) {
        for (let i = 0; i < routeTrackings.Items.length; i++) {
          let item = routeTrackings.Items[i];
          routeTrackingCards = item.records.map((rt, index) => (
            <RouteTrackingCard
              key={rt.id}
              id={rt.id}
              routeTrackingCardClasses="mb-2"
              name={_getUserText(salesRepAndSalesMangerOptions, rt.userId)}
              routeDate={toLocalDateOnly(rt.routeDate)}
              status={rt.routeStatus}
              statusText={rt.routeStatus}
              total={rt.TotalLeads}
              visited={rt.TotalVisited}
              onClick={() => onRouteTrackingSelected(rt.id)}
            />

          ));
          _routeTrackingRecords.Add(item.key, routeTrackingCards);
        }

        setRouteTrackingRecords(_routeTrackingRecords);
        dispatch(updateStatus());
      }
      dispatch(updateStatus());
    }
  }, [routeTrackingsRequestStatus, currentPage]);

  useEffect(() => {
    if (routeTrackingsRequestStatus === fetchStatus.LOADING) {
      for (let i = 0; i < 10; i++) {
        _dummyCards.push(
          <div className="route-tracking-card">
            <div className="route-tracking-card-container p-2 d-block">
              <ReactPlaceholder showLoadingAnimation={true} type='media' ready={false} rows={3}>
              </ReactPlaceholder>
            </div>
          </div>
        );
      }
      setDummyCards(_dummyCards);
    }
  }, [routeTrackingsRequestStatus]);

  useEffect(() => {
    // if (singleRefreshedStatus) {
    if (routeTrackingsRequestStatus === fetchStatus.DONE) {
      let _records = routeTrackings.Items;

      if (routeTrackings.Items && routeTrackings.Items.length > 0) {
        for (let i = 0; i < routeTrackings.Items.length; i++) {
          let item = routeTrackings.Items[i];
          routeTrackingCards = item.records.map((rt, index) => (
            <RouteTrackingCard
              key={rt.id}
              id={rt.id}
              routeTrackingCardClasses="mb-2"
              name={_getUserText(salesRepAndSalesMangerOptions, rt.userId)}
              routeDate={toLocalDateOnly(rt.routeDate)}
              status={rt.routeStatus}
              statusText={rt.routeStatus}
              total={rt.TotalLeads}
              visited={rt.TotalVisited}
              onClick={() => onRouteTrackingSelected(rt.id)}
            />
          ));
          _routeTrackingRecords.Add(item.key, routeTrackingCards);
        }

        setRouteTrackingRecords(_routeTrackingRecords);
      }
      else {
        setRouteTrackingRecords(undefined);
      }
    }
    // }

  }, [singleRefreshedStatus]);

  const _loadRouteTracking = async (id, page) => {
    await dispatch(getRouteTrackingById({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETROUTETRACKINGBYID",
        payload: {
          id: id,
          data: ""
        }
      }
    }));
  }

  const onFilterClicked = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    _loadRouteTrackings(0, 0, 10, whereClause, sort);
  };

  const onAddRecordClicked = (e) => {
    dispatch(createNewRouteTracking());
    dispatch(updateRecentClickedRouteTracking(0));
    history.push('routeList/details');
  };


  const onRouteTrackingSelected = (id) => {
    _loadRouteTracking(id, undefined);
    dispatch(updateRecentClickedRouteTracking(id));
    history.push('routeList/details');
  };

  const onPageChanged = async (p, l) => {
    dispatch(updateCurrentPage(p));
    if (l) {
      await onLoadItems(p);
    }
  };

  const onLoadItems = async (p) => {
    await _loadRouteTrackings(p, p * 10, 10, whereClause, sort);
  };

  const onSorted = async (sortBy, ascending) => {
    await dispatch(updateIsFiltered());
    _loadRouteTrackings(0, 0, 10, whereClause, {
      by: sortBy,
      ascending: ascending
    });
  };


  return (
    <>
      <div className="filter-bar p-3">
        <RouteTrackingStatusFilter
          routeTrackingStatusFilterSelector={selectRouteTrackingStatusFilter}
          onRouteTrackingStatusChanged={(e, v, routeTrackingStatuses) => { onRouteTrackingStatusChanged(e, v, routeTrackingStatuses); setIsFiltered(true); }} />
        {(isAdmin || isSalesManager) &&
          <>
            <RouteTrackingUserFilter
              routeTrackingUsers={salesRepAndSalesMangerOptions}
              routeTrackingUserFilterSelector={selectUserIdFilter}
              onRouteTrackingUserChanged={(e, v, userIds) => { onRouteTrackingUserIdChanged(e, v, userIds); setIsFiltered(true); }} />
          </>
        }
        <div className="float-end">
          {isFiltered &&
            <button type="button" className="c-btn dark-btn position-relative" onClick={(e) => onFilterClicked(e)}>
              <i className="fa fa-filter me-2" aria-hidden="true"></i> Filter
            </button>
          }
          <button type="button" className="c-btn dark-btn ms-3" onClick={onAddRecordClicked} >
            Add Route
          </button>
        </div>
      </div>

      <div className="right-content">
        <div className="d-flex w-100 p-3 pb-0">
          <div className="align-self-center">
            <div className="job-order-result-text">Showing {totalPageItems} Results <span className="job-order-result-sub-text">(Based your search)</span> </div>
          </div>
          <div className="ms-auto align-self-center">
            <div className="d-flex">
              <RouteTrackingSorter
                onSorted={(s, a) => onSorted(s, a)} />
            </div>
          </div>
        </div>
      </div>

      <PageContainer
        currentPage={currentPage}
        totalPageItems={totalPageItems}
        pageRecords={routeTrackingRecords}
        isItemsLoading={routeTrackingsRequestStatus}
        // isItemsLoading={'loading'}
        itemsLoadingContent={dummyCards}
        onPageChanged={(p, l) => onPageChanged(p, l)}
        infinite={true}
        pageRecordsCardClassName={'route-tracking-card'}
      />
    </>
  )
}

export default RouteTracking