import React, { useEffect, useState } from 'react';
import { openFileUploader } from '../../../../common/common';

function FileUpload(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
        }
        else {
            setInvalid(false);
        }
    }, [value, props.required, edited, props.submited, props.disabled]);

    const onChange = (e) => {
        props.onChange && props.onChange(e);
        setValue(e.target.value);
        setEdited(true);
    };

    const onFileUploadClicked = () => {
        let id = props.id;
        openFileUploader(id);
    };

    return (
        <React.Fragment>
            <div className={`file-upload ${props.FileUploadClasses ? props.FileUploadClasses : ""} ${invalid && "invalid-input"}`} onClick={() => onFileUploadClicked()}>
                {/* <span className={`choose-file ${invalid ? "invalid-choose-file" : ""}`}>Choose File</span> */}
                {/* <span className="chosen">{value ? value : "No files chosen"}</span> */}
                {/* <span className={`upload ${invalid ? "text-danger" : (value ? "text-success" : "text-primary")}`}><i className="fa fa-cloud-upload"></i></span> */}
                {/* <img src={props.icon}/> */}
                {props.children}
            </div>
            <input
                type="file"
                id={props.id}
                name={props.name}
                value={value}//props.value ? props.value : ""}
                className={`file-upload-input`}
                placeholder={`${props.placeholder ? props.placeholder : ""}`}
                onChange={(e) => onChange(e)}
                disabled={props.disabled === true}
                formNoValidate={true}
                data-form={props.form ? props.form : ""}
                data-invalid={invalid}
                data-required={props.required ? props.required : false}
                accept={props.accept ? props.accept : "*"}
            />
            {
                invalid && <span className="error-label">
                    <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            }
        </React.Fragment>
    );
}

export default FileUpload;