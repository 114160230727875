import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toLocalDate } from '../../common/common';
import { accountService } from '../../features/accounts/services/accountService';
import { getApplicantById, selectApplicantById, updateSingleData } from '../../features/applicant/reducerSlices/applicantSlice';
import { onNoteChanged } from '../../features/applicant/services/noteSaver';
import { saveApplicantCalledOn, saveNote } from '../../features/reviewer/reducerSlices/reviewerSlice';
import Radio from '../Controls/custom/checkers/radio';
import Statement from '../Controls/custom/textbox/statement';
import { isFormValid } from '../Controls/services/formService';

import messageSquareImg from './../../assets/images/clipart/message-square.svg';
import sortImg from './../../assets/images/clipart/sort.svg';
import { saveUpdatedApplicant } from './reducerSlices/adminNoteSlice';
import DataNotFoundCard from '../Card/DataNotFoundCard';
import ReactTooltip from 'react-tooltip';
import { fetchStatus, tenantId } from '../../api/client';
import { selectApplicantCalledOnStatus } from '../../features/reviewer/reducerSlices/reviewerSlice';
import EllipsisLoader from '../Controls/loaders/ellipsisLoader';


function CalledFlyout(props) {

  const dispatch = useDispatch();

  const [isActive, setActive] = useState(false);
  const ToggleClass = () => {
    setActive(!isActive);

    const timer = setTimeout(() => {
      props.onClose(false);
    }, 1500);
  };

  const [statusval, setStatusVal] = useState(0);

  const [type, setType] = useState("CalledOn");
  const [noChange, setNoChange] = useState(true);

  const [note, setNote] = useState("");
  const [notesList, setNotesList] = useState([]);

  const CALLEDIN_NOTE_FORM = "CalledinNoteForm";

  const [anfSubmitted, setANFSubmitted] = useState(false);
  const applicantCalledOnStatus = useSelector(selectApplicantCalledOnStatus);

  const onSaveClicked = async (id) => {
    const user = accountService.getAuthenticatedUser();
    if (isFormValid(CALLEDIN_NOTE_FORM)) {
      var noteDetails = {};
      if (noChange) {
        setStatusVal(0);
        noteDetails = {
          Content: note,
          statusValue: 0,
          status: _getLogtext(0),
        };
      }

      setANFSubmitted(false);
      if (id) {
        if (type === 'CalledOn') {
          await dispatch(saveApplicantCalledOn({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVEAPPLICANTCALLEDON",
            payload: {
              id: applicant.id,
              data: JSON.stringify(noteDetails)
            }
          }));
          _loadApplicant(props.applicantId); // Ask
          setNote("");
          ToggleClass();
        }
        if (type === 'CalledIn') {
          await dispatch(saveApplicantCalledOn({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVEAPPLICANTCALLEDIN",
            payload: {
              id: applicant.id,
              data: JSON.stringify(noteDetails)
            }
          }));
          _loadApplicant(props.applicantId); // Ask
          setNote("");
          ToggleClass();
        }
      }
    }
    else {
      setANFSubmitted(true);
    }
  };


  const applicant = useSelector(selectApplicantById);

  const _loadApplicant = async (id) => {
    await dispatch(getApplicantById({
      client: tenantId,
      serviceType: "TENANTUSER",
      operation: "GET",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  useEffect(() => {
    let _logs;
    let _noteDiv;
    let _noteArr = [];

    if (applicant && applicant.StatusLogs) {
      _logs = applicant.StatusLogs.map((log, index) => {
        if (log && log.LogValue) {

          if (log.LogValue === -2 || log.LogValue === -3) {
            _noteDiv = <div className="tracking-item">
              {getIcon(log.LogValue)}
              <div className="tracking-content">
                <p>
                  <span className="p-name">{log.UserName}</span>
                  {log && getStatus(log.LogValue, log.LogType)}
                </p>
                <p className="p-subtext">{log.Content}</p>
                <span className="p-light-gray">{log.TimeStamp && toLocalDate(log.TimeStamp)}</span>
              </div>
            </div>
            _noteArr.push(_noteDiv);
          }
        }

      });

      setNotesList(_noteArr.reverse());
    }
  }, [applicant]);

  const sort = () => {
    const sortData = [...notesList].reverse();
    setNotesList(sortData);
  }

  const getIcon = (logValue) => {
    let icon;
    if (logValue === -2) {
      icon = <div className="tracking-icon status-intransit bg-light-orange">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-inbound-fill" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0z" />
        </svg>
      </div >
    } else if (logValue === -3) {
      icon = <div className="tracking-icon status-intransit bg-blue">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-outbound-fill" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
        </svg>
      </div>
    }

    return icon;
  }

  const getStatus = (logValue, logType) => {
    let statusText;

    if (logValue === -3) {
      statusText = <React.Fragment><span className="p-changed"> Applicant </span><span className="p-status"><span className="badge rounded-pill bg-blue"> {logType} </span></span></React.Fragment>;
    }
    else if (logValue === -2) {
      statusText = <React.Fragment><span className="p-status"> <span className="badge rounded-pill bg-light-orange"> {logType} </span></span><span className="p-changed"> Applicant </span></React.Fragment>;
    }

    return statusText;
  };

  const _getLogtext = (status) => {
    if (status === 0) {
      return 'No Change';
    }
    else if (status === 2) {
      return "Available";
    }

    else if (status === 4) {
      return "InActive";
    }
    else if (status === 5) {
      return "InActive";
    }

    return 'No Change';
  }

  return (
    <>
      <div className="flyout-wrapper position-fixed top-0 end-0">
        <div className={`flyout-card ${isActive ? 'close' : 'open'}`}>
          <div className="flyout-card-body overflow-visible">
            <div className="float-end">
              <div className="close-btn">
                <i className="fa fa-times" aria-hidden="true" onClick={ToggleClass} data-tip='Close'></i>
                <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
              </div>
            </div>
            <div className="flyout-card-title">Called In / Called Out</div>
            <div className="flyout-card-sub-title">
              {applicant ? applicant.FirstName.Data : ""}  {applicant ? applicant.LastName.Data : ""}
            </div>

            <div className='pt-3'>
              <Radio id="rdbtnCalledOn"
                name="call_type"
                label="Call Out"
                value="CalledOn"
                onChange={() => setType("CalledOn")}
                checked={type === "CalledOn"}
              />
              <Radio id="rdbtnCalledOut"
                name="call_type"
                label="Call In"
                value="CalledIn"
                onChange={() => setType("CalledIn")}
                checked={type === "CalledIn"}
              />
            </div>

            <label className="flyout-label pt-2">Notes</label>
            <Statement
              id="txtAdminNote"
              name="txtAdminNote"
              value={note}
              // StatementClasses="form-control"
              StatementClasses="flyout-input"
              rows={2}
              onChange={(e) => setNote(e.target.value)}
              required={true}
              errorMessage="Please enter note"
              form={CALLEDIN_NOTE_FORM}
              submited={anfSubmitted}
              placeholder="Enter note..."
            />
            <div className="d-flex align-items-baseline pt-3">
              <div className="ms-auto">
                {(applicantCalledOnStatus === fetchStatus.LOADING) ? <EllipsisLoader />
                  : <>
                    <button className="c-btn dark-btn" type="button" onClick={() => onSaveClicked(props.applicantId)} >Save</button>
                  </>
                }
              </div>
            </div>
          </div>

          <div className="flyout-card-filter-bar d-flex align-items-baseline">
            <div className="flyout-card-semi-title">
              Recent
            </div>
            <div className="ms-auto">
              <button className="c-btn white-btn flyout-sort-bt" type="button" onClick={sort}>
                <img src={sortImg} className="pe-2" />Sort
              </button>
            </div>
          </div>

          <div className="flyout-card-body">
            <div className="tracking-list pt-3">
              {Object.keys(notesList).length === 0 ?
                <DataNotFoundCard containerClassOverriding={`data-not-found-container-100 pt-5 mt-5`} title={'No notes found '} />
                :
                <div className="tracking-list pt-3">
                  {notesList}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className='flyout-backdrop flyout-backdrop-show' />
    </>
  )
}

export default CalledFlyout