import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addCurrentStep, selectLanguage, updateLoginStatus } from '../reducerSlices/accountsSlice';
import { fetchStatus, tenantId } from '../../../api/client';
import { accountService } from '../services/accountService';
import Progress from '../../../component/Progress/Progress';
import '../css/accounts.css';
import BackButton from './../../../component/Controls/buttons/backButton';
import ContinueButton from './../../../component/Controls/buttons/continueButton';
import ShortText from '../../../component/Controls/custom/textbox/shortText';
import ZipCodeText from '../../../component/Controls/custom/textbox/zipCodeText';
import { isFormValid } from '../../../component/Controls/services/formService';
import { getRegisteredApplicantById, selectRegisteredApplicantById, selectRegisteredApplicantModificationStatus, selectRegisteredApplicantStatus, updateRegisteredApplicant, updateRegisteredModificationStatus, updateRegisteredApplicantData } from '../../applicant/reducerSlices/applicantSlice';
import { USER_KEY, Language } from '../../../common/constants';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup';
import { applicantDetailsEN } from '../../applicant/services/applicantDetails';
import { onValueChanged, sections } from '../../applicant/services/applicantSaver';
import Selector from '../../../component/Controls/custom/selectors/selectors';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';

const Personal_Info_1 = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(addCurrentStep('Personal'));

  const APPLICANT_DETAIL_FORM = "AppilcantDetailForm";
  const [adfSubmitted, setADFSubmitted] = useState(false);
  const [aPFInvalid, setAPFInvalid] = useState(false);

  const applicantRequestStatus = useSelector(selectRegisteredApplicantStatus);
  const applicantModificationStatus = useSelector(selectRegisteredApplicantModificationStatus);
  const applicant = useSelector(selectRegisteredApplicantById);

  const [applicantDetails, setApplicantDetails] = useState(applicantDetailsEN);
  const language = useSelector(selectLanguage);

  const _loadApplicant = async (id) => {
    await dispatch(getRegisteredApplicantById({
      client: tenantId,
      serviceType: "TENANTUSER",
      operation: "GETREGISTEREDAPPLICANT",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  useEffect(() => {
    if (applicantRequestStatus === fetchStatus.IDLE) {
      const userId = accountService.getLoggedInUserId(USER_KEY);
      _loadApplicant(userId);
    }
  }, [applicantRequestStatus]);

  const onNextClicked = (e) => {
    setAPFInvalid(false);
    if (isFormValid(APPLICANT_DETAIL_FORM)) {
      if (applicant) {
        const userId = accountService.getLoggedInUserId(USER_KEY);
        dispatch(updateRegisteredApplicant({
          client: tenantId,
          serviceType: "TENANTUSER",
          operation: "SUBMITAPPLICANT",
          payload: {
            id: userId,
            data: JSON.stringify({
              applicant: JSON.stringify(applicant),
              photo: {
                contentType: "image/jpg",
                length: 0,
                photoData: ""
              }
            })
          }
        }));
      }
      setADFSubmitted(false);
    }
    else {
      setAPFInvalid(true);
      setADFSubmitted(true);
    }
  };

  const onBack = (e) => {
    history.push('/register');
  }

  useEffect(() => {
    if (applicantModificationStatus === fetchStatus.SUCCEEDED) {
      if (accountService.isAuthenticated()) {
        dispatch(updateLoginStatus());
        dispatch(updateRegisteredModificationStatus());
        history.push("/personal_info_2");
      }
    }
  }, [applicantModificationStatus, dispatch, history]);

  const onValueUpdated = (e, section, value) => {
    let _applicantObj = Object.assign({}, applicant);
    let _newApplicantObj = onValueChanged(_applicantObj, e, section, value);
    dispatch(updateRegisteredApplicantData(_newApplicantObj));
  };


  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 card card-left d-flex align-items-stretch p-0">
            <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn">
              <div className="row p-2">
                <div className="col-md-12">
                  <div className="card-left-header">Personal Info</div>
                  <div className="card-left-sub-header">Please enter your personal details. Dont have any? Its ok leave it blank. </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblFirstName" className="field-label" htmlFor="txtFirstName">{applicantDetails ? applicantDetails.FirstName : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <ShortText
                          required={applicant && applicant.FirstName.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.FirstNameError : applicant && applicant.FirstName.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          id="txtFirstName"
                          name="txtFirstName"
                          ShortTextClasses="form-control"
                          placeholder={applicantDetails ? applicantDetails.FirstName : ""}
                          value={applicant ? applicant.FirstName.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />
                      } />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblMiddleName" className="field-label" htmlFor="txtMiddleName">{applicantDetails ? applicantDetails.MiddleName : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <ShortText
                          required={applicant && applicant.MiddleName.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.MiddleNameError : applicant && applicant.MiddleName.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          id="txtMiddleName"
                          name="txtMiddleName"
                          ShortTextClasses="form-control"
                          placeholder={applicantDetails ? applicantDetails.MiddleName : ""}
                          value={applicant ? applicant.MiddleName.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblLasName" className="field-label" htmlFor="txtLasName">{applicantDetails ? applicantDetails.LastName : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <ShortText
                          required={applicant && applicant.LastName.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.LastNameError : applicant && applicant.LastName.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          id="txtLastName"
                          name="txtLastName"
                          ShortTextClasses="form-control"
                          placeholder={applicantDetails ? applicantDetails.LastName : ""}
                          value={applicant ? applicant.LastName.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />
                      } />
                  </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblOtherLastName" className="field-label" htmlFor="txtOtherLastName">{applicantDetails ? applicantDetails.OtherLastName : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <ShortText
                          required={applicant && applicant.OtherLastName.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.OtherLastNameError : applicant && applicant.OtherLastName.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          id="txtOtherLastName"
                          name="txtOtherLastName"
                          ShortTextClasses="form-control"
                          placeholder={applicantDetails ? applicantDetails.OtherLastName : ""}
                          value={applicant ? applicant.OtherLastName.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />
                      } />
                  </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-12">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblAddress" className="field-label" htmlFor="txtAddress">{applicantDetails ? applicantDetails.Address : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-home"></i>}
                      control={
                        <ShortText
                          required={applicant && applicant.Address.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.AddressError : applicant && applicant.Address.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          id="txtAddress"
                          name="txtAddress"
                          ShortTextClasses="form-control"
                          placeholder={applicantDetails ? applicantDetails.AddressPlaceHolder : ""}
                          value={applicant ? applicant.Address.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />
                      } />
                  </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblCity" className="field-label" htmlFor="txtCity"> {applicantDetails ? applicantDetails.City : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-home"></i>}
                      control={
                        <ShortText
                          required={applicant && applicant.City.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.CityError : applicant && applicant.City.Validation}
                          form={APPLICANT_DETAIL_FORM} submited={adfSubmitted}
                          id="txtCity"
                          name="txtCity"
                          ShortTextClasses="form-control"
                          placeholder={applicantDetails ? applicantDetails.City : ""}
                          value={applicant ? applicant.City.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />
                      } />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblstate" className="field-label" htmlFor="txtState">{applicantDetails ? applicantDetails.State : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-home"></i>}
                      control={
                        // <Selector
                        //   required={applicant && applicant.State.Required}
                        //   errorMessage={language === Language.SPANISH ? applicantDetails.StateError : applicant && applicant.State.Validation}
                        //   form={APPLICANT_DETAIL_FORM}
                        //   submited={adfSubmitted}
                        //   id="txtState"
                        //   name="txtState"
                        //   SelectorClasses="form-select"
                        //   value={applicant && applicant.State ? applicant.State.Data.trim() : ""}
                        //   options={

                        //   } onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)}
                        // />
                        <ShortText
                          required={applicant && applicant.State.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.StateError : applicant && applicant.State.Validation}
                          form={APPLICANT_DETAIL_FORM} submited={adfSubmitted}
                          id="txtState"
                          name="txtState"
                          ShortTextClasses="form-control"
                          // placeholder={applicantDetails ? applicantDetails.State : ""}
                          value={applicant ? applicant.State.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblzip" className="field-label" htmlFor="txtZip">{applicantDetails ? applicantDetails.ZipCode : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-home"></i>}
                      control={
                        <ZipCodeText
                          required={applicant && applicant.ZipCode.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.ZipCodeError : applicant && applicant.ZipCode.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          id="txtZipcode"
                          name="txtZipcode"
                          ZipCodeTextClasses="form-control"
                          value={applicant ? applicant.ZipCode.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />
                      } />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer bottom-0 px-4 py-2 w-100">
              <div className="row">
                <div className="col-md-12">
                  {applicantModificationStatus === fetchStatus.LOADING ? <div className="float-end my-2"><EllipsisLoader /></div>
                    :
                    <>
                      <BackButton onBackClicked={onBack} />
                      <div className="float-end">
                        <ContinueButton onClick={(e) => onNextClicked(e)} />
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 card card-right d-flex align-items-stretch p-0">
            <Progress percentage="0" getPercentage="15" subTitle="Personal Info" currentPage="personalInfo1" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Personal_Info_1;