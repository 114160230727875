import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, GridComponent, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';

import { fetchStatus, tenantId } from '../../../api/client';
import { Operation } from '../../../common/constants';
import { isFormValid } from '../../../component/Controls/services/formService';

import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup'
import ShortText from '../../../component/Controls/custom/textbox/shortText'
import ContinueButton from '../../../component/Controls/buttons/continueButton';
import BackButton from '../../../component/Controls/buttons/backButton';
import SetupProgress from '../../../component/Progress/SetupProgress';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';

import { onValueChanged, sections } from '../../setting/services/positionSaver';
import { createNewPosition, createPosition, getAllPositions, getPositionById, removePosition, selectAllPositions, selectCreationStatus, selectModificationStatus, selectPositionById, selectRemovalStatus, selectSingleStatus, selectStatus, updatePosition, updatePositionsOptionsStatus, updateSingleData, updateStatus } from '../../setting/reducerSlices/positionsSlice';
import GridLoader from '../../../component/Controls/loaders/gridLoader';
import ReactTooltip from 'react-tooltip';
import { show } from '../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';
import { messages } from '../../../common/actionMessages';



function CompanyPositions() {

  const dispatch = useDispatch();
  const history = useHistory();

  const POSITION_FORM = "Positionform";

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [cFInvalid, setCFInvalid] = useState(false);
  const [cfSubmitted, setCFSubmitted] = useState(false);


  const position = useSelector(selectPositionById);
  const positionRequestStatus = useSelector(selectSingleStatus);
  const positionCreationStatus = useSelector(selectCreationStatus);
  const positionModificationStatus = useSelector(selectModificationStatus);
  const positionRemovalStatus = useSelector(selectRemovalStatus);
  const positions = useSelector(selectAllPositions);
  const positionsRequestStatus = useSelector(selectStatus);



  useEffect(() => {
    if (positionsRequestStatus === fetchStatus.IDLE) {
      _loadPositions();
    }
  }, [positionsRequestStatus, dispatch]);


  useEffect(() => {
    if (positionsRequestStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateStatus());
    }
  }, [positionsRequestStatus]);

  // useEffect(() => {
  //   if (positionsRequestStatus === fetchStatus.LOADING) {
  //     for (let i = 0; i < 10; i++) {
  //       _dummyCards.push(
  //         <div className="list-loading-content">
  //           <ReactPlaceholder type='text' ready={false} rows={2} color='#E0E0E0'>
  //           </ReactPlaceholder>
  //         </div>
  //       );
  //     }
  //     setDummyCards(_dummyCards);
  //   }
  // }, [positionsRequestStatus]);

  useEffect(() => {
    if (positionRemovalStatus === fetchStatus.SUCCEEDED) {
      _loadPositions();
      if (positions && positions.Items && positions.Items.length > 0) {
        let item = positions.Get(0);
        let _positions = item ? item.records : [];
        if (_positions && Array.isArray(_positions) && _positions.length > 0) {
          let _positions = _positions[0];
          if (_positions && _positions.id) {
            _loadPosition(_positions.id, 0);
          }
        }
        else {
          dispatch(updateSingleData(undefined));
        }
      }
      else {
        dispatch(updateSingleData(undefined));
      }

      dispatch(updatePositionsOptionsStatus());
    }
  }, [positionRemovalStatus]);

  const _loadPosition = async (id, page) => {
    await dispatch(getPositionById({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETPOSITIONBYID",
        payload: {
          id: id,
          data: ""
        }
      }
    }));
  }

  // const _loadForm = async () => {
  //     await dispatch(getClientForm({
  //         client: tenantId,
  //         serviceType: "TENANTADMIN",
  //         operation: "GETFORMBYTYPE",
  //         payload: {
  //             id: "",
  //             data: "ClientForm"
  //         }
  //     }));
  // }

  const _loadPositions = async () => {
    await dispatch(getAllPositions({
      page: 0,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETPOSITIONS",
        payload: {
          id: "",
          data: ""
        }
      }
    }));
  }

  const onPositionsSelected = (e, id) => {
    e.preventDefault();
    _loadPosition(id, undefined);
    setCreateMode(false);
    setEditmode(true);

  };

  const onDeleteClicked = (e, id) => {
    e.preventDefault();
    if (id) {
      dispatch(show({
        id: id,
        title: messages.confirmAlert.title,
        content: messages.confirmAlert.content.removal('Positions'),
        confirmedCallback: _removePosition
      }));
    }
  };

  const onAddRecordClicked = (e) => {
    dispatch(createNewPosition());

    setCreateMode(true);
    setEditmode(false);
    setCFSubmitted(false);
  };

  const _removePosition = async (id) => {
    await dispatch(removePosition({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "DELETEPOSITION",
      payload: {
        id: id,
        data: ""
      }
    }))
  };

  useEffect(() => {
    if (positionCreationStatus === fetchStatus.SUCCEEDED) {
      dispatch(updatePositionsOptionsStatus());
      _loadPositions();
    }
  }, [positionCreationStatus]);

  useEffect(() => {
    if (positionModificationStatus === fetchStatus.SUCCEEDED) {
      dispatch(updatePositionsOptionsStatus());
      _loadPositions();
    }
  }, [positionModificationStatus]);

  const addActionTemplate = (props) => {
    return <>
      <i className='fa fa-pencil' data-tip='Edit' title='Edit' onClick={(e) => onPositionsSelected(e, props.id)}></i>
      <i className='fa fa-trash ps-3' data-tip='Delete' title='Delete' onClick={(e) => onDeleteClicked(e, props ? props.id : "")}></i>
      <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
    </>
  }
  const gridRef = useRef(null);
  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  }, [positions, positionsRequestStatus]);

  const columns = [
    { field: 'PositionName', headerText: 'Position Name', width: '100' },
    {
      headerText: 'Action',
      template: addActionTemplate,
      width: '50px',
    },
  ];




  const onValueUpdated = (e, section, value) => {
    let _positionObj = Object.assign({}, position);
    console.log('_positionObj ->', _positionObj)
    let _newPositionObj = onValueChanged(_positionObj, e, section, value);
    dispatch(updateSingleData({
      position: _newPositionObj,
      operation: Operation.UPDATED
    }));
  };

  const onSaveClicked = (e, id, created) => {
    e.preventDefault();
    if (isFormValid(POSITION_FORM)) {
      if (position) {
        if (created) {
          dispatch(createPosition({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVEPOSITION",
            payload: {
              id: id,
              data: JSON.stringify({
                position: JSON.stringify(position),
              })
            }
          }));
        }
        else {
          dispatch(updatePosition({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVEPOSITION",
            payload: {
              id: id,
              data: JSON.stringify({
                position: JSON.stringify(position),
              })
            }
          }));
        }
      }
      setCreateMode(false);
      setEditmode(false);
      setCFInvalid(false);
      setCFSubmitted(false);
    }
    else {
      setCFInvalid(true);
    }
    setCFSubmitted(true);
  };

  const onCancelClicked = (e) => {
    setCreateMode(false);
    setEditmode(false);
    setCFInvalid(false);
    setCFSubmitted(false);
  };

  const onNextClicked = (e) => {
    history.push('companySkillGroup');
  }

  const onBack = (e) => {
    history.push('companyLocation');
  }


  //Add


  return (
    <>
      <div className="container ">
        <div className="row">
          <div className="col-md-4 card setup-wizard-left-side d-flex align-items-stretch p-0">
            <SetupProgress percentage="30" getPercentage="45" subTitle="Positions" currentPage="companyPositions" />
          </div>
          <div className="col-md-8 card setup-wizard-right-side d-flex align-items-stretch p-0">
            <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn">

              {(positionsRequestStatus === fetchStatus.LOADING ||
                positionRequestStatus === fetchStatus.LOADING ||
                positionRemovalStatus === fetchStatus.LOADING
              ) &&
                <div className="setup-wizard-loader">
                  <GridLoader />
                </div>
              }

              <div className="row p-2">
                <div className="col-md-12">
                  <div className="card-left-header">Job Positions Configuration</div>
                  <div className="card-left-sub-header">Configure Job Roles to Fit Your Organization </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-end">
                  {(createMode || editMode) ?
                    <>
                      <button className="c-btn dark-btn" type="button" title='Cancle' onClick={(e) => onCancelClicked(e)}>
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                    :
                    <button className="c-btn dark-btn" type="button" title='Create new skill group' onClick={(e) => onAddRecordClicked(e)}>
                      <i className="fa fa-plus"></i>
                    </button>
                  }
                </div>
              </div>

              {!editMode && !createMode ?
                <div className="row p-2">
                  <GridComponent ref={gridRef} dataSource={positions} allowPaging={true} allowSorting={true} allowResizing={true} width="100%" toolbar={['Search']} pageSettings={{ pageSize: 7, pageCount: 5 }} rowHeight={50} columns={columns} >
                    <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                  </GridComponent>
                </div>
                :
                <>
                  <div className="row p-2">
                    <div className="col-md-6">
                      <div className="form-group p-0 pt-2">
                        <label id="lblPositionName" className="flyout-label" htmlFor="txtPositionName">Position Name</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{position && position.PositionName ? position.PositionName.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-address-card"></i>}
                            control={
                              <ShortText
                                id="txtPositionName"
                                name="txtPositionName"
                                ShortTextClasses="form-control"
                                placeholder="Position Name"
                                value={position && position.PositionName ? position.PositionName.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.POSITION_DETAILS, null)}
                                // required={positionForm.PositionName.Required}
                                required={true}
                                // errorMessage={positionForm.PositionName.Validation}
                                form={POSITION_FORM}
                                submited={cfSubmitted}
                              // disabled={editMode}
                              />
                            }
                          />
                        }
                      </div>
                    </div>
                  </div>
                </>
              }

            </div>

            <div className="card-footer bottom-0 px-4 py-2 w-100">
              <div className="row">
                <div className="col-md-12">
                  {positionCreationStatus === fetchStatus.LOADING || positionModificationStatus === fetchStatus.LOADING
                    ? <div className="float-end my-2"><EllipsisLoader /></div>
                    :
                    <>
                      {(createMode || editMode) ?
                        <>
                          <BackButton onBackClicked={(e) => onCancelClicked(e)} text="Cancle" />
                          <div className="float-end">
                            <ContinueButton onClick={(e) => onSaveClicked(e, position ? position.id : "", createMode)} text="Save" />
                          </div>
                        </>
                        :
                        <>
                          <BackButton onBackClicked={(e) => onBack(e)} />
                          <div className="float-end">
                            <ContinueButton onClick={(e) => onNextClicked(e)} />
                          </div>
                        </>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyPositions