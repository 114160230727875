import { nanoid } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isValueObject } from '../../../common/common';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import YesNoRadio from '../../Controls/custom/checkers/yesNoRadio';
import InputGroup from '../../Controls/custom/inputGroup/inputGroup';
import Selector from '../../Controls/custom/selectors/selectors';
import NumberText from '../../Controls/custom/textbox/numberText';

//import './css/otherExperienceFilter.css';

function ForkLiftFilter(props) {
    const id = nanoid();
    const [havingExperience, setHavingExperience] = useState("-1");
    const [havingCertificate, setHavingCertificate] = useState("-1");
    const [operation, setOperation] = useState(">");
    const [value, setValue] = useState("");
    const [forkliftType, setForkLiftType] = useState("");

    let _forkliftTypes = props.forkliftTypes;

    const forkLiftFilter = useSelector(props.forkLiftFilterSelector);

    useEffect(() => {
        if (forkLiftFilter) {
            setHavingExperience(forkLiftFilter.havingExperience);
            setHavingCertificate(forkLiftFilter.havingCertificate);
            setForkLiftType(forkLiftFilter.type);
            if (forkLiftFilter.experience.operation) {
                setOperation(forkLiftFilter.experience.operation);
            }
            else {
                setOperation(">");
            }
            setValue(forkLiftFilter.experience.value);
        }
    }, [forkLiftFilter]);

    const onOperationChanged = (e) => {
        setOperation(e.target.value);
        let _value = undefined;
        _value = {
            havingExperience: havingExperience,
            havingCertificate: havingCertificate,
            type: forkliftType,
            experience: {
                operation: e.target.value,
                value: value
            }
        }

        props.onForkLiftChanged && props.onForkLiftChanged(e, _value);
    };

    const onForkLiftChanged = (e) => {
        setValue(e.target.value);
        let _value = undefined;

        _value = {
            havingExperience: havingExperience,
            havingCertificate: havingCertificate,
            type: forkliftType,
            experience: {
                operation: operation,
                value: e.target.value
            }
        }

        props.onForkLiftChanged && props.onForkLiftChanged(e, _value);
    };

    const onForkLiftTypeChanged = (e) => {
        setForkLiftType(e.target.value);
        let _value = undefined;

        _value = {
            havingExperience: havingExperience,
            havingCertificate: havingCertificate,
            type: e.target.value,
            experience: {
                operation: operation,
                value: value
            }
        }

        props.onForkLiftChanged && props.onForkLiftChanged(e, _value);
    };

    const onHavingForkLiftChanged = (e, v) => {
        setHavingExperience(v);
        let _value = undefined;
        _value = {
            havingExperience: v,
            havingCertificate: v === "Y" ? forkliftType : "",
            type: v === "Y" ? forkliftType : "",
            experience: {
                operation: v === "Y" ? operation : "",
                value: v === "Y" ? value : ""
            }
        }

        props.onForkLiftChanged && props.onForkLiftChanged(e, _value);
    };

    const onHavingCertificateChanged = (e, v) => {
        setHavingCertificate(v);
        let _value = undefined;

        _value = {
            havingExperience: havingExperience,
            havingCertificate: v,
            type: forkliftType,
            experience: {
                operation: operation,
                value: value
            }
        }

        props.onForkLiftChanged && props.onForkLiftChanged(e, _value);
    };

    let forkliftTypesList;
    if (isObjectArray(_forkliftTypes)) {
        forkliftTypesList = _forkliftTypes.map((et, index) => (
            <option key={et.value} value={et.value}>
                {et.text}
            </option>
        ));
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <label className="">
                        Forklift Operator
                    </label>
                    <YesNoRadio
                        name={`rdbtnHavExp${id}`}
                        value={havingExperience}
                        onSelectionChanged={(e, v) => onHavingForkLiftChanged(e, v)}
                        YesNoRadioClasses="float-end" />
                </div>
                <div className="col-md-12">
                    <div className="form-group p-0 pt-2">
                        <label id={`lbl${id}`}
                            className="field-label d-inline"
                            htmlFor={`txtForkLift${id}`}>
                            Months ForkLift
                        </label>
                        <div className="experience-controls float-end">
                            <Selector
                                required={true}
                                errorMessage={"Please select operation"}
                                form={undefined}
                                submited={false}
                                id={`drpOperation${id}`}
                                name={`drpOperation${id}`}
                                SelectorClasses="form-control operation-control"
                                value={(havingExperience === "Y") ? operation : ""}
                                disabled={!(havingExperience === "Y")}
                                withoutDefault={true}
                                options={
                                    <>
                                        <option value=">">{'>'}</option>
                                        <option value="<">{'<'}</option>
                                        <option value=">=">{'>='}</option>
                                        <option value="<=">{'<='}</option>
                                        <option value="=">{'='}</option>
                                    </>
                                }
                                onChange={(e) => onOperationChanged(e)} />
                            <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={
                                    <NumberText
                                        max="99"
                                        maxLength={2}
                                        required={true}
                                        errorMessage={"Please enter forklift"}
                                        form={undefined}
                                        submited={false}
                                        id={`txtForkLift${id}`}
                                        name={`txtForkLift${id}`}
                                        NumberTextClasses={`form-control ${havingExperience === "Y" ? "" : "disabled"}`}
                                        disabled={!(havingExperience === "Y")}
                                        value={(havingExperience === "Y") ? value : ""}
                                        onChange={(e) => onForkLiftChanged(e)} />
                                } />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group p-0 pt-2">
                        <label className="">
                            Forklift Certificate
                        </label>
                        <YesNoRadio
                            name={`rdbtnHavCertificate${id}`}
                            value={(havingExperience === "Y") ? havingCertificate : "-1"}
                            disabled={!(havingExperience === "Y")}
                            onSelectionChanged={(e, v) => onHavingCertificateChanged(e, v)}
                            YesNoRadioClasses="float-end" />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group p-0 pt-2">
                        <label id={`lblForkLiftType${id}`}
                            className="field-label d-inline"
                            htmlFor={`drpForkLiftType${id}`}>
                            Forklift Operator Type
                        </label>
                        <div className="d-flex float-end">
                            <Selector
                                required={true}
                                errorMessage={"Please select forklift type"}
                                form={undefined}
                                submited={false}
                                id={`drpForkLiftType${id}`}
                                name={`drpForkLiftType${id}`}
                                SelectorClasses="form-select"
                                value={(havingExperience === "Y") ? forkliftType : ""}
                                disabled={!(havingExperience === "Y")}
                                options={forkliftTypesList}
                                onChange={(e) => onForkLiftTypeChanged(e)} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForkLiftFilter;