import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../../../api/client';
import GridLoader from '../../../../component/Controls/loaders/gridLoader';
import { createNewSkillGroup, getAllSkillGroup, getSkillGroupById, removeSkillGroup, selectAllSkillGroup, selectCreationStatus, selectModificationStatus, selectRemovalStatus, selectStatus, showSkillGroupFlyout, updateSkillGroupOptionsStatus, updateSingleData, updateStatus } from '../../reducerSlices/skillGroupSlice';
import ReactPlaceholder from 'react-placeholder';
import { Filter, GridComponent, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';
import ReactTooltip from 'react-tooltip';
import { messages } from '../../../../common/actionMessages';
import { show } from '../../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';


function SkillGroup() {

    const dispatch = useDispatch();

    const whereClause = {
        name: ""
    };

    const [dummyCards, setDummyCards] = useState([]);

    const skillGroupCreationStatus = useSelector(selectCreationStatus);
    const skillGroupModificationStatus = useSelector(selectModificationStatus);
    const skillGroupRemovalStatus = useSelector(selectRemovalStatus);

    const skillGroup = useSelector(selectAllSkillGroup);

    const skillGroupRequestStatus = useSelector(selectStatus);


    useEffect(() => {
        if (skillGroupRequestStatus === fetchStatus.IDLE) {
            _loadSkillGroups(0, 0, 10, whereClause);
        }
    }, [skillGroupRequestStatus, dispatch]);

    let _dummyCards = [];

    useEffect(() => {
        if (skillGroupRequestStatus === fetchStatus.SUCCEEDED) {
            dispatch(updateStatus());
        }
    }, [skillGroupRequestStatus]);

    useEffect(() => {
        if (skillGroupRequestStatus === fetchStatus.LOADING) {
            for (let i = 0; i < 10; i++) {
                _dummyCards.push(
                    <div className="list-loading-content">
                        <ReactPlaceholder type='text' ready={false} rows={2} color='#E0E0E0'>
                        </ReactPlaceholder>
                    </div>
                );
            }
            setDummyCards(_dummyCards);
        }
    }, [skillGroupRequestStatus]);

    useEffect(() => {
        if (skillGroupRemovalStatus === fetchStatus.SUCCEEDED) {
            _loadSkillGroups(0, 0, 10, whereClause);
            if (skillGroup && skillGroup.Items && skillGroup.Items.length > 0) {
                let item = skillGroup.Get(0);
                let _skillGroups = item ? item.records : [];
                if (_skillGroups && Array.isArray(_skillGroups) && _skillGroups.length > 0) {
                    let _skillGroup = _skillGroups[0];
                    if (_skillGroup && _skillGroup.id) {
                        _loadSkillGroup(_skillGroup.id, 0);
                    }
                }
                else {
                    dispatch(updateSingleData(undefined));
                }
            }
            else {
                dispatch(updateSingleData(undefined));
            }

            dispatch(updateSkillGroupOptionsStatus());
        }
    }, [skillGroupRemovalStatus]);

    const _loadSkillGroup = async (id, page) => {
        await dispatch(getSkillGroupById({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETSETTINGSKILLGROUPBYID",
                payload: {
                    id: id,
                    data: ""
                }
            }
        }));
    }

    const _loadSkillGroups = async (page, offset, limit, whereClause) => {
        await dispatch(getAllSkillGroup({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETSETTINGSKILLGROUP",
                payload: {
                    id: "",
                    data: JSON.stringify({
                        offset: offset,
                        limit: 1000,
                        where: whereClause
                    })
                }
            }
        }));
    }

    const onSkillGroupSelected = (e, id) => {
        e.preventDefault();
        _loadSkillGroup(id, undefined);
        dispatch(showSkillGroupFlyout({
            createMode: false,
            editMode: true
        }));
    };

    const onDeleteClicked = (e, id) => {
        e.preventDefault();
        if (id) {
            dispatch(show({
                id: id,
                title: messages.confirmAlert.title,
                content: messages.confirmAlert.content.removal('SkillGroup'),
                confirmedCallback: _removeSkillGroup
            }));
        }
    };

    const onAddRecordClicked = (e) => {
        dispatch(createNewSkillGroup());

        dispatch(showSkillGroupFlyout({
            createMode: true,
            editMode: false
        }));
    };

    const _removeSkillGroup = async (id) => {
        await dispatch(removeSkillGroup({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "DELETESETTINGSKILLGROUP",
            payload: {
                id: id,
                data: ""
            }
        }))
    };

    useEffect(() => {
        if (skillGroupCreationStatus === fetchStatus.SUCCEEDED) {
            dispatch(updateSkillGroupOptionsStatus());
            _loadSkillGroups(0, 0, 10, whereClause);
        }
    }, [skillGroupCreationStatus]);
    
    useEffect(() => {
        if (skillGroupModificationStatus === fetchStatus.SUCCEEDED) {
            dispatch(updateSkillGroupOptionsStatus());
            _loadSkillGroups(0, 0, 10, whereClause);
        }
    }, [skillGroupModificationStatus]);

    const addActionTemplate = (props) => {
        return <>
            <i className='fa fa-pencil' data-tip='Edit' title='Edit' onClick={(e) => onSkillGroupSelected(e, props.id)}></i>
            <i className='fa fa-trash ms-3' data-tip='Delete' title='Delete' onClick={(e) => onDeleteClicked(e, props ? props.id : "")}></i>
            <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
        </>
    }
    const gridRef = useRef(null);
    useEffect(() => {
        if (gridRef.current) {
            gridRef.current.refresh();
        }
    }, [skillGroup, skillGroupRequestStatus]);

    const columns = [
        { field: 'SkillGroupName', headerText: 'Skill Group Name', width: '100' },
        { field: 'Status', headerText: 'Status', width: '100' },
        {
            headerText: 'Action',
            template: addActionTemplate,
            width: '50px',
        },
    ];


    return (
        <>
            {(skillGroupRequestStatus === fetchStatus.LOADING ||
                skillGroupRemovalStatus === fetchStatus.LOADING
            ) &&
                <div className="app-container-loader">
                    <GridLoader />
                </div>
            }
            <div className="row">
                <div className="col-md-6">
                    <span className="detail-container-title">Skills Group</span>
                </div>
                <div className="col-md-6 text-end">
                    <button className="c-btn dark-btn" type="button" data-tip='Create new skill group' onClick={(e) => onAddRecordClicked(e)}>
                        <i className="fa fa-plus"></i>
                    </button>
                </div>
            </div>
            <div className="row p-3">
                <GridComponent ref={gridRef} dataSource={skillGroup} allowPaging={true} allowSorting={true} allowResizing={true} width="100%" toolbar={['Search']} pageSettings={{ pageSize: 10, pageCount: 5 }} rowHeight={50} columns={columns} >
                    <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                </GridComponent>
            </div>
        </>
    )
}

export default SkillGroup