import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    title: "",
    id: "",
    applicant: undefined,
    updatedApplicant: undefined,
    show: false
};

export const adminNoteSlice = createSlice({
    name: 'adminNote',
    initialState,
    reducers: {
        saveUpdatedApplicant: (state, action) => {
            state.updatedApplicant = action.payload;
        }
    }
});

export const {  saveUpdatedApplicant } = adminNoteSlice.actions;

export default adminNoteSlice.reducer

