import {
    createSlice,
    createAsyncThunk,
    nanoid
} from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON, isObjectArray } from '../../../utilities/utilityFunctions';

const initialState = {
    form: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
    },
    convertToLead: {
        status: fetchStatus.IDLE,
        error: null,
        convertToLeadResource: undefined
    },
    statusChange: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
};

export const getAllProspects = createAsyncThunk('prospects/getAllProspects', async (prospectsModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', prospectsModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let prospects;
        if (isJSON(data)) {
            prospects = JSON.parse(data);
        }
        return {
            page: prospectsModel.page,
            prospects: prospects,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const addProspectsToLead = createAsyncThunk('leads/addProspectsToLead', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let lead = undefined;
        if (data && isJSON(data)) {
            lead = JSON.parse(data);
        }
        return {
            lead: lead,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const saveProspectStatus = createAsyncThunk('prospects/saveProspectStatus', async (prospectsModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, prospectsModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let prospects = undefined;
        if (data && isJSON(data)) {
            prospects = JSON.parse(data);
        }
        return {
            prospects: prospects,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const prospectsSlice = createSlice({
    name: 'prospects',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        // updateRemovalStatus: (state) => {
        //     state.removal.status = fetchStatus.IDLE;
        // },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        updateConvertToLeadStatus: (state) => {
            state.convertToLead.status = fetchStatus.IDLE;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllProspects.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        }).addCase(getAllProspects.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.prospects.totalItems;
            state.data.items = action.payload.prospects;
            // let _records = new Collection();
            // _records.Add(action.payload.prospects.items);
            // let _records = new Collection();
            // _records.Add(action.payload.page, action.payload.prospects.items);
            // if (!state.data.isFiltered) {
            //     _records.Concat(state.data.records);
            // }
            // else {
            //     state.data.firstRecords = new Collection();
            //     state.data.currentPage = 0;
            // }
            state.data.isFiltered = false;
            // state.data.records = _records;
            // state.data.records = _records;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getAllProspects.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.data.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(addProspectsToLead.pending, (state, action) => {
            state.convertToLead.status = fetchStatus.LOADING;
        }).addCase(addProspectsToLead.fulfilled, (state, action) => {
            if (action.payload.lead) {
                // state.single.data = action.payload.lead;
                // let _lead = {
                //     id: action.payload.lead.id,
                //     LeadName: action.payload.lead.LeadName.Data,
                //     LeadPhone: action.payload.lead.LeadPhone.Data,
                //     Status: action.payload.lead.Status.Data,
                //     Location: action.payload.lead.Location.Data
                // };
                // const created = refreshCreated(state.data.records, _lead);
                // state.data.records = created.records;
                // state.data.currentPage = created.lastPage;
                // state.data.totalItems += 1;
                // state.single.refreshed = nanoid();
            }
            state.convertToLead.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(addProspectsToLead.rejected, (state, action) => {
            state.convertToLead.status = fetchStatus.FAILED;
            state.convertToLead.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(saveProspectStatus.pending, (state, action) => {
            state.statusChange.status = fetchStatus.LOADING;
        }).addCase(saveProspectStatus.fulfilled, (state, action) => {
            state.statusChange.data = action.payload.applicant;
            state.statusChange.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(saveProspectStatus.rejected, (state, action) => {
            state.statusChange.status = fetchStatus.FAILED;
            state.statusChange.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { updateStatus, updateCurrentPage, updateIsFiltered, updateConvertToLeadStatus } = prospectsSlice.actions;

export default prospectsSlice.reducer;

export const selectAllProspects = state => state.prospects.data.items;

export const selectTotalItems = state => state.prospects.data.totalItems;

export const selectStatus = state => state.prospects.data.status;

export const selectError = state => state.data.prospects.error;

export const selectProspectsById = (state) => {
    return state.prospects.single ? state.prospects.single.data : undefined;
}

export const selectCurrentPage = state => state.prospects.data.currentPage;

export const selectConvertToLeadStatus = state => state.prospects.convertToLead.status;

export const selectConvertToLeadError = state => state.prospects.convertToLead.error;

export const selectProspectStatusChangeData = state => state.prospects.statusChange.data;
export const selectProspectStatusChangeStatus = state => state.prospects.statusChange.status;



