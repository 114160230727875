import React from 'react'
import Avatar from '../Avatar/Avatar'

function FillOrderPeopleCard(props) {
    return (
        <div className="fill-order-sub-card p-3" key={`${props.fullName}_${props.ssn}`}>
            <div className="d-flex">
                <Avatar PhotoPath={props.photoPath} Gender={props.gender}/>
                <div className="people-name ps-2">
                    {props.fullName}
                    <div className="d-flex">
                        <div className="label">SSN:</div>
                        <div className="label ps-1">{props.ssn}</div>
                    </div>
                </div>
                {props.onClickRemovePeople ?
                    <div className="ms-auto">
                        <div className='c-icon-box' onClick={props.onClickRemovePeople} data-tip='Cancel saving'>
                            <i className='fa-solid fa-trash'></i>
                        </div>
                    </div>
                    : ''
                }
            </div>
            <div className="d-flex justify-content-between">
                <div className="pt-2">
                    <div className="label">Mobile Number</div>
                    <div className="label-bold">{props.mobileNumber}</div>
                </div>
                <div className="pt-2">
                    <div className="label">Date</div>
                    <div className="label-bold">{props.date}</div>
                </div>
            </div>
        </div>
    )
}

export default FillOrderPeopleCard;