import React from "react";
import ChartFilter from "./chartFilter";
import Moment from 'moment';


function TopList(props) {

    const colorschemes = ['#f5717d', '#80cdc1', '#17a2b8', '#01665e', '#003c30', '#ffc107', '#e83e8c', '#28a745'];

    const backgroundColor = props.backgroundColor ? props.backgroundColor : colorschemes;
    // const data = [
    //     { name: "Ashley Villa", value: 50, percentage: 10 },
    //     { name: "Carina Cabanas", value: 100, percentage: 20 },
    //     { name: "Carina Cabanas", value: 200, percentage: 30 },
    //     { name: "Carina Cabanas", value: 300, percentage: 35 },
    //     { name: "Carina Cabanas", value: 300, percentage: 5 },
    // ];

    const sumOfTotal = props.topListData.reduce((prev, cur) => prev + cur.value, 0);

    const data = props.topListData.map((item) => ({
        name: item.name,
        value: item.value,
        percentage: ((item.value * 100) / sumOfTotal)
    }));

    const htmlList = data.map((item, index) => {
        return <div className="d-flex pl-5 pe-5" >
            <div className="me-auto p-2"><i className="fa fa-circle pe-2" aria-hidden="true" style={{ color: backgroundColor[index] }}></i>{item.name}</div>
            <div className="p-2">{item.value}</div>
        </div>
    });

    const htmlProgress = data.map((item, index) => {
        return <div className="toplist-bar" style={{ backgroundColor: backgroundColor[index], width: item.percentage + "%" }}>
            <p className="toplist-percent">{item.percentage.toFixed(0)}%</p>
        </div>
    });

    return (
        <>
            <div className="dashboard-section">
                <span className="dashboard-title">{props.title}</span>
                <ChartFilter
                    showLocation={props.showLocation}
                    locationOptionsValue={props.locationOptionsValue}
                    onLocationChange={props.onLocationChange}

                    showEmployee={props.showEmployee}
                    employeeOptionsValue={props.employeeOptionsValue}
                    onEmployeeChange={props.onEmployeeChange}

                    onWeekClicked={(e) => props.onWeekClicked() && props.onWeekClicked(e)}
                    onMonthClicked={(e) => props.onMonthClicked() && props.onMonthClicked(e)}
                    onTodayClicked={(e) => props.onTodayClicked() && props.onTodayClicked(e)}

                    startDate={props.startDate}
                    endDate={props.endDate}
                    onFilter={props.onFilter}

                    actBtn={props.actBtn}
                    title={props.actBtn === 'today' ? 'Date : ' + Moment(props.startDate).format('MM-DD-YYYY') : 'From : ' + Moment(props.startDate).format('MM-DD-YYYY') + ' To ' + Moment(props.endDate).format('MM-DD-YYYY')}
                />
            </div>
            <div className="toplist-progress">
                {htmlProgress}
            </div>
            {htmlList}
        </>
    );
}

export default TopList;