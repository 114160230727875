import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import Moment from 'moment';
import { getSalesRepAndSalesMangerOptions, selectSalesRepAndSalesMangerOptions, selectSalesRepAndSalesMangerOptionsState } from '../reducerSlices/leadSlice';
import { getUsersLocation, selectUsersLocation, selectUsersLocationStatus } from '../reducerSlices/usersLocationSlice';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import { fetchStatus, tenantId } from '../../../api/client';
import avatarMapImg from '../../../assets/images/clipart/boy-face.svg';
import mapMarkImg from '../../../assets/images/clipart/map-mark.png';
import mapMarkLoginImg from '../../../assets/images/clipart/map-mark-login.png';
import mapMarkLogoutImg from '../../../assets/images/clipart/map-mark-logout.png';
import officeMapImg from '../../../assets/images/clipart/office-map.png';
import officeBlueMapImg from '../../../assets/images/clipart/office-blue-map.png';
import officeRedMapImg from '../../../assets/images/clipart/office-red-map.png';
import officeGreenMapImg from '../../../assets/images/clipart/office-green-map.png';
import GMap from '../../../component/Map/GMap';
import GMapWaypointsDirectionsAndMarker from '../../../component/Map/GMapWaypointsDirectionsAndMarker';
import Selector from '../../../component/Controls/custom/selectors/selectors';
import DateText from '../../../component/Controls/custom/textbox/dateText';
import GridLoader from '../../../component/Controls/loaders/gridLoader';
import { toLocalDate } from '../../../common/common';


function UsersTracking() {

    const dispatch = useDispatch();

    const [pageLoaded, setPageLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    const [start, setStart] = useState(Moment(Moment(new Date())).format('YYYY-MM-DD'));
    const [userId, setUserId] = useState("All");

    const [salesRepAndSalesMangerOptionsValue, setSalesRepAndSalesMangerOptionsValue] = useState();
    const salesRepAndSalesMangerOptionsState = useSelector(selectSalesRepAndSalesMangerOptionsState);
    const salesRepAndSalesMangerOptions = useSelector(selectSalesRepAndSalesMangerOptions);

    const usersLocation = useSelector(selectUsersLocation);
    const usersLocationStatus = useSelector(selectUsersLocationStatus);

    let _salesRepAndSalesMangerOptions = undefined;

    useEffect(() => {
        if (!pageLoaded) {
            dispatch(updateLayout({
                page: 16,
                title: "User Tracking",
                product: "CRM"
            }));
            setPageLoaded(true);
        }
    });

    useEffect(() => {
        if (isObjectArray(salesRepAndSalesMangerOptions)) {
            _salesRepAndSalesMangerOptions = [{ id: 'All', FirstName: 'All', LastName: '' }, { id: 'b9309330-7cec-4f61-abbc-0f2d2a94a8aa', FirstName: 'Tushar', LastName: 'Kothari ' }, ...salesRepAndSalesMangerOptions].map((o) => (
                <option value={o.id}>{o.FirstName + ' ' + o.LastName}</option>
            ));
            setSalesRepAndSalesMangerOptionsValue(_salesRepAndSalesMangerOptions);
            setLoading(false);
        }
    }, [salesRepAndSalesMangerOptions]);

    useEffect(() => {
        if (salesRepAndSalesMangerOptionsState === fetchStatus.IDLE) {
            setLoading(true);
            dispatch(getSalesRepAndSalesMangerOptions({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETSALESREPANDSALESMANAGER",
                payload: {
                    id: "",
                    // data: JSON.stringify({ "locationId": locationId })
                    // data: JSON.stringify({ "locationId": locationId })
                }
            }));
        }
    }, [salesRepAndSalesMangerOptionsState]);

    const OnClickFilter = async () => {
        console.log('OnClickFilter ->>> ', start, userId);
        if (start != undefined && userId != '') {
            setLoading(true);
            await dispatch(getUsersLocation({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETUSERSLOCATION",
                payload: {
                    id: '',
                    data: JSON.stringify({
                        userId: userId,
                        StartDate: start
                    })
                }
            }));
            setLoading(false);

        }
        else {
            alert('Please select filter')
        }

    };


    useEffect(() => {
        if (userId && start) {
            OnClickFilter();
        }
    }, [userId, start])

    //----- MAP -----
    const GOOGLE_MAP_API_KEY = 'AIzaSyAakzSaZd7t-EBQkMQOu5U3QzhHV4yr1CY';
    // load google map script
    const loadGoogleMapScript = (callback) => {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            callback();
        } else {
            const googleMapScript = document.createElement("script");
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
            window.document.body.appendChild(googleMapScript);
            googleMapScript.addEventListener("load", callback);
        }
    }

    const [loadMap, setLoadMap] = useState(false);

    useEffect(() => {
        loadGoogleMapScript(() => {
            setLoadMap(true)
        });
    }, []);

    const getTrackingType = (trackingType) => {
        if (trackingType === 'login') {
            return 'Login';
        }
        if (trackingType === 'logout') {
            return 'Logout';
        }
        return '';
    }

    const getAppointmentStatus = (status) => {
        if (status === 'Rejected') {
            return 'Rejected';
        } else if (status === 'Done') {
            return 'Completed';
        } else if (status === 'Open') {
            return 'Open';
        } else if (status === null) {
            return 'Open';
        }
        return '';
    }

    const getTitleTextForSingle = (d) => {
        let _title = "";
        if (d.locationCategory === 'user') {
            return d.fullName && d.fullName + ' ' + (d.time && toLocalDate(d.time)) + ' ' + (d.trackingType && getTrackingType(d.trackingType));
        } else {
            return d.leadName && d.leadName + ' ' + (d.time && toLocalDate(d.time)) + ' ' + (getAppointmentStatus(d.status));
        }
    };

    const getIconForSingle = (d) => {
        if (d.trackingType === 'login') {
            return mapMarkLoginImg;
        }
        if (d.trackingType === 'logout') {
            return mapMarkLogoutImg;
        }
        return mapMarkImg;
    }

    const getTitleTextForAllOption = (d) => {
        let _text = "";
        if (d.locationCategory === 'user') {
            _text = `<table>`;
            if (d.fullName)
                _text += `<tr><td>User Name</td><td>: ${d.fullName}</td></tr><tr><td>&nbsp;</td></tr>`;
            if (d.time)
                _text += `<tr><td>Time</td><td>: ${toLocalDate(d.time)}</td></tr>`;
            _text += `</table>`;
            return _text;
        } else {
            _text = `<table>`;
            if (d.leadName)
                _text += `<tr><td>Pipeline</td><td>: ${d.leadName}</td></tr><tr><td>&nbsp;</td></tr>`;
            if (d.assignedToName)
                _text += `<tr><td>Assigned</td><td>: ${d.assignedToName}, ${d.assignedTo2Name}</td></tr>`;
            if (d.salesMangerName)
                _text += `<tr><td>Manger</td><td>: ${d.salesMangerName}</td></tr>`;
            if (d.assignedToName || d.assignedTo2Name || d.salesMangerName)
                _text += `<tr><td>&nbsp;</td></tr>`;
            if (d.time)
                _text += `<tr><td>Date</td><td>: ${toLocalDate(d.time)}</td></tr>`;
            if (d.status)
                _text += `<tr><td>Status</td><td>: ${getAppointmentStatus(d.status)}</td></tr>`;
            _text += `</table>`;
            return _text;
        }
    }

    const [markerList, setMarkerList] = useState(undefined);

    useEffect(() => {
        if (usersLocation) {

            if (userId == "All") {
                let _usersLocation = usersLocation.map((d, index) => {
                    return {
                        lat: parseFloat(d.latitude),
                        lng: parseFloat(d.longitude),
                        // icon: d.locationCategory === 'user' ? avatarMapImg : officeMapImg,
                        icon: d.locationCategory === 'user' ? avatarMapImg : d.status === 'Done' ? officeGreenMapImg : d.status === 'Rejected' ? officeRedMapImg : officeBlueMapImg,
                        // title: (d.locationCategory === 'user' ? d.fullName && d.fullName : d.leadName && d.leadName) + ' ' + d.time,
                        title: getTitleTextForAllOption(d)
                        //label: `${index + 1}`
                    };
                });
                setMarkerList(_usersLocation);
            } else {
                let i = 0;
                let _usersLocation = usersLocation.map((d, index) => {
                    d.locationCategory === 'user' && i++;
                    return {
                        lat: parseFloat(d.latitude),
                        lng: parseFloat(d.longitude),
                        icon: d.locationCategory === 'user' ? getIconForSingle(d) : d.status === 'Done' ? officeGreenMapImg : d.status === 'Rejected' ? officeRedMapImg : officeBlueMapImg,
                        title: getTitleTextForSingle(d),
                        locationCategory: d.locationCategory
                        // ,label: `${index + 1}`
                        , label: `${d.locationCategory === 'user' ? i : ''}`
                    };
                });
                setMarkerList(_usersLocation);
            }

        }
    }, [usersLocation]);

    const onUsersChange = (e) => {
        // e.preventdefault();
        setUserId(e.target.value)
    }

    const onStartChange = (e) => {
        // e.preventdefault();
        setStart(e.target.value)
    }

    return (
        <>
            <div className="filter-bar p-3">
                <div className="filter m-0 p-0">
                    <Selector
                        id="drpusers"
                        name="drpusers"
                        SelectorClasses="form-select"
                        value={userId}
                        options={
                            <>
                                {salesRepAndSalesMangerOptionsValue}
                            </>
                        }
                        onChange={(e) => onUsersChange(e)}
                    />
                </div>

                <div className="filter m-0 p-0 ms-3">
                    <DateText
                        id="txtStartDate"
                        name="txtStartDate"
                        DateTextClasses="form-control"
                        placeholder="Start Date"
                        value={Moment(start).format('YYYY-MM-DD')}
                        selected={start}
                        onChange={(e) => onStartChange(e)} />
                </div>

            </div>

            {(usersLocationStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <GridLoader />
                </div>}

            {/* <div className="row px-3 pt-3">
                <div className="card map-info-card">
                    <p className="mb-0 me-3"><img src={officeBlueMapImg} className="map-info-icon" alt='not found icon' /> Appointment Open</p>
                    <p className="mb-0 me-3"><img src={officeGreenMapImg} className="map-info-icon" alt='not found icon' /> Appointment Completed</p>
                    <p className="mb-0 me-3"><img src={officeRedMapImg} className="map-info-icon" alt='not found icon' /> Appointment Rejected</p>
                    {userId == "All" ?
                        <>
                            <p className="mb-0 me-3"><img src={avatarMapImg} className="map-info-icon" alt='not found icon' /> User Last Location</p>
                        </> :
                        <>
                            <p className="mb-0 me-3"><img src={mapMarkLoginImg} className="map-info-icon" alt='not found icon' /> User Login</p>
                            <p className="mb-0 me-3"><img src={mapMarkLogoutImg} className="map-info-icon" alt='not found icon' /> User Logout</p>
                            <p className="mb-0 me-3"><img src={mapMarkImg} className="map-info-icon" alt='not found icon' /> User Track</p>
                        </>
                    }
                </div>
            </div> */}

            <div className="row px-3 pt-3">
                <div className="col-md-10">
                    <div className="default-root-container dashboard-container p-0 overflow-hidden">
                        {!loadMap ?
                            <div>Loading...</div>
                            :
                            userId == "All" ?
                                <GMap markerList={markerList} />
                                :
                                <GMapWaypointsDirectionsAndMarker markerList={markerList} />
                        }
                    </div>
                </div>

                <div className="col-md-2">
                    <div className="d-flex">
                        <div className="card map-info-card">
                            <div className="">
                                <h5>Map Information</h5>
                                <p className="mb-0"><img src={officeBlueMapImg} className="map-info-icon" alt='not found icon' /> Appointment Open</p>
                                <p className="mb-0"><img src={officeGreenMapImg} className="map-info-icon" alt='not found icon' /> Appointment Completed</p>
                                <p className="mb-0"><img src={officeRedMapImg} className="map-info-icon" alt='not found icon' /> Appointment Rejected</p>
                                {userId == "All" ?
                                    <>
                                        <p className="mb-0"><img src={avatarMapImg} className="map-info-icon" alt='not found icon' /> User Last Location</p>
                                    </> :
                                    <>
                                        <p className="mb-0"><img src={mapMarkLoginImg} className="map-info-icon" alt='not found icon' /> User Login</p>
                                        <p className="mb-0"><img src={mapMarkLogoutImg} className="map-info-icon" alt='not found icon' /> User Logout</p>
                                        <p className="mb-0"><img src={mapMarkImg} className="map-info-icon" alt='not found icon' /> User Track</p>
                                    </>
                                }


                            </div>
                            {/* <div className="ms-5">
                                <p className="mb-0"><img src={officeBlueMapImg} className="map-info-icon" alt='not found icon' /> Appointment Open</p>
                                <p className="mb-0"><img src={officeGreenMapImg} className="map-info-icon" alt='not found icon' /> Appointment Completed</p>
                                <p className="mb-0"><img src={officeRedMapImg} className="map-info-icon" alt='not found icon' /> Appointment Rejected</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UsersTracking;