import React, { useEffect, useState } from "react";
import Moment from "moment";
import InputGroup from "../../../../component/Controls/custom/inputGroup/inputGroup";
import Selector from "../../../../component/Controls/custom/selectors/selectors";
import DateText from "../../../../component/Controls/custom/textbox/dateText";
import InputGroupSmall from "../../../../component/Controls/custom/inputGroup/inputGroup";
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';

function ChartFilter(props) {
  const [locationOptionsValue, setLocationOptionsValue] = useState();

  const [start, setStart] = useState(props.startDate);
  const [end, setEnd] = useState(props.endDate);

  // const onFilter = (e) => {
  //   // console.log(props.onFilter(props.startDate))
  //   // console.log(props.endDate)
  //   // props.onFilter({'sd':props.startDate, 'ed': props.endDate})
  //   props.onFilter({ 'startDate': start, 'endDate': end })
  // }

  const onChangeDate = (e) => {
    if (e.value) {
      setStart(e.value[0]);
      setEnd(e.value[1]);
      if(props.onFilter){
        props.onFilter({ 'startDate': e.value[0], 'endDate': e.value[1] })
      }
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-end">
        <div className="pe-2">
          {props.showLocation && props.showLocation == 'true' && <InputGroup
            icon={<i className="fa fa-map-marker"></i>}
            control={
              <Selector
                id="drpLocations"
                name="drpLocations"
                SelectorClasses="form-select py-0"
                options={
                  <>
                    {props.locationOptionsValue}
                  </>
                }
                onChange={props.onLocationChange}
              />
            } />
          }
        </div>
        <div className="pe-2">
          {props.showEmployee && props.showEmployee == 'true' && <InputGroup
            icon={<i className="fa fa-user"></i>}
            control={
              <Selector
                id="drpEmployees"
                name="drpEmployees"
                SelectorClasses="form-select py-0"
                options={
                  <>
                    {props.employeeOptionsValue}
                  </>
                }
                onChange={props.onEmployeeChange}
              />
            } />
          }
        </div>
        <div className="pe-2">
          <span className={`btn p-0 px-1 ${props.actBtn === 'today' ? 'dashboard-btn-active' : 'dashboard-btn'}`} onClick={(e) => props.onTodayClicked() && props.onTodayClicked(e)}>1D</span>
        </div>
        <div className="pe-2">
          <span className={`btn p-0 px-1 ${props.actBtn === 'week' ? 'dashboard-btn-active' : 'dashboard-btn'}`} onClick={(e) => props.onWeekClicked() && props.onWeekClicked(e)}>1W</span>
        </div>
        <div className="pe-0">
          <span className={`btn p-0 px-1 ${props.actBtn === 'month' ? 'dashboard-btn-active' : 'dashboard-btn'}`} onClick={(e) => props.onMonthClicked() && props.onMonthClicked(e)}>1M</span>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-1">
        <DateRangePickerComponent id="daterangepicker"
          // startDate={start} 
          // endDate={end}
          onChange={(e) => onChangeDate(e)}
        />
      </div>
      <div className="d-flex justify-content-end">
        <span className="dashboard-date-text pt-2">{props.title}</span>
      </div >
    </div>
  );
}

export default ChartFilter;