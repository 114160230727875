export const sections = {
    LOCATION_DETAILS: 0
};

export const onValueChanged = (location, e, section, value) => {
    let _location = JSON.parse(JSON.stringify(location));
    if (section == sections.LOCATION_DETAILS) {
        return _saveLocationDetails(_location, e, value);
    }
};

const _saveLocationDetails = (location, e, value) => {
    if (e.target.name === "txtLocationName") {
        location.LocationName.Data = e.target.value;
    }
    else if (e.target.name === "txtAddress") {
        location.Address.Data = e.target.value;
    }
    else if (e.target.name === "txtCity") {
        location.City.Data = e.target.value;
    }
    else if (e.target.name === "txtState") {
        location.State.Data = e.target.value;
    }
    else if (e.target.name === "txtPincode") {
        location.Pincode.Data = e.target.value;
    }
    else if (e.target.name === "txtPhone") {
        if (!location.Phone) {
            location["Phone"] = {
                "Id": "txtPhone",
                "Data": ""
            }
        }
        location.Phone.Data = e.target.value;
    }
    else if (e.target.name === "txtEmail") {
        if (!location.Email) {
            location["Email"] = {
                "Id": "txtEmail",
                "Data": ""
            }
        }
        location.Email.Data = e.target.value;
    }
    return location;
};