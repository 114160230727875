import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isObjectArray } from '../../../utilities/utilityFunctions';
// import './css/dataSorter.css';
import { hideMenu, selectJobSorterDetail, setSortItem, toggleAscending, toggleMenu } from './reducerSlices/jobSorterSlice';

function JobSorter(props) {
    const dispatch = useDispatch();
    const jobSorterDetail = useSelector(selectJobSorterDetail);

    const sortByContainerRef = useRef(null);

    useEffect(() => {
        if (jobSorterDetail.open && sortByContainerRef && sortByContainerRef.current) {
            sortByContainerRef.current.focus();
        }
    });

    let _sortByList;
    if (isObjectArray(jobSorterDetail.sortByItems)) {
        if (jobSorterDetail.sortByItems.length > 0) {
            _sortByList = jobSorterDetail.sortByItems.map((sortByItem, index) => (
                <li
                    className="sort-by-menu-item"
                    onClick={(e) => onSortItemClicked(e, sortByItem.value, sortByItem.text)}>
                    {sortByItem.text}
                </li>
            ));
        }
    }

    const onBlur = (e) => {
        dispatch(hideMenu());
    };

    const onSorted = (e) => {
        dispatch(toggleAscending(!jobSorterDetail.ascending));
        props.onSorted && props.onSorted(jobSorterDetail.sortBy, !jobSorterDetail.ascending);
    };

    const onSortItemClicked = (e, value, text) => {
        dispatch(setSortItem({
            sortByText: text,
            sortBy: value,
            open: false
        }));
        props.onSorted && props.onSorted(value, jobSorterDetail.ascending);
    };

    const toggle = () => {
        dispatch(toggleMenu(!jobSorterDetail.open));
    };

    return (
        <div className="sort-by-container">
            <div>
                <span className="sort-by" onClick={(e) => toggle()}>{jobSorterDetail.sortByText}</span>
                {jobSorterDetail.open ? <div className="sort-by-menu"
                    ref={sortByContainerRef}
                    tabIndex={-1}
                    onBlur={(e) => onBlur(e)}>
                    <ul className="sort-by-menu-items">
                        {_sortByList}
                    </ul>
                </div> : <React.Fragment></React.Fragment>}
            </div>
            <span className="sort-button" onClick={(e) => onSorted(e)}><i className={jobSorterDetail.ascending ? "fa fa-chevron-down" : "fa fa-chevron-up"}></i></span>
        </div>
    );
}

export default JobSorter;