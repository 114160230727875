import React, { useEffect, useRef, useState } from 'react';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import '../../Demo/css/demo.css'
// import './css/autoComplete.css';


function AutoComplete(props) {
    const [onEdit, setOnEdit] = useState(false);
    const [value, setValue] = useState("");
    const [optionsData, setOptionsData] = useState(props.options);
    const [editedOptionsData, setEditedOptionsData] = useState(props.options);
    const [options, setOptions] = useState(props.options);
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);

    useEffect(() => {
        setOptionsData(props.options);
        setEditedOptionsData(props.options);
    }, [props.options]);

    useEffect(() => {
        let _options = [];
        if (isObjectArray(editedOptionsData)) {
            _options = editedOptionsData.map((o, index) => ({
                text: o.text,
                value: o.value,
            }));
        }
        setOptions(_options);
    }, [editedOptionsData]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
        }
        else {
            setInvalid(false);
        }

    }, [value, props.required, edited, props.submited, props.disabled]);


    const onChange = (e) => {
        const _value = e.target.value;
        setValue(_value);
        let _optionData = [];
        if (isObjectArray(optionsData)) {
            if (_value === null) {
                _optionData = optionsData;
            }
            else {
                _optionData = optionsData.filter(o => o.text.toLowerCase().includes(_value.toLowerCase()) || !_value);
            }
        }
        setEditedOptionsData(_optionData);
        setEdited(true);
        props.onChange && props.onChange(e, _value);
    };


    return (
        <>
            <ComboBoxComponent
                id={props.id}
                showPopupButton={true}
                dataSource={options}
                placeholder={`${props.placeholder ? props.placeholder : ""}`}
                type="text"
                name={props.name}
                value={value}
                className={`${props.AutoCompleteClasses ? props.AutoCompleteClasses : ""} ${invalid && "invalid-input"}`}
                onChange={(e) => onChange(e)}
                //onFocus={(e) => !props.disabled && setOnEdit(true)}
                //onBlur={(e) => !props.disabled && setOnEdit(false)}
                disabled={props.disabled === true}
                formNoValidate={true}
                data-form={props.form ? props.form : ""}
                data-invalid={invalid}
                data-required={props.required ? props.required : false}
                autoComplete="off"
                style={{ height: '37.6px' }}
            />

            {
                invalid && <span className="error-label">
                    <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            }

        </>
    );
}

export default React.memo(AutoComplete);