import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckBox from '../../Controls/custom/checkers/checkbox';

function ClientFilter(props) {
    const dispatch = useDispatch();


    const [open, setOpen] = useState(false);

    const clientFilter = useSelector(props.clientFilterSelector);

    const gfContainerRef = useRef(null);

    let _clients = props.clients;

    let clientsList;
    let isClientsSelected = (clientFilter.value && Array.isArray(clientFilter.value) && clientFilter.value.length > 0);
    if (_clients && Array.isArray(_clients) && _clients.length > 0) {
        clientsList = _clients.map((client, index) => (
            <li key={client.value} className="filter-list-item"
                onClick={(e) => props.onClientChanged && props.onClientChanged(e, client.value, _clients)}>
                <CheckBox
                    id={`ckbClient-${client.value}`}
                    size="Small"
                    checked={isClientsSelected ? clientFilter.value.includes(client.value) : false} />
                <span className={`ms-3 ${isClientsSelected && clientFilter.value.includes(client.value) ? "filter-selected" : ""}`}>{client.text}</span>

            </li>
        ));
    }

    useEffect(() => {
        if (open && gfContainerRef && gfContainerRef.current) {
            gfContainerRef.current.focus();
        }
    });



    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Client</span> :
                <span className="filter-value">{clientFilter.value ? clientFilter.text : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={gfContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    {clientsList}
                </ul>
            </div> : <React.Fragment></React.Fragment>}
        </div>
    );
}

export default ClientFilter;