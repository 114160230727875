import React, { useEffect, useState } from 'react';
import RemoveIconButton from '../../../../component/Controls/buttons/removeIconButton';
import Radio from '../../../../component/Controls/custom/checkers/radio';
import InputGroup from '../../../../component/Controls/custom/inputGroup/inputGroup';
import Selector from '../../../../component/Controls/custom/selectors/selectors';
import NumberText from '../../../../component/Controls/custom/textbox/numberText';
import ShortText from '../../../../component/Controls/custom/textbox/shortText';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import { sections } from '../../services/jobSaver';
import { JobPositionStatus } from '../../../../common/constants.js';
import { toInteger } from '../../../../common/common';
import { useDispatch } from 'react-redux';
import { showFillOrderModal } from '../../../../component/Controls/modals/reducerSlices/fillOrderModalPopUpSlice';
// import '../../../css/positionDetail.css';

function PositionDetail(props) {

    const dispatch = useDispatch();

    const _getPositionText = (id) => {
        if (props.positions) {
            let position = props.positions.find(p => p.value === id);
            if (position && position.text) {
                return position.text;
            }
        }
        return "N/A";
    }
    const [positionDetailsTrs, setPositionDetailsTrs] = useState([]);
    let _positionDetailsTrs;
    const editMode = props.editMode;
    const createMode = props.createMode;
    const positionForm = props.jobForm && props.jobForm.Positions[0];

    let _positionOptions;

    if (isObjectArray(props.positions)) {
        _positionOptions = props.positions.map((position, index) => (
            <option value={position.value}>{position.text}</option>
        ));
    }


    const _getStatusClassBadge = (data) => {
        if (data === "In Progress") {
            return 'c-badge p-1 px-3 bg-gray';
        }
        else if (data === "In Active") {
            return 'c-badge p-1 px-3 status-incomplete';
        }
        else if (data === "Open") {
            return 'c-badge p-1 px-3 bg-light-orange';
        }
        else if (data === "Filled") {
            return 'c-badge p-1 px-3 bg-green';
        }
    }

    const _isQuantityInvalid = (detail) => {
        if (detail) {
            if (detail.Quantity.Data && detail.Filled) {
                return toInteger(detail.Quantity.Data) < toInteger(detail.Filled);
            }
        }
        return false;
    };

    useEffect(() => {
        if (isObjectArray(props.details)) {
            _positionDetailsTrs = props.details.map((detail, index) => (
                <tr key={detail.id}>
                    <td>{index + 1}</td>
                    <td>
                        {props.readonly ?
                            <label className="field-label-value">{detail ? _getPositionText(detail.Position.Data) : ""}</label> :
                            <React.Fragment>

                                <InputGroup
                                    icon={<i className="fa fa-globe"></i>}
                                    control={<Selector
                                        id={`drpPositions-${detail.id}`}
                                        name={`drpPositions-${detail.id}`}
                                        SelectorClasses="form-select"
                                        value={detail ? detail.Position.Data : ""}
                                        options={
                                            <React.Fragment>
                                                {_positionOptions}
                                            </React.Fragment>
                                        } onChange={(e) => props.onValueUpdated && props.onValueUpdated(e, sections.POSITION_DETAILS, null)}
                                        required={positionForm && positionForm.Position.Required}
                                        errorMessage={positionForm && positionForm.Position.Validation}
                                        form={props.form}
                                        submited={props.submited} />
                                    } />

                            </React.Fragment>
                        }
                    </td>
                    <td>
                        {props.readonly ?
                            <label className="field-label-value">{detail ? detail.PositionDescription.Data : ""}</label> :
                            <React.Fragment>
                                <InputGroup
                                    InputGroupClasses="mt-2"
                                    icon={<i className="fa fa-info-circle"></i>}
                                    control={
                                        <ShortText
                                            id={`txtPositionDesc-${detail.id}`}
                                            name={`txtPositionDesc-${detail.id}`}
                                            ShortTextClasses="form-control"
                                            placeholder=""
                                            value={detail && detail.PositionDescription ? detail.PositionDescription.Data : ""}
                                            onChange={(e) => props.onValueUpdated && props.onValueUpdated(e, sections.POSITION_DETAILS, null)}
                                            required={false}
                                            errorMessage={positionForm && positionForm.PositionDescription.Validation}
                                            form={props.form}
                                            submited={props.submited} />} />
                            </React.Fragment>
                        }
                    </td>
                    <td>
                        <div className="form-group">
                            {props.readonly ?
                                <label className="field-label-value">{detail ? detail.JobType.Data : ""}</label> :
                                <React.Fragment>
                                    <div data-form="" data-invalid="">
                                        <Radio id={`rdbtnJTLight-${detail.id}`}
                                            name={`rdbtnJT-${detail.id}`}
                                            label="Light"
                                            value="Light"
                                            onChange={(e, v) => props.onValueUpdated && props.onValueUpdated(e, sections.POSITION_DETAILS, v)}
                                            checked={detail && detail.JobType.Data === "Light"} />
                                        <Radio id={`rdbtnJTHeavy-${detail.id}`}
                                            name={`rdbtnJT-${detail.id}`}
                                            label="Heavy"
                                            value="Heavy"
                                            onChange={(e, v) => props.onValueUpdated && props.onValueUpdated(e, sections.POSITION_DETAILS, v)}
                                            checked={detail && detail.JobType.Data === "Heavy"} />

                                    </div>
                                </React.Fragment>}
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            {props.readonly ?
                                <label className="field-label-value">{detail ? detail.Quantity.Data : ""}</label> :
                                <InputGroup
                                    icon={<i className="fa fa-users"></i>}
                                    control={
                                        <NumberText
                                            maxLength={3}
                                            max="999"
                                            id={`txtQuantity-${detail.id}`}
                                            name={`txtQuantity-${detail.id}`}
                                            NumberTextClasses={`form-control ${_isQuantityInvalid(detail) ? "invalid-quantity" : ""}`}
                                            placeholder=""
                                            value={detail ? detail.Quantity.Data : ""}
                                            onChange={(e) => props.onValueUpdated && props.onValueUpdated(e, sections.POSITION_DETAILS, null)}
                                            required={positionForm && positionForm.Quantity.Required}
                                            errorMessage={positionForm && positionForm.Quantity.Validation}
                                            form={props.form}
                                            submited={props.submited} />} />}
                        </div>
                        {
                            _isQuantityInvalid(detail) ? <span className="badge badge-danger total-position-filled-label">{`Total Filled : ${detail.Filled}`}</span> : <React.Fragment></React.Fragment>
                        }
                    </td>
                    <td>
                        <div className="form-group">
                            {props.readonly ?
                                <label className="field-label-value">{detail ? detail.PayRates.Data : ""}</label> :
                                <InputGroup
                                    icon={<i className="fa fa-dollar"></i>}
                                    control={
                                        <NumberText
                                            min="0.01"
                                            step="0.01"
                                            max="2500"
                                            id={`txtPayRates-${detail.id}`}
                                            name={`txtPayRates-${detail.id}`}
                                            NumberTextClasses="form-control"
                                            placeholder=""
                                            value={detail ? detail.PayRates.Data : ""}
                                            onChange={(e) => props.onValueUpdated && props.onValueUpdated(e, sections.POSITION_DETAILS, null)}
                                            required={positionForm && positionForm.PayRates.Required}
                                            errorMessage={positionForm && positionForm.PayRates.Validation}
                                            form={props.form}
                                            submited={props.submited} />} />}
                        </div>
                    </td>
                    {/* <td></td>
                    <td></td> */}
                    {props.readonly && <td>
                        {/* <div className="c-badge p-1 px-3 bg-blue">Filled</div> */}
                        {/* <label className={_getStatusClassBadge(detail.Status.Data)}>{detail ? detail.Status.Data : ""}</label> */}
                        <div className={_getStatusClassBadge(detail.Status.Data)}>
                            {detail ? detail.Status.Data : ""}
                        </div>
                    </td>}
                    <td>
                        {!props.readonly && detail.Status.Data === JobPositionStatus.NOTFILLED ? <RemoveIconButton
                            title="Remove position"
                            RemoveIconButtonClasses="delete-document-button"
                            RemoveIconClasses="fa-2x"
                            onClick={(e) => props.onDeleteClicked && props.onDeleteClicked(e, detail.id)}
                        /> : <React.Fragment></React.Fragment>}
                    </td>
                    {props.readonly &&
                        <td>
                            <button className="c-btn dark-btn" type="button" onClick={(e) => _dispatch(e, detail)}>Dispatch</button>
                        </td>
                    }
                </tr>
            ));
            setPositionDetailsTrs(_positionDetailsTrs);
        }
        else {
            setPositionDetailsTrs(<tr>
                <td colSpan="7">No records available</td>
            </tr>);
        }
    }, [props.details, props.readonly, props.submited]);


    const _dispatch = (e, position) => {
        dispatch(showFillOrderModal({
            title: <React.Fragment><span>Fill Order</span> <span className="modal-header-name"></span></React.Fragment>,
            job: props.job,
            position: position
        }));
    }

    return (
        <React.Fragment>
            {positionDetailsTrs}
        </React.Fragment>
    );
}

export default PositionDetail;