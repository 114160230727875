import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../../api/client';
import { getAxiosRequestConfig } from '../../../../common/common';
import { isJSON } from '../../../../utilities/utilityFunctions';
import Collection from '../../paginators/collection';
import { toaster } from '../../toasts/toaster';

const initialState = {
    title: "",
    job: undefined,
    position: undefined,
    show: false,
    data: {
        totalItems: 0,
        items: [],
        currentPage: 0,
        status: fetchStatus.IDLE,
        error: null,
        isFiltered: false,
        records: new Collection()
    },
    status: {
        value: "Dispatch ",
        text: "Dispatch"
    },
    statusChange: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    readyForDispatch: []
};

export const getApplicantsForFillOrder = createAsyncThunk('fillOrderModalPopUp/getApplicantsForFillOrder', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', applicantModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let applicants;
        if (isJSON(data)) {
            applicants = JSON.parse(data);
            console.log('fillOrderModalPopUpSlice applicants --->', applicants);
        }
        console.log('fillOrderModalPopUpSlice applicants --->', applicants);

        return {
            page: applicantModel.page,
            applicants: applicants,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const saveJobDispatch = createAsyncThunk('fillOrderModalPopUp/saveJobDispatch', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
        }
        return {
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const fillOrderModalPopUpSlice = createSlice({
    name: 'fillOrderModalPopUp',
    initialState,
    reducers: {
        showFillOrderModal: (state, action) => {
            const { title, job, position, confirmedCallback } = action.payload
            state.title = title;
            state.job = job;
            state.position = position;
            state.show = true;
        },
        closeFillOrderModal: state => {
            state.show = false;
            state.title = undefined;
            state.job = undefined;
            state.position = undefined;
            state.data = {
                totalItems: 0,
                items: [],
                currentPage: 0,
                status: fetchStatus.IDLE,
                error: null,
                isFiltered: false,
                records: new Collection()
            };
            state.readyForDispatch = [];
        },
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateReadyForDispatch: (state, action) => {
            const { applicantId } = action.payload;
            console.log('applicantId====>', applicantId)
            if (applicantId) {
                let _item = state.data.records.Get(state.data.currentPage);
                if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                    let _updatedObjectIndex = _item.records.findIndex(a => a.id === applicantId);

                    let newRecords = _item.records;
                    let oldData = [...state.readyForDispatch];
                    state.readyForDispatch = [...oldData, newRecords[_updatedObjectIndex]];

                    newRecords.splice(_updatedObjectIndex, 1); // Delete

                    _item.records = newRecords;
                    let records = new Collection();
                    records.Add(_item.key, newRecords);
                    state.data.records.Remove(_item.key);
                    records.Concat(state.data.records);
                    state.data.records = records;
                }
            }
        },
        removeReadyForDispatch: (state, action) => {
            const { applicantId,  itemsPerPage} = action.payload;
            if (applicantId) {
                let newArray = state.readyForDispatch.map(obj => ({ ...obj }))
                let _deletedApplicantObj = newArray.find(obj => obj.id === applicantId);

                if (state.data.records.Count > 0) {
                    for (let i = 0; i < state.data.records.Count; i++) {
                        let _item = state.data.records.Get(i);
                        if (_item && _item.records && Array.isArray(_item.records) && _item.records.length < itemsPerPage) {
                            let newRecords = _item.records;
                            newRecords[newRecords.length] = _deletedApplicantObj;

                            _item.records = newRecords;
                            let records = new Collection();
                            records.Add(_item.key, newRecords);
                            state.data.records.Remove(_item.key);
                            records.Concat(state.data.records);
                            state.data.records = records;

                            break;
                        }
                    }
                }
                let newReadyForDispatch = state.readyForDispatch.filter(d => d.id !== applicantId); //Delte From readyForDispatch
                state.readyForDispatch = newReadyForDispatch;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getApplicantsForFillOrder.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        }).addCase(getApplicantsForFillOrder.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.applicants.totalItems;
            let _records = new Collection();
            _records.Add(action.payload.page, action.payload.applicants.items);
            if (!state.data.isFiltered) {
                _records.Concat(state.data.records);
            }
            else {
                state.data.currentPage = 0;
            }
            state.data.isFiltered = false;
            state.data.records = _records;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getApplicantsForFillOrder.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(saveJobDispatch.pending, (state, action) => {
            state.statusChange.status = fetchStatus.LOADING;
        }).addCase(saveJobDispatch.fulfilled, (state, action) => {
            state.statusChange.data = action.payload.applicant;
            state.statusChange.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(saveJobDispatch.rejected, (state, action) => {
            state.statusChange.status = fetchStatus.FAILED;
            state.statusChange.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { showFillOrderModal, closeFillOrderModal,
    updateStatus, updateCurrentPage,
    updateReadyForDispatch, removeReadyForDispatch } = fillOrderModalPopUpSlice.actions;

export default fillOrderModalPopUpSlice.reducer

export const selectFillOrderModalPopUpDetail = state => {
    return {
        title: state.fillOrderModalPopUp.title,
        job: state.fillOrderModalPopUp.job,
        position: state.fillOrderModalPopUp.position,
        show: state.fillOrderModalPopUp.show,
        status: state.fillOrderModalPopUp.status,
    }
}

export const selectStatus = state => state.fillOrderModalPopUp.data.status;

export const selectAllApplicantRecordsFillOrder = state => {
    return state.fillOrderModalPopUp.data.records;
}

export const selectSaveJobDispatchStatus = state => state.fillOrderModalPopUp.statusChange.status;

export const selectCurrentPage = state => {
    return state.fillOrderModalPopUp.data.currentPage;
}

export const selectTotalItems = state => state.fillOrderModalPopUp.data.totalItems;

export const selectReadyForDispatch = state => state.fillOrderModalPopUp.readyForDispatch;