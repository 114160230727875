import React from 'react';

function RouteTrackingCard(props) {

    const _getStatusClass = () => {
        if (props.status === "New") {
            return 'status-new';
        }
        else if (props.status === "Completed") {
            return 'status-completed';
        }
        else if (props.status === "In Progress") {
            return 'status-inprogress';
        }
    }

    const _getNotVisitedCount = () => {
        let _visited = props.visited ? props.visited : 0;
        let _total = props.total ? props.total : 0;
        if (isNaN(_visited) || isNaN(_total)) {
            return 0;
        }
        _visited = parseInt(_visited);
        _total = parseInt(_total);
        if (isNaN(_visited) || isNaN(_total)) {
            return 0;
        }
        else {
            return _total - _visited;
        }
    }

    const getCustomCalendarIcon = (dateString) => {
        // const monthNames = [
        //     'January', 'February', 'March', 'April', 'May', 'June',
        //     'July', 'August', 'September', 'October', 'November', 'December'
        // ];

        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const dateObject = new Date(dateString);
        //const month = dateObject.getMonth() + 1; // Adding 1 because getMonth() returns 0 to 11 (January is 0, February is 1, and so on)
        const monthIndex = dateObject.getMonth();
        const month = monthNames[monthIndex];
        const day = dateObject.getDate();
        return <div className="custom-calendar ">{day} <em>{month}</em></div>
    }


    return (
        <div className={`card route-tracking-card ${props.routeTrackingCardClasses ? props.routeTrackingCardClasses : ""}`} onClick={props.onClick ? props.onClick : null}>
            <div className={`status ${_getStatusClass()}`}>{props.status && props.status}</div>
            <div className="route-tracking-card-container">
                <div className={`wrapper-custom-calendar`}>
                    {getCustomCalendarIcon(props.routeDate ? props.routeDate : "01/01/2021")}
                </div>
                <div className="card-body">
                    <div className="card-title route-tracking-card-title">
                        <span className="route-date">{props.routeDate ? props.routeDate : "01/01/2021"}</span>
                    </div>
                    <div className="row">
                        {/* <div className="col-md-5">
                            <p className="card-text"> <span className="text-primary me-2">Visited</span>{props.visited ? props.visited : 0} </p>
                            <p className="card-text"><span className="text-primary me-2">InProgress</span>{_getNotVisitedCount()}</p>
                            <p className="card-text"><span className="text-primary me-2">Total </span>{props.total ? props.total : 0}</p>
                        </div>
                        <div className="col-md-7">
                            <p className="card-text"><span className="text-primary me-2"><i className="fa fa-id-badge"></i> :</span>{props.name ? props.name : "John Doe"}</p>
                        </div> */}
                        <div className="col-md-5">
                            <div className='route-tracking-card-detail'>
                                <p className=""> <span className="me-2">Visited</span><span className="card-text-dark">{props.visited ? props.visited : 0} </span></p>
                                <p className=""><span className="me-2">InProgress</span><span className="card-text-dark">{_getNotVisitedCount()}</span></p>
                                <p className=""><span className="me-2">Total </span><span className="card-text-dark">{props.total ? props.total : 0}</span></p>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <p className=""><span className="me-2"><i className="fa fa-id-badge"></i> :</span>{props.name ? props.name : "John Doe"}</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                props.footerContent ? <div className="card-footer">
                    {props.footerContent}
                </div> : <></>
            }
        </div >
    );
}

export default RouteTrackingCard;