import React, { useEffect, useState } from 'react';
import Inputmask from "inputmask";

// import './css/timeInput.css'
import { isObjectArray } from '../../../utilities/utilityFunctions';

function TimeInput(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [timeShift, setTimeShift] = useState("AM");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);

    const _getTimeValue = (value) => {
        const hrConst = props.format24HR ? 23 : 12;
        let time = value;
        let timeParts = time.split(':');
        if (isObjectArray(timeParts) && timeParts.length === 2) {
            let hr = timeParts[0];
            let min = timeParts[1];
            let second = timeParts[2];
            if (!isNaN(hr) && parseInt(hr) > hrConst) {
                hr = `${hrConst}`;
            }
            if (!isNaN(min) && parseInt(min) > 59) {
                min = '59';
            }
            if (!isNaN(second) && parseInt(second) > 59) {
                second = '59';
            }
            return `${hr}:${min}`;
        }
        else {
            return "";
        }
    };

    useEffect(() => {
        if (props.value) {
            let _data = props.value.split(' ');
            setValue(_data[0]);
            setTimeShift(_data[1]);
        }
    }, [props.value]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
        }
        else {
            setInvalid(false);
        }
    }, [value, props.required, edited, props.submited, props.disabled]);

    useEffect(() => {
        var ssnInput = document.getElementById(props.id);
        var inputMask = new Inputmask("99:99");
        inputMask.mask(ssnInput);
    });

    const onChange = (e) => {
        setValue(e.target.value);
        setEdited(true);
        let _value = "";
        if (e.target.value && timeShift) {
            _value = e.target.value + " " + timeShift;
        }
        props.onChange && props.onChange(e, _value);
    };

    const onKeyPress = (e) => {
        if (e.target.value) {
            setValue(_getTimeValue(e.target.value));
            //setEdited(true);
            // let _value = "";
            // if (value && timeShift) {
            //     _value = value + " " + timeShift;
            // }
            //props.onChange && props.onChange(e, _value);
        }
    };

    const onTimeShift = (e) => {
        let _timeShift = (timeShift === "PM" ? "AM" : "PM");
        setTimeShift(_timeShift);
        let _value = "";
        if (value && timeShift) {
            _value = value + " " + _timeShift;
        }
        let _e = { ...e };
        _e.target["name"] = props.name;
        props.onChange && props.onChange(_e, _value);
        //setEdited(true);
    };

    return (
        <React.Fragment>
            <div className="time-input">
                <div className={`control ${props.format24HR ? "control-24HR" : ""}`}>
                    <input
                        type="text"
                        id={props.id}
                        name={props.name}
                        value={value}
                        className={`${props.TimeInputClasses ? props.TimeInputClasses : ""} ${invalid && "invalid-input"}`}
                        placeholder={`hh:mm`}
                        onChange={(e) => onChange(e)}
                        disabled={props.disabled === true}
                        formNoValidate={true}
                        data-form={props.form ? props.form : ""}
                        data-invalid={invalid}
                        onKeyPress={(e) => onKeyPress(e)}
                        data-required={props.required ? props.required : false}
                    />
                </div>
                {!props.format24HR && <div className="format-selector">
                    {!props.disabled && <span className="shifter" onClick={(e) => onTimeShift(e)}><i className="fa fa-chevron-left"></i></span>}
                    <span className="shifter-value">{timeShift}</span>
                    {!props.disabled && <span className="shifter" onClick={(e) => onTimeShift(e)}><i className="fa fa-chevron-right"></i></span>}
                </div>}
            </div>
            {
                invalid && <span className="error-label">
                    <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            }
        </React.Fragment>
    );
}

export default TimeInput;