import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectModalPopUpDetail, closeModal } from './reducerSlices/routeTrackingLeadModalPopUpSlice';
import CancelButton from '../custom/buttons/cancelButton';
import SaveButton from '../custom/buttons/saveButton';
import RouteTrackingLeadCard from '../../Card/RouteTrackingLeadCard';
// import './css/routeTrackingLeadModalPopUp.css';
import { createLeadsDetails } from '../../../features/crm/reducerSlices/routeTrackingSlice';
import { accountService } from '../../../features/accounts/services/accountService';

function RouteTrackingLeadModalPopUp() {

    const dispatch = useDispatch();
    const user = accountService.getAuthenticatedUser();

    const modalPopUpDetails = useSelector(selectModalPopUpDetail);
    const [leadRecords, setLeadRecords] = useState(<></>);
    const [selectedLeads, setSelectedLeads] = useState([]);

    const onCloseClicked = () => {
        setSelectedLeads([]);
        dispatch(closeModal());
    };

    const onLeadSelected = (c, e, leadId) => {
        if (c === true) {
            setSelectedLeads((prevSelectedLeads) => [...prevSelectedLeads, leadId]);

        } else if (c === false) {
            setSelectedLeads((prevSelectedLeads) => prevSelectedLeads.filter((id) => id !== leadId));
        }
    }

    useEffect(() => {
        if (modalPopUpDetails) {
            if (modalPopUpDetails.leadsOptions) {
                let notSelectedleadsOptions = modalPopUpDetails.leadsOptions.filter(option => {
                    return !modalPopUpDetails.routeTracking.leadsDetails.some(selectedOption => selectedOption.leadId === option.id);
                });

                let _leadCards = notSelectedleadsOptions.map((lead, index) => (
                    <RouteTrackingLeadCard
                        key={lead.id}
                        leadCardClasses="m-2 route-tracking-lead-modal-pipeline-card"
                        name={lead.LeadName}
                        LeadPhone={lead.LeadPhone}
                        status={lead.Status}
                        statusText={lead.Status}
                        onClick={(c, e) => onLeadSelected(c, e, lead.id)}
                    />
                ));
                setLeadRecords(_leadCards);
            }
        }
    }, [modalPopUpDetails]);

    const onSaveClicked = async (e) => {
        e.preventDefault();
        if(selectedLeads.length > 0){
            await dispatch(createLeadsDetails({selectedLeads:selectedLeads, leadsOptions:modalPopUpDetails.leadsOptions, user:user}));
        }
        onCloseClicked()
    };


    return (
        <>
            {(modalPopUpDetails && modalPopUpDetails.show) ? (<>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">

                    <div className={"modal-dialog modal-dialog-centered route-tracking-lead-modal"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                                <button
                                    type="button"
                                    className="modal-close-button"
                                    onClick={onCloseClicked}>
                                    <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='d-flex flex-wrap'>
                                    {leadRecords}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <CancelButton
                                    CancelButtonClasses="mr-2"
                                    onCancelClicked={() => onCloseClicked()} />
                                <SaveButton
                                    onSaveClicked={(e) => onSaveClicked(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </>) : <></>}
        </>
    )
}

export default RouteTrackingLeadModalPopUp