import {
    updateAppliedDateFilter,
    updateAssemblyLineFilter,
    updateAvailableForOTFilter,
    updateCalledOnFilter,
    updateComputerFilter,
    updateElectricianFilter,
    updateEmploymentTypeFilter,
    updateEthinicityFilter,
    updateFastPacedEnvFilter,
    updateFoodProductionFilter,
    updateForkLiftOperatorFilter,
    updateGenderFilter,
    updateGeneralLaberFilter,
    updateHeavyLiftingFilter,
    updateIndustrialSewingFilter,
    updateLanguageFilter,
    updateLocationFilter,
    updatePreferredClientFilter,
    updateMachineOperatorFilter,
    updateMaintainanceMechanicFilter,
    updateMandateLunchFilter,
    updateMaritalStatusFilter,
    updateNoShowPolicyFilter,
    updateOneDayAssignmentFilter,
    updateReadEnglishFilter,
    updateWriteEnglishFilter,
    updateWriteSpanishFilter,
    updateReadSpanishFilter,
    updateBasicMathSkillsFilter,
    updateCovidVaccinatedFilter,

    updatePackagingLabelingFilter,
    updatePositionFilter,
    updateQualityAssuranceFilter,
    updateRepetitiveMovFilter,
    updateRfScanningFilter,
    updateSanitationFilter,
    updateSecurityFilter,
    updateShiftFilter,
    updateShippingReceivingFilter,
    updateSlipResistantBootsFilter,
    updateStandingLongPeriodFilter,
    updateStillToeBootsFilter,
    updateSupervisorLeadFilter,
    updateTimeClockPolicyFilter,
    updateWeekPayPolicyFilter,
    updateWentOverSafetyProtocolFilter,
    updateWentOverUniformProtocolFilter,
    updateWillingToComuteFilter,
    updateWorkinColdTempFilter,
    updateWorkInHotTempFilter,
    updateWorkStatusFilter,
    updateWorkWeekendsFilter,
    updateFlexibilityFilter,
    updateApparelMachineOperatorFilter,
    updateApparelLoaderFilter,
    updateScreenPrintingFilter,
    updateTicketingLabelingFilter,
    updateProfileProcessStatusFilter,
    updateExperienceDetailsFilter
} from '../../../component/Controls/filters/reducerSlices/filterSilce';

import store from '../../../store';

// export const onSaveNameFilterClicked = async (e, firstName, lastName) => {
//     await store.dispatch(updateNameFilter({
//         firstName: firstName,
//         lastName: lastName
//     }));
// };

export const onSaveAppliedDateFilterClicked = async (e, equal, start, end, filterAction) => {
    await store.dispatch(updateAppliedDateFilter({
        filterAction: filterAction,
        equal: equal,
        start: start,
        end: end,
    }));
};

export const onEmploymentTypeChanged = async (e, value, employmentTypes) => {
    await store.dispatch(updateEmploymentTypeFilter({
        employmentTypes: employmentTypes,
        value: value
    }));
};

export const onEthinicityChanged = async (e, value, ethinicities) => {
    await store.dispatch(updateEthinicityFilter({
        ethinicities: ethinicities,
        value: value
    }));
};

export const onGenderChanged = async (e, value, genders) => {
    await store.dispatch(updateGenderFilter({
        genders: genders,
        value: value
    }));
};

export const onLanguageChanged = async (e, value, languages) => {
    await store.dispatch(updateLanguageFilter({
        languages: languages,
        value: value
    }));
};

export const onLocationChanged = async (e, value, locations) => {
    await store.dispatch(updateLocationFilter({
        locations: locations,
        value: value
    }));
};

export const onPreferredClientChanged = async (e, value, preferredClients) => {
    await store.dispatch(updatePreferredClientFilter({
        preferredClients: preferredClients,
        value: value
    }));
};

export const onMaritalStatusChanged = async (e, value, maritalStatuses) => {
    await store.dispatch(updateMaritalStatusFilter({
        maritalStatuses: maritalStatuses,
        value: value
    }));
};

export const onPositionChanged = async (e, value, positions) => {
    await store.dispatch(updatePositionFilter({
        positions: positions,
        value: value
    }));
};

export const onShiftChanged = async (e, value, shifts) => {
    await store.dispatch(updateShiftFilter({
        shifts: shifts,
        value: value
    }));
};

export const onWorkStatusChanged = async (e, value, workStatuses) => {
    await store.dispatch(updateWorkStatusFilter({
        workStatuses: workStatuses,
        value: value
    }));
};

export const onCalledOnChanged = async (e, value) => {
    await store.dispatch(updateCalledOnFilter(value));
};

export const onFlexibilityChanged = async (e, value) => {
    await store.dispatch(updateFlexibilityFilter(value));
};

export const onAvailableForOTChanged = async (e, value) => {
    await store.dispatch(updateAvailableForOTFilter(value));
};

export const onWillingToComuteChanged = async (e, value) => {
    await store.dispatch(updateWillingToComuteFilter(value));
};

export const onFastPacedEnvChanged = async (e, value) => {
    await store.dispatch(updateFastPacedEnvFilter(value));
};

export const onStandingLongPeriodChanged = async (e, value) => {
    await store.dispatch(updateStandingLongPeriodFilter(value));
};

export const onWorkInHotTempChanged = async (e, value) => {
    await store.dispatch(updateWorkInHotTempFilter(value));
};

export const onRepetitiveMovChanged = async (e, value) => {
    await store.dispatch(updateRepetitiveMovFilter(value));
};

export const onWorkinColdTempChanged = async (e, value) => {
    await store.dispatch(updateWorkinColdTempFilter(value));
};

export const onHeavyLiftingChanged = async (e, value) => {
    await store.dispatch(updateHeavyLiftingFilter(value));
};

export const onWentOverSafetyProtocolChanged = async (e, value) => {
    await store.dispatch(updateWentOverSafetyProtocolFilter(value));
};

export const onWentOverUniformProtocolChanged = async (e, value) => {
    await store.dispatch(updateWentOverUniformProtocolFilter(value));
};

export const onNoShowPolicyChanged = async (e, value) => {
    await store.dispatch(updateNoShowPolicyFilter(value));
};

export const onTimeClockPolicyChanged = async (e, value) => {
    await store.dispatch(updateTimeClockPolicyFilter(value));
};

export const onWeekPayPolicyChanged = async (e, value) => {
    await store.dispatch(updateWeekPayPolicyFilter(value));
};

export const onStillToeBootsChanged = async (e, value) => {
    await store.dispatch(updateStillToeBootsFilter(value));
};

export const onSlipResistantBootsChanged = async (e, value) => {
    await store.dispatch(updateSlipResistantBootsFilter(value));
};

export const onWorkWeekendsChanged = async (e, value) => {
    await store.dispatch(updateWorkWeekendsFilter(value));
};

export const onMandateLunchChanged = async (e, value) => {
    await store.dispatch(updateMandateLunchFilter(value));
};

export const onOneDayAssignmentChanged = async (e, value) => {
    await store.dispatch(updateOneDayAssignmentFilter(value));
};
export const onReadEnglishChanged = async (e, value) => {
    await store.dispatch(updateReadEnglishFilter(value));
};
export const onWriteEnglishChanged = async (e, value) => {
    await store.dispatch(updateWriteEnglishFilter(value));
};
export const onWriteSpanishChanged = async (e, value) => {
    await store.dispatch(updateWriteSpanishFilter(value));
};
export const onReadSpanishChanged = async (e, value) => {
    await store.dispatch(updateReadSpanishFilter(value));
};
export const onBasicMathSkillsChanged = async (e, value) => {
    await store.dispatch(updateBasicMathSkillsFilter(value));
};
export const onCovidVaccinatedChanged = async (e, value) => {
    await store.dispatch(updateCovidVaccinatedFilter(value));
};


export const onGeneralLaberExperienceChanged = async (e, value) => {
    await store.dispatch(updateGeneralLaberFilter(value));
};

export const onShippingReceivingExperienceChanged = async (e, value) => {
    await store.dispatch(updateShippingReceivingFilter(value));
};

export const onPackagingLabelingExperienceChanged = async (e, value) => {
    await store.dispatch(updatePackagingLabelingFilter(value));
};

export const onIndustrialSewingExperienceChanged = async (e, value) => {
    await store.dispatch(updateIndustrialSewingFilter(value));
};

export const onRfScanningExperienceChanged = async (e, value) => {
    await store.dispatch(updateRfScanningFilter(value));
};

export const onAssemblyLineExperienceChanged = async (e, value) => {
    await store.dispatch(updateAssemblyLineFilter(value));
};

export const onFoodProductionExperienceChanged = async (e, value) => {
    await store.dispatch(updateFoodProductionFilter(value));
};

export const onMachineOperatorExperienceChanged = async (e, value) => {
    await store.dispatch(updateMachineOperatorFilter(value));
};

export const onSupervisorLeadExperienceChanged = async (e, value) => {
    await store.dispatch(updateSupervisorLeadFilter(value));
};

export const onMaintainanceMechanicExperienceChanged = async (e, value) => {
    await store.dispatch(updateMaintainanceMechanicFilter(value));
};

export const onElectricianExperienceChanged = async (e, value) => {
    await store.dispatch(updateElectricianFilter(value));
};

export const onSanitationExperienceChanged = async (e, value) => {
    await store.dispatch(updateSanitationFilter(value));
};

export const onQualityAssuranceExperienceChanged = async (e, value) => {
    await store.dispatch(updateQualityAssuranceFilter(value));
};

export const onForkLiftOperatorExperienceChanged = async (e, value) => {
    await store.dispatch(updateForkLiftOperatorFilter(value));
};

export const onSecurityExperienceChanged = async (e, value) => {
    await store.dispatch(updateSecurityFilter(value));
};

export const onComputerExperienceChanged = async (e, value) => {
    await store.dispatch(updateComputerFilter(value));
};

export const onApparelMachineOperatorExperienceChanged = async (e, value) => {
    await store.dispatch(updateApparelMachineOperatorFilter(value));
};

export const onApparelLoaderExperienceChanged = async (e, value) => {
    await store.dispatch(updateApparelLoaderFilter(value));
};

export const onScreenPrintingExperienceChanged = async (e, value) => {
    await store.dispatch(updateScreenPrintingFilter(value));
};

export const onTicketingLabelingExperienceChanged = async (e, value) => {
    await store.dispatch(updateTicketingLabelingFilter(value));
};

export const onProfileProcessStatusChanged = async (e, value) => {
    await store.dispatch(updateProfileProcessStatusFilter(value));
};

export const onExperienceDetailsChanged = async (e, value, expId) => {
    await store.dispatch(updateExperienceDetailsFilter({ value: value, expId, expId }));
};