import React from 'react';

import './css/gridLoader.css';

function GridLoader(props) {
    return (
        <div className="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default GridLoader;