import {
    createSlice
} from '@reduxjs/toolkit';

const initialState = {
    selectedFilter: 0,
    id: "",
    name: "",
    defaultFilter: false,
    where: {
        routeTrackingStatus: {
            value: [],
            text: "All"
        },
        userId: {
            value: [],
            text: "All"
        },
        name: ""
    },
    refreshData: false
};

export const routeTrackingFilterSlice = createSlice({
    name: 'routeTrackingFilter',
    initialState,
    reducers: {
        updateRouteTrackingRefreshData: (state, action) => {
            state.refreshData = action.payload;
        },
        updateRouteTrackingFilter: (state, action) => {
            let { id, name, whereClause, defaultFilter } = action.payload;
            state.id = id;
            state.name = name;
            state.defaultFilter = defaultFilter;
            whereClause = whereClause ? whereClause : {
                routeTrackingStatus: {
                    value: [],
                    text: "All"
                },
                userId: {
                    value: [],
                    text: "All"
                },
                name: ""
            };
            state.where = Object.assign({}, whereClause);
        },
        updateRouteTrackingStatusFilter: (state, action) => {
            const { routeTrackingStatuss, value } = action.payload;
            let routeTrackingStatus = { ...state.where.routeTrackingStatus };
            let text = "All";
            if (!routeTrackingStatus.value.includes(value)) {
                routeTrackingStatus.value = [...routeTrackingStatus.value, value];
            }
            else {
                routeTrackingStatus.value = routeTrackingStatus.value.filter((val) => (val !== value));
            }
            if ((routeTrackingStatuss && Array.isArray(routeTrackingStatuss) && routeTrackingStatus.length > 0) && routeTrackingStatus.value.length > 0) {
                text = "";
                for (let i = 0; i < routeTrackingStatuss.length; i++) {
                    let loc = routeTrackingStatuss[i];
                    if (routeTrackingStatus.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            routeTrackingStatus.text = text;
            // routeTrackingStatus.text = routeTrackingStatus.value.length > 0 ? `(${routeTrackingStatus.value.length}) selected` : "All";
            if (!routeTrackingStatus.value.includes("New")) {
                routeTrackingStatus.value = routeTrackingStatus.value.filter((val) => (val !== "Inprogress"));
                routeTrackingStatus.text = routeTrackingStatus.value.length > 0 ? `(${routeTrackingStatus.value.length}) selected` : "All";
            } else {
                if (!routeTrackingStatus.value.includes("Inprogress")) {
                    routeTrackingStatus.value = [...routeTrackingStatus.value, "Inprogress"]
                }
                routeTrackingStatus.text = routeTrackingStatus.value.length > 0 ? `(${routeTrackingStatus.value.length - 1}) selected` : "All";
            }
            state.where.routeTrackingStatus = routeTrackingStatus;
        },
        updateUserIdFilter: (state, action) => {
            const { userIds, value } = action.payload;
            let userId = { ...state.where.userId };
            let text = "All";
            if (!userId.value.includes(value)) {
                userId.value = [...userId.value, value];
            }
            else {
                userId.value = userId.value.filter((val) => (val !== value));
            }

            if (value == 'All' && !userId.value.includes('All')) {
                userId.value = [];
            } else if (value == 'All') {
                userId.value = userIds.map((d) => d.id);
            } else {
                userId.value = userId.value.filter((val) => (val !== 'All'));
            }

            if (userIds) {
                let _userIds = userIds.map((d) => d.id);
                _userIds = _userIds.filter((d) => (d !== 'All'))
                if (_userIds.length === userId.value.length) {
                    userId.value.push('All')
                }
            }

            if (value == 'All') {
                text = "All";
            } else {
                if ((userIds && Array.isArray(userIds) && userIds.length > 0) && userId.value.length > 0) {
                    text = "";
                    for (let i = 0; i < userIds.length; i++) {
                        let loc = userIds[i];
                        if (userId.value.includes(loc.value) && !userId.value.includes('All')) {
                            text += `${loc.text},`
                        }
                    }
                    text = text.slice(0, -1);
                }
            }
            userId.text = text;
            userId.text = userId.value.length > 0 && !userId.value.includes('All') ? `(${userId.value.length}) selected` : "All";
            state.where.userId = userId;
        },
        updateNameFilter: (state, action) => {
            state.where.name = action.payload;
        },
    }
});

export const { updateRouteTrackingRefreshData, updateRouteTrackingFilter,
    updateRouteTrackingStatusFilter, updateUserIdFilter,
    updateNameFilter, } = routeTrackingFilterSlice.actions;

export default routeTrackingFilterSlice.reducer

export const selectRouteTrackingStatusFilter = state => state.routeTrackingFilter.where.routeTrackingStatus;
export const selectUserIdFilter = state => state.routeTrackingFilter.where.userId;

export const selectFilterId = state => state.routeTrackingFilter.id;

export const selectFilterName = state => state.routeTrackingFilter.name;

export const selectWhereClause = state => state.routeTrackingFilter.where;

export const selectDefaultFilter = state => state.routeTrackingFilter.defaultFilter;

export const selectRefreshData = state => state.routeTrackingFilter.refreshData;

export const selectWhereClauseChanged = state => {
    const whereClause = state.routeTrackingFilter.where;
    if (whereClause.routeTrackingStatus.value.length > 0) {
        return true;
    }
    if (whereClause.userId.value.length > 0) {
        return true;
    }
    return false;
}