import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, GridComponent, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';

import { fetchStatus, tenantId } from '../../../api/client';
import { Operation } from '../../../common/constants';
import { isFormValid } from '../../../component/Controls/services/formService';

import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup'
import ShortText from '../../../component/Controls/custom/textbox/shortText'
import ZipCodeText from '../../../component/Controls/custom/textbox/zipCodeText';
import PhoneText from '../../../component/Controls/custom/textbox/phoneText';
import EmailText from '../../../component/Controls/custom/textbox/emailText';
import ContinueButton from '../../../component/Controls/buttons/continueButton';
import BackButton from '../../../component/Controls/buttons/backButton';
import SetupProgress from '../../../component/Progress/SetupProgress';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';

import { accountService } from '../../accounts/services/accountService';
import { onValueChanged, sections } from '../../setting/services/locationSaver';
import { createLocation, createNewLocation, getAllLocations, getLocationForm, refreshLocationOptions, selectAllLocations, selectCreationStatus, selectLocationById, selectLocationForm, selectLocationFormStatus, selectModificationStatus, selectRemovalStatus, selectStatus, updateLocation, updateSingleData } from '../../locations/reducerSlices/locationSlice';
import GridLoader from '../../../component/Controls/loaders/gridLoader';



function CompanyLocation() {

  const dispatch = useDispatch();
  const history = useHistory();


  const LOCATION_FORM = "Locationform";

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [cFInvalid, setCFInvalid] = useState(false);
  const [cfSubmitted, setCFSubmitted] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  const locationFormStatus = useSelector(selectLocationFormStatus);
  const locationForm = useSelector(selectLocationForm);

  const location = useSelector(selectLocationById);
  const locationCreationStatus = useSelector(selectCreationStatus);
  const locationModificationStatus = useSelector(selectModificationStatus);
  const locationRemovalStatus = useSelector(selectRemovalStatus);
  const locations = useSelector(selectAllLocations);
  const locationsRequestStatus = useSelector(selectStatus);

  useEffect(() => {
    if (!pageLoaded) {
      if (locationFormStatus === fetchStatus.IDLE && accountService.isAuthenticated()) {
        setPageLoaded(true);
        _loadForm();
      }
    }
  });

  const _loadForm = async () => {
    await dispatch(getLocationForm({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETFORMBYTYPE",
      payload: {
        id: "",
        data: "LocationForm"
      }
    }));
  }

  useEffect(() => {
    if (locationsRequestStatus === fetchStatus.IDLE) {
      _loadLocations(0, 0, 10);
    }
  }, [locationsRequestStatus, dispatch]);

  useEffect(() => {
    if (locationCreationStatus === fetchStatus.SUCCEEDED) {
      _loadLocations(0, 0, 10);
    }
  }, [locationCreationStatus]);

  useEffect(() => {
    if (locationModificationStatus === fetchStatus.SUCCEEDED) {
      _loadLocations(0, 0, 10);
    }
  }, [locationModificationStatus]);

  const _loadLocations = async (page, offset, limit, whereClause = undefined) => {
    await dispatch(getAllLocations({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLOCATIONS",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: offset,
            limit: 1000,
            where: whereClause
          })
        }
      }
    }));

  }

  const onAddRecordClicked = (e) => {
    dispatch(createNewLocation());

    setCreateMode(true);
    setEditmode(false);
    setCFSubmitted(false);
  };




  const addActionTemplate = (props) => {
    // return <>
    //   <i className='fa fa-pencil' data-tip='Edit' title='Edit' onClick={() => onLocationSelected(props.id)}></i>
    //   <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
    // </>
  }

  const gridRef = useRef(null);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  }, [locations, locationsRequestStatus]);

  const columns = [
    { field: 'LocationName', headerText: 'Location Name', width: '150' },
    { field: 'Address', headerText: 'Address', width: '200' },
    { field: 'City', headerText: 'City', width: '100' },
    { field: 'State', headerText: 'State', width: '50' },
    { field: 'Pincode', headerText: 'Pincode', width: '100' },
    // {
    //   headerText: 'Action',
    //   template: addActionTemplate,
    //   width: '50px',
    // },
  ];

  const onValueUpdated = (e, section, value) => {
    let _locationObj = Object.assign({}, location);
    let _newLocationObj = onValueChanged(_locationObj, e, section, value);
    dispatch(updateSingleData(_newLocationObj));
  };


  const onSaveClicked = (e, id, created) => {
    e.preventDefault();
    if (isFormValid(LOCATION_FORM)) {
      if (location) {
        if (created) {
          dispatch(createLocation({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVELOCATION",
            payload: {
              id: id,
              data: JSON.stringify({
                location: JSON.stringify(location)
              })
            }
          }));
        }
        else {
          dispatch(updateLocation({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVELOCATION",
            payload: {
              id: id,
              data: JSON.stringify({
                location: JSON.stringify(location)
              })
            }
          }));
        }
        dispatch(refreshLocationOptions());
      }
      setCreateMode(false);
      setEditmode(false);
      setCFInvalid(false);
      setCFSubmitted(false);
    }
    else {
      setCFInvalid(true);
    }
    setCFSubmitted(true);
  };

  const onCancelClicked = (e) => {
    setCreateMode(false);
    setEditmode(false);
    setCFInvalid(false);
    setCFSubmitted(false);
  };

  const onNextClicked = (e) => {
    history.push('companyPositions');
  }

  const onBack = (e) => {
    history.push('companyDetails');
  }





  return (
    <>
      <div className="container ">
        <div className="row">
          <div className="col-md-4 card setup-wizard-left-side d-flex align-items-stretch p-0">
            <SetupProgress percentage="15" getPercentage="30" subTitle="Company Locations" currentPage="companyLocation" />
          </div>
          <div className="col-md-8 card setup-wizard-right-side d-flex align-items-stretch p-0">
            <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn">

              {(locationsRequestStatus === fetchStatus.LOADING) &&
                <div className="setup-wizard-loader">
                  <GridLoader />
                </div>
              }

              <div className="row p-2">
                <div className="col-md-12">
                  <div className="card-left-header">Configure Company Locations</div>
                  <div className="card-left-sub-header">Manage and Set Up Company Branches</div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6 text-end">
                  {createMode ?
                    <>
                      <button className="c-btn dark-btn" type="button" title='Cancle' onClick={(e) => onCancelClicked(e)}>
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                    :
                    <button className="c-btn dark-btn" type="button" title='Create new location' onClick={(e) => onAddRecordClicked(e)}>
                      <i className="fa fa-plus"></i>
                    </button>
                  }
                </div>
              </div>

              {!createMode ?
                <div className="row p-2">
                  <GridComponent ref={gridRef} dataSource={locations} allowPaging={true} allowSorting={true} allowResizing={true} width="100%" toolbar={['Search']} pageSettings={{ pageSize: 7, pageCount: 5 }} rowHeight={50} columns={columns} >
                    <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                  </GridComponent>
                </div>
                :
                <>
                  <div className="row p-2">
                    <div className="col-md-4">
                      <div className="form-group p-0 pt-3">
                        <label className="flyout-label" htmlFor="txtFirstName">Location Name</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{location ? location.LocationName.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-address-card"></i>}
                            control={
                              <ShortText
                                id="txtLocationName"
                                name="txtLocationName"
                                ShortTextClasses="form-control"
                                placeholder="Location Name"
                                value={location ? location.LocationName.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                required={locationForm.LocationName.Required}
                                errorMessage={locationForm.LocationName.Validation}
                                form={LOCATION_FORM}
                                submited={cfSubmitted} />
                            } />
                        }
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group p-0 pt-3">
                        <label className="flyout-label" htmlFor="txtAddress">Address</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{location ? location.Address.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-map-marker"></i>}
                            control={
                              <ShortText
                                id="txtAddress"
                                name="txtAddress"
                                ShortTextClasses="form-control"
                                placeholder="Address"
                                value={location ? location.Address.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                required={locationForm.Address.Required}
                                errorMessage={locationForm.Address.Validation}
                                form={LOCATION_FORM}
                                submited={cfSubmitted} />
                            } />
                        }
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group p-0 pt-3">
                        <label className="flyout-label" htmlFor="txtCity">City</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{location ? location.City.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-map-marker"></i>}
                            control={
                              <ShortText
                                id="txtCity"
                                name="txtCity"
                                ShortTextClasses="form-control"
                                placeholder="City"
                                value={location ? location.City.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                required={locationForm.City.Required}
                                errorMessage={locationForm.City.Validation}
                                form={LOCATION_FORM}
                                submited={cfSubmitted} />
                            } />
                        }
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group p-0 pt-3">
                        <label className="flyout-label" htmlFor="txtState">State</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{location ? location.State.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-map-marker"></i>}
                            control={
                              <ShortText
                                id="txtState"
                                name="txtState"
                                ShortTextClasses="form-control"
                                placeholder="State"
                                value={location ? location.State.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                required={locationForm.State.Required}
                                errorMessage={locationForm.State.Validation}
                                form={LOCATION_FORM}
                                submited={cfSubmitted} />
                            } />
                        }
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group p-0 pt-3">
                        <label className="flyout-label" htmlFor="txtPincode">Zipcode</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{location ? location.Pincode.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-map-pin"></i>}
                            control={
                              <ZipCodeText
                                id="txtPincode"
                                name="txtPincode"
                                ZipCodeTextClasses="form-control"
                                placeholder="Zipcode"
                                value={location ? location.Pincode.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                required={locationForm.Pincode.Required}
                                errorMessage={locationForm.Pincode.Validation}
                                form={LOCATION_FORM}
                                submited={cfSubmitted} />
                            } />
                        }
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group p-0 pt-3">
                        <label className="flyout-label" htmlFor="txtPhone">Phone</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{location && location.Phone ? location.Phone.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-map-marker"></i>}
                            control={
                              <PhoneText
                                id="txtPhone"
                                name="txtPhone"
                                ShortTextClasses="form-control"
                                placeholder="Phone No"
                                PhoneTextClasses="form-control"
                                value={location && location.Phone ? location.Phone.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                required={locationForm.Phone.Required}
                                errorMessage={locationForm.Phone.Validation}
                                form={LOCATION_FORM}
                                submited={cfSubmitted} />
                            } />
                        }
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="form-group p-0 pt-3">
                        <label className="flyout-label" htmlFor="txtEmail">Email Id</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{location && location.Email ? location.Email.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-map-marker"></i>}
                            control={
                              <EmailText
                                id="txtEmail"
                                name="txtEmail"
                                ShortTextClasses="form-control"
                                placeholder="Email Id"
                                EmailTextClasses="form-control"
                                value={location && location.Email ? location.Email.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                // required={locationForm.Email.Required}
                                // errorMessage={locationForm.Email.Validation}
                                form={LOCATION_FORM}
                                submited={cfSubmitted} />
                            } />
                        }
                      </div>
                    </div>

                  </div>
                </>
              }



            </div>
            <div className="card-footer bottom-0 px-4 py-2 w-100">
              <div className="row">
                <div className="col-md-12">
                  {locationCreationStatus === fetchStatus.LOADING || locationModificationStatus === fetchStatus.LOADING
                    ? <div className="float-end my-2"><EllipsisLoader /></div>
                    :
                    <>
                      {(createMode || editMode) ?
                        <>
                          <BackButton onBackClicked={(e) => onCancelClicked(e)} text="Cancle" />
                          <div className="float-end">
                            <ContinueButton onClick={(e) => onSaveClicked(e, location ? location.id : "", createMode)} text="Save" />
                          </div>
                        </>
                        :
                        <>
                          <BackButton onBackClicked={(e) => onBack(e)} />
                          <div className="float-end">
                            <ContinueButton onClick={(e) => onNextClicked(e)} />
                          </div>
                        </>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default CompanyLocation