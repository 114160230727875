export const sections = {
    PERSONAL_DETAILS: 0,
    EMERGENCY_DETAILS: 1,
    EDUCATION_DETAILS: 2,
    EMPLOYMENT_DETAILS: 3,
    REFERENCE_DETAILS: 4,
    OTHER_DETAILS: 5,
    CUSTOMFIELDS_DETAILS: 6,
    EXPERIENCE_DETAILS: 7,
};

export const onValueChanged = (applicant, e, section, value, type = "") => {
    let _applicant = JSON.parse(JSON.stringify(applicant));
    if (section == sections.PERSONAL_DETAILS) {
        return _savePersonalDetails(_applicant, e, value);
    }
    else if (section == sections.EMERGENCY_DETAILS) {
        return _saveEmergencyContactDetails(_applicant, e, value);
    }
    else if (section == sections.EDUCATION_DETAILS) {
        return _saveEducationDetails(_applicant, e, value);
    }
    else if (section == sections.EMPLOYMENT_DETAILS) {
        return _saveEmploymentDetails(_applicant, e, value);
    }
    else if (section == sections.REFERENCE_DETAILS) {
        return _saveReferenceDetails(_applicant, e, value);
    }
    else if (section == sections.OTHER_DETAILS) {
        return _saveOtherDetails(_applicant, e, value);
    }
    else if (section == sections.CUSTOMFIELDS_DETAILS) {
        return _saveCustomFieldsDetails(_applicant, e, value);
    }
    else if (section == sections.EXPERIENCE_DETAILS) {
        return _saveExperienceDetails(_applicant, e, value, type);
    }
};

const _savePersonalDetails = (applicant, e, value) => {
    if (e.target.name === "rdbtnEEligibility") {
        applicant.Eligibility.Data = value;
    }
    else if (e.target.name === "txtDate") {
        applicant.Date.Data = e.target.value;
    }
    else if (e.target.name === "txtFirstName") {
        applicant.FirstName.Data = e.target.value;
    }
    else if (e.target.name === "txtLastName") {
        applicant.LastName.Data = e.target.value;
    }
    else if (e.target.name === "txtMiddleName") {
        applicant.MiddleName.Data = e.target.value;
    }
    else if (e.target.name === "txtOtherLastName") {
        applicant.OtherLastName.Data = e.target.value;
    }
    else if (e.target.name === "txtAddress") {
        applicant.Address.Data = e.target.value;
    }
    else if (e.target.name === "txtCity") {
        applicant.City.Data = e.target.value;
    }
    else if (e.target.name === "txtState") {
        applicant.State.Data = e.target.value;
    }
    else if (e.target.name === "txtZipcode") {
        applicant.ZipCode.Data = e.target.value;
    }
    else if (e.target.name === "drpPositions") {
        applicant.Position.Data = value;
    }
    else if (e.target.name === "drpShifts") {
        applicant.Shift.Data = value;
    }
    else if (e.target.name === "txtBirthDate") {
        applicant.DateOfBirth.Data = e.target.value;
    }
    else if (e.target.name === "txtPhone") {
        applicant.PhoneNumber.Data = e.target.value;
    }
    else if (e.target.name === "txtSSN") {
        applicant.SSN.Data = e.target.value;
    }
    else if (e.target.name === "txtEmail") {
        applicant.EmailAddress.Data = e.target.value;
    }
    else if (e.target.name === "drpEmploymentTypes") {
        applicant.EmploymentType.Data = value;
    }
    else if (e.target.name === "rdbtnFilingStatus") {
        applicant.MaritalStatus.Data = value;
    }
    else if (e.target.name === "rdbtnGender") {
        applicant.Gender.Data = value;
    }
    else if (e.target.name === "rdbtnFilingexempt") {
        if (!applicant.Filingexempt) {
            applicant["Filingexempt"] = {
                "Id": "rdbtnFilingexempt",
                "Data": "",
                "label": "Will you be filing taxes exempt?",
                "type": "radio",
                "required": false,
                "validation": "Please enter Dependent"
            }
        }
        applicant.Filingexempt.Data = value;
    }
    else if (e.target.name === "drpDependentunder17") {
        if (!applicant.Dependentunder17) {
            applicant["Dependentunder17"] = {
                "Id": "txtDependentunder17",
                "Data": "",
                "label": "Dependent of age under 17",
                "type": "text",
                "required": false,
                "validation": "Please enter Dependent"
            }
        }
        applicant.Dependentunder17.Data = e.target.value;
    }
    else if (e.target.name === "drpDependent17Above") {
        if (!applicant.Dependent17Above) {
            applicant["Dependent17Above"] = {
                "Id": "txtDependent17Above",
                "Data": "",
                "label": "Dependent of age 17 or older",
                "type": "text",
                "required": false,
                "validation": "Please enter Dependent"
            }
        }
        applicant.Dependent17Above.Data = e.target.value;
    }
    else if (e.target.name === "drpDependent") {
        applicant.Dependent.Data = e.target.value;
    }
    else if (e.target.name === "txtDeduction") {

        applicant.Deduction.Data = e.target.value;

    }
    else if (e.target.name === "drpEthnicity") {
        applicant.Ethnicity.Data = e.target.value;
    }


    else if (e.target.name === "drpBenefits") {
        applicant.Tax.Data = e.target.value;
    }
    else if (e.target.name === "txtTransport") {
        applicant.Transport.Data = e.target.value;
    }
    else if (e.target.name === "drpLanguage") {
        applicant.Language.Data = value;
    }

    else if (e.target.name === "drpFlexibility") {
        applicant.Flexibility.Data = e.target.value;
    }
    else if (e.target.name === "txtAppRest") {
        applicant.Restriction.Data = e.target.value;
    }
    else if (e.target.name === "txtReviewerName") {
        //applicant.Reviewer.Data = e.target.value;
    }

    else if (e.target.name === "rdbtnPTO") {
        applicant.IsPTO.Data = value;
        applicant.IsPTO.DisplayText = (value === "Y" ? "Yes" : "No");
    }
    else if (e.target.name === "txtPTODate") {
        applicant.PTODate.Data = e.target.value;
    }
    else if (e.target.name === "drpLocations") {
        applicant.Location.Data = e.target.value;
    }
    else if (e.target.name === "txtAlternateEmail") {
        if (!applicant.AlternateEmailAddress) {
            applicant["AlternateEmailAddress"] = {
                "Id": "txtAlternateEmail",
                "Data": "",
                "Label": "Alternate Email Address",
                "Type": "email",
                "Required": true,
                "Validation": "Please select alternate email address",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.AlternateEmailAddress.Data = e.target.value;

    }
    else if (e.target.name === "txtAlternatePhone") {
        if (!applicant.AlternatePhoneNumber) {
            applicant["AlternatePhoneNumber"] = {
                "Id": "txtAlternatePhone",
                "Data": "",
                "Label": "Alternate Phone Number",
                "Type": "text",
                "Required": true,
                "Validation": "Please select alternate phone number",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.AlternatePhoneNumber.Data = e.target.value;

    }

    return applicant;
};

const _saveEmergencyContactDetails = (applicant, e, value) => {
    if (e.target.name === "rdbtnEmerCont") {
        applicant.Sections[1].HavingDetails = (value === "Y");
    }
    else if (e.target.name === "txtContactName") {
        applicant.ContactName.Data = e.target.value;
    }
    else if (e.target.name === "txtRelationship") {
        applicant.Relationship.Data = e.target.value;
    }
    else if (e.target.name === "txtContactAddress") {
        applicant.ContactAddress.Data = e.target.value;
    }
    else if (e.target.name === "txtContactPhone") {
        applicant.ContactPhone.Data = e.target.value;
    }
    return applicant;
};

const _saveEducationDetails = (applicant, e, value) => {
    if (e.target.name === "rdbtnED") {
        applicant.Sections[2].HavingDetails = (value === "Y");
    }
    else if (e.target.name === "rdbtnHS") {
        applicant.HighSchoolCompleted.Data = value;
    }
    else if (e.target.name === "rdbtnD") {
        applicant.Diploma.Data = value;
    }
    else if (e.target.name === "rdbtnGED") {
        applicant.GED.Data = value;
    }
    else if (e.target.name === "txtSchools") {
        applicant.School.Data = e.target.value;
    }
    else if (e.target.name === "txtEDCityState") {
        applicant.CityState.Data = e.target.value;
    }
    else if (e.target.name === "txtEDOthers") {
        applicant.Other.Data = e.target.value;
    }
    return applicant;
};

const _saveEmploymentDetails = (applicant, e, value) => {
    if (e.target.name === "rdbtnCEM" || e.target.name === "rdbtnAONEAge" || e.target.name === "txtOtherEmploymentName" || e.target.name === "lblExplain" || e.target.name === "rdbtnEBAR") {
        applicant.Sections[3].HavingDetails = true;
    }

    if (e.target.name === "rdbtnEMD") {
        applicant.Sections[3].HavingDetails = (value === "Y");
    }
    else if (e.target.name === "rdbtnEMYD") {
        applicant.Sections[3].HavingEmployerDetails = (value === "Y");
    }
    else if (e.target.name === "rdbtnCEM") {
        applicant.CurrentlyEmployed.Data = value;
    }
    else if (e.target.name === "rdbtnAONEAge") {
        applicant.IsOtherEmploymentName.Data = value;
    }
    else if (e.target.name === "txtOtherEmploymentName") {
        applicant.OtherEmploymentName.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnEBAR") {
        applicant.EverTerminated.Data = value;
    }
    else if (e.target.name === "txtExplain") {
        applicant.TerminationReason.Data = e.target.value;
    }

    else if (e.target.name === "txtEmployer1") {
        applicant.Employer1.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerAddress1") {
        applicant.EmployerAddress1.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerPhone1") {
        applicant.EmployerPhone1.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerTelePhone1") {
        applicant.EmployerTelePhone1.Data = e.target.value
    }
    else if (e.target.name === "drpPosition1") {
        applicant.Position1.Data = value
    }
    else if (e.target.name === "txtSupervisor1") {
        applicant.Supervisor1.Data = e.target.value
    }
    else if (e.target.name === "txtDOEFrom1") {
        applicant.DOEFrom1.Data = e.target.value
    }
    else if (e.target.name === "txtDOETo1") {
        applicant.DOETo1.Data = e.target.value
    }
    else if (e.target.name === "txtReasonForLeaving1") {
        applicant.ReasonForLeaving1.Data = e.target.value
    }
    else if (e.target.name === "drpEmployerEmployedBy1") {
        if (!applicant.EmployerEmployedBy1) {
            applicant["EmployerEmployedBy1"] = {
                "Id": "drpEmployerEmployedBy1",
                "Data": [],
                "Label": "Employed By",
                "Type": "select",
                "Required": false,
                "Validation": "Please select employed By",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.EmployerEmployedBy1.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerAgencyName1") {
        if (!applicant.EmployerAgencyName1) {
            applicant["EmployerAgencyName1"] = {
                "Id": "txtEmployerAgencyName1",
                "Data": "",
                "Label": "Employer Agency Name",
                "Type": "text",
                "Required": false,
                "Validation": "Please enter employer Agency Name",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.EmployerAgencyName1.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerNote1") {
        if (!applicant.EmployerNote1) {
            applicant["EmployerNote1"] = {
                "Id": "txtEmployerNote1",
                "Data": "",
                "Label": "Note",
                "Type": "text",
                "Required": false,
                "Validation": "Please enter Note",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.EmployerNote1.Data = e.target.value
    }

    else if (e.target.name === "txtEmployer2") {
        applicant.Employer2.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerAddress2") {
        applicant.EmployerAddress2.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerPhone2") {
        applicant.EmployerPhone2.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerTelePhone2") {
        applicant.EmployerTelePhone2.Data = e.target.value
    }
    else if (e.target.name === "drpPosition2") {
        applicant.Position2.Data = value
    }
    else if (e.target.name === "txtSupervisor2") {
        applicant.Supervisor2.Data = e.target.value
    }
    else if (e.target.name === "txtDOEFrom2") {
        applicant.DOEFrom2.Data = e.target.value
    }
    else if (e.target.name === "txtDOETo2") {
        applicant.DOETo2.Data = e.target.value
    }
    else if (e.target.name === "txtReasonForLeaving2") {
        applicant.ReasonForLeaving2.Data = e.target.value
    }
    else if (e.target.name === "drpEmployerEmployedBy2") {
        if (!applicant.EmployerEmployedBy2) {
            applicant["EmployerEmployedBy2"] = {
                "Id": "drpEmployerEmployedBy2",
                "Data": [],
                "Label": "Employed By",
                "Type": "select",
                "Required": false,
                "Validation": "Please select employed By",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.EmployerEmployedBy2.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerAgencyName2") {
        if (!applicant.EmployerAgencyName2) {
            applicant["EmployerAgencyName2"] = {
                "Id": "txtEmployerAgencyName2",
                "Data": "",
                "Label": "Employer Agency Name",
                "Type": "text",
                "Required": false,
                "Validation": "Please enter employer Agency Name",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.EmployerAgencyName2.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerNote2") {
        if (!applicant.EmployerNote2) {
            applicant["EmployerNote2"] = {
                "Id": "txtEmployerNote2",
                "Data": "",
                "Label": "Note",
                "Type": "text",
                "Required": false,
                "Validation": "Please enter Note",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.EmployerNote2.Data = e.target.value
    }

    else if (e.target.name === "txtEmployer3") {
        applicant.Employer3.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerAddress3") {
        applicant.EmployerAddress3.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerPhone3") {
        applicant.EmployerPhone3.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerTelePhone3") {
        applicant.EmployerTelePhone3.Data = e.target.value
    }
    else if (e.target.name === "drpPosition3") {
        applicant.Position3.Data = value
    }
    else if (e.target.name === "txtSupervisor3") {
        applicant.Supervisor3.Data = e.target.value
    }
    else if (e.target.name === "txtDOEFrom3") {
        applicant.DOEFrom3.Data = e.target.value
    }
    else if (e.target.name === "txtDOETo3") {
        applicant.DOETo3.Data = e.target.value
    }
    else if (e.target.name === "txtReasonForLeaving3") {
        applicant.ReasonForLeaving3.Data = e.target.value
    }
    else if (e.target.name === "drpEmployerEmployedBy3") {
        if (!applicant.EmployerEmployedBy3) {
            applicant["EmployerEmployedBy3"] = {
                "Id": "drpEmployerEmployedBy3",
                "Data": [],
                "Label": "Employed By",
                "Type": "select",
                "Required": false,
                "Validation": "Please select employed By",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.EmployerEmployedBy3.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerAgencyName3") {
        if (!applicant.EmployerAgencyName3) {
            applicant["EmployerAgencyName3"] = {
                "Id": "txtEmployerAgencyName3",
                "Data": "",
                "Label": "Employer Agency Name",
                "Type": "text",
                "Required": false,
                "Validation": "Please enter employer Agency Name",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.EmployerAgencyName3.Data = e.target.value
    }
    else if (e.target.name === "txtEmployerNote3") {
        if (!applicant.EmployerNote3) {
            applicant["EmployerNote3"] = {
                "Id": "txtEmployerNote3",
                "Data": "",
                "Label": "Note",
                "Type": "text",
                "Required": false,
                "Validation": "Please enter Note",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.EmployerNote3.Data = e.target.value
    }

    return applicant;
};

const _saveReferenceDetails = (applicant, e, value) => {
    applicant.Sections[4].HavingDetails = true;
    if (e.target.name === "rdbtnRFD") {
        applicant.Sections[4].HavingDetails = (value === "Y");
    }
    else if (e.target.name === "txtRefName1") {
        applicant.ReferenceName1.Data = e.target.value;
    }
    else if (e.target.name === "txtRefPhone1") {
        applicant.ReferencePhone1.Data = e.target.value;
    }
    else if (e.target.name === "txtRefName2") {
        applicant.ReferenceName2.Data = e.target.value;
    }
    else if (e.target.name === "txtRefPhone2") {
        applicant.ReferencePhone2.Data = e.target.value;
    }
    return applicant;
};

const _saveOtherDetails = (applicant, e, value) => {


    if (e.target.name === "txtKnowAboutUs") {
        applicant.KnowAboutUs.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnHOMI") {
        applicant.IsMedicalInsurance.Data = value;
    }
    else if (e.target.name === "txtMedicalInsuranceType") {
        applicant.MedicalInsuranceType.Data = e.target.value;
    }


    if (e.target.name === "rdbtnASAPO") {
        applicant.ASAPOrders.Data = value;
    }
    else if (e.target.name === "txtASAPOAvailableDate") {
        applicant.ASAPOAvailableDate.Data = e.target.value;
    }

    else if (e.target.name === "rdbtnExpDate") {
        if (!applicant.ForkliftCertificate) {
            applicant["ForkliftCertificate"] = {
                "Id": "rdbtnExpDate",
                "Data": "",
                "Label": "Forklift certificate",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.ForkliftCertificate.Data = value;

    }
    else if (e.target.name === "txtCertificateExpiryDate") {
        if (!applicant.CertificateExpiryDate) {
            applicant["CertificateExpiryDate"] = {
                "Id": "txtCertificateExpiryDate",
                "Data": "",
                "Label": "Certificate Expiry Date ",
                "Type": "input",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.CertificateExpiryDate.Data = e.target.value;

    }
    else if (e.target.name === "rdbtnCYRE") {
        if (!applicant.ReadEnglish) {
            applicant["ReadEnglish"] = {
                "Id": "rdbtnCYRE",
                "Data": "",
                "Label": "Can you read English",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.ReadEnglish.Data = value;

    }
    else if (e.target.name === "rdbtnCYWE") {
        if (!applicant.WriteEnglish) {
            applicant["WriteEnglish"] = {
                "Id": "rdbtnCYWE",
                "Data": "",
                "Label": "Can you write in English",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.WriteEnglish.Data = value;

    }
    else if (e.target.name === "rdbtnCYWS") {
        if (!applicant.WriteSpanish) {
            applicant["WriteSpanish"] = {
                "Id": "rdbtnCYWS",
                "Data": "",
                "Label": "Can you write in Spanish",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.WriteSpanish.Data = value;

    }
    else if (e.target.name === "rdbtnCYRS") {
        if (!applicant.ReadSpanish) {
            applicant["ReadSpanish"] = {
                "Id": "rdbtnCYRS",
                "Data": "",
                "Label": "Can you read in Spanish",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.ReadSpanish.Data = value;

    }
    else if (e.target.name === "rdbtnBSAS") {
        if (!applicant.BasicMathSkills) {
            applicant["BasicMathSkills"] = {
                "Id": "rdbtnBSAS",
                "Data": "",
                "Label": "Do you have basic math skills",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.BasicMathSkills.Data = value;

    }
    else if (e.target.name === "rdbtnAYCV") {
        if (!applicant.CovidVaccinated) {
            applicant["CovidVaccinated"] = {
                "Id": "rdbtnAYCV",
                "Data": "",
                "Label": "Are you COVID vaccinated ",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.CovidVaccinated.Data = value;

    }

    else if (e.target.name === "txtLastWorkPlace") {
        applicant.LastWorkPlace.Data = e.target.value;
    }

    else if (e.target.name === "rdbtnAFOT") {
        applicant.OTAvailable.Data = value;
    }
    else if (e.target.name === "rdbtnWCOC") {
        applicant.WillingToCommute.Data = value;
    }
    else if (e.target.name === "rdbtnFPENV") {
        applicant.FastPacedEnvironments.Data = value;
    }
    else if (e.target.name === "rdbtnSLPO") {
        applicant.StandingLongPeriod.Data = value;
    }
    else if (e.target.name === "rdbtnCWIH") {
        applicant.HotTempeartureWork.Data = value;
    }
    else if (e.target.name === "rdbtnREPM") {
        applicant.RepetitiveMovements.Data = value;
    }
    else if (e.target.name === "rdbtnCWCT") {
        applicant.ColdTempWork.Data = value;
    }
    else if (e.target.name === "rdbtnHVLF") {
        applicant.HeavyLifting.Data = value;
    }
    else if (e.target.name === "rdbtnUNWI") {
        applicant.SafetyProtocol.Data = value;
    }
    else if (e.target.name === "rdbtnWOUP") {
        applicant.UniformPolicy.Data = value;
    }
    else if (e.target.name === "rdbtnWONC") {
        applicant.NoShowPolicy.Data = value;
    }
    else if (e.target.name === "rdbtnWOTCU") {
        applicant.TimeClockUse.Data = value;
    }
    else if (e.target.name === "rdbtnWPSD") {
        applicant.WeeklyPaySL.Data = value;
    }
    else if (e.target.name === "rdbtnOSTB") {
        applicant.StillBoots.Data = value;
    }
    else if (e.target.name === "rdbtnOSRB") {
        applicant.SlipResistBoots.Data = value;
    }
    else if (e.target.name === "rdbtnCWRW") {
        applicant.WorkWeekend.Data = value;
    }
    else if (e.target.name === "rdbtnMLPN") {
        applicant.Punctuality.Data = value;
    }
    else if (e.target.name === "rdbtnMLPN") {
        applicant.Punctuality.Data = value;
    }
    else if (e.target.name === "rdbtnODAS") {
        if (!applicant.OneDayAssignment) {
            applicant["OneDayAssignment"] = {
                "Id": "rdbtnOneDayAssignment",
                "Data": "",
                "Label": "Available for 1 Day assignment",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.OneDayAssignment.Data = value;
    }
    else if (e.target.name === "rdbtnCAMinimmum") {
        if (!applicant.CAMinimmum) {
            applicant["CAMinimmum"] = {
                "Id": "rdbtnCAMinimmum",
                "Data": "",
                "Label": "Went Over CA Minimum Wage?",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.CAMinimmum.Data = value;
    }
    else if (e.target.name === "rdbtnTemptoHire") {
        if (!applicant.TemptoHire) {
            applicant["TemptoHire"] = {
                "Id": "rdbtnTemptoHire",
                "Data": "",
                "Label": "Went Over all Job Assignments being Temporary (Temp to Hire)?",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.TemptoHire.Data = value;
    }
    else if (e.target.name === "rdbtnAlcoholProhibition") {
        if (!applicant.AlcoholProhibition) {
            applicant["AlcoholProhibition"] = {
                "Id": "rdbtnAlcoholProhibition",
                "Data": "",
                "Label": "Went Over Alcohol and Drug Prohibition?",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.AlcoholProhibition.Data = value;
    }
    else if (e.target.name === "rdbtnHarassmentBullying") {
        if (!applicant.HarassmentBullying) {
            applicant["HarassmentBullying"] = {
                "Id": "rdbtnHarassmentBullying",
                "Data": "",
                "Label": "Went Over Harassment and Bullying Reports?",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.HarassmentBullying.Data = value;
    }
    else if (e.target.name === "rdbtnDirectDeposit") {
        if (!applicant.DirectDeposit) {
            applicant["DirectDeposit"] = {
                "Id": "rdbtnDirectDeposit",
                "Data": "",
                "Label": "Went Over Check Pick Up and Direct Deposit Qualification?",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.DirectDeposit.Data = value;
    }
    else if (e.target.name === "rdbtnClarifiedEmployer") {
        if (!applicant.ClarifiedEmployer) {
            applicant["ClarifiedEmployer"] = {
                "Id": "rdbtnClarifiedEmployer",
                "Data": "",
                "Label": "Clarified to Associate that SBS is the Employer not the Client?",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.ClarifiedEmployer.Data = value;
    }
    else if (e.target.name === "txtIfNotWorkWeekend") {
        if (!applicant.IfNotWorkWeekend) {
            applicant["IfNotWorkWeekend"] = {
                "Id": "txtIfNotWorkWeekend",
                "Data": "",
                "Label": "If Not Why?",
                "Type": "text",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.IfNotWorkWeekend.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnGLLU") {
        applicant.GeneralLaber.Data = value;
    }
    else if (e.target.name === "txtGLLUYearMonthExpr") {
        applicant.GLExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnSHRC") {
        applicant.ShipRec.Data = value;
    }
    else if (e.target.name === "txtSHRCYearMonthExpr") {
        applicant.SRExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnPALA") {
        applicant.PacLab.Data = value;
    }
    else if (e.target.name === "txtPALAYearMonthExpr") {
        applicant.PLExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnINDSE") {
        applicant.IndSew.Data = value;
    }
    else if (e.target.name === "txtINDSEYearMonthExpr") {
        applicant.ISWExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnRFSC") {
        applicant.RFScan.Data = value;
    }
    else if (e.target.name === "txtRFSCYearMonthExpr") {
        applicant.RFSExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnASLI") {
        applicant.AssemblyLine.Data = value;
    }
    else if (e.target.name === "txtASLIYearMonthExpr") {
        applicant.ALExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnFOPRO") {
        applicant.FoodProd.Data = value;
    }
    else if (e.target.name === "txtFOPROYearMonthExpr") {
        applicant.FPExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnMACO") {
        applicant.MacOpe.Data = value;
    }
    else if (e.target.name === "txtMACOYearMonthExpr") {
        applicant.MOExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnSULE") {
        applicant.SupLead.Data = value;
    }
    else if (e.target.name === "txtSULEYearMonthExpr") {
        applicant.SULExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnMAIME") {
        applicant.MainMech.Data = value;
    }
    else if (e.target.name === "txtMAIMEYearMonthExpr") {
        applicant.MaMeExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnELEC") {
        applicant.Electrician.Data = value;
    }
    else if (e.target.name === "txtElecYearMonthExpr") {
        applicant.ElecExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnSANT") {
        applicant.Sanitation.Data = value;
    }
    else if (e.target.name === "txtSANTYearMonthExpr") {
        applicant.SaniExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnQAQC") {
        applicant.QAQC.Data = value;
    }
    else if (e.target.name === "txtQAQCYearMonthExpr") {
        applicant.QAQCExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnFORO") {
        applicant.ForkOpe.Data = value;
    }
    else if (e.target.name === "drpForkOpeType") {
        applicant.ForkOpeType.Data = value;
    }
    else if (e.target.name === "txtFOROYearMonthExpr") {
        applicant.FOExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnSECR") {
        applicant.Security.Data = value;
    }
    else if (e.target.name === "drpSecType") {
        applicant.SecType.Data = value;
    }
    else if (e.target.name === "txtSECRYearMonthExpr") {
        applicant.SecExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnCLCOMP") {
        applicant.CompKnow.Data = value;
    }
    else if (e.target.name === "drpCompKnowType") {
        applicant.CompKnowType.Data = value;
    }
    else if (e.target.name === "txtCLCOMPYearMonthExpr") {
        applicant.CompKnowExperience.Data = e.target.value;
    }
    else if (e.target.name === "rdbtnAPMACO") {
        if (!applicant.AppMacOpe) {
            applicant["AppMacOpe"] = {
                "Id": "rdbtnAPMACO",
                "Data": "",
                "Label": "Apparel Machine Operator",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.AppMacOpe.Data = value;
    }
    else if (e.target.name === "txtAMOYearMonthExpr") {
        if (!applicant.AMOExperience) {
            applicant["AMOExperience"] = {
                "Id": "txtAMOYearMonthExpr",
                "Data": "",
                "Label": "Years / Months Experience",
                "Type": "text",
                "Required": false,
                "Validation": "Please enter Years / Months Experience",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.AMOExperience.Data = e.target.value;;
    }
    else if (e.target.name === "rdbtnAPLO") {
        if (!applicant.AppLoa) {
            applicant["AppLoa"] = {
                "Id": "rdbtnAPLO",
                "Data": "",
                "Label": "Apparel Loader",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.AppLoa.Data = value;
    }
    else if (e.target.name === "txtAppLoaYearMonthExpr") {
        if (!applicant.AppLoaExperience) {
            applicant["AppLoaExperience"] = {
                "Id": "txtAppLoaYearMonthExpr",
                "Data": "",
                "Label": "Years / Months Experience",
                "Type": "text",
                "Required": false,
                "Validation": "Please enter Years / Months Experience",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.AppLoaExperience.Data = e.target.value;;
    }
    else if (e.target.name === "rdbtnSCPR") {
        if (!applicant.ScrPri) {
            applicant["ScrPri"] = {
                "Id": "rdbtnSCPR",
                "Data": "",
                "Label": "Screen Printing",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.ScrPri.Data = value;
    }
    else if (e.target.name === "txtScrPriYearMonthExpr") {
        if (!applicant.ScrPriExperience) {
            applicant["ScrPriExperience"] = {
                "Id": "txtScrPriYearMonthExpr",
                "Data": "",
                "Label": "Years / Months Experience",
                "Type": "text",
                "Required": false,
                "Validation": "Please enter Years / Months Experience",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.ScrPriExperience.Data = e.target.value;;
    }
    else if (e.target.name === "rdbtnTILA") {
        if (!applicant.TicLab) {
            applicant["TicLab"] = {
                "Id": "rdbtnTILA",
                "Data": "",
                "Label": "Ticketing / Labeling",
                "Type": "radio",
                "Required": false,
                "Validation": "Please select this field",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.TicLab.Data = value;
    }
    else if (e.target.name === "txtTicLabYearMonthExpr") {
        if (!applicant.TicLabExperience) {
            applicant["TicLabExperience"] = {
                "Id": "txtTicLabYearMonthExpr",
                "Data": "",
                "Label": "Years / Months Experience",
                "Type": "text",
                "Required": false,
                "Validation": "Please enter Years / Months Experience",
                "SectionID": "F805CAEE-3920-49D5-8AE4-56653F67FBD8",
                "PageID": "",
                "AllowFilter": true,
                "MasterFilter": true
            }
        }
        applicant.TicLabExperience.Data = e.target.value;;
    }

    return applicant;
};

const _saveCustomFieldsDetails = (applicant, e, value) => {
    const { id, name } = e.target;
    console.log('id', id)
    console.log('name', name)
    console.log('value', e.target.value)
    // if (!applicant.CustomFields) {
    //     applicant["CustomFields"] = {}
    // }
    // if(!applicant.CustomFields[name]){
    //     applicant.CustomFields[name] = {
    //         "Id": id,
    //         "Data": "",
    //     }
    // }
    applicant.CustomFields[name].Data = e.target.value;

    return applicant;
};

const _saveExperienceDetails = (applicant, e, value, type) => {
    const { id, name } = e.target;

    if (!applicant.ExperienceDetails) {
        applicant["ExperienceDetails"] = [];
    }

    const index = applicant.ExperienceDetails.findIndex(exp => exp.id === id);

    if (index === -1) {
        applicant.ExperienceDetails.push({
            id,
            ExperienceName: name,
            Type: type,
            Data: type === "Multi Choice" ? [] : '' 
        });
    }

    if (value === null) {
        const newValue = e.target.value;
        applicant.ExperienceDetails = applicant.ExperienceDetails.map(exp => exp.id === id ? { ...exp, Data: newValue } : exp);
    } else {
        applicant.ExperienceDetails = applicant.ExperienceDetails.map(exp => exp.id === id ? { ...exp, Data: value } : exp);
    }

    applicant.ExperienceDetails = applicant.ExperienceDetails.filter(exp => {
        const data = exp.Data;
        return !(Array.isArray(data) && (data.length === 0 || (data.length === 1 && data[0] === ""))) && data !== "";
    });

    return applicant;
};
