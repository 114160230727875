import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import boyFaceImg from './../../../assets/images/clipart/boy-face.svg';
import nophoto from './../../../assets/images/clipart/nophoto.png';
import cardViewImg from './../../../assets/images/clipart/card-view.svg';
import cardViewLightImg from './../../../assets/images/clipart/card-view-light.svg';
import listViewImg from './../../../assets/images/clipart/list-view.svg';
import listViewLightImg from './../../../assets/images/clipart/list-view-light.svg';


import PeopleCard from "../../../component/Card/PeopleCard";
import PeopleHorizontalCard from "../../../component/Card/PeopleHorizontalCard";
import PeopleMoreFilters from "../../../component/Filters/PeopleMoreFilters";
import AddNoteFlyout from "../../../component/Flyout/AddNoteFlyout";
import UploadDownloadDocumentsFlyout from "../../../component/Flyout/UploadDownloadDocumentsFlyout";
import MessageFlyout from "../../../component/Flyout/MessageFlyout";
// import DispatchFlyout from "../../../component/Flyout/DispatchFlyout";
import { useEffect } from "react";
import { updateLayout } from "../../../component/layouts/reducerSlices/layoutSlice";
import { useDispatch, useSelector } from "react-redux";
import Collection from "../../../component/Controls/paginators/collection";
import { getAllApplicants, selectAllApplicantRecords, selectCurrentPage, selectRecentClickedApplicantStatus, selectRemovalStatus, selectStatus, selectTotalItems, updateCurrentPage, updateIsFiltered, updateRecentClickedApplicant, updateRemovalStatus, updateSingleData, updateStatus } from "../../applicant/reducerSlices/applicantSlice";
import { tenantId, fetchStatus } from "../../../api/client";
import { accountService } from "../../accounts/services/accountService";
import { selectCalledOnFilter, selectDefaultFilter, selectEmploymentTypeFilter, selectFilterId, selectPositionFilter, selectProfileProcessStatusFilter, selectRefreshData, selectShiftFilter, selectTotalMoreFilterCount, selectWhereClause, selectWhereClauseChanged, selectWorkStatusFilter, updateFilter, updateLocationFilter, updateNameFilter, updateRefreshData } from "../../../component/Controls/filters/reducerSlices/filterSilce";
import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from "../../locations/reducerSlices/locationSlice";

import { selectApplicantSorterDetail, selectSort } from '../../../component/Controls/dataSorters/reducerSlices/applicantSorterSlice';
import { toLocalDate, _getDateText, _getLocationText, _getNoDataText, _getStatusText, _getTextFromValueList } from "../../../common/common";
import { isObjectArray } from "../../../utilities/utilityFunctions";
import { LanguageData } from "../../applicant/services/masterData";
import LocationFilter from "../../../component/Controls/filters/locationFilter";
import { selectLocationFilter } from "../../../component/Controls/filters/reducerSlices/filterSilce";
import { onCalledOnChanged, onEmploymentTypeChanged, onLocationChanged, onPositionChanged, onProfileProcessStatusChanged, onShiftChanged, onWorkStatusChanged } from "../../reviewer/services/filterHandlers";
import ShiftFilter from "../../../component/Controls/filters/shiftFilter";
import PositionFilter from "../../../component/Controls/filters/positionFilter";
import WorkStatusFilter from "../../../component/Controls/filters/workStatusFilter";
import EmploymentTypeFilter from "../../../component/Controls/filters/employmentTypeFilter";
import CalledOnFilter from "../../../component/Controls/filters/callOnFilter";
import { getUserFilters, removeFilter, removeUserFilter, selectDefaultFilters, selectRemoveFilterId, selectRemoveFilterStatus, selectUserFilters, selectUserFiltersStatus } from "../../reviewer/reducerSlices/reviewerSlice";
import EllipsisLoader from "../../../component/Controls/loaders/ellipsisLoader";
import { FilterType } from "../../../common/constants";
import { showSFModal } from "../../../component/Controls/modals/reducerSlices/saveFilterModalPopUpSlice";
import PageContainer from "../../../component/Controls/paginators/paginator/pageContainer";
import ApplicantSorter from "../../../component/Controls/dataSorters/applicantSorter";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { showChatFlyout } from "../../../component/Flyout/reducerSlices/chatFlyoutSlice";
import ProfileProcessStatusFilter from "../../../component/Controls/filters/profileProcessStatusFilter";
import SearchBar from "../../../component/Controls/searchBars/searchbar";
import { getStatusOptionsAll, selectStatusOptions, selectStatusOptionsStatus } from "../../setting/reducerSlices/statusesSlice";
import { applicantStatusesData } from "../../shared/services/masterData";
import { showPeopleImportModal } from "../../../component/Controls/modals/reducerSlices/peopleImportModalPopUpSlice";
import { getExperienceOptions, selectExperienceOptions, selectExperienceOptionsStatus } from "../../setting/reducerSlices/experienceSlice";
import { getPositionOptions, selectPositionOptions, selectPositionOptionsStatus, } from "../../setting/reducerSlices/positionsSlice";
import { getSkillGroupOptions, selectSkillGroupOptions, selectSkillGroupOptionsStatus } from "../../setting/reducerSlices/skillGroupSlice";

function People() {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = accountService.getAuthenticatedUser();

  const [applyMoreFilters, setApplyMoreFilters] = useState(false);
  const [uploadDownloadDocumentsFlyout, setUploadDownloadDocumentsFlyout] = useState(false);
  const [addNoteFlyout, setAddNoteFlyout] = useState(false);
  const [messageFlyout, setMessageFlyout] = useState(false);
  // const [dispatchFlyout, setDispatchFlyout] = useState(false);

  const [peopleListView, setPeopleListView] = useState(false);

  const [applicantsLoaded, setApplicantsLoaded] = useState(false);

  const [pageLoaded, setPageLoaded] = useState(false);

  const currentPage = useSelector(selectCurrentPage);

  const totalPageItems = useSelector(selectTotalItems);
  const applicants = useSelector(selectAllApplicantRecords);
  const applicantsRequestStatus = useSelector(selectStatus);


  const locationOptions = useSelector(selectLocationOptions);
  const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
  const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);

  const sort = useSelector(selectSort);


  const whereClause = useSelector(selectWhereClause);
  const whereClauseChanged = useSelector(selectWhereClauseChanged);
  const defaultFilter = useSelector(selectDefaultFilter);
  const refreshData = useSelector(selectRefreshData);

  const [appRecords, setAppRecords] = useState(new Collection());
  const [appRecordsHorizontalCard, setAppRecordsHorizontalCard] = useState(new Collection());
  const [dummyCards, setDummyCards] = useState([]);
  const [dummyHorizontalCards, setDummyHorizontalCards] = useState([]);

  const [people_Data, setPeople_Data] = useState('');
  const [people_DataHorizontalCard, setPeople_DataHorizontalCard] = useState('');

  const totalMoreFilterCount = useSelector(selectTotalMoreFilterCount);

  const userFiltersStatus = useSelector(selectUserFiltersStatus);
  const [customFilters, setCustomFilters] = useState([]);
  const _filterId = useSelector(selectFilterId);
  const [filterId, setFilterId] = useState("");
  const userFilters = useSelector(selectUserFilters);
  const defaultFilters = useSelector(selectDefaultFilters);

  const removeFilterStatus = useSelector(selectRemoveFilterStatus);
  const removeFilterId = useSelector(selectRemoveFilterId);

  const [isFiltered, setIsFiltered] = useState(false);

  const applicantRemovalStatus = useSelector(selectRemovalStatus);
  const [searchTerm, setSearchTerm] = useState("");

  const statusOptions = useSelector(selectStatusOptions);
  const statusOptionsStatus = useSelector(selectStatusOptionsStatus);
  
  const skillGroupOptions = useSelector(selectSkillGroupOptions);
  const skillGroupOptionsStatus = useSelector(selectSkillGroupOptionsStatus);

  const experienceOptions = useSelector(selectExperienceOptions);
  const experienceOptionsStatus = useSelector(selectExperienceOptionsStatus);

  const positionOptions = useSelector(selectPositionOptions);
  const positionOptionsStatus = useSelector(selectPositionOptionsStatus);


  useEffect(() => {
    if (statusOptionsStatus === fetchStatus.IDLE) {
      dispatch(getStatusOptionsAll({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSTATUSOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [statusOptionsStatus]);

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 1,
        title: "People"
      }));
      setPageLoaded(true);
    }
  });

  useEffect(() => {
    if (skillGroupOptionsStatus === fetchStatus.IDLE) {
      dispatch(getSkillGroupOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSKILLGROUPOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [skillGroupOptionsStatus]);

  useEffect(() => {
    if (positionOptionsStatus === fetchStatus.IDLE) {
      dispatch(getPositionOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETPOSITIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [positionOptionsStatus]);

  useEffect(() => {
    // if (applicantsRequestStatus === fetchStatus.IDLE || applicantsLoaded === false) {
    if (applicantsRequestStatus === fetchStatus.IDLE) {
      let loc = user ? user.locationId : "";
      let where = JSON.parse(JSON.stringify(whereClause));
      where.location.value.push(loc);
      dispatch(updateLocationFilter({
        locations: locationOptions,
        value: loc
      }));
      dispatch(updateIsFiltered());
      _loadApplicants(0, 0, 10, where, sort);
      setApplicantsLoaded(true);
    }
  }, [applicantsRequestStatus, dispatch, applicantsLoaded]);

  const _loadApplicants = async (page, offset, limit, whereClause, sort) => {
    //setIsItemsLoading(true);
    setIsFiltered(false);
    await dispatch(getAllApplicants({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTUSER",
        operation: "GET",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: offset,
            limit: limit,
            where: whereClause,
            sort: sort
          })
        }
      }
    }));

  }

  useEffect(() => {
    if (refreshData) {
      dispatch(updateIsFiltered());
      _loadApplicants(0, 0, 10, whereClause, sort);
      dispatch(updateRefreshData(false));
    }
  }, [whereClauseChanged, whereClause, refreshData]);

  useEffect(() => {
    if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
      dispatch(getLocationOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLOCATIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({})
        }
      }));
    }
  }, [locationOptionsStatus, locationOptionsRefreshed]);

  const _getLanguageText = (idList) => {
    if (isObjectArray(idList)) {
      let languages = "";
      for (let i = 0; i < idList.length; i++) {
        let language = LanguageData.find(p => p.value === idList[i]);
        if (language && language.text) {
          languages += language.text + ", ";
        }
      }
      languages = languages.trim();
      languages = languages.slice(0, -1);
      return languages;
    }
    return "";
  }

  let applicantCards;
  let _dummyCards = [];
  let _dummyHorizontalCards = [];

  let _appRecords = new Collection();

  let applicantCardsHorizontalCard;
  let _appRecordsHorizontalCard = new Collection();

  useEffect(() => {
    for (let i = 0; i < 10; i++) {
      _dummyCards.push(
        <div className="people-card">
          <div className="people-card-body p-2">
            <ReactPlaceholder showLoadingAnimation={true} type='media' ready={false} rows={3}>
            </ReactPlaceholder>
          </div>
        </div>
      );

      _dummyHorizontalCards.push(
        <div className="people-horizontal-card">
          <div className="people-horizontal-card-body p-2">
            <ReactPlaceholder showLoadingAnimation={true} type='media' ready={false} rows={3}>
            </ReactPlaceholder>
          </div>
        </div>
      );
    }
    setDummyCards(_dummyCards);
    setDummyHorizontalCards(_dummyHorizontalCards);
  }, [])

  useEffect(() => {
    if (applicantsRequestStatus === fetchStatus.SUCCEEDED || applicantsRequestStatus === fetchStatus.DONE) {
      let _records = applicants.Items;
      if (applicants.Items && applicants.Items.length > 0) {
        for (let i = 0; i < applicants.Items.length; i++) {
          let item = applicants.Items[i];
          applicantCards = item.records.map((applicant, index) => (
            <PeopleCard
              dob={''}
              address={'1'}
              lastApplied={'1'}
              onClickNote={(e) => onClickNote(e, applicant.id)}
              onClickDocuments={(e) => onClickDocuments(e, applicant.id)}
              onClickMessage={() => onClickMessage(applicant.id)}
              onChatClicked={(e) => onChatClicked(e, applicant.id)}

              key={applicant.id}
              id={applicant.id}
              profilePhoto={applicant.ImageData}
              fullName={applicant.FirstName + " " + applicant.LastName}
              applicationDate={toLocalDate(applicant.ApplicationDate)}
              email={applicant.Email}
              phone={applicant.PhoneNumber}
              location={_getLocationText(locationOptions, applicant.Location)}
              status={applicant.Status}
              statusText={_getStatusText(statusOptions, applicant.SecondaryStatus)}
              isOpened={applicant.IsOpened}
              dateOfBirth={_getDateText(applicant.DateOfBirth)}
              gender={applicant.Gender}
              onClick={(e) => onClickApplicant(e, applicant.id)}
            />
          ));
          _appRecords.Add(item.key, applicantCards);


          applicantCardsHorizontalCard = item.records.map((applicant, index) => (
            <PeopleHorizontalCard
              skill={applicant.Position ? _getTextFromValueList(positionOptions, applicant.Position) : _getNoDataText()}
              lastCalledIn={applicant.LastCalledIn ? toLocalDate(applicant.LastCalledIn) : _getNoDataText()}
              lastCalledOut={applicant.LastCalled ? toLocalDate(applicant.LastCalled) : _getNoDataText()}
              language={applicant.Language ? _getLanguageText(applicant.Language) : _getNoDataText()}
              cumulativeActiveDays={'12'}
              lastStatus={'All documents and data verify, 10/05/2022, 22:46:31.'}
              onClickNote={(e) => onClickNote(e, applicant.id)}
              onClickDocuments={(e) => onClickDocuments(e, applicant.id)}
              onClickMessage={() => onClickMessage(applicant.id)}
              onChatClicked={(e) => onChatClicked(e, applicant.id)}


              key={applicant.id}
              id={applicant.id}
              profilePhoto={applicant.ImageData}
              fullName={applicant.FirstName + " " + applicant.LastName}
              applicationDate={toLocalDate(applicant.ApplicationDate)}
              email={applicant.Email}
              phone={applicant.PhoneNumber}
              location={_getLocationText(locationOptions, applicant.Location)}
              status={applicant.Status}
              statusText={_getStatusText(statusOptions, applicant.SecondaryStatus)}
              isOpened={applicant.IsOpened}
              dateOfBirth={_getDateText(applicant.DateOfBirth)}
              gender={applicant.Gender}
              // onClick={(c, e) => onApplicantSelected(c, e, applicant.id)}
              onClick={(e) => onClickApplicant(e, applicant.id)}
            />
          ));
          _appRecordsHorizontalCard.Add(item.key, applicantCardsHorizontalCard);

          // setPeople_Data(applicantCards);
          // setPeople_DataHorizontalCard(applicantCardsHorizontalCard);
        }
        setAppRecords(_appRecords);
        setAppRecordsHorizontalCard(_appRecordsHorizontalCard);
        dispatch(updateStatus());
      }
      dispatch(updateStatus());
    }
    if (applicants && applicants.Items.length > 0) {
      let item = applicants.Get(currentPage);
      let _applicants = item ? item.records : [];
      if (_applicants && Array.isArray(_applicants) && _applicants.length > 0) {
        let _applicant = _applicants[0];
        if (_applicant && _applicant.id) {
          // if (firstApplicant && firstApplicant.records) {
          //   dispatch(loadSingleData(firstApplicant.records));
          // }
          // else {
          //   _loadApplicant(_applicant.id, currentPage);
          // }
        }
      }
      else {
        // dispatch(updateSingleData(undefined));
      }
    }
  }, [applicantsRequestStatus, currentPage, statusOptionsStatus]);


  const onClickApplicant = (e, applicant) => {
    dispatch(updateSingleData(undefined));
    dispatch(updateRecentClickedApplicant(applicant));
    history.push('people/details')
  }

  const [clickedDocumentApplicant, setClickedDocumentApplicant] = useState();
  const onClickDocuments = (e, id) => {
    e.stopPropagation();
    setMessageFlyout(false);
    setAddNoteFlyout(false);
    setClickedDocumentApplicant(id);
    setUploadDownloadDocumentsFlyout(true);
  }

  const [clickedMessageApplicant, setClickedMessageApplicant] = useState();
  const onClickMessage = (id) => {
    setUploadDownloadDocumentsFlyout(false);
    setAddNoteFlyout(false);
    setClickedMessageApplicant(id);
    setMessageFlyout(true);
  }

  const [clickedNoteApplicant, setClickedNoteApplicant] = useState();
  const onClickNote = (e, id) => {
    e.stopPropagation();
    setUploadDownloadDocumentsFlyout(false);
    setMessageFlyout(false);
    setClickedNoteApplicant(id);
    setAddNoteFlyout(true);
  }

  const onFilterClicked = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    dispatch(updateFilter({
      id: "",
      name: "",
      whereClause: whereClause,
      defaultFilter: false
    }));
    _loadApplicants(0, 0, 10, whereClause, sort);
  };

  const onSaveFilterClicked = async (e) => {
    e.preventDefault();
    if (whereClauseChanged && !defaultFilter) {
      const user = accountService.getAuthenticatedUser();
      if (user) {
        await dispatch(showSFModal({
          title: <><span>Save Filter For</span> <span className="modal-header-name">{user ? user.firstName + " " + user.lastName : "N/A"}</span></>,
          userId: user.id
        }));
      }
    }
  };

  const onClearFilterClicked = async (e) => {
    e.preventDefault();
    dispatch(updateFilter({
      id: "",
      name: "",
      whereClause: undefined,
      defaultFilter: false
    }));
    dispatch(updateRefreshData(true));
  };


  const onCustomFiterClicked = (e, id, defaultFilter) => {
    e.preventDefault();
    let links = document.getElementsByClassName('menu-sub-item-link-new');
    let filter;
    if (defaultFilter) {
      const locationId = accountService.getLoggedInUserLocationId();
      filter = defaultFilters.find(f => f.id === id);
      filter = JSON.parse(JSON.stringify(filter));
      filter.whereClause.location.value = [locationId];
      filter.whereClause.location.text = "(1) selected";
    }
    else {
      filter = userFilters.find(f => f.id === id);
    }
    dispatch(updateFilter({
      id: filter ? filter.id : "",
      name: filter ? filter.name : "",
      whereClause: filter ? filter.whereClause : undefined,
      defaultFilter: defaultFilter
    }));
    dispatch(updateRefreshData(true));
  };

  const onFiterDeleteClicked = (e, id, filterType) => {
    e.stopPropagation();
    if (id) {
      dispatch(removeFilter({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "REMOVEFILTER",
        payload: {
          id: id,
          data: ""
        }
      }));
    }
  };

  useEffect(() => {
    setFilterId(_filterId);
  });

  useEffect(() => {
    if (removeFilterStatus === fetchStatus.SUCCEEDED) {
      if (removeFilterId) {
        dispatch(removeUserFilter(removeFilterId));
      }
    }
  }, [removeFilterStatus, removeFilterId]);

  useEffect(() => {
    let _customFilters = [], _customUserFilters = [], _customClientFilters = [], _customJobFilters = [];
    if (userFiltersStatus === fetchStatus.SUCCEEDED) {
      if (userFilters && Array.isArray(userFilters) && userFilters.length > 0) {
        for (let i = 0; i < userFilters.length; i++) {
          let filter = userFilters[i];
          if (filter.filterType === FilterType.APPLICANT) {
            // _customFilters.push(<li key={filter.id}
            //   className={`menu-sub-item-link-new ${filterId === filter.id ? "menu-sub-item-link-new-selected" : ""}`}
            //   onClick={(e) => onCustomFiterClicked(e, filter.id, false)}>
            //   <span>{filter.name}</span>
            //   <span className="remove-filter-button"
            //     title="Remove filter"
            //     onClick={(e) => onFiterDeleteClicked(e, filter.id, FilterType.APPLICANT)}>
            //     <i className="fa fa-times" aria-hidden="true"></i>
            //   </span>
            // </li>);
            // _customFilters.push(<button className={`c-btn mx-1 ${filterId === filter.id ? "c-btn-selected" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, filter.id, false)}>{filter.name}</button>);
            _customFilters.push(
              <div className="btn-group mx-1" role="group" aria-label="First group">
                <button type="button" className={`c-btn ${filterId === filter.id ? "" : "c-btn-gray"}`} onClick={(e) => onCustomFiterClicked(e, filter.id, false)}>{filter.name}</button>
                <button type="button" className={`c-btn c-btn-transparent`} onClick={(e) => onFiterDeleteClicked(e, filter.id, FilterType.APPLICANT)} title="Remove filter"><i className="fa fa-trash text-danger" aria-hidden="true"></i></button>
              </div>
            );
          }

        }
        // setCustomFilters(<div className="btn-toolbar d-inline" role="toolbar" aria-label="Toolbar with button groups">{_customFilters}</div>);
        setCustomFilters(_customFilters);
      }
      else {
        setCustomFilters(undefined);
      }
    }

  }, [userFiltersStatus, userFilters, filterId]);

  useEffect(() => {
    const id = accountService.getLoggedInUserId();
    if (id) {
      if (userFiltersStatus === fetchStatus.IDLE) {
        dispatch(getUserFilters({
          client: tenantId,
          serviceType: "TENANTADMIN",
          operation: "GETUSERFILTERS",
          payload: {
            id: id,
            data: ""
          }
        }));
      }
    }
  }, [userFiltersStatus]);

  const onPageChanged = async (p, l) => {
    dispatch(updateCurrentPage(p));
    if (l) {
      await onLoadItems(p);
    }
  };

  const onLoadItems = async (p) => {
    await _loadApplicants(p, p * 10, 10, whereClause, sort);
  };

  const onSorted = async (sortBy, ascending) => {
    await dispatch(updateIsFiltered());
    _loadApplicants(0, 0, 10, whereClause, {
      by: sortBy,
      ascending: ascending
    });
  };

  const onChatClicked = (e, id) => {
    e.stopPropagation();
    dispatch(showChatFlyout({
      title: <><span></span></>,
      applicantId: id,
    }));
  };

  useEffect(() => {
    if (applicantRemovalStatus === fetchStatus.SUCCEEDED) {
      if (applicants && applicants.Items.length > 0) {
        dispatch(updateSingleData(undefined));
      }
      else {
        setAppRecords(undefined);
        dispatch(updateSingleData(undefined));
      }
      dispatch(updateRemovalStatus());
    }
  }, [applicantRemovalStatus]);


  const onSearchClicked = async (e, value) => {
    e.preventDefault();
    if (value) {
      let where = JSON.parse(JSON.stringify(whereClause));
      where.name = value;
      await dispatch(updateNameFilter(value));
      await dispatch(updateIsFiltered());
      _loadApplicants(0, 0, 10, where, sort);
    }
  };

  const onSearchCleared = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    let where = JSON.parse(JSON.stringify(whereClause));
    where.name = "";
    await dispatch(updateNameFilter(""));
    _loadApplicants(0, 0, 10, where, sort);
  };

  const onPeopleImportClicked = async (e) => {
    e.preventDefault();
    await dispatch(showPeopleImportModal({
      title: <><span>Import People</span></>
    }));
  };


  useEffect(() => {
    if (experienceOptionsStatus === fetchStatus.IDLE) {
      dispatch(getExperienceOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETEXPERIENCEOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [experienceOptionsStatus]);


  const filterBtnPlaceholder = (
    <>
      <button className="c-btn mx-1 c-btn-gray" style={{ width: 150, height: 35 }}>
        <ReactPlaceholder showLoadingAnimation={true} type='text' ready={false} rows={1} />
      </button>
      <button className="c-btn mx-1 c-btn-gray" style={{ width: 100, height: 35 }}>
        <ReactPlaceholder showLoadingAnimation={true} type='text' ready={false} rows={1} />
      </button>
      <button className="c-btn mx-1 c-btn-gray" style={{ width: 150, height: 35 }}>
        <ReactPlaceholder showLoadingAnimation={true} type='text' ready={false} rows={1} />
      </button>
      <button className="c-btn mx-1 c-btn-gray" style={{ width: 100, height: 35 }}>
        <ReactPlaceholder showLoadingAnimation={true} type='text' ready={false} rows={1} />
      </button>
      <button className="c-btn mx-1 c-btn-gray" style={{ width: 150, height: 35 }}>
        <ReactPlaceholder showLoadingAnimation={true} type='text' ready={false} rows={1} />
      </button>
    </>
  );

  return (
    <>
      {/* Filter Bar */}
      <div className="filter-bar p-3">
        {statusOptionsStatus === fetchStatus.LOADING ?
          // <span className="px-1 m-0"><EllipsisLoader /></span> 
          filterBtnPlaceholder
          :
          <>
            <button className={`c-btn mx-1 ${filterId === "3343FE89-9898-4CB0-9369-13AC9AD5D40B" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "3343FE89-9898-4CB0-9369-13AC9AD5D40B", true)}>{_getStatusText(statusOptions, applicantStatusesData.InProgress)}</button>
            <button className={`c-btn mx-1 ${filterId === "1C634E95-61BB-4831-9983-873743395DE6" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "1C634E95-61BB-4831-9983-873743395DE6", true)}>{_getStatusText(statusOptions, applicantStatusesData.Available)}</button>
            <button className={`c-btn mx-1 ${filterId === "ACFDF03E-D97D-4F47-9258-156C420E7F6D" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "ACFDF03E-D97D-4F47-9258-156C420E7F6D", true)}>{_getStatusText(statusOptions, applicantStatusesData.Dispatched)}</button>
            <button className={`c-btn mx-1 ${filterId === "5305FC65-B4C4-4C59-9E0D-8405646B1BE6" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "5305FC65-B4C4-4C59-9E0D-8405646B1BE6", true)}>{_getStatusText(statusOptions, applicantStatusesData.Active)}</button>
            <button className={`c-btn mx-1 ${filterId === "55CEF3E8-6F0E-4696-ABDF-3929F71923D9" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "55CEF3E8-6F0E-4696-ABDF-3929F71923D9", true)}>{_getStatusText(statusOptions, applicantStatusesData.InActive)}</button>
            <button className={`c-btn mx-1 ${filterId === "2D3C1DD4-00CF-4A06-A3A5-6600C53644F5" ? "" : "c-btn-gray"}`} type="button" onClick={(e) => onCustomFiterClicked(e, "2D3C1DD4-00CF-4A06-A3A5-6600C53644F5", true)}>{_getStatusText(statusOptions, applicantStatusesData.DNU)}</button>
          </>
        }
        {/* <button className="c-btn c-btn-gray mx-1" type="button">Full Time</button> */}
        {removeFilterStatus === fetchStatus.LOADING ? <span className="px-1 m-0"><EllipsisLoader /></span> : customFilters}
        {<button className="c-btn c-btn-transparent" type="button" onClick={(e) => onSaveFilterClicked(e)} disabled={!(whereClauseChanged && !defaultFilter)}>+ Create New List</button>}
        <div className="float-end d-flex">
          <button type="button" className="c-btn dark-btn position-relative mx-3" onClick={(e) => onPeopleImportClicked(e)}>
            <i className="fa fa-upload me-2" aria-hidden="true"></i>Import
          </button>
          <SearchBar
            id="txtSearch"
            name="txtSearch"
            searchTerm={searchTerm}
            onSearch={(e) => setSearchTerm(e.target.value)}
            onClear={(e, value) => onSearchCleared(e)}
            onSearchClicked={(e, value) => onSearchClicked(e, value)} />
          {isFiltered &&
            <button type="button" className="c-btn dark-btn position-relative ms-3" onClick={(e) => onFilterClicked(e)}>
              <i className="fa fa-search me-2" aria-hidden="true"></i>Search
            </button>
          }
          <button type="button" className="c-btn white-btn position-relative ms-3" onClick={() => setApplyMoreFilters(true)}>
            <i className="fa fa-filter me-2" aria-hidden="true"></i>Filters
            <span className={`position-absolute top-0 start-100 translate-middle ${totalMoreFilterCount > 0 ? 'p-1' : 'p-0'} bg-danger border border-light badge bg-danger`}>
              {/* <span className="visually-hidden">{totalMoreFilterCount > 0 ? totalMoreFilterCount : ''}</span> */}
              <span className="">{totalMoreFilterCount > 0 ? totalMoreFilterCount : ''}</span>
            </span>
          </button>
        </div>
        {/* <div className="float-end">
              <button type="button" className="c-btn dark-btn position-relative me-3" onClick={() => setDispatchFlyout(true)}>
                <i className="fa fa-filter" aria-hidden="true"></i> Dispatch Flyout
                <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                  <span className="visually-hidden"></span>
                </span>
              </button>
            </div> */}
      </div>

      <div className="filter-bar p-3 pt-0">
        <LocationFilter
          locations={locationOptions}
          locationFilterSelector={selectLocationFilter}
          onLocationChanged={(e, v, locations) => { onLocationChanged(e, v, locations); setIsFiltered(true); }}
        />
        <ShiftFilter shiftFilterSelector={selectShiftFilter} onShiftChanged={(e, v, shifts) => { onShiftChanged(e, v, shifts); setIsFiltered(true); }} />
        <PositionFilter positions={positionOptions} positionFilterSelector={selectPositionFilter} onPositionChanged={(e, v, positions) => { onPositionChanged(e, v, positions); setIsFiltered(true); }} />
        <WorkStatusFilter
          statuses={statusOptions && statusOptions.filter(d => d.StatusOfStatus === 'Active')}
          workStatusFilterSelector={selectWorkStatusFilter}
          onWorkStatusChanged={(e, v, workStatuses) => { onWorkStatusChanged(e, v, workStatuses); setIsFiltered(true); }} />
        <EmploymentTypeFilter employmentTypeFilterSelector={selectEmploymentTypeFilter} onEmploymentTypeChanged={(e, v, employmentTypes) => { onEmploymentTypeChanged(e, v, employmentTypes); setIsFiltered(true); }} />
        <CalledOnFilter calledOnFilterSelector={selectCalledOnFilter} onCalledOnChanged={(e, c) => { onCalledOnChanged(e, c); setIsFiltered(true); }} />
        <ProfileProcessStatusFilter profileProcessStatusFilterSelector={selectProfileProcessStatusFilter} onProfileProcessStatusChanged={(e, c) => { onProfileProcessStatusChanged(e, c); setIsFiltered(true); }} />

        {/* <div className="float-end">
              <button className="c-btn white-btn" type="button" onClick={() => setPeopleListView(true)} ><i className="fa fa-list" aria-hidden="true"></i></button>
              <button className="c-btn white-btn" type="button" onClick={() => setPeopleListView(false)}><i className="fa fa-th-large" aria-hidden="true"></i></button>
            </div> */}

        <div className="float-end">
          <div className="sort-btn-group">
            <button className={`c-btn sort-btn-transparent ${peopleListView && 'sort-btn-active'}`} type="button" onClick={() => setPeopleListView(true)} ><img src={peopleListView ? listViewImg : listViewLightImg} /></button>
            <button className={`c-btn sort-btn-transparent ${!peopleListView && 'sort-btn-active'}`} type="button" onClick={() => setPeopleListView(false)}><img src={peopleListView ? cardViewLightImg : cardViewImg} /></button>
          </div>
        </div>


      </div>
      {/* Filter Bar End */}

      {/* Filter Bar */}
      {/* <div className="filter-bar p-3 pt-0">
            <button className="c-btn c-btn-gray mx-1" type="button">Language: English <i className="fa fa-times" aria-hidden="true"></i></button>
            <button className="c-btn c-btn-gray mx-1" type="button">Gender: Male <i className="fa fa-times" aria-hidden="true"></i></button>
            <button className="c-btn c-btn-gray mx-1" type="button">Language: English <i className="fa fa-times" aria-hidden="true"></i></button>
            <button className="c-btn c-btn-gray mx-1" type="button">Repetitive Movements: No <i className="fa fa-times" aria-hidden="true"></i></button>
            <button className="c-btn c-btn-gray mx-1" type="button">Can Work In Cold Tempearture: Yes <i className="fa fa-times" aria-hidden="true"></i></button>
            <button className="c-btn c-btn-gray mx-1" type="button">Are You COVID Vaccinated?: Yes <i className="fa fa-times" aria-hidden="true"></i></button>
            <div className="float-end">
              <button className="c-btn transparent-btn text-dark" type="button">Clear All</button>
            </div>
          </div> */}

      {/* <div className="filter-bar p-3 pt-0">
            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
              <div className="btn-group me-2 btn-group-sm" role="group" aria-label="First group">
                <button type="button" className="c-btn c-btn-gray" onClick={(e) => { e.preventDefault(); alert('select') }}>filtername</button>
                <button type="button" className="c-btn c-btn-gray" onClick={(e) => { e.preventDefault(); alert('Delete') }}><i className="fa fa-times" aria-hidden="true"></i></button>
              </div>
            </div>
          </div> */}

      {/* main section */}

      {/* {
            peopleListView ?
              <div className="right-content p-2">
                <React.Fragment>
                  {people_DataHorizontalCard}
                </React.Fragment>
              </div>
              :
              <div className="right-content p-2">
                <React.Fragment>
                  {people_Data}
                </React.Fragment>
              </div>
          } */}

      <div className="right-content">
        <div className="d-flex w-100 p-3 pb-0">
          <div className="align-self-center">
            <div className="job-order-result-text">Showing {totalPageItems} Results <span className="job-order-result-sub-text">(Based your search)</span> </div>
          </div>
          <div className="ms-auto align-self-center">
            <div className="d-flex">
              <ApplicantSorter
                onSorted={(s, a) => onSorted(s, a)}
              />
            </div>
          </div>
        </div>
      </div>

      <PageContainer
        currentPage={currentPage}
        totalPageItems={totalPageItems}
        pageRecords={!peopleListView ? appRecords : appRecordsHorizontalCard}
        isItemsLoading={applicantsRequestStatus}
        itemsLoadingContent={!peopleListView ? dummyCards : dummyHorizontalCards}
        onPageChanged={(p, l) => onPageChanged(p, l)}
        infinite={true}
        pageRecordsCardClassName={!peopleListView ? 'people-card' : 'people-horizontal-card'}
      />

      {/* {
          peopleData.map((d) => {
            return <PeopleHorizontalCard
              profilePhoto={d.profilePhoto}
              status={d.status}
              fullName={d.fullName}
              phone={d.phone}
              email={d.email}
              dob={d.dob}
              address={d.address}
              lastApplied={d.lastApplied}
              lastUpdate={d.lastUpdate}
              skill={d.skill}
              lastCalledIn={d.lastCalledIn}
              language={d.language}
              lastCalledOut={d.lastCalledOut}
              cumulativeActiveDays={d.cumulativeActiveDays}
              lastStatus={d.lastStatus}
            />
          })
        } */}

      {/* <div className="row p-3 pb-0">
          {
            peopleCardData.map((d) => {
              return <PeopleCard
                profilePhoto={d.profilePhoto}
                status={d.status}
                fullName={d.fullName}
                phone={d.phone}
                email={d.email}
                dob={d.dob}
                address={d.address}
                lastApplied={d.lastApplied}
              />
            })
          }

        </div > */}


      {/* End main section */}


      {/* Apply More Filters */}

      {
        applyMoreFilters ?
          <PeopleMoreFilters
            onClose={() => setApplyMoreFilters(false)}
          />
          :
          <></>
      }

      {
        uploadDownloadDocumentsFlyout ?
          <UploadDownloadDocumentsFlyout
            onClose={() => setUploadDownloadDocumentsFlyout(false)}
            applicantId={clickedDocumentApplicant}
          />
          :
          <></>
      }

      {
        addNoteFlyout ?
          <AddNoteFlyout
            onClose={() => setAddNoteFlyout(false)}
            applicantId={clickedNoteApplicant}
          />
          :
          <></>
      }

      {
        messageFlyout ?
          <MessageFlyout
            onClose={() => setMessageFlyout(false)}
          />
          :
          ''
      }
    </>
  )
}

export default People