export const sections = {
    JOB_DETAILS: 0,
    POSITION_DETAILS: 1,
};

export const onValueChanged = (job, e, section, value) => {
    let _job = JSON.parse(JSON.stringify(job));
    if (section === sections.JOB_DETAILS) {
        return _saveJobDetails(_job, e, value);
    }
    else if (section === sections.POSITION_DETAILS) {
        return _savePositionDetails(_job, e, value);
    }
};

const _saveJobDetails = (job, e, value) => {
    if (e.target.name === "drpLocations") {
        job.Location.Data = e.target.value;
    }
    else if (e.target.name === "txtReqDate") {
        job.RequestDate.Data = e.target.value;
    }
    else if (e.target.name === "drpClients") {
        job.Client.Data = e.target.value;
        if (e.target.value) {
            job.Title.Data = _getJobTitle(value);
        }
        else {
            job.Title.Data = "";
        }
    }
    else if (e.target.name === "txtStartDate") {
        job.StartDate.Data = e.target.value;
    }
    else if (e.target.name === "txtReportTo") {
        job.ReportTo.Data = value;
    }
    else if (e.target.name === "txtAddress") {
        job.Address.Data = e.target.value;
    }
    else if (e.target.name === "txtCity") {
        job.City.Data = e.target.value;
    }
    else if (e.target.name === "txtState") {
        job.State.Data = e.target.value;
    }
    else if (e.target.name === "txtZipCode") {
        job.ZipCode.Data = e.target.value;
    }
    else if (e.target.name === "txtNotes") {
        job.Notes.Data = e.target.value;
    }
    else if (e.target.name === "txtStartTime") {
        if (!job.StartTime) {
            job.StartTime = {
                "Id": "txtStartTime",
                "Data": ""
            };
        }
        job.StartTime.Data = value;
    }
    else if (e.target.name === "txtDressCode") {
        if (!job.DressCode) {
            job.DressCode = {
                "Id": "txtDressCode",
                "Data": ""
            };
        }
        job.DressCode.Data = e.target.value;
    }
    else if (e.target.name === "ckbJobStatus") {
        job.Status.Data = value ? "Inactive" : _getJobStatus(job);
    }
    else if (e.target.name === "txtEndDate") {
        if (!job.EndDate) {
            job.EndDate = {
                "Id": "txtEndDate",
                "Data": ""
            };
        }
        job.EndDate.Data = e.target.value;
    }
    else if (e.target.name === "txtEndTime") {
        if (!job.EndTime) {
            job.EndTime = {
                "Id": "txtEndTime",
                "Data": ""
            };
        }
        job.EndTime.Data = value;
    }
    return job;
};

const _savePositionDetails = (job, e, value) => {
    let nameParts = e.target.name.split('-');
    const name = nameParts[0];
    const id = nameParts[1];
    job.Positions = [].concat(job.Positions);
    const position = job.Positions.find(p => p.id === id);
    if (name === "drpPositions") {
        position.Position.Data = e.target.value;
    }
    else if (name === "txtPositionDesc") {
        if (!position.PositionDescription) {
            position.PositionDescription = {
                "Id": `txtPositionDesc-${id}`,
                "Data": ""
            };
        }
        position.PositionDescription.Data = e.target.value;
    }
    else if (name === "rdbtnJT") {
        position.JobType.Data = value;
    }
    else if (name === "txtQuantity") {
        position.Quantity.Data = e.target.value;
    }
    else if (name === "txtPayRates") {
        position.PayRates.Data = e.target.value;
    }
    return job;
};

const _getJobTitle = (titleData) => {
    if (titleData && titleData.clientName && titleData.clientName.length > 1) {
        let initial = titleData.clientName[0] + titleData.clientName[1];
        return `${initial.toUpperCase()} ${titleData.total + 1} : ${titleData.clientName}`;
    }
    return "";
}

const _getJobStatus = (job) => {
    if (job.TotalFilled === job.TotalQuantity) {
        return "Filled";
    }
    else if (job.TotalFilled > 0) {
        return "Inprogress";
    }
    else {
        return "New";
    }
}