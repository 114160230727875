import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Layout from '../component/layouts/layout';
import { accountService } from '../features/accounts/services/accountService';

const LayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props =>
            accountService.isAuthorized() ? (
                <Layout>
                    <Component {...props} />
                </Layout>
            ) : (<Redirect to={{ pathname: '/login', state: { returnUrl: props.location } }} />)} />
    )
};

export default LayoutRoute;