import React, { useEffect, useState } from 'react';
import nophoto from './../../assets/images/clipart/nophoto.png';
import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import girlFaceImg from './../../assets/images/clipart/girl-face.svg';
import pencilDarkImg from './../../assets/images/clipart/pencil-dark.svg';
import phoneCallFillImg from './../../assets/images/clipart/phone-call-fill.svg';
import { useSelector } from 'react-redux';
import { selectApplicantById } from '../../features/prospectsPeople/reducerSlices/prospectsPeopleSlice';
import { selectLocationOptions } from '../../features/locations/reducerSlices/locationSlice';
import { _getDateText, _getLocationText, toLocalDateOnly } from '../../common/common';
import ReactTooltip from 'react-tooltip';
import { isObjectArray } from '../../utilities/utilityFunctions';
import DataNotFoundCard from './DataNotFoundCard';
import ProspectsPeopleDetailSubCard from './ProspectsPeopleDetailSubCard';

import boy1 from '../../assets/images/blur-photos/boys/1.png';
import boy2 from '../../assets/images/blur-photos/boys/2.png';
import boy3 from '../../assets/images/blur-photos/boys/3.png';
import boy4 from '../../assets/images/blur-photos/boys/4.png';
import boy5 from '../../assets/images/blur-photos/boys/5.png';
import boy6 from '../../assets/images/blur-photos/boys/6.png';
import boy7 from '../../assets/images/blur-photos/boys/7.png';
import boy8 from '../../assets/images/blur-photos/boys/8.png';


import girl1 from '../../assets/images/blur-photos/girls/1.png';
import girl2 from '../../assets/images/blur-photos/girls/2.png';
import girl3 from '../../assets/images/blur-photos/girls/3.png';
import girl4 from '../../assets/images/blur-photos/girls/4.png';
import girl5 from '../../assets/images/blur-photos/girls/5.png';


function ProspectsPeopleDetailCard(props) {

  const applicant = useSelector(selectApplicantById);
  const locationOptions = useSelector(selectLocationOptions);

  const [currentScheduleJob, setCurrentScheduleJob] = useState([]);
  const [completedJobs, setCompletedJobs] = useState([]);
  const [sort, setSort] = useState(false);

  const [photo, setPhoto] = useState(false);

  const getRandomPhotoByGender = (gender) => {
    let photos;

    if (gender === 'Male') {
      photos = [boy1, boy2, boy3, boy4, boy5, boy6, boy7, boy8];
    } else if (gender === 'Female') {
      photos = [girl1, girl2, girl3, girl4, girl5];
    } else {
      return boy1;
    }

    const randomIndex = Math.floor(Math.random() * photos.length);
    return photos[randomIndex];
  };

  const getRandomColor = () => {
    let color = ['#F8E3FB', '#DAD1FB', '#D1D5FB', '#FFE6CE', '#FBE3E3', '#E1E1E1', '#D9F3D0', '#FFD4D4', '#C2E2FF', '#C8ECFC', '#E1CAFF', '#FFE8A3', '#FECAD6',
      '#B2EBF2', '#FFCCBC', '#D7CCC8', '#96dbb1', '#C8E6C9', '#B2DFDB', '#E1BEE7', '#FFAB91', '#90CAF9', '#FFCCBC', '#FFD180', '#ffc1ad', '#EA80FC', 
      '#F8E3FB', '#DAD1FB', '#D1D5FB', '#FFE6CE', '#FBE3E3', '#E1E1E1', '#D9F3D0', '#C2E2FF', '#C8ECFC', '#FFE8A3', '#FECAD6', '#B2EBF2', '#D7CCC8', '#C8E6C9',
      '#B2DFDB', '#E1BEE7', '#90CAF9', '#FFD180', '#E0F7FA', '#B3E5FC', '#81D4FA', '#c7c78f', '#96d4ef'];
    const randomIndex = Math.floor(Math.random() * color.length);
    return color[randomIndex];
  }

  let profileColor = getRandomColor();


  useEffect(() => {
    let appPhoto = applicant && applicant.Gender && applicant.Gender.Data === 'Female' ? getRandomPhotoByGender('Female')
      : applicant && applicant.Gender && applicant.Gender.Data === 'Male' ? getRandomPhotoByGender('Male')
        : nophoto

    setPhoto(appPhoto);
  }, [applicant]);

  useEffect(() => {
    if (props.dispatchDetails) {
      let _completedJobs = props.dispatchDetails.filter((d) => {
        return d.ActiveDate !== '';
      });
      _completedJobs = _completedJobs.reverse();
      setCompletedJobs(_completedJobs);

      let _currentScheduleJob = props.dispatchDetails.filter((d) => {
        return d.ActiveDate === '';
      });
      _currentScheduleJob = _currentScheduleJob.reverse();
      setCurrentScheduleJob(_currentScheduleJob);
    }
  }, [props.dispatchDetails]);

  const _getPositionText = (id) => {
    if (props.positionOptions) {
      let position = props.positionOptions.find(p => p.value === id);
      if (position && position.text) {
        return position.text;
      }
    }
    return "N/A";
  }


  return (
    <div className="card people-detail-card">
      <div className="people-detail-card-header pb-4">

        {/* <div className="people-detail-card-status-top bg-transparent start-0 top-0 m-0 p-0">
          <span className='bg-green text-white py-1 px-2'>2.5 Year Exp.</span>
        </div> */}

        <div className="people-detail-card-status-top bg-transparent end-0 top-0 m-0 p-0 ">
          <span className='bg-green text-white py-1 px-2 add-people-btn' onClick={props.convertToPeopleClicked && props.convertToPeopleClicked}>Hire me</span>
        </div>

        <div className="d-flex justify-content-center pt-3">
          <div className="people-detail-card-profile position-relative" style={{ borderColor: props.profileColor }}>
            <div className="people-detail-card-profile-box" style={{ backgroundColor: props.profileColor }}>
              <img className="" src={photo}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-2">
          <div className="people-detail-card-name">{applicant ? applicant.FirstName.Data : ""} {applicant ? applicant.LastName.Data.charAt(0) : ""}</div>
        </div>
        <div className="d-flex justify-content-center">
          {applicant ?
            <>
              <span href='#' className='link'>
                <span className="people-detail-card-location">
                  <i className="fa-solid fa-location-dot pe-2"></i>
                  {_getLocationText(locationOptions, applicant.Location.Data)}
                </span>
              </span>
            </> : ""}
        </div>
        <div className="d-flex justify-content-center">
          <p className="people-detail-card-phone pt-2">
            {applicant ? <><img src={phoneCallFillImg} className="pe-2" />{applicant.PhoneNumber.Data.charAt(1)} <span className="blur">XXXXXXX</span>  {applicant.PhoneNumber.Data.charAt(applicant.PhoneNumber.Data.length - 1)}</> : ""}
          </p>
        </div>
      </div>

      {/* <div className="btn-group pr-2 pt-4">
        <div className='position-absolute top-0 start-50 translate-middle bg-success'>5 Yeae Exp</div>
      </div> */}

      <div className="nav people-detail-card-nav pt-3">
        <button className="people-detail-card-nav-btn active" data-bs-toggle="tab" href="#menu1">Work Experience </button>
        {/* <button className="people-detail-card-nav-btn" data-bs-toggle="tab" href="#menu2">All jobs</button> */}
      </div>

      <div className="people-detail-card-sub-wrapper vh-100 overflow-auto">
        <div className="tab-content mt-0">
          <div className="tab-pane fade show active" id="menu1">
            {/* {
              Object.keys(currentScheduleJob).length === 0 ?
                <div className='d-flex justify-content-between'>
                  <DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title={'No jobs found '} />
                </div>
                :
                currentScheduleJob && currentScheduleJob.map((d) => {
                  return <PeopleDetailSubCard
                    code={d.Title}
                    requestDate={d.RequestDate && _getDateText(d.RequestDate)}
                    startDate={d.StartDate && _getDateText(d.StartDate)}
                    startTime={d.StartTime}
                    jobPosition={d.Position && _getPositionText(d.Position)}
                    price={d.PayRates + ' hr'}
                  />
                })
            } */}
            {/* </div>
          <div className="tab-pane fade" id="menu2"> */}
            {
              Object.keys(completedJobs).length === 0 ?
                <div className='d-flex justify-content-between'>
                  <DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title={'No jobs found '} />
                </div>
                :
                completedJobs && completedJobs.map((d) => {
                  return <ProspectsPeopleDetailSubCard
                  clientName={d.ClientName}
                  startDate={d.StartDate && toLocalDateOnly(d.StartDate)}
                  endDate={d.EndDate ? toLocalDateOnly(d.EndDate) : 'Present'}
                  jobPosition={d.Position && _getPositionText(d.Position)}
                  jobType={d.JobType}
                  reportTo={d.ReportTo}
                  clientPhone={d.ClientPhone}
                  workingDays="10 Days"
                  code={<span class="blur">XXXXXXX</span>}
                  price={<><span class="blur">XX.XX</span> hr</>}

                  />
                })
            }
          </div>
        </div>
      </div>

    </div>
  )
}

export default ProspectsPeopleDetailCard