export const onStatusChanged = (lead, statusDetails) => {
    let _lead = JSON.parse(JSON.stringify(lead));
    _lead.Status = statusDetails.status;
    if (!_lead.StatusLogs) {
        _lead.StatusLogs = [];
    }
    _lead.StatusLogs.push(statusDetails.log);
    return _lead;
};

export const onDeleteContact= (lead, statusDetails) => {
    let _lead = JSON.parse(JSON.stringify(lead));
    if (!_lead.StatusLogs) {
        _lead.StatusLogs = [];
    }
    _lead.StatusLogs.push(statusDetails.log);
    return _lead;
};

export const onFormUploaded = (lead, fileDetails) => {
    let _lead = JSON.parse(JSON.stringify(lead));
    if (!_lead.Documents) {
        _lead.Documents = [];
    }
    _lead.Documents.push(fileDetails.documentData);
    return _lead;
};