import React from 'react';

function NoButton(props) {
    return (
        // <button
        //     type="button"
        //     className="btn btn-secondary btn-shadow mr-2"
        //     title={props.toolTipText ? props.toolTipText : "Reject changes"}
        //     onClick={props.onNoClicked}>
        //     <i className='fa fa-times me-2'></i>No
        // </button>
        <button
            type="button"
            className="c-btn white-btn me-2"
            title={props.toolTipText ? props.toolTipText : "Reject changes"}
            onClick={props.onNoClicked}>
            <i className='fa fa-times me-2'></i>No
        </button>
    );
}

export default NoButton;