import React, { useState } from "react";
import './css/demo.css';
import { useHistory } from "react-router-dom";

import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import notificationImg from './../../assets/images/menu/notification.svg';

import PeopleCard from "../Card/PeopleCard";
import PeopleHorizontalCard from "../Card/PeopleHorizontalCard";
import PeopleMoreFilters from "../Filters/PeopleMoreFilters";
import SideNavBar from "../SideNavBar/SideNavBar";
import TopNavBar from "../TopNavBar/TopNavBar";


const Demo = () => {
  const history = useHistory();

  const [applyMoreFilters, setApplyMoreFilters] = useState(false);

  const [cardView, setCardView] = useState(true);

  const peopleData = [
    { profilePhoto: boyFaceImg, 'status': 'Active', fullName: 'Allen Yarbrough', phone: '(999) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10', lastUpdate: '07/22/2022, 20:50:10', skill: 'Production, General Labor', lastCalledIn: '10/05/2022, 22:48:33', language: 'English', lastCalledOut: '10/05/2022, 22:47:17', cumulativeActiveDays: '12', lastStatus: 'All documents and data verify, 10/05/2022, 22:46:31.' },
    { profilePhoto: boyFaceImg, 'status': 'Incomplete', fullName: 'Allen Yarbrough', phone: '(888) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10', lastUpdate: '07/22/2022, 20:50:10', skill: 'Production, General Labor', lastCalledIn: '10/05/2022, 22:48:33', language: 'English', lastCalledOut: '10/05/2022, 22:47:17', cumulativeActiveDays: '12', lastStatus: 'All documents and data verify, 10/05/2022, 22:46:31.' },
    { profilePhoto: boyFaceImg, 'status': 'InProgress', fullName: 'Allen Yarbrough', phone: '(888) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10', lastUpdate: '07/22/2022, 20:50:10', skill: 'Production, General Labor', lastCalledIn: '10/05/2022, 22:48:33', language: 'English', lastCalledOut: '10/05/2022, 22:47:17', cumulativeActiveDays: '12', lastStatus: 'All documents and data verify, 10/05/2022, 22:46:31.' },
    { profilePhoto: boyFaceImg, 'status': 'Available', fullName: 'Allen Yarbrough', phone: '(888) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10', lastUpdate: '07/22/2022, 20:50:10', skill: 'Production, General Labor', lastCalledIn: '10/05/2022, 22:48:33', language: 'English', lastCalledOut: '10/05/2022, 22:47:17', cumulativeActiveDays: '12', lastStatus: 'All documents and data verify, 10/05/2022, 22:46:31.' },
    { profilePhoto: boyFaceImg, 'status': 'Active', fullName: 'Allen Yarbrough', phone: '(999) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10', lastUpdate: '07/22/2022, 20:50:10', skill: 'Production, General Labor', lastCalledIn: '10/05/2022, 22:48:33', language: 'English', lastCalledOut: '10/05/2022, 22:47:17', cumulativeActiveDays: '12', lastStatus: 'All documents and data verify, 10/05/2022, 22:46:31.' },
    { profilePhoto: boyFaceImg, 'status': 'Incomplete', fullName: 'Allen Yarbrough', phone: '(888) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10', lastUpdate: '07/22/2022, 20:50:10', skill: 'Production, General Labor', lastCalledIn: '10/05/2022, 22:48:33', language: 'English', lastCalledOut: '10/05/2022, 22:47:17', cumulativeActiveDays: '12', lastStatus: 'All documents and data verify, 10/05/2022, 22:46:31.' },
    { profilePhoto: boyFaceImg, 'status': 'InProgress', fullName: 'Allen Yarbrough', phone: '(888) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10', lastUpdate: '07/22/2022, 20:50:10', skill: 'Production, General Labor', lastCalledIn: '10/05/2022, 22:48:33', language: 'English', lastCalledOut: '10/05/2022, 22:47:17', cumulativeActiveDays: '12', lastStatus: 'All documents and data verify, 10/05/2022, 22:46:31.' },
    { profilePhoto: boyFaceImg, 'status': 'Available', fullName: 'Allen Yarbrough', phone: '(888) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10', lastUpdate: '07/22/2022, 20:50:10', skill: 'Production, General Labor', lastCalledIn: '10/05/2022, 22:48:33', language: 'English', lastCalledOut: '10/05/2022, 22:47:17', cumulativeActiveDays: '12', lastStatus: 'All documents and data verify, 10/05/2022, 22:46:31.' },
    { profilePhoto: boyFaceImg, 'status': 'Active', fullName: 'Allen Yarbrough', phone: '(999) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10', lastUpdate: '07/22/2022, 20:50:10', skill: 'Production, General Labor', lastCalledIn: '10/05/2022, 22:48:33', language: 'English', lastCalledOut: '10/05/2022, 22:47:17', cumulativeActiveDays: '12', lastStatus: 'All documents and data verify, 10/05/2022, 22:46:31.' },
    { profilePhoto: boyFaceImg, 'status': 'Incomplete', fullName: 'Allen Yarbrough', phone: '(888) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10', lastUpdate: '07/22/2022, 20:50:10', skill: 'Production, General Labor', lastCalledIn: '10/05/2022, 22:48:33', language: 'English', lastCalledOut: '10/05/2022, 22:47:17', cumulativeActiveDays: '12', lastStatus: 'All documents and data verify, 10/05/2022, 22:46:31.' },
    { profilePhoto: boyFaceImg, 'status': 'InProgress', fullName: 'Allen Yarbrough', phone: '(888) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10', lastUpdate: '07/22/2022, 20:50:10', skill: 'Production, General Labor', lastCalledIn: '10/05/2022, 22:48:33', language: 'English', lastCalledOut: '10/05/2022, 22:47:17', cumulativeActiveDays: '12', lastStatus: 'All documents and data verify, 10/05/2022, 22:46:31.' },
    { profilePhoto: boyFaceImg, 'status': 'Available', fullName: 'Allen Yarbrough', phone: '(888) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10', lastUpdate: '07/22/2022, 20:50:10', skill: 'Production, General Labor', lastCalledIn: '10/05/2022, 22:48:33', language: 'English', lastCalledOut: '10/05/2022, 22:47:17', cumulativeActiveDays: '12', lastStatus: 'All documents and data verify, 10/05/2022, 22:46:31.' },
  ];

  const peopleCardData = [
    { profilePhoto: boyFaceImg, 'status': 'Active', fullName: 'Allen Yarbrough', phone: '(999) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10' },
    { profilePhoto: boyFaceImg, 'status': 'Incomplete', fullName: 'Allen Yarbrough', phone: '(888) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10' },
    { profilePhoto: boyFaceImg, 'status': 'InProgress', fullName: 'Allen Yarbrough', phone: '(888) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10' },
    { profilePhoto: boyFaceImg, 'status': 'Available', fullName: 'Allen Yarbrough', phone: '(888) 877-7474', email: 'patricia_mireles@gmail.com', dob: '05/04/1990', address: '101 Independence Avenue, SE 85793', lastApplied: '06/22/2022, 20:50:10' }
  ];

  return (
    <div>

      {/* <!-- Side-Nav --> */}
      <SideNavBar />

      {/* <!-- Main Wrapper --> */}
      <div className="my-container active-cont">
        
        {/* <!-- Top Nav --> */}
        <TopNavBar />

        {/* Filter Bar */}
        <div className="filter-bar p-3">
          <button className="c-btn" type="button">All</button>
          <button className="c-btn c-btn-gray mx-1" type="button">Incomplete</button>
          <button className="c-btn c-btn-gray mx-1" type="button">In Progress</button>
          <button className="c-btn c-btn-gray mx-1" type="button">Available</button>
          <button className="c-btn c-btn-gray mx-1" type="button">Active</button>
          <button className="c-btn c-btn-gray mx-1" type="button">InActive</button>
          <button className="c-btn c-btn-gray mx-1" type="button">DNU</button>
          <button className="c-btn c-btn-gray mx-1" type="button">Dispatch</button>
          <button className="c-btn c-btn-gray mx-1" type="button">Called In</button>
          <button className="c-btn c-btn-gray mx-1" type="button">Full Time</button>
          <button className="c-btn c-btn-transparent" type="button">+ Create New List</button>
          <div className="float-end">
            <button type="button" className="c-btn dark-btn position-relative" onClick={() => setApplyMoreFilters(true)}>
              <i className="fa fa-filter" aria-hidden="true"></i> Filter
              <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                <span className="visually-hidden">New alerts</span>
              </span>
            </button>
          </div>
        </div>

        <div className="filter-bar p-3">
          {/* <div className="btn-group">
            <button className="btn c-dropdown dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
              Location
            </button>
            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
              <li><a className="dropdown-item" href="#">Menu item</a></li>
              <li><a className="dropdown-item" href="#">Menu item</a></li>
              <li><a className="dropdown-item" href="#">Menu item</a></li>
            </ul>
          </div> */}

          <div className="btn-group pr-2">
            <button className="btn btn-transparent btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuClickableOutside" data-bs-toggle="dropdown" data-bs-auto-close="inside" aria-expanded="false">
              Location
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickableOutside">
              <li><a className="dropdown-item" href="#">Menu item</a></li>
              <li><a className="dropdown-item" href="#">Menu item</a></li>
              <li><a className="dropdown-item" href="#">Menu item</a></li>
            </ul>
          </div>

          <div className="btn-group px-2">
            <button className="btn btn-transparent btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuClickableOutside" data-bs-toggle="dropdown" data-bs-auto-close="inside" aria-expanded="false">
              Work Status
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickableOutside">
              <li><a className="dropdown-item" href="#">Menu item</a></li>
              <li><a className="dropdown-item" href="#">Menu item</a></li>
              <li><a className="dropdown-item" href="#">Menu item</a></li>
            </ul>
          </div>

          <div className="btn-group px-2">
            <button className="btn btn-transparent btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
              Shift
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
              <li><a className="dropdown-item" href="#">Menu item</a></li>
              <li><a className="dropdown-item" href="#">Menu item</a></li>
              <li><a className="dropdown-item" href="#">Menu item</a></li>
            </ul>
          </div>

          <div className="btn-group px-2">
            <button className="btn btn-transparent btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuClickable" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
              Position
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickable">
              <li><a className="dropdown-item" href="#">Menu item</a></li>
              <li><a className="dropdown-item" href="#">Menu item</a></li>
              <li><a className="dropdown-item" href="#">Menu item</a></li>
            </ul>
          </div>

          <div className="float-end">
            <button className="c-btn white-btn" type="button"><i className="fa fa-list" aria-hidden="true"></i></button>
            <button className="c-btn white-btn" type="button"><i className="fa fa-th-large" aria-hidden="true"></i></button>
          </div>
        </div>
        {/* Filter Bar End */}

        {/* Filter Bar */}
        <div className="filter-bar p-3">
          <button className="c-btn c-btn-gray mx-1" type="button">Language: English <i className="fa fa-times" aria-hidden="true"></i></button>
          <button className="c-btn c-btn-gray mx-1" type="button">Gender: Male <i className="fa fa-times" aria-hidden="true"></i></button>
          <button className="c-btn c-btn-gray mx-1" type="button">Language: English <i className="fa fa-times" aria-hidden="true"></i></button>
          <button className="c-btn c-btn-gray mx-1" type="button">Repetitive Movements: No <i className="fa fa-times" aria-hidden="true"></i></button>
          <button className="c-btn c-btn-gray mx-1" type="button">Can Work In Cold Tempearture: Yes <i className="fa fa-times" aria-hidden="true"></i></button>
          <button className="c-btn c-btn-gray mx-1" type="button">Are You COVID Vaccinated?: Yes <i className="fa fa-times" aria-hidden="true"></i></button>
          <div className="float-end">
            <button className="c-btn transparent-btn text-dark" type="button">Clear All</button>
          </div>
        </div>

        {/* main section */}

        {
          peopleData.map((d) => {
            return <PeopleHorizontalCard
              profilePhoto={d.profilePhoto}
              status={d.status}
              fullName={d.fullName}
              phone={d.phone}
              email={d.email}
              dob={d.dob}
              address={d.address}
              lastApplied={d.lastApplied}
              lastUpdate={d.lastUpdate}
              skill={d.skill}
              lastCalledIn={d.lastCalledIn}
              language={d.language}
              lastCalledOut={d.lastCalledOut}
              cumulativeActiveDays={d.cumulativeActiveDays}
              lastStatus={d.lastStatus}
            />
          })
        }

        <div className="row p-3 pb-0">
          {
            peopleCardData.map((d) => {
              return <PeopleCard
                profilePhoto={d.profilePhoto}
                status={d.status}
                fullName={d.fullName}
                phone={d.phone}
                email={d.email}
                dob={d.dob}
                address={d.address}
                lastApplied={d.lastApplied}
              />
            })
          }

        </div >


        {/* End main section */}


        {/* Apply More Filters */}

        {
          applyMoreFilters ?
            // <div className="position-fixed bottom-0 end-0">
            //   <div className="filters-card">
            //     <div className="p-3">


            //       <div className="float-end">
            //         <div className="close-btn">
            //           <i className="fa fa-times" aria-hidden="true" onClick={() => setApplyMoreFilters(false)}></i>
            //         </div>
            //       </div>
            //       <div className="filters-card-title">Apply More Filters</div>
            //     </div>

            //     <div className="filters-card-sub-title px-3">
            //       Select filters below to narrow down your search results.<br />
            //       You can also save the results as a “list”
            //     </div>
            //     <div className="row p-3">
            //       <div className="col-md-4">
            //         <div className="btn-group">
            //           <button className="btn btn-transparent btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuClickableOutside" data-bs-toggle="dropdown" data-bs-auto-close="inside" aria-expanded="false">
            //             Language
            //           </button>
            //           <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickableOutside">
            //             <li><a className="dropdown-item" href="#">Menu item</a></li>
            //             <li><a className="dropdown-item" href="#">Menu item</a></li>
            //             <li><a className="dropdown-item" href="#">Menu item</a></li>
            //           </ul>
            //         </div>
            //       </div>

            //       <div className="col-md-4">
            //         <div className="btn-group">
            //           <button className="btn btn-transparent btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuClickableOutside" data-bs-toggle="dropdown" data-bs-auto-close="inside" aria-expanded="false">
            //             Gender
            //           </button>
            //           <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickableOutside">
            //             <li><a className="dropdown-item" href="#">Menu item</a></li>
            //             <li><a className="dropdown-item" href="#">Menu item</a></li>
            //             <li><a className="dropdown-item" href="#">Menu item</a></li>
            //           </ul>
            //         </div>
            //       </div>

            //       <div className="col-md-4">
            //         <div className="btn-group">
            //           <button className="btn btn-transparent btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuClickableOutside" data-bs-toggle="dropdown" data-bs-auto-close="inside" aria-expanded="false">
            //             Flexibility
            //           </button>
            //           <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickableOutside">
            //             <li><a className="dropdown-item" href="#">Menu item</a></li>
            //             <li><a className="dropdown-item" href="#">Menu item</a></li>
            //             <li><a className="dropdown-item" href="#">Menu item</a></li>
            //           </ul>
            //         </div>
            //       </div>
            //     </div>

            //     <div className="px-3">
            //       <button className="c-btn" type="button">General Question</button>
            //       <button className="c-btn c-btn-gray mx-2" type="button">Warehouse Experience</button>
            //       <button className="c-btn c-btn-gray" type="button">Others</button>
            //     </div>

            //     <ul className="list-group pt-3">
            //       <li className="list-group-item">
            //         Available For OT
            //         <div className="float-end">
            //           <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
            //           <label className="form-check-label" for="inlineRadio1">No</label>
            //         </div>
            //         <div className="float-end">
            //           <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
            //           <label className="form-check-label" for="inlineRadio1">Yes</label>
            //         </div>
            //       </li>
            //       <li className="list-group-item">
            //         Willing to commute for other cities
            //         <div className="float-end">
            //           <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio2" value="option1" />
            //           <label className="form-check-label" for="inlineRadio1">No</label>
            //         </div>
            //         <div className="float-end">
            //           <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio2" value="option1" />
            //           <label className="form-check-label" for="inlineRadio1">Yes</label>
            //         </div>
            //       </li>
            //       <li className="list-group-item">
            //         Fast Paced Environments
            //         <div className="float-end">
            //           <input className="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio3" value="option1" />
            //           <label className="form-check-label" for="inlineRadio1">No</label>
            //         </div>
            //         <div className="float-end">
            //           <input className="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio3" value="option1" />
            //           <label className="form-check-label" for="inlineRadio1">Yes</label>
            //         </div>
            //       </li>
            //       <li className="list-group-item">Can Work In Hot Tempearture</li>
            //       <li className="list-group-item">Repetitive Movements</li>
            //       <li className="list-group-item">Can Work In Cold Tempearture</li>
            //       <li className="list-group-item">Heavy Lifting (50LBS)</li>
            //       <li className="list-group-item">Own Still Toe Boots</li>
            //       <li className="list-group-item">Own Slip Resistant Boots</li>
            //       <li className="list-group-item">Can Work Weekends</li>
            //       <li className="list-group-item">Available for 1 Day assignment</li>
            //       <li className="list-group-item">Can You Read In English?</li>
            //     </ul>


            //     <div className="p-3">
            //       <button className="c-btn transparent-btn" type="button" >Reset</button>
            //       <div className="float-end">
            //         <button className="c-btn white-btn mx-2" type="button" >Cancel</button>
            //         <button className="c-btn dark-btn" type="button" >Apply</button>
            //       </div>
            //     </div>


            //   </div>
            // </div>
            <PeopleMoreFilters
              onClose={() => setApplyMoreFilters(false)}
            />
            :
            ''
        }

      </div >


    </div >

  )
}



export default Demo;