import React from 'react'

import userActiveImg from './../../../../assets/images/clipart/user-active.svg';
import circleCrossLineImg from './../../../../assets/images/clipart/circle-cross-line.svg';
import leftArrowImg from './../../../../assets/images/clipart/left-arrow.svg';
import rightArrowImg from './../../../../assets/images/clipart/right-arrow.svg';

const peopleData = [
  { name: 'Patricia Mireles', ssn: '445-34-5678', phone: '(099)608-0335', dispatchUser: 'Marc Anthony', payRates: '$ 5.00', jobType: 'Light', dob: '10/04/1989', address: '3553 Heavner Court, Manhattan', dispatchDate: '08/31/2022, 22:48:33', postDispatchStatus: '2 Hours Pay' },
  { name: 'Patricia Mireles', ssn: '445-34-5678', phone: '(099)608-0335', dispatchUser: 'Marc Anthony', payRates: '$ 5.00', jobType: 'Light', dob: '10/04/1989', address: '3553 Heavner Court, Manhattan', dispatchDate: '08/31/2022, 22:48:33', postDispatchStatus: '2 Hours Pay' },
  { name: 'Patricia Mireles', ssn: '445-34-5678', phone: '(099)608-0335', dispatchUser: 'Marc Anthony', payRates: '$ 5.00', jobType: 'Light', dob: '10/04/1989', address: '3553 Heavner Court, Manhattan', dispatchDate: '08/31/2022, 22:48:33', postDispatchStatus: '2 Hours Pay' },
  { name: 'Patricia Mireles', ssn: '445-34-5678', phone: '(099)608-0335', dispatchUser: 'Marc Anthony', payRates: '$ 5.00', jobType: 'Light', dob: '10/04/1989', address: '3553 Heavner Court, Manhattan', dispatchDate: '08/31/2022, 22:48:33', postDispatchStatus: '2 Hours Pay' },
  { name: 'Patricia Mireles', ssn: '445-34-5678', phone: '(099)608-0335', dispatchUser: 'Marc Anthony', payRates: '$ 5.00', jobType: 'Light', dob: '10/04/1989', address: '3553 Heavner Court, Manhattan', dispatchDate: '08/31/2022, 22:48:33', postDispatchStatus: '2 Hours Pay' },
  { name: 'Patricia Mireles', ssn: '445-34-5678', phone: '(099)608-0335', dispatchUser: 'Marc Anthony', payRates: '$ 5.00', jobType: 'Light', dob: '10/04/1989', address: '3553 Heavner Court, Manhattan', dispatchDate: '08/31/2022, 22:48:33', postDispatchStatus: '2 Hours Pay' },
  { name: 'Patricia Mireles', ssn: '445-34-5678', phone: '(099)608-0335', dispatchUser: 'Marc Anthony', payRates: '$ 5.00', jobType: 'Light', dob: '10/04/1989', address: '3553 Heavner Court, Manhattan', dispatchDate: '08/31/2022, 22:48:33', postDispatchStatus: '2 Hours Pay' },
  { name: 'Patricia Mireles', ssn: '445-34-5678', phone: '(099)608-0335', dispatchUser: 'Marc Anthony', payRates: '$ 5.00', jobType: 'Light', dob: '10/04/1989', address: '3553 Heavner Court, Manhattan', dispatchDate: '08/31/2022, 22:48:33', postDispatchStatus: '2 Hours Pay' },
  { name: 'Patricia Mireles', ssn: '445-34-5678', phone: '(099)608-0335', dispatchUser: 'Marc Anthony', payRates: '$ 5.00', jobType: 'Light', dob: '10/04/1989', address: '3553 Heavner Court, Manhattan', dispatchDate: '08/31/2022, 22:48:33', postDispatchStatus: '2 Hours Pay' },
  { name: 'Patricia Mireles', ssn: '445-34-5678', phone: '(099)608-0335', dispatchUser: 'Marc Anthony', payRates: '$ 5.00', jobType: 'Light', dob: '10/04/1989', address: '3553 Heavner Court, Manhattan', dispatchDate: '08/31/2022, 22:48:33', postDispatchStatus: '2 Hours Pay' }
];

function JobOrderPeopleTab() {
  return (
    <React.Fragment>
      <div className='p-3'>
        <table className="table c-table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">SSN</th>
              <th scope="col">Phone</th>
              <th scope="col">Dispatch User</th>
              <th scope="col">Pay Rates</th>
              <th scope="col">Job Type</th>
              <th scope="col">DOB</th>
              <th scope="col">Address</th>
              <th scope="col">Dispatch Date</th>
              <th scope="col">Post Dispatch Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {
              peopleData.map((d) => {
                return <tr>
                  <td>{d.name}</td>
                  <td>{d.ssn}</td>
                  <td>{d.phone}</td>
                  <td>{d.dispatchUser}</td>
                  <td>{d.payRates}</td>
                  <td>{d.jobType}</td>
                  <td>{d.dob}</td>
                  <td>{d.address}</td>
                  <td>{d.dispatchDate}</td>
                  <td>{d.postDispatchStatus}</td>
                  <td>
                    <img src={userActiveImg} />
                    <img src={circleCrossLineImg} className="ms-3" />
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>

        <div className="d-flex justify-content-between">
          <div className="c-table-result">Showing 1 to 10 of 57 entries</div>
          <div className="d-flex" >
            <ul className="c-pagination">
              <li><a className="" href="#"><img src={leftArrowImg} /></a></li>
              <li><a href="#">1</a></li>
              <li><a href="#" className="active">2</a></li>
              <li><a href="#">3</a></li>
              <li><a href="#">4</a></li>
              <li><a href="#">5</a></li>
              <li><a href="#">6</a></li>
              <li><a href="#">7</a></li>
              <li><a href="#"><img src={rightArrowImg} /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default JobOrderPeopleTab;