import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import Client from './components/client';
import Location from './components/location';
import User from './components/user';
import { accountService } from '../../accounts/services/accountService';
import Statuses from './components/statuses';
import Company from './components/company';
import Experience from './components/experience';
import Positions from './components/positions';
import SkillGroup from './components/skillGroup';
import { tenantId } from '../../../api/client';
import Subscribers from './components/subscribers';

const Setting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageLoaded, setPageLoaded] = useState(false);

  const isAdmin = accountService.isAdmin();
  const isPayroll = accountService.isPayroll();
  const IsReviewer = accountService.isReviewer();
  const isSalesRep = accountService.isSalesRep();
  const isSalesManager = accountService.isSalesManager();

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 9,
        title: "Settings"
      }));
      setPageLoaded(true);
    }
  });

  const [selectedLink, setSelectedLink] = useState(2);

  const onClickCompany = (e) => {
    e.preventDefault();
    setSelectedLink(1);
  }

  const onClickTeam = () => {
    setSelectedLink(2);
  }

  const onClickClient = (e) => {
    e.preventDefault();
    setSelectedLink(3);
  }

  const onClickLoaction = (e) => {
    e.preventDefault();
    setSelectedLink(4);
  }
  
  const onClickStatuses = (e) => {
    e.preventDefault();
    setSelectedLink(5);
  };

  const onClickExperience = (e) => {
    e.preventDefault();
    setSelectedLink(6);
  };

  const onClickSkillGroup = (e) => {
    e.preventDefault();
    setSelectedLink(7);
  };
  
  const onClickPositions = (e) => {
    e.preventDefault();
    setSelectedLink(8);
  };

  const onClickSubscribers  = (e) => {
    e.preventDefault();
    setSelectedLink(9);
  };

  return (
    <>
          <div className="px-3 pt-3">
            <div className="row">
              <div className="col-md-2">
                <div className="card setting-card">
                  <p className="setting-card-menu-title">Settings</p>
                  <div className="setting-card-menu">
                    {isAdmin && tenantId.toLocaleLowerCase() == 'hirebase' ?
                      <>
                        <a href="#" className={`${selectedLink === 1 ? 'active' : ''}`} onClick={(e) => onClickCompany(e)}><i className="fa fa-building pe-2" />Company</a>
                      
                        <a href="#" className={`${selectedLink === 8 ? 'active' : ''}`} onClick={(e) => onClickSubscribers(e)}><i className="fa-solid fa-users-viewfinder pe-2" />Subscribers</a>
                      </>
                      : <></>
                    }
                    {isAdmin ?
                      <a href="#" className={`${selectedLink === 2 ? 'active' : ''}`} onClick={onClickTeam}><i className="fa fa-users pe-2" />Team</a>
                      : <></>}
                    {isAdmin || isPayroll || isSalesManager ?
                      <a href="#" className={`${selectedLink === 3 ? 'active' : ''}`} onClick={(e) => onClickClient(e)}><i className="fa fa-handshake pe-2" />Clients</a>
                      : <></>}
                    {isAdmin ?
                      <a href="#" className={`${selectedLink === 4 ? 'active' : ''}`} onClick={(e) => onClickLoaction(e)}><i className="fa fa-location-dot pe-2" />Locations</a>
                      : <></>
                    }
                    {isAdmin ?
                      <a href="#" className={`${selectedLink === 5 ? 'active' : ''}`} onClick={(e) => onClickStatuses(e)}><i className="fa-solid fa-tags pe-2" />Statuses</a>
                      : <></>
                    }
                    {isAdmin ?
                      <a href="#" className={`${selectedLink === 6 ? 'active' : ''}`} onClick={(e) => onClickExperience(e)}><i className="fa-solid fa-gem pe-2" />Skills</a>
                      : <></>
                    }
                    {isAdmin ?
                      <a href="#" className={`${selectedLink === 7 ? 'active' : ''}`} onClick={(e) => onClickSkillGroup(e)}><i className="fa-solid fa-gem pe-2" />Skills Group</a>
                      : <></>
                    }
                    {isAdmin ?
                      <a href="#" className={`${selectedLink === 8 ? 'active' : ''}`} onClick={(e) => onClickPositions(e)}><i className="fa-solid fa-street-view pe-2" />Positions</a>
                      : <></>
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-10">
                <div className="default-root-container detail-container">
                  <div className="col-md-12">
                    {selectedLink === 1 && <Company />}
                    {selectedLink === 2 && <User />}
                    {selectedLink === 3 && <Client />}
                    {selectedLink === 4 && <Location />}
                    {selectedLink === 5 && <Statuses />}
                    {selectedLink === 6 && <Experience />}
                    {selectedLink === 7 && <SkillGroup />}
                    {selectedLink === 8 && <Positions />}
                    {selectedLink === 9 && <Subscribers />}
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}

export default Setting;