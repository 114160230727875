import React, { useEffect, useState } from 'react';
import Inputmask from "inputmask";

function PhoneText(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);

    const _isValidValue = (v) => {
        if (v) {
            let returnValue = v;
            returnValue = returnValue.replace("(", "");
            returnValue = returnValue.replace(")", "");
            returnValue = returnValue.replace("-", "");
            returnValue = returnValue.replace(" ", "");
            return !isNaN(returnValue);
        }
        return true;
    };

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value || !_isValidValue(value)) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
            else {
                setInvalid(!_isValidValue(value));
            }
        }
        else {
            setInvalid(false);
        }
    }, [value, props.required, edited, props.submited, props.disabled]);

    useEffect(() => {
        var phoneInput = document.getElementById(props.id);
        var inputMask = new Inputmask("(999)999-9999");
        inputMask.mask(phoneInput);
    });

    const onChange = (e) => {
        props.onChange && props.onChange(e);
        setValue(e.target.value);
        setEdited(true);
    };

    return (
        <React.Fragment>
            <input
                type="text"
                id={props.id}
                name={props.name}
                value={props.value ? props.value : ""}
                // className={`c-form-control ${props.PhoneTextClasses ? props.PhoneTextClasses : ""} ${invalid && "invalid-input"}`}
                className={`${props.PhoneTextClasses ? props.PhoneTextClasses : ""} ${invalid && "invalid-input"}`}
                // className={`form-control ${invalid && "is-invalid"}`}
                placeholder="(___)___-____"//{`${props.placeholder ? props.placeholder : ""}`}
                onChange={(e) => onChange(e)}
                disabled={props.disabled === true}
                formNoValidate={true}
                data-form={props.form ? props.form : ""}
                data-invalid={invalid}
                data-required={props.required ? props.required : false}
            />
            {
                invalid && <span className="error-label">
                    <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            }
        </React.Fragment>
    );
}

export default PhoneText;