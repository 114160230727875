import {
    createAsyncThunk,
    createSlice
}
    from '@reduxjs/toolkit';
import { BajoAPI, fetchStatus } from '../../../../api/client';
import { getAxiosRequestConfig } from '../../../../common/common';
import { isJSON } from '../../../../utilities/utilityFunctions';

const initialState = {
    title: "",
    id: "",
    applicant: undefined,
    updatedApplicant: undefined,
    status: {
        value: 0,
        text: ""
    },
    show: false,
    canceled: false,
    clients: {
        status: fetchStatus.IDLE,
        error: undefined,
        data: undefined
    },
    jobsToBeDispatched: {
        status: fetchStatus.IDLE,
        error: null,
        data: [],
        refresh: false
    }
};

export const getClients = createAsyncThunk('PreferredClientModalPopUp/getClients', async (model) => {
    const response = await BajoAPI.post('Gateway', model, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let clients;
    if (isJSON(data)) {
        clients = JSON.parse(data);
    }
    return clients;
});

export const getJobsToBeDispatched = createAsyncThunk('reviewers/getJobsToBeDispatched', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let jobs = undefined;
        if (data && isJSON(data)) {
            jobs = JSON.parse(data);
        }
        return {
            jobs: jobs,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const PreferredClientModalPopUpSlice = createSlice({
    name: 'PreferredClientModalPopUp',
    initialState,
    reducers: {
        showPreferredClientModal: (state, action) => {
            const { title, id, applicant, status } = action.payload;
            state.title = title;
            state.id = id;
            state.applicant = applicant;
            state.show = true;
        },
        closeModal: state => {
            state.title = "";
            state.id = "";
            state.applicant = undefined;
            state.updatedApplicant = undefined;
            state.status.value = 0;
            state.status.text = "";
            state.show = false;
            state.canceled = true;
            state.clients.status = fetchStatus.IDLE;
            state.clients.data = undefined;
            state.jobsToBeDispatched.refresh = false;
        },
        saveUpdatedApplicant: (state, action) => {
            state.updatedApplicant = action.payload;
        },
        resetCanceledStatus: (state) => {
            state.canceled = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getClients.pending, (state, action) => {
            state.clients.status = fetchStatus.LOADING;
        }).addCase(getClients.fulfilled, (state, action) => {
            state.clients.data = action.payload;
            state.clients.status = fetchStatus.SUCCEEDED;
        }).addCase(getClients.rejected, (state, action) => {
            state.clients.status = fetchStatus.FAILED;
            state.clients.error = action.error.message;
        }).addCase(getJobsToBeDispatched.pending, (state, action) => {
            state.jobsToBeDispatched.status = fetchStatus.LOADING;
        }).addCase(getJobsToBeDispatched.fulfilled, (state, action) => {
            state.jobsToBeDispatched.data = action.payload.jobs;
            state.jobsToBeDispatched.status = fetchStatus.SUCCEEDED;
            state.jobsToBeDispatched.refresh = false;
            //toaster.success(action.payload.success);
        }).addCase(getJobsToBeDispatched.rejected, (state, action) => {
            state.jobsToBeDispatched.status = fetchStatus.FAILED;
            state.jobsToBeDispatched.error = action.error.message;
            //toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { showPreferredClientModal, closeModal, saveUpdatedApplicant, resetCanceledStatus } = PreferredClientModalPopUpSlice.actions;

export default PreferredClientModalPopUpSlice.reducer

export const selectModalPopUpDetail = state => {
    return {
        title: state.PreferredClientModalPopUp.title,
        id: state.PreferredClientModalPopUp.id,
        applicant: state.PreferredClientModalPopUp.applicant,
        updatedApplicant: state.PreferredClientModalPopUp.updatedApplicant,
        status: state.PreferredClientModalPopUp.status,
        show: state.PreferredClientModalPopUp.show,
        refreshJobs: state.PreferredClientModalPopUp.jobsToBeDispatched.refresh,
    }
}

export const selectCanceledStatus = state => state.PreferredClientModalPopUp.canceled;

export const selectClients = state => state.PreferredClientModalPopUp.clients.data;

export const selectClientsStatus = state => state.PreferredClientModalPopUp.clients.status;

export const selectJobsToBeDispatched = state => state.PreferredClientModalPopUp.jobsToBeDispatched.data;

export const selectJobsToBeDispatchedStatus = state => state.PreferredClientModalPopUp.jobsToBeDispatched.status;

export const selectJobsToBeDispatchedFiltered = state => state.PreferredClientModalPopUp.jobsToBeDispatched.data;