import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import boyFaceImg from './../../../../assets/images/clipart/boy-face.svg';

import noDataImg from './../../../../assets/images/clipart/no-data.svg';
import InputGroup from "../../../../component/Controls/custom/inputGroup/inputGroup";
import TextBox from "../../../../component/Controls/custom/textbox/textBox";
import Selector from "../../../../component/Controls/custom/selectors/selectors";
import InputDate from "../../../../component/Controls/custom/inputDate/inputDate";
import JobOrderPeopleTab from "./jobOrderPeopleTab";
import JobOrderDetailsTab from "./jobOrderDetailsTab";

import leftArrowImg from './../../../../assets/images/clipart/left-arrow.svg';
import rightArrowImg from './../../../../assets/images/clipart/right-arrow.svg';
import plusImg from './../../../../assets/images/clipart/plus.svg';

import calendarImg from './../../../../assets/images/clipart/calendar.svg';
import userMagnifyingImg from './../../../../assets/images/clipart/user-magnifying.svg';
import ssnImg from './../../../../assets/images/clipart/ssn.svg';

import clockCalendarImg from './../../../../assets/images/clipart/clock-calendar.svg';
import userSkillImg from './../../../../assets/images/clipart/user-skill.svg';
import replyImg from './../../../../assets/images/clipart/reply.svg';
import mapLocationImg from './../../../../assets/images/clipart/map-location.svg';
import useHeartImg from './../../../../assets/images/clipart/use-heart.svg';
import handshakeImg from './../../../../assets/images/clipart/handshake.svg';
import dispatchedImg from './../../../../assets/images/clipart/dispatched.svg';
import JobPeopleCard from "../../../../component/Card/JobPeopleCard";
import CardBottomLine from "../../../../component/Card/CardBottomLine";
import FillOrderPeopleCard from "../../../../component/Card/FillOrderPeopleCard";
import Avatar from "../../../../component/Avatar/Avatar";
import AvatarGroup from "../../../../component/Avatar/AvatarGroup";
import JobOrderStatusLog from "./JobOrderStatusLog";
import { updateLayout } from "../../../../component/layouts/reducerSlices/layoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { refreshJobOptions, removeJob, selectCreationStatus, selectJobById, selectJobFormStatus, selectModificationStatus, selectRecentClickedJob, selectRemovalStatus, selectSingleStatus, updateClientStatus, updateRemovalStatus, updateSingleData } from "../../reducerSlices/jobSlice";
import { isObjectArray } from "../../../../utilities/utilityFunctions";
import ApplicantDetail from "./applicantDetails";
import PreferredApplicant from "./PreferredApplicant";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { selectLocationOptions } from "../../../locations/reducerSlices/locationSlice";
import CheckBox from "../../../../component/Controls/custom/checkers/checkbox";
import { onValueChanged, sections } from "../../services/jobSaver";
import { toInteger } from "../../../../common/common";
import ReactTooltip from "react-tooltip";
import { fetchStatus, tenantId } from "../../../../api/client";
import GridLoader from "../../../../component/Controls/loaders/gridLoader";
import { show } from "../../../../component/Controls/alerts/reducerSlices/confirmAlertSlice";
import { messages } from "../../../../common/actionMessages";
import { accountService } from "../../../accounts/services/accountService";
import SaveControls from "../../../shared/components/saveControls";
import { selectPositionOptions } from "../../../setting/reducerSlices/positionsSlice";



function JobOrderEdit() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [pageLoaded, setPageLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);

  const recentClickedJob = useSelector(selectRecentClickedJob);
  const job = useSelector(selectJobById);
  const jobRequestStatus = useSelector(selectSingleStatus);
  const jobFormStatus = useSelector(selectJobFormStatus);
  const creationStatus = useSelector(selectCreationStatus);
  const modificationStatus = useSelector(selectModificationStatus);

  const [latestLog, setLatestLog] = useState(undefined);
  const [logCards, setLogCards] = useState(undefined);

  const locationOptions = useSelector(selectLocationOptions);

  const [createMode, setCreatemode] = useState(false);
  const [editMode, setEditmode] = useState(false);

  const applicantRemovalStatus = useSelector(selectRemovalStatus);
  const [CFInvalid, setCFInvalid] = useState(false);

  const positionOptions = useSelector(selectPositionOptions);

  const onTabSelected = (e, v) => {
    setSelectedTab(v);
  };

  useEffect(() => {
    if (recentClickedJob === undefined) {
      history.push('../jobOrder');
    }

    if (recentClickedJob === 0) {
      setCreatemode(true);
      setSelectedTab(0);
    } else {
      setCreatemode(false);
    }
    setEditmode(false);
    dispatch(updateClientStatus());
  }, [recentClickedJob]);

  const fillOrderData = [
    { fullName: 'Allen Yarbrough', date: '08/31/2022, 22:48:33', skillMatched: '5.8/10', promptReply: 'Yes', distanceFromLocation: '3.2 ml', workedHereBefore: 'Yes', preferedClient: 'Yes', contacted: 'true', dispatched: 'true' },
    { fullName: 'Allen Yarbrough', date: '08/31/2022, 22:48:33', skillMatched: '5.8/10', promptReply: 'Yes', distanceFromLocation: '3.2 ml', workedHereBefore: 'Yes', preferedClient: 'Yes', contacted: 'true', dispatched: 'true' },
    { fullName: 'Allen Yarbrough', date: '08/31/2022, 22:48:33', skillMatched: '5.8/10', promptReply: 'Yes', distanceFromLocation: '3.2 ml', workedHereBefore: 'Yes', preferedClient: 'Yes', contacted: 'true', dispatched: 'true' },
    { fullName: 'Allen Yarbrough', date: '08/31/2022, 22:48:33', skillMatched: '5.8/10', promptReply: 'Yes', distanceFromLocation: '3.2 ml', workedHereBefore: 'Yes', preferedClient: 'Yes', contacted: 'true', dispatched: 'true' },
    { fullName: 'Allen Yarbrough', date: '08/31/2022, 22:48:33', skillMatched: '5.8/10', promptReply: 'Yes', distanceFromLocation: '3.2 ml', workedHereBefore: 'Yes', preferedClient: 'Yes', contacted: 'true', dispatched: 'true' },
    { fullName: 'Allen Yarbrough', date: '08/31/2022, 22:48:33', skillMatched: '5.8/10', promptReply: 'Yes', distanceFromLocation: '3.2 ml', workedHereBefore: 'Yes', preferedClient: 'Yes', contacted: 'true', dispatched: 'true' },
    { fullName: 'Allen Yarbrough', date: '08/31/2022, 22:48:33', skillMatched: '5.8/10', promptReply: 'Yes', distanceFromLocation: '3.2 ml', workedHereBefore: 'Yes', preferedClient: 'Yes', contacted: 'true', dispatched: 'true' },
    { fullName: 'Allen Yarbrough', date: '08/31/2022, 22:48:33', skillMatched: '5.8/10', promptReply: 'Yes', distanceFromLocation: '3.2 ml', workedHereBefore: 'Yes', preferedClient: 'Yes', contacted: 'true', dispatched: 'true' }
  ];

  const fillOrderPeopleCardData = [
    { profileImg: boyFaceImg, fullName: 'Allen Yarbrough', mobileNumber: '070-459-6550', ssn: '445-34-5678', date: '08/31/2022, 22:48:45' },
    { profileImg: boyFaceImg, fullName: 'Allen Yarbrough', mobileNumber: '060-459-7550', ssn: '445-34-5678', date: '08/31/2022, 22:48:45' },
    { profileImg: boyFaceImg, fullName: 'Allen Yarbrough', mobileNumber: '080-459-6552', ssn: '445-34-5678', date: '08/31/2022, 22:48:45' },
    { profileImg: boyFaceImg, fullName: 'Allen Yarbrough', mobileNumber: '090-459-8557', ssn: '445-34-5678', date: '09/13/2022, 22:48:45' },
  ];

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 5,
        title: "Jobs"
      }));
      setPageLoaded(true);
    }
  });

  useEffect(() => {
    setLatestLog(undefined);
    if (job) {
      if (isObjectArray(job.StatusLogs)) {
        let _latestLog = job.StatusLogs.slice(-1);
        if (_latestLog && Array.isArray(_latestLog) && _latestLog.length === 1) {
          setLatestLog(_latestLog[0]);
        }
      }
      if (job && isObjectArray(job.StatusLogs)) {

        let logs = [...job.StatusLogs];
        setLogCards(logs.reverse());
      }
      else {
        setLogCards(undefined);
      }
    }
    else {
      setLogCards(undefined);
    }
  }, [jobRequestStatus, job]);

  const _getPositionText = (id) => {
    if (positionOptions) {
      let position = positionOptions.find(p => p.value === id);
      if (position && position.text) {
        return position.text;
      }
    }
    return "N/A";
  }

  const exportClientPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = job.Title.Data + " dispatch details";
    const startDate = job && job.StartDate.Data ? job.StartDate.Data : "";
    const startTime = job && job.StartTime ? job.StartTime.Data : "";
    const supervisor = job.ReportTo.Data;
    const headers = [["Phone No", "Name", "SSN", "Start Date", "Start Time", "Supervisor Name", "Status", "Dispatch Description",]];





    const data = job.ApplicantDetails.map(elt => [elt.Phone, elt.Name, elt.SSN.slice(elt.SSN.length - 4), startDate, startTime, supervisor, elt.Status, _getPositionText(elt.Position) + " - " + elt.PositionDescription]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
      theme: 'grid'
    };

    var str = 'Page ' + doc.internal.getNumberOfPages()
    doc.setFontSize(10)
    var pageSize = doc.internal.pageSize
    var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
    doc.text(str, marginLeft, pageHeight - 10)
    doc.setFontSize(20)
    doc.text(title, marginLeft, 40);
    doc.setFontSize(15)
    doc.autoTable(content);
    doc.save(job.Title.Data + "_report.pdf")
  }




  const _getLocationText = (locations, id) => {
    if (isObjectArray(locations)) {
      let loc = locations.find(l => l.value === id);
      if (loc && loc.text) {
        return loc.text;
      }
    }
    return "N/A";
  }

  const onInActiveClicked = (e) => {
    let _e = { ...e };
    _e.target["name"] = "ckbJobStatus";
    onValueUpdated(_e, sections.JOB_DETAILS, !(job.Status.Data === "Inactive"));
  };

  const onValueUpdated = (e, section, value) => {
    let _jobObj = Object.assign({}, job);
    let _newJobObj = onValueChanged(_jobObj, e, section, value);
    dispatch(updateSingleData(_newJobObj));
  };

  const isAnyInvalidQuantitySet = (job) => {
    if (job && isObjectArray(job.Positions)) {
      const positions = job.Positions;
      for (let i = 0; i < positions.length; i++) {
        let position = positions[i];
        if (position) {
          if (position.Quantity.Data && position.Filled) {
            if (toInteger(position.Quantity.Data) < toInteger(position.Filled)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  const onCancelClicked = (e) => {
    setCreatemode(false);
    setEditmode(false);
    setSelectedTab(0);
    setCFInvalid(false);
    // setJFSubmitted(false);
    // if (tempJobId) {
    //   setTempJobId("");
    //   _loadJob(tempJobId, undefined);
    // }
    // else if (!(job && job.id)) {
    //   dispatch(updateSingleData(undefined));
    // }
    history.push('/jobOrder');
  };

  const onEditClicked = (e) => {
    e.preventDefault();
    setEditmode(true);
    setSelectedTab(0);
  };


  const onChangeCreateMode = (value) => {
    setCreatemode(value);
  }

  const onChangeEditMode = (value) => {
    setEditmode(value);
  }
  const onChangeCFInvalid = (value) => {
    setCFInvalid(value);
  }

  const childRef = useRef();

  const handleParentClick = (e, jobId, cMode) => {
    // Access the childRef.current and call the handleChildClick function
    if (childRef.current) {
      childRef.current.handleChildClick(e, jobId ? jobId : "", cMode);
    }
    else {
      setCFInvalid(true);
    }
  };

  const isAdmin = accountService.isAdmin();

  const onDeleteClicked = (e, id) => {
    e.preventDefault();
    if (id) {
      dispatch(show({
        id: id,
        title: messages.confirmAlert.title,
        content: messages.confirmAlert.content.removal('Job'),
        confirmedCallback: _removeJob
      }));
    }
  }
  const _removeJob = async (id) => {
    await dispatch(removeJob({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "DELETEJOB",
      payload: {
        id: id,
        data: ""
      }
    }));
    dispatch(refreshJobOptions());
  };

  useEffect(() => {
    if (applicantRemovalStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateRemovalStatus());
      history.push('../jobOrder');
    }
  }, [applicantRemovalStatus]);


  return (
    <>
      {(jobRequestStatus === fetchStatus.LOADING ||
        jobFormStatus === fetchStatus.LOADING ||
        creationStatus === fetchStatus.LOADING ||
        modificationStatus === fetchStatus.LOADING
      ) && <div className="app-container-loader">
          <GridLoader />
        </div>
      }

      {/* Filter Bar */}
      {/* <div className="filter-bar p-3">
            <button className="c-btn" type="button">All</button>
            <button className="c-btn c-btn-gray mx-1" type="button">Incomplete</button>
            <button className="c-btn c-btn-gray mx-1" type="button">In Progress</button>
            <button className="c-btn c-btn-gray mx-1" type="button">Available</button>
            <button className="c-btn c-btn-gray mx-1" type="button">Active</button>
            <button className="c-btn c-btn-gray mx-1" type="button">InActive</button>
            <button className="c-btn c-btn-gray mx-1" type="button">DNU</button>
            <button className="c-btn c-btn-gray mx-1" type="button">Dispatch</button>
            <button className="c-btn c-btn-gray mx-1" type="button">Called In</button>
            <button className="c-btn c-btn-gray mx-1" type="button">Full Time</button>
            <button className="c-btn c-btn-transparent" type="button">+ Create New List</button>
            <div className="float-end">
              <button type="button" className="c-btn dark-btn position-relative" onClick={() => setApplyMoreFilters(true)}>
                <i className="fa fa-filter" aria-hidden="true"></i> Filter
                <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                  <span className="visually-hidden">New alerts</span>
                </span>
              </button>
            </div>
          </div> */}

      {/* <div className="filter-bar p-3 pt-0">
            <div className="btn-group pr-2">
              <button className="btn btn-transparent btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuClickableOutside" data-bs-toggle="dropdown" data-bs-auto-close="inside" aria-expanded="false">
                Location
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickableOutside">
                <li><a className="dropdown-item" href="#">Menu item</a></li>
                <li><a className="dropdown-item" href="#">Menu item</a></li>
                <li><a className="dropdown-item" href="#">Menu item</a></li>
              </ul>
            </div>

            <div className="btn-group px-2">
              <button className="btn btn-transparent btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuClickableOutside" data-bs-toggle="dropdown" data-bs-auto-close="inside" aria-expanded="false">
                Work Status
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickableOutside">
                <li><a className="dropdown-item" href="#">Menu item</a></li>
                <li><a className="dropdown-item" href="#">Menu item</a></li>
                <li><a className="dropdown-item" href="#">Menu item</a></li>
              </ul>
            </div>

            <div className="btn-group px-2">
              <button className="btn btn-transparent btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                Shift
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
                <li><a className="dropdown-item" href="#">Menu item</a></li>
                <li><a className="dropdown-item" href="#">Menu item</a></li>
                <li><a className="dropdown-item" href="#">Menu item</a></li>
              </ul>
            </div>

            <div className="btn-group px-2">
              <button className="btn btn-transparent btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuClickable" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
                Position
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickable">
                <li><a className="dropdown-item" href="#">Menu item</a></li>
                <li><a className="dropdown-item" href="#">Menu item</a></li>
                <li><a className="dropdown-item" href="#">Menu item</a></li>
              </ul>
            </div>
          </div> */}
      {/* Filter Bar End */}



      {/* main section */}


      <div className="right-content">
        <div className="a-card-container">

          {/* Start c-horizontal-card */}
          <div className="c-horizontal-card pb-3">
            <div className="c-horizontal-card-container">
              <div className="c-horizontal-card-body">
                <div className="avatar">
                  <div className="avatar-box ">
                    <div className="avatar-box-text">{job ? job.Title.Data.slice(0, 2) : "Logo"}</div>
                  </div>
                </div>
                <div className="ps-3">
                  <div className="d-flex">
                    <div className="title">
                      {job ? job.Title.Data : ""}
                      <div className="badge ms-2"> {job ? job.Status.Data : ''} </div>

                    </div>
                    {editMode ?
                      <span className="job-checker-wrapper ms-3" onClick={(e) => onInActiveClicked(e)}>
                        <CheckBox
                          id="ckbJobStatus"
                          name="ckbJobStatus"
                          CheckBoxClasses="job-inactive-checker me-2"
                          checked={job ? (job.Status.Data === "Inactive") : false}
                          onClick={(c, e) => onValueUpdated(e, sections.JOB_DETAILS, c)} />
                        <span>Inactive</span>
                      </span>
                      : ''}
                  </div>
                  {/* <div className="sub-title"><span className="pe-1"><img src={mapLocationImg} alt="no photo" /></span>4139 Whiteman Street Manahawkin, NJ 08050</div> */}
                  <div className="sub-title"><span className="pe-1"><img src={mapLocationImg} alt="no photo" /></span>{job && job.Location.Data ? _getLocationText(locationOptions, job.Location.Data) : ""}</div>
                </div>
                {/* <div className="ms-auto align-self-center">
                      <div className="d-flex ">
                        <div className="flex-column">
                          <div className="sub-title pb-1">People Dispatched</div>
                          <AvatarGroup />
                        </div>
                        <div className="align-self-center">
                          <button className="c-btn dark-btn ms-3" type="button" ><img src={plusImg} /></button>
                        </div>
                      </div>
                      <button className="c-btn dark-btn" type="button" >Fill Order</button>
                    </div> */}
              </div>
            </div>
          </div>
          {/* End c-horizontal-card */}

          <div className="d-flex">
            <div className="ms-auto">
              {!editMode && !createMode ?
                <React.Fragment>
                  <div className='c-icon-box' onClick={(e) => onEditClicked(e)} data-tip='Edit'>
                    <i className='fa fa-pencil'></i>
                  </div>
                  <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                </React.Fragment>
                :
                !isAnyInvalidQuantitySet(job) ?
                  <SaveControls
                    formInvalid={CFInvalid}
                    onSaveClicked={(e) => handleParentClick(e, job ? job.id : "", createMode)}
                    onCancelClicked={(e) => onCancelClicked(e)}
                  />
                  : ''
              }
              {isAdmin && !createMode && job && job.Status && job.Status.Data === "New" &&
                <div className="c-icon-box" data-tip='Delete' onClick={(e) => onDeleteClicked(e, job ? job.id : "")}>
                  <i className="fa-solid fa-trash"></i>
                </div>
              }
            </div>
          </div>

          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className={`nav-link nav-link-people ${selectedTab === 1 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 1)}>Status Log</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={`nav-link nav-link-people ${selectedTab === 0 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 0)}>Job Details</button>
            </li>
            {!editMode && !createMode &&
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 2 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 2)}>Dispatch Details</button>
              </li>
            }
            {!editMode && !createMode &&
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 3 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 3)}>Preferred Applicant</button>
              </li>
            }
            {/* <li className="nav-item" role="presentation">
                  <button className="nav-link nav-link-people" id="people2-tab" data-bs-toggle="tab" data-bs-target="#people2" type="button" role="tab" aria-controls="people2" aria-selected="false">People2</button>
                </li> */}
            {/* <li className="nav-item" role="presentation">
                  <button className="nav-link nav-link-people" id="fillOrder-tab" data-bs-toggle="tab" data-bs-target="#fillOrder" type="button" role="tab" aria-controls="fillOrder" aria-selected="fillOrder">fillOrder</button>
                </li> */}
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className={`tab-pane fade ${selectedTab === 1 && "show active"}`} id="status-log">
              <div className="card a-card-wrap">
                {/* Start Data not found */}
                {/* <div className="data-not-found-container">
                      <div className="data-not-found">
                        <img src={noDataImg} />
                        <p className="title">No results found</p>
                        <p className="description">Try adjusting your search to find what you are looking for</p>
                      </div>
                    </div> */}
                {/* End Data not found */}
                <JobOrderStatusLog
                  statusLogs={logCards}
                />
              </div>
            </div>
            <div className={`tab-pane fade ${selectedTab === 0 && "show active"}`} id="job-details">
              <div className="card a-card-wrap">
                <JobOrderDetailsTab
                  editMode={editMode}
                  onChangeEditMode={onChangeEditMode}
                  onChangeCreateMode={onChangeCreateMode}
                  onChangeCFInvalid={onChangeCFInvalid}
                  createMode={createMode}
                  ref={childRef}
                />
              </div>
            </div>
            <div className={`tab-pane fade ${selectedTab === 2 && "show active"}`} id="dispatch-details">
              <div className="card a-card-wrap">
                {/* <JobOrderPeopleTab /> */}

                <div className='p-3'>
                  <button className="c-btn dark-btn" onClick={() => exportClientPDF()}> <i className="fa fa-download me-2" aria-hidden="true"></i>Client Export</button>

                  <table className="table table-hover c-table">
                    <thead>
                      <tr>
                        <th scope="col">Action</th>
                        <th scope="col">Status</th>
                        <th scope="col">Name</th>
                        <th scope="col">SSN</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Dispatch User</th>
                        <th scope="col">Position</th>
                        {/* <th scope="col">Position Description</th> */}
                        <th scope="col">Pay Rates</th>
                        <th scope="col">Flexibility</th>
                        <th scope="col">Job Type</th>
                        <th scope="col">Date Of Birth</th>
                        <th scope="col">Marital Status</th>
                        <th scope="col">Address</th>
                        <th scope="col">Dispatch Date</th>
                        <th scope="col">Post Dispatch Status</th>
                        <th scope="col">Active Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ApplicantDetail
                        positions={positionOptions}
                        details={job && job.ApplicantDetails}
                      />
                    </tbody>
                  </table>
                </div>

              </div>
            </div>

            <div className={`tab-pane fade ${selectedTab === 3 && "show active"}`} id="preferred-applicant">
              <div className="card a-card-wrap">
                <div className='p-3'>
                  <table className="table table-hover c-table">
                    <thead className="">
                      <tr>
                        <th scope="col">Action</th>
                        <th scope="col">Photo</th>
                        <th scope="col">Name</th>
                        <th scope="col">SSN</th>
                        <th scope="col">DOB</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Status</th>
                        <th scope="col">Flexibility</th>
                        <th scope="col">Repeat</th>
                      </tr>
                    </thead>
                    <tbody>
                      <PreferredApplicant
                        positions={positionOptions}
                        details={job && job.PreferredClient}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>



            {/* <div className="tab-pane fade" id="people2" role="tabpanel" aria-labelledby="people2-tab">
                  <div className="card a-card-wrap">
                    <div className="row m-0 p-3">
                      <div className="col-3 p-0">
                        <CardBottomLine heading="Contacted" number="10" color="#9661D2" />
                        <JobPeopleCard color="rgba(150, 97, 210, 0.12)" />
                        <JobPeopleCard color="rgba(150, 97, 210, 0.12)" />
                        <JobPeopleCard color="rgba(150, 97, 210, 0.12)" />
                        <JobPeopleCard color="rgba(150, 97, 210, 0.12)" />
                        <JobPeopleCard color="rgba(150, 97, 210, 0.12)" />
                      </div>
                      <div className="col-3 p-0">
                        <CardBottomLine heading="Accepted" number="" color="#13AC95" />
                        <JobPeopleCard color="rgba(19, 172, 149, 0.12)" />
                        <JobPeopleCard color="rgba(19, 172, 149, 0.12)" />
                        <JobPeopleCard color="rgba(19, 172, 149, 0.12)" />
                        <JobPeopleCard color="rgba(19, 172, 149, 0.12)" />
                      </div>
                      <div className="col-3 p-0">
                        <CardBottomLine heading="Dispatched" number="3" color="#E38936" />
                        <JobPeopleCard color="rgba(227, 137, 54, 0.12)" />
                        <JobPeopleCard color="rgba(227, 137, 54, 0.12)" />
                        <JobPeopleCard color="rgba(227, 137, 54, 0.12)" />
                      </div>
                      <div className="col-3 p-0">
                        <CardBottomLine heading="Active" number="2" color="#2D95F0" />
                        <JobPeopleCard color="rgba(45, 149, 240, 0.2)" />
                        <JobPeopleCard color="rgba(45, 149, 240, 0.2)" />
                      </div>
                    </div>
                  </div>
                </div> */}

            {/* <div className="tab-pane fade" id="fillOrder" role="tabpanel" aria-labelledby="fillOrder-tab">
                  <div className="card a-card-wrap">
                    <div className='p-3'>
                      <div className="title">Fill Order</div>
                      <div className="sub-title">Lorem ipsumLorem ipsum</div>
                      <div className="row">
                        <div className="col-3">
                          <div className="fill-order-card h-100">
                            <InputGroup
                              label={'Location'}
                              control={
                                <TextBox />
                              }
                            />
                            <InputGroup
                              label={'Client'}
                              control={
                                <TextBox />
                              }
                            />
                            <InputGroup
                              label={'Job'}
                              control={
                                <Selector
                                  id="drpJob"
                                  name="drpJob"
                                  SelectorClasses="c-form-control"
                                  options={
                                    <React.Fragment>
                                      <option value="1">All</option>
                                      <option value="2">option 2</option>
                                      <option value="3">option 3</option>
                                    </React.Fragment>
                                  }
                                />
                              }
                            />

                            {
                              fillOrderPeopleCardData.map((d) => {
                                return <FillOrderPeopleCard profileImg={d.profileImg} fullName={d.fullName} mobileNumber={d.mobileNumber} ssn={d.ssn} date={d.date} />
                              })
                            }

                          </div>
                        </div>
                        <div className="col-9">
                          <div className="d-flex rectangle-wrapper p-3">
                            <div className="pe-5">
                              <div className="c-label">Title</div>
                              <div className="c-label bold">AA10: Aaron Thomas</div>
                            </div>
                            <div className="pe-5">
                              <div className="c-label">Title</div>
                              <div className="c-label bold">Production (Light)</div>
                            </div>
                            <div className="pe-5">
                              <div className="c-label">Report To</div>
                              <div className="c-label bold">Christopher Maldonado</div>
                            </div>
                            <div className="pe-5">
                              <div className="c-label">Pay Rate</div>
                              <div className="c-label bold">$ 14.00</div>
                            </div>

                          </div>
                          <div className="p-2">
                            <table className="table c-table">
                              <thead>
                                <tr>
                                  <th scope="col">Name</th>
                                  <th scope="col"><img src={clockCalendarImg} className="pe-1" alt="no photo" />Date</th>
                                  <th scope="col"><img src={userSkillImg} className="pe-1" alt="no photo" />Skill Matched</th>
                                  <th scope="col"><img src={replyImg} className="pe-1" alt="no photo" />Prompt Reply</th>
                                  <th scope="col"><div className="d-flex"><img src={mapLocationImg} className="pe-1" alt="no photo" />Distance from<br /> Location</div></th>
                                  <th scope="col"><div className="d-flex"><img src={clockCalendarImg} className="pe-1" alt="no photo" />Worked here<br /> Before</div></th>
                                  <th scope="col"><div className="d-flex"><img src={useHeartImg} className="pe-1" alt="no photo" />Prefered<br />Client</div></th>
                                  <th scope="col"><img src={handshakeImg} className="pe-1" alt="no photo" />Contacted</th>
                                  <th scope="col"><img src={dispatchedImg} className="pe-1" alt="no photo" />Dispatched</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  fillOrderData.map((d) => {
                                    return <tr>
                                      <td className="py-2">
                                        <div className="d-flex">
                                          <Avatar />
                                          <div className="align-self-center p-2">
                                            {d.fullName}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="py-2">{d.date}</td>
                                      <td className="py-2"><div className="c-badge bg-purple">{d.skillMatched}</div></td>
                                      <td className="py-2"><div className="c-badge bg-dark-blue">{d.promptReply}</div></td>
                                      <td className="py-2"><div className="c-badge bg-light-orange">{d.distanceFromLocation}</div></td>
                                      <td className="py-2"><div className="c-badge c-badge-green ">{d.workedHereBefore}</div></td>
                                      <td className="py-2"><div className="c-badge bg-blue">{d.preferedClient}</div></td>
                                      <td className="py-2"><div className="c-circle bg-green text-white ">{d.contacted == 'true' ? <i className='fa fa-check'></i> : 'N'}</div></td>
                                      <td className="py-2"><div className="c-circle bg-orange text-white ">{d.dispatched == 'true' ? <i className='fa fa-check'></i> : 'N'}</div></td>
                                    </tr>
                                  })
                                }
                              </tbody>
                            </table>

                            <div className="d-flex justify-content-between">
                              <div className="c-table-result">Showing 1 to 8 of 24 entries</div>
                              <div className="d-flex" >
                                <ul className="c-pagination">
                                  <li><a className="" href="#"><img src={leftArrowImg} /></a></li>
                                  <li><a href="#" className="active">1</a></li>
                                  <li><a href="#">2</a></li>
                                  <li><a href="#">3</a></li>
                                  <li><a href="#"><img src={rightArrowImg} /></a></li>
                                </ul>
                              </div>
                            </div>

                            <div className="float-end pt-3">
                              <button className="c-btn white-btn mx-2" type="button" >Cancel</button>
                              <button className="c-btn dark-btn" type="button" >Save</button>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default JobOrderEdit;