import React from 'react';

function YesButton(props) {
    return (
        <button
            type="button"
            className="c-btn dark-btn me-2"
            title={props.toolTipText ? props.toolTipText : "Accept changes"}
            onClick={(e) => props.onYesClicked && props.onYesClicked(e)}>
            <i className='fa fa-check me-2'></i>Yes
        </button>
    );
}

export default YesButton;