import {
    createSlice, createAsyncThunk, nanoid
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    messagetemplatelist: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    templateOptions: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
};

export const getAllTemplates = createAsyncThunk('messagetemplates/getAllTemplates', async (messagetemplateModel, { rejectWithValue }) => {
    //console.log("Inside Getall")
    const response = await BajoAPI.post('Gateway', messagetemplateModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
   
    let templates;
    if (isJSON(data)) {
        templates = JSON.parse(data);
    }
    return {
        page: messagetemplateModel.page,
        templates: templates,
        success: response.data.success
    };
});

export const getTemplateById = createAsyncThunk('messagetemplates/getTemplateById', async (model) => {
    
    const response = await BajoAPI.post('Gateway', model, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    
    let message;
    if (isJSON(data)) {
        message = JSON.parse(data);
    }
    return message;
});

// export const getTemplateOptions = createAsyncThunk('messagetemplates/getAllTemplates', async (messagetemplateModel, { rejectWithValue }) => {
//     const response = await BajoAPI.post('Gateway', messagetemplateModel, getAxiosRequestConfig());
//     const data = response.data ? response.data.data : "[]";
//     let options;
//     if (isJSON(data)) {
//         options = JSON.parse(data);
//     }
//     return {
//         options: options,
//         success: response.data.success
//     };
// });





export const messagetemplateSlice= createSlice({
    name : 'messagetemplates',
    initialState,
    reducers:{
        updateStatus: (state) => {
            state.messagetemplatelist.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        

    },

    extraReducers: (builder) => {
        builder
        .addCase(getAllTemplates.pending, (state, action) => {
            state.messagetemplatelist.status = fetchStatus.LOADING;
        }).addCase(getAllTemplates.fulfilled, (state, action) => {
            state.messagetemplatelist.data = action.payload.templates;
            state.messagetemplatelist.status = fetchStatus.SUCCEEDED;
           
        }).addCase(getAllTemplates.rejected, (state, action) => {
            state.messagetemplatelist.status = fetchStatus.FAILED;
            state.messagetemplatelist.error = action.error.message;
           
        })
        .addCase(getTemplateById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getTemplateById.fulfilled, (state, action) => {
            state.single.data = action.payload;
            state.single.status = fetchStatus.SUCCEEDED;
         
        }).addCase(getTemplateById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
           
        })
        
    }

});

export default messagetemplateSlice.reducer
console.log("Before SelectAllTemplate")
export const selectAllTemplates = state => state.messagetemplates.messagetemplatelist.data ? state.messagetemplates.messagetemplatelist.data : undefined ;
