import React, { useEffect, useRef, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';

function SelectorSearch(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (props.required) {
            if (edited || props.submited) {
                if (!value) {
                    setInvalid(true);
                }
                else {
                    setInvalid(false);
                }
            }
            else {
                setInvalid(false);
            }
            setEdited(false);
        }

    }, [value, props.required, edited, props.submited]);

    const searchData = props.options;
    let listObj;
    const fields = { text: 'text', value: 'value' };
    
    function onFiltering(e) {
        let query = new Query();
        query = (e.text !== '') ? query.where('text', 'startswith', e.text, true) : query;
    }

    const onChange = (e) => {
        // e.preventDefault();
        let _e = { ...e };
        if (_e.e && _e.e.target) {
            _e.e.target["name"] = props.name;
            props.onChange && props.onChange(e.e, listObj.value);
        }
        // setValue(e.target.value);
        // setEdited(true);
    }

    return (
        <>
            <DropDownListComponent
                id={props.id}
                name={props.name}
                ref={(dropdownlist) => { listObj = dropdownlist; }}
                dataSource={searchData}
                filtering={onFiltering.bind(this)}
                filterBarPlaceholder='Search...'
                allowFiltering={true}
                fields={fields}
                placeholder="---Select---"
                // popupHeight="220px"
                value={value}
                change={onChange.bind(this)}
            />
            {
                invalid && <span className="error-label">
                    <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            }
        </>
    );
}

export default SelectorSearch;