import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoButton from '../custom/buttons/noButton';
import YesButton from '../custom/buttons/yesButton';
// import NoButton from '../buttons/noButton';
// import YesButton from '../buttons/yesButton';

// import './css/confirmAlert.css';
import { close, selectConfirmAlertDetail } from './reducerSlices/confirmAlertSlice';

function ConfirmAlert(props) {

    const dispatch = useDispatch();

    const confirmAlertDetails = useSelector(selectConfirmAlertDetail);

    const onCloseClicked = () => {
        dispatch(close());
    };

    const onNoClicked = () => {
        dispatch(close());
    };

    const onYesClicked = () => {
        confirmAlertDetails.confirmedCallback(confirmAlertDetails.id);
        dispatch(close());
    };

    return (
        <React.Fragment>
            {(confirmAlertDetails && confirmAlertDetails.show) ? (<React.Fragment>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{confirmAlertDetails.title}</h5>
                                <button
                                    type="button"
                                    className="modal-close-button"
                                    onClick={onCloseClicked}>
                                    <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {confirmAlertDetails.content}
                            </div>
                            <div className="modal-footer">
                                <NoButton
                                    onNoClicked={onNoClicked} />
                                <YesButton
                                    onYesClicked={onYesClicked} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </React.Fragment>) : <React.Fragment></React.Fragment>}
        </React.Fragment>
    );
}

export default ConfirmAlert;