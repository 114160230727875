import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../api/client';
import { toLocalDate } from '../../common/common';
import { accountService } from '../../features/accounts/services/accountService';
import DataNotFoundCard from '../Card/DataNotFoundCard';
import Statement from '../Controls/custom/textbox/statement';
import { isFormValid } from '../Controls/services/formService';
import ReactTooltip from 'react-tooltip';
import { saveNote, selectLeadById, selectNoteStatus, updateSingleData } from '../../features/crm/reducerSlices/leadSlice';
import { onNoteChanged } from '../../features/crm/services/noteSaver';
import { beforeCloseLeadNoteFlyout, closeLeadNoteFlyout, saveUpdatedLead, selectFlyoutDetail } from './reducerSlices/leadNoteFlyoutSlice';
import SortButton from '../Controls/custom/buttons/sortButton';
import AddButton from '../Controls/custom/buttons/addButton';
import EllipsisLoader from '../Controls/loaders/ellipsisLoader';

function LeadNoteFlyout() {

    const dispatch = useDispatch();
    const flyoutDetails = useSelector(selectFlyoutDetail);

    const ToggleClass = () => {
        dispatch(beforeCloseLeadNoteFlyout());

        const timer = setTimeout(() => {
            dispatch(closeLeadNoteFlyout());
        }, 1500);
    };

    const [note, setNote] = useState("");
    const [notesList, setNotesList] = useState([]);

    const LEAD_NOTE_FORM = "LeadNoteForm";
    const noteStatus = useSelector(selectNoteStatus);

    const [lnfSubmitted, setLNFSubmitted] = useState(false);
    const [sort, setSort] = useState(false);
    const lead = useSelector(selectLeadById);

    const onSaveClicked = async (id) => {
        const user = accountService.getAuthenticatedUser();
        if (isFormValid(LEAD_NOTE_FORM)) {
            const noteDetails = {
                note: note,
                log: {
                    TimeStamp: (new Date()).toUTCString(),
                    LogType: "Notes",
                    LogValue: -1,
                    Content: note,
                    UserName: (user ? user.firstName + " " + user.lastName : ""),
                }
            };
            let _leadObj = Object.assign({}, lead);
            let _newLeadObj = onNoteChanged(_leadObj, noteDetails);
            dispatch(saveUpdatedLead(_newLeadObj));
            setLNFSubmitted(false);
            if (id) {
                await dispatch(saveNote({
                    client: tenantId,
                    serviceType: "TENANTADMIN",
                    operation: "SAVENOTE_LEAD",
                    payload: {
                        id: id,
                        data: JSON.stringify(noteDetails)
                    }
                }));

            }
        }
        else {
            setLNFSubmitted(true);
        }
    };



    useEffect(() => {
        let _logs;
        let _noteDiv;
        let _noteArr = [];

        if (lead && lead.StatusLogs) {
            _logs = lead.StatusLogs.map((log, index) => {
                if (log.LogValue === -1) {
                    _noteDiv = <div className="tracking-item">
                        <div className="tracking-icon status-intransit bg-blue">
                            <span><i className="fa fa-pen-to-square fa-md" aria-hidden="true"></i></span>
                        </div>
                        <div className="tracking-content">
                            <p>
                                <span className="p-name">{log.UserName}</span>
                            </p>
                            <p className="p-subtext">{log.Content}</p>
                            <span className="p-light-gray">{log.TimeStamp && toLocalDate(log.TimeStamp)}</span>
                        </div>
                    </div>
                    _noteArr.push(_noteDiv);
                }
            });

            setNotesList(_noteArr.reverse());
        }
    }, [lead]);

    const onSort = () => {
        setSort(!sort);
        const sortData = [...notesList].reverse();
        setNotesList(sortData);
    }

    const onEnterPress = (e) => {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            onSaveClicked(lead ? lead.id : "")
        }
    }

    useEffect(() => {
        if (noteStatus === fetchStatus.SUCCEEDED && note !== '') {
            if (flyoutDetails.updatedLead && flyoutDetails.updatedLead.id) {
                dispatch(updateSingleData(flyoutDetails.updatedLead));
            }
            setNote("");
            ToggleClass();
        }
    }, [noteStatus]);

    return (
        <>
            {(flyoutDetails && flyoutDetails.show) ?
                <>
                    <div className="flyout-wrapper position-fixed top-0 end-0">
                        <div className={`flyout-card ${flyoutDetails.isActive ? 'close' : 'open'}`}>
                            <div className="flyout-card-body overflow-visible">
                                <div className="float-end">
                                    <div className="close-btn">
                                        <i className="fa fa-times" aria-hidden="true" onClick={ToggleClass} data-tip='Close'></i>
                                        <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                    </div>
                                </div>
                                <div className="flyout-card-title">Add Note</div>
                                <div className="flyout-card-sub-title">
                                    {lead && lead.LeadName ? lead.LeadName.Data : ""}
                                </div>
                                <label className="flyout-label pt-3">Notes</label>
                                <Statement
                                    id="txtAdminNote"
                                    name="txtAdminNote"
                                    value={note}
                                    StatementClasses="flyout-input"
                                    rows={2}
                                    onChange={(e) => setNote(e.target.value)}
                                    required={true}
                                    errorMessage="Please enter note"
                                    form={LEAD_NOTE_FORM}
                                    submited={lnfSubmitted}
                                    placeholder="Enter note..."
                                    onKeyDown={onEnterPress}
                                />
                                <div className="d-flex align-items-baseline pt-3">
                                    <div className="ms-auto">
                                        {(noteStatus === fetchStatus.LOADING) ? <EllipsisLoader />
                                            : <>
                                                <AddButton
                                                    buttonText={'Add Note'}
                                                    onAddClicked={() => onSaveClicked(lead ? lead.id : "")} />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className="flyout-card-filter-bar d-flex align-items-baseline">
                                <div className="flyout-card-semi-title">
                                    Recent Notes
                                </div>
                                <div className="ms-auto">
                                    <SortButton
                                        SortButtonClasses="bg-white mx-2"
                                        sorted={sort}
                                        onSortClicked={(e) => onSort(e)}
                                    />
                                </div>
                            </div>

                            <div className="flyout-card-body">
                                {Object.keys(notesList).length === 0 ?
                                    <DataNotFoundCard containerClassOverriding={`data-not-found-container-100 pt-5 mt-5`} title={'No notes found '} />
                                    :
                                    <div className="tracking-list pt-3">
                                        {notesList}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='flyout-backdrop flyout-backdrop-show' />
                </>
                :
                <></>
            }
        </>
    )
}

export default LeadNoteFlyout