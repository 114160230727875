import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../../../api/client';
import GridLoader from '../../../../component/Controls/loaders/gridLoader';
import { createNewPosition, getAllPositions, getPositionById, removePosition, selectAllPositions, selectCreationStatus, selectModificationStatus, selectRemovalStatus, selectStatus, showPositionFlyout, updatePositionsOptionsStatus, updateSingleData, updateStatus } from '../../reducerSlices/positionsSlice';
import ReactPlaceholder from 'react-placeholder';
import { Filter, GridComponent, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';
import ReactTooltip from 'react-tooltip';
import { messages } from '../../../../common/actionMessages';
import { show } from '../../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';
import { isObjectArray } from '../../../../utilities/utilityFunctions';


function Positions() {

    const dispatch = useDispatch();

    const whereClause = {
        name: ""
    };

    const [dummyCards, setDummyCards] = useState([]);

    const positionsCreationStatus = useSelector(selectCreationStatus);
    const positionsModificationStatus = useSelector(selectModificationStatus);
    const positionsRemovalStatus = useSelector(selectRemovalStatus);

    const positions = useSelector(selectAllPositions);

    const positionsRequestStatus = useSelector(selectStatus);


    useEffect(() => {
        if (positionsRequestStatus === fetchStatus.IDLE) {
            _loadPositions(0, 0, 10, whereClause);
        }
    }, [positionsRequestStatus, dispatch]);

    let _dummyCards = [];

    useEffect(() => {
        if (positionsRequestStatus === fetchStatus.SUCCEEDED) {
            dispatch(updateStatus());
        }
    }, [positionsRequestStatus]);

    useEffect(() => {
        if (positionsRequestStatus === fetchStatus.LOADING) {
            for (let i = 0; i < 10; i++) {
                _dummyCards.push(
                    <div className="list-loading-content">
                        <ReactPlaceholder type='text' ready={false} rows={2} color='#E0E0E0'>
                        </ReactPlaceholder>
                    </div>
                );
            }
            setDummyCards(_dummyCards);
        }
    }, [positionsRequestStatus]);

    useEffect(() => {
        if (positionsRemovalStatus === fetchStatus.SUCCEEDED) {
            _loadPositions(0, 0, 10, whereClause);
            if (positions && positions.Items && positions.Items.length > 0) {
                let item = positions.Get(0);
                let _positions = item ? item.records : [];
                if (_positions && Array.isArray(_positions) && _positions.length > 0) {
                    let _positions = _positions[0];
                    if (_positions && _positions.id) {
                        _loadPosition(_positions.id, 0);
                    }
                }
                else {
                    dispatch(updateSingleData(undefined));
                }
            }
            else {
                dispatch(updateSingleData(undefined));
            }

            dispatch(updatePositionsOptionsStatus());
        }
    }, [positionsRemovalStatus]);

    const _loadPosition = async (id, page) => {
        await dispatch(getPositionById({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETPOSITIONBYID",
                payload: {
                    id: id,
                    data: ""
                }
            }
        }));
    }

    // const _loadForm = async () => {
    //     await dispatch(getClientForm({
    //         client: tenantId,
    //         serviceType: "TENANTADMIN",
    //         operation: "GETFORMBYTYPE",
    //         payload: {
    //             id: "",
    //             data: "ClientForm"
    //         }
    //     }));
    // }

    const _loadPositions = async (page, offset, limit, whereClause) => {
        await dispatch(getAllPositions({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETPOSITIONS",
                payload: {
                    id: "",
                    data: ""
                }
            }
        }));
    }

    const onPositionsSelected = (e, id) => {
        e.preventDefault();
        _loadPosition(id, undefined);
        dispatch(showPositionFlyout({
            createMode: false,
            editMode: true
        }));
    };

    const onDeleteClicked = (e, id) => {
        e.preventDefault();
        if (id) {
            dispatch(show({
                id: id,
                title: messages.confirmAlert.title,
                content: messages.confirmAlert.content.removal('Positions'),
                confirmedCallback: _removePosition
            }));
        }
    };

    const onAddRecordClicked = (e) => {
        dispatch(createNewPosition());

        dispatch(showPositionFlyout({
            createMode: true,
            editMode: false
        }));
    };

    const _removePosition = async (id) => {
        await dispatch(removePosition({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "DELETEPOSITION",
            payload: {
                id: id,
                data: ""
            }
        }))
    };

    useEffect(() => {
        if (positionsCreationStatus === fetchStatus.SUCCEEDED) {
            dispatch(updatePositionsOptionsStatus());
            _loadPositions(0, 0, 10, whereClause);
        }
    }, [positionsCreationStatus]);

    useEffect(() => {
        if (positionsModificationStatus === fetchStatus.SUCCEEDED) {
            dispatch(updatePositionsOptionsStatus());
            _loadPositions(0, 0, 10, whereClause);
        }
    }, [positionsModificationStatus]);

    const addActionTemplate = (props) => {
        return <>
            <i className='fa fa-pencil' data-tip='Edit' title='Edit' onClick={(e) => onPositionsSelected(e, props.id)}></i>
            <i className='fa fa-trash ms-3' data-tip='Delete' title='Delete' onClick={(e) => onDeleteClicked(e, props ? props.id : "")}></i>
            <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
        </>
    }
    const gridRef = useRef(null);
    useEffect(() => {
        if (gridRef.current) {
            gridRef.current.refresh();
        }
    }, [positions, positionsRequestStatus]);

    const columns = [
        { field: 'PositionName', headerText: 'Position Name', width: '100' },
        {
            headerText: 'Action',
            template: addActionTemplate,
            width: '50px',
        },
    ];


    return (
        <>
            {(positionsRequestStatus === fetchStatus.LOADING ||
                positionsRemovalStatus === fetchStatus.LOADING
            ) &&
                <div className="app-container-loader">
                    <GridLoader />
                </div>
            }
            <div className="row">
                <div className="col-md-6">
                    <span className="detail-container-title">Positions</span>
                </div>
                <div className="col-md-6 text-end">
                    <button className="c-btn dark-btn" type="button" data-tip='Create new position' onClick={(e) => onAddRecordClicked(e)}>
                        <i className="fa fa-plus"></i>
                    </button>
                </div>
            </div>
            <div className="row p-3">
                <GridComponent ref={gridRef} dataSource={positions} allowPaging={true} allowSorting={true} allowResizing={true} width="100%" toolbar={['Search']} pageSettings={{ pageSize: 10, pageCount: 5 }} rowHeight={50} columns={columns} >
                    <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                </GridComponent>
            </div>
        </>
    )
}

export default Positions