export const routeTrackingStatusData = [
    {
        text: "Completed",
        value: "Completed"
    },
    {
        text: "In Progress",
        value: "In Progress"
    },
    {
        text: "New",
        value: "New"
    }
];