import { createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { Operation } from '../../../common/constants';
import Collection from '../../../component/Controls/paginators/collection';
import { refreshCreated } from '../../../component/Controls/paginators/services/createdResource';
import { refreshRemoved } from '../../../component/Controls/paginators/services/removedResource';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    form: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    statusOptions: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    removal: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    },
    show: false,
    createMode: false,
    editMode: false,
};


export const getStatusForm = createAsyncThunk('statuses/getStatusForm', async (statusModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, statusModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let form = undefined;
        if (data && isJSON(data)) {
            form = JSON.parse(data);
        }
        return {
            form: form,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getStatusOptionsAll = createAsyncThunk('locations/getStatusOptionsAll', async (statusesModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', statusesModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let options;
    if (isJSON(data)) {
        options = JSON.parse(data);
    }
    return {
        options: options,
        success: response.data.success
    };
});

export const getAllStatuses = createAsyncThunk('statuses/getAllStatuses', async (statusesModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', statusesModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let statuses;
        if (isJSON(data)) {
            statuses = JSON.parse(data);
        }
        return {
            page: statusesModel.page,
            statuses: statuses,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getStatusById = createAsyncThunk('statuses/getStatusById', async (statusesModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, statusesModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let statuses = undefined;
        if (data && isJSON(data)) {
            statuses = JSON.parse(data);
        }
        return {
            page: statusesModel.page,
            statuses: statuses,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const createStatus = createAsyncThunk('statuses/createStatus', async (statusesModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, statusesModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let statuses = undefined;
        if (data && isJSON(data)) {
            statuses = JSON.parse(data);
        }
        return {
            statuses: statuses,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const updateStatuses = createAsyncThunk('statuses/updateStatuses', async (statusesModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, statusesModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let statuses = undefined;
        if (data && isJSON(data)) {
            statuses = JSON.parse(data);
        }
        return {
            statuses: statuses,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const removeStatus = createAsyncThunk('statuses/removeStatus', async (statusesModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, statusesModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        return {
            id: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});


export const statuseslice = createSlice({
    name: 'statuses',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateRemovalStatus: (state) => {
            state.removal.status = fetchStatus.IDLE;
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateSingleData: (state, _data) => {
            state.single.refreshed = nanoid();
            state.single.data = _data.payload ? Object.assign({}, _data.payload.status) : _data.payload;
            if (_data.payload && _data.payload.status) {
                if (_data.payload.operation === Operation.UPDATED) {
                    let _item = state.data.records.Get(state.data.currentPage);
                    if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                        let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.statuses.id);
                        let newRecords = _item.records;
                        let _statuses = {
                            id: _data.payload.statuses.id,
                            StatusesName: _data.payload.statuses.StatusesName.Data,
                            StatusesNumber: _data.payload.statuses.StatusesNumber.Data,
                            Status: _data.payload.statuses.Status.Data,
                            Location: _data.payload.statuses.Location.Data
                        };
                        newRecords[_updatedObjectIndex] = _statuses;
                        _item.records = newRecords;
                        let records = new Collection();
                        records.Add(_item.key, newRecords);
                        state.data.records.Remove(_item.key);
                        records.Concat(state.data.records);
                        state.data.records = records;
                    }
                }
            }
        },
        createNewStatus: state => {
            state.single.data = {
                "StatusName": {
                    "Id": "txtStatusName",
                    "Data": ""
                },
                "PrimaryStatus": {
                    "Id": "drpPrimaryStatus",
                    "Data": ""
                },
                "Status": {
                    "Id": "drpStatus",
                    "Data": ""
                },
                "isDefaultStatusName": false
            };
        },
        showStatusFlyout: (state, action) => {
            const { createMode, editMode } = action.payload;
            state.createMode = createMode;
            state.editMode = editMode;
            state.show = true;
        },
        closeStatusFlyout: (state, action) => {
            state.createMode = false;
            state.editMode = false;
            state.show = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllStatuses.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        })
        .addCase(getAllStatuses.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.statuses.totalItems;
            state.data.items = action.payload.statuses.items;
            state.data.isFiltered = false;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        })
        .addCase(getAllStatuses.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.data.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getStatusById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getStatusById.fulfilled, (state, action) => {
            state.single.data = action.payload.statuses;
            if (action.payload.page >= 0) {
                let _records = new Collection();
                _records.Add(action.payload.page, action.payload.statuses);
                _records.Concat(state.data.firstRecords);
                state.data.firstRecords = _records;
            }
            state.single.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getStatusById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(createStatus.pending, (state, action) => {
            state.creation.status = fetchStatus.LOADING;
        }).addCase(createStatus.fulfilled, (state, action) => {
            if (action.payload.status) {
                state.single.data = action.payload.status;
                let _status = {
                    id: action.payload.status.id,
                    StatusName: action.payload.status.StatusName.Data,
                    PrimaryStatus: action.payload.status.PrimaryStatus.Data,
                    Status: action.payload.status.Status.Data
                };
                const created = refreshCreated(state.data.records, _status);
                state.data.records = created.records;
                state.data.currentPage = created.lastPage;
                state.data.totalItems += 1;
                state.single.refreshed = nanoid();
            }
            state.creation.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createStatus.rejected, (state, action) => {
            state.creation.status = fetchStatus.FAILED;
            state.creation.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateStatuses.pending, (state, action) => {
            state.modification.status = fetchStatus.LOADING;
        }).addCase(updateStatuses.fulfilled, (state, action) => {
            state.single.data = action.payload.statuses;
            state.modification.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateStatuses.rejected, (state, action) => {
            state.modification.status = fetchStatus.FAILED;
            state.modification.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(removeStatus.pending, (state, action) => {
            state.removal.status = fetchStatus.LOADING;
        }).addCase(removeStatus.fulfilled, (state, action) => {
            const removed = refreshRemoved(state.data.records, action.payload.id, state.data.currentPage);
            state.data.records = removed.records;
            state.data.currentPage = removed.lastPage;
            state.data.totalItems -= 1;
            state.single.refreshed = nanoid();
            state.removal.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(removeStatus.rejected, (state, action) => {
            state.removal.status = fetchStatus.FAILED;
            state.removal.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getStatusForm.pending, (state, action) => {
            state.form.status = fetchStatus.LOADING;
        }).addCase(getStatusForm.fulfilled, (state, action) => {
            state.form.data = action.payload.form;
            state.form.status = fetchStatus.SUCCEEDED;
        }).addCase(getStatusForm.rejected, (state, action) => {
            state.form.status = fetchStatus.FAILED;
            state.form.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getStatusOptionsAll.pending, (state, action) => {
            state.statusOptions .status = fetchStatus.LOADING;
        }).addCase(getStatusOptionsAll.fulfilled, (state, action) => {
            let _options = action.payload.options;
            _options = _options.map(function (option) {
                option['value'] = option['id'];
                delete option['id'];
                option['text'] = option['StatusName'];
                delete option['StatusName'];
                option['primaryStatus'] = option['PrimaryStatus'];
                delete option['PrimaryStatus'];
                return option;
            });
            state.statusOptions.options = _options;
            state.statusOptions.status = fetchStatus.SUCCEEDED;
            state.statusOptions.refreshed = false;
            toaster.success(action.payload.success);
        }).addCase(getStatusOptionsAll.rejected, (state, action) => {
            state.statusOptions.status = fetchStatus.FAILED;
            state.statusOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });

    }
});

export const { updateCreationStatus, updateModificationStatus, updateRemovalStatus,
    updateSingleStatus, updateIsFiltered, updateStatus, loadSingleData,
    updateCurrentPage, updateSingleData, createNewStatus, showStatusFlyout, closeStatusFlyout } = statuseslice.actions;

export default statuseslice.reducer


export const selectAllStatuses = state => state.statuses.data.items;
export const selectTotalItems = state => state.statuses.data.totalItems;
export const selectStatus = state => state.statuses.data.status;

export const selectCreationStatus = state => state.statuses.creation.status;

export const selectModificationStatus = state => state.statuses.modification.status;

export const selectRemovalStatus = state => state.statuses.removal.status;


export const selectStatusById = (state) => {
    return state.statuses.single ? state.statuses.single.data : undefined;
}
export const selectSingleStatus = state =>  state.statuses.single.status;

export const selectStatusFormStatus = state => state.statuses.form.status;
export const selectStatusFormError = state => state.statuses.form.error;
export const selectStatusForm = state => state.statuses.form.data;

export const selectStatusOptions = state => state.statuses.statusOptions.options;
export const selectStatusOptionsStatus = state => state.statuses.statusOptions.status;
export const selectStatusOptionsRefreshed = state => state.statuses.statusOptions.refreshed;

export const selectStatusFlyoutShow = state => state.statuses.show;
export const selectStatusFlyoutCreateMode = state => state.statuses.createMode;
export const selectStatusFlyoutEditmode= state => state.statuses.editMode;