import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _getLocationText } from '../../common/common';
import ShortText from '../Controls/custom/textbox/shortText';
import { isFormValid } from '../Controls/services/formService';
import { onValueChanged, sections } from '../../features/setting/services/userSaver';
import Selector from '../Controls/custom/selectors/selectors';
import { selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from '../../features/locations/reducerSlices/locationSlice';
import { isObjectArray } from '../../utilities/utilityFunctions';
import { fetchStatus, tenantId } from '../../api/client';
import { userRoles, userStatuses } from '../../features/shared/services/masterData';
import { nanoid } from '@reduxjs/toolkit';
import PhoneText from '../Controls/custom/textbox/phoneText';
import { closeUserFlyout, createUser, getUserForm, selectCreationStatus, selectModificationStatus, selectSingleStatus, selectUserById, selectUserFlyoutCreateMode, selectUserFlyoutEditmode, selectUserFlyoutShow, selectUserForm, selectUserFormStatus, updateSingleData, updateUser } from '../../features/setting/reducerSlices/userSlice';
import Moment from 'moment';
import EmailText from '../Controls/custom/textbox/emailText';
import PasswordText from '../Controls/custom/textbox/passwordText';
import ReactTooltip from 'react-tooltip';
import EllipsisLoader from '../Controls/loaders/ellipsisLoader';
import InputGroup from '../Controls/custom/inputGroup/inputGroup';
import { accountService } from '../../features/accounts/services/accountService';

function UserFlyout(props) {

    const dispatch = useDispatch();
    const userFlyoutShow = useSelector(selectUserFlyoutShow);

    const USER_FORM = "Userform";

    const [uFInvalid, setUFInvalid] = useState(false);
    const [ufSubmitted, setUFSubmitted] = useState(false);

    const userFormStatus = useSelector(selectUserFormStatus);
    const userForm = useSelector(selectUserForm);

    const locationOptions = useSelector(selectLocationOptions);
    const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
    const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);
    const [start, setStart] = useState(Moment(Moment(new Date()).add(-1, 'd').format('YYYY/MM/DD')));

    const [base64Image, setBase64Image] = useState("");
    const [file, setFile] = useState();
    const [pageLoaded, setPageLoaded] = useState(false);
    const [roleOptions, setRoleOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [tempUserId, setTempUserId] = useState("");
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [passwordLengthInvalid, setPasswordLengthInvalid] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [disDet, setDisDet] = useState();
    const [disList, setDisList] = useState();
    const [disCount, setDisCount] = useState();

    const [loading, setLoading] = useState(false);
    const [cFInvalid, setCFInvalid] = useState(false);
    const [cfSubmitted, setCFSubmitted] = useState(false);

    const userFlyoutCreateMode = useSelector(selectUserFlyoutCreateMode);
    const userFlyoutEditMode = useSelector(selectUserFlyoutEditmode);

    const userCreationStatus = useSelector(selectCreationStatus);
    const userModificationStatus = useSelector(selectModificationStatus);
    const user = useSelector(selectUserById);

    const [createMode, setCreatemode] = useState(false);
    const [editMode, setEditmode] = useState(false);

    const [isActive, setActive] = useState(false);

    const ToggleClass = () => {
        setActive(!isActive);
        onCancelClicked();

        const timer = setTimeout(() => {
            dispatch(closeUserFlyout())
            setActive(false)
        }, 1500);
    };

    const onCancelClicked = (e) => {
        setPassword("");
        setConfirmPassword("");
        setPasswordMismatch(false);
        setPasswordLengthInvalid(false);
        setCreatemode(false);
        setEditmode(false);
        setUFInvalid(false);
        setUFSubmitted(false);
    };

    useEffect(() => {
        setCreatemode(userFlyoutCreateMode);
        setEditmode(userFlyoutEditMode)
    }, [userFlyoutCreateMode, userFlyoutEditMode]);

    useEffect(() => {
        if (userCreationStatus === fetchStatus.SUCCEEDED || userModificationStatus === fetchStatus.SUCCEEDED) {
            ToggleClass()
        }
    }, [userCreationStatus, userModificationStatus]);

    useEffect(() => {
        if (!pageLoaded) {
            if (userFormStatus === fetchStatus.IDLE && accountService.isAuthenticated()) {
                setPageLoaded(true);
                _loadForm();
            }
        }
    });

    let _locationOptions = undefined;

    if (isObjectArray(locationOptions)) {
        _locationOptions = locationOptions.map((o) => (
            <option key={o.value} value={o.value}>{o.text}</option>
        ));
    }

    const _loadForm = async () => {
        await dispatch(getUserForm({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "GETFORMBYTYPE",
            payload: {
                id: "",
                data: "UserForm"
            }
        }));
    }

    const onValueUpdated = (e, section, value) => {
        let _userObj = Object.assign({}, user);
        let _newUserObj = onValueChanged(_userObj, e, section, value);
        dispatch(updateSingleData(_newUserObj));
    };

    let _roleOptions;
    useEffect(() => {
        if (isObjectArray(userRoles) && userRoles.length > 0) {
            _roleOptions = userRoles.map((role, index) => (
                <option key={nanoid()} value={role.value}>
                    {role.text}
                </option>
            ));
            setRoleOptions(_roleOptions);
        }

    }, [userRoles]);

    let _statusOptions;
    useEffect(() => {
        if (isObjectArray(userStatuses) && userStatuses.length > 0) {
            _statusOptions = userStatuses.map((status, index) => (
                <option key={nanoid()} value={status.value}>
                    {status.text}
                </option>
            ));
            setStatusOptions(_statusOptions);
        }

    }, [userStatuses]);

    const onSaveClicked = (e, id, created) => {
        e.preventDefault();
        setPasswordMismatch(false);
        setPasswordLengthInvalid(false);
        if (isFormValid(USER_FORM)) {
            if (user) {
                if (created) {
                    if (password.length >= 8) {
                        if (password === confirmPassword) {
                            dispatch(createUser({
                                client: tenantId,
                                serviceType: "TENANTADMIN",
                                operation: "SAVEUSER",
                                payload: {
                                    id: id,
                                    data: JSON.stringify({
                                        user: JSON.stringify(user),
                                        photo: {
                                            contentType: "image/jpg",
                                            length: 0,
                                            photoData: base64Image
                                        }
                                    })
                                }
                            }));
                        }
                        else {
                            setPasswordMismatch(true);
                        }
                    }
                    else {
                        setPasswordLengthInvalid(true);
                    }
                }
                else {
                    dispatch(updateUser({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVEUSER",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                user: JSON.stringify(user),
                                photo: {
                                    contentType: "image/jpg",
                                    length: 0,
                                    photoData: base64Image
                                }
                            })
                        }
                    }));
                }
            }
        }
        else {
            setUFInvalid(true);
        }
        setUFSubmitted(true);
    };

    useEffect(() => {
        const handleBackButton = (event) => {
            if (userFlyoutShow) {
                dispatch(closeUserFlyout());
            }
        };
    
        window.addEventListener('popstate', handleBackButton);
    
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [userFlyoutShow, dispatch]);


    return (
        <>
            {(userFlyoutShow) ? (<>
                <div className="flyout-wrapper position-fixed top-0 end-0">
                    <div className={`flyout-card ${isActive ? 'close' : 'open'}`}>
                        <div className="flyout-card-header">
                            <div className="float-end">
                                <div className="close-btn">
                                    <i className="fa fa-times" aria-hidden="true" data-tip='Close' onClick={ToggleClass}></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </div>
                            </div>
                            <div className="flyout-card-title">{user && user.id ? "Edit" : "Add"} User Details  </div>
                            <div className="flyout-card-sub-title">

                            </div>
                        </div>
                        <div className="flyout-card-body vh-100">
                            <div className="form-group p-0 pt-2">
                                <label id="lblFirstName" className="flyout-label" htmlFor="txtFirstName">First Name</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{user ? user.FirstName.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtFirstName"
                                                name="txtFirstName"
                                                ShortTextClasses="form-control"
                                                placeholder="First Name"
                                                value={user ? user.FirstName.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.USER_DETAILS, null)}
                                                required={userForm.FirstName.Required}
                                                errorMessage={userForm.FirstName.Validation}
                                                form={USER_FORM}
                                                submited={ufSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label className="flyout-label" htmlFor="txtLastName">Last Name</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{user ? user.LastName.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtLastName"
                                                name="txtLastName"
                                                ShortTextClasses="form-control"
                                                placeholder="Last Name"
                                                value={user ? user.LastName.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.USER_DETAILS, null)}
                                                required={userForm.LastName.Required}
                                                errorMessage={userForm.LastName.Validation}
                                                form={USER_FORM}
                                                submited={ufSubmitted} />
                                        } />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblPhoneNumber" className="flyout-label" htmlFor="txtPhoneNumber">Phone Number</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{user ? user.PhoneNumber.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <PhoneText
                                                id="txtPhone"
                                                name="txtPhone"
                                                PhoneTextClasses="form-control"
                                                placeholder="(000)-00-0000"
                                                value={user ? user.PhoneNumber.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.USER_DETAILS, null)}
                                                required={userForm.PhoneNumber.Required}
                                                errorMessage={userForm.PhoneNumber.Validation}
                                                form={USER_FORM}
                                                submited={ufSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblrolename" className="flyout-label" htmlFor="drpRoles">Role</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{user ? user.Role.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                id="drpRoles"
                                                name="drpRoles"
                                                SelectorClasses="form-select"
                                                value={user ? user.Role.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.USER_DETAILS, null)}
                                                options={
                                                    <>
                                                        {roleOptions}
                                                    </>
                                                }
                                                required={userForm.Role.Required}
                                                errorMessage={userForm.Role.Validation}
                                                form={USER_FORM}
                                                submited={ufSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblStatuses" className="flyout-label" htmlFor="drpStatuses">Status</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{user ? user.Status.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                id="drpStatuses"
                                                name="drpStatuses"
                                                SelectorClasses="form-select"
                                                value={user ? user.Status.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.USER_DETAILS, null)}
                                                options={
                                                    <>
                                                        {statusOptions}
                                                    </>
                                                }
                                                required={userForm.Status.Required}
                                                errorMessage={userForm.Status.Validation}
                                                form={USER_FORM}
                                                submited={ufSubmitted} />}
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblLocations" className="flyout-label" htmlFor="drpLocations">Location</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{user ? user.Status.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                id="drpLocations"
                                                name="drpLocations"
                                                SelectorClasses="form-select"
                                                value={user ? user.Location.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.USER_DETAILS, null)}
                                                options={
                                                    <>
                                                        {_locationOptions}
                                                    </>
                                                }
                                                required={userForm.Location.Required}
                                                errorMessage={userForm.Location.Validation}
                                                form={USER_FORM}
                                                submited={ufSubmitted} />}
                                    />
                                }
                            </div>

                            {createMode ?
                                <>
                                    <div className="form-group p-0 pt-2">
                                        <label className="flyout-label" htmlFor="txtEmail">Email</label>

                                        <InputGroup
                                            icon={<i className="fa fa-envelope"></i>}
                                            control={
                                                <EmailText
                                                    id="txtEmail"
                                                    name="txtEmail"
                                                    EmailTextClasses="form-control"
                                                    placeholder="xyz@example.com"
                                                    value={""}
                                                    onChange={(e) => onValueUpdated(e, sections.USER_DETAILS, null)}
                                                    required={userForm.EmailAddress.Required}
                                                    errorMessage={userForm.EmailAddress.Validation}
                                                    form={USER_FORM}
                                                    submited={ufSubmitted} />
                                            } />

                                    </div>
                                    <div className="form-group p-0 pt-2">
                                        <label className="flyout-label" htmlFor="txtPassword">Password</label>

                                        <InputGroup
                                            icon={<i className="fa fa-unlock-alt"></i>}
                                            control={
                                                <PasswordText
                                                    id="txtPassword"
                                                    name="txtPassword"
                                                    PasswordTextClasses="form-control"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={(e) => { setPassword(e.target.value); onValueUpdated(e, sections.USER_DETAILS, null); }}
                                                    required={userForm.Password.Required}
                                                    errorMessage={userForm.Password.Validation}
                                                    form={USER_FORM}
                                                    submited={ufSubmitted} />
                                            } />
                                        {passwordLengthInvalid && <span className="error-label">
                                            <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                            {"Password should be at least 8 characters"}
                                        </span>}
                                    </div>

                                    <div className="form-group p-0 pt-2">
                                        <label className="flyout-label" htmlFor="txtConfirmPassword">Confirm Password</label>
                                        <InputGroup
                                            icon={<i className="fa fa-unlock-alt"></i>}
                                            control={
                                                <PasswordText
                                                    id="txtConfirmPassword"
                                                    name="txtConfirmPassword"
                                                    PasswordTextClasses="form-control"
                                                    placeholder="Change Password"
                                                    value={confirmPassword}
                                                    onChange={(e) => { setConfirmPassword(e.target.value); onValueUpdated(e, sections.USER_DETAILS, null) }}
                                                    required={userForm.ConfirmPassword.Required}
                                                    errorMessage={userForm.ConfirmPassword.Validation}
                                                    form={USER_FORM}
                                                    submited={ufSubmitted} />
                                            } />
                                        {passwordMismatch && <span className="error-label">
                                            <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                            {"Password and Confirm Password did not match"}
                                        </span>}
                                    </div>
                                </> : <></>
                            }

                        </div>
                        <div className="flyout-card-footer">
                            <div className="d-flex">
                                <div className="ms-auto">
                                    {userCreationStatus === fetchStatus.LOADING || userModificationStatus === fetchStatus.LOADING ? <EllipsisLoader /> :
                                        <>
                                            <button className="c-btn white-btn me-3" type="button" onClick={ToggleClass} >Cancel</button>
                                            <button className="c-btn dark-btn" type="button" onClick={(e) => onSaveClicked(e, user ? user.id : "", createMode)}>Save</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flyout-backdrop flyout-backdrop-show' />
            </>
            ) :
                ''
            }
        </>

    )
}

export default UserFlyout;