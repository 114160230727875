import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    title: "",
    show: false,
    isActive: false,
    leadId: undefined
};

export const leadCalledFlyoutSlice = createSlice({
    name: 'leadCalledFlyout',
    initialState,
    reducers: {
        showLeadCalledFlyout: (state, action) => {
            const { title, leadId, confirmedCallback } = action.payload
            state.title = title;
            state.show = true;
            state.isActive = false;
            state.leadId = leadId;
        },
        closeLeadCalledFlyout: state => {
            state.show = false;
            state.leadId = undefined;
        },
        beforeCloseLeadCalledFlyout: state => {
            state.isActive = true;
        }
    }
});

export const { showLeadCalledFlyout, closeLeadCalledFlyout, beforeCloseLeadCalledFlyout } = leadCalledFlyoutSlice.actions;

export default leadCalledFlyoutSlice.reducer

export const selectFlyoutDetail = state => {
    return {
        title: state.leadCalledFlyout.title,
        show: state.leadCalledFlyout.show,
        isActive: state.leadCalledFlyout.isActive,
        leadId: state.leadCalledFlyout.leadId
    }
}