import React from 'react';
import ReactTooltip from 'react-tooltip';

import i9Img from './../../../assets/images/clipart/i9.svg';
import fileImg from './../../../assets/images/clipart/file.svg';
import genderImg from './../../../assets/images/clipart/gender.svg';
import termsImg from './../../../assets/images/clipart/terms.svg';
import folderImg from './../../../assets/images/clipart/folder.svg';
import safetyTestAndCertificateImg from './../../../assets/images/clipart/safety-test-and-certificate.svg';
import eddFileImg from './../../../assets/images/clipart/edd-file.png';
import covidFormFileImg from './../../../assets/images/clipart/covid-form-file.png';
import vaccinationDetailsFileImg from './../../../assets/images/clipart/vaccination-details-file.png';
import pdfImg from './../../../assets/images/clipart/pdf.svg';


function DummyDocumentsCard(props) {

    const onClickOpenModal = (e) => {
        alert('buy prospect')
    };

    return (
        <>
            <div className="accordion" id="accordionPeopleAttachment">
                <div className="flyout-list-group-item-card-container mt-3 blur-section">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        <img src={i9Img} className="icon-img" />
                        <span className="bold-text ps-2">
                            I9
                        </span>
                        <span className="ms-auto light-text">
                            1 Items
                        </span>
                    </div>
                    {/* <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            <div className="flyout-list-group-item-card system">
                                <div className='sub-icon'>
                                    <img src={pdfImg} className="icon-img" />
                                    <p className='file-size-text'>500kb</p>
                                </div>
                                <span className="ps-2">
                                    <div className='file-name'>Pre-Filled Template</div>
                                    <div className='file-by'>System Generated</div>
                                </span>
                                <span className="ms-auto d-flex">
                                    <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onClickOpenModal(e)} data-tip='Download'></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </span>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="flyout-list-group-item-card-container mt-3 blur-section">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <img src={fileImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            W4
                        </span>
                        <span className="ms-auto light-text">
                            1 Items
                        </span>
                    </div>
                    {/* <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            <div className="flyout-list-group-item-card system">
                                <div className='sub-icon'>
                                    <img src={pdfImg} className="icon-img" />
                                    <p className='file-size-text'>850kb</p>
                                </div>
                                <span className="ps-2">
                                    <div className='file-name'>Pre-Filled Template</div>
                                    <div className='file-by'>System Generated</div>
                                </span>
                                <span className="ms-auto d-flex">
                                    <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onClickOpenModal(e)} data-tip='Download'></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </span>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="flyout-list-group-item-card-container mt-3 blur-section">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        <img src={eddFileImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            EDD
                        </span>
                        <span className="ms-auto light-text">
                            2 Items
                        </span>
                    </div>
                    {/* <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            <div className="flyout-list-group-item-card system">
                                <div className='sub-icon'>
                                    <img src={pdfImg} className="icon-img" />
                                    <p className='file-size-text'>950kb</p>
                                </div>
                                <span className="ps-2">
                                    <div className='file-name'>Pre-Filled Template (English)</div>
                                    <div className='file-by'>System Generated</div>
                                </span>
                                <span className="ms-auto d-flex">
                                    <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onClickOpenModal(e)} data-tip='Download'></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </span>
                            </div>
                            <div className="flyout-list-group-item-card system">
                                <div className='sub-icon'>
                                    <img src={pdfImg} className="icon-img" />
                                    <p className='file-size-text'>900kb</p>
                                </div>
                                <span className="ps-2">
                                    <div className='file-name'>Pre-Filled Template (Spanish)</div>
                                    <div className='file-by'>System Generated</div>
                                </span>
                                <span className="ms-auto d-flex">
                                    <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onClickOpenModal(e)} data-tip='Download'></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </span>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="flyout-list-group-item-card-container mt-3 blur-section">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <img src={genderImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Sexual Harassment
                        </span>
                        <span className="ms-auto light-text">
                            1 Items
                        </span>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3 blur-section">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <img src={termsImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Terms
                        </span>
                        <span className="ms-auto light-text">
                            1 Items
                        </span>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3 blur-section">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        <img src={folderImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Others
                        </span>
                        <span className="ms-auto light-text">
                            2 Items
                        </span>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3 blur-section">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        <img src={safetyTestAndCertificateImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Safety Test and Certificate
                        </span>
                        <span className="ms-auto light-text">
                            0 Items
                        </span>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3 blur-section">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        <img src={covidFormFileImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Covid Form
                        </span>
                        <span className="ms-auto light-text">
                            0 Items
                        </span>
                    </div>
                </div>

                <div className="flyout-list-group-item-card-container mt-3 blur-section">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                        <img src={vaccinationDetailsFileImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Vaccination Details
                        </span>
                        <span className="ms-auto light-text">
                            0 Items
                        </span>
                    </div>
                </div>

            </div>
        </>
    );
}

export default DummyDocumentsCard;