import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateText from '../../../../component/Controls/custom/textbox/dateText';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import Loader from "react-js-loader";
import Moment from 'moment';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';
import { getSalesPerformanceReport, selectSalesPerformanceRecords } from '../../reducerSlices/reportSlice';
import Selector from '../../../../component/Controls/custom/selectors/selectors';
import { getSalesRepAndSalesMangerOptions, selectSalesRepAndSalesMangerOptions, selectSalesRepAndSalesMangerOptionsState } from '../../../crm/reducerSlices/leadSlice';
import { fetchStatus, tenantId } from '../../../../api/client';
import { toLocalDate } from '../../../../common/common';
import XLSX from 'xlsx';

function SalesPerformanceReport() {

  const dispatch = useDispatch();

  const [userId, setUserId] = useState("");

  const [start, setStart] = useState(Moment(Moment(new Date()).add(-7, 'd').format('YYYY-MM-DD')));
  const [end, setEnd] = useState(Moment(Moment(new Date()).format('YYYY-MM-DD')));
  const [loading, setLoading] = useState(false);
  const salesPerformanceRecordsData = useSelector(selectSalesPerformanceRecords);

  const salesRepAndSalesMangerOptionsState = useSelector(selectSalesRepAndSalesMangerOptionsState);
  const salesRepAndSalesMangerOptions = useSelector(selectSalesRepAndSalesMangerOptions);
  const [salesRepAndSalesMangerOptionsValue, setSalesRepAndSalesMangerOptionsValue] = useState();

  let _salesRepAndSalesMangerOptions = undefined;

  const _getSalesRepOption = (locationId) => {
    dispatch(getSalesRepAndSalesMangerOptions({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETSALESREPANDSALESMANAGER",
      payload: {
        id: "",
        data: JSON.stringify({ "locationId": locationId })
      }
    }));
  }

  useEffect(() => {
    if (salesRepAndSalesMangerOptionsState === fetchStatus.IDLE) {
      _getSalesRepOption("All");
    }
  }, [salesRepAndSalesMangerOptionsState]);

  useEffect(() => {
    if (salesRepAndSalesMangerOptionsState === fetchStatus.SUCCEEDED) {
      if (salesRepAndSalesMangerOptions) {
        if (isObjectArray(salesRepAndSalesMangerOptions)) {
          _salesRepAndSalesMangerOptions = [{ id: "All", FirstName: "All", LastName: "" }, ...salesRepAndSalesMangerOptions].map((o) => (
            <option value={o.id}>{o.FirstName + ' ' + o.LastName}</option>
          ));
          setSalesRepAndSalesMangerOptionsValue(_salesRepAndSalesMangerOptions);
          setLoading(false);
        }
      }
    }
  }, [salesRepAndSalesMangerOptions]);

  const OnClickReport = async () => {
    if (start != undefined && end != undefined && userId != "") {
      setLoading(true);
      await dispatch(getSalesPerformanceReport({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSALESPERFORMANCEREPORT",
        payload: {
          id: '',
          data: JSON.stringify({
            userId: userId,
            startDate: Moment(start).format('YYYY-MM-DD'),
            endDate: Moment(end).format('YYYY-MM-DD')
          })
        }
      }));
      setLoading(false);
    }
    else {
      alert('Please select filter')
    }
  };

  const fileHeaders = ["FULL NAME", "TOTAL PIPELINES", "NEW PIPELINES", "COMPLETED PIPELINES", "PIPELINES CONVERSION RATE", "CALLED OUT", "CALLED IN", "COMPLETED APPOINTMENTS", "TOTAL ROUTES", "NEW ROUTES", "COMPLETED ROUTES", "ROUTES PIEPLINES", "ROUTES PIEPLINES VISITED", "ROUTES PIEPLINES NOT VISITED"];

  const OnExportClick = async () => {
    var data = salesPerformanceRecordsData.map(elt => [elt.fullName, elt.totalPipeline, elt.totalPipelineNew, elt.totalPipelineCompleted, elt.pipelineConversionRate, elt.totalCalledOut, elt.totalCalledIn, elt.totalDoneAppointments, elt.totalRoute, elt.totalRouteNew, elt.totalRouteCompleted, elt.totalRoutePieplines, elt.totalRoutePieplinesVisited, elt.totalRoutePieplinesNotVisited]);
    const wb = XLSX.utils.book_new();
    var ws_data = [fileHeaders].concat(data);
    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(wb, ws, "Sales Performance Report");
    XLSX.writeFile(wb, "Sales Performance Report.xlsx");
  }


  return (
    <>
      <div className="d-flex">
        <div className="p-3">
          <Selector
            id="drpUsers"
            name="drpUsers"
            SelectorClasses="form-select"
            options={
              <>
                {salesRepAndSalesMangerOptionsValue}
              </>
            }
            onChange={(e) => setUserId(e.target.value)} />
        </div>
        <div className="p-3">
          <DateText
            id="txtStartDate"
            name="txtStartDate"
            DateTextClasses="form-control"
            placeholder="Start Date"
            value={Moment(start).format('YYYY-MM-DD')}
            selected={start}
            onChange={(e) => setStart(e.target.value)} />
        </div>
        <div className="p-3">
          <DateText
            id="txtEndDate"
            name="txtEndDate"
            DateTextClasses="form-control"
            placeholder="End Date"
            value={Moment(end).format('YYYY-MM-DD')}
            selected={end}
            onChange={(e) => setEnd(e.target.value)} />
        </div>

        {isObjectArray(salesPerformanceRecordsData) ?
          <div className="p-3">
            <button className="form-control" onClick={() => OnExportClick()}> <i className="fa fa-download mr-3" aria-hidden="true"></i></button>
          </div>
          : <></>}

        <div className="p-3 ms-auto">
          <button type="button" className="c-btn dark-btn position-relative" onClick={() => OnClickReport()}>
            <i className="fa fa-filter" aria-hidden="true"></i> Filter
            <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
              <span className="visually-hidden">New alerts</span>
            </span>
          </button>
        </div>
      </div>

      {loading ?
        <Loader type="spinner-circle" bgColor={"#92DCE2"} title={""} color={'#92DCE2'} size={100} />
        : <></>}
      {isObjectArray(salesPerformanceRecordsData) ? <span className="px-3 report-heading">Sales Performance Report</span> : <></>}




      <div className="row p-3">
        <GridComponent dataSource={salesPerformanceRecordsData} allowPaging={false} allowSorting={true} allowResizing={true} toolbar={['Search']} autoFit={true} allowTextWrap={true} textWrapSettings={{ wrapMode: 'Header' }}>
          <ColumnsDirective>
            <ColumnDirective field='fullName' headerText='Full Name' width='200' />
            <ColumnDirective field='totalPipeline' headerText='Total Pipelines' width='150' />
            <ColumnDirective field='totalPipelineNew' headerText='New Pipelines' width='150' />
            <ColumnDirective field='totalPipelineCompleted' headerText='Completed Pipelines' width='180' />
            <ColumnDirective field='pipelineConversionRate' headerText='Pipelines Conversion Rate' width='220' />
            <ColumnDirective field='totalCalledOut' headerText='Called Out' width='120' />
            <ColumnDirective field='totalCalledIn' headerText='Called In' width='100' />
            <ColumnDirective field='totalDoneAppointments' headerText='Completed Appointments' width='220' />
            <ColumnDirective field='totalRoute' headerText='Total Routes' width='120' />
            <ColumnDirective field='totalRouteNew' headerText='New Routes' width='120' />
            <ColumnDirective field='totalRouteCompleted' headerText='Completed Routes' width='180' />
            <ColumnDirective field='totalRoutePieplines' headerText='Routes Pipelines' width='150' />
            <ColumnDirective field='totalRoutePieplinesVisited' headerText='Routes Pipelines Visited' width='200' />
            <ColumnDirective field='totalRoutePieplinesNotVisited' headerText='Routes Pipelines Not Visited' width='250' />
          </ColumnsDirective>
          <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
        </GridComponent>
      </div>
    </>
  )
}

export default SalesPerformanceReport