export default class Collection {
    _items = [];
    constructor() {
        this._items = [];
    }

    get Count() {
        return this._items.length;
    }

    get Items() {
        return this._items;
    }

    Add = (key, item) => {
        if (!this.Contains(key)) {
            let _item = {
                key: key,
                records: item
            };
            this._items.push(_item);
        }
    }

    Update = (key, item) => {
        if (this.Contains(key)) {
            let item = this.Get(key);
            if (item && item.records) {
                item.records = item;
            }
        }
    }

    Get = (key) => {
        return this._items.find(i => i.key === key);
    }

    Contains = (key) => {
        let item = this._items.find(i => i.key === key);
        return item ? true : false;
    }

    Clear = () => {
        this._items = [];
    }

    Concat = (collection) => {
        this._items = this._items.concat(collection.Items);
    }

    Filter = (key, item) => {
        return this._items.filter(i => i.key !== key);
    }

    Append = (items) => {
        this._items = this._items.concat(items);
    }

    Remove = (key) => {
        this._items = this._items.filter(i => i.key !== key);
    }

    toString = () => {
        return JSON.stringify(this._items);
    };

    GetAll = () => {
        let res = [...this._items].reverse().map((el) =>  el.records);
        return res;
    }
}