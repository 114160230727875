import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InputGroup from "../../../component/Controls/custom/inputGroup/inputGroup";
import Selector from "../../../component/Controls/custom/selectors/selectors";
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import { accountService } from "../../accounts/services/accountService";
import {
  getDispatchList, selectDispatchListRefreshed, selectDispatchList, selectDispatchListState, getUserOptions, selectUserOptionsRefreshed, selectUserOptions, selectUserOptionsState, selectAllMessages, getAllMessages, selectInboxMessagesByFilter, selectMessageStatus, selectInboxMessagesStatusByFilter, selectUpdateMessageStatus, selectInboxMessagesStatus, selectInboxMessages, getInboxMessages,
  getInboxMessagesByFilter, refreshInbox, updaterefreshInbox, SelectrefreshInbox, updateMessage, createMessage,
} from '../reducerSlices/messageSlice';
import Moment from 'moment';
import nophoto from './../../../assets/images/clipart/nophoto.png';
import {
  selectApplicantById,
  selectSingleStatus as applicantStatus,
  getApplicantById
} from "../../applicant/reducerSlices/applicantSlice";
import { fetchStatus, tenantId } from "../../../api/client";
import { showSUModal } from "../../../component/Controls/modals/reducerSlices/statusUpdateModalPopUpSlice";
import { isObjectArray } from "../../../utilities/utilityFunctions";
import InBoxCard from "../../../component/Card/InboxCard";
import Loader from "../../../component/Controls/loaders/loader";
import DateText from "../../../component/Controls/custom/textbox/dateText";
import ChatRoom from "../../../component/Controls/chat/Chat";
import MessageFlyout from "../../../component/Flyout/MessageFlyout";
import noDataImg from './../../../assets/images/clipart/no-data.svg';
import { applicantStatusesData } from "../../shared/services/masterData";

function Inbox() {
  const [messageFlyout, setMessageFlyout] = useState(false);

  const [text, setText] = useState("");
  const [value, setValue] = useState("");
  const [prompted, setPrompted] = useState(false);

  const [pageLoaded, setPageLoaded] = useState(false);
  const dispatch = useDispatch();
  const refreshInbox = useSelector(SelectrefreshInbox);
  const [inbMessages, setinboxMessages] = useState([]);
  const inboxmessages = useSelector(selectInboxMessages);
  const inboxmessagesStatus = useSelector(selectInboxMessagesStatus);
  const updatemessageStatus = useSelector(selectUpdateMessageStatus);

  const [inbMessagesByFilter, setinboxMessagesByFilter] = useState([]);
  const inboxmessagesByFilter = useSelector(selectInboxMessagesByFilter);
  const inboxmessagesStatusByFilter = useSelector(selectInboxMessagesStatusByFilter);

  const userOptions = useSelector(selectUserOptions);
  const userOptionsStatus = useSelector(selectUserOptionsState);
  const userOptionsRefreshed = useSelector(selectUserOptionsRefreshed);
  const [userOptionsValue, setUserOptionsValue] = useState();


  const dispatchList = useSelector(selectDispatchList);
  const dispatchListStatus = useSelector(selectDispatchListState);
  const dispatchListRefreshed = useSelector(selectDispatchListRefreshed);
  const [dispatchListCount, setDispatchListCount] = useState("");


  const user = accountService.getAuthenticatedUser();
  const [selectedTab, setSelectedTab] = useState(0);
  const [start, setStart] = useState(Moment(Moment(new Date()).add(-7, 'd').format('YYYY/MM/DD HH:mm:ss')));
  const [end, setEnd] = useState(new Date());
  const [replyMsgValue, setreplyMsgValue] = useState("");
  const [selUserid, setSelUserId] = useState("");

  const [state, setState] = useState({ isPaneOpen: false, isPaneOpenLeft: false });
  const onTabSelected = (e, v) => {
    setSelectedTab(v);
  };

  const applicant = useSelector(selectApplicantById);
  const applicantRequestStatus = useSelector(applicantStatus);
  const Messages = useSelector(selectAllMessages);
  const MessagesStatus = useSelector(selectMessageStatus);
  const [Page, setPage] = useState("");
  const [loading, setLoading] = useState(false);
  const [myAppLoaded, setMyAppLoaded] = useState(false);

  useEffect(() => {
    console.log('Messages', Messages);
    console.table(Messages)
  }, [Messages])

  useEffect(() => {
    if (myAppLoaded && applicantRequestStatus === fetchStatus.SUCCEEDED) {
      let name = "";
      if (applicant.FirstName && applicant.FirstName.Data) {
        name += applicant.FirstName.Data + " ";
      }
      if (applicant.LastName && applicant.LastName.Data) {
        name += applicant.LastName.Data;
      }
      dispatch(showSUModal({
        title: <><span>Add Reason For</span> <span className="modal-header-name">{name}</span></>,
        id: applicant.id,
        applicant: applicant,
        status: {
          value: value,
          text: text
        }
      }));
      setPrompted(false);
      setMyAppLoaded(false);
    }
  }, [applicantRequestStatus]);

  const _loadApplicant = async (id, page) => {
    setMyAppLoaded(true);
    await dispatch(getApplicantById({
      // page: undefined,
      // model: {
      client: tenantId,
      serviceType: "TENANTUSER",
      operation: "GET",
      payload: {
        id: id,
        data: ""
      }
      // }
    }));

  }

  const _loadapplicantMessages = async (id) => {
    dispatch(getAllMessages({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETMESSAGES",
      payload: {
        id: id,
        data: ""
      }

    }));

  }

  const _loaddispatchList = async (userid) => {
    setLoading(true);
    dispatch(getDispatchList({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETDISPATCH",
      payload: {
        id: userid,
        data: JSON.stringify({
          userId: userid,
        })
      }

    }));

  }



  useEffect(() => {
    if (userOptionsRefreshed || userOptionsStatus === fetchStatus.IDLE) {
      setLoading(true);
      let loc = user ? user.locationId : "";
      dispatch(getUserOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({
            all: "true",
            locationId: loc
          })
        }

      }));
    }
  }, [userOptionsStatus, userOptionsRefreshed]);

  const OnFilter = async () => {

    if (selUserid != undefined) {
      _loaddispatchList(selUserid);
    }
    else {
      alert('Please select filter')
    }

  };

  const onStatusChanged = (e, value, text, id, name) => {
    e.preventDefault();
    setText(text);
    setValue(value);
    setPrompted(true);
    _loadApplicant(id);

    // dispatch(showDispatchModal({
    //     title: <><span>Change Location for</span> <span className="modal-header-name">{name}</span></>,
    //     id: id,
    //     applicant: null
    // }));
  };

  const [dispatchListDetailTrs, setDispatchListDetailTrs] = useState([]);



  useEffect(() => {
    if (dispatchListRefreshed && dispatchListStatus === fetchStatus.SUCCEEDED) {
      setLoading(false);
      if (selUserid === user.id) {
        setDispatchListCount(dispatchList.length)
      }
      let _dispatchListDetailTrs;
      setDispatchListDetailTrs([]);
      _dispatchListDetailTrs = dispatchList.map((detail, index) => (

        <tr key={detail.id}>
          <td  >
            {/* {!detail.ActiveDate ? <div className="col-md-9 controls-container">
              <a href="#" onClick={(e) => onStatusChanged(e, "Active", "Active", detail.aid, detail.Name, detail.applicant)} title="Active" className="e-focus"><span className="zoomicon"><i className="fa fa-check me-3 zoomicon"></i></span></a>

              <a href="#" onClick={(e) => onStatusChanged(e, "Available", "Available", detail.aid, detail.Name, detail.applicant)} title="Un Dispatch" className="e-focus"><span className="zoomicon text-danger"><i className="fa fa-ban me-3 zoomicon"></i></span></a>
            </div> : <></>} */}


            {!detail.ActiveDate ? <>
              <a href="#" onClick={(e) => onStatusChanged(e, applicantStatusesData.Active, applicantStatusesData.Active, detail.aid, detail.Name, detail.applicant)} title="Active" className="e-focus"><span className="zoomicon"><i className="fa fa-check me-2 zoomicon"></i></span></a>

              <a href="#" onClick={(e) => onStatusChanged(e, applicantStatusesData.Available, applicantStatusesData.Available, detail.aid, detail.Name, detail.applicant)} title="Un Dispatch" className="e-focus"><span className="zoomicon text-danger"><i className="fa fa-ban zoomicon"></i></span></a>
            </> : <React.Fragment></React.Fragment>}
          </td>
          <td className=" FieldsWidth100">
            <img id={detail.id} className={`person-small-photo `}
              src={detail && detail.Photo && detail.Photo.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "") || nophoto}
              alt="No photo available"
              style={{ width: "50px" }}
            />
          </td>
          <td>
            <label className=" FieldsWidthempoyeeaddress"  >{detail ? detail.Name : ""}</label>
          </td>
          <td>
            <label className=" FieldsWidthempoyeeaddress"  >{detail ? detail.Title : ""}</label>
          </td>
          <td>
            <label className="FieldsWidth100">{detail ? detail.StartDate : ""}</label>
          </td>
          <td>
            <label className="FieldsWidth100">{detail ? detail.DispatchDate : ""}</label>
          </td>
          <td>
            <label className="FieldsWidth100">$ {detail ? detail.PayRates : ""} </label>
          </td>
          <td>
            <label className="FieldsWidth100"> {detail ? detail.JobType : ""} </label>
          </td>






        </tr>
      ));
      setDispatchListDetailTrs(_dispatchListDetailTrs);


    }
  }, [dispatchListRefreshed, dispatchListStatus]);

  useEffect(() => {
    if (isObjectArray(userOptions)) {
      let userId = user ? user.id : "";
      setSelUserId(userId);
      let _userOptions = userOptions.map((o) => (
        o.id === userId ? <option selected value={o.id}>{o.FirstName} {o.LastName}</option> :
          <option value={o.id}>{o.FirstName} {o.LastName}</option>
      ));


      setUserOptionsValue(_userOptions);
      _loaddispatchList(user.id);
      setLoading(false);
    }
  }, [userOptions]);

  const _loadMessages = async (userid) => {

    await dispatch(getInboxMessages({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETINBOXMESSAGES",
      payload: {
        id: userid,
        data: JSON.stringify({})

      }
    }));

  }

  const _loadMessagesByFilter = async (userid) => {

    await dispatch(getInboxMessagesByFilter({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETINBOXMESSAGESBYFILTER",
      payload: {
        id: userid,
        data: JSON.stringify({
          StartDate: start,
          EndDate: end
        })

      }
    }));

  }
  const onReadClicked = (c, e, message) => {

    ////getmessage by id and update Isread = true and save it
    dispatch(updateMessage({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "UPDATEMESSAGE",
      payload: {
        id: "",
        data: JSON.stringify({
          message: message
        })
      }
    }));

  };

  const onShowAllClicked = (c, e, message) => {

    setPage("Inbox");
    _loadApplicant(message.sendById, "");

  };




  const OnClickFilter = async () => {

    if (start != undefined && end != undefined) {
      _loadMessagesByFilter(user.id);
    }
    else {
      alert('Please select filter')
    }

  };

  const onChange = (e) => {
    let msgvalue = e.target.value;
    setreplyMsgValue(msgvalue);

  };

  const onreplyClick = (c, e, message) => {

    const messagetext = {
      text: c,
      userType: "User",
      sendBy: (user ? user.firstName + " " + user.lastName : ""),
      sendById: user.id,
      sendOn: (new Date()).toUTCString(),
      receivedBy: message.sendBy,
      receivedById: message.sendById,
      receivedOn: (new Date()).toUTCString(),
      isReceived: true,
      isRead: false,
      readOn: (new Date()).toUTCString()
    };

    const applicantdata =
      [{
        id: message.sendById,
        FirstName: "",
        LastName: "",
        PhoneNumber: ""
      }]

    dispatch(createMessage({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "SAVEMESSAGE",
      payload: {
        id: "",
        data: JSON.stringify({
          message: JSON.stringify(messagetext),
          applicants: applicantdata
        })
      }
    }));

  }

  const parentCallback = (sender, senderAvatar, message, applicant) => {
    //alert("IN ParentCallback");

    const messagetext = {
      text: message,
      userType: "User",
      sendBy: (user ? user.firstName + " " + user.lastName : ""),
      sendById: user.id,
      sendOn: (new Date()).toUTCString(),
      receivedBy: applicant.FirstName.Data + " " + applicant.LastName.Data,
      receivedById: applicant.id,
      receivedOn: (new Date()).toUTCString(),
      isReceived: true,
      isRead: false,
      readOn: (new Date()).toUTCString()
    };
    const applicantdata =
      [{
        id: applicant.id,
        FirstName: applicant.FirstName.Data,
        LastName: applicant.LastName.Data,
        PhoneNumber: applicant.PhoneNumber.Data,
      }
      ]


    dispatch(createMessage({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "SAVEMESSAGE",
      payload: {
        id: "",
        data: JSON.stringify({
          message: JSON.stringify(messagetext),
          applicants: applicantdata
        })
      }
    }));



  }
  const updateReadmessages = (applicant) => {
    dispatch(updateMessage({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "UPDATEMESSAGE",
      payload: {
        id: applicant.id,
        data: null
      }
    }));
    dispatch(updaterefreshInbox("Updated"));
  }

  useEffect(() => {
    if (updatemessageStatus === fetchStatus.SUCCEEDED) {
      _loadMessages(user.id);
      _loadMessagesByFilter(user.id);

    }
  }, [updatemessageStatus]);

  useEffect(() => {

    if (applicantRequestStatus === fetchStatus.SUCCEEDED) {

      ///alert("22before laod applicant");
      _loadapplicantMessages(applicant.id);

    }
  }, [applicantRequestStatus]);

  useEffect(() => {

    if (MessagesStatus === fetchStatus.SUCCEEDED && Page === 'Inbox') {
      setState({ isPaneOpen: true });

      //alert("Msg status");  
      setMessageFlyout(true);
    }
  }, [MessagesStatus]);

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 10,
        title: "Messages"
      }));
      setPageLoaded(true);
      _loadMessages(user.id);
      _loadMessagesByFilter(user.id);
    }


    if (inboxmessagesStatus === fetchStatus.SUCCEEDED) {
      if (inboxmessages && Array.isArray(inboxmessages) && inboxmessages.length > 0) {
        var _inboxMessages = inboxmessages.map((message, index) => (

          <InBoxCard
            index={index}
            key={index}
            message={message}
            onReadClicked={(c, e) => onReadClicked(c, e, message)}
            onChange={(e) => onChange(e)}
            onreplyClick={(c, e) => onreplyClick(c, e, message)}
            IsOldMessage="false"
          />

        ));

        setinboxMessages(_inboxMessages);
      }
      else {
        // setinboxMessages("No new messages to display");
        setinboxMessages(<div className="data-not-found-container">
          <div className="data-not-found">
            <img src={noDataImg} />
            <p className="title">No new messages to display</p>
            <p className="description">Try adjusting your search to find what you are looking for</p>
          </div>
        </div>);
      }

    }

    if (inboxmessagesStatusByFilter === fetchStatus.SUCCEEDED) {
      if (inboxmessagesByFilter && Array.isArray(inboxmessagesByFilter) && inboxmessagesByFilter.length > 0) {
        var _inboxMessagesByFilter = inboxmessagesByFilter.map((message, index) => (
          <InBoxCard
            index={index}
            key={index}
            message={message}
            onShowAllClicked={(c, e) => onShowAllClicked(c, e, message)}
            onChange={(e) => onChange(e)}
            onreplyClick={(c, e) => onreplyClick(c, e, message)}
            IsOldMessage="true"
          />


        ));

        setinboxMessagesByFilter(_inboxMessagesByFilter);
      }
      else {
        setinboxMessagesByFilter(<div className="data-not-found-container">
          <div className="data-not-found">
            <img src={noDataImg} />
            <p className="title">No new messages to display</p>
            <p className="description">Try adjusting your search to find what you are looking for</p>
          </div>
        </div>);
      }

    }


  }, [inboxmessagesStatus, inboxmessages, inboxmessagesStatusByFilter, inboxmessagesByFilter]);

  useEffect(() => {


    if (refreshInbox === "Updated") {
      dispatch(updaterefreshInbox(""));
      _loadMessages(user.id);
      _loadMessagesByFilter(user.id);


    }

  }, [refreshInbox]);


  return (
    <>
      <div className="right-content">
        <div className="a-card-container">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link nav-link-people active" id="unread-messages-tab" data-bs-toggle="tab" data-bs-target="#unread-messages" type="button" role="tab" aria-controls="unread-messages" aria-selected="true">Unread Messages</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link nav-link-people" id="messages-tab" data-bs-toggle="tab" data-bs-target="#messages" type="button" role="tab" aria-controls="messages" aria-selected="false">Messages</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link nav-link-people" id="dispatches-tab" data-bs-toggle="tab" data-bs-target="#dispatches" type="button" role="tab" aria-controls="dispatches" aria-selected="false">Dispatches{dispatchListCount != "" && dispatchListCount != '0' && <span className="badge bg-danger ms-1"> {dispatchListCount} </span>}</button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="unread-messages" role="tabpanel" aria-labelledby="unread-messages-tab">
              <div className="card a-card-wrap">
                {inboxmessagesStatus === fetchStatus.SUCCEEDED ?
                  <div className="col-md-6 p-1">
                    {inbMessages}
                  </div>
                  : //'No messages to show'
                  <div className="data-not-found-container">
                    <div className="data-not-found">
                      <img src={noDataImg} />
                      <p className="title">No messages to show</p>
                      <p className="description">Try adjusting your search to find what you are looking for</p>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="tab-pane fade" id="messages" role="tabpanel" aria-labelledby="messages-tab">
              <div className="card a-card-wrap p-3">

                <div className="d-flex">
                  <div className="p-3">
                    <DateText
                      id="txtStartDate"
                      name="txtStartDate"
                      DateTextClasses="form-control"
                      placeholder="Start Date"
                      value={Moment(start).format('YYYY-MM-DD')}
                      selected={start}
                      onChange={(e) => setStart(e.target.value)} />
                  </div>
                  <div className="p-3">
                    <DateText
                      id="txtEndDate"
                      name="txtEndDate"
                      DateTextClasses="form-control"
                      placeholder="End Date"
                      value={Moment(end).format('YYYY-MM-DD')}

                      selected={end}
                      onChange={(e) => setEnd(e.target.value)} />
                  </div>
                  <div className="p-3 ms-auto">
                    <button type="button" className="c-btn dark-btn position-relative" onClick={() => OnClickFilter()}>
                      <i className="fa fa-filter" aria-hidden="true"></i> Filter
                      <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                        <span className="visually-hidden">New alerts</span>
                      </span>
                    </button>
                  </div>
                </div>


                {inboxmessagesStatusByFilter === fetchStatus.SUCCEEDED ? <div className="col-md-6 p-1">
                  {inbMessagesByFilter}
                </div> : 'No messages to show'}

              </div>

              {/* <div className="col-md-6">
                    <SlidingPane
                      className="slidingChat chatback pt-5 mt-5"
                      overlayClassName="zindexforsliding "
                      isOpen={state.isPaneOpen}
                      hideHeader={true}
                      width="400px"
                      height="750px"
                      onRequestClose={() => {
                        // triggered on "<" on left top click or on outside click
                        setState({ isPaneOpen: false });
                      }}>
                      <ChatRoom
                        applicant={applicant ? applicant : ""}
                        appsMessages={Messages ? Messages : []}
                        parentCallback={(sender, senderAvatar, message) => parentCallback(sender, senderAvatar, message, applicant)}
                        user={user ? user : ""}
                        updateReadmessages={() => updateReadmessages(applicant ? applicant : "")}
                      />
                    </SlidingPane>
                  </div> */}

              {
                messageFlyout ?
                  <MessageFlyout
                    onClose={() => setMessageFlyout(false)}
                    appsMessages={Messages ? Messages : []}
                    title={applicant ? applicant.FirstName.Data + ' ' + applicant.LastName.Data : ""}
                  >
                    <ChatRoom
                      applicant={applicant ? applicant : ""}
                      appsMessages={Messages ? Messages : []}
                      parentCallback={(sender, senderAvatar, message) => parentCallback(sender, senderAvatar, message, applicant)}
                      user={user ? user : ""}
                      updateReadmessages={() => updateReadmessages(applicant ? applicant : "")}
                    />
                  </MessageFlyout>
                  :
                  <></>
              }
            </div>
            <div className="tab-pane fade" id="dispatches" role="tabpanel" aria-labelledby="dispatches-tab">
              <div className="card a-card-wrap p-3">
                <div className="d-flex">
                  <div className="p-3">
                    <Selector
                      id="drpusers"
                      name="drpusers"
                      SelectorClasses="form-select"
                      options={
                        <>
                          {userOptionsValue}
                        </>
                      }
                      onChange={(e) => setSelUserId(e.target.value)} />
                  </div>
                  <div className="p-3 ms-auto">
                    <button type="button" className="c-btn dark-btn position-relative" onClick={() => OnFilter()}>
                      <i className="fa fa-filter" aria-hidden="true"></i> Filter
                      <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                        <span className="visually-hidden">New alerts</span>
                      </span>
                    </button>
                  </div>
                </div>

                <div className="row p-3">
                  {loading ? <Loader type="spinner-circle" bgColor={"#222fb9"} title={""} color={'#222fb9'} size={100} /> : <></>}
                  <div className="col-md-12">
                    {<div className="dispatchTable">

                      <table className="table table-hover">

                        <thead className="">
                          <tr>
                            <th scope="col">Action</th>
                            <th scope="col">Photo</th>
                            <th scope="col">Name</th>
                            <th scope="col">Title</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">Dispatch Date</th>
                            <th scope="col">Pay Rate</th>
                            <th scope="col">Job Type</th>


                          </tr>
                        </thead>
                        {!loading ? <tbody>
                          {dispatchListDetailTrs}

                        </tbody> : <></>}

                      </table>
                    </div>}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Inbox;