import {
    createSlice,
    createAsyncThunk,
    nanoid
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    appointments: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid(),
        isFiltered: false,
    },
    saveAppointment: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    removalAppointment: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    }
};

export const getAllShedules = createAsyncThunk('schedule/getAllShedules', async (schedulesModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, schedulesModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let schedules = undefined;
        if (data && isJSON(data)) {
            schedules = JSON.parse(data);
        }
        return {
            page: schedulesModel.page,
            schedules: schedules,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const createAppointment = createAsyncThunk('schedule/createAppointment', async (leadModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, leadModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let appointment = undefined;
        if (data && isJSON(data)) {
            appointment = JSON.parse(data);
        }
        return {
            appointment: appointment,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const removeScheduleAppointment = createAsyncThunk('schedule/removeScheduleAppointment', async (scheduleModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, scheduleModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        return {
            id: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const scheduleSlice = createSlice({
    name: 'schedules',
    initialState,
    reducers: {
        updateIsFiltered: (state) => {
            state.appointments.isFiltered = true;
        },
        updateAppointmentsStatus: (state) => {
            state.appointments.status = fetchStatus.IDLE;
        },
        loadAppointmentsData: (state, _data) => {
            state.appointments.data = Object.assign({}, _data.payload);
        },
        updateAppointmentsData: (state, _data) => {
            state.appointments.refreshed = nanoid();
            state.appointments.data = _data.payload ? Object.assign({}, _data.payload.appointments) : _data.payload;
        },
        updateSingleAppointments: (state, _data) => {
            if (_data.payload) {
                state.appointments.data = Object.assign({}, _data.payload);
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllShedules.pending, (state, action) => {
            state.appointments.status = fetchStatus.LOADING;
        }).addCase(getAllShedules.fulfilled, (state, action) => {
            state.appointments.data = action.payload.schedules;
            let calanderData = action.payload.schedules.map(function (d) {
                let cd = { ...d.calendarData, "appointmentId": d.id, "_leadName": d.leadName, "_assignData": d.assignData }
                return cd
            });
            state.appointments.data = calanderData;
            state.appointments.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getAllShedules.rejected, (state, action) => {
            state.appointments.status = fetchStatus.FAILED;
            state.appointments.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(createAppointment.pending, (state, action) => {
            state.saveAppointment.status = fetchStatus.LOADING;
        }).addCase(createAppointment.fulfilled, (state, action) => {
            state.saveAppointment.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createAppointment.rejected, (state, action) => {
            state.saveAppointment.status = fetchStatus.FAILED;
            state.saveAppointment.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(removeScheduleAppointment.pending, (state, action) => {
            state.removalAppointment.status = fetchStatus.LOADING;
        }).addCase(removeScheduleAppointment.fulfilled, (state, action) => {
            // state.data.records = removed.records;
            state.removalAppointment.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(removeScheduleAppointment.rejected, (state, action) => {
            state.removalAppointment.status = fetchStatus.FAILED;
            state.removalAppointment.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { updateAppointmentsData, updateIsFiltered } = scheduleSlice.actions;

export default scheduleSlice.reducer

export const selectAllSchedules = (state) => {
    return state.schedules.appointments ? state.schedules.appointments.data : undefined;
}
export const selectStatus = state => state.schedules.appointments.status;

export const selectSaveAppointmentStatus = state => state.schedules.saveAppointment.status;

export const selectRemovalAppointmentStatus = state => state.schedules.removalAppointment.status;