import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { accountService } from '../../accounts/services/accountService';
import Collection from '../../../component/Controls/paginators/collection';
import {
  getAllLeads,
  updateIsFiltered,
  selectStatus,
  selectCurrentPage,
  selectTotalItems,
  selectAllLeads,
  updateStatus,
  selectFirstRecord,
  loadSingleData,
  getLeadById,
  updateCurrentPage,
  selectLeadById,
  updateLead,
  selectModificationStatus,
  selectRemovalStatus,
  selectSingleStatus,
  selectSingleRefreshed,
  getLeadForm,
  selectLeadFormStatus,
  selectLeadForm,
  updateSingleData,
  createNewLead,
  updateLeadFilters,
  removeLead,
  createLead,
  selectCreationStatus,
  selectLeadCalledOnStatus,
  selectCalledOnLead,
  updateSingleLead,
  addLeadToClient,
  createContactDetail,
  deleteContactDetail,
  selectUserStatus,
  getAllUsers,
  selectAllUsers,
  selectSalesRepOptionsState,
  selectSalesRepOptions,
  getSalesRepOptions,
  selectSalesMangerOptionsState,
  selectSalesMangerOptions,
  getSalesMangerOptions,
  // updateAppointmentsData,
  createAppointment,
  getAppointmentsById,
  selectAppointmentsById,
  selectCreationAppointmentStatus,
  getLeadByAddress,
  selectLeadByAddress,
  updateRecentClickedLead,
  saveLeadRoute,
  selectLeadRouteStatus
} from '../reducerSlices/leadSlice';
import { fetchStatus, tenantId } from '../../../api/client';
import { selectDefaultFilter, selectLocationFilter, selectRefreshData, selectRoleFilter, selectLeadStatusFilter, selectWhereClause, selectWhereClauseChanged, updateFilter, updateLeadFilter, updateLeadRefreshData, updateNameFilter, updateLocationFilter, selectAssignedToFilter } from '../../../component/Controls/filters/reducerSlices/leadFilterSlice';
import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from '../../locations/reducerSlices/locationSlice';
import { selectSort } from '../../../component/Controls/dataSorters/reducerSlices/leadSorterSlice';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import LeadSorter from '../../../component/Controls/dataSorters/leadSorter';
import PageContainer from '../../../component/Controls/paginators/paginator/pageContainer';

import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { _getLocationText, toLocalDate } from '../../../common/common';
import LeadCard from '../../../component/Card/LeadCard';
import LocationFilter from '../../../component/Controls/filters/locationFilter';
import { onLeadAssignedToChanged, onLeadLocationChanged, onLeadStatusChanged } from '../services/leadFilterHandler';
import LeadStatusFilter from '../../../component/Controls/filters/leadStatusFilter';
import { showLRNModal } from '../../../component/Controls/modals/reducerSlices/leadRouteNoteModalPopUpSlice';
import AssignedToFilter from '../../../component/Controls/filters/assignedToFilter';


function Lead() {

  const history = useHistory();
  const dispatch = useDispatch();

  const isAdmin = accountService.isAdmin();
  const isSalesManager = accountService.isSalesManager();

  const itemsPerPage = 10;
  const LEAD_FORM = "Leadform";

  const user = accountService.getAuthenticatedUser();

  const [leadRecords, setLeadRecords] = useState(new Collection());


  const [dummyCards, setDummyCards] = useState([]);
  const currentPage = useSelector(selectCurrentPage);
  const refreshData = useSelector(selectRefreshData);

  const leadFormStatus = useSelector(selectLeadFormStatus);

  const totalPageItems = useSelector(selectTotalItems);
  const leads = useSelector(selectAllLeads);
  const leadsRequestStatus = useSelector(selectStatus);


  const whereClause = useSelector(selectWhereClause);
  const whereClauseChanged = useSelector(selectWhereClauseChanged);
  const defaultFilter = useSelector(selectDefaultFilter);

  const locationOptions = useSelector(selectLocationOptions);
  const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
  const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);

  const salesRepOptionsState = useSelector(selectSalesRepOptionsState);
  const salesRepOptions = useSelector(selectSalesRepOptions);
  const [salesRepOptionsData, setSalesRepOptionsData] = useState();

  const [pageLoaded, setPageLoaded] = useState(false);

  const sort = useSelector(selectSort);
  const [isFiltered, setIsFiltered] = useState(false);

  const leadRouteStatus = useSelector(selectLeadRouteStatus);

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 14,
        title: "Leads",
        product: "CRM"
      }));
      setPageLoaded(true);
      if (leadFormStatus === fetchStatus.IDLE) {
        _loadForm();
      }
    }
  });

  const _loadForm = async () => {
    await dispatch(getLeadForm({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETFORMBYTYPE",
      payload: {
        id: "",
        data: "LeadForm"
      }
    }));
  }

  const _loadLeads = async (page, offset, limit, whereClause, sort) => {
    setIsFiltered(false);
    await dispatch(getAllLeads({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLEADS",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: offset,
            limit: limit,
            where: whereClause,
            sort: sort
          })
        }
      }
    }));

  }

  useEffect(() => {
    if (leadsRequestStatus === fetchStatus.IDLE) {
      let loc = user ? user.locationId : "";
      let where = JSON.parse(JSON.stringify(whereClause));
      where.location.value.push(loc);
      dispatch(updateLocationFilter({
        locations: locationOptions,
        value: loc
      }));
      dispatch(updateIsFiltered());
      _loadLeads(0, 0, 10, where, sort);
    }
  }, [leadsRequestStatus, dispatch]);

  const onSorted = async (sortBy, ascending) => {
    await dispatch(updateIsFiltered());
    _loadLeads(0, 0, 10, whereClause, {
      by: sortBy,
      ascending: ascending
    });
    // dispatch(updateLeadRefreshData(false));
  };

  const onPageChanged = async (p, l) => {
    dispatch(updateCurrentPage(p));
    if (l) {
      await onLoadItems(p);
    }
  };

  const onLoadItems = async (p) => {
    await _loadLeads(p, p * 10, 10, whereClause, sort);
  };


  let leadCards;
  let _dummyCards = [];

  let _leadRecords = new Collection();

  useEffect(() => {
    if (leadsRequestStatus === fetchStatus.LOADING) {
      for (let i = 0; i < 10; i++) {
        _dummyCards.push(
          // <div className="list-loading-content">
          <div className="people-card">
            <div className="people-card-body p-2">
              <ReactPlaceholder showLoadingAnimation={true} type='media' ready={false} rows={3}>
              </ReactPlaceholder>
            </div>
          </div>
          // </div>
        );
      }
      setDummyCards(_dummyCards);
    }
  }, [leadsRequestStatus])

  const onCheckRouteDetailsData = (c, e, id) => {
    if (c === true) {
      let details = {
        RouteDetails: {
          Data: true,
          TimeStamp: (new Date()).toUTCString(),
          UnixTimestamp: Math.floor(Date.now() / 1000)
        },
        log: {
          TimeStamp: (new Date()).toUTCString(),
          LogType: "Route Check",
          LogValue: 31,
          Content: "",
          UserName: (user ? user.firstName + " " + user.lastName : ""),
          UserId: (user ? user.id : "")
        }
      }
      dispatch(saveLeadRoute({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "SAVELEADROUTE",
        payload: {
          id: id,
          data: JSON.stringify(details)
        }
      }));
    } else {
      dispatch(showLRNModal({
        title: <><span>Add Route Note</span></>,
        id: id
      }));
    }
  }

  useEffect(() => {
    if (leadsRequestStatus === fetchStatus.SUCCEEDED || leadsRequestStatus === fetchStatus.DONE) {
      let _records = leads.Items;

      if (leads.Items && leads.Items.length > 0) {
        for (let i = 0; i < leads.Items.length; i++) {
          let item = leads.Items[i];
          leadCards = item.records.map((lead, index) => (
            <LeadCard
              key={lead.id}
              leadCardClasses="mb-2"
              leadName={lead.LeadName}
              leadDate={toLocalDate(lead.LeadDate)}
              leadPhone={lead.LeadPhone}
              location={_getLocationText(locationOptions, lead.Location)}
              status={lead.Status}
              statusText={lead.Status}
              onClick={() => onLeadSelected(lead.id)}
              routeDetailsData={lead.RouteDetailsData}
              onCheckRouteDetailsData={(c, e) => onCheckRouteDetailsData(c, e, lead.id)}
            />
          ));
          _leadRecords.Add(item.key, leadCards);
        }

        setLeadRecords(_leadRecords);
        dispatch(updateStatus());
      }
      dispatch(updateStatus());
    }
    // if (leads && leads.Items.length > 0) {
    //   let item = leads.Get(currentPage);
    //   let _leads = item ? item.records : [];
    //   if (_leads && Array.isArray(_leads) && _leads.length > 0) {
    //     let _lead = _leads[0];
    //     if (_lead && _lead.id) {
    //       if (firstLead && firstLead.records) {
    //         //         dispatch(loadSingleData(firstLead.records));
    //       }
    //       else {
    //         _loadLead(_lead.id, currentPage);
    //       }
    //     }
    //   }
    //   else {
    //     dispatch(updateSingleData(undefined));
    //   }
    // }
  }, [leadsRequestStatus, currentPage]);


  const onLeadSelected = (id) => {
    _loadLead(id, undefined);
    dispatch(updateRecentClickedLead(id));
    history.push('lead/details');
  };

  const _loadLead = async (id, page) => {
    await dispatch(getLeadById({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLEADBYID",
        payload: {
          id: id,
          data: ""
        }
      }
    }));

    await dispatch(getAppointmentsById({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETAPPOINTMENTSBYLEADID",
        payload: {
          id: id,
          data: ""
        }
      }
    }));
  }

  const onFilterClicked = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    _loadLeads(0, 0, 10, whereClause, sort);
  };

  const onAddRecordClicked = (e) => {
    dispatch(createNewLead());
    dispatch(updateRecentClickedLead(0));
    history.push('lead/details');
  };

  useEffect(() => {
    if (leadRouteStatus === fetchStatus.SUCCEEDED) {
      _loadLeads(0, 0, 10, whereClause, sort);
    }
  }, [leadRouteStatus, dispatch]);


  const _getSalesRepOption = (locationId) => {
    dispatch(getSalesRepOptions({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETSALESREPBYLOCATION",
      payload: {
        id: "",
        data: JSON.stringify({ "locationId": locationId })
      }
    }));
  }

  useEffect(() => {
    if (salesRepOptionsState === fetchStatus.IDLE) {
      _getSalesRepOption("All");
    }
  }, [salesRepOptionsState]);

  return (
    <>
          <div className="filter-bar p-3">
            {(isAdmin || isSalesManager) &&
              <>
                <LocationFilter
                  locations={locationOptions}
                  locationFilterSelector={selectLocationFilter}
                  onLocationChanged={(e, v, locations) => { onLeadLocationChanged(e, v, locations); setIsFiltered(true); }}
                />
                <AssignedToFilter
                  assignedTos={salesRepOptions}
                  assignedToFilterSelector={selectAssignedToFilter}
                  onAssignedToChanged={(e, v, locations) => { onLeadAssignedToChanged(e, v, locations); setIsFiltered(true); }}
                />
              </>
            }
            <LeadStatusFilter
              leadStatusFilterSelector={selectLeadStatusFilter}
              onLeadStatusChanged={(e, v, leadStatuses) => { onLeadStatusChanged(e, v, leadStatuses); setIsFiltered(true); }} />
            <div className="float-end">
              {isFiltered &&
                <button type="button" className="c-btn dark-btn position-relative" onClick={(e) => onFilterClicked(e)}>
                  <i className="fa fa-filter me-2" aria-hidden="true"></i> Filter
                </button>
              }
              <button type="button" className="c-btn dark-btn ms-3" onClick={onAddRecordClicked} >
                Add Lead
              </button>
            </div>
          </div>


          <div className="right-content">
            <div className="d-flex w-100 p-3 pb-0">
              <div className="align-self-center">
                <div className="job-order-result-text">Showing {totalPageItems} Results <span className="job-order-result-sub-text">(Based your search)</span> </div>
              </div>
              <div className="ms-auto align-self-center">
                <div className="d-flex">
                  <LeadSorter
                    onSorted={(s, a) => onSorted(s, a)} />
                </div>
              </div>
            </div>
          </div>


          <PageContainer
            currentPage={currentPage}
            totalPageItems={totalPageItems}
            pageRecords={leadRecords}
            isItemsLoading={leadsRequestStatus}
            itemsLoadingContent={dummyCards}
            onPageChanged={(p, l) => onPageChanged(p, l)}
            infinite={true}
            pageRecordsCardClassName={'people-card'}
          />
    </>
  )
}

export default Lead;