import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jobStatusData } from '../../../features/jobOrder/services/masterData';
import CheckBox from '../../Controls/custom/checkers/checkbox';

function JobStatusFilter(props) {
    const dispatch = useDispatch();


    const [open, setOpen] = useState(false);

    const jobStatusFilter = useSelector(props.jobStatusFilterSelector);

    const gfContainerRef = useRef(null);

    let _jobStatuses = jobStatusData;

    let jobStatusesList;
    let isJobStatusesSelected = (jobStatusFilter.value && Array.isArray(jobStatusFilter.value) && jobStatusFilter.value.length > 0);
    if (_jobStatuses && Array.isArray(_jobStatuses) && _jobStatuses.length > 0) {
        jobStatusesList = _jobStatuses.map((jobStatus) => (
            <li key={jobStatus.value} className="filter-list-item"
                onClick={(e) => props.onJobStatusChanged && props.onJobStatusChanged(e, jobStatus.value, _jobStatuses)}>
                <CheckBox
                    id={`ckbJobStatus-${jobStatus.value}`}
                    size="Small"
                    checked={isJobStatusesSelected ? jobStatusFilter.value.includes(jobStatus.value) : false} />
                <span className={`ms-3 ${isJobStatusesSelected && jobStatusFilter.value.includes(jobStatus.value) ? "filter-selected" : ""}`}>{jobStatus.text}</span>

            </li>
        ));
    }

    useEffect(() => {
        if (open && gfContainerRef && gfContainerRef.current) {
            gfContainerRef.current.focus();
        }
    });

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Job Status</span> :
                <span className="filter-value">{jobStatusFilter.value ? jobStatusFilter.text : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={gfContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    {jobStatusesList}
                </ul>
            </div> : <></>}
        </div>
    );
}

export default JobStatusFilter;