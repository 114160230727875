import React, { useState } from "react";
import './css/demo.css';

import logoImg from './../../assets/images/logo/logo.svg';
import boyFaceImg from './../../assets/images/clipart/boy-face.svg';

import { FaPhoneAlt } from 'react-icons/fa';

import { HiOutlineMenuAlt2 } from 'react-icons/hi';

import notificationImg from './../../assets/images/menu/notification.svg';

//menu Image
import clientImg from './../../assets/images/menu/client.svg';
import feedImg from './../../assets/images/menu/feed.svg';
import jobOrderImg from './../../assets/images/menu/job-order.svg';
import userImg from './../../assets/images/menu/user.svg';
import locationImg from './../../assets/images/menu/location.svg';
import messageImg from './../../assets/images/menu/message.svg';
import peopleImg from './../../assets/images/menu/people.svg';
import reportImg from './../../assets/images/menu/report.svg';


//card Image
import phoneCallFillImg from './../../assets/images/clipart/phone-call-fill.svg';
import calendarImg from './../../assets/images/clipart/calendar.svg';
import mailImg from './../../assets/images/clipart/mail.svg';
import mapLocationImg from './../../assets/images/clipart/map-location.svg';
import clockImg from './../../assets/images/clipart/clock.svg';
import applicationImg from './../../assets/images/clipart/application.svg';
import noteImg from './../../assets/images/clipart/note.svg';
import documentImg from './../../assets/images/clipart/document.svg';
import msgImg from './../../assets/images/clipart/msg.svg';
import triangleExclamationiImg from './../../assets/images/clipart/triangle-exclamationi.svg';
import bagCheckImg from './../../assets/images/clipart/bag-check.svg';
import bagCheckWhiteImg from './../../assets/images/clipart/bag-check-white.png';

import i9Img from './../../assets/images/clipart/i9.svg';
import fileImg from './../../assets/images/clipart/file.svg';
import genderImg from './../../assets/images/clipart/gender.svg';
import termsImg from './../../assets/images/clipart/terms.svg';
import folderImg from './../../assets/images/clipart/folder.svg';
import filesImg from './../../assets/images/clipart/files.svg';

import buildingLocationImg from './../../assets/images/clipart/building-location.svg';
import downloadImg from './../../assets/images/clipart/download.svg';
import heroiconsSolidImg from './../../assets/images/clipart/heroicons-solid.svg';
import deleteImg from './../../assets/images/clipart/delete.svg';

import PeopleDetailCard from "../Card/PeopleDetailCard";
import SideNavBar from "../SideNavBar/SideNavBar";
import TopNavBar from "../TopNavBar/TopNavBar";


const Demo = () => {

  const [applyMoreFilters, setApplyMoreFilters] = useState(false);

  return (
    <div>

      {/* <!-- Side-Nav --> */}
      <SideNavBar />

      {/* <!-- Main Wrapper --> */}
      <div className="my-container active-cont">

        {/* <!-- Top Nav --> */}
        <TopNavBar />

        <div className="app-container">

          {/* main section */}
          <div className="px-3 pt-2">
            <div className="row">


              <div className="d-flex bd-highlight">
                <div className="flex-grow-1 bd-highlight">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="#">People</a></li>
                      <li className="breadcrumb-item active" aria-current="page">Details</li>
                    </ol>
                  </nav>
                </div>

                <div className="icon">
                  <img src={buildingLocationImg} />
                </div>
                <div className="icon">
                  <img src={downloadImg} />
                </div>
                <div className="icon">
                  <img src={triangleExclamationiImg} />
                </div>
                <div className="icon">
                  <img src={heroiconsSolidImg} />
                </div>
                <div className="icon">
                  <img src={deleteImg} />
                </div>
              </div>
            </div>
          </div>
          <div className="px-3">
            <div className="row">
              <div className="col-md-12 col-lg-3 ">
                {/* <div className="card people-detail-card">
                <div className="people-detail-card-header">
                  <div className="people-detail-card-status-top">
                    <img src={triangleExclamationiImg} /> DNR
                  </div>
                  <div className="d-flex justify-content-center pt-3">
                    <div className="c-card-photo w-25">
                      <div className="c-card-photo-box">
                        <img className="card-profile-box-img" src={boyFaceImg} />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="people-detail-card-name">Allen Yarbrough</div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <p className="">Product Manager</p>
                  </div>
                  <div className="d-flex justify-content-center">
                    <p className="people-detail-card-phone">
                      <img src={phoneCallFillImg} />
                      (999) 877-7474
                    </p>
                  </div>
                </div>

                <div className="people-detail-card-sub">
                  <div className="people-detail-card-code">ST 6453 : Stiiizy</div>
                  <div className="people-detail-card-date">Request Date:  06/30/2022</div>
                  <div className="row py-3">
                    <div className="col-2">
                      <div className="people-detail-card-icon">
                        <img src={bagCheckImg} className="w-100" />
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="text-secondary">Start Date</div>
                      Jun 27, 2022
                    </div>
                    <div className="col-5">
                      <div className="text-secondary">Start Time</div>
                      06:00 AM
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <span className="text-secondary">Job Position</span>
                      <p className="text-primary">General Labor</p>
                    </div>
                    <div className="col-4">
                      <div className="float-end">
                        <span className="people-detail-card-badge people-detail-card-badge-pink">$ 15.50</span>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="people-detail-card-sub">
                  <div className="people-detail-card-code">ST 6453 : Stiiizy</div>
                  <div className="people-detail-card-date">Request Date:  06/30/2022</div>
                  <div className="row py-3">
                    <div className="col-2">
                      <div className="people-detail-card-icon">
                        <img src={bagCheckImg} className="w-100" />
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="text-secondary">Start Date</div>
                      Jun 27, 2022
                    </div>
                    <div className="col-5">
                      <div className="text-secondary">Start Time</div>
                      06:00 AM
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <span className="text-secondary">Job Position</span>
                      <p className="text-success">General Labor</p>
                    </div>
                    <div className="col-4">
                      <div className="float-end">
                        <span className="people-detail-card-badge people-detail-card-badge-green">$ 15.50</span>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="people-detail-card-sub">
                  <div className="people-detail-card-code">ST 6453 : Stiiizy</div>
                  <div className="people-detail-card-date">Request Date:  06/30/2022</div>
                  <div className="row py-3">
                    <div className="col-2">
                      <div className="people-detail-card-icon">
                        <img src={bagCheckImg} className="w-100" />
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="text-secondary">Start Date</div>
                      Jun 27, 2022
                    </div>
                    <div className="col-5">
                      <div className="text-secondary">Start Time</div>
                      06:00 AM
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <span className="text-secondary">Job Position</span>
                      <p className="text-success">General Labor</p>
                    </div>
                    <div className="col-4">
                      <div className="float-end">
                        <span className="people-detail-card-badge people-detail-card-badge-pink">$ 15.50</span>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="people-detail-card-sub">
                  <div className="people-detail-card-code">ST 6453 : Stiiizy</div>
                  <div className="people-detail-card-date">Request Date:  06/30/2022</div>
                  <div className="row py-3">
                    <div className="col-2">
                      <div className="people-detail-card-icon">
                        <img src={bagCheckImg} className="w-100" />
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="text-secondary">Start Date</div>
                      Jun 27, 2022
                    </div>
                    <div className="col-5">
                      <div className="text-secondary">Start Time</div>
                      06:00 AM
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <span className="text-secondary">Job Position</span>
                      <p className="text-success">General Labor</p>
                    </div>
                    <div className="col-4">
                      <div className="float-end">
                        <span className="people-detail-card-badge people-detail-card-badge-green">$ 15.50</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div> */}

                <PeopleDetailCard />
              </div>


              <div className="col-md-12 col-lg-9">

                {/* tab menu */}

                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Feed</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Interaction</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Personal</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Emergency Contact</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Education</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Employment</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">References</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Other Details</button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className="card a-card-wrap">


                      <div className="d-flex">
                        <div className="col-md-8 col-lg-8">
                          <div className="tracking-list pt-3">

                            <div className="tracking-item">
                              <div className="tracking-icon status-intransit bg-blue">
                                <img src={bagCheckWhiteImg} className="w-100" />
                              </div>
                              <div className="tracking-content">
                                <p>
                                  <span className="p-name">Rob Smith</span>
                                  <span className="p-changed"> Changed status to</span>
                                  <span className="p-status"> Dispatched</span>
                                </p>
                                <p className="p-subtext">Change status In Progress to dispatch</p>
                                <p className="p-dark-gray">Client:/ per Rates :  $ <span className="p-light-gray">06/27/2022, 19:46:21</span></p>
                              </div>
                            </div>

                            <div className="tracking-item">
                              <div className="tracking-icon status-inforeceived bg-purple">
                                <i className="fa fa-file"></i>
                              </div>
                              <div className="tracking-content">
                                <p>
                                  <span className="p-name">Howard Caroll</span>
                                  <span className="p-changed"> Uploaded documents</span>
                                </p>
                                <p className="p-subtext">Document type W4 and others </p>
                                <p className="p-dark-gray"><span className="p-light-gray">06/27/2022, 20:46:21</span></p>
                              </div>

                              <div className="card file-card col-12 col-lg-6">
                                <div className="card-body p-2">
                                  <div className="d-flex justify-content-between p-md-1">
                                    <div className="d-flex flex-row">
                                      <div className="align-self-center">
                                        <div className="file-card-icon  bg-white text-danger">
                                          <i className="fa fa-file-pdf"></i>
                                        </div>
                                      </div>
                                      <div className="ms-2">
                                        <p className="mb-0">Adam Smith resume.pdf</p>
                                        <p className="mb-0">24kb</p>
                                      </div>
                                    </div>
                                    <div className="align-self-center">
                                      <div className="file-card-delete">
                                        <i className="fa-solid fa-trash "></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-lg-6">
                                <div className="card file-card">
                                  <div className="card-body p-2">
                                    <div className="d-flex justify-content-between p-md-1">
                                      <div className="d-flex flex-row">
                                        <div className="align-self-center">
                                          <div className="file-card-icon  bg-white text-danger">
                                            <i className="fa fa-file-pdf"></i>
                                          </div>
                                        </div>
                                        <div className="ms-2">
                                          <p className="mb-0">Licence_document.pdf</p>
                                          <p className="mb-0">200kb</p>
                                        </div>
                                      </div>
                                      <div className="align-self-center">
                                        <div className="file-card-delete">
                                          <i className="fa-solid fa-trash "></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>

                            <div className="tracking-item">
                              <div className="tracking-icon status-inforeceived bg-success">
                                <i className="fa-solid fa-phone-volume"></i>
                              </div>
                              <div className="tracking-content">
                                <p>
                                  <span className="p-name">Howard Carroll</span>
                                </p>
                                <p className="p-subtext">2nd attempy: she answered the call and mentioned she is no longer looking for work 3:20 PM</p>
                                <p className="p-dark-gray">Client : / Pay Rates :  $ <span className="p-light-gray">06/26/2022, 19:46:21</span></p>
                              </div>
                            </div>

                            <div className="tracking-item">
                              <div className="tracking-icon status-intransit bg-blue">
                                <img src={bagCheckWhiteImg} className="w-100" />
                              </div>
                              <div className="tracking-content">
                                <p>
                                  <span className="p-name">Cynthia Villa</span>
                                  <span className="p-changed"> Changed status to </span>
                                  <span className="p-status"><span className="badge rounded-pill bg-danger"> Incomplete</span></span>
                                </p>
                                <p className="p-subtext">Reference site about Lorem Ipsum, giving information on its origins</p>
                                <p className="p-dark-gray"><span className="p-light-gray">06/26/2022, 19:46:21</span></p>
                              </div>
                            </div>

                            <div className="tracking-item pb-0">
                              <div className="tracking-icon status-intransit bg-warning">
                                <i className="fa-solid fa-cloud-arrow-up"></i>
                              </div>
                              <div className="tracking-content">
                                <p>
                                  <span className="p-name">Corey Jimenez</span>
                                  <span className="p-changed"> Uploaded documents</span>
                                </p>
                                <p className="p-subtext">Document type others </p>
                                <span className="p-light-gray">06/26/2022, 20:46:21</span>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="col-md-4 col-lg-4  end-r-3 p-3 ">
                          <div className=" a-card">
                            Attachments
                            <div className="ms-auto">
                              <div className="close-btn">
                                <i className="fa fa-times" aria-hidden="true"></i>
                              </div>
                            </div>
                            <div className="d-flex a-card-item mt-3">
                              <img src={i9Img} />
                              <span className="a-card-item-text">
                                I9
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                2 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={fileImg} />
                              <span className="a-card-item-text">
                                W4
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                3 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={genderImg} />
                              <span className="a-card-item-text">
                                Sexual Harassment
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                6 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={termsImg} />
                              <span className="a-card-item-text">
                                Terms
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                3 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={folderImg} />
                              <span className="a-card-item-text">
                                Others
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                8 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={filesImg} />
                              <span className="a-card-item-text">
                                All
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                12 Items
                              </span>
                            </div>

                            <div className="p-3 pt-5">
                              <div className="float-end">
                                <button className="c-btn dark-btn mx-2" type="button" >Add Note</button>
                                <button className="c-btn dark-btn" type="button" >Message</button>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>








                    </div>
                  </div>
                  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  </div>
                  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">cccc ...</div>
                </div>




                {/* <div className="  wrapper">
                <ul className="StepProgress">
                  <li className="StepProgress-item is-done"><strong>Post a contest</strong></li>
                  <li className="StepProgress-item is-done"><strong>Award an entry</strong>
                    Got more entries that you love? Buy more entries anytime! Just hover on your favorite entry and click the Buy button
                  </li>
                  <li className="StepProgress-item current"><strong>Post a contest</strong></li>
                  <li className="StepProgress-item"><strong>Handover</strong></li>
                  <li className="StepProgress-item"><strong>Provide feedback</strong></li>
                </ul>
              </div> */}



              </div>
            </div>
          </div>




        </div>








        {/* End main section */}


        {/* Apply More Filters */}


      </div >


    </div >

  )
}



export default Demo;