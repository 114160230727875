import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect } from 'react';


function DoughnutChart(props) {

    const backgroundColor = ['rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(255, 205, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(201, 203, 207, 0.2)'];
    const borderColor = ['rgb(255, 99, 132)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)']

    const data = {
        labels: props.labelsChart,
        datasets: [
            {
                label: props.legendChart,
                data: props.valuesChart,
                backgroundColor: props.backgroundColor ? props.backgroundColor : backgroundColor,
                borderColor: props.backgroundColor ? '' : borderColor,
                borderWidth: props.backgroundColor ? 0 : 1
            },
        ],
    };
    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            }
        },
        responsive: true,
        maintainAspectRatio: true,
    };

    return (
        <>
            {props.labelsChart &&
                <Doughnut data={data} plugins={[ChartDataLabels]} options={options} />
            }
        </>
    );
}
export default DoughnutChart;