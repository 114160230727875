import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    selectedFilter: 0,
    id: "",
    name: "",
    defaultFilter: false,
    where: {
        location: {
            value: [],
            text: "All"
        },
        scheduleStatus: {
            value: [],
            text: "All"
        },
        name: ""
    },
    refreshData: false
};

export const scheduleFilterSlice = createSlice({
    name: 'scheduleFilter',
    initialState,
    reducers: {
        updateScheduleRefreshData: (state, action) => {
            state.refreshData = action.payload;
        },
        updateScheduleFilter: (state, action) => {
            let { id, name, whereClause, defaultFilter } = action.payload;
            state.id = id;
            state.name = name;
            state.defaultFilter = defaultFilter;
            whereClause = whereClause ? whereClause : {
                location: {
                    value: [],
                    text: "All"
                },
                scheduleStatus: {
                    value: [],
                    text: "All"
                },
                name: ""
            };
            state.where = Object.assign({}, whereClause);
        },
        updateLocationFilter: (state, action) => {
            const { locations, value } = action.payload;
            let location = { ...state.where.location };
            let text = "All";
            if (!location.value.includes(value)) {
                location.value = [...location.value, value];
            }
            else {
                location.value = location.value.filter((val) => (val !== value));
            }

            if (value == 'All' && !location.value.includes('All')) {
                location.value = [];
            } else if (value == 'All') {
                location.value = locations.map((d) => d.value);
            } else {
                location.value = location.value.filter((val) => (val !== 'All'));
            }

            if (locations) {
                let _locations = locations.map((d) => d.value);
                _locations = _locations.filter((d) => (d !== 'All'))
                if (_locations.length === location.value.length) {
                    location.value.push('All')
                }
            }

            if (value == 'All') {
                text = "All";
            } else {
                if ((locations && Array.isArray(locations) && locations.length > 0) && location.value.length > 0) {
                    text = "";
                    for (let i = 0; i < locations.length; i++) {
                        let loc = locations[i];
                        if (location.value.includes(loc.value) && !location.value.includes('All')) {
                            text += `${loc.text},`
                        }
                    }
                    text = text.slice(0, -1);
                }
            }
            location.text = text;
            location.text = location.value.length > 0 && !location.value.includes('All') ? `(${location.value.length}) selected` : "All";
            state.where.location = location;
        },
        updateScheduleStatusFilter: (state, action) => {
            const { scheduleStatuses, value } = action.payload;
            let scheduleStatus = { ...state.where.scheduleStatus };
            let text = "All";
            if (!scheduleStatus.value.includes(value)) {
                scheduleStatus.value = [...scheduleStatus.value, value];
            }
            else {
                scheduleStatus.value = scheduleStatus.value.filter((val) => (val !== value));
            }
            if ((scheduleStatuses && Array.isArray(scheduleStatuses) && scheduleStatuses.length > 0) && scheduleStatus.value.length > 0) {
                text = "";
                for (let i = 0; i < scheduleStatuses.length; i++) {
                    let loc = scheduleStatuses[i];
                    if (scheduleStatus.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            scheduleStatus.text = text;
            scheduleStatus.text = scheduleStatus.value.length > 0 ? `(${scheduleStatus.value.length}) selected` : "All";
            state.where.scheduleStatus = scheduleStatus;
        },
        updateNameFilter: (state, action) => {
            state.where.name = action.payload;
        }
    }
});

export const { updateScheduleRefreshData, updateScheduleFilter, updateLocationFilter,
    updateScheduleStatusFilter, updateNameFilter } = scheduleFilterSlice.actions;

export default scheduleFilterSlice.reducer

export const selectLocationFilter = state => state.scheduleFilter.where.location;
export const selectScheduleStatusFilter = state => state.scheduleFilter.where.scheduleStatus;

export const selectFilterId = state => state.scheduleFilter.id;

export const selectFilterName = state => state.scheduleFilter.name;

export const selectWhereClause = state => state.scheduleFilter.where;

export const selectDefaultFilter = state => state.scheduleFilter.defaultFilter;

export const selectRefreshData = state => state.scheduleFilter.refreshData;

export const selectWhereClauseChanged = state => {
    const whereClause = state.scheduleFilter.where;
    if (whereClause.location.value.length > 0) {
        return true;
    }
    if (whereClause.scheduleStatus.value.length > 0) {
        return true;
    }
    return false;
}