import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    title: "",
    id: "",
    show: false
};

export const changePasswordModalPopUpSlice = createSlice({
    name: 'changePasswordModalPopUp',
    initialState,
    reducers: {
        showCPModal: (state, action) => {
            const { title, id } = action.payload;
            state.title = title;
            state.id = id;
            state.show = true;
        },
        closeModal: state => {
            state.show = false;
        }
    }
});

export const { showCPModal, closeModal } = changePasswordModalPopUpSlice.actions;

export default changePasswordModalPopUpSlice.reducer

export const selectModalPopUpDetail = state => {
    return {
        title: state.changePasswordModalPopUp.title,
        id: state.changePasswordModalPopUp.id,
        show: state.changePasswordModalPopUp.show
    }
}