import Collection from "../collection";

export const refreshRemoved = (pageCollection, id, currentPage) => {
    if (currentPage >= 0) {
        let pages = new Collection();
        let blankPage = false;
        for (let i = 0; i < pageCollection.Count; i++) {
            const page = pageCollection.Items[i];
            //if (pageCollection.Contains(i)) {
            //const page = pageCollection.Get(i);
            if (page.key === currentPage) {
                page.records = page.records.filter(r => r.id !== id);
            }
            if (page.records.length > 0) {
                let j = i;
                if (blankPage) {
                    j--;
                }
                pages.Add(page.key, page.records);
            }
            else {
                blankPage = true;
            }
            //}
        }

        return {
            records: pages,
            lastPage: currentPage
        };
    }
    return undefined;
};