import { toast } from "react-toastify";

export const toaster = {
    success: (content,
        position = "top-center",
        autoClose = 3000,
        hideProgressBar = true,
        closeOnClick = true,
        pauseOnHover = true,
        draggable = true,
        progress = undefined) => {
        if (content) {
            toast.success(content, {
                position: position,
                autoClose: autoClose,
                hideProgressBar: hideProgressBar,
                closeOnClick: closeOnClick,
                pauseOnHover: pauseOnHover,
                draggable: draggable,
                progress: progress,
            });
            // alert(content);
        }
    },
    error: (content,
        position = "top-center",
        autoClose = 3000,
        hideProgressBar = true,
        closeOnClick = true,
        pauseOnHover = true,
        draggable = true,
        progress = undefined) => {
        if (content) {
            toast.error(content, {
                position: position,
                autoClose: autoClose,
                hideProgressBar: hideProgressBar,
                closeOnClick: closeOnClick,
                pauseOnHover: pauseOnHover,
                draggable: draggable,
                progress: progress,
            });
            // alert(content);
        }
    }
};