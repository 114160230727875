import {
    createSlice, nanoid
}
    from '@reduxjs/toolkit';
import { FilterType } from '../../../../common/constants';

const initialState = {
    title: "",
    jobId: "",
    show: false,
    filterType: FilterType.JOB,
    saving: nanoid()
};

export const saveJobFilterModalPopUpSlice = createSlice({
    name: 'saveJobFilterModalPopUp',
    initialState,
    reducers: {
        showJSFModal: (state, action) => {
            const { title, jobId } = action.payload;
            state.title = title;
            state.jobId = jobId;
            state.show = true;
            state.saving = nanoid();
        },
        closeModal: state => {
            state.title = "";
            state.jobId = "";
            state.show = false;
            state.filterType = FilterType.JOB;
        }
    }
});

export const { showJSFModal, closeModal } = saveJobFilterModalPopUpSlice.actions;

export default saveJobFilterModalPopUpSlice.reducer

export const selectModalPopUpDetail = state => {
    return {
        title: state.saveJobFilterModalPopUp.title,
        id: state.saveJobFilterModalPopUp.jobId,
        show: state.saveJobFilterModalPopUp.show,
        filterType: state.saveJobFilterModalPopUp.filterType,
    }
}

export const selectSavingStatus = state => state.saveFilterModalPopUp.saving;