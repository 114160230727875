import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    title: "",
    show: false,
    isActive: false,
    applicantId: undefined
};

export const chatFlyoutSlice = createSlice({
    name: 'chatFlyout',
    initialState,
    reducers: {
        showChatFlyout: (state, action) => {
            const { title, applicantId, confirmedCallback } = action.payload
            state.title = title;
            state.show = true;
            state.isActive = false;
            state.applicantId = applicantId;
        },
        closeChatFlyout: state => {
            state.show = false;
            state.applicantId = undefined;
        },
        beforeCloseChatFlyout: state => {
            state.isActive = true;
        }
    }
});

export const { showChatFlyout, closeChatFlyout, beforeCloseChatFlyout } = chatFlyoutSlice.actions;

export default chatFlyoutSlice.reducer

export const selectFlyoutDetail = state => {
    return {
        title: state.chatFlyout.title,
        show: state.chatFlyout.show,
        isActive: state.chatFlyout.isActive,
        applicantId: state.chatFlyout.applicantId
    }
}
export const selectChatFlyoutShow = state => state.chatFlyout.show;