import * as React from 'react';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import './css/button.css';


function BackButton(props) {
    return (
        <button
            type="button"
            // className={`btn btn-transparent ${props.BackButtonClasses ? props.BackButtonClasses : ""}`}
            // className={`btn btn btn-outline-dark ${props.BackButtonClasses ? props.BackButtonClasses : ""}`}
            className={`btn back-button ${props.BackButtonClasses ? props.BackButtonClasses : ""}`}
            title={props.title ? props.title : "Click to go back"}
            onClick={props.onBackClicked}>
            {props.text ? props.text : "Back"}
        </button>
    );
}

export default BackButton;