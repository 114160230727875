import React, { useEffect, useState } from 'react';
import CheckBox from './checkers/checkbox';

function MindMapSwitch(props) {
    const [checked, setChecked] = useState(props.checked ? true : false);

    useEffect(() => {
        setChecked(props.checked ? true : false);
    }, [props.checked])

    const onClick = (e, c) => {
        e.target.name = props.id;
        setChecked(c);
        props.onChange && props.onChange(e, c);
    };

    return (
        <div className="row switch-container m-0">
            <div className="col-md-12 switch-container-content">
                <label className='c-label'>
                    {props.title ? props.title : "Please add title here"}
                </label>
                {/* <div className="switch">
                    <div className="d-flex" onClick={(e) => onClick(e, !checked)}>
                        <label className="switch-label">Yes</label>
                        <CheckBox
                            size="Medium"
                            id="msEDDetails"
                            checked={checked}
                            round={true} />
                    </div>
                </div> */}
                <div className="form-check form-switch" >
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onClick={(e) => onClick(e, !checked)} checked={checked} />
                    <label className="form-check-label" for="flexSwitchCheckDefault">Yes</label>
                </div>
            </div>
        </div>
    );
}

export default MindMapSwitch;