export const onStatusChanged = (applicant, statusDetails) => {
    let _applicant = JSON.parse(JSON.stringify(applicant));
    _applicant.Status = statusDetails.status;
    _applicant.PayRates = statusDetails.payRates;
    _applicant.Client = statusDetails.client;
    if (!_applicant.StatusLogs) {
        _applicant.StatusLogs = [];
    }
    _applicant.StatusLogs.push(statusDetails.log);
    return _applicant;
};