import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CheckBox from '../custom/checkers/checkbox';


function AssignedToFilter(props) {

    const assignedToFilter = useSelector(props.assignedToFilterSelector);

    const [open, setOpen] = useState(false);

    const assignedToContainerRef = useRef(null);

    let _assignedTos = props.assignedTos;

    let assignedTosList;
    let isAssignedTosSelected = (assignedToFilter.value && Array.isArray(assignedToFilter.value) && assignedToFilter.value.length > 0);
    if (_assignedTos && Array.isArray(_assignedTos) && _assignedTos.length > 0) {

        assignedTosList = _assignedTos.map((assignedTo, index) => (
            index == 0 ?
                <>
                    <li key={'All'} className="filter-list-item"
                        onClick={(e) => props.onAssignedToChanged && props.onAssignedToChanged(e, 'All', _assignedTos)}>
                        <CheckBox
                            id={`All`}
                            size="Small"
                            checked={isAssignedTosSelected ? assignedToFilter.value.includes('All') : false}
                        />
                        {
                            isAssignedTosSelected ?
                                <span className={`ms-3 ${isAssignedTosSelected && assignedToFilter.value.includes('All') ? "filter-selected" : ""}`}>All</span>
                                :
                                <span className={`ms-3`}>All</span>
                        }
                    </li>
                    <li key={assignedTo.id} className="filter-list-item"
                        onClick={(e) => props.onAssignedToChanged && props.onAssignedToChanged(e, assignedTo.id, _assignedTos)}>
                        <CheckBox
                            id={`ckbAssignedTo-${assignedTo.id}`}
                            size="Small"
                            checked={isAssignedTosSelected ? assignedToFilter.value.includes(assignedTo.id) : false}
                        />
                        {
                            isAssignedTosSelected ?
                                <span className={`ms-3 ${isAssignedTosSelected && assignedToFilter.value.includes(assignedTo.id) ? "filter-selected" : ""}`}>{assignedTo.FirstName + ' ' + assignedTo.LastName
                                }</span>
                                :
                                <span className={`ms-3`}>{assignedTo.FirstName + ' ' + assignedTo.LastName}</span>
                        }
                    </li>
                </>
                :
                <li key={assignedTo.id} className="filter-list-item"
                    onClick={(e) => props.onAssignedToChanged && props.onAssignedToChanged(e, assignedTo.id, _assignedTos)}>
                    <CheckBox
                        id={`ckbAssignedTo-${assignedTo.id}`}
                        size="Small"
                        checked={isAssignedTosSelected ? assignedToFilter.value.includes(assignedTo.id) : false}
                    />
                    {
                        isAssignedTosSelected ?
                            <span className={`ms-3 ${isAssignedTosSelected && assignedToFilter.value.includes(assignedTo.id) ? "filter-selected" : ""}`}>{assignedTo.FirstName + ' ' + assignedTo.LastName}</span>
                            :
                            <span className={`ms-3`}>{assignedTo.FirstName + ' ' + assignedTo.LastName}</span>
                    }
                </li>
        ));
    }

    useEffect(() => {
        if (open && assignedToContainerRef && assignedToContainerRef.current) {
            assignedToContainerRef.current.focus();
        }
    });

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };


    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Assigned To</span> :
                <span className="filter-value">{assignedToFilter.value ? assignedToFilter.text : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={assignedToContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    {assignedTosList}
                </ul>
            </div> : <></>}
        </div>
    )
}

export default AssignedToFilter;