import React, { useEffect, useRef, useState } from 'react';
import Inputmask from "inputmask";


function ZipCodeText(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);



    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value) {// || isNaN(value)) {

                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
            // else if (isNaN(value)) {
            //     setInvalid(true);
            // }
        }
        else {
            setInvalid(false);
        }
    }, [value, props.required, edited, props.submited, props.disabled]);

    // useEffect(() => {
    //     var zipCodeInput = document.getElementById(props.id);
    //     var inputMask = new Inputmask("99999");
    //     inputMask.mask(zipCodeInput);
    // });

    const onChange = (e) => {
        props.onChange && props.onChange(e);
        setValue(e.target.value);
        setEdited(true);
    };

    return (
        <React.Fragment>
            <input
                type="text"
                id={props.id}
                name={props.name}
                value={value}
                // className={`c-form-control ${invalid && "e-error"}`}
                // className={`${props.ZipCodeTextClasses ? props.ZipCodeTextClasses : ""} ${invalid && "is-invalid"}`}
                className={`form-control ${invalid && "is-invalid"}`}
                placeholder={`${props.placeholder ? props.placeholder : ""}`}
                onChange={(e) => onChange(e)}
                disabled={props.disabled === true}
                formNoValidate={true}
                data-form={props.form ? props.form : ""}
                data-invalid={invalid}
                data-required={props.required ? props.required : false}
            />
            {
                invalid && <span className="error-label">
                    <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            }
        </React.Fragment>
    );
}

export default ZipCodeText;