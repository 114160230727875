import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatus, tenantId } from '../../../api/client';
import { getBase64Image, _getDateText, getFileSizeText } from '../../../common/common';
import { accountService } from '../../../features/accounts/services/accountService';
import { addLeadToClient, selectConvertToClientData, selectConvertToClientStatus, updateSingleData } from '../../../features/crm/reducerSlices/leadSlice';
import { onFormUploaded, onStatusChanged } from '../../../features/crm/services/statusSaver';
import CancelButton from '../custom/buttons/cancelButton';
import SaveButton from '../custom/buttons/saveButton';
import InputGroup from '../custom/inputGroup/inputGroup';
import EllipsisLoader from '../loaders/ellipsisLoader';
import { isFormValid } from '../services/formService';
import Statement from '../custom/textbox/statement';
import { useDropzone } from 'react-dropzone';
import { closeModal, saveUpdatedLead, selectModalPopUpDetail } from './reducerSlices/leadStatusUpdateModelPopUpSlice';
import ShortText from '../textboxes/shortText';
import pdfImg from '../../../assets/images/clipart/pdf.svg';

function LeadStatusUpdateModalPopUp(props) {

    const dispatch = useDispatch();

    const STATUS_UPDATE_FORM = "StatusUpdateForm";

    // const statusChangeData = useSelector(selectStatusChangeData);
    const statusChangeData = useSelector(selectConvertToClientData);
    const statusChangeStatus = useSelector(selectConvertToClientStatus);
    const modalPopUpDetails = useSelector(selectModalPopUpDetail);

    const lead = modalPopUpDetails.lead;

    const _status = modalPopUpDetails.status.text;

    const refreshJobs = modalPopUpDetails.refreshJobs;

    const [sufSubmitted, setSUFSubmitted] = useState(false);
    const [statusNote, setStatusNote] = useState("");
    const [payroll, setPayroll] = useState("");
    const [udReason, setUDReason] = useState("");

    const [cl, setCL] = useState("0");

    const [loading, setLoading] = useState(false);

    const [fileUploder, setFileUploder] = useState(false);

    const [files, setFiles] = useState([]);
    const [file, setFile] = useState();
    const [base64Image, setBase64Image] = useState("");
    const [value, setValue] = useState("");
    const [size, setSize] = useState(0);
    const [edited, setEdited] = useState(false);
    const [invalid, setInvalid] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        accept: '*',
        onDrop: acceptedFiles => {
            console.log(`acceptedFiles`);
            console.log(acceptedFiles);

            let file = acceptedFiles[0];
            getBase64Image(file)
                .then(result => {
                    result = result.split(',').pop();
                    file["base64"] = result;
                    setBase64Image(result);
                })
                .catch(err => {
                    console.log(err);
                });

            setFile(acceptedFiles[0]);
            setSize(acceptedFiles[0].size);
            setValue(acceptedFiles[0].name);
            setEdited(true);


            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const _getLogValue = (status) => {
        if (status === "In Progress") {
            return 1;
        }
        else if (status === "Completed") {
            return 18;
        }
        else if (status === "Rejected") {
            return 19;
        }
        else if (status === "New") {
            return 20;
        }
        else if (status === "Credit Check") {
            return 21;
        }
        else if (status === "Safety Check") {
            return 22;
        }
        else if (status === "Proposal") {
            return 23;
        }
        else if (status === "SA") {
            return 24;
        }
        else if (status === "COI") {
            return 25;
        }
        return 0;
    }

    useEffect(() => {
        if (statusChangeStatus !== fetchStatus.LOADING) {
            setStatusNote("");
            setPayroll("");
            setFile("");
            setFiles("");
            setValue("");
            setBase64Image("");
            setCL("0");
            dispatch(closeModal());
        }
        if (statusChangeStatus === fetchStatus.SUCCEEDED) {
            if (statusChangeData && statusChangeData.id) {
                dispatch(updateSingleData(statusChangeData));
            }
        }
    }, [statusChangeStatus]);

    const onCloseClicked = () => {
        setStatusNote("");
        setPayroll("");
        setFile("");
        setFiles("");
        setValue("");
        setBase64Image("");
        dispatch(closeModal());
        setSUFSubmitted(false);
        setCL("0");
    };

    const _getDocumentTypes = (status) => {
        if (status === "In Progress") {
            return 51;
        }
        else if (status === "Credit Check") {
            return 52;
        }
        else if (status === "Safety Check") {
            return 53;
        }
        else if (status === "Proposal") {
            return 54;
        }
        else if (status === "Completed") {
            return 55;
        }
        else if (status === "SA") {
            return 56;
        }
        else if (status === "COI") {
            return 57;
        }
        return 0;
    }

    const onSaveClicked = async (id) => {
        const user = accountService.getAuthenticatedUser();
        if (isFormValid(STATUS_UPDATE_FORM)) {

            if (_status === "Credit Check" || _status === "Safety Check" || _status === "Proposal" || _status === "Completed" || _status === "SA" || _status === "COI") {
                if (file && file.size >= 1) {

                } else {
                    setInvalid(true);
                    return;
                }
            }

            let statusDetails = {
                Status: modalPopUpDetails.status.text,
                statusNote: statusNote,
                log: {
                    TimeStamp: (new Date()).toUTCString(),
                    LogType: modalPopUpDetails.status.text,
                    LogValue: _getLogValue(modalPopUpDetails.status.value),
                    Content: statusNote,
                    UserName: (user ? user.firstName + " " + user.lastName : ""),
                    UserId: (user ? user.id : ""),
                    StatusNote: statusNote
                }
            };
            console.log('statusDetails', statusDetails)
            if (_status === "Completed") {
                statusDetails.Payroll = payroll;
            }
            let _leadObj = Object.assign({}, lead);
            let _newLeadObj = onStatusChanged(_leadObj, statusDetails);

            let fileDetails;
            if (file && file.size >= 1) {
                let documentId = nanoid();
                fileDetails = {
                    length: file.size,
                    fileContent: base64Image,
                    documentData: {
                        id: documentId,
                        UploadedOn: (new Date()).toUTCString(),
                        DocumentName: documentId + "_" + file.name,
                        DocumentType: _getDocumentTypes(modalPopUpDetails.status.value),
                        DocumentTypeText: modalPopUpDetails.status.value,
                        Note: statusNote,
                        UploadedBy: (user ? user.firstName + " " + user.lastName : ""),
                        UploadedFileInfo: "",
                        Length: file.size
                    }
                };
                _newLeadObj = onFormUploaded(_newLeadObj, fileDetails);

                statusDetails = { ...statusDetails, fileDeatils: { ...fileDetails } };

            }

            dispatch(saveUpdatedLead(statusDetails));
            setSUFSubmitted(false);
            if (id) {
                dispatch(addLeadToClient({
                    client: tenantId,
                    serviceType: "TENANTADMIN",
                    operation: "SAVELEADSTATUS",
                    payload: {
                        id: lead.id,
                        data: JSON.stringify(statusDetails)
                    }
                }));
            }
        }
        else {
            setSUFSubmitted(true);
        }
    };

    useEffect(() => {
        if (edited || sufSubmitted) {
            if (!value) {
                setInvalid(true);
            }
            else {
                setInvalid(false);
            }
        }
        else {
            setInvalid(false);
        }
        setEdited(false);

    }, [value, edited, sufSubmitted]);


    return (
        <>
            {(modalPopUpDetails && modalPopUpDetails.show) ? (<>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">

                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-block">
                                <div className="d-flex">
                                    <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                                    {statusChangeStatus === fetchStatus.LOADING ? <></> : <button
                                        type="button"
                                        className="modal-close-button ms-auto"
                                        onClick={onCloseClicked}>
                                        <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                    </button>}
                                </div>
                                <div className="d-flex">
                                    <span className="modal-sub-title">Lead status change</span>
                                </div>
                            </div>
                            <div className="modal-body">
                                {(_status === "Credit Check" || _status === "Safety Check" || _status === "Proposal" || _status === "Completed" || _status === "SA" || _status === "COI") &&
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <div className="c-input-group">
                                                <label className="c-label" for="fuDocument">Document</label>
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    {/* <div className={`file-upload form-control-file ${invalid && "invalid-input"}`}>
                                                        <span className={`choose-file ${invalid ? "invalid-choose-file" : ""}`}>Choose File</span>
                                                        <span className="chosen">{value ? value : "No files chosen"}</span>
                                                        <span className={`upload ${invalid ? "text-danger" : (value != "" ? "text-success" : "text-primary")}`}><i className="fa fa-cloud-upload"></i></span>
                                                    </div>
                                                    <span>Drag 'n' drop some files here, or click to choose files</span> */}
                                                    {
                                                        <div className='pt-1'>
                                                            <div className={`file-upload-box ${invalid && "file-upload-box-invalid"}`}>
                                                                <div className="flyout-list-group-item-card">
                                                                    <i className="fa-solid fa-cloud-arrow-up"></i>
                                                                    <span className="ps-2">
                                                                        <div className='file-upload-text'>Drag & Drop files here <span className='text-secondary'>or</span></div>
                                                                    </span>
                                                                    <span className="ms-auto">
                                                                        <button className="c-btn dark-btn rounded-pill" type="button" >Choose Files</button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    invalid && <span className="error-label">
                                                        <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                                        {props.errorMessage ? props.errorMessage : "This field is required"}
                                                    </span>
                                                }
                                                <label className="c-label pt-2">Attached File</label>
                                                {
                                                    value &&
                                                    <div className="flyout-list-group-item-card p-0 pt-1">
                                                        <div className='sub-icon'>
                                                            <img src={pdfImg} className="icon-img" />
                                                            <p className='file-size-text'>{getFileSizeText(size)}</p>
                                                        </div>
                                                        <span className="ps-2">
                                                            <div className='file-name'> {value}</div>
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(_status === "Completed") &&
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <label id="lblStatusNote" className="field-label" htmlFor="txtStatusNote">Payroll</label>
                                            <InputGroup
                                                icon={<i className="fa fa-address-card"></i>}
                                                control={
                                                    <ShortText
                                                        id="txtPayroll"
                                                        name="txtPayroll"
                                                        ShortTextClasses="form-control"
                                                        placeholder=""
                                                        value={payroll}
                                                        onChange={(e) => setPayroll(e.target.value)}
                                                        required={true}
                                                        errorMessage="Please enter payroll"
                                                        form={STATUS_UPDATE_FORM}
                                                        submited={sufSubmitted} />
                                                } />

                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-md-12">
                                        <label id="lblStatusNote" className="field-label" htmlFor="txtStatusNote">Note</label>
                                        <InputGroup
                                            icon={<i className="fa fa-pencil-square-o"></i>}
                                            control={
                                                <Statement
                                                    id="txtStatusNote"
                                                    name="txtStatusNote"
                                                    value={statusNote}
                                                    StatementClasses="form-control"
                                                    onChange={(e) => setStatusNote(e.target.value)}
                                                    required={true}
                                                    errorMessage="Please enter status note"
                                                    form={STATUS_UPDATE_FORM}
                                                    submited={sufSubmitted}
                                                    placeholder="Enter status note..." />
                                            } />

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {(statusChangeStatus === fetchStatus.LOADING) ? <EllipsisLoader /> : <><CancelButton
                                    CancelButtonClasses="mr-2"
                                    onCancelClicked={() => onCloseClicked()} />
                                    <SaveButton
                                        onSaveClicked={() => onSaveClicked(modalPopUpDetails.id)} /></>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </>) : <></>}
        </>
    );
}

export default LeadStatusUpdateModalPopUp;