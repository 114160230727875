import React from 'react';

function SaveButton(props) {
    return (
        <button
            type="button"
            className={`c-btn dark-btn  ${props.SaveButtonClasses ? props.SaveButtonClasses : ""}`}
            title={props.toolTipText ? props.toolTipText : "Accept changes"}
            onClick={(e) => props.onSaveClicked && props.onSaveClicked(e)}>
            {/* <i className='fa fa-solid fa-floppy-disk me-2'></i> */}
            {props.text ? props.text : "Save"}
        </button>
    );
}

export default SaveButton;