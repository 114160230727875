import {
    createSlice,
    createAsyncThunk,
    nanoid
}
from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    graphCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    registrationCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    dispatchCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    interviewCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    jobCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    clientCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    noShowCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    callCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    statusCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    leadStatusCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    dispatchListCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    interviewListCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    employeePerformanceListCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    leadPerformanceListCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    activePeopleCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    userOptions: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    emailBodyDashboard: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    }
};

export const getUserOptions = createAsyncThunk('dashbaord/GetUserOptions', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let userOptionsData;
        if (isJSON(data)) {
            userOptionsData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            userOptions: userOptionsData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getGraphCounts = createAsyncThunk('dashbaord/GetGraphCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let dashbaordData;
        if (isJSON(data)) {
            dashbaordData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            dashbaordData: dashbaordData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getRegistrationCounts = createAsyncThunk('dashbaord/GetRegistrationCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let registrationData;
        if (isJSON(data)) {
            registrationData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            registrationData: registrationData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getDispatchCounts = createAsyncThunk('dashbaord/GetDispatchCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let dispatchData;
        if (isJSON(data)) {
            dispatchData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            dispatchData: dispatchData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getInterviewCounts = createAsyncThunk('dashbaord/GetInterviewCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let interviewData;
        if (isJSON(data)) {
            interviewData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            interviewData: interviewData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getJobCounts = createAsyncThunk('dashbaord/GetJobCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let jobData;
        if (isJSON(data)) {
            jobData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            jobData: jobData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getClientCounts = createAsyncThunk('dashbaord/GetClientCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let clientData;
        if (isJSON(data)) {
            clientData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            clientData: clientData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});


export const getNoShowCounts = createAsyncThunk('dashbaord/GetNoShowCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let noShowData;
        if (isJSON(data)) {
            noShowData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            noShowData: noShowData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});


export const getCallCounts = createAsyncThunk('dashbaord/GetCallCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let callData;
        if (isJSON(data)) {
            callData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            callData: callData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getStatusCounts = createAsyncThunk('dashbaord/GetStatusCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let statusData;
        if (isJSON(data)) {
            statusData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            statusData: statusData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getLeadStatusCounts = createAsyncThunk('dashbaord/GetLeadStatusCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let leadStatusData;
        if (isJSON(data)) {
            leadStatusData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            leadStatusData: leadStatusData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getDispatchListCounts = createAsyncThunk('dashbaord/GetDispatchListCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let dispatchListData;
        if (isJSON(data)) {
            dispatchListData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            dispatchListData: dispatchListData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getInterviewListCounts = createAsyncThunk('dashbaord/GetInterviewListCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let interviewListData;
        if (isJSON(data)) {
            interviewListData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            interviewListData: interviewListData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getEmployeePerformanceListCounts = createAsyncThunk('dashbaord/GetEmployeePerformanceListCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let employeePerformanceListData;
        if (isJSON(data)) {
            employeePerformanceListData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            employeePerformanceListData: employeePerformanceListData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getLeadPerformanceListCounts = createAsyncThunk('dashbaord/GetLeadPerformanceListCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let leadPerformanceListData;
        if (isJSON(data)) {
            leadPerformanceListData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            leadPerformanceListData: leadPerformanceListData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getActivePeopleCounts = createAsyncThunk('dashbaord/getActivePeopleCounts', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', dashbaordModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let activePeopleData;
        if (isJSON(data)) {
            activePeopleData = JSON.parse(data);
        }
        return {
            page: dashbaordModel.page,
            activePeopleData: activePeopleData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const getEmailBodyDashboard = createAsyncThunk('dashbaord/getEmailBodyDashboard', async(dashbaordModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Identity/emailBodyDashboard', dashbaordModel, getAxiosRequestConfig());
        return {
            data: response.data,
            success: response.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }


});

export const dashbaordSlice = createSlice({
    name: 'dashboards',
    initialState,
    reducers: {
        updateGraphCountsStatus: (state) => {
            state.graphCount.status = fetchStatus.DONE;
        },
        updateRegistrationCountsStatus: (state) => {
            state.registrationCount.status = fetchStatus.DONE;
        },
        updateDispatchCountsStatus: (state) => {
            state.dispatchCount.status = fetchStatus.DONE;
        },
        updateInterviewCountsStatus: (state) => {
            state.interviewCount.status = fetchStatus.DONE;
        },
        updateJobCountsStatus: (state) => {
            state.jobCount.status = fetchStatus.DONE;
        },
        updateClientCountsStatus: (state) => {
            state.clientCount.status = fetchStatus.DONE;
        },
        updateNoShowCountsStatus: (state) => {
            state.noShowCount.status = fetchStatus.DONE;
        },
        updateCallCountsStatus: (state) => {
            state.callCount.status = fetchStatus.DONE;
        },
        updateStatusCountsStatus: (state) => {
            state.statusCount.status = fetchStatus.DONE;
        },
        updateStatusCounts: (state) => {
            state.statusCount.data = undefined;
        },
        updateDispatchListCountsStatus: (state) => {
            state.dispatchListCount.status = fetchStatus.DONE;
        },
        updateInterviewListCountsStatus: (state) => {
            state.interviewListCount.status = fetchStatus.DONE;
        },
        updateEmployeePerformanceCountsStatus: (state) => {
            state.employeePerformanceListCount.status = fetchStatus.DONE;
        },
        updateLeadPerformanceCountsStatus: (state) => {
            state.leadPerformanceListCount.status = fetchStatus.DONE;
        },
        updateActivePeopleCountsStatus: (state) => {
            state.activePeopleCount.status = fetchStatus.DONE;
        },
        updateUserOptionsStatus: (state) => {
            state.userOptions.status = fetchStatus.DONE;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getGraphCounts.pending, (state, action) => {
            state.graphCount.status = fetchStatus.LOADING;
        }).addCase(getGraphCounts.fulfilled, (state, action) => {
            state.graphCount.data = action.payload.dashbaordData;
            state.graphCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getGraphCounts.rejected, (state, action) => {
            state.graphCount.status = fetchStatus.FAILED;
            state.graphCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getRegistrationCounts.pending, (state, action) => {
            state.registrationCount.status = fetchStatus.LOADING;
        }).addCase(getRegistrationCounts.fulfilled, (state, action) => {
            state.registrationCount.data = action.payload.registrationData;
            state.registrationCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getRegistrationCounts.rejected, (state, action) => {
            state.registrationCount.status = fetchStatus.FAILED;
            state.registrationCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getDispatchCounts.pending, (state, action) => {
            state.dispatchCount.status = fetchStatus.LOADING;
        }).addCase(getDispatchCounts.fulfilled, (state, action) => {
            state.dispatchCount.data = action.payload.dispatchData;
            state.dispatchCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getDispatchCounts.rejected, (state, action) => {
            state.dispatchCount.status = fetchStatus.FAILED;
            state.dispatchCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getInterviewCounts.pending, (state, action) => {
            state.interviewCount.status = fetchStatus.LOADING;
        }).addCase(getInterviewCounts.fulfilled, (state, action) => {
            state.interviewCount.data = action.payload.interviewData;
            state.interviewCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getInterviewCounts.rejected, (state, action) => {
            state.interviewCount.status = fetchStatus.FAILED;
            state.interviewCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getJobCounts.pending, (state, action) => {
            state.jobCount.status = fetchStatus.LOADING;
        }).addCase(getJobCounts.fulfilled, (state, action) => {
            state.jobCount.data = action.payload.jobData;
            state.jobCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getJobCounts.rejected, (state, action) => {
            state.jobCount.status = fetchStatus.FAILED;
            state.jobCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getClientCounts.pending, (state, action) => {
            state.clientCount.status = fetchStatus.LOADING;
        }).addCase(getClientCounts.fulfilled, (state, action) => {
            state.clientCount.data = action.payload.clientData;
            state.clientCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getClientCounts.rejected, (state, action) => {
            state.clientCount.status = fetchStatus.FAILED;
            state.clientCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getNoShowCounts.pending, (state, action) => {
            state.noShowCount.status = fetchStatus.LOADING;
        }).addCase(getNoShowCounts.fulfilled, (state, action) => {
            state.noShowCount.data = action.payload.noShowData;
            state.noShowCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getNoShowCounts.rejected, (state, action) => {
            state.noShowCount.status = fetchStatus.FAILED;
            state.noShowCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getCallCounts.pending, (state, action) => {
            state.callCount.status = fetchStatus.LOADING;
        }).addCase(getCallCounts.fulfilled, (state, action) => {
            state.callCount.data = action.payload.callData;
            state.callCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getCallCounts.rejected, (state, action) => {
            state.callCount.status = fetchStatus.FAILED;
            state.callCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getStatusCounts.pending, (state, action) => {
            state.statusCount.status = fetchStatus.LOADING;
        }).addCase(getStatusCounts.fulfilled, (state, action) => {
            state.statusCount.data = action.payload.statusData;
            state.statusCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getStatusCounts.rejected, (state, action) => {
            state.statusCount.status = fetchStatus.FAILED;
            state.statusCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getLeadStatusCounts.pending, (state, action) => {
            state.leadStatusCount.status = fetchStatus.LOADING;
        }).addCase(getLeadStatusCounts.fulfilled, (state, action) => {
            state.leadStatusCount.data = action.payload.leadStatusData;
            state.leadStatusCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getLeadStatusCounts.rejected, (state, action) => {
            state.leadStatusCount.status = fetchStatus.FAILED;
            state.leadStatusCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getDispatchListCounts.pending, (state, action) => {
            state.dispatchListCount.status = fetchStatus.LOADING;
        }).addCase(getDispatchListCounts.fulfilled, (state, action) => {
            state.dispatchListCount.data = action.payload.dispatchListData;
            state.dispatchListCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getDispatchListCounts.rejected, (state, action) => {
            state.dispatchListCount.status = fetchStatus.FAILED;
            state.dispatchListCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getInterviewListCounts.pending, (state, action) => {
            state.interviewListCount.status = fetchStatus.LOADING;
        }).addCase(getInterviewListCounts.fulfilled, (state, action) => {
            state.interviewListCount.data = action.payload.interviewListData;
            state.interviewListCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getInterviewListCounts.rejected, (state, action) => {
            state.interviewListCount.status = fetchStatus.FAILED;
            state.interviewListCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getEmployeePerformanceListCounts.pending, (state, action) => {
            state.employeePerformanceListCount.status = fetchStatus.LOADING;
        }).addCase(getEmployeePerformanceListCounts.fulfilled, (state, action) => {
            state.employeePerformanceListCount.data = action.payload.employeePerformanceListData;
            state.employeePerformanceListCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getEmployeePerformanceListCounts.rejected, (state, action) => {
            state.employeePerformanceListCount.status = fetchStatus.FAILED;
            state.employeePerformanceListCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getLeadPerformanceListCounts.pending, (state, action) => {
            state.leadPerformanceListCount.status = fetchStatus.LOADING;
        }).addCase(getLeadPerformanceListCounts.fulfilled, (state, action) => {
            state.leadPerformanceListCount.data = action.payload.leadPerformanceListData;
            state.leadPerformanceListCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getLeadPerformanceListCounts.rejected, (state, action) => {
            state.leadPerformanceListCount.status = fetchStatus.FAILED;
            state.leadPerformanceListCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getActivePeopleCounts.pending, (state, action) => {
            state.activePeopleCount.status = fetchStatus.LOADING;
        }).addCase(getActivePeopleCounts.fulfilled, (state, action) => {
            state.activePeopleCount.data = action.payload.activePeopleData;
            state.activePeopleCount.status = fetchStatus.SUCCEEDED;
        }).addCase(getActivePeopleCounts.rejected, (state, action) => {
            state.activePeopleCount.status = fetchStatus.FAILED;
            state.activePeopleCount.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getUserOptions.pending, (state, action) => {
            state.userOptions.status = fetchStatus.LOADING;
        }).addCase(getUserOptions.fulfilled, (state, action) => {
            state.userOptions.data = action.payload.userOptions;
            state.userOptions.status = fetchStatus.SUCCEEDED;
        }).addCase(getUserOptions.rejected, (state, action) => {
            state.userOptions.status = fetchStatus.FAILED;
            state.userOptions.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getEmailBodyDashboard.pending, (state, action) => {
            state.emailBodyDashboard.status = fetchStatus.LOADING;
        }).addCase(getEmailBodyDashboard.fulfilled, (state, action) => {
            state.emailBodyDashboard.data = action.payload.data;
            state.emailBodyDashboard.status = fetchStatus.SUCCEEDED;
        }).addCase(getEmailBodyDashboard.rejected, (state, action) => {
            state.emailBodyDashboard.status = fetchStatus.FAILED;
            state.emailBodyDashboard.error = action.error.message;
            // toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const {
    updateGraphCountsStatus,
    updateRegistrationCountsStatus,
    updateInterviewCountsStatus,
    updateJobCountsStatus,
    updateClientCountsStatus,
    updateNoShowCountsStatus,
    updateStatusCounts,
    updateCallCountsStatus,
    updateStatusCountsStatus,
    updateDispatchListCountsStatus,
    updateInterviewListCountsStatus,
    updateActivePeopleCountsStatus,
    updateUserOptionsStatus
} = dashbaordSlice.actions;

export default dashbaordSlice.reducer

export const selectGraphCounts = state => state.dashboards.graphCount.data;
export const selectGraphCountStatus = state => state.dashboards.graphCount.status;


export const selectRegistrationCount = state => state.dashboards.registrationCount.data;
export const selectRegistrationCountStatus = state => state.dashboards.registrationCount.status;

export const selectDispatchCount = state => state.dashboards.dispatchCount.data;
export const selectDispatchCountStatus = state => state.dashboards.dispatchCount.status;

export const selectEmployeePerformanceCount = state => state.dashboards.employeePerformanceCount.data;
export const selectEmployeePerformanceStatus = state => state.dashboards.employeePerformanceCount.status;

export const selectInterviewCount = state => state.dashboards.interviewCount.data;
export const selectInterviewStatus = state => state.dashboards.interviewCount.status;

export const selectJobCount = state => state.dashboards.jobCount.data;
export const selectJobStatus = state => state.dashboards.jobCount.status;

export const selectClientCount = state => state.dashboards.clientCount.data;
export const selectClientStatus = state => state.dashboards.clientCount.status;

export const selectNoShowCount = state => state.dashboards.noShowCount.data;
export const selectNoShowStatus = state => state.dashboards.noShowCount.status;

export const selectCallCount = state => state.dashboards.callCount.data;
export const selectCallStatus = state => state.dashboards.callCount.status;

export const selectStatusCount = state => state.dashboards.statusCount.data;
export const selectStatusStatus = state => state.dashboards.statusCount.status;

export const selectLeadStatusCount = state => state.dashboards.leadStatusCount.data;
export const selectLeadStatusStatus = state => state.dashboards.leadStatusCount.status;

export const selectDispatchListCount = state => state.dashboards.dispatchListCount.data;
export const selectDispatchListStatus = state => state.dashboards.dispatchListCount.status;

export const selectInterviewListCount = state => state.dashboards.interviewListCount.data;
export const selectInterviewListStatus = state => state.dashboards.interviewListCount.status;

export const selectEmployeePerformanceListCount = state => state.dashboards.employeePerformanceListCount.data;
export const selectEmployeePerformanceListStatus = state => state.dashboards.employeePerformanceListCount.status;

export const selectLeadPerformanceListCount = state => state.dashboards.leadPerformanceListCount.data;
export const selectLeadPerformanceListStatus = state => state.dashboards.leadPerformanceListCount.status;

export const selectActivePeopleCount = state => state.dashboards.activePeopleCount.data;
export const selectActivePeopleStatus = state => state.dashboards.activePeopleCount.status;

export const selectUserOptions = state => state.dashboards.userOptions.data;
export const selectUserOptionsState = state => state.dashboards.userOptions.status;

export const selectEmailBodyDashboard = state => state.dashboards.emailBodyDashboard.data;
export const selectEmailBodyDashboardStatus = state => state.dashboards.emailBodyDashboard.status;