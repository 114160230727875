import React, { useState, useEffect } from 'react';

import downloadImg from './../../assets/images/clipart/download.svg';
import deleteImg from './../../assets/images/clipart/delete.svg';
import pdfImg from './../../assets/images/clipart/pdf.svg';
import licenseImg from './../../assets/images/clipart/license.svg';

import i9Img from './../../assets/images/clipart/i9.svg';
import fileImg from './../../assets/images/clipart/file.svg';
import genderImg from './../../assets/images/clipart/gender.svg';
import termsImg from './../../assets/images/clipart/terms.svg';
import folderImg from './../../assets/images/clipart/folder.svg';
import filesImg from './../../assets/images/clipart/files.svg';
import DocumentsCard from '../Controls/cards/documentsCard';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicantById, selectApplicantById } from '../../features/applicant/reducerSlices/applicantSlice';
import ReactTooltip from 'react-tooltip';
import { tenantId } from '../../api/client';



function UploadDownloadDocumentsFlyout(props) {

  const dispatch = useDispatch();

  const [isActive, setActive] = useState(false);
  const ToggleClass = () => {
    setActive(!isActive);

    const timer = setTimeout(() => {
      props.onClose(false);
    }, 1500);
  };

  const applicant = useSelector(selectApplicantById);

  const _loadApplicant = async (id) => {
    await dispatch(getApplicantById({
      client: tenantId,
      serviceType: "TENANTUSER",
      operation: "GET",
      payload: {
        // id: '42c29b41-1ce0-442f-abcc-5e86b44ee842',
        id: id,
        data: ""
      }
    }));
  }

  useEffect(() => {
    if (props.applicantId) {
      console.log('useEffect props.applicantId ->', props.applicantId);
      _loadApplicant(props.applicantId);
    }
  }, [props.applicantId]);

  return (
    <>
      <div className="flyout-wrapper position-fixed top-0 end-0">
        <div className={`flyout-card ${isActive ? 'close' : 'open'}`}>
          <div className="flyout-card-body">
            <div className="float-end">
              <div className="close-btn">
                <i className="fa fa-times" aria-hidden="true" onClick={ToggleClass} data-tip='Close'></i>
                <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
              </div>
            </div>
            <div className="flyout-card-title">Upload/Download Documents</div>
            <div className="flyout-card-sub-title">
              {applicant ? applicant.FirstName.Data : ""}  {applicant ? applicant.LastName.Data : ""}
            </div>
            <div className="flyout-card-sub-title bold">
              Uploaded Documents
            </div>
            {/* <ul className="flyout-list-group pt-3 p-0">
            <li className="pt-3">
              <div className="flyout-list-group-item-card-container">
                <div className="flyout-list-group-item-card">
                  <img src={i9Img} className="icon-img" />
                  <span className="bold-text ps-2">
                    I9
                  </span>
                  <span className="ms-auto light-text">
                    2 Items
                  </span>
                </div>
              </div>
            </li>
            <li className="pt-3">
              <div className="flyout-list-group-item-card-container">
                <div className="flyout-list-group-item-card active">
                  <img src={fileImg} className="icon-img" />
                  <span className="bold-text ps-2">
                    W4
                  </span>
                  <span className="ms-auto light-text">
                    1 Items
                  </span>
                </div>
                <div className="flyout-list-group-item-card-footer">
                  <div className="flyout-list-group-item-card">
                    <div className='sub-icon'>
                      <img src={pdfImg} className="icon-img" />
                      <p className='file-size-text'>10kb</p>
                    </div>
                    <span className="ps-2">
                      <div className='file-name'>Resume.pdf</div>
                      <div className='file-by'>Adam Smith, Jul 02, 2022  10:30 AM </div>
                    </span>
                    <span className="ms-auto">
                      <img src={downloadImg} alt='not found' className='icon-btn me-2' />
                      <img src={deleteImg} alt='not found' className='icon-btn' />
                    </span>
                  </div>
                  <div className="flyout-card-sub-title bold ps-3">
                    Attached File
                  </div>
                  <div className="flyout-list-group-item-card">
                    <div className='sub-icon'>
                      <img src={licenseImg} className="icon-img" />
                      <p className='file-size-text'>10kb</p>
                    </div>
                    <span className="ps-2">
                      <div className='file-name'>License.png</div>
                    </span>
                    <span className="ms-auto light-text">
                      1 Items
                    </span>
                  </div>
                  <div className='px-2 pb-3'>
                    <textarea className="flyout-input" placeholder='Add note here'></textarea>
                  </div>
                </div>
              </div>
            </li>
            <li className="pt-3">
              <div className="flyout-list-group-item-card-container">
                <div className="flyout-list-group-item-card">
                  <img src={genderImg} className="icon-img" />
                  <span className="bold-text ps-2">
                    Sexual Harassment
                  </span>
                  <span className="ms-auto light-text">
                    3 Items
                  </span>
                </div>
              </div>
            </li>
            <li className="pt-3">
              <div className="flyout-list-group-item-card-container">
                <div className="flyout-list-group-item-card">
                  <img src={termsImg} className="icon-img" />
                  <span className="bold-text ps-2">
                    Terms
                  </span>
                  <span className="ms-auto light-text">
                    4 Items
                  </span>
                </div>
              </div>
            </li>
            <li className="pt-3">
              <div className="flyout-list-group-item-card-container">
                <div className="flyout-list-group-item-card">
                  <img src={folderImg} className="icon-img" />
                  <span className="bold-text ps-2">
                    Others
                  </span>
                  <span className="ms-auto light-text">
                    2 Items
                  </span>
                </div>
              </div>
            </li>
            <li className="pt-3">
              <div className="flyout-list-group-item-card-container">
                <div className="flyout-list-group-item-card">
                  <img src={filesImg} className="icon-img" />
                  <span className="bold-text ps-2">
                    All
                  </span>
                  <span className="ms-auto light-text">
                    12 Items
                  </span>
                </div>
              </div>
            </li>
          </ul> */}

            <DocumentsCard documents={applicant && applicant.Documents ? applicant.Documents : ""} applicant={applicant ? applicant : ""} />

          </div>
          {/* <div className="position-fixed bottom-0 end-0">
          <div className="p-3 px-4">
            <button className="c-btn white-btn mx-2" type="button" >Cancel</button>
            <button className="c-btn dark-btn" type="button" >Save</button>
          </div>
        </div> */}


        </div>
      </div>
      <div className='flyout-backdrop flyout-backdrop-show' />
    </>
  )
}

export default UploadDownloadDocumentsFlyout;