import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import YesNoRadio from '../../Controls/custom/checkers/yesNoRadio';


function FlexibilityFilter(props) {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [flexibile, setFlexibile] = useState("-1");

    const flexibilityFilter = useSelector(props.flexibilityFilterSelector);

    //const gfContainerRef = useRef(null);

    useEffect(() => {
        if (flexibilityFilter) {
            setFlexibile(flexibilityFilter);
        }
    }, [flexibilityFilter, open]);

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    // const onBlur = (e) => {
    //     setOpen(false);
    // };

    const onSaveFilterClicked = (e) => {
        e.preventDefault();
        props.onFlexibilityChanged && props.onFlexibilityChanged(e, flexibile);
        setOpen(false);

    }

    const onClearFilterClicked = (e) => {
        e.preventDefault();
        props.onFlexibilityChanged && props.onFlexibilityChanged(e, "-1");
        setFlexibile("-1");
        setOpen(false);
    }

    const onCloseClicked = (e) => {
        e.preventDefault();
        setFlexibile("-1");
        setOpen(false);
    }

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Flexibility</span> :
                <span className="filter-value">{flexibilityFilter === "-1" ? "All" : (flexibilityFilter === "Y" ? "Yes" : "No")}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
            //ref={gfContainerRef}
            //tabIndex={-1}
            //onBlur={(e) => onBlur(e)}
            >
                <label id="lblJobDateEqual" className="filter-label pe-4" ><span
                    className="close-filter-button"
                    title="Close"
                    onClick={(e) => onCloseClicked(e)}>
                    <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                </span></label>
                <YesNoRadio
                    name={`rdbtnYNFlexibility}`}
                    value={flexibile}
                    onSelectionChanged={(e, v) => { setFlexibile(v) }} />
                <div className="text-end pe-4">
                    <a
                        href="#"
                        title="Save"
                        onClick={(e) => onSaveFilterClicked(e)}>
                        <span className="filter-controls">
                            <i className="fa fa-save me-2"></i>
                        </span>
                    </a>
                    <a
                        href="#"
                        title="Clear"
                        onClick={(e) => onClearFilterClicked(e)}>
                        <span className="filter-controls">
                            <i className="fa fa-eraser"></i>
                        </span>
                    </a>
                </div>
            </div> : <></>}
        </div>
    );
}

export default FlexibilityFilter;