import {
    createSlice, createAsyncThunk, nanoid
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    messagelist: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    count: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    countById: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    inboxmessages: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    userOptions: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    dispatchList: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    dispatchListCount: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    inboxmessagesByFilter: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    mystatus: "",
    refreshInbox:"",
    refreshApplicantMessage:"",
    messageUserid : "",
    messageApplicantid : ""

};

export const getAllMessages = createAsyncThunk('messages/getAllMessages', async (messageModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', messageModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
   
    let messages;
    if (isJSON(data)) {
        messages = JSON.parse(data);
    }
    return {
        page: messageModel.page,
        messages: messages,
        success: response.data.success
    };
});

export const getUserOptions = createAsyncThunk('messages/getUserOptions', async (messageModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', messageModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
   
    let userOptions;
    if (isJSON(data)) {
        userOptions = JSON.parse(data);
    }
    return {
        page: messageModel.page,
        userOptions: userOptions,
        success: response.data.success
    };
});

export const getDispatchList = createAsyncThunk('messages/getDispatchList', async (messageModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', messageModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
   
    let dispatchList;
    if (isJSON(data)) {
        dispatchList = JSON.parse(data);
    }
    return {
        page: messageModel.page,
        dispatchList: dispatchList,
        success: response.data.success
    };
});


export const getDispatchListCount = createAsyncThunk('messages/getDispatchListCount', async (messageModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', messageModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
   
    let dispatchList;
    if (isJSON(data)) {
        dispatchList = JSON.parse(data);
    }
    return {
        page: messageModel.page,
        dispatchList: dispatchList,
        success: response.data.success
    };
});

export const getUnreadMessages = createAsyncThunk('messages/getUnreadMessages', async (messageModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', messageModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
   
    let messages;
    if (isJSON(data)) {
        messages = JSON.parse(data);
    }
    return {
        page: messageModel.page,
        messages: messages,
        success: response.data.success
    };
});

export const getUnreadMessagesByApplicantId = createAsyncThunk('messages/getUnreadMessagesByApplicantId', async (messageModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', messageModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
   
    let messages;
    if (isJSON(data)) {
        messages = JSON.parse(data);
    }
    return {
        page: messageModel.page,
        messages: messages,
        success: response.data.success
    };
});



export const getInboxMessages = createAsyncThunk('messages/getInboxMessages', async (messageModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', messageModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
   
    let messages;
    if (isJSON(data)) {
        messages = JSON.parse(data);
    }
    return {
        page: messageModel.page,
        messages: messages,
        success: response.data.success
    };
});

export const getInboxMessagesByFilter = createAsyncThunk('messages/getInboxMessagesByFilter', async (messageModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', messageModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
   
    let messages;
    if (isJSON(data)) {
        messages = JSON.parse(data);
    }
    return {
        page: messageModel.page,
        messages: messages,
        success: response.data.success
    };
});

export const getMessageById = createAsyncThunk('messages/getMessageById', async (model) => {
    
    const response = await BajoAPI.post('Gateway', model, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    
    let message;
    if (isJSON(data)) {
        message = JSON.parse(data);
    }
    return message;
});




export const createMessage = createAsyncThunk('messages/saveMessage', async (messageModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, messageModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let message = undefined;
    let applicats = undefined;
    if (data && isJSON(data)) {
        message = JSON.parse(data);
    }
    return {
        message: message,
        success: response.data.success
    };
});


export const updateMessage = createAsyncThunk('messages/updateMessage', async (messageModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, messageModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    let message = undefined;
    if (data && isJSON(data)) {
        message = JSON.parse(data);
    }
    return {
        message: message,
        success: response.data.success
    };
});


export const removeMessage = createAsyncThunk('messages/removeMessage', async (messageModel, { rejectWithValue }) => {
    const response = await BajoAPI.post(`Gateway`, messageModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : undefined;
    return {
        id: data,
        success: response.data.success
    };
});

export const messageSlice= createSlice({
    name : 'messages',
    initialState,
    reducers:{
        updateStatus: (state) => {
            state.messagelist.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        createNewMessage: state => {
            state.single.data = {
                "text": "",
                "userType": "",
                "sendBy": "",
                "sendById": "",
                "sendOn": "",
                "receivedBy": "",
                "receivedById": "",
                "receivedOn": "",
                "isReceived": "",
                "isRead": "",
                "readOn": "",
                "reply": []
            };
        },
        updatemystatus: (state,updatedstatus) => {
            state.mystatus = updatedstatus.payload;
        },
        updaterefreshInbox:(state,updatedstatus) =>{
            state.refreshInbox = updatedstatus.payload;
        },
        updaterefreshApplicantMessage:(state,updatedstatus) => {
            state.refreshApplicantMessage = updatedstatus.payload;
        },
        updatemessageUserid : (state,updatedid) => {
            state.messageUserid = updatedid.payload;
        },
        updatemessageApplicantid : (state,updatedid) => {
            state.messageApplicantid = updatedid.payload;
        }

    },

    extraReducers: (builder) => {
        builder
        .addCase(getAllMessages.pending, (state, action) => {
            state.messagelist.status = fetchStatus.LOADING;
        }).addCase(getAllMessages.fulfilled, (state, action) => {
          
            
            state.messagelist.data = action.payload.messages;
            state.messagelist.status = fetchStatus.SUCCEEDED;
            //toaster.success(action.payload.success);
        }).addCase(getAllMessages.rejected, (state, action) => {
            state.messagelist.status = fetchStatus.FAILED;
            state.messagelist.error = action.error.message;
            //toaster.error(action.payload ? action.payload.error : "")
        })

        .addCase(getUserOptions.pending, (state, action) => {
            state.userOptions.status = fetchStatus.LOADING;
        }).addCase(getUserOptions.fulfilled, (state, action) => {
          
            
            state.userOptions.data = action.payload.userOptions;
            state.userOptions.status = fetchStatus.SUCCEEDED;
            state.userOptions.refreshed = false;
            //toaster.success(action.payload.success);
        }).addCase(getUserOptions.rejected, (state, action) => {
            state.userOptions.status = fetchStatus.FAILED;
            state.userOptions.error = action.error.message;
            //toaster.error(action.payload ? action.payload.error : "")
        })

        .addCase(getDispatchList.pending, (state, action) => {
            state.dispatchList.status = fetchStatus.LOADING;
            state.dispatchList.refreshed = false;
        }).addCase(getDispatchList.fulfilled, (state, action) => {
          
            
            state.dispatchList.data = action.payload.dispatchList;
            state.dispatchList.status = fetchStatus.SUCCEEDED;
            state.dispatchList.refreshed = true;
            //toaster.success(action.payload.success);
        }).addCase(getDispatchList.rejected, (state, action) => {
            state.dispatchList.status = fetchStatus.FAILED;
            state.dispatchList.error = action.error.message;
            state.dispatchList.refreshed = true
            //toaster.error(action.payload ? action.payload.error : "")
        })
        
        .addCase(getDispatchListCount.pending, (state, action) => {
            state.dispatchListCount.status = fetchStatus.LOADING;
            state.dispatchListCount.refreshed = false;
        }).addCase(getDispatchListCount.fulfilled, (state, action) => {
          
            
            state.dispatchListCount.data = action.payload.dispatchList.length;
            state.dispatchListCount.status = fetchStatus.SUCCEEDED;
            state.dispatchListCount.refreshed = true;
            //toaster.success(action.payload.success);
        }).addCase(getDispatchListCount.rejected, (state, action) => {
            state.dispatchListCount.status = fetchStatus.FAILED;
            state.dispatchListCount.error = action.error.message;
            state.dispatchListCount.refreshed = true
            //toaster.error(action.payload ? action.payload.error : "")
        })
        
        .addCase(getMessageById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getMessageById.fulfilled, (state, action) => {
            state.single.data = action.payload;
            state.single.status = fetchStatus.SUCCEEDED;
         
        }).addCase(getMessageById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
           
        })
        .addCase(getUnreadMessages.pending, (state, action) => {
            state.count.status = fetchStatus.LOADING;
            
        }).addCase(getUnreadMessages.fulfilled, (state, action) => {
            state.count.data = action.payload.messages[0].count;
            state.count.status = fetchStatus.SUCCEEDED;
         
        }).addCase(getUnreadMessages.rejected, (state, action) => {
            state.count.status = fetchStatus.FAILED;
            state.count.error = action.error.message;
           
        })
        .addCase(getUnreadMessagesByApplicantId.pending, (state, action) => {
            state.countById.status = fetchStatus.LOADING;
            
        }).addCase(getUnreadMessagesByApplicantId.fulfilled, (state, action) => {
            state.countById.data = action.payload.messages[0].count;
            state.countById.status = fetchStatus.SUCCEEDED;
         
        }).addCase(getUnreadMessagesByApplicantId.rejected, (state, action) => {
            state.countById.status = fetchStatus.FAILED;
            state.countById.error = action.error.message;
           
        })
        .addCase(getInboxMessages.pending, (state, action) => {
            state.inboxmessages.status = fetchStatus.LOADING;
        }).addCase(getInboxMessages.fulfilled, (state, action) => {
           state.inboxmessages.data = action.payload.messages;
            state.inboxmessages.status = fetchStatus.SUCCEEDED;
         }).addCase(getInboxMessages.rejected, (state, action) => {
            state.inboxmessages.status = fetchStatus.FAILED;
            state.inboxmessages.error = action.error.message;
            })
        .addCase(getInboxMessagesByFilter.pending, (state, action) => {
            state.inboxmessagesByFilter.status = fetchStatus.LOADING;
        }).addCase(getInboxMessagesByFilter.fulfilled, (state, action) => {
            state.inboxmessagesByFilter.data = action.payload.messages;
            state.inboxmessagesByFilter.status = fetchStatus.SUCCEEDED;
            }).addCase(getInboxMessagesByFilter.rejected, (state, action) => {
            state.inboxmessagesByFilter.status = fetchStatus.FAILED;
            state.inboxmessagesByFilter.error = action.error.message;
            })    
        .addCase(createMessage.pending, (state, action) => {
            state.creation.status = fetchStatus.LOADING;
        }).addCase(createMessage.fulfilled, (state, action) => {
            state.creation.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createMessage.rejected, (state, action) => {
            state.creation.status = fetchStatus.FAILED;
            state.creation.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        })
        .addCase(updateMessage.pending, (state, action) => {
            state.modification.status = fetchStatus.LOADING;
        }).addCase(updateMessage.fulfilled, (state, action) => {
            state.modification.status = fetchStatus.SUCCEEDED;
            toaster.success("Message got Read");
        }).addCase(updateMessage.rejected, (state, action) => {
            state.modification.status = fetchStatus.FAILED;
            state.modification.error = action.error.message;
            //toaster.error(action.payload ? action.payload.error : "")
         });
        //.addCase(removeMessage.pending, (state, action) => {
        //     state.removal.status = fetchStatus.LOADING;
        // }).addCase(removeMessage.fulfilled, (state, action) => {
        //     const removed = refreshRemoved(state.data.records, action.payload.id, state.data.currentPage);
        //     state.data.records = removed.records;
        //     state.data.currentPage = removed.lastPage;
        //     state.data.totalItems -= 1;
        //     state.single.refreshed = nanoid();
        //     state.removal.status = fetchStatus.SUCCEEDED;
        //     toaster.success(action.payload.success);
        // }).addCase(removeMessage.rejected, (state, action) => {
        //     state.removal.status = fetchStatus.FAILED;
        //     state.removal.error = action.error.message;
        //     toaster.error(action.payload ? action.payload.error : "");
        // });
    }

});




export const {updateCreationStatus,updateStatus,updateSingleStatus,createNewMessage,updatemystatus,updaterefreshInbox,updaterefreshApplicantMessage,updatemessageUserid,updatemessageApplicantid} = messageSlice.actions

export default messageSlice.reducer

export const Selectupdatemystatus =  state => state.messages.mystatus;

export const SelectrefreshInbox =  state => state.messages.refreshInbox;

export const SelectrefreshApplicantMessage =  state => state.messages.refreshApplicantMessage;

export const SelectMessageUserId =  state => state.messages.updatemessageUserid;

export const SelectMessageApplicantId =  state => state.messages.updatemessageApplicantid;

export const selectAllMessages = state => state.messages.messagelist.data ? state.messages.messagelist.data : undefined;

export const selectUserOptions = state => state.messages.userOptions.data ? state.messages.userOptions.data : undefined;
export const selectUserOptionsState = state => state.messages.userOptions.status;
export const selectUserOptionsRefreshed = state => state.messages.userOptions.refreshed;

export const selectDispatchList = state => state.messages.dispatchList.data ? state.messages.dispatchList.data : undefined;
export const selectDispatchListState = state => state.messages.dispatchList.status;
export const selectDispatchListRefreshed = state => state.messages.dispatchList.refreshed;

export const selectDispatchListCount = state => state.messages.dispatchListCount.data ? state.messages.dispatchListCount.data : undefined;
export const selectDispatchListCountState = state => state.messages.dispatchListCount.status;
export const selectDispatchListCountRefreshed = state => state.messages.dispatchListCount.refreshed;


export const selectMessageById = (state) => {
   
    return state.messages.single ? state.messages.single.data : undefined;
}

export const selectSingleStatus = state => state.messages.single.status;

export const selectUnreadMessageCount = (state) => {
   
    return state.messages.count ? state.messages.count.data : undefined;
}
export const selectUnreadMessageCountStatus = (state) => state.messages.count.status;

export const selectUnreadMessageCountbyApplicantId = (state) => {
   
    return state.messages.countById ? state.messages.countById.data : undefined;
}
export const selectUnreadMessageCountbyApplicantIdStatus = (state) => state.messages.countById.status; 

export const selectSingleError = state => state.messages.single.error;

export const selectSingleRefreshed = state => state.messages.single.refreshed;

export const selectStatus = state => state.messages.messagelist.status;
export const selectMessageStatus = state => state.messages.messagelist.status;

export const selectCreationStatus = state => state.messages.creation.status;
export const selectCreationError = state => state.messages.creation.error;

export const selectUpdateMessageStatus = state => state.messages.modification.status;

export const selectInboxMessages = state => state.messages.inboxmessages.data ? state.messages.inboxmessages.data : undefined;
export const selectInboxMessagesStatus = state => state.messages.inboxmessages.status;

export const selectInboxMessagesByFilter = state => state.messages.inboxmessagesByFilter.data ? state.messages.inboxmessagesByFilter.data : undefined;
export const selectInboxMessagesStatusByFilter = state => state.messages.inboxmessagesByFilter.status;