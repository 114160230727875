import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    title: "",
    content: "",
    note: "",
    show: false
};

export const alertModalPopUpSlice = createSlice({
    name: 'alertModalPopUp',
    initialState,
    reducers: {
        showAlertModal: (state, action) => {
            const { title, content, note, confirmedCallback } = action.payload
            state.title = title;
            state.content = content;
            state.note = note;
            state.show = true;
        },
        closeAlertModal: state => {
            state.show = false;
        }
    }
});

export const { showAlertModal, closeAlertModal } = alertModalPopUpSlice.actions;

export default alertModalPopUpSlice.reducer

export const selectAlertModalPopUpDetail = state => {
    return {
        title: state.alertModalPopUp.title,
        content: state.alertModalPopUp.content,
        note: state.alertModalPopUp.note,
        show: state.alertModalPopUp.show
    }
}