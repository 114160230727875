import store from '../../../store';
import { updateLocationFilter, updateScheduleStatusFilter } from '../../../component/Controls/filters/reducerSlices/scheduleFilterSlice';

export const onScheduleLocationChanged = async (e, value, locations) => {
    await store.dispatch(updateLocationFilter({
        locations: locations,
        value: value
    }));
};

export const onScheduleStatusChanged = async (e, value, shifts) => {
    await store.dispatch(updateScheduleStatusFilter({
        shifts: shifts,
        value: value
    }));
};