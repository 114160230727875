import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addCurrentStep, selectLanguage, updateLoginStatus } from '../reducerSlices/accountsSlice';
import Progress from '../../../component/Progress/Progress';
import '../css/accounts.css';
import BackButton from './../../../component/Controls/buttons/backButton';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import ContinueButton from './../../../component/Controls/buttons/continueButton';
import ShortText from '../../../component/Controls/custom/textbox/shortText';
import { getApplicantById, getRegisteredApplicantById, selectApplicantById, selectRegisteredApplicantById, selectRegisteredApplicantModificationStatus, selectRegisteredApplicantStatus, updateRegisteredApplicant, updateRegisteredApplicantData, updateRegisteredModificationStatus } from '../../applicant/reducerSlices/applicantSlice';
import { accountService } from '../services/accountService';
import { Language, USER_KEY } from '../../../common/constants';
import { useEffect } from 'react';
import { fetchStatus, tenantId } from '../../../api/client';
import { isFormValid } from '../../../component/Controls/services/formService';
import { handlerError } from '../../../common/common';
import YesNoRadio from '../../../component/Controls/custom/checkers/yesNoRadio';
import Radio from '../../../component/Controls/custom/checkers/radio';
import { applicantDetailsEN } from '../../applicant/services/applicantDetails';
import { onValueChanged, sections } from '../../applicant/services/applicantSaver';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';


const Education_Details = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(addCurrentStep('Education'));

  const APPLICANT_DETAIL_FORM = "AppilcantDetailForm";
  const [adfSubmitted, setADFSubmitted] = useState(false);
  const [aPFInvalid, setAPFInvalid] = useState(false);

  const applicantRequestStatus = useSelector(selectRegisteredApplicantStatus);
  const applicantModificationStatus = useSelector(selectRegisteredApplicantModificationStatus);
  const applicant = useSelector(selectRegisteredApplicantById);

  const [applicantDetails, setApplicantDetails] = useState(applicantDetailsEN);
  const language = useSelector(selectLanguage);

  const _loadApplicant = async (id) => {
    await dispatch(getRegisteredApplicantById({
      client: tenantId,
      serviceType: "TENANTUSER",
      operation: "GETREGISTEREDAPPLICANT",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  useEffect(() => {
    if (applicantRequestStatus === fetchStatus.IDLE) {
      const userId = accountService.getLoggedInUserId(USER_KEY);
      _loadApplicant(userId);
    }
  }, [applicantRequestStatus]);



  const onNextClicked = (e) => {
    setAPFInvalid(false);
    if (isFormValid(APPLICANT_DETAIL_FORM)) {
      if (applicant) {
        const userId = accountService.getLoggedInUserId(USER_KEY);
        dispatch(updateRegisteredApplicant({
          client: tenantId,
          serviceType: "TENANTUSER",
          operation: "SUBMITAPPLICANT",
          payload: {
            id: userId,
            data: JSON.stringify({
              applicant: JSON.stringify(applicant),
              photo: {
                contentType: "image/jpg",
                length: 0,
                photoData: ""
              }
            })
          }
        }));
      }
      setADFSubmitted(false);
    }
    else {
      setAPFInvalid(true);
      setADFSubmitted(true);
    }
  };

  const onBack = (e) => {
    history.push('/employment_details_1');
  }

  useEffect(() => {
    if (applicantModificationStatus === fetchStatus.SUCCEEDED) {
      if (accountService.isAuthenticated()) {
        dispatch(updateLoginStatus());
        dispatch(updateRegisteredModificationStatus());
        history.push("/congratulations");
      }
    }
  }, [applicantModificationStatus, dispatch, history]);

  const onValueUpdated = (e, section, value) => {
    let _applicantObj = Object.assign({}, applicant);
    let _newApplicantObj = onValueChanged(_applicantObj, e, section, value);
    dispatch(updateRegisteredApplicantData(_newApplicantObj));
  };

  const _isHavingEducationDetails = () => {
    return applicant && applicant.Sections && applicant.Sections[2] && applicant.Sections[2].HavingDetails;
  }


  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 card card-left d-flex align-items-stretch p-0">
            <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn">
              <div className="row p-2">
                <div className="col-md-12">
                  <div className="card-left-header">Education Details</div>
                  <div className="card-left-sub-header">Please enter your education details.</div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-12">
                  <label className="field-label">Do you have education details?</label>
                  <YesNoRadio
                    name="rdbtnED"
                    yesText={applicantDetails ? applicantDetails.Yes : ""}
                    noText={applicantDetails ? applicantDetails.No : ""}
                    onSelectionChanged={(e, value) => onValueUpdated(e, sections.EDUCATION_DETAILS, value)}
                    value={applicant && applicant.Sections[2].HavingDetails ? (applicant.Sections[2].HavingDetails ? "Y" : "N") : ""} />
                </div>
              </div>
              {_isHavingEducationDetails() ?
                <>
                  <div className="row p-2">
                    <div className="col-md-5">
                      <div className="form-group p-0 mobile-margin-bottom-3">
                        <label className="field-label">
                          {applicantDetails ? applicantDetails.HighSchoolCompleted : ""}
                        </label>
                        <div data-form={APPLICANT_DETAIL_FORM} data-invalid={applicant && applicant.HighSchoolCompleted.Data === ""}>
                          <Radio id="rdbtnHSOne"
                            name="rdbtnHS"
                            label="1"
                            value="1"
                            onChange={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                            checked={applicant && applicant.HighSchoolCompleted.Data === "1"} />
                          <Radio id="rdbtnHSTwo"
                            name="rdbtnHS"
                            label="2"
                            value="2"
                            onChange={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                            checked={applicant && applicant.HighSchoolCompleted.Data === "2"} />
                          <Radio id="rdbtnHSThree"
                            name="rdbtnHS"
                            label="3"
                            value="3"
                            onChange={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                            checked={applicant && applicant.HighSchoolCompleted.Data === "3"} />
                          <Radio id="rdbtnHSFour"
                            name="rdbtnHS"
                            label="4"
                            value="4"
                            onChange={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                            checked={applicant && applicant.HighSchoolCompleted.Data === "4"} />
                        </div>
                        {
                          (aPFInvalid && applicant && applicant.HighSchoolCompleted.Data === "") ?
                            <span className="error-label">
                              <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                              {applicantDetails ? applicantDetails.HighSchoolCompletedError : ""}
                            </span>
                            : <></>
                        }
                      </div>
                    </div>
                    <div className="col-md-3">
                      <label className="field-label">
                        {applicantDetails ? applicantDetails.Diploma : ""}
                      </label>
                      <YesNoRadio
                        name="rdbtnD"
                        yesText={applicantDetails ? applicantDetails.Yes : ""}
                        noText={applicantDetails ? applicantDetails.No : ""}
                        value={applicant ? applicant.Diploma.Data : "N"}
                        onSelectionChanged={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)} />
                    </div>
                    <div className="col-md-4">
                      <label className="field-label">
                        {applicantDetails ? applicantDetails.GED : ""}
                      </label>
                      <YesNoRadio
                        name="rdbtnGED"
                        yesText={applicantDetails ? applicantDetails.Yes : ""}
                        noText={applicantDetails ? applicantDetails.No : ""}
                        value={applicant ? applicant.GED.Data : "N"}
                        onSelectionChanged={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)} />
                    </div>
                  </div>

                  <div className="row p-2">
                    <div className="col-md-6">
                      <div className="form-group p-0 mobile-margin-bottom-3">
                        <label id="lblSchool" className="field-label" htmlFor="txtSchool">{applicantDetails ? applicantDetails.School : ""}</label>
                        <InputGroup
                          icon={<i className="fa fa-university"></i>}
                          control={
                            <ShortText
                              required={applicant && applicant.School.Required}
                              errorMessage={language === Language.SPANISH ? applicantDetails.SchoolError : applicant && applicant.School.Validation}
                              form={APPLICANT_DETAIL_FORM}
                              submited={adfSubmitted}
                              id="txtSchools"
                              name="txtSchools"
                              ShortTextClasses="form-control"
                              placeholder={applicantDetails ? applicantDetails.School : ""}
                              value={applicant ? applicant.School.Data : ""}
                              onChange={(e) => onValueUpdated(e, sections.EDUCATION_DETAILS, null)} />
                          } />
                      </div>
                    </div>
                  </div>

                  <div className="row p-2">
                    <div className="col-md-6">
                      <div className="form-group p-0 mobile-margin-bottom-3">
                        <label id="lblCityState" className="field-label" htmlFor="txtCityState">{applicantDetails ? applicantDetails.CityState : ""}</label>
                        <InputGroup
                          icon={<i className="fa fa-map-marker"></i>}
                          control={
                            <ShortText
                              required={applicant.CityState.Required}
                              errorMessage={language === Language.SPANISH ? applicantDetails.CityStateError : applicant.CityState.Validation}
                              form={APPLICANT_DETAIL_FORM}
                              submited={adfSubmitted}
                              id="txtEDCityState"
                              name="txtEDCityState"
                              placeholder={applicantDetails ? applicantDetails.CityState : ""}
                              ShortTextClasses="form-control"
                              value={applicant ? applicant.CityState.Data : ""}
                              onChange={(e) => onValueUpdated(e, sections.EDUCATION_DETAILS, null)} />
                          } />

                      </div>
                    </div>
                  </div>

                  <div className="row p-2">
                    <div className="col-md-6">
                      <div className="form-group p-0 mobile-margin-bottom-3">
                        <label id="lblOther" className="field-label" htmlFor="txtOther">{applicantDetails ? applicantDetails.Other : ""}</label>
                        <InputGroup
                          icon={<i className="fa fa-pencil-square-o"></i>}
                          control={
                            <ShortText
                              required={applicant && applicant.Other.Required}
                              errorMessage={language === Language.SPANISH ? applicantDetails.OtherError : applicant && applicant.Other.Validation}
                              form={APPLICANT_DETAIL_FORM}
                              submited={adfSubmitted}
                              id="txtEDOthers"
                              name="txtEDOthers"
                              placeholder={applicantDetails ? applicantDetails.OtherPlaceHolder : ""}
                              ShortTextClasses="form-control"
                              value={applicant ? applicant.Other.Data : ""}
                              onChange={(e) => onValueUpdated(e, sections.EDUCATION_DETAILS, null)} />
                          } />
                      </div>
                    </div>
                  </div>
                </>
                :
                <></>
              }

            </div>
            <div className="card-footer bottom-0 px-4 py-2 w-100">
              <div className="row">
                <div className="col-md-12">
                  {applicantModificationStatus === fetchStatus.LOADING ? <div className="float-end my-2"><EllipsisLoader /></div>
                    :
                    <>
                      <BackButton onBackClicked={onBack} />
                      <div className="float-end">
                        <ContinueButton onClick={(e) => onNextClicked(e)} />
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 card card-right d-flex align-items-stretch p-0">
            <Progress percentage="75" getPercentage="15" subTitle="Education Details" currentPage="educationDetails" />
          </div>
        </div>

      </div>
    </div>
  )
}

export default Education_Details;