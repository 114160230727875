import React from "react";
import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import girlFaceImg from './../../assets/images/clipart/girl-face.svg';
import nophoto from './../../assets/images/clipart/nophoto.png';
import phoneCallFillImg from './../../assets/images/clipart/phone-call-fill.svg';
import calendarImg from './../../assets/images/clipart/calendar.svg';
import mailImg from './../../assets/images/clipart/mail.svg';
import mapLocationImg from './../../assets/images/clipart/map-location.svg';
import applicationImg from './../../assets/images/clipart/application.svg';
import noteImg from './../../assets/images/clipart/note.svg';
import documentImg from './../../assets/images/clipart/document.svg';
import msgImg from './../../assets/images/clipart/msg.svg';
import ReactTooltip from "react-tooltip";


const ProspectsPeopleCard = (props) => {

  return (
    <div className="card people-card">
      <div className="convert-profile-wrapper">
        {/* <input type="checkbox" name="c1" /> */}
        {/* <button className="convert-profile">Add as people</button> */}
      </div>
      {/* <div className={`status ${_getStatusClass()}`}>{props.status && props.status}</div> */}
      <div className="people-card-body p-2 cursor-pointer" onClick={props.onClick}>
        <div className="d-flex">
          <div className="pe-1">
            <div className="people-card-photo" style={{ borderColor: props.profileColor }}>
              <div className="people-card-photo-box" style={{ backgroundColor: props.profileColor }}>
                <img className="people-card-profile-box-img "
                  src={props && props.profilePhoto ?
                    props.profilePhoto
                    : props && props.gender === 'Female' ? girlFaceImg
                      : props && props.gender === 'Male' ? boyFaceImg
                        : nophoto
                  }
                />
              </div>
            </div>
          </div>
          <div className="ps-2">
            <div className="title w-100">
              {props.fullName}
            </div>
            <div className="row">
              <div className="detail col-4">
                <img src={phoneCallFillImg} />
                <span dangerouslySetInnerHTML={{ __html: props.phone.replace(/\*/g, '<span class="blur">*</span>') }} />
              </div>
              <div className="detail col-8">
                {/* <img src={mailImg} /> {props.email} */}
                <img src={mailImg} />
                <span dangerouslySetInnerHTML={{ __html: props.email.replace(/\*/g, '<span class="blur">*</span>') }} />
              </div>
              <div className="detail col-4">
                <img src={calendarImg} /> DOB:
                <span dangerouslySetInnerHTML={{ __html: props.dateOfBirth.replace(/\*/g, '<span class="blur">*</span>') }} />
              </div>
              <div className="detail col-8">
                <img src={mapLocationImg} /> {props.location}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="people-card-footer">
        <div className="item">
          <span className="detail">
            {props.skill}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ProspectsPeopleCard;
