import {
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    usersLocationData: {
        totalItems: 0,
        data: undefined,
        status: fetchStatus.IDLE
    }
};

export const getUsersLocation = createAsyncThunk('usersLocation/getUsersLocation', async (usersLocationModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', usersLocationModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let usersLocationData;
        if (isJSON(data)) {
            usersLocationData = JSON.parse(data);
        }
        return {
            page: usersLocationData.page,
            usersLocationData: usersLocationData,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});

export const usersLocationslice = createSlice({
    name: 'UsersLocation',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getUsersLocation.pending, (state, acttion) =>{
            state.usersLocationData.status = fetchStatus.LOADING;
        }).addCase(getUsersLocation.fulfilled, (state, action) => {
            state.usersLocationData.totalItems = action.payload.usersLocationData.totalItems;
            state.usersLocationData.data = action.payload.usersLocationData.items;
            state.usersLocationData.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getUsersLocation.rejected, (state, action) => {
            state.usersLocationData.status = fetchStatus.FAILED;
            state.usersLocationData.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export default usersLocationslice.reducer

export const selectUsersLocationStatus = state => state.usersLocation.usersLocationData.status;

export const selectUsersLocation = (state) => {
    return state.usersLocation.usersLocationData ? state.usersLocation.usersLocationData.data : undefined;
}