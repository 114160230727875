import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _getLocationText, _getStatusText } from '../../common/common';
import ShortText from '../Controls/custom/textbox/shortText';
import { isFormValid } from '../Controls/services/formService';
import { closeStatusFlyout, createStatus, selectStatusFlyoutCreateMode, selectStatusFlyoutEditmode, selectStatusFlyoutShow, selectStatusForm, selectStatusFormStatus, selectCreationStatus, selectModificationStatus, pdateSingleData, selectStatusById, updateStatuses, updateSingleData } from '../../features/setting/reducerSlices/statusesSlice';
import { onValueChanged, sections } from '../../features/setting/services/statusSaver';
import { Operation } from '../../common/constants';
import Selector from '../Controls/custom/selectors/selectors';
import { isObjectArray } from '../../utilities/utilityFunctions';
import { fetchStatus, tenantId } from '../../api/client';
import { applicantStatuses, userStatuses, visibilityStatuses } from '../../features/shared/services/masterData';
import { nanoid } from '@reduxjs/toolkit';
import InputGroup from '../Controls/custom/inputGroup/inputGroup';
import ReactTooltip from 'react-tooltip';
import EllipsisLoader from '../Controls/loaders/ellipsisLoader';
import { accountService } from '../../features/accounts/services/accountService';


function StatusFlyout(props) {

    const dispatch = useDispatch();
    const statusFlyoutShow = useSelector(selectStatusFlyoutShow);

    const STATUS_FORM = "Statusform";

    const [createMode, setCreatemode] = useState(false);
    const [editMode, setEditmode] = useState(false);
    const [isActive, setActive] = useState(false);
    const [cFInvalid, setCFInvalid] = useState(false);
    const [cfSubmitted, setCFSubmitted] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [statusOptions, setStatusOptions] = useState([]);

    const statusFlyoutCreateMode = useSelector(selectStatusFlyoutCreateMode);
    const statusFlyoutEditMode = useSelector(selectStatusFlyoutEditmode);
    const statusFormStatus = useSelector(selectStatusFormStatus);
    const statusForm = useSelector(selectStatusForm);
    const status = useSelector(selectStatusById);
    const statusCreationStatus = useSelector(selectCreationStatus);
    const statusModificationStatus = useSelector(selectModificationStatus);

    const ToggleClass = () => {
        setActive(!isActive);
        onCancelClicked();

        const timer = setTimeout(() => {
            dispatch(closeStatusFlyout())
            setActive(false)
        }, 1500);
    };

    const onCancelClicked = (e) => {
        setCreatemode(false);
        setEditmode(false);
        setCFInvalid(false);
        setCFSubmitted(false);
    };


    useEffect(() => {
        setCreatemode(statusFlyoutCreateMode);
        setEditmode(statusFlyoutEditMode)
    }, [statusFlyoutCreateMode, statusFlyoutEditMode]);

    useEffect(() => {
        if (statusCreationStatus === fetchStatus.SUCCEEDED || statusModificationStatus === fetchStatus.SUCCEEDED) {
            ToggleClass()
            window.location.reload();
        }
    }, [statusCreationStatus, statusModificationStatus]);

    useEffect(() => {
        if (!pageLoaded) {
            if (statusFormStatus === fetchStatus.IDLE && accountService.isAuthenticated()) {
                setPageLoaded(true);
                _loadForm();
            }
        }
    });

    useEffect(() => {
        if (isObjectArray(visibilityStatuses) && visibilityStatuses.length > 0) {
            let _statusOptions = visibilityStatuses.map((status, index) => (
                <option key={nanoid()} value={status.value}>
                    {status.text}
                </option>
            ));
            setStatusOptions(_statusOptions);
        }
    }, [visibilityStatuses]);

    const _loadForm = async () => {
        // await dispatch(getStatusForm({
        //     status: tenantId,
        //     serviceType: "TENANTADMIN",
        //     operation: "GETFORMBYTYPE",
        //     payload: {
        //         id: "",
        //         data: "StatusForm"
        //     }
        // }));
    }

    const onValueUpdated = (e, section, value) => {
        let _statusObj = Object.assign({}, status);
        let _newStatusObj = onValueChanged(_statusObj, e, section, value);
        dispatch(updateSingleData({
            status: _newStatusObj,
            operation: Operation.UPDATED
        }));
    };

    const onSaveClicked = (e, id, created) => {
        e.preventDefault();
        if (isFormValid(STATUS_FORM)) {
            if (status) {
                if (created) {
                    dispatch(createStatus({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVESTATUS",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                status: JSON.stringify(status),
                            })
                        }
                    }));
                }
                else {
                    dispatch(updateStatuses({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVESTATUS",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                status: JSON.stringify(status),
                            })
                        }
                    }));
                }
            }
            setCreatemode(false);
            setEditmode(false);
            setCFInvalid(false);
            setCFSubmitted(false);
        }
        else {
            setCFInvalid(true);
        }
        setCFSubmitted(true);
    };

    useEffect(() => {
        const handleBackButton = (event) => {
            if (statusFlyoutShow) {
                dispatch(closeStatusFlyout());
            }
        };
    
        window.addEventListener('popstate', handleBackButton);
    
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [statusFlyoutShow, dispatch]);


    return (
        <>
            {(statusFlyoutShow) ? (<>
                <div className="flyout-wrapper position-fixed top-0 end-0">
                    <div className={`flyout-card ${isActive ? 'close' : 'open'}`}>
                        <div className="flyout-card-header">
                            <div className="float-end">
                                <div className="close-btn">
                                    <i className="fa fa-times" aria-hidden="true" data-tip='Close' onClick={ToggleClass}></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </div>
                            </div>
                            <div className="flyout-card-title">{status && status.id ? "Edit" : "Add"} Status Details  </div>
                            <div className="flyout-card-sub-title">

                            </div>
                        </div>
                        <div className="flyout-card-body vh-100">
                            <div className="form-group p-0 pt-2">
                                <label id="lblFirstName" className="flyout-label" htmlFor="txtStatusNumber">Status Name</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{status && status.StatusName ? status.StatusName.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtStatusName"
                                                name="txtStatusName"
                                                ShortTextClasses="form-control"
                                                placeholder="Status Name"
                                                value={status && status.StatusName ? status.StatusName.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.STATUS_DETAILS, null)}
                                                // required={statusForm && statusForm.StatusName.Required}
                                                required={true}
                                                errorMessage={statusForm && statusForm.StatusName.Validation}
                                                form={STATUS_FORM}
                                                submited={cfSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblState" className="flyout-label" htmlFor="drpPrimaryStatus">Primary State</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{status && status.PrimaryStatus ? status.PrimaryStatus.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                // required={statusForm && statusForm.PrimaryStatus.Required}
                                                required={true}
                                                disabled={status && status.isDefaultStatusName}
                                                errorMessage={statusForm && statusForm.PrimaryStatus.validation}
                                                form={STATUS_FORM} submited={cfSubmitted}
                                                id="drpPrimaryStatus"
                                                name="drpPrimaryStatus"
                                                SelectorClasses="form-select"
                                                value={status && status.PrimaryStatus ? status.PrimaryStatus.Data.trim() : ""}
                                                options={
                                                    <>
                                                        {applicantStatuses.map((status) => <option value={status.value}>{status.text}</option>)}
                                                    </>
                                                } onChange={(e) => onValueUpdated(e, sections.STATUS_DETAILS, null)} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblStatus" className="flyout-label" htmlFor="drpStatus">Visibility</label>
                                {!editMode && !createMode ?
                                    // <label className="field-label-value">{status && status.Status ? status.Status.Data : ""}</label> :
                                    <label className="field-label-value">{status && status.Status ? _getStatusText(visibilityStatuses, status.Status.Data) : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                required={true}
                                                // required={statusForm && statusForm.Status.Required}
                                                errorMessage={statusForm && statusForm.Status.validation}
                                                form={STATUS_FORM}
                                                submited={cfSubmitted}
                                                id="drpStatus"
                                                name="drpStatus"
                                                SelectorClasses="form-select"
                                                value={status && status.Status ? status.Status.Data.trim() : ""}
                                                options={
                                                    <>
                                                        {statusOptions}
                                                    </>
                                                }
                                                onChange={(e) => onValueUpdated(e, sections.STATUS_DETAILS, null)} />
                                        }
                                    />
                                }
                            </div>
                        </div>
                        <div className="flyout-card-footer">
                            <div className="d-flex">
                                <div className="ms-auto">
                                    {statusCreationStatus === fetchStatus.LOADING || statusModificationStatus === fetchStatus.LOADING ? <EllipsisLoader /> :
                                        <>
                                            <button className="c-btn white-btn me-3" type="button" onClick={ToggleClass} >Cancel</button>
                                            <button className="c-btn dark-btn" type="button" onClick={(e) => onSaveClicked(e, status ? status.id : "", createMode)}>Save</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flyout-backdrop flyout-backdrop-show' />
            </>
            ) :
                <></>
            }
        </>

    )
}

export default StatusFlyout;