import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toLocalDate, _getLocationText } from '../../common/common';
import ShortText from '../Controls/custom/textbox/shortText';
import { isFormValid } from '../Controls/services/formService';

import sortImg from './../../assets/images/clipart/sort.svg';

import {
    getAllLocations,
    updateIsFiltered,
    selectStatus,
    selectCurrentPage,
    selectTotalItems,
    selectAllLocations,
    updateStatus,
    selectFirstRecord,
    loadSingleData,
    getLocationById,
    updateCurrentPage,
    selectLocationById,
    updateLocation,
    selectModificationStatus,
    selectRemovalStatus,
    selectSingleStatus,
    selectSingleRefreshed,
    getLocationForm,
    selectLocationFormStatus,
    selectLocationForm,
    updateSingleData,
    createNewLocation,
    updateLocationFilters,
    removeLocation,
    createLocation,
    selectCreationStatus,
    refreshLocationOptions,
    selectDispatchDetail,
    selectDispatchDetailStatus,
    selectDispatchList,
    selectDispatchListStatus,
    selectDispatchCount,
    selectDispatchCountStatus,
    getDispatchById,
    getDispatchListById,
    getDispatchCountById,
    showLocationFlyout,
    selectLocationFlyoutShow,
    closeLocationFlyout,
    selectLocationFlyoutCreateMode,
    selectLocationFlyoutEditmode
} from '../../features/locations/reducerSlices/locationSlice';

import { onValueChanged, sections } from '../../features/setting/services/locationSaver';
import { Operation } from '../../common/constants';
import Selector from '../Controls/custom/selectors/selectors';
import { selectLocationOptions } from '../../features/locations/reducerSlices/locationSlice';
import { isObjectArray } from '../../utilities/utilityFunctions';
import ZipCodeText from '../Controls/custom/textbox/zipCodeText';
import { fetchStatus, tenantId } from '../../api/client';
import { nanoid } from '@reduxjs/toolkit';
import PhoneText from '../Controls/custom/textbox/phoneText';
import EmailText from '../Controls/custom/textbox/emailText';
import ReactTooltip from 'react-tooltip';
import EllipsisLoader from '../Controls/loaders/ellipsisLoader';
import InputGroup from '../Controls/custom/inputGroup/inputGroup';
import { accountService } from '../../features/accounts/services/accountService';




function LocationFlyout(props) {

    const dispatch = useDispatch();
    const locationFlyoutShow = useSelector(selectLocationFlyoutShow);


    const [isActive, setActive] = useState(false);

    const ToggleClass = () => {
        setActive(!isActive);
        onCancelClicked();

        const timer = setTimeout(() => {
            dispatch(closeLocationFlyout())
            setActive(false)
        }, 1500);
    };

    const onCancelClicked = (e) => {
        setCreatemode(false);
        setEditmode(false);
        setCFInvalid(false);
        setCFSubmitted(false);
        // if (tempLocationId) {
        //     setTempLocationId("");
        //     _loadLocation(tempLocationId, undefined);
        // }
        // else {
        //     dispatch(updateSingleData(undefined));
        // }
    };

    const LOCATION_FORM = "Locationform";

    const [cFInvalid, setCFInvalid] = useState(false);
    const [cfSubmitted, setCFSubmitted] = useState(false);

    const locationFlyoutCreateMode = useSelector(selectLocationFlyoutCreateMode);
    const locationFlyoutEditMode = useSelector(selectLocationFlyoutEditmode);

    const [createMode, setCreatemode] = useState(false);
    const [editMode, setEditmode] = useState(false);

    useEffect(() => {
        setCreatemode(locationFlyoutCreateMode);
        setEditmode(locationFlyoutEditMode)
    }, [locationFlyoutCreateMode, locationFlyoutEditMode]);


    const locationCreationStatus = useSelector(selectCreationStatus);
    const locationModificationStatus = useSelector(selectModificationStatus);

    useEffect(() => {
        if (locationCreationStatus === fetchStatus.SUCCEEDED || locationModificationStatus === fetchStatus.SUCCEEDED) {
            ToggleClass()
        }
    }, [locationCreationStatus, locationModificationStatus]);

    const locationFormStatus = useSelector(selectLocationFormStatus);
    const locationForm = useSelector(selectLocationForm);

    const location = useSelector(selectLocationById);

    const [pageLoaded, setPageLoaded] = useState(false);
    const [roleOptions, setRoleOptions] = useState([]);
    const [tempLocationId, setTempLocationId] = useState("");

    useEffect(() => {
        if (!pageLoaded) {
            if (locationFormStatus === fetchStatus.IDLE && accountService.isAuthenticated()) {
                setPageLoaded(true);
                _loadForm();
            }
        }
    });

    const _loadForm = async () => {
        await dispatch(getLocationForm({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "GETFORMBYTYPE",
            payload: {
                id: "",
                data: "LocationForm"
            }
        }));
    }

    const onValueUpdated = (e, section, value) => {
        let _locationObj = Object.assign({}, location);
        let _newLocationObj = onValueChanged(_locationObj, e, section, value);
        dispatch(updateSingleData(_newLocationObj));
    };


    const onSaveClicked = (e, id, created) => {
        e.preventDefault();
        if (isFormValid(LOCATION_FORM)) {
            if (location) {
                if (created) {
                    dispatch(createLocation({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVELOCATION",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                location: JSON.stringify(location)
                            })
                        }
                    }));
                }
                else {
                    dispatch(updateLocation({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVELOCATION",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                location: JSON.stringify(location)
                            })
                        }
                    }));
                }
                dispatch(refreshLocationOptions());
            }
            setCreatemode(false);
            setEditmode(false);
            setCFInvalid(false);
            setCFSubmitted(false);
        }
        else {
            setCFInvalid(true);
        }
        setCFSubmitted(true);
    };

    useEffect(() => {
        const handleBackButton = (event) => {
            if (locationFlyoutShow) {
                dispatch(closeLocationFlyout());
            }
        };
    
        window.addEventListener('popstate', handleBackButton);
    
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [locationFlyoutShow, dispatch]);


    return (
        <React.Fragment>
            {(locationFlyoutShow) ? (<React.Fragment>
                <div className="flyout-wrapper position-fixed top-0 end-0">
                    <div className={`flyout-card ${isActive ? 'close' : 'open'}`}>
                        <div className="flyout-card-header">
                            <div className="float-end">
                                <div className="close-btn">
                                    <i className="fa fa-times" aria-hidden="true" data-tip='Close' onClick={ToggleClass}></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </div>
                            </div>
                            <div className="flyout-card-title">{location && location.id ? "Edit" : "Add"} Location Details</div>
                            <div className="flyout-card-sub-title">

                            </div>
                        </div>
                        <div className="flyout-card-body vh-100">
                            <div className="form-group p-0 pt-3">
                                <label className="flyout-label" htmlFor="txtFirstName">Location Name</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{location ? location.LocationName.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtLocationName"
                                                name="txtLocationName"
                                                ShortTextClasses="form-control"
                                                placeholder="Location Name"
                                                value={location ? location.LocationName.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                                required={locationForm.LocationName.Required}
                                                errorMessage={locationForm.LocationName.Validation}
                                                form={LOCATION_FORM}
                                                submited={cfSubmitted} />
                                        } />
                                }
                            </div>

                            <div className="form-group p-0 pt-3">
                                <label className="flyout-label" htmlFor="txtAddress">Address</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{location ? location.Address.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-map-marker"></i>}
                                        control={
                                            <ShortText
                                                id="txtAddress"
                                                name="txtAddress"
                                                ShortTextClasses="form-control"
                                                placeholder="Address"
                                                value={location ? location.Address.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                                required={locationForm.Address.Required}
                                                errorMessage={locationForm.Address.Validation}
                                                form={LOCATION_FORM}
                                                submited={cfSubmitted} />
                                        } />
                                }
                            </div>

                            <div className="form-group p-0 pt-3">
                                <label className="flyout-label" htmlFor="txtCity">City</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{location ? location.City.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-map-marker"></i>}
                                        control={
                                            <ShortText
                                                id="txtCity"
                                                name="txtCity"
                                                ShortTextClasses="form-control"
                                                placeholder="City"
                                                value={location ? location.City.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                                required={locationForm.City.Required}
                                                errorMessage={locationForm.City.Validation}
                                                form={LOCATION_FORM}
                                                submited={cfSubmitted} />
                                        } />
                                }
                            </div>

                            {/* <div className="form-group p-0 pt-3">
                                <label className="flyout-label" htmlFor="txtState">State</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{location ? location.State.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-map-marker"></i>}
                                        control={<Selector required={locationForm.State.Required} errorMessage={locationForm.State.validation} form={LOCATION_FORM} submited={cfSubmitted} id="txtState" name="txtState" SelectorClasses="form-select"
                                            value={location && location.State ? location.State.Data.trim() : ""}
                                            options={<></>
                                            } onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)} />} />
                                }
                            </div> */}

                            <div className="form-group p-0 pt-3">
                                <label className="flyout-label" htmlFor="txtState">State</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{location ? location.State.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-map-marker"></i>}
                                        control={
                                            <ShortText
                                                id="txtState"
                                                name="txtState"
                                                ShortTextClasses="form-control"
                                                placeholder="State"
                                                value={location ? location.State.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                                required={locationForm.State.Required}
                                                errorMessage={locationForm.State.Validation}
                                                form={LOCATION_FORM}
                                                submited={cfSubmitted} />
                                        } />
                                }
                            </div>

                            <div className="form-group p-0 pt-3">
                                <label className="flyout-label" htmlFor="txtPincode">Zipcode</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{location ? location.Pincode.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-map-pin"></i>}
                                        control={
                                            <ZipCodeText
                                                id="txtPincode"
                                                name="txtPincode"
                                                ZipCodeTextClasses="form-control"
                                                placeholder="Zipcode"
                                                value={location ? location.Pincode.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                                required={locationForm.Pincode.Required}
                                                errorMessage={locationForm.Pincode.Validation}
                                                form={LOCATION_FORM}
                                                submited={cfSubmitted} />
                                        } />
                                }
                            </div>

                            <div className="form-group p-0 pt-3">
                                <label className="flyout-label" htmlFor="txtPhone">Phone</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{location && location.Phone ? location.Phone.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-map-marker"></i>}
                                        control={
                                            <PhoneText
                                                id="txtPhone"
                                                name="txtPhone"
                                                ShortTextClasses="form-control"
                                                placeholder="Phone No"
                                                PhoneTextClasses="form-control"
                                                value={location && location.Phone ? location.Phone.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                                required={locationForm.Phone.Required}
                                                errorMessage={locationForm.Phone.Validation}
                                                form={LOCATION_FORM}
                                                submited={cfSubmitted} />
                                        } />
                                }
                            </div>

                            <div className="form-group p-0 pt-3">
                                <label className="flyout-label" htmlFor="txtEmail">Email Id</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{location && location.Email ? location.Email.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-map-marker"></i>}
                                        control={
                                            <EmailText
                                                id="txtEmail"
                                                name="txtEmail"
                                                ShortTextClasses="form-control"
                                                placeholder="Email Id"
                                                EmailTextClasses="form-control"
                                                value={location && location.Email ? location.Email.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.LOCATION_DETAILS, null)}
                                                // required={locationForm.Email.Required}
                                                // errorMessage={locationForm.Email.Validation}
                                                form={LOCATION_FORM}
                                                submited={cfSubmitted} />
                                        } />
                                }
                            </div>


                        </div>
                        <div className="flyout-card-footer">
                            <div className="d-flex">
                                <div className="ms-auto">
                                    {locationCreationStatus === fetchStatus.LOADING || locationModificationStatus === fetchStatus.LOADING ? <EllipsisLoader /> :
                                        <>
                                            <button className="c-btn white-btn me-3" type="button" onClick={ToggleClass}>Cancel</button>
                                            <button className="c-btn dark-btn" type="button" onClick={(e) => onSaveClicked(e, location ? location.id : "", createMode)}>Save</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flyout-backdrop flyout-backdrop-show' />
            </React.Fragment>
            ) :
                ''
            }
        </React.Fragment>

    )
}

export default LocationFlyout;