import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import BranchReport from './components/branchReport';
import ActiveEmpReport from './components/activeEmpReport';
import PayrollReport from './components/payrollReport';
import ActivityReport from './components/activityReport';
import MileageReport from './components/mileageReport';
import SalesPerformanceReport from './components/salesPerformanceReport';

const Report = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 8,
        title: "Report"
      }));
      setPageLoaded(true);
    }
  });

  const [selectedLink, setSelectedLink] = useState(1);

  const onClickTab = (e, num) => {
    e.preventDefault();
    setSelectedLink(num);
  }

  return (
    <>
          <div className="px-3 pt-3">
            <div className="row">
              <div className="col-12">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people active" id="new-start-report-tab" data-bs-toggle="tab" data-bs-target="#new-start-report" type="button" role="tab" aria-controls="new-start-report" aria-selected="true" onClick={(e) => onClickTab(e, 1)}>New Start Report</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people" id="branch-report-tab" data-bs-toggle="tab" data-bs-target="#branch-report" type="button" role="tab" aria-controls="branch-report" aria-selected="false" onClick={(e) => onClickTab(e, 2)}>Branch Report</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people" id="active-employee-tab" data-bs-toggle="tab" data-bs-target="#active-employee" type="button" role="tab" aria-controls="active-employee" aria-selected="false" onClick={(e) => onClickTab(e, 3)}>Active Employee</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people" id="activity-report-tab" data-bs-toggle="tab" data-bs-target="#activity-report" type="button" role="tab" aria-controls="activity-report" aria-selected="false" onClick={(e) => onClickTab(e, 4)}>Activity Report</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people" id="mileage-report-tab" data-bs-toggle="tab" data-bs-target="#mileage-report" type="button" role="tab" aria-controls="mileage-report" aria-selected="false" onClick={(e) => onClickTab(e, 5)}>Mileage Report</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link nav-link-people" id="sales-performance-report-tab" data-bs-toggle="tab" data-bs-target="#sales-performance-report" type="button" role="tab" aria-controls="sales-performance-report" aria-selected="false" onClick={(e) => onClickTab(e, 6)}>Sales Performance Report</button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="new-start-report" role="tabpanel" aria-labelledby="new-start-report-tab">
                    <div className="card a-card-wrap p-3">
                      {selectedLink === 1 &&
                        <PayrollReport />
                      }
                    </div>
                  </div>
                  <div className="tab-pane fade" id="branch-report" role="tabpanel" aria-labelledby="branch-report-tab">
                    <div className="card a-card-wrap p-3">
                      {selectedLink === 2 &&
                        <BranchReport />
                      }
                    </div>
                  </div>
                  <div className="tab-pane fade" id="active-employee" role="tabpanel" aria-labelledby="active-employee-tab">
                    <div className="card a-card-wrap p-3">
                      {selectedLink === 3 &&
                        <ActiveEmpReport />
                      }
                    </div>
                  </div>
                  <div className="tab-pane fade" id="activity-report" role="tabpanel" aria-labelledby="activity-report-tab">
                    <div className="card a-card-wrap p-3">
                      {selectedLink === 4 &&
                        <ActivityReport />
                      }
                    </div>
                  </div>
                  <div className="tab-pane fade" id="mileage-report" role="tabpanel" aria-labelledby="mileage-report-tab">
                    <div className="card a-card-wrap p-3">
                      {selectedLink === 5 &&
                        <MileageReport />
                      }
                    </div>
                  </div>
                  <div className="tab-pane fade" id="sales-performance-report" role="tabpanel" aria-labelledby="sales-performance-report-tab">
                    <div className="card a-card-wrap p-3">
                      {selectedLink === 6 &&
                        <SalesPerformanceReport />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}

export default Report;