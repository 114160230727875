import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateText from '../../../../component/Controls/custom/textbox/dateText';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import jsPDF from "jspdf";
import "jspdf-autotable";
import Loader from "react-js-loader";
import Moment from 'moment';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';
import { getActivityReport, selectActivityRecords } from '../../reducerSlices/reportSlice';
import { tenantId } from '../../../../api/client';


function ActivityReport() {

    const dispatch = useDispatch();

    const [start, setStart] = useState(Moment(Moment(new Date()).add(-7, 'd').format('YYYY-MM-DD')));
    const [end, setEnd] = useState(Moment(Moment(new Date()).format('YYYY-MM-DD')));
    const [loading, setLoading] = useState(false);
    const PayrollData = useSelector(selectActivityRecords);

    const OnClickReport = async () => {
        if (start != undefined && end != undefined) {
            setLoading(true);
            await dispatch(getActivityReport({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETACTIVITYREPORT",
                payload: {
                    id: '',
                    data: JSON.stringify({
                        StartDate: Moment(start).format('YYYY-MM-DD'),
                        EndDate: Moment(end).format('YYYY-MM-DD')
                    })
                }
            }));
            setLoading(false);
        }
        else {
            alert('Please select filter')
        }

    };

    const ExportToPdf = () => {
        const orientation = "landscape"; // portrait or landscape
        const doc = new jsPDF(orientation);
        doc.setFontSize(40);
        doc.text("Activity Report", 100, 20);
        var col = ["Sr. No.", "Location", "Total Applications", "Total Dispatched", "Total Active", "Total No Shows", "Total Show Up Time", "Placed Available", "Placed Inactive", "Placed DNU", "Placed DNR", "Total Call Ins", "Total Call Outs", "Total Job Orders", "Total New Clients"];
        var rows = [];
        PayrollData.forEach((d, index) => {
            var temp = [index + 1, d.Location, d.TotalApplications, d.TotalDispatched, d.TotalActive, d.TotalNoShows, d.TotalShowUpTime, d.PlacedAvailable, d.PlacedInactive, d.PlacedDNU, d.PlacedDNR, d.TotalCallIns, d.TotalCallOuts, d.TotalJobOrders, d.TotalNewClients];
            rows.push(temp);
        });
        doc.autoTable(col, rows, { startY: 30 });
        doc.save("Activity Report.pdf")
    }

    return (
        <>
            <div className="d-flex">
                <div className="p-3">
                    <DateText
                        id="txtStartDate"
                        name="txtStartDate"
                        DateTextClasses="form-control"
                        placeholder="Start Date"
                        value={Moment(start).format('YYYY-MM-DD')}
                        selected={start}
                        onChange={(e) => setStart(e.target.value)} />
                </div>

                <div className="p-3">
                    <DateText
                        id="txtEndDate"
                        name="txtEndDate"
                        DateTextClasses="form-control"
                        placeholder="End Date"
                        value={Moment(end).format('YYYY-MM-DD')}
                        selected={end}
                        onChange={(e) => setEnd(e.target.value)} />
                </div>

                {isObjectArray(PayrollData) ?
                    <div className="p-3">
                        <button className="form-control" onClick={() => ExportToPdf()}> <i className="fa fa-download mr-3" aria-hidden="true"></i></button>
                    </div>
                    : <></>}

                <div className="p-3 ms-auto">
                    <button type="button" className="c-btn dark-btn position-relative" onClick={() => OnClickReport()}>
                        <i className="fa fa-filter" aria-hidden="true"></i> Filter
                        <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    </button>
                </div>
            </div>

            {loading ?
                <Loader type="spinner-circle" bgColor={"#92DCE2"} title={""} color={'#92DCE2'} size={100} />
                : <></>}
            {isObjectArray(PayrollData) ? <span className="px-3 report-heading">Active Employee Report </span> : <></>}

            <div className="row p-3">
                <GridComponent dataSource={PayrollData} allowPaging={false} allowSorting={true} allowResizing={true} toolbar={['Search']} autoFit={true} allowTextWrap={true} textWrapSettings={{ wrapMode: 'Header' }}>
                    <ColumnsDirective>
                        <ColumnDirective field='Location' headerText='Location' width='140' />
                        <ColumnDirective field='TotalApplications' headerText='Total Applications' width='180' />
                        <ColumnDirective field='TotalDispatched' headerText='Total Dispatched' width='150' />
                        <ColumnDirective field='TotalActive' headerText='Total Active' width='150' />
                        <ColumnDirective field='TotalNoShows' headerText='Total No Shows' width='150' />
                        <ColumnDirective field='TotalShowUpTime' headerText='Total Show Up Time' width='200' />
                        <ColumnDirective field='PlacedAvailable' headerText='Placed Available' width='150' />
                        <ColumnDirective field='PlacedInactive' headerText='Placed Inactive' width='150' />
                        <ColumnDirective field='PlacedDNU' headerText='Placed DNU' width='150' />
                        <ColumnDirective field='PlacedDNR' headerText='Placed DNR' width='150' />
                        <ColumnDirective field='TotalCallIns' headerText='Total Callins' width='150' />
                        <ColumnDirective field='TotalCallOuts' headerText='Total Call Outs' width='150' />
                        <ColumnDirective field='TotalJobOrders' headerText='Total Job Orders' width='150' />
                        <ColumnDirective field='TotalNewClients' headerText='Total New Clients' width='150' />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                </GridComponent>
            </div>
        </>

    )
}

export default ActivityReport