export const sections = {
    SKILLGROUP_DETAILS: 0,
    SKILLGROUP_OPTIONS_DETAILS: 1
};

export const onValueChanged = (skillGroup, e, section, value) => {
    let _skillGroup = JSON.parse(JSON.stringify(skillGroup));
    if (section == sections.SKILLGROUP_DETAILS) {
        return _saveSkillGroupDetails(_skillGroup, e, value);
    } else if (section === sections.SKILLGROUP_OPTIONS_DETAILS) {
        return _saveSkillGroupOptionsDetails(_skillGroup, e, value);
    }
};

const _saveSkillGroupDetails = (skillGroup, e, value) => {
    if (e.target.name === "txtSkillGroupName") {
        skillGroup.SkillGroupName.Data = e.target.value.trim();
    }
    else if (e.target.name === "drpStatuses") {
        if (!skillGroup.Status) {
            skillGroup.Status = {
                "Id": "drpStatuses",
                "Data": ""
            }
        }
        skillGroup.Status.Data = e.target.value;
    }

    return skillGroup;
};

const _saveSkillGroupOptionsDetails = (skillGroup, e, value) => {
    let nameParts = e.target.name.split('-');
    const name = nameParts[0];
    const index = nameParts[1];
    const newOptions = [...skillGroup.SkillGroupOptions];
    newOptions[index] = e.target.value.trim();
    skillGroup.SkillGroupOptions = newOptions

    return skillGroup;
};