import React, { useEffect, useState } from 'react';
import nophoto from './../../assets/images/clipart/nophoto.png';
import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import girlFaceImg from './../../assets/images/clipart/girl-face.svg';
import pencilDarkImg from './../../assets/images/clipart/pencil-dark.svg';
import triangleExclamationiImg from './../../assets/images/clipart/triangle-exclamationi.svg';
import PeopleDetailSubCard from './PeopleDetailSubCard';
import { useSelector } from 'react-redux';
import { selectApplicantById } from '../../features/applicant/reducerSlices/applicantSlice';
import { selectLocationOptions } from '../../features/locations/reducerSlices/locationSlice';
import { _getDateText, _getLocationText, toLocalDateOnly } from '../../common/common';
import ReactTooltip from 'react-tooltip';
import { isObjectArray } from '../../utilities/utilityFunctions';
import DataNotFoundCard from './DataNotFoundCard';
import DialCallButton from '../Controls/buttons/dialCallButton';
import { applicantStatusesData } from '../../features/shared/services/masterData';
import SkillPeopleDetailSubCard from './SkillPeopleDetailSubCard';


function PeopleDetailCard(props) {
  const applicant = useSelector(selectApplicantById);
  const locationOptions = useSelector(selectLocationOptions);

  const [currentScheduleJob, setCurrentScheduleJob] = useState([]);
  const [completedJobs, setCompletedJobs] = useState([]);
  const [skill, setSkill] = useState([]);
  const [sort, setSort] = useState(false);

  const onJobSearch = (e) => {
    let value = e.target.value;
    let searchText = value.toLowerCase();

    if (props.dispatchDetails) {
      let _dispatchDetails = props.dispatchDetails.map((o) => ({ ...o, PositionText: _getPositionText(o.Position) }));

      let _completedJobs = _dispatchDetails.filter((d) => {
        return d.EndDate !== undefined && d.EndDate !== '' && (d.Title.toLowerCase().includes(searchText) || d.PayRates.toLowerCase().includes(searchText) || d.PositionText.toLowerCase().includes(searchText))
      });
      let _currentScheduleJob = _dispatchDetails.filter((d) => {
        return (d.ActiveDate === '' || d.EndDate === '') && (d.Title.toLowerCase().includes(searchText) || d.PayRates.toLowerCase().includes(searchText) || d.PositionText.toLowerCase().includes(searchText))
      });

      let _skill = applicant.ExperienceDetails.filter((d) => {
        if (d.Type === "Multi Choice") {
          return d.Data.some((dataItem) => dataItem.toLowerCase().includes(searchText)) || d.ExperienceName.toLowerCase().includes(searchText);
        }
        return d.Data.toLowerCase().includes(searchText) || d.ExperienceName.toLowerCase().includes(searchText);
      });

      if (!sort) {
        _completedJobs = _completedJobs.reverse();
        _currentScheduleJob = _currentScheduleJob.reverse();
        // _skill = _skill.reverse();
        _skill = _skill.sort((a, b) => a.ExperienceName.localeCompare(b.ExperienceName));
      }
      else {
        _skill = _skill.sort((a, b) => b.ExperienceName.localeCompare(a.ExperienceName));
      }

      setCompletedJobs(_completedJobs);
      setCurrentScheduleJob(_currentScheduleJob);
      setSkill(_skill);
    }
  }

  const onSort = (e) => {
    setSort(!sort);
    const _currentScheduleJob = [...currentScheduleJob].reverse();
    const _completedJobs = [...completedJobs].reverse();
    const _skill = [...skill].reverse();
    setCurrentScheduleJob(_currentScheduleJob);
    setCompletedJobs(_completedJobs);
    setSkill(_skill);
  }

  useEffect(() => {
    if (props.dispatchDetails) {
      let _completedJobs = props.dispatchDetails.filter((d) => {
        return d.EndDate !== undefined && d.EndDate !== '';
      });
      _completedJobs = _completedJobs.reverse();
      setCompletedJobs(_completedJobs);

      let _currentScheduleJob = props.dispatchDetails.filter((d) => {
        return d.ActiveDate === '' || d.EndDate === '';
      });
      _currentScheduleJob = _currentScheduleJob.reverse();
      setCurrentScheduleJob(_currentScheduleJob);
    }
  }, [props.dispatchDetails]);

  useEffect(() => {
    if (applicant && applicant.ExperienceDetails) {
      let _skill = applicant.ExperienceDetails.filter((d) => {
        return d.Data !== '';
      });
      _skill.sort((a, b) => a.ExperienceName.localeCompare(b.ExperienceName));
      setSkill(_skill);
    } else {
      setSkill([]);
    }
  }, [applicant]);

  let statuses = props.statuses;

  const findSecondaryStatusByPrimary = (primaryStatusValue, selectedStatus) => {
    const matchingStatuses = statuses.filter(status => status.primaryStatus === primaryStatusValue);
    let matchingValues = matchingStatuses.map(status => status.value);
    matchingValues = matchingValues.filter(value => value !== selectedStatus);
    return matchingValues;
  }

  let _statusList;
  if (isObjectArray(statuses)) {
    if (statuses.length > 0) {
      switch (props.selectedStatus) {
        case 'Incomplete':
          _statusList = statuses.map((status, index) => (
            status.text === "In Progress" ? (
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
                {status.text}
              </a>
            ) :
              (
                <a
                  className="dropdown-item-disabled">
                  {status.text}
                </a>
              )
          ));
          break;
        // case 'In Progress':
        case applicantStatusesData.InProgress:
          _statusList = statuses.map((status, index) => (
            // status.text === "Available" || status.text === "DNU" ? (
            status.primaryStatus === applicantStatusesData.Available || status.primaryStatus === applicantStatusesData.DNU || findSecondaryStatusByPrimary(props.selectedStatus, props.selectedSecondaryStatus).includes(status.value) ? (
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
                {status.text}
              </a>
            ) :
              (
                <a
                  className="dropdown-item-disabled">
                  {status.text}
                </a>
              )
          ));
          break;
        // case 'Available':
        case applicantStatusesData.Available:
          _statusList = statuses.map((status, index) => (
            // status.text === "DNU" || status.text === "Dispatched" || status.text === "InActive" || status.text === "In Progress" ? (<a
            status.primaryStatus === applicantStatusesData.DNU || status.primaryStatus === applicantStatusesData.Dispatched || status.primaryStatus === applicantStatusesData.InActive || status.primaryStatus === applicantStatusesData.InProgress || findSecondaryStatusByPrimary(props.selectedStatus, props.selectedSecondaryStatus).includes(status.value) ? (<a
              className="dropdown-item"
              href="#"
              onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
              {status.text}
            </a>) :
              (
                <a
                  className="dropdown-item-disabled">
                  {status.text}
                </a>
              )
          ));
          break;
        // case 'Active':
        case applicantStatusesData.Active:
          _statusList = statuses.map((status, index) => (
            // status.text === "DNU" || status.text === "InActive" || status.text === "Available" ? (<a
            status.primaryStatus === applicantStatusesData.DNU || status.primaryStatus === applicantStatusesData.InActive || status.primaryStatus === applicantStatusesData.Available ? (<a
              className="dropdown-item"
              href="#"
              onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
              {status.text}
            </a>) :
              (
                <a
                  className="dropdown-item-disabled">
                  {status.text}
                </a>
              )
          ));
          break;
        // case 'InActive':
        case applicantStatusesData.InActive:
          _statusList = statuses.map((status, index) => (
            // status.text === "DNU" || status.text === "In Progress" || status.text === "Available" ? (<a
            status.primaryStatus === applicantStatusesData.DNU || status.primaryStatus === applicantStatusesData.InProgress || status.primaryStatus === applicantStatusesData.Available || findSecondaryStatusByPrimary(props.selectedStatus, props.selectedSecondaryStatus).includes(status.value) ? (<a
              className="dropdown-item"
              href="#"
              onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
              {status.text}
            </a>) :
              (
                <a
                  className="dropdown-item-disabled">
                  {status.text}
                </a>
              )
          ));
          break;
        // case 'DNU':
        case applicantStatusesData.DNU:
          _statusList = statuses.map((status, index) => (
            // status.text === "In Progress" ? (<a
            status.primaryStatus === applicantStatusesData.InProgress ? (<a
              className="dropdown-item"
              href="#"
              onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
              {status.text}
            </a>) :
              (
                <a
                  className="dropdown-item-disabled">
                  {status.text}
                </a>
              )
          ));
          break;
        // case 'Dispatched':
        case applicantStatusesData.Dispatched:
          _statusList = statuses.map((status, index) => (
            // status.text === "Active" || status.text === "Available" ? (<a
            status.primaryStatus === applicantStatusesData.Active || status.primaryStatus === applicantStatusesData.Available ? (<a
              className="dropdown-item"
              href="#"
              onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
              {status.text}
            </a>) :
              (
                <a
                  className="dropdown-item-disabled">
                  {status.text}
                </a>
              )
          ));
          break;
        default:
          _statusList = statuses.map((status, index) => (
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
              {status.text}
            </a>
          ));
      }

    }
  }

  const _getPositionText = (id) => {
    if (props.positionOptions) {
      let position = props.positionOptions.find(p => p.value === id);
      if (position && position.text) {
        return position.text;
      }
    }
    return "N/A";
  }


  return (
    <div className="card people-detail-card">
      <div className="people-detail-card-header pb-4">
        {props.isDNR &&
          <div className="people-detail-card-status-top">
            <img src={triangleExclamationiImg} /> DNR
          </div>
        }
        <div className="d-flex justify-content-center pt-3">
          <div className="people-detail-card-profile position-relative">
            <div className="people-detail-card-profile-box">
              <img className=""
                src={applicant && applicant.PhotoPath && applicant.PhotoPath.Data ?
                  applicant.PhotoPath.Data.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "")
                  : applicant && applicant.Gender && applicant.Gender.Data === 'Female' ? girlFaceImg
                    : applicant && applicant.Gender && applicant.Gender.Data === 'Male' ? boyFaceImg
                      : nophoto
                }
              />
            </div>
            <button className="position-absolute  translate-middle px-1 people-detail-card-profile-box-badge rounded-circle" onClick={(e) => props.onPhotoClicked && props.onPhotoClicked(e, props.photoPath)} data-tip='Edit profile picture'>
              <img src={pencilDarkImg} />
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-2">
          <div className="people-detail-card-name">{applicant ? applicant.FirstName.Data : ""} {applicant ? applicant.LastName.Data : ""}</div>
        </div>
        <div className="d-flex justify-content-center">
          {applicant ?
            <>
              <a href='#' className='link' data-tip='Change Loaction' onClick={props.onChangeLocationClicked}>
                <span className="people-detail-card-location">
                  <i className="fa-solid fa-location-dot pe-2"></i>
                  {_getLocationText(locationOptions, applicant.Location.Data)}
                </span>
              </a>
              <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
            </> : ""}
        </div>
        <div className="d-flex justify-content-center">
          <p className="d-flex align-items-center people-detail-card-phone pt-2">
            {applicant ? <>
              <DialCallButton
                dialCallButtonClasses="pe-2" />
              {applicant.PhoneNumber.Data}</> : ""}
          </p>
        </div>
      </div>

      <div className="btn-group pr-2 pt-4">
        <div className="position-absolute top-0 start-50 translate-middle">
          {props.isRecordValid &&
            <>
              <button className="people-detail-card-select dropdown-toggle" type="button" id="dropdownMenuClickableOutside" data-bs-toggle="dropdown" data-bs-auto-close="inside outside" aria-expanded="false">
                {props.selectedSecondaryStatusText}
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickableOutside">
                {_statusList}
              </ul>
            </>
          }
        </div>
      </div>
      <div className="nav people-detail-card-nav pt-3">
        <button className="people-detail-card-nav-btn active" data-bs-toggle="tab" href="#menu1">Current schedule</button>
        <button className="people-detail-card-nav-btn" data-bs-toggle="tab" href="#menu2">Work Experience</button>
        <button className="people-detail-card-nav-btn" data-bs-toggle="tab" href="#menu3">Skill</button>
      </div>
      <div className="people-detail-card-search">
        <i className="fa fa-search"></i>
        <input type="text" className="form-control" placeholder="Search" onChange={(e) => onJobSearch(e)} />
        <button className="" onClick={(e) => onSort(e)}>
          <span className={sort ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-wide-short'}></span>
        </button>
      </div>
      <div className="people-detail-card-sub-wrapper vh-100 overflow-auto">
        <div className="tab-content mt-0">
          <div className="tab-pane fade show active" id="menu1">
            {
              Object.keys(currentScheduleJob).length === 0 ?
                <div className='d-flex justify-content-between'>
                  <DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title={'No jobs found '} />
                </div>
                :
                currentScheduleJob && currentScheduleJob.map((d) => {
                  return <PeopleDetailSubCard
                    code={d.Title}
                    startDate={d.StartDate && toLocalDateOnly(d.StartDate)}
                    startTime={d.StartTime}
                    endDate={d.EndDate ? toLocalDateOnly(d.EndDate) : '-'}
                    jobPosition={d.Position && _getPositionText(d.Position)}
                    jobType={d.JobType}
                    reportTo={d.ReportTo}
                    clientPhone={d.ClientPhone === '' ? <><span class="blur">(XXX)XXX-XXXX</span></> : d.ClientPhone}
                    payRates={d.PayRates === '' ? <><span class="blur">XX.XX</span> hr</> : d.PayRates + ' hr'}
                  />
                })
            }
          </div>
          <div className="tab-pane fade" id="menu2">
            {
              Object.keys(completedJobs).length === 0 ?
                <div className='d-flex justify-content-between'>
                  <DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title={'No jobs found '} />
                </div>
                :
                completedJobs && completedJobs.map((d) => {
                  return <PeopleDetailSubCard
                    code={d.Title}
                    startDate={d.StartDate && toLocalDateOnly(d.StartDate)}
                    startTime={d.StartTime}
                    endDate={d.EndDate ? toLocalDateOnly(d.EndDate) : 'Present'}
                    jobPosition={d.Position && _getPositionText(d.Position)}
                    jobType={d.JobType}
                    reportTo={d.ReportTo}
                    clientPhone={d.ClientPhone === '' ? <><span class="blur">(XXX)XXX-XXXX</span></> : d.ClientPhone}
                    payRates={d.PayRates === '' ? <><span class="blur">XX.XX</span> hr</> : d.PayRates + ' hr'}
                    DNRStatus={d.DNRStatus}
                    whyItEnded={d.WhyItEnded}
                  />
                })
            }
          </div>
          <div className="tab-pane fade" id="menu3">
            {/* {applicant && applicant.ExperienceDetails && Array.isArray(applicant.ExperienceDetails) && applicant.ExperienceDetails.length > 0 ? (
              applicant.ExperienceDetails.map((d, index) => (
                <>
                  {d.Data &&
                    <SkillPeopleDetailSubCard
                      ExperienceName={d.ExperienceName}
                      Data={d.Data}
                      Type={d.Type}
                    />
                  }
                </>
              ))
            ) : (
              <div className='d-flex justify-content-between'>
                <DataNotFoundCard containerClassOverriding='data-not-found-container-100 mt-5 pt-5' title='No skill found' />
              </div>
            )} */}

            {
              Object.keys(skill).length === 0 ?
                <div className='d-flex justify-content-between'>
                  <DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title={'No skill found '} />
                </div>
                :
                skill && skill.map((d) => {
                  return <SkillPeopleDetailSubCard
                    ExperienceName={d.ExperienceName}
                    Data={d.Data}
                    Type={d.Type}
                  />
                })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default PeopleDetailCard