import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CheckBox from '../custom/checkers/checkbox';


function RouteTrackingUserFilter(props) {

    const routeTrackingUserFilter = useSelector(props.routeTrackingUserFilterSelector);

    const [open, setOpen] = useState(false);

    const routeTrackingUserContainerRef = useRef(null);

    let _routeTrackingUsers = props.routeTrackingUsers;

    let routeTrackingUsersList;
    let isRouteTrackingUsersSelected = (routeTrackingUserFilter.value && Array.isArray(routeTrackingUserFilter.value) && routeTrackingUserFilter.value.length > 0);
    if (_routeTrackingUsers && Array.isArray(_routeTrackingUsers) && _routeTrackingUsers.length > 0) {

        routeTrackingUsersList = _routeTrackingUsers.map((routeTrackingUser, index) => (
            index == 0 ?
                <>
                    <li key={'All'} className="filter-list-item"
                        onClick={(e) => props.onRouteTrackingUserChanged && props.onRouteTrackingUserChanged(e, 'All', _routeTrackingUsers)}>
                        <CheckBox
                            id={`All`}
                            size="Small"
                            checked={isRouteTrackingUsersSelected ? routeTrackingUserFilter.value.includes('All') : false}
                        />
                        {
                            isRouteTrackingUsersSelected ?
                                <span className={`ms-3 ${isRouteTrackingUsersSelected && routeTrackingUserFilter.value.includes('All') ? "filter-selected" : ""}`}>All</span>
                                :
                                <span className={`ms-3`}>All</span>
                        }
                    </li>
                    <li key={routeTrackingUser.id} className="filter-list-item"
                        onClick={(e) => props.onRouteTrackingUserChanged && props.onRouteTrackingUserChanged(e, routeTrackingUser.id, _routeTrackingUsers)}>
                        <CheckBox
                            id={`ckbRouteTrackingUser-${routeTrackingUser.id}`}
                            size="Small"
                            checked={isRouteTrackingUsersSelected ? routeTrackingUserFilter.value.includes(routeTrackingUser.id) : false}
                        />
                        {
                            isRouteTrackingUsersSelected ?
                                <span className={`ms-3 ${isRouteTrackingUsersSelected && routeTrackingUserFilter.value.includes(routeTrackingUser.id) ? "filter-selected" : ""}`}>{routeTrackingUser.FirstName + ' ' + routeTrackingUser.LastName
                                }</span>
                                :
                                <span className={`ms-3`}>{routeTrackingUser.FirstName + ' ' + routeTrackingUser.LastName}</span>
                        }
                    </li>
                </>
                :
                <li key={routeTrackingUser.id} className="filter-list-item"
                    onClick={(e) => props.onRouteTrackingUserChanged && props.onRouteTrackingUserChanged(e, routeTrackingUser.id, _routeTrackingUsers)}>
                    <CheckBox
                        id={`ckbRouteTrackingUser-${routeTrackingUser.id}`}
                        size="Small"
                        checked={isRouteTrackingUsersSelected ? routeTrackingUserFilter.value.includes(routeTrackingUser.id) : false}
                    />
                    {
                        isRouteTrackingUsersSelected ?
                            <span className={`ms-3 ${isRouteTrackingUsersSelected && routeTrackingUserFilter.value.includes(routeTrackingUser.id) ? "filter-selected" : ""}`}>{routeTrackingUser.FirstName + ' ' + routeTrackingUser.LastName}</span>
                            :
                            <span className={`ms-3`}>{routeTrackingUser.FirstName + ' ' + routeTrackingUser.LastName}</span>
                    }
                </li>
        ));
    }

    useEffect(() => {
        if (open && routeTrackingUserContainerRef && routeTrackingUserContainerRef.current) {
            routeTrackingUserContainerRef.current.focus();
        }
    });

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };


    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>User Name</span> :
                <span className="filter-value">{routeTrackingUserFilter.value ? routeTrackingUserFilter.text : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={routeTrackingUserContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    {routeTrackingUsersList}
                </ul>
            </div> : <></>}
        </div>
    )
}

export default RouteTrackingUserFilter;