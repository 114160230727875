import React from 'react'

function InputGroup(props) {

  // console.log(props.control.props.value);
  return (
    <div className={`c-input-group ${props.InputGroupClasses ? props.InputGroupClasses : ""}`}>
      <label className='c-label'>{props.label}</label>
      {props.control ? props.control : <div></div>}
    </div>
    // <div className='c-input-group'>
    //   <label className='c-label'>{props.label}</label>
    //   {props.editMode ?
    //     props.control ? props.control : <div></div>
    //     :
    //     props.control && props.control.props && props.control.props.value ?
    //       // <label className='c-label'>{props.control.props.value}</label>
    //       <input type="text" value={props.control.props.value} className={`form-control`} disabled/>
    //       :
    //       ''
    //   }
    // </div>
  )
}

export default InputGroup;