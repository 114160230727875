import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  getAllLeads,
  updateIsFiltered,
  selectStatus,
  selectCurrentPage,
  selectTotalItems,
  selectAllLeads,
  updateStatus,
  selectFirstRecord,
  loadSingleData,
  getLeadById,
  updateCurrentPage,
  selectLeadById,
  updateLead,
  selectModificationStatus,
  selectRemovalStatus,
  selectSingleStatus,
  selectSingleRefreshed,
  getLeadForm,
  selectLeadFormStatus,
  selectLeadForm,
  updateSingleData,
  createNewLead,
  updateLeadFilters,
  removeLead,
  createLead,
  selectCreationStatus,
  selectLeadCalledOnStatus,
  selectCalledOnLead,
  updateSingleLead,
  addLeadToClient,
  createContactDetail,
  deleteContactDetail,
  selectUserStatus,
  getAllUsers,
  selectAllUsers,
  selectSalesRepOptionsState,
  selectSalesRepOptions,
  getSalesRepOptions,
  selectSalesMangerOptionsState,
  selectSalesMangerOptions,
  getSalesMangerOptions,
  // updateAppointmentsData,
  createAppointment,
  getAppointmentsById,
  selectAppointmentsById,
  selectCreationAppointmentStatus,
  getLeadByAddress,
  selectLeadByAddress,
  selectRecentClickedLead,
  selectAppointmentsStatus,
  removeAppointment,
  selectRemovalAppointmentStatus
} from '../reducerSlices/leadSlice';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup';
import ShortText from '../../../component/Controls/custom/textbox/shortText';
import { onGoogleDetails, onValueChanged, sections } from '../services/leadSaver';
import { Operation } from '../../../common/constants';
import { accountService } from '../../accounts/services/accountService';
import PhoneText from '../../../component/Controls/custom/textbox/phoneText';
import { fetchStatus, tenantId } from '../../../api/client';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import {
  selectDefaultFilter,
  selectLocationFilter,
  selectRefreshData,
  selectRoleFilter,
  selectLeadStatusFilter,
  selectWhereClause,
  selectWhereClauseChanged,
  updateFilter,
  updateLeadFilter,
  updateLeadRefreshData,
  updateNameFilter
} from '../../../component/Controls/filters/reducerSlices/leadFilterSlice';
import SelectorSearch from '../../../component/Controls/custom/selectors/selectorSearch';
import NumberText from '../../../component/Controls/custom/textbox/numberText';
import Selector from '../../../component/Controls/custom/selectors/selectors';
import { _getDateText, _getLocationText, toLocalDate } from '../../../common/common';
import DateText from '../../../component/Controls/custom/textbox/dateText';
import Statement from '../../../component/Controls/custom/textbox/statement';
import MindMapSwitch from '../../../component/Controls/custom/mindMapSwitch';
import YesNoRadio from '../../../component/Controls/custom/checkers/yesNoRadio';
import MultiSelector from '../../../component/Controls/custom/selectors/multiSelector';
import { shiftsData } from '../../applicant/services/masterData';
import SortButton from '../../../component/Controls/custom/buttons/sortButton';
import LogCardLead from '../../../component/Card/LogCardLead';
import ContactDetail from './components/contactDetail';
import { show } from '../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';
import { onDeleteContact } from '../services/statusSaver';


import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { extend } from '@syncfusion/ej2-base';
import LeadDocumentsCard from '../../../component/Controls/cards/leadDocumentsCard';
import { isFormValid } from '../../../component/Controls/services/formService';
import { showLeadCalledFlyout } from '../../../component/Flyout/reducerSlices/leadCalledFlyoutSlice';
import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from '../../locations/reducerSlices/locationSlice';
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import AddressParser from "parse-google-address";

import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject, Year, ExcelExport, Resize, Print } from '@syncfusion/ej2-react-schedule';
import { showLSUModal } from '../../../component/Controls/modals/reducerSlices/leadStatusUpdateModelPopUpSlice';
import { leadStatuses } from '../../shared/services/masterData';
import { showLeadNoteFlyout } from '../../../component/Flyout/reducerSlices/leadNoteFlyoutSlice';
import GridLoader from '../../../component/Controls/loaders/gridLoader';
import { selectPositionOptions } from '../../setting/reducerSlices/positionsSlice';

function LeadDetails() {

  const history = useHistory();
  const dispatch = useDispatch();

  let scheduleObj;

  const recentClickedLead = useSelector(selectRecentClickedLead);

  const LEAD_FORM = "Leadform";
  const user = accountService.getAuthenticatedUser();

  const [selectedTab, setSelectedTab] = useState(1);
  const lead = useSelector(selectLeadById);

  const whereClause = useSelector(selectWhereClause);
  const whereClauseChanged = useSelector(selectWhereClauseChanged);

  const locationOptions = useSelector(selectLocationOptions);
  const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
  const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);

  const [lFInvalid, setLFInvalid] = useState(false);
  const [lfSubmitted, setLFSubmitted] = useState(false);
  const [createMode, setCreatemode] = useState(false);
  const [editMode, setEditmode] = useState(false);


  const leadFormStatus = useSelector(selectLeadFormStatus);
  const leadForm = useSelector(selectLeadForm);

  const [logCards, setLogCards] = useState(undefined);
  const [sort, setSort] = useState(false);

  const usersRequestStatus = useSelector(selectUserStatus);
  const allUsers = useSelector(selectAllUsers);
  const [usersOptions, setUsersOptions] = useState();

  const salesMangerOptionsState = useSelector(selectSalesMangerOptionsState);
  const salesMangerOptions = useSelector(selectSalesMangerOptions);
  const [salesMangerOptionsData, setSalesMangerOptionsData] = useState();

  const salesRepOptionsState = useSelector(selectSalesRepOptionsState);
  const salesRepOptions = useSelector(selectSalesRepOptions);
  const [salesRepOptionsData, setSalesRepOptionsData] = useState();

  const [activeAddBtn, setActiveAddBtn] = useState(true);


  const [isAgency, setIsAgency] = useState(false);
  const [haveOnSite, setHaveOnSite] = useState(false);
  const [haveOnSite2, setHaveOnSite2] = useState(false);
  const [haveOnSite3, setHaveOnSite3] = useState(false);

  const [isBilledSickDays, setIsBilledSickDays] = useState(false);
  const [isBilledHolidays, setIsBilledHolidays] = useState(false);
  const [isBilledVacation, setIsBilledVacation] = useState(false);

  const [googleAddressValidate, setGoogleAddressValidate] = useState(false);
  const duplicateLeadAddress = useSelector(selectLeadByAddress);

  const appointments = useSelector(selectAppointmentsById);
  const creationAppointmentStatus = useSelector(selectCreationAppointmentStatus);
  const removalAppointmentStatus = useSelector(selectRemovalAppointmentStatus);

  const [selectedStatus, setSelectedStatus] = useState();

  const positionOptions = useSelector(selectPositionOptions);

  useEffect(() => {
    if (recentClickedLead === undefined) {
      history.push('../lead');
    }

    if (recentClickedLead === 0) {
      setCreatemode(true);
    } else {
      setCreatemode(false);
    }

  }, [recentClickedLead]);

  const onTabSelected = (e, v) => {
    setSelectedTab(v);
  };

  const _loadUsers = async (page, offset, limit, whereClause) => {
    await dispatch(getAllUsers({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETUSERS",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: offset,
            limit: limit,
            where: whereClause
          })
        }
      }
    }));

  }

  const _getSalesRepOption = (locationId) => {
    dispatch(getSalesRepOptions({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETSALESREPBYLOCATION",
      payload: {
        id: "",
        data: JSON.stringify({ "locationId": locationId })
      }
    }));
  }

  useEffect(() => {
    if (salesRepOptionsState === fetchStatus.IDLE) {
      _getSalesRepOption("All");
    }
  }, [salesRepOptionsState]);

  useEffect(() => {
    if (salesRepOptionsState === fetchStatus.SUCCEEDED) {
      if (salesRepOptions) {
        if (isObjectArray(salesRepOptions)) {
          let _salesRepOptions;
          _salesRepOptions = salesRepOptions.map((d, index) => (
            {
              text: d.FirstName + ' ' + d.LastName,
              value: d.id,
            }
          ));
          setSalesRepOptionsData(_salesRepOptions);
        }
      }
    }
  }, [salesRepOptions]);

  const _getSalesMangerOption = (locationId) => {
    dispatch(getSalesMangerOptions({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETSALESMANAGERBYLOCATION",
      payload: {
        id: "",
        data: JSON.stringify({ "locationId": locationId })
      }
    }));
  }

  useEffect(() => {
    if (salesMangerOptionsState === fetchStatus.IDLE) {
      _getSalesMangerOption("All");
    }
  }, [salesMangerOptionsState]);

  useEffect(() => {
    if (salesMangerOptionsState === fetchStatus.SUCCEEDED) {
      if (salesMangerOptions) {
        if (isObjectArray(salesMangerOptions)) {
          let _salesMangerOptions;
          _salesMangerOptions = salesMangerOptions.map((d, index) => (
            {
              text: d.FirstName + ' ' + d.LastName,
              value: d.id,
            }
          ));
          setSalesMangerOptionsData(_salesMangerOptions);
        }
      }
    }
  }, [salesMangerOptions]);

  useEffect(() => {
    if (usersRequestStatus === fetchStatus.IDLE) {
      _loadUsers(0, 0, 1000, whereClause);
    }
  }, [usersRequestStatus, dispatch]);

  useEffect(() => {
    if (usersRequestStatus === fetchStatus.SUCCEEDED) {
      if (allUsers) {
        if (isObjectArray(allUsers)) {
          let _usersOptions;
          _usersOptions = allUsers.map((d, index) => (
            {
              text: d.FirstName + ' ' + d.LastName,
              value: d.id,
            }
          ));
          setUsersOptions(_usersOptions);
        }
      }
    }
  }, [allUsers]);

  let _userOptions = undefined;

  if (isObjectArray(allUsers)) {
    _userOptions = allUsers.map((o) => (
      <option value={o.id}>{o.FirstName + ' ' + o.LastName}</option>
    ));
  }

  const _getUserText = (options, id) => {
    if (isObjectArray(options)) {
      let userResult = options.find(u => u.id === id);
      if (userResult && userResult.FirstName) {
        return userResult.FirstName + ' ' + userResult.LastName;
      }
    }
    return "";
  }

  useEffect(() => {
    if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
      dispatch(getLocationOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLOCATIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({})
        }
      }));
    }
  }, [locationOptionsStatus, locationOptionsRefreshed]);

  let _locationOptions = undefined;

  if (isObjectArray(locationOptions)) {
    _locationOptions = locationOptions.map((o) => (
      <option value={o.value}>{o.text}</option>
    ));
  }

  useEffect(() => {
    if (lead) {

      if (lead && isObjectArray(lead.StatusLogs)) {

        let logs = [...lead.StatusLogs];
        setLogCards(logs.reverse());
      }
      else {
        setLogCards(undefined);
      }

      setIsAgency(lead.IsAgency && lead.IsAgency.Data === "Y");
      setHaveOnSite(lead.IsHaveOnSite && lead.IsHaveOnSite.Data === "Y");
      setHaveOnSite2(lead.IsHaveOnSite2 && lead.IsHaveOnSite2.Data === "Y");
      setHaveOnSite3(lead.IsHaveOnSite3 && lead.IsHaveOnSite3.Data === "Y");

      if (lead.Status) {
        setSelectedStatus(lead.Status.Data);
      }
    }
    else {
      setLogCards(undefined);
    }
  }, [lead]);



  const _getShiftText = (idList) => {
    if (isObjectArray(idList)) {
      let shifts = "";
      for (let i = 0; i < idList.length; i++) {
        let shift = shiftsData.find(s => s.value === idList[i]);
        if (shift && shift.text) {
          shifts += shift.text + ", ";
        }
      }
      shifts = shifts.trim();
      shifts = shifts.slice(0, -1);
      return shifts;
    }
    return "";
  }

  const customValidation = () => {
    if (lead && lead.GoogleAddressDetail && lead.GoogleAddressDetail.place_id) {
      if (lead.GoogleAddressDetail.place_id == "") {
        setGoogleAddressValidate(true);
        return false;
      } else {
        setGoogleAddressValidate(false);
        return true;
      }
    } else {
      setGoogleAddressValidate(true);
      return false;
    }
  }

  const onSaveClicked = (e, id) => {
    e.preventDefault();
    if (customValidation() && isFormValid(LEAD_FORM)) {
      if (lead) {
        if (createMode) {
          dispatch(createLead({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVELEAD",
            payload: {
              id: id,
              data: JSON.stringify({
                lead: JSON.stringify(lead),
                // photo: {
                //   contentType: "image/jpg",
                //   length: 0,
                //   photoData: base64Image
                // }
              })
            }
          }));
        }
        else {
          dispatch(updateLead({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVELEAD",
            payload: {
              id: id,
              data: JSON.stringify({
                lead: JSON.stringify(lead),
                // photo: {
                //   contentType: "image/jpg",
                //   length: 0,
                //   photoData: base64Image
                // }
              })
            }
          }));
        }
      }
      setCreatemode(false);
      setEditmode(false);
      setLFInvalid(false);
      setLFSubmitted(false);
    }
    else {
      setLFInvalid(true);
    }
    setLFSubmitted(true);
    setActiveAddBtn(true);
  }

  const onValueUpdated = (e, section, value) => {
    let _leadObj = Object.assign({}, lead);
    let _newLeadObj = onValueChanged(_leadObj, e, section, value);
    dispatch(updateSingleData({
      lead: _newLeadObj,
      operation: Operation.UPDATED
    }));
  };

  const _getDuplicateLead = (place_id) => {
    dispatch(getLeadByAddress({
      // page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLEADBYADDRESS",
        payload: {
          id: place_id,
          // data: place_id
        }
      }
    }));
  }

  const onGoogleAddressUpdated = (value) => {
    setGoogleAddressValidate(false);
    _getDuplicateLead(value.place_id);
    AddressParser.Parse_Reverse_GeoCode_Address(value.address_components, function (err, ParsedData) {
      // console.log('ParsedData--->', ParsedData)
      if (err) console.error(err);


      //let Locations = document.getElementById("drpLocations").value;


      let _leadObj = Object.assign({}, lead);

      _leadObj.Location = {
        "Id": "drpLocations",
        "Data": document.getElementById("drpLocations").value
      }
      _leadObj.LeadType = {
        "Id": "drpLeadType",
        "Data": document.getElementById("drpLeadType").value
      }
      _leadObj.LeadName = {
        "Id": "txtLeadName",
        "Data": document.getElementById("txtLeadName").value
      }
      _leadObj.LeadPhone = {
        "Id": "txtLeadPhone",
        "Data": document.getElementById("txtLeadPhone").value
      }
      _leadObj.FaxNumber = {
        "Id": "txtLeadPhone",
        "Data": document.getElementById("txtFaxNumber").value
      }

      let _newLeadObj = onGoogleDetails(_leadObj, value, ParsedData);

      dispatch(updateSingleData({
        lead: _newLeadObj,
        operation: Operation.UPDATED
      }));

    });
  };

  const onLogSearch = (e) => {
    let text = e.target.value;
    var items = lead.StatusLogs.filter((d) => {
      return d.Content.toLowerCase().includes(text.toLowerCase()) || d.LogType.toLowerCase().includes(text.toLowerCase()) || d.UserName.toLowerCase().includes(text.toLowerCase())
    });
    if (!sort) {
      items = items.reverse();
    }
    setLogCards(items);
  }

  const onSort = (e) => {
    setSort(!sort);
    const sortData = [...logCards].reverse();
    setLogCards(sortData);
  }

  const onAddContactClicked = (e) => {
    e.preventDefault();
    dispatch(createContactDetail());
    setActiveAddBtn(false);
  }

  const onDeleteContactClicked = (e, id) => {
    e.preventDefault();
    // dispatch(deleteContactDetail(id));

    if (id) {
      dispatch(show({
        id: id,
        title: <><span>Delete Contact </span> <span className="modal-header-name"></span></>,
        content: "Are you sure, you want to delete contact?",
        confirmedCallback: _deleteContact
      }));
    }

  }

  const _deleteContact = (id) => {
    if (id) {
      const user = accountService.getAuthenticatedUser();

      dispatch(deleteContactDetail(id));
      const _lead = { ...lead }
      _lead.Contacts = _lead.Contacts.filter(d => d.id !== id);

      const deletedContact = lead.Contacts.filter(d => d.id === id);

      if (deletedContact[0]['FullName']['Data'] !== "") {
        let deletedContactNote = '';
        deletedContactNote += "Full Name : " + deletedContact[0]['FullName']['Data'] + " \n";
        deletedContactNote += "Position : " + deletedContact[0]['Position']['Data'] + " \n";
        deletedContactNote += "Contact Number : " + deletedContact[0]['ContactNumber']['Data'] + " \n";
        deletedContactNote += "Email : " + deletedContact[0]['Email']['Data'];

        const statusDetails = {
          log: {
            TimeStamp: (new Date()).toUTCString(),
            LogType: "Deleted Contact",
            LogValue: -10,
            Content: deletedContactNote,
            UserName: (user ? user.firstName + " " + user.lastName : ""),
            UserId: (user ? user.id : "")
          }
        };
        // let _leadObj = Object.assign({}, lead);
        let _newLeadObj = onDeleteContact(_lead, statusDetails);

        dispatch(updateLead({
          client: tenantId,
          serviceType: "TENANTADMIN",
          operation: "SAVELEAD",
          payload: {
            id: lead.id,
            data: JSON.stringify({
              lead: JSON.stringify(_newLeadObj),
              // photo: {
              //   contentType: "image/jpg",
              //   length: 0,
              //   photoData: base64Image
              // }
            })
          }
        }));
      }
    }
    setActiveAddBtn(true);
  };


  const data = extend([], appointments, null, true);

  const onActionComplete = (args) => {
    // return
    if (args.requestType === 'eventCreated') {
      // console.log('args.addedRecords', args.addedRecords[0])
      const appointmentDetails = {
        leadId: lead ? lead.id : "",
        leadName: lead && lead.LeadName ? lead.LeadName.Data : "",
        calendarData: args.addedRecords[0],
        locationId: lead ? lead.Location.Data : ""
      };
      if (appointmentDetails.calendarData && appointmentDetails.calendarData.IsAllDay) {
        delete appointmentDetails.calendarData.IsAllDay;
        appointmentDetails.calendarData.Status = "Open";
        const date = new Date(appointmentDetails.calendarData.StartTime).toLocaleDateString();
        appointmentDetails.calendarData.StartTime = new Date(`${date} 00:00:00`);
        appointmentDetails.calendarData.EndTime = new Date(`${date} 23:59:59`);
        appointmentDetails.calendarData.Description = "";
        appointmentDetails.calendarData.MeetingWith = "";
        appointmentDetails.calendarData.Feedback = "";
        appointmentDetails.calendarData.StartTimezone = null;
        appointmentDetails.calendarData.EndTimezone = null;
      }
      // console.log(appointmentDetails);
      // return;
      dispatch(createAppointment({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "SAVEAPPOINTMENT",
        payload: {
          id: "",
          data: JSON.stringify({
            appointment: JSON.stringify(appointmentDetails)
          })
        }
      }));
    }
    if (args.requestType === 'eventChanged') {
      // args.changedRecords
      // console.log('args.changedRecords', args.changedRecords)
      const appointmentId = args.changedRecords[0]["appointmentId"];
      const appointmentDetails = {
        leadId: lead ? lead.id : "",
        leadName: lead && lead.LeadName ? lead.LeadName.Data : "",
        calendarData: args.changedRecords[0],
        locationId: lead ? lead.Location.Data : ""
      };

      delete appointmentDetails.calendarData.appointmentId;
      // console.log('appointmentId', appointmentId)
      // console.log('appointmentDetails', appointmentDetails)
      dispatch(createAppointment({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "SAVEAPPOINTMENT",
        payload: {
          id: appointmentId,
          data: JSON.stringify({
            appointment: JSON.stringify(appointmentDetails)
          })
        }
      }));
    }
    // if (args.requestType === 'eventRemoved') {
    // args.deletedRecords
    // }
    if (args.requestType === 'eventRemoved') {
      const appointmentId = args.deletedRecords[0]["appointmentId"];
      dispatch(removeAppointment({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "REMOVEAPPOINTMENT",
        payload: {
          id: appointmentId,
          data: ''
        }
      }));
    }
  }

  const editorTemplate = (props) => {

    const handleStartTimeChange = (args) => {
      const startTime = args.value;
      const endTimePicker = document.getElementById("EndTime").ej2_instances[0];
      const endTime = endTimePicker.value;

      if (startTime && endTime && startTime.getTime() >= endTime.getTime()) {
        endTimePicker.value = new Date(startTime.getTime() + 30 * 60 * 1000); // Add 30 minutes
      }
    }

    const handleEndTimeChange = (args) => {
      const endTime = args.value;
      const endTimePicker = document.getElementById("EndTime").ej2_instances[0];
      const startTimePicker = document.getElementById("StartTime").ej2_instances[0];
      const startTime = startTimePicker.value;

      if (startTime && endTime && endTime.getTime() == startTime.getTime()) {
        endTimePicker.value = new Date(endTime.getTime() + 15 * 60 * 1000);
      } else if (startTime && endTime && endTime.getTime() < startTime.getTime()) {
        startTimePicker.value = new Date(endTime.getTime());
      }
    }
    const [title, setTitle] = useState(props.Subject || '');
    const [description, setDescription] = useState(props.Description || '');
    const [meetingWith, setMeetingWith] = useState(props.MeetingWith || '');

    return props !== undefined ? (
      <table
        className="custom-event-editor"
        style={{ width: "100%", cellpadding: "5" }}
      >
        <tbody>
          <tr>
            <td className="e-textlabel mb-2">Title</td>
            <td colSpan={4}>
              <input
                id="Summary"
                className="e-field e-input"
                type="text"
                name="Subject"
                style={{ width: '100%' }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel mb-2">Status</td>
            <td colSpan={4}>
              <DropDownListComponent
                id="Status"
                placeholder="-- Choose Status --"
                data-name="Status"
                className="e-field"
                style={{ width: "100%" }}
                dataSource={["Open", "Done", "Rejected"]}
                value={props.Status || null}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">From</td>
            <td colSpan={4}>
              <DateTimePickerComponent
                format="dd/MM/yy hh:mm a"
                id="StartTime"
                data-name="StartTime"
                value={new Date(props.startTime || props.StartTime)}
                className="e-field"
                change={handleStartTimeChange}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">To</td>
            <td colSpan={4}>
              <DateTimePickerComponent
                format="dd/MM/yy hh:mm a"
                id="EndTime"
                data-name="EndTime"
                value={new Date(props.endTime || props.EndTime)}
                className="e-field"
                change={handleEndTimeChange}
              />
            </td>
          </tr>

          <tr>
            <td className="e-textlabel">Description</td>
            <td colSpan={4}>
              <textarea
                id="Description"
                className="e-field e-input"
                name="Description"
                rows={3}
                cols={50}
                style={{
                  width: '100%',
                  height: '60px !important',
                  resize: 'vertical',
                }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel mb-2">Meeting with</td>
            <td colSpan={4}>
              <input
                id="MeetingWith"
                className="e-field e-input"
                type="text"
                name="MeetingWith"
                style={{ width: '100%' }}
                value={meetingWith}
                onChange={(e) => setMeetingWith(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Feedback</td>
            <td colSpan={4}>
              <DropDownListComponent
                id="Feedback"
                placeholder="-- Choose Feedback --"
                data-name="Feedback"
                className="e-field"
                style={{ width: "100%" }}
                dataSource={["Positive", "Negative", "Rescheduled"]}
                value={props.Feedback || null}
              />
            </td>
          </tr>
        </tbody>
      </table>
    ) : (
      <div />
    );
  }
  const onActionBegin = (args) => {
    if (
      (args.requestType === "eventCreate" || args.requestType === "eventChange") &&
      args.data &&
      args.data.StartTime >= args.data.EndTime
    ) {
      // Cancel the action and show an error message
      args.cancel = true;
      alert("End time must be greater than start time.");
    }
  }

  useEffect(() => {
    if (creationAppointmentStatus === fetchStatus.SUCCEEDED) {
      if (lead && lead.id) {
        _loadLead(lead.id, undefined);
      }
    }
  }, [creationAppointmentStatus]);

  useEffect(() => {
    if (removalAppointmentStatus === fetchStatus.SUCCEEDED) {
      if (lead && lead.id) {
        _loadLead(lead.id, undefined);
      }
    }
    if (removalAppointmentStatus === fetchStatus.FAILED) {
      _loadLead(lead.id, undefined);
    }
  }, [removalAppointmentStatus]);

  const _loadLead = async (id, page) => {
    await dispatch(getLeadById({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLEADBYID",
        payload: {
          id: id,
          data: ""
        }
      }
    }));

    await dispatch(getAppointmentsById({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETAPPOINTMENTSBYLEADID",
        payload: {
          id: id,
          data: ""
        }
      }
    }));
  }

  function onEventRendered(args) {
    // applyCategoryColor(args, scheduleObj.currentView);
    if (args.data.Status == "") {
      args.element.style.backgroundColor = "#357cd2";
    } else if (args.data.Status == "Open") {
      args.element.style.backgroundColor = "#008000";
    } else if (args.data.Status == "Rejected" && args.data.Feedback == "Positive") {
      args.element.style.backgroundColor = "#A9A9A9";
    } else if (args.data.Status == "Rejected" && args.data.Feedback == "Negative") {
      args.element.style.backgroundColor = "#909090";
    } else if (args.data.Status == "Rejected" && args.data.Feedback == "Rescheduled") {
      args.element.style.backgroundColor = "#a0ab2d";
    } else if (args.data.Status == "Rejected") {
      args.element.style.backgroundColor = "#A0A0A0";
    } else if (args.data.Status == "Done" && args.data.Feedback == "Positive") {
      args.element.style.backgroundColor = " rgb(26, 170, 85)";
    } else if (args.data.Status == "Done" && args.data.Feedback == "Negative") {
      args.element.style.backgroundColor = "#FF6666";
    } else if (args.data.Status == "Done" && args.data.Feedback == "Rescheduled") {
      args.element.style.backgroundColor = "#c6d33e";
    } else if (args.data.Status == "Done") {
      args.element.style.backgroundColor = "#1ABC9C";
    } else {
      return
    }
  }
  const tooltipTemplate = (props) => {
    return (<div className="tooltip-wrap">
      <div className="content-area">
        <table>
          <tr>
            <td>Subject</td>
            <td>: {props.Subject}</td>
          </tr>
          <br />
          <tr>
            <td>From</td>
            <td>: {props.StartTime.toLocaleString()}</td>
          </tr>
          <tr>
            <td>To</td>
            <td>: {props.EndTime.toLocaleString()}</td>
          </tr>
          <br />
          <tr>
            <td>Status</td>
            <td>: {props.Status}</td>
          </tr>
          <tr>
            <td>Feedback</td>
            <td>: {props.Feedback}</td>
          </tr>
        </table>
      </div>
    </div>);
  }

  const onCalledFlyoutClicked = (e, id) => {
    e.stopPropagation();
    dispatch(showLeadCalledFlyout({
      title: <><span></span></>,
      leadId: id,
    }));
  };

  const onNoteFlyoutClicked = (e, id) => {
    e.stopPropagation();
    dispatch(showLeadNoteFlyout({
      title: <><span></span></>,
      leadId: id,
    }));
  };


  const onStatusUpdated = (e, value, text) => {
    e.preventDefault();
    dispatch(showLSUModal({
      title: <><span>Add Reason For</span> <span className="modal-header-name">{lead ? lead.LeadName.Data : "N/A"}</span></>,
      id: lead.id,
      lead: lead,
      status: {
        value: value,
        text: text
      }
    }));
  };

  let _leadStatusesOptions = leadStatuses.map((o) => (
    <option value={o.value}>{o.text}</option>
  ));

  const leadRequestStatus = useSelector(selectSingleStatus);
  const appointmentsRequestStatus = useSelector(selectAppointmentsStatus);

  return (
    <>
      {(leadRequestStatus === fetchStatus.LOADING ||
        appointmentsRequestStatus === fetchStatus.LOADING ||
        creationAppointmentStatus === fetchStatus.LOADING ||
        removalAppointmentStatus === fetchStatus.LOADING
      ) && <div className="app-container-loader">
          <GridLoader />
        </div>}

      {/* main section */}
      <div className="px-3 pt-3">
        <div className="row">
          <div className="d-flex bd-highlight">
            <div className="bd-highlight">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Leads</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Details</li>
                </ol>
              </nav>
            </div>
            <div className="ms-auto">
              <Selector
                id="drpStatus"
                name="drpStatus"
                SelectorClasses="mx-2"
                options={
                  <>
                    {_leadStatusesOptions}
                  </>
                }
                value={selectedStatus}
                onChange={(e, value, text) => onStatusUpdated(e, e.target.value, e.target.value)}
              />
              <div className="c-icon-box" onClick={(e) => onNoteFlyoutClicked(e, lead ? lead.id : "")} data-tip='Notes'>
                <i className="fa-solid fa-note-sticky" ></i>
              </div>
              <div className="c-icon-box" onClick={(e) => onCalledFlyoutClicked(e, lead ? lead.id : "")} data-tip='Called In / Called Out'>
                <i className="fa-solid fa-phone" ></i>
              </div>

              {!editMode && !createMode ?
                <>
                  <div className='c-icon-box' onClick={() => setEditmode(!editMode)} data-tip='Edit'>
                    <i className='fa-solid fa-pencil'></i>
                  </div>
                </>
                :
                <>
                  <div className='c-icon-box' onClick={(e) => onSaveClicked(e, lead ? lead.id : "")} data-tip='Save'>
                    <i className='fa fa-solid fa-floppy-disk'></i>
                  </div>
                  <div className='c-icon-box' onClick={() => setEditmode(!editMode)} data-tip='Cancel saving'>
                    <i className='fa fa-times'></i>
                  </div>
                  <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                </>
              }
              <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
            </div>


          </div>
        </div>
      </div>

      <div className="px-3">
        <div className="row">
          <div className="col-md-12">

            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button className={`nav-link nav-link-people ${selectedTab === 0 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 0)}>Status Logs</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link nav-link-people ${selectedTab === 1 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 1)}>Lead Details</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link nav-link-people ${selectedTab === 4 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 4)}>Other Details</button>
              </li>
              {lead && lead.id &&
                <li className="nav-item">
                  <button className={`nav-link nav-link-people ${selectedTab === 2 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 2)}>Contacts</button>
                </li>
              }
              {lead && lead.id &&
                <li className="nav-item">
                  <button className={`nav-link nav-link-people ${selectedTab === 3 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 3)}>Appointments</button>
                </li>
              }
            </ul>

            <div className="tab-content" id="myTabContent">
              <div className={`tab-pane fade ${selectedTab === 0 && "show active"}`} >
                <div className="card a-card-wrap">

                  <div className="pt-3">
                    <div className="separator">
                      <div className="line line-start"></div>
                      <input type="text" className="separator-input" placeholder="Search"
                        onChange={(e) => onLogSearch(e)}
                      />
                      <SortButton
                        SortButtonClasses="mx-2"
                        sorted={sort}
                        onSortClicked={(e) => onSort(e)}
                      />
                      <div className="line"></div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="col-md-9 col-lg-9">
                      <div className="tracking-list pt-3 ps-3">
                        <LogCardLead statusLogs={logCards} />
                      </div>
                    </div>

                    <div className="col-md-3 col-lg-3  end-r-3 p-3 ">
                      <div className=" a-card">
                        <div className="d-flex">
                          <span className="text-primary">Documents</span>
                          <div className="ms-auto">
                            <div className="close-btn">
                              {/* <i className="fa fa-cloud-download" aria-hidden="true"></i> */}
                            </div>
                          </div>
                        </div>
                        <LeadDocumentsCard documents={lead && lead.Documents ? lead.Documents : ""} lead={lead ? lead : ""} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className={`tab-pane fade ${selectedTab === 1 && "show active"}`} >
                <div className="card a-card-wrap">

                  {editMode || createMode ?
                    <div className="row m-0 px-3">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label id="lblAddress2" className="field-label" htmlFor="txtAddress2">Address</label>
                          <InputGroup
                            icon={<i className="fa fa-map-marker"></i>}
                            control={
                              <React.Fragment>
                                <Autocomplete
                                  apiKey={'AIzaSyAakzSaZd7t-EBQkMQOu5U3QzhHV4yr1CY'}
                                  className="form-control"
                                  onPlaceSelected={(place, inputRef, autocomplete, e) => {
                                    onGoogleAddressUpdated(place);
                                  }
                                  }
                                  options={{
                                    types: [],
                                  }}
                                  id="txtAddress2"
                                  name="txtAddress2"
                                  form={LEAD_FORM}
                                // value={lead && lead.Address ? lead.Address.Data : ""}
                                />
                                {googleAddressValidate &&
                                  <span className="error-label">
                                    <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                    {'This field is required'}
                                  </span>
                                }
                                {duplicateLeadAddress &&
                                  <span className="error-label">
                                    <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                    {duplicateLeadAddress}
                                  </span>
                                }
                              </React.Fragment>
                            } />

                        </div>
                      </div>
                    </div>
                    : ''
                  }

                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="drpLocations">Location</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.Location ? _getLocationText(locationOptions, lead.Location.Data) : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-map-marker"></i>}
                            control={
                              <Selector
                                id="drpLocations"
                                name="drpLocations"
                                SelectorClasses="form-select"
                                // value={lead ? lead.Location.Data : ""}
                                // value={createMode ? (user ? user.locationId : "") : lead ? lead.Location.Data : "" }
                                value={editMode ? (lead ? lead.Location.Data : "") : (user ? user.locationId : "")}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                options={
                                  <>
                                    {_locationOptions}
                                  </>
                                }
                                //disabled={!isAdmin}
                                required={leadForm.Location.Required ? leadForm.Location : true}
                                errorMessage={leadForm.Location.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />
                        }
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblLeadType" className="field-label" htmlFor="drpLeadType">Lead Type</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.LeadType && lead.LeadType.Data}</label> :
                          <InputGroup
                            icon={<i className="fa fa-stack-exchange"></i>}
                            control={
                              <Selector
                                id="drpLeadType"
                                name="drpLeadType"
                                SelectorClasses="form-select"
                                value={lead ? lead.LeadType.Data : ""}
                                // required={leadForm.LeadType.Required}
                                // errorMessage={leadForm.LeadType.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                                options={
                                  <>
                                    <option value="Cold">Cold</option>
                                    <option value="Warm">Warm</option>
                                    <option value="Hot">Hot</option>
                                  </>
                                } onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                              />
                            }
                          />}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblDate" className="field-label" htmlFor="txtDate">Date</label>
                        <label className="field-label-value">{lead && lead.LeadDate ? toLocalDate(lead.LeadDate) : toLocalDate(new Date)}</label>
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtLeadName">Company Name</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.LeadName ? lead.LeadName.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-address-card"></i>}
                            control={
                              <ShortText
                                id="txtLeadName"
                                name="txtLeadName"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.LeadName ? lead.LeadName.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                required={leadForm.LeadName.Required}
                                errorMessage={leadForm.LeadName.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />
                        }
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtLeadPhone">Company Phone Number</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.LeadPhone ? lead.LeadPhone.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-phone"></i>}
                            control={
                              <PhoneText
                                id="txtLeadPhone"
                                name="txtLeadPhone"
                                PhoneTextClasses="form-control"
                                value={lead ? lead.LeadPhone.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                required={leadForm.LeadPhone.Required}
                                errorMessage={leadForm.LeadPhone.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                              />
                            } />
                        }
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtFaxNumber">Company Fax Number</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.FaxNumber ? lead.FaxNumber.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-fax"></i>}
                            control={
                              <PhoneText
                                id="txtFaxNumber"
                                name="txtFaxNumber"
                                PhoneTextClasses="form-control"
                                value={lead && lead.FaxNumber ? lead.FaxNumber.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm.FaxNumber.Required}
                                // errorMessage={leadForm.FaxNumber.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                              />
                            } />
                        }
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">

                    <div className="col-md-8">
                      <div className="form-group">
                        <label id="lblAddress" className="field-label" htmlFor="txtAddress">Street Address</label>
                        <label className="field-label-value">{lead && lead.Address ? lead.Address.Data : ""}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtCity">City</label>
                        <label className="field-label-value">{lead && lead.City ? lead.City.Data : ""}</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtState">State</label>
                        <label className="field-label-value">{lead && lead.State ? lead.State.Data : ""}</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtZipCode">Zipcode</label>
                        <label className="field-label-value">{lead && lead.ZipCode ? lead.ZipCode.Data : ""}</label>
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtLeadEmail">Company Email</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.LeadEmail ? lead.LeadEmail.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-envelope"></i>}
                            control={
                              <ShortText
                                id="txtLeadEmail"
                                name="txtLeadEmail"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.LeadEmail ? lead.LeadEmail.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.LeadEmail.Required}
                                // errorMessage={leadForm && leadForm.LeadEmail.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtWebsite">Website</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.Website ? lead.Website.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-globe"></i>}
                            control={
                              <ShortText
                                id="txtWebsite"
                                name="txtWebsite"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.Website ? lead.Website.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.Website.Required}
                                // errorMessage={leadForm && leadForm.Website.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />}
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtAssignedTo">Assigned To</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.AssignedTo ? _getUserText(allUsers, lead.AssignedTo.Data) : ""}</label> :
                          <div className="e-input-group">
                            <div className="e-input-in-wrap">
                              <SelectorSearch
                                id="txtAssignedTo"
                                name="txtAssignedTo"
                                // required={leadForm && leadForm.AssignedTo.Required}
                                // errorMessage={leadForm && leadForm.AssignedTo.Validation}
                                value={lead && lead.AssignedTo ? lead.AssignedTo.Data : ""}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                                options={
                                  salesRepOptionsData
                                }
                                onChange={(e, v) => onValueUpdated(e, sections.LEAD_DETAILS, v)}
                              />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtAssignedTo2">Assigned To 2</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.AssignedTo2 ? _getUserText(allUsers, lead.AssignedTo2.Data) : ""}</label> :
                          <div className="e-input-group">
                            <div className="e-input-in-wrap">
                              <SelectorSearch
                                id="txtAssignedTo2"
                                name="txtAssignedTo2"
                                // required={leadForm && leadForm.AssignedTo2.Required}
                                // errorMessage={leadForm && leadForm.AssignedTo2.Validation}
                                value={lead && lead.AssignedTo2 ? lead.AssignedTo2.Data : ""}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                                options={
                                  salesRepOptionsData
                                }
                                onChange={(e, v) => onValueUpdated(e, sections.LEAD_DETAILS, v)}
                              />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblSalesManger" className="field-label" htmlFor="txtSalesManger">Sales Manger</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.SalesManger ? _getUserText(allUsers, lead.SalesManger.Data) : ""}</label> :
                          <div className="e-input-group">
                            <div className="e-input-in-wrap">
                              <SelectorSearch
                                id="txtSalesManger"
                                name="txtSalesManger"
                                // required={leadForm && leadForm.SalesManger.Required}
                                // errorMessage={leadForm && leadForm.SalesManger.Validation}
                                value={lead && lead.SalesManger ? lead.SalesManger.Data : ""}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                                options={
                                  salesMangerOptionsData
                                }
                                onChange={(e, v) => onValueUpdated(e, sections.LEAD_DETAILS, v)}
                              />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblSupervisor1" className="field-label" htmlFor="txtSupervisor">Contact Name</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.Supervisor ? lead.Supervisor.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-user-circle"></i>}
                            control={
                              <ShortText
                                id="txtSupervisor"
                                name="txtSupervisor"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead ? lead.Supervisor.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                required={leadForm.Supervisor.Required}
                                errorMessage={leadForm.Supervisor.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />
                        }
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtLeadTelePhone">Contact Number</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.LeadTelePhone ? lead.LeadTelePhone.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-phone"></i>}
                            control={
                              <PhoneText
                                id="txtLeadTelePhone"
                                name="txtLeadTelePhone"
                                PhoneTextClasses="form-control"
                                value={lead ? lead.LeadTelePhone.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                required={leadForm.LeadTelePhone.Required}
                                errorMessage={leadForm.LeadTelePhone.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                              />
                            } />
                        }
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblPosition" className="field-label" htmlFor="drpPosition">Contact Position</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.Position ? lead.Position.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-id-badge"></i>}
                            control={
                              <ShortText
                                id="drpPosition"
                                name="drpPosition"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead ? lead.Position.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                required={leadForm.Position.Required}
                                errorMessage={leadForm.Position.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />}
                      </div>
                    </div>
                  </div>


                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtNumberOfEmployees">Number of Employees</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.NumberOfEmployees ? lead.NumberOfEmployees.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-users"></i>}
                            control={
                              <NumberText
                                min="1"
                                step="1"
                                max="10000000"
                                id="txtNumberOfEmployees"
                                name="txtNumberOfEmployees"
                                NumberTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.NumberOfEmployees ? lead.NumberOfEmployees.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.NumberOfEmployees.Required}
                                // errorMessage={leadForm && leadForm.NumberOfEmployees.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                              />
                            } />}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblIndustryType" className="field-label" htmlFor="drpIndustryType">Industry Type</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.IndustryType && lead.IndustryType.Data}</label> :
                          <InputGroup
                            icon={<i className="fa fa-industry"></i>}
                            control={
                              <Selector
                                id="drpIndustryType"
                                name="drpIndustryType"
                                SelectorClasses="form-select"
                                value={lead && lead.IndustryType ? lead.IndustryType.Data : ""}
                                // required={leadForm.IndustryType.Required}
                                // errorMessage={leadForm.IndustryType.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                                options={
                                  <>
                                    <option value="Admin">Admin</option>
                                    <option value="Manufacturing">Manufacturing</option>
                                    <option value="Warehouse">Warehouse</option>
                                  </>
                                } onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                              />
                            }
                          />}
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblSourceType" className="field-label" htmlFor="drpSourceType">Source Type</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.SourceType && lead.SourceType.Data}</label> :
                          <InputGroup
                            icon={<i className="fa fa-id-badge"></i>}
                            control={
                              <Selector
                                id="drpSourceType"
                                name="drpSourceType"
                                SelectorClasses="form-select"
                                value={lead && lead.SourceType ? lead.SourceType.Data : ""}
                                // required={leadForm.SourceType.Required}
                                // errorMessage={leadForm.SourceType.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                                options={
                                  <>
                                    <option value="Affiliate">Affiliate</option>
                                    <option value="Blogging">Blogging</option>
                                    <option value="Cold Call">Cold Call</option>
                                    <option value="Digital Advertising">Digital Advertising</option>
                                    <option value="Direct Marketing">Direct Marketing</option>
                                    <option value="Email Marketing">Email Marketing</option>
                                    <option value="Events">Events</option>
                                    <option value="Media Coverage">Media Coverage</option>
                                    <option value="Organic Search">Organic Search</option>
                                    <option value="Outbound Sales">Outbound Sales</option>
                                    <option value="Premium Content">Premium Content</option>
                                    <option value="Referrals">Referrals</option>
                                    <option value="Social Media">Social Media</option>
                                    <option value="Speaking Engagements">Speaking Engagements</option>
                                    <option value="Sponsorships">Sponsorships</option>
                                    <option value="Traditional Advertising">Traditional Advertising</option>
                                    <option value="Offline Networking">Offline Networking</option>
                                    <option value="Website">Website</option>
                                    <option value="Other">Other</option>
                                  </>
                                } onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                              />
                            }
                          />}
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtReference">Source Type Details</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.Reference ? lead.Reference.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-child"></i>}
                            control={
                              <ShortText
                                id="txtReference"
                                name="txtReference"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.Reference ? lead.Reference.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.Reference.Required}
                                // errorMessage={leadForm && leadForm.Reference.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />}
                      </div>
                    </div>
                  </div>


                  {editMode || createMode ?
                    <div className="row m-0 p-3">
                      <div className="col-md-12">
                        <MindMapSwitch
                          id="rdbtnIsAgency"
                          title="Using Agency?"
                          checked={lead && lead.IsAgency && lead.IsAgency.Data === "Y" ? true : false}
                          onChange={(e, c) => onValueUpdated(e, sections.LEAD_DETAILS, c === true ? "Y" : "N")} />
                      </div>
                    </div>
                    : <></>}

                  {lead && lead.IsAgency && lead.IsAgency.Data === "Y" ?
                    <>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblAgency" className="field-label" htmlFor="txtAgency">Agency Name</label>
                            {!editMode && !createMode ?
                              <label className="field-label-value">{lead && lead.Agency ? lead.Agency.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-tree"></i>}
                                control={
                                  <ShortText
                                    id="txtAgency"
                                    name="txtAgency"
                                    ShortTextClasses="form-control"
                                    placeholder=""
                                    value={isAgency ? (lead && lead.Agency ? lead.Agency.Data : "") : ""}
                                    disabled={!isAgency}
                                    onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                    // required={leadForm && leadForm.Agency.Required}
                                    // errorMessage={leadForm && leadForm.Agency.Validation}
                                    form={LEAD_FORM}
                                    submited={lfSubmitted} />
                                } />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Do they have on-site?</label>
                            {!editMode && !createMode ?
                              <label className="field-label-value">{lead && lead.IsHaveOnSite ? (lead.IsHaveOnSite.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnIsHaveOnSite"
                                value={lead && lead.IsHaveOnSite ? lead.IsHaveOnSite.Data : "N"}
                                disabled={!isAgency}
                                onSelectionChanged={(e, v) => { setHaveOnSite(v == "Y"); onValueUpdated(e, sections.LEAD_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblMarkUpRate" className="field-label" htmlFor="txtMarkUpRate">Mark Up Rate</label>
                            {!editMode && !createMode ?
                              <label className="field-label-value">{lead && lead.MarkUpRate ? lead.MarkUpRate.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-star-half-o"></i>}
                                control={
                                  <ShortText
                                    id="txtMarkUpRate"
                                    name="txtMarkUpRate"
                                    ShortTextClasses="form-control"
                                    placeholder=""
                                    value={lead && lead.MarkUpRate ? lead.MarkUpRate.Data : ""}
                                    disabled={!isAgency}
                                    onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                    // required={leadForm && leadForm.MarkUpRate.Required}
                                    // errorMessage={leadForm && leadForm.MarkUpRate.Validation}
                                    form={LEAD_FORM}
                                    submited={lfSubmitted} />
                                } />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblAgency2" className="field-label" htmlFor="txtAgency2">Agency Name</label>
                            {!editMode && !createMode ?
                              <label className="field-label-value">{lead && lead.Agency2 ? lead.Agency2.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-tree"></i>}
                                control={
                                  <ShortText
                                    id="txtAgency2"
                                    name="txtAgency2"
                                    ShortTextClasses="form-control"
                                    placeholder=""
                                    value={isAgency ? (lead && lead.Agency2 ? lead.Agency2.Data : "") : ""}
                                    disabled={!isAgency}
                                    onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                    // required={leadForm && leadForm.Agency.Required}
                                    // errorMessage={leadForm && leadForm.Agency.Validation}
                                    form={LEAD_FORM}
                                    submited={lfSubmitted} />
                                } />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Do they have on-site?</label>
                            {!editMode && !createMode ?
                              <label className="field-label-value">{lead && lead.IsHaveOnSite2 ? (lead.IsHaveOnSite2.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnIsHaveOnSite2"
                                value={lead && lead.IsHaveOnSite2 ? lead.IsHaveOnSite2.Data : "N"}
                                disabled={!isAgency}
                                onSelectionChanged={(e, v) => { setHaveOnSite2(v == "Y"); onValueUpdated(e, sections.LEAD_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblMarkUpRate2" className="field-label" htmlFor="txtMarkUpRate2">Mark Up Rate</label>
                            {!editMode && !createMode ?
                              <label className="field-label-value">{lead && lead.MarkUpRate2 ? lead.MarkUpRate2.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-star-half-o"></i>}
                                control={
                                  <ShortText
                                    id="txtMarkUpRate2"
                                    name="txtMarkUpRate2"
                                    ShortTextClasses="form-control"
                                    placeholder=""
                                    value={lead && lead.MarkUpRate2 ? lead.MarkUpRate2.Data : ""}
                                    disabled={!isAgency}
                                    onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                    // required={leadForm && leadForm.MarkUpRate2.Required}
                                    // errorMessage={leadForm && leadForm.MarkUpRate2.Validation}
                                    form={LEAD_FORM}
                                    submited={lfSubmitted} />
                                } />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblAgency3" className="field-label" htmlFor="txtAgency3">Agency Name</label>
                            {!editMode && !createMode ?
                              <label className="field-label-value">{lead && lead.Agency3 ? lead.Agency3.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-tree"></i>}
                                control={
                                  <ShortText
                                    id="txtAgency3"
                                    name="txtAgency3"
                                    ShortTextClasses="form-control"
                                    placeholder=""
                                    value={isAgency ? (lead && lead.Agency3 ? lead.Agency3.Data : "") : ""}
                                    disabled={!isAgency}
                                    onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                    // required={leadForm && leadForm.Agency.Required}
                                    // errorMessage={leadForm && leadForm.Agency.Validation}
                                    form={LEAD_FORM}
                                    submited={lfSubmitted} />
                                } />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Do they have on-site?</label>
                            {!editMode && !createMode ?
                              <label className="field-label-value">{lead && lead.IsHaveOnSite3 ? (lead.IsHaveOnSite3.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnIsHaveOnSite3"
                                value={lead && lead.IsHaveOnSite3 ? lead.IsHaveOnSite3.Data : "N"}
                                disabled={!isAgency}
                                onSelectionChanged={(e, v) => { setHaveOnSite3(v == "Y"); onValueUpdated(e, sections.LEAD_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblMarkUpRate3" className="field-label" htmlFor="txtMarkUpRate3">Mark Up Rate</label>
                            {!editMode && !createMode ?
                              <label className="field-label-value">{lead && lead.MarkUpRate3 ? lead.MarkUpRate3.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-star-half-o"></i>}
                                control={
                                  <ShortText
                                    id="txtMarkUpRate3"
                                    name="txtMarkUpRate3"
                                    ShortTextClasses="form-control"
                                    placeholder=""
                                    value={lead && lead.MarkUpRate3 ? lead.MarkUpRate3.Data : ""}
                                    disabled={!isAgency}
                                    onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                    // required={leadForm && leadForm.MarkUpRate2.Required}
                                    // errorMessage={leadForm && leadForm.MarkUpRate2.Validation}
                                    form={LEAD_FORM}
                                    submited={lfSubmitted} />
                                } />}
                          </div>
                        </div>
                      </div>
                    </>
                    :
                    <></>
                  }

                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblDateSigned" className="field-label" htmlFor="txtDateSigned">Date Signed</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.DateSigned ? _getDateText(lead.DateSigned.Data) : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-calendar"></i>}
                            control={
                              <DateText
                                id="txtDateSigned"
                                name="txtDateSigned"
                                DateTextClasses={`form-control`}
                                // required={leadForm.DateSigned.Required}
                                // errorMessage={lead.DateSigned.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                                value={lead && lead.DateSigned && lead.DateSigned.Data}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                              />}
                          />}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblCommission" className="field-label" htmlFor="txtCommission">Commission</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.Commission ? lead.Commission.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-exclamation"></i>}
                            control={
                              <ShortText
                                id="txtCommission"
                                name="txtCommission"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.Commission ? lead.Commission.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.Commission.Required}
                                // errorMessage={leadForm && leadForm.Commission.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />}
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblConversionFee" className="field-label" htmlFor="txtConversionFee">Conversion Fee</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.ConversionFee ? lead.ConversionFee.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-exclamation"></i>}
                            control={
                              <ShortText
                                id="txtConversionFee"
                                name="txtConversionFee"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.ConversionFee ? lead.ConversionFee.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.ConversionFee.Required}
                                // errorMessage={leadForm && leadForm.ConversionFee.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblContributions" className="field-label" htmlFor="txtContributions">Contributions</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.Contributions ? lead.Contributions.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-exclamation"></i>}
                            control={
                              <ShortText
                                id="txtContributions"
                                name="txtContributions"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.Contributions ? lead.Contributions.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.Contributions.Required}
                                // errorMessage={leadForm && leadForm.Contributions.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />}
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblAnnualRevenue" className="field-label" htmlFor="txtAnnualRevenue">Annual Revenue</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.AnnualRevenue ? lead.AnnualRevenue.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-dollar"></i>}
                            control={
                              <NumberText
                                min="0.01"
                                step="0.01"
                                max="2500000000"
                                id="txtAnnualRevenue"
                                name="txtAnnualRevenue"
                                NumberTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.AnnualRevenue ? lead.AnnualRevenue.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && lead.AnnualRevenue.Required}
                                // errorMessage={lead && lead.AnnualRevenue.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                              />}
                          />}
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label id="lblAdditionalNote" className="field-label" htmlFor="txtAdditionalNote">Additional Note</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.AdditionalNote ? lead.AdditionalNote.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-question-circle"></i>}
                            control={
                              <Statement
                                // required={leadForm.AdditionalNote ? lead.AdditionalNote.Required : false}
                                // errorMessage={lead.AdditionalNote ? lead.AdditionalNote.Validation : "Please enter Note"}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                                id="txtAdditionalNote"
                                name="txtAdditionalNote"
                                StatementClasses="form-control"
                                value={lead && lead.AdditionalNote ? lead.AdditionalNote.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                              />}
                          />}
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className={`tab-pane fade ${selectedTab === 4 && "show active"}`} >
                <div className="card a-card-wrap">

                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtNoOfPeopleRequired">Required Number Of Associates</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.NoOfPeopleRequired ? lead.NoOfPeopleRequired.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-user-plus"></i>}
                            control={
                              <NumberText
                                min="1"
                                step="0.01"
                                max="25000000"
                                id="txtNoOfPeopleRequired"
                                name="txtNoOfPeopleRequired"
                                NumberTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.NoOfPeopleRequired ? lead.NoOfPeopleRequired.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.NoOfPeopleRequired.Required}
                                // errorMessage={leadForm && leadForm.NoOfPeopleRequired.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                              />
                            } />}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblShifts" className="field-label" htmlFor="drpShifts">Shift</label>

                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.Shift ? _getShiftText(lead.Shift.Data) : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-id-badge"></i>}
                            control={
                              <MultiSelector
                                id="drpShifts"
                                name="drpShifts"
                                value={lead && lead.Shift ? lead.Shift.Data : []}
                                options={shiftsData}
                                onChange={(e, v) => onValueUpdated(e, sections.LEAD_DETAILS, v)}
                                // required={lead.Shift.Required}
                                // errorMessage={lead.Shift.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />

                            } />}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblOfficeServicing" className="field-label" htmlFor="txtOfficeServicing">Office Servicing</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.OfficeServicing ? lead.OfficeServicing.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-exclamation"></i>}
                            control={
                              <ShortText
                                id="txtOfficeServicing"
                                name="txtOfficeServicing"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.OfficeServicing ? lead.OfficeServicing.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.OfficeServicing.Required}
                                // errorMessage={leadForm && leadForm.OfficeServicing.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />}
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblGeneralLaborPercentage" className="field-label" htmlFor="txtGeneralLaborPercentage">General Labor %</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.GeneralLaborPercentage ? lead.GeneralLaborPercentage.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-calculator"></i>}
                            control={
                              <ShortText
                                id="txtGeneralLaborPercentage"
                                name="txtGeneralLaborPercentage"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.GeneralLaborPercentage ? lead.GeneralLaborPercentage.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.GeneralLaborPercentage.Required}
                                // errorMessage={leadForm && leadForm.GeneralLaborPercentage.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblOverTimePercentage" className="field-label" htmlFor="txtOverTimePercentage">Over Time %</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.OverTimePercentage ? lead.OverTimePercentage.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-calculator"></i>}
                            control={
                              <ShortText
                                id="txtOverTimePercentage"
                                name="txtOverTimePercentage"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.OverTimePercentage ? lead.OverTimePercentage.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.OverTimePercentage.Required}
                                // errorMessage={leadForm && leadForm.OverTimePercentage.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblClericalPercentage" className="field-label" htmlFor="txtClericalPercentage">Clerical %</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.ClericalPercentage ? lead.ClericalPercentage.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-calculator"></i>}
                            control={
                              <ShortText
                                id="txtClericalPercentage"
                                name="txtClericalPercentage"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.ClericalPercentage ? lead.ClericalPercentage.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.ClericalPercentage.Required}
                                // errorMessage={leadForm && leadForm.ClericalPercentage.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />}
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label id="lblRolloverPercentage" className="field-label" htmlFor="txtRolloverPercentage">Rollover %</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.RolloverPercentage ? lead.RolloverPercentage.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-calculator"></i>}
                            control={
                              <ShortText
                                id="txtRolloverPercentage"
                                name="txtRolloverPercentage"
                                ShortTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.RolloverPercentage ? lead.RolloverPercentage.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.RolloverPercentage.Required}
                                // errorMessage={leadForm && leadForm.RolloverPercentage.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted} />
                            } />}
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label id="lblSickDays" className="field-label" htmlFor="txtSickDays">Sick Days</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.SickDays ? lead.SickDays.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-thermometer-empty"></i>}
                            control={
                              <NumberText
                                min="0.01"
                                step="0.01"
                                max="1000"
                                id="txtSickDays"
                                name="txtSickDays"
                                NumberTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.SickDays ? lead.SickDays.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                //required={leadForm && leadForm.SickDays.Required}
                                //errorMessage={leadForm && leadForm.SickDays.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                              />
                            } />}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="field-label">Billed Sick Days?</label>
                        {!editMode && !createMode ? <label className="field-label-value">{lead && lead.IsBilledSickDays ? (lead.IsBilledSickDays.Data === "Y" ? "Yes" : "No") : ""}</label> :
                          <YesNoRadio
                            name="rdbtnIsBilledSickDays"
                            value={lead && lead.IsBilledSickDays ? lead.IsBilledSickDays.Data : "N"} onSelectionChanged={(e, v) => { setIsBilledSickDays(v == "Y"); onValueUpdated(e, sections.LEAD_DETAILS, v) }} />
                        }
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label id="lblHolidays" className="field-label" htmlFor="txtHolidays">Holidays</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.Holidays ? lead.Holidays.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-umbrella"></i>}
                            control={
                              <NumberText
                                min="0.01"
                                step="0.01"
                                max="1000"
                                id="txtHolidays"
                                name="txtHolidays"
                                NumberTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.Holidays ? lead.Holidays.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.Holidays.Required}
                                // errorMessage={leadForm && leadForm.Holidays.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                              />
                            } />}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="field-label">Billed Holidays?</label>
                        {!editMode && !createMode ? <label className="field-label-value">{lead && lead.IsBilledHolidays ? (lead.IsBilledHolidays.Data === "Y" ? "Yes" : "No") : ""}</label> :
                          <YesNoRadio
                            name="rdbtnIsBilledHolidays"
                            value={lead && lead.IsBilledHolidays ? lead.IsBilledHolidays.Data : "N"} onSelectionChanged={(e, v) => { setIsBilledHolidays(v == "Y"); onValueUpdated(e, sections.LEAD_DETAILS, v) }} />
                        }
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label id="lblVacation" className="field-label" htmlFor="txtVacation">Vacation</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{lead && lead.Vacation ? lead.Vacation.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-tree"></i>}
                            control={
                              <NumberText
                                min="0.01"
                                step="0.01"
                                max="1000"
                                id="txtVacation"
                                name="txtVacation"
                                NumberTextClasses="form-control"
                                placeholder=""
                                value={lead && lead.Vacation ? lead.Vacation.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.LEAD_DETAILS, null)}
                                // required={leadForm && leadForm.Vacation.Required}
                                // errorMessage={leadForm && leadForm.Vacation.Validation}
                                form={LEAD_FORM}
                                submited={lfSubmitted}
                              />
                            } />}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="field-label">Billed Vacation?</label>
                        {!editMode && !createMode ? <label className="field-label-value">{lead && lead.IsBilledVacation ? (lead.IsBilledVacation.Data === "Y" ? "Yes" : "No") : ""}</label> :
                          <YesNoRadio
                            name="rdbtnIsBilledVacation"
                            value={lead && lead.IsBilledVacation ? lead.IsBilledVacation.Data : ""}
                            onSelectionChanged={(e, v) => { setIsBilledVacation(v == "Y"); onValueUpdated(e, sections.LEAD_DETAILS, v); }} />
                        }
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className={`tab-pane fade ${selectedTab === 2 && "show active"}`} >
                <div className="card a-card-wrap">

                  <div className="row m-0 px-3 pt-3">
                    <div className="col-md-11">
                      <h6 className='font-weight-bold'>Contact Details</h6><br />
                    </div>
                    <div className="col-md-1 d-flex justify-content-end align-items-center">
                      {activeAddBtn === true ?
                        <span
                          className="add-position-button"
                          title="Create new contact"
                          onClick={(e) => onAddContactClicked(e)}>
                          <i className="fa fa-plus-circle fa-3x"></i>
                        </span>
                        :
                        <span
                          className="add-position-button"
                          title="Save"
                          onClick={(e) => onSaveClicked(e, lead ? lead.id : "", createMode)}>
                          <i className="fa fa-solid fa-floppy-disk fa-2x"></i>
                        </span>
                      }
                    </div>
                  </div>
                  <div className="reporttable px-5">
                    <table className="table c-table table-hover">
                      <thead className="">
                        <tr>
                          <th scope="col">Full Name</th>
                          <th scope="col">Position</th>
                          <th scope="col">Contact Number</th>
                          <th scope="col">Extension</th>
                          <th scope="col">Email</th>
                          <th scope="col">Client Interest</th>
                          <th scope="col" style={{ width: '125px' }}>Decision Maker</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ContactDetail
                          positions={positionOptions}
                          form={LEAD_FORM}
                          submited={lfSubmitted}
                          leadForm={leadForm}
                          readonly={!editMode && !createMode}
                          details={lead && lead.Contacts}
                          onValueUpdated={(e, s, v) => onValueUpdated(e, s, v)}
                          onDeleteClicked={(e, id) => onDeleteContactClicked(e, id)}
                          activeAddBtn={activeAddBtn}
                        />
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

              <div className={`tab-pane fade ${selectedTab === 3 && "show active"}`} >
                <div className="card a-card-wrap">
                  <div className="p-3">
                    <ScheduleComponent
                      id='schedule'
                      height='700px'
                      currentView='Month'
                      cssClass='excel-export'
                      selectedDate={new Date()}
                      // eventSettings={{ dataSource: appointments, fields: fields }}
                      eventSettings={{ dataSource: data, enableTooltip: true, tooltipTemplate: tooltipTemplate.bind(this), allowDeleting: true }}
                      actionComplete={onActionComplete.bind(this)}
                      editorTemplate={editorTemplate.bind(this)}
                      // ref={t => scheduleObj = t}
                      ref={(t) => (scheduleObj = t)}
                      // actionBegin={onActionBegin.bind(this)}
                      // timezone='UTC'
                      eventRendered={onEventRendered.bind(this)}
                      // floatLabelType='Always'
                      actionBegin={onActionBegin.bind(this)}
                    >
                      <Inject services={[Day, Week, WorkWeek, Month, Year, Agenda, ExcelExport, Resize, Print]} />
                    </ScheduleComponent>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default LeadDetails;