export const applicantDetailsEN = {
    Welcome: "Welcome",
    Yes: "Yes",
    No: "No",
    FirstName: "First Name",
    MiddleName: "Middle Name",
    LastName: "Last Name",
    OtherLastName: "Other Last Name",
    Address: "Address",
    AddressPlaceHolder: "Flat Number, Building Name, Street Name",
    City: "City",
    State: "State",
    ZipCode: "Zip Code",
    PhoneNumber: "Phone Number",
    Email: "Email",
    DateOfBirth: "Date Of Birth",
    SSN: "Social Security Number",
    EmploymentType: "Employment Type",
    MaritalStatus: "Marital Status (Tax Filling Status)",
    Married: "Married",
    Unmarried: "Single",
    Head: "Head Of House",
    TotalDependent: "How many dependents will you claim?",
    DependentUnder17: "How many dependents are 17 years of age or older?",
    DependentAbove17: "How many dependents are under the age of 17?",
    TaxExempt: "Will you be filing taxes exempt?",
    ContactName: "Name",
    Relationship: "Relationship",
    ContactAddress: "Address",
    ContactPhone: "Phone",
    HighSchoolCompleted: "High School: Completed (circle one)",
    Diploma: "Diploma",
    GED: "G.E.D.",
    School: "School",
    CityState: "City/State",
    Other: "Others",
    OtherPlaceHolder: "Please provide other details",
    CurrentlyEmployed: "Currently Employed?",
    IsOtherEmploymentName: "Any other name for employment at any point in time?",
    OtherName: "Other Name",
    EverTerminated: "Ever been discharged or asked to resign from a job?",
    Explain: "Explain",
    ExplainPlaceHolder: "Please explain reason",
    Employer: "Employer",
    EmployerAddress: "Address",
    EmployerPhone: "Phone Number",
    EmployerTelePhone: "Supervisor Number",
    Position: "Position",
    Supervisor: "Supervisor",
    DOEFrom: "Date Of Employment From",
    DOETo: "Date Of Employment To",
    ReasonForLeaving: "Reason For Leaving",
    RefName: "Name",
    RefPhone: "Phone",
    Next: "Next",
    NextToolTip: "Click to go next",
    Back: "Back",
    BackToolTip: "Click to go back",
    ThankYouMessage: "Thank you for applying with us!",
    M1: "A representative will get back to you within 1 business day.",
    M2: "If you have any questions please feel free to call us.",
    HighSchoolCompletedError: "Please select high school",
    AlternateEmailAddress: "Alternate Email Address",
    AlternatePhoneNumber: "Alternate Phone Number",
    Shifts: "Shifts"
}

export const applicantDetailsSP = {
    Welcome: "Bienvenidos",
    Yes: "Sí",
    No: "No",
    FirstName: "Primer nombre",
    MiddleName: "Segundo nombre",
    LastName: "Apellido",
    OtherLastName: "Otro apellido",
    Address: "Domicilio",
    AddressPlaceHolder: "Número de piso, nombre del edificio, nombre de la calle",
    City: "Ciudad",
    State: "Estado",
    ZipCode: "Código postal",
    PhoneNumber: "Número de teléfono",
    Email: "Correo electrónico",
    DateOfBirth: "Fecha de nacimiento",
    SSN: "Número de seguridad social",
    EmploymentType: "Tipo de empleo",
    MaritalStatus: "Estado Civil (Estado de Llenado de Impuestos)",
    Married: "Casado",
    Unmarried: "Soltero",
    Head: "Cabeza de Familia",
    TaxExempt: "¿Estará exento de impuestos?",
    TotalDependent: "¿Cuántos dependientes reclamará?",
    DependentUnder17: "¿Cuántos dependientes tienen 17 años o más?",
    DependentAbove17: "¿Cuántos dependientes son menores de 17 años?",
    ContactName: "Nombre",
    Relationship: "Relación",
    ContactAddress: "Domicilio",
    ContactPhone: "Número de teléfono",
    HighSchoolCompleted: "Secundaria: Completado (circule uno)",
    School: "Escuela(s) / Colegio(s)",
    CityState: "Ciudad/Estado",
    Other: "Otro",
    OtherPlaceHolder: "Proporcione otros detalles",
    CurrentlyEmployed: "¿Actualmente empleado?",
    IsOtherEmploymentName: "A Usado Cualquier Otro Nombre Para Empleo?",
    OtherName: "Otro Nombre",
    EverTerminated: "Alguna Vez Ha Sido Despedido O Se Le Pidio Que Renunciara a Un Trabajo?",
    Explain: "Explicar",
    ExplainPlaceHolder: "Por favor explique el motivo",
    Employer: "Empleador",
    EmployerAddress: "Domicilio",
    EmployerPhone: "Número de teléfono",
    EmployerTelePhone: "Número de supervisor",
    Position: "Posición",
    Supervisor: "Supervisor",
    DOEFrom: "Fecha De Empleo Desde",
    DOETo: "Fecha De Empleo Hasta",
    ReasonForLeaving: "Razón Para Irse",
    RefName: "Nombre",
    RefPhone: "Número de teléfono",
    Next: "Próximo",
    NextToolTip: "Haga clic para ir al siguiente",
    Back: "Atrás",
    BackToolTip: "Haga clic para volver",
    FirstNameError: "Por favor ingrese su nombre",
    MiddleNameError: "Por favor ingrese el segundo nombre",
    LastNameError: "Por favor ingrese su apellido",
    OtherLastNameError: "Por favor ingrese otro apellido",
    AddressError: "Por favor ingrese la direccion",
    CityError: "Por favor ingrese la ciudad",
    StateError: "Por favor ingrese el estado",
    ZipCodeError: "Por favor ingrese el código postal",
    PhoneNumberError: "Por favor ingrese el numero de telefono",
    EmailAddressError: "Por favor ingrese la dirección de correo electrónico",
    DateOfBirthError: "Por favor ingrese la fecha de nacimiento",
    SSNError: "Por favor ingrese el número de seguro social",
    EmploymentTypeError: "Por favor ingrese el tipo de empleo",
    ContactNameError: "Por favor ingrese el nombre de contacto",
    RelationshipError: "Por favor ingrese la relación",
    ContactAddressError: "Por favor ingrese la dirección de contacto",
    ContactPhoneError: "Por favor ingrese el teléfono de contacto",
    SchoolError: "Por favor ingrese a la escuela",
    CityStateError: "Por favor ingrese ciudad / estado",
    OtherError: "Por favor ingrese otros detalles",
    OtherEmploymentNameError: "Por favor ingrese otro nombre de empleo",
    TerminationReasonError: "Por favor ingrese el motivo de la rescisión",
    EmployerError: "Por favor ingrese al empleador",
    EmployerAddressError: "Por favor ingrese la dirección del empleador",
    EmployerPhoneError: "Por favor ingrese el teléfono del empleador",
    EmployerTelePhoneError: "Por favor ingrese el teléfono del empleador",
    PositionError: "Por favor ingrese la posición",
    SupervisorError: "Por favor ingrese supervisor",
    DOEFromError: "Por favor ingrese la fecha de empleo de",
    DOEToError: "Por favor ingrese la fecha de empleo para",
    ReasonForLeavingError: "Por favor ingrese el motivo de su partida",
    RefNameError: "Por favor ingrese nombre",
    RefPhoneError: "Por favor ingrese el numero de telefono",
    ReasonForLeavingError: "Por favor ingrese el motivo de su partida",
    ThankYouMessage: "Gracias por aplicar con nosotros!",
    M1: "Un representante se comunicará con usted dentro de 1 día hábil.",
    M2: "Si tiene alguna pregunta, no dude en llamarnos.",
    HighSchoolCompletedError: "Seleccione la escuela secundaria",
    AlternateEmailAddress: "Dirección de correo electrónico alternativo",
    AlternatePhoneNumber: "Número de teléfono alternativo",
    Shifts: "Turnos"
}