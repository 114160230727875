import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup'
import ShortText from '../../../component/Controls/custom/textbox/shortText'
import { onValueChanged, sections } from '../../setting/services/companySaver';
import { createCompany, createNewCompany, getCheckSubDomainIsTaken, getCompanyByTenantId, selectCheckSubDomainIsTaken, selectCheckSubDomainIsTakenStatus, selectCompanyById, selectCompanyByTenantId, selectCompanyByTenantIdStatus, selectCompanyForm, selectCreationStatus, selectModificationStatus, updateCompany, updateSingleData } from '../../setting/reducerSlices/companySlice';
import { Operation } from '../../../common/constants';
import PhoneText from '../../../component/Controls/custom/textbox/phoneText';
import FileUpload from '../../../component/Controls/custom/fileUpload/fileUpload';
import BackButton from '../../../component/Controls/buttons/backButton';
import ContinueButton from '../../../component/Controls/buttons/continueButton';
import SetupProgress from '../../../component/Progress/SetupProgress';
import { isFormValid } from '../../../component/Controls/services/formService';
import { fetchStatus, masterTenantId, tenantId } from '../../../api/client';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';
import UrlText from '../../../component/Controls/custom/textbox/urlText';
import SubDomainUrlText from '../../../component/Controls/custom/textbox/subDomainUrlText';
import GridLoader from '../../../component/Controls/loaders/gridLoader';
// import FileUpload from '../../../component/Controls/custom/textbox/fileUpload';



function CompanyDetails() {

  const dispatch = useDispatch();
  const history = useHistory();


  const COMPANY_FORM = "CompanyForm";

  const [cFInvalid, setCFInvalid] = useState(false);
  const [cfSubmitted, setCFSubmitted] = useState(false);

  const companyForm = useSelector(selectCompanyForm);

  // const company = useSelector(selectCompanyById);
  const company = useSelector(selectCompanyByTenantId);
  const companyByTenantIdStatus = useSelector(selectCompanyByTenantIdStatus);

  const statusCreationStatus = useSelector(selectCreationStatus);
  const statusModificationStatus = useSelector(selectModificationStatus);
  const checkSubDomainIsTakenStatus = useSelector(selectCheckSubDomainIsTakenStatus);
  const checkSubDomainIsTaken = useSelector(selectCheckSubDomainIsTaken);

  const [base64ImageUploadedImage, setBase64ImageUploadedImage] = useState("");

  const handleImageUpload = (base64Image) => {
    setBase64ImageUploadedImage(base64Image);
    console.log('base64Image--->', base64Image)
  };

  // useEffect(() => {
  //   dispatch(createNewCompany());
  // }, []);

  useEffect(() => {
    _loadCompanyByTenantId();
  }, []);


  const _loadCompanyByTenantId = async () => {
    await dispatch(getCompanyByTenantId({
      page: 0,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSETTINGCOMPANYMASTERBYTENANTID",
        payload: {
          id: tenantId,
          data: ""
        }
      }
    }));
  }




  const _getCheckSubDomainIsTaken = async (workspaceURL) => {
    await dispatch(getCheckSubDomainIsTaken({
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETCHECKSUBDOMAINISTAKEN",
        payload: {
          id: "",
          data: workspaceURL
        }
      }
    }));
  }

  // Debounced function
  const debouncedCheckSubDomainIsTaken = useCallback(
    (() => {
      let timeout;
      return async (newDomain) => {
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(async () => {
          await _getCheckSubDomainIsTaken(newDomain);
        }, 300); // Adjust the debounce delay as needed
      };
    })(),
    []
  );

  const onValueUpdated = (e, section, value) => {
    let _companyObj = Object.assign({}, company);
    let _newCompanyObj = onValueChanged(_companyObj, e, section, value);
    dispatch(updateSingleData({
      company: _newCompanyObj,
      operation: Operation.UPDATED
    }));
  };

  const customValidation = () => {
    if (checkSubDomainIsTaken) {
      if (checkSubDomainIsTaken.isTaken) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const onSave = () => {
    // if (customValidation() && isFormValid(COMPANY_FORM)) {
    if (isFormValid(COMPANY_FORM)) {
      if (company && company.id) {
        // if (created) {
        dispatch(updateCompany({
          client: masterTenantId,
          serviceType: "TENANTADMIN",
          operation: "SAVESETTINGCOMPANYMASTER",
          payload: {
            id: company.id,
            data: JSON.stringify({
              settingCompanyMaster: JSON.stringify(company),
              photo: {
                contentType: "image/jpg",
                length: 0,
                photoData: base64ImageUploadedImage
              }
            })
          }
        }));
        // }
        // else {
        //   dispatch(updateCompany({
        //     client: tenantId,
        //     serviceType: "TENANTADMIN",
        //     operation: "SAVESETTINGCOMPANYMASTER",
        //     payload: {
        //       id: id,
        //       data: JSON.stringify({
        //         settingCompanyMaster: JSON.stringify(company),
        //       })
        //     }
        //   }));
        // }
      }
      // setCreateMode(false);
      // setEditmode(false);
      // setCFInvalid(false);
      setCFSubmitted(false);
    }
    else {
      setCFInvalid(true);
    }
    setCFSubmitted(true);
  }

  const onNextClicked = (e) => {
    console.log('company ->', company);
    onSave();
  }

  useEffect(() => {
    if (statusCreationStatus === fetchStatus.SUCCEEDED || statusModificationStatus === fetchStatus.SUCCEEDED) {
      history.push('companyLocation');
    }
  }, [statusCreationStatus, statusModificationStatus]);

  return (
    <>
      <div className="container ">
        <div className="row">
          {/* <div className="col-md-12 card setup-wizard d-flex align-items-stretch p-0"> */}
          <div className="col-md-4 card setup-wizard-left-side d-flex align-items-stretch p-0">
            <SetupProgress percentage="0" getPercentage="15" subTitle="Company Details" currentPage="companyDetails" />
          </div>
          <div className="col-md-8 card setup-wizard-right-side d-flex align-items-stretch p-0">
            <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn">

              {(companyByTenantIdStatus === fetchStatus.LOADING) &&
                <div className="setup-wizard-loader">
                  <GridLoader />
                </div>
              }

              <div className="row p-2">
                <div className="col-md-12">
                  <div className="card-left-header">Getting Started with Company Details</div>
                  <div className="card-left-sub-header">Please enter your company details.</div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-6">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblCompanyName" className="field-label" htmlFor="txtCompanyName">Company Name</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <ShortText
                          id="txtCompanyName"
                          name="txtCompanyName"
                          ShortTextClasses="form-control"
                          placeholder="Company Name"
                          value={company && company.CompanyName ? company.CompanyName.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                          required={true}
                          errorMessage={companyForm && companyForm.CompanyName.Validation}
                          form={COMPANY_FORM}
                          submited={cfSubmitted}
                          disabled={true}
                        />
                      } />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblCompanyPhoneNumber" className="field-label" htmlFor="txtCompanyPhoneNumber">Company Phone Number</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <PhoneText
                          id="txtCompanyPhoneNumber"
                          name="txtCompanyPhoneNumber"
                          PhoneTextClasses="form-control"
                          placeholder="(000)-00-0000"
                          value={company && company.CompanyPhoneNumber ? company.CompanyPhoneNumber.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                          required={true}
                          errorMessage={companyForm && companyForm.CompanyPhoneNumber.Validation}
                          form={COMPANY_FORM}
                          submited={cfSubmitted}
                          disabled={true}
                        />
                      } />
                  </div>
                </div>
              </div>

              <div className="row p-2">
                <div className="col-md-6">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblCompanyAddress" className="field-label" htmlFor="txtCompanyAddress">Company Address</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <ShortText
                          id="txtCompanyAddress"
                          name="txtCompanyAddress"
                          ShortTextClasses="form-control"
                          placeholder="Company Address"
                          value={company && company.CompanyAddress ? company.CompanyAddress.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                          required={true}
                          errorMessage={companyForm && companyForm.CompanyAddress.Validation}
                          form={COMPANY_FORM}
                          submited={cfSubmitted}
                          disabled={true}
                        />
                      } />
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblCompanyWebsite" className="field-label" htmlFor="txtCompanyWebsite">Company Website</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <UrlText
                          id="txtCompanyWebsite"
                          name="txtCompanyWebsite"
                          UrlTextClasses="form-control"
                          placeholder="Company Website"
                          value={company && company.CompanyWebsite ? company.CompanyWebsite.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                          // required={companyForm && companyForm.CompanyWebsite.Required}
                          errorMessage={companyForm && companyForm.CompanyWebsite.Validation}
                          form={COMPANY_FORM}
                          submited={cfSubmitted}
                          disabled={true}
                        />
                      } />
                  </div>
                </div>
              </div>

              {/* <div className="row p-2">
                <div className="col-md-6">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblWorkspaceURL" className="field-label" htmlFor="txtWorkspaceURL">Workspace URL</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Workspace URL"
                              aria-label="Subdomain"
                              aria-describedby="basic-addon2"

                              id="txtWorkspaceURL"
                              name="txtWorkspaceURL"
                              value={company && company.WorkspaceURL ? company.WorkspaceURL.Data : ""}
                              onChange={(e) => { onValueUpdated(e, sections.COMPANY_DETAILS, null); debouncedCheckSubDomainIsTaken(e.target.value) }}
                              required={true}
                              data-form={COMPANY_FORM}
                            />
                            <span class="input-group-text" id="basic-addon2">.hirebase.co</span>

                            {checkSubDomainIsTaken && checkSubDomainIsTaken.isTaken &&
                              <span className="error-label">
                                <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                {'Workspace URL is already taken.'}
                              </span>
                            }

                          </div>


                        </>
                      } />
                  </div>
                </div>
              </div> */}


              <div className="row p-2">
                <div className="col-md-6">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblWorkspaceURL" className="field-label" htmlFor="txtWorkspaceURL">Workspace URL</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <>
                          {/* <div class="input-group mb-3"> */}
                          <SubDomainUrlText
                            id="txtWorkspaceURL"
                            name="txtWorkspaceURL"
                            subDomainUrlTextClasses="form-control"
                            placeholder="Workspace Name"
                            value={company && company.WorkspaceURL ? company.WorkspaceURL.Data : ""}
                            onChange={(e) => { onValueUpdated(e, sections.COMPANY_DETAILS, null); debouncedCheckSubDomainIsTaken(e.target.value) }}
                            required={true}
                            errorMessage={companyForm && companyForm.CompanyWebsite.Validation}
                            form={COMPANY_FORM}
                            submited={cfSubmitted}
                            disabled={true}
                          >

                            {checkSubDomainIsTaken && checkSubDomainIsTaken.isTaken &&
                              <span className="error-label">
                                <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                {'Workspace URL is already taken.'}
                              </span>
                            }
                          </SubDomainUrlText>

                          {/* </div> */}


                        </>
                      } />
                  </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-6 mobile-margin-bottom-3">
                  <div className="form-group p-0 pt-2">
                    <label id="lblStatus" className="field-label" htmlFor="drpStatus">Company Logo</label>
                    <FileUpload
                      onImageUpload={handleImageUpload} />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer bottom-0 px-4 py-2 w-100">
              <div className="row">
                <div className="col-md-12">
                  {statusCreationStatus === fetchStatus.LOADING ||
                    statusModificationStatus === fetchStatus.LOADING ? <div className="float-end my-2"><EllipsisLoader /></div>
                    :
                    <>
                      <div className="float-end">
                        <ContinueButton
                          onClick={(e) => onNextClicked(e)}
                          disabled={checkSubDomainIsTakenStatus === fetchStatus.LOADING} />
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyDetails