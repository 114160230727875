import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import NoButton from '../buttons/noButton';
import OkButton from '../custom/buttons/okButton';
// import YesButton from '../buttons/yesButton';

// import './css/modalPopUp.css';
import { closeAlertModal, selectAlertModalPopUpDetail } from './reducerSlices/alertModalPopUpSlice';

function AlertModalPopUp(props) {

    const dispatch = useDispatch();

    const alertModalPopUpDetails = useSelector(selectAlertModalPopUpDetail);

    const onCloseClicked = () => {
        dispatch(closeAlertModal());
    };

    const onOkClicked = () => {
        dispatch(closeAlertModal());
    };

    return (
        <React.Fragment>
            {(alertModalPopUpDetails && alertModalPopUpDetails.show) ? (<React.Fragment>
                <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{alertModalPopUpDetails.title}</h5>
                                <button
                                    type="button"
                                    className="modal-close-button"
                                    onClick={onCloseClicked}>
                                    <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {alertModalPopUpDetails.content}
                                <span className="text-danger">{alertModalPopUpDetails.note}</span><br />
                            </div>
                            <div className="modal-footer">
                                <OkButton onOkClicked={() => onOkClicked()} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show">
                </div>
            </React.Fragment>) : ''}
        </React.Fragment>
    );
}

export default AlertModalPopUp;