import React, { useEffect, useState } from 'react';

function NumberText(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
        }
        else {
            setInvalid(false);
        }
    }, [value, props.required, edited, props.submited, props.disabled]);

    const onChange = (e) => {
        if (e.target.value) {
            if (e.target.value.length > e.target.maxLength) {
                return;
            }
        }
        props.onChange && props.onChange(e);
        setValue(e.target.value);
        setEdited(true);
    };

    return (
        <React.Fragment>
            <input
                type="number"
                id={props.id}
                name={props.name}
                value={value}//props.value ? props.value : ""}
                min={props.min ? props.min : "0"}
                step={props.min ? props.step : "1"}
                max={props.min ? props.max : "100"}
                maxLength={props.maxLength ? props.maxLength : 100000}
                // className={`${props.NumberTextClasses ? props.NumberTextClasses : ""} ${invalid && "invalid-input"}`}
                className={`form-control ${invalid && "is-invalid"}`}
                onChange={(e) => onChange(e)}
                disabled={props.disabled === true}
                formNoValidate={true}
                data-form={props.form ? props.form : ""}
                data-invalid={invalid}
                data-required={props.required ? props.required : false}
                placeholder={`${props.placeholder ? props.placeholder : ""}`}

            />
            {
                invalid && <span className="error-label">
                    <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                    {props.errorMessage ? props.errorMessage : "This field is required"}
                </span>
            }
        </React.Fragment>
    );
}

export default NumberText;