import { useEffect, useState, useCallback } from 'react';

const urlPattern = new RegExp(
    /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i
);

function useUrlValidator(url, delay = 300) {
    const [valid, setValid] = useState(true);

    const validateUrl = useCallback(() => {
        setValid(urlPattern.test(url));
    }, [url]);

    useEffect(() => {
        const handler = setTimeout(validateUrl, delay);
        return () => clearTimeout(handler);
    }, [url, validateUrl, delay]);

    return valid;
}

export default useUrlValidator;