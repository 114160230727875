import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import girlFaceImg from './../../assets/images/clipart/girl-face.svg';
import nophoto from './../../assets/images/clipart/nophoto.png';
import phoneCallFillImg from './../../assets/images/clipart/phone-call-fill.svg';
import calendarImg from './../../assets/images/clipart/calendar.svg';
import mailImg from './../../assets/images/clipart/mail.svg';
import mapLocationImg from './../../assets/images/clipart/map-location.svg';
import applicationImg from './../../assets/images/clipart/application.svg';
import noteImg from './../../assets/images/clipart/note.svg';
import documentImg from './../../assets/images/clipart/document.svg';
import msgImg from './../../assets/images/clipart/msg.svg';
import CheckBox from "../Controls/custom/checkers/checkbox";
import DialCallButton from "../Controls/buttons/dialCallButton";


const LeadCard = (props) => {

    const _getStatusClass = () => {
        if (props.status === "In Progress") {
            return 'status-inprogress';
        }
        else if (props.status === "Completed") {
            return 'status-completed';
        }
        else if (props.status === "Rejected") {
            return 'status-rejected';
        }
        else if (props.status === "New") {
            return 'status-new';
        }
        else if (props.status === "Credit Check") {
            return 'status-credit-check';
        }
        else if (props.status === "status-safety-check") {
            return 'status-safety-check';
        }
        else if (props.status === "Proposal") {
            return 'status-proposal';
        }
        else if (props.status === "SA") {
            return 'status-sa';
        }
        else if (props.status === "COI") {
            return 'status-coi';
        }
    }

    
    const handleChange = (event) => {
        props.onCheckRouteDetailsData && props.onCheckRouteDetailsData(event.target.checked, event);
    };


    return (
        <div className="card people-card" id={props.key}>
            <div className="c-checkbox">
            <input type="checkbox" checked={props.routeDetailsData ? props.routeDetailsData : false} onChange={handleChange} />
            {/* <input
                    type="checkbox"
                    // checked={isChecked}
                    onChange={handleChange}
                /> */}
            </div>
            {/* <div className="checkbox">
                <CheckBox size="Small" checked={props.routeDetailsData ? props.routeDetailsData : false} onClick={(c, e) => onClick(c, e)} />
            </div> */}
            <div className={`status ${_getStatusClass()}`}>{props.status && props.status}</div>
            <div className="people-card-body p-2 cursor-pointer" onClick={props.onClick}>
                <div className="d-block">
                    <div className="ps-2">
                        <div className="title w-100">
                            {props.leadName}
                        </div>
                        <div className="row">
                            <div className="detail col-4 d-flex align-items-center">
                                <DialCallButton dialCallButtonClasses="pe-1" />
                                {/* <img src={phoneCallFillImg} /> */}
                                {props.leadPhone}
                            </div>
                            <div className="detail col-8">
                                {/* <img src={mailImg} /> */}
                            </div>
                            <div className="detail col-4">
                                <img src={mapLocationImg} /> {props.location}
                            </div>
                            <div className="detail col-8">
                                {/* <img src={calendarImg} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="people-card-footer">
                <div className="item">
                    <span className="detail">
                        <img src={applicationImg} /> Lead Date: {props.leadDate}
                    </span>
                </div>
                <div className="item">
                </div>
            </div>
        </div>
    )
}

export default LeadCard;
