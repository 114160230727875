import { createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { Operation } from '../../../common/constants';
import Collection from '../../../component/Controls/paginators/collection';
import { refreshCreated } from '../../../component/Controls/paginators/services/createdResource';
import { refreshRemoved } from '../../../component/Controls/paginators/services/removedResource';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    form: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    removal: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    },
    experienceOptions: {
        status: fetchStatus.IDLE,
        error: null,
        options: undefined,
        refreshed: false
    },
    show: false,
    createMode: false,
    editMode: false,
};


export const getAllExperience = createAsyncThunk('experience/getAllExperience', async (experienceModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', experienceModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let experience;
        if (isJSON(data)) {
            experience = JSON.parse(data);
        }
        return {
            page: experienceModel.page,
            experience: experience,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getExperienceById = createAsyncThunk('experience/getExperienceById', async (experienceModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, experienceModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let experience = undefined;
        if (data && isJSON(data)) {
            experience = JSON.parse(data);
        }
        return {
            page: experienceModel.page,
            experience: experience,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const createExperience = createAsyncThunk('experience/createExperience', async (experienceModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, experienceModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let experience = undefined;
        if (data && isJSON(data)) {
            experience = JSON.parse(data);
        }
        return {
            experience: experience,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const updateExperience = createAsyncThunk('experience/updateExperience', async (experienceModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, experienceModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let experience = undefined;
        if (data && isJSON(data)) {
            experience = JSON.parse(data);
        }
        return {
            experience: experience,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const removeExperience = createAsyncThunk('experience/removeExperience', async (experienceModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, experienceModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        return {
            id: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getExperienceOptions = createAsyncThunk('experience/getExperienceOptions', async (experienceModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', experienceModel, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let options;
    if (isJSON(data)) {
        options = JSON.parse(data);
    }
    return {
        options: options,
        success: response.data.success
    };
});


export const experiencelice = createSlice({
    name: 'experience',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateRemovalStatus: (state) => {
            state.removal.status = fetchStatus.IDLE;
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateSingleData: (state, _data) => {
            state.single.refreshed = nanoid();
            state.single.data = _data.payload ? Object.assign({}, _data.payload.experience) : _data.payload;
            if (_data.payload && _data.payload.experience) {
                if (_data.payload.operation === Operation.UPDATED) {
                    let _item = state.data.records.Get(state.data.currentPage);
                    if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                        let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.experience.id);
                        let newRecords = _item.records;
                        let _experience = {
                            id: _data.payload.experience.id,
                            ExperienceName: _data.payload.experience.ExperienceName.Data,
                            Type: _data.payload.experience.Type.Data,
                            // Status: _data.payload.experience.Status.Data
                        };
                        newRecords[_updatedObjectIndex] = _experience;
                        _item.records = newRecords;
                        let records = new Collection();
                        records.Add(_item.key, newRecords);
                        state.data.records.Remove(_item.key);
                        records.Concat(state.data.records);
                        state.data.records = records;
                    }
                }
            }
        },
        createNewExperience: state => {
            state.single.data = {
                "ExperienceName": {
                    "Id": "txtExperienceName",
                    "Data": ""
                },
                "Type": {
                    "Id": "drpType",
                    "Data": ""
                },
                "ExperienceOptions": [''],
                "Status": {
                    "Id": "drpStatuses",
                    "Data": "Active"
                },
                "SkillGroupId": {
                    "Id": "drpSkillGroupId",
                    "Data": ""
                },
            };
        },
        createExperienceOptionDetail: (state) => {
            state.single.data.ExperienceOptions = [...state.single.data.ExperienceOptions, ''];
        },
        deleteExperienceOptionDetail: (state, action) => {
            const index = action.payload;
            state.single.data.ExperienceOptions = state.single.data.ExperienceOptions.filter((_, i) => i !== index);
        },
        updateExperienceOptionsStatus: (state) => {
            state.experienceOptions.status = fetchStatus.IDLE;
        },
        showExperienceFlyout: (state, action) => {
            const { createMode, editMode } = action.payload;
            state.createMode = createMode;
            state.editMode = editMode;
            state.show = true;
        },
        closeExperienceFlyout: (state, action) => {
            state.createMode = false;
            state.editMode = false;
            state.show = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllExperience.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        })
            .addCase(getAllExperience.fulfilled, (state, action) => {
                state.data.totalItems = action.payload.experience.totalItems;
                state.data.items = action.payload.experience.items;
                state.data.isFiltered = false;
                state.data.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            })
            .addCase(getAllExperience.rejected, (state, action) => {
                state.data.status = fetchStatus.FAILED;
                state.data.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(getExperienceById.pending, (state, action) => {
                state.single.status = fetchStatus.LOADING;
            }).addCase(getExperienceById.fulfilled, (state, action) => {
                state.single.data = action.payload.experience;
                if (action.payload.page >= 0) {
                    let _records = new Collection();
                    _records.Add(action.payload.page, action.payload.experience);
                    _records.Concat(state.data.firstRecords);
                    state.data.firstRecords = _records;
                }
                state.single.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(getExperienceById.rejected, (state, action) => {
                state.single.status = fetchStatus.FAILED;
                state.single.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(createExperience.pending, (state, action) => {
                state.creation.status = fetchStatus.LOADING;
            }).addCase(createExperience.fulfilled, (state, action) => {
                if (action.payload.experience) {
                    state.single.data = action.payload.experience;
                    let _experience = {
                        id: action.payload.experience.id,
                        ExperienceName: action.payload.experience.ExperienceName.Data,
                        Type: action.payload.experience.Type.Data,
                        // Status: action.payload.experience.Status.Data
                    };
                    const created = refreshCreated(state.data.records, _experience);
                    state.data.records = created.records;
                    state.data.currentPage = created.lastPage;
                    state.data.totalItems += 1;
                    state.single.refreshed = nanoid();
                }
                state.creation.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(createExperience.rejected, (state, action) => {
                state.creation.status = fetchStatus.FAILED;
                state.creation.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(updateExperience.pending, (state, action) => {
                state.modification.status = fetchStatus.LOADING;
            }).addCase(updateExperience.fulfilled, (state, action) => {
                state.single.data = action.payload.experience;
                state.modification.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(updateExperience.rejected, (state, action) => {
                state.modification.status = fetchStatus.FAILED;
                state.modification.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(removeExperience.pending, (state, action) => {
                state.removal.status = fetchStatus.LOADING;
            }).addCase(removeExperience.fulfilled, (state, action) => {
                const removed = refreshRemoved(state.data.records, action.payload.id, state.data.currentPage);
                state.data.records = removed.records;
                state.data.currentPage = removed.lastPage;
                state.data.totalItems -= 1;
                state.single.refreshed = nanoid();
                state.removal.status = fetchStatus.SUCCEEDED;
                toaster.success(action.payload.success);
            }).addCase(removeExperience.rejected, (state, action) => {
                state.removal.status = fetchStatus.FAILED;
                state.removal.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            }).addCase(getExperienceOptions.pending, (state, action) => {
                state.experienceOptions.status = fetchStatus.LOADING;
            }).addCase(getExperienceOptions.fulfilled, (state, action) => {
                let _options = action.payload.options;
                state.experienceOptions.options = _options;
                state.experienceOptions.status = fetchStatus.SUCCEEDED;
                state.experienceOptions.refreshed = false;
                toaster.success(action.payload.success);
            }).addCase(getExperienceOptions.rejected, (state, action) => {
                state.experienceOptions.status = fetchStatus.FAILED;
                state.experienceOptions.error = action.error.message;
                toaster.error(action.payload ? action.payload.error : "");
            });

    }
});

export const { updateCreationStatus, updateModificationStatus, updateRemovalStatus,
    updateSingleStatus, updateIsFiltered, updateStatus, loadSingleData,
    updateCurrentPage, updateSingleData, createNewExperience, createExperienceOptionDetail, deleteExperienceOptionDetail,
    updateExperienceOptionsStatus,
    showExperienceFlyout, closeExperienceFlyout } = experiencelice.actions;

export default experiencelice.reducer


export const selectAllExperience = state => state.experience.data.items;
export const selectTotalItems = state => state.experience.data.totalItems;
export const selectStatus = state => state.experience.data.status;

export const selectCreationStatus = state => state.experience.creation.status;

export const selectModificationStatus = state => state.experience.modification.status;

export const selectRemovalStatus = state => state.experience.removal.status;

export const selectExperienceById = (state) => {
    return state.experience.single ? state.experience.single.data : undefined;
}
export const selectSingleStatus = state =>  state.experience.single.status;

export const selectExperienceFormStatus = state => state.experience.form.status;
export const selectExperienceFormError = state => state.experience.form.error;
export const selectExperienceForm = state => state.experience.form.data;

export const selectExperienceOptionsStatus = state => state.experience.experienceOptions.status;
export const selectExperienceOptions = state => state.experience.experienceOptions.options;

export const selectExperienceFlyoutShow = state => state.experience.show;
export const selectExperienceFlyoutCreateMode = state => state.experience.createMode;
export const selectExperienceFlyoutEditmode = state => state.experience.editMode;