import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, GridComponent, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';
import ReactTooltip from "react-tooltip";

import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup'
import ShortText from '../../../component/Controls/custom/textbox/shortText'
import ContinueButton from '../../../component/Controls/buttons/continueButton';

import BackButton from '../../../component/Controls/buttons/backButton';
import SetupProgress from '../../../component/Progress/SetupProgress';
import Selector from '../../../component/Controls/custom/selectors/selectors';
import { userStatuses } from '../../shared/services/masterData';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import RemoveIconButton from '../../../component/Controls/custom/buttons/removeIconButton';
import { createNewSkillGroup, createSkillGroup, getAllSkillGroup, getSkillGroupById, removeSkillGroup, selectAllSkillGroup, selectCreationStatus, selectModificationStatus, selectRemovalStatus, selectSingleStatus, selectSkillGroupById, selectStatus, showSkillGroupFlyout, updateSingleData, updateSkillGroup, updateSkillGroupOptionsStatus, updateStatus } from '../../setting/reducerSlices/skillGroupSlice';
import { fetchStatus, tenantId } from '../../../api/client';
import { onValueChanged, sections } from '../../setting/services/skillGroupSaver';
import { Operation } from '../../../common/constants';
import { isFormValid } from '../../../component/Controls/services/formService';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';
import GridLoader from '../../../component/Controls/loaders/gridLoader';
import { show } from '../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';
import { messages } from '../../../common/actionMessages';





function CompanySkillGroup() {

  const dispatch = useDispatch();
  const history = useHistory();

  const SKILLGROUP_FORM = "SkillGroupform";

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [cFInvalid, setCFInvalid] = useState(false);
  const [cfSubmitted, setCFSubmitted] = useState(false);

  const [statusOptions, setStatusOptions] = useState([]);

  const skillGroup = useSelector(selectSkillGroupById);
  const skillGroupStatus = useSelector(selectSingleStatus);
  const skillGroupCreationStatus = useSelector(selectCreationStatus);
  const skillGroupModificationStatus = useSelector(selectModificationStatus);
  const skillGroupRemovalStatus = useSelector(selectRemovalStatus);

  const skillGroups = useSelector(selectAllSkillGroup);
  const skillGroupsRequestStatus = useSelector(selectStatus);


  const onAddRecordClicked = (e) => {
    dispatch(createNewSkillGroup());

    setCreateMode(true);
    setEditmode(false);
    setCFSubmitted(false);
  };

  let _statusOptions;
  useEffect(() => {
    if (isObjectArray(userStatuses) && userStatuses.length > 0) {
      _statusOptions = userStatuses.map((status, index) => (
        <option key={index} value={status.value}>
          {status.text}
        </option>
      ));
      setStatusOptions(_statusOptions);
    }

  }, [userStatuses]);








  useEffect(() => {
    if (skillGroupsRequestStatus === fetchStatus.IDLE) {
      _loadSkillGroups();
    }
  }, [skillGroupsRequestStatus, dispatch]);

  let _dummyCards = [];

  useEffect(() => {
    if (skillGroupsRequestStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateStatus());
    }
  }, [skillGroupsRequestStatus]);

  useEffect(() => {
    if (skillGroupCreationStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateSkillGroupOptionsStatus());
      _loadSkillGroups();
    }
  }, [skillGroupCreationStatus]);

  useEffect(() => {
    if (skillGroupModificationStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateSkillGroupOptionsStatus());
      _loadSkillGroups();
    }
  }, [skillGroupModificationStatus]);

  useEffect(() => {
    if (skillGroupRemovalStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateSkillGroupOptionsStatus());
      _loadSkillGroups();
    }
  }, [skillGroupRemovalStatus]);

  const _loadSkillGroup = async (id, page) => {
    await dispatch(getSkillGroupById({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSETTINGSKILLGROUPBYID",
        payload: {
          id: id,
          data: ""
        }
      }
    }));
  }

  const _loadSkillGroups = async () => {
    await dispatch(getAllSkillGroup({
      page: 0,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSETTINGSKILLGROUP",
        payload: {
          id: "",
          data: JSON.stringify({

          })
        }
      }
    }));
  }


  const onSkillGroupSelected = (e, id) => {
    e.preventDefault();
    _loadSkillGroup(id, undefined);
    setCreateMode(false);
    setEditmode(true);
  };

  const _removeSkillGroup = async (id) => {
    await dispatch(removeSkillGroup({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "DELETESETTINGSKILLGROUP",
      payload: {
        id: id,
        data: ""
      }
    }))
  };

  const onDeleteClicked = (e, id) => {
    e.preventDefault();
    if (id) {
      dispatch(show({
        id: id,
        title: messages.confirmAlert.title,
        content: messages.confirmAlert.content.removal('SkillGroup'),
        confirmedCallback: _removeSkillGroup
      }));
    }
  };

  const addActionTemplate = (props) => {
    return <>
      <i className='fa fa-pencil' data-tip='Edit' title='Edit' onClick={(e) => onSkillGroupSelected(e, props.id)}></i>
      <i className='fa fa-trash ps-3' data-tip='Delete' title='Delete' onClick={(e) => onDeleteClicked(e, props ? props.id : "")}></i>
      <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
    </>
  }

  const gridRef = useRef(null);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  }, [skillGroups, skillGroupsRequestStatus]);


  const columns = [
    { field: 'SkillGroupName', headerText: 'Skill Group Name', width: '100' },
    { field: 'Status', headerText: 'Status', width: '100' },
    {
      headerText: 'Action',
      template: addActionTemplate,
      width: '50px',
    },
  ];


  const onValueUpdated = (e, section, value) => {
    let _skillGroupObj = Object.assign({}, skillGroup);
    let _newSkillGroupObj = onValueChanged(_skillGroupObj, e, section, value);
    dispatch(updateSingleData({
      skillGroup: _newSkillGroupObj,
      operation: Operation.UPDATED
    }));
  };

  const onSaveClicked = (e, id, created) => {
    e.preventDefault();
    if (isFormValid(SKILLGROUP_FORM)) {
      if (skillGroup) {
        if (created) {
          dispatch(createSkillGroup({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVESETTINGSKILLGROUP",
            payload: {
              id: id,
              data: JSON.stringify({
                settingSkillGroup: JSON.stringify(skillGroup),
              })
            }
          }));
        }
        else {
          dispatch(updateSkillGroup({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVESETTINGSKILLGROUP",
            payload: {
              id: id,
              data: JSON.stringify({
                settingSkillGroup: JSON.stringify(skillGroup),
              })
            }
          }));
        }
      }
      setCreateMode(false);
      setEditmode(false);
      setCFInvalid(false);
      setCFSubmitted(false);
    }
    else {
      setCFInvalid(true);
    }
    setCFSubmitted(true);
  };

  const onCancelClicked = (e) => {
    setCreateMode(false);
    setEditmode(false);
    setCFInvalid(false);
    setCFSubmitted(false);
  };

  const onNextClicked = (e) => {
    history.push('companyExperience');
  }

  const onBack = (e) => {
    history.push('companyPositions');
  }


  return (
    <>
      <div className="container ">
        <div className="row">
          <div className="col-md-4 card setup-wizard-left-side d-flex align-items-stretch p-0">
            <SetupProgress percentage="30" getPercentage="45" subTitle="Skill Group" currentPage="companySkillGroup" />
          </div>
          <div className="col-md-8 card setup-wizard-right-side d-flex align-items-stretch p-0">
            <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn">

              {(skillGroupsRequestStatus === fetchStatus.LOADING ||
                skillGroupStatus === fetchStatus.LOADING ||
                skillGroupRemovalStatus === fetchStatus.LOADING) &&
                <div className="setup-wizard-loader">
                  <GridLoader />
                </div>
              }

              <div className="row p-2">
                <div className="col-md-12">
                  <div className="card-left-header">Skills Group Configuration</div>
                  <div className="card-left-sub-header">Setup custom skill sets tailored to organizational proficiency needs.</div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {/* <span className="detail-container-title">Skills Group</span> */}
                </div>
                <div className="col-md-6 text-end">
                  {(createMode || editMode) ?
                    <>
                      <button className="c-btn dark-btn" type="button" title='Cancle' onClick={(e) => onCancelClicked(e)}>
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                    :
                    <button className="c-btn dark-btn" type="button" title='Create new skill group' onClick={(e) => onAddRecordClicked(e)}>
                      <i className="fa fa-plus"></i>
                    </button>
                  }
                </div>
              </div>

              {!editMode && !createMode ?
                <div className="row p-2">
                  <GridComponent ref={gridRef} dataSource={skillGroups} allowPaging={true} allowSorting={true} allowResizing={true} width="100%" toolbar={['Search']} pageSettings={{ pageSize: 7, pageCount: 5 }} rowHeight={50} columns={columns} >
                    <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                  </GridComponent>
                </div>
                :
                <>
                  <div className="row p-2">
                    <div className="col-md-6">
                      <div className="form-group p-0 pt-2">
                        <label id="lblFirstName" className="flyout-label" htmlFor="txtSkillGroupNumber">Skill Group Name</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{skillGroup && skillGroup.SkillGroupName ? skillGroup.SkillGroupName.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-address-card"></i>}
                            control={
                              <ShortText
                                id="txtSkillGroupName"
                                name="txtSkillGroupName"
                                ShortTextClasses="form-control"
                                placeholder="Skill Group Name"
                                value={skillGroup && skillGroup.SkillGroupName ? skillGroup.SkillGroupName.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.SKILLGROUP_DETAILS, null)}
                                // required={skillGroupForm.SkillGroupName.Required}
                                required={true}
                                // errorMessage={skillGroupForm.SkillGroupName.Validation}
                                form={SKILLGROUP_FORM}
                                submited={cfSubmitted}
                              // disabled={editMode}
                              />
                            }
                          />
                        }
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group p-0 pt-2">
                        <label id="lblStatuses" className="flyout-label" htmlFor="drpStatuses">Status</label>
                        {!editMode && !createMode ?
                          <label className="field-label-value">{skillGroup ? skillGroup.Status.Data : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-address-card"></i>}
                            control={
                              <Selector
                                id="drpStatuses"
                                name="drpStatuses"
                                SelectorClasses="form-select"
                                value={(skillGroup && skillGroup.Status) ? skillGroup.Status.Data : ""}
                                onChange={(e) => onValueUpdated(e, sections.SKILLGROUP_DETAILS, null)}
                                options={
                                  <>
                                    {statusOptions}
                                  </>
                                }
                                required={true}
                                // errorMessage={}
                                form={SKILLGROUP_FORM}
                                submited={cfSubmitted} />}
                          />
                        }
                      </div>
                    </div>

                  </div>
                </>
              }
            </div>

            <div className="card-footer bottom-0 px-4 py-2 w-100">
              <div className="row">
                <div className="col-md-12">
                  {skillGroupCreationStatus === fetchStatus.LOADING || skillGroupModificationStatus === fetchStatus.LOADING
                    ? <div className="float-end my-2"><EllipsisLoader /></div>
                    :
                    <>
                      {(createMode || editMode) ?
                        <>
                          <BackButton onBackClicked={(e) => onCancelClicked(e)} text="Cancle" />
                          <div className="float-end">
                            <ContinueButton onClick={(e) => onSaveClicked(e, skillGroup ? skillGroup.id : "", createMode)} text="Save" />
                          </div>
                        </>
                        :
                        <>
                          <BackButton onBackClicked={(e) => onBack(e)} />
                          <div className="float-end">
                            <ContinueButton onClick={(e) => onNextClicked(e)} />
                          </div>
                        </>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanySkillGroup;