import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addCurrentStep, selectLanguage, updateLoginStatus } from '../reducerSlices/accountsSlice';
import Progress from '../../../component/Progress/Progress';
import '../css/accounts.css';
import BackButton from './../../../component/Controls/buttons/backButton';
import ContinueButton from './../../../component/Controls/buttons/continueButton';
import ShortText from '../../../component/Controls/custom/textbox/shortText';
import PhoneText from '../../../component/Controls/custom/textbox/phoneText';
import { getRegisteredApplicantById, selectRegisteredApplicantById, selectRegisteredApplicantModificationStatus, selectRegisteredApplicantStatus, updateRegisteredApplicant, updateRegisteredModificationStatus, updateRegisteredApplicantData } from '../../applicant/reducerSlices/applicantSlice';
import { Language, USER_KEY } from '../../../common/constants';
import { fetchStatus, tenantId } from '../../../api/client';
import { accountService } from '../services/accountService';
import { isFormValid } from '../../../component/Controls/services/formService';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup';
import { applicantDetailsEN } from '../../applicant/services/applicantDetails';
import { onValueChanged, sections } from '../../applicant/services/applicantSaver';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';

const References = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(addCurrentStep('References'));

  const APPLICANT_DETAIL_FORM = "AppilcantDetailForm";
  const [adfSubmitted, setADFSubmitted] = useState(false);
  const [aPFInvalid, setAPFInvalid] = useState(false);

  const applicantRequestStatus = useSelector(selectRegisteredApplicantStatus);
  const applicantModificationStatus = useSelector(selectRegisteredApplicantModificationStatus);
  const applicant = useSelector(selectRegisteredApplicantById);

  const [applicantDetails, setApplicantDetails] = useState(applicantDetailsEN);
  const language = useSelector(selectLanguage);

  const _loadApplicant = async (id) => {
    await dispatch(getRegisteredApplicantById({
      client: tenantId,
      serviceType: "TENANTUSER",
      operation: "GETREGISTEREDAPPLICANT",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  useEffect(() => {
    if (applicantRequestStatus === fetchStatus.IDLE) {
      const userId = accountService.getLoggedInUserId(USER_KEY);
      _loadApplicant(userId);
    }
  }, [applicantRequestStatus]);

  const onNextClicked = (e) => {
    setAPFInvalid(false);
    if (isFormValid(APPLICANT_DETAIL_FORM)) {
      if (applicant) {
        const userId = accountService.getLoggedInUserId(USER_KEY);
        dispatch(updateRegisteredApplicant({
          client: tenantId,
          serviceType: "TENANTUSER",
          operation: "SUBMITAPPLICANT",
          payload: {
            id: userId,
            data: JSON.stringify({
              applicant: JSON.stringify(applicant),
              photo: {
                contentType: "image/jpg",
                length: 0,
                photoData: ""
              }
            })
          }
        }));
      }
      setADFSubmitted(false);
    }
    else {
      setAPFInvalid(true);
      setADFSubmitted(true);
    }
  };

  const onBack = (e) => {
    history.push('/personal_info_2');
  }

  useEffect(() => {
    if (applicantModificationStatus === fetchStatus.SUCCEEDED) {
      if (accountService.isAuthenticated()) {
        dispatch(updateLoginStatus());
        dispatch(updateRegisteredModificationStatus());
        history.push("/employment_details_1");
      }
    }
  }, [applicantModificationStatus]);

  const onValueUpdated = (e, section, value) => {
    let _applicantObj = Object.assign({}, applicant);
    let _newApplicantObj = onValueChanged(_applicantObj, e, section, value);
    dispatch(updateRegisteredApplicantData(_newApplicantObj));
  };


  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 card card-left d-flex align-items-stretch p-0">
            <div className="card-body animate__animated animate__fadeIn">
              <div className="row p-2">
                <div className="col-md-12">
                  <div className="card-left-header">References</div>
                  <div className="card-left-sub-header">Please enter your references details. Dont have any? Its ok leave it blank. </div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblRefName1" className="field-label" htmlFor="txtRefName1">{applicantDetails ? applicantDetails.RefName : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <ShortText
                          required={applicant && applicant.ReferenceName1.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.RefNameError : applicant && applicant.ReferenceName1.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          id="txtRefName1"
                          name="txtRefName1"
                          ShortTextClasses="form-control"
                          value={applicant ? applicant.ReferenceName1.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.REFERENCE_DETAILS, null)}
                          placeholder={applicantDetails ? applicantDetails.RefName : ""} />
                      } />

                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblRefPhone1" className="field-label" htmlFor="txtRefPhone1">{applicantDetails ? applicantDetails.RefPhone : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-phone"></i>}
                      control={
                        <PhoneText
                          required={applicant && applicant.ReferencePhone1.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.RefPhoneError : applicant && applicant.ReferencePhone1.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          id="txtRefPhone1"
                          name="txtRefPhone1"
                          PhoneTextClasses="form-control"
                          value={applicant ? applicant.ReferencePhone1.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.REFERENCE_DETAILS, null)} />
                      } />
                  </div>
                </div>
              </div>
              <div className="row p-2 pt-5">
                <div className="col-md-12">
                  <div className="card-left-header">References 2</div>
                </div>
              </div>
              <div className="row p-2">
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblRefName2" className="field-label" htmlFor="txtRefName2">{applicantDetails ? applicantDetails.RefName : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-address-card"></i>}
                      control={
                        <ShortText
                          required={applicant && applicant.ReferenceName2.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.RefNameError : applicant && applicant.ReferenceName2.Validation}
                          form={APPLICANT_DETAIL_FORM} submited={adfSubmitted}
                          id="txtRefName2" name="txtRefName2"
                          ShortTextClasses="form-control"
                          value={applicant ? applicant.ReferenceName2.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.REFERENCE_DETAILS, null)}
                          placeholder={applicantDetails ? applicantDetails.RefName : ""} />
                      } />

                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group p-0 mobile-margin-bottom-3">
                    <label id="lblRefPhone2" className="field-label" htmlFor="txtRefPhone2">{applicantDetails ? applicantDetails.RefPhone : ""}</label>
                    <InputGroup
                      icon={<i className="fa fa-phone"></i>}
                      control={
                        <PhoneText
                          required={applicant && applicant.ReferencePhone2.Required}
                          errorMessage={language === Language.SPANISH ? applicantDetails.RefPhoneError : applicant && applicant.ReferencePhone2.Validation}
                          form={APPLICANT_DETAIL_FORM}
                          submited={adfSubmitted}
                          id="txtRefPhone2"
                          name="txtRefPhone2"
                          PhoneTextClasses="form-control"
                          value={applicant ? applicant.ReferencePhone2.Data : ""}
                          onChange={(e) => onValueUpdated(e, sections.REFERENCE_DETAILS, null)} />
                      } />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer bottom-0 px-4 py-2 w-100">
              <div className="row">
                <div className="col-md-12">
                  {applicantModificationStatus === fetchStatus.LOADING ? <div className="float-end my-2"><EllipsisLoader /></div>
                    :
                    <>
                      <BackButton onBackClicked={onBack} />
                      <div className="float-end">
                        <ContinueButton onClick={(e) => onNextClicked(e)} />
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 card card-right d-flex align-items-stretch p-0">
            <Progress percentage="25" getPercentage="15" subTitle="References Info" currentPage="references" />
          </div>
        </div>
      </div >
    </div >
  )
}

export default References;