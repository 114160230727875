import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../../api/client';
import { getAxiosRequestConfig } from '../../../../common/common';
import { isJSON } from '../../../../utilities/utilityFunctions';
import Collection from '../../paginators/collection';
import { toaster } from '../../toasts/toaster';

const initialState = {
    title: "",
    show: false,
    statusChange: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
}

export const savePeopleImport = createAsyncThunk('peopleImportModalPopUp/savePeopleImport', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
        }
        return {
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const peopleImportModalPopUpSlice = createSlice({
    name: 'peopleImportModalPopUp',
    initialState,
    reducers: {
        showPeopleImportModal: (state, action) => {
            const { title } = action.payload
            state.title = title;
            state.show = true;
        },
        closePeopleImportModal: state => {
            state.show = false;
            state.title = "";
            state.statusChange = {
                status: fetchStatus.IDLE,
                error: null,
                data: undefined
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(savePeopleImport.pending, (state, action) => {
            state.statusChange.status = fetchStatus.LOADING;
        }).addCase(savePeopleImport.fulfilled, (state, action) => {
            state.statusChange.data = action.payload.applicant;
            state.statusChange.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(savePeopleImport.rejected, (state, action) => {
            if (action.payload.data !== "") {
                let emailArray = action.payload.data.split(", ").map(email => email.trim());
                state.statusChange.data = emailArray;
            } else {
                state.statusChange.data = undefined;
            }
            state.statusChange.status = fetchStatus.FAILED;
            state.statusChange.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { showPeopleImportModal, closePeopleImportModal,
    updateStatus, updateCurrentPage,
    updateReadyForDispatch, removeReadyForDispatch } = peopleImportModalPopUpSlice.actions;

export default peopleImportModalPopUpSlice.reducer

export const selectPeopleImportModalPopUpDetail = state => {
    return {
        title: state.peopleImportModalPopUp.title,
        show: state.peopleImportModalPopUp.show
    }
}

export const selectSavePeopleImportStatus = state => state.peopleImportModalPopUp.statusChange.status;
export const selectSavePeopleImportData = state => state.peopleImportModalPopUp.statusChange.data;
