import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import nophoto from './../../../assets/images/clipart/nophoto.png';

import triangleExclamationiImg from './../../../assets/images/clipart/triangle-exclamationi.svg';
import bagCheckImg from './../../../assets/images/clipart/bag-check.svg';
// import bagCheckWhiteImg from './../../../assets/images/clipart/bag-check-white.png';
import bagCheckWhiteImg from './../../../assets/images/clipart/bag-check-white.svg';
import pagelinewhiteImg from './../../../assets/images/clipart/page-line-white.svg';
import phoneCallWhiteImg from './../../../assets/images/clipart/phone-call-white.svg';
import cloudImg from './../../../assets/images/clipart/cloud.svg';


import i9Img from './../../../assets/images/clipart/i9.svg';
import fileImg from './../../../assets/images/clipart/file.svg';
import genderImg from './../../../assets/images/clipart/gender.svg';
import termsImg from './../../../assets/images/clipart/terms.svg';
import folderImg from './../../../assets/images/clipart/folder.svg';
import filesImg from './../../../assets/images/clipart/files.svg';
import pdfImg from './../../../assets/images/clipart/pdf.svg';
import licenseImg from './../../../assets/images/clipart/license.svg';

import buildingLocationImg from './../../../assets/images/clipart/building-location.svg';
import downloadImg from './../../../assets/images/clipart/download.svg';
import heroiconsSolidImg from './../../../assets/images/clipart/heroicons-solid.svg';
import deleteImg from './../../../assets/images/clipart/delete.svg';


import PeopleDetailCard from "../../../component/Card/PeopleDetailCard";

import noDataImg from './../../../assets/images/clipart/no-data.svg';

import noProfileImg from './../../../assets/images/clipart/nophoto.png';
import user1Img from './../../../assets/images/clipart/user-1.png';
import deleteDarkImg from './../../../assets/images/clipart/delete-dark.svg';
import downloadDarkImg from './../../../assets/images/clipart/download-dark.svg';
import pencilDarkImg from './../../../assets/images/clipart/pencil-dark.svg';
import InputGroup from "../../../component/Controls/custom/inputGroup/inputGroup";
import TextBox from "../../../component/Controls/custom/textbox/textBox";
import InputDate from "../../../component/Controls/custom/inputDate/inputDate";

import {
  getApplicantById, selectApplicantById, updateSingleData,
  selectSingleStatus as applicantStatus,
  updateApplicant,
  selectRecentClickedApplicant,
  getApplicantQRCode,
  selectApplicantQRCodeStatus,
  selectApplicantQRCode,
  selectJobsByApplicantId,
  selectJobsApplicantStatus,
  getJobsByApplicantId,
  selectModificationStatus,
  updateAppicantCompleteProfileByUser, selectAppicantCompleteProfileByUserStatus, selectAppicantEmailForTempLoginStatus, updateAppicantEmailForTempLogin, removeApplicant, selectRemovalStatus,
  createExperienceDetail, removeExperienceDetailByItemId
} from "../../applicant/reducerSlices/applicantSlice";
import { useDispatch, useSelector } from "react-redux";
import { onValueChanged, sections as _sections } from '../../applicant/services/applicantSaver';
import { tenantId, fetchStatus } from "../../../api/client";
import { accountService } from "../../accounts/services/accountService";
import { USER_KEY } from "../../../common/constants";
import {
  getBase64Image,
  isAgeOver18,
  openFileUploader,
  setUploadedPhoto,
  toLocalDate,
  _getDateText,
  _getLocationText,
  _getStatusText,
  _splitCamelCase,
  _getTextFromValueList
} from '../../../common/common';

import {
  shiftsData,
  employmentTypesData,
  forkOpeTypeData,
  secutrityTypeData,
  compKnowTypeData,
  LanguageData
} from '../../applicant/services/masterData';

import { isFormValid } from '../../../component/Controls/services/formService';


import ShortText from "../../../component/Controls/custom/textbox/shortText";
import DateText from "../../../component/Controls/custom/textbox/dateText";
import YesNoRadio from "../../../component/Controls/custom/checkers/yesNoRadio";
import ZipCodeText from "../../../component/Controls/custom/textbox/zipCodeText";
import PhoneText from "../../../component/Controls/custom/textbox/phoneText";
import SSNText from "../../../component/Controls/custom/textbox/ssnText";
import EmailText from "../../../component/Controls/custom/textbox/emailText";
import Radio from "../../../component/Controls/custom/checkers/radio";
import NumberText from "../../../component/Controls/custom/textbox/numberText";
import MindMapSwitch from "../../../component/Controls/custom/mindMapSwitch";

import MultiSelector from "../../../component/Controls/custom/selectors/multiSelector";
import Selector from "../../../component/Controls/custom/selectors/selectors";
import { updateLayout } from "../../../component/layouts/reducerSlices/layoutSlice";
import LogCard from "../../../component/Controls/cards/logCard";
import DocumentsCard from "../../../component/Controls/cards/documentsCard";
import AddNoteFlyout from "../../../component/Flyout/AddNoteFlyout";
import CalledFlyout from "../../../component/Flyout/CalledFlyout";

import { showAlertModal } from "../../../component/Controls/modals/reducerSlices/alertModalPopUpSlice";
import { isObjectArray } from "../../../utilities/utilityFunctions";
import { resetCanceledStatus, selectCanceledStatus, showSUModal } from "../../../component/Controls/modals/reducerSlices/statusUpdateModalPopUpSlice";
import { applicantStatuses, applicantStatusesData, locationsData } from '../../shared/services/masterData';
import { showLocationModal } from "../../../component/Controls/modals/reducerSlices/ChangeLocationModalPopUpSlice";
import { showDPModal } from "../../../component/Controls/modals/reducerSlices/downloadPhotoModalPopUpSlice";
import { showModal } from "../../../component/Controls/modals/reducerSlices/modalPopUpSlice";
import ReactTooltip from "react-tooltip";
import { onPhotoChanged } from "../../applicant/services/photoSaver";
import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from "../../locations/reducerSlices/locationSlice";
import Statement from "../../../component/Controls/custom/textbox/statement";
import { showDNRModal } from "../../../component/Controls/modals/reducerSlices/DNRModalPopUpSlice";
import { showChatFlyout } from "../../../component/Flyout/reducerSlices/chatFlyoutSlice";
import SortButton from "../../../component/Controls/custom/buttons/sortButton";
import { selectDocumentUploadStatus, selectStatusChangeStatus } from "../../reviewer/reducerSlices/reviewerSlice";
import GridLoader from "../../../component/Controls/loaders/gridLoader";
import { showPreferredClientModal } from "../../../component/Controls/modals/reducerSlices/PreferredClientModalPopUpSlice";
import { show } from "../../../component/Controls/alerts/reducerSlices/confirmAlertSlice";
import { messages } from "../../../common/actionMessages";
import EllipsisLoader from "../../../component/Controls/loaders/ellipsisLoader";
import SaveControls from "../../shared/components/saveControls";
import StatusLogFilterBar from "../../../component/Controls/filterBars/statusLogFilterBar/statusLogFilterBar";
import { getStatusOptionsAll, selectStatusOptions, selectStatusOptionsStatus } from "../../setting/reducerSlices/statusesSlice";
import { getExperienceOptions, selectExperienceOptions, selectExperienceOptionsStatus } from "../../setting/reducerSlices/experienceSlice";
import RemoveIconButton from "../../../component/Controls/buttons/removeIconButton";
import { getPositionOptions, selectPositionOptions, selectPositionOptionsStatus } from "../../setting/reducerSlices/positionsSlice";
import { getSkillGroupOptions, selectSkillGroupOptions, selectSkillGroupOptionsStatus } from "../../setting/reducerSlices/skillGroupSlice";


const PeopleDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = accountService.isAdmin();

  const [addNoteFlyout, setAddNoteFlyout] = useState(false);
  const [calledFlyout, setCalledFlyout] = useState(false);

  const [applicantObj, setApplicantObj] = useState('');


  const [applyMoreFilters, setApplyMoreFilters] = useState(false);

  const [statusVisibility, setStatusVisibility] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const APPLICANT_FORM = "Applicantform";

  const applicant = useSelector(selectApplicantById);

  const [applicantFormSubmitted, setApplicantFormSubmitted] = useState(false);

  const [sections, setSections] = useState(_sections);

  const applicantRequestStatus = useSelector(applicantStatus);

  const recentClickedApplicant = useSelector(selectRecentClickedApplicant);

  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedSecondaryStatus, setSelectedSecondaryStatus] = useState();

  const canceledStatus = useSelector(selectCanceledStatus);

  const applicantQRCode = useSelector(selectApplicantQRCode);
  const applicantQRCodeStatus = useSelector(selectApplicantQRCodeStatus);
  const [onClickedQrCode, setOnClickedQrCode] = useState(false);

  const [latestLog, setLatestLog] = useState(undefined);
  const [logCards, setLogCards] = useState(undefined);
  const [sort, setSort] = useState(false);

  const [isHavingLWDetails, setIsHavingLWDetails] = useState(false);
  const [ageOver18, setAgeOver18] = useState(false);
  const [isPTO, setIsPTO] = useState(false);
  const [havingMI, setHavingMI] = useState(false);

  const locationOptions = useSelector(selectLocationOptions);
  const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
  const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);

  const [aPFInvalid, setAPFInvalid] = useState(false);

  const [isAnotherEmpName, setIsAnotherEmpName] = useState(false);
  const [isEverTerminated, setIsEverTerminated] = useState(false);
  const [havingNoWeekend, setHavingNoWeekend] = useState(false);
  const [havingASAPO, setHavingASAPO] = useState(false);
  const [havingExpDate, setHavingExpDate] = useState(false);

  const [havingGENL, setHavingGENL] = useState(false);
  const [havingSHIP, setHavingSHIP] = useState(false);
  const [havingPCLB, setHavingPCLB] = useState(false);
  const [havingINSW, setHavingINSW] = useState(false);
  const [havingRFSC, setHavingRFSC] = useState(false);
  const [havingASLI, setHavingASLI] = useState(false);
  const [havingFOPO, setHavingFOPO] = useState(false);
  const [havingMNOP, setHavingMNOP] = useState(false);
  const [havingSULE, setHavingSULE] = useState(false);
  const [havingMAME, setHavingMAME] = useState(false);
  const [havingELEC, setHavingELEC] = useState(false);
  const [havingSANI, setHavingSANI] = useState(false);
  const [havingQAQC, setHavingQAQC] = useState(false);
  const [havingFLOP, setHavingFLOP] = useState(false);
  const [havingSECR, setHavingSECR] = useState(false);
  const [havingCMKL, setHavingCMKL] = useState(false);
  const [havingAPMNOP, setHavingAPMNOP] = useState(false);
  const [havingAPLO, setHavingAPLO] = useState(false);
  const [havingSCPR, setHavingSCPR] = useState(false);
  const [havingTILA, setHavingTILA] = useState(false);
  const [base64Image, setBase64Image] = useState("");

  const [pageLoaded, setPageLoaded] = useState(false);

  const jobsApplicantStatus = useSelector(selectJobsApplicantStatus);
  const jobsApplicant = useSelector(selectJobsByApplicantId);

  const statusChangeStatus = useSelector(selectStatusChangeStatus);
  const applicantModificationStatus = useSelector(selectModificationStatus);
  const applicantRemovalStatus = useSelector(selectRemovalStatus);

  const appicantEmailForTempLoginStatus = useSelector(selectAppicantEmailForTempLoginStatus);
  const appicantCompleteProfileByUserStatus = useSelector(selectAppicantCompleteProfileByUserStatus);

  const [isDNR, setIsDNR] = useState(false);

  const [searchTermStatusLogs, setSearchTermStatusLogs] = useState("");
  const [statusLogsType, setStatusLogsType] = useState("");

  const documentUploadStatus = useSelector(selectDocumentUploadStatus);

  const statusOptionsStatus = useSelector(selectStatusOptionsStatus);
  const statusOptions = useSelector(selectStatusOptions);
  const experienceOptionsStatus = useSelector(selectExperienceOptionsStatus);
  const experienceOptions = useSelector(selectExperienceOptions);
  const positionOptionsStatus = useSelector(selectPositionOptionsStatus);
  const positionOptions = useSelector(selectPositionOptions);

  const skillGroupOptions = useSelector(selectSkillGroupOptions);
  const skillGroupOptionsStatus = useSelector(selectSkillGroupOptionsStatus);

  const _isHavingEducationDetails = () => {
    return applicant && applicant.Sections && applicant.Sections[2] && applicant.Sections[2].HavingDetails;
  }

  const _isHavingEmploymentDetails = () => {
    return applicant && applicant.Sections && applicant.Sections[3] && applicant.Sections[3].HavingDetails;
  }

  const _isHavingEmployerDetails = () => {
    return applicant && applicant.Sections && applicant.Sections[3] && applicant.Sections[3].HavingEmployerDetails;
  }

  const _isHavingReferenceDetails = () => {
    return applicant && applicant.Sections && applicant.Sections[4] && applicant.Sections[4].HavingDetails;
  }

  const _isCurrentlyEmployed = () => {
    return applicant && applicant.CurrentlyEmployed.Data === 'Y';
  }

  useEffect(() => {
    if (skillGroupOptionsStatus === fetchStatus.IDLE) {
      dispatch(getSkillGroupOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSKILLGROUPOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [skillGroupOptionsStatus]);

  useEffect(() => {
    if (experienceOptionsStatus === fetchStatus.IDLE) {
      dispatch(getExperienceOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETEXPERIENCEOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [experienceOptionsStatus]);


  useEffect(() => {
    if (statusOptionsStatus === fetchStatus.IDLE) {
      dispatch(getStatusOptionsAll({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSTATUSOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [statusOptionsStatus]);


  useEffect(() => {
    if (positionOptionsStatus === fetchStatus.IDLE) {
      dispatch(getPositionOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETPOSITIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({ All: "false" })
        }
      }));
    }
  }, [positionOptionsStatus]);

  const _getShiftText = (idList) => {
    if (isObjectArray(idList)) {
      let shifts = "";
      for (let i = 0; i < idList.length; i++) {
        let shift = shiftsData.find(s => s.value === idList[i]);
        if (shift && shift.text) {
          shifts += shift.text + ", ";
        }
      }
      shifts = shifts.trim();
      shifts = shifts.slice(0, -1);
      return shifts;
    }
    return "";
  }

  const _getLanguageText = (idList) => {
    if (isObjectArray(idList)) {
      let languages = "";
      for (let i = 0; i < idList.length; i++) {
        let language = LanguageData.find(p => p.value === idList[i]);
        if (language && language.text) {
          languages += language.text + ", ";
        }
      }
      languages = languages.trim();
      languages = languages.slice(0, -1);
      return languages;
    }
    return "";
  }

  const _getEmploymentTypeText = (idList) => {
    if (isObjectArray(idList)) {
      let empTypes = "";
      for (let i = 0; i < idList.length; i++) {
        let empType = employmentTypesData.find(e => e.value === idList[i]);
        if (empType && empType.text) {
          empTypes += empType.text + ", ";
        }
      }
      empTypes = empTypes.trim();
      empTypes = empTypes.slice(0, -1);
      return empTypes;
    }
    return "";
  }

  const _getForkLiftOperatorText = (idList) => {
    if (isObjectArray(idList)) {
      let forkLiftOpeTypes = "";
      for (let i = 0; i < idList.length; i++) {
        let forkLiftOpeType = forkOpeTypeData.find(fl => fl.value === idList[i]);
        if (forkLiftOpeType && forkLiftOpeType.text) {
          forkLiftOpeTypes += forkLiftOpeType.text + ", ";
        }
      }
      forkLiftOpeTypes = forkLiftOpeTypes.trim();
      forkLiftOpeTypes = forkLiftOpeTypes.slice(0, -1);
      return forkLiftOpeTypes;
    }
    return "";
  }

  const _getSecurityTypeText = (idList) => {
    if (isObjectArray(idList)) {
      let secTypes = "";
      for (let i = 0; i < idList.length; i++) {
        let secType = secutrityTypeData.find(st => st.value === idList[i]);
        if (secType && secType.text) {
          secTypes += secType.text + ", ";
        }
      }
      secTypes = secTypes.trim();
      secTypes = secTypes.slice(0, -1);
      return secTypes;
    }
    return "";
  }

  const _getComputerKnowText = (idList) => {
    if (isObjectArray(idList)) {
      let comKnows = "";
      for (let i = 0; i < idList.length; i++) {
        let comKnow = compKnowTypeData.find(p => p.value === idList[i]);
        if (comKnow && comKnow.text) {
          comKnows += comKnow.text + ", ";
        }
      }
      comKnows = comKnows.trim();
      comKnows = comKnows.slice(0, -1);
      return comKnows;
    }
    return "";
  }



  const _loadApplicant = async (id) => {
    await dispatch(getApplicantById({
      client: tenantId,
      serviceType: "TENANTUSER",
      operation: "GET",
      payload: {
        // id: '42c29b41-1ce0-442f-abcc-5e86b44ee842',
        id: id,
        data: ""
      }
    }));
  }

  // useEffect(() => {
  //   if (applicantRequestStatus === fetchStatus.IDLE) {
  //     const userId = accountService.getLoggedInUserId(USER_KEY);
  //     console.log('1st call userId', userId);
  //     _loadApplicant(userId);
  //   }
  // }, [applicantRequestStatus]);

  useEffect(() => {
    if (recentClickedApplicant === undefined) {
      history.push('../people');
    }

    if (recentClickedApplicant) {
      console.log('useEffect recentClickedApplicant ->', recentClickedApplicant);
      _loadApplicant(recentClickedApplicant);
      _loadJobsApplicant(recentClickedApplicant);
      setSelectedTab(0);
      setSearchTermStatusLogs("");
      setStatusLogsType("");
    }
  }, [recentClickedApplicant]);

  useEffect(() => {
    if (statusChangeStatus === fetchStatus.SUCCEEDED && recentClickedApplicant !== undefined) {
      _loadJobsApplicant(recentClickedApplicant);
      setSelectedTab(0);
    }
  }, [statusChangeStatus]);

  useEffect(() => {
    if (applicant) {
      setAgeOver18(isAgeOver18(applicant.DateOfBirth.Data));
      // setPersonalInfo({
      //   ...personalInfo,
      //   ['EmailAddress']: applicant.EmailAddress.Data,
      //   ['PhoneNumber']: applicant.PhoneNumber.Data,
      //   ['SSN']: applicant.SSN.Data,
      //   ['Position']: applicant.Position.Data,
      //   ['Shift']: applicant.Shift.Data,
      //   ['EmploymentType']: applicant.EmploymentType.Data,
      //   ['MaritalStatus']: applicant.MaritalStatus.Data,
      //   ['Gender']: applicant.Gender.Data,
      // });

      setApplicantObj(applicant);
    }
  }, [applicant]);

  const onValueUpdated = (e, section, value, type = "") => {
    let _applicantObj = Object.assign({}, applicant);
    let _newApplicantObj = onValueChanged(_applicantObj, e, section, value, type);
    dispatch(updateSingleData(_newApplicantObj));
  };

  useEffect(() => {
    if (applicantModificationStatus === fetchStatus.SUCCEEDED) {
      setEditMode(false);
      setAPFInvalid(false);
      setSelectedTab(0);
    }
  }, [applicantModificationStatus]);

  const onSaveClicked = (e, id) => {
    e.preventDefault();
    if (isFormValid(APPLICANT_FORM)) {
      if (applicant) {
        dispatch(updateApplicant({
          client: tenantId,
          serviceType: "TENANTUSER",
          operation: "SUBMITAPPLICANT",
          payload: {
            id: id,
            data: JSON.stringify({
              applicant: JSON.stringify(applicant),
              photo: {
                contentType: "image/jpg",
                length: 0,
                photoData: base64Image
              }
            })
          }
        }));

      }

    }
    else {
      setAPFInvalid(true);
      // selectInvalidTab(APPLICANT_FORM);
    }
    setApplicantFormSubmitted(true);
  };
  const onCancelClicked = (e) => {
    // setSelectedTab(6);
    setEditMode(false)
    setAPFInvalid(false);
    setApplicantFormSubmitted(false);
    setSelectedTab(0);
  }

  const _checkIsDNR = (statusLogs) => {
    if (statusLogs) {
      if (statusLogs.some(d => d.LogValue === -4)) {
        setIsDNR(true);
      } else {
        setIsDNR(false);
      }
    } else {
      setIsDNR(false);
    }
  }

  let _logCards;
  useEffect(() => {
    setLatestLog(undefined);
    if (applicant) {

      setSelectedStatus(applicant.Status);
      setSelectedSecondaryStatus(applicant.SecondaryStatus);
      if (applicant.StatusLogs && Array.isArray(applicant.StatusLogs) && applicant.StatusLogs.length > 0) {
        let _latestLog = applicant.StatusLogs.slice(-1);
        if (_latestLog && Array.isArray(_latestLog) && _latestLog.length === 1) {
          setLatestLog(_latestLog[0]);
        }
      }
      if (applicant && applicant.StatusLogs && Array.isArray(applicant.StatusLogs) && applicant.StatusLogs.length > 0) {

        let logs = [...applicant.StatusLogs];
        setLogCards(logs.reverse());
      }
      else {
        setLogCards(undefined);
      }
      setAgeOver18(isAgeOver18(applicant.DateOfBirth.Data));
      // _loadMessage(applicant.id);
      // _loadapplicantMessages(applicant.id);
      // _loadUnreadMessageCount(applicant.id);
      // _loadMessagTemplates(Language.ENGLISH);
      _checkIsDNR(applicant.StatusLogs);

    }
    else {
      setLogCards(undefined);
    }
  }, [applicantRequestStatus, applicant]);

  useEffect(() => {
    if (applicant) {
      setIsPTO(applicant.IsPTO.Data === "Y");
      setIsAnotherEmpName(applicant.IsOtherEmploymentName.Data === "Y");
      setIsEverTerminated(applicant.EverTerminated.Data === "Y");
      setHavingMI(applicant.IsMedicalInsurance.Data === "Y");
      setHavingASAPO(applicant.ASAPOrders.Data === "Y");

      setHavingNoWeekend(applicant.WorkWeekend.Data === "Y");

      setHavingGENL(applicant.GeneralLaber.Data === "Y");
      setHavingSHIP(applicant.ShipRec.Data === "Y");
      setHavingPCLB(applicant.PacLab.Data === "Y");
      setHavingINSW(applicant.IndSew.Data === "Y");
      setHavingRFSC(applicant.RFScan.Data === "Y");
      setHavingASLI(applicant.AssemblyLine.Data === "Y");
      setHavingFOPO(applicant.FoodProd.Data === "Y");
      setHavingMNOP(applicant.MacOpe.Data === "Y");
      setHavingSULE(applicant.SupLead.Data === "Y");
      setHavingMAME(applicant.MainMech.Data === "Y");
      setHavingELEC(applicant.Electrician.Data === "Y");
      setHavingSANI(applicant.Sanitation.Data === "Y");
      setHavingQAQC(applicant.QAQC.Data === "Y");

      setHavingFLOP(applicant.ForkOpe.Data === "Y");
      setHavingExpDate(applicant.ForkliftCertificate && applicant.ForkliftCertificate.Data === "Y");
      setHavingSECR(applicant.Security.Data === "Y");
      setHavingCMKL(applicant.CompKnow.Data === "Y");

      setHavingAPMNOP(applicant.AppMacOpe && applicant.AppMacOpe.Data === "Y");
      setHavingAPLO(applicant.AppLoa && applicant.AppLoa.Data === "Y");
      setHavingSCPR(applicant.ScrPri && applicant.ScrPri.Data === "Y");
      setHavingTILA(applicant.TicLab && applicant.TicLab.Data === "Y");
    }
  }, [applicantRequestStatus]);

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 1,
        title: "People"
      }));
      setPageLoaded(true);
    }
  });


  const onUploadFormsClicked = (e) => {
    e.preventDefault();
    // dispatch(showUDModal({
    //     title: <><span>Upload Documents For</span> <span className="modal-header-name">{applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : "N/A"}</span></>,
    //     id: applicant.id,
    //     applicant: applicant
    // }));

  };

  useEffect(() => {
    if (canceledStatus) {
      setSelectedStatus(applicant ? applicant.Status : "");
      setSelectedSecondaryStatus(applicant ? applicant.SecondaryStatus : "");
      dispatch(resetCanceledStatus());
    }
  }, [canceledStatus]);

  const onStatusClicked = (e) => {

    setStatusVisibility(!statusVisibility);
  };

  const onStatusChanged = (e, value, text) => {
    console.log('onStatusChanged ----->', e, value, text)
    e.preventDefault();
    var isDocPending = false;
    // if (applicant.Status == 'In Progress' && text == "Available") {
    if (applicant.Status == applicantStatusesData.InProgress && text == applicantStatusesData.Available) {
      var temptype = [{ value: 1, text: "I9" }, { value: 3, text: "Sexual Harassments Certificate" }, { value: 4, text: "Terms" }, { value: 5, text: "Others" }, { value: 7, text: "Safety Test and Certificate" }, { value: 8, text: "Covid Form" }];
      var isDocPending = false;
      if (!applicant.Documents || applicant.Documents.length == 0) {
        var doclist = [];
        for (let j = 0; j < temptype.length; j++) {
          if (temptype[j] && temptype[j].value != 5) {
            let tempText = temptype[j].text;
            doclist.push(<li> {tempText}</li>);

          }
        }
        isDocPending = true;
        dispatch(showAlertModal({
          title: <React.Fragment><span><i class="fa-solid fa-exclamation"></i> Document(s) pending For</span> <span className="modal-header-name">{applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : "N/A"}</span></React.Fragment>,
          id: applicant.id,
          applicant: applicant,
          content: <React.Fragment><span>Please upload following documents.</span></React.Fragment>,
          note: <React.Fragment>
            <ul>{doclist} </ul>
          </React.Fragment>
        }));
        setStatusVisibility(!statusVisibility);
      }
      if (applicant && applicant.Documents && isObjectArray(applicant.Documents)) {

        for (let i = 0; i < applicant.Documents.length; i++) {

          for (let j = 0; j < temptype.length; j++) {
            if (temptype[j] && (temptype[j].value == applicant.Documents[i].DocumentType)) {
              delete temptype[j];
              break;
            }

          }
        }
        var isDocPending = false;
        // var doclist = '';
        // for (let j = 0; j < temptype.length; j++) {
        //     if(temptype[j] && temptype[j].value != 5) {
        //         doclist = doclist + "<br>" + temptype[j].text;
        //         isDocPending = true; 
        //     }
        // }

        var doclist = [];
        for (let j = 0; j < temptype.length; j++) {
          if (temptype[j] && temptype[j].value != 5) {
            let tempText = temptype[j].text;
            doclist.push(<li> {tempText}</li>);
            isDocPending = true;
          }
        }

        if (isDocPending) {
          // alert(doclist);
          dispatch(showAlertModal({
            title: <React.Fragment><span>Document pending For</span> <span className="modal-header-name">{applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : "N/A"}</span></React.Fragment>,
            id: applicant.id,
            applicant: applicant,
            content: <React.Fragment><span>please upload following documents.</span></React.Fragment>,
            note: <React.Fragment>
              <ul>{doclist} </ul>
            </React.Fragment>
          }));
          setStatusVisibility(!statusVisibility);
          // dispatch(showModal({
          //     title: <><span>Document pending For</span> <span className="modal-header-name">{applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : "N/A"}</span></>,
          //     id: applicant.id,
          //     applicant: applicant,
          //     content: <><h1>hello</h1>
          //     </>
          // }));
        }

      }
    }
    if (!isDocPending) {
      dispatch(showSUModal({
        // title: <React.Fragment><span>{text === "Dispatched" ? "Let's Dispatched" : "Add Reason For"}</span> <span className="modal-header-name">{applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : "N/A"}</span></React.Fragment>,
        title: <><span>{value === applicantStatusesData.Dispatched ? "Let's Dispatched" : "Add Reason For"}</span> <span className="modal-header-name">{applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : "N/A"}</span></>,
        id: applicant.id,
        applicant: applicant,
        status: {
          value: value,
          text: text
        }
      }));
      setStatusVisibility(!statusVisibility);
      setSelectedStatus(text);
      setSelectedSecondaryStatus(text);
    }
  };

  const onChangeLocationClicked = (e, id) => {
    e.preventDefault();
    dispatch(showLocationModal({
      title: <React.Fragment><span>Change Location for</span> <span className="modal-header-name">{applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : "N/A"}</span></React.Fragment>,
      id: id,
      applicant: applicant
    }));
  };

  const onDeleteClicked = (e, id) => {
    e.preventDefault();
    if (id) {
      dispatch(show({
        id: id,
        title: messages.confirmAlert.title,
        content: messages.confirmAlert.content.removal('Applicant'),
        confirmedCallback: _removeApplicant
      }));
    }
  }

  const _removeApplicant = async (id) => {
    await dispatch(removeApplicant({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "REMOVEAPPLICANT",
      payload: {
        id: id,
        data: ""
      }
    }))
  };

  const onGenerateAppicantQRCodeClicked = async (e, id) => {
    e.preventDefault();
    setOnClickedQrCode(true);
    if (id) {
      await dispatch(getApplicantQRCode({
        client: tenantId,
        serviceType: "TENANTUSER",
        operation: "GETAPPLICANTQRCODE",
        payload: {
          id: id,
          data: ""
        }
      }));
    }
  };

  const onDNRClicked = (e, id) => {
    e.preventDefault();
    dispatch(showDNRModal({
      title: <><span>Set DNR (Do not return) For</span> <span className="modal-header-name">{applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : "N/A"}</span></>,
      id: id,
      applicant: applicant
    }));
  };

  const onPreferredClientClicked = (e, id) => {
    e.preventDefault();
    dispatch(showPreferredClientModal({
      title: <><span>Set Preferred Client For</span> <span className="modal-header-name">{applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : "N/A"}</span></>,
      id: id,
      applicant: applicant
    }));
  };

  useEffect(() => {
    if (applicantQRCodeStatus === fetchStatus.SUCCEEDED && onClickedQrCode === true) {
      dispatch(showModal({
        title: <React.Fragment><span>Generated QR Code For</span> <span className="modal-header-name">{applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : "N/A"}</span></React.Fragment>,
        content: <React.Fragment>
          <div className="barcode-container">
            <img id="imgGeneratedRQRCode" src={applicantQRCode} alt="qr data" className="barcode" />
          </div>
        </React.Fragment>,
        subtitle: <>Applicant QR Code</>
      }));
    }
  }, [applicantQRCodeStatus]);

  const onPhotoClicked = (e, photoData) => {
    // if (editMode) {
    //   openFileUploader("fuApplicantPhoto");
    // }
    // if (photoData && photoData != "" && photoData.startsWith("https://bajoapplicantstorage.blob.core.windows.net/")) {
    if (applicant) {
      dispatch(showDPModal({
        title: <React.Fragment><span>Profile Photo of</span> <span className="modal-header-name">{applicant ? applicant.FirstName.Data + " " + applicant.LastName.Data : "N/A"}</span></React.Fragment>,
        content: photoData,
        applicant: applicant,
      }));
    }
  };

  useEffect(() => {
    if (applicantRemovalStatus === fetchStatus.SUCCEEDED) {
      history.push('../people');
    }
  }, [applicantRemovalStatus]);


  useEffect(() => {
    if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
      dispatch(getLocationOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETLOCATIONOPTIONS",
        payload: {
          id: "",
          data: JSON.stringify({})
        }
      }));
    }
  }, [locationOptionsStatus, locationOptionsRefreshed]);

  useEffect(() => {
    if (!applicant || !applicant.StatusLogs || !Array.isArray(applicant.StatusLogs) || applicant.StatusLogs.length === 0) {
      setLogCards(undefined);
      return;
    }

    let filteredLogs = [...applicant.StatusLogs];

    if (statusLogsType !== "") {
      filteredLogs = filteredLogs.filter((d) => d.LogValue === parseInt(statusLogsType));
    }

    if (searchTermStatusLogs !== "") {
      const _searchTerm = searchTermStatusLogs.toLowerCase();
      filteredLogs = filteredLogs.filter(
        (d) =>
          d.Content.toLowerCase().includes(_searchTerm) ||
          d.LogType.toLowerCase().includes(_searchTerm) ||
          // statusOptions.filter(option => option.text.toLowerCase().includes(_searchTerm.toLowerCase())).map( d => d.value).includes(d.LogType) ||
          statusOptions.some(option => option.text.toLowerCase().includes(_searchTerm) && option.value === d.LogType) ||
          d.UserName.toLowerCase().includes(_searchTerm) ||
          toLocalDate(d.TimeStamp).toLowerCase().includes(_searchTerm) ||
          (d.Client && d.Client.toLowerCase().includes(_searchTerm))
      );
    }

    if (!sort) {
      filteredLogs = filteredLogs.reverse();
    }
    setLogCards(filteredLogs);
  }, [searchTermStatusLogs, statusLogsType]);

  const onSort = (e) => {
    setSort(!sort);
    const sortData = [...logCards].reverse();
    setLogCards(sortData);
  }

  const onChatClicked = (e, id) => {
    e.preventDefault();
    dispatch(showChatFlyout({
      title: <><span></span></>,
      applicantId: id,
    }));
  };


  const _loadJobsApplicant = async (id) => {
    await dispatch(getJobsByApplicantId({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETJOBSBYAPPLICANTID",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  const onGenerateAppicantEmailForTempLogin = async (e, id) => {
    e.preventDefault();
    if (id) {
      await dispatch(updateAppicantEmailForTempLogin({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "UPDATEAPPICANTEMAILFORTEMPLOGIN",
        payload: {
          id: id,
          data: ""
        }
      }));
      _loadApplicant(id);
    }
  };

  const onAppicantCompleteProfileByUser = async (e, id) => {
    e.preventDefault();
    if (id) {
      await dispatch(updateAppicantCompleteProfileByUser({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "UPDATEAPPICANTCOMPLETEPROFILEBYUSER",
        payload: {
          id: id,
          data: ""
        }
      }));
      _loadApplicant(id);
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const [selectedTab, setSelectedTab] = useState(0);

  const onTabSelected = (e, v) => {
    setSelectedTab(v);
  };

  const onEditClicked = (e) => {
    e.preventDefault();
    setEditMode(true);
    setSelectedTab(1);
  };

  useEffect(() => {
    if (documentUploadStatus === fetchStatus.FAILED && recentClickedApplicant) {
      _loadApplicant(recentClickedApplicant)
    }
  }, [documentUploadStatus]);

  const customFieldsOptions = {
    "JobTitle": ["SDR", "Appointment Setter"],
    "Availability": ["Available for Full-time", "Available for Part-time", "Not Available", "Employed by Worca"],
    "EnglishLevel": ["1 - Bad", "2 - Acceptable", "3 - Good", "4 - Excellent", "5 - Native Accent"],
    "PreferredOverlappedTime": ["All works", "TW Early morning", "TW Evening "],
    "ExperienceLevel": ["Below 5", "5 - 7 years", "7 - 10 years", "10+ years"]
  };

  const getOptionsByCustomFieldsKey = (key) => {
    if (customFieldsOptions.hasOwnProperty(key)) {
      return (
        <>
          {customFieldsOptions[key].map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  }

  const _confirmedRemoveExperienceDetailByItemId = (experienceId) => {
    if (experienceId) {
      dispatch(removeExperienceDetailByItemId(experienceId));
    }
  }

  const onRemoveExperienceFieldClicked = async (e, experienceId, experienceName) => {
    e.preventDefault();
    if (experienceId) {
      dispatch(show({
        id: experienceId,
        title: <><span>Remove of {experienceName} Experience</span> </>,
        content: "Are you sure, you want to remove the experience field for " + experienceName + " ?",
        confirmedCallback: _confirmedRemoveExperienceDetailByItemId
      }));
      // await dispatch(removeExperienceDetailByItemId(experienceId));
    }
  }

  const renderInput = (expOption) => {
    const expObj = applicant && applicant.ExperienceDetails && applicant.ExperienceDetails.find(detail => detail.id === expOption.id);
    const expData = expObj ? expObj.Data : '';

    switch (expOption.Type) {
      case 'Months':
        return (
          <InputGroup
            icon={<i className="fa fa-calendar"></i>}
            control={
              <NumberText
                max="999"
                maxLength={3}
                // required={true}
                form={APPLICANT_FORM}
                submited={applicantFormSubmitted}
                id={expOption.id}
                name={expOption.ExperienceName}
                NumberTextClasses="form-control"
                value={expData}
                onChange={(e) => onValueUpdated(e, sections.EXPERIENCE_DETAILS, null, expOption.Type)}
                placeholder="Enter the number of months"
                customtype={expOption.Type}
              />
            }
          />
        );
      case 'Single Choice':
        return (
          <Selector
            // required={true}
            form={APPLICANT_FORM}
            submited={applicantFormSubmitted}
            id={expOption.id}
            name={expOption.ExperienceName}
            data-id={expOption.id}
            SelectorClasses="form-select"
            placeholder=""
            options={
              <>
                {experienceOptions
                  .find(
                    (option) => option.ExperienceName === expOption.ExperienceName
                  )
                  .ExperienceOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
              </>
            }
            value={expData}
            onChange={(e) => onValueUpdated(e, sections.EXPERIENCE_DETAILS, null, expOption.Type)}
            customtype={expOption.Type}
          />
        );
      case 'Multi Choice':
        return (
          <InputGroup
            icon={<i className="fa fa-id-badge"></i>}
            control={
              <MultiSelector
                id={expOption.id}
                name={expOption.ExperienceName}
                value={expData ? expData : []}
                options={
                  experienceOptions
                    .find(
                      (option) => option.ExperienceName === expOption.ExperienceName
                    )
                    .ExperienceOptions.map((option) => ({
                      text: option,
                      value: option
                    }))

                }
                onChange={(e, v) => onValueUpdated(e, sections.EXPERIENCE_DETAILS, v, expOption.Type)}
                // required={true}
                form={APPLICANT_FORM}
                submited={applicantFormSubmitted}
                customtype={expOption.Type}
              />
            } />
        );
      default:
        return null;
    }
  };

  const renderLabelData = (expOption) => {
    const expObj = applicant && applicant.ExperienceDetails && applicant.ExperienceDetails.find(detail => detail.id === expOption.id);
    const expData = expObj ? expObj.Data : '';
    if (expOption.Type === "Multi Choice") {
      return Array.isArray(expData) ? expData.join(", ") : '';
    }
    return expData;
  }

  return (
    <>
      {(applicantRequestStatus === fetchStatus.LOADING ||
        jobsApplicantStatus === fetchStatus.LOADING ||
        applicantModificationStatus === fetchStatus.LOADING ||
        applicantRemovalStatus === fetchStatus.LOADING
      ) && <div className="app-container-loader">
          <GridLoader />
        </div>}

      {/* main section */}
      <div className="px-3 pt-3">
        <div className="row">
          <div className="d-flex bd-highlight">
            <div className="bd-highlight">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">People</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Details</li>
                </ol>
              </nav>
            </div>



            {/* <span className="icon">
                    <img src={buildingLocationImg} />
                  </span>
                  <span className="icon">
                    <img src={downloadImg} />
                  </span>
                  <span className="icon">
                    <img src={triangleExclamationiImg} />
                  </span>
                  <span className="icon">
                    <img src={heroiconsSolidImg} />
                  </span> */}

            {/* <div className="c-icon-box ms-5">
                  <i className="fa-solid fa-location-dot"></i>
                </div>
                <div className="c-icon-box">
                  <i className="fa-solid fa-download"></i>
                </div>
                <div className="c-icon-box">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                </div>
                <div className="c-icon-box">
                  <i className="fa-solid fa-qrcode"></i>
                </div> */}

            {/* <div className="fa fa-floppy-o fa-lg m-2" onClick={(e) => onSaveClicked(e, applicant ? applicant.id : "")} /> */}

            <div className="ms-auto">

              {!editMode && applicant && applicant.ProfileProcessStatus === false ?
                <>
                  {(appicantEmailForTempLoginStatus === fetchStatus.LOADING ||
                    appicantCompleteProfileByUserStatus === fetchStatus.LOADING ||
                    skillGroupOptionsStatus === fetchStatus.LOADING ||
                    experienceOptionsStatus === fetchStatus.LOADING) ?
                    <EllipsisLoader />
                    :
                    <div className="c-icon-box" ref={dropdownRef} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                      <i className="fa-solid fa fa-cog" />
                      {isDropdownOpen && (
                        <div className="dropdown-content">
                          <div className="d-flex justify-content-start c-icon-drop-box mailProcess" data-tip='Send Mail'>
                            <div onClick={(e) => onGenerateAppicantEmailForTempLogin(e, applicant ? applicant.id : "")}>
                              <i className="fa-solid fa fa-envelope me-2"></i>
                              Send Mail
                            </div>
                          </div>
                          <div className="d-flex justify-content-start c-icon-drop-box mailProcess" data-tip='Process'>
                            <div onClick={(e) => onAppicantCompleteProfileByUser(e, applicant ? applicant.id : "")}>
                              <i className="fa-solid fa-clipboard-check me-2 pe-1"></i>
                              Process Done
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  }
                </>
                : <></>}
              {!editMode ?
                <>
                  <div className="c-icon-box" onClick={(e) => onPreferredClientClicked(e, applicant ? applicant.id : "")} data-tip='Preferred Client'>
                    <i className="fa-solid fa-building-user"></i>
                  </div>
                  <div className="c-icon-box" onClick={(e) => onDNRClicked(e, applicant ? applicant.id : "")} data-tip='Set DNR ( Do not Return )'>
                    <i className="fa-solid fa-exclamation-triangle"></i>
                  </div>
                  <div className="c-icon-box" onClick={(e) => onGenerateAppicantQRCodeClicked(e, applicant ? applicant.id : "")} data-tip='Generated QR Code'>
                    <i className="fa-solid fa-qrcode"></i>
                  </div>
                  <div className="c-icon-box" onClick={() => setCalledFlyout(true)} data-tip='Called In / Called Out'>
                    <i className="fa-solid fa-phone" ></i>
                  </div>
                </>
                :
                <></>
              }
              {!editMode ?
                <>
                  {/* <div className='c-icon-box' onClick={() => setEditMode(!editMode)} data-tip='Edit'>
                    <i className='fa-solid fa-pencil'></i>
                  </div> */}
                  <div className='c-icon-box' onClick={(e) => onEditClicked(e)} data-tip='Edit'>
                    <i className='fa-solid fa-pencil'></i>
                  </div>
                </>
                :
                <SaveControls
                  formInvalid={aPFInvalid}
                  onSaveClicked={(e) => onSaveClicked(e, applicant ? applicant.id : "")}
                  onCancelClicked={(e) => onCancelClicked(e)}
                />

              }
              {isAdmin && !editMode ?
                <>
                  <div className="c-icon-box" data-tip='Delete' onClick={(e) => onDeleteClicked(e, applicant ? applicant.id : "")} >
                    <i className="fa-solid fa-trash"></i>
                  </div>
                </>
                :
                <></>
              }
              <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
            </div>


          </div>
        </div>
      </div>

      {/* <div className="d-flex w-100 p-3">
            <div className="align-self-center">
              <div className="flex-grow-1 bd-highlight">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">People</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Details</li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="ms-auto align-self-center d-flex">
              <div className="fa fa-floppy-o fa-lg m-2" onClick={(e) => onSaveClicked(e, applicant ? applicant.id : "")} />
              <div className="icon">
                <img src={pencilDarkImg} onClick={() => setEditMode(!editMode)} />
              </div>
              <div className="icon">
                <img src={buildingLocationImg} />
              </div>
              <div className="icon">
                <img src={downloadImg} />
              </div>
              <div className="icon">
                <img src={triangleExclamationiImg} />
              </div>
              <div className="icon">
                <img src={heroiconsSolidImg} />
              </div>
              <div className="icon">
                <img src={deleteImg} />
              </div>
            </div>
          </div> */}


      <div className="px-3">
        <div className="row">
          <div className="col-md-3">
            <PeopleDetailCard
              isRecordValid={applicant}
              // statuses={applicantStatuses}
              statuses={statusOptions && statusOptions.filter(d => d.StatusOfStatus === 'Active')}
              selectedStatus={selectedStatus}
              selectedStatusText={_getStatusText(statusOptions, selectedStatus)}
              selectedSecondaryStatus={selectedSecondaryStatus}
              selectedSecondaryStatusText={_getStatusText(statusOptions, selectedSecondaryStatus)}
              statusVisibility={statusVisibility}
              onStatusClicked={(e) => onStatusClicked(e)}
              onStatusChanged={(e, value, text) => onStatusChanged(e, value, text)}


              onChangeLocationClicked={(e) => onChangeLocationClicked(e, applicant ? applicant.id : "")}
              photoPath={applicant && applicant.PhotoPath ? applicant.PhotoPath.Data.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "") : nophoto}
              onPhotoClicked={(e, photoData) => onPhotoClicked(e, photoData)}

              dispatchDetails={jobsApplicant}
              applicantId={recentClickedApplicant}
              isDNR={isDNR}
              positionOptions={positionOptions}
            />
          </div>


          <div className="col-md-9">

            {/* tab menu */}
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 0 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 0)} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Feed</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 1 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 1)} id="personal-tab" data-bs-toggle="tab" data-bs-target="#personal" type="button" role="tab" aria-controls="personal" aria-selected="false">Personal</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 8 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 8)} id="experienceDetails-tab" data-bs-toggle="tab" data-bs-target="#experienceDetails" type="button" role="tab" aria-controls="experienceDetails" aria-selected="false">Skill Details</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 2 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 2)} id="emergencyContact-tab" data-bs-toggle="tab" data-bs-target="#emergencyContact" type="button" role="tab" aria-controls="emergencyContact" aria-selected="false">Emergency Contact</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 3 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 3)} id="education-tab" data-bs-toggle="tab" data-bs-target="#education" type="button" role="tab" aria-controls="education" aria-selected="false">Education</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 4 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 4)} id="employment-tab" data-bs-toggle="tab" data-bs-target="#employment" type="button" role="tab" aria-controls="employment" aria-selected="false">Employment</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 5 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 5)} id="references-tab" data-bs-toggle="tab" data-bs-target="#references" type="button" role="tab" aria-controls="references" aria-selected="false">References</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 6 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 6)} id="otherDetails-tab" data-bs-toggle="tab" data-bs-target="#otherDetails" type="button" role="tab" aria-controls="otherDetails" aria-selected="false">Other Details</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`nav-link nav-link-people ${selectedTab === 7 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 7)} id="customDetails-tab" data-bs-toggle="tab" data-bs-target="#customDetails" type="button" role="tab" aria-controls="customDetails" aria-selected="false">Custom Details</button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className={`tab-pane fade ${selectedTab === 0 && "show active"}`} id="home" role="tabpanel" aria-labelledby="home-tab">
                <div className="card a-card-wrap pt-3">

                  <StatusLogFilterBar
                    onSearch={(e) => setSearchTermStatusLogs(e.target.value)}
                    searchTerm={searchTermStatusLogs}>
                    <SortButton
                      SortButtonClasses="mx-2"
                      sorted={sort}
                      onSortClicked={(e) => onSort(e)}
                    />
                    <div className="line line-start"></div>
                    {/* <Selector id="drpStatusLogsType" name="drpStatusLogsType" SelectorClasses="separator-input style-2 mx-2"
                      value={statusLogsType}
                      options={
                        <>
                          <option value="3">Active</option>
                          <option value="-1">Admin Note</option>
                          <option value="2">Available</option>
                          <option value="-3">Called In</option>
                          <option value="-2">Called Out</option>
                          <option value="7">Dispatch</option>
                          <option value="-4">DNR</option>
                          <option value="5">DNU</option>
                          <option value="4">InActive</option>
                          <option value="1">InProgress</option>
                          <option value="-9">Preferred Client / Location Chnage </option>
                          <option value="8">UnDispatch</option>
                        </>
                      }
                      onChange={(e) => setStatusLogsType(e.target.value)} /> */}
                  </StatusLogFilterBar>

                  <div className="d-flex">
                    <div className="col-md-8 col-lg-8">
                      <div className="tracking-list pt-3 ps-3">
                        <LogCard statusLogs={logCards}
                          statuses={statusOptions} />
                      </div>
                    </div>

                    {/* <div className="col-md-4 col-lg-4  end-r-3 p-3 ">

                          <div className=" a-card">
                            Attachments

                            <div className="accordion" id="accordionExample">

                              <div className="flyout-list-group-item-card-container mt-3">
                                <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                  <img src={i9Img} className="icon-img" />
                                  <span className="bold-text ps-2">
                                    I9
                                  </span>
                                  <span className="ms-auto light-text">
                                    1 Items
                                  </span>
                                </div>
                                <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                  <div className="flyout-list-group-item-card-footer">
                                    <div className="flyout-list-group-item-card">
                                      <div className='sub-icon'>
                                        <img src={pdfImg} className="icon-img" />
                                        <p className='file-size-text'>10kb</p>
                                      </div>
                                      <span className="ps-2">
                                        <div className='file-name'>Resume.pdf</div>
                                        <div className='file-by'>Adam Smith, Jul 02, 2022  10:30 AM </div>
                                      </span>
                                      <span className="ms-auto">
                                        <img src={downloadImg} alt='not found' className='icon-btn me-2' />
                                        <img src={deleteImg} alt='not found' className='icon-btn' />
                                      </span>
                                    </div>
                                    <div className="flyout-card-sub-title bold ps-3">
                                      Attached File
                                    </div>
                                    <div className="flyout-list-group-item-card">
                                      <div className='sub-icon'>
                                        <img src={licenseImg} className="icon-img" />
                                        <p className='file-size-text'>10kb</p>
                                      </div>
                                      <span className="ps-2">
                                        <div className='file-name'>License.png</div>
                                      </span>
                                      <span className="ms-auto light-text">
                                        1 Items
                                      </span>
                                    </div>
                                    <div className='px-2 pb-3'>
                                      <textarea className="flyout-input" placeholder='Add note here'></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="flyout-list-group-item-card-container mt-3">
                                <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                  <img src={fileImg} className="icon-img" />
                                  <span className="bold-text ps-2">
                                    W4
                                  </span>
                                  <span className="ms-auto light-text">
                                    3 Items
                                  </span>
                                </div>
                                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                  <div className="flyout-list-group-item-card-footer">
                                    <div className="flyout-list-group-item-card">
                                      <div className='sub-icon'>
                                        <img src={pdfImg} className="icon-img" />
                                        <p className='file-size-text'>10kb</p>
                                      </div>
                                      <span className="ps-2">
                                        <div className='file-name'>Resume.pdf</div>
                                        <div className='file-by'>Adam Smith, Jul 02, 2022  10:30 AM </div>
                                      </span>
                                      <span className="ms-auto">
                                        <img src={downloadImg} alt='not found' className='icon-btn me-2' />
                                        <img src={deleteImg} alt='not found' className='icon-btn' />
                                      </span>
                                    </div>
                                    <div className="flyout-card-sub-title bold ps-3">
                                      Attached File
                                    </div>
                                    <div className="flyout-list-group-item-card">
                                      <div className='sub-icon'>
                                        <img src={licenseImg} className="icon-img" />
                                        <p className='file-size-text'>10kb</p>
                                      </div>
                                      <span className="ps-2">
                                        <div className='file-name'>License.png</div>
                                      </span>
                                      <span className="ms-auto light-text">
                                        1 Items
                                      </span>
                                    </div>
                                    <div className='px-2 pb-3'>
                                      <textarea className="flyout-input" placeholder='Add note here'></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="flyout-list-group-item-card-container mt-3">
                                <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                  <img src={genderImg} className="icon-img" />
                                  <span className="bold-text ps-2">
                                    Sexual Harassment
                                  </span>
                                  <span className="ms-auto light-text">
                                    1 Items
                                  </span>
                                </div>
                                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                  <div className="flyout-list-group-item-card-footer">
                                    <div className="flyout-list-group-item-card">
                                      <div className='sub-icon'>
                                        <img src={pdfImg} className="icon-img" />
                                        <p className='file-size-text'>10kb</p>
                                      </div>
                                      <span className="ps-2">
                                        <div className='file-name'>Resume.pdf</div>
                                        <div className='file-by'>Adam Smith, Jul 02, 2022  10:30 AM </div>
                                      </span>
                                      <span className="ms-auto">
                                        <img src={downloadImg} alt='not found' className='icon-btn me-2' />
                                        <img src={deleteImg} alt='not found' className='icon-btn' />
                                      </span>
                                    </div>
                                    <div className="flyout-card-sub-title bold ps-3">
                                      Attached File
                                    </div>
                                    <div className="flyout-list-group-item-card">
                                      <div className='sub-icon'>
                                        <img src={licenseImg} className="icon-img" />
                                        <p className='file-size-text'>10kb</p>
                                      </div>
                                      <span className="ps-2">
                                        <div className='file-name'>License.png</div>
                                      </span>
                                      <span className="ms-auto light-text">
                                        1 Items
                                      </span>
                                    </div>
                                    <div className='px-2 pb-3'>
                                      <textarea className="flyout-input" placeholder='Add note here'></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="flyout-list-group-item-card-container mt-3">
                                <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                  <img src={termsImg} className="icon-img" />
                                  <span className="bold-text ps-2">
                                    Terms
                                  </span>
                                  <span className="ms-auto light-text">
                                    2 Items
                                  </span>
                                </div>
                                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                  <div className="flyout-list-group-item-card-footer">
                                    <div className="flyout-list-group-item-card">
                                      <div className='sub-icon'>
                                        <img src={pdfImg} className="icon-img" />
                                        <p className='file-size-text'>10kb</p>
                                      </div>
                                      <span className="ps-2">
                                        <div className='file-name'>Resume.pdf</div>
                                        <div className='file-by'>Adam Smith, Jul 02, 2022  10:30 AM </div>
                                      </span>
                                      <span className="ms-auto">
                                        <img src={downloadImg} alt='not found' className='icon-btn me-2' />
                                        <img src={deleteImg} alt='not found' className='icon-btn' />
                                      </span>
                                    </div>
                                    <div className="flyout-card-sub-title bold ps-3">
                                      Attached File
                                    </div>
                                    <div className="flyout-list-group-item-card">
                                      <div className='sub-icon'>
                                        <img src={licenseImg} className="icon-img" />
                                        <p className='file-size-text'>10kb</p>
                                      </div>
                                      <span className="ps-2">
                                        <div className='file-name'>License.png</div>
                                      </span>
                                      <span className="ms-auto light-text">
                                        1 Items
                                      </span>
                                    </div>
                                    <div className='px-2 pb-3'>
                                      <textarea className="flyout-input" placeholder='Add note here'></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="flyout-list-group-item-card-container mt-3">
                                <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                  <img src={folderImg} className="icon-img" />
                                  <span className="bold-text ps-2">
                                    Others
                                  </span>
                                  <span className="ms-auto light-text">
                                    2 Items
                                  </span>
                                </div>
                                <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                  <div className="flyout-list-group-item-card-footer">
                                    <div className="flyout-list-group-item-card">
                                      <div className='sub-icon'>
                                        <img src={pdfImg} className="icon-img" />
                                        <p className='file-size-text'>10kb</p>
                                      </div>
                                      <span className="ps-2">
                                        <div className='file-name'>Resume.pdf</div>
                                        <div className='file-by'>Adam Smith, Jul 02, 2022  10:30 AM </div>
                                      </span>
                                      <span className="ms-auto">
                                        <img src={downloadImg} alt='not found' className='icon-btn me-2' />
                                        <img src={deleteImg} alt='not found' className='icon-btn' />
                                      </span>
                                    </div>
                                    <div className="flyout-card-sub-title bold ps-3">
                                      Attached File
                                    </div>
                                    <div className="flyout-list-group-item-card">
                                      <div className='sub-icon'>
                                        <img src={licenseImg} className="icon-img" />
                                        <p className='file-size-text'>10kb</p>
                                      </div>
                                      <span className="ps-2">
                                        <div className='file-name'>License.png</div>
                                      </span>
                                      <span className="ms-auto light-text">
                                        1 Items
                                      </span>
                                    </div>
                                    <div className='px-2 pb-3'>
                                      <textarea className="flyout-input" placeholder='Add note here'></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="flyout-list-group-item-card-container mt-3">
                                <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                  <img src={filesImg} className="icon-img" />
                                  <span className="bold-text ps-2">
                                  All
                                  </span>
                                  <span className="ms-auto light-text">
                                    2 Items
                                  </span>
                                </div>
                                <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                  <div className="flyout-list-group-item-card-footer">
                                    <div className="flyout-list-group-item-card">
                                      <div className='sub-icon'>
                                        <img src={pdfImg} className="icon-img" />
                                        <p className='file-size-text'>10kb</p>
                                      </div>
                                      <span className="ps-2">
                                        <div className='file-name'>Resume.pdf</div>
                                        <div className='file-by'>Adam Smith, Jul 02, 2022  10:30 AM </div>
                                      </span>
                                      <span className="ms-auto">
                                        <img src={downloadImg} alt='not found' className='icon-btn me-2' />
                                        <img src={deleteImg} alt='not found' className='icon-btn' />
                                      </span>
                                    </div>
                                    <div className="flyout-card-sub-title bold ps-3">
                                      Attached File
                                    </div>
                                    <div className="flyout-list-group-item-card">
                                      <div className='sub-icon'>
                                        <img src={licenseImg} className="icon-img" />
                                        <p className='file-size-text'>10kb</p>
                                      </div>
                                      <span className="ps-2">
                                        <div className='file-name'>License.png</div>
                                      </span>
                                      <span className="ms-auto light-text">
                                        1 Items
                                      </span>
                                    </div>
                                    <div className='px-2 pb-3'>
                                      <textarea className="flyout-input" placeholder='Add note here'></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div> */}


                    <div className="col-md-4 col-lg-4  end-r-3 p-3 pb-5 ">
                      <div className=" a-card">
                        <div className="d-flex">
                          <span className="text-primary">Documents</span>
                          <div className="ms-auto">
                            <div className="close-btn">
                              {/* <i className="fa fa-cloud-download" aria-hidden="true"></i> */}
                            </div>
                          </div>
                        </div>

                        <DocumentsCard documents={applicant && applicant.Documents ? applicant.Documents : ""} applicant={applicant ? applicant : ""} />
                        {/* <div className="position-absolute bottom-0 end-0 pe-3 pb-3"> */}
                        <div className="float-button">
                          {/* <div className="float-end"> */}
                          <button className="c-btn dark-btn rounded-pill me-3" type="button" onClick={() => setAddNoteFlyout(true)}><i className="fa fa-file pe-2" ></i>Add Note</button>
                          <button className="c-btn dark-btn rounded-pill" type="button" onClick={(e) => onChatClicked(e, applicant ? applicant.id : "")}><i className="fa-solid fa-comment pe-2"></i>Message</button>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>

                    {/* Start Attachments */}
                    {/* <div className="col-md-4 col-lg-4  end-r-3 p-3 ">
                          <div className=" a-card">
                            Attachments
                            <div className="ms-auto">
                              <div className="close-btn">
                                <i className="fa fa-times" aria-hidden="true"></i>
                              </div>
                            </div>
                            <div className="d-flex a-card-item mt-3">
                              <img src={i9Img} />
                              <span className="a-card-item-text">
                                I9
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                2 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={fileImg} />
                              <span className="a-card-item-text">
                                W4
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                3 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={genderImg} />
                              <span className="a-card-item-text">
                                Sexual Harassment
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                6 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={termsImg} />
                              <span className="a-card-item-text">
                                Terms
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                3 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={folderImg} />
                              <span className="a-card-item-text">
                                Others
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                8 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={filesImg} />
                              <span className="a-card-item-text">
                                All
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                12 Items
                              </span>
                            </div>

                            <div className="p-3 pt-5">
                              <div className="float-end">
                                <button className="c-btn dark-btn mx-2" type="button" >Add Note</button>
                                <button className="c-btn dark-btn" type="button" >Message</button>
                              </div>
                            </div>

                          </div>
                        </div> */}
                    {/* End Attachments */}


                  </div>

                  {/* <div className="d-flex">
                        <div className="col-md-8 col-lg-8">
                          <div className="tracking-list pt-3 ps-3">

                            <div className="tracking-item">
                              <div className="tracking-icon status-intransit bg-blue">
                                <img src={bagCheckWhiteImg} className="" />
                              </div>
                              <div className="tracking-content">
                                <p>
                                  <span className="p-name">Rob Smith</span>
                                  <span className="p-changed"> Changed status to</span>
                                  <span className="p-status"> Dispatched</span>
                                </p>
                                <p className="p-subtext">Change status In Progress to dispatch</p>
                                <p className="p-dark-gray">Client:/ per Rates :  $ <span className="p-light-gray">06/27/2022, 19:46:21</span></p>
                              </div>
                            </div>

                            <div className="tracking-item">
                              <div className="tracking-icon status-inforeceived bg-purple">
                                <img src={pagelinewhiteImg} className="" />
                              </div>
                              <div className="tracking-content">
                                <p>
                                  <span className="p-name">Howard Caroll</span>
                                  <span className="p-changed"> Uploaded documents</span>
                                </p>
                                <p className="p-subtext">Document type W4 and others </p>
                                <p className="p-dark-gray"><span className="p-light-gray">06/27/2022, 20:46:21</span></p>
                              </div>

                              <div className="card file-card col-12 col-lg-6">
                                <div className="card-body p-2">
                                  <div className="d-flex justify-content-between p-md-1">
                                    <div className="d-flex flex-row">
                                      <div className="align-self-center">
                                        <div className="file-card-icon  bg-white text-danger">
                                          <i className="fa fa-file-pdf"></i>
                                        </div>
                                      </div>
                                      <div className="ms-2">
                                        <p className="mb-0">Adam Smith resume.pdf</p>
                                        <p className="mb-0">24kb</p>
                                      </div>
                                    </div>
                                    <div className="align-self-center">
                                      <div className="file-card-delete">
                                        <i className="fa-solid fa-trash "></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-lg-6">
                                <div className="card file-card">
                                  <div className="card-body p-2">
                                    <div className="d-flex justify-content-between p-md-1">
                                      <div className="d-flex flex-row">
                                        <div className="align-self-center">
                                          <div className="file-card-icon  bg-white text-danger">
                                            <i className="fa fa-file-pdf"></i>
                                          </div>
                                        </div>
                                        <div className="ms-2">
                                          <p className="mb-0">Licence_document.pdf</p>
                                          <p className="mb-0">200kb</p>
                                        </div>
                                      </div>
                                      <div className="align-self-center">
                                        <div className="file-card-delete">
                                          <i className="fa-solid fa-trash "></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>

                            <div className="tracking-item">
                              <div className="tracking-icon status-inforeceived bg-success">
                                <img src={phoneCallWhiteImg} className="" />
                              </div>
                              <div className="tracking-content">
                                <p>
                                  <span className="p-name">Howard Carroll</span>
                                </p>
                                <p className="p-subtext">2nd attempy: she answered the call and mentioned she is no longer looking for work 3:20 PM</p>
                                <p className="p-dark-gray">Client : / Pay Rates :  $ <span className="p-light-gray">06/26/2022, 19:46:21</span></p>
                              </div>
                            </div>

                            <div className="tracking-item">
                              <div className="tracking-icon status-intransit bg-blue">
                                <img src={bagCheckWhiteImg} />
                              </div>
                              <div className="tracking-content">
                                <p>
                                  <span className="p-name">Cynthia Villa</span>
                                  <span className="p-changed"> Changed status to </span>
                                  <span className="p-status"><span className="badge rounded-pill bg-danger"> Incomplete</span></span>
                                </p>
                                <p className="p-subtext">Reference site about Lorem Ipsum, giving information on its origins</p>
                                <p className="p-dark-gray"><span className="p-light-gray">06/26/2022, 19:46:21</span></p>
                              </div>
                            </div>

                            <div className="tracking-item pb-0">
                              <div className="tracking-icon status-intransit bg-gold">
                                <img src={cloudImg} className="" />
                              </div>
                              <div className="tracking-content">
                                <p>
                                  <span className="p-name">Corey Jimenez</span>
                                  <span className="p-changed"> Uploaded documents</span>
                                </p>
                                <p className="p-subtext">Document type others </p>
                                <span className="p-light-gray">06/26/2022, 20:46:21</span>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="col-md-4 col-lg-4  end-r-3 p-3 ">
                          <div className=" a-card">
                            Attachments
                            <div className="ms-auto">
                              <div className="close-btn">
                                <i className="fa fa-times" aria-hidden="true"></i>
                              </div>
                            </div>
                            <div className="d-flex a-card-item mt-3">
                              <img src={i9Img} />
                              <span className="a-card-item-text">
                                I9
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                2 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={fileImg} />
                              <span className="a-card-item-text">
                                W4
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                3 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={genderImg} />
                              <span className="a-card-item-text">
                                Sexual Harassment
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                6 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={termsImg} />
                              <span className="a-card-item-text">
                                Terms
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                3 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={folderImg} />
                              <span className="a-card-item-text">
                                Others
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                8 Items
                              </span>
                            </div>

                            <div className="d-flex a-card-item mt-3">
                              <img src={filesImg} />
                              <span className="a-card-item-text">
                                All
                              </span>
                              <span className="ms-auto a-card-item-right-text">
                                12 Items
                              </span>
                            </div>

                            <div className="p-3 pt-5">
                              <div className="float-end">
                                <button className="c-btn dark-btn mx-2" type="button" >Add Note</button>
                                <button className="c-btn dark-btn" type="button" >Message</button>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div> */}

                </div>
              </div>
              <div className={`tab-pane fade ${selectedTab === 1 && "show active"}`} id="personal" role="tabpanel" aria-labelledby="personal-tab">
                <div className="card a-card-wrap">
                  {/* Start Personal */}
                  {applicant &&
                    <React.Fragment>
                      <div className="row m-0 p-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblDate" className="field-label" htmlFor="txtDate">Date</label>
                            <label className="field-label-value">{applicant ? toLocalDate(applicant.ApplicationDate) : ""}</label>
                          </div>
                        </div>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblstest" className="field-label" htmlFor="txtDate">Safety Test Result</label>
                            {applicant && applicant.Trainings && applicant.Trainings.Safety ? <div>
                              {applicant && applicant.Trainings && applicant.Trainings.Safety && applicant.Trainings.Safety.Result == 'Failed' && <div className="fail-strip">
                                <span><i className="fa fa-times me-2" aria-hidden="true"></i>{applicant.Trainings.Safety.Result}</span>
                              </div>}
                              {applicant && applicant.Trainings && applicant.Trainings.Safety && applicant.Trainings.Safety.Result == 'Passed' && <div className="pass-strip">
                                <span><i className="fa fa-check me-2" aria-hidden="true"></i>{applicant.Trainings.Safety.Result}</span>
                              </div>}
                            </div>
                              :
                              <div className="ng-strip">
                                <span><i className="fa fa-exclamation-circle me-2" aria-hidden="true"></i>Pending</span>
                              </div>}
                          </div>
                        </div>
                      </div>
                      {/* <div className="row m-0 p-3">
                            <div className="col-md-4">
                              <InputGroup
                                label={'Date'}
                                control={
                                  <ShortText
                                    ShortTextClasses="form-control"
                                    value={applicant ? toLocalDate(applicant.ApplicationDate) : ""}
                                    disabled={true}
                                  />
                                }
                              />
                            </div>
                            <div className="col-md-8">

                            </div>
                          </div> */}

                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblFirstName" className="field-label" htmlFor="txtFirstName">First Name</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.FirstName.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-address-card"></i>}
                                control={<ShortText required={applicant.FirstName.Required} errorMessage={applicant.FirstName.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtFirstName" name="txtFirstName" ShortTextClasses="form-control"
                                  placeholder="" value={applicant ? applicant.FirstName.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblMiddleName" className="field-label" htmlFor="txtMiddleName">Middle Name</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.MiddleName.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-address-card"></i>}
                                control={<ShortText required={applicant.MiddleName.Required} errorMessage={applicant.MiddleName.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtMiddleName" name="txtMiddleName" ShortTextClasses="form-control"
                                  placeholder="" value={applicant ? applicant.MiddleName.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblLastName" className="field-label" htmlFor="txtLastName">Last Name</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.LastName.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-address-card"></i>}
                                control={<ShortText required={applicant.LastName.Required} errorMessage={applicant.LastName.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtLastName" name="txtLastName" ShortTextClasses="form-control"
                                  placeholder="" value={applicant ? applicant.LastName.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblOtherLastName" className="field-label" htmlFor="txtOtherLastName">Other Last Name</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.OtherLastName.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-address-card"></i>}
                                control={<ShortText required={applicant.OtherLastName.Required} errorMessage={applicant.OtherLastName.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted}
                                  id="txtOtherLastName" name="txtOtherLastName" ShortTextClasses="form-control" placeholder=""
                                  value={applicant ? applicant.OtherLastName.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblBirthDate" className="field-label" htmlFor="txtBirthDate">Date Of Birth</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? _getDateText(applicant.DateOfBirth.Data) : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-calendar"></i>}
                                control={<DateText required={applicant.DateOfBirth.Required} errorMessage={applicant.DateOfBirth.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtBirthDate" name="txtBirthDate" DateTextClasses="form-control" placeholder=""
                                  value={applicant ? applicant.DateOfBirth.Data : ""} onChange={(e) => { setAgeOver18(isAgeOver18(e.target.value)); onValueUpdated(e, sections.PERSONAL_DETAILS, null) }} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label className="field-label">Are You Over 18 Years Old?</label>
                          <div className="form-group">
                            {!editMode ? <label className="field-label-value">{(applicantRequestStatus === fetchStatus.SUCCEEDED) ? (ageOver18 ? "Yes" : "No") : ""}</label> :
                              <span className={`age-badge ${ageOver18 ? "age-badge-yes" : "age-badge-no"}`}>
                                <span className="age-badge-icon">
                                  <i className={`fa ${ageOver18 ? "fa-check" : "fa-close"}`}></i>
                                </span>
                                {ageOver18 ? " Yes" : " No"}
                              </span>}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label id="lblAddress" className="field-label" htmlFor="txtAddress">Address</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.Address.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-home"></i>}
                                control={<ShortText required={applicant.Address.Required} errorMessage={applicant.Address.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtAddress" name="txtAddress" ShortTextClasses="form-control"
                                  placeholder="" value={applicant ? applicant.Address.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblCity" className="field-label" htmlFor="txtCity">City</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.City.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-home"></i>}
                                control={<ShortText required={applicant.City.Required} errorMessage={applicant.City.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtCity" name="txtCity" ShortTextClasses="form-control"
                                  placeholder="" value={applicant ? applicant.City.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblState" className="field-label" htmlFor="txtState">State</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.State.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-home"></i>}
                                control={<ShortText required={applicant.State.Required} errorMessage={applicant.State.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtState" name="txtState" ShortTextClasses="form-control"
                                  placeholder="" value={applicant ? applicant.State.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        {/* <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblState" className="field-label" htmlFor="txtState">State</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.State.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-home"></i>}
                                control={<Selector required={applicant.State.required} errorMessage={applicant.State.validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtState" name="txtState" SelectorClasses="form-select"
                                  value={applicant ? applicant.State.Data.trim() : ""}
                                  options={
                                    <React.Fragment>
                                      <option value="AL">AL</option>
                                      <option value="AK">AK</option>
                                      <option value="AR">AR</option>
                                      <option value="AZ">AZ</option>
                                      <option value="CA">CA</option>
                                      <option value="CO">CO</option>
                                      <option value="CT">CT</option>
                                      <option value="DC">DC</option>
                                      <option value="DE">DE</option>
                                      <option value="FL">FL</option>
                                      <option value="GA">GA</option>
                                      <option value="HI">HI</option>
                                      <option value="IA">IA</option>
                                      <option value="ID">ID</option>
                                      <option value="IL">IL</option>
                                      <option value="IN">IN</option>
                                      <option value="KS">KS</option>
                                      <option value="KY">KY</option>
                                      <option value="LA">LA</option>
                                      <option value="MA">MA</option>
                                      <option value="MD">MD</option>
                                      <option value="ME">ME</option>
                                      <option value="MI">MI</option>
                                      <option value="MN">MN</option>
                                      <option value="MO">MO</option>
                                      <option value="MS">MS</option>
                                      <option value="MT">MT</option>
                                      <option value="NC">NC</option>
                                      <option value="NE">NE</option>
                                      <option value="NH">NH</option>
                                      <option value="NJ">NJ</option>
                                      <option value="NM">NM</option>
                                      <option value="NV">NV</option>
                                      <option value="NY">NY</option>
                                      <option value="ND">ND</option>
                                      <option value="OH">OH</option>
                                      <option value="OK">OK</option>
                                      <option value="OR">OR</option>
                                      <option value="PA">PA</option>
                                      <option value="RI">RI</option>
                                      <option value="SC">SC</option>
                                      <option value="SD">SD</option>
                                      <option value="TN">TN</option>
                                      <option value="TX">TX</option>
                                      <option value="UT">UT</option>
                                      <option value="VT">VT</option>
                                      <option value="VA">VA</option>
                                      <option value="WA">WA</option>
                                      <option value="WI">WI</option>
                                      <option value="WV">WV</option>
                                      <option value="WY">WY</option>

                                      <option value="AS">AS</option>
                                      <option value="GU">GU</option>
                                      <option value="MP">MP</option>
                                      <option value="PR">PR</option>
                                      <option value="UM">UM</option>
                                      <option value="VI">VI</option>

                                      <option value="AA">AA</option>
                                      <option value="AP">AP</option>
                                      <option value="AE">AE</option>
                                    </React.Fragment>
                                  } onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />
                            }


                          </div>
                        </div> */}
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblZipcode" className="field-label" htmlFor="txtZipcode">Zipcode</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.ZipCode.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-home"></i>}
                                control={<ZipCodeText required={applicant.ZipCode.Required} errorMessage={applicant.ZipCode.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtZipcode" name="txtZipcode" ZipCodeTextClasses="form-control"
                                  placeholder="" value={applicant ? applicant.ZipCode.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmail" className="field-label" htmlFor="txtEmail">Email Address</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.EmailAddress.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-envelope"></i>}
                                control={<EmailText disabled={true} required={applicant.EmailAddress.Required} errorMessage={applicant.EmailAddress.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmail" name="txtEmail" EmailTextClasses="form-control" placeholder=""
                                  value={applicant ? applicant.EmailAddress.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>


                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblPhone" className="field-label" htmlFor="txtPhone">Phone</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.PhoneNumber.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-phone"></i>}
                                control={<PhoneText required={applicant.PhoneNumber.Required} errorMessage={applicant.PhoneNumber.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtPhone" name="txtPhone" PhoneTextClasses="form-control" placeholder=""
                                  value={applicant ? applicant.PhoneNumber.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblSSN" className="field-label" htmlFor="txtSSN">Social Security Number</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.SSN.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-address-card"></i>}
                                control={<SSNText required={false} errorMessage={applicant.SSN.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtSSN" name="txtSSN" SSNTextClasses="form-control" placeholder=""
                                  value={applicant ? applicant.SSN.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblAlternateEmail" className="field-label" htmlFor="txtAlternateEmail">Alternate Email Address</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant && applicant.AlternateEmailAddress ? applicant.AlternateEmailAddress.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-envelope"></i>}
                                control={<EmailText required={applicant.AlternateEmailAddress ? applicant.AlternateEmailAddress.Required : false} errorMessage={applicant.AlternateEmailAddress ? applicant.AlternateEmailAddress.Validation : "Please enter alternate email address"} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtAlternateEmail" name="txtAlternateEmail" EmailTextClasses="form-control" placeholder=""
                                  value={applicant && applicant.AlternateEmailAddress ? applicant.AlternateEmailAddress.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>


                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblAlternatePhone" className="field-label" htmlFor="txtAlternatePhone">Alternate Phone</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant && applicant.AlternatePhoneNumber ? applicant.AlternatePhoneNumber.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-phone"></i>}
                                control={<PhoneText required={applicant.AlternatePhoneNumber ? applicant.AlternatePhoneNumber.Required : false} errorMessage={applicant.AlternatePhoneNumber ? applicant.AlternatePhoneNumber.Validation : "Please add alternate phone number"} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtAlternatePhone" name="txtAlternatePhone" PhoneTextClasses="form-control" placeholder=""
                                  value={applicant && applicant.AlternatePhoneNumber ? applicant.AlternatePhoneNumber.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblPositions" className="field-label" htmlFor="drpPositions">Position</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? _getTextFromValueList(positionOptions, applicant.Position.Data) : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-id-badge"></i>}
                                control={
                                  <MultiSelector
                                    id="drpPositions"
                                    name="drpPositions"
                                    value={applicant ? applicant.Position.Data : []}
                                    options={positionOptions}
                                    onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                                    required={applicant.Position.Required}
                                    errorMessage={applicant.Position.Validation}
                                    form={APPLICANT_FORM}
                                    submited={applicantFormSubmitted} />
                                } />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblShifts" className="field-label" htmlFor="drpShifts">Shift</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? _getShiftText(applicant.Shift.Data) : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-id-badge"></i>}
                                control={
                                  <MultiSelector
                                    id="drpShifts"
                                    name="drpShifts"
                                    value={applicant ? applicant.Shift.Data : []}
                                    options={shiftsData}
                                    onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                                    required={applicant.Shift.Required}
                                    errorMessage={applicant.Shift.Validation}
                                    form={APPLICANT_FORM}
                                    submited={applicantFormSubmitted} />

                                } />}
                          </div>
                        </div>


                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEmploymentTypes" className="field-label" htmlFor="drpEmploymentTypes">Employment Type</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? _getEmploymentTypeText(applicant.EmploymentType.Data) : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-id-badge"></i>}
                                control={
                                  <MultiSelector
                                    id="drpEmploymentTypes"
                                    name="drpEmploymentTypes"
                                    value={applicant ? applicant.EmploymentType.Data : []}
                                    options={employmentTypesData}
                                    onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                                    required={applicant.EmploymentType.Required}
                                    errorMessage={applicant.EmploymentType.Validation}
                                    form={APPLICANT_FORM}
                                    submited={applicantFormSubmitted} />

                                } />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Marital Status (Tax Filling Status)</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? (applicant.MaritalStatus.Data === "Unmarried" ? "Single" : applicant.MaritalStatus.Data) : ""}</label> :
                              <React.Fragment><div data-form={APPLICANT_FORM} data-invalid={applicant && applicant.MaritalStatus.Data === ""}>
                                <Radio id="rdbtnFSMar"
                                  name="rdbtnFilingStatus"
                                  label="Married"
                                  value="Married"
                                  onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                                  checked={applicant && applicant.MaritalStatus.Data === "Married"} />
                                <Radio id="rdbtnFSSing"
                                  name="rdbtnFilingStatus"
                                  label="Single"
                                  value="Unmarried"
                                  onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                                  checked={applicant && applicant.MaritalStatus.Data === "Unmarried"} />
                                <Radio id="rdbtnFSHOH"
                                  name="rdbtnFilingStatus"
                                  label="Head Of House"
                                  value="HOH"
                                  onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                                  checked={applicant && applicant.MaritalStatus.Data === "HOH"} />
                              </div>
                                {
                                  (aPFInvalid && applicant && applicant.MaritalStatus.Data === "") ?
                                    <span className="error-label">
                                      <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                      Please select marital status
                                    </span>
                                    : <React.Fragment></React.Fragment>
                                }
                              </React.Fragment>
                            }

                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Gender</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.Gender.Data : ""}</label> :
                              <React.Fragment><div data-form={APPLICANT_FORM} data-invalid={applicant && applicant.Gender.Data === ""}>
                                <Radio id="rdbtnMale"
                                  name="rdbtnGender"
                                  label="Male"
                                  value="Male"
                                  onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                                  checked={applicant && applicant.Gender.Data === "Male"} />
                                <Radio id="rdbtnFemale"
                                  name="rdbtnGender"
                                  label="Female"
                                  value="Female"
                                  onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                                  checked={applicant && applicant.Gender.Data === "Female"} />
                                <Radio id="rdbtnOther"
                                  name="rdbtnGender"
                                  label="Others"
                                  value="Other"
                                  onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                                  checked={applicant && applicant.Gender.Data === "Other"} />
                              </div>
                                {
                                  (aPFInvalid && applicant && applicant.Gender.Data === "") ?
                                    <span className="error-label">
                                      <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                      Please select gender
                                    </span>
                                    : <React.Fragment></React.Fragment>
                                }
                              </React.Fragment>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Will you be filing taxes exempt?</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.Filingexempt ? (applicant.Filingexempt.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnFilingexempt"
                                value={applicant && applicant.Filingexempt ? applicant.Filingexempt.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)} />
                            }
                          </div>
                        </div>


                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblDependent" className="field-label" htmlFor="drpDependent">Total Dependent</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.Dependent.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-users"></i>}
                                control={<Selector required={applicant.Dependent.required} errorMessage={applicant.Dependent.validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="drpDependent" name="drpDependent" SelectorClasses="form-select"
                                  value={applicant ? applicant.Dependent.Data : ""}
                                  options={
                                    <React.Fragment>
                                      <option value="0">
                                        0
                                      </option>
                                      <option value="1">
                                        1
                                      </option>
                                      <option value="2">
                                        2
                                      </option>
                                      <option value="3">
                                        3
                                      </option>
                                      <option value="4">
                                        4
                                      </option>
                                      <option value="5">
                                        5
                                      </option>
                                      <option value="6">
                                        6
                                      </option>
                                      <option value="7">
                                        7
                                      </option>
                                      <option value="8">
                                        8
                                      </option>
                                      <option value="9">
                                        9
                                      </option>
                                      <option value="10">
                                        10
                                      </option>
                                    </React.Fragment>
                                  } onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblDependentunder17" className="field-label" htmlFor="drpDependentunder17">Dependent of age under 17</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant && applicant.Dependentunder17 ? applicant.Dependentunder17.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-users"></i>}
                                control={<Selector required={applicant && applicant.Dependentunder17 ? applicant.Dependentunder17.required : false} errorMessage={applicant && applicant.Dependentunder17 ? applicant.Dependentunder17.validation : ""} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="drpDependentunder17" name="drpDependentunder17" SelectorClasses="form-select"
                                  value={applicant && applicant.Dependentunder17 ? applicant.Dependentunder17.Data : ""}
                                  options={
                                    <React.Fragment>
                                      <option value="0">
                                        0
                                      </option>
                                      <option value="1">
                                        1
                                      </option>
                                      <option value="2">
                                        2
                                      </option>
                                      <option value="3">
                                        3
                                      </option>
                                      <option value="4">
                                        4
                                      </option>
                                      <option value="5">
                                        5
                                      </option>
                                      <option value="6">
                                        6
                                      </option>
                                      <option value="7">
                                        7
                                      </option>
                                      <option value="8">
                                        8
                                      </option>
                                      <option value="9">
                                        9
                                      </option>
                                      <option value="10">
                                        10
                                      </option>
                                    </React.Fragment>
                                  } onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}

                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblDependent17Above" className="field-label" htmlFor="drpDependent17Above">Dependent of age 17 or older</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant && applicant.Dependent17Above ? applicant.Dependent17Above.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-users"></i>}
                                control={<Selector required={applicant && applicant.Dependent17Above ? applicant.Dependent17Above.required : false} errorMessage={applicant && applicant.Dependent17Above ? applicant.Dependent17Above.validation : ""} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="drpDependent17Above" name="drpDependent17Above" SelectorClasses="form-select"
                                  value={applicant && applicant.Dependent17Above ? applicant.Dependent17Above.Data : ""}
                                  options={
                                    <React.Fragment>
                                      <option value="0">
                                        0
                                      </option>
                                      <option value="1">
                                        1
                                      </option>
                                      <option value="2">
                                        2
                                      </option>
                                      <option value="3">
                                        3
                                      </option>
                                      <option value="4">
                                        4
                                      </option>
                                      <option value="5">
                                        5
                                      </option>
                                      <option value="6">
                                        6
                                      </option>
                                      <option value="7">
                                        7
                                      </option>
                                      <option value="8">
                                        8
                                      </option>
                                      <option value="9">
                                        9
                                      </option>
                                      <option value="10">
                                        10
                                      </option>
                                    </React.Fragment>
                                  } onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblDeduction" className="field-label" htmlFor="txtDeduction">Deduction</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.Deduction.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-usd"></i>}
                                control={<NumberText min="0.00" max="100000.00" required={applicant.Deduction.Required} errorMessage={applicant.Deduction.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtDeduction" name="txtDeduction" NumberTextClasses="form-control"
                                  value={applicant ? applicant.Deduction.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblLanguage" className="field-label" htmlFor="drpLanguage">Language</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? _getLanguageText(applicant.Language.Data) : ""}</label> :

                              <InputGroup
                                icon={<i className="fa fa-language"></i>}
                                control={<MultiSelector
                                  id="drpLanguage"
                                  name="drpLanguage"
                                  value={applicant ? applicant.Language.Data : []}
                                  options={LanguageData}
                                  onChange={(e, v) => onValueUpdated(e, sections.PERSONAL_DETAILS, v)}
                                  required={applicant.Language.required}
                                  errorMessage={applicant.Language.validation}
                                  form={APPLICANT_FORM}
                                  submited={applicantFormSubmitted} />} />}


                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblEthnicity" className="field-label" htmlFor="drpEthnicity">Ethnicity</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.Ethnicity.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-globe"></i>}
                                control={<Selector required={applicant.Ethnicity.required} errorMessage={applicant.Ethnicity.validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="drpEthnicity" name="drpEthnicity" SelectorClasses="form-select"
                                  value={applicant ? applicant.Ethnicity.Data : ""}
                                  options={
                                    <React.Fragment>
                                      <option value="American Indian or Alaska Native">
                                        American Indian or Alaska Native
                                      </option>
                                      <option value="Asian">
                                        Asian
                                      </option>
                                      <option value="Black or African American">
                                        Black or African American
                                      </option>
                                      <option value="Hispanic or Latino">
                                        Hispanic or Latino
                                      </option>
                                      <option value="Native Hawaiian or Other Pacific Islander">
                                        Native Hawaiian or Other Pacific Islander
                                      </option>
                                      <option value="White">
                                        White
                                      </option>
                                      <option value="Do not want to say">
                                        Do not want to say
                                      </option>
                                    </React.Fragment>
                                  } onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblBenefits" className="field-label" htmlFor="drpBenefits">Benefit</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.Tax.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-money"></i>}
                                control={<Selector required={applicant.Tax.required} errorMessage={applicant.Tax.validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="drpBenefits" name="drpBenefits" SelectorClasses="form-select"
                                  value={applicant ? applicant.Tax.Data : ""}
                                  options={
                                    <React.Fragment>
                                      <option value="Yes">
                                        Yes
                                      </option>
                                      <option value="No">
                                        No
                                      </option>
                                    </React.Fragment>
                                  } onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblFlexibility" className="field-label" htmlFor="drpFlexibility">Flexibility</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.Flexibility.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-recycle"></i>}
                                control={<Selector required={applicant.Flexibility.Required} errorMessage={applicant.Flexibility.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="drpFlexibility" name="drpFlexibility" SelectorClasses="form-select"
                                  value={applicant ? applicant.Flexibility.Data : ""}
                                  options={
                                    <React.Fragment>
                                      <option value="Yes">
                                        Yes
                                      </option>
                                      <option value="No">
                                        No
                                      </option>
                                    </React.Fragment>
                                  } onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>

                      </div>
                      <div className="row m-0 px-3">

                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblTransport" className="field-label" htmlFor="txtTransport">Transport</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.Transport.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-car"></i>}
                                control={<ShortText required={applicant.Transport.required} errorMessage={applicant.Transport.validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtTransport" name="txtTransport" ShortTextClasses="form-control"
                                  value={applicant ? applicant.Transport.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblAppRest" className="field-label" htmlFor="txtAppRest">Application Restrictions</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.Restriction.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-ban"></i>}
                                control={<ShortText required={applicant.Restriction.Required} errorMessage={applicant.Restriction.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtAppRest" name="txtAppRest" ShortTextClasses="form-control"
                                  value={applicant ? applicant.Restriction.Data : ""} onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblReviewerName" className="field-label" htmlFor="txtReviewerName">Reviewer Name</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.Reviewer.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-thumbs-up"></i>}
                                control={<ShortText
                                  required={applicant.Reviewer.Required}
                                  errorMessage={applicant.Reviewer.Validation}
                                  form={APPLICANT_FORM}
                                  submited={applicantFormSubmitted} id="txtReviewerName"
                                  name="txtReviewerName"
                                  ShortTextClasses="form-control"
                                  value={applicant ? applicant.Reviewer.Data : ""}
                                  onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} disabled={true} />}
                              />}
                          </div>
                        </div>

                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">PTO</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? (applicant.IsPTO.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnPTO"
                                value={applicant ? applicant.IsPTO.Data : ""} onSelectionChanged={(e, v) => { setIsPTO(v == "Y"); onValueUpdated(e, sections.PERSONAL_DETAILS, v) }} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblPTODate" className="field-label" htmlFor="txtPTODate">PTO Date</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? _getDateText(applicant.PTODate.Data) : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-calendar"></i>}
                                control={
                                  <DateText required={applicant.PTODate.Required} errorMessage={applicant.PTODate.Validation}
                                    form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtPTODate" name="txtPTODate"
                                    DateTextClasses={`form-control ${!isPTO ? "disabled" : ""}`}
                                    disabled={!isPTO}
                                    value={isPTO ? (applicant ? applicant.PTODate.Data : "") : ""}
                                    onChange={(e) => onValueUpdated(e, sections.PERSONAL_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblLocations" className="field-label" htmlFor="drpLocations">Location</label>

                            {/* {!editMode ? */}
                            <label className="field-label-value">{applicant ? _getLocationText(locationOptions, applicant.Location.Data) : ""}</label>
                          </div>
                        </div>
                      </div>



                    </React.Fragment>
                  }
                  {/* End Personal */}
                </div>
              </div>
              <div className={`tab-pane fade ${selectedTab === 2 && "show active"}`} id="emergencyContact" role="tabpanel" aria-labelledby="emergencyContact-tab">
                <div className="card a-card-wrap">
                  {/* Start emergencyContact */}
                  {applicant &&
                    <React.Fragment>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblContactName" className="field-label" htmlFor="txtContactName">Name</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.ContactName.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-address-card"></i>}
                                control={<ShortText required={applicant.ContactName.Required} errorMessage={applicant.ContactName.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtContactName" name="txtContactName" ShortTextClasses="form-control"
                                  value={applicant ? applicant.ContactName.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMERGENCY_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblRelationship" className="field-label" htmlFor="txtRelationship">Relationship</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.Relationship.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-handshake-o"></i>}
                                control={<ShortText required={applicant.Relationship.Required} errorMessage={applicant.Relationship.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtRelationship" name="txtRelationship" ShortTextClasses="form-control"
                                  value={applicant ? applicant.Relationship.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMERGENCY_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-8">
                          <div className="form-group">
                            <label id="lblContactAddress" className="field-label" htmlFor="txtContactAddress">Address</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.ContactAddress.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-home"></i>}
                                control={<ShortText required={applicant.ContactAddress.Required} errorMessage={applicant.ContactAddress.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtContactAddress" name="txtContactAddress" ShortTextClasses="form-control"
                                  value={applicant ? applicant.ContactAddress.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMERGENCY_DETAILS, null)} />} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblContactPhone" className="field-label" htmlFor="txtContactPhone">Phone Number</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.ContactPhone.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-phone"></i>}
                                control={<PhoneText required={applicant.ContactPhone.Required} errorMessage={applicant.ContactPhone.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtContactPhone" name="txtContactPhone" PhoneTextClasses="form-control"
                                  value={applicant ? applicant.ContactPhone.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMERGENCY_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                  {/* Start emergencyContact */}
                </div>
              </div>
              <div className={`tab-pane fade ${selectedTab === 3 && "show active"}`} id="education" role="tabpanel" aria-labelledby="education-tab">
                <div className="card a-card-wrap">
                  {/* Start education */}
                  {applicant &&
                    <React.Fragment>
                      <div className="row m-0 p-3">

                        {
                          editMode &&
                          <div className="col-md-12">
                            <MindMapSwitch
                              id="rdbtnED"
                              title="Do you have education details?"
                              checked={_isHavingEducationDetails()}
                              onChange={(e, c) => onValueUpdated(e, sections.EDUCATION_DETAILS, c === true ? "Y" : "N")} />
                          </div>
                        }

                        {_isHavingEducationDetails() || !editMode ?
                          <React.Fragment>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="field-label">High School: Completed</label>
                                {!editMode ?
                                  <label className="field-label-value">{applicant ? applicant.HighSchoolCompleted.Data : ""}</label> :
                                  <React.Fragment><div data-form={APPLICANT_FORM} data-invalid={applicant && applicant.MaritalStatus.Data === ""}>
                                    <Radio id="rdbtnHSOne"
                                      name="rdbtnHS"
                                      label="1"
                                      value="1"
                                      onChange={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                                      checked={applicant && applicant.HighSchoolCompleted.Data === "1"} />
                                    <Radio id="rdbtnHSTwo"
                                      name="rdbtnHS"
                                      label="2"
                                      value="2"
                                      onChange={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                                      checked={applicant && applicant.HighSchoolCompleted.Data === "2"} />
                                    <Radio id="rdbtnHSThree"
                                      name="rdbtnHS"
                                      label="3"
                                      value="3"
                                      onChange={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                                      checked={applicant && applicant.HighSchoolCompleted.Data === "3"} />
                                    <Radio id="rdbtnHSFour"
                                      name="rdbtnHS"
                                      label="4"
                                      value="4"
                                      onChange={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                                      checked={applicant && applicant.HighSchoolCompleted.Data === "4"} />
                                  </div>
                                    {
                                      (aPFInvalid && applicant && applicant.HighSchoolCompleted.Data === "") ?
                                        <span className="error-label">
                                          <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                          Please select high school
                                        </span>
                                        : <React.Fragment></React.Fragment>
                                    }
                                  </React.Fragment>
                                }

                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="field-label">Diploma</label>
                                {!editMode ? <label className="field-label-value">{applicant ? (applicant.Diploma.Data === "Y" ? "Yes" : "No") : ""}</label> :
                                  <YesNoRadio
                                    name="rdbtnD"
                                    value={applicant ? applicant.Diploma.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)} />
                                }
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="field-label">G.E.D</label>

                                {!editMode ? <label className="field-label-value">{applicant ? (applicant.GED.Data === "Y" ? "Yes" : "No") : ""}</label> :
                                  <YesNoRadio
                                    name="rdbtnGED"
                                    value={applicant ? applicant.GED.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)} />
                                }
                              </div>
                            </div>

                            {/* <div className="col-md-4">
                                  <InputGroup
                                    editMode={editMode}
                                    label={'High School: Completed'}
                                    control={
                                      <React.Fragment>
                                        <Radio id="rdbtnHSOne"
                                          name="rdbtnHS"
                                          label="1"
                                          value="1"
                                          onChange={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                                          checked={applicant && applicant.HighSchoolCompleted.Data === "1"} />
                                        <Radio id="rdbtnHSTwo"
                                          name="rdbtnHS"
                                          label="2"
                                          value="2"
                                          onChange={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                                          checked={applicant && applicant.HighSchoolCompleted.Data === "2"} />
                                        <Radio id="rdbtnHSThree"
                                          name="rdbtnHS"
                                          label="3"
                                          value="3"
                                          onChange={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                                          checked={applicant && applicant.HighSchoolCompleted.Data === "3"} />
                                        <Radio id="rdbtnHSFour"
                                          name="rdbtnHS"
                                          label="4"
                                          value="4"
                                          onChange={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                                          checked={applicant && applicant.HighSchoolCompleted.Data === "4"} />
                                      </React.Fragment>
                                    }
                                  />
                                </div>
                                <div className="col-md-4">
                                  <InputGroup
                                    editMode={editMode}
                                    label={'Diploma'}
                                    control={
                                      <YesNoRadio
                                        name="rdbtnD"
                                        value={applicant ? applicant.Diploma.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                                      />
                                    }
                                  />
                                </div>
                                <div className="col-md-4">
                                  <InputGroup
                                    editMode={editMode}
                                    label={'G.E.D'}
                                    control={
                                      <YesNoRadio
                                        name="rdbtnGED"
                                        value={applicant ? applicant.GED.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.EDUCATION_DETAILS, v)}
                                      />
                                    }
                                  />
                                </div> */}
                            <div className="col-md-12">
                              <div className="form-group">
                                <label id="lblSchools" className="field-label" htmlFor="txtSchools">School(s)</label>

                                {!editMode ?
                                  <label className="field-label-value">{applicant ? applicant.School.Data : ""}</label> :
                                  <InputGroup
                                    icon={<i className="fa fa-university"></i>}
                                    control={<ShortText required={applicant.School.Required} errorMessage={applicant.School.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtSchools" name="txtSchools" ShortTextClasses="form-control"
                                      value={applicant ? applicant.School.Data : ""} onChange={(e) => onValueUpdated(e, sections.EDUCATION_DETAILS, null)} />} />}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label id="lblEDCityState" className="field-label" htmlFor="txtEDCityState">City / State</label>

                                {!editMode ?
                                  <label className="field-label-value">{applicant ? applicant.CityState.Data : ""}</label> :
                                  <InputGroup
                                    icon={<i className="fa fa-map-marker"></i>}
                                    control={<ShortText required={applicant.CityState.Required} errorMessage={applicant.CityState.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEDCityState" name="txtEDCityState" ShortTextClasses="form-control"
                                      value={applicant ? applicant.CityState.Data : ""} onChange={(e) => onValueUpdated(e, sections.EDUCATION_DETAILS, null)} />} />}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label id="lblEDOthers" className="field-label" htmlFor="txtEDOthers">Others</label>
                                {!editMode ?
                                  <label className="field-label-value">{applicant ? applicant.Other.Data : ""}</label> :
                                  <InputGroup
                                    icon={<i className="fa fa-pencil-square-o"></i>}
                                    control={<ShortText required={applicant.Other.Required} errorMessage={applicant.Other.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEDOthers" name="txtEDOthers" ShortTextClasses="form-control"
                                      value={applicant ? applicant.Other.Data : ""} onChange={(e) => onValueUpdated(e, sections.EDUCATION_DETAILS, null)} />} />}
                              </div>
                            </div>
                          </React.Fragment>
                          :
                          ''
                        }
                      </div>
                    </React.Fragment>
                  }
                  {/* Start education */}
                </div>
              </div>
              <div className={`tab-pane fade ${selectedTab === 4 && "show active"}`} id="employment" role="tabpanel" aria-labelledby="employment-tab">
                <div className="card a-card-wrap">
                  {/* Start employment */}
                  {applicant &&
                    <div className="row m-0 p-3">

                      {
                        editMode && <div className="col-md-12">
                          <MindMapSwitch
                            id="rdbtnEMYD"
                            title="Do you have employment history?"
                            checked={_isHavingEmployerDetails()}
                            onChange={(e, c) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, c === true ? "Y" : "N")}
                          />
                        </div>
                      }
                      {_isHavingEmployerDetails() || !editMode ?
                        <React.Fragment>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblEmployer1" className="field-label" htmlFor="txtEmployer1">Employer</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.Employer1.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-industry"></i>}
                                  control={<ShortText required={applicant.Employer1.Required} errorMessage={applicant.Employer1.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployer1" name="txtEmployer1" ShortTextClasses="form-control"
                                    value={applicant ? applicant.Employer1.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>

                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblEmployerAddress1" className="field-label" htmlFor="txtEmployerAddress1">Address</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.EmployerAddress1.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-map-marker"></i>}
                                  control={<ShortText required={applicant.EmployerAddress1.Required} errorMessage={applicant.EmployerAddress1.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerAddress1" name="txtEmployerAddress1" ShortTextClasses="form-control"
                                    value={applicant ? applicant.EmployerAddress1.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}

                            </div>

                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblEmployerPhone1" className="field-label" htmlFor="txtEmployerPhone1">Company Phone Number</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.EmployerPhone1.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-phone"></i>}
                                  control={<PhoneText required={applicant.EmployerPhone1.Required} errorMessage={applicant.EmployerPhone1.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerPhone1" name="txtEmployerPhone1" PhoneTextClasses="form-control"
                                    value={applicant ? applicant.EmployerPhone1.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}

                            </div>

                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblSupervisor1" className="field-label" htmlFor="txtSupervisor1">Supervisor</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.Supervisor1.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-user-circle"></i>}
                                  control={<ShortText required={applicant.Supervisor1.Required} errorMessage={applicant.Supervisor1.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtSupervisor1" name="txtSupervisor1" ShortTextClasses="form-control"
                                    value={applicant ? applicant.Supervisor1.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblPosition1" className="field-label" htmlFor="drpPosition1">Position</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? _getTextFromValueList(positionOptions, applicant.Position1.Data) : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-id-badge"></i>}
                                  control={
                                    <MultiSelector
                                      id="drpPosition1"
                                      name="drpPosition1"
                                      value={applicant ? applicant.Position1.Data : []}
                                      options={positionOptions}
                                      onChange={(e, v) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v)}
                                      required={applicant.Position1.Required}
                                      errorMessage={applicant.Position1.Validation}
                                      form={APPLICANT_FORM}
                                      submited={applicantFormSubmitted} />
                                  } />}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblEmployerTelePhone1" className="field-label" htmlFor="txtEmployerTelePhone1">Supervisor Number</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.EmployerTelePhone1.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-fax"></i>}
                                  control={<PhoneText required={applicant.EmployerTelePhone1.Required} errorMessage={applicant.EmployerTelePhone1.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerTelePhone1" name="txtEmployerTelePhone1" PhoneTextClasses="form-control"
                                    value={applicant ? applicant.EmployerTelePhone1.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>


                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="field-label">Currently Employed?</label>
                              {!editMode ? <label className="field-label-value">{applicant ? (applicant.CurrentlyEmployed.Data === "Y" ? "Yes" : "No") : ""}</label> :
                                <YesNoRadio
                                  name="rdbtnCEM"
                                  value={applicant ? applicant.CurrentlyEmployed.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v)} />
                              }
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblDOEFrom1" className="field-label" htmlFor="txtDOEFrom1">Date Of Employment From</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? _getDateText(applicant.DOEFrom1.Data) : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-calendar"></i>}
                                  control={<DateText required={applicant.DOEFrom1.Required} errorMessage={applicant.DOEFrom1.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtDOEFrom1" name="txtDOEFrom1" DateTextClasses="form-control"
                                    value={applicant ? applicant.DOEFrom1.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)}
                                    max={applicant && applicant.DOETo1 && applicant.DOETo1.Data} />} />}
                            </div>
                          </div>

                          {_isCurrentlyEmployed() ?
                            <></>
                            : <div className="col-md-4">
                              <div className="form-group">
                                <label id="lblDOETo1" className="field-label" htmlFor="txtDOETo1">Date Of Employment To</label>
                                {!editMode ?
                                  <label className="field-label-value">{applicant ? _getDateText(applicant.DOETo1.Data) : ""}</label> :
                                  <InputGroup
                                    icon={<i className="fa fa-calendar"></i>}
                                    control={<DateText required={applicant.DOETo1.Required} errorMessage={applicant.DOETo1.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtDOETo1" name="txtDOETo1" DateTextClasses="form-control"
                                      value={applicant ? applicant.DOETo1.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)}
                                      min={applicant && applicant.DOEFrom1 && applicant.DOEFrom1.Data} />} />}
                              </div>
                            </div>
                          }

                          {!_isCurrentlyEmployed() && (applicant && applicant.DOEFrom1.Data !== '' ? true : false) && (applicant && applicant.DOETo1.Data !== '' ? true : false) ?
                            <div className="col-md-12">
                              <div className="form-group">
                                <label id="lblReasonForLeaving1" className="field-label" htmlFor="txtReasonForLeaving1">Reason For Leaving</label>
                                {!editMode ?
                                  <label className="field-label-value">{applicant ? applicant.ReasonForLeaving1.Data : ""}</label> :
                                  <InputGroup
                                    icon={<i className="fa fa-question-circle"></i>}
                                    control={<ShortText required={applicant.ReasonForLeaving1.Required} errorMessage={applicant.ReasonForLeaving1.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtReasonForLeaving1" name="txtReasonForLeaving1" ShortTextClasses="form-control"
                                      value={applicant ? applicant.ReasonForLeaving1.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                              </div>
                            </div>
                            : <></>
                          }

                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblEmployerEmployedBy1" className="field-label" htmlFor="drpEmployerEmployedBy1">Employed By</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant && applicant.EmployerEmployedBy1 ? applicant.EmployerEmployedBy1.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-users"></i>}
                                  control={<Selector required={applicant && applicant.EmployerEmployedBy1 ? applicant.EmployerEmployedBy1.Required : false} errorMessage={applicant && applicant.EmployerEmployedBy1 ? applicant.EmployerEmployedBy1.Validation : ""} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="drpEmployerEmployedBy1" name="drpEmployerEmployedBy1" SelectorClasses="form-select"
                                    value={applicant && applicant.EmployerEmployedBy1 ? applicant.EmployerEmployedBy1.Data : ""}
                                    options={
                                      <React.Fragment>
                                        <option value="Company Direct Hire">
                                          Company Direct Hire
                                        </option>
                                        <option value="Staffing Agency">
                                          Staffing Agency
                                        </option>
                                      </React.Fragment>
                                    } onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblEmployerAgencyName1" className="field-label" htmlFor="txtEmployerAgencyName1">Employer Agency Name</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant && applicant.EmployerAgencyName1 ? applicant.EmployerAgencyName1.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-address-card"></i>}
                                  control={<ShortText required={applicant.EmployerAgencyName1 ? applicant.EmployerAgencyName1.Required : false} errorMessage={applicant.EmployerAgencyName1 ? applicant.EmployerAgencyName1.Validation : "Please enter employer Agency Name"} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerAgencyName1" name="txtEmployerAgencyName1" ShortTextClasses="form-control"
                                    disabled={applicant && applicant.EmployerEmployedBy1 && applicant.EmployerEmployedBy1.Data === 'Staffing Agency' ? false : true}
                                    value={applicant && applicant.EmployerEmployedBy1 && applicant.EmployerEmployedBy1.Data === 'Staffing Agency' ? (applicant && applicant.EmployerAgencyName1 ? applicant.EmployerAgencyName1.Data : "") : ''}
                                    onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label id="lblEmployerNote1" className="field-label" htmlFor="txtEmployerNote1">Note</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant && applicant.EmployerNote1 ? applicant.EmployerNote1.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-question-circle"></i>}
                                  control={<Statement required={applicant.EmployerNote1 ? applicant.EmployerNote1.Required : false} errorMessage={applicant.EmployerNote1 ? applicant.EmployerNote1.Validation : "Please enter Note"} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerNote1" name="txtEmployerNote1" StatementClasses="form-control"
                                    value={applicant && applicant.EmployerNote1 ? applicant.EmployerNote1.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>


                          {/* {
                                editMode && <div className="col-md-12">
                                  <MindMapSwitch
                                    id="rdbtnEMD"
                                    title="Do you have employment details?"
                                    checked={_isHavingEmploymentDetails()}
                                    onChange={(e, c) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, c === true ? "Y" : "N")} />
                                </div>
                              } */}
                          {/* {_isHavingEmploymentDetails() || !editMode ? */}
                          {/* <React.Fragment> */}

                          {/* <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="field-label">Currently Employed?</label>
                                      {!editMode ? <label className="field-label-value">{applicant ? (applicant.CurrentlyEmployed.Data === "Y" ? "Yes" : "No") : ""}</label> :
                                        <YesNoRadio
                                          name="rdbtnCEM"
                                          value={applicant ? applicant.CurrentlyEmployed.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v)} />
                                      }
                                    </div>
                                  </div> */}


                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="field-label">Any other name for employment at any time?</label>
                              {!editMode ? <label className="field-label-value">{applicant ? (applicant.IsOtherEmploymentName.Data === "Y" ? "Yes" : "No") : ""}</label> :
                                <YesNoRadio
                                  name="rdbtnAONEAge"
                                  value={applicant ? applicant.IsOtherEmploymentName.Data : "N"} onSelectionChanged={(e, v) => { setIsAnotherEmpName(v == "Y"); onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v) }} />
                              }
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblOtherEmploymentName" className="field-label" htmlFor="txtOtherEmploymentName">Other Employment Name</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.OtherEmploymentName.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-address-card"></i>}
                                  control={<ShortText required={applicant.OtherEmploymentName.Required} errorMessage={applicant.OtherEmploymentName.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtOtherEmploymentName" name="txtOtherEmploymentName" ShortTextClasses={`form-control ${!isAnotherEmpName ? "disabled" : ""}`}
                                    disabled={!isAnotherEmpName} value={isAnotherEmpName ? (applicant ? applicant.OtherEmploymentName.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="field-label">Ever been asked to resign?</label>

                              {!editMode ? <label className="field-label-value">{applicant ? (applicant.EverTerminated.Data === "Y" ? "Yes" : "No") : ""}</label> :
                                <YesNoRadio
                                  name="rdbtnEBAR"
                                  value={applicant ? applicant.EverTerminated.Data : "N"} onSelectionChanged={(e, v) => { setIsEverTerminated(v == "Y"); onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v) }} />
                              }
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblExplain" className="field-label" htmlFor="txtExplain">Explain</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.TerminationReason.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-question-circle"></i>}
                                  control={<ShortText required={applicant.TerminationReason.Required} errorMessage={applicant.TerminationReason.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtExplain" name="txtExplain" ShortTextClasses={`form-control ${!isEverTerminated ? "disabled" : ""}`}
                                    disabled={!isEverTerminated} value={isEverTerminated ? (applicant ? applicant.TerminationReason.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>
                          {/* </React.Fragment> */}
                          {/* : <></> */}
                          {/* } */}


                          <div className="col-md-12">
                            <hr />
                          </div>


                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblEmployer2" className="field-label" htmlFor="txtEmployer2">Employer</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.Employer2.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-industry"></i>}
                                  control={<ShortText required={applicant.Employer2.Required} errorMessage={applicant.Employer2.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployer2" name="txtEmployer2" ShortTextClasses="form-control"
                                    value={applicant ? applicant.Employer2.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblEmployerAddress2" className="field-label" htmlFor="txtEmployerAddress2">Address</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.EmployerAddress2.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-map-marker"></i>}
                                  control={<ShortText required={applicant.EmployerAddress2.Required} errorMessage={applicant.EmployerAddress2.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerAddress2" name="txtEmployerAddress2" ShortTextClasses="form-control"
                                    value={applicant ? applicant.EmployerAddress2.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblEmployerPhone2" className="field-label" htmlFor="txtEmployerPhone2">Company Phone Number</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.EmployerPhone2.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-phone"></i>}
                                  control={<PhoneText required={applicant.EmployerPhone2.Required} errorMessage={applicant.EmployerPhone2.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerPhone2" name="txtEmployerPhone2" PhoneTextClasses="form-control"
                                    value={applicant ? applicant.EmployerPhone2.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>


                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblSupervisor2" className="field-label" htmlFor="txtSupervisor2">Supervisor</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.Supervisor2.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-user-circle"></i>}
                                  control={<ShortText required={applicant.Supervisor2.Required} errorMessage={applicant.Supervisor2.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtSupervisor2" name="txtSupervisor2" ShortTextClasses="form-control"
                                    value={applicant ? applicant.Supervisor2.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblPosition2" className="field-label" htmlFor="drpPosition2">Position</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? _getTextFromValueList(positionOptions, applicant.Position2.Data) : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-id-badge"></i>}
                                  control={
                                    <MultiSelector
                                      id="drpPosition2"
                                      name="drpPosition2"
                                      value={applicant ? applicant.Position2.Data : []}
                                      options={positionOptions}
                                      onChange={(e, v) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v)}
                                      required={applicant.Position2.Required}
                                      errorMessage={applicant.Position2.Validation}
                                      form={APPLICANT_FORM}
                                      submited={applicantFormSubmitted} />
                                  } />}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblEmployerTelePhone2" className="field-label" htmlFor="txtEmployerTelePhone2">Supervisor Number</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.EmployerTelePhone2.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-fax"></i>}
                                  control={<PhoneText required={applicant.EmployerTelePhone2.Required} errorMessage={applicant.EmployerTelePhone2.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerTelePhone2" name="txtEmployerTelePhone2" PhoneTextClasses="form-control"
                                    value={applicant ? applicant.EmployerTelePhone2.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblDOEFrom2" className="field-label" htmlFor="txtDOEFrom2">Date Of Employment From</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? _getDateText(applicant.DOEFrom2.Data) : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-calendar"></i>}
                                  control={<DateText required={applicant.DOEFrom2.Required} errorMessage={applicant.DOEFrom2.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtDOEFrom2" name="txtDOEFrom2" DateTextClasses="form-control"
                                    value={applicant ? applicant.DOEFrom2.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)}
                                    max={applicant && applicant.DOETo2 && applicant.DOETo2.Data} />} />}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblDOETo2" className="field-label" htmlFor="txtDOETo2">Date Of Employment To</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? _getDateText(applicant.DOETo2.Data) : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-calendar"></i>}
                                  control={<DateText required={applicant.DOETo2.Required} errorMessage={applicant.DOETo2.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtDOETo2" name="txtDOETo2" DateTextClasses="form-control"
                                    value={applicant ? applicant.DOETo2.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)}
                                    min={applicant && applicant.DOEFrom2 && applicant.DOEFrom2.Data} />} />}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label id="lblReasonForLeaving2" className="field-label" htmlFor="txtReasonForLeaving2">Reason For Leaving</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.ReasonForLeaving2.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-question-circle"></i>}
                                  control={<ShortText required={applicant.ReasonForLeaving2.Required} errorMessage={applicant.ReasonForLeaving2.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtReasonForLeaving2" name="txtReasonForLeaving2" ShortTextClasses="form-control"
                                    value={applicant ? applicant.ReasonForLeaving2.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblEmployerEmployedBy2" className="field-label" htmlFor="drpEmployerEmployedBy2">Employed By</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant && applicant.EmployerEmployedBy2 ? applicant.EmployerEmployedBy2.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-users"></i>}
                                  control={<Selector required={applicant && applicant.EmployerEmployedBy2 ? applicant.EmployerEmployedBy2.Required : false} errorMessage={applicant && applicant.EmployerEmployedBy2 ? applicant.EmployerEmployedBy2.Validation : ""} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="drpEmployerEmployedBy2" name="drpEmployerEmployedBy2" SelectorClasses="form-select"
                                    value={applicant && applicant.EmployerEmployedBy2 ? applicant.EmployerEmployedBy2.Data : ""}
                                    options={
                                      <React.Fragment>
                                        <option value="Company Direct Hire">
                                          Company Direct Hire
                                        </option>
                                        <option value="Staffing Agency">
                                          Staffing Agency
                                        </option>
                                      </React.Fragment>
                                    } onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblEmployerAgencyName2" className="field-label" htmlFor="txtEmployerAgencyName2">Employer Agency Name</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant && applicant.EmployerAgencyName2 ? applicant.EmployerAgencyName2.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-address-card"></i>}
                                  control={<ShortText required={applicant.EmployerAgencyName2 ? applicant.EmployerAgencyName2.Required : false} errorMessage={applicant.EmployerAgencyName2 ? applicant.EmployerAgencyName2.Validation : "Please enter employer Agency Name"} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerAgencyName2" name="txtEmployerAgencyName2" ShortTextClasses="form-control"
                                    disabled={applicant && applicant.EmployerEmployedBy2 && applicant.EmployerEmployedBy2.Data === 'Staffing Agency' ? false : true}
                                    value={applicant && applicant.EmployerEmployedBy2 && applicant.EmployerEmployedBy2.Data === 'Staffing Agency' ? (applicant && applicant.EmployerAgencyName2 ? applicant.EmployerAgencyName2.Data : "") : ''}
                                    onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>


                          <div className="col-md-12">
                            <div className="form-group">
                              <label id="lblEmployerNote2" className="field-label" htmlFor="txtEmployerNote2">Note</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant && applicant.EmployerNote2 ? applicant.EmployerNote2.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-question-circle"></i>}
                                  control={<Statement required={applicant.EmployerNote2 ? applicant.EmployerNote2.Required : false} errorMessage={applicant.EmployerNote2 ? applicant.EmployerNote2.Validation : "Please enter Note"} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerNote2" name="txtEmployerNote2" StatementClasses="form-control"
                                    value={applicant && applicant.EmployerNote2 ? applicant.EmployerNote2.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>


                          <div className="col-md-12">
                            <hr />
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblEmployer3" className="field-label" htmlFor="txtEmployer3">Employer</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.Employer3.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-industry"></i>}
                                  control={<ShortText required={applicant.Employer3.Required} errorMessage={applicant.Employer3.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployer3" name="txtEmployer3" ShortTextClasses="form-control"
                                    value={applicant ? applicant.Employer3.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblEmployerAddress3" className="field-label" htmlFor="txtEmployerAddress3">Address</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.EmployerAddress3.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-map-marker"></i>}
                                  control={<ShortText required={applicant.EmployerAddress3.Required} errorMessage={applicant.EmployerAddress3.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerAddress3" name="txtEmployerAddress3" ShortTextClasses="form-control"
                                    value={applicant ? applicant.EmployerAddress3.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblEmployerPhone3" className="field-label" htmlFor="txtEmployerPhone3">Company Phone Number</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.EmployerPhone3.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-phone"></i>}
                                  control={<PhoneText required={applicant.EmployerPhone3.Required} errorMessage={applicant.EmployerPhone3.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerPhone3" name="txtEmployerPhone3" PhoneTextClasses="form-control"
                                    value={applicant ? applicant.EmployerPhone3.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>


                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblSupervisor3" className="field-label" htmlFor="txtSupervisor3">Supervisor</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.Supervisor3.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-user-circle"></i>}
                                  control={<ShortText required={applicant.Supervisor3.Required} errorMessage={applicant.Supervisor3.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtSupervisor3" name="txtSupervisor3" ShortTextClasses="form-control"
                                    value={applicant ? applicant.Supervisor3.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblPosition3" className="field-label" htmlFor="drpPosition3">Position</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? _getTextFromValueList(positionOptions, applicant.Position3.Data) : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-id-badge"></i>}
                                  control={
                                    <MultiSelector
                                      id="drpPosition3"
                                      name="drpPosition3"
                                      value={applicant ? applicant.Position3.Data : []}
                                      options={positionOptions}
                                      onChange={(e, v) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, v)}
                                      required={applicant.Position3.Required}
                                      errorMessage={applicant.Position3.Validation}
                                      form={APPLICANT_FORM}
                                      submited={applicantFormSubmitted} />
                                  } />}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label id="lblEmployerTelePhone3" className="field-label" htmlFor="txtEmployerTelePhone3">Supervisor Number</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.EmployerTelePhone3.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-fax"></i>}
                                  control={<PhoneText required={applicant.EmployerTelePhone3.Required} errorMessage={applicant.EmployerTelePhone3.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerTelePhone3" name="txtEmployerTelePhone3" PhoneTextClasses="form-control"
                                    value={applicant ? applicant.EmployerTelePhone3.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblDOEFrom3" className="field-label" htmlFor="txtDOEFrom3">Date Of Employment From</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? _getDateText(applicant.DOEFrom3.Data) : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-calendar"></i>}
                                  control={<DateText required={applicant.DOEFrom3.Required} errorMessage={applicant.DOEFrom3.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtDOEFrom3" name="txtDOEFrom3" DateTextClasses="form-control"
                                    value={applicant ? applicant.DOEFrom3.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)}
                                    max={applicant && applicant.DOETo3 && applicant.DOETo3.Data} />} />}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblDOETo3" className="field-label" htmlFor="txtDOETo3">Date Of Employment To</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? _getDateText(applicant.DOETo3.Data) : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-calendar"></i>}
                                  control={<DateText required={applicant.DOETo3.Required} errorMessage={applicant.DOETo3.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtDOETo3" name="txtDOETo3" DateTextClasses="form-control"
                                    value={applicant ? applicant.DOETo3.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)}
                                    min={applicant && applicant.DOEFrom3 && applicant.DOEFrom3.Data} />} />}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label id="lblReasonForLeaving3" className="field-label" htmlFor="txtReasonForLeaving3">Reason For Leaving</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.ReasonForLeaving3.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-question-circle"></i>}
                                  control={<ShortText required={applicant.ReasonForLeaving3.Required} errorMessage={applicant.ReasonForLeaving3.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtReasonForLeaving3" name="txtReasonForLeaving3" ShortTextClasses="form-control"
                                    value={applicant ? applicant.ReasonForLeaving3.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>


                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblEmployerEmployedBy3" className="field-label" htmlFor="drpEmployerEmployedBy3">Employed By</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant && applicant.EmployerEmployedBy3 ? applicant.EmployerEmployedBy3.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-users"></i>}
                                  control={<Selector required={applicant && applicant.EmployerEmployedBy3 ? applicant.EmployerEmployedBy3.Required : false} errorMessage={applicant && applicant.EmployerEmployedBy3 ? applicant.EmployerEmployedBy3.Validation : ""} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="drpEmployerEmployedBy3" name="drpEmployerEmployedBy3" SelectorClasses="form-select"
                                    value={applicant && applicant.EmployerEmployedBy3 ? applicant.EmployerEmployedBy3.Data : ""}
                                    options={
                                      <React.Fragment>
                                        <option value="Company Direct Hire">
                                          Company Direct Hire
                                        </option>
                                        <option value="Staffing Agency">
                                          Staffing Agency
                                        </option>
                                      </React.Fragment>
                                    } onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblEmployerAgencyName3" className="field-label" htmlFor="txtEmployerAgencyName3">Employer Agency Name</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant && applicant.EmployerAgencyName3 ? applicant.EmployerAgencyName3.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-address-card"></i>}
                                  control={<ShortText required={applicant.EmployerAgencyName3 ? applicant.EmployerAgencyName3.Required : false} errorMessage={applicant.EmployerAgencyName3 ? applicant.EmployerAgencyName3.Validation : "Please enter employer Agency Name"} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerAgencyName3" name="txtEmployerAgencyName3" ShortTextClasses="form-control"
                                    disabled={applicant && applicant.EmployerEmployedBy3 && applicant.EmployerEmployedBy3.Data === 'Staffing Agency' ? false : true}
                                    value={applicant && applicant.EmployerEmployedBy3 && applicant.EmployerEmployedBy3.Data === 'Staffing Agency' ? (applicant && applicant.EmployerAgencyName3 ? applicant.EmployerAgencyName3.Data : "") : ''}
                                    onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <label id="lblEmployerNote3" className="field-label" htmlFor="txtEmployerNote3">Note</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant && applicant.EmployerNote3 ? applicant.EmployerNote3.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-question-circle"></i>}
                                  control={<Statement required={applicant.EmployerNote3 ? applicant.EmployerNote3.Required : false} errorMessage={applicant.EmployerNote3 ? applicant.EmployerNote3.Validation : "Please enter Note"} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtEmployerNote3" name="txtEmployerNote3" StatementClasses="form-control"
                                    value={applicant && applicant.EmployerNote3 ? applicant.EmployerNote3.Data : ""} onChange={(e) => onValueUpdated(e, sections.EMPLOYMENT_DETAILS, null)} />} />}
                            </div>
                          </div>

                        </React.Fragment>
                        : ''
                      }



                    </div>
                  }
                  {/* Start employment */}
                </div>
              </div>
              <div className={`tab-pane fade ${selectedTab === 5 && "show active"}`} id="references" role="tabpanel" aria-labelledby="references-tab">
                <div className="card a-card-wrap">
                  {/* Start references */}
                  {applicant &&
                    <React.Fragment>
                      {
                        editMode &&
                        <div className="row m-0 px-3 pt-3">
                          <div className="col-md-12">
                            <MindMapSwitch
                              id="rdbtnRFD"
                              title="Do you have any references?"
                              checked={_isHavingReferenceDetails()}
                              onChange={(e, c) => onValueUpdated(e, sections.REFERENCE_DETAILS, c === true ? "Y" : "N")}
                            />
                          </div>
                        </div>
                      }
                      {_isHavingReferenceDetails() || !editMode ?
                        <React.Fragment>
                          <div className="row m-0 px-3">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label id="lblRefName1" className="field-label" htmlFor="txtRefName1">Name</label>

                                {!editMode ?
                                  <label className="field-label-value">{applicant ? applicant.ReferenceName1.Data : ""}</label> :
                                  <InputGroup
                                    icon={<i className="fa fa-address-card"></i>}
                                    control={<ShortText required={applicant.ReferenceName1.Required} errorMessage={applicant.ReferenceName1.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtRefName1" name="txtRefName1" ShortTextClasses="form-control"
                                      value={applicant ? applicant.ReferenceName1.Data : ""} onChange={(e) => onValueUpdated(e, sections.REFERENCE_DETAILS, null)} />} />}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label id="lblRefPhone1" className="field-label" htmlFor="txtRefPhone1">Phone Number</label>
                                {!editMode ?
                                  <label className="field-label-value">{applicant ? applicant.ReferencePhone1.Data : ""}</label> :
                                  <InputGroup
                                    icon={<i className="fa fa-phone"></i>}
                                    control={<PhoneText required={applicant.ReferencePhone1.Required} errorMessage={applicant.ReferencePhone1.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtRefPhone1" name="txtRefPhone1" PhoneTextClasses="form-control"
                                      value={applicant ? applicant.ReferencePhone1.Data : ""} onChange={(e) => onValueUpdated(e, sections.REFERENCE_DETAILS, null)} />} />}
                              </div>
                            </div>
                          </div>
                          <div className="row m-0 px-3">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label id="lblRefName2" className="field-label" htmlFor="txtRefName2">Name</label>
                                {!editMode ?
                                  <label className="field-label-value">{applicant ? applicant.ReferenceName2.Data : ""}</label> :
                                  <InputGroup
                                    icon={<i className="fa fa-address-card"></i>}
                                    control={<ShortText required={applicant.ReferenceName2.Required} errorMessage={applicant.ReferenceName2.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtRefName2" name="txtRefName2" ShortTextClasses="form-control"
                                      value={applicant ? applicant.ReferenceName2.Data : ""} onChange={(e) => onValueUpdated(e, sections.REFERENCE_DETAILS, null)} />} />}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label id="lblRefPhone2" className="field-label" htmlFor="txtRefPhone2">Phone Number</label>
                                {!editMode ?
                                  <label className="field-label-value">{applicant ? applicant.ReferencePhone2.Data : ""}</label> :
                                  <InputGroup
                                    icon={<i className="fa fa-phone"></i>}
                                    control={<PhoneText required={applicant.ReferencePhone2.Required} errorMessage={applicant.ReferencePhone2.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtRefPhone2" name="txtRefPhone2" PhoneTextClasses="form-control"
                                      value={applicant ? applicant.ReferencePhone2.Data : ""} onChange={(e) => onValueUpdated(e, sections.REFERENCE_DETAILS, null)} />} />}
                              </div>
                            </div>

                          </div>
                        </React.Fragment>
                        : ''
                      }
                    </React.Fragment>
                  }
                  {/* End references */}
                </div>
              </div>
              <div className={`tab-pane fade ${selectedTab === 6 && "show active"}`} id="otherDetails" role="tabpanel" aria-labelledby="otherDetails-tab">
                <div className="card a-card-wrap">
                  {/* Start references */}
                  {applicant &&
                    <>
                      <React.Fragment>
                        {/* {
                        editMode &&
                        <div className="row m-0 px-3 pt-3">
                          <div className="col-md-12">
                            <MindMapSwitch
                              title="Do you have prior work experience with agencies?"
                              checked={isHavingLWDetails}
                              onChange={(c) => setIsHavingLWDetails(c)} />

                          </div>
                        </div>
                      } */}

                        <div className="row m-0 px-3">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblLastWorkPlace" className="field-label" htmlFor="txtLastWorkPlace">Last Work Place Job / Duties</label>

                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.LastWorkPlace.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-industry"></i>}
                                  control={<ShortText required={applicant.LastWorkPlace.Required} errorMessage={applicant.LastWorkPlace.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtLastWorkPlace" name="txtLastWorkPlace" ShortTextClasses="form-control"
                                    value={applicant ? applicant.LastWorkPlace.Data : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                            </div>
                          </div>
                        </div>

                        <div className="row m-0 px-3">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label id="lblKnowAboutUs" className="field-label" htmlFor="txtKnowAboutUs">How Did You Learn About Us?</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.KnowAboutUs.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-pencil"></i>}
                                  control={<ShortText required={applicant.KnowAboutUs.Required} errorMessage={applicant.KnowAboutUs.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtKnowAboutUs" name="txtKnowAboutUs" ShortTextClasses="form-control"
                                    value={applicant ? applicant.KnowAboutUs.Data : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                            </div>
                          </div>

                        </div>

                        <div className="row m-0 px-3">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="field-label">Has Own Medical Insurance?</label>
                              {!editMode ? <label className="field-label-value">{applicant ? (applicant.IsMedicalInsurance.Data === "Y" ? "Yes" : "No") : ""}</label> :
                                <YesNoRadio
                                  name="rdbtnHOMI"
                                  value={applicant ? applicant.IsMedicalInsurance.Data : ""}
                                  onSelectionChanged={(e, v) => { setHavingMI(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                              }
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblMedicalInsuranceType" className="field-label" htmlFor="txtMedicalInsuranceType">Medical Insurance Type</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? applicant.MedicalInsuranceType.Data : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-heartbeat"></i>}
                                  control={<ShortText required={applicant.MedicalInsuranceType.Required}
                                    errorMessage={applicant.MedicalInsuranceType.Validation}
                                    form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtMedicalInsuranceType"
                                    name="txtMedicalInsuranceType" ShortTextClasses={`form-control ${!havingMI ? "disabled" : ""}`}
                                    disabled={!havingMI}
                                    value={havingMI ? (applicant ? applicant.MedicalInsuranceType.Data : "") : ""}
                                    onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                            </div>
                          </div>

                        </div>

                        <div className="row m-0 px-3">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="field-label">Available For ASAP Orders</label>
                              {!editMode ? <label className="field-label-value">{applicant ? (applicant.ASAPOrders.Data === "Y" ? "Yes" : "No") : ""}</label> :
                                <YesNoRadio
                                  name="rdbtnASAPO"
                                  value={applicant ? applicant.ASAPOrders.Data : ""}
                                  onSelectionChanged={(e, v) => { setHavingASAPO(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label id="lblASAPOAvailableDate" className="field-label" htmlFor="txtASAPOAvailableDate">Available Start Date</label>
                              {!editMode ?
                                <label className="field-label-value">{applicant ? _getDateText(applicant.ASAPOAvailableDate.Data) : ""}</label> :
                                <InputGroup
                                  icon={<i className="fa fa-calendar"></i>}
                                  control={<DateText required={applicant.ASAPOAvailableDate.Required} errorMessage={applicant.ASAPOAvailableDate.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtASAPOAvailableDate"
                                    name="txtASAPOAvailableDate" DateTextClasses={`form-control ${!havingASAPO ? "disabled" : ""}`}
                                    disabled={!havingASAPO}
                                    value={havingASAPO ? (applicant ? applicant.ASAPOAvailableDate.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                            </div>
                          </div>
                        </div>

                        {/* <div className="row m-0 p-3">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div> */}
                      </React.Fragment>

                      {/* <React.Fragment>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Available For OT</label>
                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.OTAvailable.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnAFOT"
                                value={applicant ? applicant.OTAvailable.Data : "N"}
                                onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Willing to commute for other cities</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.WillingToCommute.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnWCOC"
                                value={applicant ? applicant.WillingToCommute.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Fast Paced Environments</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.FastPacedEnvironments.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnFPENV"
                                value={applicant ? applicant.FastPacedEnvironments.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Standing Long Period Of Time (8 Hours)</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.StandingLongPeriod.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnSLPO"
                                value={applicant ? applicant.StandingLongPeriod.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can Work In Hot Tempearture</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.HotTempeartureWork.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnCWIH"
                                value={applicant ? applicant.HotTempeartureWork.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Repetitive Movements</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.RepetitiveMovements.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnREPM"
                                value={applicant ? applicant.RepetitiveMovements.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can Work In Cold Tempearture</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.ColdTempWork.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnCWCT"
                                value={applicant ? applicant.ColdTempWork.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Heavy Lifting (50LBS)</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.HeavyLifting.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnHVLF"
                                value={applicant ? applicant.HeavyLifting.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Understand / Went Over Safety / Injury Protocol</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.SafetyProtocol.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnUNWI"
                                value={applicant ? applicant.SafetyProtocol.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over CA Minimum Wage</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.CAMinimmum ? (applicant.CAMinimmum.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnCAMinimmum"
                                value={applicant && applicant.CAMinimmum ? applicant.CAMinimmum.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over all Job Assignments being Temporary (Temp to Hire)</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.TemptoHire ? (applicant.TemptoHire.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnTemptoHire"
                                value={applicant && applicant.TemptoHire ? applicant.TemptoHire.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over Alcohol and Drug Prohibition</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.AlcoholProhibition ? (applicant.AlcoholProhibition.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnAlcoholProhibition"
                                value={applicant && applicant.AlcoholProhibition ? applicant.AlcoholProhibition.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over Harassment and Bullying Reports</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.HarassmentBullying ? (applicant.HarassmentBullying.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnHarassmentBullying"
                                value={applicant && applicant.HarassmentBullying ? applicant.HarassmentBullying.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over Check Pick Up and Direct Deposit Qualification</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.DirectDeposit ? (applicant.DirectDeposit.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnDirectDeposit"
                                value={applicant && applicant.DirectDeposit ? applicant.DirectDeposit.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Clarified to Associate that SBS is the Employer not the Client</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.ClarifiedEmployer ? (applicant.ClarifiedEmployer.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnClarifiedEmployer"
                                value={applicant && applicant.ClarifiedEmployer ? applicant.ClarifiedEmployer.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Understand / Went Over Uniform Policy</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.UniformPolicy.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnWOUP"
                                value={applicant ? applicant.UniformPolicy.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Understand / Went Over No Call No Show Policy</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.NoShowPolicy.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnWONC"
                                value={applicant ? applicant.NoShowPolicy.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Understand / Went Over Time Clock Use / Policy</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.TimeClockUse.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnWOTCU"
                                value={applicant ? applicant.TimeClockUse.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over Weekly Pay And 3 Sick Days</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.WeeklyPaySL.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnWPSD"
                                value={applicant ? applicant.WeeklyPaySL.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Own Still Toe Boots</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.StillBoots.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnOSTB"
                                value={applicant ? applicant.StillBoots.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Own Slip Resistant Boots</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.SlipResistBoots.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnOSRB"
                                value={applicant ? applicant.SlipResistBoots.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Went Over 30 Mandatory Lunch / Punctuality</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.Punctuality.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnMLPN"
                                value={applicant ? applicant.Punctuality.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Available for 1 Day assignment</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.OneDayAssignment ? (applicant.OneDayAssignment.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnODAS"
                                value={applicant && applicant.OneDayAssignment ? applicant.OneDayAssignment.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can You Read In English?</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.ReadEnglish ? (applicant.ReadEnglish.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnCYRE"
                                value={applicant && applicant.ReadEnglish ? applicant.ReadEnglish.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can You Write In English?</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.WriteEnglish ? (applicant.WriteEnglish.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnCYWE"
                                value={applicant && applicant.WriteEnglish ? applicant.WriteEnglish.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can You Write In Spanish?</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.WriteSpanish ? (applicant.WriteSpanish.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnCYWS"
                                value={applicant && applicant.WriteSpanish ? applicant.WriteSpanish.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can You Read In Spanish?</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.ReadSpanish ? (applicant.ReadSpanish.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnCYRS"
                                value={applicant && applicant.ReadSpanish ? applicant.ReadSpanish.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Do You Have Basic Math Skills(Adding and Substracting)?</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.BasicMathSkills ? (applicant.BasicMathSkills.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnBSAS"
                                value={applicant && applicant.BasicMathSkills ? applicant.BasicMathSkills.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Are You COVID Vaccinated?</label>

                            {!editMode ? <label className="field-label-value">{applicant && applicant.CovidVaccinated ? (applicant.CovidVaccinated.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnAYCV"
                                value={applicant && applicant.CovidVaccinated ? applicant.CovidVaccinated.Data : "N"} onSelectionChanged={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)} />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Can Work Weekends</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.WorkWeekend.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnCWRW"
                                value={applicant ? applicant.WorkWeekend.Data : "N"} onSelectionChanged={(e, v) => { setHavingNoWeekend(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblReasontoNotWork" className="field-label" htmlFor="txtReasontoNotWork">If Not Why</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant && applicant.IfNotWorkWeekend ? applicant.IfNotWorkWeekend.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<ShortText required={applicant && applicant.IfNotWorkWeekend ? applicant.IfNotWorkWeekend.Required : false}
                                  errorMessage={applicant && applicant.IfNotWorkWeekend ? applicant.IfNotWorkWeekend.Validation : ""}
                                  form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtIfNotWorkWeekend"
                                  name="txtIfNotWorkWeekend" ShortTextClasses={`form-control ${!havingNoWeekend ? "disabled" : ""}`}
                                  disabled={!havingNoWeekend}
                                  value={havingNoWeekend ? (applicant && applicant.IfNotWorkWeekend ? applicant.IfNotWorkWeekend.Data : "") : ""}
                                  onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 p-3">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>


                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">General Laber: Loading / Unloading</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.GeneralLaber.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnGLLU"
                                value={applicant ? applicant.GeneralLaber.Data : "N"} onSelectionChanged={(e, v) => { setHavingGENL(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblGLLUYearMonthExpr" className="field-label" htmlFor="txtGLLUYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.GLExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.GLExperience.Required}
                                  errorMessage={applicant.GLExperience.Validation}
                                  form={APPLICANT_FORM}
                                  submited={applicantFormSubmitted}
                                  id="txtGLLUYearMonthExpr"
                                  name="txtGLLUYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingGENL ? "disabled" : ""}`}
                                  disabled={!havingGENL}
                                  value={havingGENL ? (applicant ? applicant.GLExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Shipping & Receiving</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.ShipRec.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnSHRC"
                                value={applicant ? applicant.ShipRec.Data : "N"} onSelectionChanged={(e, v) => { setHavingSHIP(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblSHRCYearMonthExpr" className="field-label" htmlFor="txtSHRCYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.SRExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.SRExperience.Required} errorMessage={applicant.SRExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtSHRCYearMonthExpr" name="txtSHRCYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingSHIP ? "disabled" : ""}`}
                                  disabled={!havingSHIP}
                                  value={havingSHIP ? (applicant ? applicant.SRExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Packaging & Labeling</label>
                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.PacLab.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnPALA"
                                value={applicant ? applicant.PacLab.Data : "N"} onSelectionChanged={(e, v) => { setHavingPCLB(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblPALAYearMonthExpr" className="field-label" htmlFor="txtPALAYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.PLExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.PLExperience.Required} errorMessage={applicant.PLExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtPALAYearMonthExpr" name="txtPALAYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingPCLB ? "disabled" : ""}`}
                                  disabled={!havingPCLB}
                                  value={havingPCLB ? (applicant ? applicant.PLExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Industrial Sewing</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.IndSew.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnINDSE"
                                value={applicant ? applicant.IndSew.Data : "N"} onSelectionChanged={(e, v) => { setHavingINSW(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblINDSEYearMonthExpr" className="field-label" htmlFor="txtINDSEYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.ISWExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.ISWExperience.Required} errorMessage={applicant.ISWExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtINDSEYearMonthExpr" name="txtINDSEYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingINSW ? "disabled" : ""}`}
                                  disabled={!havingINSW}
                                  value={havingINSW ? (applicant ? applicant.ISWExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">RF Scanning</label>
                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.RFScan.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnRFSC"
                                value={applicant ? applicant.RFScan.Data : "N"} onSelectionChanged={(e, v) => { setHavingRFSC(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblRFSCYearMonthExpr" className="field-label" htmlFor="txtRFSCYearMonthExpr">Months Experience</label>

                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.RFSExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.RFSExperience.Required} errorMessage={applicant.RFSExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtRFSCYearMonthExpr" name="txtRFSCYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingRFSC ? "disabled" : ""}`}
                                  disabled={!havingRFSC}
                                  value={havingRFSC ? (applicant ? applicant.RFSExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Assembly Line</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.AssemblyLine.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnASLI"
                                value={applicant ? applicant.AssemblyLine.Data : "N"} onSelectionChanged={(e, v) => { setHavingASLI(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblASLIYearMonthExpr" className="field-label" htmlFor="txtASLIYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.ALExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.ALExperience.Required} errorMessage={applicant.ALExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtASLIYearMonthExpr" name="txtASLIYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingASLI ? "disabled" : ""}`}
                                  disabled={!havingASLI}
                                  value={havingASLI ? (applicant ? applicant.ALExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Food Production</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.FoodProd.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnFOPRO"
                                value={applicant ? applicant.FoodProd.Data : "N"} onSelectionChanged={(e, v) => { setHavingFOPO(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblFOPROYearMonthExpr" className="field-label" htmlFor="txtFOPROYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.FPExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.FPExperience.Required} errorMessage={applicant.FPExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtFOPROYearMonthExpr" name="txtFOPROYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingFOPO ? "disabled" : ""}`}
                                  disabled={!havingFOPO}
                                  value={havingFOPO ? (applicant ? applicant.FPExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Machine Operator</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.MacOpe.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnMACO"
                                value={applicant ? applicant.MacOpe.Data : "N"} onSelectionChanged={(e, v) => { setHavingMNOP(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblMACOYearMonthExpr" className="field-label" htmlFor="txtMACOYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.MOExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.MOExperience.Required} errorMessage={applicant.MOExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtMACOYearMonthExpr" name="txtMACOYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingMNOP ? "disabled" : ""}`}
                                  disabled={!havingMNOP}
                                  value={havingMNOP ? (applicant ? applicant.MOExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Supervisor / Lead</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.SupLead.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnSULE"
                                value={applicant ? applicant.SupLead.Data : "N"} onSelectionChanged={(e, v) => { setHavingSULE(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblSULEYearMonthExpr" className="field-label" htmlFor="txtSULEYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.SULExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.SULExperience.Required} errorMessage={applicant.SULExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtSULEYearMonthExpr" name="txtSULEYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingSULE ? "disabled" : ""}`}
                                  disabled={!havingSULE}
                                  value={havingSULE ? (applicant ? applicant.SULExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Maintainance Mechanic</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.MainMech.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnMAIME"
                                value={applicant ? applicant.MainMech.Data : "N"} onSelectionChanged={(e, v) => { setHavingMAME(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblMAIMEYearMonthExpr" className="field-label" htmlFor="txtMAIMEYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.MaMeExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.MaMeExperience.Required} errorMessage={applicant.MaMeExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtMAIMEYearMonthExpr" name="txtMAIMEYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingMAME ? "disabled" : ""}`}
                                  disabled={!havingMAME}
                                  value={havingMAME ? (applicant ? applicant.MaMeExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Electrician</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.Electrician.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnELEC"
                                value={applicant ? applicant.Electrician.Data : "N"} onSelectionChanged={(e, v) => { setHavingELEC(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblElecYearMonthExpr" className="field-label" htmlFor="txtElecYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.ElecExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.ElecExperience.Required} errorMessage={applicant.ElecExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtElecYearMonthExpr" name="txtElecYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingELEC ? "disabled" : ""}`}
                                  disabled={!havingELEC}
                                  value={havingELEC ? (applicant ? applicant.ElecExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Sanitation</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.Sanitation.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnSANT"
                                value={applicant ? applicant.Sanitation.Data : "N"} onSelectionChanged={(e, v) => { setHavingSANI(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblSANTYearMonthExpr" className="field-label" htmlFor="txtSANTYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.SaniExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.SaniExperience.Required} errorMessage={applicant.SaniExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtSANTYearMonthExpr" name="txtSANTYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingSANI ? "disabled" : ""}`}
                                  disabled={!havingSANI}
                                  value={havingSANI ? (applicant ? applicant.SaniExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Quality Assurance / Quality Control</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.QAQC.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnQAQC"
                                value={applicant ? applicant.QAQC.Data : "N"} onSelectionChanged={(e, v) => { setHavingQAQC(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblQAQCExperience" className="field-label" htmlFor="txtQAQCExperience">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.QAQCExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.QAQCExperience.Required} errorMessage={applicant.QAQCExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtQAQCYearMonthExpr" name="txtQAQCYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingQAQC ? "disabled" : ""}`}
                                  disabled={!havingQAQC}
                                  value={havingQAQC ? (applicant ? applicant.QAQCExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 p-3">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>

                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Forklift Operator</label>
                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.ForkOpe.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnFORO"
                                value={applicant ? applicant.ForkOpe.Data : "N"} onSelectionChanged={(e, v) => { setHavingFLOP(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblForkOpeType" className="field-label" htmlFor="drpForkOpeType">Forklift Operator Type</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? _getForkLiftOperatorText(applicant.ForkOpeType.Data) : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-id-badge"></i>}
                                control={
                                  <MultiSelector
                                    id="drpForkOpeType"
                                    name="drpForkOpeType"
                                    disabled={!havingFLOP}
                                    value={havingFLOP ? (applicant ? applicant.ForkOpeType.Data : []) : []}
                                    options={forkOpeTypeData}
                                    onChange={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)}
                                    required={applicant.ForkOpeType.Required}
                                    errorMessage={applicant.ForkOpeType.Validation}
                                    form={APPLICANT_FORM}
                                    submited={applicantFormSubmitted} />
                                } />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblFOROYearMonthExpr" className="field-label" htmlFor="txtFOROYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.FOExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.FOExperience.Required} errorMessage={applicant.FOExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtFOROYearMonthExpr" name="txtFOROYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingFLOP ? "disabled" : ""}`}
                                  disabled={!havingFLOP}
                                  value={havingFLOP ? (applicant ? applicant.FOExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>


                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <>
                          </>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Forklift Certificate</label>
                            {!editMode ? <label className="field-label-value">{applicant && applicant.ForkliftCertificate ? (applicant.ForkliftCertificate.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnExpDate"
                                value={havingFLOP ? (applicant && applicant.ForkliftCertificate ? applicant.ForkliftCertificate.Data : "") : ""}
                                disabled={!havingFLOP}
                                onSelectionChanged={(e, v) => { setHavingExpDate(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblCertificateExpiryDate" className="field-label" htmlFor="txtCertificateExpiryDate">Certificate Expiry Date</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant && applicant.CertificateExpiryDate ? _getDateText(applicant && applicant.CertificateExpiryDate ? applicant.CertificateExpiryDate.Data : "") : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-calendar"></i>}
                                control={<DateText required={applicant && applicant.CertificateExpiryDate ? applicant.CertificateExpiryDate.Required : false} errorMessage={applicant && applicant.CertificateExpiryDate ? applicant.CertificateExpiryDate.Validation : ""} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtCertificateExpiryDate"
                                  name="txtCertificateExpiryDate" DateTextClasses={`form-control ${!havingExpDate ? "disabled" : ""} `}
                                  disabled={!havingExpDate || !havingFLOP}
                                  value={havingExpDate && havingFLOP ? (applicant && applicant.CertificateExpiryDate ? applicant.CertificateExpiryDate.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Security</label>
                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.Security.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnSECR"
                                value={applicant ? applicant.Security.Data : "N"} onSelectionChanged={(e, v) => { setHavingSECR(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblSecType" className="field-label" htmlFor="drpSecType">Security Type</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? _getSecurityTypeText(applicant.SecType.Data) : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-id-badge"></i>}
                                control={
                                  <MultiSelector
                                    id="drpSecType"
                                    name="drpSecType"
                                    disabled={!havingSECR}
                                    value={havingSECR ? (applicant ? applicant.SecType.Data : []) : []}
                                    options={secutrityTypeData}
                                    onChange={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)}
                                    required={applicant.SecType.Required}
                                    errorMessage={applicant.SecType.Validation}
                                    form={APPLICANT_FORM}
                                    submited={applicantFormSubmitted} />
                                } />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblSECRYearMonthExpr" className="field-label" htmlFor="txtSECRYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.SecExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.SecExperience.Required} errorMessage={applicant.SecExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtSECRYearMonthExpr" name="txtSECRYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingSECR ? "disabled" : ""}`}
                                  disabled={!havingSECR}
                                  value={havingSECR ? (applicant ? applicant.SecExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="field-label">Clerical: Computer Knowledge</label>

                            {!editMode ? <label className="field-label-value">{applicant ? (applicant.CompKnow.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnCLCOMP"
                                value={applicant ? applicant.CompKnow.Data : "N"} onSelectionChanged={(e, v) => { setHavingCMKL(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblCompKnowType" className="field-label" htmlFor="drpCompKnowType">Computer Knowledge Type</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? _getComputerKnowText(applicant.CompKnowType.Data) : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-desktop"></i>}
                                control={
                                  <MultiSelector
                                    id="drpCompKnowType"
                                    name="drpCompKnowType"
                                    disabled={!havingCMKL}
                                    value={havingCMKL ? (applicant ? applicant.CompKnowType.Data : []) : []}
                                    options={compKnowTypeData}
                                    onChange={(e, v) => onValueUpdated(e, sections.OTHER_DETAILS, v)}
                                    required={applicant.CompKnowType.Required}
                                    errorMessage={applicant.CompKnowType.Validation}
                                    form={APPLICANT_FORM}
                                    submited={applicantFormSubmitted} />
                                } />}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label id="lblCLCOMPYearMonthExpr" className="field-label" htmlFor="txtCLCOMPYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant ? applicant.CompKnowExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.CompKnowExperience.Required} errorMessage={applicant.CompKnowExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtCLCOMPYearMonthExpr" name="txtCLCOMPYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingCMKL ? "disabled" : ""}`}
                                  disabled={!havingCMKL}
                                  value={havingCMKL ? (applicant ? applicant.CompKnowExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 p-3">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>

                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Apparel Machine Operator</label>
                            {!editMode ? <label className="field-label-value">{applicant ? applicant.AppMacOpe && (applicant.AppMacOpe.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnAPMACO"
                                value={applicant && applicant.AppMacOpe ? applicant.AppMacOpe.Data : "N"} onSelectionChanged={(e, v) => { setHavingAPMNOP(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblAMOYearMonthExpr" className="field-label" htmlFor="txtAMOYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant && applicant.AMOExperience ? applicant.AMOExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.AMOExperience && applicant.AMOExperience.Required} errorMessage={applicant.AMOExperience && applicant.AMOExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtAMOYearMonthExpr" name="txtAMOYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingAPMNOP ? "disabled" : ""}`}
                                  disabled={!havingAPMNOP}
                                  value={havingAPMNOP ? (applicant && applicant.AMOExperience ? applicant.AMOExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Apparel Loader</label>
                            {!editMode ? <label className="field-label-value">{applicant ? applicant.AppLoa && (applicant.AppLoa.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnAPLO"
                                value={applicant && applicant.AppLoa ? applicant.AppLoa.Data : "N"} onSelectionChanged={(e, v) => { setHavingAPLO(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblAppLoaYearMonthExpr" className="field-label" htmlFor="txtAppLoaYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant && applicant.AppLoaExperience ? applicant.AppLoaExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.AppLoaExperience && applicant.AppLoaExperience.Required} errorMessage={applicant.AppLoaExperience && applicant.AppLoaExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtAppLoaYearMonthExpr" name="txtAppLoaYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingAPLO ? "disabled" : ""}`}
                                  disabled={!havingAPLO}
                                  value={havingAPLO ? (applicant && applicant.AppLoaExperience ? applicant.AppLoaExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Screen Printing</label>
                            {!editMode ? <label className="field-label-value">{applicant ? applicant.ScrPri && (applicant.ScrPri.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnSCPR"
                                value={applicant && applicant.ScrPri ? applicant.ScrPri.Data : "N"} onSelectionChanged={(e, v) => { setHavingSCPR(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblScrPriYearMonthExpr" className="field-label" htmlFor="txtScrPriYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant && applicant.ScrPriExperience ? applicant.ScrPriExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.ScrPriExperience && applicant.ScrPriExperience.Required} errorMessage={applicant.ScrPriExperience && applicant.ScrPriExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtScrPriYearMonthExpr" name="txtScrPriYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingSCPR ? "disabled" : ""}`}
                                  disabled={!havingSCPR}
                                  value={havingSCPR ? (applicant && applicant.ScrPriExperience ? applicant.ScrPriExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 px-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="field-label">Ticketing / Labeling</label>
                            {!editMode ? <label className="field-label-value">{applicant ? applicant.TicLab && (applicant.TicLab.Data === "Y" ? "Yes" : "No") : ""}</label> :
                              <YesNoRadio
                                name="rdbtnTILA"
                                value={applicant && applicant.TicLab ? applicant.TicLab.Data : "N"} onSelectionChanged={(e, v) => { setHavingTILA(v === "Y"); onValueUpdated(e, sections.OTHER_DETAILS, v) }} />
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="lblTicLabYearMonthExpr" className="field-label" htmlFor="txtTicLabYearMonthExpr">Months Experience</label>
                            {!editMode ?
                              <label className="field-label-value">{applicant && applicant.TicLabExperience ? applicant.TicLabExperience.Data : ""}</label> :
                              <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={<NumberText max="99" maxLength={2} required={applicant.TicLabExperience && applicant.TicLabExperience.Required} errorMessage={applicant.TicLabExperience && applicant.TicLabExperience.Validation} form={APPLICANT_FORM} submited={applicantFormSubmitted} id="txtTicLabYearMonthExpr" name="txtTicLabYearMonthExpr"
                                  NumberTextClasses={`form-control ${!havingTILA ? "disabled" : ""}`}
                                  disabled={!havingTILA}
                                  value={havingTILA ? (applicant && applicant.TicLabExperience ? applicant.TicLabExperience.Data : "") : ""} onChange={(e) => onValueUpdated(e, sections.OTHER_DETAILS, null)} />} />}
                          </div>
                        </div>
                      </div>
                      </React.Fragment> */}
                    </>
                  }
                </div>
              </div>
              <div className={`tab-pane fade ${selectedTab === 7 && "show active"}`} id="customDetails" role="tabpanel" aria-labelledby="customDetails-tab">
                <div className="card a-card-wrap">
                  {/* Start custom Details */}
                  {applicant && applicant.CustomFields &&
                    <>
                      <div className="row m-0 px-3">
                        {Object.keys(applicant.CustomFields).map(key => {
                          const field = applicant.CustomFields[key];
                          return (
                            <div className="col-md-4">
                              <div className="form-group" key={field.Id}>
                                <label id={`lbl${key}`} className="field-label" htmlFor={`txt${key}`}>{_splitCamelCase(key)}</label>
                                {!editMode ?
                                  <label className="field-label-value">{field.Data}</label> :
                                  <>
                                    {(() => {
                                      if (["JobTitle", "Availability", "EnglishLevel", "PreferredOverlappedTime", "ExperienceLevel"].includes(key)) {
                                        return (
                                          <InputGroup
                                            icon={<i className="fa fa-address-card"></i>}
                                            control={
                                              <Selector
                                                form={APPLICANT_FORM}
                                                submited={applicantFormSubmitted}
                                                id={field.Id}
                                                name={key}
                                                SelectorClasses="form-select"
                                                placeholder=""
                                                options={
                                                  <>
                                                    {getOptionsByCustomFieldsKey(key)}
                                                  </>
                                                }
                                                value={field.Data}
                                                onChange={(e) => onValueUpdated(e, sections.CUSTOMFIELDS_DETAILS, null)}
                                              />}
                                          />
                                        );
                                      } else if (["ExpectedSalaryHourlyFrom", "ExpectedSalaryHourlyTo", "ExpectedSalaryMonthlyFrom", "ExpectedSalaryMonthlyTo", "WeeklyAvailability"].includes(key)) {
                                        return (
                                          <InputGroup
                                            icon={<i className="fa fa-address-card"></i>}
                                            control={
                                              <NumberText
                                                form={APPLICANT_FORM}
                                                submited={applicantFormSubmitted}
                                                id={field.Id}
                                                name={key}
                                                NumberTextClasses="form-control"
                                                value={field.Data}
                                                onChange={(e) => onValueUpdated(e, sections.CUSTOMFIELDS_DETAILS, null)}
                                                placeholder=""
                                              />
                                            }
                                          />
                                        );
                                      } else {
                                        return (
                                          <InputGroup
                                            icon={<i className="fa fa-address-card"></i>}
                                            control={
                                              <ShortText
                                                form={APPLICANT_FORM}
                                                submited={applicantFormSubmitted}
                                                id={field.Id}
                                                name={key}
                                                ShortTextClasses="form-control"
                                                placeholder=""
                                                value={field.Data}
                                                onChange={(e) => onValueUpdated(e, sections.CUSTOMFIELDS_DETAILS, null)}
                                              />}
                                          />
                                        );
                                      }
                                    })()}
                                  </>}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  }
                </div>
              </div>
              <div className={`tab-pane fade ${selectedTab === 8 && "show active"}`} id="experienceDetails" role="tabpanel" aria-labelledby="experienceDetails-tab">
                <div className="card a-card-wrap a-card-wrap-skill-deatils">
                  {/* <>
                    {(skillGroupOptionsStatus === fetchStatus.SUCCEEDED && experienceOptionsStatus === fetchStatus.SUCCEEDED && applicantRequestStatus === fetchStatus.SUCCEEDED) ? (
                      <>
                        {
                          skillGroupOptions.map((group, index) => (
                            <>
                              <div className="row m-0 px-3 pt-3" key={group.value}>
                                <div className="col-md-11 mb-3">
                                  <div className="d-flex align-items-center">
                                    <p className="p-name">{group.text}</p>
                                  </div>
                                </div>
                                <>
                                  {experienceOptions
                                    .filter(f => f.SkillGroupId === group.value)
                                    .map(expOption => (
                                      <>
                                        <div key={expOption.id} className="col-md-4">
                                          <div className="form-group">
                                            <label className="field-label" htmlFor="txtExperienceName">{expOption.ExperienceName}</label>
                                            {!editMode ?
                                              <label className="field-label-value">{renderLabelData(expOption)}</label>
                                              :
                                              renderInput(expOption)
                                            }
                                          </div>
                                        </div>
                                      </>
                                    ))
                                  }

                                  {index < skillGroupOptions.length - 1 &&
                                    <div className="col-md-12"><hr /></div>}
                                </>
                              </div>
                            </>
                          ))
                        }
                      </>
                    ) : (
                      <></>
                    )}
                  </> */}
                  <>
                    {(skillGroupOptionsStatus === fetchStatus.SUCCEEDED && experienceOptionsStatus === fetchStatus.SUCCEEDED && applicantRequestStatus === fetchStatus.SUCCEEDED) ? (
                      <div class="accordion accordion-flush m-3" id="accordionSkill">
                        {
                          skillGroupOptions.map((group, index) => (
                            <>
                              <div class="accordion-item">
                                <h2 class="accordion-header" id={`flush-heading_${index}`}>
                                  <button class="accordion-button collapsed accordion-button-skill" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse_${index}`} aria-expanded="false" aria-controls={`flush-collapse_${index}`}>
                                    {group.text}
                                  </button>
                                </h2>
                                <div id={`flush-collapse_${index}`} class="accordion-collapse collapse" aria-labelledby={`flush-heading_${index}`} data-bs-parent="#accordionSkill">
                                  <div class="accordion-body">
                                    <div className="row m-0 px-3">
                                      <>
                                        {experienceOptions
                                          .filter(f => f.SkillGroupId === group.value)
                                          .map(expOption => (
                                            <>
                                              <div key={expOption.id} className="col-md-4">
                                                <div className="form-group">
                                                  <label className="field-label" htmlFor="txtExperienceName">{expOption.ExperienceName}</label>
                                                  {!editMode ?
                                                    <label className="field-label-value">{renderLabelData(expOption)}</label>
                                                    :
                                                    renderInput(expOption)
                                                  }
                                                </div>
                                              </div>
                                            </>
                                          ))
                                        }

                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))
                        }
                      </div >
                    ) : (
                      <></>
                    )}
                  </>
                </div>
              </div>

            </div>
            {/* <div className="  wrapper">
                <ul className="StepProgress">
                  <li className="StepProgress-item is-done"><strong>Post a contest</strong></li>
                  <li className="StepProgress-item is-done"><strong>Award an entry</strong>
                    Got more entries that you love? Buy more entries anytime! Just hover on your favorite entry and click the Buy button
                  </li>
                  <li className="StepProgress-item current"><strong>Post a contest</strong></li>
                  <li className="StepProgress-item"><strong>Handover</strong></li>
                  <li className="StepProgress-item"><strong>Provide feedback</strong></li>
                </ul>
              </div> */}



          </div>
        </div>
      </div >




      {
        addNoteFlyout ?
          <AddNoteFlyout
            onClose={() => setAddNoteFlyout(false)}
            applicantId={applicant && applicant.id
            }
          />
          :
          ''
      }

      {
        calledFlyout ?
          <CalledFlyout
            onClose={() => setCalledFlyout(false)}
            applicantId={applicant && applicant.id}
          />
          :
          ''
      }
    </>
  )
}



export default PeopleDetails;