import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllApplicants, updateIsFiltered } from '../../features/prospectsPeople/reducerSlices/prospectsPeopleSlice';
import { compKnowTypeData, forkOpeTypeData, secutrityTypeData } from '../../features/applicant/services/masterData';
import { onApparelLoaderExperienceChanged, onApparelMachineOperatorExperienceChanged, onAssemblyLineExperienceChanged, onAvailableForOTChanged, onBasicMathSkillsChanged, onComputerExperienceChanged, onCovidVaccinatedChanged, onElectricianExperienceChanged, onFastPacedEnvChanged, onFlexibilityChanged, onFoodProductionExperienceChanged, onForkLiftOperatorExperienceChanged, onGenderChanged, onGeneralLaberExperienceChanged, onHeavyLiftingChanged, onIndustrialSewingExperienceChanged, onLanguageChanged, onMachineOperatorExperienceChanged, onMaintainanceMechanicExperienceChanged, onOneDayAssignmentChanged, onPackagingLabelingExperienceChanged, onQualityAssuranceExperienceChanged, onReadEnglishChanged, onReadSpanishChanged, onRepetitiveMovChanged, onRfScanningExperienceChanged, onSanitationExperienceChanged, onScreenPrintingExperienceChanged, onSecurityExperienceChanged, onShippingReceivingExperienceChanged, onSlipResistantBootsChanged, onStillToeBootsChanged, onSupervisorLeadExperienceChanged, onTicketingLabelingExperienceChanged, onWillingToComuteChanged, onWorkinColdTempChanged, onWorkInHotTempChanged, onWorkWeekendsChanged, onWriteEnglishChanged, onWriteSpanishChanged } from '../../features/prospectsPeople/services/prospectsPeopleFilterHandlers';
import { selectSort } from '../Controls/dataSorters/reducerSlices/prospectsPeopleSorterSlice';
import FlexibilityFilter from '../Controls/filters/flexibilityFilter';
import ForkLiftFilter from '../Controls/filters/forkLiftFilter';
import GenderFilter from '../Controls/filters/genderFilter';
import LanguageFilter from '../Controls/filters/languageFilter';
import OtherExperienceFilter from '../Controls/filters/otherExperienceFilter';
import { selectApparelLoaderFilter, selectApparelMachineOperatorFilter, selectAssemblyLineFilter, selectAvailableForOTFilter, selectBasicMathSkillsFilter, selectComputerFilter, selectCovidVaccinatedFilter, selectElectricianFilter, selectFastPacedEnvFilter, selectFlexibilityFilter, selectFoodProductionFilter, selectForkLiftOperatorFilter, selectGenderFilter, selectGeneralLaberFilter, selectGeneralQuestionaireFilterCount, selectHeavyLiftingFilter, selectIndustrialSewingFilter, selectLanguageFilter, selectMachineOperatorFilter, selectMaintainanceMechanicFilter, selectOneDayAssignmentFilter, selectPackagingLabelingFilter, selectQualityAssuranceFilter, selectReadEnglishFilter, selectReadSpanishFilter, selectRepetitiveMovFilter, selectRfScanningFilter, selectSanitationFilter, selectScreenPrintingFilter, selectSecurityFilter, selectShippingReceivingFilter, selectSlipResistantBootsFilter, selectStillToeBootsFilter, selectSupervisorLeadFilter, selectTicketingLabelingFilter, selectWareHouseExperienceFilterCount, selectWhereClause, selectWillingToComuteFilter, selectWorkExperienceOtherFilterCount, selectWorkinColdTempFilter, selectWorkInHotTempFilter, selectWorkWeekendsFilter, selectWriteEnglishFilter, selectWriteSpanishFilter, updateFilter, updateRefreshData } from '../Controls/filters/reducerSlices/prospectsPeopleFilterSlice';
import YesNoChoiceFilter from '../Controls/filters/yesNoChoiceFilter';
import { tenantId } from '../../api/client';

function ProspectsPeopleMoreFilters(props) {
  const dispatch = useDispatch();
  const [isActive, setActive] = useState(false);
  const ToggleClass = () => {
    setActive(!isActive);

    const timer = setTimeout(() => {
      props.onClose(false);
    }, 1500);

  };

  const [selectedTab, setSelectedTab] = useState(0);
  const whereClause = useSelector(selectWhereClause);
  const sort = useSelector(selectSort);

  const gqFilterCount = useSelector(selectGeneralQuestionaireFilterCount);
  const wheFilterCount = useSelector(selectWareHouseExperienceFilterCount);
  const weoFilterCount = useSelector(selectWorkExperienceOtherFilterCount);

  const onFilterClicked = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    await dispatch(updateFilter({
      id: "",
      name: "",
      whereClause: whereClause,
      defaultFilter: false
    }));
    await dispatch(getAllApplicants({
      page: 0,
      model: {
        client: tenantId,
        serviceType: "TENANTUSER",
        operation: "GETPROSPECTSPEOPLE",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: 0,
            limit: 10,
            where: whereClause,
            sort: sort
          })
        }
      }
    }));
    ToggleClass();
  };

  const onClearFilterClicked = async (e) => {
    e.preventDefault();
    dispatch(updateFilter({
      id: "",
      name: "",
      whereClause: undefined,
      defaultFilter: false
    }));
    dispatch(updateRefreshData(true));
  };

  const onTabSelected = (e, v) => {
    setSelectedTab(v);
  };

  return (
    <div className="people-more-filters-wrapper position-fixed top-0 end-0">
      {/* <div className={`people-more-filters-card ${props.applyMoreFilters ? 'close' : 'open'}`}> */}
      {/* <div className={`people-more-filters-card ${isActive ? 'open' : 'close'}`}> */}
      <div className={`people-more-filters-card ${isActive ? 'close' : 'open'}`}>
        <div className="p-3 px-4">
          <div className="float-end">
            <div className="close-btn">
              {/* <i className="fa fa-times" aria-hidden="true" onClick={() => props.onClose(false)}></i> */}
              <i className="fa fa-times" aria-hidden="true" onClick={ToggleClass}></i>
            </div>
          </div>
          <div className="people-more-filters-card-title">Apply More Filters</div>
        </div>

        <div className="people-more-filters-card-sub-title px-4">
          Select filters below to narrow down your search results.<br />
        </div>
        <div className="d-flex p-3 px-4">
          <LanguageFilter languageFilterSelector={selectLanguageFilter} onLanguageChanged={(e, v, locations) => onLanguageChanged(e, v, locations)} />
          <GenderFilter genderFilterSelector={selectGenderFilter} onGenderChanged={(e, v, locations) => onGenderChanged(e, v, locations)} />
          <FlexibilityFilter flexibilityFilterSelector={selectFlexibilityFilter} onFlexibilityChanged={(e, v) => onFlexibilityChanged(e, v)} />
        </div>

        {/* <div className="px-4">
          <button className="c-btn" type="button">General Question</button>
          <button className="c-btn c-btn-gray mx-2" type="button">Warehouse Experience</button>
          <button className="c-btn c-btn-gray" type="button">Others</button>
        </div> */}

        <div className="px-4">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link nav-link-people active" id="general-question-tab" data-bs-toggle="tab" data-bs-target="#general-question" type="button" role="tab" aria-controls="general-question" aria-selected="true">General Questionnaire {gqFilterCount > 0 ? `(${gqFilterCount})` : ""}</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link nav-link-people" id="warehouse-experience-tab" data-bs-toggle="tab" data-bs-target="#warehouse-experience" type="button" role="tab" aria-controls="warehouse-experience" aria-selected="false">Warehouse Experience {wheFilterCount > 0 ? `(${wheFilterCount})` : ""}</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link nav-link-people" id="others-tab" data-bs-toggle="tab" data-bs-target="#others" type="button" role="tab" aria-controls="others" aria-selected="false">Others {weoFilterCount > 0 ? `(${weoFilterCount})` : ""}</button>
            </li>
          </ul>
        </div>

        <div className="people-more-filters-card-body">

          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="general-question" role="tabpanel" aria-labelledby="general-question-tab">
              <ul className="list-group pt-3">
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Available For OT"
                    yesNoChoiceFilterSelector={selectAvailableForOTFilter}
                    onYesNoChoiceChanged={(e, v) => onAvailableForOTChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Willing to commute for other cities"
                    yesNoChoiceFilterSelector={selectWillingToComuteFilter}
                    onYesNoChoiceChanged={(e, v) => onWillingToComuteChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Fast Paced Environments"
                    yesNoChoiceFilterSelector={selectFastPacedEnvFilter}
                    onYesNoChoiceChanged={(e, v) => onFastPacedEnvChanged(e, v)} />
                </li>

                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Can Work In Hot Tempearture"
                    yesNoChoiceFilterSelector={selectWorkInHotTempFilter}
                    onYesNoChoiceChanged={(e, v) => onWorkInHotTempChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Repetitive Movements"
                    yesNoChoiceFilterSelector={selectRepetitiveMovFilter}
                    onYesNoChoiceChanged={(e, v) => onRepetitiveMovChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Can Work In Cold Tempearture"
                    yesNoChoiceFilterSelector={selectWorkinColdTempFilter}
                    onYesNoChoiceChanged={(e, v) => onWorkinColdTempChanged(e, v)} />
                </li>

                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Heavy Lifting (50LBS)"
                    yesNoChoiceFilterSelector={selectHeavyLiftingFilter}
                    onYesNoChoiceChanged={(e, v) => onHeavyLiftingChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Own Still Toe Boots"
                    yesNoChoiceFilterSelector={selectStillToeBootsFilter}
                    onYesNoChoiceChanged={(e, v) => onStillToeBootsChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Own Slip Resistant Boots"
                    yesNoChoiceFilterSelector={selectSlipResistantBootsFilter}
                    onYesNoChoiceChanged={(e, v) => onSlipResistantBootsChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Can Work Weekends"
                    yesNoChoiceFilterSelector={selectWorkWeekendsFilter}
                    onYesNoChoiceChanged={(e, v) => onWorkWeekendsChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Available for 1 Day assignment"
                    yesNoChoiceFilterSelector={selectOneDayAssignmentFilter}
                    onYesNoChoiceChanged={(e, v) => onOneDayAssignmentChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Can You Read In English?"
                    yesNoChoiceFilterSelector={selectReadEnglishFilter}
                    onYesNoChoiceChanged={(e, v) => onReadEnglishChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Can You Write In English?"
                    yesNoChoiceFilterSelector={selectWriteEnglishFilter}
                    onYesNoChoiceChanged={(e, v) => onWriteEnglishChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Can You Write In Spanish?"
                    yesNoChoiceFilterSelector={selectWriteSpanishFilter}
                    onYesNoChoiceChanged={(e, v) => onWriteSpanishChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Can You Read In Spanish?"
                    yesNoChoiceFilterSelector={selectReadSpanishFilter}
                    onYesNoChoiceChanged={(e, v) => onReadSpanishChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Do You Have Basic Math Skills(Adding and Substracting)?"
                    yesNoChoiceFilterSelector={selectBasicMathSkillsFilter}
                    onYesNoChoiceChanged={(e, v) => onBasicMathSkillsChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <YesNoChoiceFilter
                    choiceFor="Are You COVID Vaccinated?"
                    yesNoChoiceFilterSelector={selectCovidVaccinatedFilter}
                    onYesNoChoiceChanged={(e, v) => onCovidVaccinatedChanged(e, v)} />
                </li>

                {/* <li className="list-group-item">
                      Available for 1 Day assignment
                      <div className="float-end">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio2" value="option1" />
                        <label className="form-check-label" for="inlineRadio1">No</label>
                      </div>
                      <div className="float-end pe-3">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio2" value="option1" />
                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                      </div>
                    </li>
                    <li className="list-group-item">
                      Can You Read In English?
                      <div className="float-end">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio2" value="option1" />
                        <label className="form-check-label" for="inlineRadio1">No</label>
                      </div>
                      <div className="float-end pe-3">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio2" value="option1" />
                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                      </div>
                    </li> */}
              </ul>
            </div>
            <div className="tab-pane fade" id="warehouse-experience" role="tabpanel" aria-labelledby="warehouse-experience-tab">
              <ul className="list-group pt-3">
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="General Laber: Loading / Unloading"
                    otherExperienceFilterSelector={selectGeneralLaberFilter}
                    onExperienceChanged={(e, v) => onGeneralLaberExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Shipping & Receiving"
                    otherExperienceFilterSelector={selectShippingReceivingFilter}
                    onExperienceChanged={(e, v) => onShippingReceivingExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Packaging & Labeling"
                    otherExperienceFilterSelector={selectPackagingLabelingFilter}
                    onExperienceChanged={(e, v) => onPackagingLabelingExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="RF Scanning"
                    otherExperienceFilterSelector={selectRfScanningFilter}
                    onExperienceChanged={(e, v) => onRfScanningExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <ForkLiftFilter
                    forkliftTypes={forkOpeTypeData}
                    forkLiftFilterSelector={selectForkLiftOperatorFilter}
                    onForkLiftChanged={(e, v) => onForkLiftOperatorExperienceChanged(e, v)} />
                </li>
              </ul>
            </div>
            <div className="tab-pane fade" id="others" role="tabpanel" aria-labelledby="others-tab">
              <ul className="list-group pt-3">
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Industrial Sewing"
                    otherExperienceFilterSelector={selectIndustrialSewingFilter}
                    onExperienceChanged={(e, v) => onIndustrialSewingExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Assembly Line"
                    otherExperienceFilterSelector={selectAssemblyLineFilter}
                    onExperienceChanged={(e, v) => onAssemblyLineExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Food Production"
                    otherExperienceFilterSelector={selectFoodProductionFilter}
                    onExperienceChanged={(e, v) => onFoodProductionExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Machine Operator"
                    otherExperienceFilterSelector={selectMachineOperatorFilter}
                    onExperienceChanged={(e, v) => onMachineOperatorExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Supervisor / Lead"
                    otherExperienceFilterSelector={selectSupervisorLeadFilter}
                    onExperienceChanged={(e, v) => onSupervisorLeadExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Maintainance Mechanic"
                    otherExperienceFilterSelector={selectMaintainanceMechanicFilter}
                    onExperienceChanged={(e, v) => onMaintainanceMechanicExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Electrician"
                    otherExperienceFilterSelector={selectElectricianFilter}
                    onExperienceChanged={(e, v) => onElectricianExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Sanitation"
                    otherExperienceFilterSelector={selectSanitationFilter}
                    onExperienceChanged={(e, v) => onSanitationExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Quality Assurance / Quality Control"
                    otherExperienceFilterSelector={selectQualityAssuranceFilter}
                    onExperienceChanged={(e, v) => onQualityAssuranceExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    includeType={true}
                    experienceType="Security Type"
                    experienceFor="Security"
                    experienceTypes={secutrityTypeData}
                    otherExperienceFilterSelector={selectSecurityFilter}
                    onExperienceChanged={(e, v) => onSecurityExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    includeType={true}
                    experienceType="Computer Knowledge Type"
                    experienceFor="Clerical: Computer Knowledge"
                    experienceTypes={compKnowTypeData}
                    otherExperienceFilterSelector={selectComputerFilter}
                    onExperienceChanged={(e, v) => onComputerExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Apparel Machine Operator"
                    otherExperienceFilterSelector={selectApparelMachineOperatorFilter}
                    onExperienceChanged={(e, v) => onApparelMachineOperatorExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Apparel Loader"
                    otherExperienceFilterSelector={selectApparelLoaderFilter}
                    onExperienceChanged={(e, v) => onApparelLoaderExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Screen Printing"
                    otherExperienceFilterSelector={selectScreenPrintingFilter}
                    onExperienceChanged={(e, v) => onScreenPrintingExperienceChanged(e, v)} />
                </li>
                <li className="list-group-item">
                  <OtherExperienceFilter
                    experienceFor="Ticketing / Labeling"
                    otherExperienceFilterSelector={selectTicketingLabelingFilter}
                    onExperienceChanged={(e, v) => onTicketingLabelingExperienceChanged(e, v)} />
                </li>
              </ul>
            </div>
          </div>
        </div>




        <div className="p-3">
          <button className="c-btn transparent-btn" type="button" title="Clear Filters" onClick={(e) => onClearFilterClicked(e)} >Clear Filters</button>
          <div className="float-end">
            <button className="c-btn white-btn mx-2" type="button" onClick={ToggleClass}>Cancel</button>
            <button className="c-btn dark-btn" title="Apply Filters" type="button" onClick={(e) => onFilterClicked(e)} >Apply</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProspectsPeopleMoreFilters