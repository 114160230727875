import React, { useEffect, useState } from 'react'

function AvatarGroup(props) {

    const [count, setCount] = useState(0);
    const [gAvatars, setGAvatars] = useState(<></>);

    useEffect(() => {
        if (props.photosData) {
            let avatarsFilter = props.photosData.filter((d) => d.photo !== '').slice(0, 2);
            let avatars = avatarsFilter.map((items) =>
                <div className="g-avatar" title={items.title}>
                    <img src={items.photo} />
                </div>
            );
            setCount(props.photosData.length - avatarsFilter.length);
            setGAvatars(avatars);

        } else {
            setCount(0);
            setGAvatars(<></>);
        }
    }, [props])

    return (
        <div className="g-avatar-group">
            {gAvatars}
            {count > 0 &&
                <div className="g-avatar">
                    <div className="g-hidden-avatars">
                        +{count}
                    </div>
                </div>
            }
        </div>
    )
}

export default AvatarGroup