import store from '../../../store';
import { updateAssignedToFilter, updateLeadStatusFilter, updateLocationFilter } from '../../../component/Controls/filters/reducerSlices/leadFilterSlice';

export const onLeadLocationChanged = async (e, value, locations) => {
    await store.dispatch(updateLocationFilter({
        locations: locations,
        value: value
    }));
};

export const onLeadStatusChanged = async (e, value, shifts) => {
    await store.dispatch(updateLeadStatusFilter({
        shifts: shifts,
        value: value
    }));
};

export const onLeadAssignedToChanged = async (e, value, assignedTos) => {
    await store.dispatch(updateAssignedToFilter({
        assignedTos: assignedTos,
        value: value
    }));
};