import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { FilterType, ForPage } from '../../../common/constants';
// import Content from '../../../components/Controls/custom/content/content';
// import DetailContainer from '../../../components/Controls/custom/content/detailContainer';
// import ListContainer from '../../../components/Controls/custom/content/listContainer';
// import ToolBar from '../../../components/Controls/custom/toolBar';
// import FilterBar from '../../../components/Controls/filterBars/filterBar/filterBar';
// import LocationFilter from '../../../components/Controls/filters/locationFilter';
// import Loader from "react-js-loader";
import {
    selectDefaultFilter,
    selectLocationFilter,
    selectRefreshData,
    selectRoleFilter,
    selectUserStatusFilter,
    selectWhereClause,
    selectWhereClauseChanged,
    updateFilter,
    updateNameFilter,
    updateUserFilter,
    updateUserRefreshData
} from '../../../../component/Controls/filters/reducerSlices/userFilterSlice';
// import RoleFilter from '../../../components/Controls/filters/roleFilter';
// import UserStatusFilter from '../../../components/Controls/filters/userStatusFilter';
// import SearchBar from '../../../components/Controls/searchBars/searchbar';
import { accountService } from '../../../accounts/services/accountService';
// import { onRoleChanged, onUserLocationChanged, onUserStatusChanged } from '../services/userFilterHandler';
import {
    getAllUsers,
    updateIsFiltered,
    selectStatus,
    selectCurrentPage,
    selectTotalItems,
    selectAllUsers,
    updateStatus,
    selectFirstRecord,
    loadSingleData,
    getUserById,
    updateCurrentPage,
    selectUserById,
    updateUser,
    selectModificationStatus,
    selectRemovalStatus,
    selectSingleStatus,
    selectDispatchDetail,
    selectDispatchDetailStatus,
    selectDispatchList,
    selectDispatchListStatus,
    selectDispatchCount,
    selectDispatchCountStatus,
    selectSingleRefreshed,
    getUserForm,
    selectUserFormStatus,
    selectUserForm,
    updateSingleData,
    createNewUser,
    updateUserFilters,
    createUser,
    selectCreationStatus,
    removeUser,
    getDispatchById,
    getDispatchListById,
    getDispatchCountById,
    showUserFlyout
} from '../../reducerSlices/userSlice';
import { fetchStatus, tenantId } from '../../../../api/client';
// import PageContainer from '../../../components/Controls/paginators/paginator/pageContainer';
import Collection from '../../../../component/Controls/paginators/collection';
// import HorizontalCard from '../../../components/Controls/cards/horizontalCard';
// import EllipsisLoader from '../../../components/Controls/loaders/ellipsisLoader';
import nophoto from '../../../../assets/images/clipart/nophoto.png';
// import EditControls from './components/user/editControl';
// import SaveControls from '../../shared/components/saveControls';
import { isFormValid } from '../../../../component/Controls/services/formService';
import { show } from '../../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';
import { messages } from '../../../../common/actionMessages';
import { userStatuses, userRoles, locationsData } from '../../../shared/services/masterData';
// import BasicInfoUser from '../../shared/components/basicinfoUser';
import { getBase64Image, openFileUploader, setUploadedPhoto, _getLocationText } from '../../../../common/common';
// import DetailContent from '../../shared/components/detailContent';
// import { updateLayout } from '../../../components/layouts/reducerSlices/layoutSlice';
// import { LayoutEngine } from '../../../LayoutEngine/layoutEngine';
// import DefaultLayout from '../../shared/layouts/defaultLayout';
// import InputGroup from '../../../components/Controls/custom/inputGroup';
// import ShortText from '../../../components/Controls/textboxes/shortText';
// import PhoneText from '../../../components/Controls/textboxes/phoneText';
// import EmailText from '../../../components/Controls/textboxes/emailText';
// import PasswordText from '../../../components/Controls/textboxes/PasswordText';
// import Selector from '../../../components/Controls/selectors/selector';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import { nanoid } from '@reduxjs/toolkit';
import { onValueChanged, sections } from '../../services/userSaver';
import { onUserStatusUpdated } from '../../services/userStatusUpdate';
// import { onUserPhotoChanged } from '../services/userPhotoSaver';
// import { showUSFModal } from '../../../components/Controls/modals/reducerSlices/saveUserFilterModalPopUpSlice';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { getLocationOptions, selectLocationOptions, selectLocationOptionsRefreshed, selectLocationOptionsStatus } from '../../../locations/reducerSlices/locationSlice';
// import { showCPModal } from '../../../components/Controls/modals/reducerSlices/changePasswordModalPopUpSlice';
import Moment from 'moment';
import PageContainer from '../../../../component/Controls/paginators/paginator/pageContainer';
import Avatar from '../../../../component/Avatar/Avatar';
import { showCPModal } from '../../../../component/Controls/modals/reducerSlices/changePasswordModalPopUpSlice';
// import DateText from '../../../components/Controls/textboxes/DateText';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';
import ReactTooltip from 'react-tooltip';
import GridLoader from '../../../../component/Controls/loaders/gridLoader';
import DialCallButton from '../../../../component/Controls/buttons/dialCallButton';

function User(props) {

    // const _getLocationText = (id) => {
    //     let loc = locationsData.find(l => l.value === id);
    //     if (loc && loc.text) {
    //         return loc.text;
    //     }
    //     return "N/A";
    // }

    const itemsPerPage = 10;
    const USER_FORM = "Userform";

    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState("");
    const [userRecords, setUserRecords] = useState(new Collection());

    const [uFInvalid, setUFInvalid] = useState(false);
    const [ufSubmitted, setUFSubmitted] = useState(false);
    const [createMode, setCreatemode] = useState(false);
    const [editMode, setEditmode] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [statusVisibility, setStatusVisibility] = useState(false);

    const user = useSelector(selectUserById);
    const firstUser = useSelector((state) => selectFirstRecord(state, currentPage));
    const [dummyCards, setDummyCards] = useState([]);
    const currentPage = useSelector(selectCurrentPage);
    const refreshData = useSelector(selectRefreshData);

    const userFormStatus = useSelector(selectUserFormStatus);
    const userForm = useSelector(selectUserForm);

    const userRequestStatus = useSelector(selectSingleStatus);
    const dispatchDetail = useSelector(selectDispatchDetail);
    const disatchDetailStatus = useSelector(selectDispatchDetailStatus);
    const dispatchList = useSelector(selectDispatchList);
    const disatchListStatus = useSelector(selectDispatchListStatus);
    const dispatchCount = useSelector(selectDispatchCount);
    const disatchCountStatus = useSelector(selectDispatchCountStatus);

    const singleRefreshedStatus = useSelector(selectSingleRefreshed);

    const userCreationStatus = useSelector(selectCreationStatus);
    const userModificationStatus = useSelector(selectModificationStatus);
    const userRemovalStatus = useSelector(selectRemovalStatus);

    const totalPageItems = useSelector(selectTotalItems);
    const users = useSelector(selectAllUsers);
    const usersRequestStatus = useSelector(selectStatus);


    const whereClause = useSelector(selectWhereClause);
    const whereClauseChanged = useSelector(selectWhereClauseChanged);
    const defaultFilter = useSelector(selectDefaultFilter);

    const locationOptions = useSelector(selectLocationOptions);
    const locationOptionsStatus = useSelector(selectLocationOptionsStatus);
    const locationOptionsRefreshed = useSelector(selectLocationOptionsRefreshed);
    const [start, setStart] = useState(Moment(Moment(new Date()).add(-1, 'd').format('YYYY/MM/DD')));

    const [base64Image, setBase64Image] = useState("");
    const [file, setFile] = useState();
    const [pageLoaded, setPageLoaded] = useState(false);
    const [roleOptions, setRoleOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [tempUserId, setTempUserId] = useState("");
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [passwordLengthInvalid, setPasswordLengthInvalid] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [disDet, setDisDet] = useState();
    const [disList, setDisList] = useState();
    const [disCount, setDisCount] = useState();

    const [loading, setLoading] = useState(false);

    //const canceledStatus = useSelector(selectCanceledStatus);

    // useEffect(() => {
    //     if (canceledStatus) {
    //         setSelectedStatus(user ? user.Status.Data : "");
    //         dispatch(resetCanceledStatus());
    //     }
    // }, [canceledStatus]);

    // useEffect(() => {
    //     if (!pageLoaded) {
    //         dispatch(updateLayout({
    //             page: 2,
    //             title: "Users"
    //         }));
    //         setPageLoaded(true);
    //         if (userFormStatus === fetchStatus.IDLE) {
    //             _loadForm();
    //         }
    //     }
    // });

    useEffect(() => {
        if (user) {
            setSelectedStatus(user.Status.Data);
        }
    }, [userRequestStatus, user])


    useEffect(() => {
        if (usersRequestStatus === fetchStatus.IDLE) {
            _loadUsers(0, 0, 10, whereClause);
        }
    }, [usersRequestStatus, dispatch]);


    useEffect(() => {
        if (usersRequestStatus === fetchStatus.IDLE) {
            _loadUsers(0, 0, 10, whereClause);
        }
    }, [usersRequestStatus, dispatch]);
    let userCards;
    let _dummyCards = [];

    let _userRecords = new Collection();

    useEffect(() => {
        if (disatchDetailStatus === fetchStatus.SUCCEEDED) {
            let _disDet;

            if (isObjectArray(dispatchDetail) && dispatchDetail.length > 0) {
                _disDet = dispatchDetail.map((disDetail, index) => (
                    <div>
                        <div className="card-border card p-3 mb-2 ">

                            <div className="mt-1">
                                <p className="card-text text-justify">Employee Name: <b>{disDetail ? disDetail.name : ""}</b></p>
                                <p className="card-text text-justify">Show/No Show: <b>{disDetail ? disDetail.dispatchStatus : ""}</b></p>
                                <p className="card-text text-justify">Client Location: <b>{disDetail ? disDetail.clientLocation : ""}</b></p>
                                <p className="card-text text-justify">Start Time: <b>{disDetail ? disDetail.startTime : ""}</b></p>
                                <p className="card-text text-justify">Start Date: <b>{disDetail ? disDetail.startDate : ""}</b></p>
                            </div>
                        </div>
                    </div>


                ));

                setDisDet(_disDet);
            }
            else {
                _disDet = <div> No data found</div>
                setDisDet(_disDet);
            }
        }
    }, [disatchDetailStatus, dispatchDetail]);


    useEffect(() => {
        if (disatchListStatus === fetchStatus.SUCCEEDED) {
            let _disList;

            if (isObjectArray(dispatchList) && dispatchList.length > 0) {

                _disList = dispatchList.map((disList, index) => (


                    <tr key={disList.id}>
                        <td>
                            <label className=" FieldsWidth100"  >{disList ? disList.id : ""}</label>
                        </td>
                        <td>
                            <label className=" FieldsWidth100"  >{disList ? disList.name : ""}</label>
                        </td>
                        <td>
                            <label className="FieldsWidth100">{disList ? disList.clientName : ""}</label>
                        </td>
                        <td>
                            <label className="FieldsWidth100">{disList ? disList.startTime : ""}</label>
                        </td>
                        <td>
                            <label className="FieldsWidth100">{disList ? disList.startDate : ""}</label>
                        </td>
                    </tr>




                ));

                setDisList(_disList);
            }
            else {
                _disList = <div> No data found</div>
                setDisList(_disList);
            }
        }
    }, [disatchListStatus, dispatchList]);

    useEffect(() => {
        if (disatchCountStatus === fetchStatus.SUCCEEDED) {
            let _disCount;

            if (isObjectArray(dispatchCount) && dispatchCount.length > 0) {
                _disCount = dispatchCount.map((disList, index) => (
                    <div className="row">
                        <div className="md-col-2 p-2">
                            <h4><span className="badge badge-primary">{disList.CalledOnTotal} Incoming Calls </span></h4>
                        </div>
                        <div className="md-col-2 p-2">
                            <h4><span className="badge badge-info">{disList.CalledInTotal} Outgoing Calls</span></h4>
                        </div>
                        <div className="md-col-2 p-2">
                            <h4><span className="badge badge-secondary">{disList.ApplicantTotal} Applications Taken</span></h4>
                        </div>
                        <div className="md-col-2 p-2">
                            <h4><span className="badge badge-success">{disList.DispatchTotal} Dispatch</span></h4>
                        </div>
                        <div className="md-col-2 p-2">
                            <h4><span className="badge badge-warning">{disList.StatusChangeTotal} Status Changes</span></h4>
                        </div>


                    </div>
                ));

                setDisCount(_disCount);
            }
            else {
                _disCount = <div className="row">
                    <div className="md-col-2 p-2">
                        <h2><span className="badge badge-primary">{disList.CalledOnTotal} Incoming Calls </span></h2>
                    </div>
                    <div className="md-col-2 p-2">
                        <h2><span className="badge badge-secondary">{disList.ApplicantTotal} Applications Taken</span></h2>
                    </div>
                    <div className="md-col-2 p-2">
                        <h2><span className="badge badge-success">{disList.DispatchTotal} Dispatch</span></h2>
                    </div>
                    <div className="md-col-2 p-2">
                        <h2><span className="badge badge-warning">{disList.StatusChangeTotal} Status Changes</span></h2>
                    </div>

                </div>
                setDisCount(_disCount);

            }

        }
    }, [disatchCountStatus, dispatchCount]);


    useEffect(() => {
        if (usersRequestStatus === fetchStatus.SUCCEEDED) {
            let _records = users.Items;
            // console.log(users)

            if (users.Items && users.Items.length > 0) {
                for (let i = 0; i < users.Items.length; i++) {
                    let item = users.Items[i];
                    userCards = item.records.map((user, index) => (
                        // <HorizontalCard
                        //     key={user.Id}
                        //     horizontalCardClasses="mb-2"
                        //     imageUrl={user.ImageData || nophoto}
                        //     name={user.FirstName + " " + user.LastName}
                        //     email={user.Email}
                        //     phoneNumber={user.PhoneNumber}
                        //     location={_getLocationText(locationOptions, user.Location)}
                        //     status={user.Status}
                        //     statusText={user.Status}
                        //     isOpened={true}
                        //     onClick={() => onUserSelected(user.id)}
                        // />
                        <tr key={user.Id}>
                            <td>{user.FirstName + " " + user.LastName}</td>
                            <td>{user.PhoneNumber}</td>
                            <td>{user.Email}</td>
                            <td>{_getLocationText(locationOptions, user.Location)}</td>
                            <td>{user.Status}</td>
                            <td>
                                <div className='c-icon-box' data-tip='Edit' onClick={() => onUserSelected(user.id)}>
                                    <i className='fa fa-pencil'></i>
                                </div>
                                <div className='c-icon-box' data-tip='Change Password' onClick={(e) => onChangePasswordClicked(e, user ? user.id : "", user ? user : "")}>
                                    <i className='fa fa-lock'></i>
                                </div>
                            </td>
                        </tr>
                    ));
                    _userRecords.Add(item.key, userCards);
                }

                setUserRecords(_userRecords);
                dispatch(updateStatus());
            }
            dispatch(updateStatus());
        }
        // if (users && users.Items.length > 0) {
        //     let item = users.Get(currentPage);
        //     let _users = item ? item.records : [];
        //     if (_users && Array.isArray(_users) && _users.length > 0) {
        //         let _user = _users[0];
        //         if (_user && _user.id) {
        //             if (firstUser && firstUser.records) {
        //                 dispatch(loadSingleData(firstUser.records));
        //             }
        //             else {
        //                 _loadUser(_user.id, currentPage);

        //             }
        //         }
        //     }
        //     else {
        //         dispatch(updateSingleData(undefined));
        //     }
        // }
    }, [usersRequestStatus, currentPage]);//, users]);

    useEffect(() => {
        if (usersRequestStatus === fetchStatus.LOADING) {
            for (let i = 0; i < 10; i++) {
                _dummyCards.push(
                    // <HorizontalCard
                    //     key={i}
                    //     horizontalCardClasses="mb-2 card-loading-cursor"
                    //     imageUrl={nophoto}
                    //     name={<EllipsisLoader />}
                    //     email={<EllipsisLoader />}
                    //     phoneNumber={<EllipsisLoader />}
                    //     location={<EllipsisLoader />}
                    //     status=""
                    //     statusText=""
                    //     isLoading={true}
                    // />
                    <div className="list-loading-content">
                        <ReactPlaceholder type='text' ready={false} rows={2} color='#E0E0E0'>
                        </ReactPlaceholder>
                    </div>
                );
            }
            setDummyCards(_dummyCards);
        }
    }, [usersRequestStatus]);

    useEffect(() => {
        if (refreshData) {
            dispatch(updateIsFiltered());
            _loadUsers(0, 0, 10, whereClause);
            dispatch(updateUserRefreshData(false));
        }
    }, [whereClauseChanged, whereClause, refreshData]);

    // let userLayout;
    // useEffect(() => {
    //     if (userFormStatus === fetchStatus.SUCCEEDED) {
    //         if (userForm && user) {
    //             let layoutEngine = new LayoutEngine(userForm, {
    //                 dataObj: user,
    //                 onChange: onValueUpdated,
    //                 form: USER_FORM,
    //                 submitted: ufSubmitted
    //             });
    //             userLayout = layoutEngine.Create();
    //         }
    //     }

    // }, [userFormStatus, userForm, user]);

    let _roleOptions;
    useEffect(() => {
        if (isObjectArray(userRoles) && userRoles.length > 0) {
            _roleOptions = userRoles.map((role, index) => (
                <option key={nanoid()} value={role.value}>
                    {role.text}
                </option>
            ));
            setRoleOptions(_roleOptions);
        }

    }, [userRoles]);


    let _statusOptions;
    useEffect(() => {
        if (isObjectArray(userStatuses) && userStatuses.length > 0) {
            _statusOptions = userStatuses.map((status, index) => (
                <option key={nanoid()} value={status.value}>
                    {status.text}
                </option>
            ));
            setStatusOptions(_statusOptions);
        }

    }, [userStatuses]);

    useEffect(() => {
        if (usersRequestStatus === fetchStatus.DONE) {
            let _records = users.Items;

            if (users.Items && users.Items.length > 0) {
                for (let i = 0; i < users.Items.length; i++) {
                    let item = users.Items[i];
                    userCards = item.records.map((user, index) => (
                        // <HorizontalCard
                        //     key={user.Id}
                        //     horizontalCardClasses="mb-2"
                        //     imageUrl={user.ImageData || nophoto}
                        //     name={user.FirstName + " " + user.LastName}
                        //     email={user.Email}
                        //     phoneNumber={user.PhoneNumber}
                        //     location={_getLocationText(locationOptions, user.Location)}
                        //     status={user.Status}
                        //     statusText={user.Status}
                        //     isOpened={true}
                        //     onClick={() => onUserSelected(user.id)}
                        // />
                        <tr key={user.Id}>
                            {/* <td className="py-2">
                                <div className="d-flex">
                                    <Avatar PhotoPath={user.ImageData || nophoto} />
                                </div>
                            </td> */}
                            <td>{user.FirstName + " " + user.LastName}</td>
                            <td>{user.PhoneNumber}</td>
                            <td>{user.Email}</td>
                            <td>{_getLocationText(locationOptions, user.Location)}</td>
                            <td>{user.Status}</td>
                            <td>
                                <div className='c-icon-box' data-tip='Edit' onClick={() => onUserSelected(user.id)}>
                                    <i className='fa fa-pencil'></i>
                                </div>
                                <div className='c-icon-box' data-tip='Change Password' onClick={(e) => onChangePasswordClicked(e, user ? user.id : "", user ? user : "")}>
                                    <i className='fa fa-lock'></i>
                                </div>
                            </td>
                        </tr>
                    ));
                    _userRecords.Add(item.key, userCards);
                }

                setUserRecords(_userRecords);
            }
        }

    }, [singleRefreshedStatus]);

    useEffect(() => {
        if (userRemovalStatus === fetchStatus.SUCCEEDED) {
            if (users && users.Items.length > 0) {
                let item = users.Get(currentPage);
                let _users = item ? item.records : [];
                if (_users && Array.isArray(_users) && _users.length > 0) {
                    let _user = _users[0];
                    if (_user && _user.id) {
                        _loadUser(_user.id, currentPage);

                    }
                }
                else {
                    dispatch(updateSingleData(undefined));
                }
            }
            else {
                setUserRecords(undefined);
                dispatch(updateSingleData(undefined));
            }
        }

    }, [userRemovalStatus]);

    useEffect(() => {
        if (userCreationStatus === fetchStatus.SUCCEEDED) {
            setCreatemode(false);
            //setEditmode(false);
            setUFInvalid(false);
            setUFSubmitted(false);
            setPassword("");
            setConfirmPassword("");
            _loadUsers(0, 0, 10, whereClause);
        }
    }, [userCreationStatus]);

    useEffect(() => {
        if (userModificationStatus === fetchStatus.SUCCEEDED) {
            //setCreatemode(false);
            setEditmode(false);
            setUFInvalid(false);
            setUFSubmitted(false);
            _loadUsers(0, 0, 10, whereClause);
        }
    }, [userModificationStatus]);

    useEffect(() => {
        if (locationOptionsRefreshed || locationOptionsStatus === fetchStatus.IDLE) {
            dispatch(getLocationOptions({
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETLOCATIONOPTIONS",
                payload: {
                    id: "",
                    data: JSON.stringify({})
                }
            }));
        }
    }, [locationOptionsStatus, locationOptionsRefreshed]);

    let _locationOptions = undefined;

    if (isObjectArray(locationOptions)) {
        _locationOptions = locationOptions.map((o) => (
            <option value={o.value}>{o.text}</option>
        ));
    }

    const _loadUser = async (id, page) => {
        await dispatch(getUserById({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETUSERBYID",
                payload: {
                    id: id,
                    data: ""
                }
            }
        }));
        setDisDet('');
        setDisCount('');
        setDisList('');
    }



    const OnClickReport = async (id) => {

        // var dt = `Between: ${start} and ${end} at location ${locationid}`;

        setLoading(true);

        await dispatch(getDispatchCountById({
            page: 1,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GECOUNTS",
                payload: {
                    id: id,
                    data: JSON.stringify({
                        id: id, EndDate: Moment(start).add(1, 'd').format('YYYY-MM-DD'),
                        userName: user.FirstName.Data + ' ' + user.LastName.Data,
                        reportDate: Moment(start).format('YYYY-MM-DD'),
                        type: 'User'
                    })
                }
            }
        }));
        await dispatch(getDispatchById({
            page: 1,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GEDISPATCHDETAILS",
                payload: {
                    id: id,
                    data: JSON.stringify({
                        id: id, userName: user.FirstName.Data + ' ' + user.LastName.Data,
                        reportDate: Moment(start).format('YYYY-MM-DD'),
                        type: 'User'
                    })
                }
            }
        }));

        await dispatch(getDispatchListById({
            page: 1,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETDISPATCHLIST",
                payload: {
                    id: id,
                    data: JSON.stringify({
                        id: id, userName: user.FirstName.Data + ' ' + user.LastName.Data,
                        reportDate: Moment(start).format('YYYY-MM-DD'),
                        type: 'User'
                    })
                }
            }
        }));
        setLoading(false);


    };


    const _loadForm = async () => {
        await dispatch(getUserForm({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "GETFORMBYTYPE",
            payload: {
                id: "",
                data: "UserForm"
            }
        }));
    }

    const _loadUsers = async (page, offset, limit, whereClause) => {
        await dispatch(getAllUsers({
            page: page,
            model: {
                client: tenantId,
                serviceType: "TENANTADMIN",
                operation: "GETUSERS",
                payload: {
                    id: "",
                    data: JSON.stringify({
                        offset: offset,
                        limit: 1000,
                        where: whereClause
                    })
                }
            }
        }));

    }

    const onValueUpdated = (e, section, value) => {
        let _userObj = Object.assign({}, user);
        let _newUserObj = onValueChanged(_userObj, e, section, value);
        dispatch(updateSingleData(_newUserObj));
    };

    const onFilterClicked = async (e) => {
        e.preventDefault();
        await dispatch(updateIsFiltered());
        _loadUsers(0, 0, 10, whereClause);
    };

    const onSaveFilterClicked = async (e) => {
        // e.preventDefault();
        // if (whereClauseChanged && !defaultFilter) {
        //     const user = accountService.getAuthenticatedUser();
        //     if (user) {
        //         await dispatch(showUSFModal({
        //             title: `Save Filter For ${user.firstName + " " + user.lastName}`,
        //             userId: user.id
        //         }));
        //     }
        // }
    };

    const onClearFilterClicked = async (e) => {
        // e.preventDefault();
        // dispatch(updateUserFilter({
        //     id: "",
        //     name: "",
        //     whereClause: undefined,
        //     defaultFilter: false
        // }));
        // dispatch(updateUserRefreshData(true));
    };

    const onUserSelected = (id) => {
        _loadUser(id, undefined);

        setEditmode(false);
        setCreatemode(false);

        dispatch(showUserFlyout({
            createMode: false,
            editMode: true
        }));
    };

    const onPageChanged = async (p, l) => {
        dispatch(updateCurrentPage(p));
        if (l) {
            await onLoadItems(p);
        }
    };

    const onLoadItems = async (p) => {
        await _loadUsers(p, p * 10, 10, whereClause);
    };

    const onEditClicked = (e) => {
        e.preventDefault();
        setEditmode(true);
    };

    const onSaveClicked = (e, id, created) => {
        e.preventDefault();
        setPasswordMismatch(false);
        setPasswordLengthInvalid(false);
        if (isFormValid(USER_FORM)) {
            if (user) {
                if (created) {
                    if (password.length >= 8) {
                        if (password === confirmPassword) {
                            dispatch(createUser({
                                client: tenantId,
                                serviceType: "TENANTADMIN",
                                operation: "SAVEUSER",
                                payload: {
                                    id: id,
                                    data: JSON.stringify({
                                        user: JSON.stringify(user),
                                        photo: {
                                            contentType: "image/jpg",
                                            length: 0,
                                            photoData: base64Image
                                        }
                                    })
                                }
                            }));
                        }
                        else {
                            setPasswordMismatch(true);
                        }
                    }
                    else {
                        setPasswordLengthInvalid(true);
                    }
                }
                else {
                    dispatch(updateUser({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVEUSER",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                user: JSON.stringify(user),
                                photo: {
                                    contentType: "image/jpg",
                                    length: 0,
                                    photoData: base64Image
                                }
                            })
                        }
                    }));
                }

            }

        }
        else {
            setUFInvalid(true);
        }
        setUFSubmitted(true);
    };

    const onCancelClicked = (e) => {
        setPassword("");
        setConfirmPassword("");
        setPasswordMismatch(false);
        setPasswordLengthInvalid(false);
        setCreatemode(false);
        setEditmode(false);
        setUFInvalid(false);
        setUFSubmitted(false);
        if (tempUserId) {
            setTempUserId("");
            _loadUser(tempUserId, undefined);

        }
    };

    const onDeleteClicked = (e, id) => {
        e.preventDefault();
        if (id) {
            dispatch(show({
                id: id,
                title: messages.confirmAlert.title,
                content: messages.confirmAlert.content.removal('User'),
                confirmedCallback: _removeUser
            }));
        }
    };

    const onStatusUpdated = (e, value, text) => {
        e.preventDefault();
        let _userObj = Object.assign({}, user);
        let _newUserObj = onUserStatusUpdated(_userObj, value);
        dispatch(updateUser({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVEUSER",
            payload: {
                id: _newUserObj.id,
                data: JSON.stringify({
                    user: JSON.stringify(_newUserObj),
                    photo: {
                        contentType: "image/jpg",
                        length: 0,
                        photoData: ""
                    }
                })
            }
        }));
        dispatch(updateSingleData(_newUserObj));
        setStatusVisibility(!statusVisibility);
        setSelectedStatus(text);
    };

    const onPhotoClicked = (e) => {
        if (editMode) {
            openFileUploader("fuUserPhoto");
        }
    };

    const onFileSelected = e => {
        // let file = e.target.files[0];
        // getBase64Image(file)
        //     .then(result => {
        //         result = result.split(',').pop();
        //         file["base64"] = result;
        //         setBase64Image(result);
        //         let _userObj = Object.assign({}, user);
        //         let _newUserObj = onUserPhotoChanged(_userObj, result);
        //         dispatch(updateSingleData(_newUserObj));
        //     })
        //     .catch(err => {
        //         console.log(err);
        //     });

        // setFile(e.target.files[0]);
        // setUploadedPhoto(e, "imgUserPhoto");
    };

    const onStatusClicked = (e) => {
        setStatusVisibility(!statusVisibility);
    };

    const onAddRecordClicked = (e) => {
        setCreatemode(true);
        // setTempUserId(user.id);
        dispatch(createNewUser());

        dispatch(showUserFlyout({
            createMode: true,
            editMode: false
        }));
    };


    const onPhotoDelete = async (e, photoData) => {
        if (editMode) {


            setFile('');
        }
    }
    const _removeUser = async (id) => {
        await dispatch(removeUser({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "DELETEUSER",
            payload: {
                id: id,
                data: ""
            }
        }))
    };

    const onSearchClicked = async (e, value) => {
        e.preventDefault();
        if (value) {
            let where = JSON.parse(JSON.stringify(whereClause));
            where.name = value;
            await dispatch(updateNameFilter(value));
            await dispatch(updateIsFiltered());
            _loadUsers(0, 0, 10, where);
        }
    };

    const onSearchCleared = async (e) => {
        e.preventDefault();
        let where = JSON.parse(JSON.stringify(whereClause));
        where.name = "";
        await dispatch(updateNameFilter(""));
        await dispatch(updateIsFiltered());
        _loadUsers(0, 0, 10, where);
    };

    const onChangePasswordClicked = (e, id, user) => {
        console.log(user)
        e.preventDefault();
        dispatch(showCPModal({
            title: <><span>Reset Password For</span> <span className="modal-header-name">{user ? user.FirstName + " " + user.LastName : "N/A"}</span></>,
            id: id
        }));
    };

    const getLocation = (field, data, column) => {
        return _getLocationText(locationOptions, data["Location"]);
    }

    const addActionTemplate = (props) => {
        return <>
            {/* <div className='c-icon-box' data-tip='Edit' onClick={() => onUserSelected(props.id)}>
                <i className='fa fa-pencil'></i>
            </div>
            <div className='c-icon-box' data-tip='Change Password' onClick={(e) => onChangePasswordClicked(e, props ? props.id : "", props ? props : "")}>
                <i className='fa fa-lock'></i>
            </div> */}
            <div className='d-flex align-items-center'>
                <DialCallButton />
                <i className='fa fa-pencil ms-3' data-tip='Edit' title='Edit' onClick={() => onUserSelected(props.id)}></i>
                <i className='fa fa-lock ms-3' data-tip='Change Password' title='Change Password' onClick={(e) => onChangePasswordClicked(e, props ? props.id : "", props ? props : "")}></i>
                <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
            </div>

        </>
    }
    const gridRef = useRef(null);
    useEffect(() => {
        if (gridRef.current) {
            gridRef.current.refresh();
        }
    }, [users, usersRequestStatus]);

    const columns = [
        { field: 'FirstName', headerText: 'First Name', width: '100' },
        { field: 'LastName', headerText: 'Last Name', width: '100' },
        { field: 'PhoneNumber', headerText: 'Phone Number', width: '100' },
        { field: 'Email', headerText: 'Email', width: '100' },
        { field: 'Location', headerText: 'Location', width: '100', valueAccessor: getLocation },
        { field: 'Status', headerText: 'Status', width: '100' },
        {
            headerText: 'Action',
            template: addActionTemplate,
            width: '50px',
        },
    ];
    return (
        <>
            {(usersRequestStatus === fetchStatus.LOADING ||
                userRemovalStatus === fetchStatus.LOADING ||
                locationOptionsStatus === fetchStatus.LOADING
            ) && <div className="app-container-loader">
                    <GridLoader />
                </div>}

            <div className="row">
                <div className="col-md-6">
                    <span className="detail-container-title">Team </span>
                </div>
                <div className="col-md-6 text-end">
                    <button className="c-btn dark-btn" data-tip="Add new user" type="button" onClick={(e) => onAddRecordClicked(e)}>
                        <i className="fa fa-plus"></i>
                    </button>
                    {/* <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' /> */}

                </div>
            </div>


            <div className="row p-3">
                <GridComponent ref={gridRef} dataSource={users} allowPaging={true} allowSorting={true} allowResizing={true} width="100%" toolbar={['Search']} pageSettings={{ pageSize: 10, pageCount: 5 }} rowHeight={50} columns={columns} >
                    <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                </GridComponent>
            </div>
            {/* <div className="row pt-3">
                <table className="table c-table ">
                    <thead>
                        <tr>
                            <th scope="col">User Name</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Email</th>
                            <th scope="col">Location</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <PageContainer
                            currentPage={currentPage}
                            totalPageItems={totalPageItems}
                            pageRecords={userRecords}
                            isItemsLoading={usersRequestStatus}
                            itemsLoadingContent={dummyCards}
                            onPageChanged={(p, l) => onPageChanged(p, l)}
                            numberOfColumns={7}
                        />
                    </tbody>
                </table>
            </div> */}
        </>
    );
}

export default User;