import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';

const exportAsImage2 = async (imageFileName,
    exportRefTopInterviewer,
    exportRefTopDispatchersr,
    exportRefActivePeople,
    exportRefApplicantRegistration,
    exportRefClients,
    exportRefApplicantDispatche,
    exportRefAssociateMovements,
    exportRefNewJobsByOffice,
    exportRefCalledInAndCalledOut,
    exportRefNoShow,
    exportRefApplicantInterview,

    exportRefLeadStatus,
    exportRefLeadPerformance,

    exportRefDashboardCard,
    exportEmailBodyDashboard
) => {

    const element = exportRefTopInterviewer;
    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];
    let htmlWidth = html.clientWidth;
    let bodyWidth = body.clientWidth;

    const newWidth = element.scrollWidth - element.clientWidth;

    if (newWidth > element.clientWidth) {
        htmlWidth += newWidth;
        bodyWidth += newWidth;
        htmlWidth += newWidth;
        bodyWidth += newWidth;
    }

    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";

    const canvas = await html2canvas(exportRefDashboardCard);
    const image = canvas.toDataURL('image/jpeg', 1);

    const canvas1 = await html2canvas(exportRefTopInterviewer);
    const image1 = canvas1.toDataURL('image/jpeg', 1);

    const canvas2 = await html2canvas(exportRefTopDispatchersr);
    const image2 = canvas2.toDataURL('image/jpeg', 1);

    const canvas3 = await html2canvas(exportRefActivePeople);
    const image3 = canvas3.toDataURL('image/jpeg', 1);

    const canvas4 = await html2canvas(exportRefApplicantRegistration);
    const image4 = canvas4.toDataURL('image/jpeg', 1);

    const canvas5 = await html2canvas(exportRefClients);
    const image5 = canvas5.toDataURL('image/jpeg', 1);

    const canvas6 = await html2canvas(exportRefApplicantDispatche);
    const image6 = canvas6.toDataURL('image/jpeg', 1);

    const canvas7 = await html2canvas(exportRefAssociateMovements);
    const image7 = canvas7.toDataURL('image/jpeg', 1);

    const canvas8 = await html2canvas(exportRefNewJobsByOffice);
    const image8 = canvas8.toDataURL('image/jpeg', 1);

    const canvas9 = await html2canvas(exportRefCalledInAndCalledOut);
    const image9 = canvas9.toDataURL('image/jpeg', 1);

    const canvas10 = await html2canvas(exportRefNoShow);
    const image10 = canvas10.toDataURL('image/jpeg', 1);

    const canvas11 = await html2canvas(exportRefApplicantInterview);
    const image11 = canvas11.toDataURL('image/jpeg', 1);

    const canvas12 = await html2canvas(exportRefLeadStatus);
    const image12 = canvas12.toDataURL('image/jpeg', 1);

    const canvas13 = await html2canvas(exportRefLeadPerformance);
    const image13 = canvas13.toDataURL('image/jpeg', 1);

    downloadImage(imageFileName, image, image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13,exportEmailBodyDashboard);
    html.style.width = null;
    body.style.width = null;
};

const downloadImage = (fileName, blob, blob1, blob2, blob3, blob4, blob5, blob6, blob7, blob8, blob9, blob10, blob11, blob12, blob13, exportEmailBodyDashboard) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;
    var doc = new jsPDF('l')
    doc.setFontSize(40);
    doc.text("Performance Report", 90, 20);
    doc.addImage(blob, 'jpeg', 15, 30, 270, 15);
    // doc.addPage('', 'l'); // Add new page
    doc.addImage(blob1, 'jpeg', 15, 60, 270, 140);
    doc.addPage('', 'l'); // Add new page
    doc.addImage(blob2, 'jpeg', 15, 20, 270, 180);
    doc.addPage('', 'l'); // Add new page
    doc.addImage(blob3, 'jpeg', 15, 20, 150, 170);
    doc.addPage('', 'l'); // Add new page
    doc.addImage(blob4, 'jpeg', 15, 20, 270, 200);
    doc.addPage('', 'l'); // Add new page
    doc.addImage(blob5, 'jpeg', 15, 20, 270, 180);
    doc.addPage('', 'l'); // Add new page
    doc.addImage(blob6, 'jpeg', 15, 20, 270, 180);
    doc.addPage('', 'l'); // Add new page
    doc.addImage(blob7, 'jpeg', 15, 20, 270, 200);
    doc.addPage('', 'l'); // Add new page
    doc.addImage(blob8, 'jpeg', 15, 20, 270, 200);
    doc.addPage('', 'l'); // Add new page
    doc.addImage(blob9, 'jpeg', 15, 20, 270, 200);
    doc.addPage('', 'l'); // Add new page
    doc.addImage(blob10, 'jpeg', 15, 20, 150, 170);
    doc.addPage('', 'l'); // Add new page
    doc.addImage(blob11, 'jpeg', 15, 20, 270, 180);
    doc.addPage('', 'l'); // Add new page
    doc.addImage(blob12, 'jpeg', 15, 20, 270, 200);
    doc.addPage('', 'l'); // Add new page
    doc.addImage(blob13, 'jpeg', 15, 20, 270, 180);
    doc.addPage('', 'l'); // Add new page

    //Start TABLE
    var col = ["Sr. No.", "Location", "Total Applications", "Total Dispatched", "Total Active", "Total No Shows", "Total Show Up Time", "Placed Available", "Placed Inactive", "Placed DNU", "Placed DNR", "Total Call Ins", "Total Call Outs", "Total Job Orders", "Total New Clients"];
    var rows = [];
    const exportEmailBodyDashboardData = exportEmailBodyDashboard ? JSON.parse(exportEmailBodyDashboard) : null;
    exportEmailBodyDashboardData.forEach((d, index) => {
        var temp = [index + 1, d.Location, d.TotalApplications, d.TotalDispatched, d.TotalActive, d.TotalNoShows, d.TotalShowUpTime, d.PlacedAvailable, d.PlacedInactive, d.PlacedDNU, d.PlacedDNR, d.TotalCallIns, d.TotalCallOuts, d.TotalJobOrders, d.TotalNewClients];
        rows.push(temp);
    });
    doc.autoTable(col, rows, { startY: 10 });
    //End TABLE

    doc.save(fileName);
};

export default exportAsImage2;