import React, { useEffect, useState } from 'react';
import { toLocalDate, _getDateText } from '../../../../common/common';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
// import {
//     selectCanceledStatus,
//     showSUModal,
//     resetCanceledStatus
// } from '../../../../components/Controls/modals/reducerSlices/statusUpdateModalPopUpSlice';
import { getJobApplicantById, selecteJobApplicant, selectJobApplicant, selectJobApplicantStatus } from '../../reducerSlices/jobSlice';
import { fetchStatus, tenantId } from '../../../../api/client';
import { showSUModal } from '../../../../component/Controls/modals/reducerSlices/statusUpdateModalPopUpSlice';
import DialCallButton from '../../../../component/Controls/buttons/dialCallButton';
import { applicantStatusesData } from '../../../shared/services/masterData';



function ApplicantDetail(props) {

    const dispatch = useDispatch();

    const [text, setText] = useState("");
    const [value, setValue] = useState("");
    const [prompted, setPrompted] = useState(false);

    const jobApplicant = useSelector(selectJobApplicant);
    const jobApplicantStatus = useSelector(selectJobApplicantStatus);

    useEffect(() => {
        if (prompted && jobApplicantStatus === fetchStatus.SUCCEEDED && jobApplicant && jobApplicant.id) {
            let name = "";
            if (jobApplicant.FirstName && jobApplicant.FirstName.Data) {
                name += jobApplicant.FirstName.Data + " ";
            }
            if (jobApplicant.LastName && jobApplicant.LastName.Data) {
                name += jobApplicant.LastName.Data;
            }
            dispatch(showSUModal({
                title: <React.Fragment><span>Add Reason For</span> <span className="modal-header-name">{name}</span></React.Fragment>,
                id: jobApplicant.id,
                applicant: jobApplicant,
                status: {
                    value: value,
                    text: text
                }
            }));
            setPrompted(false);
        }
    });

    const _loadApplicant = async (id) => {
        await dispatch(getJobApplicantById({
            client: tenantId,
            serviceType: "TENANTUSER",
            operation: "GET",
            payload: {
                id: id,
                data: ""
            }
        }));
    }

    const onStatusChanged = (e, value, text, id) => {
        e.preventDefault();
        setText(text);
        setValue(value);
        setPrompted(true);
        _loadApplicant(id);
    };


    const _getPostDispatchStatus = (value) => {
        if (value === "1") {
            return "Showed Up";
        }
        else if (value === "2") {
            return "Not Showed Up";
        }
        else if (value === "3") {
            return "2 Hours Pay";
        }
        return "N/A";
    }
    const _getPositionText = (id) => {
        if (props.positions) {
            let position = props.positions.find(p => p.value === id);
            if (position && position.text) {
                return position.text;
            }
        }
        return "N/A";
    }
    const [applicantDetailsTrs, setApplicantDetailsTrs] = useState([]);
    let _applicantDetailsTrs;

    useEffect(() => {
        if (isObjectArray(props.details)) {
            _applicantDetailsTrs = props.details.map((detail, index) => (
                <tr key={detail.id}>
                    <td className="py-2">
                        <div className='d-flex align-items-center'>
                            <DialCallButton dialCallButtonClasses="pe-1" />
                        
                        {!detail.ActiveDate ? <>
                            <a href="#" onClick={(e) => onStatusChanged(e, applicantStatusesData.Active, applicantStatusesData.Active, detail.aid, detail.Name, detail.applicant)} title="Active" className="e-focus"><span className="zoomicon"><i className="fa fa-check me-2 zoomicon"></i></span></a>

                            <a href="#" onClick={(e) => onStatusChanged(e, applicantStatusesData.Available, applicantStatusesData.Available, detail.aid, detail.Name, detail.applicant)} title="Un Dispatch" className="e-focus"><span className="zoomicon text-danger"><i className="fa fa-ban zoomicon"></i></span></a>
                        </> : <React.Fragment></React.Fragment>}
                        </div>
                    </td>
                    {/* <td className="py-2">{index + 1}</td> */}
                    <td className="py-2">
                        {detail ? detail.Status : ""}
                    </td>
                    <td className="py-2">
                        {detail ? detail.Name : ""}
                    </td>
                    <td className="py-2">
                        {detail ? detail.SSN : ""}
                    </td>
                    <td className="py-2">
                        {detail ? detail.Phone : ""}
                    </td>
                    <td className="py-2">
                        {detail ? detail.dispatchUser : ""}
                    </td>
                    <td className="py-2">
                        {detail ? _getPositionText(detail.Position) : ""} - {detail ? detail.PositionDescription : ""}
                    </td>
                    {/* <td className="py-2">
                        {detail ? detail.PositionDescription : ""}
                    </td> */}
                    <td className="py-2">
                        $ {detail ? detail.PayRates : ""}
                    </td>
                    <td className="py-2">
                        {detail ? detail.flexibility : ""}
                    </td>
                    <td className="py-2">
                        {detail ? detail.JobType : ""}
                    </td>
                    <td className="py-2">
                        {detail ? _getDateText(detail.DOB) : ""}
                    </td>
                    <td className="py-2">
                        {detail ? detail.MaritalStatus : ""}
                    </td>
                    <td className="py-2">
                        {detail ? detail.Address : ""}
                    </td>
                    <td className="py-2">
                        {detail ? toLocalDate(detail.DispatchDate) : ""}
                    </td>
                    <td className="py-2">
                        {detail ? _getPostDispatchStatus(detail.PostDispatchStatus) : ""}
                    </td>
                    <td className="py-2">
                        {detail && detail.ActiveDate ? toLocalDate(detail.ActiveDate) : ""}
                    </td>

                </tr>
            ));
            setApplicantDetailsTrs(_applicantDetailsTrs);
        }
        else {
            setApplicantDetailsTrs(<tr>
                <td colSpan="16">No records available</td>
            </tr>);
        }
    }, [props.details, props.readonly]);
    return (
        <React.Fragment>
            {applicantDetailsTrs}
        </React.Fragment>
    );
}

export default ApplicantDetail;