import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Layout from '../component/layouts/layout';
import { accountService } from '../features/accounts/services/accountService';

const CompanySetupRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props =>
            accountService.isAuthorized() && accountService.isAdmin() && accountService.isCompanySetup() === false ? (
                <Component {...props} />
            ) : (<Redirect to={{ pathname: '/login', state: { returnUrl: props.location } }} />)} />
    )
};

export default CompanySetupRoute;