import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import logoImg from './../../assets/images/logo/logo.svg';
//menu Image

import feedImg from './../../assets/images/menu/feed.svg';
import feedTransparentImg from './../../assets/images/menu/feed-transparent.svg';
import jobOrderImg from './../../assets/images/menu/job-order.svg';
import jobOrderTransparentImg from './../../assets/images/menu/job-order-transparent.svg';
import messageImg from './../../assets/images/menu/message.svg';
import messageTransparentImg from './../../assets/images/menu/message-transparent.svg';
import peopleImg from './../../assets/images/menu/people.svg';
import peopleTransparentImg from './../../assets/images/menu/people-transparent.svg';
import reportImg from './../../assets/images/menu/report.svg';
import reportTransparentImg from './../../assets/images/menu/report-transparent.svg';
import settingImg from './../../assets/images/menu/setting.svg';
import settingTransparentImg from './../../assets/images/menu/setting-transparent.svg';
import { selectLayoutDetail, selectProduct } from '../layouts/reducerSlices/layoutSlice';
import { accountService } from '../../features/accounts/services/accountService';

function SideNavBar() {

  const history = useHistory();

  const isAdmin = accountService.isAdmin();
  const isPayroll = accountService.isPayroll();
  const IsReviewer = accountService.isReviewer();
  const isSalesRep = accountService.isSalesRep();
  const isSalesManager = accountService.isSalesManager();

  const onClickFeed = () => {
    history.push('/feed');
  }

  const onClickPeople = () => {
    history.push('/people');
  }

  const onClickJobOrder = () => {
    history.push('/jobOrder');
  }

  const onReportLinkClicked = () => {
    history.push('/report');
  }

  const onClickSetting = () => {
    history.push('/setting');
  }

  const onInboxLinkClicked = (e) => {
    history.push('/inbox');
  };

  const onClickDashboard = (e) => {
    history.push('/dashboard');
  }

  const onClickProspects = (e) => {
    history.push('/prospects');
  }

  const onClickLeads = (e) => {
    history.push('/lead');
  }

  const onClickRouteList = (e) => {
    history.push('/routeList');
  }

  const onClickSchedules = (e) => {
    history.push('/schedules');
  }

  const onUsersTracking = (e) => {
    history.push('/usersTracking');
  }

  const onClickProspectsPeople = () => {
    history.push('/prospectsPeople');
  }

  const [selectedLink, setSelectedLink] = useState(0);

  const layoutDetail = useSelector(selectLayoutDetail);
  const product = useSelector(selectProduct);

  useEffect(() => {
    if (layoutDetail) {
      setSelectedLink(layoutDetail.page);
    }
  });

  return (
    <div
      className="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column overflow-auto d-block"
      id="sidebar">
      <ul className="nav flex-column text-white w-100">
        <li href="#" className="nav-link">
          <div className="nav-item-box">
            <img src={logoImg} alt="logo"></img>
          </div>
        </li>
        {isAdmin || isSalesManager ?
          <li href="#" className="nav-link mt-3">
            <div className="nav-item-box" onClick={onClickDashboard}>
              <div className={`nav-item-box-img ${selectedLink === 12 ? 'active' : ''}`}>
                <span><i className={`fa fa-xl fa-dashboard ${selectedLink === 12 ? 'text-white' : ''}`} aria-hidden="true"></i></span>
              </div>
              <div className={`nav-item-box-text ${selectedLink === 12 ? 'active' : ''}`}>Dash <br></br>Board</div>
            </div>
          </li>
          : <></>}
        {isAdmin || IsReviewer ?
          <li href="#" className="nav-link">
            <div className="nav-item-box" onClick={onClickFeed}>
              <div className={`nav-item-box-img ${selectedLink === 11 ? 'active' : ''}`}>
                <img src={selectedLink === 11 ? feedTransparentImg : feedImg} ></img>
              </div>
              <div className={`nav-item-box-text ${selectedLink === 11 ? 'active' : ''}`}>Feed</div>
            </div>
          </li>
          : <></>}
        {(isAdmin || IsReviewer || isPayroll) && (product === "ATS") ?
          <li href="#" className="nav-link">
            <div className="nav-item-box" onClick={onClickPeople}>
              <div className={`nav-item-box-img ${selectedLink === 1 ? 'active' : ''}`}>
                <img src={selectedLink === 1 ? peopleTransparentImg : peopleImg} ></img>
              </div>
              <div className={`nav-item-box-text ${selectedLink === 1 ? 'active' : ''}`}>People</div>
            </div>
          </li>
          : <></>}
        {(isAdmin || IsReviewer || isPayroll) && (product === "ATS") ?
          <li href="#" className="nav-link">
            <div className="nav-item-box" onClick={onClickProspectsPeople}>
              <div className={`nav-item-box-img ${selectedLink === 18 ? 'active' : ''}`}>
                <img src={selectedLink === 18 ? peopleTransparentImg : peopleImg} ></img>
              </div>
            </div>
            <div className={`nav-item-box-text ${selectedLink === 18 ? 'active' : ''}`}>Prospects</div>
          </li>
          : <></>}
        {(isAdmin || isSalesRep || isSalesManager) && (product === "CRM") ?
          <li href="#" className="nav-link">
            <div className="nav-item-box" onClick={onClickProspects}>
              <div className={`nav-item-box-img ${selectedLink === 13 ? 'active' : ''}`}>
                <span><i className={`fa fa-xl fa-users ${selectedLink === 13 ? 'text-white' : ''}`} aria-hidden="true"></i></span>
              </div>
            </div>
            <div className={`nav-item-box-text ${selectedLink === 13 ? 'active' : ''}`} onClick={onClickProspects}>Prospects</div>
          </li>
          : <></>}
        {(isAdmin || isSalesRep || isSalesManager) && (product === "CRM") ?
          <li href="#" className="nav-link">
            <div className="nav-item-box" onClick={onClickLeads}>
              <div className={`nav-item-box-img ${selectedLink === 14 ? 'active' : ''}`}>
                <span><i className={`fa fa-xl fa-building-flag ${selectedLink === 14 ? 'text-white' : ''}`} aria-hidden="true"></i></span>
              </div>
              <div className={`nav-item-box-text ${selectedLink === 14 ? 'active' : ''}`}>leads</div>
            </div>
          </li>
          : <></>}
        {(isAdmin || isSalesRep || isSalesManager) && (product === "CRM") ?
          <li href="#" className="nav-link">
            <div className="nav-item-box" onClick={onClickRouteList}>
              <div className={`nav-item-box-img ${selectedLink === 17 ? 'active' : ''}`}>
                <span><i className={`fa fa-xl fa-route ${selectedLink === 17 ? 'text-white' : ''}`} aria-hidden="true"></i></span>
              </div>
              <div className={`nav-item-box-text ${selectedLink === 17 ? 'active' : ''}`}>Route List</div>
            </div>
          </li>
          : <></>}
        {(isAdmin || isSalesRep || isSalesManager) && (product === "CRM") ?
          <li href="#" className="nav-link">
            <div className="nav-item-box" onClick={onClickSchedules}>
              <div className={`nav-item-box-img ${selectedLink === 15 ? 'active' : ''}`}>
                <span><i className={`fa fa-xl fa-calendar-days ${selectedLink === 15 ? 'text-white' : ''}`} aria-hidden="true"></i></span>
              </div>
            </div>
            <div className={`nav-item-box-text ${selectedLink === 15 ? 'active' : ''}`} onClick={onClickSchedules}>Calendar</div>
          </li>
          : <></>}
        {(isAdmin) && (product === "CRM") ?
          <li href="#" className="nav-link">
            <div className="nav-item-box" onClick={onUsersTracking}>
              <div className={`nav-item-box-img ${selectedLink === 16 ? 'active' : ''}`}>
                <span><i className={`fa fa-xl fa-street-view ${selectedLink === 16 ? 'text-white' : ''}`} aria-hidden="true"></i></span>
              </div>
            </div>
            <div className={`nav-item-box-text ${selectedLink === 16 ? 'active' : ''}`} onClick={onUsersTracking}>Users Tracking</div>
          </li>
          : <></>}
        {isAdmin || IsReviewer ?
          <li href="#" className="nav-link">
            <div className="nav-item-box" onClick={onInboxLinkClicked}>
              <div className={`nav-item-box-img ${selectedLink === 10 ? 'active' : ''}`}>
                <img src={selectedLink === 10 ? messageTransparentImg : messageImg} ></img>
              </div>
            </div>
            <div className={`nav-item-box-text ${selectedLink === 10 ? 'active' : ''}`} onClick={onInboxLinkClicked}>Message</div>
          </li>
          : <></>}
        {(isAdmin || IsReviewer) && (product === "ATS") ?
          <li href="#" className="nav-link">
            <div className="nav-item-box" onClick={onClickJobOrder}>
              <div className={`nav-item-box-img ${selectedLink === 5 ? 'active' : ''}`}>
                <img src={selectedLink === 5 ? jobOrderTransparentImg : jobOrderImg} ></img>
              </div>
              <div className={`nav-item-box-text ${selectedLink === 5 ? 'active' : ''}`}>Job Orders</div>
            </div>
          </li>
          : <></>}
        {isAdmin || IsReviewer ?
          <li href="#" className="nav-link">
            <div className="nav-item-box" onClick={onReportLinkClicked}>
              <div className={`nav-item-box-img ${selectedLink === 8 ? 'active' : ''}`}>
                <img src={selectedLink === 8 ? reportTransparentImg : reportImg} ></img>
              </div>
              <div className={`nav-item-box-text ${selectedLink === 8 ? 'active' : ''}`}>Report</div>
            </div>
          </li>
          : <></>}

        {isAdmin || isPayroll || isSalesManager ?
          <span href="#" className="nav-link w-100">
            <div className="nav-item-box" onClick={onClickSetting}>
              <div className={`nav-item-box-img ${selectedLink === 9 ? 'active' : 'bg-transparent'}`}>
                <img src={selectedLink === 9 ? settingTransparentImg : settingImg} ></img>
              </div>
              <div className="nav-item-box-text">Settings</div>
            </div>
          </span>
          : <></>}

      </ul>

      {/* <span href="#" className="nav-link h4 w-100">
        <a href=""><i className="bx bxl-instagram-alt text-white"></i></a>
        <a href=""><i className="bx bxl-twitter px-2 text-white"></i></a>
        <a href=""><i className="bx bxl-facebook text-white"></i></a>
      </span> */}



      {/* <div className="dropdown border-top">
        <a href="#" className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="https://github.com/mdo.png" alt="mdo" width="24" height="24" className="rounded-circle" />
        </a>
        <ul className="dropdown-menu text-small shadow">
          <li><a className="dropdown-item" href="#">New project...</a></li>
          <li><a className="dropdown-item" href="#">Settings</a></li>
          <li><a className="dropdown-item" href="#">Profile</a></li>
          <li><hr className="dropdown-divider" /></li>
          <li><a className="dropdown-item" href="#">Sign out</a></li>
        </ul>
      </div> */}
    </div>
  )
}

export default SideNavBar