import React, { useState } from 'react';
import noPhoto from '../../assets/images/clipart/nophoto.png';
import { toLocalDate } from '../../common/common';
import applicationImg from './../../assets/images/clipart/application.svg';
import msgImg from './../../assets/images/clipart/msg.svg';

function InBoxCard(props) {
    const [value, setValue] = useState("");

    const onChange = (e) => {
        let msgvalue = e.target.value;
        setValue(msgvalue);

    };

    const onClick = (c, e, message) => {
        const _value = c;
        if (c != '') {
            props.onreplyClick(c, e, _value);
        }
        setValue("");
    }

    return (

        // <div className="card-border card p-3 mb-2">
        //     <div className="d-flex justify-content-between card-border">
        //         <div className="d-flex flex-row align-items-center">
        //             <div className="icon"> <img className={`card-image horizontal-card-image status-card-image-dimensions`} src={noPhoto} /> </div>
        //             <div className="ms-2 c-details p-2">
        //                 <span className="text-dark cardtitle"> {props.message.sendBy} </span>
        //                 <p className="text-muted center">{toLocalDate(props.message.sendOn)}</p>
        //             </div>
        //         </div>
        //         {props.IsOldMessage && props.IsOldMessage == "false" && <button onClick={(c, e) => props.onReadClicked(c, e, props.message)} title="Read Message" className="DeletePhoto e-focus">
        //             <span className="zoomicon text-danger">
        //                 <i className="fa fa-times mr-3 zoomicon"></i>
        //             </span>
        //         </button>}
        //         {props.IsOldMessage && props.IsOldMessage == "true" && <button onClick={(c, e) => props.onShowAllClicked(c, e, props.message)} title="Read Message" className="DeletePhoto e-focus">
        //             <span className="zoomicon text-primary">
        //                 <i className="fa  fa-comment mr-3 zoomicon"></i>
        //             </span>
        //         </button>}
        //     </div>
        //     <div className="mt-3">
        //         <p className="card-text text-justify">{props.message.text}</p>
        //     </div>
        //     {props.IsOldMessage && props.IsOldMessage == "false" && <div className="mt-3">
        //         <input
        //             type="text"
        //             key={props.index}
        //             id={props.message.id}
        //             name={props.message.id}
        //             value={value}
        //             className={"chatApp__convInput"}
        //             placeholder="Reply message"
        //             disabled={false}
        //             tabIndex="0"
        //             onChange={(e) => onChange(e)}

        //         />
        //         <button onClick={(c, e) => onClick(value, e)} title="Reply" className="chatApp__convButton">
        //             <i className="fa fa-paper-plane" aria-hidden="true"></i>;
        //         </button>
        //     </div>}
        // </div>

        <div className="card people-card">
            <div className="people-card-body p-2 cursor-pointer">
                <div className="d-flex">
                    <div className="pe-1">
                        <div className="people-card-photo">
                            <div className="people-card-photo-box">
                                <img className="people-card-profile-box-img" src={noPhoto} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="ps-2">
                        <div className="title w-100">{props.message.sendBy}</div>
                        <div className="row">
                            <div className="detail col-12">{props.message.text}</div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="people-card-footer">
                <div className="item">
                    <span className="detail">
                        <img src={applicationImg} /> Date: {toLocalDate(props.message.sendOn)}
                    </span>
                </div>
                <div className="item">
                    {props.IsOldMessage && props.IsOldMessage == "false" &&
                        <span className="detail cursor-pointer" onClick={(c, e) => props.onReadClicked(c, e, props.message)} title="Read Message">
                            <img src={msgImg} />Message
                        </span>
                    }
                    {props.IsOldMessage && props.IsOldMessage == "true" &&
                        <span className="detail cursor-pointer" onClick={(c, e) => props.onShowAllClicked(c, e, props.message)} title="Read Message">
                            <img src={msgImg} />Message
                        </span>
                    }
                </div>
            </div>

            {props.IsOldMessage && props.IsOldMessage == "false" && <div className="mt-3">
                <input
                    type="text"
                    key={props.index}
                    id={props.message.id}
                    name={props.message.id}
                    value={value}
                    className={"chatApp__convInput"}
                    placeholder="Reply message"
                    disabled={false}
                    tabIndex="0"
                    onChange={(e) => onChange(e)}

                />
                <button onClick={(c, e) => onClick(value, e)} title="Reply" className="chatApp__convButton">
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>;
                </button>
            </div>}
        </div>

    );
}

export default InBoxCard;