import Collection from "../collection";

export const refreshCreated = (pageCollection, record) => {
    let currentPage = pageCollection.Count - 1;
    let lastRecords = [];
    let pages = new Collection();
    if (currentPage >= 0) {
        let page = pageCollection.Get(currentPage);
        if (page) {
            if (page.records.length < 10) {
                lastRecords = lastRecords.concat(page.records);
                lastRecords.push(record);
                pages.Add(page.key, lastRecords);
                pageCollection.Remove(page.key);
            }
            else {
                currentPage++;
                lastRecords.push(record);
                pages.Add(page.key + 1, lastRecords);
            }
            pages.Concat(pageCollection);
        }
    }
    else {
        currentPage = 0;
        lastRecords.push(record);
        pages.Add(currentPage, lastRecords);
    }
    return {
        records: pages,
        lastPage: currentPage
    };
};