import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import YesNoRadio from '../../Controls/custom/checkers/yesNoRadio';


function YesNoChoiceFilter(props) {

    const yesNoChoiceFilter = useSelector(props.yesNoChoiceFilterSelector);

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="form-group p-0">
                    <label>{props.choiceFor ? props.choiceFor : ""}</label>
                    <YesNoRadio
                        name={`rdbtnYNChoice${nanoid()}`}
                        value={yesNoChoiceFilter}
                        onSelectionChanged={(e, v) => { props.onYesNoChoiceChanged && props.onYesNoChoiceChanged(e, v) }}
                        YesNoRadioClasses="float-end"
                    />
                </div>
            </div>
        </div>
    );
}

export default YesNoChoiceFilter;