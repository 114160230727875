import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import XLSX from 'xlsx';
import moment from 'moment';
// import Papa from 'papaparse';

import { fetchStatus, tenantId } from '../../../api/client';
import { closePeopleImportModal, savePeopleImport, selectPeopleImportModalPopUpDetail, selectSavePeopleImportData, selectSavePeopleImportStatus } from './reducerSlices/peopleImportModalPopUpSlice';
import { isFormValid } from '../services/formService';
import CancelButton from '../custom/buttons/cancelButton';
import SaveButton from '../custom/buttons/saveButton';
import EmailText from '../custom/textbox/emailText';
import PhoneText from '../custom/textbox/phoneText';
// import ShortText from '../textboxes/shortText';
import Selector from '../custom/selectors/selectors';
import DateText from '../custom/textbox/dateText';
import ShortText from '../custom/textbox/shortText';
import ZipCodeText from '../custom/textbox/zipCodeText';
import { applicantDetailsEN } from '../../../features/applicant/services/applicantDetails';
import { selectLocationOptions } from '../../../features/locations/reducerSlices/locationSlice';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import StateOptionsValue from '../../../common/stateOptionsValue';
import MultiSelector from '../custom/selectors/multiSelector';
import { employmentTypesData, shiftsData } from '../../../features/applicant/services/masterData';
import SSNText from '../custom/textbox/ssnText';
import EllipsisLoader from '../loaders/ellipsisLoader';
import InputGroup from '../custom/inputGroup/inputGroup';
import { updateStatusIdle } from '../../../features/applicant/reducerSlices/applicantSlice';
import { toaster } from '../toasts/toaster';
import { _splitCamelCase, isValidURL } from '../../../common/common';
import { selectPositionOptions } from '../../../features/setting/reducerSlices/positionsSlice';
import { updateFilter, updateRefreshData } from '../filters/reducerSlices/filterSilce';
import UrlText from '../custom/textbox/urlText';
import { selectExperienceOptions } from '../../../features/setting/reducerSlices/experienceSlice';
import NumberText from '../custom/textbox/numberText';


function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

function PeopleImportModalPopUp() {

  const dispatch = useDispatch();

  const PEOPLE_FORM = "PEOPLE_FORM";

  const [applicantDetails, setApplicantDetails] = useState(applicantDetailsEN);
  const [editedData, setEditedData] = useState(null);
  const [pfSubmitted, setPFSubmitted] = useState(false);


  const peopleImportModalPopUpDetails = useSelector(selectPeopleImportModalPopUpDetail);
  const savePeopleImportStatus = useSelector(selectSavePeopleImportStatus);
  const errorPeopleImportData = useSelector(selectSavePeopleImportData);

  const [locationOptionsValue, setLocationOptionsValue] = useState();
  const locationOptions = useSelector(selectLocationOptions);

  const positionOptions = useSelector(selectPositionOptions);

  const [primarySkillsOptionsValue, setPrimarySkillsOptionsValue] = useState();

  const experienceOptions = useSelector(selectExperienceOptions);


  useEffect(() => {
    if (isObjectArray(locationOptions)) {
      let _locationOptions = undefined;
      _locationOptions = locationOptions.map((o) => (
        <option value={o.value}>{o.text}</option>
      ));
      setLocationOptionsValue(_locationOptions);
    }
  }, [locationOptions]);

  const onCloseClicked = () => {
    setEditedData(null);
    setPFSubmitted(false);
    dispatch(closePeopleImportModal());
  };


  const _formatPhoneNumber = (phoneNumber) => {
    let numericPhoneNumber = phoneNumber.replace(/\D/g, '');

    if (numericPhoneNumber.startsWith('0')) {
      numericPhoneNumber = numericPhoneNumber.substring(1);
    }

    if (numericPhoneNumber.length !== 10) {
      return "";
    }
    const formattedPhoneNumber = `(${numericPhoneNumber.substring(0, 3)})${numericPhoneNumber.substring(3, 6)}-${numericPhoneNumber.substring(6, 10)}`;
    return formattedPhoneNumber;
  };

  const _formatSSN = (SSN) => {
    const numericSSN = SSN.replace(/\D/g, '');
    if (numericSSN.length !== 9) {
      return "";
    }
    const formattedSSN = `${numericSSN.substring(0, 3)}-${numericSSN.substring(3, 5)}-${numericSSN.substring(5, 9)}`;
    return formattedSSN;
  };

  const _excelDateToJSDate = (serial) => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);
    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);
    const seconds = total_seconds % 60;
    total_seconds -= seconds;
    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  };


  const _is18Age = (formattedDate) => {
    const jsDate = moment(formattedDate, 'YYYY-MM-DD').toDate();
    const now = moment();
    const diff = now.diff(jsDate, 'years');
    return diff >= 18;
  };

  const _formatDate = (dateValue) => {
    if (typeof dateValue === 'number') {
      const jsDate = _excelDateToJSDate(dateValue);
      const fDate = moment(jsDate).format('YYYY-MM-DD');
      if (_is18Age(fDate)) {
        return fDate;
      } else {
        return '';
      }

    } else {
      let fDate = moment(dateValue).format('YYYY-MM-DD');
      if (fDate === 'Invalid date') {
        return '';
      } else {
        if (_is18Age(fDate)) {
          return fDate;
        } else {
          return '';
        }
      }
    }
  };

  const extractIntegersStringFromString = (str) => {
    const integers = str.match(/\d+/g);
    if (integers === null) {
      return "";
    }
    return integers[0];
  }

  function findByExperienceOptionsId(id) {
    return experienceOptions.reduce((acc, item) => {
      if (item.id === id) {
        acc = item;
      }
      return acc;
    }, null);
  }

  useEffect(() => {
    if (isObjectArray(experienceOptions)) {
      let _Options = experienceOptions
        .find(
          (option) => option.ExperienceName === "Primary Skills"
        )
        .ExperienceOptions.map((option) => ({
          text: option,
          value: option
        }));
      setPrimarySkillsOptionsValue(_Options);
    }
  }, [experienceOptions])

  // function getEmploymentTypeByText(inputString) {
  //   const textArray = inputString.split(',');
  //   const resultArray = [];
  //   for (const text of textArray) {
  //     for (const type of employmentTypesData) {
  //       if (type.text === text) {
  //         resultArray.push(type.value);
  //         break; // Move to the next text in the input array
  //       }
  //     }
  //   }
  //   console.log('resultArray', resultArray)
  //   return resultArray;
  // }

  const textMappings = {
    "Available for Full-time": "Full Time",
    "Available for Part-time": "Part Time"
  };

  function getEmploymentTypeByText(inputString) {
    const textArray = inputString.split(',');
    const resultArray = [];
    for (const text of textArray) {
      const trimmedText = text.trim();
      const mappedText = textMappings[trimmedText];
      if (mappedText) {
        for (const type of employmentTypesData) {
          if (type.text === mappedText) {
            resultArray.push(type.value);
            break; // Move to the next text in the input array
          }
        }
      }
    }
    return resultArray;
  }

  function getValueByText(DataList, inputString) {
    const textArray = inputString.split(/\s*,\s*/);
    const resultArray = [];
    for (const text of textArray) {
      for (const type of DataList) {
        if (type.text === text) {
          resultArray.push(type.value);
          break; // Move to the next text in the input array
        }
      }
    }
    // console.log('resultArray', resultArray)
    return resultArray;
  }

  const employmentTypesData2 = [
    {
      text: "Available for Full-time",
      value: "Available for Full-time"
    },
    {
      text: "Available for Part-time",
      value: "Available for Part-time"
    },
    {
      text: "Not Available",
      value: "Not Available"
    },
    {
      text: "Employed by Worca",
      value: "Employed by Worca"
    },
    {
      text: "Full-time",
      value: "Available for Full-time"
    },
    {
      text: "Part-Time",
      value: "Available for Part-time"
    }
  ];

  function getEmploymentTypeValueByText(DataList, inputString) {
    const cleanedInputString = inputString.trim().toLowerCase();
    const textArray = cleanedInputString.split(',').map(text => text.trim().toLowerCase());
    const resultArray = [];
    //case for "Both"
    if (cleanedInputString === "both") {
      for (const type of DataList) {
        if (type.text.toLowerCase() === "available for full-time" || type.text.toLowerCase() === "available for part-time") {
          resultArray.push(type.value);
        }
      }
    } else {
      for (const text of textArray) {
        for (const type of DataList) {
          if (type.text.toLowerCase() === text) {
            resultArray.push(type.value);
            break;
          }
        }
      }
    }

    return resultArray;
  }

  const separateSalaryData = (data) => {
    // Remove all spaces from the data
    data = data.replace(/\s+/g, '');

    // Define regex patterns to extract hourly and monthly ranges
    const hourlyPattern = /\$([\d\.]+)-([\d\.]+)\/hour/;
    const monthlyPattern = /\$([\d\.]+)-([\d\.]+)\/month/;

    // Initialize result object
    const result = {
      HourlyFrom: null,
      HourlyTo: null,
      MonthlyFrom: null,
      MonthlyTo: null
    };

    // Match hourly ranges
    const hourlyMatch = data.match(hourlyPattern);
    if (hourlyMatch) {
      result.HourlyFrom = parseFloat(hourlyMatch[1]);
      result.HourlyTo = parseFloat(hourlyMatch[2]);
    }

    // Match monthly ranges
    const monthlyMatch = data.match(monthlyPattern);
    if (monthlyMatch) {
      result.MonthlyFrom = parseFloat(monthlyMatch[1]);
      result.MonthlyTo = parseFloat(monthlyMatch[2]);
    }

    return result;
  }

  // this hirebase
  // const handleCSVUpload = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   reader.onload = () => {
  //     const fileData = reader.result;
  //     const workbook = XLSX.read(fileData, { type: 'binary' });
  //     const sheetName = workbook.SheetNames[0]; // Assuming the first sheet
  //     const sheet = workbook.Sheets[sheetName];
  //     const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

  //     // Convert array of arrays to array of objects (key-value pairs)
  //     const headerRow = data[0];
  //     const formattedData = data.slice(1).map((row) => {
  //       const rowData = {};
  //       headerRow.forEach((header, index) => {
  //         rowData[header] = row[index];
  //       });
  //       return rowData;
  //     });
  //     console.log('formattedData', formattedData);

  //     const formattedData2 = data.slice(1).map((row) => ({
  //       Location: "",
  //       FirstName: row[0] || "",
  //       LastName: row[1] || "",
  //       PhoneNumber: (row[2] && _formatPhoneNumber(row[2].toString())) || "",
  //       EmailAddress: row[3] || "",
  //       DateOfBirth: row[4] ? _formatDate(row[4]) : "",

  //       MiddleName: row[5] || "",
  //       OtherLastName: row[6] || "",
  //       Address: row[7] || "",
  //       City: row[8] || "",
  //       State: row[9] || "",
  //       ZipCode: row[10] || "",

  //       SSN: row[11] ? _formatSSN(row[11].toString()) : "",
  //       AlternateEmailAddress: row[12] || "",
  //       AlternatePhoneNumber: row[13] ? _formatPhoneNumber(row[13].toString()) : "",
  //       // Position: row[14] || [],
  //       Position: row[14] ? getValueByText(positionOptions, row[14]) : [],
  //       // Shift: row[15] || [],
  //       Shift: row[15] ? getValueByText(shiftsData, row[15]) : [],
  //       // EmploymentType: row[16] || [],
  //       EmploymentType: row[16] ? getEmploymentTypeByText(row[16]) : [],
  //       MaritalStatus: row[17] || "",
  //       Gender: row[18] || "",
  //       ReferenceName1: row[19] || "",
  //       ReferencePhone1: row[20] ? _formatPhoneNumber(row[20].toString()) : "",
  //       ReferenceName2: row[21] || "",
  //       ReferencePhone2: row[22] ? _formatPhoneNumber(row[22].toString()) : ""
  //     }));
  //     console.log('formattedData2', formattedData2);


  //     // setCsvData(formattedData);
  //     setEditedData(formattedData2);
  //   };

  //   reader.readAsBinaryString(file);
  // };

  // Define the expected headers in the correct sequence




  const expectedHeaders = [
    "Worca Bench ID",
    ["Full Name (First Middle Last)", "Full Name (Nickname)"],
    "Nickname",
    "Job Title",
    "Archie - Availability",
    "Current Worca Client",
    "Past Worca Client(s)",
    "Currently Interviewing With",
    "Mobile Number",
    "Email",
    "Resume",
    "Archie - English Level",
    "Archie - Salary Range Expectation",
    "Archie - City of Residence",
    "Location",
    "Loom Videos (Self Intro)",
    "Note",
    "Preferred Overlapped time",
    "Experience Level",
    "Top 3 Primary Skills",
    "Preferred Work Type",
    "Expected Salary (Hourly & Monthly)",
    "Weekly Availability",
    "LinkedIn",
    "Portfolio/Github"
  ];

  const expectedHeadersString = expectedHeaders.join(', ');

  // Function to validate the header row sequence
  // function validateHeaderRowSequence(headerRow, expectedHeaders) {
  //   return headerRow.length === expectedHeaders.length &&
  //     headerRow.every((header, index) => header === expectedHeaders[index]);
  // }

  function validateHeaderRowSequence(headerRow, expectedHeaders) {
    if (headerRow.length !== expectedHeaders.length) return false;

    return headerRow.every((header, index) => {
      const expectedHeader = expectedHeaders[index];
      if (Array.isArray(expectedHeader)) {
        return expectedHeader.includes(header);
      }
      return header === expectedHeader;
    });
  }

  //Below Code For Worca
  const handleCSVUpload = (event) => {


    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const fileData = reader.result;
      const workbook = XLSX.read(fileData, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming the first sheet
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Convert array of arrays to array of objects (key-value pairs)
      const headerRow = data[0];

      // console.warn('headerRow ------->', headerRow);
      // console.warn('headerRow ------->', headerRow[1]);

      // Validate the header row sequence
      if (validateHeaderRowSequence(headerRow, expectedHeaders)) {
        const formattedData = data.slice(1).map((row) => {
          const rowData = {};
          headerRow.forEach((header, index) => {
            rowData[header] = row[index];
          });
          return rowData;
        });
        // console.log('formattedData', formattedData);

        const formattedData2 = data.slice(1).map((row) => {
          const chekcFullName = headerRow[1];

          let fullName = row[1] || "";
          let separatedName;

          if (chekcFullName === "Full Name (First Middle Last)") {
            separatedName = separateFullName(fullName);
          } else {
            separatedName = separateFullNameNickName(fullName);
          }

          let expectedSalary = row[21] || "";
          let separatedExpectedSalary = separateSalaryData(expectedSalary);

          return {
            Location: row[14] && getLocationValueByText(row[14]) || "",                //Done
            FirstName: row[1] && separatedName.firstName || "",                       // Done
            LastName: row[1] && separatedName.lastName || "",                         // Done
            PhoneNumber: (row[8] && _formatPhoneNumber(row[8].toString())) || "",
            EmailAddress: row[9] && row[9].trim() || "",
            // DateOfBirth: row[4] ? _formatDate(row[4]) : "",
            DateOfBirth: "",

            MiddleName: row[1] && separatedName.middleName || "", //Done
            // OtherLastName: row[2] || "",                          // Nickname
            OtherLastName: chekcFullName === "Full Name (First Middle Last)" ? row[2] || "" : separatedName.nickName,                          // Nickname
            // Address: row[7] || "",
            Address: "",
            // City: row[8] || "",
            City: row[13] || "",
            // State: row[9] || "",
            State: "",
            // ZipCode: row[10] || "",
            ZipCode: "",

            // SSN: row[11] ? _formatSSN(row[11].toString()) : "",
            SSN: "",
            // AlternateEmailAddress: row[12] || "",
            AlternateEmailAddress: "",
            // AlternatePhoneNumber: row[13] ? _formatPhoneNumber(row[13].toString()) : "",
            AlternatePhoneNumber: "",
            // Position: row[14] ? getValueByText(positionOptions, row[14]) : [],
            Position: row[3] ? getValueByText(positionOptions, row[3]) : [],
            // Shift: row[15] ? getValueByText(shiftsData, row[15]) : [],
            Shift: [],
            // EmploymentType: row[4] ? getEmploymentTypeByText(row[4]) : [],    // Done   Archie - Availability
            // EmploymentType: row[4] ? getValueByText(employmentTypesData, row[4]) : [],
            EmploymentType: row[4]
              ? getValueByText(employmentTypesData, row[4])
              : (row[20] ? getEmploymentTypeValueByText(employmentTypesData2, row[20]) : []),
            // MaritalStatus: row[17] || "",
            MaritalStatus: "",
            // Gender: row[18] || "",
            Gender: "",
            ReferenceName1: "",
            ReferencePhone1: "",
            ReferenceName2: "",
            ReferencePhone2: "",

            //Skills Fileds
            ExperienceDetails: [
              {
                "id": "104755dc-e90c-4f2d-a627-a48210a1e533",
                "ExperienceName": "Primary Skills",
                "Type": "Multi Choice",
                "Data": row[19] ? getValueByText(primarySkillsOptionsValue, row[19]) : []
                // "Data": [
                //   "Java",
                //   "selenium",
                //   "TypeScript"
                // ]
              }
            ],
            //Custom Filed
            CustomFields: {
              BenchID: {
                "Id": "txtBenchID",
                "Data": row[0] || "",
              },
              // JobTitle: {
              //   "Id": "txtJobTitle",
              //   "Data": row[3] || ""
              // },
              // Availability: {
              //   "Id": "txtAvailability",
              //   "Data": row[4] || ""
              // },
              CurrentClient: {
                "Id": "txtCurrentClient",
                "Data": row[5] || ""
              },
              PastClient: {
                "Id": "txtPastClient",
                "Data": row[6] || ""
              },
              CurrentlyInterviewingWith: {
                "Id": "txtCurrentlyInterviewingWith",
                "Data": row[7] || ""
              },
              Resume: {
                "Id": "txtResume",
                "Data": row[10] ? isValidURL(row[10]) ? row[10] : "" : "" || ""
              },
              EnglishLevel: {
                "Id": "txtEnglishLevel",
                "Data": row[11] || ""
              },
              SalaryRangeExpectation: {
                "Id": "txtSalaryRangeExpectation",
                "Data": row[12] || ""
              },
              LoomVideosSelfIntro: {
                "Id": "txtLoomVideosSelfIntro",
                "Data": row[15] ? isValidURL(row[15]) ? row[15] : "" : "" || ""
              },
              Note: {
                "Id": "txtNote",
                "Data": row[16] || ""
              },
              PreferredOverlappedTime: {
                "Id": "drpPreferredOverlappedTime",
                "Data": row[17] || ""
              },
              ExperienceLevel: {
                "Id": "drpExperienceLevel",
                "Data": row[18] || ""
              },

              ExpectedSalaryHourlyFrom: {
                "Id": "txtExpectedSalaryHourlyFrom",
                "Data": row[21] && separatedExpectedSalary.HourlyFrom || ""
              },
              ExpectedSalaryHourlyTo: {
                "Id": "txtExpectedSalaryHourlyTo",
                "Data": row[21] && separatedExpectedSalary.HourlyTo || ""
              },
              ExpectedSalaryMonthlyFrom: {
                "Id": "txtExpectedSalaryMonthlyFrom",
                "Data": row[21] && separatedExpectedSalary.MonthlyFrom || ""
              },
              ExpectedSalaryMonthlyTo: {
                "Id": "txtExpectedSalaryMonthlyTo",
                "Data": row[21] && separatedExpectedSalary.MonthlyTo || ""
              },

              WeeklyAvailability: {
                "Id": "txtWeeklyAvailability",
                "Data": row[22] && extractIntegersStringFromString(row[22]) || ""
              },
              LinkedIn: {
                "Id": "txtLinkedIn",
                "Data": row[23] ? isValidURL(row[23]) ? row[23] : "" : "" || ""
              },
              PortfolioGithub: {
                "Id": "txtPortfolioGithub",
                "Data": row[24] ? isValidURL(row[23]) ? row[23] : "" : "" || ""
              },
            }
          }
        });
        // console.log('formattedData2', formattedData2);


        // setCsvData(formattedData);
        setEditedData(formattedData2);
      } else {
        setEditedData(null);
        // alert("Header column sequence invalid. Check the order of your column headers.")
        toaster.error("Header column sequence invalid. Check the order of your column headers.")

      }
    };

    reader.readAsBinaryString(file);
  };

  function separateFullName(fullName) {
    // Split the name by spaces
    let nameParts = fullName.trim().split(" ");

    let firstName = "";
    let middleName = "";
    let lastName = "";

    if (nameParts.length === 3) {
      firstName = nameParts[0];
      middleName = nameParts[1];
      lastName = nameParts[2];
    } else if (nameParts.length === 2) {
      firstName = nameParts[0];
      middleName = "";
      lastName = nameParts[1];
    } else if (nameParts.length === 1) {
      firstName = nameParts[0];
      middleName = "";
      lastName = "";
    } else {
      firstName = "";
      middleName = "";
      lastName = "";
    }

    return {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName
    };
  }

  function separateFullNameNickName(fullName) {
    if (!fullName.trim()) {
      return {
        firstName: "",
        lastName: "",
        nickName: "",
        middleName: ""
      };
    }

    let nameRegex = /^(\w+)\s+(\w+)(?:\s+\((\w+)\))?$/;

    let matches = fullName.trim().match(nameRegex);

    let firstName = "";
    let lastName = "";
    let nickName = "";

    if (matches) {
      firstName = matches[1];
      lastName = matches[2];
      nickName = matches[3] || "";
    } else {
      let nameParts = fullName.trim().split(/\s+/);
      if (nameParts.length === 2) {
        firstName = nameParts[0];
        lastName = nameParts[1];
      } else if (nameParts.length === 1) {
        firstName = nameParts[0];
      }
    }

    return {
      firstName: firstName,
      lastName: lastName,
      nickName: nickName,
      middleName: ""
    };
  }


  const handleEditDebounced = debounce((newData) => {
    setEditedData(newData);
  }, 300);

  const handleEdit = (e, rowIndex, columnName, isCustomField = false) => {
    const newData = [...editedData];
    if (isCustomField) {
      newData[rowIndex].CustomFields[columnName]['Data'] = e.target.value;
    } else {
      newData[rowIndex][columnName] = e.target.value;
    }
    if (['PhoneNumber', 'AlternatePhoneNumber', 'ReferencePhone1', 'ReferencePhone2'].includes(columnName)) {
      setEditedData(newData);
    } else {
      handleEditDebounced(newData);
    }
  };

  const handleEdit2 = (e, rowIndex, columnName, v) => {
    const newData = [...editedData];
    newData[rowIndex][columnName] = v;
    setEditedData(newData);
  };

  const handleEditExperienceData = (e, rowIndex, columnName, v) => {
    const newData = [...editedData];
    newData[rowIndex][columnName][0]["Data"] = v
    setEditedData(newData);
  }

  const onDeleteClicked = (rowIndex) => {
    const newData = [...editedData];
    newData.splice(rowIndex, 1);
    setEditedData(newData);
  };

  const onSaveClicked = async () => {
    // console.log('Saving data:', editedData);
    if (isFormValid(PEOPLE_FORM)) {
      let disData = {
        applicants: editedData
      }
      await dispatch(savePeopleImport({
        client: tenantId,
        serviceType: "TENANTUSER",
        operation: "SAVEPEOPLEIMPORTMULTIPLE",
        payload: {
          id: "",
          data: JSON.stringify(disData)
        }
      }));

    } else {
      setPFSubmitted(true);
    }
  };

  const onClearFilterClicked = async () => {
    await dispatch(updateFilter({
      id: "",
      name: "",
      whereClause: undefined,
      defaultFilter: false
    }));
    await dispatch(updateRefreshData(true));
    await dispatch(updateStatusIdle());
    await onCloseClicked();
  };

  const onClearFilterImportStatusFailed = async () => {
    await dispatch(updateFilter({
      id: "",
      name: "",
      whereClause: undefined,
      defaultFilter: false
    }));
    await dispatch(updateRefreshData(true));
    dispatch(updateStatusIdle());
    if (errorPeopleImportData) {
      const _editedData = editedData.filter(option =>
        errorPeopleImportData.some(emailAddressId => emailAddressId === option.EmailAddress)
      );
      setEditedData(_editedData);
    }
  };

  useEffect(() => {
    if (savePeopleImportStatus === fetchStatus.SUCCEEDED) {
      onClearFilterClicked();
      // dispatch(updateStatusIdle());
      // onCloseClicked();
    } else if (savePeopleImportStatus === fetchStatus.FAILED) {
      onClearFilterImportStatusFailed();
      // dispatch(updateStatusIdle());
      // if (errorPeopleImportData) {
      //   const _editedData = editedData.filter(option =>
      //     errorPeopleImportData.some(emailAddressId => emailAddressId === option.EmailAddress)
      //   );
      //   setEditedData(_editedData);
      // }
    }
  }, [savePeopleImportStatus]);

  // useEffect(() => {
  //   if (errorPeopleImportData) {
  //     console.error('errorPeopleImportData ', errorPeopleImportData)
  //   }
  // }, [errorPeopleImportData]);

  function getLocationValueByText(text) {
    for (const location of locationOptions) {
      if (location.text === text) {
        return location.value;
      }
    }
    return "";
  }

  const customFields = [
    "BenchID",
    // "JobTitle",
    // "Availability",
    "CurrentClient",
    "PastClient",
    "CurrentlyInterviewingWith",
    "Resume",
    "EnglishLevel",
    "SalaryRangeExpectation",
    "LoomVideosSelfIntro",
    "Note",
    "PreferredOverlappedTime",
    "ExperienceLevel",

    "ExpectedSalaryHourlyFrom",
    "ExpectedSalaryHourlyTo",
    "ExpectedSalaryMonthlyFrom",
    "ExpectedSalaryMonthlyTo",

    "WeeklyAvailability",
    "LinkedIn",
    "PortfolioGithub"
  ];

  const customFieldsOptions = {
    // "JobTitle": ["SDR", "Appointment Setter"],
    // "Availability": ["Available for Full-time", "Available for Part-time", "Not Available", "Employed by Worca"],
    "EnglishLevel": ["1 - Bad", "2 - Acceptable", "3 - Good", "4 - Excellent", "5 - Native Accent"],
    "PreferredOverlappedTime": ["All works", "TW Early morning", "TW Evening "],
    "ExperienceLevel": ["Below 5", "5 - 7 years", "7 - 10 years", "10+ years"]
  };

  const getOptionsByCustomFieldsKey = (key) => {
    if (customFieldsOptions.hasOwnProperty(key)) {
      return (
        <>
          {customFieldsOptions[key].map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  }

  useEffect(() => {
    const handleBackButton = (event) => {
      if (peopleImportModalPopUpDetails.show) {
        onCloseClicked();
      }
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [peopleImportModalPopUpDetails.show]);


  return (
    <>
      {(peopleImportModalPopUpDetails && peopleImportModalPopUpDetails.show) ?
        <>

          <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
            {/* <div className="modal-dialog modal-dialog-centered" role="document"> */}
            {/* <div className="modal-dialog modal-dialog-centered FillOrderDialogWidth modal-fullscreen" role="document"> */}
            {/* <div className={`modal-dialog modal-dialog-centered ${(editedData && editedData.length > 0) ? 'FillOrderDialogWidth modal-fullscreen' : ''} `} role="document"> */}
            <div className={`modal-dialog modal-dialog-centered ${(editedData && editedData.length > 0) ? 'FillOrderDialogWidth' : ''} `} role="document">

              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{peopleImportModalPopUpDetails.title}</h5>
                  <button
                    type="button"
                    className="modal-close-button"
                    onClick={onCloseClicked}>
                    <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row p-2">
                    <div className="col-md-12">
                      {/* <i>{expectedHeadersString}</i> */}
                      {(editedData && editedData.length > 0)
                        ?
                        <>
                          <b className="text-pink">Please fill the missing fields that are required:</b> <br />
                        </> :
                        <>
                          <b className="text-pink">The CSV column sequence must precisely match the following order:</b> <br />
                          {/* {expectedHeaders.map((d, index) =>
                            (<>{`${index + 1} . ${d}`} <br /></>)
                          )} */}

                          {expectedHeaders.map((header, index) => (
                            <React.Fragment key={index}>
                              {`${index + 1}. ${Array.isArray(header) ? header.join(" / ") : header}`} <br />
                            </React.Fragment>
                          ))}
                        </>
                      }

                    </div>
                  </div>
                  <div className="row p-2">
                    <div className="col-md-4">
                      <div className="form-group p-0">
                        <label id="lblImportFile" className="field-label" htmlFor="txtImportFile">Upload CSV File</label>
                        <InputGroup
                          icon={<i className="fa fa-address-card"></i>}
                          control={
                            <input type="file" onChange={handleCSVUpload} accept=".csv, .xlsx, .xls" />
                          } />

                      </div>
                    </div>
                  </div>

                  <div>



                    <br></br>
                    <br></br>
                    {editedData && editedData.length > 0 && (
                      <>
                        <div class="people-table">
                          <table class="table table-responsive table-striped">
                            <thead>
                              <tr>
                                {errorPeopleImportData &&
                                  <th className="action-width"></th>
                                }
                                {Object.keys(editedData[0]).map((header) => {
                                  if (header !== "CustomFields") {
                                    return (
                                      <th key={header} className="single-line">
                                        {_splitCamelCase(header)}
                                      </th>
                                    );
                                  } else {
                                    return Object.keys(editedData[0].CustomFields).map((customHeader) => (
                                      <th key={customHeader} className="single-line">
                                        {_splitCamelCase(customHeader)}
                                      </th>
                                    ));
                                  }
                                })}
                                <th className="action-width">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                /* {editedData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  {Object.entries(row).map(([key, value]) => (
                                    <td key={key}>
                                      <input
                                        type="text"
                                        value={value}
                                        onChange={(e) => handleEdit(e, rowIndex, key)}
                                      />
                                    </td>
                                  ))}
                                  <td>
                                    <button onClick={() => onDeleteClicked(rowIndex)}>Delete</button>
                                  </td>
                                </tr>
                                ))} */
                              }

                              {editedData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  {errorPeopleImportData &&
                                    <td className="align-middle">
                                      <i class="fa-solid fa-triangle-exclamation text-danger" title='Applicant is already exists'></i>
                                    </td>
                                  }
                                  <td>
                                    <Selector
                                      id="drpLocation"
                                      name="drpLocation"
                                      SelectorClasses="form-select"
                                      default={" -- Select -- "}
                                      options={
                                        <>
                                          {locationOptionsValue}
                                          {/* <option value="138EB11A-4A9E-4C97-8C38-F369B869CB22">Venice (Mucker)</option>
                                          <option value="63A33046-0AAE-4778-9781-81D2056027C5">COMPTON</option>
                                          <option value="838EB11A-4A9E-4C97-8C38-F369B869CB21">BUENA PARK</option> */}
                                        </>
                                      }
                                      required={true}
                                      errorMessage="Please select location"
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      value={row.Location}
                                      onChange={(e) => handleEdit(e, rowIndex, "Location")} />
                                  </td>
                                  <td>
                                    <ShortText
                                      id="txtFirstName"
                                      name="txtFirstName"
                                      ShortTextClasses="form-control"
                                      required={true}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      value={row.FirstName}
                                      onChange={(e) => handleEdit(e, rowIndex, "FirstName")} />
                                  </td>
                                  <td>
                                    <ShortText
                                      id="txtLastName"
                                      name="txtLastName"
                                      ShortTextClasses="form-control"
                                      required={true}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      value={row.LastName}
                                      onChange={(e) => handleEdit(e, rowIndex, "LastName")} />
                                  </td>
                                  <td>
                                    <PhoneText
                                      id={"txtPhoneNumber" + rowIndex}
                                      name={"txtPhoneNumber" + rowIndex}
                                      PhoneTextClasses="form-control"
                                      placeholder="Please enter phone number"
                                      required={true}
                                      errorMessage={"Please enter phone number"}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      value={row.PhoneNumber}
                                      onChange={(e) => handleEdit(e, rowIndex, "PhoneNumber")} />
                                  </td>
                                  <td>
                                    <EmailText
                                      id={"txtEmailAddress" + rowIndex}
                                      name="txtEmailAddress"
                                      EmailTextClasses="form-control"
                                      required={true}
                                      errorMessage="Please enter email"
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      value={row.EmailAddress}
                                      onChange={(e) => handleEdit(e, rowIndex, "EmailAddress")}
                                    />
                                  </td>
                                  <td>
                                    <DateText
                                      id={"txtDateOfBirth" + rowIndex}
                                      name="txtDateOfBirth"
                                      DateTextClasses="form-control"
                                      // required={true}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      value={row.DateOfBirth}
                                      onChange={(e) => handleEdit(e, rowIndex, "DateOfBirth")}
                                      max={(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate())).toISOString().split('T')[0]}
                                      min={(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate())).toISOString().split('T')[0]} />
                                  </td>
                                  <td>
                                    <ShortText
                                      // required={applicant && applicant.MiddleName.Required}
                                      errorMessage={applicantDetails.MiddleNameError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtMiddleName"
                                      name="txtMiddleName"
                                      ShortTextClasses="form-control"
                                      placeholder={applicantDetails ? applicantDetails.MiddleName : ""}
                                      value={row.MiddleName}
                                      onChange={(e) => handleEdit(e, rowIndex, 'MiddleName')} />
                                  </td>
                                  <td>
                                    <ShortText
                                      // required={applicant && applicant.OtherLastName.Required}
                                      errorMessage={applicantDetails.OtherLastNameError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtOtherLastName"
                                      name="txtOtherLastName"
                                      ShortTextClasses="form-control"
                                      placeholder={applicantDetails ? applicantDetails.OtherLastName : ""}
                                      value={row.OtherLastName}
                                      onChange={(e) => handleEdit(e, rowIndex, 'OtherLastName')} />
                                  </td>
                                  <td>
                                    <ShortText
                                      // required={applicant && applicant.Address.Required}
                                      // required={true}
                                      errorMessage={applicantDetails.AddressError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtAddress"
                                      name="txtAddress"
                                      ShortTextClasses="form-control"
                                      placeholder={applicantDetails ? applicantDetails.AddressPlaceHolder : ""}
                                      value={row.Address}
                                      onChange={(e) => handleEdit(e, rowIndex, 'Address')} />
                                  </td>
                                  <td>
                                    <ShortText
                                      // required={applicant && applicant.City.Required}
                                      // required={true}
                                      errorMessage={applicantDetails.CityError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtCity"
                                      name="txtCity"
                                      ShortTextClasses="form-control"
                                      placeholder={applicantDetails ? applicantDetails.City : ""}
                                      value={row.City}
                                      onChange={(e) => handleEdit(e, rowIndex, 'City')} />
                                  </td>
                                  <td>
                                    <ShortText
                                      // required={applicant && applicant.City.Required}
                                      // required={true}
                                      errorMessage={applicantDetails.State}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtState"
                                      name="txtState"
                                      ShortTextClasses="form-control"
                                      placeholder={applicantDetails ? applicantDetails.State : ""}
                                      value={row.State.trim()}
                                      onChange={(e) => handleEdit(e, rowIndex, 'State')} />
                                  </td>
                                  <td>
                                    <ZipCodeText
                                      // required={applicant && applicant.ZipCode.Required}
                                      // required={true}
                                      errorMessage={applicantDetails.ZipCodeError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtZipcode"
                                      name="txtZipcode"
                                      ZipCodeTextClasses="form-control"
                                      value={row.ZipCode}
                                      onChange={(e) => handleEdit(e, rowIndex, 'ZipCode')} />
                                  </td>

                                  {/* Personal_Info_2 */}

                                  <td>
                                    <SSNText
                                      required={false}
                                      errorMessage={applicantDetails.SSNError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id={"txtSSN" + rowIndex}
                                      name="txtSSN"
                                      SSNTextClasses="form-control"
                                      placeholder="000000000"
                                      value={row.SSN}
                                      onChange={(e) => handleEdit(e, rowIndex, 'SSN')}
                                    />
                                  </td>
                                  <td>
                                    <EmailText
                                      // required={applicant && applicant.AlternateEmailAddress && applicant.AlternateEmailAddress.Required}
                                      errorMessage={applicantDetails.EmailAddressError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtAlternateEmail"
                                      name="txtAlternateEmail"
                                      EmailTextClasses="form-control"
                                      placeholder="xyz@example.com"
                                      value={row.AlternateEmailAddress}
                                      onChange={(e) => handleEdit(e, rowIndex, 'AlternateEmailAddress')} />
                                  </td>
                                  <td>
                                    <PhoneText
                                      required={false}
                                      errorMessage={'Enter phone number'}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id={"txtAlternatePhone" + rowIndex}
                                      name="txtAlternatePhone"
                                      PhoneTextClasses="form-control"
                                      placeholder=""
                                      value={row.AlternatePhoneNumber}
                                      onChange={(e) => handleEdit(e, rowIndex, 'AlternatePhoneNumber')}
                                    />
                                  </td>
                                  <td>
                                    <MultiSelector
                                      id="drpPositions"
                                      name="drpPositions"
                                      value={row.Position}
                                      options={positionOptions}
                                      onChange={(e, v) => handleEdit2(e, rowIndex, 'Position', v)}
                                      // required={applicant && applicant.Position.Required}
                                      // required={true}
                                      errorMessage={applicantDetails.PositionError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted} />
                                  </td>
                                  <td>
                                    <MultiSelector
                                      id="drpShifts"
                                      name="drpShifts"
                                      value={row.Shift}
                                      options={shiftsData}
                                      onChange={(e, v) => handleEdit2(e, rowIndex, 'Shift', v)}
                                      // required={applicant && applicant.Shift.Required}
                                      // errorMessage={applicant && applicant.Shift.Validation}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted} />
                                  </td>
                                  <td>
                                    <MultiSelector
                                      id="drpEmploymentTypes"
                                      name="drpEmploymentTypes"
                                      value={row.EmploymentType}
                                      options={employmentTypesData}
                                      onChange={(e, v) => handleEdit2(e, rowIndex, 'EmploymentType', v)}
                                      // required={applicant && applicant.EmploymentType.Required}
                                      // required={true}
                                      // errorMessage={applicant && applicant.EmploymentType.Validation}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted} />
                                  </td>
                                  <td>
                                    <Selector
                                      // required={applicant.MaritalStatus.required}
                                      // errorMessage={applicantDetails.validation} 
                                      // required={true}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="rdbtnMaritalStatus"
                                      name="rdbtnMaritalStatus"
                                      SelectorClasses="form-select"
                                      value={row.MaritalStatus}
                                      options={
                                        <>
                                          <option value="Married">Married</option>
                                          <option value="Unmarried">Unmarried</option>
                                          <option value="HOH">HOH</option>
                                        </>
                                      }
                                      onChange={(e) => handleEdit(e, rowIndex, 'MaritalStatus')} />
                                  </td>
                                  <td>
                                    <Selector
                                      // required={applicant.Gender.required}
                                      // required={true}
                                      errorMessage="Please select gender"
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="rdbtnGender"
                                      name="rdbtnGender"
                                      SelectorClasses="form-select"
                                      value={row.Gender}
                                      options={
                                        <>
                                          <option value="Male">Male</option>
                                          <option value="Female">Female</option>
                                          <option value="Other">Other</option>
                                        </>
                                      }
                                      onChange={(e) => handleEdit(e, rowIndex, 'Gender')} />
                                  </td>

                                  <td>
                                    <ShortText
                                      // required={applicant && applicant.ReferenceName1.Required}
                                      // errorMessage={ applicantDetails.RefNameError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtRefName1"
                                      name="txtRefName1"
                                      ShortTextClasses="form-control"
                                      value={row.ReferenceName1}
                                      onChange={(e) => handleEdit(e, rowIndex, 'ReferenceName1')}
                                    // placeholder={applicantDetails ? applicantDetails.RefName : ""} 
                                    />
                                  </td>
                                  <td>
                                    <PhoneText
                                      // required={false}
                                      // errorMessage={'Enter phone number'}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id={"txtRefPhone1" + rowIndex}
                                      name="txtRefPhone1"
                                      PhoneTextClasses="form-control"
                                      placeholder=""
                                      value={row.ReferencePhone1}
                                      onChange={(e) => handleEdit(e, rowIndex, 'ReferencePhone1')}
                                    />
                                  </td>
                                  <td>
                                    <ShortText
                                      // required={applicant && applicant.ReferenceName1.Required}
                                      // errorMessage={ applicantDetails.RefNameError}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id="txtRefName2"
                                      name="txtRefName2"
                                      ShortTextClasses="form-control"
                                      value={row.ReferenceName2}
                                      onChange={(e) => handleEdit(e, rowIndex, 'ReferenceName2')}
                                    // placeholder={applicantDetails ? applicantDetails.RefName : ""} 
                                    />
                                  </td>
                                  <td>
                                    <PhoneText
                                      // required={false}
                                      // errorMessage={'Enter phone number'}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted}
                                      id={"txtRefPhone2" + rowIndex}
                                      name="txtRefPhone2"
                                      PhoneTextClasses="form-control"
                                      placeholder=""
                                      value={row.ReferencePhone2}
                                      onChange={(e) => handleEdit(e, rowIndex, 'ReferencePhone2')}
                                    />
                                  </td>

                                  {/* experienceOptions Start */}
                                  <td>
                                    <MultiSelector
                                      id="104755dc-e90c-4f2d-a627-a48210a1e533"
                                      name="104755dc-e90c-4f2d-a627-a48210a1e533"
                                      value={row.ExperienceDetails[0]["Data"]}
                                      options={

                                        experienceOptions
                                          .find(
                                            (option) => option.ExperienceName === "Primary Skills"
                                          )
                                          .ExperienceOptions.map((option) => ({
                                            text: option,
                                            value: option
                                          }))
                                      }
                                      onChange={(e, v) => handleEditExperienceData(e, rowIndex, 'ExperienceDetails', v)}
                                      form={PEOPLE_FORM}
                                      submited={pfSubmitted} />
                                  </td>
                                  {/* experienceOptions End*/}

                                  {customFields.map((field, index) => {
                                    const fieldData = row.CustomFields[field]['Data'];
                                    return (
                                      <td key={rowIndex + index}>
                                        {(() => {
                                          if (["EnglishLevel", "PreferredOverlappedTime", "ExperienceLevel"].includes(field)) {
                                            return (
                                              <Selector
                                                id={"drp" + field}
                                                name={"drp" + field}
                                                SelectorClasses="form-select"
                                                default={" -- Select -- "}
                                                options={
                                                  <>
                                                    {getOptionsByCustomFieldsKey(field)}
                                                  </>
                                                }
                                                form={PEOPLE_FORM}
                                                submited={pfSubmitted}
                                                value={row.CustomFields[field]['Data']}
                                                onChange={(e) => handleEdit(e, rowIndex, field, true)} />
                                            );
                                          } else if (["Resume", "LoomVideosSelfIntro", "LinkedIn", "PortfolioGithub"].includes(field)) {
                                            return (
                                              <UrlText
                                                id={"drp" + field}
                                                name={"drp" + field}
                                                UrlTextClasses="form-control"
                                                placeholder=""
                                                value={row.CustomFields[field]['Data']}
                                                onChange={(e) => handleEdit(e, rowIndex, field, true)}
                                                form={PEOPLE_FORM}
                                                submited={pfSubmitted} />
                                            );
                                          } else if (["ExpectedSalaryHourlyFrom", "ExpectedSalaryHourlyTo", "ExpectedSalaryMonthlyFrom", "ExpectedSalaryMonthlyTo", "WeeklyAvailability"].includes(field)) {
                                            return (
                                              <NumberText
                                                form={PEOPLE_FORM}
                                                submited={pfSubmitted}
                                                id={"drp" + field}
                                                name={"drp" + field}
                                                NumberTextClasses="form-control"
                                                value={row.CustomFields[field]['Data']}
                                                onChange={(e) => handleEdit(e, rowIndex, field, true)}
                                                placeholder=""
                                              />
                                            );
                                          } else {
                                            return (
                                              <ShortText
                                                form={PEOPLE_FORM}
                                                submited={pfSubmitted}
                                                id={"txt" + field}
                                                name={"txt" + field}
                                                ShortTextClasses="form-control"
                                                value={row.CustomFields[field]['Data']}
                                                onChange={(e) => handleEdit(e, rowIndex, field, true)}
                                              />
                                            );
                                          }
                                        })()}
                                      </td>
                                    );
                                  })}


                                  <td>
                                    <i className='fa fa-trash ps-3' onClick={(e) => onDeleteClicked(rowIndex)}></i>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>



                </div>
                <div className="modal-footer">
                  {(savePeopleImportStatus === fetchStatus.LOADING) ? <EllipsisLoader /> : <>
                    <CancelButton
                      CancelButtonClasses="me-2"
                      onCancelClicked={() => onCloseClicked()} />
                    <SaveButton
                      onSaveClicked={() => onSaveClicked()} />
                  </>}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show">
          </div>
        </>
        :
        <></>
      }
    </>
  )
}

export default PeopleImportModalPopUp