import { nanoid } from '@reduxjs/toolkit';
import React, { useState, useEffect } from 'react';


import bagCheckWhiteImg from './../../../../assets/images/clipart/bag-check-white.svg';
import pagelinewhiteImg from './../../../../assets/images/clipart/page-line-white.svg';
import phoneCallWhiteImg from './../../../../assets/images/clipart/phone-call-white.svg';
import cloudImg from './../../../../assets/images/clipart/cloud.svg';



import filePlusCircleImg from './../../../../assets/images/clipart/file-plus-circle.svg';
import messageImg from './../../../../assets/images/clipart/message.svg';
import i9Img from './../../../../assets/images/clipart/i9.svg';
import fileImg from './../../../../assets/images/clipart/file.svg';
import genderImg from './../../../../assets/images/clipart/gender.svg';
import termsImg from './../../../../assets/images/clipart/terms.svg';
import folderImg from './../../../../assets/images/clipart/folder.svg';
import filesImg from './../../../../assets/images/clipart/files.svg';

import sortImg from './../../../../assets/images/clipart/sort.svg';
import { isObjectArray } from '../../../../utilities/utilityFunctions';
import { toLocalDate } from '../../../../common/common';
import JobStatusCard from '../../../../component/Card/JobStatusCard';

function JobOrderStatusLog(props) {

  const [logs, setLogs] = useState();
  const [sort, setSort] = useState(false);

  useEffect(() => {
    let _statusLogs = props.statusLogs;
    let _logs;
    let date = '';
    if (isObjectArray(_statusLogs) && _statusLogs.length > 0) {
      _logs = _statusLogs.map((log, index) => (
        log !== null &&
        <JobStatusCard
          key={nanoid()}
          JobStatusCardClasses=""
          logType={log.LogType}
          logValue={log.LogValue}
          content={log.Content}
          client={log.Client}
          userName={log.UserName}
          timeStamp={toLocalDate(log.TimeStamp)}
        />

      ));
      {/* <div>
              <div className="timeline-detail-status text-muted status-card-timestamp">
                <span><i className="fa fa-calendar-check-o mr-2" aria-hidden="true"></i></span>{`${log.UserName} ${log.LogValue === -1 ? "added on" : "updated status on"} ${toLocalDate(log.TimeStamp)}`}
              </div>
            </div> */}

      setLogs(_logs);
    }
    else {
      setLogs(undefined);
    }
  }, [props.statusLogs]);

  const onSort = () => {
    setSort(!sort);
    const sortData = [...logs].reverse();
    setLogs(sortData);
  }

  const onLogSearch = (e) => {
    let text = e.target.value;
    var items = props.statusLogs ? props.statusLogs.filter((d) => {
      return d && ((d.Content && d.Content.toLowerCase().includes(text.toLowerCase())) ||
        (d.Client && d.Client.toLowerCase().includes(text.toLowerCase())));
    }) : [];

    let _logs = items.map((log, index) => (
      <JobStatusCard
        key={nanoid()}
        JobStatusCardClasses=""
        logType={log.LogType}
        logValue={log.LogValue}
        content={log.Content}
        client={log.Client}
        userName={log.UserName}
        timeStamp={toLocalDate(log.TimeStamp)}
      />
    ));
    setLogs(_logs);
  }

  return (
    <React.Fragment>
      <div className="P-3">
        <div className="pt-3">
          {/* <input type="text" className='c-form-control w-25' />
          <button className="c-btn white-btn mx-2" type="button" ><img src={sortImg} /> Sort</button>
          <select name="modifiedDate" className="me-3">
            {<option className="" value="">Modified Date</option>}
            {<option className="" value="">Modified Date</option>}
          </select> */}

          <div className="separator">
            <div className="line line-start"></div>
            <input type="text" className="separator-input" placeholder="Search"
              onChange={(e) => onLogSearch(e)}
            />

            {/* <button className="separator-input mx-2" type="button" ><img src={sortImg} /> Sort</button> */}

            <div className="sort-btn-group mx-2" onClick={onSort}>
              {/* <button className={`c-btn sort-btn-transparent sort-btn-active`} type="button"><img src={sortImg} /></button> */}
              {/* <button className={`c-btn sort-btn-transparent`} type="button">{sort ? <span className="fa fa-arrow-up-wide-short fa-lg"></span> : <span className="fa fa-arrow-down-wide-short fa-lg"></span>}</button> */}
              <button className={`c-btn sort-btn-transparent sort-btn-active`} type="button"><span className={sort ? 'fa fa-arrow-up-wide-short fa-lg' : 'fa fa-arrow-down-wide-short fa-lg'}></span></button>
              <button className={`c-btn sort-btn-transparent`} type="button">Sort</button>
            </div>

            <div className="line"></div>
            <select name="modifiedDate" className="separator-input style-2 mx-2">
              {<option className="" value="">Filter By</option>}
              {<option className="" value="">Filter By</option>}
            </select>
            <div className="separator-input style-2 mx-2">Sep 22, 2022</div>
            <div className="line"></div>
          </div>

        </div>

        <div className="d-flex">
          <div className="col-md-8 col-lg-8">
            <div className="tracking-list pt-3 ps-3">

              {logs}

              {/*
              <div className="tracking-item">
                <div className="tracking-icon status-intransit bg-blue">
                  <img src={bagCheckWhiteImg} />
                </div>
                <div className="tracking-content">
                  <p>
                    <span className="p-name">Rob Smith</span>
                    <span className="p-changed"> Updated Status on - 09/22/2022, 10:46:21 </span>
                  </p>
                  <p className="p-subtext">Job modified successfully by <b> Rob Smith </b></p>
                  <p className="p-dark-gray">Note:Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
              </div>

              <div className="tracking-item">
                <div className="tracking-icon status-intransit bg-blue">
                  <img src={bagCheckWhiteImg} />
                </div>
                <div className="tracking-content">
                  <p>
                    <span className="p-name">Rob Smith</span>
                    <span className="p-changed"> Updated Status on - 09/22/2022, 10:46:21 </span>
                  </p>
                  <p className="p-subtext">Job modified successfully by <b> Rob Smith </b></p>
                  <p className="p-dark-gray">Note:Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
              </div> 
              
              <div className="tracking-item">
                <div className="tracking-icon status-inforeceived bg-purple">
                  <img src={pagelinewhiteImg} />
                </div>
                <div className="tracking-content">
                  <p>
                    <span className="p-name">Howard Caroll</span>
                    <span className="p-changed"> Uploaded documents</span>
                    <span className="p-status"> Dispatched</span>
                    <span className="p-changed">  09/22/2022, 10:46:21 </span>
                  </p>
                  <p className="p-subtext"><b>Patricia Mireles</b> dispatched successfully by <b>Howard Caroll</b></p>
                  <p className="p-dark-gray">Note: It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
              </div>

              <div className="tracking-item">
                <div className="tracking-icon status-inforeceived bg-success">
                  <img src={phoneCallWhiteImg} />
                </div>
                <div className="tracking-content">
                  <p>
                    <span className="p-name">Howard Carroll</span>
                    <span className="p-changed"> Applicationt</span>
                    <span className="p-status"> Undispatched</span>
                    <span className="p-changed"> 09/20/2022, 17:45:12</span>
                  </p>
                  <p className="p-subtext"><b>Mohini</b> latest update undispatched successfully by <b>Howard Carroll</b></p>
                  <p className="p-dark-gray"><span className="p-light-gray">Client: Corporate eWaste Solutions</span></p>
                  <p className="p-dark-gray">Note: There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour</p>
                </div>
              </div>

              <div className="tracking-item">
                <div className="tracking-icon status-intransit bg-blue">
                  <img src={bagCheckWhiteImg} />
                </div>
                <div className="tracking-content">
                  <p>
                    <span className="p-name">Cynthia Villa</span>
                    <span className="p-changed"> Job Inactivated  09/19/2022, 13:45:12</span>
                  </p>
                  <p className="p-subtext">Job inactivated successfully</p>
                  <span className="p-light-gray">Client: Corporate eWaste Solutions</span>
                  <p className="p-dark-gray">Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                </div>
              </div>

              <div className="tracking-item pb-0">
                <div className="tracking-icon status-intransit bg-gold">
                  <img src={cloudImg} />
                </div>
                <div className="tracking-content">
                  <p>
                    <span className="p-name">Corey Jimenez</span>
                    <span className="p-changed"> Job Creation  09/19/2022, 13:45:12</span>
                  </p>
                  <p className="p-subtext">Job created successfully</p>
                  <span className="p-light-gray">Client: Corporate eWaste Solutions</span>
                  <p className="p-dark-gray">Note: Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default JobOrderStatusLog