import store from '../../../store';
import { updateLocationFilter, updateProspectsStatusFilter } from '../../../component/Controls/filters/reducerSlices/prospectsFilterSlice';

export const onProspectsLocationChanged = async (e, value, locations) => {
    await store.dispatch(updateLocationFilter({
        locations: locations,
        value: value
    }));
};

export const onProspectsStatusChanged = async (e, value, shifts) => {
    await store.dispatch(updateProspectsStatusFilter({
        shifts: shifts,
        value: value
    }));
};