import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    sortByText: "Last Updated Date",
    sortBy: 1,
    ascending: false,
    open: false,
    sortItems: [
        {
            text: "Last Updated Date",
            value: 1
        },
        {
            text: "Applied Date",
            value: 2
        }
    ]
};

export const prospectsPeopleSorterSlice = createSlice({
    name: 'prospectsPeopleSorter',
    initialState,
    reducers: {
        updateAscending: (state, action) => {
            state.ascending = action.payload;
        },
        toggleMenu: (state, action) => {
            state.open = action.payload;
        },
        toggleAscending: (state, action) => {
            state.ascending = action.payload;
        },
        setSortItem: (state, action) => {
            const { sortByText, sortBy, open } = action.payload;
            state.sortByText = sortByText;
            state.sortBy = sortBy;
            state.open = open;
        },
        hideMenu: state => {
            state.open = false;
        }
    }
});

export const { updateAscending, setSortItem, toggleMenu, toggleAscending, hideMenu } = prospectsPeopleSorterSlice.actions;

export default prospectsPeopleSorterSlice.reducer

export const selectProspectsPeopleSorterDetail = state => {
    return {
        sortByText: state.prospectsPeopleSorter.sortByText,
        sortBy: state.prospectsPeopleSorter.sortBy,
        ascending: state.prospectsPeopleSorter.ascending,
        open: state.prospectsPeopleSorter.open,
        sortByItems: state.prospectsPeopleSorter.sortItems
    }
}

export const selectSort = state => {
    return {
        by: state.prospectsPeopleSorter.sortBy,
        ascending: state.prospectsPeopleSorter.ascending
    }
};
