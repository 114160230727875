import React from 'react';
import { useSelector } from 'react-redux';
import './progress.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaCheck, FaRegIdBadge, FaUserFriends, FaBriefcase, FaUserGraduate, FaLocationArrow, FaGem, FaTags } from 'react-icons/fa';
import { accountService } from '../../features/accounts/services/accountService';


const SetupProgress = (props) => {
  let myState = useSelector((state) => state.accounts);

  const user = accountService.getAuthenticatedUser();
  //   let name = user && user.firstName ? user.firstName : "";


  let companyDetailsCompleted = (props.currentPage === 'companyLocation' || props.currentPage === 'companyPositions' || props.currentPage === 'companySkillGroup' || props.currentPage === 'companyExperience' || props.currentPage === 'companyStatuses') ? true : false;
  let companyLocationCompleted = (props.currentPage === 'companyPositions' || props.currentPage === 'companySkillGroup' || props.currentPage === 'companyExperience' || props.currentPage === 'companyStatuses') ? true : false;
  let companyPositionsCompleted = (props.currentPage === 'companySkillGroup' || props.currentPage === 'companyExperience' || props.currentPage === 'companyStatuses') ? true : false;
  let companySkillGroupCompleted = (props.currentPage === 'companyExperience' || props.currentPage === 'companyStatuses') ? true : false;
  let companyExperienceCompleted = (props.currentPage === 'companyStatuses') ? true : false;
  let companyStatusesCompleted = false;

  const percentage = props.percentage ? props.percentage : '0';


  return (
    <>
      <div className="card-body">
        <div className="row p-md-2">
          <div className="col-md-12 text-center">
            <div className="animate__animated animate__swing">
              <div className="stepper-item-sub-heading">Welcome!</div>
            </div>
            <div className="animate__animated animate__bounceIn text-white">
              {/* <div className="stepper-item-heading">{name ? name : ''}</div> */}
              <div className="stepper-item-heading">Begin Setup</div>
            </div>
          </div>

          {/* <div className="col-md-12 text-center mt-2 d-none d-md-block">
            <div className="animate__animated animate__zoomI text-white">
              <div className="stepper-item-details">
                Setup Configuration
              </div>
            </div>
          </div> */}

          <div className="col-md-12 d-flex justify-content-center mt-5 d-none d-md-flex ">
            {/* <div style={{ width: 200, height: 200 }}>
              <CircularProgressbar
                background={true}
                value={percentage}
                text={percentage + `%`}
                className="<div className='animate__animated animate__fadeIn'>25</div>"
                styles={buildStyles({
                  pathColor: '#F02469',
                  textColor: '#0A253E',
                  trailColor: '#E6EAF3',
                  backgroundColor: '#FFF'
                })}
              />
            </div> */}


            <div class="">
              {/* completed  */}
              {/* <div class="v-step v-completed"> */}
              <div class={`v-step ${props.currentPage === 'companyDetails' ? 'v-active' : companyDetailsCompleted && 'v-completed'}`}>
                <div class="v-stepper">
                  <div class="v-circle">{companyDetailsCompleted ? <FaCheck /> : <FaRegIdBadge />}</div>
                  <div class="v-line"></div>
                </div>
                <div class="v-content">
                  Company Details
                </div>
              </div>

              {/* active  */}
              {/* <div class="v-step v-active"> */}
              <div class={`v-step ${props.currentPage === 'companyLocation' ? 'v-active' : companyLocationCompleted && 'v-completed'}`}>
                <div class="v-stepper">
                  <div class="v-circle">{companyLocationCompleted ? <FaCheck /> : <FaLocationArrow />}</div>
                  <div class="v-line"></div>
                </div>
                <div class="v-content">
                  Company Locations
                </div>
              </div>

              <div class={`v-step ${props.currentPage === 'companyPositions' ? 'v-active' : companyPositionsCompleted && 'v-completed'}`}>
                <div class="v-stepper">
                  <div class="v-circle">{companyPositionsCompleted ? <FaCheck /> : <FaUserGraduate />}</div>
                  <div class="v-line"></div>
                </div>
                <div class="v-content">
                  Positions
                </div>
              </div>

              <div class={`v-step ${props.currentPage === 'companySkillGroup' ? 'v-active' : companySkillGroupCompleted && 'v-completed'}`}>
                <div class="v-stepper">
                  <div class="v-circle">{companySkillGroupCompleted ? <FaCheck /> : <FaGem />}</div>
                  <div class="v-line"></div>
                </div>
                <div class="v-content">
                  Skill Group
                </div>
              </div>

              {/* regular  */}
              <div class={`v-step ${props.currentPage === 'companyExperience' ? 'v-active' : companyExperienceCompleted && 'v-completed'}`}>
                <div class="v-stepper">
                  <div class="v-circle">{companyExperienceCompleted ? <FaCheck /> : <FaGem />}</div>
                  <div class="v-line"></div>
                </div>
                <div class="v-content">
                  Skills Fields
                </div>
              </div>

              <div class={`v-step ${props.currentPage === 'companyStatuses' ? 'v-active' : companyStatusesCompleted && 'v-completed'}`}>
                <div class="v-stepper">
                  <div class="v-circle">{companyStatusesCompleted ? <FaCheck /> : <FaTags />}</div>
                  <div class="v-line"></div>
                </div>
                <div class="v-content">
                  Statuses
                </div>
              </div>

            </div>

          </div>
          <div className="col-md-12 text-center mt-5 d-none d-md-block">
            <div className="animate__animated animate__zoomI text-white">
              <div className="stepper-item-details">
                {/* {props.subTitle} */}
              </div>
            </div>
            {/* <p className="animate__animated animate__zoomIn text-white">complete and Get <span className='text-success'>+10%</span></p> */}
            <p className="animate__animated animate__zoomIn text-white">Complete and get start</p>
          </div>
        </div>
      </div>
      <div className="card-footer mb-md-5">
        {/* <div className="stepper-wrapper">
          <div className={`stepper-item ${myState.currentStep === 'companyDetails' ? 'active' : companyDetailsCompleted && 'completed'}`}>
            <div className="step-counter">{companyDetailsCompleted ? <FaCheck /> : <FaRegIdBadge />}</div>
            <div className="step-name">Company Details</div>
          </div>
          <div className={`stepper-item ${myState.currentStep === 'companyLocation' ? 'active' : companyLocationCompleted && 'completed'}`}>
            <div className="step-counter">{companyLocationCompleted ? <FaCheck /> : <FaLocationArrow />}</div>
            <div className="step-name">Company Locations</div>
          </div>
          <div className={`stepper-item ${myState.currentStep === 'companyPositions' ? 'active' : companyPositionsCompleted && 'completed'}`}>
            <div className="step-counter">{companyPositionsCompleted ? <FaCheck /> : <FaUserGraduate />}</div>
            <div className="step-name">Positions Details</div>
          </div>
          <div className={`stepper-item ${myState.currentStep === 'Education' ? 'active' : educationCompleted && 'completed'}`}>
            <div className="step-counter">{educationCompleted ? <FaCheck /> : <FaUserGraduate />}</div>
            <div className="step-name">Skills <br />Fields</div>
          </div>
        </div> */}

      </div>
    </>
  )
}

export default SetupProgress;