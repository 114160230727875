export const onNoteChanged = (lead, noteDetails) => {
    let _lead = JSON.parse(JSON.stringify(lead));
    if (!_lead.LeadNote) {
        _lead["LeadNote"] = {
            "Id": "txtLeadNote",
            "Data": ""
        }
    }
    _lead.LeadNote.Data = noteDetails.note;
    if (!_lead.StatusLogs) {
        _lead.StatusLogs = [];
    }
    _lead.StatusLogs.push(noteDetails.log);
    return _lead;
};
