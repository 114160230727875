import React from 'react';
import { Bar } from 'react-chartjs-2';


function MultipleVerticalBarChart(props) {

  const backgroundColor = ['rgba(23, 162, 184, 0.8)', 'rgba(40, 168, 69, 0.8)', 'rgba(220, 53, 69, 0.8)', 'rgba(255, 193, 1, 0.8)', '#007bff', '#e83e8c', '#28a745', '#6610f2', '#fd7e14', '#ffc107', '#28a745', '#80cdc1', '#17a2b8', '#01665e', '#003c30'];

  const dict = props.multipleBarChartData.datasets.map((key, index) => {
    return {
      label: key.label,
      data: key.data,
      backgroundColor: props.backgroundColor ? props.backgroundColor[index] : backgroundColor[index]
    };
  });

  const data = {
    labels: props.multipleBarChartData.labels,
    datasets: dict
  }

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      }
    },
    responsive: true,
    maintainAspectRatio: true,
    scale: {
      ticks: {
        precision: 0
      }
    },
  };
  return (
    <>
      <Bar data={data} options={options} />
    </>
  );
}
export default MultipleVerticalBarChart;