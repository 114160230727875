import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    title: "",
    show: false,
    isActive: false,
    leadId: undefined,
    updatedLead: undefined
};

export const leadNoteFlyoutSlice = createSlice({
    name: 'leadNoteFlyout',
    initialState,
    reducers: {
        showLeadNoteFlyout: (state, action) => {
            const { title, leadId, confirmedCallback } = action.payload
            state.title = title;
            state.show = true;
            state.isActive = false;
            state.leadId = leadId;
        },
        closeLeadNoteFlyout: state => {
            state.show = false;
            state.leadId = undefined;
        },
        beforeCloseLeadNoteFlyout: state => {
            state.isActive = true;
        },
        saveUpdatedLead: (state, action) => {
            state.updatedLead = action.payload;
            console.log('---------->', action.payload)
        }
    }
});

export const { showLeadNoteFlyout, closeLeadNoteFlyout, beforeCloseLeadNoteFlyout, saveUpdatedLead } = leadNoteFlyoutSlice.actions;

export default leadNoteFlyoutSlice.reducer

export const selectFlyoutDetail = state => {
    return {
        title: state.leadNoteFlyout.title,
        show: state.leadNoteFlyout.show,
        isActive: state.leadNoteFlyout.isActive,
        leadId: state.leadNoteFlyout.leadId,
        updatedLead: state.leadNoteFlyout.updatedLead
    }
}