import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _getLocationText } from '../../common/common';
import ShortText from '../Controls/custom/textbox/shortText';
import { isFormValid } from '../Controls/services/formService';
import { closeCompanyFlyout, createCompany, selectCompanyFlyoutCreateMode, selectCompanyFlyoutEditmode, selectCompanyFlyoutShow, selectCompanyForm, selectCompanyFormStatus, selectCreationStatus, selectModificationStatus, pdateSingleData, selectCompanyById, updateCompany, updateSingleData } from '../../features/setting/reducerSlices/companySlice';
import { onValueChanged, sections } from '../../features/setting/services/companySaver';
import { AccountPlan, Operation } from '../../common/constants';
import Selector from '../Controls/custom/selectors/selectors';
import { isObjectArray } from '../../utilities/utilityFunctions';
import { fetchStatus, tenantId } from '../../api/client';
import { userStatuses } from '../../features/shared/services/masterData';
import { nanoid } from '@reduxjs/toolkit';
import InputGroup from '../Controls/custom/inputGroup/inputGroup';
import ReactTooltip from 'react-tooltip';
import EllipsisLoader from '../Controls/loaders/ellipsisLoader';
import { accountService } from '../../features/accounts/services/accountService';
import PhoneText from '../Controls/custom/textbox/phoneText';
import clientLogo from '../../assets/images/clipart/client-logo.svg';
import FileUpload from '../Controls/custom/fileUpload/fileUpload';
import UrlText from '../Controls/custom/textbox/urlText';



function CompanyFlyout(props) {

    const dispatch = useDispatch();
    const companyFlyoutShow = useSelector(selectCompanyFlyoutShow);

    const COMPANY_FORM = "CompanyForm";

    const [createMode, setCreatemode] = useState(false);
    const [editMode, setEditmode] = useState(false);
    const [isActive, setActive] = useState(false);
    const [cFInvalid, setCFInvalid] = useState(false);
    const [cfSubmitted, setCFSubmitted] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [statusOptions, setStatusOptions] = useState([]);

    const companyFlyoutCreateMode = useSelector(selectCompanyFlyoutCreateMode);
    const companyFlyoutEditMode = useSelector(selectCompanyFlyoutEditmode);
    const companyFormStatus = useSelector(selectCompanyFormStatus);
    const companyForm = useSelector(selectCompanyForm);
    const company = useSelector(selectCompanyById);
    const statusCreationStatus = useSelector(selectCreationStatus);
    const statusModificationStatus = useSelector(selectModificationStatus);

    const ToggleClass = () => {
        setActive(!isActive);
        onCancelClicked();

        const timer = setTimeout(() => {
            dispatch(closeCompanyFlyout())
            setActive(false)
        }, 1500);
    };

    const onCancelClicked = (e) => {
        setCreatemode(false);
        setEditmode(false);
        setCFInvalid(false);
        setCFSubmitted(false);
    };


    useEffect(() => {
        setCreatemode(companyFlyoutCreateMode);
        setEditmode(companyFlyoutEditMode)
    }, [companyFlyoutCreateMode, companyFlyoutEditMode]);

    useEffect(() => {
        if (statusCreationStatus === fetchStatus.SUCCEEDED || statusModificationStatus === fetchStatus.SUCCEEDED) {
            ToggleClass()
        }
    }, [statusCreationStatus, statusModificationStatus]);

    useEffect(() => {
        if (!pageLoaded) {
            if (companyFormStatus === fetchStatus.IDLE && accountService.isAuthenticated()) {
                setPageLoaded(true);
                _loadForm();
            }
        }
    });

    useEffect(() => {
        if (isObjectArray(userStatuses) && userStatuses.length > 0) {
            let _statusOptions = userStatuses.map((status, index) => (
                <option key={nanoid()} value={status.value}>
                    {status.text}
                </option>
            ));
            setStatusOptions(_statusOptions);
        }
    }, [userStatuses]);

    const _loadForm = async () => {
        // await dispatch(getCompanyForm({
        //     status: tenantId,
        //     serviceType: "TENANTADMIN",
        //     operation: "GETFORMBYTYPE",
        //     payload: {
        //         id: "",
        //         data: "CompanyForm"
        //     }
        // }));
    }

    const onValueUpdated = (e, section, value) => {
        let _companyObj = Object.assign({}, company);
        let _newCompanyObj = onValueChanged(_companyObj, e, section, value);
        console.log('_newCompanyObj : ', _newCompanyObj)
        dispatch(updateSingleData({
            company: _newCompanyObj,
            operation: Operation.UPDATED
        }));
    };

    const onSaveClicked = (e, id, created) => {
        e.preventDefault();
        if (isFormValid(COMPANY_FORM)) {
            if (company) {
                if (created) {
                    dispatch(createCompany({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVESETTINGCOMPANYMASTER",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                settingCompanyMaster: JSON.stringify(company),
                            })
                        }
                    }));
                }
                else {
                    dispatch(updateCompany({
                        client: tenantId,
                        serviceType: "TENANTADMIN",
                        operation: "SAVESETTINGCOMPANYMASTER",
                        payload: {
                            id: id,
                            data: JSON.stringify({
                                settingCompanyMaster: JSON.stringify(company),
                            })
                        }
                    }));
                }
            }
            setCreatemode(false);
            setEditmode(false);
            setCFInvalid(false);
            setCFSubmitted(false);
        }
        else {
            setCFInvalid(true);
        }
        setCFSubmitted(true);
    };


    return (
        <>
            {(companyFlyoutShow) ? (<>
                <div className="flyout-wrapper position-fixed top-0 end-0">
                    <div className={`flyout-card ${isActive ? 'close' : 'open'}`}>
                        <div className="flyout-card-header">
                            <div className="float-end">
                                <div className="close-btn">
                                    <i className="fa fa-times" aria-hidden="true" data-tip='Close' onClick={ToggleClass}></i>
                                    <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                                </div>
                            </div>
                            <div className="flyout-card-title">{company && company.id ? "Edit" : "Add"} Company Details</div>
                            <div className="flyout-card-sub-title">

                            </div>
                        </div>
                        <div className="flyout-card-body vh-100">
                            <div className="form-group p-0 pt-2">
                                <label id="lblCompanyName" className="flyout-label" htmlFor="txtCompanyName">Company Name</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{company && company.CompanyName ? company.CompanyName.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtCompanyName"
                                                name="txtCompanyName"
                                                ShortTextClasses="form-control"
                                                placeholder="Company Name"
                                                value={company && company.CompanyName ? company.CompanyName.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                                                // required={companyForm && companyForm.CompanyName.Required}
                                                required={true}
                                                errorMessage={companyForm && companyForm.CompanyName.Validation}
                                                form={COMPANY_FORM}
                                                submited={cfSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblCompanyPhoneNumber" className="flyout-label" htmlFor="txtCompanyPhoneNumber">Company Phone Number</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{company && company.CompanyPhoneNumber ? company.CompanyPhoneNumber.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <PhoneText
                                                id="txtCompanyPhoneNumber"
                                                name="txtCompanyPhoneNumber"
                                                PhoneTextClasses="form-control"
                                                placeholder="(000)-00-0000"
                                                value={company && company.CompanyPhoneNumber ? company.CompanyPhoneNumber.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                                                // required={companyForm && companyForm.CompanyPhoneNumber.Required}
                                                errorMessage={companyForm && companyForm.CompanyPhoneNumber.Validation}
                                                form={COMPANY_FORM}
                                                submited={cfSubmitted}
                                            />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblCompanyAddress" className="flyout-label" htmlFor="txtCompanyAddress">Company Address</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{company && company.CompanyAddress ? company.CompanyAddress.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <ShortText
                                                id="txtCompanyAddress"
                                                name="txtCompanyAddress"
                                                ShortTextClasses="form-control"
                                                placeholder="Company Address"
                                                value={company && company.CompanyAddress ? company.CompanyAddress.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                                                // required={companyForm && companyForm.CompanyAddress.Required}
                                                required={true}
                                                errorMessage={companyForm && companyForm.CompanyAddress.Validation}
                                                form={COMPANY_FORM}
                                                submited={cfSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblStatus" className="flyout-label" htmlFor="drpStatus">Status</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{company && company.Status ? company.Status.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                required={true}
                                                // required={companyForm && companyForm.Status.Required}
                                                errorMessage={companyForm && companyForm.Status.validation}
                                                form={COMPANY_FORM}
                                                submited={cfSubmitted}
                                                id="drpStatus"
                                                name="drpStatus"
                                                SelectorClasses="form-select"
                                                value={company && company.Status ? company.Status.Data.trim() : ""}
                                                options={
                                                    <>
                                                        {statusOptions}
                                                    </>
                                                }
                                                onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblCompanyWebsite" className="flyout-label" htmlFor="txtCompanyWebsite">Company Website</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{company && company.CompanyWebsite ? company.CompanyWebsite.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <UrlText
                                                id="txtCompanyWebsite"
                                                name="txtCompanyWebsite"
                                                UrlTextClasses="form-control"
                                                placeholder="www.example.com"
                                                value={company && company.CompanyWebsite ? company.CompanyWebsite.Data : ""}
                                                onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                                                // required={companyForm && companyForm.CompanyWebsite.Required}
                                                errorMessage={companyForm && companyForm.CompanyWebsite.Validation}
                                                form={COMPANY_FORM}
                                                submited={cfSubmitted} />
                                        }
                                    />
                                }
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblStatus" className="flyout-label" htmlFor="drpStatus">Company Logo</label>
                                <FileUpload />
                            </div>

                            <div className="form-group p-0 pt-2">
                                <label id="lblPlan" className="flyout-label" htmlFor="drpPlan">Plan</label>
                                {!editMode && !createMode ?
                                    <label className="field-label-value">{company && company.Plan ? AccountPlan[`${company.Plan.Data}`] : ""}</label> :
                                    // <label className="field-label-value">{company && company.Plan ? company.Plan.Data : ""}</label> :
                                    <InputGroup
                                        icon={<i className="fa fa-address-card"></i>}
                                        control={
                                            <Selector
                                                required={true}
                                                // required={companyForm && companyForm.Plan.Required}
                                                errorMessage={companyForm && companyForm.Plan.validation}
                                                form={COMPANY_FORM}
                                                submited={cfSubmitted}
                                                id="drpPlan"
                                                name="drpPlan"
                                                SelectorClasses="form-select"
                                                value={company && company.Plan ? company.Plan.Data.trim() : ""}
                                                options={
                                                    <>
                                                        <option value="0">Free</option>
                                                        <option value="1">Basic</option>
                                                        <option value="2">Standard</option>
                                                        <option value="3">Enterprise</option>
                                                    </>
                                                }
                                                onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)} />
                                        }
                                    />
                                }
                            </div>
                        </div>
                        <div className="flyout-card-footer">
                            <div className="d-flex">
                                <div className="ms-auto">
                                    {statusCreationStatus === fetchStatus.LOADING || statusModificationStatus === fetchStatus.LOADING ? <EllipsisLoader /> :
                                        <>
                                            <button className="c-btn white-btn me-3" type="button" onClick={ToggleClass} >Cancel</button>
                                            <button className="c-btn dark-btn" type="button" onClick={(e) => onSaveClicked(e, company ? company.id : "", createMode)}>Save</button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flyout-backdrop flyout-backdrop-show' />
            </>
            ) :
                <></>
            }
        </>

    )
}

export default CompanyFlyout;