import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { createRouteTracking, deleteLeadsDetails, getLeadsOptions, getRouteTrackingByUserAndDate, selectCreationStatus, selectLeadsOptions, selectLeadsOptionsState, selectModificationStatus, selectRecentClickedRouteTracking, selectRouteTrackingById, selectRouteTrackingByIdUserAndDate, selectSingleRefreshed, selectSingleStatus, updateRouteTracking, updateSingleData } from '../reducerSlices/routeTrackingSlice';
import { getSalesRepAndSalesMangerOptions, selectSalesRepAndSalesMangerOptions, selectSalesRepAndSalesMangerOptionsState } from '../reducerSlices/leadSlice';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import { fetchStatus, tenantId } from '../../../api/client';
import { accountService } from '../../accounts/services/accountService';
import SelectorSearch from '../../../component/Controls/custom/selectors/selectorSearch';
import { onValueChanged, sections } from '../services/routeTrackingSaver';
import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup';
import DateText from '../../../component/Controls/custom/textbox/dateText';
import { showRTLModal } from '../../../component/Controls/modals/reducerSlices/routeTrackingLeadModalPopUpSlice';
import LeadsDetailsTracking from './components/leadsDetailsTracking';
import { isFormValid } from '../../../component/Controls/services/formService';
import LogCardRouteTracking from '../../../component/Card/LogCardRouteTracking';
import { selectDocumentDownloadData, selectDocumentDownloadStatus, updateDocumentDownloadStatus } from '../../reviewer/reducerSlices/reviewerSlice';
import { downloadFile } from '../../../common/common';


function RouteTrackingDetails() {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = accountService.getAuthenticatedUser();

  const recentClickedRouteTracking = useSelector(selectRecentClickedRouteTracking);
  const ROUTETRACKING_FORM = "RouteTrackingForm";

  const [RTFInvalid, setRTFInvalid] = useState(false);
  const [rtfSubmitted, setRTFSubmitted] = useState(false);
  const [createMode, setCreatemode] = useState(false);
  const [editMode, setEditmode] = useState(false);

  const [selectedTab, setSelectedTab] = useState(1);
  const routeTracking = useSelector(selectRouteTrackingById);

  const [salesRepAndSalesMangerOptionsData, setSalesRepAndSalesMangerOptionsData] = useState();
  const salesRepAndSalesMangerOptionsState = useSelector(selectSalesRepAndSalesMangerOptionsState);
  const salesRepAndSalesMangerOptions = useSelector(selectSalesRepAndSalesMangerOptions);

  const leadsOptionsState = useSelector(selectLeadsOptionsState);
  const leadsOptions = useSelector(selectLeadsOptions);
  const [leadsOptionsData, setLeadsOptionsData] = useState([]);

  const routeTrackingRequestStatus = useSelector(selectSingleStatus);
  const singleRefreshedStatus = useSelector(selectSingleRefreshed);

  const routeTrackingCreationStatus = useSelector(selectCreationStatus);
  const routeTrackingModificationStatus = useSelector(selectModificationStatus);

  const isAdmin = accountService.isAdmin();
  const isSalesManager = accountService.isSalesManager();

  const routeTrackingByIdUserAndDate = useSelector(selectRouteTrackingByIdUserAndDate);

  const [latestLog, setLatestLog] = useState(undefined);
  const [logCards, setLogCards] = useState(undefined);
  const [leadsDetailsCards, setLeadsDetailsCards] = useState(undefined);

  useEffect(() => {
    if (recentClickedRouteTracking === undefined) {
      history.push('../routeList');
    }

    if (recentClickedRouteTracking === 0) {
      setCreatemode(true);
    } else {
      setCreatemode(false);
    }

  }, [recentClickedRouteTracking]);

  const onTabSelected = (e, v) => {
    setSelectedTab(v);
  };

  useEffect(() => {
    if (salesRepAndSalesMangerOptionsState === fetchStatus.SUCCEEDED) {
      if (salesRepAndSalesMangerOptions) {
        if (isObjectArray(salesRepAndSalesMangerOptions)) {
          if (isAdmin || isSalesManager) {
            let _salesRepAndSalesMangerOptions;
            _salesRepAndSalesMangerOptions = salesRepAndSalesMangerOptions.map((d, index) => (
              {
                text: d.FirstName + ' ' + d.LastName,
                value: d.id,
              }
            ));
            setSalesRepAndSalesMangerOptionsData(_salesRepAndSalesMangerOptions);
          } else {
            let _fullName = user.firstName + ' ' + user.lastName;
            let _userId = user.id;
            setSalesRepAndSalesMangerOptionsData([{ text: _fullName, value: _userId }]);
          }
        }
      }
    }
  }, [salesRepAndSalesMangerOptions]);

  useEffect(() => {
    if (salesRepAndSalesMangerOptionsState === fetchStatus.IDLE) {
      dispatch(getSalesRepAndSalesMangerOptions({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSALESREPANDSALESMANAGER",
        payload: {
          id: "",
        }
      }));
    }
  }, [salesRepAndSalesMangerOptionsState]);

  useEffect(() => {
    if (routeTracking && routeTracking.userId) {
      _getLeadsOption(routeTracking.userId)
    }
  }, [routeTracking])

  useEffect(() => {
    if (leadsOptionsState === fetchStatus.SUCCEEDED) {
      if (leadsOptions) {
        if (isObjectArray(leadsOptions)) {
          let _leadsOptions;
          _leadsOptions = leadsOptions.map((d, index) => (
            {
              text: d.LeadName,
              value: d.id,
            }
          ));
          setLeadsOptionsData(_leadsOptions);
        }
      }
    }
  }, [leadsOptions]);

  const _getLeadsOption = (userId) => {
    dispatch(getLeadsOptions({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "GETLEADSOPTIONSBYUSERID",
      payload: {
        id: "",
        data: JSON.stringify({ "userId": userId })
      }
    }));
  }

  const onSalesRepChnage = (e, value) => {
    if (value) {
      _getLeadsOption(value);
    }
  };

  const onValueUpdated = (e, section, value) => {
    let _routeTrackingObj = Object.assign({}, routeTracking);
    let _newRouteTrackingObj = onValueChanged(_routeTrackingObj, e, section, value);
    dispatch(updateSingleData(_newRouteTrackingObj));
  };

  const _getUserText = (options, id) => {
    if (isObjectArray(options)) {
      let userResult = options.find(u => u.id === id);
      if (userResult && userResult.FirstName) {
        return userResult.FirstName + ' ' + userResult.LastName;
      }
    }
    return "";
  }

  const onSaveClicked = (e, id, created) => {
    e.preventDefault();

    if (routeTracking && routeTracking.leadsDetails.length > 0) {

    } else {
      alert('At least add one Pipeline');
      setRTFInvalid(true);
      setRTFSubmitted(true);
      return;
    }

    if (created) {
      if (isFormValid(ROUTETRACKING_FORM) && routeTrackingByIdUserAndDate === "") {
        if (routeTracking) {
          if (created) {
            dispatch(createRouteTracking({
              client: tenantId,
              serviceType: "TENANTADMIN",
              operation: "SAVEROUTETRACKING",
              payload: {
                id: id,
                data: JSON.stringify({
                  routeTracking: JSON.stringify(routeTracking)
                })
              }
            }));
          }
        }
        setCreatemode(false);
        setEditmode(false);
        setRTFInvalid(false);
        setRTFSubmitted(false);
      }
      else {
        setRTFInvalid(true);
      }
    } else {
      if (isFormValid(ROUTETRACKING_FORM)) {
        if (routeTracking) {
          if (!created) {
            dispatch(updateRouteTracking({
              client: tenantId,
              serviceType: "TENANTADMIN",
              operation: "SAVEROUTETRACKING",
              payload: {
                id: id,
                data: JSON.stringify({
                  routeTracking: JSON.stringify(routeTracking)
                })
              }
            }));
          }
        }
        setCreatemode(false);
        setEditmode(false);
        setRTFInvalid(false);
        setRTFSubmitted(false);
      }
      else {
        setRTFInvalid(true);
      }
    }
    setRTFSubmitted(true);
  };

  useEffect(() => {
    if (routeTrackingModificationStatus === fetchStatus.SUCCEEDED) {
      setEditmode(false);
      setSelectedTab(1);
      setRTFInvalid(false);
    }
  }, [routeTrackingModificationStatus]);

  const onLeadClicked = () => {
    dispatch(showRTLModal({
      title: <><span>Add Pipeline For</span> <span className="modal-header-name">{routeTracking && routeTracking.userId ? _getUserText(salesRepAndSalesMangerOptions, routeTracking.userId) : ""}</span></>,
      id: routeTracking.id,
      routeTracking: routeTracking,
      leadsOptions: leadsOptions
    }));
  }

  useEffect(() => {
    setLatestLog(undefined);
    if (routeTracking) {
      if (isObjectArray(routeTracking.StatusLogs)) {
        let _latestLog = routeTracking.StatusLogs.slice(-1);
        if (_latestLog && Array.isArray(_latestLog) && _latestLog.length === 1) {
          setLatestLog(_latestLog[0]);
        }
      }
      if (routeTracking && isObjectArray(routeTracking.StatusLogs)) {
        let logs = [...routeTracking.StatusLogs];
        setLogCards(logs.reverse());
      }
      else {
        setLogCards(undefined);
      }

      if (routeTracking && isObjectArray(routeTracking.leadsDetails)) {
        let _leadsDetails = [...routeTracking.leadsDetails];
        setLeadsDetailsCards(_leadsDetails);
      }
      else {
        setLeadsDetailsCards(undefined);
      }

    }
    else {
      setLogCards(undefined);
      setLeadsDetailsCards(undefined);
    }
  }, [routeTrackingRequestStatus, routeTracking]);

  const onDeleteLeadClicked = (e, leadId) => {
    e.preventDefault();
    dispatch(deleteLeadsDetails({ leadId, user }));
  }

  useEffect(() => {
    if (routeTrackingCreationStatus === fetchStatus.FAILED) {
      setCreatemode(true);
    }
    if (routeTrackingCreationStatus === fetchStatus.SUCCEEDED) {
      setRTFInvalid(false);
      setRTFSubmitted(false);
    }
  }, [routeTrackingCreationStatus]);

  useEffect(() => {
    if (createMode) {
      if (routeTracking && routeTracking.userId && routeTracking.routeDate) {
        _getRouteTrackingByUserAndDate(routeTracking.userId, routeTracking.routeDate)
      }
    }
  }, [routeTracking]);

  const _getRouteTrackingByUserAndDate = async (userId, routeDate) => {
    if (userId && routeDate) {
      await dispatch(getRouteTrackingByUserAndDate({
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETROUTETRACKINGBYUSERANDDATE",
        payload: {
          id: userId,
          data: JSON.stringify({ routeDate: routeDate, userId: userId })
        }
      }));
    }
  }

  const documentDownloadStatus = useSelector(selectDocumentDownloadStatus);
  const documentDownloadData = useSelector(selectDocumentDownloadData);

  if (documentDownloadStatus === fetchStatus.SUCCEEDED) {
    if (documentDownloadData) {
      downloadFile(documentDownloadData.documentData, documentDownloadData.documentName);
    }
    dispatch(updateDocumentDownloadStatus());
  }


  return (
    <>
      <div className="px-3 pt-3">
        <div className="row">
          <div className="d-flex bd-highlight">
            <div className="bd-highlight">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Route List</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Details</li>
                </ol>
              </nav>
            </div>
            <div className="ms-auto">
              {!editMode && !createMode ?
                <>
                  <div className='c-icon-box' onClick={() => setEditmode(!editMode)} data-tip='Edit'>
                    <i className='fa-solid fa-pencil'></i>
                  </div>
                </>
                :
                <>
                  <div
                    className={`c-icon-box ${createMode && routeTrackingByIdUserAndDate ? 'disabled' : ''}`}
                    onClick={(e) => createMode && routeTrackingByIdUserAndDate ? null : onSaveClicked(e, routeTracking ? routeTracking.id : "")}
                    data-tip='Save'>
                    <i className={`fa fa-solid fa-floppy-disk ${createMode && routeTrackingByIdUserAndDate ? 'text-danger' : ''}`}></i>
                  </div>
                  <div className='c-icon-box' onClick={() => setEditmode(!editMode)} data-tip='Cancel saving'>
                    <i className='fa fa-times'></i>
                  </div>
                  <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
                </>
              }
              <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
            </div>
          </div>
        </div>
      </div>

      <div className="px-3">
        <div className="row">
          <div className="col-md-12">

            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button className={`nav-link nav-link-people ${selectedTab === 0 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 0)}>Status Logs</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link nav-link-people ${selectedTab === 1 ? "active" : ""}`} onClick={(e) => onTabSelected(e, 1)}>Route Details</button>
              </li>
            </ul>

            <div className="tab-content" id="myTabContent">
              <div className={`tab-pane fade ${selectedTab === 0 && "show active"}`} >
                <div className="card a-card-wrap">

                  {/* <div className="pt-3">
                    <div className="separator">
                      <div className="line line-start"></div>
                      <input type="text" className="separator-input" placeholder="Search"
                        onChange={(e) => onLogSearch(e)}
                      />
                      <SortButton
                        SortButtonClasses="mx-2"
                        sorted={sort}
                        onSortClicked={(e) => onSort(e)}
                      />
                      <div className="line"></div>
                    </div>
                  </div> */}

                  <div className="d-flex">
                    <div className="col-md-9 col-lg-9">
                      <div className="tracking-list pt-3 ps-3">
                        <LogCardRouteTracking statusLogs={logCards} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="tab-content" id="myTabContent">
              <div className={`tab-pane fade ${selectedTab === 1 && "show active"}`} >
                <div className="card a-card-wrap">

                  <div className="row m-0 px-3">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="field-label" htmlFor="txtUserId">User Name</label>
                        {!createMode ?
                          <label className="field-label-value">{routeTracking && routeTracking.userId ? _getUserText(salesRepAndSalesMangerOptions, routeTracking.userId) : ""}</label> :
                          <div className="e-input-group">
                            <div className="e-input-in-wrap">
                              <SelectorSearch
                                id="txtUserId"
                                name="txtUserId"
                                required={true}
                                value={routeTracking && routeTracking.userId}
                                form={ROUTETRACKING_FORM}
                                submited={rtfSubmitted}
                                options={
                                  salesRepAndSalesMangerOptionsData
                                }
                                onChange={(e, v) => { onValueUpdated(e, sections.ROUTETRACKING_DETAILS, v); onSalesRepChnage(e, v) }}
                              />
                            </div>
                          </div>
                        }
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">

                        <label id="lblRouteDate" className="field-label" htmlFor="txtRouteDate">Date</label>
                        {!createMode ?
                          <label className="field-label-value">{routeTracking && routeTracking.routeDate ? routeTracking.routeDate : ""}</label> :
                          <InputGroup
                            icon={<i className="fa fa-calendar"></i>}
                            control={<DateText
                              required={true}
                              errorMessage={"Select route date"}
                              id="txtRouteDate"
                              name="txtRouteDate"
                              DateTextClasses="form-control"
                              form={ROUTETRACKING_FORM}
                              submited={rtfSubmitted}
                              value={routeTracking ? routeTracking.routeDate : ""}
                              onChange={(e) => onValueUpdated(e, sections.ROUTETRACKING_DETAILS, null)}
                            />} />


                        }
                        {createMode && routeTrackingByIdUserAndDate &&
                          <span className="error-label"><i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>{routeTrackingByIdUserAndDate}</span>
                        }
                      </div>

                    </div>
                  </div>

                  <div className="c-line"></div>

                  <div className="row m-0 px-3 pt-3">
                    <div className="col-md-11 mb-3">
                      <div className="d-flex align-items-center">
                        <p className="p-name">Pipelines</p>
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div className="d-flex align-items-center">
                        {!editMode && !createMode ?
                          <></>
                          :
                          routeTracking ?
                            routeTracking.userId ?
                              <span
                                className="add-position-button"
                                title={"Add Pipelines"}
                                onClick={(e) => onLeadClicked(e)}>
                                <i className="fa fa-plus-circle fa-3x"></i>
                              </span>
                              : <>
                                <span
                                  className="add-position-button"
                                  title={"First Select User"}>
                                  <i className="fa fa-plus-circle fa-3x"></i>
                                </span>
                              </>
                            : <></>
                        }
                      </div>

                    </div>
                  </div>

                  <div className="row m-0 px-3">
                    <LeadsDetailsTracking
                      routeTracking={routeTracking}
                      leadsDetails={leadsDetailsCards}
                      onDeleteClicked={(e, id) => onDeleteLeadClicked(e, id)}
                      createMode={createMode}
                      editMode={editMode}
                      readonly={!editMode && !createMode}
                    />
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default RouteTrackingDetails