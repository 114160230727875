import React from 'react';

import './css/ellipsisLoader.css';

function EllipsisLoader(props) {
    return (
        <span className="lds-ellipsis">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </span>
    );
}

export default EllipsisLoader;