import React from 'react';

function CancelButton(props) {
    return (
        <button
            type="button"
            className={`c-btn white-btn mx-2 ${props.CancelButtonClasses ? props.CancelButtonClasses : ""}`}
            title={props.title ? props.title : "Cancel operation"}
            onClick={(e) => props.onCancelClicked && props.onCancelClicked(e)}>
            {props.text ? props.text : "Cancel"}
        </button>
    );
}

export default CancelButton;