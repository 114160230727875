export const sections = {
    STATUS_DETAILS: 0
};

export const onValueChanged = (status, e, section, value) => {
    let _status = JSON.parse(JSON.stringify(status));
    if (section == sections.STATUS_DETAILS) {
        return _saveStatusDetails(_status, e, value);
    }
};

const _saveStatusDetails = (status, e, value) => {
    if (e.target.name === "txtStatusName") {
        status.StatusName.Data = e.target.value;
    }
    else if (e.target.name === "drpPrimaryStatus") {
        status.PrimaryStatus.Data = e.target.value;
    }
    else if (e.target.name === "drpStatus") {
        status.Status.Data = e.target.value;
    }
    
    return status;
};