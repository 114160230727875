import { nanoid } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isValueObject } from '../../../common/common';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import YesNoRadio from '../../Controls/custom/checkers/yesNoRadio';
import InputGroup from '../../Controls/custom/inputGroup/inputGroup';
import Selector from '../../Controls/custom/selectors/selectors';
import NumberText from '../../Controls/custom/textbox/numberText';


function OtherExperienceFilter(props) {
    const id = nanoid();
    const [havingExperience, setHavingExperience] = useState("-1");
    //const [experience, setExperience] = useState("");
    const [operation, setOperation] = useState(">");
    const [value, setValue] = useState("");
    const [experienceType, setExperienceType] = useState("");

    let _experienceTypes = props.experienceTypes;

    const otherExperienceFilter = useSelector(props.otherExperienceFilterSelector);

    useEffect(() => {
        if (otherExperienceFilter) {
            setHavingExperience(otherExperienceFilter.havingExperience);
            setExperienceType(otherExperienceFilter.type);
            if (otherExperienceFilter.experience.operation) {
                setOperation(otherExperienceFilter.experience.operation);
            }
            else {
                setOperation(">");
            }
            setValue(otherExperienceFilter.experience.value);
        }
    }, [otherExperienceFilter]);

    const onOperationChanged = (e) => {
        setOperation(e.target.value);
        let _value = undefined;

        _value = {
            havingExperience: havingExperience,
            type: props.includeType ? experienceType : "",
            experience: {
                operation: e.target.value,
                value: value
            }
        }

        props.onExperienceChanged && props.onExperienceChanged(e, _value);
    };

    const onExperienceChanged = (e) => {
        setValue(e.target.value);
        let _value = undefined;

        _value = {
            havingExperience: havingExperience,
            type: props.includeType ? experienceType : "",
            experience: {
                operation: operation,
                value: e.target.value
            }
        }

        props.onExperienceChanged && props.onExperienceChanged(e, _value);
    };

    const onExperienceTypeChanged = (e) => {
        setExperienceType(e.target.value);
        let _value = undefined;

        _value = {
            havingExperience: havingExperience,
            type: props.includeType ? e.target.value : "",
            experience: {
                operation: operation,
                value: value
            }
        }

        props.onExperienceChanged && props.onExperienceChanged(e, _value);
    };

    const onHavingExperienceChanged = (e, v) => {
        setHavingExperience(v);
        let _value = undefined;

        _value = {
            havingExperience: v,
            type: v === "Y" ? (props.includeType ? experienceType : "") : "",
            experience: {
                operation: v === "Y" ? operation : "",
                value: v === "Y" ? value : ""
            }
        }

        props.onExperienceChanged && props.onExperienceChanged(e, _value);
    };

    let experienceTypesList;
    if (isObjectArray(_experienceTypes)) {
        experienceTypesList = _experienceTypes.map((et, index) => (
            <option key={et.value} value={et.value}>
                {et.text}
            </option>
        ));
    }

    return (
        <>
            <div className="row">
                <div className={props.includeType ? "col-md-12" : "col-md-12"}>
                    <label>
                        {props.experienceFor ? props.experienceFor : ""}
                    </label>
                    <YesNoRadio
                        name={`rdbtnHavExp${id}`}
                        value={havingExperience}
                        onSelectionChanged={(e, v) => onHavingExperienceChanged(e, v)}
                        YesNoRadioClasses="float-end" />
                </div>
            </div>
            <div className="row">
                <div className={props.includeType ? "col-md-12" : "col-md-12"}>
                    <div className="form-group p-0 pt-2">
                        <label id={`lbl${id}`}
                            className="field-label d-inline"
                            htmlFor={`txtExperience${id}`}>
                            Months Experience
                        </label>
                        <div className="experience-controls float-end">
                            <Selector
                                required={true}
                                errorMessage={"Please select operation"}
                                form={undefined}
                                submited={false}
                                id={`drpOperation${id}`}
                                name={`drpOperation${id}`}
                                SelectorClasses="form-control operation-control"
                                value={(havingExperience === "Y") ? operation : ""}
                                disabled={!(havingExperience === "Y")}
                                withoutDefault={true}
                                options={
                                    <>
                                        <option value=">">{'>'}</option>
                                        <option value="<">{'<'}</option>
                                        <option value=">=">{'>='}</option>
                                        <option value="<=">{'<='}</option>
                                        <option value="=">{'='}</option>
                                    </>
                                }
                                onChange={(e) => onOperationChanged(e)} />
                            <InputGroup
                                icon={<i className="fa fa-suitcase"></i>}
                                control={
                                    <NumberText
                                        max="99"
                                        maxLength={2}
                                        required={true}
                                        errorMessage={"Please enter experience"}
                                        form={undefined}
                                        submited={false}
                                        id={`txtExperience${id}`}
                                        name={`txtExperience${id}`}
                                        NumberTextClasses={`form-control ${havingExperience === "Y" ? "" : "disabled"}`}
                                        disabled={!(havingExperience === "Y")}
                                        value={(havingExperience === "Y") ? value : ""}
                                        onChange={(e) => onExperienceChanged(e)} />
                                } />
                        </div>
                    </div>
                </div>
            </div>
            {props.includeType ? <div className="row">
                <div className="col-md-12">
                    <div className="form-group p-0 pt-2">
                        <label id={`lblExperienceType${id}`}
                            className="field-label d-inline"
                            htmlFor={`drpExperienceType${id}`}>
                            {props.experienceType ? props.experienceType : ""}
                        </label>
                        <div className="d-flex float-end">
                            <Selector
                                required={true}
                                errorMessage={"Please select experience type"}
                                form={undefined}
                                submited={false}
                                id={`drpExperienceType${id}`}
                                name={`drpExperienceType${id}`}
                                SelectorClasses="form-select"
                                value={(havingExperience === "Y") ? experienceType : ""}
                                disabled={!(havingExperience === "Y")}
                                options={experienceTypesList}
                                onChange={(e) => onExperienceTypeChanged(e)} />
                        </div>
                    </div>
                </div>
            </div> : <></>}

        </>
    );
}

export default OtherExperienceFilter;