import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckBox from '../../Controls/custom/checkers/checkbox';

function PositionFilter(props) {
    const dispatch = useDispatch();
    //const selectedFilter = useSelector(selectSelectedFilter);

    const [open, setOpen] = useState(false);

    const positionFilter = useSelector(props.positionFilterSelector);

    const pfContainerRef = useRef(null);

    let _positions = props.positions;

    let positionsList;
    let isPositionsSelected = (positionFilter.value && Array.isArray(positionFilter.value) && positionFilter.value.length > 0);
    if (_positions && Array.isArray(_positions) && _positions.length > 0) {
        positionsList = _positions.map((position, index) => (
            <li key={position.value} className="filter-list-item"
                onClick={(e) => props.onPositionChanged && props.onPositionChanged(e, position.value, _positions)}>
                <CheckBox
                    id={`ckbPosition-${position.value}`}
                    size="Small"
                    checked={isPositionsSelected ? positionFilter.value.includes(position.value) : false} />
                <span className={`ms-3 ${isPositionsSelected && positionFilter.value.includes(position.value) ? "filter-selected" : ""}`}>{position.text}</span>

            </li>
        ));
    }

    useEffect(() => {
        if (open && pfContainerRef && pfContainerRef.current) {
            pfContainerRef.current.focus();
        }
    });

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Position</span> :
                <span className="filter-value">{positionFilter.value ? positionFilter.text : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={pfContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    {positionsList}
                </ul>
            </div> : <></>}
        </div>
    );
}

export default PositionFilter;