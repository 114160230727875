import React from "react";
import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import girlFaceImg from './../../assets/images/clipart/girl-face.svg';
import nophoto from './../../assets/images/clipart/nophoto.png';
import calendarImg from './../../assets/images/clipart/calendar.svg';
import mailImg from './../../assets/images/clipart/mail.svg';
import mapLocationImg from './../../assets/images/clipart/map-location.svg';
import applicationImg from './../../assets/images/clipart/application.svg';
import noteImg from './../../assets/images/clipart/note.svg';
import documentImg from './../../assets/images/clipart/document.svg';
import msgImg from './../../assets/images/clipart/msg.svg';
import ReactTooltip from "react-tooltip";
import DialCallButton from "../Controls/buttons/dialCallButton";
import { applicantStatusesData } from "../../features/shared/services/masterData";


const PeopleCard = (props) => {

  const _getStatusClass = () => {
    if (props.status === applicantStatusesData.Active) {
      return 'status-active';
    }
    else if (props.status === "Incomplete") {
      return 'status-incomplete';
    }
    else if (props.status === applicantStatusesData.InProgress) {
      return 'status-inprogress';
    }
    else if (props.status === applicantStatusesData.Available) {
      return 'status-available';
    }
    else if (props.status === applicantStatusesData.Dispatched) {
      return 'status-dispatched';
    }
    else if (props.status === applicantStatusesData.InActive) {
      return 'status-inactive';
    }
    else if (props.status === applicantStatusesData.DNU) {
      return 'status-dnu';
    }
  }


  return (
    <div className="card people-card">
      <div className="c-checkbox">
        <input type="checkbox" name="c1" />
      </div>
      <div className={`status ${_getStatusClass()}`}>{props.statusText && props.statusText}</div>
      <div className="people-card-body p-2 cursor-pointer" onClick={props.onClick}>
        <div className="d-flex">
          <div className="pe-1">
            <div className="people-card-photo">
              <div className="people-card-photo-box">
                <img className="people-card-profile-box-img "
                  src={props && props.profilePhoto ?
                    props.profilePhoto.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "")
                    : props && props.gender === 'Female' ? girlFaceImg
                      : props && props.gender === 'Male' ? boyFaceImg
                        : nophoto
                  }
                />
              </div>
            </div>
          </div>
          <div className="ps-2">
            <div className="title w-100">
              {props.fullName}
            </div>
            <div className="row">
              <div className="detail col-4">
                <div className="d-flex align-items-center">
                  <DialCallButton
                    dialCallButtonClasses="pe-1" />
                  {props.phone}
                </div>
              </div>
              <div className="detail col-8">
                <img src={mailImg} /> {props.email}
              </div>
              <div className="detail col-4">
                <img src={calendarImg} /> DOB: {props.dateOfBirth}
              </div>
              <div className="detail col-8">
                <img src={mapLocationImg} /> {props.location}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="people-card-footer">
        <div className="item">
          <span className="detail">
            <img src={applicationImg} /> Application Date: {props.applicationDate}
          </span>
        </div>
        <div className="item">
          <span className="detail cursor-pointer" data-tip='Add Note' onClick={props.onClickNote}>
            <img src={noteImg} />Note
          </span>
          <span className="detail cursor-pointer ms-2" data-tip='Upload/Download Documents' onClick={props.onClickDocuments} >
            <img src={documentImg} />Document
          </span>
          <span className="detail cursor-pointer ms-2" data-tip='Message' onClick={props.onChatClicked}>
            <img src={msgImg} />Message
          </span>
          <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
        </div>
      </div>
    </div>
  )
}

export default PeopleCard;
